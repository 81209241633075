import { NgModule } from "@angular/core";
import { EditDomainDialogComponent } from "./edit-domain-dialog/container/edit-domain-dialog.component";
import { StoreModule } from "@ngrx/store";
import { EditDomainDialogSandbox } from "./edit-domain-dialog/sandbox/edit-domain-dialog.sandbox";
import { REDUCER_NAME } from "../store/reducers";
import { reducer } from "../store/main/reducer";
import { SharedModule } from "../../../../../../shared/shared.module";
import { EditDomainFormComponent } from "./edit-domain-dialog/components/edit-domain-form/edit-domain-form.component";



@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditDomainDialogSandbox
    ],
    declarations: [
        EditDomainDialogComponent,
        EditDomainFormComponent
    ],
    entryComponents: [
        EditDomainDialogComponent
    ],
    exports: [
        EditDomainDialogComponent
    ]
})
export class EditDomainDialogModule {

}
