import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

export interface SubmitPassData {
    description: string;
}

@Component({
    selector: 'app-add-pass-dialog-form',
    templateUrl: './add-pass-dialog-form.component.html'
})

export class AddPassDialogFormComponent {

    @Output() submit: EventEmitter<SubmitPassData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        this.form = new FormGroup({
            description: new FormControl()
        });
    }

    onSubmit() {

        let description: string = this.form.value.description;

        if (!description) {
            this.hasError = true;
            this.errorMessage = "Please enter multipass name";
            return;
        }

        this.submit.emit({
            description: description
        });
    }

    onCancel() {
        this.cancel.emit();
    }
}
