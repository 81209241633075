<div class="component-item-wrapper">
    <label class="label component-label">Latitude</label>
    <div class="component-input-wrapper">
        <input placeholder="Enter location latitude" type="text" [(ngModel)]="latitude" (input)="onCoordinatesChange()">
    </div>
</div>
<div class="component-item-wrapper">
    <label class="label component-label">Longitude</label>
    <div class="component-input-wrapper">
        <input placeholder="Enter location longitude" type="text" [(ngModel)]="longitude" (input)="onCoordinatesChange()">
    </div>
</div>
<div class="component-item-wrapper">
    <div class="component-location-map-wrapper" #map>

    </div>
</div>
