import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {DropdownOptionModel} from '../form-elements/dropdown/dropdown-option.model';
import {FavoriteResponseModel} from '../../../core/modules/rest/favorite/response/favorite-response.model';



@Component({
    selector: 'app-favorites',
    templateUrl: './favorites.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoritesComponent implements OnInit, OnChanges {

    @Input() listOfFavorites: FavoriteResponseModel[];
    @Input() selectedFavoriteId: number;
    @Input() expressionItemsListEmpty: boolean;
    @Input() areEnoughFieldsSelected: boolean;
    @Input() hasUseFavoritesPermission: boolean;
    @Input() hasCreateFavoritePermission: boolean;
    @Input() hasEditFavoritePermission: boolean;
    @Input() hasRemoveFavoritePermission: boolean;
    @Input() showSpanSelectFavorite: boolean;

    @Output() selectedFavoriteChangedEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() editFavoriteEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() removeFavoriteEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() sidebarOpenStatusChanged: EventEmitter<void> = new EventEmitter<void>();

    listOfFavoritesForDropdown: DropdownOptionModel[] = [];
    favoriteName: string;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.listOfFavorites &&  this.listOfFavorites) {
            this.listOfFavoritesForDropdown = this.listOfFavorites.map((favorite: FavoriteResponseModel) =>
                new DropdownOptionModel(favorite.description, favorite.favoriteId.toString())
            );
        }
        this.updateFavoriteName();
    }

    onFavoriteSelected(value: string) {
        const favoriteId = value !== null ? +value : null;
        this.selectedFavoriteChangedEvent.emit(favoriteId);
    }

    addFavoriteClick() {
        this.editFavoriteEvent.emit();
    }

    updateFavoriteClick() {
        this.editFavoriteEvent.emit(this.selectedFavoriteId);
    }

    removeFavoriteClick() {
        this.removeFavoriteEvent.emit(this.selectedFavoriteId);
    }

    onOpenCloseSidebarFavorite() {
        this.sidebarOpenStatusChanged.emit();
    }

    private updateFavoriteName() {
        if (this.selectedFavoriteId) {
            this.favoriteName = this.listOfFavorites.find(favorite => favorite.favoriteId === this.selectedFavoriteId).description;
        } else {
            this.favoriteName = null;
        }
    }
}
