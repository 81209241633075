import {KioskConfigurationWarningModel} from "./kiosk-configuration-warning.model";

export class KioskConfigurationModel {
    kioskConfigurationId: number;
    createdById: number;
    lastUpdatedById: number;
    active: boolean;
    description: string;
    pickupLocationId: number;
    pickupLocationDescription: string;
    valid: boolean;
    warnings: KioskConfigurationWarningModel[];

    constructor(kioskConfigurationId: number, createdById: number, lastUpdatedById: number, active: boolean, description: string, pickupLocationId: number, pickupLocationDescription: string, valid: boolean, warnings: KioskConfigurationWarningModel[]) {
        this.kioskConfigurationId = kioskConfigurationId;
        this.createdById = createdById;
        this.lastUpdatedById = lastUpdatedById;
        this.active = active;
        this.description = description;
        this.pickupLocationId = pickupLocationId;
        this.pickupLocationDescription = pickupLocationDescription;
        this.valid = valid;
        this.warnings = warnings;
    }
}
