import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {CategoryResponseModel} from "../../../../../../../core/modules/rest/category/response/category-response.model";
import {Observable} from "rxjs";
import {SubmitCategoryEmailHtmlTemplatesData} from "./components/edit-category-email-html-templates-dialog-form/edit-category-email-html-templates-dialog-form.component";
import {EditCategoryEmailHtmlTemplatesDialogSandbox} from "./sandbox/edit-category-email-html-templates-dialog.sandbox";
import {DropdownOptionModel} from "../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export class EditCategoryEmailHtmlTemplatesDialogData extends DialogData {

    categoryId: number;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, categoryId: number) {
        super();
        this.categoryId = categoryId;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-edit-category-email-html-templates-dialog',
    templateUrl: './edit-category-email-html-templates-dialog.component.html'
})
export class EditCategoryEmailHtmlTemplatesDialogComponent implements OnDestroy {

    categoryId: number;
    successCallbackFn: Function;

    category$: Observable<CategoryResponseModel>;
    createOrderEmailHtmlTemplates$: Observable<DropdownOptionModel[]>;
    voidOrderEmailHtmlTemplates$: Observable<DropdownOptionModel[]>;
    termsAndConditionsEmailHtmlTemplates$: Observable<DropdownOptionModel[]>;

    constructor(@Inject(DialogData) data: EditCategoryEmailHtmlTemplatesDialogData, private sandbox: EditCategoryEmailHtmlTemplatesDialogSandbox) {

        this.categoryId = data.categoryId;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.categoryId);

        this.category$ = this.sandbox.category$;
        this.createOrderEmailHtmlTemplates$ = this.sandbox.createOrderEmailHtmlTemplates$;
        this.voidOrderEmailHtmlTemplates$ = this.sandbox.voidOrderEmailHtmlTemplates$;
        this.termsAndConditionsEmailHtmlTemplates$ = this.sandbox.termsAndConditionsEmailHtmlTemplates$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitCategoryEmailHtmlTemplatesData) {
        this.sandbox.updateCategoryEmailHtmlTemplates(data).subscribe(() => {
            this.successCallbackFn(data.categoryId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
