import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {SubmitPassProductsData} from "./components/edit-pass-products-dialog-form/edit-pass-products-dialog-form.component";
import {EditPassProductsDialogSandbox} from "./sandbox/edit-pass-products-dialog.sandbox";
import {PassProductResponseModel} from "../../../../../../../core/modules/rest/pass/response/pass-product-response.model";
import {DropdownOptionModel} from "../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export class EditPassProductsDialogData extends DialogData {

    passId: number;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, passId: number) {
        super();
        this.passId = passId;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-edit-pass-products-dialog',
    templateUrl: './edit-pass-products-dialog.component.html'
})
export class EditPassProductsDialogComponent implements OnDestroy {

    passId: number;
    successCallbackFn: Function;

    passProducts$: Observable<PassProductResponseModel[]>;
    products$: Observable<DropdownOptionModel[]>;

    constructor(@Inject(DialogData) data: EditPassProductsDialogData, private sandbox: EditPassProductsDialogSandbox) {

        this.passId = data.passId;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.passId);

        this.passProducts$ = this.sandbox.passProducts$;
        this.products$ = this.sandbox.products$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitPassProductsData) {
        this.sandbox.updatePassProducts(data).subscribe(() => {
            this.successCallbackFn(data.passId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
