const PaymentStatusEnum = {
    PENDING: 'PENDING' as 'PENDING',
    COMPLETE: 'COMPLETE' as 'COMPLETE',
    ERROR: 'ERROR' as 'ERROR',
    REFUND: 'REFUND' as 'REFUND',
    CANCELLED: 'CANCELLED' as 'CANCELLED',
    PARTIAL_REFUND: 'PARTIAL_REFUND' as 'PARTIAL_REFUND',
};
type PaymentStatusEnum = (typeof PaymentStatusEnum)[keyof typeof PaymentStatusEnum];
export {PaymentStatusEnum};
