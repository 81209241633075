import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {CategoryResponseModel} from "../../../../../../../core/modules/rest/category/response/category-response.model";
import {Observable} from "rxjs";
import {SubmitCategoryBasicInfoData} from "./components/edit-category-basic-info-dialog-form/edit-category-basic-info-dialog-form.component";
import {EditCategoryBasicInfoDialogSandbox} from "./sandbox/edit-category-basic-info-dialog.sandbox";

export class EditCategoryBasicInfoDialogData extends DialogData {

    categoryId: number;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, categoryId: number) {
        super();
        this.categoryId = categoryId;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-edit-category-basic-info-dialog',
    templateUrl: './edit-category-basic-info-dialog.component.html'
})
export class EditCategoryBasicInfoDialogComponent implements OnDestroy {

    categoryId: number;
    successCallbackFn: Function;

    category$: Observable<CategoryResponseModel>;

    constructor(@Inject(DialogData) data: EditCategoryBasicInfoDialogData, private sandbox: EditCategoryBasicInfoDialogSandbox) {

        this.categoryId = data.categoryId;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.categoryId);

        this.category$ = this.sandbox.category$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitCategoryBasicInfoData) {
        this.sandbox.updateCategoryBasicInfo(data).subscribe(() => {
            this.successCallbackFn(data.categoryId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
