<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>Mapping for bus: {{ bus.description }}</h1>
            </div>
        </div>
    </div>


    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">

        <div class="formRow">
            <div class="grid-x grid-margin-x">

                <div class="cell">
                    <app-input-label-group>
                        <label class="label">Product</label>
                        <app-multiselect-dropdown
                            class="main--element max-width-unset"
                            [loading]="areProductBusMappingItemsLoading"
                            [allOptionEnabled]="true"
                            [preSelectedValues]="selectedProductBusMappingItems"
                            [options]="productOptions"
                            (changed)="onSelectedProductsChanged($event)">
                        </app-multiselect-dropdown>
                    </app-input-label-group>
                </div>

                <div class="cell">
                    <app-switch
                        [switchLabel]="'Show Inactive Products'"
                        [value]="showInactiveProducts"
                        (switchValueChanged)="onShowInactiveProducts($event)">
                    </app-switch>
                </div>

            </div>
        </div>

    </div><!-- Dialog Content :: END -->

    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>


            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
