<div class="payment-summary-table" *ngIf="currentSearchData && currentSearchData.paymentSummary && currentSearchData.paymentSummary.length !== 0">
    <h3>Payment summary info</h3>
    <ul>
        <li *ngFor="let payment of currentSearchData.paymentSummary">
            {{getPaymentMethodLabel(payment.method)}}: {{getCellValueForPrintingCurrency(payment.amount)}}
        </li>
    </ul>
</div>

<div class="orderReportTable_container" *ngIf="currentSearchData">
    <app-loader [loadType]="'inline'" *ngIf="currentSearchData.loadingReport">
        Loading Report
    </app-loader>

    <div
        *ngIf="!currentSearchData.loadingReport">

        <!-- Empty State component :: BEGIN -->
        <div class="emptyState_wrapper"
             *ngIf="currentSearchData.rows.length === 0">
            <div class="emptyState_container">
                <img src="{{noResultImage}}" alt="" class="emptyStateImage">

                <h1>No Result to Display!</h1>
                <p>We can not find the items you are searching.</p>
                <p>Please try again.</p>
            </div>
        </div><!-- Empty State component :: END -->


        <div class="table_wrapper"
             *ngIf="currentSearchData.rows.length !== 0">

            <div class="table_caption">
               <app-order-reports-table-caption
                   [displayMessage]="getDisplayMessage()"
                   (generateUrlForExportToXlsxEvent)="onGenerateUrlForExportToXlsx()">
               </app-order-reports-table-caption>
            </div>


            <div class="table_container table--wrapper">
                <app-order-reports-table-element
                    (scrollTable)="onScrollTable()"
                    [headers]="currentSearchData.headersToShow"
                    [rows]="currentSearchData.rowsToShow"
                    [level]="currentSearchData.level"
                    [groupSearch]="currentSearchData.groupSearch"
                    [summableFieldsExist]="currentSearchData.summableFieldsExist"
                    [sortBy]="currentSearchData.sortBy"
                    (headerSortEvent)="onSortReport($event)"
                    (showSummaryEvent)="onShowSummary($event)">
                </app-order-reports-table-element>

                <app-loader *ngIf="currentSearchData.loadingMoreItems"></app-loader>
            </div>
        </div>


    </div><!-- Table Wrapper :: END -->
</div>
