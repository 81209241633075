<div class="toolboxButtons_container">
    <ul class="toolboxButtons_list clearfix">
        <li class="toolboxButtons_li float-left" [ngClass]="{'remove' : !hasCreateFavoritePermission}">
            <a class="icon_button"
               *ngIf="hasCreateFavoritePermission"
               [class.disabled]="selectedFavoriteId || expressionItemsListEmpty || !areEnoughFieldsSelected"
               (click)="addFavoriteClick()">

                <div class="icon_container">
                    <i class="fal fa-star"></i>
                </div>
                <span class="iconButtonLabel">
                    Add To Favorite
                </span>
            </a>
        </li>

        <li class="toolboxButtons_li float-left" [ngClass]="{'remove' : !hasEditFavoritePermission}">
            <a class="icon_button"
               *ngIf="hasEditFavoritePermission"
               [class.disabled]="!selectedFavoriteId || expressionItemsListEmpty || !areEnoughFieldsSelected"
               (click)="updateFavoriteClick()">

                <div class="icon_container">
                    <i class="fal fa-sync"></i>
                </div>
                <span class="iconButtonLabel">
                    Update Favorite
                </span>
            </a>
        </li>

        <li class="toolboxButtons_li float-left" [ngClass]="{'remove' : !hasRemoveFavoritePermission}">
            <a class="icon_button"
               *ngIf="hasRemoveFavoritePermission"
               [class.disabled]="!selectedFavoriteId"
               (click)="removeFavoriteClick()">

                <div class="icon_container">
                    <i class="fal fa-times-circle"></i>
                </div>
                <span class="iconButtonLabel">
                    Remove Favorite
                </span>
            </a>
        </li>

        <li class="toolboxButtons_li float-left" [ngClass]="{'remove' : !hasUseFavoritesPermission}">
            <div class="dropdown_wrapper" *ngIf="!showSpanSelectFavorite">
                <app-dropdown
                    [disabled]="listOfFavoritesForDropdown.length === 0"
                    [placeholderText]="'Select a favorite'"
                    [preSelectedValue]="selectedFavoriteId"
                    [options]="listOfFavoritesForDropdown"
                    [sort]="'ASC'"
                    (changed)='onFavoriteSelected($event)'>
                </app-dropdown>
            </div>
            <a class="icon_button"
               *ngIf="showSpanSelectFavorite"
               (click)="onOpenCloseSidebarFavorite()">
                <div class="icon_container">
                    <i class="fal fa-bars"></i>
                </div>
                <span *ngIf="!favoriteName" class="iconButtonLabel">
                    Select a favorite
                </span>
                <span *ngIf="favoriteName" class="iconButtonLabel">
                    {{favoriteName}}
                </span>
            </a>
        </li>
    </ul>
</div>
