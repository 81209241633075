import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {KioskConfigurationDropoffLocationProductTierModel} from '../../model/kiosk-configuration-dropoff-location-product-tier.model';

export interface TierUpdatedData {
    tierId: number;
    value: number;
}


@Component({
    selector: 'app-dialog-product-tier-item',
    templateUrl: './product-tier-dialog-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductTierDialogItemComponent implements OnInit {

    @Input() productTier: KioskConfigurationDropoffLocationProductTierModel;

    @Output() defaultQuantityChangedEvent: EventEmitter<TierUpdatedData> = new EventEmitter();
    @Output() minQuantityChangedEvent: EventEmitter<TierUpdatedData> = new EventEmitter();
    @Output() maxQuantityChangedEvent: EventEmitter<TierUpdatedData> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    defaultQuantityChanged(value) {
        if (value === '') {
            value = null;
        }

        const obj = {
            tierId: this.productTier.tierId,
            value: value
        };
        this.defaultQuantityChangedEvent.emit(obj);
    }

    minQuantityChanged(value) {
        if (value === '') {
            value = null;
        }

        const obj = {
            tierId: this.productTier.tierId,
            value: value
        };
        this.minQuantityChangedEvent.emit(obj);
    }

    maxQuantityChanged(value) {
        if (value === '') {
            value = null;
        }

        const obj = {
            tierId: this.productTier.tierId,
            value: value
        };
        this.maxQuantityChangedEvent.emit(obj);
    }
}
