import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditLocationDialogComponent} from "./container/edit-location-dialog.component";
import {EditLocationDialogSandbox} from "./sandbox/edit-location-dialog.sandbox";
import {StoreModule} from "@ngrx/store";
import {REDUCER_NAME, reducer} from './sandbox/store/reducer';
import {EditLocationFormComponent} from './components/edit-location-form/edit-location-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditLocationDialogSandbox
    ],
    exports: [
        EditLocationDialogComponent
    ],
    declarations: [
        EditLocationFormComponent,
        EditLocationDialogComponent
    ],
    entryComponents: [
        EditLocationDialogComponent
    ]
})
export class EditLocationDialogModule {

}
