import {CurrencyEnum} from "../../../../../shared/enums/currency-enum";

export class UpdateCompanyInfoRequestModel {
    companyInfoId: number;
    companyCode: string;
    description: string;
    senderEmailAddress: string;
    senderEmailName: string;
    companyLogoAmazonFilePath: string;
    androidLogoAmazonFilePath: string;
    address: string;
    website: string;
    currency: CurrencyEnum;

    constructor(companyInfoId: number,
                companyCode: string,
                description: string,
                senderEmailAddress: string,
                senderEmailName: string,
                companyLogoAmazonFilePath: string,
                androidLogoAmazonFilePath: string,
                address: string,
                website: string,
                currency: CurrencyEnum) {
        this.companyInfoId = companyInfoId;
        this.companyCode = companyCode;
        this.description = description;
        this.senderEmailAddress = senderEmailAddress;
        this.senderEmailName = senderEmailName;
        this.companyLogoAmazonFilePath = companyLogoAmazonFilePath;
        this.androidLogoAmazonFilePath = androidLogoAmazonFilePath;
        this.address = address;
        this.website = website;
        this.currency = currency;
    }
}
