<app-loader class="tree-loader" *ngIf="!isTreeRendered">
    Loading...
</app-loader>

<ul class="mt--15px">
    <ng-container
        *ngTemplateOutlet="singleNodeTemplate; context: { $implicit: tree }"
    ></ng-container>
</ul>

<div *ngIf="treeType != 'CUSTOMER_OWNERS_TREE'" class="app-checkbox-wrapper">
    <app-checkbox
        (valueChanged)="onShowInactiveChanged($event)"
        [labelName]="'Show inactive options'"
        [checked]="showInactive"
    >
    </app-checkbox>
</div>

<ng-template #singleNodeTemplate let-tree>
    <mat-tree
        class="ml--5px"
        [treeControl]="treeControl"
        [dataSource]="dataSource"
    >
        <mat-tree-node
            *matTreeNodeDef="let node"
            matTreeNodePadding
        >
            <button
                class="displayNone"
                mat-icon-button
                disabled
            ></button>
            <li class="node-list-item" *ngIf="node.active">
                <div *ngIf="node.active" class="mat-tree-node">
                    <span
                        [ngClass]="{
                            'ml-20px mt-2px': !node.expendable && node.level == 0
                        }"
                        class="mat-tree-node mat-tree-node-child-element cursorPointer"
                        *ngIf="node.id != null"
                        (click)="selectNode(node)"
                        ><span
                            [className]="
                                selectedNode &&
                                selectedNode.id === node.id &&
                                selectedNode.type === node.type
                                    ? 'mat-element-bold'
                                    : ''
                            "
                            >{{ node.name }}</span
                        ></span
                    >
                </div>
                <div class="mat-tree-node">
                    <span
                        class="mat-tree-child-node-href mat-tree-node mat-tree-node-child-add cursorPointer"
                        *ngIf="node.id == null"
                        (click)="selectNode(node)"
                        >{{ node.name }}</span
                    >
                </div>
            </li>
            <li class="node-list-item" *ngIf="!node.active && showInactive">
                <div *ngIf="!node.active" class="mat-tree-node">
                    <span
                        class="mat-tree-node mat-tree-node-child-element-inactive cursorPointer"
                        *ngIf="node.id != null"
                        (click)="selectNode(node)"
                        ><span
                            [className]="
                                selectedNode &&
                                selectedNode.id === node.id &&
                                selectedNode.type === node.type
                                    ? 'mat-element-bold'
                                    : ''
                            "
                            >{{ node.name }}</span
                        ></span
                    >
                </div>
                <div class="mat-tree-node">
                    <span
                        class="mat-tree-child-node-href mat-tree-node mat-tree-node-child-add cursorPointer"
                        *ngIf="node.id == null"
                        (click)="selectNode(node)"
                        >{{ node.name }}</span
                    >
                </div>
            </li>
        </mat-tree-node>
        <mat-tree-node
            class="mb--25px"
            *matTreeNodeDef="let node; when: hasChild"
            matTreeNodePadding
        >
            <button
                [className]="
                    !node.active && showInactive
                        ? 'mat-tree-child-node cursorAuto'
                        : node.active
                        ? 'mat-tree-child-node cursorAuto'
                        : 'mat-tree-node-display mat-tree-child-node cursorAuto'
                "
                mat-icon-button
                [attr.atria-label]="'Tooggle' + 'node.name'"
            >
                <mat-icon
                    matTreeNodeToggle
                    *ngIf="
                        (node.type != 'ADD_NEW_ROLE' &&
                            node.type != 'ADD_NEW_PASS' &&
                            node.type != 'ADD_NEW_CATEGORY' &&
                            node.active) ||
                        (!node.active && showInactive)
                    "
                    class="cursorPointer"
                    [class]="
                        treeControl.isExpanded(node)
                            ? 'fas fa-chevron-down'
                            : 'fas fa-chevron-right'
                    "
                >
                </mat-icon>
            </button>
            <span
                class="mat-tree-node-parent-element"
                *ngIf="
                    node.type != 'ADD_NEW_ROLE' &&
                    node.type != 'ADD_NEW_PASS' &&
                    node.type != 'ADD_NEW_CATEGORY' &&
                    node.active
                "
                (click)="selectNode(node)"
                ><span
                    [className]="
                        selectedNode &&
                        selectedNode.id === node.id &&
                        selectedNode.type === node.type
                            ? 'mat-element-bold cursorPointer'
                            : 'cursorPointer'
                    "
                    >{{ node.name }}</span
                ></span
            >
            <span
                class="mat-tree-node-parent-element-inactive cursorPointer"
                *ngIf="
                    node.type != 'ADD_NEW_ROLE' &&
                    node.type != 'ADD_NEW_PASS' &&
                    node.type != 'ADD_NEW_CATEGORY' &&
                    !node.active &&
                    showInactive
                "
                (click)="selectNode(node)"
                ><span
                    [className]="
                        selectedNode &&
                        selectedNode.id === node.id &&
                        selectedNode.type === node.type
                            ? 'mat-element-bold'
                            : ''
                    "
                    >{{ node.name }}</span
                ></span
            >
            <span
                class="mat-tree-child-node-href mat-tree-node-parent-add cursorPointer"
                *ngIf="
                    node.type == 'ADD_NEW_ROLE' ||
                    node.type == 'ADD_NEW_CATEGORY' ||
                    node.type == 'ADD_NEW_PASS'
                "
                (click)="selectNode(node)"
                >{{ node.name }}</span
            >
        </mat-tree-node>
    </mat-tree>
</ng-template>
