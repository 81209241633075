import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HolidayResponseModel} from "../../../../../../../../../core/modules/rest/holiday/response/holiday-response.model";
import {HolidayFormSubmitDataModel} from "../../model/holiday-form-submit-data.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {DateTimeUtility} from "../../../../../../../../../shared/utils/date-time-utility";


@Component({
  selector: 'app-edit-holiday-form',
  templateUrl: './edit-holiday-form.component.html'
})
export class EditHolidayFormComponent implements OnChanges {

    @Input() editMode: boolean;
    @Input() holiday: HolidayResponseModel;

    @Output() submitEvent = new EventEmitter<HolidayFormSubmitDataModel>();
    @Output() closeEvent  = new EventEmitter<void>();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    yearOptions: DropdownOptionModel[];
    monthOptions: DropdownOptionModel[];
    dayOptions: DropdownOptionModel[];

    constructor() {

        this.yearOptions = this.getYearOptions();
        this.monthOptions = this.getMonthOptions();
        this.dayOptions = this.getDayOptions();

        this.form = new FormGroup({
            description: new FormControl(),
            year: new FormControl(),
            month: new FormControl(),
            day: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.holiday) {
            this.initializeFormData();
        }
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        let description: string = this.form.value.description;
        let year: number = this.form.value.year ? +this.form.value.year : null;
        let month: number = this.form.value.month ? +this.form.value.month : null;
        let day: number = this.form.value.day ? +this.form.value.day : null;

        if (!description || description.trim().length === 0) {
            this.hasError = true;
            this.errorMessage = "Please enter description";
            return;
        }

        if (!year || isNaN(year)) {
            this.hasError = true;
            this.errorMessage = "Please choose year";
            return;
        }

        if (!month || isNaN(month)) {
            this.hasError = true;
            this.errorMessage = "Please choose month";
            return;
        }

        if (!day || isNaN(day)) {
            this.hasError = true;
            this.errorMessage = "Please choose day";
            return;
        }

        this.submitEvent.emit({
            description: description,
            selectedYear: year,
            selectedMonth: month,
            selectedDay: day
        });
    }

    private getYearOptions(): DropdownOptionModel[] {

        const array: DropdownOptionModel[] = [];

        const now: Date = new Date();

        for (let i = -10; i < 10; i++) {
            array.push(new DropdownOptionModel((now.getFullYear() + i).toString(), (now.getFullYear() + i).toString()));
        }

        return array;
    }

    private getMonthOptions(): DropdownOptionModel[] {
        return [
            new DropdownOptionModel('January', '1'),
            new DropdownOptionModel('February', '2'),
            new DropdownOptionModel('March', '3'),
            new DropdownOptionModel('April', '4'),
            new DropdownOptionModel('May', '5'),
            new DropdownOptionModel('June', '6'),
            new DropdownOptionModel('July', '7'),
            new DropdownOptionModel('August', '8'),
            new DropdownOptionModel('September', '9'),
            new DropdownOptionModel('October', '10'),
            new DropdownOptionModel('November', '11'),
            new DropdownOptionModel('December', '12'),
        ];
    }

    private getDayOptions(): DropdownOptionModel[] {
        const array: DropdownOptionModel[] = [];

        for (let i = 1; i <= 31; i++) {
            array.push(new DropdownOptionModel(i.toString(), i.toString()));
        }

        return array;
    }

    private initializeFormData(): void {

        const now: Date = new Date();

        let description: string = null;
        let year: number = now.getFullYear();
        let month: number = now.getMonth() + 1;
        let day: number = now.getDate();

        if (this.editMode) {
            description = this.holiday.description;
            year = this.holiday.year;
            month = this.holiday.month;
            day = this.holiday.day;
        }

        this.form.patchValue({
            description: description,
            year: year,
            month: month,
            day: day
        });
    }
}

