import {HolidayResponseModel} from "../../../../../../core/modules/rest/holiday/response/holiday-response.model";

export class HolidayDataModel {
    holidayId: number;
    active: boolean;
    description: string;
    year: number;
    month: number;
    day: number;

    constructor(holiday: HolidayResponseModel) {
        this.holidayId = holiday.holidayId;
        this.active = holiday.active;
        this.description = holiday.description;
        this.year = holiday.year;
        this.month = holiday.month;
        this.day = holiday.day;
    }
}
