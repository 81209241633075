import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {GetOccurrencesRequestModel} from "./request/get-occurrences-request.model";
import {UpdateOccurrencesRequestModel} from "./request/update-occurrences-request.model";
import {EnableSalesRequestModel} from "./request/enable-sales-request.model";
import {DisableSalesRequestModel} from "./request/disable-sales-request.model";
import {DeactivateOccurrencesRequestModel} from "./request/deactivate-occurrences-request.model";
import {GetOrdersByOccurrenceIdsRequestModel} from "./request/get-orders-by-occurrence-ids-request.model";
import {OccurrenceOrdersResponseModel} from "./response/occurrence-orders-response.model";
import {ActivateOccurrencesRequestModel} from "./request/activate-occurrences-request.model";
import {OccurrenceTicketsResponseModel} from "./response/occurrence-tickets-response.model";
import {EmailOccurrenceManifestRequestModel} from "./request/email-occurrence-manifest-request.model";
import {OccurrencesDescriptorResponseModel} from "./response/occurrences-descriptor-response.model";

@Injectable()
export class OccurrenceRestService {

    constructor(private httpClient: HttpClient) {
    }

    getOccurrencesByProductAvailabilityIdsAndDateRange(request: GetOccurrencesRequestModel): Observable<OccurrencesDescriptorResponseModel> {
        return this.httpClient.post<OccurrencesDescriptorResponseModel>(environment.server_api_url + '/api/secured/occurrence/getOccurrencesByProductAvailabilityIdsAndDateRange', request);
    }

    getOrdersByOccurrenceIds(request: GetOrdersByOccurrenceIdsRequestModel): Observable<OccurrenceOrdersResponseModel[]> {
        return this.httpClient.post<OccurrenceOrdersResponseModel[]>(environment.server_api_url + '/api/secured/occurrence/getOrdersByOccurrenceIds', request);
    }

    getTicketsByOccurrenceId(occurrenceId: number): Observable<OccurrenceTicketsResponseModel> {
        return this.httpClient.get<OccurrenceTicketsResponseModel>(environment.server_api_url + '/api/secured/occurrence/getTicketsByOccurrenceId/' + occurrenceId);
    }

    generateUrlForPrintPdfOccurrenceManifest(occurrenceId: number): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/occurrence/generateUrlForPrintPdfOccurrenceManifest', occurrenceId, {responseType: 'text'});
    }

    emailOccurrenceManifest(request: EmailOccurrenceManifestRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/occurrence/sendEmailOccurrenceManifestToEmailAddress', request, {responseType: 'text'});
    }

    updateOccurrencesQuantity(request: UpdateOccurrencesRequestModel): Observable<void> {
        return this.httpClient.post<void>(environment.server_api_url + '/api/secured/occurrence/updateOccurrencesQuantity', request);
    }

    disableSales(request: DisableSalesRequestModel): Observable<void> {
        return this.httpClient.post<void>(environment.server_api_url + '/api/secured/occurrence/disableSales', request);
    }

    enableSales(request: EnableSalesRequestModel): Observable<void> {
        return this.httpClient.post<void>(environment.server_api_url + '/api/secured/occurrence/enableSales', request);
    }

    activateOccurrences(request: ActivateOccurrencesRequestModel): Observable<void> {
        return this.httpClient.post<void>(environment.server_api_url + '/api/secured/occurrence/activateOccurrences', request);
    }

    deactivateOccurrences(request: DeactivateOccurrencesRequestModel): Observable<void> {
        return this.httpClient.post<void>(environment.server_api_url + '/api/secured/occurrence/deactivateOccurrences', request);
    }

    doBulkCheckInMultipleOccurrences(occurrenceIds: number[]): Observable<boolean> {
        return this.httpClient.post<boolean>(environment.server_api_url + '/api/secured/occurrence/doBulkCheckInMultipleOccurrences', occurrenceIds);
    }
}
