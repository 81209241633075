
import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {BusResponseModel} from "../../../../../../../../core/modules/rest/bus/response/bus-response.model";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {Store} from "@ngrx/store";
import {BusRestService} from "../../../../../../../../core/modules/rest/bus/bus-rest.service";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {CreateBusRequestModel} from "../../../../../../../../core/modules/rest/bus/request/create-bus-request.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {UpdateBusRequestModel} from "../../../../../../../../core/modules/rest/bus/request/update-bus-request.model";


@Injectable()
export class EditBusDialogSandbox extends DialogSandbox {

    bus$: Observable<BusResponseModel> = this.store.select(reducer.bus_selector);

    constructor(appDialogsService: AppDialogsService,
                private busRestService: BusRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadBus(busId: number) {

        this.showLoader();

        this.busRestService.getById(busId).subscribe((bus: BusResponseModel) => {
            this.store.dispatch(new actions.SetBus(bus));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    createBus(number: number, description: string, capacity: number): Observable<CreateBaseResponseModel> {
        const request = new CreateBusRequestModel(number, description, capacity);

        return this.busRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while creating bus", error);
            }));
    }

    updateBus(busId: number, number: number, description: string, capacity: number): Observable<any> {
        const request = new UpdateBusRequestModel(busId, number, description, capacity);

        return this.busRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while updating bus", error);
            }));
    }
}
