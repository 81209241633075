<div class="dialog-section">
    <h2>Pass Details</h2>

    <ng-container *ngIf="!passOrderDetails || passOrderDetails.length === 0">
        <div class="placeholderText-emptySection">
            No Pass Info to Display
        </div>
    </ng-container>

    <ng-container *ngIf="passOrderDetails && passOrderDetails.length !== 0">
        <ng-container *ngFor="let passOrderDetail of passOrderDetails">
            <app-pass-detail-dialog-summary
                [passOrderDetail]="passOrderDetail"
                [editEnabled]="isEditEnabled(passOrderDetail.passOrderDetailId)"
                [hasEditPermission]="hasEditPermission"
                [hasCompletedEditPermission]="hasCompletedEditPermission"
                (saveClicked)="onSaveClicked($event)"
                (editClicked)="onEditClick($event)"
                (cancelClicked)="onCancelClick()"
                [ngClass]="[passOrderDetail.orderStatus === 'CANCELLED' ? 'canceled-details' : '']">
            </app-pass-detail-dialog-summary>
        </ng-container>
    </ng-container>
</div>
