import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {SubmitPassPricingDateRangesData} from "./components/edit-pass-pricing-date-ranges-dialog-form/edit-pass-pricing-date-ranges-dialog-form.component";
import {EditPassPricingDateRangesDialogSandbox} from "./sandbox/edit-pass-pricing-date-ranges-dialog.sandbox";
import {DropdownOptionModel} from "../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {PricingDateRangeFullResponseModel} from "../../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-full-response.model";
import {TierResponseModel} from "../../../../../../../core/modules/rest/tier/response/tier-response.model";

export class EditPassPricingDateRangesDialogData extends DialogData {

    isEditMode: boolean;
    passId: number;
    pricingDateRangeIds: number[];
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, isEditMode: boolean, passId: number, pricingDateRangeIds: number[]) {
        super();
        this.isEditMode = isEditMode;
        this.passId = passId;
        this.pricingDateRangeIds = pricingDateRangeIds;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-edit-pass-pricing-date-ranges-dialog',
    templateUrl: './edit-pass-pricing-date-ranges-dialog.component.html'
})
export class EditPassPricingDateRangesDialogComponent implements OnDestroy {

    isEditMode: boolean;
    passId: number;
    pricingDateRangeIds: number[];
    successCallbackFn: Function;

    pricingDateRanges$: Observable<PricingDateRangeFullResponseModel[]>;
    pickupLocations$: Observable<DropdownOptionModel[]>;
    dropoffLocations$: Observable<DropdownOptionModel[]>;
    tiers$: Observable<TierResponseModel[]>;

    constructor(@Inject(DialogData) data: EditPassPricingDateRangesDialogData, private sandbox: EditPassPricingDateRangesDialogSandbox) {

        this.isEditMode = data.isEditMode;
        this.passId = data.passId;
        this.pricingDateRangeIds = data.pricingDateRangeIds;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.pricingDateRangeIds);

        this.pricingDateRanges$ = this.sandbox.pricingDateRanges$;
        this.pickupLocations$ = this.sandbox.pickupLocations$;
        this.dropoffLocations$ = this.sandbox.dropoffLocations$;
        this.tiers$ = this.sandbox.tiers$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitPassPricingDateRangesData) {
        this.sandbox.updatePassPricingDateRanges(data).subscribe(() => {
            this.successCallbackFn(data.passId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
