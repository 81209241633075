<div class="category-and-product-dialog-content">
    <h1 class="mb-16px">Enable category</h1>
    <p class="mb-16px">You are about to re-enable a category, this action will add access to all products.</p>
    <div class="flex-container category-radio-option">
        <div class="radio-input-wrapper" (click)="setType(ALL_PRODUCTS)">
            <input type="radio" [checked]="type === ALL_PRODUCTS">
            <label>Enable all products</label>
        </div>
    </div>
    <div class="flex-container category-radio-option">
        <div class="radio-input-wrapper" (click)="setType(SOME_PRODUCTS)">
            <input type="radio" [checked]="type === SOME_PRODUCTS">
            <label>Enable some products</label>
        </div>
        <div>
            <app-multiselect-dropdown
                [disabled]="type !== SOME_PRODUCTS"
                [options]="products"
                [preSelectedValues]="selectedProducts"
                (changed)="onProductsSelected($event)"></app-multiselect-dropdown>
        </div>
    </div>
    <div class="flex-container category-radio-option">
        <div class="radio-input-wrapper" (click)="setType(NONE)">
            <input type="radio" [checked]="type === NONE">
            <label>Enable only category without any products in particular</label>
        </div>
    </div>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Enable</button>
    </div>
</div>
