import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { DropdownOptionModel } from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import { MechantResponseModel } from '../../../../../../../../../core/modules/rest/merchants/response/merchant-response.model';
import { AddAndroidDeviceInfoRemoteCommandDialogSandbox } from '../../sandbox/add-android-device-info-remote-command-dialog.sandbox';
import { AndroidDeviceInfoResponseModel } from '../../../../../../../../../core/modules/rest/android-device-info/response/android-device-info-response.model';
import { ConnectMerchantDialogFormSubmitDataModel } from '../../model/connect-merchant-account-dialog-form-submit-data.model';

@Component({
    selector: 'app-connect-merchant-form',
    templateUrl: './connect-merchant-form.component.html'
})
export class ConnectMerchantAndroidDeviceInfoDialogFormComponent implements OnInit {

    @Output() closeEvent = new EventEmitter<any>();
    @Output() submitEvent = new EventEmitter<ConnectMerchantDialogFormSubmitDataModel>();

    form: FormGroup;
    errorMessage: string;
    merchantsOptions: DropdownOptionModel[] = [];
    merchants: MechantResponseModel[];
    merchant: MechantResponseModel;
    androidInfoDevices: AndroidDeviceInfoResponseModel[];
    androidInfoDeviceOptions: DropdownOptionModel[] = [];
    selectedAndroidInfoDevices = [];

    constructor(private sandbox: AddAndroidDeviceInfoRemoteCommandDialogSandbox) {
        this.form = new FormGroup({
            merchant: new FormControl(null) 
        });
    }

    ngOnInit() {

        this.sandbox.loadMerchants().subscribe( res =>{
            this.merchants = res;
            if(this.merchants != null){
                this.merchants.forEach( merchant => {
                    this.merchantsOptions.push( new DropdownOptionModel( merchant.name, merchant.merchantId.toString() ) )
                    
                });
                this.sandbox.loadAndroidDevices().subscribe(res => {
                    this.androidInfoDevices = res;
                    this.androidInfoDevices.forEach( device => {
                        this.androidInfoDeviceOptions.push( new DropdownOptionModel( device.androidDeviceNumber, device.androidDeviceNumber ) )
                        
                    });
                });
            }
        })
  
    }

    onBack(){
        this.merchant = null;
        this.errorMessage = null;
    }

    onClose() {
        this.closeEvent.emit();
    }

    onSelectedCustomersChanged(values: string[]) {
        this.selectedAndroidInfoDevices = values;
    }

    onSubmit() {

        if (this.selectedAndroidInfoDevices.length < 1) {
            this.errorMessage = 'Please select Android devices';
            return;
        }

        this.errorMessage = null;

        let data: ConnectMerchantDialogFormSubmitDataModel = new ConnectMerchantDialogFormSubmitDataModel(
            Number(this.merchant),
            this.selectedAndroidInfoDevices
        );

        this.submitEvent.emit(data);
    }
}
