import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output  } from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {HeaderResponseModel} from '../../../../../../core/modules/rest/reporting/response/report-search-response.model';
import {CurrentSearchDataModel} from '../model/current-search-data.model';
import {SortByModel} from '../../../../../../shared/model/sort-by.model';
import {NO_RESULTS_ICON} from "../../../../../../core/services/app-properties.service";
import {PaymentMethodEnum} from "../../../../../../shared/enums/payment-method.enum";
import {getPaymentMethodLabel as PaymentMethodEnum_getPaymentMethodLabel } from "../../../../../../shared/enums/payment-method.enum";


const SORT_ASC = 'ASC';
const SORT_DESC = 'DESC';



@Component({
    selector: 'app-order-reports-table-wrapper',
    templateUrl: './order-reports-table-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReportsTableWrapperComponent implements OnInit, OnDestroy {

    @Input() currentSearchData: CurrentSearchDataModel;
    @Output() headerSortEvent: EventEmitter<SortByModel> = new EventEmitter<SortByModel>();
    @Output() showSummaryEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() generateUrlForExportToXlsxEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() scrollTable: EventEmitter<void> = new EventEmitter<void>();

    private formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });

    noResultImage = NO_RESULTS_ICON;

    private decimalPipe = new DecimalPipe('en');

    constructor() {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    onSortReport(header: HeaderResponseModel) {
        let newSortBy: SortByModel;

        if (!this.currentSearchData.sortBy) {
            newSortBy = new SortByModel(header.fieldName, SORT_ASC);
        } else if (this.currentSearchData.sortBy.fieldName !== header.fieldName) {
            newSortBy = new SortByModel(header.fieldName, SORT_ASC);
        } else {
            if (this.currentSearchData.sortBy.order === SORT_ASC) {
                newSortBy = new SortByModel(header.fieldName, SORT_DESC);
            } else {
                newSortBy = new SortByModel(header.fieldName, SORT_ASC);
            }
        }

        // this.generateReport();
        this.headerSortEvent.emit(newSortBy);
    }

    onShowSummary(orderId: number) {
        this.showSummaryEvent.emit(orderId);
    }

    onGenerateUrlForExportToXlsx() {
        this.generateUrlForExportToXlsxEvent.emit();
    }

    getDisplayMessage(): string {
        if (this.currentSearchData.rows.length === 0) {
            return '';
        }

        let displayMessage: string = this.decimalPipe.transform(this.currentSearchData.approximateNumberOfResults) + ' Items';

        if (this.currentSearchData.groupSearch) {
            displayMessage += ' (Results grouped)';
        }

        return displayMessage;
    }

    getCellValueForPrintingCurrency(value: number): string {
        return this.formatter.format(value);
    }

    getPaymentMethodLabel(pm: PaymentMethodEnum): string {
        return PaymentMethodEnum_getPaymentMethodLabel(pm);
    }

    onScrollTable() {
        this.scrollTable.emit();
    }
}
