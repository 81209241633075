<div class="sidebar_container">

    <div class="search_wrapper">
        <div class="padding-row">
            <app-search
                (searchTextChanged)="onSearchTextChanged($event)">
            </app-search>
        </div>
    </div>
    <ul class="accordion bordered">
        <app-order-reports-sidebar-report-group
            *ngFor="let reportGroup of reportGroups"
            [reportGroup]="reportGroup"
            [reportItemsSearchText]="reportItemsSearchText"
            [activeReportGroups]="activeReportGroups"
            (dragStartEvent)="onDragStart($event)"
            (dragEndEvent)="onDragEnd()"
            (changeReportGroupActivityStatusEvent)="onChangeReportGroupActivityStatus($event)">
        </app-order-reports-sidebar-report-group>
    </ul>
    <app-checkbox
        [labelName]="'Show payment summary'"
        (valueChanged)="onShowPaymentSummaryChanged($event)"
        [checked]="showPaymentSummary"
        [disabled]="showPaymentSummaryDisabled">
    </app-checkbox>

</div>
