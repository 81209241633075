
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { take } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { DialogSandbox } from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import { AppDialogsService } from "../../../../../../../../core/services/app-dialogs.service";
import { CategoryRestService } from "../../../../../../../../core/modules/rest/category/category-rest.service";
import { select, Store } from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import { CategoryResponseModel } from "../../../../../../../../core/modules/rest/category/response/category-response.model";
import { MultiselectDropdownOptionModel } from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import { CostRestService } from "../../../../../../../../core/modules/rest/cost/cost-rest.service";
import { ProductRestService } from "../../../../../../../../core/modules/rest/product/product-rest.service";
import { PassRestService } from "../../../../../../../../core/modules/rest/pass/pass-rest.service";
import { CostMappingsResponseModel } from "../../../../../../../../core/modules/rest/cost/response/cost-mappings-response.model";
import { ProductSimpleResponseModel } from "../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import { PassSimpleResponseModel } from "../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import { SubmitCostMappingsData } from "../components/mapping-cost-dialog-form/mapping-cost-dialog-form.component";
import { MultiselectDropdownOptionActiveModel } from '../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option-active.model';

@Injectable()
export class MappingCostDialogSandbox extends DialogSandbox {

    loading$: Observable<boolean> = this.store.pipe(select(reducer.loading_selector));
    categories$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.categories_selector));
    mappedCategories$: Observable<number[]> = this.store.pipe(select(reducer.mappedCategories_selector));
    products$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.products_selector));
    mappedProducts$: Observable<number[]> = this.store.pipe(select(reducer.mappedProducts_selector));
    passes$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.passes_selector));
    mappedPasses$: Observable<number[]> = this.store.pipe(select(reducer.mappedPasses_selector));

    constructor(appDialogsService: AppDialogsService,
        private costRestService: CostRestService,
        private categoryRestService: CategoryRestService,
        private productRestService: ProductRestService,
        private passRestService: PassRestService,
        private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(costId: number) {

        this.store.dispatch(new actions.SetLoading(true));

        observableCombineLatest(this.costRestService.getCostMappings(costId), this.categoryRestService.getAll(), this.productRestService.getAll(), this.passRestService.getAll()).pipe(
            take(1))
            .subscribe(([costMappings, categories, products, passes]: [CostMappingsResponseModel, CategoryResponseModel[], ProductSimpleResponseModel[], PassSimpleResponseModel[]]) => {
                this.store.dispatch(new actions.SetCategories(categories.map(c => new MultiselectDropdownOptionActiveModel(c.description, c.categoryId.toString(), c.active))));
                this.store.dispatch(new actions.SetMappedCategories(costMappings.categoryCostMappings.map(ccm => ccm.categoryId).filter(ccm => categories.some(c => c.categoryId == ccm))));
                this.store.dispatch(new actions.SetProducts(products.map(p => new MultiselectDropdownOptionActiveModel(p.description, p.productId.toString(), p.active))));
                this.store.dispatch(new actions.SetMappedProducts(costMappings.productCostMappings.map(pcm => pcm.productId).filter(pcm => products.some(p => p.productId == pcm))));
                this.store.dispatch(new actions.SetPasses(passes.map(p => new MultiselectDropdownOptionActiveModel(p.description, p.passId.toString(), p.active))));
                this.store.dispatch(new actions.SetMappedPasses(costMappings.passCostMappings.map(pcm => pcm.passId).filter(pcm => passes.some(p => p.passId == pcm))));

                this.store.dispatch(new actions.SetLoading(false));
            },
                () => this.store.dispatch(new actions.SetLoading(false)));
    }

    updateCostMappings(data: SubmitCostMappingsData): Observable<any> {

        return this.costRestService.updateCostMappings({
            costId: data.costId,
            categoryIds: data.categoryIds,
            productIds: data.productIds,
            passIds: data.passIds
        });
    }
}
