import { Component, Input, OnInit, Output  } from '@angular/core';


@Component({
    selector   : 'app-dialog-summary-editable-fields-new',
    templateUrl: './editable-fields-dialog-summary.component.html'
})
export class EditableFieldsComponentDialogSummaryComponent implements OnInit {

    @Input() labelName;
    @Input() fieldValue   = '&nbsp;';
    @Input() errorMessage = '&nbsp;';

    enableEditing = false;
    // TODO
    @Output() valueChanged;

    constructor() { }

    ngOnInit() {
    }

    editDynamicInputField() {
        this.enableEditing = true;
    }
}
