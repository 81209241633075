import * as actions from './actions';
import {FavoriteResponseModel} from "../../../../../../core/modules/rest/favorite/response/favorite-response.model";
import {createFeatureSelector, createSelector} from "@ngrx/store";

export const REDUCER_NAME = 'dialog_edit-favorite';

export interface State {
    favorite: FavoriteResponseModel;
    descriptionAvailable: boolean;
}

const initialState: State = {
    favorite: null,
    descriptionAvailable: true
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const favorite_selector = createSelector(state_selector, (state: State) => state.favorite);

export const descriptionAvailable_selector = createSelector(state_selector, (state: State) => state.descriptionAvailable);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {

    switch (action.type) {
        case actions.RESET_STATE_ACTION:
            return initialState;

        case actions.SET_FAVORITE:
            return {...state, favorite: action.favorite};

        case actions.SET_DESCRIPTION_AVAILABLE:
            return {...state, descriptionAvailable: action.descriptionAvailable};

        default:
            return state;

    }
}
