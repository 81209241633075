
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {PricingDateRangeRestService} from "../../../../../../../../core/modules/rest/pricing-date-range/pricing-date-range-rest.service";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {PricingDateRangeFullResponseModel} from "../../../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-full-response.model";
import {
    SubmitPassPricingDateRangesData,
    SubmitPassTierData
} from "../components/edit-pass-pricing-date-ranges-dialog-form/edit-pass-pricing-date-ranges-dialog-form.component";
import {DropdownOptionModel} from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {LocationRestService} from "../../../../../../../../core/modules/rest/location/location-rest.service";
import {TierRestService} from "../../../../../../../../core/modules/rest/tier/tier-rest.service";
import {TierResponseModel} from "../../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {TiersResponseModel} from "../../../../../../../../core/modules/rest/tier/response/tiers-response.model";
import {LocationsResponseModel} from "../../../../../../../../core/modules/rest/location/response/locations-response.model";
import {CreateUpdatePassTierRequestModel} from "../../../../../../../../core/modules/rest/pricing-date-range/request/create-update-pass-tier-request.model";
import moment from 'moment';

@Injectable()
export class EditPassPricingDateRangesDialogSandbox extends DialogSandbox {

    pricingDateRanges$: Observable<PricingDateRangeFullResponseModel[]> = this.store.pipe(select(reducer.pricingDateRanges_selector));
    pickupLocations$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.pickupLocations_selector));
    dropoffLocations$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.dropoffLocations_selector));
    tiers$: Observable<TierResponseModel[]> = this.store.pipe(select(reducer.tiers_selector));

    constructor(appDialogsService: AppDialogsService,
                private pricingDateRangeRestService: PricingDateRangeRestService,
                private locationRestService: LocationRestService,
                private tierRestService: TierRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(pricingDateRangeIds: number[]) {

        observableCombineLatest(this.pricingDateRangeRestService.getPricingDateRangesFullByPricingDateRangeIds(pricingDateRangeIds), this.locationRestService.getAllActive(), this.tierRestService.getAllActive()).pipe(
            take(1))
            .subscribe(([pricingDateRangesFull, locations, tiers]: [PricingDateRangeFullResponseModel[], LocationsResponseModel, TiersResponseModel]) => {
                this.store.dispatch(new actions.SetPricingDateRanges(pricingDateRangesFull));
                this.store.dispatch(new actions.SetPickupLocations(locations.locations.map(l => new DropdownOptionModel(l.description, l.locationId.toString()))));
                this.store.dispatch(new actions.SetDropoffLocations(locations.locations.map(l => new DropdownOptionModel(l.description, l.locationId.toString()))));
                this.store.dispatch(new actions.SetTiers(tiers.tiers));
            });
    }

    updatePassPricingDateRanges(data: SubmitPassPricingDateRangesData): Observable<any> {

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // Check if create or edit
        if (data.isEditMode) {

            // Check if single edit or multiple edit
            if (data.pricingDateRangeIds.length > 1) {
                return this.pricingDateRangeRestService.updatePricingDateRanges({
                    pricingDateRangeIds: data.pricingDateRangeIds,
                    productId: null,
                    passId: data.passId,
                    startDateMS: data.startDate ? moment.parseZone(data.startDate, timeZone).valueOf() : null,
                    startDateChanged: data.startDateChanged,
                    endDateMS: data.endDate ? moment.parseZone(data.endDate, timeZone).valueOf() : null,
                    endDateChanged: data.endDateChanged,
                    startTime: data.startTime,
                    startTimeChanged: data.startTimeChanged,
                    endTime: data.endTime,
                    endTimeChanged: data.endTimeChanged,
                    fromLocationId: data.fromLocationId,
                    fromLocationIdChanged: data.fromLocationIdChanged,
                    toLocationId: data.toLocationId,
                    toLocationIdChanged: data.toLocationIdChanged
                });
            } else {
                return this.pricingDateRangeRestService.updatePricingDateRange({
                    pricingDateRangeId: data.pricingDateRangeIds[0],
                    productId: null,
                    passId: data.passId,
                    startDateMS: data.startDate ? moment.parseZone(data.startDate, timeZone).valueOf() : null,
                    endDateMS: data.endDate ? moment.parseZone(data.endDate, timeZone).valueOf() : null,
                    startTime: data.startTime,
                    endTime: data.endTime,
                    fromLocationId: data.fromLocationId,
                    toLocationId: data.toLocationId,
                    productTiers: null,
                    passTiers: data.passTiers.map((pt: SubmitPassTierData) => new CreateUpdatePassTierRequestModel(pt.tierId, pt.cost))
                });
            }
        } else {
            return this.pricingDateRangeRestService.createPricingDateRange({
                productId: null,
                passId: data.passId,
                startDateMS: data.startDate ? moment.parseZone(data.startDate, timeZone).valueOf() : null,
                endDateMS: data.endDate ? moment.parseZone(data.endDate, timeZone).valueOf() : null,
                startTime: data.startTime,
                endTime: data.endTime,
                fromLocationId: data.fromLocationId,
                toLocationId: data.toLocationId,
                productTiers: null,
                passTiers: data.passTiers.map((pt: SubmitPassTierData) => new CreateUpdatePassTierRequestModel(pt.tierId, pt.cost))
            });
        }
    }
}
