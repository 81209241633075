

<app-accordion-item>
    <div class="accordion--item--header accordionItemHeader">
        <h5>{{reportGroup.name}}</h5>
    </div>

    <div class="accordion--item--body accordionItemBody">
        <ul class="reportItemsList">
            <li class="reportItemLi"
                *ngFor="let reportItem of reportGroup.items">
                <app-order-reports-sidebar-report-item
                    [reportItem]="reportItem"
                    (dragStartEvent)="onDragStart($event)"
                    (dragEndEvent)="onDragEnd()">
                </app-order-reports-sidebar-report-item>
            </li>
        </ul>
    </div>
</app-accordion-item>
