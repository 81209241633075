import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {KioskConfigurationResponseModel} from "../../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-response.model";
import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {EditKioskConfigurationDialogFormSubmitData} from "../../model/edit-kiosk-configuration-dialog-form-submit-data.model";

@Component({
    selector: 'app-edit-kiosk-configuration-dialog-content',
    templateUrl: './edit-kiosk-configuration-dialog-content.component.html'
})
export class EditKioskConfigurationDialogContentComponent implements OnInit, OnChanges {

    @Input() kioskConfiguration: KioskConfigurationResponseModel;
    @Input() pickupLocations: LocationResponseModel[];
    @Input() editMode: boolean;

    @Output() submitEvent = new EventEmitter<EditKioskConfigurationDialogFormSubmitData>();
    @Output() closeEvent  = new EventEmitter<void>();

    form: FormGroup;

    pickupLocationOptions: DropdownOptionModel[];

    constructor() {
    }

    ngOnInit() {

        let description: string;
        let pickupLocationId: number;

        if (this.editMode) {
            description = this.kioskConfiguration.description;
            pickupLocationId = this.kioskConfiguration.pickupLocationId;
        }

        this.form = new FormGroup({
            description: new FormControl(description, Validators.required),
            pickupLocationId: new FormControl(pickupLocationId, Validators.required)
        });
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.editMode && this.form && changes.kioskConfiguration) {

            let kioskConfiguration: KioskConfigurationResponseModel = this.kioskConfiguration;

            this.form.patchValue({
                description: kioskConfiguration.description,
                pickupLocationId: kioskConfiguration.pickupLocationId
            });
        }

        if (changes.pickupLocations) {

            let pickupLocations: LocationResponseModel[] = this.pickupLocations;

            this.pickupLocationOptions = pickupLocations.map( (location: LocationResponseModel) => {
                return new DropdownOptionModel(location.description, location.locationId.toString());
            });
        }

    }

    onFormSubmit() {

        let data: EditKioskConfigurationDialogFormSubmitData = new EditKioskConfigurationDialogFormSubmitData(
            this.form.value.description,
            Number(this.form.value.pickupLocationId)
        );

        this.submitEvent.emit(data);
    }
}
