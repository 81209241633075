import {CurrencyEnum} from "../../../../../../../../shared/enums/currency-enum";

export class CompanyInfoFormSubmitDataModel {
    companyCode: string;
    description: string;
    emailInfo: string;
    authorizeDescription: string;
    ticketBackgroundImagePath: string;
    androidLogoImagePath: string;
    address: string;
    website: string;
    currency: CurrencyEnum;

    constructor(
        companyCode: string,
        description: string,
        emailInfo: string,
        authorizeDescription: string,
        ticketBackgroundImagePath: string,
        androidLogoImagePath: string,
        address: string,
        website: string,
        currency: CurrencyEnum) {

        this.companyCode = companyCode;
        this.description = description;
        this.emailInfo = emailInfo;
        this.authorizeDescription = authorizeDescription;
        this.ticketBackgroundImagePath = ticketBackgroundImagePath;
        this.androidLogoImagePath = androidLogoImagePath;
        this.address = address;
        this.website = website;
        this.currency = currency;
    }
}
