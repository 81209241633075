

<nav class="navigation_content">
    <div class="primaryNav_wrapper">
        <div class="primaryNav_container">

            <div class="grid-x grid-padding-x">
                <!-- Remove Comments once HISTORY section enabled  -->
                <!-- <div class="columns medium-3 large-2 empty-cell">
                        &nbsp;
                </div> -->

                <ng-container
                    *ngFor="let primaryTab of primaryNavTabsList; let i = index">
                    <div class="primaryNavTab_wrapper cell medium-3 large-2"
                         *ngIf="!primaryTab.hide"
                         [ngClass]='{"activePrimNav": primaryTab.active, "end": i==2}'
                         (click)='switchPrimaryNavTab(primaryTab)'>

                        <a class="primaryNav_tabContainer"
                            >
                            <div class="primaryNavIcon_container">
                                <i class="primaryNavIcon fal {{primaryTab.icon}}"></i>
                            </div>

                            <div class="primaryNavTabLabel">
                                {{primaryTab.value}}
                            </div>
                        </a>
                    </div>
                </ng-container>
            </div>
        </div>
    </div> <!-- Primary Navigation :: END -->




    <!-- Secondary Navigation :: BEGIN -->
    <div class="secondaryNav_wrapper">
        <div class="secondaryNav_container">

            <!-- History Section--><!--<app-secondary-nav-history></app-secondary-nav-history>-->

            <div class="row-padding">
                <app-secondary-nav-purchase
                    appCloseNavDirective
                    (navClicked)="closeNav()"
                    *ngIf='activePrimNavTab?.name == purchaseRoute'>
                </app-secondary-nav-purchase>

                <app-secondary-nav-reports
                    appCloseNavDirective
                    (navClicked)="closeNav()"
                    *ngIf='activePrimNavTab?.name == reportsRoute'>
                </app-secondary-nav-reports>

                <app-secondary-nav-management
                    appCloseNavDirective
                    (navClicked)="closeNav()"
                    *ngIf='activePrimNavTab?.name == managementRoute'>
                </app-secondary-nav-management>
            </div>
        </div>
    </div><!-- Secondary Navigation :: END -->
</nav>
