<div class="dialog-section">
    <h2>Payment</h2>

    <div class="placeholderText-emptySection"
         *ngIf="orderPaymentsDialogData === null || orderPaymentsDialogData === undefined || orderPaymentsDialogData.length === 0">
        No Payment Info to Display
    </div>

    <div class="summary-general-info-wrapper summary-payment-info-wrapper"
         *ngIf="!(orderPaymentsDialogData === null || orderPaymentsDialogData === undefined || orderPaymentsDialogData.length === 0)">
        <ng-container *ngFor="let orderPayment of orderPaymentsDialogData; let i = index">
            <div class="table-wrapper">
                <i class="fas fa-caret-right table-arrow"
                   *ngIf="isCreditCardPaymentMethod(orderPayment.paymentMethod) || isPostBillPaymentMethod(orderPayment.paymentMethod)"
                   [ngClass]="{'down':orderPayment.opened}"
                   (click)="onToggle(orderPayment)"></i>
                <table (click)="onToggle(orderPayment)">
                    <thead>
                    <tr>
                        <th>Payment Date/Time</th>
                        <th>Amount</th>
                        <th *ngIf="orderStatus === ORDER_STATUS_VOID || orderPaymentEdited(orderPayment)">Refunded</th>
                        <th *ngIf="orderStatus === ORDER_STATUS_VOID || orderPaymentEdited(orderPayment)">Revenue</th>
                        <th>Method</th>
                        <th>Payment Status</th>
                        <th *ngIf="isCreditCardPaymentMethod(orderPayment.paymentMethod)">IP address</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{orderPayment.paymentDateTimeFriendly}}</td>
                        <td>{{getCellValueForPrintingCurrency(orderPayment.amount)}}</td>
                        <td *ngIf="orderStatus === ORDER_STATUS_VOID || orderPaymentEdited(orderPayment)">{{getCellValueForPrintingCurrency(orderPayment.refundedAmount)}}</td>
                        <td *ngIf="orderStatus === ORDER_STATUS_VOID || orderPaymentEdited(orderPayment)">{{getCellValueForPrintingCurrency(orderPayment.revenue)}}</td>
                        <td>{{getPaymentMethodDescription(orderPayment)}}</td>
                        <td>{{orderPayment.paymentStatusDescription}}</td>
                        <td *ngIf="isCreditCardPaymentMethod(orderPayment.paymentMethod)">{{ipAddress}}</td>
                    </tr>
                    </tbody>
                </table>
                <ng-container *ngIf="isCreditCardPaymentMethod(orderPayment.paymentMethod) && orderPayment.opened">
                    <div class="children-table-container">
                        <table>
                            <thead>
                            <tr>
                                <th>Payment Date/Time</th>
                                <th>Amount</th>
                                <th>Type</th>
                                <th>Transaction ID</th>
                                <th>Reference ID</th>
                                <th>Message</th>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container *ngFor="let orderPaymentDetail of orderPayment.orderPaymentDetails">
                                <tr>
                                    <td>{{orderPaymentDetail.requestDateTimeFriendly ? orderPaymentDetail.requestDateTimeFriendly : 'N/A'}}</td>
                                    <td>{{getCellValueForPrintingCurrency(orderPaymentDetail.amount)}}</td>
                                    <td>{{orderPaymentDetail.transactionTypeFriendly}}</td>
                                    <td>{{orderPaymentDetail.requestId}}</td>
                                    <td>{{orderPaymentDetail.merchantRefCode}}</td>
                                    <td>{{orderPaymentDetail.message}}</td>
                                </tr>
                            </ng-container>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
                <ng-container *ngIf="isPostBillPaymentMethod(orderPayment.paymentMethod) && orderPayment.opened">
                    <div class="children-table-container">
                        <table>
                            <thead>
                            <tr>
                                <th>Amount</th>
                                <th>Type</th>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container
                                *ngFor="let orderPaymentPostBillDetail of orderPayment.orderPaymentPostBillDetails">
                                <tr>
                                    <td>{{getCellValueForPrintingCurrency(orderPaymentPostBillDetail.amount)}}</td>
                                    <td>{{orderPaymentPostBillDetail.postBillTypeDescription}}</td>
                                </tr>
                            </ng-container>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div class="mt-20px" *ngIf="discountDialogData.length > 0">
        <h2>Discounts</h2>

        <div class="summary-general-info-wrapper summary-payment-info-wrapper"
             *ngIf="!(discountDialogData === null || discountDialogData === undefined || discountDialogData.length === 0)">
            <ng-container *ngFor="let discount of discountDialogData; let i = index">
                <div class="table-wrapper">
                    <table>
                        <thead>
                        <tr>
                            <th>Cost Description</th>
                            <th>Cost Amount</th>
                            <th>Discount Code</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{discount.costDescription}}</td>
                            <td>{{discount.costAmount}}</td>
                            <td>{{discount.discountCode}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>
    </div>
</div>
