import {UpdateCustomFieldRequestModel} from "./update-custom-field-request.model";

export class UpdateCustomFieldsRequestModel {

    customFields: UpdateCustomFieldRequestModel[];

    constructor(customFields: UpdateCustomFieldRequestModel[]) {
        this.customFields = customFields;
    }
}
