
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {mergeMap, map, take, catchError} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {HttpErrorModel} from "../../../../../../core/modules/rest/http-error.model";
import {RootSandbox} from "../../../../../../core/store/root.sandbox";
import {ReportingRestService} from "../../../../../../core/modules/rest/reporting/reporting-rest.service";
import {ProductRestService} from "../../../../../../core/modules/rest/product/product-rest.service";
import {ProductSimpleResponseModel} from "../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {DateTimeDescriptor} from "../../../../../../shared/model/date-time-descriptor.model";
import {SortByRequestModel} from "../../../../../../core/modules/rest/reporting/request/sort-by-request.model";
import {SortByModel} from "../../../../../../shared/model/sort-by.model";
import {CommissionReportRequestModel} from "../../../../../../core/modules/rest/reporting/financial/commission-report/request/commission-report-request.model";
import {CommissionReportResponseModel} from "../../../../../../core/modules/rest/reporting/financial/commission-report/response/commission-report-response.model";
import {CommissionReportCurrentSearchDataModel} from "../model/commission-report-current-search-data.model";
import * as fromReducer from './store/reducer';
import * as fromActions from './store/actions';
import {RoleResponseModel} from "../../../../../../core/modules/rest/role/response/role-response.model";
import {UserResponseModel} from "../../../../../../core/modules/rest/user/response/user-response.model";
import {RoleRestService} from "../../../../../../core/modules/rest/role/role-rest.service";
import {UserRestService} from "../../../../../../core/modules/rest/user/user-rest.service";
import {UsersResponseModel} from "../../../../../../core/modules/rest/user/response/users-response.model";
import {RolesResponseModel} from "../../../../../../core/modules/rest/role/response/roles-response.model";
import {PassSimpleResponseModel} from "../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {PassRestService} from "../../../../../../core/modules/rest/pass/pass-rest.service";
import {FavoriteTypeEnum} from "../../../../../../shared/enums/favorite-type.enum";
import {FavoritesResponseModel} from "../../../../../../core/modules/rest/favorite/response/favorites-response.model";
import {FavoriteRestService} from "../../../../../../core/modules/rest/favorite/favorite-rest.service";
import {FavoriteResponseModel} from "../../../../../../core/modules/rest/favorite/response/favorite-response.model";
import * as moment from "moment";
import {ReportSearchRequestModel} from "../../../../../../core/modules/rest/reporting/request/report-search-request.model";
import {FilterExpressionRequestModel} from "../../../../../../core/modules/rest/reporting/request/filter-expression-request.model";
import {BaseExpressionRequestModel} from "../../../../../../core/modules/rest/reporting/request/base-expression-request.model";
import {FieldRequestModel} from "../../../../../../core/modules/rest/reporting/request/field-request.model";
import {GroupByRequestModel} from "../../../../../../core/modules/rest/reporting/request/group-by-request.model";
import {OperatorExpressionRequestModel} from "../../../../../../core/modules/rest/reporting/request/operator-expression-request.model";

@Injectable()
export class CommissionReportSandbox {

    loadingFavorites$: Observable<boolean> = this.store.select(fromReducer.loadingFavorites_selector);
    favorites$: Observable<FavoriteResponseModel[]> = this.store.select(fromReducer.favorites_selector);
    selectedFavoriteId$: Observable<number> = this.store.select(fromReducer.selectedFavoriteId_selector);
    favoriteCreatedUpdated$: Observable<boolean> = this.store.select(fromReducer.favoriteCreatedUpdated_selector);
    loadingRoles$: Observable<boolean> = this.store.select(fromReducer.loadingRoles_selector);
    roles$: Observable<RoleResponseModel[]> = this.store.select(fromReducer.roles_selector);
    loadingUsers$: Observable<boolean> = this.store.select(fromReducer.loadingUsers_selector);
    users$: Observable<UserResponseModel[]> = this.store.select(fromReducer.users_selector);
    usersForSelectedRoles$: Observable<UserResponseModel[]> = this.store.select(fromReducer.usersForSelectedRoles_selector);
    loadingPasses$: Observable<boolean> = this.store.select(fromReducer.loadingPasses_selector);
    passes$: Observable<PassSimpleResponseModel[]> = this.store.select(fromReducer.passes_selector);
    loadingProducts$: Observable<boolean> = this.store.select(fromReducer.loadingProducts_selector);
    products$: Observable<ProductSimpleResponseModel[]> = this.store.select(fromReducer.products_selector);
    selectedStartDate$: Observable<moment.Moment> = this.store.select(fromReducer.selectedStartDate_selector);
    selectedEndDate$: Observable<moment.Moment> = this.store.select(fromReducer.selectedEndDate_selector);
    selectedRoleIds$: Observable<number[]> = this.store.select(fromReducer.selectedRoleIds_selector);
    selectedUserIds$: Observable<number[]> = this.store.select(fromReducer.selectedUserIds_selector);
    selectedPassIds$: Observable<number[]> = this.store.select(fromReducer.selectedPassIds_selector);
    selectedProductIds$: Observable<number[]> = this.store.select(fromReducer.selectedProductIds_selector);
    currentSearchData$: Observable<CommissionReportCurrentSearchDataModel> = this.store.select(fromReducer.currentSearchData_selector);
    currentSearchDataSortBy$: Observable<SortByModel> = this.store.select(fromReducer.currentSearchDataSortBy_selector);

    constructor(private store: Store<fromReducer.State>, private rootSandbox: RootSandbox, private favoriteRestService: FavoriteRestService,
                private roleRestService: RoleRestService, private userRestService: UserRestService,
                private passRestService: PassRestService, private productRestService: ProductRestService,
                private reportingRestService: ReportingRestService) {
    }

    loadData() {

        this.loadAllCommissionReportFavoritesByUser(null, false);

        this.store.dispatch(new fromActions.UpdateRolesLoader({loadingRoles: true}));
        this.roleRestService.getAll()
            .subscribe((rolesResponse: RolesResponseModel) => {
                this.store.dispatch(new fromActions.UpdateRoles({roles: rolesResponse.roles}));
                this.store.dispatch(new fromActions.UpdateRolesLoader({loadingRoles: false}));
            },
            () => this.store.dispatch(new fromActions.UpdateRolesLoader({loadingRoles: false})));

        this.store.dispatch(new fromActions.UpdateUsersLoader({loadingUsers: true}));
        this.userRestService.getAllSortedByDisplayName()
            .subscribe((usersResponse: UsersResponseModel) => {
                this.store.dispatch(new fromActions.UpdateUsers({users: usersResponse.users}));
                this.store.dispatch(new fromActions.UpdateUsersForSelectedRoles({usersForSelectedRoles: usersResponse.users}));
                this.store.dispatch(new fromActions.UpdateUsersLoader({loadingUsers: false}));
            },
            () =>  this.store.dispatch(new fromActions.UpdateUsersLoader({loadingUsers: false})));

        this.store.dispatch(new fromActions.UpdatePassesLoader({loadingPasses: true}));
        this.passRestService.getAll()
            .subscribe((passes: PassSimpleResponseModel[]) => {
                this.store.dispatch(new fromActions.UpdatePasses({passes: passes}));
                this.store.dispatch(new fromActions.UpdatePassesLoader({loadingPasses: false}));
            },
            () => this.store.dispatch(new fromActions.UpdatePassesLoader({loadingPasses: false})));

        this.store.dispatch(new fromActions.UpdateProductsLoader({loadingProducts: true}));
        this.productRestService.getAll()
            .subscribe((products: ProductSimpleResponseModel[]) => {
                this.store.dispatch(new fromActions.UpdateProducts({products: products}));
                this.store.dispatch(new fromActions.UpdateProductsLoader({loadingProducts: false}));
            },
            () => this.store.dispatch(new fromActions.UpdateProductsLoader({loadingProducts: false})));
    }

    loadAllCommissionReportFavoritesByUser(favoriteId: number, favoriteCreatedUpdated: boolean) {

        this.store.dispatch(new fromActions.UpdateFavoritesLoader({loadingFavorites: true}));
        this.favoriteRestService.getActiveFavoritesByCurrentUserAndFavoriteType(FavoriteTypeEnum.COMMISSION_REPORT)
            .subscribe((favoritesResponse: FavoritesResponseModel) => {
                this.store.dispatch(new fromActions.UpdateFavorites({favorites: favoritesResponse.favorites, favoriteId: favoriteId, favoriteCreatedUpdated: favoriteCreatedUpdated}));
                this.store.dispatch(new fromActions.UpdateFavoritesLoader({loadingFavorites: false}));
            },
            () => this.store.dispatch(new fromActions.UpdateFavoritesLoader({loadingFavorites: false})));
    }

    getAllUsersByRoleIds(roleIds: number[]) {

        this.store.dispatch(new fromActions.UpdateUsersLoader({loadingUsers: true}));
        this.users$
            .subscribe((users: UserResponseModel[]) => {
                this.store.dispatch(new fromActions.UpdateUsersForSelectedRoles({usersForSelectedRoles: users.filter(u => roleIds.length === 0 || roleIds.indexOf(u.roleId) > -1)}));
                this.store.dispatch(new fromActions.UpdateUsersLoader({loadingUsers: false}));
            },
            () =>  this.store.dispatch(new fromActions.UpdateUsersLoader({loadingUsers: false})));
    }

    resetState() {
        this.store.dispatch(new fromActions.ResetState());
        this.loadData();
    }

    clearSearchData(clearSelectedFavorite: boolean) {
        if (clearSelectedFavorite) {
            this.store.dispatch(new fromActions.UpdateSelectedFavoriteId({favoriteId: null}));
        }
        this.store.dispatch(new fromActions.ClearSearchData());
    }

    updateFilter(selectedStartDate: moment.Moment, selectedEndDate: moment.Moment, selectedRoleIds: number[], selectedUserIds: number[], selectedPassIds: number[], selectedProductIds: number[], isFavoriteSelected: boolean) {
        // If filter changed by selecting favorite, do not set filter fields again
        if (!isFavoriteSelected) {
            this.store.dispatch(new fromActions.UpdateSelectedStartDate({selectedStartDate: selectedStartDate}));
            this.store.dispatch(new fromActions.UpdateSelectedEndDate({selectedEndDate: selectedEndDate}));
            this.store.dispatch(new fromActions.UpdateSelectedRoleIds({selectedRoleIds: selectedRoleIds}));
            this.store.dispatch(new fromActions.UpdateSelectedUserIds({selectedUserIds: selectedUserIds}));
            this.store.dispatch(new fromActions.UpdateSelectedPassIds({selectedPassIds: selectedPassIds}));
            this.store.dispatch(new fromActions.UpdateSelectedProductIds({selectedProductIds: selectedProductIds}));
        }
    }

    setSelectedFavoriteId(favoriteId: number) {
        this.store.dispatch(new fromActions.UpdateSelectedFavoriteId({favoriteId: favoriteId}));
    }

    deactivateFavorite(favoriteId: number): Observable<any> {
        return this.favoriteRestService.deactivate(favoriteId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while deactivating favorite", error);
            }));
    }

    createReportSearchRequest(): Observable<ReportSearchRequestModel> {

        return observableCombineLatest(
                this.selectedStartDate$,
                this.selectedEndDate$,
                this.selectedRoleIds$,
                this.selectedUserIds$,
                this.selectedPassIds$,
                this.selectedProductIds$,
                this.currentSearchDataSortBy$
            ).pipe(
            take(1),
            map(([selectedStartDate, selectedEndDate, selectedRoleIds, selectedUserIds, selectedPassIds, selectedProductIds, currentSearchDataSortBy]: [moment.Moment, moment.Moment, number[], number[], number[], number[], SortByModel]) => {

                let searchExpression: BaseExpressionRequestModel[] = [];
                let fieldRequests: FieldRequestModel[] = [];
                let sortRequests: SortByRequestModel[] = [];
                let groupByRequests: GroupByRequestModel[] = [];
                let from: number = 0;
                let size: number = 0;
                let scrollId: string = "";
                let showPaymentSummary: boolean = false;

                if (currentSearchDataSortBy) {
                    sortRequests = [new SortByRequestModel(currentSearchDataSortBy.fieldName, currentSearchDataSortBy.order)];
                }

                let startDateString = JSON.stringify(this.getDateObjectFromMomentDate(selectedStartDate));
                let endDateString = JSON.stringify(this.getDateObjectFromMomentDate(selectedEndDate));
                let startEndDateFilter: FilterExpressionRequestModel = new FilterExpressionRequestModel("createDateTime", [startDateString, endDateString]);
                searchExpression.push(startEndDateFilter);

                let and1Operator: OperatorExpressionRequestModel = new OperatorExpressionRequestModel("AND");
                searchExpression.push(and1Operator);

                let selectedRoleIdsStrings: string[] = selectedRoleIds.map(roleId => roleId.toString());
                let roleIdsFilter: FilterExpressionRequestModel = new FilterExpressionRequestModel("roleSoldBy", selectedRoleIdsStrings);
                searchExpression.push(roleIdsFilter);

                let and2Operator: OperatorExpressionRequestModel = new OperatorExpressionRequestModel("AND");
                searchExpression.push(and2Operator);

                let selectedUserIdsStrings: string[] = selectedUserIds.map(userId => userId.toString());
                let userIdsFilter: FilterExpressionRequestModel = new FilterExpressionRequestModel("soldBy", selectedUserIdsStrings);
                searchExpression.push(userIdsFilter);

                let and3Operator: OperatorExpressionRequestModel = new OperatorExpressionRequestModel("AND");
                searchExpression.push(and3Operator);

                let selectedPassIdsStrings: string[] = selectedPassIds.map(passId => passId.toString());
                let passIdsFilter: FilterExpressionRequestModel = new FilterExpressionRequestModel("purchasedMultiPass", selectedPassIdsStrings);
                searchExpression.push(passIdsFilter);

                let and4Operator: OperatorExpressionRequestModel = new OperatorExpressionRequestModel("AND");
                searchExpression.push(and4Operator);

                let selectedProductIdsStrings: string[] = selectedProductIds.map(productId => productId.toString());
                let productIdsFilter: FilterExpressionRequestModel = new FilterExpressionRequestModel("product", selectedProductIdsStrings);
                searchExpression.push(productIdsFilter);

                let request: ReportSearchRequestModel = new ReportSearchRequestModel(searchExpression, fieldRequests, sortRequests, groupByRequests, from, size, scrollId, showPaymentSummary);

                return request;
            }),);
    }

    private getDateObjectFromMomentDate(dateMoment: moment.Moment): any {
        return {
            day: dateMoment.date(),
            month: dateMoment.month() + 1,
            year: dateMoment.year()
        };
    }

    getCommissionReport(startDate: DateTimeDescriptor, endDate: DateTimeDescriptor, roleIds: number[], userIds: number[], passIds: number[], productIds: number[]): void {

        this.store.dispatch(new fromActions.InitSearch());

        this.currentSearchDataSortBy$.pipe(
            take(1),
            mergeMap((sortBy: SortByModel) => {

                let fieldName: string = null;
                let order: string = null;
                if (sortBy != null && sortBy !== undefined) {
                    if (sortBy.fieldName != null && sortBy.fieldName !== undefined) {
                        fieldName = sortBy.fieldName;
                    }
                    if (sortBy.order != null && sortBy.order !== undefined) {
                        order = sortBy.order;
                    }
                }

                const sortByRequest = new SortByRequestModel(fieldName, order);
                const request: CommissionReportRequestModel = {
                    fromDate: startDate,
                    toDate: endDate,
                    roleIds: roleIds,
                    userIds: userIds,
                    passIds: passIds,
                    productIds: productIds,
                    sortBy: sortByRequest
                };

                // Get commission report
                return this.reportingRestService.getCommissionReport(request).pipe(
                    catchError((error: HttpErrorModel) => {
                        return this.rootSandbox.handleHttpError("Error while getting commission report", error);
                    }));
            }),)
            .subscribe((response: CommissionReportResponseModel) => {
                this.store.dispatch(new fromActions.UpdateCurrentSearchData({response: response}));
            });
    }

    generateUrlForExportToXlsCommissionReport(startDate: DateTimeDescriptor, endDate: DateTimeDescriptor, roleIds: number[], userIds: number[], passIds: number[], productIds: number[]): Observable<string> {

        return this.currentSearchDataSortBy$.pipe(
            take(1),
            mergeMap((sortBy: SortByModel) => {

                let fieldName: string = null;
                let order: string = null;
                if (sortBy != null && sortBy !== undefined) {
                    if (sortBy.fieldName != null && sortBy.fieldName !== undefined) {
                        fieldName = sortBy.fieldName;
                    }
                    if (sortBy.order != null && sortBy.order !== undefined) {
                        order = sortBy.order;
                    }
                }

                const sortByRequest = new SortByRequestModel(fieldName, order);
                const request: CommissionReportRequestModel = {
                    fromDate: startDate,
                    toDate: endDate,
                    roleIds: roleIds,
                    userIds: userIds,
                    passIds: passIds,
                    productIds: productIds,
                    sortBy: sortByRequest
                };

                // Get url for export to xls commission report
                return this.reportingRestService.generateUrlForExportToXlsCommissionReport(request).pipe(
                    catchError((error: HttpErrorModel) => {
                        return this.rootSandbox.handleHttpError("Error while generating url for export to xls commission report", error);
                    }));
            }),);
    }

    updateSortBy(sortBy: SortByModel, startDate: DateTimeDescriptor, endDate: DateTimeDescriptor, roleIds: number[], userIds: number[], passIds: number[], productIds: number[]) {
        this.store.dispatch(new fromActions.UpdateCurrentSearchDataSortBy({sortBy: sortBy}));
        this.getCommissionReport(startDate, endDate, roleIds, userIds, passIds, productIds);
    }
}
