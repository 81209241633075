import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { DomainResponseModel } from "./response/domain-response.model";
import { CreateDomainRequestModel } from "./request/create-domain-request.model";
import { UpdateDomainRequestModel } from "./request/update-domain-request.model";
import { QuickSellingButtonPassResponseModel } from "../quick-selling-button-pass/response/quick-selling-button-pass-response.model";
import { QuickSellingButtonProductResponseModel } from "../quick-selling-button-product/response/quick-selling-button-product-response.model";
import { DomainQSBResponseModel } from "./response/domain-qsb-response.model";
import { CustomerResponseModel } from "../customer/response/customer-response.model";
import { CreateDomainQSBRequestModel } from "./request/create-domain-qsb-request.model";
import { RemoveDomainCustomerRequestModel } from "./request/remove-sale-group-domains-request.model";
import { RemoveDomainQSBRequestModel } from "./request/remove-domain-qsb-request.model";
@Injectable()
export class DomainRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<DomainResponseModel[]> {
        return this.httpClient.get<DomainResponseModel[]>(environment.server_api_url + '/api/secured/domain/getAll');
    }

    getAllActive(): Observable<DomainResponseModel[]> {
        return this.httpClient.get<DomainResponseModel[]>(environment.server_api_url + '/api/secured/domain/getAllActive');
    }

    getById( domainId: number ): Observable<DomainResponseModel> {
        return this.httpClient.get<DomainResponseModel>(environment.server_api_url + '/api/secured/domain/getById/' + domainId);
    }

    getQSBPassesForDomain( domainId: number ){
        return this.httpClient.get<QuickSellingButtonPassResponseModel[]>(environment.server_api_url + '/api/secured/domain/getDomainQuickSellingButtonPassAll/' + domainId );
    }

    getCustomersForDomain( domainId: number ){
        return this.httpClient.get<CustomerResponseModel[]>(environment.server_api_url + '/api/secured/domain/getDomainCustomers/' + domainId );
    }

    getQSBProductsForDomain( domainId: number ){
        return this.httpClient.get<QuickSellingButtonProductResponseModel[]>(environment.server_api_url + '/api/secured/domain/getDomainQuickSellingButtonProductAll/' + domainId );
    }

    getQSBPForDomain( domainId: number ){
        return this.httpClient.get<DomainQSBResponseModel>(environment.server_api_url + '/api/secured/domain/getDomainQuickSellingButtons/' + domainId );
    }

    getQSBPItems(){
        return this.httpClient.get<DomainQSBResponseModel>(environment.server_api_url + '/api/secured/domain/getDomainQuickSellingButtons' );
    }

    create( request: CreateDomainRequestModel ): Observable<CreateDomainRequestModel> {
        return this.httpClient.post<CreateDomainRequestModel>(environment.server_api_url + '/api/secured/domain/create', request);
    }

    update( request: UpdateDomainRequestModel ): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/domain/update', request, {responseType: 'text'});
    }

    activate( domainId: number ): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/domain/activate', domainId, {responseType: 'text'});
    }

    deactivate( domainId: number ): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/domain/deactivate', domainId, {responseType: 'text'});
    }

    addCustomersToDomain( request: CreateDomainRequestModel ): Observable<CreateDomainRequestModel> {
        return this.httpClient.post<CreateDomainRequestModel>(environment.server_api_url + '/api/secured/domain/addCustomersToDomain', request);
    }

    addQSBItemToDomain( request: CreateDomainQSBRequestModel ): Observable<CreateDomainQSBRequestModel> {
        return this.httpClient.post<CreateDomainQSBRequestModel>(environment.server_api_url + '/api/secured/domain/addQuickSellingButton', request);
    }

    removeQSBItemFromDomain( request: RemoveDomainQSBRequestModel ): Observable<RemoveDomainQSBRequestModel> {
        return this.httpClient.post<RemoveDomainQSBRequestModel>(environment.server_api_url + '/api/secured/domain/removeQuickSellingButton', request);
    }

    removeCustomerFromDomain( request: RemoveDomainCustomerRequestModel ): Observable<RemoveDomainCustomerRequestModel> {
        return this.httpClient.post<RemoveDomainCustomerRequestModel>(environment.server_api_url + '/api/secured/domain/removeCustomerFromDomain', request);
    }

}
