export class SaleGroupDomainFormSubmitDataModel {
    saleGroupName: string;
    saleGroupId: number;
    domainIds: number[];

    constructor( saleGroupName: string, domainIds: number[] ) {
        this.saleGroupName = saleGroupName;
        this.domainIds = domainIds;
    }
}
