<app-abstract-dialog-container>
    <app-edit-role-or-user-dialog-form
        [editMode]="editMode"
        [type]="type"
        [useLoginLocations]="useLoginLocations"
        [user]="user$ | async"
        [role]="role$ | async"
        [roles]="roles$ | async"
        (submit)="onSubmit($event)"
        (cancel)="onCancel()">
    </app-edit-role-or-user-dialog-form>
</app-abstract-dialog-container>
