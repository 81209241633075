<div [ngClass]="{'disabled':disabled}" class="datepicker_container">
    <input [ngClass]="{'remove':hiddenInput}"
           class="datepicker-input"
           type="text"
           readonly
           ngxDaterangepickerMd
           [(ngModel)]="selected"
           [showDropdowns]="showDropdowns"
           [drops]="drops"
           [showCustomRangeLabel]="showCustomRangeLabel"
           [alwaysShowCalendars]="true"
           [singleDatePicker]="isSingleDatePicker"
           [timePicker]="timePicker"
           [timePickerSeconds]="timePickerSeconds"
           [ranges]="ranges ? ranges : (showDefaultRanges ? defaultRanges : null)"
           [linkedCalendars]="linkedCalendars"
           [showClearButton]="showClearButton"
           [opens]="opensPosition"
           [minDate]="minDate"
           [maxDate]="maxDate"
           [locale]="{format:'YYYY-MM-DD', displayFormat:timePicker?'YYYY-MM-DD HH:mm:ss':'YYYY-MM-DD'}"
           [placeholder]="placeholderText"
           (datesUpdated)="changeDate($event)"
           (clickOutside)="onClose()"/>

    <div [ngClass]="{'remove':hiddenInput}" class="date-picker-icons">
        <ng-container *ngIf="!loading">
            <a (click)="openDatepicker()" class="fal fa-calendar-alt fa-sm ngx-daterangepicker-action"
               aria-hidden="true"></a>
            <a *ngIf="timePicker" (click)="openDatepicker()" class="fal fa-clock fa-sm ngx-daterangepicker-action"
               aria-hidden="true"></a>
        </ng-container>
        <ng-container *ngIf="loading">
            <i class="fas fa-circle-notch fa-spin"></i>
        </ng-container>
    </div>
</div>
