import * as actions from './actions';
import { SaleGroupResponseModel } from '../../../../../../../core/modules/rest/sale-group/response/sale-group-response.model';
import { QuickSellingButtonProductResponseModel } from "../../../../../../../core/modules/rest/quick-selling-button-product/response/quick-selling-button-product-response.model";
import { QuickSellingButtonPassResponseModel } from "../../../../../../../core/modules/rest/quick-selling-button-pass/response/quick-selling-button-pass-response.model"
import { DomainResponseModel } from '../../../../../../../core/modules/rest/domain/response/domain-response.model';
import { CustomerCurrentSearchDataModel } from '../../../customers/model/customer-current-search-data.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomerItemResponseModel } from '../../../../../../../core/modules/rest/customer/response/customer-item-response.model';

export const REDUCER_NAME = 'feature_customers_sale_group';

export const CUSTOMER_PAGE_SIZE = 100;

export interface State {
    saleGroups: SaleGroupResponseModel[];
    domainSaleGroups: SaleGroupResponseModel[];
    qsbPasses: QuickSellingButtonPassResponseModel[];
    qsbProducts: QuickSellingButtonProductResponseModel[];
    domains: DomainResponseModel[];
    customers: CustomerItemResponseModel[];
    currentSearchData: CustomerCurrentSearchDataModel;
}

const initialState: State = {
    saleGroups: [],
    domainSaleGroups: [],
    qsbPasses: [],
    qsbProducts: [],
    customers: [],
    domains: [],
    currentSearchData: null
};

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const currentSearchData_selector = createSelector(state_selector, (state: State) => state.currentSearchData);

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.UPDATE_SALE_GROUP_ACTION:
            return handleUpdateSaleGroupsAction(state, action);
        case actions.UPDATE_DOMAIN_TYPE_SALE_GROUP_ACTION:
            return handleUpdateDomainTypeSaleGroupsAction(state, action);
        case actions.UPDATE_QSB_PASSES_ACTION:
            return handleUpdateSQBPassesAction(state, action);
        case actions.UPDATE_QSB_PRODUCTS_ACTION:
            return handleUpdatQSBProductsAction(state, action);
       case actions.UPDATE_CUSTOMERS_ACTION:
            return handleUpdatCustomersAction(state, action);
        case actions.UPDATE_DOMAINS_ACTION:
            return handleUpdatDomainsAction(state, action);
        case actions.INIT_CUSTOMERS_ACTION:
            return handleInitCustomersAction(state, action);
        case actions.LOAD_MORE_CUSTOMERS_ACTION:
            return handleLoadMoreCustomersAction(state, action);
        case actions.SET_CUSTOMERS_ACTION:
            return handleSetCustomersAction(state, action);
        default:
            return state;
    }
}


// #################################################### SELECTORS ####################################################
export const saleGroups_selector = (state: State) => state.saleGroups;
export const domainTypeSaleGroups_selector = (state: State) => state.domainSaleGroups;
export const qsbPasses_selector = (state: State) => state.qsbPasses;
export const qsbProducts_selector = (state: State) => state.qsbProducts;
export const customers_selector = (state: State) => state.customers;
export const domains_selector = (state: State) => state.domains;
export const current_search_data_selector = (state: State) => state.currentSearchData;

// #################################################### HANDLERS ####################################################
function handleUpdateSaleGroupsAction(state: State, action: actions.UpdateSaleGroup) {
    return {
        ...state,
        saleGroups: action.saleGroups
    };
}

function handleUpdateDomainTypeSaleGroupsAction(state: State, action: actions.UpdateDomainTypeSaleGroup) {
    return {
        ...state,
        domainSaleGroups: action.saleGroups
    };
}

function handleUpdateSQBPassesAction(state: State, action: actions.UpdateQSBPasses) {
    return {
        ...state,
        qsbPasses: action.qsbPasses
    };
}

function handleUpdatQSBProductsAction(state: State, action: actions.UpdateQSBProducts) {
    return {
        ...state,
        qsbProducts: action.qsbProducts
    };
}

function handleInitCustomersAction(state: State, action: actions.InitCustomers) {
    return {
        ...state,
        currentSearchData: {
            from: 0,
            size: CUSTOMER_PAGE_SIZE,
            loadingCustomers: true,
            loadingMoreItems: false,
            noMoreItemsForLoading: false,
            totalNumberOfResults: 0,
            customerItems: []
        }
    };
}

function handleLoadMoreCustomersAction(state: State, action: actions.LoadMoreCustomers) {
    return {
        ...state,
        currentSearchData: {
            ...state.currentSearchData,
            from: state.currentSearchData.from + CUSTOMER_PAGE_SIZE,
            loadingMoreItems: true
        }
    };
}

function handleUpdatCustomersAction(state: State, action: actions.UpdateCustomers) {
    return {
        ...state,
        customers: [...state.customers, ...action.customers]
    };
}

function handleSetCustomersAction(state: State, action: actions.SetCustomers) {
    return {
        ...state,
        customers: action.customers
    };
}

function handleUpdatDomainsAction(state: State, action: actions.UpdateDomains) {
    return {
        ...state,
        domains: action.domains
    };
}
