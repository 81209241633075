<app-abstract-dialog-container>
    <app-edit-product-routes-dialog-form
        [product]="product$ | async"
        [pickupDropoffLocationLists]="pickupDropoffLocationLists$ | async"
        [pickupLocationLists]="pickupLocationLists$ | async"
        [dropoffLocationLists]="dropoffLocationLists$ | async"
        (submit)="onSubmit($event)"
        (cancel)="onCancel()">
    </app-edit-product-routes-dialog-form>
</app-abstract-dialog-container>
