<app-abstract-dialog-container>
    <app-edit-domain-form
        [editMode]="editMode"
        [domain]="domain"
        (submitEvent)="onSubmit($event)"
        (closeEvent)="onClose()">
    </app-edit-domain-form>
</app-abstract-dialog-container>


