import { Component, OnInit  } from '@angular/core';


@Component({
  selector: 'app-tab-content',
  templateUrl: './tab-content.component.html'
})
export class TabContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
