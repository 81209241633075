import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {AddAndroidDeviceInfoRemoteCommandDialogFormSubmitDataModel} from "../../model/add-android-device-info-remote-command-dialog-form-submit-data.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
    DropdownOptionModel
} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {RemoteCommandEnum} from "../../../../../../../../../shared/enums/remote-command.enum";

@Component({
    selector: 'app-add-android-device-info-remote-command-dialog-form',
    templateUrl: './add-android-device-info-remote-command-dialog-form.component.html'
})
export class AddAndroidDeviceInfoRemoteCommandDialogFormComponent implements OnInit {

    @Input() androidDeviceInfoId: number;
    @Input() androidDeviceNumber: string;

    @Output() closeEvent = new EventEmitter<any>();
    @Output() submitEvent = new EventEmitter<AddAndroidDeviceInfoRemoteCommandDialogFormSubmitDataModel>();

    form: FormGroup;
    errorMessage: string;
    remoteCommandOptions: DropdownOptionModel[];

    constructor() {

        // Populate "Remote Command" dropdown
        this.remoteCommandOptions = [
            new DropdownOptionModel("Send Android DB Backup", RemoteCommandEnum.SEND_ANDROID_DB_BACKUP),
            // new DropdownOptionModel("Get and Restore Android DB", RemoteCommandEnum.GET_AND_RESTORE_ANDROID_DB),
            // new DropdownOptionModel("Clear Android Device Number, Order Counter and Pass Card Counter", RemoteCommandEnum.CLEAR_ANDROID_DEVICE_NUMBER_ORDER_COUNTER_PASS_CARD_COUNTER.toString()),
            // new DropdownOptionModel("Export Locally Android DB Backup", RemoteCommandEnum.EXPORT_LOCALLY_ANDROID_DB_BACKUP),
            // new DropdownOptionModel("Force Full Synchronization", RemoteCommandEnum.FORCE_FULL_SYNCHRONIZATION),
            new DropdownOptionModel("Send Android Logs", RemoteCommandEnum.SEND_ANDROID_LOGS_TO_S3)
        ];
    }

    ngOnInit() {

        this.form = new FormGroup({
            remoteCommand: new FormControl(null, Validators.required),
            timeoutHours: new FormControl(12, [Validators.required, Validators.min(1)])
        });
    }

    onClose() {
        this.closeEvent.emit();
    }

    onSubmit() {

        if (!this.form.value.remoteCommand) {
            this.errorMessage = 'Please select Remote Command';
            return;
        }

        if (!this.form.value.timeoutHours || this.form.value.timeoutHours <= 0) {
            this.errorMessage = 'Please enter a valid positive number for Timeout Hours';
            return;
        }

        this.errorMessage = null;

        let data: AddAndroidDeviceInfoRemoteCommandDialogFormSubmitDataModel = new AddAndroidDeviceInfoRemoteCommandDialogFormSubmitDataModel(
            this.androidDeviceInfoId,
            this.form.value.remoteCommand,
            this.form.value.timeoutHours
        );

        this.submitEvent.emit(data);
    }
}
