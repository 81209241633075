import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {StoreModule} from "@ngrx/store";
import {reducer, REDUCER_NAME} from './sandbox/store/reducer';
import {PassTierSetupDialogSandbox} from "./sandbox/pass-tier-setup-dialog.sandbox";
import {PassTierSetupDialogComponent} from "./container/pass-tier-setup-dialog.component";
import {PassTierSetupDialogFormComponent} from './components/pass-tier-setup-dialog-form/pass-tier-setup-dialog-form.component';
import {PassTierDialogItemComponent} from "./components/pass-tier-dialog-tier-item/pass-tier-dialog-item.component";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        PassTierSetupDialogSandbox
    ],
    declarations: [
        PassTierSetupDialogComponent,
        PassTierSetupDialogFormComponent,
        PassTierDialogItemComponent
    ],
    exports: [
        PassTierSetupDialogComponent
    ],
    entryComponents: [
        PassTierSetupDialogComponent
    ]
})
export class PassTierSetupDialogModule {

}
