import {Action} from "@ngrx/store";
import {HolidayResponseModel} from "../../../../../../../../../core/modules/rest/holiday/response/holiday-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Holiday :: MAIN] Reset State';
export const SET_HOLIDAY_ACTION = '[Dialog - Create/Edit Holiday :: MAIN] Set Holiday Info';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetHoliday implements Action {
    readonly type = SET_HOLIDAY_ACTION;
    constructor(public holiday: HolidayResponseModel) {}
}

export type All = ResetState | SetHoliday;
