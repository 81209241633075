import { Component, Input, OnInit } from "@angular/core";
import {FormGroup} from "@angular/forms";

@Component({
    selector: 'app-clone-product-availability-dialog-form',
    templateUrl: './clone-product-availability-dialog-form.component.html'
})
export class CloneProductAvailabilityDialogFormComponent implements OnInit {

    @Input() productAvailabilityForm: FormGroup;

    constructor() {}

    ngOnInit() {}

    getOriginalDescriptionFriendly(originalDescription: string): string {
        if (originalDescription && originalDescription.trim().length > 0) {
            return  originalDescription;
        } else {
            return "N/A";
        }
    }
}
