import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {HolidayResponseModel} from "../../../../../../../../core/modules/rest/holiday/response/holiday-response.model";
import {MappingHolidayDialogSandbox} from "../sandbox/mapping-holiday-dialog.sandbox";
import {CategoryResponseModel} from "../../../../../../../../core/modules/rest/category/response/category-response.model";
import {ProductSimpleResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {ProductAvailabilityHolidayMappingResponseModel} from "../../../../../../../../core/modules/rest/product-availability/response/product-availability-holiday-mapping-response.model";
import {ProductAndInactivitySelectedModel} from "../model/product-and-inactivity-selected.model";
import {SubmitFormDialogDataModel} from "../model/submit-form-dialog-data.model";


// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class HolidayMappingDialogData extends DialogData {

    holidayId: number;
    callbackSuccessFn: () => void;

    constructor(successFn: () => void, holidayId?: number) {
        super();
        this.holidayId = holidayId;
        this.callbackSuccessFn = successFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-mapping-holiday-dialog',
    templateUrl: './mapping-holiday-dialog.component.html'
})
export class MappingHolidayDialogComponent implements OnInit, OnDestroy {

    holiday$: Observable<HolidayResponseModel>;
    categories$: Observable<CategoryResponseModel[]>;
    products$: Observable<ProductSimpleResponseModel[]>;
    productAvailabilityHolidayMappingItems$: Observable<ProductAvailabilityHolidayMappingResponseModel[]>;

    categoriesLoading$: Observable<boolean>;
    productsLoading$: Observable<boolean>;
    productAvailabilityHolidayMappingItemsLoading$: Observable<boolean>;

    holidayLoaded = false;

    holidayId: number;
    callbackSuccessFn: () => void;

    constructor(@Inject(DialogData) dialogData: HolidayMappingDialogData, private sandbox: MappingHolidayDialogSandbox) {
        this.holidayId = dialogData.holidayId;
        this.callbackSuccessFn = dialogData.callbackSuccessFn;
    }

    ngOnInit() {

        this.sandbox.loadHoliday(this.holidayId);
        this.sandbox.loadCategories();

        this.holiday$ = this.sandbox.holiday$;
        this.holiday$.subscribe(res => {
            if (res.description) {
                this.holidayLoaded = true;
            }
          });
        this.categories$ = this.sandbox.categories$;
        this.products$ = this.sandbox.products$;
        this.productAvailabilityHolidayMappingItems$ = this.sandbox.productAvailabilityHolidayMappingItems$;

        this.categoriesLoading$ = this.sandbox.categoriesLoading$;
        this.productsLoading$ = this.sandbox.productsLoading$;
        this.productAvailabilityHolidayMappingItemsLoading$ = this.sandbox.productAvailabilityHolidayMappingItemsLoading$;
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    // Category has been selected
    onCategoriesSelectedEvent(selectedCategories: number[]) {
        this.sandbox.loadProductsByCategoryIds(selectedCategories);
    }

    // Product has been selected
    onProductsSelectedEvent(data: ProductAndInactivitySelectedModel) {
        this.sandbox.loadProductAvailabilityHolidayMappingByHolidayIdAndProductIds(
            this.holidayId,
            data.selectedProducts,
            !data.showInactiveProductAvailabilities
        );
    }

    // Switch value has changed
    onShowInactiveExpiredProductAvailabilitiesEvent(data: ProductAndInactivitySelectedModel) {
        this.sandbox.loadProductAvailabilityHolidayMappingByHolidayIdAndProductIds(
            this.holidayId,
            data.selectedProducts,
            !data.showInactiveProductAvailabilities
        );
    }

    onSubmit(data: SubmitFormDialogDataModel) {

        this.sandbox.updateProductAvailabilityHolidayMapping(
            this.holidayId,
            data.selectedProducts,
            data.unselectedProductAvailabilityHolidayMappingItems,
            data.selectedProductAvailabilityHolidayMappingItems
        ).subscribe(() => {
            this.callbackSuccessFn();
            this.sandbox.destroyDialog();
        });
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
