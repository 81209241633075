
import {map, mergeMap, catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {Injectable} from "@angular/core";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {Observable} from "rxjs";
import {KioskConfigurationDetailsSandbox} from "../../../store/kiosk-configuration-details.sandbox";
import {select, Store} from "@ngrx/store";
import {dropoffLocations_selector} from "./store/reducer";
import {LocationResponseModel} from "../../../../../../../../core/modules/rest/location/response/location-response.model";
import {CreateKioskConfigurationDropoffLocationRequestModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-dropoff-location-request.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {KioskConfigurationDropoffLocationValidatedResponseModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-dropoff-location-validated-response.model";
import {KioskConfigurationDropoffLocationValidatedModel} from "../../../model/kiosk-configuration-dropoff-location-validated.model";
import * as fromFeatureMainActions from "../../../store/main/actions";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {KioskConfigurationRestService} from "../../../../../../../../core/modules/rest/kiosk-configuration/kiosk-configuration-rest.service";


@Injectable()
export class CreateDropoffLocationDialogSandbox extends DialogSandbox {

    allLocations$: Observable<LocationResponseModel[]> = this.store.pipe(select(dropoffLocations_selector));

    constructor(appDialogsService: AppDialogsService,
                private rootSandbox: RootSandbox,
                private kioskConfigurationRestService: KioskConfigurationRestService,
                private kioskConfigurationDetailsSandbox: KioskConfigurationDetailsSandbox,
                private store: Store<reducer.State>) {

        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetStateAction());
    }

    loadAllLocations() {

        this.showLoader();

        this.kioskConfigurationDetailsSandbox.getAllPossibleLocationsForCreateDropoffLocationDialog()
            .subscribe((locations: LocationResponseModel[]) => {
                this.store.dispatch(new actions.SetDropoffLocations(locations));
                this.hideLoader();
            },
            () => this.hideLoader());
    }

    createKioskConfigurationDropoffLocation(kioskConfigurationId: number, locationId: number): Observable<void> {

        let request: CreateKioskConfigurationDropoffLocationRequestModel = new CreateKioskConfigurationDropoffLocationRequestModel(kioskConfigurationId, locationId);

        return this.kioskConfigurationRestService.createKioskConfigurationDropoffLocation(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while creating kiosk configuration dropoff location", error);
            }),
            mergeMap((createBaseResponse: CreateBaseResponseModel) => {
                return this.kioskConfigurationRestService.getKioskConfigurationDropoffLocationValidatedById(createBaseResponse.id);
            }),
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while loading kiosk configuration dropoff location by id", error);
            }),
            map((kioskConfigurationDropoffLocation: KioskConfigurationDropoffLocationValidatedResponseModel) => {

                const converted: KioskConfigurationDropoffLocationValidatedModel = this.kioskConfigurationDetailsSandbox.convertKioskConfigurationDropoffLocationValidatedResponseModelIntoModel(kioskConfigurationDropoffLocation);

                // Dispatch action
                this.store.dispatch(new fromFeatureMainActions.AddKioskConfigurationDropoffLocation(converted));
            }),);
    }

}
