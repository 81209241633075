import { Component, OnDestroy, OnInit  } from '@angular/core';
import * as DialogServiceAlias from "./core/services/app-dialogs.service";
import {Subscription, Observable} from 'rxjs';
import {SharedSandbox} from './shared/shared.sandbox';
import {UserInfoResponseModel} from './core/modules/rest/user/response/user-info-response.model';
import {NgxPermissionsService} from 'ngx-permissions';
import {PermissionEnum} from './shared/enums/permission.enum';

import _ from 'lodash';
import 'rxjs/Rx';

import {RenderDialogModel} from "./shared/dialogs/model/render-dialog.model";
import {RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent} from "@angular/router";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    activeDialog$: Observable<RenderDialogModel>;

    userInfoSubscription: Subscription;

    preloadingModules: boolean = false;

    preloadingModulesCount: number = 0;

    constructor(private router: Router,
                private sharedSandbox: SharedSandbox,
                private appDialogsService: DialogServiceAlias.AppDialogsService,
                private permissionsService: NgxPermissionsService) {

        router.events.subscribe((event: RouterEvent) => this.checkForPreloadingLoadingModules(event));
    }

    ngOnInit() {

        this.activeDialog$ = this.appDialogsService.activeDialog$;

        this.userInfoSubscription = this.sharedSandbox.getUserInfo().subscribe(
            (userInfo: UserInfoResponseModel) => {
                if (userInfo) {
                    const permissions: string[] = _.map(userInfo.permissions, (permission: PermissionEnum) => permission);
                    this.permissionsService.loadPermissions(permissions);
                } else {
                    this.permissionsService.flushPermissions();
                }
            });
    }

    ngOnDestroy() {
        if (this.userInfoSubscription) {
            this.userInfoSubscription.unsubscribe();
        }
    }

    checkForPreloadingLoadingModules(event: RouterEvent) {

        if (event instanceof RouteConfigLoadStart) {
            this.preloadingModulesCount++;
        } else if (event instanceof RouteConfigLoadEnd) {
            this.preloadingModulesCount--;
        }

        this.preloadingModules = this.preloadingModulesCount !== 0;
    }
}
