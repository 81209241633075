import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild  } from '@angular/core';
import {Observable} from "rxjs";
import {AppDialogsService} from "../../core/services/app-dialogs.service";


@Component({
    selector: 'app-abstract-dialog-container',
    templateUrl: './abstract-dialog-container.component.html'
})
export class AbstractDialogContainerComponent implements OnInit {

    @Input() sizeClass: string = 'medium';
    @Input() shouldOverrideCloseClicked: boolean = false;
    @Input() shouldOverrideStyle: boolean = false;
    @Input() classList: string = "";

    @Output() closeClicked: EventEmitter<any> = new EventEmitter();

    @ViewChild('dialogContainer') dialogContainer: ElementRef;

    dialogLoaderVisible$: Observable<boolean>;

    ESCAPE_KEY_CODE: string = "Escape";

    readonly IGNORE_CLASSES: string[] = ["ui-datepicker", "notification-alert-box", "pac-container", "mat-option-text", "cdk-overlay-backdrop", "cdk-overlay-pane"];

    constructor(private appDialogsService: AppDialogsService) {
    }

    ngOnInit() {
        this.dialogLoaderVisible$ = this.appDialogsService.dialogLoaderVisible$;
    }

    onClickClose() {

        if (this.shouldOverrideCloseClicked) {
            this.closeClicked.emit();
        } else {
            this.appDialogsService.destroyActiveDialog();
        }
    }

    @HostListener('document:keydown', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        if (event.key === this.ESCAPE_KEY_CODE) {
            this.onClickClose();
        }
    }

    // Instead of using clickOutside directive (not working in case of dialog, doesn't properly
    // handle some click cases), we will use this host listener that detects if click happened in dialog.
    @HostListener('document:mousedown', ['$event'])
    onClick(event): void {

        var path = event.path || (event.composedPath && event.composedPath());

        let notificationDialogClicked: boolean = path.some(p => p.classList && this.hasIgnoreClass(p.classList));

        if (this.dialogContainer && !this.dialogContainer.nativeElement.contains(event.target) && !notificationDialogClicked) {

            // Respect override of close
            if (!this.shouldOverrideCloseClicked) {
                this.appDialogsService.destroyActiveDialog();
            } else {
                this.onClickClose();
            }
        }
    }

    private hasIgnoreClass(classes: DOMTokenList): boolean {
        return this.IGNORE_CLASSES.some(ignore => classes.contains(ignore));
    }
}
