import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {DRAG_FILTER_IMAGE, DRAG_N_DROP_DEFAULT_ICON} from '../../../../../../../../../core/services/app-properties.service';
import {ExpressionFilterModel} from '../../../../model/expression-filter.model';
import {DraggedDataI} from '../../../../model/dragged-data.model';
import {DraggedFilterDataModel} from '../../../../model/dragged-filter-data.model';
import {UpdateExpressionItemValueModel} from '../../../../model/update-expression-item-value.model';
import {detect} from 'detect-browser';

const browser = detect();


@Component({
    selector   : 'app-order-reports-expression-filter',
    templateUrl: './order-reports-expression-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReportsExpressionFilterComponent implements OnInit {

    @Input() expressionFilterItem: ExpressionFilterModel;
    @Input() hasRunOrderReportFullPermission: boolean;
    @Output() deleteExpressionItemEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() dragStartEvent: EventEmitter<DraggedDataI> = new EventEmitter<DraggedDataI>();
    @Output() dragEndEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() filterValueChanged: EventEmitter<UpdateExpressionItemValueModel> = new EventEmitter<UpdateExpressionItemValueModel>();

    browser = browser;
    isIE = (this.browser.name === 'ie' || this.browser.name === 'edge') ? true : false;

    draggableIconDefault = DRAG_N_DROP_DEFAULT_ICON;
    dragImage = DRAG_FILTER_IMAGE;
    isDragged = false;

    constructor() { }

    ngOnInit() {
    }

    onDragStart() {
        // Native drag and drop has a problem in chrome and safari, this hack solves that problem
        setTimeout(() => {
            this.isDragged = true;
            const data: DraggedDataI = this.getDragData();
            this.dragStartEvent.emit(data);
        });
    }

    onDragEnd() {
        this.isDragged = false;
        this.dragEndEvent.emit();
    }

    deleteThisItem() {
        this.deleteExpressionItemEvent.emit(this.expressionFilterItem.uuid);
    }

    onExpressionFilterValueChanged(values: string[]) {
        const item: UpdateExpressionItemValueModel = new UpdateExpressionItemValueModel(this.expressionFilterItem.uuid, values);
        this.filterValueChanged.emit(item);
    }

    getDragData(): DraggedDataI {
        return new DraggedFilterDataModel(this.expressionFilterItem.uuid, this.expressionFilterItem.filter);
    }
}
