import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { CustomerFormSubmitDataModel } from '../../model/customer-form-submit-data.model';
import { RadioButtonGroupDataModel } from '../../../../../../../../../shared/components/form-elements/radio-button-group/radio-button-group-data.model';
import { CustomerItemResponseModel } from '../../../../../../../../../core/modules/rest/customer/response/customer-item-response.model';

@Component({
    selector: 'app-edit-customer-form',
    templateUrl: './edit-customer-form.component.html'
})
export class EditCustomerFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() customer: CustomerItemResponseModel;

    @Output() submitEvent = new EventEmitter<CustomerFormSubmitDataModel>();
    @Output() closeEvent = new EventEmitter<void>();

    form: FormGroup;
    customerEmail: string = "";
    forPlugin: boolean = true;

    fieldScopes: RadioButtonGroupDataModel[] = [
        {
            label: "Plug-in",
            value: "plugin",
            disabled: false
        },
        {
            label: "Customer Application",
            value: "application",
            disabled: false
        }
    ];

    maxCharacters: number = 150;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.editMode && changes.customer !== null && Object.keys(changes.customer.currentValue).length !== 0) {
            this.form.patchValue({
                email: changes.customer.currentValue.email,
                displayName: changes.customer.currentValue.displayName,
                phoneNumber: changes.customer.currentValue.phoneNumber,
                isCustomerOwner: changes.customer.currentValue.customerOwner

            });
            this.customerEmail = this.customer.email;
        }
    }

    ngOnInit() {

        let email: string = '';
        let displayName: string = '';
        let phoneNumber: string = '';
        let forPlugin: boolean = true;
        let isCustomerOwner: boolean = false;

        if (this.editMode) {
            email = this.customer.email;
            displayName = this.customer.displayName;
            phoneNumber = this.customer.phoneNumber;
            isCustomerOwner = this.customer.isCustomerOwner;
            this.customerEmail = this.customer.email;
        }

        this.form = new FormGroup({
            email: new FormControl(email),
            displayName: new FormControl(displayName),
            phoneNumber: new FormControl(phoneNumber),
            forPlugin: new FormControl(forPlugin),
            isCustomerOwner: new FormControl(isCustomerOwner)
        });
    }

    selectedRadioButtonChanged(checked: string) {

        if (checked == 'plugin') {
            this.form.value.forPlugin = true;
            this.forPlugin = true;
        }
        else {
            this.form.value.forPlugin = false;
            this.forPlugin = false;
        }
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {
        const email = this.form.value.email;
        const displayName = this.form.value.displayName;
        const phoneNumber = this.form.value.phoneNumber;
        const forPlugin = this.forPlugin;
        const isCustomerOwner = this.form.value.isCustomerOwner;
        const data: CustomerFormSubmitDataModel = new CustomerFormSubmitDataModel(email, displayName, phoneNumber, forPlugin, isCustomerOwner);
        this.submitEvent.emit(data);
    }
}
