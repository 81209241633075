import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {BusResponseModel} from "../../../../../../../../core/modules/rest/bus/response/bus-response.model";
import {MappingBusDialogSandbox} from "../sandbox/mapping-bus-dialog.sandbox";
import {SubmitFormDialogDataModel} from "../model/submit-form-dialog-data.model";
import { ProductBusMappingResponseModel } from '../../../../../../../../core/modules/rest/product/response/product-bus-mapping-response.model';


// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class BusMappingDialogData extends DialogData {

    busId: number;
    callbackSuccessFn: () => void;

    constructor(successFn: () => void, busId?: number) {
        super();
        this.busId = busId;
        this.callbackSuccessFn = successFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-mapping-bus-dialog',
    templateUrl: './mapping-bus-dialog.component.html'
})
export class MappingBusDialogComponent implements OnInit, OnDestroy {

    bus$: Observable<BusResponseModel>;

    productBusMappingItems$: Observable<ProductBusMappingResponseModel[]>;

    productBusMappingItemsLoading$: Observable<boolean>;

    busLoaded = false;

    busId: number;
    callbackSuccessFn: () => void;

    constructor(@Inject(DialogData) dialogData: BusMappingDialogData, private sandbox: MappingBusDialogSandbox) {
        this.busId = dialogData.busId;
        this.callbackSuccessFn = dialogData.callbackSuccessFn;
    }

    ngOnInit() {
        this.sandbox.loadBus(this.busId);
        this.sandbox.loadProductBusMappingItems(this.busId);

        this.bus$ = this.sandbox.bus$;
        this.productBusMappingItems$ = this.sandbox.productBusMappingItems$;
        this.productBusMappingItemsLoading$ = this.sandbox.productBusMappingItemsLoading$;
        this.productBusMappingItemsLoading$.subscribe(()=>{
            this.busLoaded = true;
        })
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitFormDialogDataModel) {

        this.sandbox.updateProductBusMapping(
            this.busId,
            data.unselectedProductBusMappingItems,
            data.selectedProductBusMappingItems
        ).subscribe(() => {
            this.callbackSuccessFn();
            this.sandbox.destroyDialog();
        });
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
