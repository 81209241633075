<!-- Dialog Content :: BEGIN -->
<div class="content_wrapper">
    <div class="formRow">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <app-input-label-group>
                    <label class="label">Description</label>
                    <input class="main--element input-large"
                           type="text"
                           name="Description"
                           [(ngModel)]="additionalCostLocalCopy.description"
                           (change)="onInputChanged()">
                </app-input-label-group>
            </div>
        </div>
    </div>

    <div class="formRow">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
                <app-input-label-group>
                    <label class="label">Amount</label>
                    <input class="main--element input-large"
                           appNumericInput
                           numericType="decimal"
                           name="Amount"
                           [(ngModel)]="additionalCostLocalCopy.amount"
                           (change)="onInputChanged()">
                </app-input-label-group>
            </div>

            <div class="cell medium-6">
                <app-input-label-group>
                    <label class="label">&nbsp;</label>
                    <app-dropdown class="main--element"
                                  [preSelectedValue]="additionalCostLocalCopy.amountType"
                                  [customClasses]="'dropdown_large'"
                                  [options]="amountTypes"
                                  (changed)="onAmountTypeChanged($event)">
                    </app-dropdown>
                </app-input-label-group>
            </div>
        </div>
    </div>

    <div class="formRow">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <app-input-label-group>
                    <label class="label">GL Code (optional)</label>
                    <input class="main--element input-large"
                           type="text"
                           name="GL Code"
                           [(ngModel)]="additionalCostLocalCopy.glCode"
                           (change)="onInputChanged()">
                </app-input-label-group>
            </div>
        </div>
    </div>

    <!-- Custom Date :: BEGIN-->
    <div class="formRow">
        <div class="grid-x grid-margin-x align-middle">
            <div class="cell medium-6">
                <app-input-label-group>
                    <label class="label">Start Date</label>

                    <app-datetimepicker
                        class="main--element datepicker-input-width"
                        [placeholderText]="'Start Date'"
                        [startDateValue]="startDate"
                        (dateChanged)="onStartDateChanged($event)">
                    </app-datetimepicker>
                </app-input-label-group>
            </div>


            <div class="cell medium-6">
                <app-input-label-group>
                    <label class="label">End Date</label>

                    <app-datetimepicker
                        class="main--element datepicker-input-width"
                        [placeholderText]="'End Date'"
                        [startDateValue]="endDate"
                        (dateChanged)="onEndDateChanged($event)">
                    </app-datetimepicker>
                </app-input-label-group>
            </div>
        </div>
    </div><!-- Custom Date :: END-->

    <div class="formRow">
        <div class="grid-x grid-margin-x align-middle">
            <div class="cell large-12">
                <app-checkbox
                    [labelName]="'Order Level Additional Cost'"
                    [checked]="additionalCostLocalCopy.isOrderLevel"
                    (valueChanged)="onIsOrderLevelChanged($event)">
                </app-checkbox>
            </div>
        </div>
    </div>

    <div class="flex-container space-between align-items-center mb-16px">
        <div class="small-12 medium-6 large-6 mr-8px pt-8px">
            <div class="cell large-12 pt-4px">
                <app-checkbox
                    [labelName]="'Use Additional Cost For All Locations'"
                    [checked]="additionalCostLocalCopy.allLocations"
                    (valueChanged)="onAllLocationsChanged($event)">
                </app-checkbox>
            </div>
        </div>
        <div class="small-12 medium-6 large-6 pl-8px">
            <div class="cell large-12">
                <app-input-label-group>
                    <label class="label">Location (optional)</label>

                    <app-multiselect-dropdown
                        class="main--element"
                        [preSelectedValues]="additionalCostLocalCopy.locationIds"
                        [options]="allLocations"
                        [disabled]="additionalCostLocalCopy.allLocations"
                        (changed)="onSelectedLocationsChanged($event)">
                    </app-multiselect-dropdown>
                </app-input-label-group>
            </div>
        </div>
    </div>

    <div class="flex-container space-between align-items-center mb-16px">
        <div class="small-12 medium-6 large-6 mr-8px pt-8px">
            <div class="cell large-12 pt-4px">
                <app-checkbox
                    [labelName]="'Use Additional Cost For All Platforms'"
                    [checked]="additionalCostLocalCopy.allPlatforms"
                    (valueChanged)="onAllPlatformsChanged($event)">
                </app-checkbox>
            </div>
        </div>
        <div class="small-12 medium-6 large-6 pl-8px">
            <div class="cell large-12">
                <app-input-label-group>
                    <label class="label">Platform (optional)</label>

                    <app-multiselect-dropdown
                        class="main--element"
                        [preSelectedValues]="getOnlyActivePlatforms()"
                        [options]="allPlatforms"
                        [disabled]="additionalCostLocalCopy.allPlatforms"
                        (changed)="onSelectedPlatformsChanged($event)">
                    </app-multiselect-dropdown>
                </app-input-label-group>
            </div>
        </div>
    </div>
</div><!-- Dialog Content :: END -->

