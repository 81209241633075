import { Action } from "@ngrx/store";
import { CloudPaymentDeviceResponseModel } from "../../../../../../../../../core/modules/rest/cloud-payment/response/cloud-payment-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Cloud payment device :: MAIN] Reset State';
export const SET_CLOUD_PAYMENT_DEVICE_ACTION = '[Dialog - Create/Edit Cloud payment device :: MAIN] Set Cloud payment device';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetCloudPaymentDevice implements Action {
    readonly type = SET_CLOUD_PAYMENT_DEVICE_ACTION;
    constructor(public cloudPaymentDevice: CloudPaymentDeviceResponseModel) {}
}

export type All = ResetState | SetCloudPaymentDevice;
