import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {ReportItemModel} from '../../../model/report-item.model';
import {DraggedDataI} from '../../../model/dragged-data.model';
import {DraggedReportItemDataModel} from '../../../model/dragged-report-item-data.model';
import {DRAG_ITEM_IMAGE} from '../../../../../../../../core/services/app-properties.service';
import {detect} from 'detect-browser';

const browser = detect();


@Component({
    selector       : 'app-order-reports-sidebar-report-item',
    templateUrl    : './order-reports-sidebar-report-item.component.html',
    styleUrls      : ['./order-reports-sidebar-report-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrderReportsSidebarReportItemComponent implements OnInit {

    @Input() reportItem: ReportItemModel;
    @Output() dragStartEvent: EventEmitter<DraggedDataI> = new EventEmitter<DraggedDataI>();
    @Output() dragEndEvent: EventEmitter<void> = new EventEmitter<void>();

    dragImage = DRAG_ITEM_IMAGE;
    browser   = browser;
    isIE = (this.browser.name === 'ie' || this.browser.name === 'edge') ? true : false;

    constructor() {
    }

    ngOnInit() {
    }

    getDragData(): DraggedDataI {
        return new DraggedReportItemDataModel(this.reportItem);
    }

    onDragStart() {
        const data: DraggedDataI = this.getDragData();
        this.dragStartEvent.emit(data);
    }

    onDragEnd() {
        this.dragEndEvent.emit();
    }
}
