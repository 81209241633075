import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {QuickSellingButtonPassResponseModel} from "../../../../../../../../../core/modules/rest/quick-selling-button-pass/response/quick-selling-button-pass-response.model";
import {TierResponseModel} from "../../../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {PassSimpleResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {LocationListItemDescriptorResponseModel} from "../../../../../../../../../core/modules/rest/location-list/response/location-list-item-descriptor-response.model";

export const REDUCER_NAME = 'dialog_edit-quick-selling-button-pass';

export interface State {
    quickSellingButtonPass: QuickSellingButtonPassResponseModel;
    tiers: TierResponseModel[];
    tiersLoading: boolean;
    passes: PassSimpleResponseModel[];
    passesLoading: boolean;
    pickupLocations: LocationListItemDescriptorResponseModel[];
    pickupLocationsLoading: boolean;
    dropoffLocations: LocationListItemDescriptorResponseModel[];
    dropoffLocationsLoading: boolean;
    errorMessage: string;
}

const initialState: State = {
    quickSellingButtonPass: {} as QuickSellingButtonPassResponseModel,
    tiers: [],
    tiersLoading: false,
    passes: [],
    passesLoading: false,
    pickupLocations: [],
    pickupLocationsLoading: false,
    dropoffLocations: [],
    dropoffLocationsLoading: false,
    errorMessage: ""
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const quick_selling_button_pass_selector = createSelector(state_selector, (state: State) => state.quickSellingButtonPass);

export const tiers_selector = createSelector(state_selector, (state: State) => state.tiers);
export const tiers_loading_selector = createSelector(state_selector, (state: State) => state.tiersLoading);

export const passes_selector = createSelector(state_selector, (state: State) => state.passes);
export const passes_loading_selector = createSelector(state_selector, (state: State) => state.passesLoading);

export const pickup_locations_selector = createSelector(state_selector, (state: State) => state.pickupLocations);
export const pickup_locations_loading_selector = createSelector(state_selector, (state: State) => state.pickupLocationsLoading);

export const dropoff_locations_selector = createSelector(state_selector, (state: State) => state.dropoffLocations);
export const dropoff_locations_loading_selector = createSelector(state_selector, (state: State) => state.dropoffLocationsLoading);

export const error_message_selector = createSelector(state_selector, (state: State) => state.errorMessage);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION:
            return initialState;

        case actions.SET_DATA_ACTION:
            return {
                ...state,
                quickSellingButtonPass: action.quickSellingButtonPass,
                tiers: action.tiers,
                passes: action.passes,
                pickupLocations: action.pickupLocations,
                dropoffLocations: action.dropoffLocations
            };

        case actions.SET_PICKUP_LOCATIONS_ACTION:
            return {
                ...state,
                pickupLocations: action.pickupLocations
            };

        case actions.SET_PICKUP_LOCATIONS_LOADING_ACTION:
            return {
                ...state,
                pickupLocationsLoading: action.loading
            };

        case actions.SET_DROPOFF_LOCATIONS_ACTION:
            return {
                ...state,
                dropoffLocations: action.dropoffLocations
            };

        case actions.SET_DROPOFF_LOCATIONS_LOADING_ACTION:
            return {
                ...state,
                dropoffLocationsLoading: action.loading
            };

        default:
            return state;
    }
}
