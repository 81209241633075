<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmit()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>Mapping for cost: {{ costDescription }}</h1>
            </div>
        </div>
    </div>

    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">
        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-4">
                    <app-input-label-group>
                        <label class="label">Category</label>
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="loading"
                            [allOptionEnabled]="true"
                            [options]="categories"
                            [formControlName]="'categoryIds'">
                        </app-multiselect-dropdown>
                    </app-input-label-group>
                </div>

                <div class="cell medium-4">
                    <app-input-label-group>
                        <label class="label">Product</label>
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="loading"
                            [allOptionEnabled]="true"
                            [options]="products"
                            [formControlName]="'productIds'">
                        </app-multiselect-dropdown>
                    </app-input-label-group>
                </div>

                <div class="cell medium-4">
                    <app-input-label-group>
                        <label class="label">MultiPass</label>
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="loading"
                            [allOptionEnabled]="true"
                            [options]="passes"
                            [formControlName]="'passIds'">
                        </app-multiselect-dropdown>
                    </app-input-label-group>
                </div>
                <div class="cell medium-4">
                    <app-switch
                        [switchLabel]="'Show Inactive Categories'"
                        [value]="showInactiveCategories"
                        (switchValueChanged)="onShowInactiveCategories($event)">
                    </app-switch>
                </div>

                <div class="cell medium-4">
                    <app-switch
                        [switchLabel]="'Show Inactive Products'"
                        [value]="showInactiveProducts"
                        (switchValueChanged)="onShowInactiveProducts($event)">
                    </app-switch>
                </div>

                <div class="cell medium-4">
                    <app-switch
                        [switchLabel]="'Show Inactive Passes'"
                        [value]="showInactivePasses"
                        (switchValueChanged)="onShowInactivePasses($event)">
                    </app-switch>
                </div>
            </div>
        </div>
    </div><!-- Dialog Content :: END -->

    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancel()">
                    Cancel
                </app-button>
            </div>
            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    (clickEvent)="onSubmit()">
                    Update
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
