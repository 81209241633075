import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {AutoReportResponseModel} from "../../../../../../../../core/modules/rest/reporting/general/auto-reports/response/auto-report-response.model";
import {AutoReportFormSubmitDataModel} from "../model/auto-report-form-submit-data.model";
import {EditAutoReportDialogSandbox} from "../sandbox/edit-auto-report-dialog.sandbox";
import {DropdownOptionModel} from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {UserInfoResponseModel} from "../../../../../../../../core/modules/rest/user/response/user-info-response.model";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditAutoReportDialogData extends DialogData {

    autoReportId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, autoReportId?: number) {
        super();
        this.autoReportId = autoReportId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-auto-report-dialog',
    templateUrl: './edit-auto-report-dialog.component.html'
})
export class EditAutoReportDialogComponent implements OnInit, OnDestroy {

    autoReportId: number;
    autoReport$: Observable<AutoReportResponseModel>;
    favorites$: Observable<DropdownOptionModel[]>;
    currentUser$: Observable<UserInfoResponseModel>;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditAutoReportDialogData, private sandbox: EditAutoReportDialogSandbox) {
        this.autoReportId = dialogData.autoReportId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
        if (this.autoReportId && !isNaN(this.autoReportId)) {
            this.editMode = true;
        }

        this.sandbox.loadData(this.autoReportId);
        this.autoReport$ = this.sandbox.autoReport$;
        this.favorites$ = this.sandbox.favorites$;
        this.currentUser$ = this.sandbox.currentUser$;
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: AutoReportFormSubmitDataModel) {

        if (this.editMode) {
            this.sandbox.updateAutoReport(this.autoReportId, data).subscribe(() => {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createAutoReport(data).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
