import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { OverrideReasonResponseModel } from "./response/over-ride-reason-response.model";
import { CreateOverrideReasonRequestModel } from "./request/create-override-reason-request.model";
import { UpdateOverrideRequestRequestModel } from "./request/update-override-reason-request.model";

@Injectable()
export class OverrideReasonRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<OverrideReasonResponseModel[]> {
        return this.httpClient.get<OverrideReasonResponseModel[]>(environment.server_api_url + '/api/secured/overrideReason/getAll');
    }

    getAllActive(): Observable<OverrideReasonResponseModel[]> {
        return this.httpClient.get<OverrideReasonResponseModel[]>(environment.server_api_url + '/api/secured/overrideReason/getAllActive');
    }

    getById(overrideReasonId: number): Observable<OverrideReasonResponseModel> {
        return this.httpClient.get<OverrideReasonResponseModel>(environment.server_api_url + '/api/secured/overrideReason/getById/' + overrideReasonId);
    }

    create(request: CreateOverrideReasonRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/overrideReason/create', request, {responseType: 'text'});
    }

    update(request: UpdateOverrideRequestRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/overrideReason/update', request, {responseType: 'text'});
    }

    activate(overrideReasonId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/overrideReason/activate', overrideReasonId, {responseType: 'text'});
    }

    deactivate(overrideReasonId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/overrideReason/deactivate', overrideReasonId, {responseType: 'text'});
    }
}
