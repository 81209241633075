<div class="pass-dialog-content">
    <h1 class="mb-8px">Edit Pass Options</h1>
    <form class="pl-8px pr-8px" [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="flex-container">
                    <div class="medium-6 mr-16px">
                        <div class="cell medium-12 mb-8px">
                            <app-checkbox
                                [labelName]="'Attach QR Code Image'"
                                [formControlName]="'shouldSendBarcodeQRCodeImageAttachment'">
                            </app-checkbox>
                        </div>
                        <div class="cell medium-12 mb-8px">
                            <app-checkbox
                                [labelName]="'Use Pregenerated QR Code on Android'"
                                [formControlName]="'usesPregeneratedBarcodeOnAndroid'">
                            </app-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Update</button>
    </div>
</div>
