
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {PassRestService} from "../../../../../../../../core/modules/rest/pass/pass-rest.service";
import {ProductRestService} from "../../../../../../../../core/modules/rest/product/product-rest.service";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {PassProductResponseModel} from "../../../../../../../../core/modules/rest/pass/response/pass-product-response.model";
import {ProductSimpleResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {SubmitPassProductsData} from "../components/edit-pass-products-dialog-form/edit-pass-products-dialog-form.component";
import {MultiselectDropdownOptionModel} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

@Injectable()
export class EditPassProductsDialogSandbox extends DialogSandbox {

    passProducts$: Observable<PassProductResponseModel[]> = this.store.pipe(select(reducer.passProducts_selector));
    products$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.products_selector));

    constructor(appDialogsService: AppDialogsService,
                private passRestService: PassRestService,
                private productRestService: ProductRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(passId: number) {

        observableCombineLatest(this.passRestService.getPassProductsByPassId(passId), this.productRestService.getAllActive()).pipe(
            take(1))
            .subscribe(([passProducts, products]: [PassProductResponseModel[], ProductSimpleResponseModel[]]) => {
                this.store.dispatch(new actions.SetPassProducts(passProducts));
                this.store.dispatch(new actions.SetProducts(products.map(p => new MultiselectDropdownOptionModel(p.description, p.productId.toString()))));
            });
    }

    updatePassProducts(data: SubmitPassProductsData): Observable<any> {

        return this.passRestService.updatePassProducts({
            passId: data.passId,
            productIds: data.productIds
        });
    }
}
