<form class="form_container dialog-edit-email"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? "Edit Template" : "Add New Template" }}</h1>
            </div>
        </div>
    </div>


    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-6">
                    <app-input-label-group>
                        <label class="label">Type</label>
                        <app-dropdown class="main--element"
                                      formControlName="emailHtmlTemplateType"
                                      [customClasses]="'dropdown_large'"
                                      [preSelectedValue]="form.value.emailHtmlTemplateType"
                                      [options]='emailHtmlTemplateTypeOptions'
                                      (changed)="onSelectedEmailHtmlTemplateTypeChanged($event)">
                        </app-dropdown>
                    </app-input-label-group>
                </div>

                <div class="cell medium-6">
                    <app-input-label-group>
                        <label class="label">Description</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Description"
                               formControlName="description">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow" *ngIf="form.value.emailHtmlTemplateType && form.value.emailHtmlTemplateType !== TERMS_AND_CONDITIONS">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-6">
                    <app-input-label-group>
                        <label class="label">Sender Email Address</label>
                        <input class="main--element input-large"
                               type="text"
                               name="SenderEmailAddress"
                               formControlName="senderEmailAddress">
                    </app-input-label-group>
                </div>

                <div class="cell medium-6">
                    <app-input-label-group>
                        <label class="label">Sender Email Name</label>
                        <input class="main--element input-large"
                               type="text"
                               name="SenderEmailName"
                               formControlName="senderEmailName">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow" *ngIf="form.value.emailHtmlTemplateType && form.value.emailHtmlTemplateType !== TERMS_AND_CONDITIONS">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Email Subject</label>
                        <input class="main--element input-large"
                               type="text"
                               name="EmailSubject"
                               formControlName="emailSubject">
                        <label *ngIf="form.value.emailHtmlTemplateType && form.value.emailHtmlTemplateType !== NOTIFICATION" class="message fix-width-message">
                            *Variables: %company%, %ordernumber%, %category%, %expirationdate%, %departuredate%, %product% and %multipass%
                        </label>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">HTML</label>
                        <textarea class="main--element input-large no-resize"
                                  rows="10"
                                  name="Html"
                                  formControlName="html"
                        >
                        </textarea>
                        <label *ngIf="form.value.emailHtmlTemplateType !== NOTIFICATION"class="message">
                            {{message}}
                        </label>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <div class="previewContainer">
                        <label class="label">Preview HTML</label>
                        <div   class="previewContent"
                               [innerHTML]="form.value.html"></div>
                    </div>
                </div>
            </div>
        </div>

    </div><!-- Dialog Content :: END -->


    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>

            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
