import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {ProductRestService} from "../../../../../../../../core/modules/rest/product/product-rest.service";
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {ProductSimpleResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {SubmitProductValidityData} from "../components/edit-product-validity-dialog-form/edit-product-validity-dialog-form.component";

@Injectable()
export class EditProductValidityDialogSandbox extends DialogSandbox {

    product$: Observable<ProductSimpleResponseModel> = this.store.pipe(select(reducer.product_selector));

    constructor(appDialogsService: AppDialogsService,
                private productRestService: ProductRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(productId: number) {

        this.productRestService.getProductSimpleById(productId)
            .subscribe((product: ProductSimpleResponseModel) => {
                this.store.dispatch(new actions.SetProduct(product));
            });
    }

    updateProductValidity(data: SubmitProductValidityData): Observable<any> {

        return this.productRestService.updateProductValidity({
            productId: data.productId,
            maximalDaysValidFor: data.maximalDaysValidFor,
            maximalDaysValidForRoundTrip: data.maximalDaysValidForRoundTrip
        });
    }
}
