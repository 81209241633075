import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild  } from '@angular/core';
import {MultiselectDropdownOptionModel, OPTION_ALL} from './multiselect-dropdown-option.model';
import {CloseDropdownOnScrollService} from '../../../directives/scroll/close-dropdown-on-scroll.service';
import {Subscription} from 'rxjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const SORT_ASC = 'ASC';
const SORT_DESC = 'DESC';

@Component({
    selector: 'app-multiselect-dropdown',
    templateUrl: './multiselect-dropdown.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiselectDropdownComponent),
            multi: true,
        }
    ]
})
export class MultiselectDropdownComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {

    @Input() loading = false;
    @Input() autocomplete = true;
    @Input() allOptionEnabled: boolean = false;
    @Input() preSelectedValues: string[] = []; // Used in ngOnChanges
    @Input() placeholderText = '- Select option(s) -';
    @Input() noOptionsText = 'No items to select';
    @Input() openedOnInit = false; // Used in ngOnChanges
    @Input() options: MultiselectDropdownOptionModel[] = []; // Used in ngOnChanges
    @Input() customClasses: string;
    @Input() positionedOptionsList = false; // Used in ngOnChanges
    @Input() disabled = false;
    @Input() sort = null;

    @Output() changed: EventEmitter<string[]> = new EventEmitter();

    @ViewChild('dropdownContainer') dropdownContainer: ElementRef;
    @ViewChild('dropdownOptionsList') optionsListContainer: ElementRef;

    opened = false;
    selectedOptionValues: string[] = [];
    hoverOptionIndex = 0;
    private closeDropdownSubscription: Subscription;

    private _options: MultiselectDropdownOptionModel[] = [];
    private _filteredOptions: MultiselectDropdownOptionModel[] = [];
    private registerOnChangeFunc: (values: string[]) => void;

    constructor(private cdr: ChangeDetectorRef, private closeDropdownService: CloseDropdownOnScrollService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.openedOnInit) {
            this.opened = this.openedOnInit;
        }

        if (changes.preSelectedValues) {
            this.evaluateSelectedValues(this.preSelectedValues);
        }

        if (changes.options) {

            // Check if options are null
            this._options = this.options ? [...this.options] : [];

            // Sort options, before adding option "All"
            if (this.sort) {
                if (this.sort === SORT_ASC) {
                    this._options.sort((o1, o2) => (o1.label.toLowerCase() > o2.label.toLowerCase()) ? 1 : -1);
                } else if (this.sort === SORT_DESC) {
                    this._options.sort((o1, o2) => (o1.label.toLowerCase() > o2.label.toLowerCase()) ? -1 : 1);
                }
            }

            // Workaround: Option "All" is sometimes added explicitly, as valid option, so even if not "allOptionsEnabled", it can still be present; put it at the first place
            let allOptionIndex = this._options.findIndex(o => o.label === "All");
            if (allOptionIndex !== -1) {
                let allOption: MultiselectDropdownOptionModel = this._options[allOptionIndex];
                this._options.splice(allOptionIndex, 1);
                this._options.unshift(allOption);
            }

            if (this.allOptionEnabled && this._options.length !== 0) {
                this._options.unshift(new MultiselectDropdownOptionModel("All", OPTION_ALL));
            }

            this._filteredOptions = this._options;
            let preSelectedValuesFiltered = this.preSelectedValues.filter(value => {
                return this._filteredOptions.some(item => item.value === value.toString());
              });

            if (this._filteredOptions.length != preSelectedValuesFiltered.length) {
                preSelectedValuesFiltered = preSelectedValuesFiltered.filter(function(value) {
                    return value !== "OPTION_ALL";
                  });
            }
            this.evaluateSelectedValues(preSelectedValuesFiltered);
        }

        if (changes.positionedOptionsList) {
            if (this.positionedOptionsList) {
                this.closeDropdownSubscription = this.closeDropdownService.parentContainerScrollOccurred()
                    .subscribe(() => {
                        if (this.opened && this.positionedOptionsList) {
                            this.closeDropdown();
                            this.cdr.detectChanges();
                        }
                    });
            } else {
                if (this.closeDropdownSubscription) {
                    this.closeDropdownSubscription.unsubscribe();
                }
            }
        }
    }

    ngOnDestroy() {
        if (this.closeDropdownSubscription) {
            this.closeDropdownSubscription.unsubscribe();
        }
    }

    hasToBeDisabled(): boolean {
        if (this.loading) {
            return true;
        } else {
            if (this._options) {
                return this._options.length === 0;
            } else {
                return true;
            }
        }
    }

    getOptions() {
        if (this.autocomplete) {
            return this._filteredOptions;
        } else {
            return this._options;
        }
    }

    getOptionId(index: number, option: MultiselectDropdownOptionModel) {
        return option.value;
    }

    isOptionActive(option: MultiselectDropdownOptionModel): boolean {
        return this.selectedOptionValues.some(value => option.value === value);
    }

    toggleDropdown() {
        this.opened = !this.opened;

        if (this.opened) {
            this.openDropdown();
        } else {
            this.closeDropdown();
        }
    }

    openDropdown() {
        this.resetAutocompleteInput();
        this.calculateOptionsListPosition();

        const customFocus = document.createEvent('HTMLEvents');
        customFocus.initEvent('focus', true, true);
        this.dropdownContainer.nativeElement.dispatchEvent(customFocus);
    }

    closeDropdown() {
        this.opened = false;

        const customBlur = document.createEvent('HTMLEvents');
        customBlur.initEvent('blur', true, true);
        this.dropdownContainer.nativeElement.dispatchEvent(customBlur);
    }

    dropdownOptionClick(selectedDropdownOption: MultiselectDropdownOptionModel, event?) {

        event.stopPropagation();

        let value = selectedDropdownOption.value;

        if (value) {
            value = String(value);
        } else {
            value = null;
        }

        const index = this.selectedOptionValues.findIndex(item => item === value);
        let values: string[] = [...this.selectedOptionValues];

        if (index === -1) {
            values.push(value);
        } else if (value === OPTION_ALL) {
            values = [];
        } else {
            values.splice(index, 1);
        }

        if (this.allOptionEnabled) {

            if (values.length !== 0) {

                if (!values.some(v => v === OPTION_ALL) && values.length === this.options.length) {
                    values.push(OPTION_ALL);
                }

                if (value !== OPTION_ALL && values.some(v => v === OPTION_ALL) && values.length - 1 !== this.options.length) {
                    values = values.filter(v => v !== OPTION_ALL);
                }
            }
        }

        this.evaluateSelectedValues(values);
        
        const filteredSelectedValues: string[] = this.selectedOptionValues.filter((v: string) => v !== OPTION_ALL);
        
        // Emit new values - form
        if (this.registerOnChangeFunc) {
            this.registerOnChangeFunc(filteredSelectedValues);
        }

        // Emit new values - event
        this.changed.emit(filteredSelectedValues);
    }

    getPrintValue() {
        if (this.loading) {
            return 'Loading...';
        } else {
            if (this.opened) {
                return '';
            } else {
                if (this.selectedOptionValues.length === 0) {
                    return this.placeholderText;
                } else if (this.selectedOptionValues.length === 1) {
                    const selectedOption: MultiselectDropdownOptionModel = this._options.find((option: MultiselectDropdownOptionModel) => option.value === this.selectedOptionValues[0]);
                    return selectedOption ? selectedOption.label : '';
                } else {
                    return 'Multiple: ' + this.selectedOptionValues.length + ' items';
                }
            }
        }
    }

    onAutocompleteInput(e) {
        const searchParam = e.target.value;
        this.filterDropdownOptionsValue(searchParam);
    }

    private resetAutocompleteInput() {
        this._filteredOptions = this._options;
    }

    private filterDropdownOptionsValue(param: string) {
        const searchParam = param.trim().toLowerCase();

        this._filteredOptions = this._options.filter((option: MultiselectDropdownOptionModel) => {
            // check if existing option matches search param
            if (option.label.toLowerCase().indexOf(searchParam) > -1) {
                return true;
            } else {
                this.hoverOptionIndex = 0;
                return false;
            }
        }).map((option: MultiselectDropdownOptionModel) => {
            return this.highlightMatchingString(option, searchParam);
        });
    }

    private highlightMatchingString(dropdownOption: MultiselectDropdownOptionModel, searchValue: string): MultiselectDropdownOptionModel {
        const value = dropdownOption.value;

        const searchValueLength: number = searchValue.length;
        const indexOfSearchValue: number = dropdownOption.label.toLowerCase().indexOf(searchValue);
        const foundSearchValue = dropdownOption.label.substr(indexOfSearchValue, searchValueLength);

        const newLabel = dropdownOption.label.replace(foundSearchValue, `<b>${foundSearchValue}</b>`);
        return new MultiselectDropdownOptionModel(newLabel, value);
    }

    private evaluateSelectedValues(values: string[]) {
        if (this.allOptionEnabled) {
            let allSelected: boolean = values && values.some((v) => v === OPTION_ALL);
            if (allSelected) {
                this.selectedOptionValues = this._options.map(mo => mo.value);
            } else {
                if (this.allOptionEnabled && this.options.length !== 0 && values && values.length === this.options.length) {
                    values.push(OPTION_ALL);
                }

                this.selectedOptionValues = values ? values.map(value => String(value)) : [];
            }

        } else {
            this.selectedOptionValues = values ? values.map(value => String(value)) : [];
        }
    }  

    private calculateOptionsListPosition() {

        if (!this.positionedOptionsList) {
            return;
        }

        const optionListContainerNativeElement = this.optionsListContainer.nativeElement;
        const dropdownContainerNativeElement = this.dropdownContainer.nativeElement;

        optionListContainerNativeElement.style.minWidth = dropdownContainerNativeElement.offsetWidth + "px";
        optionListContainerNativeElement.style.top = dropdownContainerNativeElement.getBoundingClientRect().top + dropdownContainerNativeElement.offsetHeight + "px";
        optionListContainerNativeElement.style.left = dropdownContainerNativeElement.getBoundingClientRect().left + "px";
    }

    public writeValue(values: string[]) {
        if (values) {
            this.preSelectedValues = values;
            this.evaluateSelectedValues(values);
        }

        this.cdr.detectChanges();
    }

    public registerOnChange(fn: (values: string[]) => {}) {
        this.registerOnChangeFunc = fn;
    }

    public registerOnTouched() {
    }
}
