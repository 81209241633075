import { Component, Input, OnInit  } from "@angular/core";
import {FormGroup} from "@angular/forms";

@Component({
    selector: 'app-edit-product-tier-dialog-form',
    templateUrl: './edit-product-tier-dialog-form.component.html'
})
export class EditProductTierDialogFormComponent implements OnInit {

    @Input() productTierForm: FormGroup;
    @Input() isRoundTripProduct: boolean;
    @Input() areProductTiersDisabled: boolean;

    constructor() {}

    ngOnInit() {}
}
