import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";

export interface SubmitProductValidityData {
    productId: number;
    maximalDaysValidFor: number;
    maximalDaysValidForRoundTrip: number;
}


@Component({
    selector: 'app-edit-product-validity-dialog-form',
    templateUrl: './edit-product-validity-dialog-form.component.html'
})

export class EditProductValidityDialogFormComponent implements OnChanges {

    @Input() product: ProductSimpleResponseModel;

    @Output() submit: EventEmitter<SubmitProductValidityData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        this.form = new FormGroup({
            maximalDaysValidFor: new FormControl(),
            maximalDaysValidForRoundTrip: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.product) {
            this.initializeFormData();
        }
    }

    onSubmit() {

        let maximalDaysValidFor: number = (this.form.value.maximalDaysValidFor !== null && this.form.value.maximalDaysValidFor !== undefined) ? +this.form.value.maximalDaysValidFor : null;
        let maximalDaysValidForRoundTrip: number = (this.form.value.maximalDaysValidForRoundTrip !== null && this.form.value.maximalDaysValidForRoundTrip !== undefined) ? +this.form.value.maximalDaysValidForRoundTrip : null;

        if (maximalDaysValidFor === null || maximalDaysValidFor === undefined || isNaN(maximalDaysValidFor)) {
            this.hasError = true;
            this.errorMessage = "Please enter one way validity";
            return;
        }

        if (maximalDaysValidFor <= 0) {
            this.hasError = true;
            this.errorMessage = "Please enter valid value for one way validity";
            return;
        }

        if (this.product.isRoundTripProduct) {
            if (maximalDaysValidForRoundTrip === null || maximalDaysValidForRoundTrip === undefined || isNaN(maximalDaysValidForRoundTrip)) {
                this.hasError = true;
                this.errorMessage = "Please enter round trip validity";
                return;
            }

            if (maximalDaysValidForRoundTrip <= 0) {
                this.hasError = true;
                this.errorMessage = "Please enter valid value for round trip validity";
                return;
            }
        }

        this.submit.emit({
            productId: this.product.productId,
            maximalDaysValidFor: maximalDaysValidFor,
            maximalDaysValidForRoundTrip: maximalDaysValidForRoundTrip
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {
        this.form.patchValue({
            maximalDaysValidFor: this.product.maximalDaysValidFor,
            maximalDaysValidForRoundTrip: this.product.maximalDaysValidForRoundTrip
        });
    }
}
