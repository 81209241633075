import { NgModule } from "@angular/core";
import { EditSaleGroupDialogComponent } from "./edit-sale-group-dialog/container/edit-sale-group-dialog.component";
import { StoreModule } from "@ngrx/store";
import { EditSaleGroupDialogSandbox } from "./edit-sale-group-dialog/sandbox/edit-sale-group-dialog.sandbox";
import { REDUCER_NAME } from "../store/reducers";
import { reducer } from "../store/main/reducer";
import { SharedModule } from "../../../../../../shared/shared.module";
import { EditSaleGroupFormComponent } from "./edit-sale-group-dialog/components/edit-sale-group-form/edit-sale-group-form.component";



@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditSaleGroupDialogSandbox
    ],
    declarations: [
        EditSaleGroupDialogComponent,
        EditSaleGroupFormComponent
    ],
    entryComponents: [
        EditSaleGroupDialogComponent
    ],
    exports: [
        EditSaleGroupDialogComponent
    ]
})
export class EditSaleGroupDialogModule {

}
