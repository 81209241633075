import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {PassRestService} from "../../../../../../../../core/modules/rest/pass/pass-rest.service";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {SubmitPassData} from "../components/add-pass-dialog-form/add-pass-dialog-form.component";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";

@Injectable()
export class AddPassDialogSandbox extends DialogSandbox {

    constructor(appDialogsService: AppDialogsService,
                private passRestService: PassRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    createPass(data: SubmitPassData): Observable<CreateBaseResponseModel> {

        return this.passRestService.create({
            description: data.description
        });
    }
}
