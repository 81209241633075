import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PassOrderDetailSummaryResponseModel } from "../../../../../../../core/modules/rest/order-summary/response/pass-order-detail-summary-response.model";
import {
    CustomFieldDataModel,
    CustomFieldsEventDataModel
} from "../../general-info-dialog-summary/general-info-dialog-summary.component";
import { CustomFieldSummaryResponseModel } from "../../../../../../../core/modules/rest/order-summary/response/custom-field-summary-response.model";
import { TicketCheckInSummaryResponseModel } from "../../../../../../../core/modules/rest/order-summary/response/ticket-check-in-summary-response.model";
import { PassCardSummaryResponseModel } from "../../../../../../../core/modules/rest/order-summary/response/pass-card-summary-response.model";
import { OrderStatusEnum } from '../../../../../../enums/order-status.enum';
import { PassCardCheckInSummaryResponseModel } from '../../../../../../../core/modules/rest/order-summary/response/pass-card-check-in-summary-response.model';

export interface EditPassOrderDetailEventDataModel {
    passOrderDetailId: number;
    customFields: CustomFieldsEventDataModel[];
}


@Component({
    selector: 'app-pass-detail-dialog-summary',
    templateUrl: './pass-detail-dialog-summary.component.html'
})
export class PassDetailDialogSummaryComponent implements OnChanges {

    @Input() passOrderDetail: PassOrderDetailSummaryResponseModel;
    @Input() hasEditPermission: boolean;
    @Input() hasCompletedEditPermission: boolean;
    @Input() editEnabled: boolean;

    @Output() saveClicked: EventEmitter<EditPassOrderDetailEventDataModel> = new EventEmitter();
    @Output() editClicked: EventEmitter<number> = new EventEmitter();
    @Output() cancelClicked: EventEmitter<any> = new EventEmitter();

    editMode: boolean = false;
    passCardsDialogData: PassCardSummaryDialogData[];
    saveEnabled: boolean = false;
    invalidTicketCheckinExists: boolean = false;
    invalidPassCheckinExists: boolean = false;

    customFields: CustomFieldDataModel[] = [];

    constructor() {
    }

    passCardsExist(): boolean {
        return this.passOrderDetail && this.passOrderDetail.passCards && this.passOrderDetail.passCards.length !== 0;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.passOrderDetail && Object.keys(changes.passOrderDetail.currentValue).length !== 0) {
            this.initCustomFields();
            this.passCardsDialogData = this.getPassCardSummaryDialogData(this.passOrderDetail);
        }
        if (this.passOrderDetail.orderStatus !== OrderStatusEnum.COMPLETE && this.hasCompletedEditPermission) {
            this.hasCompletedEditPermission = false;
        }
        if (this.passOrderDetail.orderStatus === OrderStatusEnum.COMPLETE && this.hasEditPermission) {
            this.hasEditPermission = false;
        }
    }

    isEditEnabled() {
        return !this.hasEditPermission && !this.hasCompletedEditPermission
    }

    onEditClick() {
        this.editMode = true;
        this.editClicked.emit(this.passOrderDetail.passOrderDetailId);
    }

    onCancelClick() {
        this.editMode = false;
        this.initCustomFields();
        this.cancelClicked.emit();
    }

    onSaveClick() {

        this.saveClicked.emit({
            passOrderDetailId: this.passOrderDetail.passOrderDetailId,
            customFields: this.customFields.map(cf => {
                return {
                    fieldValue: cf.fieldValue,
                    templateItemId: cf.templateItemId,
                    partyMember: cf.partyMember
                };
            })
        });

        this.editMode = false;
    }

    onDataEdit(): void {
        this.saveEnabled = this.hasChanges();
    }

    private hasChanges(): boolean {

        let hasChanges: boolean = false;

        this.customFields.forEach(cf => {

            let odf: CustomFieldSummaryResponseModel = this.passOrderDetail.passOrderDetailFields
                .find(of => of.templateItemId === cf.templateItemId && of.partyMember === cf.partyMember);

            if (odf.fieldValue !== cf.fieldValue) {
                hasChanges = true;
                return;
            }
        });

        return hasChanges;
    }

    private initCustomFields() {

        this.customFields = [];

        this.passOrderDetail.passOrderDetailFields.forEach(odf => {
            this.customFields.push({
                templateItemId: odf.templateItemId,
                templateItemDescription: odf.templateItemDescription,
                partyMember: odf.partyMember,
                fieldValue: odf.fieldValue
            });
        });
    }

    getLocationDescriptionForTicketCheckIn(ticketCheckIn: TicketCheckInSummaryResponseModel): string {
        if (ticketCheckIn.startLocationDescription && ticketCheckIn.startLocationDescription.length > 0) {
            return ticketCheckIn.startLocationDescription;
        } else {
            return "N/A";
        }
    }

    getLocationDescriptionForPassCardCheckIn(passCardCheckIn: PassCardCheckInSummaryResponseModel): string {
        if (passCardCheckIn.startLocationDescription && passCardCheckIn.startLocationDescription.length > 0) {
            return passCardCheckIn.startLocationDescription;
        } else {
            return "N/A";
        }
    }

    onToggle(passCard: PassCardSummaryDialogData) {
        passCard.opened = !passCard.opened;
    }

    invalidTicketCheckInExist() {
        for (let passCard of this.passOrderDetail.passCards) {
            for (let ticketCheckin of passCard.ticketCheckIns) {
                if (ticketCheckin.failedScan) {
                    this.invalidTicketCheckinExists = true;
                    return true;
                }
            }
        }
        return false;
    }

    invalidPassCardCheckInExist() {
        for (let passCard of this.passOrderDetail.passCards) {
            for (let passCardCheckin of passCard.passCardCheckIns) {
                if (passCardCheckin.failedScan) {
                    this.invalidPassCheckinExists = true;
                    return true;
                }
            }
        }
        return false;
    }

    getPassCardSummaryDialogData(passOrderDetail: PassOrderDetailSummaryResponseModel): PassCardSummaryDialogData[] {

        if (!passOrderDetail.passCards) {
            return [];
        }

        let data: PassCardSummaryDialogData[] = passOrderDetail.passCards.map((passCards: PassCardSummaryResponseModel) => {
            return {
                passCardId: passCards.passCardId,
                passOrderDetailId: passCards.passOrderDetailId,
                passId: passCards.passId,
                tierId: passCards.tierId,
                passCardExternalId: passCards.passCardExternalId,
                createDateTime: passCards.createDateTime,
                lastUpdateDateTime: passCards.lastUpdateDateTime,
                createdById: passCards.createdById,
                lastUpdatedById: passCards.lastUpdatedById,
                createdByDisplayName: passCards.createdByDisplayName,
                lastUpdatedByDisplayName: passCards.lastUpdatedByDisplayName,
                createdByRoleDescription: passCards.createdByRoleDescription,
                lastUpdatedByRoleDescription: passCards.lastUpdatedByRoleDescription,
                active: passCards.active,
                barcode: passCards.barcode,
                isValidForDateInterval: passCards.isValidForDateInterval,
                validForStartDateTime: passCards.validForStartDateTime,
                validForEndDateTime: passCards.validForEndDateTime,
                isValidSincePurchaseForHours: passCards.isValidSincePurchaseForHours,
                validForHours: passCards.validForHours,
                isUsageLimiterPerOccurrence: passCards.isUsageLimiterPerOccurrence,
                usageLimiterForMinutes: passCards.usageLimiterForMinutes,
                purchaseDateTime: passCards.purchaseDateTime,
                firstCheckInDateTime: passCards.firstCheckInDateTime,
                lastCheckInDateTime: passCards.lastCheckInDateTime,
                startLocationId: passCards.startLocationId,
                startLocationDescription: passCards.startLocationDescription,
                stopLocationId: passCards.stopLocationId,
                stopLocationDescription: passCards.stopLocationDescription,
                invalidDueToReprint: passCards.invalidDueToReprint,
                isUnlimited: passCards.isUnlimited,
                initialQuantity: passCards.initialQuantity,
                remainingQuantity: passCards.remainingQuantity,
                totalUsed: passCards.totalUsed,
                expiration: passCards.expiration,
                createDateTimeFriendly: passCards.createDateTimeFriendly,
                lastUpdateDateTimeFriendly: passCards.lastUpdateDateTimeFriendly,
                purchaseDateTimeFriendly: passCards.purchaseDateTimeFriendly,
                firstCheckInDateTimeFriendly: passCards.firstCheckInDateTimeFriendly,
                lastCheckInDateTimeFriendly: passCards.lastCheckInDateTimeFriendly,
                ticketCheckIns: [...passCards.ticketCheckIns],
                passCardCheckIns: [...passCards.passCardCheckIns],
                opened: true
            };
        });

        return data;
    }
}


interface PassCardSummaryDialogData extends PassCardSummaryResponseModel {
    opened: boolean;
}

