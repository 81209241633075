<div *ngIf="!shouldOverrideStyle" [ngClass]="classList">
    <div class="reveal-overlay_wrapper">
        <div class="reveal-overlay">
            <div class="reveal-container">
                <div class="reveal {{ sizeClass }}" #dialogContainer>

                    <div class="closeButton_wrapper">
                        <a class="closeButton_container" (click)="onClickClose()">
                            <span class="closeButton">
                                <i class="fal fa-times"></i>
                            </span>
                        </a>
                    </div>

                    <div class="revealContent_wrapper">
                        <app-loader *ngIf="dialogLoaderVisible$ | async"></app-loader>
                        <div>
                            <ng-content></ng-content>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="shouldOverrideStyle">
    <div class="dialog-new-template" [ngClass]="classList">
        <div class="dialog-mask" (click)="onClickClose()"></div>
        <div class="dialog-content">
            <app-loader *ngIf="dialogLoaderVisible$ | async"></app-loader>
            <div>
                <i class="fal fa-times dialog-close-icon" (click)="onClickClose()"></i>
                <ng-content select="[alt-design]"></ng-content>
            </div>
        </div>
    </div>
</div>
