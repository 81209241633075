import {LocationResponseModel} from '../../../../../../../../../core/modules/rest/location/response/location-response.model';
import {AdditionalCostModel} from '../../model/additional-cost.model';
import {createFeatureSelector, createSelector} from "@ngrx/store";

import * as actions from './actions';

export interface State {
    dialogDataLoaded: boolean;
    editAdditionalCost: boolean;
    additionalCost: AdditionalCostModel;
    allLocations: LocationResponseModel[];
}

const initialState: State = {
    dialogDataLoaded: false,
    editAdditionalCost: false,
    additionalCost: new AdditionalCostModel(),
    allLocations: [],
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.UPDATE_DIALOG_DATA_LOADED_ACTION:
            return handleUpdateDialogDataLoadedAction(state, action);
        case actions.UPDATE_EDIT_ADDITIONAL_COST_ACTION:
            return handleUpdateEditAdditionalCostAction(state, action);
        case actions.RESET_STATE_ACTION:
            return handleResetStateAction(state, action);
        case actions.UPDATE_ADDITIONAL_COST_ACTION:
            return handleUpdateAdditionalCostAction(state, action);
        case actions.UPDATE_ALL_LOCATIONS_ACTION:
            return handleUpdateAllLocationsAction(state, action);
        default:
            return state;
    }
}


// #################################################### SELECTORS ####################################################
export const REDUCER_NAME = 'dialog_edit-additional-cost';

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const dialogDataLoaded_selector = createSelector(state_selector, (state: State) => state.dialogDataLoaded);
export const editAdditionalCost_selector = createSelector(state_selector, (state: State) => state.editAdditionalCost);
export const additionalCost_selector = createSelector(state_selector, (state: State) => state.additionalCost);
export const allLocations_selector = createSelector(state_selector, (state: State) => state.allLocations);


// #################################################### HANDLERS ####################################################
function handleUpdateDialogDataLoadedAction(state: State, action: actions.UpdateDialogDataLoaded) {
    return {
        ...state,
        dialogDataLoaded: action.value
    };
}

function handleResetStateAction(state: State, action: actions.ResetState) {
    return initialState;
}

function handleUpdateAdditionalCostAction(state: State, action: actions.UpdateAdditionalCost) {
    return {
        ...state,
        additionalCost: action.additionalCost
    };
}

function handleUpdateAllLocationsAction(state: State, action: actions.UpdateAllLocations) {
    return {
        ...state,
        allLocations: action.locations
    };
}

function handleUpdateEditAdditionalCostAction(state: State, action: actions.UpdateEditAdditionalCost) {
    return {
        ...state,
        editAdditionalCost: action.value
    };
}
