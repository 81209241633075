import {Action} from "@ngrx/store";
import {KioskConfigurationDropoffLocationProductTierModel} from "../../model/kiosk-configuration-dropoff-location-product-tier.model";


export const RESET_STATE_ACTION = "[Dialog Product Tier Setup :: MAIN] Reset State";

export const SET_PRODUCT_TIERS = "[Dialog Product Tier Setup :: MAIN] Set Product Tiers";

export class ResetStateAction implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class SetProductTiers implements Action {
    readonly type = SET_PRODUCT_TIERS;

    constructor(public productTiers: KioskConfigurationDropoffLocationProductTierModel[]) {
    }
}


export type All = ResetStateAction | SetProductTiers;
