import {Action} from "@ngrx/store";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export const RESET_STATE_ACTION = '[Dialog - Activate Category :: MAIN] Reset State';
export const SET_PRODUCTS_ACTION = '[Dialog - Activate Category :: MAIN] Set Products';

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class SetProducts implements Action {
    readonly type = SET_PRODUCTS_ACTION;

    constructor(public products: DropdownOptionModel[]) {
    }
}

export type All = ResetState | SetProducts;
