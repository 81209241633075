import * as actions from './actions';
import {TreeItemTypeEnum} from "../../model/tree-item-type.enum";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {TreeNode} from "../../../../../../../shared/components/tree/tree.component";
import {PermissionNode} from "../../model/permission-node";
import { PermissionItemNode } from '../../../../../../../shared/components/tree-permission/tree-permission.component';

export const REDUCER_NAME: string = "feature_manage_customer_owners";

export interface State {

    item: TreeNode;
    node: TreeNodeMaterial;
    itemType: TreeItemTypeEnum;
    itemId: number;
    itemActive: boolean;

    customerOwnersTree: TreeNode[];
    permissionTree: PermissionNode[];
    permissionTreeMaterial: PermissionItemNode[];

    loadingCustomerOwners: boolean;
    loadingPermissionsTree: boolean;

    customerOwnersPermissionTree: PermissionNode[];

    formHasChanges: boolean;
}

export const INITIAL_STATE: State = {

    item: {} as TreeNode,
    node: {} as TreeNodeMaterial,
    itemType: null,
    itemId: null,
    itemActive: false,

    customerOwnersTree: [],
    permissionTree: [],
    permissionTreeMaterial: [],

    loadingCustomerOwners: false,
    loadingPermissionsTree: false,

    customerOwnersPermissionTree: [],

    formHasChanges: false
};

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const item_selector = createSelector(state_selector, (state: State) => state.item);

export const node_selector = createSelector(state_selector, (state: State) => state.node);

export const item_id_selector = createSelector(state_selector, (state: State) => state.itemId);

export const item_active_selector = createSelector(state_selector, (state: State) => state.itemActive);

export const item_type_selector = createSelector(state_selector, (state: State) => state.itemType);

export const customer_owners_tree_selector = createSelector(state_selector, (state: State) => state.customerOwnersTree);

export const permission_tree_selector = createSelector(state_selector, (state: State) => state.permissionTree);

export const permission_tree_material_selector = createSelector(state_selector, (state: State) => state.permissionTreeMaterial);

export const loading_customer_owners_selector = createSelector(state_selector, (state: State) => state.loadingCustomerOwners);

export const loading_permissions_tree_selector = createSelector(state_selector, (state: State) => state.loadingPermissionsTree);

export const customer_owners_permission_tree_selector = createSelector(state_selector, (state: State) => state.customerOwnersPermissionTree);

export const formHasChanges_selector = createSelector(state_selector, (state: State) => state.formHasChanges);

export function reducer(state: State = INITIAL_STATE, action: actions.All): State {

    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;
        case actions.SET_SELECTED_ITEM:
            return {
                ...state,
                item: action.item
            };
        case actions.SET_SELECTED_NODE:
            return {
                ...state,
                node: action.node
            };
        case actions.SET_CUSTOMER_OWNER:
            return {
                ...state,
                itemType: action.itemType,
                itemId: action.itemId,
                itemActive: action.itemActive
            };
        case actions.SET_CUSTOMER_OWNERS_TREE:
            return {
                ...state,
                customerOwnersTree: action.tree
            };
        case actions.SET_PERMISSION_TREE:
            return {
                ...state,
                permissionTree: action.tree
            };
        case actions.SET_PERMISSION_TREE_MATERIAL:
            return {
                ...state,
                permissionTreeMaterial: action.treeMaterial
            };
        case actions.SET_LOADING_CUSTOMER_OWNERS:
            return {
                ...state,
                loadingCustomerOwners: action.loading
            };
        case actions.SET_LOADING_PERMISSIONS_TREE:
            return {
                ...state,
                loadingPermissionsTree: action.loading
            };
        case actions.SET_CUSTOMER_OWNERS_PERMISSION_TREE:
            return {
                ...state,
                customerOwnersPermissionTree: action.tree
            };
        case actions.SET_FORM_HAS_CHANGES:
            return {
                ...state, formHasChanges: action.data
            };
        default:
            return state;
    }
}
