import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {BusResponseModel} from "../../../../../../../../core/modules/rest/bus/response/bus-response.model";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {BusRestService} from "../../../../../../../../core/modules/rest/bus/bus-rest.service";
import {Store} from "@ngrx/store";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {ProductSimpleResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {ProductRestService} from "../../../../../../../../core/modules/rest/product/product-rest.service";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import { catchError } from "rxjs/operators";
import { UpdateProductBusMappingRequestModel } from "../../../../../../../../core/modules/rest/product/request/update-product-bus-mapping-request.model";
import { ProductBusMappingResponseModel } from "../../../../../../../../core/modules/rest/product/response/product-bus-mapping-response.model";

@Injectable()
export class MappingBusDialogSandbox extends DialogSandbox {

    bus$: Observable<BusResponseModel> = this.store.select(reducer.bus_selector);

    productBusMappingItems$: Observable<ProductBusMappingResponseModel[]> = this.store.select(reducer.product_bus_mapping_items_selector);
    productBusMappingItemsLoading$: Observable<boolean> = this.store.select(reducer.product_bus_mapping_items_loading_selector);


    constructor(appDialogsService: AppDialogsService,
                private busRestService: BusRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox,
                private productRestService: ProductRestService) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadBus(busId: number) {

        this.showLoader();

        this.busRestService.getById(busId).subscribe((bus: BusResponseModel) => {
            this.store.dispatch(new actions.SetBus(bus));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    loadProductBusMappingItems(busId: number) {
        this.store.dispatch(new actions.SetProductBusMappingItemsLoading(true));
        this.productRestService.getProductBusMappingByBusId(busId)
            .subscribe((products: ProductBusMappingResponseModel[]) => {
                this.store.dispatch(new actions.SetProductBusMappingItems(products));
                this.store.dispatch(new actions.SetProductBusMappingItemsLoading(false));
            },
            () => this.store.dispatch(new actions.SetProductBusMappingItemsLoading(false)));
    }

    updateProductBusMapping(busId: number, unselectedProductIds: number[], selectedProductIds: number[]): Observable<any> {

        const request = new UpdateProductBusMappingRequestModel(
            busId,
            unselectedProductIds,
            selectedProductIds
        );

        return this.productRestService.updateProductBusMapping(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating product - bus mapping', error);
            }));
    }

}
