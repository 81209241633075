<div class="dialog-section-header">
    <h3>{{orderDetail.productDescription}}</h3>
    <div class="dialog-section-edit-options" [ngClass]="{'remove': isEditEnabled()}">
        <div class="edit-option" (click)="onEditClick()" [ngClass]="{'disabled': editMode || !editEnabled}">
            <i class="fas fa-pencil" [ngClass]="{'disabled': editMode || !editEnabled}"></i>
            <span>Edit</span>
        </div>
        <div class="delete-option" (click)="onNoShowClicked(orderDetail.orderDetailId)" [ngClass]="{'disabled': checkIsNoShowDisabled()}">
            <i class="fas fa-times-circle" [ngClass]="{'disabled': checkIsNoShowDisabled()}"></i>
            <span>No show</span>
        </div>
    </div>
</div>

<!-- VIEW MODE -->
<div class="summary-general-info-wrapper" [ngClass]="{'remove': editMode}">
    <div class="summary-general-info">
        <div class="summary-data-wrapper" [ngClass]="{'remove': !showPickupLocation()}">
            <p>Pick Up Location</p>
            <p>{{getLocationDescription(orderDetail.startLocationDescription, orderDetail.customStartLocationDescription)}}</p>
        </div>
        <div class="summary-data-wrapper" [ngClass]="{'remove': !showDropoffLocation()}">
            <p>Drop Off Location</p>
            <p>{{getLocationDescription(orderDetail.stopLocationDescription, orderDetail.customStopLocationDescription)}}</p>
        </div>
        <div class="summary-data-wrapper" *ngFor="let orderDetailTier of orderDetail.orderDetailTiers; let i = index">
            <ng-container *ngIf="i === 0">
                <p>Tiers</p>
            </ng-container>
            <ng-container *ngIf="i > 0">
                <p></p>
            </ng-container>
            <p>
                <span>{{orderDetailTier.quantity}}</span>
                <span>x</span>
                <span>{{orderDetailTier.tierDescription}}</span>
            </p>
        </div>
        <div class="summary-data-wrapper" *ngFor="let orderDetailField of orderDetail.orderDetailFields">
            <p>{{orderDetailField.templateItemDescription}}</p>
            <p>{{orderDetailField.fieldValue}}</p>
        </div>
    </div>

    <div class="summary-general-info">
        <div class="summary-data-wrapper">
            <p>Departure Date</p>
            <p>{{orderDetail.occurrenceDateFriendly}}</p>
        </div>
        <div class="summary-data-wrapper">
            <p>Departure Time</p>
            <p>{{orderDetail.occurrenceStartTimeWithOffsetFriendly}}</p>
        </div>
        <div class="summary-data-wrapper">
            <p>Status</p>
            <p>{{orderDetail.orderStatusDescription}}</p>
        </div>
        <ng-container *ngIf="orderDetail.tickets[0]">
            <div class="summary-data-wrapper">
                <p>Expires on</p>
                <p>{{orderDetail.tickets[0].validUntilDateTimeFriendly}}</p>
            </div>
        </ng-container>
    </div>
</div>

<!-- EDIT MODE -->
<div class="summary-general-info-wrapper editable" [ngClass]="{'remove': !editMode}">
    <div class="summary-general-info">

        <div class="summary-data-wrapper h-38" [ngClass]="{'remove': !showPickupLocation()}">
            <p>Pick Up Location</p>
            <p>{{getLocationDescription(orderDetail.startLocationDescription, orderDetail.customStartLocationDescription)}}</p>
        </div>

        <div class="summary-data-wrapper h-38" [ngClass]="{'remove': !showDropoffLocation()}">
            <p>Drop Off Location</p>
            <p>{{getLocationDescription(orderDetail.stopLocationDescription, orderDetail.customStopLocationDescription)}}</p>
        </div>

        <div class="summary-data-wrapper" *ngFor="let orderDetailTier of orderDetail.orderDetailTiers; let i = index">
            <ng-container *ngIf="i === 0">
                <p>Tiers</p>
            </ng-container>
            <ng-container *ngIf="i > 0">
                <p></p>
            </ng-container>
            <p>
                <span>{{orderDetailTier.quantity}}</span>
                <span>x</span>
                <span>{{orderDetailTier.tierDescription}}</span>
            </p>
        </div>

        <div class="summary-data-wrapper h-38 summary-data-wrapper-custom"
             *ngFor="let customField of customFields">
            <p>{{customField.templateItemDescription}}</p>
            <input [(ngModel)]="customField.fieldValue" (input)="onDataEdit()">
        </div>
    </div>

    <div class="summary-general-info">
        <div class="summary-data-wrapper h-38 summary-data-wrapper-departure">
            <p>Departure Date</p>
            <p *ngIf="!canEditOrderDetailOccurrence">{{orderDetail.occurrenceDateFriendly}}</p>
            <div *ngIf="canEditOrderDetailOccurrence" class="order-input-wrapper">
                <app-datetimepicker
                    [startDateValue]="selectedDateMoment"
                    (dateChanged)="onSelectedDateChanged($event)">
                </app-datetimepicker>
            </div>
        </div>
        <div class="summary-data-wrapper h-38">
            <p>Departure Time</p>
            <p *ngIf="!canEditOrderDetailOccurrence">{{orderDetail.occurrenceStartTimeWithOffsetFriendly}}</p>
            <div *ngIf="canEditOrderDetailOccurrence" class="order-input-wrapper">
                <app-dropdown
                    [usePlaceholder]="false"
                    [loading]="startTimesLoading"
                    [options]="startTimesOptions"
                    [preSelectedValue]="selectedProductAvailabilityId ? selectedProductAvailabilityId.toString() : AVAILABILITY_ANY_TIME.toString()"
                    (changed)="onSelectedProductAvailabilityChanged($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="summary-data-wrapper h-38">
            <p>Status</p>
            <p>{{orderDetail.orderStatusDescription}}</p>
        </div>
        <ng-container *ngIf="orderDetail.tickets[0]">
            <div class="summary-data-wrapper h-38">
                <p>Expires on</p>
                <p>{{orderDetail.tickets[0].validUntilDateTimeFriendly}}</p>
            </div>
        </ng-container>
    </div>

    <div class="summary-general-button-wrapper">
        <button class="button cancel-button" (click)="onCancelClick()">Cancel</button>
        <button class="button primaryButton" (click)="onSaveClick()" [disabled]="!saveEnabled">Save</button>
    </div>
</div>

<div class="placeholderText-emptySection" *ngIf="!ticketCheckInsExist()">
    No Usage Info to Display
</div>

<ng-container *ngIf="ticketCheckInsExist()">
    <div class="summary-general-info-wrapper summary-scroll-wrapper">
        <h4>Usage Info</h4>
        <table style="width:100%">
            <thead>
            <tr>
                <th *ngIf="invalidTicketCheckInExist()"></th>
                <th>Direction</th>
                <th>Trip Name</th>
                <th>Occurrence Date/Time</th>
                <th>Check in Date/Time</th>
                <th>Check in Location</th>
                <th>Bus</th>
                <th>Driver</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let ticket of orderDetail.tickets">
                <ng-container *ngFor="let ticketCheckIn of ticket.ticketCheckIns">
                    <tr *ngIf="!ticket.invalidDueToReprint">
                        <td class="width-5px" title="Invalid Check-in" *ngIf="invalidTicketCheckinExists && ticketCheckIn.failedScan">
                            <i class="fa fa-exclamation-triangle"></i>
                        </td>
                        <td class="width-5px" *ngIf="invalidTicketCheckinExists && !ticketCheckIn.failedScan"></td>
                        <td>{{ticketCheckIn.directionDescription}}</td>
                        <td>{{ticketCheckIn.productAvailabilityDescription}}</td>
                        <td>{{ticketCheckIn.occurrenceDateTimeFriendly ? ticketCheckIn.occurrenceDateTimeFriendly : 'N/A'}}</td>
                        <td>{{ticketCheckIn.checkInDateTimeFriendly ? ticketCheckIn.checkInDateTimeFriendly : 'N/A'}}</td>
                        <td>
                            <div *ngIf="ticketCheckIn.startLocationId">
                                {{getLocationDescriptionForTicketCheckIn(ticketCheckIn)}}
                            </div>
                            <div *ngIf="!ticketCheckIn.startLocationId && (!ticketCheckIn.startLocationLatitude || !ticketCheckIn.startLocationLongitude)">
                                {{getLocationDescriptionForTicketCheckIn(ticketCheckIn)}} (NO GPS DATA)
                            </div>
                            <div *ngIf="!ticketCheckIn.startLocationId && ticketCheckIn.startLocationLatitude && ticketCheckIn.startLocationLongitude">
                                <a href="https://www.google.com/maps/place/{{ticketCheckIn.startLocationLatitude}},{{ticketCheckIn.startLocationLongitude}}" target="_blank">
                                    {{getLocationDescriptionForTicketCheckIn(ticketCheckIn)}} ({{ticketCheckIn.startLocationLatitude}},{{ticketCheckIn.startLocationLongitude}})
                                </a>
                            </div>
                        </td>
                        <td>{{ticketCheckIn.busNumber ? ticketCheckIn.busNumber : 'N/A'}}</td>
                        <td>{{ticketCheckIn.driverDisplayName ? ticketCheckIn.driverDisplayName : 'N/A'}}</td>
                    </tr>
                </ng-container>
            </ng-container>
            </tbody>
        </table>
    </div>
</ng-container>
