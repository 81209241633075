import {Action} from "@ngrx/store";
import {NotificationResponseModel} from "../../../../../../../../../core/modules/rest/notification/response/notification-response.model";
import { NotificationBaseUserResponseModel } from "../../../../../../../../../core/modules/rest/notification/response/notification-base-user-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Notification :: MAIN] Reset State';
export const SET_NOTIFICATION_ACTION = '[Dialog - Create/Edit Notification :: MAIN] Set Notification';
export const SET_NOTIFICATION_BASE_USER_ACTION = '[Dialog - Create/Edit Notification :: MAIN] Set Notification Base User';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetNotification implements Action {
    readonly type = SET_NOTIFICATION_ACTION;
    constructor(public notification: NotificationResponseModel) {}
}

export class SetNotificationBaseUser implements Action {
    readonly type = SET_NOTIFICATION_BASE_USER_ACTION;
    constructor(public notificationBaseUser: NotificationBaseUserResponseModel[]) {}
}

export type All = ResetState | SetNotification | SetNotificationBaseUser;
