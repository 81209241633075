import {Action} from "@ngrx/store";
import {PricingDateRangeResponseModel} from "../../../../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-response.model";

export const RESET_STATE = '[Dialog - Clone Product Pricing Date Ranges :: MAIN] Reset State';
export const SET_PRICING_DATE_RANGES = '[Dialog - Clone Product Pricing Date Ranges :: MAIN] Set Pricing Date Ranges';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetPricingDateRanges implements Action {
    readonly type = SET_PRICING_DATE_RANGES;

    constructor(public pricingDateRanges: PricingDateRangeResponseModel[]) {
    }
}

export type All = ResetState
    | SetPricingDateRanges;
