import {Observable} from "rxjs";
import {MultiselectDropdownOptionModel} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {MappingCostDialogSandbox} from "../sandbox/mapping-cost-dialog.sandbox";
import {SubmitCostMappingsData} from "../components/mapping-cost-dialog-form/mapping-cost-dialog-form.component";

export class MappingCostDialogData {

    costId: number;
    costDescription: string;
    successCallbackFn: Function;

    constructor(successCallbackFn: () => void, costId: number, costDescription: string) {
        this.costId = costId;
        this.costDescription = costDescription;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-mapping-cost-dialog',
    templateUrl: './mapping-cost-dialog.component.html'
})
export class MappingCostDialogComponent implements OnDestroy {

    costId: number;
    costDescription: string;
    successCallbackFn: Function;

    loading$: Observable<boolean>;
    categories$: Observable<MultiselectDropdownOptionModel[]>;
    mappedCategories$: Observable<number[]>;
    products$: Observable<MultiselectDropdownOptionModel[]>;
    mappedProducts$: Observable<number[]>;
    passes$: Observable<MultiselectDropdownOptionModel[]>;
    mappedPasses$: Observable<number[]>;

    constructor(@Inject(DialogData) data: MappingCostDialogData, private sandbox: MappingCostDialogSandbox) {

        this.costId = data.costId;
        this.costDescription = data.costDescription;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.costId);

        this.loading$ = this.sandbox.loading$;
        this.categories$ = this.sandbox.categories$;
        this.mappedCategories$ = this.sandbox.mappedCategories$;
        this.products$ = this.sandbox.products$;
        this.mappedProducts$ = this.sandbox.mappedProducts$;
        this.passes$ = this.sandbox.passes$;
        this.mappedPasses$ = this.sandbox.mappedPasses$;
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitCostMappingsData) {
        this.sandbox.updateCostMappings(data).subscribe(() => {
            this.successCallbackFn(data.costId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
