import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {ExpressionGroupModel} from '../../../../model/expression-group.model';
import {
    DRAG_GROUP_IMAGE,
    DRAG_N_DROP_DEFAULT_ICON
} from '../../../../../../../../../core/services/app-properties.service';
import {DroppedDataI} from '../../../../model/dropped-data.model';
import {DroppedGroupDataModel} from '../../../../model/dropped-group-data.model';
import {DraggedDataI} from '../../../../model/dragged-data.model';
import {DraggedGroupDataModel} from '../../../../model/dragged-group-data.model';
import {UpdateExpressionItemValueModel} from '../../../../model/update-expression-item-value.model';
import {ExpressionItemModel} from '../../../../model/expression-item.model';
import {MAX_DEPTH} from '../../../../model/data';
import {DraggedFilterDataModel} from '../../../../model/dragged-filter-data.model';
import {detect} from 'detect-browser';

const browser = detect();

@Component({
    selector: 'app-order-reports-expression-group',
    templateUrl: './order-reports-expression-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrderReportsExpressionGroupComponent implements OnInit {

    @Input() itemDepth: number;
    @Input() draggedItemHeight: number;
    @Input() expressionGroupItem: ExpressionGroupModel;
    @Input() draggedData: DraggedDataI;
    @Input() dropAreasVisible: boolean;
    @Input() hasRunOrderReportFullPermission: boolean;
    @Output() droppedDataEvent: EventEmitter<DroppedDataI> = new EventEmitter<DroppedDataI>();
    @Output() deleteExpressionItemEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() dragStartEvent: EventEmitter<DraggedDataI> = new EventEmitter<DraggedDataI>();
    @Output() dragEndEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() expressionItemValueChangedEvent: EventEmitter<UpdateExpressionItemValueModel> = new EventEmitter<UpdateExpressionItemValueModel>();

    browser = browser;
    isIE = this.browser.name === 'ie' || this.browser.name === 'edge';

    maxDepth = MAX_DEPTH;
    dragImage = DRAG_GROUP_IMAGE;
    draggableIconDefault = DRAG_N_DROP_DEFAULT_ICON;
    isDragged = false;

    constructor() {
    }

    ngOnInit() {
    }

    onDroppedData(droppedData: DroppedDataI) {
        this.droppedDataEvent.emit(droppedData);
    }

    deleteThisItem() {
        this.deleteExpressionItemEvent.emit(this.expressionGroupItem.uuid);
    }

    onDeleteExpressionItem(uuid: string) {
        this.deleteExpressionItemEvent.emit(uuid);
    }

    onAddGroupClick() {
        const leftSiblingUUID: string = this.expressionGroupItem.items.length === 0 ? null : this.expressionGroupItem.items[this.expressionGroupItem.items.length - 1].uuid;
        const newGroup: DroppedDataI = new DroppedGroupDataModel(this.expressionGroupItem.uuid, leftSiblingUUID, []);
        this.droppedDataEvent.emit(newGroup);
    }

    onGroupDragStart() {
        // Native drag and drop has a problem in chrome and safari, this hack solves that problem
        setTimeout(() => {
            this.isDragged = true;
            const data: DraggedDataI = this.getDragData();
            this.dragStartEvent.emit(data);
        });
    }

    onGroupDragEnd() {
        this.isDragged = false;
        this.dragEndEvent.emit();
    }

    onDragStart(data: DraggedDataI) {
        this.dragStartEvent.emit(data);
    }

    onDragEnd() {
        this.dragEndEvent.emit();
    }

    onExpressionItemValueChanged(item: UpdateExpressionItemValueModel) {
        this.expressionItemValueChangedEvent.emit(item);
    }

    getDragData(): DraggedDataI {
        return new DraggedGroupDataModel(this.expressionGroupItem.uuid, this.expressionGroupItem.items, this.expressionGroupItem.groupHeight);
    }

    trackByExpressionItems(index: number, expressionItem: ExpressionItemModel) {
        return expressionItem.uuid;
    }

    isFirstGroupExpressionItemCurrentlyInDrag(): boolean {
        if (this.draggedData) {
            if (this.expressionGroupItem.items.length > 0) {
                const firstExpressionItemUUID = this.expressionGroupItem.items[0].uuid;

                switch (this.draggedData.constructor) {
                    case DraggedFilterDataModel:
                        const draggedFilter: DraggedFilterDataModel = <DraggedFilterDataModel>this.draggedData;
                        return draggedFilter.uuid === firstExpressionItemUUID;
                    case DraggedGroupDataModel:
                        const draggedGroupData: DraggedGroupDataModel = <DraggedGroupDataModel>this.draggedData;
                        return draggedGroupData.uuid === firstExpressionItemUUID;
                }
            }
        }

        return false;
    }

    isLastGroupExpressionItemCurrentlyInDrag(): boolean {
        if (this.draggedData) {
            if (this.expressionGroupItem.items.length > 0) {
                const lastExpressionItemUUID = this.expressionGroupItem.items[this.expressionGroupItem.items.length - 1].uuid;

                switch (this.draggedData.constructor) {
                    case DraggedFilterDataModel:
                        const draggedFilter: DraggedFilterDataModel = <DraggedFilterDataModel>this.draggedData;
                        return draggedFilter.uuid === lastExpressionItemUUID;
                    case DraggedGroupDataModel:
                        const draggedGroupData: DraggedGroupDataModel = <DraggedGroupDataModel>this.draggedData;
                        return draggedGroupData.uuid === lastExpressionItemUUID;
                }
            }
        }

        return false;
    }
}
