import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';

import _ from 'lodash';
import {MultiselectDropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import {IncludedCostModel} from "../../model/included-cost.model";
import {AmountTypeEnum} from "../../../../../../../../../shared/enums/amount-type.enum";


@Component({
    selector: 'app-edit-included-cost-dialog-content',
    templateUrl: './edit-included-cost-dialog-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditIncludedCostDialogContentComponent implements OnInit {

    @Input('includedCost')
    set setIncludedCost(includedCost: IncludedCostModel) {
        this.includedCost = _.cloneDeep(includedCost);
    }

    @Input() allLocations: MultiselectDropdownOptionModel[];
    @Input() amountTypes: MultiselectDropdownOptionModel[];

    @Output() includedCostChangedEvent: EventEmitter<IncludedCostModel> = new EventEmitter();

    includedCost: IncludedCostModel;

    constructor() {
    }

    ngOnInit() {
    }

    onInputChanged() {
        this.includedCostChangedEvent.emit(this.includedCost);
    }

    onAmountTypeChanged(value: AmountTypeEnum) {
        this.includedCost.amountType = value;
        this.includedCostChangedEvent.emit(this.includedCost);
    }
}
