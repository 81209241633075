export class ShoppingCartCustomFieldRequestModel {
    templateItemId: number;
    fieldValue: string;
    partyMember: number;

    constructor(templateItemId: number, fieldValue: string, partyMember: number) {
        this.templateItemId = templateItemId;
        this.fieldValue = fieldValue;
        this.partyMember = partyMember;
    }
}