import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditNotificationDialogComponent} from "./container/edit-notification-dialog.component";
import {EditNotificationDialogSandbox} from "./sandbox/edit-notification-dialog.sandbox";
import {StoreModule} from "@ngrx/store";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import { EditNotificationFormComponent } from './components/edit-notification-form/edit-notification-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditNotificationDialogSandbox
    ],
    declarations: [
        EditNotificationDialogComponent,
        EditNotificationFormComponent
    ],
    exports: [
        EditNotificationDialogComponent
    ],
    entryComponents: [
        EditNotificationDialogComponent
    ]
})
export class EditNotificationDialogModule {

}
