export class UpdateCloudPaymentDeviceRequestModel {
    cloudPaymentDeviceId: number;
    nmiPaymentDeviceId: string;
    description: string;

    constructor( cloudPaymentDeviceId: number, nmiPaymentDeviceId: string, description: string ) {
        this.cloudPaymentDeviceId = cloudPaymentDeviceId;
        this.nmiPaymentDeviceId = nmiPaymentDeviceId;
        this.description = description;
    }
}
