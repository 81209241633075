<form
    [formGroup]="tierWithQuantityForm">
    <div class="grid-x space-between">
        <div>
            <p>{{tierWithQuantityForm.value.tierDescription}}</p>
        </div>

        <div>
            <input class="main--element input-xs"
                type="number"
                placeholder="-"
                [min]="1"
                [formControlName]="'tierQuantity'">
        </div>
    </div>
</form>
