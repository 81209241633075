import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DialogData} from "../../../model/dialog.data";
import {Sandbox} from "../sandbox/sandbox";


// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class InputDialogData extends DialogData {

    header: string;
    message: string;
    placeholder: string;
    yesLabel: string;
    noLabel: string;
    buttonType: string;

    callbackFn: (yesClicked: boolean, orderExternalID: string) => void;

    constructor(header: string, message: string, placeholder: string, noLabel: string = 'No', yesLabel: string = 'Yes', buttonType: string = 'primary', callbackFn: (yesClicked: boolean, orderExternalID: string) => void) {
        super();
        this.header = header;
        this.message = message;
        this.placeholder = placeholder;
        this.noLabel = noLabel;
        this.yesLabel = yesLabel;
        this.buttonType = buttonType;
        this.callbackFn = callbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
// Component that represents it
// -------------------------------------------------------------------------------------------------
@Component({
    selector: 'app-input-dialog',
    templateUrl: './input-dialog.component.html'
})
export class InputDialogComponent {

    header: string;
    message: string;
    placeholder: string;
    noLabel: string;
    yesLabel: string;
    buttonType: string;
    orderExternalID: string;

    // Function that gets called once yes or no button has been clicked
    callbackFn: (yesClicked: boolean, orderExternalID: string) => void;

    constructor(@Inject(DialogData) dialogData: InputDialogData, private sandbox: Sandbox) {

        this.header = dialogData.header;
        this.message = dialogData.message;
        this.placeholder = dialogData.placeholder;
        this.noLabel = dialogData.noLabel;
        this.yesLabel = dialogData.yesLabel;
        this.buttonType = dialogData.buttonType;
        this.callbackFn = dialogData.callbackFn;
    }

    onNoClicked() {
        this.callbackFn(false, this.orderExternalID);
        this.sandbox.destroyDialog();
    }

    onYesClicked() {
        this.callbackFn(true, this.orderExternalID);
        this.sandbox.destroyDialog();
    }

    onCloseClicked() {
        this.callbackFn(false, this.orderExternalID);
        this.sandbox.destroyDialog();
    }

    valueChanged(event) {
        this.orderExternalID = event;
    }
}
