import {Action} from '@ngrx/store';
import {AdditionalCostResponseModel} from "../../../../../../../core/modules/rest/cost/response/additional-cost-response.model";
import {IncludedCostResponseModel} from "../../../../../../../core/modules/rest/cost/response/included-cost-response.model";
import {DiscountResponseModel} from "../../../../../../../core/modules/rest/cost/response/discount-response.model";
import {AutoAppliedDiscountResponseModel} from "../../../../../../../core/modules/rest/cost/response/auto-applied-discount-response.model";


export const UPDATE_ADDITIONAL_COSTS_ACTION = '[Costs Main] Update additional costs';
export const UPDATE_ADDITIONAL_COST_ACTIVITY_ACTION = '[Costs Main] Update additional cost activity';

export const UPDATE_INCLUDED_COSTS_ACTION = '[Costs Main] Update included costs';
export const UPDATE_INCLUDED_COST_ACTIVITY_ACTION = '[Costs Main] Update included cost activity';

export const UPDATE_DISCOUNTS_ACTION = '[Costs Main] Update discounts';
export const UPDATE_DISCOUNT_ACTIVITY_ACTION = '[Costs Main] Update discount activity';

export const UPDATE_AUTO_APPLIED_DISCOUNTS_ACTION = '[Costs Main] Update auto applied discounts';
export const UPDATE_AUTO_APPLIED_DISCOUNT_ACTIVITY_ACTION = '[Costs Main] Update auto applied discount activity';




export class UpdateAdditionalCosts implements Action {
    readonly type = UPDATE_ADDITIONAL_COSTS_ACTION;

    constructor(public data: AdditionalCostResponseModel[]) {
    }
}

export class UpdateAdditionalCostActivity implements Action {
    readonly type = UPDATE_ADDITIONAL_COST_ACTIVITY_ACTION;

    constructor(public costId: number, public active: boolean) {
    }
}

export class UpdateIncludedCosts implements Action {
    readonly type = UPDATE_INCLUDED_COSTS_ACTION;

    constructor(public data: IncludedCostResponseModel[]) {
    }
}

export class UpdateIncludedCostActivity implements Action {
    readonly type = UPDATE_INCLUDED_COST_ACTIVITY_ACTION;

    constructor(public costId: number, public active: boolean) {
    }
}

export class UpdateDiscounts implements Action {
    readonly type = UPDATE_DISCOUNTS_ACTION;

    constructor(public data: DiscountResponseModel[]) {
    }
}

export class UpdateDiscountActivity implements Action {
    readonly type = UPDATE_DISCOUNT_ACTIVITY_ACTION;

    constructor(public costId: number, public active: boolean) {
    }
}

export class UpdateAutoAppliedDiscounts implements Action {
    readonly type = UPDATE_AUTO_APPLIED_DISCOUNTS_ACTION;

    constructor(public data: AutoAppliedDiscountResponseModel[]) {
    }
}

export class UpdateAutoAppliedDiscountActivity implements Action {
    readonly type = UPDATE_AUTO_APPLIED_DISCOUNT_ACTIVITY_ACTION;

    constructor(public costId: number, public active: boolean) {
    }
}


export type Actions =
    UpdateAdditionalCosts |
    UpdateAdditionalCostActivity |
    UpdateIncludedCosts |
    UpdateIncludedCostActivity |
    UpdateDiscounts |
    UpdateDiscountActivity |
    UpdateAutoAppliedDiscounts |
    UpdateAutoAppliedDiscountActivity;
