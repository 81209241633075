<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ addMode ? 'Deposit Funds' : 'Subtract Funds' }}</h1>
            </div>
        </div>
    </div>

    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">
        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Deposit amount</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Deposit amount"
                               formControlName="depositAmount"
                               min="0"
                               (input)="formatCurrency($event)"> 
                    </app-input-label-group>
                </div>
            </div>
        </div>
    </div>
    <!-- Dialog Content :: END -->

    <div class="errorMessage_wrapper"
         *ngIf="errorMessage">
        <label class="error">
            {{ errorMessage }}
        </label>
    </div>

    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]="'secondary'"
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>
            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]="'primary'"
                    [disabled]="!form.valid || saveButtonDisabled"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div>
    <!-- Footer :: END -->
</form>
