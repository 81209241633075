import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild  } from '@angular/core';
import {FilterItemModel} from '../../../../../../model/filter-item.model';

@Component({
    selector: 'app-filter-input',
    templateUrl: './filter-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterInputComponent implements OnInit, OnChanges {

    @Input() filterItem: FilterItemModel; // Used in ngOnChanges
    @Input() hasRunOrderReportFullPermission: boolean;
    @Output() changed: EventEmitter<string[]> = new EventEmitter<string[]>();

    @ViewChild('inputField')
    inputField: ElementRef;

    hadInitialValue: boolean;
    inputValue = '';

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterItem && changes.filterItem.firstChange) {
            const initialValue = this.getValueFromDataArray();
            this.hadInitialValue = initialValue !== '';
        }
    }

    ngOnInit() {
        if (this.filterItem.data) {
            this.inputValue = this.getValueFromDataArray();
        }
    }

    inputValueChanged() {
        const value: string[] = this.inputValue.length !== 0 ? [this.inputValue] : [];
        this.changed.emit(value);
    }

    onBlur() {
        const customBlur = document.createEvent('HTMLEvents');
        customBlur.initEvent('blur', true, true);
        this.inputField.nativeElement.dispatchEvent(customBlur);
    }

    onFocus() {
        const customFocus = document.createEvent('HTMLEvents');
        customFocus.initEvent('focus', true, true);
        this.inputField.nativeElement.dispatchEvent(customFocus);
    }

    private getValueFromDataArray(): string {
        if (this.filterItem.data) {
            if (this.filterItem.data.length > 1) {
                throw Error('Unexpected array size for recreating input filter: ' + this.filterItem.data);
            } else if (this.filterItem.data.length === 1) {
                return this.filterItem.data[0];
            } else {
                return '';
            }
        } else {
            return '';
        }
    }
}
