import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PrimaryNavTabModel } from './primary-nav-tab.model';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MANAGEMENT_ROOT_URL, REPORTS_ROOT_URL, SALES_ROOT_URL } from '../../../../core/services/app-routes.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { PermissionEnum } from '../../../enums/permission.enum';
import { NgxPermission } from "ngx-permissions/lib/model/permission.model";
import { RootSandbox } from '../../../../core/store/root.sandbox';
import { SharedSandbox } from '../../../../shared/shared.sandbox';
import { UserInfoResponseModel } from '../../../../core/modules/rest/user/response/user-info-response.model';
import { environment } from '../../../../../environments/environment';
import { SystemPermissionsRestService } from '../../../../core/modules/rest/system-permissions/system-permissions-rest.service';
import { SystemPermissionsResponseModel } from '../../../../core/modules/rest/system-permissions/response/system-permissions-response.model';
import { SystemPermissionEnum } from '../../../enums/system-permission.enum';

@Component({
    selector: 'app-dropdown-nav',
    templateUrl: './dropdown-nav.component.html',
    animations: [
        trigger('navigationState', [
            state('show', style({ transform: 'translateY(0)' })),

            transition('void => *', [
                style({ transform: 'translateY(-100%)' }),
                animate('0.45s ease-in-out')
            ]),
            transition('* => void',
                animate(500, style({ transform: 'translateY(-100%)' }))
            )

        ])]
})

export class DropdownNavComponent implements OnInit {

    @Output() closeNavEvent = new EventEmitter();

    purchaseRoute: string = SALES_ROOT_URL;
    reportsRoute: string = REPORTS_ROOT_URL;
    managementRoute: string = MANAGEMENT_ROOT_URL;
    user: UserInfoResponseModel;
    userPermissions: string[];
    systemPermissions: SystemPermissionsResponseModel[];

    activePrimNavTab: PrimaryNavTabModel;
    primaryNavTabsList: PrimaryNavTabModel[];

    constructor(private router: Router, private permissionsService: NgxPermissionsService, private systemPermissionsRestService: SystemPermissionsRestService, private rootSandbox: RootSandbox,
        private sharedSandbox: SharedSandbox) {
    }

    ngOnInit() {
        this.systemPermissionsRestService.getSystemPermissions().subscribe((systemPermissions: SystemPermissionsResponseModel[]) => {
            this.systemPermissions = systemPermissions;
            this.rootSandbox.getUserInfo().subscribe(res => {
                if (res) {
                    this.userPermissions = res.permissions
                }
                let salesPermission: NgxPermission = this.permissionsService.getPermission(PermissionEnum.SALES);
                let reportingPermission: NgxPermission = this.permissionsService.getPermission(PermissionEnum.REPORTS);
                let managementPermission: boolean = this.checkManagmentUserPermissions();

                this.initTabs(!!salesPermission, !!reportingPermission, managementPermission);
            });
        });
    }

    checkManagmentUserPermissions() {
        return this.checkSystemUserSystemPermissions() || this.checkSalesUserSystemPermissions() ||
            this.checkInventoryUserSystemPermissions() || this.checkHardwareUserSystemPermissions() ||
            this.checkCustomersUserSystemPermissions() || this.adminPanelPermission();
    }

    userSystemPermissions(item: string) {
        return this.userPermissions ? this.userPermissions.includes(item) : false;
    }

    checkMultipleUserSystemPermissions(items: string[]) {
        return items.some(item => this.userPermissions ? this.userPermissions.includes(item) : false);
    }

    checkSystemUserSystemPermissions() {
        return this.checkMultipleUserSystemPermissions([PermissionEnum.MANAGE_SYSTEM_OPTIONS,
        PermissionEnum.MANAGE_COMPANY_INFOS, PermissionEnum.MANAGE_EMAIL_HTML_TEMPLATES,
        PermissionEnum.MANAGE_ROLES_AND_USERS, PermissionEnum.MANAGE_FAVORITES, PermissionEnum.MANAGE_NOTIFICATIONS]);
    }

    checkSalesUserSystemPermissions() {
        return this.checkMultipleUserSystemPermissions([PermissionEnum.MANAGE_QSB_PRODUCTS,
        PermissionEnum.MANAGE_QSB_PASSES, PermissionEnum.MANAGE_TIERS,
        PermissionEnum.MANAGE_COSTS, PermissionEnum.MANAGE_POST_BILL_TYPES,
        PermissionEnum.MANAGE_COMMISSIONS]);
    }

    switchPrimaryNavTab(clickedPrimaryTab) {

        for (const primaryNavTab of this.primaryNavTabsList) {
            primaryNavTab.active = false;

            if (primaryNavTab === clickedPrimaryTab) {
                primaryNavTab.active = true;
                this.activePrimNavTab = primaryNavTab;
            }
        }
    }

    closeNav() {
        this.closeNavEvent.emit();
    }

    private initTabs(hasSalesPermissions: boolean, hasReportsPermission: boolean, hasManagementPermissions: boolean) {

        this.primaryNavTabsList = [
            new PrimaryNavTabModel(this.purchaseRoute, 'Sales', false, !hasSalesPermissions, 'fa-shopping-cart'),
            new PrimaryNavTabModel(this.reportsRoute, 'Reports', false, !hasReportsPermission, 'fa-file'),
            new PrimaryNavTabModel(this.managementRoute, 'Management', false, !hasManagementPermissions, 'fa-cog')
        ];

        for (const primaryNavTab of this.primaryNavTabsList) {
            if (this.router.isActive(primaryNavTab.name, false)) {
                primaryNavTab.active = true;
                this.activePrimNavTab = primaryNavTab;
            }
        }

    }

    checkInventoryUserSystemPermissions() {
        return this.checkMultipleUserSystemPermissions([PermissionEnum.MANAGE_CATEGORIES_AND_PRODUCTS,
        PermissionEnum.MANAGE_OCCURRENCE, PermissionEnum.MANAGE_PASSES,
        PermissionEnum.MANAGE_PASS_CARDS, PermissionEnum.MANAGE_BUSES,
        PermissionEnum.MANAGE_HOLIDAYS, PermissionEnum.MANAGE_LOCATIONS_AND_ROUTES,
        PermissionEnum.MANAGE_TRIP_GROUPINGS]);
    }

    checkHardwareUserSystemPermissions() {
        return (this.userSystemPermissions(PermissionEnum.MANAGE_KIOSKS) && this.customKioskPermission()) ||
            (this.userSystemPermissions(PermissionEnum.MANAGE_KIOSK_CONFIGURATION_DETAILS) && this.customKioskConfigurationPermission()) ||
            this.userSystemPermissions(PermissionEnum.MANAGE_ANDROIDS)
    }

    checkCustomersUserSystemPermissions() {
        return this.checkMultipleUserSystemPermissions([PermissionEnum.MANAGE_CUSTOMERS,
        PermissionEnum.EDIT_SALE_GROUP, PermissionEnum.MANAGE_SALE_GROUPS, PermissionEnum.MANAGE_DOMAINS, PermissionEnum.MANAGE_CUSTOMER_OWNER]) && this.useCustomerPermission();
    }


    customKioskPermission() {
        let useKiosk =  this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.KIOSK)?.active ?? false;
        return useKiosk;
    }

    customKioskConfigurationPermission() {
        let useKioskConfiguration =  this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.KIOSK_CONFIGURATION)?.active ?? false;
        return useKioskConfiguration;
    }

    useCustomerPermission() {
        let useCustomers =  this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.USE_CUSTOMERS)?.active ?? false;
        return useCustomers;
    }

    adminPanelPermission() {
        this.sharedSandbox.getUserInfo().subscribe(res => {
            this.user = res
        });
        return this.user ? environment.adminUserId === this.user.userId : false;
    }
}
