import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { SaleGroupResponseModel } from '../../../../../../../../core/modules/rest/sale-group/response/sale-group-response.model';
import { SaleGroupCustomerFormSubmitDataModel } from '../model/sale-group-customer-form-submit-data.model';
import { SaleGroupRestService } from '../../../../../../../../core/modules/rest/sale-group/sale-group-rest.service';
import { HttpErrorModel } from '../../../../../../../../core/modules/rest/http-error.model';
import { catchError, map } from 'rxjs/operators';
import { RootSandbox } from '../../../../../../../../core/store/root.sandbox';
import { forkJoin } from 'rxjs';
import { CustomerItemResponseModel } from '../../../../../../../../core/modules/rest/customer/response/customer-item-response.model';
import { MultiselectDropdownOptionModel, OPTION_ALL } from '../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model';
import { CustomerSaleGroupResponseModel } from '../../../../../../../../core/modules/rest/sale-group/response/customer-sale-group-response.model';
import { SelectionState } from '../../../../../../../../shared/components/form-elements/multiselect-dropdown-batch/multiselect-dropdown-batch.component';
import { AvailableCustomerResponse } from '../../../../../../../../core/modules/rest/sale-group/response/available-customer-response.model';
import { SaleGroupsSandbox } from '../../../store/sale-groups.sandbox';
import { Store } from '@ngrx/store';
import * as fromFeature from '../../../store/reducers';
import * as fromFeatureMainActions from '../../../store/main/actions';

@Component({
    selector: 'app-add-sale-group-customer-form',
    templateUrl: './add-sale-group-customer-form.component.html'
})
export class AddSaleGroupCustomerFormComponent implements OnInit, OnChanges {

    @Input() saleGroup: SaleGroupResponseModel;
    @Input() saleGroupId: number;
    @Input() saleGroupName: number;

    @Output() submitEvent = new EventEmitter<SaleGroupCustomerFormSubmitDataModel>();
    @Output() closeEvent = new EventEmitter<void>();
    @Output() customerOptionsChange = new EventEmitter<MultiselectDropdownOptionModel[]>();
    @Output() newCustomerOptions = new EventEmitter<MultiselectDropdownOptionModel[]>();

    totalCount: number = 0;

    customers: any;
    form: FormGroup;
    isFormValid: boolean = false;

    customerOptions: MultiselectDropdownOptionModel[] = [];
    areCustomersLoading: boolean;
    customerIds: number[] = [];
    newOptions: MultiselectDropdownOptionModel[] = [];

    customersLoaded = true;
    currentOffset = 0;
    pageSize = 50;

    currentSearchInput = '';
    selectedOptionValues: number[] = [];
    selectedState = SelectionState.INDIVIDUAL;
    deselectedOptionValues: number[] = [];

    constructor(
        private saleGroupRestService: SaleGroupRestService,
        private rootSandbox: RootSandbox,
        private store: Store<fromFeature.State>,
        private saleGroupSandbox: SaleGroupsSandbox
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes != null && changes.customer != null && Object.keys(changes.customer.currentValue).length !== 0) {
            this.isFormValid = true;
            this.form.patchValue({
                customerId: changes.customer.currentValue.customer,
            });
        }
    }

    ngOnInit() {
        let customerId: number = 0;

        this.form = new FormGroup({
            customerId: new FormControl(customerId),
            customer: new FormControl()
        });

        this.loadCustomers();

        this.form.valueChanges.subscribe(() => {
            this.isFormValid = this.form.valid;
        });
    }

    loadCustomers(callback?: () => void): void {
        if (!this.customersLoaded || this.areCustomersLoading) return;
    
        this.areCustomersLoading = true;
    
        const request = {
            saleGroupId: this.saleGroupId,
            from: this.currentOffset,
            size: this.pageSize,
            searchInput: this.currentSearchInput
        };
    
        this.saleGroupRestService.getCustomersNotInSaleGroupInRange(request).pipe(
            catchError((error: HttpErrorModel) => {
                this.areCustomersLoading = false;
                return this.rootSandbox.handleHttpError('Error while loading Customers', error);
            })
        ).subscribe((response: AvailableCustomerResponse) => {
            if (response?.customers.length) {
                this.newOptions = response.customers.map((customer: CustomerItemResponseModel) =>
                    new MultiselectDropdownOptionModel(customer.displayName + " - " + customer.email, customer.customerId.toString())
                );
    
                this.customerOptions = [...this.customerOptions, ...this.newOptions];
    
                if (!this.currentSearchInput || this.currentSearchInput.trim() === '') {
                    if (this.selectedState == SelectionState.PARTIAL_ALL) {
                        this.newOptions.forEach(option => {
                            const optionValue = parseInt(option.value, 10);
                            if (!this.customerIds.includes(optionValue)) {
                                this.customerIds.push(optionValue);
                            }
                        });
                    }
                }
    
                this.customerOptionsChange.emit(this.customerOptions);
                this.totalCount = response.totalCount;
                this.currentOffset += this.pageSize;
            } else {
                this.customersLoaded = false;
            }
    
            this.areCustomersLoading = false;
    
            if (callback) {
                callback();
            }
        });
    }        

    onDropdownScroll() {   
        this.loadCustomers();
    }

    onSearchInput(searchInput: string): void {
        this.currentSearchInput = searchInput;
        this.currentOffset = 0;
    
        const previousSelectedValues = [...this.customerIds];
        this.customerOptions = [];
        this.customersLoaded = true;
    
        this.loadCustomers(() => {
            const selectedFromSearch = previousSelectedValues.filter(id =>
                this.customerOptions.some(option => parseInt(option.value, 10) === id)
            );
    
            this.customerIds = Array.from(new Set([...selectedFromSearch]));
    
            this.customerOptionsChange.emit(this.customerOptions);
        });
    }
    
    

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        const saleGroupName = this.form.value.saleGroupName;

        if(this.selectedState == SelectionState.ALL) {
            this.customerIds.shift();
        }

        else if(this.selectedState == SelectionState.PARTIAL_ALL) {
            this.customerIds = this.deselectedOptionValues;
        }

        const data: SaleGroupCustomerFormSubmitDataModel = new SaleGroupCustomerFormSubmitDataModel(saleGroupName, this.customerIds, this.selectedState);

        this.submitEvent.emit(data);
        this.saleGroupSandbox.loadCustomersForSaleGroup(this.saleGroupId, 0, 50)
        .subscribe((customers: CustomerItemResponseModel[]) => {
            this.customers = customers;
            this.store.dispatch(new fromFeatureMainActions.SetCustomers(this.customers));
        }, (error) => {
            console.error('Error while loading customers:', error);
        });
    }

    onSelectedCustomersChanged(values: number[]) {
        values.length > 0 ? this.isFormValid = true : this.isFormValid = false;
        this.customerIds = values;
    }

    onSelectedTypeChanged(selectedState: SelectionState) {
        this.selectedState = selectedState;
    }

    onDeselectedCustomersChanged(values: number[]) {
        this.deselectedOptionValues = values;
    }

}
