<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onFormSubmit()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ this.editMode ? "Edit KioskConfiguration" : "Add New Kiosk Configuration" }}</h1>
            </div>
        </div>
    </div>


    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">
        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Description*</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Description"
                               placeholder="Description"
                               formControlName="description">
                    </app-input-label-group>
                </div>
            </div>
        </div>


        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Pickup Location</label>
                        <app-dropdown class="main--element"
                                      formControlName="pickupLocationId"
                                      [preSelectedValue]="form.value.pickupLocationId"
                                      [customClasses]="'dropdown_large'"
                                      [options]='pickupLocationOptions'>
                        </app-dropdown>
                    </app-input-label-group>
                </div>
            </div>
        </div>
    </div><!-- Dialog Content :: END -->


    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="closeEvent.emit()">
                    Cancel
                </app-button>
            </div>


            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid"
                    (clickEvent)="onFormSubmit()">
                    Save
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->

</form>


