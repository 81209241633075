<app-abstract-dialog-container>
    <app-edit-auto-report-form
        [editMode]="editMode"
        [autoReport]="autoReport$ | async"
        [favorites]="favorites$ | async"
        [currentUser]="currentUser$ | async"
        (submitEvent)="onSubmit($event)"
        (closeEvent)="onClose()">
    </app-edit-auto-report-form>
</app-abstract-dialog-container>


