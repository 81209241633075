import {Action} from '@ngrx/store';
import {HttpErrorModel} from "../../modules/rest/http-error.model";
import {NotificationAlertModel} from "../../../shared/notifications/notification-alert.model";

export const DISPOSE_FIRST_NOTIFICATION_ACTION = '[Root Notifications] Dispose first notification';

export const ADD_ERROR_NOTIFICATION = '[Root Notifications] Add Error Notification';

export const ADD_INFO_NOTIFICATION = '[Root Notifications] Add Info Notification';


export class DisposeFirstNotification implements Action {
    readonly type = DISPOSE_FIRST_NOTIFICATION_ACTION;

    constructor() {
    }
}

export class AddErrorNotification implements Action {
    readonly type = ADD_ERROR_NOTIFICATION;

    constructor(public httpError: HttpErrorModel) {
    }
}

export class AddInfoNotification implements Action {
    readonly type = ADD_INFO_NOTIFICATION;

    constructor(public info: NotificationAlertModel) {
    }
}


export type Actions =
    | DisposeFirstNotification
    | AddErrorNotification
    | AddInfoNotification
;

