export class UpdateKioskConfigurationRequestModel {
    kioskConfigurationId: number;
    description: string;
    pickupLocationId: number;

    constructor(kioskConfigurationId: number, description: string, pickupLocationId: number) {
        this.kioskConfigurationId = kioskConfigurationId;
        this.description = description;
        this.pickupLocationId = pickupLocationId;
    }
}
