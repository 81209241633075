import {FieldItemModel} from './field-item.model';

export class DroppedFieldItemModel {
    leftSibbling_queryFieldName: string;
    fieldItem: FieldItemModel;

    constructor(leftSibbling_queryFieldName: string, fieldItem: FieldItemModel) {
        this.leftSibbling_queryFieldName = leftSibbling_queryFieldName;
        this.fieldItem = fieldItem;
    }
}
