import {DateTimeDescriptor} from "../../../../../shared/model/date-time-descriptor.model";
import {DayOfWeekEnum} from "../../../../../shared/enums/day-of-week.enum";
import {SortByRequestModel} from "../../reporting/request/sort-by-request.model";

export class GetProductAvailabilitiesFilteredRequestModel {
    from: number;
    size: number;
    productId: number;
    startDate: DateTimeDescriptor;
    endDate: DateTimeDescriptor;
    startTimeFrom: number;
    startTimeTo: number;
    daysOfWeek: DayOfWeekEnum[];
    departureGroupIds: number[];
    productAvailabilityDescription: string;
    showInactive: boolean;
    showInactiveTiers: boolean;
    showInactivePickupLocations: boolean;
    showInactiveDropoffLocations: boolean;
    showInactiveDepartureGroups: boolean;
    sortBy: SortByRequestModel;

    constructor(from: number, size: number, productId: number, startDate: DateTimeDescriptor, endDate: DateTimeDescriptor, startTimeForm: number, startTimeTo: number, daysOfWeek: DayOfWeekEnum[], departureGroupIds: number[], productAvailabilityDescription: string, showInactive: boolean, showInactiveTiers: boolean, showInactivePickupLocations: boolean, showInactiveDropoffLocations: boolean, showInactiveDepartureGroups: boolean, sortBy: SortByRequestModel) {
        this.from = from;
        this.size = size;
        this.productId = productId;
        this.startDate = startDate;
        this.endDate = endDate;
        this.startTimeFrom = startTimeForm;
        this.startTimeTo = startTimeTo;
        this.daysOfWeek = daysOfWeek;
        this.departureGroupIds = departureGroupIds;
        this.productAvailabilityDescription = productAvailabilityDescription;
        this.showInactive = showInactive;
        this.showInactiveTiers = showInactiveTiers;
        this.showInactivePickupLocations = showInactivePickupLocations;
        this.showInactiveDropoffLocations = showInactiveDropoffLocations;
        this.showInactiveDepartureGroups = showInactiveDepartureGroups;
        this.sortBy = sortBy;
    }
}
