import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {DepartureGroupResponseModel} from "../../../../../../../../core/modules/rest/departure-group/response/departure-group-response.model";
import {EditDepartureGroupDialogSandbox} from "../sandbox/edit-departure-group-dialog.sandbox";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditDepartureGroupDialogData extends DialogData {

    departureGroupId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, departureGroupId?: number) {
        super();
        this.departureGroupId = departureGroupId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
  selector: 'app-edit-departure-group-dialog',
  templateUrl: './edit-departure-group-dialog.component.html'
})
export class EditDepartureGroupDialogComponent implements OnInit, OnDestroy {

    departureGroupId: number;
    departureGroup$: Observable<DepartureGroupResponseModel>;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditDepartureGroupDialogData, private sandbox: EditDepartureGroupDialogSandbox) {
        this.departureGroupId = dialogData.departureGroupId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
        if (this.departureGroupId && !isNaN(this.departureGroupId)) {
            this.editMode = true;
            this.sandbox.loadDepartureGroup(this.departureGroupId);
            this.departureGroup$ = this.sandbox.departureGroup$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(description: string) {
        if (this.editMode) {
            this.sandbox.updateDepartureGroup(this.departureGroupId, description).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createDepartureGroup(description).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }

}
