const TreeTypeEnum = {
    CATEGORIES_AND_PRODUCTS_TREE: 'CATEGORIES_AND_PRODUCTS_TREE' as 'CATEGORIES_AND_PRODUCTS_TREE',
    PASSES_TREE: 'PASSES_TREE' as 'PASSES_TREE',
    ROLES_AND_USERS_TREE: 'ROLES_AND_USERS_TREE' as 'ROLES_AND_USERS_TREE',
    CUSTOMER_OWNERS_TREE: 'CUSTOMER_OWNERS_TREE' as 'CUSTOMER_OWNERS_TREE'
};

type TreeTypeEnum = (typeof TreeTypeEnum)[keyof typeof TreeTypeEnum];

export { TreeTypeEnum };
