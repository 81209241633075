<div class='loader_wrapper' [ngClass]="{
    'default-loader' : loadType === '',
    'page-loader' : loadType === 'page',
    'inline-loader' : loadType === 'inline'
    }">
    <div class="loader_container">
        <div class="loader">
            Loading...
        </div>

        <div class="loader_label">
            <ng-content></ng-content>
        </div>
    </div>
</div>

