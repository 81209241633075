export class EndOfShiftReportOrderRevenueDescriptorRequestModel {

    orderId: number;
    isRevenueIn: boolean;

    constructor(orderId: number, isRevenueIn: boolean) {
        this.orderId = orderId;
        this.isRevenueIn = isRevenueIn;
    }
}
