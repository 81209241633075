export class CustomerFormSubmitDataModel {
    email: string;
    displayName: string;
    phoneNumber: string;
    forPlugin: boolean;
    isCustomerOwner: boolean;

    constructor( email: string, displayName: string, phoneNumber: string, forPlugin: boolean, isCustomerOwner: boolean ) {
        this.email = email;
        this.displayName = displayName;
        this.phoneNumber = phoneNumber;
        this.forPlugin = forPlugin;
        this.isCustomerOwner = isCustomerOwner;
    }
}
