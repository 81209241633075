<app-abstract-dialog-container>
    <app-edit-kiosk-device-info-dialog-form
        [kioskDeviceInfo]="kioskDeviceInfo$ | async"
        [kioskConfigurations]="kioskConfigurations$ | async"
        [users]="users$ | async"
        [termsAndConditionsTemplates]="termsAndConditionsTemplates$ | async"
        [editMode]="editMode"
        (submitEvent)="onSubmit($event)"
        (closeEvent)="onClose()">
    </app-edit-kiosk-device-info-dialog-form>
</app-abstract-dialog-container>
