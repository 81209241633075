import {DraggedDataI} from './dragged-data.model';
import {ReportItemModel} from './report-item.model';

export class DraggedReportItemDataModel implements DraggedDataI {
    reportItem: ReportItemModel;

    constructor(reportItem: ReportItemModel) {
        this.reportItem = reportItem;
    }
}
