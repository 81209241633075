import * as moment from "moment";
import { DateTimeDescriptor } from "../model/date-time-descriptor.model";

export class DateTimeUtility {

    static convertDateToInt(dateTimeMoment: moment.Moment): number {

        if (dateTimeMoment) {
            return dateTimeMoment.year() * 10000 + (dateTimeMoment.month() + 1) * 100 + dateTimeMoment.date();
        } else {
            return 0;
        }
    }

    static convertIntToDate(date: number): moment.Moment {

        if (date) {
            return moment(date.toString(), "YYYYMMDD");
        } else {
            return null;
        }
    }

    static getDateFriendly(date: moment.Moment): string {

        if (!date) {
            return 'N/A';
        }

        let dateTimeDescriptor: DateTimeDescriptor = this.convertMomentToDateTimeDescriptor(date);

        let dateStr: string = "";

        // Year
        dateStr += dateTimeDescriptor.year;

        // Month
        dateStr += "-";
        if (dateTimeDescriptor.month < 10) {
            dateStr += "0";
        }
        dateStr += dateTimeDescriptor.month;

        // Day
        dateStr += "-";
        if (dateTimeDescriptor.day < 10) {
            dateStr += "0";
        }
        dateStr += dateTimeDescriptor.day;

        return dateStr;
    }

    static getDateAndTimeFriendly(date: moment.Moment): string {

        if (!date) {
            return 'N/A';
        }

        let dateTimeDescriptor: DateTimeDescriptor = this.convertMomentToDateTimeDescriptor(date);

        let dateStr: string = "";

        // Year
        dateStr += dateTimeDescriptor.year;

        // Month
        dateStr += "-";
        if (dateTimeDescriptor.month < 10) {
            dateStr += "0";
        }
        dateStr += dateTimeDescriptor.month;

        // Day
        dateStr += "-";
        if (dateTimeDescriptor.day < 10) {
            dateStr += "0";
        }
        dateStr += dateTimeDescriptor.day;

        // Hours
        dateStr += " ";
        if (dateTimeDescriptor.hour < 10) {
            dateStr += "0";
        }
        dateStr += dateTimeDescriptor.hour;

        // Minutes
        dateStr += ":";
        if (dateTimeDescriptor.minute < 10) {
            dateStr += "0";
        }
        dateStr += dateTimeDescriptor.minute;

        // Seconds
        dateStr += ":";
        if (dateTimeDescriptor.second < 10) {
            dateStr += "0";
        }
        dateStr += dateTimeDescriptor.second;

        return dateStr;
    }

    static getTimeFriendly(time: number): string {

        if (time === null || time === undefined) {
            return "All Day";
        } else if (time === -2) {
            return "Any Time";
        } else if (time >= 0 && time <= 2359) {

            let newTimeMins: number = time % 100;
            let newTimeHours: number = Math.floor(time / 100);
            let newTime: number = ((newTimeHours + Math.floor(newTimeMins / 60)) * 100 + (newTimeMins % 60)) % 2400;
            let ampm: string = newTime >= 1200 ? "PM" : "AM";
            if (newTime >= 1300) {
                // Change it to afternoon time (e.g. 1300 = 100)
                newTime = newTime % 1200;
            }

            let timeStr: string = (Math.floor(newTime / 100)).toString() + ":" + (newTime % 100 < 10 ? "0" : "") + (newTime % 100).toString() + " " + ampm;

            return timeStr;

        } else {
            return null;
        }
    }

    static convertMomentToDateTimeDescriptor(dateTimeMoment: moment.Moment): DateTimeDescriptor {

        if (!dateTimeMoment) {
            return null;
        }

        const year = dateTimeMoment.year();
        const month = dateTimeMoment.month() + 1;
        const day = dateTimeMoment.date();
        const hour = dateTimeMoment.hour();
        const minute = dateTimeMoment.minute();
        const second = dateTimeMoment.second();
        const millisecond = dateTimeMoment.millisecond();

        const dateTimeDescriptor = new DateTimeDescriptor(year, month, day, hour, minute, second, millisecond);

        return dateTimeDescriptor;
    }

    static convertDateTimeDescriptorToMoment(dateTimeDescriptor: DateTimeDescriptor): moment.Moment {

        const dateTimeString = DateTimeUtility.getDateTimeStringFromDateTimeDescriptor(dateTimeDescriptor);
        const dateTimeMoment: moment.Moment = moment(dateTimeString, "YYYY-MM-DDTHH:mm:ss.SSS");

        return dateTimeMoment;
    }

    static convertDateTimeDescriptorToInt(dateTimeDescriptor: DateTimeDescriptor): number {

        if (dateTimeDescriptor) {
            return dateTimeDescriptor.year * 10000 + dateTimeDescriptor.month * 100 + dateTimeDescriptor.day;
        } else {
            return 0;
        }
    }

    static getDateTimeStringFromDateTimeDescriptor(dateTimeDescriptor: DateTimeDescriptor): string {

        let dateTimeString: string = "";

        // Year
        dateTimeString += dateTimeDescriptor.year;

        // Month
        dateTimeString += "-";
        if (dateTimeDescriptor.month < 10) {
            dateTimeString += "0";
        }
        dateTimeString += dateTimeDescriptor.month;

        // Day
        dateTimeString += "-";
        if (dateTimeDescriptor.day < 10) {
            dateTimeString += "0";
        }
        dateTimeString += dateTimeDescriptor.day;

        // Hour
        dateTimeString += "T";
        if (dateTimeDescriptor.hour < 10) {
            dateTimeString += "0";
        }
        dateTimeString += dateTimeDescriptor.hour;

        // Minute
        dateTimeString += ":";
        if (dateTimeDescriptor.minute < 10) {
            dateTimeString += "0";
        }
        dateTimeString += dateTimeDescriptor.minute;

        // Second
        dateTimeString += ":";
        if (dateTimeDescriptor.second < 10) {
            dateTimeString += "0";
        }
        dateTimeString += dateTimeDescriptor.second;

        // Millisecond
        dateTimeString += ".";
        if (dateTimeDescriptor.millisecond < 10) {
            dateTimeString += "00";
        } else if (dateTimeDescriptor.millisecond < 100) {
            dateTimeString += "0";
        }
        dateTimeString += dateTimeDescriptor.millisecond;

        return dateTimeString;
    }

    static getDateTimeStringFromMoment(momentObj: moment.Moment): string {
        return momentObj.format("YYYY-MM-DDTHH:mm:ss.SSS");
    }

    static parseTime(timeStr: string): number {

        let time: number = null;

        // 1) Check if "All Day"
        if (timeStr === null || timeStr === undefined || timeStr.toString().trim().length === 0) {
            return time;
        }

        // Make sure timeStr is a string
        timeStr = timeStr.toString();

        // 2) Check if number, and check if whole/integer, 4-digit number (military format)
        time = timeStr ? +timeStr : null;
        if (!isNaN(time) && time % 1 === 0 && timeStr.trim().length === 4) {
            return time;
        }

        // 3) Check if "hh:mm" format
        let timeParts: string[] = timeStr.split(":");
        if (timeParts.length === 2) {

            let hours: number = +timeParts[0];
            let minutes: number;

            // Capitalize AM/PM
            timeParts[1] = timeParts[1].toUpperCase();

            // 3.1) Check if 12-hour format or 24-hour format
            if (timeParts[1].indexOf("M") > -1) {

                // 12-hour format
                minutes = +timeParts[1].substring(0, timeParts[1].indexOf("M") - 1).trim();
                let isPM: boolean = timeParts[1].indexOf("PM") > -1;

                // 3.1.1) Check if PM
                if (isPM) {

                    // 3.1.1.1) Check if not noon (noon is special case)
                    if (hours < 12) {
                        hours += 12;
                    }
                }

                time = hours * 100 + minutes;

            } else {

                // 24-hour format
                minutes = +timeParts[1].trim();
                time = hours * 100 + minutes;
            }

            // Check if hours and minutes are valid
            if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
                return time;
            }
        }

        // 4) Check if short hour format ("h" or "hh"), with or without AM/PM
        // 4.1) Check if 12-hour format or 24-hour format
        if (timeStr.toUpperCase().indexOf("M") > -1) {

            // 12-hour format
            let hours: number = +(timeStr.substring(0, timeStr.toUpperCase().indexOf("M") - 1).trim());
            let minutes: number = 0;
            let isPM: boolean = timeStr.toUpperCase().indexOf("PM") > -1;

            // 4.1.1) Check if PM
            if (isPM) {

                // 4.1.1.1) Check if not noon (noon is special case)
                if (hours < 12) {
                    hours += 12;
                }
            }

            time = hours * 100 + minutes;

            // Check if hours are valid
            if (hours >= 0 && hours <= 23) {
                return time;
            }
        } else {

            // 24-hour format
            let hours: number = +(timeStr.trim());
            let minutes: number = 0;
            time = hours * 100 + minutes;

            // Check if hours are valid
            if (hours >= 0 && hours <= 23) {
                return time;
            }
        }

        // Invalid time
        return -1;
    }

    static convertDateArrayToDateAndTimeFriendly(dateArray: number[]): string {
        if (dateArray) {
            const [year, month, day, hours, minutes, seconds] = dateArray;
            const date = new Date(year, month - 1, day, hours, minutes, seconds);
            return moment(date).format("MMMM-DD-YYYY hh:mm A")
        } else {
            return "";
        }
    }

    static getDateTimeFriendly(createDate: number[]): string {

        if (createDate) {

            const [year, month, day, hours, minutes] = createDate;

            // Format the month abbreviation
            const monthAbbr = new Date(year, month - 1, 1).toLocaleDateString('en-US', {
              month: 'short',
            });

            // Format the date
            const formattedDate = `${monthAbbr}-${day.toString().padStart(2, '0')}-${year}`;

            // Format the time in 12-hour format with AM/PM
            const amPm = hours >= 12 ? 'PM' : 'AM';

            const formattedHours = hours % 12 || 12; // Convert to 12-hour format

            const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${amPm}`;

            // Combine date and time
            const formattedDateTime = `${formattedDate} ${formattedTime}`;

            return formattedDateTime;
        } else {
            return "";
        }
    }
}
