import {ExpressionItemModel} from './expression-item.model';
import {UUID} from 'angular2-uuid';
import {ExpressionItemTypeEnum} from './expression-item-type.enum';

export class ExpressionGroupModel implements ExpressionItemModel {
    readonly itemType: ExpressionItemTypeEnum = ExpressionItemTypeEnum.GROUP;
    readonly uuid: string;

    items: ExpressionItemModel[];
    groupHeight: number;

    constructor(items: ExpressionItemModel[], groupHeight: number, uuid?: string) {
        this.items = items;
        this.groupHeight = groupHeight;
        this.uuid = uuid ? uuid : UUID.UUID();
    }
}
