import {Action} from "@ngrx/store";
import {ShoppingCartDataModel} from "../../../../order-long-form/model/shopping-cart/shopping-cart-data.model";
import {ShoppingCartResponseModel} from "../../../../../../../../core/modules/rest/shopping-cart/response/shopping-cart-response.model";
import {ShoppingCartItemDataModel} from "../../../../order-long-form/model/shopping-cart/shopping-cart-item-data.model";
import {PaymentItemDataModel} from "../../../../order-long-form/model/shopping-cart/payment-item-data.model";
import {OrderCostItemDataModel} from "../../../../order-long-form/model/shopping-cart/order-cost-item-data.model";

export const SET_LOADING = "[Shopping Cart] Set Loading";
export const RESET_STATE = "[Shopping Cart] Reset State";

export const SHOPPING_CART_SET_SHOPPING_CART = "[Shopping Cart] Shopping Cart Set Shopping Cart";
export const SHOPPING_CART_SET_SHOPPING_CART_RESPONSE = "[Shopping Cart] Shopping Cart Set Shopping Cart Response";
export const SHOPPING_CART_SET_SHOPPING_CART_ITEMS = "[Shopping Cart] Shopping Cart Set Shopping Cart Items";
export const SHOPPING_CART_SET_SHOPPING_CART_ITEM_EDIT_MODE = "[Shopping Cart] Shopping Cart Set Shopping Cart Item Edit Mode";
export const SHOPPING_CART_SET_PAYMENT_ITEMS = "[Shopping Cart] Shopping Cart Set Payment Items";
export const SHOPPING_CART_SET_ORDER_LEVEL_ADDITIONAL_COST_ITEMS = "[Shopping Cart] Shopping Cart Set Order Level Additional Cost Items";
export const SHOPPING_CART_SET_ORDER_LEVEL_DISCOUNT_ITEMS = "[Shopping Cart] Shopping Cart Set Order Level Discount Items";

// -------------------------------------------------------------------------
// SHOPPING CART
// -------------------------------------------------------------------------

export class SetLoading implements Action {
    readonly type = SET_LOADING;

    constructor(public loading: boolean) {
    }
}

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class ShoppingCartSetShoppingCart implements Action {
    readonly type = SHOPPING_CART_SET_SHOPPING_CART;

    constructor(public shoppingCart: ShoppingCartDataModel) {
    }
}

export class ShoppingCartSetShoppingCartResponse implements Action {
    readonly type = SHOPPING_CART_SET_SHOPPING_CART_RESPONSE;

    constructor(public shoppingCartResponse: ShoppingCartResponseModel) {
    }
}

export class ShoppingCartSetShoppingCartItems implements Action {
    readonly type = SHOPPING_CART_SET_SHOPPING_CART_ITEMS;

    constructor(public shoppingCartItems: ShoppingCartItemDataModel[]) {
    }
}

export class ShoppingCartSetShoppingCartItemEditMode implements Action {
    readonly type = SHOPPING_CART_SET_SHOPPING_CART_ITEM_EDIT_MODE;

    constructor(public shoppingCartItem: ShoppingCartItemDataModel) {
    }
}

export class ShoppingCartSetPaymentItems implements Action {
    readonly type = SHOPPING_CART_SET_PAYMENT_ITEMS;

    constructor(public paymentItems: PaymentItemDataModel[]) {
    }
}

export class ShoppingCartSetOrderLevelAdditionalCostItems implements Action {
    readonly type = SHOPPING_CART_SET_ORDER_LEVEL_ADDITIONAL_COST_ITEMS;

    constructor(public orderLevelAdditionalCostItems: OrderCostItemDataModel[]) {
    }
}

export class ShoppingCartSetOrderLevelDiscountItems implements Action {
    readonly type = SHOPPING_CART_SET_ORDER_LEVEL_DISCOUNT_ITEMS;

    constructor(public orderLevelDiscountItems: OrderCostItemDataModel[]) {
    }
}

export type All =
    | SetLoading
    | ResetState
    | ShoppingCartSetShoppingCart
    | ShoppingCartSetShoppingCartResponse
    | ShoppingCartSetShoppingCartItems
    | ShoppingCartSetShoppingCartItemEditMode
    | ShoppingCartSetPaymentItems
    | ShoppingCartSetOrderLevelAdditionalCostItems
    | ShoppingCartSetOrderLevelDiscountItems;
