import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {NgxPermissionsModule} from 'ngx-permissions';
import {SharedModule} from '../../../../../shared/shared.module';
import {RouterModule, Routes} from '@angular/router';
import {DriverManifestReportSandbox} from './sandbox/driver-manifest-report.sandbox';
import {DriverManifestReportComponent} from './container/driver-manifest-report.component';
import {DriverManifestReportContentComponent} from './components/driver-manifest-report-content/driver-manifest-report-content.component';
import {DriverManifestReportFilterComponent} from './components/driver-manifest-report-content/driver-manifest-report-filter/driver-manifest-report-filter.component';
import {DriverManifestReportTableWrapperComponent} from './components/driver-manifest-report-content/driver-manifest-report-table-wrapper/driver-manifest-report-table-wrapper.component';
import {DriverManifestReportTableCaptionComponent} from './components/driver-manifest-report-content/driver-manifest-report-table-wrapper/driver-manifest-report-table-caption/driver-manifest-report-table-caption.component';
import {DriverManifestReportTableElementComponent} from './components/driver-manifest-report-content/driver-manifest-report-table-wrapper/driver-manifest-report-table-element/driver-manifest-report-table-element.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import * as fromReducer from './sandbox/store/reducer';

const routes: Routes = [
    {path: '', component: DriverManifestReportComponent}
];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature(fromReducer.REDUCER_NAME, fromReducer.reducer),
        NgxPermissionsModule.forChild(),
        InfiniteScrollModule
    ],
    declarations: [
        DriverManifestReportComponent,
        DriverManifestReportContentComponent,
        DriverManifestReportFilterComponent,
        DriverManifestReportTableWrapperComponent,
        DriverManifestReportTableCaptionComponent,
        DriverManifestReportTableElementComponent
    ],
    providers: [
        DriverManifestReportSandbox
    ]
})
export class DriverManifestReportModule {

}
