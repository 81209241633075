import {createSelector} from '@ngrx/store';

import * as fromRouter from './router/reducer';
import * as fromNotifications from './notifications/reducer';
import * as fromUser from './user/reducer';
import * as fromSystemOptions from './system-options/reducer';

export interface AppState {
    router: fromRouter.State;
    notifications: fromNotifications.State;
    user: fromUser.State;
    systemOptions: fromSystemOptions.State;
}

export const reducers = {
    router: fromRouter.routerReducer,
    notifications: fromNotifications.reducer,
    user: fromUser.reducer,
    systemOptions: fromSystemOptions.reducer
};

// ######### NOTIFICATIONS STATE #########
const notificationsState_selector = (state: AppState) => state.notifications;

export const notificationsState_notifications_selector = createSelector(notificationsState_selector, fromNotifications.notifications_selector);

// ######### USER STATE #########
const userState_selector = (state: AppState) => state.user;

export const userState_userInfo_selector = createSelector(userState_selector, fromUser.userInfo_selector);

export const userState_webSocketNotifications_selector = createSelector(userState_selector, fromUser.webSocketNotifications_selector);

export const userState_webSocketAlertIndicator_selector = createSelector(userState_selector, fromUser.webSocketAlertIndicator_selector);

// ######## SYSTEM OPTIONS STATE #########
const systemOptionsState_selector = (state: AppState) => state.systemOptions;

export const systemOptionsState_systemOptions_selector = createSelector(systemOptionsState_selector, fromSystemOptions.systemOptions_selector);

export const allowFixedTimeForFlexibleAndMultipleCheckInProducts_systemOptions_selector = createSelector(systemOptionsState_selector, fromSystemOptions.allowFixedTimeForFlexibleAndMultipleCheckInProducts_selector);

export const companyCode_systemOptions_selector = createSelector(systemOptionsState_selector, fromSystemOptions.companyCode_selector);
