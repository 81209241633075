<div class="pass-dialog-content">
    <h1 class="mb-8px">Edit Pass Validity</h1>
    <form class="pl-8px pr-8px" [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="order-form-item">
                    <label class="order-label">Validity Type</label>
                    <div class="order-input-wrapper">
                        <app-dropdown
                            class="main--element"
                            [customClasses]="'dropdown_large'"
                            [options]="validityTypes"
                            [preSelectedValue]="pass.isValidForDateInterval ? VALIDITY_TYPE_VALID_FOR_DATE_INTERVAL : VALIDITY_TYPE_VALID_FOR_HOURS"
                            formControlName="validityType">
                        </app-dropdown>
                    </div>
                </div>
                <ng-container *ngIf="form.value.validityType === VALIDITY_TYPE_VALID_FOR_DATE_INTERVAL">
                    <div class="order-form-item">
                        <label class="order-label">Start Date</label>
                        <div class="order-input-wrapper">
                            <app-datetimepicker
                                class="main--element"
                                [placeholderText]="'Choose Start Date'"
                                formControlName="validForStartDate">
                            </app-datetimepicker>
                        </div>
                    </div>
                    <div class="order-form-item">
                        <label class="order-label">End Date</label>
                        <div class="order-input-wrapper">
                            <app-datetimepicker
                                class="main--element"
                                [placeholderText]="'Choose End Date'"
                                formControlName="validForEndDate">
                            </app-datetimepicker>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="form.value.validityType === VALIDITY_TYPE_VALID_FOR_HOURS">
                    <div class="order-form-item">
                        <label class="order-label">Hours Since</label>
                        <div class="order-input-wrapper">
                            <app-dropdown
                                class="main--element"
                                [customClasses]="'dropdown_large'"
                                [options]="validityForHoursTypes"
                                [preSelectedValue]="pass.isValidSincePurchaseForHours ? VALIDITY_FOR_HOURS_TYPE_VALID_SINCE_PURCHASE_FOR_HOURS : VALIDITY_FOR_HOURS_TYPE_VALID_SINCE_FIRST_CHECK_IN_FOR_HOURS"
                                formControlName="validityForHoursType">
                            </app-dropdown>
                        </div>
                    </div>
                    <div class="order-form-item">
                        <label class="order-label">Hours</label>
                        <div class="order-input-wrapper">
                            <input type="text" placeholder="Enter number of hours" formControlName="validForHours">
                        </div>
                    </div>
                </ng-container>
                <div class="order-form-item">
                    <label class="order-label">Usage Limit Type</label>
                    <div class="order-input-wrapper">
                        <app-dropdown
                            class="main--element"
                            [customClasses]="'dropdown_large'"
                            [options]="usageLimiterTypes"
                            [preSelectedValue]="pass.isUsageLimiterPerOccurrence ? USAGE_LIMITER_TYPE_USAGE_LIMITER_PER_OCCURRENCE : USAGE_LIMITER_TYPE_USAGE_LIMITER_FOR_MINUTES"
                            formControlName="usageLimiterType">
                        </app-dropdown>
                    </div>
                </div>
                <div class="order-form-item" *ngIf="form.value.usageLimiterType === USAGE_LIMITER_TYPE_USAGE_LIMITER_FOR_MINUTES">
                    <label class="order-label">Minutes</label>
                    <div class="order-input-wrapper">
                        <input type="text" placeholder="Enter number of minutes" formControlName="usageLimiterForMinutes">
                    </div>
                </div>
                <div class="order-form-item">
                    <app-checkbox
                        [labelName]="'Unlimited Use'"
                        formControlName="isUnlimited">
                    </app-checkbox>
                </div>
                <div class="order-form-item" *ngIf="!form.value.isUnlimited">
                    <label class="order-label">Initial Quantity</label>
                    <div class="order-input-wrapper">
                        <input type="text" placeholder="Enter initial quantity" formControlName="initialQuantity">
                    </div>
                </div>
                <div class="order-form-item" *ngIf="form.value.isUnlimited">
                    <label class="order-label">Predicted Quantity</label>
                    <div class="order-input-wrapper">
                        <input type="text" placeholder="Enter predicted quantity" formControlName="predictedQuantity">
                    </div>
                </div>
            </div>
        </ng-container>

        <p *ngIf="hasError" class="error">{{ errorMessage }}</p>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Update</button>
    </div>
</div>
