import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PostBillTypeResponseModel } from "../../../../../../../../../core/modules/rest/post-bill-type/response/post-bill-type-response.model";
import { PostBillTypeFormSubmitData } from "../../models/post-bill-type-form-submit-data";
import { DropdownOptionModel } from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import { PostBillTypeAdditionalActionEnum } from "../../../../../../../../../shared/enums/post-bill-type-additional-action.enum";


@Component({
    selector: 'app-edit-post-bill-type-form',
    templateUrl: './edit-post-bill-type-form.component.html'
})
export class EditPostBillTypeFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() postBillType: PostBillTypeResponseModel;

    @Output() submitEvent = new EventEmitter<PostBillTypeFormSubmitData>();
    @Output() closeEvent = new EventEmitter<void>();

    form: FormGroup;

    postBillTypeAdditionalActionOptions: DropdownOptionModel[];
    selectedPostBillTypeAdditionalAction: PostBillTypeAdditionalActionEnum = null;

    saveButtonDisabled = false;
    errorMessage: string;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.editMode && changes.postBillType !== null && Object.keys(changes.postBillType.currentValue).length !== 0) {
            this.form.patchValue({
                description: changes.postBillType.currentValue.description,
                details: changes.postBillType.currentValue.details,
                useDeposit: changes.postBillType.currentValue.useDeposit,
            });
            this.selectedPostBillTypeAdditionalAction = changes.postBillType.currentValue.postBillTypeAdditionalAction;
        }
    }

    ngOnInit() {

        let description: string;
        let details: string;
        let useDeposit: boolean;

        if (this.editMode) {
            description = this.postBillType.description;
            details = this.postBillType.details;
            useDeposit = this.postBillType.useDeposit;
        }

        this.form = new FormGroup({
            description: new FormControl(description, Validators.required),
            details: new FormControl(details, Validators.required),
            useDeposit: new FormControl(useDeposit),
        });

        this.postBillTypeAdditionalActionOptions = [
            new DropdownOptionModel('None', PostBillTypeAdditionalActionEnum.NONE),
            new DropdownOptionModel('Enter code manually', PostBillTypeAdditionalActionEnum.ENTER_CODE_MANUALLY),
            new DropdownOptionModel('Take a photo', PostBillTypeAdditionalActionEnum.TAKE_A_PHOTO)
        ];
    }

    onSelectedAdditionalAction(value: PostBillTypeAdditionalActionEnum) {
        this.selectedPostBillTypeAdditionalAction = value;
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        if (!this.selectedPostBillTypeAdditionalAction) {
            this.errorMessage = 'Please choose Additional Action';
            return;
        }

        this.saveButtonDisabled = true;

        const description = this.form.value.description;
        const details = this.form.value.details;
        const useDeposit = this.form.value.useDeposit;

        const data: PostBillTypeFormSubmitData = new PostBillTypeFormSubmitData(description, details, useDeposit, this.selectedPostBillTypeAdditionalAction);

        this.submitEvent.emit(data);
    }
}
