import {Action} from "@ngrx/store";
import {BusResponseModel} from "../../../../../../../../../core/modules/rest/bus/response/bus-response.model";
import {ProductBusMappingResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-bus-mapping-response.model";

export const RESET_STATE_ACTION = '[Dialog - Mapping Bus :: MAIN] Reset State';

export const SET_BUS_ACTION = '[Dialog - Mapping Bus :: MAIN] Set Bus Info';

export const SET_PRODUCT_BUS_MAPPING_ITEMS_ACTION = '[Dialog - Mapping Bus :: MAIN] Set Product Bus Mapping Items Info';
export const SET_PRODUCT_BUS_MAPPING_ITEMS_LOADING_ACTION = '[Dialog - Mapping Bus :: MAIN] Set Product Bus Mapping Items Loading Info';

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetBus implements Action {
    readonly type = SET_BUS_ACTION;
    constructor(public bus: BusResponseModel) {}
}

export class SetProductBusMappingItems implements Action {
    readonly type = SET_PRODUCT_BUS_MAPPING_ITEMS_ACTION;
    constructor(public productBusMappingItems: ProductBusMappingResponseModel[]) {}
}

export class SetProductBusMappingItemsLoading implements Action {
    readonly type = SET_PRODUCT_BUS_MAPPING_ITEMS_LOADING_ACTION;
    constructor(public loading: boolean) {}
}


export type All = ResetState
    | SetBus
    | SetProductBusMappingItems
    | SetProductBusMappingItemsLoading;
