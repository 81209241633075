<div class="category-and-product-dialog-content">
    <h1 class="mb-8px">{{getHeader()}}</h1>
    <form class="pl-8px pr-8px" [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="order-form-item">
                    <label class="order-label">Start date</label>
                    <div class="order-input-wrapper">
                        <app-datetimepicker
                            class="main--element"
                            [placeholderText]="'Choose Start Date'"
                            formControlName="startDate">
                        </app-datetimepicker>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">End Date</label>
                    <div class="order-input-wrapper">
                        <app-datetimepicker
                            class="main--element"
                            [placeholderText]="'Choose End Date'"
                            formControlName="endDate">
                        </app-datetimepicker>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">Start Time</label>
                    <div class="order-input-wrapper">
                        <input class="main--element input-large"
                               placeholder="Enter Start time"
                               formControlName="startTime"/>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">End Time</label>
                    <div class="order-input-wrapper">
                        <input class="main--element input-large"
                               placeholder="Enter End time"
                               formControlName="endTime"/>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">From</label>
                    <div class="order-input-wrapper">
                        <app-dropdown
                            class="main--element"
                            [customClasses]="'dropdown_large'"
                            [options]="pickupLocations"
                            [preSelectedValue]="form.value.fromLocationId"
                            formControlName="fromLocationId">
                        </app-dropdown>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">To</label>
                    <div class="order-input-wrapper">
                        <app-dropdown
                            class="main--element"
                            [customClasses]="'dropdown_large'"
                            [options]="dropoffLocations"
                            [preSelectedValue]="form.value.toLocationId"
                            formControlName="toLocationId">
                        </app-dropdown>
                    </div>
                </div>
                <div class="edit-dialog-tier-wrapper">
                    <h3 class="mb-8px">Tiers</h3>
                    <div class="flex-container edit-dialog-tier-header">
                        <p class="edit-dialog-tier-name-header">Tier name</p>
                        <div class="edit-dialog-tier-cost-header">
                            <p>Price</p>
                            <p *ngIf="isRoundTripProduct">Round Trip Price</p>
                        </div>
                    </div>
                    <div class="edit-dialog-tier-row-container">
                        <div class="flex-container edit-dialog-tier-row" *ngFor="let productTierForm of getProductTiersFormControls(); let i = index">
                            <app-edit-product-tier-dialog-form
                                formArrayName="productTiers"
                                [productTierForm]="productTierForm"
                                [isRoundTripProduct]="isRoundTripProduct"
                                [areProductTiersDisabled]="areProductTiersDisabled">
                            </app-edit-product-tier-dialog-form>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <p *ngIf="hasError" class="error">{{ errorMessage }}</p>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">{{getSubmitButtonLabel()}}</button>
    </div>
</div>
