import { Component, OnInit  } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AccordionItemService} from "./accordion-item.service";
import {Observable} from "rxjs";



@Component({
    selector: 'app-accordion-item',
    templateUrl: './accordion-item.component.html',
    animations: [
        trigger('toggleAccordionItem', [
            state('show', style({height: '*'})),
            state('hide', style({height: '0px'})),
            transition('hide <=> show', animate('0.3s ease-out'))
        ])
    ]
})
export class AccordionItemComponent implements OnInit {

    cardActive$: Observable<boolean>;

    constructor(private accordionItemService: AccordionItemService) {
    }

    ngOnInit() {
        this.cardActive$ = this.accordionItemService.listenOnItemActivityStatus();
    }

    onHeaderClick() {
        this.accordionItemService.headerClick();
    }
}
