<div class="orderReportTable_container">

    <div class="mt-10px">
        <!-- Empty State component :: BEGIN -->
        <div class="emptyState_wrapper"
             *ngIf="!currentSearchData || currentSearchData.commissionReportItems?.length === 0">
            <div class="emptyState_container">
                <img src="{{noResultImage}}" alt="" class="emptyStateImage">

                <h1>No Result to Display!</h1>
                <p>We can not find the items you are searching.</p>
                <p>Please try again.</p>
            </div>
        </div><!-- Empty State component :: END -->

        <div class="table_wrapper"
             *ngIf="currentSearchData && currentSearchData.commissionReportItems?.length > 0">

            <div class="table_caption">
                <app-commission-report-table-caption
                    [displayMessage]="getDisplayMessage()"
                    (generateUrlForExportToXlsCommissionReportEvent)="onGenerateUrlForExportToXlsCommissionReport()">
                </app-commission-report-table-caption>
            </div>

            <div class="table_container table--wrapper">
                <app-commission-report-table-element
                    [currentSearchData]="currentSearchData"
                    [currentSearchDataSortBy]="currentSearchDataSortBy"
                    (headerSortEvent)="onHeaderSort($event)">
                </app-commission-report-table-element>
            </div>
        </div>

    </div><!-- Table Wrapper :: END -->
</div>
