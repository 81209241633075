import {NgModule} from "@angular/core";
import {EditCategoryTemplateItemsDialogSandbox} from "./sandbox/edit-category-template-items-dialog.sandbox";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {EditCategoryTemplateItemsDialogFormComponent} from './components/edit-category-template-items-dialog-form/edit-category-template-items-dialog-form.component';
import {EditCategoryTemplateItemsDialogComponent} from "./edit-category-template-items-dialog.component";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    declarations: [
        EditCategoryTemplateItemsDialogComponent,
        EditCategoryTemplateItemsDialogFormComponent
    ],
    providers: [
        EditCategoryTemplateItemsDialogSandbox
    ],
    exports: [
        EditCategoryTemplateItemsDialogComponent
    ],
    entryComponents: [
        EditCategoryTemplateItemsDialogComponent
    ]
})
export class EditCategoryTemplateItemsDialogModule {
}
