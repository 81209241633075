<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? 'Edit Company Info' : 'Add Company Info' }}</h1>
            </div>
        </div>
    </div>


    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Company Code</label>
                        <input class="main--element input-large"
                               type="text"
                               name="CompanyCode"
                               formControlName="companyCode">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Description</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Description"
                               formControlName="description">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Address</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Address"
                               formControlName="address">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Website</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Website"
                               formControlName="website">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Sender Email Address</label>
                        <input class="main--element input-large"
                               type="text"
                               name="SenderEmailAddress"
                               formControlName="senderEmailAddress">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Sender Email Name</label>
                        <input class="main--element input-large"
                               type="text"
                               name="SenderEmailName"
                               formControlName="senderEmailName">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Company Logo Amazon File Path</label>
                        <input class="main--element input-large"
                               type="text"
                               name="CompanyLogoAmazonFilePath"
                               formControlName="companyLogoAmazonFilePath">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Android Logo Amazon File Path</label>
                        <input class="main--element input-large"
                               type="text"
                               name="AndroidLogoAmazonFilePath"
                               formControlName="androidLogoAmazonFilePath">
                    </app-input-label-group>
                </div>
            </div>
        </div>


        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Currency</label>
                        <app-dropdown class="main--element"
                                      [options]="currenciesDropdownModelData"
                                      [preSelectedValue]="selectedCurrency"
                                      (changed)="onCurrencyChanged($event)">
                        </app-dropdown>
                    </app-input-label-group>
                </div>
            </div>
        </div>


    </div><!-- Dialog Content :: END -->


    <div class="errorMessage_wrapper"
         *ngIf="errorMessage">
        <label class="error">
            {{ errorMessage }}
        </label>
    </div>


    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>


            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
