import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ClickOutsideModule} from 'ng-click-outside';
import {BreadcrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {DropdownComponent} from './components/form-elements/dropdown/dropdown.component';
import {ButtonComponent} from './components/form-elements/button/button.component';
import {TableComponent} from './components/table/table.component';
import {MultiselectDropdownComponent} from './components/form-elements/multiselect-dropdown/multiselect-dropdown.component';
import {LoaderComponent} from './components/loader/loader.component';
import {InputLabelGroupComponent} from './components/form-elements/input-label-group/input-label-group.component';
import {SwitchComponent} from './components/form-elements/switch/switch.component';
import {SecondaryNavManagementComponent} from './components/header/dropdown-nav/secondary-nav-management/secondary-nav-management.component';
import {SecondaryNavPurchaseComponent} from './components/header/dropdown-nav/secondary-nav-purchase/secondary-nav-purchase.component';
import {SecondaryNavReportsComponent} from './components/header/dropdown-nav/secondary-nav-reports/secondary-nav-reports.component';
import {DropdownNavComponent} from './components/header/dropdown-nav/dropdown-nav.component';
import {HeaderNotificationDropdownComponent} from './components/header/header-notification-dropdown/header-notification-dropdown.component';
import {HeaderUserMenuDropdownComponent} from './components/header/header-user-menu-dropdown/header-user-menu-dropdown.component';
import {HeaderComponent} from './components/header/header.component';
import {NotificationAlertComponent} from './notifications/notification-alert/notification-alert.component';
import {SharedSandbox} from './shared.sandbox';
import {StandardPageLayoutComponent} from './components/page-layout/standard-page-layout/standard-page-layout.component';
import {CheckboxComponent} from './components/form-elements/checkbox/checkbox.component';
import {AccordionComponent} from './components/accordion/accordion.component';
import {TableNoStripesComponent} from './components/table/table-no-stripes/table-no-stripes.component';
import {AccordionItemComponent} from './components/accordion/accordion-item/accordion-item.component';

import {SecondaryNavHistoryComponent} from './components/header/dropdown-nav/secondary-nav-history/secondary-nav-history.component';
import {KeypressEscDirective} from './directives/keypress-esc/keypress-esc.directive';
import {AutoFocusDirective} from './directives/auto-focus/auto-focus.directive';
import {SearchComponent} from './components/search/search.component';
import {MousePointerNearDirective} from './directives/mouse-pointer-near/mouse-pointer-near.directive';
import {MousePointerNearTopEdgeDirective} from './directives/mouse-pointer-near-top-edge/mouse-pointer-near-top-edge.directive';
import {AbstractDialogContainerComponent} from "./dialogs/abstract-dialog-container.component";
import {TableScrollDirective} from "./directives/table-scroll/table-scroll.directive";
import {ScrollDirective} from "./directives/scroll/scroll.directive";
import {RadioButtonGroupComponent} from './components/form-elements/radio-button-group/radio-button-group.component';
import {InputGoogleMapsAutocompleteComponent} from './components/input-google-maps-autocomplete/input-google-maps-autocomplete.component';
import {InputNumberStepComponent} from './components/form-elements/input-number-step/input-number-step.component';
import {NumericInputDirective} from "./directives/numeric-input/numeric-input.directive";
import {DropdownTagComponent} from './components/form-elements/dropdown-tag/dropdown-tag.component';
import {InputGoogleMapsLatLngComponent} from './components/input-google-maps-lat-lng/input-google-maps-lat-lng.component';
import {ImageUploadComponent} from './components/image-upload/image-upload.component';
import {TreeComponent} from './components/tree/tree.component';
import {TabContainerComponent} from './components/tab-container/tab-container.component';
import {TabHeaderComponent} from './components/tab-container/tab-header/tab-header.component';
import {TabContentComponent} from './components/tab-container/tab-content/tab-content.component';
import {TabHeaderItemComponent} from './components/tab-container/tab-header/tab-header-item/tab-header-item.component';
import {TabContentItemComponent} from './components/tab-container/tab-content/tab-content-item/tab-content-item.component';
import {FavoritesComponent} from "./components/favorites/favorites.component";
import {NotificationsComponent} from "./components/notifications/notifications.component";
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {DatetimepickerComponent} from "./components/datetimepicker/datetimepicker.component";
import { MatTreeModule } from '@angular/material/tree';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { TreePermissionComponent } from './components/tree-permission/tree-permission.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgApexchartsModule } from 'ng-apexcharts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MultiselectDropdownBatchComponent } from './components/form-elements/multiselect-dropdown-batch/multiselect-dropdown-batch.component';
import { AdminGuard } from './guards/admin.guard';
import { RelatedOrdersGuard } from './guards/related-orders.guard';
import { SearchBarComponent } from './components/search-bar/search-bar.component';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        ClickOutsideModule,
        MatTreeModule,
        MatTabsModule,
        NgApexchartsModule,
        MatSelectModule,
        MatCheckboxModule,
        NgxDaterangepickerMd.forRoot(),
        InfiniteScrollModule
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        ClickOutsideModule,
        MatTreeModule,
        MatTabsModule,
        NgApexchartsModule,
        MatSelectModule,
        MatCheckboxModule,
        BreadcrumbsComponent,
        DropdownComponent,
        ButtonComponent,
        TableComponent,
        MultiselectDropdownComponent,
        MultiselectDropdownBatchComponent,
        LoaderComponent,
        InputLabelGroupComponent,
        SwitchComponent,
        SecondaryNavManagementComponent,
        SecondaryNavPurchaseComponent,
        SecondaryNavReportsComponent,
        SecondaryNavHistoryComponent,
        DropdownNavComponent,
        HeaderNotificationDropdownComponent,
        HeaderUserMenuDropdownComponent,
        HeaderComponent,
        AbstractDialogContainerComponent,
        NotificationAlertComponent,
        StandardPageLayoutComponent,
        CheckboxComponent,
        AccordionComponent,
        AccordionItemComponent,
        KeypressEscDirective,
        AutoFocusDirective,
        SearchComponent,
        MousePointerNearDirective,
        MousePointerNearTopEdgeDirective,
        NumericInputDirective,
        TableScrollDirective,
        ScrollDirective,
        NumericInputDirective,
        RadioButtonGroupComponent,
        InputGoogleMapsAutocompleteComponent,
        InputNumberStepComponent,
        DropdownTagComponent,
        InputGoogleMapsLatLngComponent,
        ImageUploadComponent,
        TreeComponent,
        TreePermissionComponent,
        TabContainerComponent,
        TabHeaderComponent,
        TabContentComponent,
        TabHeaderItemComponent,
        TabContentItemComponent,
        FavoritesComponent,
        NotificationsComponent,
        DatetimepickerComponent,
        SearchBarComponent
    ],
    declarations: [
        BreadcrumbsComponent,
        DropdownComponent,
        ButtonComponent,
        TableComponent,
        MultiselectDropdownComponent,
        LoaderComponent,
        InputLabelGroupComponent,
        SwitchComponent,
        SecondaryNavManagementComponent,
        SecondaryNavPurchaseComponent,
        SecondaryNavReportsComponent,
        DropdownNavComponent,
        HeaderNotificationDropdownComponent,
        HeaderUserMenuDropdownComponent,
        HeaderComponent,
        AbstractDialogContainerComponent,
        NotificationAlertComponent,
        StandardPageLayoutComponent,
        CheckboxComponent,
        TableNoStripesComponent,
        AccordionComponent,
        AccordionItemComponent,
        SecondaryNavHistoryComponent,
        KeypressEscDirective,
        AutoFocusDirective,
        SearchComponent,
        MousePointerNearDirective,
        MousePointerNearTopEdgeDirective,
        NumericInputDirective,
        TableScrollDirective,
        ScrollDirective,
        RadioButtonGroupComponent,
        InputGoogleMapsAutocompleteComponent,
        InputNumberStepComponent,
        DropdownTagComponent,
        InputGoogleMapsLatLngComponent,
        ImageUploadComponent,
        TreeComponent,
        TreePermissionComponent,
        TabContainerComponent,
        TabHeaderComponent,
        TabContentComponent,
        TabHeaderItemComponent,
        TabContentItemComponent,
        FavoritesComponent,
        NotificationsComponent,
        DatetimepickerComponent,
        MultiselectDropdownBatchComponent,
        SearchBarComponent
    ],
    providers: [
        SharedSandbox,
        AdminGuard,
        RelatedOrdersGuard
    ]
})
export class SharedModule {
    // This method is called only in AppModule
    // Its purpose is to create only one instance of given services
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
        };
    }
}
