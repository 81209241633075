import {BaseExpressionRequestModel} from './base-expression-request.model';
import {ExpressionItemTypeEnum} from '../../../../../modules/secured/reports/general/order-reports/model/expression-item-type.enum';

export class OperatorExpressionRequestModel extends BaseExpressionRequestModel {
    operator: string;

    constructor(operator: string) {
        super(ExpressionItemTypeEnum.OPERATOR);
        this.operator = operator;
    }
}
