import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CompanyInfoResponseModel} from "../../../../../../../../../core/modules/rest/company-info/response/company-info-response.model";
import {CompanyInfoFormSubmitDataModel} from "../../model/company-info-form-submit-data.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {CurrencyEnum} from "../../../../../../../../../shared/enums/currency-enum";
import {MultiselectDropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";


@Component({
  selector: 'app-edit-company-info-form',
  templateUrl: './edit-company-info-form.component.html'
})
export class EditCompanyInfoFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() companyInfo: CompanyInfoResponseModel;

    @Output() submitEvent = new EventEmitter<CompanyInfoFormSubmitDataModel>();
    @Output() closeEvent  = new EventEmitter<void>();

    form: FormGroup;
    errorMessage: string;
    selectedCurrency: CurrencyEnum = null;
    currenciesDropdownModelData: DropdownOptionModel[];

    constructor() {
        this.currenciesDropdownModelData = [
            new MultiselectDropdownOptionModel('USD', CurrencyEnum.USD),
            new MultiselectDropdownOptionModel('CAD', CurrencyEnum.CAD)
        ];
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.editMode && changes.companyInfo !== null && Object.keys(changes.companyInfo.currentValue).length !== 0) {

            const loadedCompanyInfo: CompanyInfoResponseModel = changes.companyInfo.currentValue;

            this.selectedCurrency = this.companyInfo.currency;

            this.form.patchValue({
                companyCode: loadedCompanyInfo.companyCode,
                description: loadedCompanyInfo.description,
                address: loadedCompanyInfo.address,
                website: loadedCompanyInfo.website,
                senderEmailAddress: loadedCompanyInfo.senderEmailAddress,
                senderEmailName: loadedCompanyInfo.senderEmailName,
                companyLogoAmazonFilePath: loadedCompanyInfo.companyLogoAmazonFilePath,
                androidLogoAmazonFilePath: loadedCompanyInfo.androidLogoAmazonFilePath
            });
        }
    }

    ngOnInit() {

        let companyCode;
        let description;
        let address;
        let website;
        let senderEmailAddress;
        let senderEmailName;
        let companyLogoAmazonFilePath;
        let androidLogoAmazonFilePath;

        if (this.editMode && this.companyInfo) {
            companyCode = this.companyInfo.companyCode;
            description = this.companyInfo.description;
            address = this.companyInfo.address;
            website = this.companyInfo.website;
            senderEmailAddress = this.companyInfo.senderEmailAddress;
            senderEmailName = this.companyInfo.senderEmailName;
            companyLogoAmazonFilePath = this.companyInfo.companyLogoAmazonFilePath;
            androidLogoAmazonFilePath = this.companyInfo.androidLogoAmazonFilePath;
            this.selectedCurrency = this.companyInfo.currency;
        }

        this.form = new FormGroup({
            companyCode: new FormControl(companyCode, Validators.required),
            description: new FormControl(description, Validators.required),
            address: new FormControl(address, Validators.required),
            website: new FormControl(website, Validators.required),
            senderEmailAddress: new FormControl(senderEmailAddress, Validators.required),
            senderEmailName: new FormControl(senderEmailName, Validators.required),
            companyLogoAmazonFilePath: new FormControl(companyLogoAmazonFilePath, Validators.required),
            androidLogoAmazonFilePath: new FormControl(androidLogoAmazonFilePath, Validators.required),
        });
    }

    onCurrencyChanged(value: string) {
        this.selectedCurrency = value !== null ? <CurrencyEnum>value : null;
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        if (!this.selectedCurrency) {
            this.errorMessage = 'Please select Currency';
            return;
        }

        this.errorMessage = 'null';

        const companyCode = this.form.value.companyCode;
        const description = this.form.value.description;
        const address = this.form.value.address;
        const website = this.form.value.website;
        const senderEmailAddress = this.form.value.senderEmailAddress;
        const senderEmailName = this.form.value.senderEmailName;
        const companyLogoAmazonFilePath = this.form.value.companyLogoAmazonFilePath;
        const androidLogoAmazonFilePath = this.form.value.androidLogoAmazonFilePath;

        const companyInfoData: CompanyInfoFormSubmitDataModel = new CompanyInfoFormSubmitDataModel(
            companyCode, description, senderEmailAddress, senderEmailName, companyLogoAmazonFilePath, androidLogoAmazonFilePath, address, website, this.selectedCurrency);

        this.submitEvent.emit(companyInfoData);
    }
}
