import { AuthRestService } from './auth/auth-rest.service';
import { NgModule } from '@angular/core';
import { UserRestService } from './user/user-rest.service';
import { CategoryRestService } from './category/category-rest.service';
import { FavoriteRestService } from './favorite/favorite-rest.service';
import { LocationRestService } from './location/location-rest.service';
import { PassRestService } from './pass/pass-rest.service';
import { ProductRestService } from './product/product-rest.service';
import { RoleRestService } from './role/role-rest.service';
import { TierRestService } from './tier/tier-rest.service';
import { ReportingRestService } from './reporting/reporting-rest.service';
import { LocationListRestService } from './location-list/location-list-rest.service';
import { OrderSummaryRestService } from './order-summary/order-summary-rest.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './jwt-interceptor';
import { KioskDeviceInfoRestService } from './kiosk-device-info/kiosk-device-info-rest.service';
import { KioskConfigurationRestService } from './kiosk-configuration/kiosk-configuration-rest.service';
import { BusRestService } from './bus/bus-rest.service';
import { PostBillTypeRestService } from './post-bill-type/post-bill-type-rest.service';
import { BidRestService } from './bid/bid-rest.service';
import { LetterGroupRestService } from './letter-group/letter-group-rest.service';
import { DepartureGroupRestService } from './departure-group/departure-group-rest.service';
import { HolidayRestService } from './holiday/holiday-rest.service';
import { CompanyInfoRestService } from './company-info/company-info-rest.service';
import { QuickSellingButtonProductRestService } from './quick-selling-button-product/quick-selling-button-product-rest.service';
import { QuickSellingButtonPassRestService } from './quick-selling-button-pass/quick-selling-button-pass-rest.service';
import { ProductAvailabilityRestService } from './product-availability/product-availability-rest.service';
import { CostRestService } from './cost/cost-rest.service';
import { EmailHtmlTemplateRestService } from './email-html-template/email-html-template-rest.service';
import { ShoppingCartRestService } from './shopping-cart/shopping-cart-rest.service';
import { SystemOptionsRestService } from "./system-options/system-options-rest.service";
import { PricingDateRangeRestService } from "./pricing-date-range/pricing-date-range-rest.service";
import { TemplateRestService } from "./template/template-rest.service";
import { CreditCardPaymentRestService } from "./credit-card-payment/credit-card-payment-rest.service";
import { EndOfShiftRestService } from "./end-of-shift/end-of-shift-rest.service";
import { BarcodeCheckInRestService } from "./barcode-check-in/barcode-check-in-rest.service";
import { OccurrenceRestService } from "./occurrence/occurrence-rest.service";
import { AutoReportRestService } from "./reporting/general/auto-reports/auto-report-rest.service";
import { CommissionRestService } from "./commission/commission-rest.service";
import { PassCardRestService } from "./pass-card/pass-card-rest.service";
import { AndroidDeviceInfoRestService } from "./android-device-info/android-device-info-rest.service";
import { AuditLogRestService } from './audit-log/audit-log-rest.service';
import { AdminPanelRestService } from '../../../modules/secured/management/admin-panel/admin-panel-rest.service';
import { CustomerRestService } from './customer/customer-rest.service';
import { SaleGroupRestService } from './sale-group/sale-group-rest.service';
import { CloudPaymentDeviceRestService } from './cloud-payment/cloud-payment-device-rest.service';
import { SystemPermissionsRestService } from './system-permissions/system-permissions-rest.service';
import { NotificationRestService } from './notification/notification-rest.service';
import { OverrideReasonRestService } from './over-ride-reason/override-reason-rest.service';
import { DomainRestService } from './domain/domain-rest.service';
import { MerchantRestService } from './merchants/merchants-rest.service';

@NgModule({
    imports: [
        HttpClientModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        AuthRestService,
        UserRestService,
        CategoryRestService,
        FavoriteRestService,
        LocationRestService,
        PassRestService,
        ProductRestService,
        RoleRestService,
        TierRestService,
        ReportingRestService,
        LocationListRestService,
        OrderSummaryRestService,
        AndroidDeviceInfoRestService,
        KioskDeviceInfoRestService,
        KioskConfigurationRestService,
        BusRestService,
        PostBillTypeRestService,
        BidRestService,
        LetterGroupRestService,
        DepartureGroupRestService,
        HolidayRestService,
        CompanyInfoRestService,
        QuickSellingButtonProductRestService,
        QuickSellingButtonPassRestService,
        ProductAvailabilityRestService,
        CostRestService,
        EmailHtmlTemplateRestService,
        ShoppingCartRestService,
        SystemOptionsRestService,
        SystemPermissionsRestService,
        PricingDateRangeRestService,
        TemplateRestService,
        CreditCardPaymentRestService,
        EndOfShiftRestService,
        BarcodeCheckInRestService,
        OccurrenceRestService,
        AutoReportRestService,
        CommissionRestService,
        PassCardRestService,
        AuditLogRestService,
        AdminPanelRestService,
        CustomerRestService,
        SaleGroupRestService,
        CloudPaymentDeviceRestService,
        NotificationRestService,
        OverrideReasonRestService,
        DomainRestService,
        MerchantRestService
    ]
})
export class RestModule {

}
