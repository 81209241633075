import * as actions from './actions';
import {ActionReducer, createFeatureSelector, createSelector} from "@ngrx/store";
import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";


export const REDUCER_NAME = 'dialog_create/edit-location';

export interface State {
    location: LocationResponseModel;
}

const initialState: State = {
    location: {} as LocationResponseModel
};

export const state_reducer: ActionReducer<State> = reducer;

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const location_selector = createSelector(state_selector, (state: State) => state.location);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_LOCATION_ACTION: return handleSetLocation(state, action);

        default: return state;
    }
}

function handleSetLocation(state: State, action: actions.SetLocation): State {
    return {
        ... state,
        location: action.location
    };
}
