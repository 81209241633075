import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {AddCategoryDialogSandbox} from "./sandbox/add-category-dialog.sandbox";
import {SubmitCategoryData} from "./components/add-category-dialog-form/add-category-dialog-form.component";
import {CreateBaseResponseModel} from "../../../../../../../core/modules/rest/create-base-response.model";

export class AddCategoryDialogData extends DialogData {

    successCallbackFn: Function;

    constructor(successCallbackFn: Function) {
        super();
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-add-category-dialog',
    templateUrl: './add-category-dialog.component.html'
})
export class AddCategoryDialogComponent implements OnDestroy {

    successCallbackFn: Function;

    constructor(@Inject(DialogData) data: AddCategoryDialogData, private sandbox: AddCategoryDialogSandbox) {

        this.successCallbackFn = data.successCallbackFn;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitCategoryData) {
        this.sandbox.createCategory(data).subscribe((response: CreateBaseResponseModel) => {
            this.successCallbackFn(response.id);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
