import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';

@Injectable()
export class CloseDropdownOnScrollService {

    private subject: Subject<void> = new Subject<void>();

    constructor() { }

    parentContainerScrollOccurred(): Observable<void> {
        return this.subject.asObservable();
    }

    setParentContainerScrollDetect() {
        this.subject.next();
    }
}
