import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { CloudPaymentDeviceResponseModel } from "./response/cloud-payment-response.model";
import { UpdateCloudPaymentDeviceRequestModel } from "./request/update-cloud-payment-device-request.model";
import { CreateCloudPaymentDeviceRequestModel } from "./request/create-cloud-payment-device-request.model";
import { CloudPaymentAuthorizeTransactionAsyncResponse } from "./response/cloud-payment-transaction-response.model";
import { CloudPaymentAuthorizeTransactionRequest } from "./request/cloud-payment-authorize-transaction-request.model";
import { CloudPaymentTransactionStatusResponse } from "./response/cloud-payment-transaction-status-response.model";
import { CloudDeviceResponseModel } from "./response/cloud-payment-device-response.model";

@Injectable()
export class CloudPaymentDeviceRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<CloudPaymentDeviceResponseModel[]> {
        return this.httpClient.get<CloudPaymentDeviceResponseModel[]>(environment.server_api_url + '/api/secured/cloudPaymentDevice/getAll');
    }

    getAllActive(): Observable<CloudPaymentDeviceResponseModel[]> {
        return this.httpClient.get<CloudPaymentDeviceResponseModel[]>(environment.server_api_url + '/api/secured/cloudPaymentDevice/getAllActive');
    }

    getCloudPaymentDevicesByCurrentUser(): Observable<CloudPaymentDeviceResponseModel[]> {
        return this.httpClient.get<CloudPaymentDeviceResponseModel[]>(environment.server_api_url + '/api/secured/cloudPaymentDevice/getCloudPaymentDevicesByCurrentUser');
    }

    getById(paymentDeviceId: number): Observable<CloudPaymentDeviceResponseModel> {
        return this.httpClient.get<CloudPaymentDeviceResponseModel>(environment.server_api_url + '/api/secured/cloudPaymentDevice/getById/' + paymentDeviceId);
    }

    create(request: CreateCloudPaymentDeviceRequestModel): Observable<CloudDeviceResponseModel> {
        return this.httpClient.post<CloudDeviceResponseModel>(environment.server_api_url + '/api/secured/cloudPaymentDevice/create', request);
    }

    update(request: UpdateCloudPaymentDeviceRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/cloudPaymentDevice/update', request, {responseType: 'text'});
    }

    activate(paymentDeviceId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/cloudPaymentDevice/activate', paymentDeviceId, {responseType: 'text'});
    }

    deactivate(paymentDeviceId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/cloudPaymentDevice/deactivate', paymentDeviceId, {responseType: 'text'});
    }

    getCloudPaymentTransaction(cloudPaymentDeviceRequestId: string): Observable<CloudPaymentTransactionStatusResponse> {
        let params = { cloudPaymentDeviceRequestId: cloudPaymentDeviceRequestId };
        return this.httpClient.get<CloudPaymentTransactionStatusResponse>(environment.server_api_url + '/api/secured/cloudPaymentDevice/getTransactionStatus', { params: params });
    }

    authorizeTransaction(request: CloudPaymentAuthorizeTransactionRequest): Observable<any> {
        return this.httpClient.post<CloudPaymentAuthorizeTransactionAsyncResponse>(environment.server_api_url + '/api/secured/cloudPaymentDevice/authorizeTransaction', request);
    }

}
