import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

export interface SubmitCategoryData {
    description: string;
}

@Component({
    selector: 'app-add-category-dialog-form',
    templateUrl: './add-category-dialog-form.component.html'
})

export class AddCategoryDialogFormComponent {

    @Output() submit: EventEmitter<SubmitCategoryData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        this.form = new FormGroup({
            description: new FormControl()
        });
    }

    onSubmit() {

        let description: string = this.form.value.description;

        if (!description) {
            this.hasError = true;
            this.errorMessage = "Please enter category name";
            return;
        }

        this.submit.emit({
            description: description
        });
    }

    onCancel() {
        this.cancel.emit();
    }
}
