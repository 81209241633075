


<li class="accordion_card"
    [class.card-active]="cardActive$ | async">
    <div class="accordion-item">
        <a class="card_title has-icon"
           (click)="onHeaderClick()">
            <ng-content select=".accordion--item--header">
            </ng-content>
        </a>


        <div class="card_content"
             [@toggleAccordionItem]="(cardActive$ | async)? 'show': 'hide'">
            <div class="cardContent_container">
                <ng-content select=".accordion--item--body">
                </ng-content>
            </div>
        </div>
    </div>
</li>
