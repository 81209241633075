
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {PassRestService} from "../../../../../../../../core/modules/rest/pass/pass-rest.service";
import {CostRestService} from "../../../../../../../../core/modules/rest/cost/cost-rest.service";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {PassCostResponseModel} from "../../../../../../../../core/modules/rest/pass/response/pass-cost-response.model";
import {AdditionalCostResponseModel} from "../../../../../../../../core/modules/rest/cost/response/additional-cost-response.model";
import {IncludedCostResponseModel} from "../../../../../../../../core/modules/rest/cost/response/included-cost-response.model";
import {DiscountResponseModel} from "../../../../../../../../core/modules/rest/cost/response/discount-response.model";
import {AutoAppliedDiscountResponseModel} from "../../../../../../../../core/modules/rest/cost/response/auto-applied-discount-response.model";
import {SubmitPassCostsData} from "../components/edit-pass-costs-dialog-form/edit-pass-costs-dialog-form.component";
import {MultiselectDropdownOptionModel} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import {CostTypeEnum} from "../../../../../../../../shared/enums/cost-type.enum";

@Injectable()
export class EditPassCostsDialogSandbox extends DialogSandbox {

    loading$: Observable<boolean> = this.store.pipe(select(reducer.loading_selector));
    passAdditionalCosts$: Observable<PassCostResponseModel[]> = this.store.pipe(select(reducer.passAdditionalCosts_selector));
    additionalCosts$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.additionalCosts_selector));
    passIncludedCosts$: Observable<PassCostResponseModel[]> = this.store.pipe(select(reducer.passIncludedCosts_selector));
    includedCosts$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.includedCosts_selector));
    passDiscounts$: Observable<PassCostResponseModel[]> = this.store.pipe(select(reducer.passDiscounts_selector));
    discounts$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.discounts_selector));
    passAutoAppliedDiscounts$: Observable<PassCostResponseModel[]> = this.store.pipe(select(reducer.passAutoAppliedDiscounts_selector));
    autoAppliedDiscounts$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.autoAppliedDiscounts_selector));

    constructor(appDialogsService: AppDialogsService,
                private passRestService: PassRestService,
                private costRestService: CostRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(passId: number) {

        this.store.dispatch(new actions.SetLoading(true));

        observableCombineLatest(this.passRestService.getPassCostsByPassId(passId), this.costRestService.getAllAdditionalCostsActive(), this.costRestService.getAllIncludedCostsActive(), this.costRestService.getAllDiscountsActive(), this.costRestService.getAllAutoAppliedDiscountsActive()).pipe(
            take(1))
            .subscribe(([passCosts, additionalCosts, includedCosts, discounts, autoAppliedDiscounts]: [PassCostResponseModel[], AdditionalCostResponseModel[], IncludedCostResponseModel[], DiscountResponseModel[], AutoAppliedDiscountResponseModel[]]) => {
                this.store.dispatch(new actions.SetAdditionalCosts(additionalCosts.map(ac => new MultiselectDropdownOptionModel(ac.description, ac.costId.toString()))));
                this.store.dispatch(new actions.SetPassAdditionalCosts(passCosts.filter(pc => pc.costType === CostTypeEnum.ADDITIONAL)));
                this.store.dispatch(new actions.SetIncludedCosts(includedCosts.map(ic => new MultiselectDropdownOptionModel(ic.description, ic.costId.toString()))));
                this.store.dispatch(new actions.SetPassIncludedCosts(passCosts.filter(pc => pc.costType === CostTypeEnum.INCLUDED)));
                this.store.dispatch(new actions.SetDiscounts(discounts.map(d => new MultiselectDropdownOptionModel(d.description, d.costId.toString()))));
                this.store.dispatch(new actions.SetPassDiscounts(passCosts.filter(pc => pc.costType === CostTypeEnum.DISCOUNT)));
                this.store.dispatch(new actions.SetAutoAppliedDiscounts(autoAppliedDiscounts.map(aad => new MultiselectDropdownOptionModel(aad.description, aad.costId.toString()))));
                this.store.dispatch(new actions.SetPassAutoAppliedDiscounts(passCosts.filter(pc => pc.costType === CostTypeEnum.AUTO_APPLIED_DISCOUNT)));
                this.store.dispatch(new actions.SetLoading(false));
            },
            () => this.store.dispatch(new actions.SetLoading(false)));
    }

    updatePassCosts(data: SubmitPassCostsData): Observable<any> {

        return this.passRestService.updatePassCosts({
            passId: data.passId,
            costIds: data.costIds
        });
    }
}
