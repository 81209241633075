import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DepartureGroupResponseModel} from "../../../../../../../../../core/modules/rest/departure-group/response/departure-group-response.model";


@Component({
  selector: 'app-edit-departure-group-form',
  templateUrl: './edit-departure-group-form.component.html'
})
export class EditDepartureGroupFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() departureGroup: DepartureGroupResponseModel;

    @Output() submitEvent = new EventEmitter<string>();
    @Output() closeEvent  = new EventEmitter<void>();

    form: FormGroup;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.form && this.editMode && changes.departureGroup !== null && Object.keys(changes.departureGroup.currentValue).length !== 0) {
            this.form.patchValue({
                description: changes.departureGroup.currentValue.description
            });
        }
    }

    ngOnInit() {

        let description: string;

        if (this.editMode) {
            description = this.departureGroup.description;
        }

        this.form = new FormGroup({
            description: new FormControl(description, Validators.required)
        });
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        const description = this.form.value.description;

        this.submitEvent.emit(description);
    }
}
