import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {PricingDateRangeFullResponseModel} from "../../../../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-full-response.model";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import * as moment from "moment";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {DateTimeUtility} from "../../../../../../../../../shared/utils/date-time-utility";
import {TierResponseModel} from "../../../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {ProductTierResponseModel} from "../../../../../../../../../core/modules/rest/pricing-date-range/response/product-tier-response.model";
import {NumberUtility} from "../../../../../../../../../shared/utils/number-utility";
import {DayOfWeekEnum} from "../../../../../../../../../shared/enums/day-of-week.enum";
import {DirectionEnum} from "../../../../../../../../../shared/enums/direction.enum";

export interface SubmitProductPricingDateRangesData {
    isEditMode: boolean;
    productId: number;
    pricingDateRangeIds: number[];
    startDate: moment.Moment;
    startDateChanged: boolean;
    endDate: moment.Moment;
    endDateChanged: boolean;
    startTime: number;
    startTimeChanged: boolean;
    endTime: number;
    endTimeChanged: boolean;
    fromLocationId: number;
    fromLocationIdChanged: boolean;
    toLocationId: number;
    toLocationIdChanged: boolean;
    productTiers: SubmitProductTierData[];
}

export interface SubmitProductTierData {
    tierId: number;
    cost: number;
    costRoundTrip: number;
}


@Component({
    selector: 'app-edit-product-pricing-date-ranges-dialog-form',
    templateUrl: './edit-product-pricing-date-ranges-dialog-form.component.html'
})

export class EditProductPricingDateRangesDialogFormComponent implements OnChanges {

    @Input() isEditMode: boolean;
    @Input() isRoundTripProduct: boolean;
    @Input() productId: number;
    @Input() pricingDateRanges: PricingDateRangeFullResponseModel[];
    @Input() pickupLocations: DropdownOptionModel[];
    @Input() dropoffLocations: DropdownOptionModel[];
    @Input() tiers: TierResponseModel[];

    @Output() submit: EventEmitter<SubmitProductPricingDateRangesData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    areProductTiersDisabled: boolean = false;

    private originalStartDate: moment.Moment = null;
    private originalEndDate: moment.Moment = null;
    private originalStartTime: number = null;
    private originalEndTime: number = null;
    private originalFromLocationId: number = null;
    private originalToLocationId: number = null;

    constructor() {

        this.form = new FormGroup({
            startDate: new FormControl(),
            endDate: new FormControl(),
            startTime: new FormControl(),
            endTime: new FormControl(),
            fromLocationId: new FormControl(),
            toLocationId: new FormControl(),
            productTiers: new FormArray([])
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pricingDateRanges) {
            this.initializeFormData();
            this.areProductTiersDisabled = this.isEditMode && this.pricingDateRanges.length > 1;
        }
    }

    getHeader(): string {
        if (this.isEditMode) {
            if (this.pricingDateRanges.length > 1) {
                return "Edit Pricings";
            } else {
                return "Edit Pricing";
            }
        } else {
            return "Add Pricing";
        }
    }

    getProductTiersFormControls() {
        return (<FormArray>this.form.get('productTiers')).controls;
    }

    getSubmitButtonLabel(): string {
        if (this.isEditMode) {
            return "Update";
        } else {
            return "Save";
        }
    }

    onSubmit() {

        let startDate: moment.Moment = this.form.value.startDate;
        let endDate: moment.Moment = this.form.value.endDate;
        let startTime: number = DateTimeUtility.parseTime(this.form.value.startTime);
        let endTime: number = DateTimeUtility.parseTime(this.form.value.endTime);
        let fromLocationId: number = this.form.value.fromLocationId ? +this.form.value.fromLocationId : null;
        let toLocationId: number = this.form.value.toLocationId ? +this.form.value.toLocationId : null;

        let startDateChanged: boolean = (this.originalStartDate === null && startDate !== null) || (this.originalStartDate !== null && startDate === null) || (this.originalStartDate !== null && startDate !== null && !this.originalStartDate.isSame(startDate));
        let endDateChanged: boolean = (this.originalEndDate === null && endDate !== null) || (this.originalEndDate !== null && endDate === null) || (this.originalEndDate !== null && endDate !== null && !this.originalEndDate.isSame(endDate));
        let startTimeChanged: boolean = this.originalStartTime !== startTime;
        let endTimeChanged: boolean = this.originalEndTime !== endTime;
        let fromLocationIdChanged: boolean = this.originalFromLocationId !== fromLocationId;
        let toLocationIdChanged: boolean = this.originalToLocationId !== toLocationId;

        if (isNaN(startTime) || startTime < 0 || startTime > 2359) {
            this.hasError = true;
            this.errorMessage = "Please enter valid start time (00:00 AM - 11:59 PM, or 00:00 - 23:59, or 0000 - 2359)";
            return;
        }

        if (isNaN(endTime) || endTime < 0 || endTime > 2359) {
            this.hasError = true;
            this.errorMessage = "Please enter valid end time (00:00 AM - 11:59 PM, or 00:00 - 23:59, or 0000 - 2359)";
            return;
        }

        let productTiers: SubmitProductTierData[] = [];

        // Do not collect product tiers if multiple edit
        if (this.pricingDateRanges.length <= 1) {

            for (let control of (<FormArray>this.form.get('productTiers')).controls) {

                let tierId: number = +control.value.tierId;
                let tierDescription: string = control.value.tierDescription;
                let cost: number = +control.value.cost;
                let costRoundTrip: number = this.isRoundTripProduct ? +control.value.costRoundTrip : null;

                // Form values are sometimes numbers (if not edited), sometimes strings (if edited)
                //  Make sure to check form values as strings

                // Check if only RT cost populated for round trip product
                if ((control.value.cost === null || control.value.cost === undefined || control.value.cost.toString().trim().length === 0) &&
                    this.isRoundTripProduct && control.value.costRoundTrip !== null && control.value.costRoundTrip !== undefined && control.value.costRoundTrip.toString().trim().length > 0) {

                    this.hasError = true;
                    this.errorMessage = "Please enter valid cost for tier " + tierDescription;
                    return;
                }

                // Check if only OW cost populated for round trip product
                if (control.value.cost !== null && control.value.cost !== undefined && control.value.cost.toString().trim().length > 0 &&
                    this.isRoundTripProduct && (control.value.costRoundTrip === null || control.value.costRoundTrip === undefined || control.value.costRoundTrip.toString().trim().length === 0)) {

                    this.hasError = true;
                    this.errorMessage = "Please enter valid cost (RT) for tier " + tierDescription;
                    return;
                }

                // Check if cost and optionally costRoundTrip have valid values
                if (control.value.cost !== null && control.value.cost !== undefined && control.value.cost.toString().trim().length > 0 &&
                    (!this.isRoundTripProduct || (control.value.costRoundTrip !== null && control.value.costRoundTrip !== undefined && control.value.costRoundTrip.toString().trim().length > 0))) {

                    if (isNaN(cost) || cost < -Math.abs(NumberUtility.EPSILON)) {
                        this.hasError = true;
                        this.errorMessage = "Please enter valid cost for tier " + tierDescription;
                        return;
                    }

                    if (this.isRoundTripProduct && (isNaN(costRoundTrip) || costRoundTrip < -Math.abs(NumberUtility.EPSILON))) {
                        this.hasError = true;
                        this.errorMessage = "Please enter valid cost (RT) for tier " + tierDescription;
                        return;
                    }

                    productTiers.push({
                        tierId: tierId,
                        cost: cost,
                        costRoundTrip: costRoundTrip
                    });
                }
            }

            // Check if there is at least one product tier defined for pricing date range
            if (productTiers.length === 0) {
                this.hasError = true;
                this.errorMessage = "There has to be at least one product tier defined for pricing";
                return;
            }
        }

        let pricingDateRangeIds: number[] = null;
        if (this.isEditMode) {
            pricingDateRangeIds = this.pricingDateRanges.map((pdr) => pdr.pricingDateRangeId);
        }

        this.submit.emit({
            isEditMode: this.isEditMode,
            productId: this.productId,
            pricingDateRangeIds: pricingDateRangeIds,
            startDate: startDate,
            startDateChanged: startDateChanged,
            endDate: endDate,
            endDateChanged: endDateChanged,
            startTime: startTime,
            startTimeChanged: startTimeChanged,
            endTime: endTime,
            endTimeChanged: endTimeChanged,
            fromLocationId: fromLocationId,
            fromLocationIdChanged: fromLocationIdChanged,
            toLocationId: toLocationId,
            toLocationIdChanged: toLocationIdChanged,
            productTiers: productTiers
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {

        let startDate: moment.Moment = null;
        let endDate: moment.Moment = null;
        let startTime: number = null;
        let endTime: number = null;
        let fromLocationId: number = null;
        let toLocationId: number = null;

        let productTiersFormArray: FormArray = <FormArray>this.form.get('productTiers');
        while (productTiersFormArray.length > 0) {
            productTiersFormArray.removeAt(0);
        }
        this.tiers.map((t: TierResponseModel) => {
            productTiersFormArray.push(new FormGroup({
                tierId: new FormControl(t.tierId),
                tierDescription: new FormControl(t.description),
                cost: new FormControl(),
                costRoundTrip: new FormControl()
            }));
        });

        if (this.isEditMode) {
            if (this.pricingDateRanges.length === 1) {

                startDate = this.pricingDateRanges[0].startDateInt ? DateTimeUtility.convertIntToDate(this.pricingDateRanges[0].startDateInt) : null;
                endDate = this.pricingDateRanges[0].endDateInt ? DateTimeUtility.convertIntToDate(this.pricingDateRanges[0].endDateInt) : null;
                startTime = this.pricingDateRanges[0].startTime;
                endTime = this.pricingDateRanges[0].endTime;
                fromLocationId = this.pricingDateRanges[0].fromLocationId;
                toLocationId = this.pricingDateRanges[0].toLocationId;

                while (productTiersFormArray.length > 0) {
                    productTiersFormArray.removeAt(0);
                }
                this.tiers.map((t: TierResponseModel) => {
                    let productTier: ProductTierResponseModel = this.pricingDateRanges[0].productTiers.find((pt: ProductTierResponseModel) => pt.tierId === t.tierId);
                    productTiersFormArray.push(new FormGroup({
                        tierId: new FormControl(t.tierId),
                        tierDescription: new FormControl(t.description),
                        cost: new FormControl((productTier ? productTier.cost : null)),
                        costRoundTrip: new FormControl((productTier ? productTier.costRoundTrip : null))
                    }));
                });

            } else if (this.pricingDateRanges.length > 1) {

                let startDateIntFirstValue: number = this.pricingDateRanges[0].startDateInt;
                let startDateIntAllItemsSameValue: boolean = !this.pricingDateRanges.some(pdr => pdr.startDateInt !== startDateIntFirstValue);
                if (startDateIntAllItemsSameValue) {
                    startDate = DateTimeUtility.convertIntToDate(startDateIntFirstValue);
                }

                let endDateIntFirstValue: number = this.pricingDateRanges[0].endDateInt;
                let endDateIntAllItemsSameValue: boolean = !this.pricingDateRanges.some(pdr => pdr.endDateInt !== endDateIntFirstValue);
                if (endDateIntAllItemsSameValue) {
                    endDate = DateTimeUtility.convertIntToDate(endDateIntFirstValue);
                }

                let startTimeFirstValue: number = this.pricingDateRanges[0].startTime;
                let startTimeAllItemsSameValue: boolean = !this.pricingDateRanges.some(pdr => pdr.startTime !== startTimeFirstValue);
                if (startTimeAllItemsSameValue) {
                    startTime = startTimeFirstValue;
                }

                let endTimeFirstValue: number = this.pricingDateRanges[0].endTime;
                let endTimeAllItemsSameValue: boolean = !this.pricingDateRanges.some(pdr => pdr.endTime !== endTimeFirstValue);
                if (endTimeAllItemsSameValue) {
                    endTime = endTimeFirstValue;
                }

                let fromLocationIdFirstValue: number = this.pricingDateRanges[0].fromLocationId;
                let fromLocationIdAllItemsSameValue: boolean = !this.pricingDateRanges.some(pdr => pdr.fromLocationId !== fromLocationIdFirstValue);
                if (fromLocationIdAllItemsSameValue) {
                    fromLocationId = fromLocationIdFirstValue;
                }

                let toLocationIdFirstValue: number = this.pricingDateRanges[0].toLocationId;
                let toLocationIdAllItemsSameValue: boolean = !this.pricingDateRanges.some(pdr => pdr.toLocationId !== toLocationIdFirstValue);
                if (toLocationIdAllItemsSameValue) {
                    toLocationId = toLocationIdFirstValue;
                }
            }
        }

        // Populate original values
        this.originalStartDate = startDate;
        this.originalEndDate = endDate;
        this.originalStartTime = startTime;
        this.originalEndTime = endTime;
        this.originalFromLocationId = fromLocationId;
        this.originalToLocationId = toLocationId;

        this.form.patchValue({
            startDate: startDate,
            endDate: endDate,
            startTime: startTime !== null && startTime !== undefined ? DateTimeUtility.getTimeFriendly(startTime) : null,
            endTime: endTime ? DateTimeUtility.getTimeFriendly(endTime) : null,
            fromLocationId: fromLocationId,
            toLocationId: toLocationId
        });
    }
}
