import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {ClonePassDialogSandbox} from "./sandbox/clone-pass-dialog.sandbox";
import {SubmitClonePassData} from "./components/clone-pass-dialog-form/clone-pass-dialog-form.component";
import {CreateBaseResponseModel} from "../../../../../../../core/modules/rest/create-base-response.model";

export class ClonePassDialogData extends DialogData {

    passId: number;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, passId: number) {
        super();
        this.passId = passId;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-clone-pass-dialog',
    templateUrl: './clone-pass-dialog.component.html'
})
export class ClonePassDialogComponent implements OnDestroy {

    passId: number;
    successCallbackFn: Function;

    constructor(@Inject(DialogData) data: ClonePassDialogData, private sandbox: ClonePassDialogSandbox) {

        this.passId = data.passId;
        this.successCallbackFn = data.successCallbackFn;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitClonePassData) {
        this.sandbox.clonePass(data).subscribe((response: CreateBaseResponseModel) => {
            this.successCallbackFn(response.id);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
