import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {Observable} from "rxjs";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {AdditionalCostResponseModel} from "./response/additional-cost-response.model";
import {IncludedCostResponseModel} from "./response/included-cost-response.model";
import {DiscountResponseModel} from "./response/discount-response.model";
import {AutoAppliedDiscountResponseModel} from "./response/auto-applied-discount-response.model";
import {CreateCostRequestModel} from "./request/create-cost-request.model";
import {UpdateCostRequestModel} from "./request/update-cost-request.model";
import {AutoAppliedDiscountsRequestModel} from "./request/auto-applied-discounts-request.model";
import {AutoAppliedDiscountMatchedResponseModel} from "./response/auto-applied-discount-matched-response.model";
import {UpdateAutoAppliedDiscountPriorityRequestModel} from "./request/update-auto-applied-discount-priority-request.model";
import {OrderLevelDiscountsRequestModel} from "./request/order-level-discounts-request.model";
import {PassDiscountsRequestModel} from "./request/pass-discounts-request.model";
import {ProductDiscountsRequestModel} from "./request/product-discounts-request.model";
import {UpdateCostMappingsRequestModel} from "./request/update-cost-mappings-request.model";
import {CostMappingsResponseModel} from "./response/cost-mappings-response.model";
import { CostUsedInResponseModel } from "./response/cost-used-in-response.model";

@Injectable()
export class CostRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAdditionalCostById(costId: number): Observable<AdditionalCostResponseModel> {
        return this.httpClient.get<AdditionalCostResponseModel>(environment.server_api_url + '/api/secured/cost/getAdditionalCostById/' + costId);
    }

    getAllAdditionalCosts(): Observable<AdditionalCostResponseModel[]> {
        return this.httpClient.get<AdditionalCostResponseModel[]>(environment.server_api_url + '/api/secured/cost/getAllAdditionalCosts');
    }

    getAllAdditionalCostsActive(): Observable<AdditionalCostResponseModel[]> {
        return this.httpClient.get<AdditionalCostResponseModel[]>(environment.server_api_url + '/api/secured/cost/getAllAdditionalCostsActive');
    }

    getAllOrderLevelAdditionalCosts(): Observable<AdditionalCostResponseModel[]> {
        return this.httpClient.get<AdditionalCostResponseModel[]>(environment.server_api_url + '/api/secured/cost/getAllOrderLevelAdditionalCosts');
    }

    getIncludedCostById(costId: number): Observable<AdditionalCostResponseModel> {
        return this.httpClient.get<AdditionalCostResponseModel>(environment.server_api_url + '/api/secured/cost/getIncludedCostById/' + costId);
    }

    getAllIncludedCosts(): Observable<IncludedCostResponseModel[]> {
        return this.httpClient.get<IncludedCostResponseModel[]>(environment.server_api_url + '/api/secured/cost/getAllIncludedCosts');
    }

    getAllIncludedCostsActive(): Observable<IncludedCostResponseModel[]> {
        return this.httpClient.get<IncludedCostResponseModel[]>(environment.server_api_url + '/api/secured/cost/getAllIncludedCostsActive');
    }

    getDiscountById(costId: number): Observable<AdditionalCostResponseModel> {
        return this.httpClient.get<AdditionalCostResponseModel>(environment.server_api_url + '/api/secured/cost/getDiscountById/' + costId);
    }

    getAllDiscounts(): Observable<DiscountResponseModel[]> {
        return this.httpClient.get<DiscountResponseModel[]>(environment.server_api_url + '/api/secured/cost/getAllDiscounts');
    }

    getAllDiscountsActive(): Observable<DiscountResponseModel[]> {
        return this.httpClient.get<DiscountResponseModel[]>(environment.server_api_url + '/api/secured/cost/getAllDiscountsActive');
    }

    getDiscountsByPlatformProductIdAndDates(request: ProductDiscountsRequestModel): Observable<DiscountResponseModel[]> {
        return this.httpClient.post<DiscountResponseModel[]>(environment.server_api_url + '/api/secured/cost/getDiscountsByPlatformProductIdAndDates', request);
    }

    getDiscountsByPlatformAndPassIdNow(request: PassDiscountsRequestModel): Observable<DiscountResponseModel[]> {
        return this.httpClient.post<DiscountResponseModel[]>(environment.server_api_url + '/api/secured/cost/getDiscountsByPlatformAndPassIdNow', request);
    }

    getOrderLevelDiscountsByPlatformProductIdsAndPassIdsNow(request: OrderLevelDiscountsRequestModel): Observable<DiscountResponseModel[]> {
        return this.httpClient.post<DiscountResponseModel[]>(environment.server_api_url + '/api/secured/cost/getOrderLevelDiscountsByPlatformProductIdsAndPassIdsNow', request);
    }

    getAutoAppliedDiscountById(costId: number): Observable<AdditionalCostResponseModel> {
        return this.httpClient.get<AdditionalCostResponseModel>(environment.server_api_url + '/api/secured/cost/getAutoAppliedDiscountById/' + costId);
    }

    getAllAutoAppliedDiscounts(): Observable<AutoAppliedDiscountResponseModel[]> {
        return this.httpClient.get<AutoAppliedDiscountResponseModel[]>(environment.server_api_url + '/api/secured/cost/getAllAutoAppliedDiscounts');
    }

    getAllAutoAppliedDiscountsActive(): Observable<AutoAppliedDiscountResponseModel[]> {
        return this.httpClient.get<AutoAppliedDiscountResponseModel[]>(environment.server_api_url + '/api/secured/cost/getAllAutoAppliedDiscountsActive');
    }

    getAutoAppliedDiscountsByProductIdAndLocations(request: AutoAppliedDiscountsRequestModel): Observable<AutoAppliedDiscountResponseModel[]> {
        return this.httpClient.post<AutoAppliedDiscountMatchedResponseModel[]>(environment.server_api_url + '/api/secured/cost/getAutoAppliedDiscountsByProductIdAndLocations', request);
    }

    getAutoAppliedDiscountsByPassId(passId: number): Observable<AutoAppliedDiscountResponseModel[]> {
        return this.httpClient.get<AutoAppliedDiscountMatchedResponseModel[]>(environment.server_api_url + '/api/secured/cost/getAutoAppliedDiscountsByPassId/' + passId);
    }

    getCostMappings(costId: number): Observable<CostMappingsResponseModel> {
        return this.httpClient.get<CostMappingsResponseModel>(environment.server_api_url + '/api/secured/cost/getCostMappings/' + costId);
    }

    create(request: CreateCostRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/cost/create', request);
    }

    update(request: UpdateCostRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/cost/update', request, {responseType: 'text'});
    }

    updateAutoAppliedDiscountPriority(request: UpdateAutoAppliedDiscountPriorityRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/cost/updateAutoAppliedDiscountPriority', request, {responseType: 'text'});
    }

    updateCostMappings(request: UpdateCostMappingsRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/cost/updateCostMappings', request, {responseType: 'text'});
    }

    activate(costId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/cost/activate', costId, {responseType: 'text'});
    }

    deactivate(costId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/cost/deactivate', costId, {responseType: 'text'});
    }

    getAllReferencesByCostId(costId: number): Observable<CostUsedInResponseModel> {
        return this.httpClient.post<CostUsedInResponseModel>(environment.server_api_url + '/api/secured/cost/getAllReferencesByCost', costId);
    }
}
