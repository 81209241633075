import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";

export interface SubmitProductFlagsData {
    productId: number;
    requireCheckIn: boolean;
    availableForSale: boolean;
    isFlexibleProduct: boolean;
    isRoundTripProduct: boolean;
    manualAcceptanceForFailedScanRequired: boolean;
    shouldSendBarcodeQRCodeImageAttachment: boolean;
    shouldPrintProductImageOnPdfTicket: boolean;
}


@Component({
    selector: 'app-edit-product-flags-dialog-form',
    templateUrl: './edit-product-flags-dialog-form.component.html'
})

export class EditProductFlagsDialogFormComponent implements OnChanges {

    @Input() product: ProductSimpleResponseModel;

    @Output() submit: EventEmitter<SubmitProductFlagsData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        this.form = new FormGroup({
            requireCheckIn: new FormControl(),
            availableForSale: new FormControl(),
            isFlexibleProduct: new FormControl(),
            isRoundTripProduct: new FormControl(),
            manualAcceptanceForFailedScanRequired: new FormControl(),
            shouldSendBarcodeQRCodeImageAttachment: new FormControl(),
            shouldPrintProductImageOnPdfTicket: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.product) {
            this.initializeFormData();
        }
    }

    onSubmit() {

        let requireCheckIn: boolean = this.form.value.requireCheckIn;
        let availableForSale: boolean = this.form.value.availableForSale;
        let isFlexibleProduct: boolean = this.form.value.isFlexibleProduct;
        let isRoundTripProduct: boolean = this.form.value.isRoundTripProduct;
        let manualAcceptanceForFailedScanRequired: boolean = this.form.value.manualAcceptanceForFailedScanRequired;
        let shouldSendBarcodeQRCodeImageAttachment: boolean = this.form.value.shouldSendBarcodeQRCodeImageAttachment;
        let shouldPrintProductImageOnPdfTicket: boolean = this.form.value.shouldPrintProductImageOnPdfTicket;

        this.submit.emit({
            productId: this.product.productId,
            requireCheckIn: requireCheckIn,
            availableForSale: availableForSale,
            isFlexibleProduct: isFlexibleProduct,
            isRoundTripProduct: isRoundTripProduct,
            manualAcceptanceForFailedScanRequired: manualAcceptanceForFailedScanRequired,
            shouldSendBarcodeQRCodeImageAttachment: shouldSendBarcodeQRCodeImageAttachment,
            shouldPrintProductImageOnPdfTicket: shouldPrintProductImageOnPdfTicket
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {
        this.form.patchValue({
            requireCheckIn: this.product.requireCheckIn,
            availableForSale: this.product.availableForSale,
            isFlexibleProduct: this.product.isFlexibleProduct,
            isRoundTripProduct: this.product.isRoundTripProduct,
            manualAcceptanceForFailedScanRequired: this.product.manualAcceptanceForFailedScanRequired,
            shouldSendBarcodeQRCodeImageAttachment: this.product.shouldSendBarcodeQRCodeImageAttachment,
            shouldPrintProductImageOnPdfTicket: this.product.shouldPrintProductImageOnPdfTicket
        });
    }
}
