
import { of as observableOf, combineLatest, Observable } from 'rxjs';

import { take, flatMap, map } from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { RoleRestService } from "../../../../../../core/modules/rest/role/role-rest.service";
import { TreeItemTypeEnum } from "../model/tree-item-type.enum";
import { TreeNode } from "../../../../../../shared/components/tree/tree.component";
import { PermissionNode } from "../model/permission-node";
import { AppDialogsService } from "../../../../../../core/services/app-dialogs.service";
import { RootSandbox } from "../../../../../../core/store/root.sandbox";
import { TreeNodeDataModel } from "../model/tree-node-data.model";
import { MappedData, SelectedCustomerMappedDataModel } from "../model/mapped-data";
import { PermissionItemNode } from '../../../../../../shared/components/tree-permission/tree-permission.component';
import { CustomerRestService } from '../../../../../../core/modules/rest/customer/customer-rest.service';
import { CustomerItemResponseModel } from '../../../../../../core/modules/rest/customer/response/customer-item-response.model';
import { CustomerMappingResponseModel } from '../../../../../../core/modules/rest/customer/response/customer-mapping-response.model';

@Injectable()
export class CustomerOwnersSandbox {

    item$: Observable<TreeNode> = this.store.pipe(select(reducer.item_selector));

    node$: Observable<TreeNodeMaterial> = this.store.pipe(select(reducer.node_selector));

    itemId$: Observable<number> = this.store.pipe(select(reducer.item_id_selector));

    itemType$: Observable<TreeItemTypeEnum> = this.store.pipe(select(reducer.item_type_selector));

    itemActive$: Observable<boolean> = this.store.pipe(select(reducer.item_active_selector));


    customerOwnersTree$: Observable<TreeNode[]> = this.store.pipe(select(reducer.customer_owners_tree_selector));

    permissionTree$: Observable<PermissionNode[]> = this.store.pipe(select(reducer.permission_tree_selector));

    permissionTreeMaterial$: Observable<PermissionItemNode[]> = this.store.pipe(select(reducer.permission_tree_material_selector));

    customerOwnersPermissionTree$: Observable<PermissionNode[]> = this.store.pipe(select(reducer.customer_owners_permission_tree_selector));

    loadingCustomerOwners$: Observable<boolean> = this.store.pipe(select(reducer.loading_customer_owners_selector));

    loadingPermissionsTree$: Observable<boolean> = this.store.pipe(select(reducer.loading_permissions_tree_selector));

    formHasChanges$: Observable<boolean> = this.store.pipe(select(reducer.formHasChanges_selector));

    mapper: MappedData;

    constructor(private store: Store<reducer.State>,
        private customerService: CustomerRestService,
        private customerRestService: CustomerRestService,
        private appDialogsService: AppDialogsService,
        private rootSandbox: RootSandbox) {


        this.mapper = new MappedData();
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    /**
     * Loads left section - Tree of roles and users
     */
    loadCustomerOwners() {

        this.store.dispatch(new actions.SetLoadingCustomerOwners(true));

        this.customerRestService.getAllCustomerOwners().pipe(
            take(1),
            map((customers: CustomerItemResponseModel[]) => {
                let nodes: TreeNode[] = this.getCustomerOwnersTree(customers);
                this.store.dispatch(new actions.SetLoadingCustomerOwners(false));
                this.store.dispatch(new actions.SetCustomerOwnersTree(nodes));
            })
        ).subscribe();
    }

    setPermissionTreeMaterial(nodes: PermissionItemNode[]) {
        this.store.dispatch(new actions.SetPermissionTreeMaterial(nodes));
    }

    /**
     * Loads right section - Basic information with permission tree info
     */
    loadPermissions() {

        this.item$.pipe(
            take(1),
            map((item: TreeNode<TreeNodeDataModel>) => {
                this.setCustomerOwner(item);
            })
        ).subscribe();
    }

    loadNode(passedNode: TreeNodeMaterial) {
        this.node$.pipe(
            take(1),
            map((node: TreeNodeMaterial) => {
                node = passedNode
                this.store.dispatch(new actions.SetSelectedNode(passedNode));
            })
        ).subscribe();
    }

    customerOwnerNodeSelected(itemCustomerOwner: TreeNode<TreeNodeDataModel>) {
        this.item$.pipe(
            take(1))
            .subscribe((item: TreeNode<TreeNodeDataModel>) => {
                if (!item || !item.data || item.data.type !== itemCustomerOwner.data.type || item.data.id !== itemCustomerOwner.data.id) {
                    this.setCustomerOwner(itemCustomerOwner);
                }
            });
    }

    setCustomerOwner(item: TreeNode<TreeNodeDataModel>) {
        let customerId: number = item.data.id;

        this.store.dispatch(new actions.SetSelectedItem(item));
        this.store.dispatch(new actions.SetLoadingPermissionsTree(true));

        this.customerService.getCustomerMapping(customerId).subscribe((customer: CustomerMappingResponseModel) => {

            this.store.dispatch(new actions.SetCustomerOwner(TreeItemTypeEnum.CUSTOMER, customer.customerId, customer.active));

            this.store.dispatch(new actions.SetPermissionTree(this.mapper.getPermissionTreeFromCustomerMapping(customer)));

            this.store.dispatch(new actions.SetLoadingPermissionsTree(false));
        },
        () => this.store.dispatch(new actions.SetLoadingPermissionsTree(false)));
    }

    refreshViewAndSelect(id: number, type: TreeItemTypeEnum) {
        this.store.dispatch(new actions.SetLoadingCustomerOwners(true));

            this.customerRestService.getAllCustomerOwners().pipe(
            take(1),
            map((customers: CustomerItemResponseModel[]) => {
                let nodes: TreeNode<TreeNodeDataModel>[] = this.getCustomerOwnersTree(customers);
                let node: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(id, type, nodes);

                this.store.dispatch(new actions.SetLoadingCustomerOwners(false));
                this.store.dispatch(new actions.SetCustomerOwnersTree(nodes));

                this.setCustomerOwner(node);
            })
        ).subscribe();
    }

    iterateTree(children, treeMaterial) {
        for (let child in children) {
            let filteredNode = treeMaterial.filter(
                nodeLocal => nodeLocal.item === children[child].label && nodeLocal.id === children[child].id);
            if (filteredNode[0]) {
                children[child] = { ...children[child], checked: filteredNode[0].isChecked, open: filteredNode[0].isOpen }
            }
            if (children[child].children && children[child].children.length !== 0) {
                this.iterateTree(children[child].children, treeMaterial)
            }
        }
    }

    prepareTreeMaterial(cloned, treeMaterial = []) {
        this.permissionTreeMaterial$.subscribe((res) => {
            treeMaterial = res;
        });
        for (let node in cloned) {
            let filteredNode = treeMaterial.filter(
                nodeLocal => nodeLocal.item === cloned[node].label);
            if (filteredNode[0]) {
                cloned[node].checked = filteredNode[0].isChecked
                cloned[node].open = filteredNode[0].isOpen
                if (cloned[node].children.length !== 0) {
                    this.iterateTree(cloned[node].children, treeMaterial)
                }
            }

        }
        this.store.dispatch(new actions.SetPermissionTree(cloned));
    }

    save() {
        combineLatest([this.item$, this.permissionTree$]).pipe(
            take(1),
            flatMap(([node, tree]: [TreeNode<TreeNodeDataModel>, PermissionNode[]]) => {

                let cloned = this.deepClone(tree);

                this.prepareTreeMaterial(cloned);

                let mappedData: SelectedCustomerMappedDataModel = this.mapper.getCustomerMappedDataFromPermissionTree(cloned);

                return this.customerRestService.updateCustomerMapping({
                    customerId: node.data.id,
                    permissionsCustomer: mappedData.permissionsCustomer,
                    saleGroups: mappedData.saleGroups,
                    postBillTypes: mappedData.postBillTypes,
                });
            })
        ).subscribe();
    }

    setFormHasChanges(hasChanges: boolean) {
        this.store.dispatch(new actions.SetFormHasChanges(hasChanges));
    }

    private getCustomerOwnersTree(customers: CustomerItemResponseModel[]): TreeNode<TreeNodeDataModel>[] {
        let result: TreeNode<TreeNodeDataModel>[] = [];

        customers.sort((r1, r2) => (r1.email.toLowerCase() > r2.email.toLowerCase()) ? 1 : -1);

        customers.forEach((customer: CustomerItemResponseModel) => {

            result.push(new TreeNode<TreeNodeDataModel>(new TreeNodeDataModel(TreeItemTypeEnum.CUSTOMER, customer.customerId, null, customer.email), [], customer.active, true));

        });

        return result;
    }

    private findNodeByIdAndType(id: number, type: TreeItemTypeEnum, nodes: TreeNode<TreeNodeDataModel>[]): TreeNode<TreeNodeDataModel> {
        let result: TreeNode<TreeNodeDataModel> = null;

        for (let i = 0; result === null && i < nodes.length; i++) {
            result = this._findNodeByIdAndType(id, type, nodes[i]);
        }

        return result;
    }

    private _findNodeByIdAndType(id: number, type: TreeItemTypeEnum, node: TreeNode<TreeNodeDataModel>): TreeNode<TreeNodeDataModel> {
        if (node.data.id === id && node.data.type === type) {
            return node;
        } else if (node.children.length !== 0) {

            let result: TreeNode<TreeNodeDataModel> = null;

            for (let i = 0; result === null && i < node.children.length; i++) {
                result = this._findNodeByIdAndType(id, type, node.children[i]);
            }

            return result;
        }

        return null;
    }

    deepClone(obj, hash = new WeakMap()) {
        // Do not try to clone primitives or functions
        if (Object(obj) !== obj || obj instanceof Function) return obj;
        if (hash.has(obj)) return hash.get(obj); // Cyclic reference
        try { // Try to run constructor (without arguments, as we don't know them)
            var result = new obj.constructor();
        } catch (e) { // Constructor failed, create object without running the constructor
            result = Object.create(Object.getPrototypeOf(obj));
        }
        // Optional: support for some standard constructors (extend as desired)
        if (obj instanceof Map)
            Array.from(obj, ([key, val]) => result.set(this.deepClone(key, hash),
                this.deepClone(val, hash)));
        else if (obj instanceof Set)
            Array.from(obj, (key) => result.add(this.deepClone(key, hash)));
        // Register in hash
        hash.set(obj, result);
        // Clone and assign enumerable own properties recursively
        return Object.assign(result, ...Object.keys(obj).map(
            key => ({ [key]: this.deepClone(obj[key], hash) })));
    }
}
