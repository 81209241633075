<div class="headerDropdown_container">

    <div class="headerDropdown_content">

        <div class="userProfile_container">

            <div class="userImage_wrapper">
                <a class="userImage_container">
                    <img src="./assets/images/logo/ss-ico.svg" alt="Smart Stubs Logo Icon">
                </a>
            </div>


            <div class="userData_container">
                <div class="userDataName">
                    <h2> {{ (user$ | async)?.displayName }}</h2>
                </div>
                <div class="userDataEmail">
                    {{ (user$ | async)?.email }}
                </div>
            </div>
            <div *ngIf="user$ | async as user" class="userData_container">
                <div *ngIf="user.locationDescription !== null" class="userLoginLocation mt-10px mb--5px">
                    Location: {{ (user$ | async)?.locationDescription }}
                </div>
            </div>
            <app-switch
                class="userUseTouchScreenLayout"
                [switchLabel]="'Use Touch Screen Layout'"
                [value]="(user$ | async)?.useTouchScreenLayout"
                (switchValueChanged)="onUseTouchScreenLayoutChanged($event)">
            </app-switch>

            <div>
                <a
                class="userChangePassword"
                (click)="onChangePasswordClick()">Change Password</a>
            </div>
        </div>
    </div>


    <div class="headerDropdown_footer userProfileDropdownFooter">
        <a class="headerDropdownFooter_label"
           (click)="logoutClick()">
            Sign Out
        </a>
    </div>
</div>
