import {KioskConfigurationDropoffLocationProductResponseModel} from "../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-dropoff-location-product-response.model";
import {KioskConfigurationDropoffLocationPassResponseModel} from "../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-dropoff-location-pass-response.model";
import {KioskConfigurationDropoffLocationProductTierResponseModel} from "../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-dropoff-location-product-tier-response.model";
import {KioskConfigurationWarningModel} from "./kiosk-configuration-warning.model";

export class KioskConfigurationDropoffLocationValidatedModel {
    kioskConfigurationDropoffLocationId: number;
    kioskConfigurationId: number;
    dropoffLocationId: number;
    dropoffLocationDescription: string;
    active: boolean;
    sortOrder: number;
    kioskConfigurationDropoffLocationProducts: KioskConfigurationDropoffLocationProductResponseModel[];
    kioskConfigurationDropoffLocationProductTiersOneWay: KioskConfigurationDropoffLocationProductTierResponseModel[];
    kioskConfigurationDropoffLocationProductTiersRoundTrip: KioskConfigurationDropoffLocationProductTierResponseModel[];
    kioskConfigurationDropoffLocationPasses: KioskConfigurationDropoffLocationPassResponseModel[];
    warnings: KioskConfigurationWarningModel[];
    valid: boolean;

    constructor(kioskConfigurationDropoffLocationId: number, kioskConfigurationId: number, dropoffLocationId: number, dropoffLocationDescription: string, active: boolean, sortOrder: number, kioskConfigurationDropoffLocationProducts: KioskConfigurationDropoffLocationProductResponseModel[], kioskConfigurationDropoffLocationProductTiersOneWay: KioskConfigurationDropoffLocationProductTierResponseModel[], kioskConfigurationDropoffLocationProductTiersRoundTrip: KioskConfigurationDropoffLocationProductTierResponseModel[], kioskConfigurationDropoffLocationPasses: KioskConfigurationDropoffLocationPassResponseModel[], warnings: KioskConfigurationWarningModel[], valid: boolean) {
        this.kioskConfigurationDropoffLocationId = kioskConfigurationDropoffLocationId;
        this.kioskConfigurationId = kioskConfigurationId;
        this.dropoffLocationId = dropoffLocationId;
        this.dropoffLocationDescription = dropoffLocationDescription;
        this.active = active;
        this.sortOrder = sortOrder;
        this.kioskConfigurationDropoffLocationProducts = kioskConfigurationDropoffLocationProducts;
        this.kioskConfigurationDropoffLocationProductTiersOneWay = kioskConfigurationDropoffLocationProductTiersOneWay;
        this.kioskConfigurationDropoffLocationProductTiersRoundTrip = kioskConfigurationDropoffLocationProductTiersRoundTrip;
        this.kioskConfigurationDropoffLocationPasses = kioskConfigurationDropoffLocationPasses;
        this.warnings = warnings;
        this.valid = valid;
    }
}
