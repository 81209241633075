<app-abstract-dialog-container>
    <app-route-setup-dialog-content

        [allProducts]="allProducts$ | async"
        [kioskConfigurationDropoffLocationProducts]="kioskConfigurationDropoffLocationProducts$ | async"
        [warningsWhileCreating]="warningsWhileCreating$ | async"

        (productChangedEvent)="onProductChanged($event)"
        (directionChangedEvent)="onDirectionChanged($event)"
        (pickupLocationChangedEvent)="onPickupLocationChanged($event)"
        (dropoffLocationChangedEvent)="onDropoffLocationChanged($event)"

        (productAddedEvent)="onProductAdded()"
        (productDeletedEvent)="onProductDeleted($event)"

        (submitEvent)="onSubmit($event)"
        (closeEvent)="onClose()"
    >
    </app-route-setup-dialog-content>
</app-abstract-dialog-container>
