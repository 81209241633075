<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>Add Customer to Sale Group</h1>
            </div>
        </div>
    </div>


    <!-- Dialog Content :: BEGIN -->

    <div>
        <div class="cell medium-4">
            <app-input-label-group>
                <label class="label">Customers</label>
                <app-multiselect-dropdown-batch
                    class="main--element"
                    [allOptionEnabled]="true"
                    [options]="customerOptions"
                    [newCustomers]="newOptions"
                    [loading]="areCustomersLoading"
                    [totalCount]="totalCount"
                    (selectedType)="onSelectedTypeChanged($event)"
                    (changed)="onSelectedCustomersChanged($event)"
                    (deselected)="onDeselectedCustomersChanged($event)"
                    (scrolled)="onDropdownScroll()"
                    (search)="onSearchInput($event)">
                </app-multiselect-dropdown-batch>
            </app-input-label-group>
        </div>
    </div>
    <!-- Dialog Content :: END -->


    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>


            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!isFormValid"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div>
    <!-- Footer :: END -->

</form>
