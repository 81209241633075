const PermissionEnum = {
    EMPTY_PERMISSION: 'EMPTY_PERMISSION' as 'EMPTY_PERMISSION',
    CREATE_ORDER_LONG_FORM_PRODUCT: 'CREATE_ORDER_LONG_FORM_PRODUCT' as 'CREATE_ORDER_LONG_FORM_PRODUCT',
    REPORTS: 'REPORTS' as 'REPORTS',
    MANAGEMENT: 'MANAGEMENT' as 'MANAGEMENT',
    MANAGE_ROLES_AND_USERS: 'MANAGE_ROLES_AND_USERS' as 'MANAGE_ROLES_AND_USERS',
    VIEW_ORDER: 'VIEW_ORDER' as 'VIEW_ORDER',
    EDIT_ORDER_DETAILS: 'EDIT_ORDER_DETAILS' as 'EDIT_ORDER_DETAILS',
    VOID_ORDER: 'VOID_ORDER' as 'VOID_ORDER',
    CAN_BE_REFERRER: 'CAN_BE_REFERRER' as 'CAN_BE_REFERRER',
    CAN_ACCEPT_CASH_PAYMENT: 'CAN_ACCEPT_CASH_PAYMENT' as 'CAN_ACCEPT_CASH_PAYMENT',
    CAN_ACCEPT_CREDIT_CARD_PAYMENT: 'CAN_ACCEPT_CREDIT_CARD_PAYMENT' as 'CAN_ACCEPT_CREDIT_CARD_PAYMENT',
    CAN_ACCEPT_VOUCHER_PAYMENT: 'CAN_ACCEPT_VOUCHER_PAYMENT' as 'CAN_ACCEPT_VOUCHER_PAYMENT',
    CAN_ACCEPT_POST_BILL_PAYMENT: 'CAN_ACCEPT_POST_BILL_PAYMENT' as 'CAN_ACCEPT_POST_BILL_PAYMENT',
    RUN_MANIFEST_REPORT: 'RUN_MANIFEST_REPORT' as 'RUN_MANIFEST_REPORT',
    CAN_CHOOSE_REFERRER: 'CAN_CHOOSE_REFERRER' as 'CAN_CHOOSE_REFERRER',
    EDIT_COMPLETED_ORDER: 'EDIT_COMPLETED_ORDER' as 'EDIT_COMPLETED_ORDER',
    VOID_COMPLETED_ORDER: 'VOID_COMPLETED_ORDER' as 'VOID_COMPLETED_ORDER',
    SALES: 'SALES' as 'SALES',
    CREATE_ORDER_LONG_FORM_PASS: 'CREATE_ORDER_LONG_FORM_PASS' as 'CREATE_ORDER_LONG_FORM_PASS',
    CREATE_ORDER_QSB_PRODUCT: 'CREATE_ORDER_QSB_PRODUCT' as 'CREATE_ORDER_QSB_PRODUCT',
    CREATE_ORDER_QSB_PASS: 'CREATE_ORDER_QSB_PASS' as 'CREATE_ORDER_QSB_PASS',
    CAN_ENTER_CUSTOM_PRICE_FOR_PRODUCT: 'CAN_ENTER_CUSTOM_PRICE_FOR_PRODUCT' as 'CAN_ENTER_CUSTOM_PRICE_FOR_PRODUCT',
    CAN_ENTER_CUSTOM_PRICE_FOR_PASS: 'CAN_ENTER_CUSTOM_PRICE_FOR_PASS' as 'CAN_ENTER_CUSTOM_PRICE_FOR_PASS',
    RUN_ORDER_REPORT_FULL: 'RUN_ORDER_REPORT_FULL' as 'RUN_ORDER_REPORT_FULL',
    RUN_DRIVER_MANIFEST_REPORT: 'RUN_DRIVER_MANIFEST_REPORT' as 'RUN_DRIVER_MANIFEST_REPORT',
    RUN_PASSENGER_COUNT_REPORT: 'RUN_PASSENGER_COUNT_REPORT' as 'RUN_PASSENGER_COUNT_REPORT',
    RUN_OPERATIONS_REPORT: 'RUN_OPERATIONS_REPORT' as 'RUN_OPERATIONS_REPORT',
    RUN_REVENUE_REPORT: 'RUN_REVENUE_REPORT' as 'RUN_REVENUE_REPORT',
    USE_LOGIN_LOCATIONS: 'USE_LOGIN_LOCATIONS' as 'USE_LOGIN_LOCATIONS',
    CAN_DO_CASHOUT_ENTRY: 'CAN_DO_CASHOUT_ENTRY' as 'CAN_DO_CASHOUT_ENTRY',
    VOID_OWN_ORDER_IN_LAST_24_HOURS: 'VOID_OWN_ORDER_IN_LAST_24_HOURS' as 'VOID_OWN_ORDER_IN_LAST_24_HOURS',
    RUN_ORDER_REPORT: 'RUN_ORDER_REPORT' as 'RUN_ORDER_REPORT',
    RUN_OCCURRENCE_REPORT: 'RUN_OCCURRENCE_REPORT' as 'RUN_OCCURRENCE_REPORT',
    RUN_TRANSACTION_REPORT: 'RUN_TRANSACTION_REPORT' as 'RUN_TRANSACTION_REPORT',
    RUN_RECONCILE_REPORT: 'RUN_RECONCILE_REPORT' as 'RUN_RECONCILE_REPORT',
    RUN_AUTO_REPORTS: 'RUN_AUTO_REPORTS' as 'RUN_AUTO_REPORTS',
    RUN_PASS_CARD_REPORT: 'RUN_PASS_CARD_REPORT' as 'RUN_PASS_CARD_REPORT',
    CAN_DO_CASHOUT_RECONCILIATION: 'CAN_DO_CASHOUT_RECONCILIATION' as 'CAN_DO_CASHOUT_RECONCILIATION',
    RUN_COMMISSION_REPORT: 'RUN_COMMISSION_REPORT' as 'RUN_COMMISSION_REPORT',
    CAN_DO_MOBILE_SALES_QSB: 'CAN_DO_MOBILE_SALES_QSB' as 'CAN_DO_MOBILE_SALES_QSB',
    CAN_CHANGE_DATE_ON_TRIP_SELECTOR: 'CAN_CHANGE_DATE_ON_TRIP_SELECTOR' as 'CAN_CHANGE_DATE_ON_TRIP_SELECTOR',
    PRINT_ORDER_MULTIPLE_TIMES: 'PRINT_ORDER_MULTIPLE_TIMES' as 'PRINT_ORDER_MULTIPLE_TIMES',
    RUN_TRIP_REVENUE_REPORT: 'RUN_TRIP_REVENUE_REPORT' as 'RUN_TRIP_REVENUE_REPORT',
    RUN_TICKET_TYPE_REVENUE_REPORT: 'RUN_TICKET_TYPE_REVENUE_REPORT' as 'RUN_TICKET_TYPE_REVENUE_REPORT',
    RUN_OVERRIDE_REPORT: 'RUN_OVERRIDE_REPORT' as 'RUN_OVERRIDE_REPORT',
    PRINT_ORDER_ONCE: 'PRINT_ORDER_ONCE' as 'PRINT_ORDER_ONCE',
    PRINT_ORDER_TWICE: 'PRINT_ORDER_TWICE' as 'PRINT_ORDER_TWICE',
    EMAIL_ORDER: 'EMAIL_ORDER' as 'EMAIL_ORDER',
    CREATE_FAVORITE: 'CREATE_FAVORITE' as 'CREATE_FAVORITE',
    EDIT_FAVORITE: 'EDIT_FAVORITE' as 'EDIT_FAVORITE',
    RUN_CUSTOM_REPORTS: 'RUN_CUSTOM_REPORTS' as 'RUN_CUSTOM_REPORTS',
    MANAGE_SYSTEM: 'MANAGE_SYSTEM' as 'MANAGE_SYSTEM',
    MANAGE_SYSTEM_OPTIONS: 'MANAGE_SYSTEM_OPTIONS' as 'MANAGE_SYSTEM_OPTIONS',
    MANAGE_COMPANY_INFOS: 'MANAGE_COMPANY_INFOS' as 'MANAGE_COMPANY_INFOS',
    MANAGE_TIERS: 'MANAGE_TIERS' as 'MANAGE_TIERS',
    MANAGE_COSTS: 'MANAGE_COSTS' as 'MANAGE_COSTS',
    MANAGE_POST_BILL_TYPES: 'MANAGE_POST_BILL_TYPES',
    MANAGE_BUSES: 'MANAGE_BUSES' as 'MANAGE_BUSES',
    MANAGE_HOLIDAYS: 'MANAGE_HOLIDAYS' as 'MANAGE_HOLIDAYS',
    MANAGE_EMAIL_HTML_TEMPLATES: 'MANAGE_EMAIL_HTML_TEMPLATES' as 'MANAGE_EMAIL_HTML_TEMPLATES',
    MANAGE_PAYMENT_GATEWAYS: 'MANAGE_PAYMENT_GATEWAYS' as 'MANAGE_PAYMENT_GATEWAYS',
    MANAGE_CATEGORIES_AND_PRODUCTS: 'MANAGE_CATEGORIES_AND_PRODUCTS' as 'MANAGE_CATEGORIES_AND_PRODUCTS',
    APPLY_CATEGORY_AND_PRODUCT_COSTS: 'APPLY_CATEGORY_AND_PRODUCT_COSTS' as 'APPLY_CATEGORY_AND_PRODUCT_COSTS',
    MANAGE_QSB_PRODUCTS: 'MANAGE_QSB_PRODUCTS' as 'MANAGE_QSB_PRODUCTS',
    AVAILABILITY_CALENDAR: 'AVAILABILITY_CALENDAR' as 'AVAILABILITY_CALENDAR',
    MANAGE_TRIP_GROUPINGS: 'MANAGE_TRIP_GROUPINGS' as 'MANAGE_TRIP_GROUPINGS',
    MANAGE_PASSES: 'MANAGE_PASSES' as 'MANAGE_PASSES',
    APPLY_PASS_COSTS: 'APPLY_PASS_COSTS' as 'APPLY_PASS_COSTS',
    MANAGE_QSB_PASSES: 'MANAGE_QSB_PASSES' as 'MANAGE_QSB_PASSES',
    MANAGE_COMMISSIONS: 'MANAGE_COMMISSIONS' as 'MANAGE_COMMISSIONS',
    MANAGE_KIOSKS: 'MANAGE_KIOSKS' as 'MANAGE_KIOSKS',
    MANAGE_KIOSK_CONFIGURATION_DETAILS: 'MANAGE_KIOSK_CONFIGURATION_DETAILS' as 'MANAGE_KIOSK_CONFIGURATION_DETAILS',
    MANAGE_ANDROIDS: 'MANAGE_ANDROIDS' as 'MANAGE_ANDROIDS',
    ANDROID_DEBUG_LOGS: 'ANDROID_DEBUG_LOGS' as 'ANDROID_DEBUG_LOGS',
    CAN_APPLY_DISCOUNT: 'CAN_APPLY_DISCOUNT' as 'CAN_APPLY_DISCOUNT',
    CAN_CASHOUT_ALL_USERS: 'CAN_CASHOUT_ALL_USERS' as 'CAN_CASHOUT_ALL_USERS',
    USE_FAVORITES: 'USE_FAVORITES' as 'USE_FAVORITES',
    MANAGE_LOCATIONS_AND_ROUTES: 'MANAGE_LOCATIONS_AND_ROUTES' as 'MANAGE_LOCATIONS_AND_ROUTES',
    RUN_NET_SUITE_EXPORT_REPORT: 'RUN_NET_SUITE_EXPORT_REPORT' as 'RUN_NET_SUITE_EXPORT_REPORT',
    REMOVE_FAVORITE: 'REMOVE_FAVORITE' as 'REMOVE_FAVORITE',
    RUN_OPERATIONAL_REPORTS: 'RUN_OPERATIONAL_REPORTS' as 'RUN_OPERATIONAL_REPORTS',
    RUN_FINANCIAL_REPORTS: 'RUN_FINANCIAL_REPORTS' as 'RUN_FINANCIAL_REPORTS',
    RUN_COLLECTED_AND_EARNED_REVENUE_REPORT: 'RUN_COLLECTED_AND_EARNED_REVENUE_REPORT' as 'RUN_COLLECTED_AND_EARNED_REVENUE_REPORT',
    MANAGE_INVENTORY: 'MANAGE_INVENTORY' as 'MANAGE_INVENTORY',
    MANAGE_SALES: 'MANAGE_SALES' as 'MANAGE_SALES',
    MANAGE_HARDWARE: 'MANAGE_HARDWARE' as 'MANAGE_HARDWARE',
    MANAGE_MOBILE: 'MANAGE_MOBILE' as 'MANAGE_MOBILE',
    RUN_AUDITLOG_REPORT: 'RUN_AUDITLOG_REPORT' as 'RUN_AUDITLOG_REPORT',
    RUN_HARDWARE_REPORTS: 'RUN_HARDWARE_REPORTS' as 'RUN_HARDWARE_REPORTS',
    RUN_INVALID_SCAN_REPORT: 'RUN_INVALID_SCAN_REPORT' as 'RUN_INVALID_SCAN_REPORT',
    MANAGE_FAVORITES: 'MANAGE_FAVORITES' as 'MANAGE_FAVORITES',
    MANAGE_PASS_CARDS: 'MANAGE_PASS_CARDS' as 'MANAGE_PASS_CARDS',
    MANAGE_OCCURRENCE: 'MANAGE_OCCURRENCE' as 'MANAGE_OCCURRENCE',
    CAN_EDIT_ORDERS: 'CAN_EDIT_ORDERS' as 'CAN_EDIT_ORDERS',
    PRINT_ORDER: 'PRINT_ORDER' as 'PRINT_ORDER',
    MANAGE_VOID_ORDER: 'MANAGE_VOID_ORDER' as 'MANAGE_VOID_ORDER',
    PRINT_ORDER_SUMMARY: 'PRINT_ORDER_SUMMARY' as 'PRINT_ORDER_SUMMARY',
    CLONE_ORDER: 'CLONE_ORDER' as 'CLONE_ORDER',
    EDIT_ORDER: 'EDIT_ORDER' as 'EDIT_ORDER',
    MANAGE_CUSTOMERS: 'MANAGE_CUSTOMERS' as 'MANAGE_CUSTOMERS',
	EDIT_SALE_GROUP: 'EDIT_SALE_GROUP' as 'EDIT_SALE_GROUP',
	MANAGE_SALE_GROUPS: 'MANAGE_SALE_GROUPS' as 'MANAGE_SALE_GROUPS',
    CUSTOMERS_ADMINISTRATION: 'CUSTOMERS_ADMINISTRATION' as 'CUSTOMERS_ADMINISTRATION',
    CAN_VIEW_CANCELLED_DETAILS: 'CAN_VIEW_CANCELLED_DETAILS' as 'CAN_VIEW_CANCELLED_DETAILS',
    MANAGE_CLOUD_PAYMENTS: 'MANAGE_CLOUD_PAYMENTS' as 'MANAGE_CLOUD_PAYMENTS',
    USE_CLOUD_PAYMENTS: 'USE_CLOUD_PAYMENTS' as 'USE_CLOUD_PAYMENTS',
    BULK_CHECK_IN: 'BULK_CHECK_IN' as 'BULK_CHECK_IN',
    CREATE_ORDER_LONG_FORM_CLOUD_PAYMENT_DEVICE: 'CREATE_ORDER_LONG_FORM_CLOUD_PAYMENT_DEVICE' as 'CREATE_ORDER_LONG_FORM_CLOUD_PAYMENT_DEVICE',
    RUN_CUSTOM_BILLING_REPORT: 'RUN_CUSTOM_BILLING_REPORT' as 'RUN_CUSTOM_BILLING_REPORT',
    MANAGE_NOTIFICATIONS: 'MANAGE_NOTIFICATIONS' as 'MANAGE_NOTIFICATIONS',
    MANAGE_OVERRIDE_REASON: 'MANAGE_OVERRIDE_REASON' as 'MANAGE_OVERRIDE_REASON',
    CAN_CREATE_LEGACY_TICKET_SCANS: 'CAN_CREATE_LEGACY_TICKET_SCANS' as 'CAN_CREATE_LEGACY_TICKET_SCANS',
    MOBILE: 'MOBILE' as 'MOBILE',
    CAN_DO_OVERRIDE_SCAN: 'CAN_DO_OVERRIDE_SCAN' as 'CAN_DO_OVERRIDE_SCAN',
    MANAGE_DOMAINS: 'MANAGE_DOMAINS' as 'MANAGE_DOMAINS',
    MANAGE_CUSTOMER_OWNER: 'MANAGE_CUSTOMER_OWNER' as 'MANAGE_CUSTOMER_OWNER',
    VOID_EXPIRED_ORDER: 'VOID_EXPIRED_ORDER' as 'VOID_EXPIRED_ORDER'
};
type PermissionEnum = (typeof PermissionEnum)[keyof typeof PermissionEnum];
export {PermissionEnum};
