import { Component, OnInit, Input, OnChanges, SimpleChanges  } from '@angular/core';
import {OrderEmailSendingInfoSummaryResponseModel} from '../../../../../../core/modules/rest/order-summary/response/order-email-sending-info-summary-response.model';


@Component({
    selector: 'app-email-sending-info-dialog-summary',
    templateUrl: './email-sending-info-dialog-summary.component.html'
})
export class EmailSendingInfoDialogSummaryComponent implements OnChanges {

    @Input() emailSendingInfos: OrderEmailSendingInfoSummaryResponseModel[];

    emailSendingInformation: EmailSendingInformation[] = [];

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.emailSendingInfos.currentValue) {
            this.emailSendingInformation = this.getEmailSendingInformation(this.emailSendingInfos).filter(e => e != null);
        }
    }

    getEmailSendingInformation(infos: OrderEmailSendingInfoSummaryResponseModel[]): EmailSendingInformation[] {

        return infos.map((info: OrderEmailSendingInfoSummaryResponseModel) => {

            let sentFromEmailAddress: string = "";
            let sentFromIp: string = "";
            let sentToEmailAddress: string = "";
            let sentToIp: string = "";
            let status: string = "";
            let dateTimeFriendly: string = "";
            let sentFromExternalSource: boolean = false;
            let sentByUser: string = "";

            if (info.exists && info.emailStatusDescriptors.length === 0 && info.emailViewDescriptors.length === 0) {
                // TODO: see what to do here, email is sent, but no response from amazon yet, maybe show some status about this
            }

            if (info.emailStatusDescriptors.length === 0) {
                return null;
            } else {
                sentFromEmailAddress = info.emailStatusDescriptors[0].sourceEmail;
                sentFromIp = info.emailStatusDescriptors[0].sourceIp;
                sentToEmailAddress = info.emailStatusDescriptors[0].recipient;
                status = info.emailStatusDescriptors[0].status;
                dateTimeFriendly = info.emailStatusDescriptors[0].dateTimeFriendly;
                sentByUser = info.sentByUser;
                sentFromExternalSource = info.sentFromExternalSource;

                if (info.emailStatusDescriptors.length !== 1) {
                    status = "";
                    info.emailStatusDescriptors.forEach((emailStatusDescriptor, index) => {
                        status += emailStatusDescriptor.status + (index !== info.emailStatusDescriptors.length -1 ? ", " : "");
                    });
                    
                }
            

            }

            sentToIp = info.emailViewDescriptors.length === 0 ? "-" : info.emailViewDescriptors[info.emailViewDescriptors.length - 1].ipAddress;

            return {
                sentFromEmailAddress: sentFromEmailAddress,
                sentFromIp: sentFromIp,
                sentToEmailAddress: sentToEmailAddress,
                sentToIp: sentToIp,
                status: status,
                viewCount: info.emailViewDescriptors.length,
                dateTimeFriendly: dateTimeFriendly,
                sentByUser: sentByUser,
                sentFromExternalSource: sentFromExternalSource
            };
        });
    }
}

interface EmailSendingInformation {
    sentFromEmailAddress: string;
    sentFromIp: string;
    sentToEmailAddress: string;
    sentToIp: string;
    status: string;
    viewCount: number;
    dateTimeFriendly: string;
    sentByUser: string;
    sentFromExternalSource: boolean;
}
