import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared.module";
import {YesNoDialogComponent} from "./container/yes-no-dialog.component";
import {Sandbox} from "./sandbox/sandbox";

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        Sandbox
    ],
    exports: [
        YesNoDialogComponent
    ],
    declarations: [
        YesNoDialogComponent
    ],
    entryComponents: [
        YesNoDialogComponent
    ]
})
export class YesNoDialogModule {

}
