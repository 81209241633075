import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ProductAvailabilityResponseModel} from "../../../../../../../../../core/modules/rest/product-availability/response/product-availability-response.model";

export const REDUCER_NAME = 'dialog_clone-product-availabilities';

export interface State {
    productAvailabilities: ProductAvailabilityResponseModel[];
}

const INITIAL_STATE: State = {
    productAvailabilities: []
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const productAvailabilities_selector = createSelector(state_selector, (state: State) => state.productAvailabilities);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;

        case actions.SET_PRODUCT_AVAILABILITIES:
            return {...state, productAvailabilities: action.productAvailabilities};

        default:
            return state;
    }
}
