import * as actions from './actions';

import {OccurrenceOrdersResponseModel} from "../../../../../../../../../core/modules/rest/occurrence/response/occurrence-orders-response.model";
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {createFeatureSelector, createSelector} from "@ngrx/store";

export const REDUCER_NAME: string = "dialog_deactivate_occurrences";

export interface State {

    occurrencesOrders: OccurrenceOrdersResponseModel[];
    emailHtmlTemplates: EmailHtmlTemplateResponseModel[];
}

const INITIAL_STATE: State = {

    occurrencesOrders: [],
    emailHtmlTemplates: []
};

// -----------
// Selectors
// -----------

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const occurrencesOrders_selector = createSelector(state_selector, (state: State) => state.occurrencesOrders);

export const emailHtmlTemplates_selector = createSelector(state_selector, (state: State) => state.emailHtmlTemplates);

// ---------
// Reducer
// ---------

export function reducer(state: State = INITIAL_STATE, action: actions.All): State {

    switch (action.type) {
        case actions.SET_OCCURRENCES_ORDERS:
            return {
                ...state,
                occurrencesOrders: action.occurrencesOrders
            };

        case actions.SET_EMAIL_HTML_TEMPLATES:
            return {
                ...state,
                emailHtmlTemplates: action.emailHtmlTemplates
            };

        default:
            return state;
    }
}
