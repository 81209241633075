import {Action} from "@ngrx/store";
import {PostBillTypeResponseModel} from "../../../../../../../../../core/modules/rest/post-bill-type/response/post-bill-type-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Post Bill Type :: MAIN] Reset State';
export const SET_POST_BILL_TYPE_ACTION = '[Dialog - Create/Edit Post Bill Type :: MAIN] Set Post Bill Type';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetPostBillType implements Action {
    readonly type = SET_POST_BILL_TYPE_ACTION;
    constructor(public postBillType: PostBillTypeResponseModel) {}
}

export type All = ResetState | SetPostBillType;
