import {DroppedDataI} from './dropped-data.model';
import {ExpressionItemModel} from './expression-item.model';

export class DroppedGroupDataModel implements DroppedDataI {
    parentGroupUUID: string;
    leftSiblingUUID: string;
    items: ExpressionItemModel[];

    constructor(parentGroupUUID: string, leftSiblingUUID: string, items: ExpressionItemModel[]) {
        this.parentGroupUUID = parentGroupUUID;
        this.leftSiblingUUID = leftSiblingUUID;
        this.items = items;
    }
}
