import {Action} from '@ngrx/store';

export const PAGE_DATA_LOADED = '[Kiosk Configuration Details UI] Page Data Loaded';

export class PageDataLoaded implements Action {
    readonly type = PAGE_DATA_LOADED;

    constructor(public loaded: boolean) {
    }
}


export type Actions =
    PageDataLoaded;
