import {NgModule} from "@angular/core";
import {EditOccurrencesDialogComponent} from "./edit-occurrences-dialog.component";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditOccurrencesDialogSandbox} from "./sandbox/edit-occurrences-dialog.sandbox";

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        EditOccurrencesDialogSandbox
    ],
    declarations: [
        EditOccurrencesDialogComponent
    ],
    exports: [
        EditOccurrencesDialogComponent
    ],
    entryComponents: [
        EditOccurrencesDialogComponent
    ]

})
export class EditOccurrencesDialogModule {

}
