<app-abstract-dialog-container>
    <app-mapping-cost-dialog-form
        [costId]="costId"
        [costDescription]="costDescription"
        [loading]="loading$ | async"
        [categories]="categories$ | async"
        [mappedCategories]="mappedCategories$ | async"
        [products]="products$ | async"
        [mappedProducts]="mappedProducts$ | async"
        [passes]="passes$ | async"
        [mappedPasses]="mappedPasses$ | async"
        (submit)="onSubmit($event)"
        (cancel)="onCancel()">
    </app-mapping-cost-dialog-form>
</app-abstract-dialog-container>
