
import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {LocationRestService} from "../../../../../../../../core/modules/rest/location/location-rest.service";
import {Observable} from "rxjs";
import {LocationResponseModel} from "../../../../../../../../core/modules/rest/location/response/location-response.model";
import {Store} from "@ngrx/store";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {CreateLocationRequestModel} from "../../../../../../../../core/modules/rest/location/request/create-location-request.model";
import {UpdateLocationRequestModel} from "../../../../../../../../core/modules/rest/location/request/update-location-request.model";
import {Injectable} from "@angular/core";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {LocationFormSubmitDataModel} from "../model/location-form-submit-data.model";

@Injectable()
export class EditLocationDialogSandbox extends DialogSandbox {

    location$: Observable<LocationResponseModel> = this.store.select(reducer.location_selector);

    constructor(appDialogsService: AppDialogsService,
                private locationRestService: LocationRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadLocation(locationId: number) {

        this.showLoader();

        this.locationRestService.getById(locationId).subscribe((location: LocationResponseModel) => {
            this.store.dispatch(new actions.SetLocation(location));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    createLocation(data: LocationFormSubmitDataModel): Observable<CreateBaseResponseModel> {
        const request = new CreateLocationRequestModel(data.description, data.zipCode, data.latitude, data.longitude);

        return this.locationRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating location', error);
            }));
    }

    updateLocation(locationId: number, data: LocationFormSubmitDataModel): Observable<any> {
        const request = new UpdateLocationRequestModel(locationId, data.description, data.zipCode, data.latitude, data.longitude);

        return this.locationRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating location', error);
            }));
    }
}
