export class VoidOrderRequestModel {

    orderId: number;
    lastStatusReason: string;
    lastStatusExplanation: string;
    refundAmount: number;
    shouldRefundPayments: boolean;
    shouldIncreaseRemainingQuantities: boolean;
    shouldUnusePassCards: boolean;
    shouldSendCustomerEmail: boolean;

    constructor(orderId: number, lastStatusReason: string, lastStatusExplanation: string, refundAmount: number,
                shouldRefundPayments: boolean, shouldIncreaseRemainingQuantities: boolean, shouldUnusePassCards: boolean, shouldSendCustomerEmail: boolean) {

        this.orderId = orderId;
        this.lastStatusReason = lastStatusReason;
        this.lastStatusExplanation = lastStatusExplanation;
        this.refundAmount = refundAmount;
        this.shouldRefundPayments = shouldRefundPayments;
        this.shouldIncreaseRemainingQuantities = shouldIncreaseRemainingQuantities;
        this.shouldUnusePassCards = shouldUnusePassCards;
        this.shouldSendCustomerEmail = shouldSendCustomerEmail;
    }
}
