
import {from as observableFrom, Observable} from 'rxjs';
import { Component, OnDestroy, OnInit  } from '@angular/core';
import * as moment from "moment";
import {CommissionReportSandbox} from '../sandbox/commission-report.sandbox';
import {ProductSimpleResponseModel} from '../../../../../../core/modules/rest/product/response/product-simple-response.model';
import {SortByModel} from '../../../../../../shared/model/sort-by.model';
import {BreadcrumbItemModel} from '../../../../../../shared/components/breadcrumbs/breadcrumb-item.model';
import {AppRoutesService} from '../../../../../../core/services/app-routes.service';
import {FilterFormValueModel} from '../model/filter-form-value.model';
import {CommissionReportCurrentSearchDataModel} from '../model/commission-report-current-search-data.model';
import {RoleResponseModel} from '../../../../../../core/modules/rest/role/response/role-response.model';
import {UserResponseModel} from '../../../../../../core/modules/rest/user/response/user-response.model';
import {DateTimeUtility} from "../../../../../../shared/utils/date-time-utility";
import {PassSimpleResponseModel} from "../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {FavoriteResponseModel} from "../../../../../../core/modules/rest/favorite/response/favorite-response.model";
import {
    EditFavoriteDialogComponent,
    EditFavoriteDialogData
} from "../../../../../../shared/dialogs/common-dialogs/edit-favorite-dialog/container/edit-favorite-dialog.component";
import {
    YesNoDialogComponent,
    YesNoDialogData
} from "../../../../../../shared/dialogs/common-dialogs/yes-no-dialog/container/yes-no-dialog.component";
import {AppDialogsService} from "../../../../../../core/services/app-dialogs.service";
import {PermissionEnum} from "../../../../../../shared/enums/permission.enum";
import {NgxPermissionsService} from "ngx-permissions";
import {FavoriteTypeEnum} from "../../../../../../shared/enums/favorite-type.enum";


@Component({
    selector: 'app-commission-report',
    templateUrl: './commission-report.component.html'
})
export class CommissionReportComponent implements OnInit, OnDestroy {

    pageBreadcrumbItems: BreadcrumbItemModel[];

    loadingFavorites$: Observable<boolean>;
    favorites$: Observable<FavoriteResponseModel[]>;
    selectedFavoriteId$: Observable<number>;
    favoriteCreatedUpdated$: Observable<boolean>;
    hasUseFavoritesPermission$: Observable<boolean>;
    hasCreateFavoritePermission$: Observable<boolean>;
    hasEditFavoritePermission$: Observable<boolean>;
    hasRemoveFavoritePermission$: Observable<boolean>;
    loadingRoles$: Observable<boolean>;
    roles$: Observable<RoleResponseModel[]>;
    loadingUsers$: Observable<boolean>;
    usersForSelectedRoles$: Observable<UserResponseModel[]>;
    loadingPasses$: Observable<boolean>;
    passes$: Observable<PassSimpleResponseModel[]>;
    loadingProducts$: Observable<boolean>;
    products$: Observable<ProductSimpleResponseModel[]>;
    selectedStartDate$: Observable<moment.Moment>;
    selectedEndDate$: Observable<moment.Moment>;
    selectedRoleIds$: Observable<number[]>;
    selectedUserIds$: Observable<number[]>;
    selectedPassIds$: Observable<number[]>;
    selectedProductIds$: Observable<number[]>;
    currentSearchData$: Observable<CommissionReportCurrentSearchDataModel>;
    currentSearchDataSortBy$: Observable<SortByModel>;

    private selectedStartDate: moment.Moment;
    private selectedEndDate: moment.Moment;
    private selectedRoleIds: number[];
    private selectedUserIds: number[];
    private selectedPassIds: number[];
    private selectedProductIds: number[];

    constructor(private commissionReportSandbox: CommissionReportSandbox, private appDialogsService: AppDialogsService,
                private appRoutesService: AppRoutesService, private permissionsService: NgxPermissionsService) {

        this.pageBreadcrumbItems = appRoutesService.createCommissionReportPageBreadcrumbs();
    }

    ngOnInit() {
        this.commissionReportSandbox.loadData();

        this.loadingFavorites$ = this.commissionReportSandbox.loadingFavorites$;
        this.favorites$ = this.commissionReportSandbox.favorites$;
        this.selectedFavoriteId$ = this.commissionReportSandbox.selectedFavoriteId$;
        this.favoriteCreatedUpdated$ = this.commissionReportSandbox.favoriteCreatedUpdated$;
        this.hasUseFavoritesPermission$ = observableFrom(this.permissionsService.hasPermission(PermissionEnum.USE_FAVORITES));
        this.hasCreateFavoritePermission$ = observableFrom(this.permissionsService.hasPermission(PermissionEnum.CREATE_FAVORITE));
        this.hasEditFavoritePermission$ = observableFrom(this.permissionsService.hasPermission(PermissionEnum.EDIT_FAVORITE));
        this.hasRemoveFavoritePermission$ = observableFrom(this.permissionsService.hasPermission(PermissionEnum.REMOVE_FAVORITE));
        this.loadingRoles$ = this.commissionReportSandbox.loadingRoles$;
        this.roles$ = this.commissionReportSandbox.roles$;
        this.loadingUsers$ = this.commissionReportSandbox.loadingUsers$;
        this.usersForSelectedRoles$ = this.commissionReportSandbox.usersForSelectedRoles$;
        this.loadingPasses$ = this.commissionReportSandbox.loadingPasses$;
        this.passes$ = this.commissionReportSandbox.passes$;
        this.loadingProducts$ = this.commissionReportSandbox.loadingProducts$;
        this.products$ = this.commissionReportSandbox.products$;
        this.selectedStartDate$ = this.commissionReportSandbox.selectedStartDate$;
        this.selectedEndDate$ = this.commissionReportSandbox.selectedEndDate$;
        this.selectedRoleIds$ = this.commissionReportSandbox.selectedRoleIds$;
        this.selectedUserIds$ = this.commissionReportSandbox.selectedUserIds$;
        this.selectedPassIds$ = this.commissionReportSandbox.selectedPassIds$;
        this.selectedProductIds$ = this.commissionReportSandbox.selectedProductIds$;
        this.currentSearchData$ = this.commissionReportSandbox.currentSearchData$;
        this.currentSearchDataSortBy$ = this.commissionReportSandbox.currentSearchDataSortBy$;

        // Keep selected role ids locally in order to track when they changed, in order to load user
        this.selectedRoleIds = [];
    }

    ngOnDestroy() {
        this.commissionReportSandbox.resetState();
    }

    onSelectedFavoriteChanged(favoriteId: number) {
        this.commissionReportSandbox.setSelectedFavoriteId(favoriteId);
    }

    onEditFavorite(favoriteId: number) {
        this.appDialogsService.renderDialog(EditFavoriteDialogComponent, new EditFavoriteDialogData(this.onFavoriteUpdated.bind(this), this.onFavoriteCreated.bind(this), favoriteId, FavoriteTypeEnum.COMMISSION_REPORT));
    }

    onRemoveFavorite(favoriteId: number) {

        let data: YesNoDialogData = new YesNoDialogData(
            "Disable Favorite",
            "Are you sure you want to disable favorite?",
            "Cancel",
            "Disable",
            "danger",
            (confirm: boolean) => {
                if (confirm) {
                    this.commissionReportSandbox.setSelectedFavoriteId(null);
                    this.commissionReportSandbox.deactivateFavorite(favoriteId)
                        .subscribe(() => {
                            this.onFavoriteRemoved(null);
                            this.commissionReportSandbox.resetState();
                        });
                }
            }
        );

        this.appDialogsService.renderDialog(YesNoDialogComponent, data);
    }

    onFavoriteUpdated(favoriteId: number) {
        this.commissionReportSandbox.loadAllCommissionReportFavoritesByUser(favoriteId, true);
    }

    onFavoriteCreated(favoriteId: number) {
        this.commissionReportSandbox.loadAllCommissionReportFavoritesByUser(favoriteId, true);
    }

    onFavoriteRemoved(favoriteId: number) {
        this.commissionReportSandbox.loadAllCommissionReportFavoritesByUser(favoriteId, false);
    }

    onFilterChanged(filterFormValue: FilterFormValueModel) {

        // Keep previous selected role ids
        let previousSelectedRoleIds: number[] = [...this.selectedRoleIds];

        // Check if selected roles changed
        let selectedRoleIdsChanged: boolean = false;
        for ( let roleId of previousSelectedRoleIds ) {
            if ( filterFormValue.selectedRoleIds.indexOf( roleId ) === -1 ) {
                selectedRoleIdsChanged = true;
                break;
            }
        }
        if ( !selectedRoleIdsChanged ) {
            for ( let roleId of filterFormValue.selectedRoleIds ) {
                if ( previousSelectedRoleIds.indexOf( roleId ) === -1 ) {
                    selectedRoleIdsChanged = true;
                    break;
                }
            }
        }

        // If selected roles changed, get users for selected roles (but with delay, in order to avoid ExpressionChangedAfterItHasBeenCheckedError)
        if (selectedRoleIdsChanged) {
            let that = this;
            setTimeout(() => {
                that.commissionReportSandbox.getAllUsersByRoleIds(filterFormValue.selectedRoleIds);
            }, 100);
        }

        this.selectedStartDate = filterFormValue.selectedStartDateEndDate.startDate;
        this.selectedEndDate = filterFormValue.selectedStartDateEndDate.endDate;
        this.selectedRoleIds = filterFormValue.selectedRoleIds;
        this.selectedUserIds = filterFormValue.selectedUserIds;
        this.selectedPassIds = filterFormValue.selectedPassIds;
        this.selectedProductIds = filterFormValue.selectedProductIds;

        // Update filter values
        this.commissionReportSandbox.updateFilter(this.selectedStartDate, this.selectedEndDate, this.selectedRoleIds, this.selectedUserIds, this.selectedPassIds, this.selectedProductIds, filterFormValue.isFavoriteSelected);

        // Clear search data (do not clear selected favorite)
        // this.commissionReportSandbox.clearSearchData(false);
    }

    onClearSearchData() {
        // Clear search data (clear selected favorite too)
        this.commissionReportSandbox.clearSearchData(true);
    }

    onRunSearch() {

        const selectedStartDateDescriptor = DateTimeUtility.convertMomentToDateTimeDescriptor(this.selectedStartDate);
        const selectedEndDateDescriptor = DateTimeUtility.convertMomentToDateTimeDescriptor(this.selectedEndDate);

        this.commissionReportSandbox.getCommissionReport(selectedStartDateDescriptor, selectedEndDateDescriptor, this.selectedRoleIds, this.selectedUserIds, this.selectedPassIds, this.selectedProductIds);
    }

    onGenerateUrlForExportToXlsCommissionReport() {

        const selectedStartDateDescriptor = DateTimeUtility.convertMomentToDateTimeDescriptor(this.selectedStartDate);
        const selectedEndDateDescriptor = DateTimeUtility.convertMomentToDateTimeDescriptor(this.selectedEndDate);

        this.commissionReportSandbox.generateUrlForExportToXlsCommissionReport(selectedStartDateDescriptor, selectedEndDateDescriptor, this.selectedRoleIds, this.selectedUserIds, this.selectedPassIds, this.selectedProductIds)
            .subscribe(this.generateUrlForExportToXlsCommissionReportSuccessCallback.bind(this));
    }

    onHeaderSort(sortBy: SortByModel) {

        const selectedStartDateDescriptor = DateTimeUtility.convertMomentToDateTimeDescriptor(this.selectedStartDate);
        const selectedEndDateDescriptor = DateTimeUtility.convertMomentToDateTimeDescriptor(this.selectedEndDate);

        this.commissionReportSandbox.updateSortBy(sortBy, selectedStartDateDescriptor, selectedEndDateDescriptor, this.selectedRoleIds, this.selectedUserIds, this.selectedPassIds, this.selectedProductIds);
    }

    private generateUrlForExportToXlsCommissionReportSuccessCallback(url: string) {
        window.open(url);
    }
}
