<div class="pass-dialog-content">
    <h1 class="mb-8px">Edit Pass Products</h1>
    <form class="pl-8px pr-8px" [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="order-form-item">
                    <label class="order-label">Products</label>
                    <div class="order-input-wrapper">
                        <app-multiselect-dropdown
                            class="main--element"
                            [allOptionEnabled]="true"
                            [options]="products"
                            [formControlName]="'productIds'">
                        </app-multiselect-dropdown>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Update</button>
    </div>
</div>
