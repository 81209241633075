import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import { EditDiscountDialogSandbox } from "../sandbox/edit-discount-dialog.sandbox";
import { Observable} from "rxjs";
import { MultiselectDropdownOptionModel } from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import { DiscountCostModel } from "../model/discount-cost.model";
import { DialogData } from "../../../../../../../../shared/dialogs/model/dialog.data";
import { SystemPermissionsResponseModel } from '../../../../../../../../core/modules/rest/system-permissions/response/system-permissions-response.model';
import { SystemPermissionEnum } from '../../../../../../../../shared/enums/system-permission.enum';
import { SystemPermissionsRestService } from '../../../../../../../../core/modules/rest/system-permissions/system-permissions-rest.service';

export class EditDiscountDialogData {

    discountId: number;
    callbackSuccessFn: () => void;

    constructor(callbackSuccessFn: () => void, discountId?: number) {
    this.callbackSuccessFn = callbackSuccessFn;
    this.discountId = discountId;
}
}


@Component({
    selector: 'app-edit-discount-dialog',
    templateUrl: './edit-discount-dialog.component.html',
    styleUrls: ['./edit-discount-dialog.component.scss'],
})
export class EditDiscountDialogComponent implements OnInit, OnDestroy {

    private discountId: number;
    private callbackSuccessFn: () => void;

    dialogDataLoaded$: Observable<boolean>;
    editDiscount$: Observable<boolean>;
    allPlatforms$: Observable<MultiselectDropdownOptionModel[]>;
    discount$: Observable<DiscountCostModel>;
    amountTypes$: Observable<MultiselectDropdownOptionModel[]>;
    errorMessage$: Observable<string>;

    constructor(@Inject(DialogData) dialogData: EditDiscountDialogData, private sandbox: EditDiscountDialogSandbox, private systemPermissionsRestService: SystemPermissionsRestService) {

        this.discountId = dialogData.discountId;
        this.callbackSuccessFn = dialogData.callbackSuccessFn;

        this.dialogDataLoaded$ = this.sandbox.getDialogDataLoaded();
        this.editDiscount$ = this.sandbox.getEditDiscount();
        this.allPlatforms$ = this.sandbox.getBasePlatformsForMultiselectDropdown();
        this.discount$ = this.sandbox.getDiscount();
        this.amountTypes$ = this.sandbox.getAmountTypesForMultiselectDropdown();
        this.errorMessage$ = this.sandbox.getErrorMessage();
    }

    ngOnInit() {
        this.sandbox.loadDialogData(this.discountId);
        this.systemPermissionsRestService.getSystemPermissions().subscribe((systemPermissions: SystemPermissionsResponseModel[]) => {
            let useKiosk =  systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.KIOSK)?.active ?? false;
            let useCustomers =  systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.USE_CUSTOMERS)?.active ?? false;
            this.allPlatforms$ = this.sandbox.getAllPlatformsForMultiselectDropdown(useKiosk, useCustomers);
         });
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onDiscountChanged(discount: DiscountCostModel) {
        this.sandbox.updateDiscountProperty(discount);
    }

    onSubmit() {
        this.sandbox.save().subscribe(this.saveSuccessCallback.bind(this));
    }

    onDialogClosed() {
        this.sandbox.destroyDialog();
    }

    private saveSuccessCallback(success: boolean) {
        if (success) {
            this.callbackSuccessFn();
            this.sandbox.destroyDialog();
        }
    }
}
