const PaymentMethodEnum = {
    CASH: 'CASH' as 'CASH',
    VISA: 'VISA' as 'VISA',
    MASTER_CARD: 'MASTER_CARD' as 'MASTER_CARD',
    DISCOVER: 'DISCOVER' as 'DISCOVER',
    AMERICAN_EXPRESS: 'AMERICAN_EXPRESS' as 'AMERICAN_EXPRESS',
    VOUCHER: 'VOUCHER' as 'VOUCHER',
    GIFTCARD: 'GIFTCARD' as 'GIFTCARD',
    UNKNOWN: 'UNKNOWN' as 'UNKNOWN',
    POST_BILL: 'POST_BILL' as 'POST_BILL',
    DINERS_CLUB: 'DINERS_CLUB' as 'DINERS_CLUB',
    DISCOUNT: 'DISCOUNT' as 'DISCOUNT',
    MULTI_PASS: 'MULTI_PASS' as 'MULTI_PASS',
    CUSTOM: 'CUSTOM' as 'CUSTOM',
    JCB: 'JCB' as 'JCB'
};
type PaymentMethodEnum = (typeof PaymentMethodEnum)[keyof typeof PaymentMethodEnum];
export {PaymentMethodEnum};

export function getPaymentMethodLabel(pm: PaymentMethodEnum) {

    switch (pm) {
        case PaymentMethodEnum.CASH:  return "Cash";
        case PaymentMethodEnum.VISA: return "Visa";
        case PaymentMethodEnum.MASTER_CARD: return "Master Card";
        case PaymentMethodEnum.DISCOVER: return "Discover";
        case PaymentMethodEnum.AMERICAN_EXPRESS: return "American Express";
        case PaymentMethodEnum.VOUCHER: return "Voucher";
        case PaymentMethodEnum.GIFTCARD: return "Giftcard";
        case PaymentMethodEnum.UNKNOWN: return "Unknown";
        case PaymentMethodEnum.POST_BILL: return "Postbill";
        case PaymentMethodEnum.DINERS_CLUB: return "Diners Club";
        case PaymentMethodEnum.DISCOUNT: return "Discount";
        case PaymentMethodEnum.MULTI_PASS: return "MultiPass";
        case PaymentMethodEnum.CUSTOM: return "Custom";
        case PaymentMethodEnum.JCB: return "Jcb";

        default:
            console.error("UNKNOWN PAYMENT METHOD VALUE");
            return "UNKNOWN PAYMENT METHOD VALUE";
    }
}

export function isCreditCardPayment(pm: PaymentMethodEnum): boolean {
    return pm === PaymentMethodEnum.VISA
        || pm === PaymentMethodEnum.MASTER_CARD
        || pm === PaymentMethodEnum.DISCOVER
        || pm === PaymentMethodEnum.AMERICAN_EXPRESS
        || pm === PaymentMethodEnum.DINERS_CLUB
        || pm === PaymentMethodEnum.JCB;
}
