const PlatformEnum = {
    ANDROID: 'ANDROID' as 'ANDROID',
    WEBSITE: 'WEBSITE' as 'WEBSITE',
    WEB_APPLICATION: 'WEB_APPLICATION' as 'WEB_APPLICATION',
    KIOSK: 'KIOSK' as 'KIOSK',
    CUSTOMER_APPLICATION: 'CUSTOMER_APPLICATION' as 'CUSTOMER_APPLICATION',
    ALL_PLATFORMS: 'ALL_PLATFORMS' as 'ALL_PLATFORMS'
};
type PlatformEnum = (typeof PlatformEnum)[keyof typeof PlatformEnum];
export {PlatformEnum};

export function getDescription(platform: PlatformEnum): string {
    if (platform === PlatformEnum.ANDROID) {
        return "Android";
    } else if (platform === PlatformEnum.WEBSITE) {
        return "Website";
    } else if (platform === PlatformEnum.WEB_APPLICATION) {
        return "Web Application";
    } else if (platform === PlatformEnum.KIOSK) {
        return "Kiosk";
    } else if (platform === PlatformEnum.CUSTOMER_APPLICATION) {
        return "Customer Application";
    } else if (platform === PlatformEnum.ALL_PLATFORMS) {
        return "All Platforms";
    }
    else {
        return "";
    }
}

