import {PassTierDataModel} from "./pass-tier-data.model";
import {PricingDateRangeFullResponseModel} from "../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-full-response.model";
import {PassTierResponseModel} from "../../../../../../core/modules/rest/pricing-date-range/response/pass-tier-response.model";

export class PricingDateRangeDataModel {
    pricingDateRangeId: number;
    active: boolean;
    passId: number;
    priority: number;
    startDateMS: number;
    startDateFriendly: string;
    endDateMS: number;
    endDateFriendly: string;
    startTime: number;
    startTimeFriendly: string;
    endTime: number;
    endTimeFriendly: string;
    fromLocationId: number;
    fromLocationDescription: string;
    toLocationId: number;
    toLocationDescription: string;

    passTiers: PassTierDataModel[];

    constructor(pricingDateRangeFull: PricingDateRangeFullResponseModel) {
        this.pricingDateRangeId = pricingDateRangeFull.pricingDateRangeId;
        this.active = pricingDateRangeFull.active;
        this.passId = pricingDateRangeFull.passId;
        this.priority = pricingDateRangeFull.priority;
        this.startDateMS = pricingDateRangeFull.startDateMS;
        this.startDateFriendly = pricingDateRangeFull.startDateFriendly;
        this.endDateMS = pricingDateRangeFull.endDateMS;
        this.endDateFriendly = pricingDateRangeFull.endDateFriendly;
        this.startTime = pricingDateRangeFull.startTime;
        this.startTimeFriendly = pricingDateRangeFull.startTimeFriendly;
        this.endTime = pricingDateRangeFull.endTime;
        this.endTimeFriendly = pricingDateRangeFull.endTimeFriendly;
        this.fromLocationId = pricingDateRangeFull.fromLocationId;
        this.fromLocationDescription = pricingDateRangeFull.fromLocationDescription;
        this.toLocationId = pricingDateRangeFull.toLocationId;
        this.toLocationDescription = pricingDateRangeFull.toLocationDescription;

        this.passTiers = pricingDateRangeFull.passTiers ?
            pricingDateRangeFull.passTiers.map((pt: PassTierResponseModel) => new PassTierDataModel(pt.tierId, pt.tierDescription, pt.cost)) :
            [];
    }
}
