import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild  } from '@angular/core';
import {DriverManifestReportFilterComponent} from './driver-manifest-report-filter/driver-manifest-report-filter.component';
import {FilterFormValueModel} from '../../model/filter-form-value.model';
import {SortByModel} from '../../../../../../../shared/model/sort-by.model';
import {BreadcrumbItemModel} from '../../../../../../../shared/components/breadcrumbs/breadcrumb-item.model';
import {DriverManifestReportCurrentSearchDataModel} from '../../model/driver-manifest-report-current-search-data.model';
import {FavoriteResponseModel} from "../../../../../../../core/modules/rest/favorite/response/favorite-response.model";
import * as moment from "moment";
import {ProductDirectionDataModel} from "../../model/product-direction-data.model";


@Component({
    selector: 'app-driver-manifest-report-content',
    templateUrl: './driver-manifest-report-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverManifestReportContentComponent implements OnInit {

    @Input() pageBreadcrumbItems: BreadcrumbItemModel[];
    @Input() favorites: FavoriteResponseModel[];
    @Input() loadingFavorites: boolean;
    @Input() selectedFavoriteId: number;
    @Input() hasUseFavoritesPermission: boolean;
    @Input() hasCreateFavoritePermission: boolean;
    @Input() hasEditFavoritePermission: boolean;
    @Input() hasRemoveFavoritePermission: boolean;
    @Input() productDirections: ProductDirectionDataModel[];
    @Input() loadingProducts: boolean;
    @Input() selectedDateType: string;
    @Input() selectedDate: moment.Moment;
    @Input() selectedProductDirections: string[];
    @Input() currentSearchData: DriverManifestReportCurrentSearchDataModel;
    @Input() currentSearchDataSortBy: SortByModel;
    @Input() favoriteCreatedUpdated: boolean;

    @Output() selectedFavoriteChangedEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() editFavoriteEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() removeFavoriteEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() clearSearchDataEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() runSearchEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() generateUrlForExportToXlsxDriverManifestReportEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() generateUrlForExportToPdfDriverManifestReportEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() printDriverManifestReportEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() filterChangedEvent: EventEmitter<FilterFormValueModel> = new EventEmitter();
    @Output() searchForNextReportItemsEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() headerSortEvent: EventEmitter<SortByModel> = new EventEmitter<SortByModel>();
    @Output() showSummaryEvent: EventEmitter<number> = new EventEmitter();

    @ViewChild(DriverManifestReportFilterComponent) driverManifestReportFilterComponent: DriverManifestReportFilterComponent;

    // Infinite Scroll
    scrollDistance = 2;
    scrollThrottle = 300;

    private filterFormValue: FilterFormValueModel;

    constructor() {
    }

    ngOnInit() {
    }

    onSelectedFavoriteChanged(favoriteId: number) {
        this.selectedFavoriteChangedEvent.emit(favoriteId);
    }

    onEditFavorite(favoriteId: number) {
        this.editFavoriteEvent.emit(favoriteId);
    }

    onRemoveFavorite(favoriteId) {
        this.removeFavoriteEvent.emit(favoriteId);
    }

    onClearSearchData() {

        // Clear filter
        this.driverManifestReportFilterComponent.clearFilter();

        // Clear search data
        this.clearSearchDataEvent.emit();
    }

    onRunSearch() {
        this.runSearchEvent.emit();
    }

    onSearchForNextReportItems() {
        if (this.isInfiniteScrollEnabled()) {
            this.searchForNextReportItemsEvent.emit();
        }
    }

    onGenerateUrlForExportToXlsxDriverManifestReport() {
        this.generateUrlForExportToXlsxDriverManifestReportEvent.emit();
    }

    onGenerateUrlForExportToPdfDriverManifestReport() {
        this.generateUrlForExportToPdfDriverManifestReportEvent.emit();
    }

    onPrintDriverManifestReport() {
        this.printDriverManifestReportEvent.emit();
    }

    onFilterChanged(filterFormValue: FilterFormValueModel) {
        this.filterFormValue = filterFormValue;
        this.filterChangedEvent.emit(filterFormValue);
    }

    onHeaderSort(sortBy: SortByModel) {
        this.headerSortEvent.emit(sortBy);
    }

    onShowSummary(orderId: number) {
        this.showSummaryEvent.emit(orderId);
    }

    isDisableRun() {
        return !this.filterFormValue ||
            !this.filterFormValue.selectedDateType ||
            !this.filterFormValue.selectedDate ||
            !this.filterFormValue.selectedProductDirections || (this.filterFormValue.selectedProductDirections && this.filterFormValue.selectedProductDirections.length === 0);
    }

    private isInfiniteScrollEnabled(): boolean {
        if (this.currentSearchData) {
            return !this.currentSearchData.noMoreItemsForLoading && !this.currentSearchData.loadingMoreItems;
        }

        return false;
    }
}
