export class UpdateProductAvailabilityHolidayMappingRequestModel {
    holidayId: number;
    productIds: number[];
    unassignedProductAvailabilityIds: number[];
    assignedProductAvailabilityIds: number[];

    constructor(holidayId: number, productIds: number[], unassignedProductAvailabilityIds: number[], assignedProductAvailabilityIds: number[]) {
        this.holidayId = holidayId;
        this.productIds = productIds;
        this.unassignedProductAvailabilityIds = unassignedProductAvailabilityIds;
        this.assignedProductAvailabilityIds = assignedProductAvailabilityIds;
    }
}
