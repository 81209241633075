
import {catchError} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {CashCollectedModel} from "../../../model/cash-collected.model";
import {CreateCashCollectedRequestModel} from "../../../../../../../../core/modules/rest/end-of-shift/request/create-cash-collected-request.model";
import {EndOfShiftRestService} from "../../../../../../../../core/modules/rest/end-of-shift/end-of-shift-rest.service";
import {CreateCashCollectedPaymentMethodRequestModel} from "../../../../../../../../core/modules/rest/end-of-shift/request/create-cash-collected-payment-method-request.model";
import {Observable} from "rxjs";
import {EndOfShiftReportOrderRevenueDescriptorRequestModel} from "../../../../../../../../core/modules/rest/end-of-shift/request/end-of-shift-report-order-revenue-descriptor-request.model";

@Injectable()
export class AddNewEntryDialogSandbox extends DialogSandbox {

    constructor(appDialogsService: AppDialogsService,
                private endOfShiftRestService: EndOfShiftRestService,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        // Do nothing
    }

    createCashCollected(cashCollectedData: CashCollectedModel): Observable<CreateBaseResponseModel> {

        let cashCollectedPaymentMethods: CreateCashCollectedPaymentMethodRequestModel[] = [];
        if (cashCollectedData.cashCollectedPaymentMethods) {
            for (let cashCollectedPaymentMethodData of cashCollectedData.cashCollectedPaymentMethods) {
                cashCollectedPaymentMethods.push(new CreateCashCollectedPaymentMethodRequestModel(
                    cashCollectedPaymentMethodData.paymentMethod,
                    cashCollectedPaymentMethodData.postBillTypeId,
                    cashCollectedPaymentMethodData.usd,
                    cashCollectedPaymentMethodData.totalCash
                ));
            }
        }

        let orderRevenueDescriptors: EndOfShiftReportOrderRevenueDescriptorRequestModel[] = [];
        if (cashCollectedData.orderRevenueDescriptors) {
            for (let orderRevenueDescriptor of cashCollectedData.orderRevenueDescriptors) {
                orderRevenueDescriptors.push(new EndOfShiftReportOrderRevenueDescriptorRequestModel(
                    orderRevenueDescriptor.orderId,
                    orderRevenueDescriptor.isRevenueIn
                ));
            }
        }

        const request = new CreateCashCollectedRequestModel(
            cashCollectedData.startDateTime,
            cashCollectedData.dateTimeCollected,
            cashCollectedData.locationId,
            cashCollectedData.cashCollectedForUserId,
            cashCollectedData.cashCollectedByUserId,
            cashCollectedData.reason,
            cashCollectedData.reconciled,
            cashCollectedPaymentMethods,
            orderRevenueDescriptors);

        return this.endOfShiftRestService.createCashCollected(request).pipe(
            catchError((error: HttpErrorModel) => {
                this.hideLoader();
                return this.rootSandbox.handleHttpError("Error while creating cashout entry", error);
            }));
    }
}
