import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {KioskConfigurationDropoffLocationPassTierModel} from "../../model/kiosk-configuration-dropoff-location-pass-tier.model";

export const REDUCER_NAME = "dialog_kiosk-configuration-details-pass-tier-setup";

export interface State {
    passTiers: KioskConfigurationDropoffLocationPassTierModel[];
}

const INITIAL_STATE: State = {
    passTiers: []
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const passTiers_selector = createSelector(state_selector, (state: State) => state.passTiers);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return INITIAL_STATE;

        case actions.SET_PASS_TIERS: return { ... state, passTiers: action.passTiers };

        default: return state;
    }
}
