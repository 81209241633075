import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';


@Component({
    selector: 'app-order-reports-table-caption',
    templateUrl: './order-reports-table-caption.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReportsTableCaptionComponent implements OnInit {

    @Input() displayMessage: string;
    @Output() generateUrlForExportToXlsxEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit() {
    }

    exportToExcelClick() {
        this.generateUrlForExportToXlsxEvent.emit();
    }
}
