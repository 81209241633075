import { UUID } from "angular2-uuid";
import { PaymentMethodEnum } from "../../../../../../../shared/enums/payment-method.enum";
import { DateTimeDescriptor } from "../../../../../../../shared/model/date-time-descriptor.model";
import { PostBillTypeAdditionalActionEnum } from "../../../../../../../shared/enums/post-bill-type-additional-action.enum";

export class PaymentItemDataModel {

    readonly guid: string;

    amount: number;
    paymentDateTime: DateTimeDescriptor;
    paymentMethod: PaymentMethodEnum;
    paymentMethodDescription: string;

    paymentServiceAuthorizationToken: string;
    lastFourOfCard: string;

    postBillTypeId: number;
    postBillTypeDescription: string;
    postBillTypeAdditionalAction: PostBillTypeAdditionalActionEnum;
    postBillImage: string;
    postBillCode: string;

    constructor() {
        this.guid = UUID.UUID();
    }

    isEqualTo(other: PaymentItemDataModel): boolean {
        return this.guid === other.guid;
    }
}
