const CloudPaymentRequestStatusEnum = {
    INFLIGHT: 'inFlight' as 'inFlight',
    POIDEVICEINUSE: 'poiDeviceInUse' as 'poiDeviceInUse',
    CANCELLED: 'cancelled' as 'cancelled',
    CANCELLEDATTERMINAL: 'cancelledAtTerminal' as 'cancelledAtTerminal',
    CANCELLEDBYAPI: 'cancelledByApi' as 'cancelledByApi',
    CANCELLEDBYTIMEOUT: 'cancelledByTimeout ' as 'cancelledByTimeout',
    INTERACTIONCOMPLETE: 'interactionComplete' as 'interactionComplete'
};
type CloudPaymentRequestStatusEnum = (typeof CloudPaymentRequestStatusEnum)[keyof typeof CloudPaymentRequestStatusEnum];
export {CloudPaymentRequestStatusEnum as CloudPaymentRequestStatusEnum};
