import {NgModule} from "@angular/core";
import {PreviewEmailHtmlTemplateDialogComponent} from "./container/preview-email-html-template-dialog.component";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {PreviewEmailHtmlTemplateDialogSandbox} from "./sandbox/preview-email-html-template-dialog.sandbox";
import { PreviewEmailHtmlTemplateFormComponent } from './components/preview-email-html-template-form/preview-email-html-template-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        PreviewEmailHtmlTemplateDialogSandbox
    ],
    declarations: [
        PreviewEmailHtmlTemplateDialogComponent,
        PreviewEmailHtmlTemplateFormComponent
    ],
    exports: [
        PreviewEmailHtmlTemplateDialogComponent
    ],
    entryComponents: [
        PreviewEmailHtmlTemplateDialogComponent
    ]
})
export class PreviewEmailHtmlTemplateDialogModule {

}
