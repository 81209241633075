import {RouterStateSerializer} from '@ngrx/router-store';
import {RouterStateSnapshot} from '@angular/router';
import { Injectable } from "@angular/core";

export * from '@ngrx/router-store';


export interface State {
    url: string;
    queryParams: any;
}

@Injectable()
export class CustomSerializer implements RouterStateSerializer<State> {
    serialize(routerState: RouterStateSnapshot): State {
        const url = routerState ? routerState.url : '';
        const queryParams = routerState.root.queryParams;

        return {url, queryParams};
    }
}
