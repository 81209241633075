import {
    getKioskConfigurationWarningTypeEnumDescription,
    KioskConfigurationWarningTypeEnum
} from "../../../../../../shared/enums/kiosk-configuration-warning-type.enum";

export class KioskConfigurationWarningModel {
    type: KioskConfigurationWarningTypeEnum;
    additionalDescription: string;
    fullDescription: string;

    constructor(type: KioskConfigurationWarningTypeEnum, additionalDescription: string) {
        this.type = type;
        this.additionalDescription = additionalDescription;

        this.fullDescription = getKioskConfigurationWarningTypeEnumDescription(type) + (additionalDescription ? ': ' + additionalDescription : '');
    }
}



