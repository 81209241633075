import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {ProductSimpleResponseModel} from "../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {Observable} from "rxjs";
import {SubmitProductRoutesData} from "./components/edit-product-routes-dialog-form/edit-product-routes-dialog-form.component";
import {EditProductRoutesDialogSandbox} from "./sandbox/edit-product-routes-dialog.sandbox";
import {DropdownOptionModel} from "../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export class EditProductRoutesDialogData extends DialogData {

    productId: number;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, productId: number) {
        super();
        this.productId = productId;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-edit-product-routes-dialog',
    templateUrl: './edit-product-routes-dialog.component.html'
})
export class EditProductRoutesDialogComponent implements OnDestroy {

    productId: number;
    successCallbackFn: Function;

    product$: Observable<ProductSimpleResponseModel>;
    pickupDropoffLocationLists$: Observable<DropdownOptionModel[]>;
    pickupLocationLists$: Observable<DropdownOptionModel[]>;
    dropoffLocationLists$: Observable<DropdownOptionModel[]>;

    constructor(@Inject(DialogData) data: EditProductRoutesDialogData, private sandbox: EditProductRoutesDialogSandbox) {

        this.productId = data.productId;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.productId);

        this.product$ = this.sandbox.product$;
        this.pickupDropoffLocationLists$ = this.sandbox.pickupDropoffLocationLists$;
        this.pickupLocationLists$ = this.sandbox.pickupLocationLists$;
        this.dropoffLocationLists$ = this.sandbox.dropoffLocationLists$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitProductRoutesData) {
        this.sandbox.updateProductRoutes(data).subscribe(() => {
            this.successCallbackFn(data.productId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
