import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DomainResponseModel } from '../../../../../../../../../core/modules/rest/domain/response/domain-response.model';
import { DomainFormSubmitDataModel } from '../../model/domain-form-submit-data.model';

@Component({
  selector: 'app-edit-domain-form',
  templateUrl: './edit-domain-form.component.html'
})
export class EditDomainFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() domain: DomainResponseModel;

    @Output() submitEvent = new EventEmitter<DomainFormSubmitDataModel>();
    @Output() closeEvent  = new EventEmitter<void>();

    form: FormGroup;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.form) {
            this.createForm();
        }

        if (this.editMode && changes.domain?.currentValue) {
            this.form.patchValue({
                domainName: changes.domain.currentValue.domainName,
            });
        }
    }

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.form = new FormGroup({
            domainName: new FormControl(
                this.editMode ? this.domain?.domainName || '' : '',
                [Validators.required, this.domainValidator]
            ),
        });
    }

    domainValidator(control: FormControl) {
        const value = control.value?.trim();

        if (!value) {
            return { required: true };
        }

        if (value.includes('@')) {
            return { invalidDomain: true };
        }

        if (/\s/.test(value)) {
            return { invalidDomain: true };
        }

        return null;
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {
        if (this.form.invalid) {
            return;
        }

        const domainName = this.form.value.domainName.trim();
        
        let domainId = 0;
        if (this.domain) {
            domainId = this.domain.domainId;
            this.domain.domainName = domainName;
        }

        const data: DomainFormSubmitDataModel = new DomainFormSubmitDataModel(domainId, domainName);
        this.submitEvent.emit(data);
    }
}
