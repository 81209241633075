const CustomFieldTypeEnum = {
    CHECKBOX : 'CHECKBOX' as 'CHECKBOX',
    TEXT: 'TEXT' as 'TEXT',
    NUMBER: 'NUMBER' as 'NUMBER',
    DATE: 'DATE' as 'DATE',
    LOCATION: 'LOCATION' as 'LOCATION',
    PICK_UP_LIST: 'PICK_UP_LIST' as 'PICK_UP_LIST',
    DROP_OFF_LIST: 'DROP_OFF_LIST' as 'DROP_OFF_LIST'
};

type CustomFieldTypeEnum = (typeof  CustomFieldTypeEnum)[keyof typeof CustomFieldTypeEnum];

export {CustomFieldTypeEnum};
