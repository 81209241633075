import { Action } from '@ngrx/store';
import { SaleGroupResponseModel } from '../../../../../../../core/modules/rest/sale-group/response/sale-group-response.model';
import { QuickSellingButtonProductResponseModel } from "../../../../../../../core/modules/rest/quick-selling-button-product/response/quick-selling-button-product-response.model";
import { QuickSellingButtonPassResponseModel } from "../../../../../../../core/modules/rest/quick-selling-button-pass/response/quick-selling-button-pass-response.model"
import { CustomerResponseModel } from '../../../../../../../core/modules/rest/customer/response/customer-response.model';
import { DomainResponseModel } from '../../../../../../../core/modules/rest/domain/response/domain-response.model';
import { CustomerItemResponseModel } from '../../../../../../../core/modules/rest/customer/response/customer-item-response.model';

export const UPDATE_SALE_GROUP_ACTION = '[Sale Group Main] Update sale group';
export const UPDATE_DOMAIN_TYPE_SALE_GROUP_ACTION = '[Sale Group Main] Update domain type sale group';
export const UPDATE_QSB_PASSES_ACTION = '[Sale Group Main] Update qsb passes';
export const UPDATE_QSB_PRODUCTS_ACTION = '[Sale Group Main] Update qsb products';
export const UPDATE_CUSTOMERS_ACTION = '[Sale Group Main] Update customers';
export const UPDATE_DOMAINS_ACTION = '[Sale Group Main] Update domains';
export const INIT_CUSTOMERS_ACTION = '[Sale Group Main] Initialize customers';
export const LOAD_MORE_CUSTOMERS_ACTION = '[Sale Group Main] Load more customers';
export const SET_CUSTOMERS_ACTION = '[Sale Group Main] Set customers';

export class UpdateSaleGroup implements Action {
    readonly type = UPDATE_SALE_GROUP_ACTION;

    constructor(public saleGroups: SaleGroupResponseModel[]) {
    }
}

export class UpdateDomainTypeSaleGroup implements Action {
    readonly type = UPDATE_DOMAIN_TYPE_SALE_GROUP_ACTION;

    constructor(public saleGroups: SaleGroupResponseModel[]) {
    }
}

export class UpdateQSBPasses implements Action {
    readonly type = UPDATE_QSB_PASSES_ACTION;

    constructor(public qsbPasses: QuickSellingButtonPassResponseModel[]) {
    }
}

export class UpdateQSBProducts implements Action {
    readonly type = UPDATE_QSB_PRODUCTS_ACTION;

    constructor(public qsbProducts: QuickSellingButtonProductResponseModel[]) {
    }
}

export class UpdateCustomers implements Action {
    readonly type = UPDATE_CUSTOMERS_ACTION;

    constructor(public customers) {
    }
}


export class UpdateDomains implements Action {
    readonly type = UPDATE_DOMAINS_ACTION;

    constructor(public domains: DomainResponseModel[]) {
    }
}


export class InitCustomers implements Action {
    readonly type = INIT_CUSTOMERS_ACTION;

    constructor() {
    }
}

export class LoadMoreCustomers implements Action {
    readonly type = LOAD_MORE_CUSTOMERS_ACTION;

    constructor() {
    }
}

export class SetCustomers implements Action {
    readonly type = SET_CUSTOMERS_ACTION;
    constructor(public customers: CustomerItemResponseModel[]) {}
  }

export type Actions =
    UpdateSaleGroup |
    UpdateDomainTypeSaleGroup |
    UpdateQSBPasses |
    UpdateQSBProducts |
    UpdateCustomers |
    UpdateDomains |
    InitCustomers |
    LoadMoreCustomers |
    SetCustomers;
