import {ShoppingCartProductTierRequestModel} from "./shopping-cart-product-tier-request.model";
import {ShoppingCartTicketRequestModel} from "./shopping-cart-ticket-request.model";
import {ShoppingCartCustomFieldRequestModel} from "./shopping-cart-custom-field-request.model";

export class ShoppingCartProductRequestModel {
    productId: number;
    productAvailabilityId: number;
    dateInt: number;

    pickupLocationId: number;
    customPickupLocationDescription: string;
    customPickupLocationLatitude: number;
    customPickupLocationLongitude: number;

    dropoffLocationId: number;
    customDropoffLocationDescription: string;
    customDropoffLocationLatitude: number;
    customDropoffLocationLongitude: number;

    isRoundTrip: boolean;
    isReturnTrip: boolean;

    parentGuid: string;
    childGuid: string;

    productTiers: ShoppingCartProductTierRequestModel[];
    tickets: ShoppingCartTicketRequestModel[];
    customFields: ShoppingCartCustomFieldRequestModel[];
    discountCodes: string[];

    constructor() {
    }
}
