export class GetProductAvailabilityHolidayMappingRequestModel {
    holidayId: number;
    productIds: number[];
    onlyValid: boolean;

    constructor(holidayId: number, productIds: number[], onlyValid) {
        this.holidayId = holidayId;
        this.productIds = productIds;
        this.onlyValid = onlyValid;
    }
}
