import * as actions from './actions';
import {OrderSummaryResponseModel} from "../../../../../../core/modules/rest/order-summary/response/order-summary-response.model";
import {ActionReducer, createFeatureSelector, createSelector} from "@ngrx/store";
import {UserResponseModel} from "../../../../../../core/modules/rest/user/response/user-response.model";
import {LocationResponseModel} from "../../../../../../core/modules/rest/location/response/location-response.model";
import {ProductSimpleResponseModel} from "../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {StartTimeResponseModel} from "../../../../../../core/modules/rest/product-availability/response/start-time-response.model";

export const REDUCER_NAME = 'dialog_order-summary';

export interface State {
    order: OrderSummaryResponseModel;
    users: UserResponseModel[];
    locations: LocationResponseModel[];
    products: ProductSimpleResponseModel[];
    startTimesForEditedOrderDetailLoading: boolean;
    startTimesForEditedOrderDetail: StartTimeResponseModel[];
}

const initialState: State = {
    order : {} as any,
    users: [],
    locations: [],
    startTimesForEditedOrderDetailLoading: false,
    startTimesForEditedOrderDetail: [],
    products: []
};

export const state_reducer: ActionReducer<State> = reducer;

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const order_selector = createSelector(state_selector, (state: State) => state.order);

export const users_selector = createSelector(state_selector, (state: State) => state.users);

export const products_selector = createSelector(state_selector, (state: State) => state.products);

export const startTimesForEditedOrderDetailLoading_selector = createSelector(state_selector, (state: State) => state.startTimesForEditedOrderDetailLoading);

export const startTimesForEditedOrderDetail_selector = createSelector(state_selector, (state: State) => state.startTimesForEditedOrderDetail);

export const locations_selector = createSelector(state_selector, (state: State) => state.locations);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_ORDER_ACTION: return handleSetOrder(state, action);

        case actions.SET_USERS_ACTION: return {...state, users: action.users};

        case actions.SET_PRODUCTS_ACTION: return {...state, products: action.products};

        case actions.SET_START_TIMES_FOR_EDITED_ORDER_DETAIL_LOADING_ACTION: return {...state, startTimesForEditedOrderDetailLoading: action.startTimesForEditedOrderDetailLoading};

        case actions.SET_START_TIMES_FOR_EDITED_ORDER_DETAIL_ACTION: return {...state, startTimesForEditedOrderDetail: action.startTimesForEditedOrderDetail};

        case actions.SET_LOCATIONS_ACTION: return {...state, locations: action.locations};

        default: return state;
    }
}

function handleSetOrder(state: State, action: actions.SetOrder): State {
    return {
        ... state,
        order: action.orderSummary
    };
}
