import * as actions from './actions';
import {TreeItemTypeEnum} from "../../model/tree-item-type.enum";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {TreeNode} from "../../../../../../../shared/components/tree/tree.component";
import {PermissionNode} from "../../model/permission-node";
import {BasicInformationDataModel} from "../../model/basic-information-data.model";
import { PermissionItemNode } from '../../../../../../../shared/components/tree-permission/tree-permission.component';

export const REDUCER_NAME: string = "feature_manage_roles_and_users";

export interface State {

    item: TreeNode;
    node: TreeNodeMaterial;
    itemType: TreeItemTypeEnum;
    itemId: number;
    itemActive: boolean;
    itemBasicInfo: BasicInformationDataModel;

    showInactiveItems: boolean;

    rolesAndUsersTree: TreeNode[];
    permissionTree: PermissionNode[];
    permissionTreeMaterial: PermissionItemNode[];

    loadingRolesAndUsers: boolean;
    loadingPermissionsTree: boolean;

    userRolePermissionTree: PermissionNode[];

    formHasChanges: boolean;
}

export const INITIAL_STATE: State = {

    item: {} as TreeNode,
    node: {} as TreeNodeMaterial,
    itemType: null,
    itemId: null,
    itemActive: false,
    itemBasicInfo: {
        roles: [],
        roleId: null,
        displayName: null,
        username: null,
        password: null,
        email: null,
        description: null
    },
    showInactiveItems: false,

    rolesAndUsersTree: [],
    permissionTree: [],
    permissionTreeMaterial: [],

    loadingRolesAndUsers: false,
    loadingPermissionsTree: false,

    userRolePermissionTree: [],

    formHasChanges: false
};

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const item_selector = createSelector(state_selector, (state: State) => state.item);

export const node_selector = createSelector(state_selector, (state: State) => state.node);

export const item_id_selector = createSelector(state_selector, (state: State) => state.itemId);

export const item_active_selector = createSelector(state_selector, (state: State) => state.itemActive);

export const item_type_selector = createSelector(state_selector, (state: State) => state.itemType);

export const item_basic_info_selector = createSelector(state_selector, (state: State) => state.itemBasicInfo);

export const roles_and_users_tree_selector = createSelector(state_selector, (state: State) => state.rolesAndUsersTree);

export const permission_tree_selector = createSelector(state_selector, (state: State) => state.permissionTree);

export const permission_tree_material_selector = createSelector(state_selector, (state: State) => state.permissionTreeMaterial);

export const loading_roles_and_users_selector = createSelector(state_selector, (state: State) => state.loadingRolesAndUsers);

export const loading_permissions_tree_selector = createSelector(state_selector, (state: State) => state.loadingPermissionsTree);

export const user_role_permission_tree_selector = createSelector(state_selector, (state: State) => state.userRolePermissionTree);

export const show_inactive_items_selector = createSelector(state_selector, (state: State) => state.showInactiveItems);

export const formHasChanges_selector = createSelector(state_selector, (state: State) => state.formHasChanges);

export function reducer(state: State = INITIAL_STATE, action: actions.All): State {

    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;
        case actions.SET_SELECTED_ITEM:
            return {
                ...state,
                item: action.item
            };
        case actions.SET_SELECTED_NODE:
            return {
                ...state,
                node: action.node
            };
        case actions.SET_ROLE_OR_USER_FOR_EDIT:
            return {
                ...state,
                itemType: action.itemType,
                itemId: action.itemId,
                itemActive: action.itemActive
            };
        case actions.SET_ROLES_AND_USERS_TREE:
            return {
                ...state,
                rolesAndUsersTree: action.tree
            };
        case actions.SET_PERMISSION_TREE:
            return {
                ...state,
                permissionTree: action.tree
            };
        case actions.SET_PERMISSION_TREE_MATERIAL:
            return {
                ...state,
                permissionTreeMaterial: action.treeMaterial
            };
        case actions.SET_BASIC_INFO:
            return {
                ...state,
                itemBasicInfo: action.info
            };
        case actions.SET_LOADING_ROLES_AND_USERS:
            return {
                ...state,
                loadingRolesAndUsers: action.loading
            };
        case actions.SET_LOADING_PERMISSIONS_TREE:
            return {
                ...state,
                loadingPermissionsTree: action.loading
            };
        case actions.SET_SHOW_INACTIVE_ITEMS:
            return {
                ...state,
                showInactiveItems: action.showInactiveItems
            };
        case actions.SET_USER_ROLE_PERMISSION_TREE:
            return {
                ...state,
                userRolePermissionTree: action.tree
            };
        case actions.SET_FORM_HAS_CHANGES:
            return {
                ...state, formHasChanges: action.data
            };
        default:
            return state;
    }
}
