
import {filter, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild  } from '@angular/core';
import {FavoriteResponseModel} from "../../../../../../core/modules/rest/favorite/response/favorite-response.model";
import {Subject, Subscription} from "rxjs";
import {FavoriteTypeEnum} from "../../../../../enums/favorite-type.enum";

export interface FavoriteDescriptionAndGroupDescription {
    favoriteDescription: string;
    groupDescription: string;
}



@Component({
    selector: 'app-edit-favorite-dialog-form',
    templateUrl: './edit-favorite-dialog-form.component.html'
})

export class EditFavoriteDialogFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() favorite: FavoriteResponseModel;
    @Input() descriptionAvailable: boolean = true;
    @Input() favoriteType: FavoriteTypeEnum = null;

    @Output() descriptionChanged: EventEmitter<string> = new EventEmitter();
    @Output() submitEvent: EventEmitter<FavoriteDescriptionAndGroupDescription> = new EventEmitter();
    @Output() closeEvent = new EventEmitter<void>();

    @ViewChild('groupName') groupNameInput: ElementRef;

    favoriteDescriptionValue: string = "";
    groupDescriptionValue: string = "";
    previousDescriptionValue: string = "";

    private keyUp$: Subject<string>;
    private keyUpSubscription: Subscription;

    constructor() {
        this.keyUp$ = new Subject();
    }

    ngOnInit(): void {

        this.keyUpSubscription = this.keyUp$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            filter(value => !!value && value.trim() !== this.previousDescriptionValue),)
            .subscribe(() => {
                this.descriptionChanged.emit(this.favoriteDescriptionValue);
            });
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.editMode && changes.favorite && changes.favorite.currentValue !== null) {

            // Set description values
            this.favoriteDescriptionValue = this.favorite.description;
            this.previousDescriptionValue = this.favorite.description;
        }
        if (this.favorite && this.editMode) {
            this.favorite.groupDescription ? this.groupDescriptionValue = this.favorite.groupDescription : this.groupDescriptionValue = "";
        }
    }

    onFavoriteInputKeyUp(): void {
        this.keyUp$.next(this.favoriteDescriptionValue);
    }

    onCancelClick(): void {
        this.closeEvent.emit();
    }

    onSubmitClick(): void {
        this.submitEvent.emit({
            favoriteDescription: this.favoriteDescriptionValue,
            groupDescription: this.groupDescriptionValue
        });
    }

    checkIsOrderReportPage(): boolean {
        return this.favoriteType === FavoriteTypeEnum.ORDER_REPORTS;
    }
}
