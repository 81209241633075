import { PlatformEnum } from "../../../../../shared/enums/platform.enum";
import { NotificationTypeEnum } from "../../../../../shared/enums/notification-type.enum";

export class CreateNotificationRequestModel {
    subject: string;
    description: string;
    notificationType: NotificationTypeEnum;
    platform: PlatformEnum;
    fromDateTime: String;
    toDateTime: String;
    sendEmail: boolean;
    baseUserIds: number[];
    templateId: number;

    constructor( subject: string, description: string, notificationType: NotificationTypeEnum, platform: PlatformEnum, fromDateTime: String, toDateTime: String, sendEmail: boolean, baseUserIds: number[], templateId: number) {
        this.subject = subject;
        this.description = description;
        this.notificationType = notificationType;
        this.platform = platform;
        this.fromDateTime = fromDateTime;
        this.toDateTime = toDateTime;
        this.sendEmail = sendEmail;
        this.baseUserIds = baseUserIds;
        this.templateId = templateId;
    }
}
