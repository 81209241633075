
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {ProductRestService} from "../../../../../../../../core/modules/rest/product/product-rest.service";
import {CostRestService} from "../../../../../../../../core/modules/rest/cost/cost-rest.service";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {ProductCostResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-cost-response.model";
import {AdditionalCostResponseModel} from "../../../../../../../../core/modules/rest/cost/response/additional-cost-response.model";
import {IncludedCostResponseModel} from "../../../../../../../../core/modules/rest/cost/response/included-cost-response.model";
import {DiscountResponseModel} from "../../../../../../../../core/modules/rest/cost/response/discount-response.model";
import {AutoAppliedDiscountResponseModel} from "../../../../../../../../core/modules/rest/cost/response/auto-applied-discount-response.model";
import {SubmitProductCostsData} from "../components/edit-product-costs-dialog-form/edit-product-costs-dialog-form.component";
import {MultiselectDropdownOptionModel} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import {CostTypeEnum} from "../../../../../../../../shared/enums/cost-type.enum";

@Injectable()
export class EditProductCostsDialogSandbox extends DialogSandbox {

    loading$: Observable<boolean> = this.store.pipe(select(reducer.loading_selector));
    productAdditionalCosts$: Observable<ProductCostResponseModel[]> = this.store.pipe(select(reducer.productAdditionalCosts_selector));
    additionalCosts$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.additionalCosts_selector));
    productIncludedCosts$: Observable<ProductCostResponseModel[]> = this.store.pipe(select(reducer.productIncludedCosts_selector));
    includedCosts$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.includedCosts_selector));
    productDiscounts$: Observable<ProductCostResponseModel[]> = this.store.pipe(select(reducer.productDiscounts_selector));
    discounts$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.discounts_selector));
    productAutoAppliedDiscounts$: Observable<ProductCostResponseModel[]> = this.store.pipe(select(reducer.productAutoAppliedDiscounts_selector));
    autoAppliedDiscounts$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.autoAppliedDiscounts_selector));

    constructor(appDialogsService: AppDialogsService,
                private productRestService: ProductRestService,
                private costRestService: CostRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(productId: number) {

        this.store.dispatch(new actions.SetLoading(true));

        observableCombineLatest(this.productRestService.getProductCostsByProductId(productId), this.costRestService.getAllAdditionalCostsActive(), this.costRestService.getAllIncludedCostsActive(), this.costRestService.getAllDiscountsActive(), this.costRestService.getAllAutoAppliedDiscountsActive()).pipe(
            take(1))
            .subscribe(([productCosts, additionalCosts, includedCosts, discounts, autoAppliedDiscounts]: [ProductCostResponseModel[], AdditionalCostResponseModel[], IncludedCostResponseModel[], DiscountResponseModel[], AutoAppliedDiscountResponseModel[]]) => {
                this.store.dispatch(new actions.SetAdditionalCosts(additionalCosts.map(ac => new MultiselectDropdownOptionModel(ac.description, ac.costId.toString()))));
                this.store.dispatch(new actions.SetProductAdditionalCosts(productCosts.filter(pc => pc.costType === CostTypeEnum.ADDITIONAL)));
                this.store.dispatch(new actions.SetIncludedCosts(includedCosts.map(ic => new MultiselectDropdownOptionModel(ic.description, ic.costId.toString()))));
                this.store.dispatch(new actions.SetProductIncludedCosts(productCosts.filter(pc => pc.costType === CostTypeEnum.INCLUDED)));
                this.store.dispatch(new actions.SetDiscounts(discounts.map(d => new MultiselectDropdownOptionModel(d.description, d.costId.toString()))));
                this.store.dispatch(new actions.SetProductDiscounts(productCosts.filter(pc => pc.costType === CostTypeEnum.DISCOUNT)));
                this.store.dispatch(new actions.SetAutoAppliedDiscounts(autoAppliedDiscounts.map(aad => new MultiselectDropdownOptionModel(aad.description, aad.costId.toString()))));
                this.store.dispatch(new actions.SetProductAutoAppliedDiscounts(productCosts.filter(pc => pc.costType === CostTypeEnum.AUTO_APPLIED_DISCOUNT)));
                this.store.dispatch(new actions.SetLoading(false));
            },
            () => this.store.dispatch(new actions.SetLoading(false)));
    }

    updateProductCosts(data: SubmitProductCostsData): Observable<any> {

        return this.productRestService.updateProductCosts({
            productId: data.productId,
            costIds: data.costIds
        });
    }
}
