import {DateTimeDescriptor} from "../../../../../shared/model/date-time-descriptor.model";
import {SortByRequestModel} from "../../reporting/request/sort-by-request.model";

export class GetPricingDateRangesFilteredRequestModel {
    from: number;
    size: number;
    productId: number;
    passId: number;
    startDate: DateTimeDescriptor;
    endDate: DateTimeDescriptor;
    pickupLocationIds: number[];
    dropoffLocationIds: number[];
    tierIds: number[];
    showInactive: boolean;
    showInactiveTiers: boolean;
    showInactivePickupLocations: boolean;
    showInactiveDropoffLocations: boolean;
    showInactiveDepartureGroups: boolean;
    sortBy: SortByRequestModel;

    constructor(from: number, size: number, productId: number, passId: number, startDate: DateTimeDescriptor, endDate: DateTimeDescriptor, pickupLocationIds: number[], dropoffLocationIds: number[], tierIds: number[], showInactive: boolean, showInactiveTiers: boolean, showInactivePickupLocations: boolean, showInactiveDropoffLocations: boolean, showInactiveDepartureGroups: boolean, sortBy: SortByRequestModel) {
        this.from = from;
        this.size = size;
        this.productId = productId;
        this.passId = passId;
        this.startDate = startDate;
        this.endDate = endDate;
        this.pickupLocationIds = pickupLocationIds;
        this.dropoffLocationIds = dropoffLocationIds;
        this.tierIds = tierIds;
        this.showInactive = showInactive;
        this.showInactiveTiers = showInactiveTiers;
        this.showInactivePickupLocations = showInactivePickupLocations;
        this.showInactiveDropoffLocations = showInactiveDropoffLocations;
        this.showInactiveDepartureGroups = showInactiveDepartureGroups;
        this.sortBy = sortBy;
    }
}
