<div class="category-and-product-dialog-content">
    <h1 class="mb-8px">Edit Product Routes</h1>
    <form class="pl-8px pr-8px" [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="mb-16px">
                    <app-switch
                        [switchLabel]="'Tracks Quantity Between Stops'"
                        formControlName="tracksQuantityBetweenStops">
                    </app-switch>
                </div>
                <div *ngIf="form.value.tracksQuantityBetweenStops" class="cell medium-12 mb-8px">
                    <app-input-label-group>
                        <label class="label">Pickup & Drop-off Location List</label>
                        <app-dropdown
                            class="main--element"
                            [customClasses]="'dropdown_large'"
                            [options]="pickupDropoffLocationLists"
                            formControlName="pickupDropoffLocationListId">
                        </app-dropdown>
                    </app-input-label-group>
                </div>
                <div *ngIf="!form.value.tracksQuantityBetweenStops" class="cell medium-12 mb-8px">
                    <div class="flex-container space-between mb-8px flex-dir-column align-stretch ">
                        <app-checkbox
                            [labelName]="'Uses Pickup Locations'"
                            formControlName="usesPickupLocations">
                        </app-checkbox>
                        <app-dropdown
                            class="main--element"
                            [customClasses]="'dropdown_large'"
                            [disabled]="!form.value.usesPickupLocations"
                            [options]="pickupLocationLists"
                            formControlName="pickupLocationListId">
                        </app-dropdown>
                    </div>
                    <div class="flex-container space-between flex-dir-column align-stretch ">
                        <app-checkbox
                            [labelName]="'Uses Drop-off Locations'"
                            formControlName="usesDropoffLocations">
                        </app-checkbox>
                        <app-dropdown
                            class="main--element"
                            [customClasses]="'dropdown_large'"
                            [disabled]="!form.value.usesDropoffLocations"
                            [options]="dropoffLocationLists"
                            formControlName="dropoffLocationListId">
                        </app-dropdown>
                    </div>
                </div>
            </div>
        </ng-container>

        <p *ngIf="hasError" class="error">{{ errorMessage }}</p>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Update</button>
    </div>
</div>
