import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AdditionalCostModel } from "../../model/additional-cost.model";
import { MultiselectDropdownOptionModel } from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import * as moment from "moment";
import { AmountTypeEnum } from "../../../../../../../../../shared/enums/amount-type.enum";
import _ from 'lodash';
import { PlatformEnum } from "../../../../../../../../../shared/enums/platform.enum";


@Component({
    selector: 'app-edit-additional-cost-dialog-content',
    templateUrl: './edit-additional-cost-dialog-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditAdditionalCostDialogContentComponent implements OnInit, OnChanges {

    @Input() additionalCost: AdditionalCostModel;
    @Input() amountTypes: MultiselectDropdownOptionModel[];
    @Input() allLocations: MultiselectDropdownOptionModel[];
    @Input() allPlatforms: MultiselectDropdownOptionModel[];

    @Output() additionalCostChangedEvent: EventEmitter<AdditionalCostModel> = new EventEmitter();

    additionalCostLocalCopy: AdditionalCostModel;
    startDate: moment.Moment = null;
    endDate: moment.Moment = null;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.additionalCost) {

            this.additionalCostLocalCopy = _.cloneDeep(changes.additionalCost.currentValue);

            if (changes.additionalCost.currentValue.startDateInt) {
                this.startDate = moment(changes.additionalCost.currentValue.startDateInt, 'YYYYMMDD');
            }
            else {
                this.startDate = null;
            }
            if (changes.additionalCost.currentValue.endDateInt) {
                this.endDate = moment(changes.additionalCost.currentValue.endDateInt, 'YYYYMMDD');
            }
            else {
                this.endDate = null;
            }
        }
    }

    getOnlyActivePlatforms() {
        if (this.additionalCost === undefined || this.additionalCost.platforms === undefined) {
            return [];
        }
        if (this.additionalCostLocalCopy === undefined || this.additionalCostLocalCopy.platforms === undefined) {
            return this.additionalCost.platforms.filter((value: PlatformEnum) => {
                if (this.allPlatforms.some(platform => platform.value == value)) {
                    return true;
                }
                return false;
            })
        }
        return this.additionalCostLocalCopy.platforms.filter((value: PlatformEnum) => {
            if (this.allPlatforms.some(platform => platform.value == value)) {
                return true;
            }
            return false;
        })
    }

    onInputChanged() {
        this.additionalCostChangedEvent.emit(this.additionalCostLocalCopy);
    }

    onAmountTypeChanged(value: AmountTypeEnum) {
        this.additionalCostLocalCopy.amountType = value;
        this.additionalCostChangedEvent.emit(this.additionalCostLocalCopy);
    }

    onStartDateChanged(date: moment.Moment) {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const ms = date ? moment.parseZone(date, timeZone).valueOf() : null;
            this.additionalCostLocalCopy.startDateMS = ms;
            const int = date ? parseInt(date.format("YYYYMMDD")) : null;
            this.additionalCostLocalCopy.startDateInt = int;
            this.additionalCostChangedEvent.emit(this.additionalCostLocalCopy);
    }

    onEndDateChanged(date: moment.Moment) {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const ms = date ? moment.parseZone(date, timeZone).valueOf() : null;
            this.additionalCostLocalCopy.endDateMS = ms;
            const int = date ? parseInt(date.format("YYYYMMDD")) : null;
            this.additionalCostLocalCopy.endDateInt = int;
            this.additionalCostChangedEvent.emit(this.additionalCostLocalCopy);
    }

    onIsOrderLevelChanged(value: boolean) {
        this.additionalCostLocalCopy.isOrderLevel = value;
        this.additionalCostChangedEvent.emit(this.additionalCostLocalCopy);
    }

    onAllLocationsChanged(value: boolean) {
        this.additionalCostLocalCopy.allLocations = value;
        this.additionalCostChangedEvent.emit(this.additionalCostLocalCopy);
    }

    onSelectedLocationsChanged(values: string[]) {
        this.additionalCostLocalCopy.locationIds = values.map((value: string) => +value);
        this.additionalCostChangedEvent.emit(this.additionalCostLocalCopy);
    }

    onAllPlatformsChanged(value: boolean) {
        this.additionalCostLocalCopy.allPlatforms = value;
        this.additionalCostChangedEvent.emit(this.additionalCostLocalCopy);
    }

    onSelectedPlatformsChanged(values: string[]) {
        this.additionalCostLocalCopy.platforms = values.map((value: string) => <PlatformEnum>value);
        this.additionalCostChangedEvent.emit(this.additionalCostLocalCopy);
    }
}
