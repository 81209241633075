import {ShoppingCartProductRequestModel} from "./shopping-cart-product-request.model";
import {ShoppingCartPassRequestModel} from "./shopping-cart-pass-request.model";
import {ShoppingCartPaymentRequestModel} from "./shopping-cart-payment-request.model";
import {ShoppingCartCustomFieldRequestModel} from "./shopping-cart-custom-field-request.model";
import {PlatformEnum} from "../../../../../shared/enums/platform.enum";

export class ShoppingCartRequestModel {

    guid: string;
    kioskDeviceNumber: string;
    shouldProcessOrder: boolean;
    shouldCreateFreeSellProducts: boolean;
    shouldGenerateExternalIds: boolean;
    shouldGenerateTicketsAndPassCards: boolean;
    shouldGeneratePdf: boolean;
    shouldGenerateGooglePass: boolean;
    shouldGenerateApplePass: boolean;
    shouldSendCustomerEmail: boolean;
    shouldSendProductOwnersEmails: boolean;

    orderExternalId: string;
    soldById: number;
    referredById: number;
    soldAtLocationId: number;
    soldFrom: PlatformEnum;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    isTicketPerPerson: boolean;
    signature: string;

    products: ShoppingCartProductRequestModel[];
    passes: ShoppingCartPassRequestModel[];
    payments: ShoppingCartPaymentRequestModel[];
    customFields: ShoppingCartCustomFieldRequestModel[];
    discountCodes: string[];
    paymentServiceAuthorizationTokensToAdd: string[];
    paymentServiceAuthorizationTokensToRemove: string[];

    constructor() {
    }
}
