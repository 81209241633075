import {Action} from "@ngrx/store";
import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Location :: MAIN] Reset State';
export const SET_LOCATION_ACTION = '[Dialog - Create/Edit Location :: MAIN] Set Location';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetLocation implements Action {
    readonly type = SET_LOCATION_ACTION;
    constructor(public location: LocationResponseModel) {}
}

export type All = ResetState | SetLocation;
