import { Component, Input, OnInit  } from "@angular/core";
import {FormGroup} from "@angular/forms";


@Component({
    selector: 'app-edit-pass-tier-dialog-form',
    templateUrl: './edit-pass-tier-dialog-form.component.html'
})
export class EditPassTierDialogFormComponent implements OnInit {

    @Input() passTierForm: FormGroup;
    @Input() arePassTiersDisabled: boolean;

    constructor() {}

    ngOnInit() {}
}
