<form class="form_container deactivate-occurrence-dialog">
    <div class="header_wrapper">
        <h1>Occurrence Manifest</h1>
    </div>
    <div class="content_wrapper">
        <h3 class="mb-16px">{{getDialogSubtitle()}}</h3>

        <div class="empty-state flex-container flex-dir-column align-items-center align-center" *ngIf="!occurrenceTickets || !occurrenceTickets.occurrenceTickets.length && !passCardCheckIns.length">
            <h2 class="mb-4px">No Manifest Results to Display</h2>
            <p class="mb-8px">Please select another occurrence</p>
        </div>

        <div class="occurrence-manifest-table"  *ngIf="occurrenceTickets && 
        (occurrenceTickets.occurrenceTickets.length || 
        passCardCheckIns.length)">
            <table>
                <tr>
                    <th></th>
                    <th>Order Number</th>
                    <th>Status</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Tier Head Count</th>
                    <th>Ticket Status Date/Time</th>
                    <th>Ticket Status</th>
                </tr>

                <tr *ngFor="let reservedTicket of reservedTickets">
                    <td>
                        <span (click)="onShowOrderSummaryClick(reservedTicket.orderId)">
                            <i class="fal fa-file-alt"></i>
                        </span>
                    </td>
                    <td>{{reservedTicket.orderExternalId}}</td>
                    <td>{{reservedTicket.orderDetailStatusFriendly}}</td>
                    <td>{{reservedTicket.lastName}}</td>
                    <td>{{reservedTicket.email}}</td>
                    <td>{{reservedTicket.phone}}</td>
                    <td>{{reservedTicket.tierHeadCountDescription}}</td>
                    <td>{{reservedTicket.lastTicketStatusDateTimeFriendly}}</td>
                    <td>
                        <app-dropdown
                            class="main--element"
                            [options]="reservedTicketStatusOptions"
                            [preSelectedValue]="RESERVED"
                            (changed)="onSelectedTicketStatusChanged($event, reservedTicket)">
                        </app-dropdown>
                    </td>
                </tr>
                <ng-container *ngIf="reservedTickets.length > 0 && checkedInTickets.length > 0">
                    <tr class="separator">
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                    </tr>
                </ng-container>
                <tr *ngFor="let checkedInTicket of checkedInTickets">
                    <td>
                        <span (click)="onShowOrderSummaryClick(checkedInTicket.orderId)">
                            <i class="fal fa-file-alt"></i>
                        </span>
                    </td>
                    <td>{{checkedInTicket.orderExternalId}}</td>
                    <td>{{checkedInTicket.orderDetailStatusFriendly}}</td>
                    <td>{{checkedInTicket.lastName}}</td>
                    <td>{{checkedInTicket.email}}</td>
                    <td>{{checkedInTicket.phone}}</td>
                    <td>{{checkedInTicket.tierHeadCountDescription}}</td>
                    <td>{{checkedInTicket.lastTicketStatusDateTimeFriendly}}</td>
                    <td>
                        <app-dropdown
                            class="main--element"
                            [options]="checkedInTicketStatusOptions"
                            [preSelectedValue]="CHECKED_IN"
                            [disabled]="checkedInTicket.orderExternalId?.includes('LA')"
                            (changed)="onSelectedTicketStatusChanged($event, checkedInTicket)">
                        </app-dropdown>

                    </td>
                </tr>
                <ng-container *ngIf="(reservedTickets.length > 0 || checkedInTickets.length > 0) && noShowTickets.length > 0">
                    <tr class="separator">
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                        <td><p></p></td>
                    </tr>
                </ng-container>
                <tr *ngFor="let noShowTicket of noShowTickets">
                    <td>
                        <span (click)="onShowOrderSummaryClick(noShowTicket.orderId)">
                            <i class="fal fa-file-alt"></i>
                        </span>
                    </td>
                    <td>{{noShowTicket.orderExternalId}}</td>
                    <td>{{noShowTicket.orderDetailStatusFriendly}}</td>
                    <td>{{noShowTicket.lastName}}</td>
                    <td>{{noShowTicket.email}}</td>
                    <td>{{noShowTicket.phone}}</td>
                    <td>{{noShowTicket.tierHeadCountDescription}}</td>
                    <td>{{noShowTicket.lastTicketStatusDateTimeFriendly}}</td>
                    <td>
                        <app-dropdown
                            class="main--element"
                            [options]="noShowTicketStatusOptions"
                            [preSelectedValue]="NO_SHOW"
                            (changed)="onSelectedTicketStatusChanged($event, noShowTicket)">
                        </app-dropdown>
                    </td>
                </tr>

                <tr *ngFor="let passCardCheckIn of passCardCheckIns">
                    <td>
                        <span (click)="onShowOrderSummaryClick(passCardCheckIn.orderId)">
                            <i class="fal fa-file-alt"></i>
                        </span>
                    </td>
                    <td>{{passCardCheckIn.orderExternalId}}</td>
                    <td>{{passCardCheckIn.orderDetailStatusFriendly}}</td>
                    <td>{{passCardCheckIn.lastName}}</td>
                    <td>{{passCardCheckIn.email}}</td>
                    <td>{{passCardCheckIn.phone}}</td>
                    <td>{{passCardCheckIn.tierHeadCountDescription}}</td>
                    <td>{{passCardCheckIn.lastTicketStatusDateTimeFriendly}}</td>
                    <td>
                        <app-dropdown
                            class="main--element"
                            [options]="reservedTicketStatusOptions"
                            [preSelectedValue]="CHECKED_IN"
                            [disabled]="true"
                            (changed)="onSelectedTicketStatusChanged($event, passCardCheckIn)">
                        </app-dropdown>
                    </td>
                </tr>

            </table>
        </div>
    </div>

    <div class="footer_wrapper">
        <div class="flex-container align-center-middle" [ngClass]="{'disabled': !occurrenceTickets || !occurrenceTickets.occurrenceTickets.length}">
            <button (click)="onPrintClick()" class="button ghost-button">
                <i class="far fa-print mr-8px"></i>
                <span>Print</span>
            </button>

            <button (click)="onEmailClick()" class="button ghost-button">
                <i class="far fa-envelope mr-8px"></i>
                <span>Email</span>
            </button>

            <button *ngIf="canDoBulkCheckIn()" (click)="onCheckInAllClick()" class="button ghost-button">
                <i class="far fa-check mr-8px"></i>
                <span>Check-in All</span>
            </button>
        </div>
    </div>
</form>
