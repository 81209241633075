
import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {Injectable} from "@angular/core";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {select, Store} from "@ngrx/store";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {KioskConfigurationDropoffLocationProductTierModel} from "../model/kiosk-configuration-dropoff-location-product-tier.model";
import {KioskConfigurationRestService} from "../../../../../../../../core/modules/rest/kiosk-configuration/kiosk-configuration-rest.service";
import {TierResponseModel} from "../../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {KioskConfigurationDropoffLocationProductTierResponseModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-dropoff-location-product-tier-response.model";
import _ from 'lodash';
import {CreateKioskConfigurationDropoffLocationProductTierRequestModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-dropoff-location-product-tier-request.model";
import {CreateKioskConfigurationDropoffLocationProductTiersRequestModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-dropoff-location-product-tiers-request.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";

@Injectable()
export class ProductTierSetupDialogSandbox extends  DialogSandbox {

    productTiers$: Observable<KioskConfigurationDropoffLocationProductTierModel[]> = this.store.pipe(select(reducer.productTiers_selector));

    constructor(appDialogsService: AppDialogsService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox,
                private kioskConfigurationRestService: KioskConfigurationRestService) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetStateAction());
    }

    loadDialogData(kioskConfigurationDropoffLocationId: number, roundTrip: boolean) {

        this.showLoader();

        const getAllTiers_request: Observable<TierResponseModel[]> = this.rootSandbox.getAllTiers();

        const getAllProductTiers_request: Observable<KioskConfigurationDropoffLocationProductTierResponseModel[]> = this.kioskConfigurationRestService.getKioskConfigurationDropoffLocationProductTiersByDropoffLocationIdAndTripType(kioskConfigurationDropoffLocationId, roundTrip);

        observableForkJoin(getAllTiers_request, getAllProductTiers_request)
            .subscribe(([allTiersResponse, allProductTiersResponse]: [TierResponseModel[], KioskConfigurationDropoffLocationProductTierResponseModel[]]) => {

                let allTiersResponseActive = allTiersResponse.filter((tiers: TierResponseModel) => tiers.active == true);

                const allTiersParameterized: TierResponseModel[] = allTiersResponseActive;
                const allProductTiersParameterized: KioskConfigurationDropoffLocationProductTierResponseModel[] = allProductTiersResponse;

                let allProductTiers: KioskConfigurationDropoffLocationProductTierModel[] = allTiersParameterized.map((tier: TierResponseModel) => {

                    const productTier: KioskConfigurationDropoffLocationProductTierResponseModel = _.find(allProductTiersParameterized, (productTier: KioskConfigurationDropoffLocationProductTierResponseModel) => productTier.tierId === tier.tierId);

                    if (productTier) {
                        return new KioskConfigurationDropoffLocationProductTierModel(productTier.tierId, productTier.tierDescription, productTier.defaultQuantity, productTier.minQuantity, productTier.maxQuantity);
                    } else {
                        return new KioskConfigurationDropoffLocationProductTierModel(tier.tierId, tier.description, null, null, null);
                    }
                });

                this.store.dispatch(new actions.SetProductTiers(allProductTiers));

                this.hideLoader();
            },
            () => this.hideLoader());
    }

    createKioskConfigurationDropoffLocationProductTier(kioskConfigurationDropoffLocationId: number, roundTrip: boolean, productTiers: KioskConfigurationDropoffLocationProductTierModel[]): Observable<any> {

        this.showLoader();

        const productTiersRequest: CreateKioskConfigurationDropoffLocationProductTierRequestModel[] = _.map(productTiers, (productTier: KioskConfigurationDropoffLocationProductTierModel) => new CreateKioskConfigurationDropoffLocationProductTierRequestModel(productTier.tierId, productTier.defaultQuantity, productTier.minQuantity, productTier.maxQuantity));

        const data: CreateKioskConfigurationDropoffLocationProductTiersRequestModel = new CreateKioskConfigurationDropoffLocationProductTiersRequestModel(
            kioskConfigurationDropoffLocationId,
            roundTrip,
            productTiersRequest
        );

        return this.kioskConfigurationRestService.createKioskConfigurationDropoffLocationProductTier(data).pipe(
            catchError((error: HttpErrorModel) => {
            	this.hideLoader();
                return this.rootSandbox.handleHttpError("Error while creating kiosk configuration dropoff location product tier", error);
            }));
    }
}
