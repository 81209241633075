<div class="search_wrapper">
    <div class="searchGroup_wrapper" [ngClass]="{'open-search': isSearchOpen}" (clickOutside)="closeSearchBar()">
        <div class="searchGroup_container is-inverted">
            
            <div class="searchGroup_iconWrapper">
                <a class="widgetItem_content" [ngClass]="{'widgetItem_active': isSearchOpen}" (click)="toggleSearch($event)">
                    <div class="iconImage_container">
                        <i class="fal fa-search"></i>
                    </div>
                </a>
            </div>

            <input #searchInput class="searchGroup_input" type="text" [placeholder]="placeholder"
            [(ngModel)]="searchTerm" (input)="onSearchChanged()" (keyup.enter)="onSearchChanged()">

            <ng-container *ngIf="multipleResults">
                <div class="searchGroupResultsDropdown_wrapper showSearchResults">
                    <ul class="searchResultsList_container">
                        <li class="searchResultLi" *ngFor="let result of []">
                            <a class="searchResultLabel searchResultLink">
                                {{ result }}
                            </a>
                        </li>
                    </ul>
                </div>
            </ng-container>

            <ng-container *ngIf="noResults">
                <div class="searchGroupResultsDropdown_wrapper showSearchResults">
                    <ul class="searchResultsList_container">
                        <li class="searchResultLi">
                            <span class="searchResultLabel">No customers found.</span>
                        </li>
                    </ul>
                </div>
            </ng-container>
        </div>
    </div>
</div>
