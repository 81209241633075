import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {AddAndroidDeviceInfoRemoteCommandDialogSandbox} from "../sandbox/add-android-device-info-remote-command-dialog.sandbox";
import {AddAndroidDeviceInfoRemoteCommandDialogFormSubmitDataModel} from "../model/add-android-device-info-remote-command-dialog-form-submit-data.model";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class AddAndroidDeviceInfoRemoteCommandDialogData extends DialogData {

    androidDeviceInfoId: number;
    androidDeviceNumber: string;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, androidDeviceInfoId: number, androidDeviceNumber: string) {
        super();
        this.androidDeviceInfoId = androidDeviceInfoId;
        this.androidDeviceNumber = androidDeviceNumber;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-add-android-device-info-remote-command-dialog',
    templateUrl: './add-android-device-info-remote-command-dialog.component.html'
})
export class AddAndroidDeviceInfoRemoteCommandDialogComponent implements OnInit, OnDestroy {

    androidDeviceInfoId: number;
    androidDeviceNumber: string;

    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: AddAndroidDeviceInfoRemoteCommandDialogData,
                private sandbox: AddAndroidDeviceInfoRemoteCommandDialogSandbox) {

        this.androidDeviceInfoId = dialogData.androidDeviceInfoId;
        this.androidDeviceNumber = dialogData.androidDeviceNumber;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: AddAndroidDeviceInfoRemoteCommandDialogFormSubmitDataModel) {

        this.sandbox.createAndroidDeviceInfoRemoteCommand(
            data.androidDeviceInfoId,
            data.remoteCommand,
            data.timeoutHours
        ).subscribe(() => {
            this.createCallbackFn();
            this.sandbox.destroyDialog();
        });
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
