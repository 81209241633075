import {CreateQuickSellingButtonProductTierRequestModel} from "./create-quick-selling-button-product-tier-request.model";

export class UpdateQuickSellingButtonProductRequestModel {
    quickSellingButtonProductId: number;
    description: string;
    categoryId: number;
    productId: number;
    pickupLocationId: number;
    dropoffLocationId: number;
    productAvailabilityId: number;
    productAvailabilityReturnTripId: number;
    useLastName: boolean;
    lastNameRequired: boolean;
    useEmail: boolean;
    emailRequired: boolean;
    usePhone: boolean;
    phoneRequired: boolean;
    useDiscountCode: boolean;
    useRoundTrip: boolean;
    color: string;
    quickSellingButtonProductTiers: CreateQuickSellingButtonProductTierRequestModel[];

    constructor(quickSellingButtonProductId: number, description: string, categoryId: number, productId: number, pickupLocationId: number, dropoffLocationId: number, productAvailabilityId: number, productAvailabilityReturnTripId: number, useLastName: boolean, lastNameRequired: boolean, useEmail: boolean, emailRequired: boolean, usePhone: boolean, phoneRequired: boolean, useDiscountCode: boolean, useRoundTrip: boolean, color: string, quickSellingButtonProductTiers: CreateQuickSellingButtonProductTierRequestModel[]) {
        this.quickSellingButtonProductId = quickSellingButtonProductId;
        this.description = description;
        this.categoryId = categoryId;
        this.productId = productId;
        this.pickupLocationId = pickupLocationId;
        this.dropoffLocationId = dropoffLocationId;
        this.productAvailabilityId = productAvailabilityId;
        this.productAvailabilityReturnTripId = productAvailabilityReturnTripId;
        this.useLastName = useLastName;
        this.lastNameRequired = lastNameRequired;
        this.useEmail = useEmail;
        this.emailRequired = emailRequired;
        this.usePhone = usePhone;
        this.phoneRequired = phoneRequired;
        this.useDiscountCode = useDiscountCode;
        this.useRoundTrip = useRoundTrip;
        this.color = color;
        this.quickSellingButtonProductTiers = quickSellingButtonProductTiers;
    }
}
