<!--
	ng-drag-drop library applied  => https://www.npmjs.com/package/ng-drag-drop
	[dragTransitClass] - class applied when element is being dragged (fallback for IE and Edge)
 -->
<li>
    <div class="dragNdropCard draggableItem"
         [ngClass]="{'dragItemPlaceholder': isDragged}"
         draggable="true"
         [dragEnabled]="hasRunOrderReportFullPermission"
         [dragData]="getDragData()"
         (onDragStart)="onDragStart()"
         (onDragEnd)="onDragEnd()"
         [dragImage]=" !isIE ? dragImage : null"
         [dragTransitClass]="isIE ? 'is-ie-drag' : null">

        <div class="dragNdropCardHeader">
            <div class="icon_container"
                 [ngClass]="{'disabled':!hasRunOrderReportFullPermission}">
                <img src="{{draggableIconDefault}}">
            </div>

            <h5 class="dragNdropCardLabel">
                {{ fieldItem.name }}
            </h5>

            <a class="closeButton_container"
               [ngClass]="{'disabled':!hasRunOrderReportFullPermission}"
               (click)="deleteFieldItemClick()">
		        <span class="closeButton">
		           <i class="fal fa-times"></i>
                </span>
            </a>
        </div>
    </div>
</li>
