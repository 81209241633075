import {Action} from "@ngrx/store";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export const RESET_STATE = '[Dialog - Create Product :: MAIN] Reset State';
export const SET_CATEGORIES = '[Dialog - Create Product :: MAIN] Set Categories';
export const SET_PICKUP_DROPOFF_LOCATION_LISTS = '[Dialog - Create Product :: MAIN] Set Pickup & Dropoff Location Lists';
export const SET_PICKUP_LOCATION_LISTS = '[Dialog - Create Product :: MAIN] Set Pickup Location Lists';
export const SET_DROPOFF_LOCATION_LISTS = '[Dialog - Create Product :: MAIN] Set Dropoff Location Lists';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetCategories implements Action {
    readonly type = SET_CATEGORIES;

    constructor(public categories: DropdownOptionModel[]) {
    }
}

export class SetPickupDropoffLocationLists implements Action {
    readonly type = SET_PICKUP_DROPOFF_LOCATION_LISTS;

    constructor(public pickupDropoffLocationLists: DropdownOptionModel[]) {
    }
}

export class SetPickupLocationLists implements Action {
    readonly type = SET_PICKUP_LOCATION_LISTS;

    constructor(public pickupLocationLists: DropdownOptionModel[]) {
    }
}

export class SetDropoffLocationLists implements Action {
    readonly type = SET_DROPOFF_LOCATION_LISTS;

    constructor(public dropoffLocationLists: DropdownOptionModel[]) {
    }
}

export type All = ResetState
    | SetCategories
    | SetPickupDropoffLocationLists
    | SetPickupLocationLists
    | SetDropoffLocationLists;
