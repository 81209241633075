import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditQuickSellingButtonProductDialogComponent} from "./container/edit-quick-selling-button-product-dialog.component";
import {EditQuickSellingButtonProductFormComponent } from './components/edit-quick-selling-button-product-form/edit-quick-selling-button-product-form.component';
import {EditQuickSellingButtonProductTierFormComponent } from './components/edit-quick-selling-button-product-form/edit-quick-selling-button-product-tier-form/edit-quick-selling-button-product-tier-form.component';
import {EditQuickSellingButtonProductDialogSandbox} from "./sandbox/edit-quick-selling-button-product-dialog.sandbox";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditQuickSellingButtonProductDialogSandbox
    ],
    declarations: [
        EditQuickSellingButtonProductDialogComponent,
        EditQuickSellingButtonProductFormComponent,
        EditQuickSellingButtonProductTierFormComponent
    ],
    exports: [
        EditQuickSellingButtonProductDialogComponent
    ],
    entryComponents: [
        EditQuickSellingButtonProductDialogComponent
    ]
})
export class EditQuickSellingButtonProductDialogModule {

}
