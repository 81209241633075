import {LocationListItemDescriptorModel} from "./location-list-item-descriptor.model";

export class KioskConfigurationDropoffLocationProductModel {
    productId: number;
    isRoundTripProduct: boolean;
    isReturnTrip: boolean;
    pickupLocationId: number;
    dropoffLocationId: number;

    pickupLocations: LocationListItemDescriptorModel[];
    dropoffLocations: LocationListItemDescriptorModel[];

    loadingPickupLocations: boolean;
    loadingDropoffLocations: boolean;

    constructor(productId: number, isRoundTripProduct: boolean, isReturnTrip: boolean, pickupLocationId: number, dropoffLocationId: number,
                pickupLocations: LocationListItemDescriptorModel[], dropoffLocations: LocationListItemDescriptorModel[]) {

        this.productId = productId;
        this.isRoundTripProduct = isRoundTripProduct;
        this.isReturnTrip = isReturnTrip;
        this.pickupLocationId = pickupLocationId;
        this.dropoffLocationId = dropoffLocationId;
        this.pickupLocations = pickupLocations;
        this.dropoffLocations = dropoffLocations;

        this.loadingPickupLocations = false;
        this.loadingDropoffLocations = false;
    }
}
