import { catchError } from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { DialogSandbox } from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import { SaleGroupResponseModel } from '../../../../../../../../core/modules/rest/sale-group/response/sale-group-response.model';
import { AppDialogsService } from "../../../../../../../../core/services/app-dialogs.service";
import { Store } from "@ngrx/store";
import { SaleGroupRestService } from '../../../../../../../../core/modules/rest/sale-group/sale-group-rest.service';
import { RootSandbox } from "../../../../../../../../core/store/root.sandbox";
import { CreateBaseResponseModel } from "../../../../../../../../core/modules/rest/create-base-response.model";
import { CreateSaleGroupRequestModel } from '../../../../../../../../core/modules/rest/sale-group/request/create-sale-group-request.model';
import { HttpErrorModel } from "../../../../../../../../core/modules/rest/http-error.model";
import { UpdateSaleGroupRequestModel } from '../../../../../../../../core/modules/rest/sale-group/request/update-sale-group-request.model';
import { SaleGroupFormSubmitDataModel } from '../model/sale-group-form-submit-data.model';

@Injectable()
export class EditSaleGroupDialogSandbox extends DialogSandbox {

    saleGroup$: Observable<SaleGroupResponseModel> = this.store.select(reducer.saleGroup_selector);

    constructor(appDialogsService: AppDialogsService,
                private saleGroupRestService: SaleGroupRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadSaleGroup( saleGroupId: number ) {

        this.showLoader();

        this.saleGroupRestService.getById( saleGroupId ).subscribe((saleGroup: SaleGroupResponseModel) => {
            this.store.dispatch(new actions.SetSaleGroup( saleGroup ));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    createSaleGroup( saleGroupName: string, useOwnerTypeGroup: boolean, useDomainTypeGroup: boolean ): Observable<CreateBaseResponseModel> {
        const request = new CreateSaleGroupRequestModel( saleGroupName, useOwnerTypeGroup, useDomainTypeGroup );

        return this.saleGroupRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while creating sale group", error);
            }));
    }

    updateSaleGroup( saleGroup: SaleGroupFormSubmitDataModel ): Observable<any> {
        const request = new UpdateSaleGroupRequestModel( saleGroup.saleGroupId, saleGroup.saleGroupName, saleGroup.useOwnerTypeGroup, saleGroup.useDomainTypeGroup );

        return this.saleGroupRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while updating sale group", error);
            }));
    }
}
