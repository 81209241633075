
import {of as observableOf, Observable} from 'rxjs';
import { Component, Inject, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {CashCollectedModel} from "../../../model/cash-collected.model";
import {AddNewEntryDialogSandbox} from "../sandbox/add-new-entry-dialog.sandbox";
import {NumberUtility} from "../../../../../../../../shared/utils/number-utility";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {AppPrintingService} from "../../../../../../../../core/services/app-printing-service";
import {flatMap, take} from "rxjs/operators";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class AddNewEntryDialogData extends DialogData {

    cashCollectedData: CashCollectedModel;
    cancelCallbackFn: () => void;
    submitCallbackFn: () => void;

    constructor(cancelCallbackFn: () => void, submitCallbackFn: () => void, cashCollectedData: CashCollectedModel) {
        super();
        this.cashCollectedData = cashCollectedData;
        this.cancelCallbackFn = cancelCallbackFn;
        this.submitCallbackFn = submitCallbackFn;
    }
}


@Component({
    selector: 'app-add-new-entry-dialog',
    templateUrl: './add-new-entry-dialog.component.html'
})
export class AddNewEntryDialogComponent implements OnInit {

    cashCollectedData: CashCollectedModel;
    cancelCallbackFn: () => void;
    submitCallbackFn: () => void;

    errorMessage: string = "";

    constructor(@Inject(DialogData) dialogData: AddNewEntryDialogData,
                private appPrintingService: AppPrintingService,
                private addNewEntryDialogSandbox: AddNewEntryDialogSandbox) {

        this.cashCollectedData = dialogData.cashCollectedData;
        this.cancelCallbackFn = dialogData.cancelCallbackFn;
        this.submitCallbackFn = dialogData.submitCallbackFn;
    }

    ngOnInit() {
    }

    onCancel() {
        if (this.cancelCallbackFn) {
            this.cancelCallbackFn();
        }
        this.addNewEntryDialogSandbox.destroyDialog();
    }

    onSubmit(cashCollectedData: CashCollectedModel) {

        // Calculate "reconciled"
        if (cashCollectedData.cashCollectedPaymentMethods) {
            for (let cashCollectedPaymentMethod of cashCollectedData.cashCollectedPaymentMethods) {
                if (Math.abs(cashCollectedPaymentMethod.usd - cashCollectedPaymentMethod.totalCash) > Math.abs(NumberUtility.EPSILON)) {
                    cashCollectedData.reconciled = false;
                    break;
                }
            }
        }

        // If reconciled, or if not reconciled and discrepancy reason provided, submit data
        if (cashCollectedData.reconciled || (cashCollectedData.reason && cashCollectedData.reason.trim().length > 0)) {

            this.addNewEntryDialogSandbox.showLoader();

            this.addNewEntryDialogSandbox.createCashCollected(cashCollectedData)
                .pipe(
                    take(1),
                    flatMap((response: CreateBaseResponseModel) => {

                        this.addNewEntryDialogSandbox.hideLoader();

                        if (response.id > 0) {

                            this.errorMessage = "";

                            if ( cashCollectedData.shouldPrintAfterSubmit ) {
                                return this.appPrintingService.downloadOrPrintCashoutEntry(response.id);
                            }

                            if (this.submitCallbackFn) {
                                this.submitCallbackFn();
                            }

                            return observableOf(true);

                        } else {

                            this.errorMessage = "Error while submitting cashout entry";
                            return observableOf(false);
                        }
                    })
                )
                .subscribe((result: boolean) => {
                    if (result) {
                        this.addNewEntryDialogSandbox.destroyDialog();
                    }
                });
        }
    }
}
