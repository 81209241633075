import {DropdownOptionModel} from './dropdown-option.model';
import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild  } from '@angular/core';
import {CloseDropdownOnScrollService} from '../../../directives/scroll/close-dropdown-on-scroll.service';
import {Subscription} from 'rxjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const KEY_ENTER = 27;
const KEY_ESC = 13;
const KEY_ARROW_UP = 38;
const KEY_ARROW_DOWN = 40;

const SORT_ASC = 'ASC';
const SORT_DESC = 'DESC';


@Component({
    selector   : 'app-dropdown',
    templateUrl: './dropdown.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true,
        }
    ]
})
export class DropdownComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor, AfterViewChecked {

    @Input() loading = false;
    @Input() autocomplete = true;
    @Input() preSelectedValue: string = null;
    @Input() usePlaceholder = true;
    @Input() placeholderText = '- Select option -';
    @Input() noOptionsText = 'No items to select';
    @Input() openedOnInit = false;
    @Input() options: DropdownOptionModel[] = [];
    @Input() customClasses: string;
    @Input() positionedOptionsList = false;
    @Input() disabled = false;
    @Input() sort = null;

    @Output() changed: EventEmitter<string> = new EventEmitter();

    @ViewChild('dropdownContainer') dropdownContainer: ElementRef;
    @ViewChild('dropdownOptionsList') optionsListContainer: ElementRef;

    opened: boolean = false;
    selectedOptionValue: string;
    selectedOptionLabel: string;
    hoverOptionIndex = 0;

    private _parentContainerScrollOccurredSubscription: Subscription;
    private _options: DropdownOptionModel[] = [];
    private _filteredOptions: DropdownOptionModel[] = [];
    private _arrowNavigationEvent;
    private _onChange: (value: string) => void;
    private _onTouch: (val: string) => void;

    constructor(private renderer: Renderer2, private componentHTML: ElementRef, private cdr: ChangeDetectorRef, private closeDropdownService: CloseDropdownOnScrollService) {
    }

    ngOnInit() {
        this.addKeypressListener();
    }

    ngAfterViewInit() {
        this.scrollDropdown();
    }

    ngAfterViewChecked() {
        let out = this.isOutOfRightViewportEdge(this.optionsListContainer.nativeElement);

        if (out.right) {
            this.optionsListContainer.nativeElement.setAttribute("class", "dropdownList_container alignToRightEdge");
        }
    }

    @HostListener('click', ['$event'])
    onClick() {

        let out = this.isOutOfRightViewportEdge(this.optionsListContainer.nativeElement);

        if (out.right) {
            this.optionsListContainer.nativeElement.setAttribute("class", "dropdownList_container alignToRightEdge");
        }

        if (out.bottom) {
            this.optionsListContainer.nativeElement.setAttribute("class", "dropdownList_container alignBeforeInput");
        }
    }

    @HostListener('keydown', ['$event'])
    onKeydown(event) {

        if (event.code === "ArrowDown" && !this.opened) {
            this.opened = true;
            this.openDropdown();
        }

        let out = this.isOutOfRightViewportEdge(this.optionsListContainer.nativeElement);

        if (out.right) {
            this.optionsListContainer.nativeElement.setAttribute("class", "dropdownList_container alignToRightEdge");
        }

        if (out.bottom) {
            this.optionsListContainer.nativeElement.setAttribute("class", "dropdownList_container alignBeforeInput");
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.openedOnInit) {
            this.opened = this.openedOnInit;
        }

        if (changes.preSelectedValue) {
            this.evaluateSelectedValueAndLabel(this.preSelectedValue);
        }

        if (changes.options || changes.usePlaceholder) {

            // Check if options are null
            this._options = this.options ? [...this.options] : [];

            // Sort options, before adding placeholder
            if (this.sort) {
                if (this.sort === SORT_ASC) {
                    this._options.sort((o1, o2) => (o1.label.toLowerCase() > o2.label.toLowerCase()) ? 1 : -1);
                } else if (this.sort === SORT_DESC) {
                    this._options.sort((o1, o2) => (o1.label.toLowerCase() > o2.label.toLowerCase()) ? -1 : 1);
                }
            }

            if (this.usePlaceholder) {
                this._options = [
                    new DropdownOptionModel(this.placeholderText, null),
                    ...this._options
                ];
            }

            this._filteredOptions = this._options;

            this.evaluateSelectedValueAndLabel(this.preSelectedValue);
        }

        if (changes.positionedOptionsList) {
            if (this.positionedOptionsList) {
                if (this._parentContainerScrollOccurredSubscription) {
                    this._parentContainerScrollOccurredSubscription.unsubscribe();
                }

                this._parentContainerScrollOccurredSubscription = this.closeDropdownService.parentContainerScrollOccurred()
                    .subscribe(() => {
                        if (this.opened && this.positionedOptionsList) {
                            this.closeDropdown();
                            this.cdr.detectChanges();
                        }
                    });
            } else {
                if (this._parentContainerScrollOccurredSubscription) {
                    this._parentContainerScrollOccurredSubscription.unsubscribe();
                }
            }
        }
    }

    ngOnDestroy() {

        if (this._arrowNavigationEvent) {
            this._arrowNavigationEvent();
        }

        if (this._parentContainerScrollOccurredSubscription) {
            this._parentContainerScrollOccurredSubscription.unsubscribe();
        }
    }

    hasToBeDisabled(): boolean {
        if (this.loading) {
            return true;
        } else {
            if (this._options) {
                if (this.usePlaceholder && this._options.length === 1) {
                    return true;
                } else if (!this.usePlaceholder && this._options.length === 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }
    }

    getOptions() {
        if (this.autocomplete) {
            return this._filteredOptions;
        } else  {
            return this._options;
        }
    }

    toggleDropdown() {

        this.opened = !this.opened;

        if (this.opened) {
            this.openDropdown();
        } else {
            this.closeDropdown();
        }
    }

    openDropdown() {
        this.resetAutocompleteInput();
        this.scrollToActiveOption();
        this.calculateOptionsListPosition();

        const customFocus = document.createEvent('HTMLEvents');
        customFocus.initEvent('focus', true, true);
        this.dropdownContainer.nativeElement.dispatchEvent(customFocus);
    }

    closeDropdown() {
        this.opened = false;

        const customBlur = document.createEvent('HTMLEvents');
        customBlur.initEvent('blur', true, true);
        this.dropdownContainer.nativeElement.dispatchEvent(customBlur);
    }

    dropdownOptionClick(selectedDropdownOption: DropdownOptionModel, event?) {

        event.stopPropagation();

        let value = selectedDropdownOption.value;

        if (value) {
            value = String(value);
        } else {
            value = null;
        }

        this.changed.emit(value);

        this.evaluateSelectedValueAndLabel(value);

        if (this._onChange) {
            this._onChange(value);
        }

        this.closeDropdown();
    }

    getPrintValue() {

        if (this.hasToBeDisabled()) {
            return "Select option";
        }

        if (this.loading) {
            return 'Loading...';
        }

        if (this.opened) {
            return '';
        } else {
            if (this.selectedOptionValue) {
                return this.selectedOptionLabel;
            } else {
                return this.placeholderText;
            }
        }
    }

    onAutocompleteInput(e) {
        // ignore arrow navigation
        if (e.keyCode !== KEY_ARROW_DOWN && e.keyCode !== KEY_ARROW_UP) {
            const searchParam = e.target.value;
            this.filterDropdownOptionsValue(searchParam);
        }
    }

    isOutOfRightViewportEdge(elem) {
        const out = {
            right: false,
            bottom: false
        };

        out.right =  elem.getBoundingClientRect().right > (window.innerWidth || document.documentElement.clientWidth  + window.scrollX);
        out.bottom =  elem.getBoundingClientRect().bottom > ((window.innerHeight || document.documentElement.clientHeight) + window.scrollY);

        return out;
    }

    private resetAutocompleteInput() {
        this._filteredOptions = this._options;
    }

    private addKeypressListener() {
        this._arrowNavigationEvent = this.renderer.listen(this.componentHTML.nativeElement, 'keydown' , (e) => {
            // 38 up, 40 down
            if (this.opened) {
                if (e.keyCode === KEY_ARROW_UP && this.hoverOptionIndex > 0) {
                    this.hoverOptionIndex--;
                    this.scrollDropdown();
                    return;
                } else if (e.keyCode === KEY_ARROW_DOWN && this.hoverOptionIndex < this.getOptions().length - 1) {
                    this.hoverOptionIndex++;
                    this.scrollDropdown();
                    return;
                } else if (e.keyCode === KEY_ESC) { // enter
                    const activeOption = this.getOptions()[this.hoverOptionIndex];
                    this.dropdownOptionClick(activeOption, e);
                    return;
                } else if (e.keyCode === KEY_ENTER) { // escape
                    this.closeDropdown();
                }
            }
        }) ;
    }

    private filterDropdownOptionsValue(param: string) {
        const searchParam = param.trim().toLowerCase();

        this._filteredOptions = this._options.filter( (option: DropdownOptionModel) => {
            // check if existing option matches search param
            if (option.label.toLowerCase().indexOf(searchParam) > -1)  {
                return true;
            } else if (option.openDropdownLabel && option.openDropdownLabel.toLowerCase().indexOf(searchParam) > -1) {
                return true;
            } else {
                this.hoverOptionIndex = 0;
                return false;
            }
        }).map( (option: DropdownOptionModel ) => {
            return this.highlightMatchingString(option, searchParam);
        });
    }

    private highlightMatchingString(dropdownOption: DropdownOptionModel, searchValue: string) {
        let newOpenDropdownLabel;
        const value = dropdownOption.value;

        const searchValueLength: number = searchValue.length;
        const indexOfSearchValue: number = dropdownOption.label.toLowerCase().indexOf(searchValue);
        const foundSearchValue = dropdownOption.label.substr(indexOfSearchValue, searchValueLength);

        const newLabel = dropdownOption.label.replace(foundSearchValue, `<b>${foundSearchValue}</b>`) ;

        if (dropdownOption.openDropdownLabel) {
            const indexOfDropdownLabel = dropdownOption.openDropdownLabel.toLowerCase().indexOf(searchValue);
            const foundDropdownLabel = dropdownOption.openDropdownLabel.substr(indexOfDropdownLabel, searchValueLength);
            newOpenDropdownLabel = dropdownOption.openDropdownLabel.replace(foundDropdownLabel, `<b>${foundDropdownLabel}</b>`) ;
        }
        return new DropdownOptionModel(newLabel, value, newOpenDropdownLabel);
    }

    private evaluateSelectedValueAndLabel(value: string) {
        if (value) {
            value = String(value);
        }

        this.selectedOptionValue = value;

        const selectedOption: DropdownOptionModel = this._options.find((option: DropdownOptionModel) => {
            return option.value === value;
        });

        // If selected option doesn't exist in options list
        if (selectedOption) {
            this.selectedOptionLabel = selectedOption.label;
        }
    }

    private scrollDropdown() {
        // elements
        const container = this.optionsListContainer.nativeElement;
        const item = container.getElementsByClassName('dropdown--list--element')[this.hoverOptionIndex];

        // height
        const containerHeight =  container.clientHeight;
        const itemHeight = item ? item.clientHeight : 0;
        // top position
        const itemPositionTop = item ? item.offsetTop : 0;

        this.cdr.detectChanges();

        // scroll to top
        if (containerHeight <= itemPositionTop + itemHeight) {
            container.scrollTop += itemHeight;
        } else if (container.scrollTop <= itemPositionTop) { // scroll to top
            container.scrollTop -= itemHeight;
        }
    }

    private scrollToActiveOption() {
        const container = this.optionsListContainer.nativeElement;
        for (let i = 0; i < this._options.length; i++) {
            if (this._options[i].value === this.selectedOptionValue) {
                this.hoverOptionIndex = i;
            }
        }

        this.cdr.detectChanges();

        const activeItem: HTMLElement = container.getElementsByClassName('dropdown--list--element')[this.hoverOptionIndex];
        container.scrollTop = activeItem.offsetTop - activeItem.clientHeight;
    }

    private calculateOptionsListPosition() {

        // Only for dNd filters
        if (this.positionedOptionsList) {
            const optionListContainerNativeElement = this.optionsListContainer.nativeElement;
            const dropdownContainerNativeElement   = this.dropdownContainer.nativeElement;

            optionListContainerNativeElement.style.minWidth = dropdownContainerNativeElement.offsetWidth + "px";
            optionListContainerNativeElement.style.top = dropdownContainerNativeElement.getBoundingClientRect().top + dropdownContainerNativeElement.offsetHeight + "px";
            optionListContainerNativeElement.style.left = dropdownContainerNativeElement.getBoundingClientRect().left + "px";
        }
    }

    public writeValue(value: string) {

        this.preSelectedValue = value;

        this.evaluateSelectedValueAndLabel(value);

        if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public registerOnChange(fn: (value: string) => void) {
        this._onChange = fn;
    }

    public registerOnTouched(fn: (value: string) => void) {
        this._onTouch = fn;
    }
}
