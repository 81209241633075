
import {forkJoin as observableForkJoin, of as observableOf, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {Injectable} from "@angular/core";
import {KioskConfigurationResponseModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-response.model";
import {LocationResponseModel} from "../../../../../../../../core/modules/rest/location/response/location-response.model";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {KioskConfigurationRestService} from "../../../../../../../../core/modules/rest/kiosk-configuration/kiosk-configuration-rest.service";
import {LocationRestService} from "../../../../../../../../core/modules/rest/location/location-rest.service";
import {Store} from "@ngrx/store";
import {LocationsResponseModel} from "../../../../../../../../core/modules/rest/location/response/locations-response.model";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {CreateKioskConfigurationRequestModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-request.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {UpdateKioskConfigurationRequestModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/request/update-kiosk-configuration-request.model";

@Injectable()
export class EditKioskConfigurationDialogSandbox extends DialogSandbox {

    kioskConfiguration$: Observable<KioskConfigurationResponseModel> = this.store.select(reducer.kioskConfiguration_selector);

    pickupLocations$: Observable<LocationResponseModel[]> = this.store.select(reducer.locations_selector);

    constructor(appDialogsService: AppDialogsService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox,
                private kioskConfigurationRestService: KioskConfigurationRestService,
                private locationRestService: LocationRestService) {

        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadDialogData(kioskConfigurationId: number) {
        this.showLoader();

        let getKioskConfiguration_request = this.kioskConfigurationRestService.getById(kioskConfigurationId);
        let getAllLocations_request = this.locationRestService.getAllActive();

        if (kioskConfigurationId === undefined) {
            getKioskConfiguration_request = observableOf(null);
        }

        observableForkJoin(getKioskConfiguration_request, getAllLocations_request)
            .subscribe(([kiosk, locationsResponse]: [KioskConfigurationResponseModel, LocationsResponseModel]) => {

                const kioskConfiguration: KioskConfigurationResponseModel = kiosk;
                const locations: LocationResponseModel[] = locationsResponse.locations;

                this.store.dispatch(new actions.SetKioskConfiguration(kioskConfiguration));
                this.store.dispatch(new actions.SetLocations(locations));

                this.hideLoader();
            },
            () => this.hideLoader());

    }

    addNewKioskConfiguration(description: string, pickupLocationId: number): Observable<CreateBaseResponseModel> {
        const data: CreateKioskConfigurationRequestModel = new CreateKioskConfigurationRequestModel(description, pickupLocationId);
        return this.kioskConfigurationRestService.create(data).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating kiosk configuration', error);
            }));
    }

    updateKioskConfiguration(kioskConfigurationId: number, description: string, pickupLocationId: number): Observable<any> {
        const data: UpdateKioskConfigurationRequestModel = new UpdateKioskConfigurationRequestModel(kioskConfigurationId, description, pickupLocationId);
        return this.kioskConfigurationRestService.update(data).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating kiosk configuration', error);
            }));
    }
}
