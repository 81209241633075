import * as actions from './actions';
import {AdditionalCostResponseModel} from "../../../../../../../core/modules/rest/cost/response/additional-cost-response.model";
import {DiscountResponseModel} from "../../../../../../../core/modules/rest/cost/response/discount-response.model";
import {AutoAppliedDiscountResponseModel} from "../../../../../../../core/modules/rest/cost/response/auto-applied-discount-response.model";
import {IncludedCostResponseModel} from "../../../../../../../core/modules/rest/cost/response/included-cost-response.model";

import _ from 'lodash';


export interface State {
    allAdditionalCosts: AdditionalCostResponseModel[];
    allIncludedCosts: IncludedCostResponseModel[];
    allDiscounts: DiscountResponseModel[];
    allAutoAppliedDiscounts: AutoAppliedDiscountResponseModel[];
}

const initialState: State = {
    allAdditionalCosts: [],
    allIncludedCosts: [],
    allDiscounts: [],
    allAutoAppliedDiscounts: [],
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.UPDATE_ADDITIONAL_COSTS_ACTION:
            return handleUpdateAdditionalCostsAction(state, action);
        case actions.UPDATE_ADDITIONAL_COST_ACTIVITY_ACTION:
            return handleUpdateAdditionalCostActivityAction(state, action);
        case actions.UPDATE_INCLUDED_COSTS_ACTION:
            return handleUpdateIncludedCostsAction(state, action);
        case actions.UPDATE_INCLUDED_COST_ACTIVITY_ACTION:
            return handleUpdateIncludedCostActivityAction(state, action);
        case actions.UPDATE_DISCOUNTS_ACTION:
            return handleUpdateDiscountsAction(state, action);
        case actions.UPDATE_DISCOUNT_ACTIVITY_ACTION:
            return handleUpdateDiscountActivityAction(state, action);
        case actions.UPDATE_AUTO_APPLIED_DISCOUNTS_ACTION:
            return handleUpdateAutoAppliedDiscountsAction(state, action);
        case actions.UPDATE_AUTO_APPLIED_DISCOUNT_ACTIVITY_ACTION:
            return handleUpdateAutoAppliedDiscountActivityAction(state, action);
        default:
            return state;
    }
}


// #################################################### SELECTORS ####################################################
export const allAdditionalCosts_selector = (state: State) => state.allAdditionalCosts;
export const allIncludedCosts_selector = (state: State) => state.allIncludedCosts;
export const allDiscounts_selector = (state: State) => state.allDiscounts;
export const allAutoAppliedDiscounts_selector = (state: State) => state.allAutoAppliedDiscounts;


// #################################################### HANDLERS ####################################################
function handleUpdateAdditionalCostsAction(state: State, action: actions.UpdateAdditionalCosts) {
    return {
        ...state,
        allAdditionalCosts: action.data
    };
}

function handleUpdateAdditionalCostActivityAction(state: State, action: actions.UpdateAdditionalCostActivity) {

    const index = state.allAdditionalCosts.findIndex((costObj: AdditionalCostResponseModel) => action.costId === costObj.costId);
    const cost: AdditionalCostResponseModel = _.cloneDeep(state.allAdditionalCosts[index]);
    cost.active = action.active;

    return {
        ...state,
        allAdditionalCosts: [
            ...state.allAdditionalCosts.slice(0, index),
            cost,
            ...state.allAdditionalCosts.slice(index + 1)
        ]
    };
}

function handleUpdateIncludedCostsAction(state: State, action: actions.UpdateIncludedCosts) {
    return {
        ...state,
        allIncludedCosts: action.data
    };
}

function handleUpdateIncludedCostActivityAction(state: State, action: actions.UpdateIncludedCostActivity) {

    const index = state.allIncludedCosts.findIndex((cost: IncludedCostResponseModel) => action.costId === cost.costId);
    const cost: IncludedCostResponseModel = _.cloneDeep(state.allIncludedCosts[index]);
    cost.active = action.active;

    return {
        ...state,
        allIncludedCosts: [
            ...state.allIncludedCosts.slice(0, index),
            cost,
            ...state.allIncludedCosts.slice(index + 1)
        ]
    };
}

function handleUpdateDiscountsAction(state: State, action: actions.UpdateDiscounts) {
    return {
        ...state,
        allDiscounts: action.data
    };
}

function handleUpdateDiscountActivityAction(state: State, action: actions.UpdateDiscountActivity) {

    const index = state.allDiscounts.findIndex((cost: DiscountResponseModel) => action.costId === cost.costId);
    const cost: DiscountResponseModel = _.cloneDeep(state.allDiscounts[index]);
    cost.active = action.active;

    return {
        ...state,
        allDiscounts: [
            ...state.allDiscounts.slice(0, index),
            cost,
            ...state.allDiscounts.slice(index + 1)
        ]
    };
}

function handleUpdateAutoAppliedDiscountsAction(state: State, action: actions.UpdateAutoAppliedDiscounts) {
    return {
        ...state,
        allAutoAppliedDiscounts: action.data
    };
}

function handleUpdateAutoAppliedDiscountActivityAction(state: State, action: actions.UpdateAutoAppliedDiscountActivity) {

    const index = state.allAutoAppliedDiscounts.findIndex((cost: AutoAppliedDiscountResponseModel) => action.costId === cost.costId);
    const cost: AutoAppliedDiscountResponseModel = _.cloneDeep(state.allAutoAppliedDiscounts[index]);
    cost.active = action.active;

    return {
        ...state,
        allAutoAppliedDiscounts: [
            ...state.allAutoAppliedDiscounts.slice(0, index),
            cost,
            ...state.allAutoAppliedDiscounts.slice(index + 1)
        ]
    };
}

