import * as actions from "./actions";
import {TreeNode} from "../../../../../../../shared/components/tree/tree.component";
import {TreeItemTypeEnum} from "../../model/tree-item-type.enum";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {PassBasicInfoDataModel} from "../../model/pass-basic-info-data.model";
import {PassProductsDataModel} from "../../model/pass-products-data.model";
import {PassPricingDateRangesDataModel} from "../../model/pass-pricing-date-ranges-data.model";
import {PassFlagsDataModel} from "../../model/pass-flags-data.model";
import {PassValidityDataModel} from "../../model/pass-validity-data.model";
import {PassEmailHtmlTemplatesDataModel} from "../../model/pass-email-html-templates-data.model";
import {PassTemplateItemsDataModel} from "../../model/pass-template-items-data.model";
import {PassTabItemEnum} from "../../model/pass-tab-item.enum";
import {PassImagesDataModel} from "../../model/pass-images-data.model";
import {PassPricingDateRangesSidebarFilterDataModel} from "../../model/pass-pricing-date-ranges-sidebar-filter-data.model";
import {PassPricingDateRangesSidebarFilterTypeEnum} from "../../model/pass-pricing-date-ranges-sidebar-filter-type.enum";
import {TierResponseModel} from "../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {LocationResponseModel} from "../../../../../../../core/modules/rest/location/response/location-response.model";
import {PurchaseTypeEnum} from "../../../../../../../shared/enums/purchase-type.enum";
import {PassCostsDataModel} from "../../model/pass-costs-data.model";
import {SortByModel} from "../../../../../../../shared/model/sort-by.model";

export const REDUCER_NAME: string = "feature_manage_passes";

export interface State {

    loadingPasses: boolean;
    passesTree: TreeNode[];
    showInactiveItems: boolean;

    item: TreeNode;
    node: TreeNodeMaterial;
    itemType: TreeItemTypeEnum;
    itemId: number;
    itemActive: boolean;

    selectedPassTabItem: PassTabItemEnum;
    loadingPassFull: boolean;
    loadingPassImages: boolean;
    loadingPassTemplateItems: boolean;
    loadingPassPricingDateRanges: boolean;
    itemPassBasicInfo: PassBasicInfoDataModel;
    itemPassProducts: PassProductsDataModel;
    itemPassImages: PassImagesDataModel;
    itemPassFlags: PassFlagsDataModel;
    itemPassValidity: PassValidityDataModel;
    itemPassEmailHtmlTemplates: PassEmailHtmlTemplatesDataModel;
    itemPassCosts: PassCostsDataModel;
    itemPassTemplateItems: PassTemplateItemsDataModel;
    itemPassPricingDateRanges: PassPricingDateRangesDataModel;
    passPricingDateRangesFrom: number;
    passPricingDateRangesSidebarFilterOpen: boolean;
    passPricingDateRangesSidebarFilterPickupLocations: LocationResponseModel[];
    passPricingDateRangesSidebarFilterDropoffLocations: LocationResponseModel[];
    passPricingDateRangesSidebarFilterTiers: TierResponseModel[];
    passPricingDateRangesSidebarFilterData: PassPricingDateRangesSidebarFilterDataModel;
    passPricingDateRangesSidebarFilterCurrentFocus: PassPricingDateRangesSidebarFilterTypeEnum;
    passPricingDateRangesCurrentSearchDataSortBy: SortByModel;
}

export const INITIAL_STATE: State = {

    loadingPasses: false,
    passesTree: [],
    showInactiveItems: false,

    item: {} as TreeNode,
    node: {} as TreeNodeMaterial,
    itemType: null,
    itemId: null,
    itemActive: false,

    selectedPassTabItem: PassTabItemEnum.PROPERTIES,
    loadingPassFull: false,
    loadingPassImages: false,
    loadingPassTemplateItems: false,
    loadingPassPricingDateRanges: false,
    itemPassBasicInfo: {
        passId: null,
        active: true,
        description: null,
        startDateInt: null,
        startDateMS: null,
        startDateFriendly: null,
        endDateInt: null,
        endDateMS: null,
        endDateFriendly: null,
        details: null,
        purchaseType: PurchaseTypeEnum.MULTIPASS
    },
    itemPassProducts: {
        passId: null,
        products: []
    },
    itemPassImages: {
        passId: null,
        passImage: {
            imageAmazonFilePath: null,
            imageBase64: null,
        },
        passCardBackgroundImage: {
            imageAmazonFilePath: null,
            imageBase64: null
        }
    },
    itemPassFlags: {
        passId: null,
        shouldSendBarcodeQRCodeImageAttachment: false,
        usesPregeneratedBarcodeOnAndroid: false
    },
    itemPassValidity: {
        passId: null,
        isValidForDateInterval: true,
        validForStartDate: null,
        validForStartDateMS: null,
        validForStartDateFriendly: null,
        validForEndDate: null,
        validForEndDateMS: null,
        validForEndDateFriendly: null,
        isValidSincePurchaseForHours: true,
        validForHours: null,
        isUsageLimiterPerOccurrence: true,
        usageLimiterForMinutes: null,
        isUnlimited: true,
        initialQuantity: null,
        predictedQuantity: null
    },
    itemPassEmailHtmlTemplates: {
        passId: null,
        createOrderEmailHtmlTemplateId: null,
        createOrderEmailHtmlTemplateDescription: null,
        voidOrderEmailHtmlTemplateId: null,
        voidOrderEmailHtmlTemplateDescription: null,
        termsAndConditionsEmailHtmlTemplateId: null,
        termsAndConditionsEmailHtmlTemplateDescription: null,
        createOrderEmailHtmlTemplateActive: null,
        voidOrderEmailHtmlTemplateActive: null,
        termsAndConditionsEmailHtmlTemplateActive: null
    },
    itemPassCosts: {
        passId: null,
        costs: []
    },
    itemPassTemplateItems: {
        passId: null,
        templateId: null,
        templateItems: []
    },
    itemPassPricingDateRanges: {
        passId: null,
        totalNumberOfResults: 0,
        pricingDateRanges: []
    },
    passPricingDateRangesFrom: 0,
    passPricingDateRangesSidebarFilterOpen: false,
    passPricingDateRangesSidebarFilterPickupLocations: [],
    passPricingDateRangesSidebarFilterDropoffLocations: [],
    passPricingDateRangesSidebarFilterTiers: [],
    passPricingDateRangesSidebarFilterData: null,
    passPricingDateRangesSidebarFilterCurrentFocus: null,
    passPricingDateRangesCurrentSearchDataSortBy: null
};

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const loading_passes_selector = createSelector(state_selector, (state: State) => state.loadingPasses);

export const passes_tree_selector = createSelector(state_selector, (state: State) => state.passesTree);

export const show_inactive_items_selector = createSelector(state_selector, (state: State) => state.showInactiveItems);

export const item_selector = createSelector(state_selector, (state: State) => state.item);

export const node_selector = createSelector(state_selector, (state: State) => state.node);

export const item_type_selector = createSelector(state_selector, (state: State) => state.itemType);

export const item_id_selector = createSelector(state_selector, (state: State) => state.itemId);

export const item_active_selector = createSelector(state_selector, (state: State) => state.itemActive);

export const loading_pass_full_selector = createSelector(state_selector, (state: State) => state.loadingPassFull);

export const loading_pass_images_selector = createSelector(state_selector, (state: State) => state.loadingPassImages);

export const loading_pass_template_items_selector = createSelector(state_selector, (state: State) => state.loadingPassTemplateItems);

export const loading_pass_pricing_date_ranges_selector = createSelector(state_selector, (state: State) => state.loadingPassPricingDateRanges);

export const selected_pass_tab_item_selector = createSelector(state_selector, (state: State) => state.selectedPassTabItem);

export const item_pass_basic_info_selector = createSelector(state_selector, (state: State) => state.itemPassBasicInfo);

export const item_pass_products_selector = createSelector(state_selector, (state: State) => state.itemPassProducts);

export const item_pass_images_selector = createSelector(state_selector, (state: State) => state.itemPassImages);

export const item_pass_flags_selector = createSelector(state_selector, (state: State) => state.itemPassFlags);

export const item_pass_validity_selector = createSelector(state_selector, (state: State) => state.itemPassValidity);

export const item_pass_email_html_templates_selector = createSelector(state_selector, (state: State) => state.itemPassEmailHtmlTemplates);

export const item_pass_costs_selector = createSelector(state_selector, (state: State) => state.itemPassCosts);

export const item_pass_template_items_selector = createSelector(state_selector, (state: State) => state.itemPassTemplateItems);

export const item_pass_pricing_date_ranges_selector = createSelector(state_selector, (state: State) => state.itemPassPricingDateRanges);

export const pass_pricing_date_ranges_from_selector = createSelector(state_selector, (state: State) => state.passPricingDateRangesFrom);

export const pass_pricing_date_ranges_sidebar_filter_open_selector = createSelector(state_selector, (state: State) => state.passPricingDateRangesSidebarFilterOpen);

export const pass_pricing_date_ranges_sidebar_filter_pickup_locations_selector = createSelector(state_selector, (state: State) => state.passPricingDateRangesSidebarFilterPickupLocations);

export const pass_pricing_date_ranges_sidebar_filter_dropoff_locations_selector = createSelector(state_selector, (state: State) => state.passPricingDateRangesSidebarFilterDropoffLocations);

export const pass_pricing_date_ranges_sidebar_filter_tiers_selector = createSelector(state_selector, (state: State) => state.passPricingDateRangesSidebarFilterTiers);

export const pass_pricing_date_ranges_sidebar_filter_data_selector = createSelector(state_selector, (state: State) => state.passPricingDateRangesSidebarFilterData);

export const pass_pricing_date_ranges_sidebar_filter_current_focus_selector = createSelector(state_selector, (state: State) => state.passPricingDateRangesSidebarFilterCurrentFocus);

export const pass_pricing_date_ranges_current_search_data_sort_by_selector = createSelector(state_selector, (state: State) => state.passPricingDateRangesCurrentSearchDataSortBy);

export function reducer(state: State = INITIAL_STATE, action: actions.All): State {

    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;
        case actions.SET_LOADING_PASSES:
            return {
                ...state,
                loadingPasses: action.loading
            };
        case actions.SET_PASSES_TREE:
            return {
                ...state,
                passesTree: action.tree
            };
        case actions.SET_SHOW_INACTIVE_ITEMS:
            return {
                ...state,
                showInactiveItems: action.showInactiveItems
            };
        case actions.SET_SELECTED_ITEM:
            return {
                ...state,
                item: action.item,
                itemType: action.itemType,
                itemId: action.itemId,
                itemActive: action.itemActive
            };
        case actions.SET_SELECTED_NODE:
            return {
                 ...state,
                node: action.node
            };
        case actions.SET_LOADING_PASS_FULL:
            return {
                ...state,
                loadingPassFull: action.loading
            };
        case actions.SET_LOADING_PASS_IMAGES:
            return {
                ...state,
                loadingPassImages: action.loading
            };
        case actions.SET_LOADING_PASS_TEMPLATE_ITEMS:
            return {
                ...state,
                loadingPassTemplateItems: action.loading
            };
        case actions.SET_LOADING_PASS_PRICING_DATE_RANGES:
            return {
                ...state,
                loadingPassPricingDateRanges: action.loading
            };
        case actions.SET_SELECTED_PASS_TAB_ITEM:
            return {
                ...state,
                selectedPassTabItem: action.passTabItem
            };
        case actions.SET_PASS_BASIC_INFO:
            return {
                ...state,
                itemPassBasicInfo: {
                    passId: action.passBasicInfo.passId,
                    active: action.passBasicInfo.active,
                    description: action.passBasicInfo.description,
                    startDateInt: action.passBasicInfo.startDateInt,
                    startDateMS: action.passBasicInfo.startDateMS,
                    startDateFriendly: action.passBasicInfo.startDateFriendly,
                    endDateInt: action.passBasicInfo.endDateInt,
                    endDateMS: action.passBasicInfo.endDateMS,
                    endDateFriendly: action.passBasicInfo.endDateFriendly,
                    details: action.passBasicInfo.details,
                    purchaseType: action.passBasicInfo.purchaseType
                }
            };
        case actions.SET_PASS_PRODUCTS:
            return {
                ...state,
                itemPassProducts: {
                    passId: action.passProducts.passId,
                    products: [...action.passProducts.products]
                }
            };
        case actions.SET_PASS_IMAGES:
            return {
                ...state,
                itemPassImages: {
                    passId: action.passImages.passId,
                    passImage: {
                        imageAmazonFilePath: action.passImages.passImage.imageAmazonFilePath,
                        imageBase64: action.passImages.passImage.imageBase64,
                    },
                    passCardBackgroundImage: {
                        imageAmazonFilePath: action.passImages.passCardBackgroundImage.imageAmazonFilePath,
                        imageBase64: action.passImages.passCardBackgroundImage.imageBase64
                    }
                }
            };
        case actions.SET_PASS_IMAGE:
            return {
                ...state,
                itemPassImages: {
                    ...state.itemPassImages,
                    passImage: {
                        imageAmazonFilePath: action.passImage.imageAmazonFilePath,
                        imageBase64: action.passImage.imageBase64,
                    }
                }
            };
        case actions.SET_PASS_CARD_BACKGROUND_IMAGE:
            return {
                ...state,
                itemPassImages: {
                    ...state.itemPassImages,
                    passCardBackgroundImage: {
                        imageAmazonFilePath: action.passCardBackgroundImage.imageAmazonFilePath,
                        imageBase64: action.passCardBackgroundImage.imageBase64,
                    }
                }
            };
        case actions.SET_PASS_FLAGS:
            return {
                ...state,
                itemPassFlags: {
                    passId: action.passFlags.passId,
                    shouldSendBarcodeQRCodeImageAttachment: action.passFlags.shouldSendBarcodeQRCodeImageAttachment,
                    usesPregeneratedBarcodeOnAndroid: action.passFlags.usesPregeneratedBarcodeOnAndroid
                }
            };
        case actions.SET_PASS_VALIDITY:
            return {
                ...state,
                itemPassValidity: {
                    passId: action.passValidity.passId,
                    isValidForDateInterval: action.passValidity.isValidForDateInterval,
                    validForStartDate: action.passValidity.validForStartDate,
                    validForStartDateMS: action.passValidity.validForStartDateMS,
                    validForStartDateFriendly: action.passValidity.validForStartDateFriendly,
                    validForEndDate: action.passValidity.validForEndDate,
                    validForEndDateMS: action.passValidity.validForEndDateMS,
                    validForEndDateFriendly: action.passValidity.validForEndDateFriendly,
                    isValidSincePurchaseForHours: action.passValidity.isValidSincePurchaseForHours,
                    validForHours: action.passValidity.validForHours,
                    isUsageLimiterPerOccurrence: action.passValidity.isUsageLimiterPerOccurrence,
                    usageLimiterForMinutes: action.passValidity.usageLimiterForMinutes,
                    isUnlimited: action.passValidity.isUnlimited,
                    initialQuantity: action.passValidity.initialQuantity,
                    predictedQuantity: action.passValidity.predictedQuantity
                }
            };
        case actions.SET_PASS_EMAIL_HTML_TEMPLATES:
            return {
                ...state,
                itemPassEmailHtmlTemplates: {
                    passId: action.passEmailHtmlTemplates.passId,
                    createOrderEmailHtmlTemplateId: action.passEmailHtmlTemplates.createOrderEmailHtmlTemplateId,
                    createOrderEmailHtmlTemplateDescription: action.passEmailHtmlTemplates.createOrderEmailHtmlTemplateDescription,
                    voidOrderEmailHtmlTemplateId: action.passEmailHtmlTemplates.voidOrderEmailHtmlTemplateId,
                    voidOrderEmailHtmlTemplateDescription: action.passEmailHtmlTemplates.voidOrderEmailHtmlTemplateDescription,
                    termsAndConditionsEmailHtmlTemplateId: action.passEmailHtmlTemplates.termsAndConditionsEmailHtmlTemplateId,
                    termsAndConditionsEmailHtmlTemplateDescription: action.passEmailHtmlTemplates.termsAndConditionsEmailHtmlTemplateDescription,
                    createOrderEmailHtmlTemplateActive: action.passEmailHtmlTemplates.createOrderEmailHtmlTemplateActive,
                    voidOrderEmailHtmlTemplateActive: action.passEmailHtmlTemplates.voidOrderEmailHtmlTemplateActive,
                    termsAndConditionsEmailHtmlTemplateActive: action.passEmailHtmlTemplates.termsAndConditionsEmailHtmlTemplateActive
                }
            };
        case actions.SET_PASS_COSTS:
            return {
                ...state,
                itemPassCosts: {
                    passId: action.passCosts.passId,
                    costs: [...action.passCosts.costs]
                }
            };
        case actions.SET_PASS_TEMPLATE_ITEMS:
            return {
                ...state,
                itemPassTemplateItems: {
                    passId: action.passTemplateItems.passId,
                    templateId: action.passTemplateItems.templateId,
                    templateItems: [...action.passTemplateItems.templateItems]
                }
            };
        case actions.RESET_PASS_PRICING_DATE_RANGES:
            return {
                ...state,
                itemPassPricingDateRanges: {
                    passId: null,
                    totalNumberOfResults: 0,
                    pricingDateRanges: []
                }
            };
        case actions.SET_PASS_PRICING_DATE_RANGES:
            return {
                ...state,
                itemPassPricingDateRanges: {
                    passId: action.passPricingDateRanges.passId,
                    totalNumberOfResults: action.passPricingDateRanges.totalNumberOfResults,
                    pricingDateRanges: action.append ? [...state.itemPassPricingDateRanges.pricingDateRanges, ...action.passPricingDateRanges.pricingDateRanges] : [...action.passPricingDateRanges.pricingDateRanges]
                }
            };
        case actions.SET_PASS_PRICING_DATE_RANGES_FROM:
            return {
                ...state,
                passPricingDateRangesFrom: action.passPricingDateRangesFrom
            };
        case actions.SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_OPEN:
            return {
                ...state,
                passPricingDateRangesSidebarFilterOpen: action.passPricingDateRangesSidebarFilterOpen,
                passPricingDateRangesSidebarFilterCurrentFocus: action.passPricingDateRangesSidebarFilterCurrentFocus
            };
        case actions.SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_PICKUP_LOCATIONS:
            return {
                ...state,
                passPricingDateRangesSidebarFilterPickupLocations: action.passPricingDateRangesSidebarFilterPickupLocations
            };
        case actions.SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_DROPOFF_LOCATIONS:
            return {
                ...state,
                passPricingDateRangesSidebarFilterDropoffLocations: action.passPricingDateRangesSidebarFilterDropoffLocations
            };
        case actions.SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_TIERS:
            return {
                ...state,
                passPricingDateRangesSidebarFilterTiers: action.passPricingDateRangesSidebarFilterTiers
            };
        case actions.SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_DATA:
            return {
                ...state,
                passPricingDateRangesSidebarFilterData: {
                    showInactive: action.passPricingDateRangesSidebarFilterData.showInactive,
                    showInactiveTiers: action.passPricingDateRangesSidebarFilterData.showInactiveTiers,
                    showInactivePickupLocations: action.passPricingDateRangesSidebarFilterData.showInactivePickupLocations,
                    showInactiveDropoffLocations: action.passPricingDateRangesSidebarFilterData.showInactiveDropoffLocations,
                    showInactiveDepartureGroups: action.passPricingDateRangesSidebarFilterData.showInactiveDepartureGroups,
                    allDates: action.passPricingDateRangesSidebarFilterData.allDates,
                    startDate: action.passPricingDateRangesSidebarFilterData.startDate,
                    startDateFriendly: action.passPricingDateRangesSidebarFilterData.startDateFriendly,
                    endDate: action.passPricingDateRangesSidebarFilterData.endDate,
                    endDateFriendly: action.passPricingDateRangesSidebarFilterData.endDateFriendly,
                    pickupLocationIds: action.passPricingDateRangesSidebarFilterData.pickupLocationIds,
                    pickupLocationDescriptions: action.passPricingDateRangesSidebarFilterData.pickupLocationDescriptions,
                    dropoffLocationIds: action.passPricingDateRangesSidebarFilterData.dropoffLocationIds,
                    dropoffLocationDescriptions: action.passPricingDateRangesSidebarFilterData.dropoffLocationDescriptions,
                    tierIds: action.passPricingDateRangesSidebarFilterData.tierIds,
                    tierDescriptions: action.passPricingDateRangesSidebarFilterData.tierDescriptions
                }
            };
        case actions.UPDATE_PASS_PRICING_DATE_RANGES_CURRENT_SEARCH_DATA_SORT_BY:
            return {
                ...state,
                passPricingDateRangesCurrentSearchDataSortBy: action.passPricingDateRangesSortBy
            };
        default:
            return state;
    }
}
