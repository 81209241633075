import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {LetterGroupResponseModel} from "./response/letter-group-response.model";
import {CreateLetterGroupRequestModel} from "./request/create-letter-group-request.model";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {UpdateLetterGroupRequestModel} from "./request/update-letter-group-request.model";

@Injectable()
export class LetterGroupRestService {

    constructor(private httpClient: HttpClient) {
    }

    getById(letterGroupId: number): Observable<LetterGroupResponseModel> {
        return this.httpClient.get<LetterGroupResponseModel>(environment.server_api_url + '/api/secured/letterGroup/getById/' + letterGroupId);
    }

    getAll(): Observable<LetterGroupResponseModel[]> {
        return this.httpClient.get<LetterGroupResponseModel[]>(environment.server_api_url + '/api/secured/letterGroup/getAll');
    }

    getAllActive(): Observable<LetterGroupResponseModel[]> {
        return this.httpClient.get<LetterGroupResponseModel[]>(environment.server_api_url + '/api/secured/letterGroup/getAllActive');
    }

    create(request: CreateLetterGroupRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/letterGroup/create', request);
    }

    update(request: UpdateLetterGroupRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/letterGroup/update', request, {responseType: 'text'});
    }

    activate(letterGroupId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/letterGroup/activate', letterGroupId, {responseType: 'text'});
    }

    deactivate(letterGroupId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/letterGroup/deactivate', letterGroupId, {responseType: 'text'});
    }
}
