
import {forkJoin as observableForkJoin, combineLatest as observableCombineLatest, of as observableOf, Observable} from 'rxjs';

import {catchError, flatMap, map, take, tap} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {QuickSellingButtonProductResponseModel} from "../../../../../../../../core/modules/rest/quick-selling-button-product/response/quick-selling-button-product-response.model";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {QuickSellingButtonProductRestService} from "../../../../../../../../core/modules/rest/quick-selling-button-product/quick-selling-button-product-rest.service";
import {select, Store} from "@ngrx/store";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {CategoryResponseModel} from "../../../../../../../../core/modules/rest/category/response/category-response.model";
import {CategoryRestService} from "../../../../../../../../core/modules/rest/category/category-rest.service";
import {ProductSimpleResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {ProductRestService} from "../../../../../../../../core/modules/rest/product/product-rest.service";
import {LocationListItemDescriptorResponseModel} from "../../../../../../../../core/modules/rest/location-list/response/location-list-item-descriptor-response.model";
import {LocationListRestService} from "../../../../../../../../core/modules/rest/location-list/location-list-rest.service";
import {StartTimeResponseModel} from '../../../../../../../../core/modules/rest/product-availability/response/start-time-response.model';
import {ProductAvailabilityRestService} from '../../../../../../../../core/modules/rest/product-availability/product-availability-rest.service';
import {TiersResponseModel} from "../../../../../../../../core/modules/rest/tier/response/tiers-response.model";
import {TierResponseModel} from "../../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {TierRestService} from "../../../../../../../../core/modules/rest/tier/tier-rest.service";
import {TierWithQuantityModel} from "../model/tier-with-quantity.model";
import {CreateQuickSellingButtonProductRequestModel} from "../../../../../../../../core/modules/rest/quick-selling-button-product/request/create-quick-selling-button-product-request.model";
import {CreateQuickSellingButtonProductTierRequestModel} from "../../../../../../../../core/modules/rest/quick-selling-button-product/request/create-quick-selling-button-product-tier-request.model";
import {UpdateQuickSellingButtonProductRequestModel} from "../../../../../../../../core/modules/rest/quick-selling-button-product/request/update-quick-selling-button-product-request.model";
import {SubmitFormDialogDataModel} from "../model/submit-form-dialog-data.model";
import { ProductForRouteSetupResponseModel } from '../../../../../../../../core/modules/rest/product/response/product-for-route-setup-response.model';

@Injectable()
export class EditQuickSellingButtonProductDialogSandbox extends DialogSandbox {

    quickSellingButtonProduct$: Observable<QuickSellingButtonProductResponseModel> = this.store.pipe(select(reducer.quick_selling_button_product_selector));

    categories$: Observable<CategoryResponseModel[]> = this.store.pipe(select(reducer.categories_selector));
    products$: Observable<ProductSimpleResponseModel[]> = this.store.pipe(select(reducer.products_selector));
    product$: Observable<ProductSimpleResponseModel> = this.store.pipe(select(reducer.product_selector));
    pickupLocations$: Observable<LocationListItemDescriptorResponseModel[]> = this.store.pipe(select(reducer.pickup_locations_selector));
    dropoffLocations$: Observable<LocationListItemDescriptorResponseModel[]> = this.store.pipe(select(reducer.dropoff_locations_selector));
    startTimes$: Observable<StartTimeResponseModel[]> = this.store.pipe(select(reducer.start_times_selector));
    startTimesReturnTrip$: Observable<StartTimeResponseModel[]> = this.store.pipe(select(reducer.start_times_return_trip_selector));
    tiers$: Observable<TierResponseModel[]> = this.store.pipe(select(reducer.tiers_selector));

    categoriesLoading$: Observable<boolean> = this.store.pipe(select(reducer.categories_loading_selector));
    productsLoading$: Observable<boolean> = this.store.pipe(select(reducer.products_loading_selector));
    pickupLocationsLoading$: Observable<boolean> = this.store.pipe(select(reducer.pickup_locations_loading_selector));
    dropoffLocationsLoading$: Observable<boolean> = this.store.pipe(select(reducer.dropoff_locations_loading_selector));
    startTimesLoading$: Observable<boolean> = this.store.pipe(select(reducer.start_times_loading_selector));
    startTimesReturnTripLoading$: Observable<boolean> = this.store.pipe(select(reducer.start_times_return_trip_loading_selector));
    tiersLoading$: Observable<boolean> = this.store.pipe(select(reducer.tiers_loading_selector));

    errorMessage$: Observable<string> = this.store.pipe(select(reducer.error_message_selector));

    constructor(appDialogsService: AppDialogsService,
                private quickSellingButtonProductRestService: QuickSellingButtonProductRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox,
                private categoryRestService: CategoryRestService,
                private productRestService: ProductRestService,
                private locationListRestService: LocationListRestService,
                private productAvailabilityRestService: ProductAvailabilityRestService,
                private tierRestService: TierRestService) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(editMode: boolean, quickSellingButtonProductId: number, categoryId: number, productId: number, pickupLocationId: number, dropoffLocationId: number) {

        this.showLoader();

        if (editMode) {

            this.productRestService.getProductsByCategoryId(categoryId).pipe(
                take(1),
                flatMap((products: ProductSimpleResponseModel[]) => {

                    let activeProducts = products.filter((product: ProductForRouteSetupResponseModel) => product.active == true);
                    let product: ProductSimpleResponseModel = activeProducts.find(p => p.productId === productId);

                    this.store.dispatch(new actions.SetProduct(product));

                    let getPickupLocations$: Observable<LocationListItemDescriptorResponseModel[]>;
                    let getDropoffLocations$: Observable<LocationListItemDescriptorResponseModel[]>;
                    let getStartTimesRegular$: Observable<StartTimeResponseModel[]>;
                    let getStartTimesReturn$: Observable<StartTimeResponseModel[]>;

                    if (product.usesPickupLocations && pickupLocationId) {
                        getPickupLocations$ = this.locationListRestService.getPickupLocationsByProductIdAndDirection(productId, false);
                        getDropoffLocations$ = this.locationListRestService.getDropoffLocationsByProductIdPickupLocationIdAndDirection(productId, pickupLocationId, false);
                        getStartTimesRegular$ = this.productAvailabilityRestService.getStartTimesByProductIdPickupLocationIdAndDirection(productId, pickupLocationId, false);
                    } else {
                        getPickupLocations$ = observableOf([]);
                        getDropoffLocations$ = this.locationListRestService.getDropoffLocationsByProductIdAndDirection(productId, false);
                        getStartTimesRegular$ = this.productAvailabilityRestService.getStartTimesByProductIdAndDirection(productId, false);
                    }

                    if (product.usesDropoffLocations && dropoffLocationId) {
                        getStartTimesReturn$ = this.productAvailabilityRestService.getStartTimesByProductIdPickupLocationIdAndDirection(productId, dropoffLocationId, true);
                    } else {
                        getStartTimesReturn$ = this.productAvailabilityRestService.getStartTimesByProductIdAndDirection(productId, true);
                    }

                    return observableCombineLatest([
                        this.quickSellingButtonProductRestService.getById(quickSellingButtonProductId),
                        this.tierRestService.getAllActive(),
                        this.categoryRestService.getAllActive(),
                        observableOf(activeProducts),
                        getPickupLocations$,
                        getDropoffLocations$,
                        getStartTimesRegular$,
                        getStartTimesReturn$
                    ]);
                }),
                take(1),
                flatMap(([qsbProduct, tiers, categories, products, pickupLocations, dropoffLocations, startTimesRegularTrip, startTimesReturnTrip]: [QuickSellingButtonProductResponseModel, TiersResponseModel, CategoryResponseModel[], ProductSimpleResponseModel[], LocationListItemDescriptorResponseModel[], LocationListItemDescriptorResponseModel[], StartTimeResponseModel[], StartTimeResponseModel[]]) => {

                    this.store.dispatch(new actions.SetData(qsbProduct, tiers.tiers, categories, products, pickupLocations, dropoffLocations, startTimesRegularTrip, startTimesReturnTrip));

                    return observableOf(true);
                })
            ).subscribe((success: true) => {
                if (success) {
                    this.hideLoader();
                }
            },
            () => this.hideLoader());
        } else {
            observableForkJoin(
                    this.tierRestService.getAllActive(),
                    this.categoryRestService.getAllActive())
                .subscribe(results => {
                    const quickSellingButtonProduct: QuickSellingButtonProductResponseModel = null;
                    const tiersResponse: TiersResponseModel = results[0];
                    const categories: CategoryResponseModel[] = results[1];
                    const products: ProductSimpleResponseModel[] = [];
                    const pickupLocations: LocationListItemDescriptorResponseModel[] = [];
                    const dropoffLocations: LocationListItemDescriptorResponseModel[] = [];
                    const startTimes: StartTimeResponseModel[] = [];
                    const startTimesReturnTrip: StartTimeResponseModel[] = [];

                    this.store.dispatch(new actions.SetData(quickSellingButtonProduct, tiersResponse.tiers, categories, products, pickupLocations, dropoffLocations, startTimes, startTimesReturnTrip));
                    this.hideLoader();
                },
                () => this.hideLoader());
        }
    }

    selectedCategoryChanged(categoryId: number) {

        if (!categoryId) {

            this.store.dispatch(new actions.SetPickupLocations([]));
            this.store.dispatch(new actions.SetDropoffLocations([]));
            this.store.dispatch(new actions.SetStartTimes([]));
            this.store.dispatch(new actions.SetStartTimesReturnTrip([]));

            return;
        }

        this.store.dispatch(new actions.SetProductsLoading(true));

        this.productRestService.getProductsByCategoryId(categoryId).subscribe((products: ProductSimpleResponseModel[]) => {
            let activeProducts = products.filter((product: ProductForRouteSetupResponseModel) => product.active == true);
            this.store.dispatch(new actions.SetProducts(activeProducts));
            this.store.dispatch(new actions.SetProductsLoading(false));
        },
        () => this.store.dispatch(new actions.SetProductsLoading(false)));
    }

    selectedProductChanged(productId: number) {
        this.products$.pipe(
            take(1),
            tap((products: ProductSimpleResponseModel[]) => {

                let product: ProductSimpleResponseModel = products.find(p => p.productId === productId);

                this.store.dispatch(new actions.SetProduct(product));

                this.productChanged(product);
            })
        ).subscribe();
    }

    selectedPickupLocationChanged(productId: number, pickupLocationId: number) {
        this.product$.pipe(
            take(1),
            tap((product: ProductSimpleResponseModel) => {
                this.pickupLocationChanged(product, pickupLocationId);
            })
        ).subscribe();
    }

    selectedDropoffLocationChanged(productId: number, dropoffLocationId: number) {

        this.store.dispatch(new actions.SetStartTimesReturnTripLoading(true));

        this.product$.pipe(
            take(1),
            tap((product: ProductSimpleResponseModel) => this.dropoffLocationChanged(product, dropoffLocationId))
        ).subscribe(
            () => this.store.dispatch(new actions.SetStartTimesReturnTripLoading(false)),
            () => this.store.dispatch(new actions.SetStartTimesReturnTripLoading(false)));
    }

    private productChanged(product: ProductSimpleResponseModel) {

        if (!product) {

            this.store.dispatch(new actions.SetPickupLocations([]));
            this.store.dispatch(new actions.SetDropoffLocations([]));
            this.store.dispatch(new actions.SetStartTimes([]));
            this.store.dispatch(new actions.SetStartTimesReturnTrip([]));

            return;
        }

        let productId: number = product.productId;
        let usesPl: boolean = product.usesPickupLocations;
        let usesDl: boolean = product.usesDropoffLocations;
        let roundTrip: boolean = product.isRoundTripProduct;

        let pickupLocations$: Observable<LocationListItemDescriptorResponseModel[]> = usesPl
            ? this.locationListRestService.getPickupLocationsByProductIdAndDirection(productId, false)
            : observableOf([]);

        let dropoffLocations$: Observable<LocationListItemDescriptorResponseModel[]> = !usesPl && usesDl
            ? this.locationListRestService.getDropoffLocationsByProductIdAndDirection(productId, false)
            : observableOf([]);

        let startTimesRegular$: Observable<StartTimeResponseModel[]> = !usesPl
            ? this.productAvailabilityRestService.getStartTimesByProductIdAndDirection(productId, false)
            : observableOf([]);

        let startTimesReturn$: Observable<StartTimeResponseModel[]> = ((usesPl && !usesDl) || (!usesPl && !usesDl)) && roundTrip
            ? this.productAvailabilityRestService.getStartTimesByProductIdAndDirection(productId, true)
            : observableOf([]);

        observableCombineLatest([
            pickupLocations$,
            dropoffLocations$,
            startTimesRegular$,
            startTimesReturn$
        ]).pipe(
            take(1),
            map(([pl, dl, st, str]: [LocationListItemDescriptorResponseModel[], LocationListItemDescriptorResponseModel[], StartTimeResponseModel[], StartTimeResponseModel[]]) => {
                this.store.dispatch(new actions.SetPickupLocations(pl));
                this.store.dispatch(new actions.SetDropoffLocations(dl));
                this.store.dispatch(new actions.SetStartTimes(st));
                this.store.dispatch(new actions.SetStartTimesReturnTrip(str));
            })
        ).subscribe();
    }

    private pickupLocationChanged(product: ProductSimpleResponseModel, pickupLocationId: number) {

        if (!pickupLocationId) {

            this.store.dispatch(new actions.SetDropoffLocations([]));
            this.store.dispatch(new actions.SetStartTimes([]));
            this.store.dispatch(new actions.SetStartTimesReturnTrip([]));

            return;
        }

        let productId: number = product.productId;
        let usesDl: boolean = product.usesDropoffLocations;
        let roundTrip: boolean = product.isRoundTripProduct;

        let dropoffLocations$: Observable<LocationListItemDescriptorResponseModel[]> = usesDl
            ? this.locationListRestService.getDropoffLocationsByProductIdPickupLocationIdAndDirection(productId, pickupLocationId, false)
            : observableOf([]);

        let startTimesRegular$: Observable<StartTimeResponseModel[]> = this.productAvailabilityRestService.getStartTimesByProductIdPickupLocationIdAndDirection(productId, pickupLocationId, false);

        let startTimesReturn$: Observable<StartTimeResponseModel[]> = !usesDl && roundTrip
            ? this.productAvailabilityRestService.getStartTimesByProductIdAndDirection(productId, true)
            : observableOf([]);

        observableCombineLatest([
            dropoffLocations$,
            startTimesRegular$,
            startTimesReturn$
        ]).pipe(
            take(1),
            map(([dl, st, str]: [LocationListItemDescriptorResponseModel[], StartTimeResponseModel[], StartTimeResponseModel[]]) => {
                this.store.dispatch(new actions.SetDropoffLocations(dl));
                this.store.dispatch(new actions.SetStartTimes(st));
                this.store.dispatch(new actions.SetStartTimesReturnTrip(str));
            })
        ).subscribe();
    }

    private dropoffLocationChanged(product: ProductSimpleResponseModel, dropoffLocationId: number) {

        if (!dropoffLocationId) {

            //this.store.dispatch(new actions.SetStartTimes([]));
            this.store.dispatch(new actions.SetStartTimesReturnTrip([]));

            return;
        }

        if (product.isRoundTripProduct) {
            this.productAvailabilityRestService.getStartTimesByProductIdPickupLocationIdAndDirection(product.productId, dropoffLocationId, true)
                .subscribe((str: StartTimeResponseModel[]) => {
                    this.store.dispatch(new actions.SetStartTimesReturnTrip(str));
                });
        }
    }

    createQuickSellingButtonProduct(data: SubmitFormDialogDataModel): Observable<any> {

        let quickSellingButtonProductTiers: CreateQuickSellingButtonProductTierRequestModel[] = [];
        data.tiersWithQuantities.map((tierWithQuantity: TierWithQuantityModel) => {
            quickSellingButtonProductTiers.push(new CreateQuickSellingButtonProductTierRequestModel(tierWithQuantity.tierId, tierWithQuantity.tierQuantity));
        });

        const request = new CreateQuickSellingButtonProductRequestModel(
            data.description,
            data.categoryId,
            data.productId,
            data.pickupLocationId,
            data.dropoffLocationId,
            data.productAvailabilityId,
            data.productAvailabilityReturnTripId,
            data.useLastName,
            data.lastNameRequired,
            data.useEmail,
            data.emailRequired,
            data.usePhone,
            data.phoneRequired,
            data.useDiscountCode,
            data.useRoundTrip,
            data.color,
            quickSellingButtonProductTiers
        );

        return this.quickSellingButtonProductRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating quick selling button product', error);
            }));
    }

    updateQuickSellingButtonProduct(quickSellingButtonProductId: number, data: SubmitFormDialogDataModel): Observable<any> {

        let quickSellingButtonProductTiers: CreateQuickSellingButtonProductTierRequestModel[] = [];
        data.tiersWithQuantities.map((tierWithQuantity: TierWithQuantityModel) => {
            quickSellingButtonProductTiers.push(new CreateQuickSellingButtonProductTierRequestModel(tierWithQuantity.tierId, tierWithQuantity.tierQuantity));
        });

        const request = new UpdateQuickSellingButtonProductRequestModel(
            quickSellingButtonProductId,
            data.description,
            data.categoryId,
            data.productId,
            data.pickupLocationId,
            data.dropoffLocationId,
            data.productAvailabilityId,
            data.productAvailabilityReturnTripId,
            data.useLastName,
            data.lastNameRequired,
            data.useEmail,
            data.emailRequired,
            data.usePhone,
            data.phoneRequired,
            data.useDiscountCode,
            data.useRoundTrip,
            data.color,
            quickSellingButtonProductTiers
        );

        return this.quickSellingButtonProductRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating quick selling button product', error);
            }));
    }
}
