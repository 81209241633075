import { animate, state, style, transition, trigger } from '@angular/animations';


import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SharedSandbox } from '../../shared.sandbox';
import { BreadcrumbItemModel } from '../breadcrumbs/breadcrumb-item.model';
import { SS_LOGO } from "../../../core/services/app-properties.service";
import { AppDialogsService } from "../../../core/services/app-dialogs.service";
import {
    OrderSummaryDialogComponent,
    OrderSummaryDialogData
} from "../../dialogs/common-dialogs/order-summary-dialog/container/order-summary-dialog.component";
import { OrderSummaryResponseModel } from "../../../core/modules/rest/order-summary/response/order-summary-response.model";
import { OrderSearchHistoryResponseModel } from '../../../core/modules/rest/order-summary/response/order-search-history-response.model';
import { OrderSearchHistoryRequestModel } from '../../../core/modules/rest/order-summary/request/order-search-history-request.model';
import { UserInfoResponseModel } from '../../../core/modules/rest/user/response/user-info-response.model';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store/reducers';
import * as fromRoot from '../../../core/store/reducers';;
import { NotificationResponseModel } from '../../../core/modules/rest/notification/response/notification-response.model';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [
        trigger('navigationState', [
            state('show', style({ transform: 'translateY(0)' })),

            transition('void => *', [
                style({ transform: 'translateY(-100%)' }),
                animate('0.45s ease-in-out')
            ]),
            transition('* => void',
                animate('0.45s ease-in', style({ transform: 'translateY(-100%)' }))
            )

        ])]
})
export class HeaderComponent implements OnInit {

    @Input() pageId: string;
    @Input() pageBreadcrumbItems: BreadcrumbItemModel[];
    @ViewChild('searchInput') searchInput;

    readonly IGNORE_CLASSES: string[] = ["searchResultsList_container_icon"];

    // Images URL
    ssLogo = SS_LOGO;

    isNavOpen = false;
    isLoading = false;
    isSearchOpen = false;
    isNotificationDropdownOpen = false;
    isUserDropdownOpen = false;
    criteria = null;
    isDropDownOpen = false;

    result: OrderSummaryResponseModel[] = [];
    multipleResults: boolean = false;
    noResults: boolean = false;
    searchDisabled: boolean = false;
    unreadNotificationsCounter: number = 0;

    user: UserInfoResponseModel;

    orderSearchHistoryResults: OrderSearchHistoryResponseModel[] = [];
    notificationHistoryResults: NotificationResponseModel[] = [];

    webSocketAlertIndicator$: Observable<any>;
    newNotificationAlert: boolean = false;
    private alertIndicatorSubscription: Subscription;

    // webSocketAlertIndicator$: Observable<boolean> = this.store.select(fromRoot.userState_webSocketAlertIndicator_selector);

    constructor(private sharedSandbox: SharedSandbox,
        private appDialogsService: AppDialogsService,
        private store: Store<AppState>) {
            this.webSocketAlertIndicator$ = this.store.select(fromRoot.userState_webSocketAlertIndicator_selector);
    }

    ngOnInit() {
        this.sharedSandbox.getUserInfo().subscribe(res => {
            this.user = res
            if (this.user) {
                this.getAllOrderSearchHistoryByUser();
                this.getAllNotificationHistoryByUser();
            }
        });
        this.alertIndicatorSubscription = this.webSocketAlertIndicator$.subscribe(res => {
            this.newNotificationAlert = res;
            this.store.select(fromRoot.userState_webSocketNotifications_selector).subscribe(notifications => {
                this.notificationHistoryResults = notifications;
                let checkForUnreadNotifications = this.notificationHistoryResults.filter(notification => !notification.displayed);
                this.unreadNotificationsCounter = checkForUnreadNotifications.length;
                this.newNotificationAlert = checkForUnreadNotifications.length > 0 ? true : false;
            })
          });
    }


    ngOnDestroy() {
        if (this.alertIndicatorSubscription) {
        this.alertIndicatorSubscription.unsubscribe();
        }
    }

    getAllOrderSearchHistoryByUser() {
        this.sharedSandbox.getAllOrderSearchHistoryByUser(this.user.userId).subscribe((res) => {
            this.orderSearchHistoryResults = [];
            for (let r in res) {
                this.orderSearchHistoryResults.push(res[r]);
            }
        })
    }

    getAllNotificationHistoryByUser() {
        this.sharedSandbox.getAllNotificationHistoryByUser(this.user.userId).subscribe((notifications) => {
            this.notificationHistoryResults = notifications;
            let checkForUnreadNotifications = this.notificationHistoryResults.filter(notification => !notification.displayed);
            this.unreadNotificationsCounter = checkForUnreadNotifications.length;
            this.newNotificationAlert = checkForUnreadNotifications.length > 0 ? true : false;
        })
    }

    getNotificationState(result: any) {
        this.notificationHistoryResults = result;
        let checkForUnreadNotifications = this.notificationHistoryResults.filter(notification => !notification.displayed);
        this.unreadNotificationsCounter = checkForUnreadNotifications.length;
        this.newNotificationAlert = checkForUnreadNotifications.length > 0 ? true : false;
    }

    showSummary(orderId: number) {

        this.cleanSearchBox();
        this.appDialogsService.renderDialog(OrderSummaryDialogComponent, new OrderSummaryDialogData(orderId));
    }

    showSummaryFromDropDown(item: OrderSummaryResponseModel) {

        let orderSearhHistory = this.orderSearchHistoryResults.filter(orderSearchHistoryitem => orderSearchHistoryitem.searchTerm === item.orderExternalId);

        if (orderSearhHistory[0]) {
            this._updateOrderSearchHistory(orderSearhHistory[0].orderSearchHistoryId);
        }
        else {
            const request: OrderSearchHistoryRequestModel = {
                userId: this.user.userId,
                searchTerm: item.orderExternalId
            }
            this._createOrderSearchHistory(request)
        }

        this.cleanSearchBox();
        this.appDialogsService.renderDialog(OrderSummaryDialogComponent, new OrderSummaryDialogData(item.orderId));
    }

    private cleanSearchBox() {

        this.criteria = null;
        this.multipleResults = false;
        this.isSearchOpen = false;
        this.noResults = false;
        this.searchDisabled = false;
        this.isDropDownOpen = false;
    }

    _openMenu(): void {
        this.isNavOpen = !this.isNavOpen;
    }

    _closeMenu() {
        this.isNavOpen = false;
    }

    _openSearch(): void {

        this.searchInput.nativeElement.focus();

        if (this.criteria !== null && this.criteria !== '') {
            this._runSearch();
        } else {
            this.isSearchOpen = !this.isSearchOpen;
            if (!this.isSearchOpen) {
                this.cleanSearchBox();
            }
        }
    }

    _openDropDown(): void {
        this.isDropDownOpen = !this.isDropDownOpen;
        if (this.isDropDownOpen && this.noResults) {
            this.noResults = false;
        }
        if (this.isDropDownOpen && this.multipleResults) {
            this.multipleResults = false;
        }
    }

    _runSearch(): void {

        if (!this.criteria) {
            return;
        }

        const serchTerm = this.criteria

        let orderSearhHistory = this.orderSearchHistoryResults.filter(item => item.searchTerm === serchTerm);

        if (orderSearhHistory[0]) {
            this._updateOrderSearchHistory(orderSearhHistory[0].orderSearchHistoryId);
        }

        this.searchDisabled = true;
        this.noResults = false;
        this.isLoading = true;

        this.sharedSandbox
            .searchOrderByCriteria(this.criteria)
            .subscribe((res: { orders: OrderSummaryResponseModel[] }) => {

                if (res.orders.length < 1) {
                    this.noResults = true;
                    this.isDropDownOpen = false;
                } else if (res.orders.length === 1) {
                    this.showSummary(res.orders[0].orderId);
                    if (!orderSearhHistory[0]) {
                        const request: OrderSearchHistoryRequestModel = {
                            userId: this.user.userId,
                            searchTerm: serchTerm
                        }
                        this._createOrderSearchHistory(request)
                    }
                } else {
                    this.isDropDownOpen = false;
                    this.multipleResults = true;
                }

                this.searchDisabled = false;
                this.result = res.orders;
                this.isLoading = false;
            },
                () => this.isLoading = false);
    }

    _deleteOrderSearchHistory(orderSearchHistoryId: number): void {
        this.sharedSandbox.deleteOrderSearchHistory(orderSearchHistoryId).subscribe(() => {
            this.getAllOrderSearchHistoryByUser();
        });
    }

    _createOrderSearchHistory(request: OrderSearchHistoryRequestModel): void {
        if (this.orderSearchHistoryResults.length === 10) {
            const last = this.orderSearchHistoryResults[this.orderSearchHistoryResults.length - 1];
            this.sharedSandbox.deleteOrderSearchHistory(last.orderSearchHistoryId).subscribe();

        }
        this.sharedSandbox.createOrderSearchHistory(request).subscribe(() => {
            this.getAllOrderSearchHistoryByUser();
        });
    }

    _updateOrderSearchHistory(orderSearchHistoryId: number): void {
        this.sharedSandbox.updateOrderSearchHistoryCreatedDate(orderSearchHistoryId).subscribe(() => {
            this.getAllOrderSearchHistoryByUser();
        });
    }

    private hasIgnoreClass(classes: DOMTokenList): boolean {
        return this.IGNORE_CLASSES.some(ignore => classes.contains(ignore));
    }

    _runSearchFromDropDown(criteria: string): void {
        this.searchDisabled = true;
        this.noResults = false;
        this.isLoading = true;

        this.sharedSandbox
            .searchOrderByCriteria(criteria)
            .subscribe((res: { orders: OrderSummaryResponseModel[] }) => {
                this.showSummary(res.orders[0].orderId);
                this.searchDisabled = false;
                this.result = res.orders;
                this.isLoading = false;
            },
                () => this.isLoading = false);
    }

    _onSearchChanged() {
        this.noResults = false;
    }

    _openNotificationDropdown(): void {
        this.isNotificationDropdownOpen = !this.isNotificationDropdownOpen;
    }

    _openUserDropdown(): void {
        this.isUserDropdownOpen = !this.isUserDropdownOpen;
    }

    _clickedOutsideSearch(event) {
        var path = event.path || (event.composedPath && event.composedPath());
        let notificationDialogClicked: boolean = path.some(p => p.classList && this.hasIgnoreClass(p.classList));
        if (this.isLoading !== true && !notificationDialogClicked) {
            this.cleanSearchBox();
        }
    }

    _clickedOutsideNotificationDropdown() {
        this.isNotificationDropdownOpen = false;
    }

    _clickedOutsideUserDropdown() {
        this.isUserDropdownOpen = false;
    }

}
