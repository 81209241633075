import {DateTimeDescriptor} from "../../../../../shared/model/date-time-descriptor.model";
import {ShoppingCartPaymentDetailRequestModel} from "./shopping-cart-payment-detail-request.model";
import {ShoppingCartPaymentPostBillDetailRequestModel} from "./shopping-cart-payment-post-bill-detail-request.model";
import {PaymentMethodEnum} from "../../../../../shared/enums/payment-method.enum";

export class ShoppingCartPaymentRequestModel {
    amount: number;
    paymentDateTime: DateTimeDescriptor;
    paymentMethod: PaymentMethodEnum;

    isCP: boolean;
    nameOnCard: string;
    lastFourOfCard: string;

    passCardId: number;
    passCardBarcode: string;
    passCardCheckInDateTime: DateTimeDescriptor;

    paymentDetails: ShoppingCartPaymentDetailRequestModel[];
    paymentPostBillDetails: ShoppingCartPaymentPostBillDetailRequestModel[];

    constructor() {
    }
}
