import * as actions from './actions';
import {SystemOptionsResponseModel} from "../../modules/rest/system-options/response/system-options-response.model";
import {createSelector} from "@ngrx/store";

export interface State {
    systemOptions: SystemOptionsResponseModel;
}

const initialState: State = {
    systemOptions: {} as SystemOptionsResponseModel
};

export const systemOptions_selector = (state: State) => state.systemOptions;

export const allowFixedTimeForFlexibleAndMultipleCheckInProducts_selector = createSelector(systemOptions_selector, (so: SystemOptionsResponseModel) => so.allowFixedTimeForFlexibleAndMultipleCheckInProducts);

export const companyCode_selector = createSelector(systemOptions_selector, (so: SystemOptionsResponseModel) => so.companyCode);

export function reducer(state: State = initialState, action: actions.All) {

    switch (action.type) {
        case actions.SET_SYSTEM_OPTIONS: return {...state, systemOptions: action.systemOptions};
        default: return state;
    }
}
