import { Action } from '@ngrx/store';
import { SaleGroupResponseModel } from '../../../../../../../core/modules/rest/sale-group/response/sale-group-response.model';


export const UPDATE_SALE_GROUP_ACTION = '[Sale Group Managment Main] Update sale group managment';
export const UPDATE_SHOW_INACTIVE_SALE_GROUP_ACTION = '[Sale Group Managment Main] Update show inactive sale groups';


export class UpdateSaleGroupManagment implements Action {
    readonly type = UPDATE_SALE_GROUP_ACTION;

    constructor(public saleGroups: SaleGroupResponseModel[]) {
    }
}

export class UpdateShowInactiveSaleGroup implements Action {
    readonly type = UPDATE_SHOW_INACTIVE_SALE_GROUP_ACTION;

    constructor(public show: boolean) {
    }
}

export type Actions =
    UpdateSaleGroupManagment |
    UpdateShowInactiveSaleGroup;
