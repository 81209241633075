export class ActivateRoleRequestModel {

    roleId: number;
    enableAllUsers: boolean;
    userIds: number[];

    constructor(roleId: number, enableAllUsers: boolean, userIds: number[]) {
        this.roleId = roleId;
        this.enableAllUsers = enableAllUsers;
        this.userIds = userIds;
    }
}
