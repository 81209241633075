<app-abstract-dialog-container>
    <app-edit-category-email-html-templates-dialog-form
        [category]="category$ | async"
        [createOrderEmailHtmlTemplates]="createOrderEmailHtmlTemplates$ | async"
        [voidOrderEmailHtmlTemplates]="voidOrderEmailHtmlTemplates$ | async"
        [termsAndConditionsEmailHtmlTemplates]="termsAndConditionsEmailHtmlTemplates$ | async"
        (submit)="onSubmit($event)"
        (cancel)="onCancel()">
    </app-edit-category-email-html-templates-dialog-form>
</app-abstract-dialog-container>
