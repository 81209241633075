import {Action} from "@ngrx/store";
import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";


export const RESET_STATE_ACTION    = "[Dialog Create Dropoff Location :: MAIN] Reset State Action";
export const SET_DROPOFF_LOCATIONS = "[Dialog Create Dropoff Location :: MAIN Set Dropoff Locations";

export class ResetStateAction implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class SetDropoffLocations implements Action {
    readonly type = SET_DROPOFF_LOCATIONS;

    constructor(public dropoffLocations: LocationResponseModel[]) {
    }
}

export type All = ResetStateAction | SetDropoffLocations;

