<div class="title_wrapper">
    <div class="grid-x grid-margin-x">

        <div class="cell medium-12 large-2">
            <div class="title_container">
                <h1>Order Report</h1>
            </div>
        </div>

        <div class="cell medium-12 large-10">
            <div class="titleToolbox_wrapper">
                <div class="favorites_wrapper">
                    <app-favorites
                        [listOfFavorites]="listOfFavorites"
                        [selectedFavoriteId]="selectedFavoriteId"
                        [expressionItemsListEmpty]="expressionItemsListEmpty"
                        [areEnoughFieldsSelected]="areEnoughFieldsSelected"
                        [hasUseFavoritesPermission]="hasUseFavoritesPermission"
                        [hasCreateFavoritePermission]="hasCreateFavoritePermission"
                        [hasEditFavoritePermission]="hasEditFavoritePermission"
                        [hasRemoveFavoritePermission]="hasRemoveFavoritePermission"
                        [showSpanSelectFavorite]="true"
                        (selectedFavoriteChangedEvent)="onSelectedFavoriteChanged($event)"
                        (editFavoriteEvent)="onEditFavorite($event)"
                        (removeFavoriteEvent)="onRemoveFavorite($event)"
                        (sidebarOpenStatusChanged)="onOpenCloseSidebarFavorite()">
                    </app-favorites>
                </div>
            </div>
        </div>
    </div>
</div>
