
import { from as observableFrom, Observable } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from "moment";
import { DriverManifestReportSandbox } from '../sandbox/driver-manifest-report.sandbox';
import { SortByModel } from '../../../../../../shared/model/sort-by.model';
import { BreadcrumbItemModel } from '../../../../../../shared/components/breadcrumbs/breadcrumb-item.model';
import { AppRoutesService } from '../../../../../../core/services/app-routes.service';
import { FilterFormValueModel } from '../model/filter-form-value.model';
import { DriverManifestReportCurrentSearchDataModel } from '../model/driver-manifest-report-current-search-data.model';
import { DateTimeUtility } from "../../../../../../shared/utils/date-time-utility";
import { FavoriteResponseModel } from "../../../../../../core/modules/rest/favorite/response/favorite-response.model";
import {
    EditFavoriteDialogComponent,
    EditFavoriteDialogData
} from "../../../../../../shared/dialogs/common-dialogs/edit-favorite-dialog/container/edit-favorite-dialog.component";
import {
    YesNoDialogComponent,
    YesNoDialogData
} from "../../../../../../shared/dialogs/common-dialogs/yes-no-dialog/container/yes-no-dialog.component";
import { AppDialogsService } from "../../../../../../core/services/app-dialogs.service";
import { PermissionEnum } from "../../../../../../shared/enums/permission.enum";
import { NgxPermissionsService } from "ngx-permissions";
import { FavoriteTypeEnum } from "../../../../../../shared/enums/favorite-type.enum";
import { ProductDirectionDataModel } from "../model/product-direction-data.model";
import {
    OrderSummaryDialogComponent,
    OrderSummaryDialogData
} from "../../../../../../shared/dialogs/common-dialogs/order-summary-dialog/container/order-summary-dialog.component";


@Component({
    selector: 'app-driver-manifest-report',
    templateUrl: './driver-manifest-report.component.html'
})
export class DriverManifestReportComponent implements OnInit, OnDestroy {

    pageBreadcrumbItems: BreadcrumbItemModel[];

    loadingFavorites$: Observable<boolean>;
    favorites$: Observable<FavoriteResponseModel[]>;
    selectedFavoriteId$: Observable<number>;
    favoriteCreatedUpdated$: Observable<boolean>;
    hasUseFavoritesPermission$: Observable<boolean>;
    hasCreateFavoritePermission$: Observable<boolean>;
    hasEditFavoritePermission$: Observable<boolean>;
    hasRemoveFavoritePermission$: Observable<boolean>;
    loadingProducts$: Observable<boolean>;
    productDirections$: Observable<ProductDirectionDataModel[]>;
    selectedDateType$: Observable<string>;
    selectedDate$: Observable<moment.Moment>;
    selectedProductDirections$: Observable<string[]>;
    currentSearchData$: Observable<DriverManifestReportCurrentSearchDataModel>;
    currentSearchDataSortBy$: Observable<SortByModel>;

    private selectedDateType: string;
    private selectedDate: moment.Moment;
    private selectedProductDirections: string[];
    private showInactiveProducts: boolean;

    constructor(private driverManifestReportSandbox: DriverManifestReportSandbox, private appDialogsService: AppDialogsService,
        private appRoutesService: AppRoutesService, private permissionsService: NgxPermissionsService) {

        this.pageBreadcrumbItems = appRoutesService.createDriverManifestReportPageBreadcrumbs();
    }

    ngOnInit() {

        this.showInactiveProducts = false;
        this.driverManifestReportSandbox.loadData();

        this.loadingFavorites$ = this.driverManifestReportSandbox.loadingFavorites$;
        this.favorites$ = this.driverManifestReportSandbox.favorites$;
        this.selectedFavoriteId$ = this.driverManifestReportSandbox.selectedFavoriteId$;
        this.favoriteCreatedUpdated$ = this.driverManifestReportSandbox.favoriteCreatedUpdated$;
        this.hasUseFavoritesPermission$ = observableFrom(this.permissionsService.hasPermission(PermissionEnum.USE_FAVORITES));
        this.hasCreateFavoritePermission$ = observableFrom(this.permissionsService.hasPermission(PermissionEnum.CREATE_FAVORITE));
        this.hasEditFavoritePermission$ = observableFrom(this.permissionsService.hasPermission(PermissionEnum.EDIT_FAVORITE));
        this.hasRemoveFavoritePermission$ = observableFrom(this.permissionsService.hasPermission(PermissionEnum.REMOVE_FAVORITE));
        this.loadingProducts$ = this.driverManifestReportSandbox.loadingProducts$;
        this.productDirections$ = this.driverManifestReportSandbox.productDirections$;
        this.selectedDateType$ = this.driverManifestReportSandbox.selectedDateType$;
        this.selectedDate$ = this.driverManifestReportSandbox.selectedDate$;
        this.selectedProductDirections$ = this.driverManifestReportSandbox.selectedProductDirections$;
        this.currentSearchData$ = this.driverManifestReportSandbox.currentSearchData$;
        this.currentSearchDataSortBy$ = this.driverManifestReportSandbox.currentSearchDataSortBy$;
    }

    ngOnDestroy() {
        this.driverManifestReportSandbox.resetState();
    }

    onSelectedFavoriteChanged(favoriteId: number) {
        this.driverManifestReportSandbox.setSelectedFavoriteId(favoriteId);
    }

    onEditFavorite(favoriteId: number) {
        this.appDialogsService.renderDialog(EditFavoriteDialogComponent, new EditFavoriteDialogData(this.onFavoriteUpdated.bind(this), this.onFavoriteCreated.bind(this), favoriteId, FavoriteTypeEnum.DRIVER_MANIFEST_REPORT));
    }

    onRemoveFavorite(favoriteId: number) {

        let data: YesNoDialogData = new YesNoDialogData(
            "Disable Favorite",
            "Are you sure you want to disable favorite?",
            "Cancel",
            "Disable",
            "danger",
            (confirm: boolean) => {
                if (confirm) {
                    this.driverManifestReportSandbox.setSelectedFavoriteId(null);
                    this.driverManifestReportSandbox.deactivateFavorite(favoriteId)
                        .subscribe(() => {
                            this.onFavoriteRemoved(null);
                            this.driverManifestReportSandbox.resetState();
                        });
                }
            }
        );

        this.appDialogsService.renderDialog(YesNoDialogComponent, data);
    }

    onFavoriteUpdated(favoriteId: number) {
        this.driverManifestReportSandbox.loadAllDriverManifestReportFavoritesByUser(favoriteId, true);
    }

    onFavoriteCreated(favoriteId: number) {
        this.driverManifestReportSandbox.loadAllDriverManifestReportFavoritesByUser(favoriteId, true);
    }

    onFavoriteRemoved(favoriteId: number) {
        this.driverManifestReportSandbox.loadAllDriverManifestReportFavoritesByUser(favoriteId, false);
    }

    onFilterChanged(filterFormValue: FilterFormValueModel) {

        this.selectedDateType = filterFormValue.selectedDateType;
        this.selectedDate = filterFormValue.selectedDate;
        this.selectedProductDirections = filterFormValue.selectedProductDirections;

        if (filterFormValue.showInactiveProducts != this.showInactiveProducts) {
            this.showInactiveProducts = filterFormValue.showInactiveProducts;
            this.selectedProductDirections = [];
        }

        // Update filter values
        this.driverManifestReportSandbox.updateFilter(this.selectedDateType, this.selectedDate, this.selectedProductDirections, filterFormValue.isFavoriteSelected);

        // Clear search data (do not clear selected favorite)
        // this.driverManifestReportSandbox.clearSearchData(false);
    }

    onClearSearchData() {
        // Clear search data (clear selected favorite too)
        this.driverManifestReportSandbox.clearSearchData(true);
    }

    onRunSearch() {

        const selectedDateDescriptor = DateTimeUtility.convertMomentToDateTimeDescriptor(this.selectedDate);

        this.driverManifestReportSandbox.getDriverManifestReport(selectedDateDescriptor, selectedDateDescriptor, this.selectedProductDirections);
    }

    onGenerateUrlForExportToXlsxDriverManifestReport() {

        const selectedDateDescriptor = DateTimeUtility.convertMomentToDateTimeDescriptor(this.selectedDate);

        this.driverManifestReportSandbox.generateUrlForExportToXlsxDriverManifestReport(selectedDateDescriptor, selectedDateDescriptor, this.selectedProductDirections)
            .subscribe(this.generateUrlForExportToXlsxDriverManifestReportSuccessCallback.bind(this));
    }

    onGenerateUrlForExportToPdfDriverManifestReport() {

        const selectedDateDescriptor = DateTimeUtility.convertMomentToDateTimeDescriptor(this.selectedDate);

        this.driverManifestReportSandbox.generateUrlForExportToPdfDriverManifestReport(selectedDateDescriptor, selectedDateDescriptor, this.selectedProductDirections)
            .subscribe(this.generateUrlForExportToPdfDriverManifestReportSuccessCallback.bind(this));
    }

    onPrintDriverManifestReport() {

        const selectedDateDescriptor = DateTimeUtility.convertMomentToDateTimeDescriptor(this.selectedDate);

        this.driverManifestReportSandbox.generateUrlForExportToPdfDriverManifestReport(selectedDateDescriptor, selectedDateDescriptor, this.selectedProductDirections)
            .subscribe(this.generateUrlForPrintDriverManifestReportSuccessCallback.bind(this));
    }

    onHeaderSort(sortBy: SortByModel) {

        const selectedDateDescriptor = DateTimeUtility.convertMomentToDateTimeDescriptor(this.selectedDate);

        this.driverManifestReportSandbox.updateSortBy(sortBy, selectedDateDescriptor, selectedDateDescriptor, this.selectedProductDirections);
    }

    onShowSummary(orderId: number) {
        this.appDialogsService.renderDialog(OrderSummaryDialogComponent, new OrderSummaryDialogData(orderId));
    }

    private generateUrlForExportToXlsxDriverManifestReportSuccessCallback(url: string) {
        window.open(url);
    }

    private generateUrlForExportToPdfDriverManifestReportSuccessCallback(url: string) {
        window.open(url);
    }

    private generateUrlForPrintDriverManifestReportSuccessCallback(url: string) {
        this.driverManifestReportSandbox.printDriverManifestReport(url);
    }
}
