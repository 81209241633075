
import {of as observableOf, combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {take, catchError, map} from 'rxjs/operators';
import * as appReducer from "../../../../../core/store/reducers";
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {OrderSummaryRestService} from "../../../../../core/modules/rest/order-summary/order-summary-rest.service";
import {HttpErrorModel} from "../../../../../core/modules/rest/http-error.model";
import {RootSandbox} from "../../../../../core/store/root.sandbox";
import {OrderSummaryResponseModel} from "../../../../../core/modules/rest/order-summary/response/order-summary-response.model";
import {DialogSandbox} from "../../../services/dialog.sandbox";
import {AppDialogsService} from "../../../../../core/services/app-dialogs.service";
import {UserInfoResponseModel} from "../../../../../core/modules/rest/user/response/user-info-response.model";
import {OrderPrintingInfoSummaryResponseModel} from "../../../../../core/modules/rest/order-summary/response/order-printing-info-summary-response.model";
import {UserResponseModel} from "../../../../../core/modules/rest/user/response/user-response.model";
import {UserRestService} from "../../../../../core/modules/rest/user/user-rest.service";
import {UsersResponseModel} from "../../../../../core/modules/rest/user/response/users-response.model";
import {EditOrderEventDataModel} from "../components/general-info-dialog-summary/general-info-dialog-summary.component";
import {UpdateCustomFieldsRequestModel} from "../../../../../core/modules/rest/order-summary/request/update-custom-fields-request.model";
import {EditOrderDetailEventDataModel} from "../components/product-details-dialog-summary/product-detail-dialog-summary/product-detail-dialog-summary.component";
import {EditPassOrderDetailEventDataModel} from "../components/pass-details-dialog-summary/pass-detail-dialog-summary/pass-detail-dialog-summary.component";
import {BarcodeCheckInRestService} from "../../../../../core/modules/rest/barcode-check-in/barcode-check-in-rest.service";
import {LocationResponseModel} from "../../../../../core/modules/rest/location/response/location-response.model";
import {LocationRestService} from "../../../../../core/modules/rest/location/location-rest.service";
import {LocationsResponseModel} from "../../../../../core/modules/rest/location/response/locations-response.model";
import {ProductRestService} from "../../../../../core/modules/rest/product/product-rest.service";
import {ProductSimpleResponseModel} from "../../../../../core/modules/rest/product/response/product-simple-response.model";
import {StartTimeResponseModel} from "../../../../../core/modules/rest/product-availability/response/start-time-response.model";
import {OrderDetailSummaryResponseModel} from "../../../../../core/modules/rest/order-summary/response/order-detail-summary-response.model";
import {ProductAvailabilityRestService} from "../../../../../core/modules/rest/product-availability/product-availability-rest.service";
import {UpdateOrderDetailOccurrenceRequestModel} from "../../../../../core/modules/rest/order-summary/request/update-order-detail-occurrence-request.model";
import {YesNoDialogComponent, YesNoDialogData} from "../../yes-no-dialog/container/yes-no-dialog.component";

export const ANY_TIME = -2;

@Injectable()
export class OrderSummaryDialogSandbox extends DialogSandbox {

    users$: Observable<UserResponseModel[]> = this.store.pipe(select(reducer.users_selector));
    products$: Observable<ProductSimpleResponseModel[]> = this.store.pipe(select(reducer.products_selector));
    allowFixedTimeForFlexibleAndMultipleCheckInProducts$: Observable<boolean> = this.rootSandbox.getAllowFixedTimeForFlexibleAndMultipleCheckInProducts();
    startTimesForEditedOrderDetailLoading$: Observable<boolean> = this.store.pipe(select(reducer.startTimesForEditedOrderDetailLoading_selector));
    startTimesForEditedOrderDetail$: Observable<StartTimeResponseModel[]> = this.store.pipe(select(reducer.startTimesForEditedOrderDetail_selector));
    locations$: Observable<LocationResponseModel[]> = this.store.pipe(select(reducer.locations_selector));
    currentUser$: Observable<UserInfoResponseModel> = this.appState.pipe(select(appReducer.userState_userInfo_selector));
    orderSummary$: Observable<OrderSummaryResponseModel> = this.store.pipe(select(reducer.order_selector));

    constructor(appDialogsService: AppDialogsService,
                private rootSandbox: RootSandbox,
                private appState: Store<appReducer.AppState>,
                private store: Store<reducer.State>,
                private orderSummaryRestService: OrderSummaryRestService,
                private userRestService: UserRestService,
                private locationRestService: LocationRestService,
                private barcodeCheckInRestService: BarcodeCheckInRestService,
                private productRestService: ProductRestService,
                private productAvailabilityRestService: ProductAvailabilityRestService) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadOrderSummary(orderId: number, additionalPrintingInfo: OrderPrintingInfoSummaryResponseModel = null, newOrder: boolean = false) {

        this.showLoader();

        observableCombineLatest([
            this.orderSummaryRestService.getById(orderId),
            this.userRestService.getAllSortedByDisplayName(),
            this.locationRestService.getAllActive(),
            this.productRestService.getAll()
        ]).pipe(
            take(1),
            map(([order, users, locations, products]: [OrderSummaryResponseModel, UsersResponseModel, LocationsResponseModel, ProductSimpleResponseModel[]]) => {

                // Only if we passed frontend 'printing info' and order is new and printing count
                // wasn't increased on backend show frontend created additional information
                if (additionalPrintingInfo !== null && newOrder && order.printingCount === 0) {
                    order.printingInfos = [...order.printingInfos, additionalPrintingInfo];
                    order.printingCount++;
                }

                let productsIndexedById: ProductSimpleResponseModel[] = [];
                products.forEach((p) => productsIndexedById[p.productId] = p);

                this.store.dispatch(new actions.SetOrder(order));
                this.store.dispatch(new actions.SetProducts(productsIndexedById));
                this.store.dispatch(new actions.SetUsers(users.users));
                this.store.dispatch(new actions.SetLocations(locations.locations));

                this.hideLoader();
            })
        ).pipe(catchError((error: HttpErrorModel) => {
            return this.rootSandbox.handleHttpError("Error while loading order summary by id", error);
        })).subscribe();
    }

    regenerateBarcodes(orderId: number): Observable<any> {
        return this.orderSummaryRestService.regenerateBarcodesForOrder(orderId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while regenerating barcodes", error);
            }));
    }

    sendEmailOrderTo(orderId: number, emailAddress: string): Observable<any> {
        return this.orderSummaryRestService.sendEmailOrderToEmailAddress({orderId: orderId, emailAddress: emailAddress}).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while sending email order", error);
            }));
    }

    changeSelectedDateInt(product: ProductSimpleResponseModel, orderDetail: OrderDetailSummaryResponseModel, dateInt: number): void {

        // Check if date is set
        if (dateInt === null || dateInt === undefined || dateInt <= 0) {
            this.store.dispatch(new actions.SetStartTimesForEditedOrderDetail([]));
            return;
        }

        this.store.dispatch(new actions.SetStartTimesForEditedOrderDetailLoading(true));

        let startTimesForEditedOrderDetail$: Observable<StartTimeResponseModel[]> = observableOf([]);

        if (!orderDetail.isReturnTrip) {
            if (!product.usesPickupLocations) {
                startTimesForEditedOrderDetail$ = this.productAvailabilityRestService.getStartTimesByProductIdDateAndDirection(product.productId, dateInt, false);
            } else if ((product.usesPickupLocations && orderDetail.startLocationId && !product.usesDropoffLocations) || (product.usesPickupLocations && orderDetail.startLocationId && product.usesDropoffLocations && orderDetail.stopLocationId && !product.tracksQuantityBetweenStops)) {
                startTimesForEditedOrderDetail$ = this.productAvailabilityRestService.getStartTimesByProductIdPickupLocationIdDateAndDirection(product.productId, orderDetail.startLocationId, dateInt, false);
            } else if (product.usesPickupLocations && orderDetail.startLocationId && product.usesDropoffLocations && orderDetail.stopLocationId && product.tracksQuantityBetweenStops) {
                startTimesForEditedOrderDetail$ = this.productAvailabilityRestService.getStartTimesByProductIdPickupLocationIdDropoffLocationIdDateAndDirection(product.productId, orderDetail.startLocationId, orderDetail.stopLocationId, dateInt, false);
            } else {
                startTimesForEditedOrderDetail$ = observableOf([]);
            }
        }

        if (orderDetail.isReturnTrip) {
            if (!product.usesDropoffLocations) {
                startTimesForEditedOrderDetail$ = this.productAvailabilityRestService.getStartTimesByProductIdDateAndDirection(product.productId, dateInt, true);
            } else if ((product.usesDropoffLocations && orderDetail.startLocationId && !product.usesPickupLocations) || (product.usesDropoffLocations && orderDetail.startLocationId && product.usesPickupLocations && orderDetail.stopLocationId && !product.tracksQuantityBetweenStops)) {
                startTimesForEditedOrderDetail$ = this.productAvailabilityRestService.getStartTimesByProductIdPickupLocationIdDateAndDirection(product.productId, orderDetail.startLocationId, dateInt, true);
            } else if (product.usesDropoffLocations && orderDetail.startLocationId && product.usesPickupLocations && orderDetail.stopLocationId && product.tracksQuantityBetweenStops) {
                startTimesForEditedOrderDetail$ = this.productAvailabilityRestService.getStartTimesByProductIdPickupLocationIdDropoffLocationIdDateAndDirection(product.productId, orderDetail.startLocationId, orderDetail.stopLocationId, dateInt, true);
            } else {
                startTimesForEditedOrderDetail$ = observableOf([]);
            }
        }

        startTimesForEditedOrderDetail$.subscribe((startTimes: StartTimeResponseModel[]) => {

            startTimes.map((st: StartTimeResponseModel) => {
                if (st.productAvailabilityId === null) {
                    st.productAvailabilityId = ANY_TIME;
                }

                return st;
            });

            this.store.dispatch(new actions.SetStartTimesForEditedOrderDetailLoading(false));
            this.store.dispatch(new actions.SetStartTimesForEditedOrderDetail(startTimes));
        },
        () => this.store.dispatch(new actions.SetStartTimesForEditedOrderDetailLoading(false)));
    }

    updateOrderData(orderId: number, data: EditOrderEventDataModel): void {

        this.showLoader();

        let updateOrderBasicInformation$: Observable<any> = this.orderSummaryRestService.updateOrderBasicInformation({
            orderId: orderId,
            soldById: data.soldById,
            soldAtLocationId: data.soldAtLocationId,
            emailAddress: data.email,
            partyName: data.passengerName,
            phone: data.phoneNumber
        });

        let updateCustomFields$: Observable<any> = this.orderSummaryRestService.updateCustomFields(new UpdateCustomFieldsRequestModel(data.customFields.map(cf => {
            return {
                orderId: orderId,
                orderDetailId: null,
                passOrderDetailId: null,
                partyMember: cf.partyMember,
                templateItemId: cf.templateItemId,
                fieldValue: cf.fieldValue
            };
        })));

        observableCombineLatest([
            updateOrderBasicInformation$,
            updateCustomFields$,
        ]).pipe(catchError((error: HttpErrorModel) => {
            this.hideLoader();
            return this.rootSandbox.handleHttpError("Error while updating order general info and fields", error);
        })).subscribe(() => this.loadOrderSummary(orderId));
    }

    updateOrderDetailData(orderId: number, data: EditOrderDetailEventDataModel) {

        // If should update order detail occurrence, first check if order detail total cost is different
        if (data.shouldUpdateOrderDetailOccurrence) {

            this.orderSummaryRestService.checkOrderDetailTotalCostForOldVsNewOccurrence(new UpdateOrderDetailOccurrenceRequestModel(
                data.orderDetailId,
                data.dateInt,
                data.productAvailabilityId === ANY_TIME ? null : data.productAvailabilityId
            )).pipe(catchError((error: HttpErrorModel) => {
                this.hideLoader();
                return this.rootSandbox.handleHttpError("Error while checking order detail total cost for old vs new occurrence", error);
            })).subscribe((result: number) => {

                let updateOrderDetailOccurrence$: Observable<any> = this.orderSummaryRestService.updateOrderDetailOccurrence(new UpdateOrderDetailOccurrenceRequestModel(
                    data.orderDetailId,
                    data.dateInt,
                    data.productAvailabilityId === ANY_TIME ? null : data.productAvailabilityId
                ));

                let updateCustomFields$: Observable<any> = this.orderSummaryRestService.updateCustomFields(new UpdateCustomFieldsRequestModel(data.customFields.map(cf => {
                    return {
                        orderId: null,
                        orderDetailId: data.orderDetailId,
                        passOrderDetailId: null,
                        partyMember: cf.partyMember,
                        templateItemId: cf.templateItemId,
                        fieldValue: cf.fieldValue
                    };
                })));

                let lessThanOrGreaterThan: string = "";
                if (result !== 0) {

                    if (result < 0) {
                        lessThanOrGreaterThan = "less than";
                    } else if (result > 0) {
                        lessThanOrGreaterThan = "greater than";
                    }

                    let dialogData: YesNoDialogData = new YesNoDialogData(
                        "Price mismatch",
                        "Price for old order detail's occurrence is " + lessThanOrGreaterThan + " price for new order detail's occurrence. Change the occurrence anyway?",
                        "Cancel",
                        "Confirm",
                        "primaryButton",
                        (confirmed: boolean) => {
                            if (confirmed) {

                                observableCombineLatest([
                                    updateOrderDetailOccurrence$,
                                    updateCustomFields$,
                                ]).pipe(catchError((error: HttpErrorModel) => {
                                    this.hideLoader();
                                    return this.rootSandbox.handleHttpError("Error while updating order detail occurrence and fields", error);
                                })).subscribe(() => this.loadOrderSummary(orderId));
                            }
                        }
                    );

                    this.renderDialog(YesNoDialogComponent, dialogData);

                } else {

                    observableCombineLatest([
                        updateOrderDetailOccurrence$,
                        updateCustomFields$,
                    ]).pipe(catchError((error: HttpErrorModel) => {
                        this.hideLoader();
                        return this.rootSandbox.handleHttpError("Error while updating order detail occurrence and fields", error);
                    })).subscribe(() => this.loadOrderSummary(orderId));
                }
            });
        } else {

            this.orderSummaryRestService.updateCustomFields(new UpdateCustomFieldsRequestModel(data.customFields.map(cf => {
                return {
                    orderId: null,
                    orderDetailId: data.orderDetailId,
                    passOrderDetailId: null,
                    partyMember: cf.partyMember,
                    templateItemId: cf.templateItemId,
                    fieldValue: cf.fieldValue
                };
            }))).pipe(catchError((error: HttpErrorModel) => {
                this.hideLoader();
                return this.rootSandbox.handleHttpError("Error while updating order detail fields", error);
            })).subscribe(() => this.loadOrderSummary(orderId));
        }
    }

    updatePassOrderDetailData(orderId: number, data: EditPassOrderDetailEventDataModel) {

        let updateCustomFields$: Observable<any> = this.orderSummaryRestService.updateCustomFields(new UpdateCustomFieldsRequestModel(data.customFields.map(cf => {
            return {
                orderId: null,
                orderDetailId: null,
                passOrderDetailId: data.passOrderDetailId,
                partyMember: cf.partyMember,
                templateItemId: cf.templateItemId,
                fieldValue: cf.fieldValue
            };
        })));

        updateCustomFields$.pipe(catchError((error: HttpErrorModel) => {
            this.hideLoader();
            return this.rootSandbox.handleHttpError("Error while updating pass order detail fields", error);
        })).subscribe(() => this.loadOrderSummary(orderId));
    }

    doNoShow(orderId: number, orderDetailId: number) {

        this.showLoader();

        this.barcodeCheckInRestService.doNoShowOrderDetail(orderDetailId).pipe(
            take(1),
            catchError((error: HttpErrorModel) => {
                this.hideLoader();
                return this.rootSandbox.handleHttpError("Error while updating no-show status for order detail: " + orderDetailId, error);
            }),)
            .subscribe((success: boolean) => {
                if (success) {
                    this.rootSandbox.addInfoNotification("No-show status updated");
                    this.loadOrderSummary(orderId);
                }
            });
    }
}
