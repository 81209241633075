import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild  } from '@angular/core';
// @ts-ignore
import {} from "googlemaps";
import Geocoder = google.maps.Geocoder;
import Autocomplete = google.maps.places.Autocomplete;
import PlaceResult = google.maps.places.PlaceResult;


@Component({
    selector: 'app-input-google-maps-autocomplete',
    templateUrl: './input-google-maps-autocomplete.component.html'
})
export class InputGoogleMapsAutocompleteComponent implements OnChanges, AfterViewInit {

    @Input() classList: string[];

    @Input() placeholderText: string;

    @Input() preSelectedValue: string;

    @Output() addressChanged: EventEmitter<GoogleMapsLocation> = new EventEmitter();

    @ViewChild('addressText') addressText: any;

    geocoder: Geocoder;

    autocomplete: Autocomplete;

    autocompleteStr: string;

    classListStr: string;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.autocomplete && changes.preSelectedValue) {
            this.autocompleteStr = this.preSelectedValue;
        }

        if (changes.classList) {
            this.classListStr = this.classList.join(" ");
        }
    }

    ngAfterViewInit() {
        this.getPlaceAutocomplete();
    }

    private getPlaceAutocomplete() {

        this.geocoder = new Geocoder();

        this.autocomplete = new google.maps.places.Autocomplete(
            this.addressText.nativeElement/*,
            {
                types: ["address"] // establishment, address, geocode, etc.
            }*/);

        google.maps.event.addListener(this.autocomplete, 'place_changed', () => {

            let place: PlaceResult = this.autocomplete.getPlace();

            let zipCode: string = null;
            if (place.address_components && place.address_components.length > 0) {
                const postalCodeComponent = place.address_components.find(component => component.types.includes('postal_code')) || null;
                zipCode = postalCodeComponent !== null ? postalCodeComponent.long_name : null;
            }

            let latitude: number = null;
            let longitude: number = null;
            if (place.geometry && place.geometry.location) {
                latitude = place.geometry.location.lat();
                longitude = place.geometry.location.lng();
            }

            this.addressChanged.emit({
                address: place.formatted_address,
                zipCode: zipCode,
                latitude: latitude,
                longitude: longitude
            });
        });
    }
}

/**
 * Google Maps Autocomplete Input Result
 */
export interface GoogleMapsLocation {
    address: string;
    zipCode;
    latitude: number;
    longitude: number;
}
