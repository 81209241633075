import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {EditAutoAppliedDiscountDialogSandbox} from "../sandbox/edit-auto-applied-discount-dialog.sandbox";
import {Observable} from "rxjs";
import {MultiselectDropdownOptionModel} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import {AutoAppliedDiscountCostModel} from "../model/auto-applied-discount-cost.model";
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";

export class EditAutoAppliedDiscountDialogData {

    costId: number;
    callbackSuccessFn: () => void;

    constructor(callbackSuccessFn: () => void, costId?: number) {
    this.callbackSuccessFn = callbackSuccessFn;
    this.costId = costId;
}
}


@Component({
    selector: 'app-edit-auto-applied-dialog',
    templateUrl: './edit-auto-applied-discount-dialog.component.html',
    styleUrls: ['./edit-auto-applied-discount-dialog.component.scss']
})
export class EditAutoAppliedDiscountDialogComponent implements OnInit, OnDestroy {

    private costId: number;
    private callbackSuccessFn: () => void;

    dialogDataLoaded$: Observable<boolean>;
    editAutoAppliedDiscount$: Observable<boolean>;
    allLocations$: Observable<MultiselectDropdownOptionModel[]>;
    autoAppliedDiscount$: Observable<AutoAppliedDiscountCostModel>;
    errorMessage$: Observable<string>;

    constructor(@Inject(DialogData) dialogData: EditAutoAppliedDiscountDialogData, private sandbox: EditAutoAppliedDiscountDialogSandbox) {

        this.costId = dialogData.costId;
        this.callbackSuccessFn = dialogData.callbackSuccessFn;

        this.dialogDataLoaded$ = this.sandbox.getDialogDataLoaded();
        this.editAutoAppliedDiscount$ = this.sandbox.getEditAutoAppliedDiscount();
        this.allLocations$ = this.sandbox.getAllLocationsForMultiselectDropdown();
        this.autoAppliedDiscount$ = this.sandbox.getAutoAppliedDiscount();
        this.errorMessage$ = this.sandbox.getErrorMessage();
    }

    ngOnInit() {
        this.sandbox.loadDialogData(this.costId);
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onAutoAppliedDiscountChanged(autoAppliedDiscount: AutoAppliedDiscountCostModel) {
        this.sandbox.updateAutoAppliedDiscountProperty(autoAppliedDiscount);
    }

    onSubmit() {
        this.sandbox.save().subscribe(this.saveSuccessCallback.bind(this));
    }

    onDialogClosed() {
        this.sandbox.destroyDialog();
    }

    private saveSuccessCallback(success: boolean) {
        if (success) {
            this.callbackSuccessFn();
            this.sandbox.destroyDialog();
        }
    }
}
