<div class="form_container">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell large-6">
                <h1>Route Setup</h1>
            </div>
        </div>
    </div>

    <div class="content_wrapper removeRow_wrapper"
         [ngClass]="{'removeRow_wrapper': kioskConfigurationDropoffLocationProducts && kioskConfigurationDropoffLocationProducts.length !== 0}">
        <div class="formRow clearfix">
            <div class="iconButton_wrapper float-right">
                <a class="icon_button"
                   (click)="onAddProductClick()">
                    <div class="icon_container">
                        <i class="fal fa-plus-circle"></i>
                    </div>
                    Add Product
                </a>
            </div>
        </div>

        <div class="formRow removeRowIcon"
             *ngFor="let product of kioskConfigurationDropoffLocationProducts; let i = index">
            <app-route-setup-product
                [product]="product"
                [index]="i"
                [allProducts]="allProducts"
                (productChangedEvent)="onProductChanged($event)"
                (directionChangedEvent)="onDirectionChanged($event)"
                (pickupLocationChangedEvent)="onPickupLocationChanged($event)"
                (dropoffLocationChangedEvent)="onDropoffLocationChanged($event)"
                (deleteProductEvent)="onDeleteProductClick($event)">
            </app-route-setup-product>
        </div>

        <ng-container
            *ngIf="kioskConfigurationDropoffLocationProducts && kioskConfigurationDropoffLocationProducts.length == 0">
            <div class="formRow">
                <div class="emptyState bordered emptyState-large">
                    <div class="emptyMessage">
                        No Products Defined
                    </div>
                    <span class="icon_container errorIcon error">
                        <i class="fas fa-exclamation-triangle"></i>
                    </span>
                </div>
            </div>
        </ng-container>
    </div>
    <!-- Dialog Content :: END -->

    <!--Error Message :: BEGIN-->
    <div class="errorMessage_wrapper"
         *ngIf="warningsWhileCreating.length !== 0 || errorMessage">

        <div class="error strong">
            Warnings
        </div>

        <ul class="errorList unorder-list">
            <ng-container *ngIf="errorMessage">
                <li> {{ errorMessage }}</li>
            </ng-container>

            <ng-container *ngIf="warningsWhileCreating">
                <li class="errorMessage"
                    *ngFor="let warning of warningsWhileCreating">
                    {{ warning.fullDescription }}
                </li>
            </ng-container>
        </ul>
    </div>
    <!--Error Message :: END-->

    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]="'secondary'"
                    (clickEvent)="onCloseClick()">
                    Cancel
                </app-button>
            </div>

            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]="'primary'"
                    [disabled]="disabledSaveButton"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div>
    <!--footer :: end-->
</div>
