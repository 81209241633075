import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[appMousePointerNearTopEdge]'
})
export class MousePointerNearTopEdgeDirective {

    @Input() disabled: boolean;
    @Input() distance: number;
    @Output() pointerNearTopEdgeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    private previousValue: boolean = false;

    constructor(private elRef: ElementRef) {}

    @HostListener('document:drag', ['$event'])
    @HostListener('document:mousemove', ['$event'])
    onDrag(event: MouseEvent) {
        if (this.disabled) {
            return;
        }

        const mouseY = event.clientY;
        const scrollTop = this.elRef.nativeElement.scrollTop;
        const elPosition = this.getPosition();

        let pointerDistance: number = Number.MAX_VALUE;
        if (scrollTop > 0) {
            pointerDistance = (scrollTop + elPosition.top) + mouseY - this.distance;
        }

        const valueToEmit = pointerDistance < this.distance;

        if (valueToEmit !== this.previousValue) {
            this.previousValue = valueToEmit;
            this.pointerNearTopEdgeEvent.emit(valueToEmit);
        }
    }

    private getPosition() {
        let nativeElem = this.elRef.nativeElement;
        let x = 0;
        let y = 0;
        while ( nativeElem && !isNaN( nativeElem.offsetLeft ) && !isNaN( nativeElem.offsetTop ) ) {
            x += nativeElem.offsetLeft - nativeElem.scrollLeft;
            y += nativeElem.offsetTop - nativeElem.scrollTop;
            nativeElem = nativeElem.offsetParent;
        }

        return {
            top: y,
            left: x
        };
    }
}
