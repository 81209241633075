export class PostBillTypeFormDepositSubmitData {

    postBillTypeId: number;
    depositAmount: number

    constructor(postBillTypeId: number,depositAmount: number ) {
        this.depositAmount = depositAmount;
        this.postBillTypeId = postBillTypeId;
    }
}
