export class CreateKioskConfigurationDropoffLocationPassTierRequestModel {
    tierId: number;
    defaultQuantity: number;
    minQuantity: number;
    maxQuantity: number;

    constructor(tierId: number, defaultQuantity: number, minQuantity: number, maxQuantity: number) {
        this.tierId = tierId;
        this.defaultQuantity = defaultQuantity;
        this.minQuantity = minQuantity;
        this.maxQuantity = maxQuantity;
    }
}
