<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? 'Edit Commission' : 'Add New Commission' }}</h1>
            </div>
        </div>
    </div>

    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Description</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Description"
                               formControlName="description">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-6">
                    <app-input-label-group>
                        <label class="label">Commission Amount</label>
                        <input class="main--element input-large"
                               appNumericInput
                               numericType="decimal"
                               name="CommissionAmount"
                               formControlName="commissionAmount">
                    </app-input-label-group>
                </div>

                <div class="cell medium-6">
                    <app-input-label-group>
                        <label class="label">&nbsp;</label>
                        <app-dropdown class="main--element"
                                      [preSelectedValue]="form.value.commissionAmountType"
                                      [customClasses]="'dropdown_large'"
                                      [options]="amountTypes"
                                      [formControlName]="'commissionAmountType'">
                        </app-dropdown>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-6">
                    <app-input-label-group>
                        <label class="label">Referral Amount</label>
                        <input class="main--element input-large"
                               appNumericInput
                               numericType="decimal"
                               name="ReferralAmount"
                               formControlName="referralAmount">
                    </app-input-label-group>
                </div>

                <div class="cell medium-6">
                    <app-input-label-group>
                        <label class="label">&nbsp;</label>
                        <app-dropdown class="main--element"
                                      [preSelectedValue]="form.value.referralAmountType"
                                      [customClasses]="'dropdown_large'"
                                      [options]="amountTypes"
                                      [formControlName]="'referralAmountType'">
                        </app-dropdown>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x align-middle">
                <div class="cell medium-6">
                    <app-input-label-group>
                        <label class="label">Start Date</label>
                        <app-datetimepicker class="main--element"
                                            [placeholderText]="'Start Date'"
                                            formControlName="startDate">
                        </app-datetimepicker>
                    </app-input-label-group>
                </div>

                <div class="cell medium-6">
                    <app-input-label-group>
                        <label class="label">End Date</label>
                        <app-datetimepicker class="main--element"
                                            [placeholderText]="'End Date'"
                                            [opensPosition]="'left'"
                                            formControlName="endDate">
                        </app-datetimepicker>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x align-middle">
                <div class="cell medium-6">
                    <app-input-label-group>
                        <label class="label">Start Time</label>
                        <input class="main--element input-large"
                               placeholder="Start time"
                               formControlName="startTime"/>
                    </app-input-label-group>
                </div>

                <div class="cell medium-6">
                    <app-input-label-group>
                        <label class="label">End Time</label>
                        <input class="main--element input-large"
                               placeholder="End time"
                               formControlName="endTime"/>
                    </app-input-label-group>
                </div>
            </div>
        </div>
        <div class="error" [ngClass]="{'disabled': displayErrorMessage}"  style="color: red;" >
            <span> 
                {{errorMessage}}
            </span>
        </div>

    </div>
    <!-- Dialog Content :: END -->


    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>

            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div>
    <!-- Footer :: END -->

</form>
