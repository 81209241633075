import { Component, Input, OnInit  } from '@angular/core';


@Component({
    selector: 'app-table',
    templateUrl: './table.component.html'
})
export class TableComponent implements OnInit {

    @Input() tableHeaders;
    @Input() tableContent;
    @Input() editColumn = false;
    @Input() statusColumn = false;


    tableRows = [
        ['Kiosk Second', 'fdsf', '222', '48d2c98a-a146-9d57-1bc5-7c7f30c43fda'],
        ['Kiosk Second', 'fdsf', '222', '48d2c98a-a146-9d57-1bc5-7c7f30c43fda'],
        ['Kiosk Second', 'fdsf', '222', '48d2c98a-a146-9d57-1bc5-7c7f30c43fda'],
    ];

    constructor() {
    }

    ngOnInit() {
    }

    editIconClicked(row) {
    }

    statusValueChanged(row) {
    }

    activeTableRow(row) {
    }
}
