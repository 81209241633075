import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {MappingHolidayDialogComponent} from "./container/mapping-holiday-dialog.component";
import {MappingHolidayDialogFormComponent} from './components/mapping-holiday-dialog-form/mapping-holiday-dialog-form.component';
import {MappingHolidayDialogSandbox} from "./sandbox/mapping-holiday-dialog.sandbox";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        MappingHolidayDialogSandbox
    ],
    declarations: [
        MappingHolidayDialogComponent,
        MappingHolidayDialogFormComponent
    ],
    exports: [
        MappingHolidayDialogComponent
    ],
    entryComponents: [
        MappingHolidayDialogComponent
    ]
})
export class MappingHolidayDialogModule {

}
