import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {AccordionItemService} from '../../../../../../shared/components/accordion/accordion-item/accordion-item.service';
import {ReportGroupModel} from '../model/report-group.model';
import {DraggedDataI} from '../model/dragged-data.model';


@Component({
    selector: 'app-order-reports-sidebar',
    templateUrl: './order-reports-sidebar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AccordionItemService]
})
export class OrderReportsSidebarComponent implements OnInit {

    @Input() reportGroups: ReportGroupModel[];
    @Input() reportItemsSearchText: string;
    @Input() activeReportGroups: string[];
    @Input() showPaymentSummary: boolean = false;
    @Input() showPaymentSummaryDisabled: boolean = false;

    @Output() dragStartEvent: EventEmitter<DraggedDataI> = new EventEmitter<DraggedDataI>();
    @Output() dragEndEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() searchTextChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() changeReportGroupActivityStatusEvent: EventEmitter<string> = new EventEmitter();
    @Output() showPaymentSummaryChangedEvent: EventEmitter<boolean> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    onDragStart(data: DraggedDataI) {
        this.dragStartEvent.emit(data);
    }

    onDragEnd() {
        this.dragEndEvent.emit();
    }

    onSearchTextChanged(text: string) {
        this.searchTextChanged.emit(text);
    }

    onChangeReportGroupActivityStatus(name: string) {
        this.changeReportGroupActivityStatusEvent.emit(name);
    }

    onShowPaymentSummaryChanged(value: boolean) {
        this.showPaymentSummaryChangedEvent.emit(value);
    }
}
