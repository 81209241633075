import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MechantResponseModel } from "./response/merchant-response.model";
import { environment } from "../../../../../environments/environment";
import { Observable } from "rxjs";
import { CreateMerchantRequestModel } from "./request/create-merchant-request.model";
import { ConnectMerchantDialogFormSubmitDataModel } from "../../../../modules/secured/management/hardware/android-device-infos/dialogs/add-android-device-info-remote-command-dialog/model/connect-merchant-account-dialog-form-submit-data.model";

@Injectable()
export class MerchantRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<MechantResponseModel[]> {
        return this.httpClient.get<MechantResponseModel[]>(environment.server_api_url + '/api/secured/merchant/getAll');
    }

    getAllActive(): Observable<MechantResponseModel[]> {
        return this.httpClient.get<MechantResponseModel[]>(environment.server_api_url + '/api/secured/merchant/getAllActive');
    }

    create(request: CreateMerchantRequestModel): Observable<CreateMerchantRequestModel> {
        return this.httpClient.post<CreateMerchantRequestModel>(environment.server_api_url + '/api/secured/merchant/create', request);
    }

    updateMerchantKey(request: CreateMerchantRequestModel): Observable<CreateMerchantRequestModel> {
        return this.httpClient.post<CreateMerchantRequestModel>(environment.server_api_url + '/api/secured/merchant/updateKey', request);
    }

    activateMerchant(request: CreateMerchantRequestModel): Observable<CreateMerchantRequestModel> {
        return this.httpClient.post<CreateMerchantRequestModel>(environment.server_api_url + '/api/secured/merchant/activate', request);
    }

    deactivateMerchant(request: CreateMerchantRequestModel): Observable<CreateMerchantRequestModel> {
        return this.httpClient.post<CreateMerchantRequestModel>(environment.server_api_url + '/api/secured/merchant/deactivate', request);
    }

    addAndroidDevicesToMerchantsAccount(request: ConnectMerchantDialogFormSubmitDataModel): Observable<ConnectMerchantDialogFormSubmitDataModel>{
        return this.httpClient.post<ConnectMerchantDialogFormSubmitDataModel>(environment.server_api_url + '/api/secured/merchant/connectAndroidDevices', request);
    }
}