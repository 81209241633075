import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {AmountTypeEnum} from "../../../../../../../../shared/enums/amount-type.enum";
import {DiscountResponseModel} from "../../../../../../../../core/modules/rest/cost/response/discount-response.model";
import {SortByModel} from "../../../../../../../../shared/model/sort-by.model";

const SORT_ASC = 'ASC';
const SORT_DESC = 'DESC';

@Component({
    selector: 'app-discounts-table',
    templateUrl: './discounts-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscountsTableComponent implements OnInit, OnChanges {

    @Input() discounts: DiscountResponseModel[];

    @Output() mappingDiscountEvent: EventEmitter<[number, string]> = new EventEmitter();
    @Output() editDiscountEvent: EventEmitter<number> = new EventEmitter();
    @Output() editDiscountActivityEvent: EventEmitter<DiscountResponseModel> = new EventEmitter();

    currentSearchDataSortBy: SortByModel;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.discounts) {
            if (this.currentSearchDataSortBy) {

                // Invert order direction, because it will be inverted again in sort method
                this.currentSearchDataSortBy.order = this.currentSearchDataSortBy.order === SORT_ASC ? SORT_DESC : SORT_ASC;
                this.sortByHeaderClick(this.currentSearchDataSortBy.fieldName);
            }
        }
    }

    mappingDiscountClick(discount: DiscountResponseModel) {
        this.mappingDiscountEvent.emit([discount.costId, discount.description]);
    }

    editDiscountClick(discount: DiscountResponseModel) {
        this.editDiscountEvent.emit(discount.costId);
    }

    onDiscountActivityChanged(discount: DiscountResponseModel) {
        this.editDiscountActivityEvent.emit(discount);
    }

    getAmountPrintValue(discount: DiscountResponseModel): string {

        let amountPrintValue: string = "";

        if (discount.amountType === AmountTypeEnum.DOLLARS) {
            amountPrintValue = '$' + discount.amount;
        } else if (discount.amountType === AmountTypeEnum.PERCENT) {
            amountPrintValue = discount.amount + '%';
        }

        return amountPrintValue;
    }

    getDateRangePrintValue(discount: DiscountResponseModel): string {
        if (discount.startDateFriendly && discount.endDateFriendly) {
            return discount.startDateFriendly + ' - ' + discount.endDateFriendly;
        } else if (discount.endDateFriendly && !discount.startDateFriendly) {
            return 'Before ' + discount.endDateFriendly;
        } else if (discount.startDateFriendly && !discount.endDateFriendly) {
            return 'After ' + discount.startDateFriendly;
        } else {
            return 'Any Date';
        }
    }

    getIsOrderLevelPrintValue(discount: DiscountResponseModel): string {
        return (discount.isOrderLevel ? 'Yes' : 'No');
    }

    sortByHeaderClick(header: string) {

        if (!this.currentSearchDataSortBy) {
            this.currentSearchDataSortBy = new SortByModel(header, SORT_ASC);
        } else if (this.currentSearchDataSortBy.fieldName !== header) {
            this.currentSearchDataSortBy = new SortByModel(header, SORT_ASC);
        } else {
            if (this.currentSearchDataSortBy.order === SORT_ASC) {
                this.currentSearchDataSortBy = new SortByModel(header, SORT_DESC);
            } else {
                this.currentSearchDataSortBy = new SortByModel(header, SORT_ASC);
            }
        }

        switch (this.currentSearchDataSortBy.fieldName) {
            case  "Description":
                this.currentSearchDataSortBy.order === SORT_ASC ? this.discounts.sort((a: DiscountResponseModel, b: DiscountResponseModel) => (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : -1) : this.discounts.sort((a: DiscountResponseModel, b: DiscountResponseModel) => (a.description.toLowerCase() > b.description.toLowerCase()) ? -1 : 1);
                break;
            case  "Amount":
                this.currentSearchDataSortBy.order === SORT_ASC ? this.discounts.sort((a: DiscountResponseModel, b: DiscountResponseModel) => (a.amount > b.amount) ? 1 : -1) : this.discounts.sort((a: DiscountResponseModel, b: DiscountResponseModel) => (a.amount > b.amount) ? -1 : 1);
                break;
            case  "Is Order Level":
                this.currentSearchDataSortBy.order === SORT_ASC ? this.discounts.sort((a: DiscountResponseModel, b: DiscountResponseModel) => (this.getIsOrderLevelPrintValue(a) > this.getIsOrderLevelPrintValue(b)) ? 1 : -1) : this.discounts.sort((a: DiscountResponseModel, b: DiscountResponseModel) => (this.getIsOrderLevelPrintValue(a) > this.getIsOrderLevelPrintValue(b)) ? -1 : 1);
                break;
            case  "Discount Code":
                this.currentSearchDataSortBy.order === SORT_ASC ? this.discounts.sort((a: DiscountResponseModel, b: DiscountResponseModel) => (a.discountCode.toLowerCase() > b.discountCode.toLowerCase()) ? 1 : -1) : this.discounts.sort((a: DiscountResponseModel, b: DiscountResponseModel) => (a.discountCode > b.discountCode) ? -1 : 1);
                break;
            case  "GL Code":
                this.currentSearchDataSortBy.order === SORT_ASC ? this.discounts.sort((a: DiscountResponseModel, b: DiscountResponseModel) => ((a.glCode || b.glCode) ? !a.glCode ? -1 : !b.glCode ? 1 : a.glCode.toLowerCase() > b.glCode.toLowerCase() ? 1 : -1 : 0)) : this.discounts.sort((a: DiscountResponseModel, b: DiscountResponseModel) => ((a.glCode || b.glCode) ? !a.glCode ? 1 : !b.glCode ? -1 : a.glCode.toLowerCase() > b.glCode.toLowerCase() ? -1 : 1 : 0));
                break;
            case  "Date Range":
                this.currentSearchDataSortBy.order === SORT_ASC ? this.discounts.sort((a: DiscountResponseModel, b: DiscountResponseModel) => {
                    if (a.startDateInt === null && b.startDateInt !== null) {
                        return -1;
                    } else if (a.startDateInt !== null && b.startDateInt === null) {
                        return 1;
                    } else if (a.startDateInt !== null && b.startDateInt !== null && a.startDateInt !== b.startDateInt) {
                        return a.startDateInt < b.startDateInt ? -1 : 1;
                    } else {
                        if (a.endDateInt === null && b.endDateInt !== null) {
                            return -1;
                        } else if (a.endDateInt !== null && b.endDateInt === null) {
                            return 1;
                        } else if (a.endDateInt !== null && b.endDateInt !== null && a.endDateInt !== b.endDateInt) {
                            return a.endDateInt < b.endDateInt ? -1 : 1;
                        } else {
                            return 0;
                        }
                    }
                }) : this.discounts.sort((a: DiscountResponseModel, b: DiscountResponseModel) => {
                    if (a.startDateInt === null && b.startDateInt !== null) {
                        return 1;
                    } else if (a.startDateInt !== null && b.startDateInt === null) {
                        return -1;
                    } else if (a.startDateInt !== null && b.startDateInt !== null && a.startDateInt !== b.startDateInt) {
                        return a.startDateInt < b.startDateInt ? 1 : -1;
                    } else {
                        if (a.endDateInt === null && b.endDateInt !== null) {
                            return 1;
                        } else if (a.endDateInt !== null && b.endDateInt === null) {
                            return -1;
                        } else if (a.endDateInt !== null && b.endDateInt !== null && a.endDateInt !== b.endDateInt) {
                            return a.endDateInt < b.endDateInt ? 1 : -1;
                        } else {
                            return 0;
                        }
                    }
                });
                break;
            case  "Platform":
                this.currentSearchDataSortBy.order === SORT_ASC ? this.discounts.sort((a: DiscountResponseModel, b: DiscountResponseModel) => (a.platformDescription > b.platformDescription) ? 1 : -1) : this.discounts.sort((a: DiscountResponseModel, b: DiscountResponseModel) => (a.platformDescription > b.platformDescription) ? -1 : 1);
                break;
        }
    }

}
