import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {MousePointerOverDropAreaService} from '../../../service/mouse-pointer-over-drop-area.service';
import {Observable} from 'rxjs';
import {DraggedDataI} from '../../../model/dragged-data.model';
import {DroppedFieldItemModel} from '../../../model/dropped-field-item.model';
import {DraggedReportItemDataModel} from '../../../model/dragged-report-item-data.model';
import {DraggedFieldDataModel} from '../../../model/dragged-field-data.model';
import {FieldItemModel} from '../../../model/field-item.model';
import {ReportItemModel} from '../../../model/report-item.model';
import {detect} from 'detect-browser';


const browser = detect();



@Component({
    selector: 'app-order-reports-field-drop-area',
    templateUrl: './order-reports-field-drop-area.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReportsFieldDropAreaComponent implements OnInit {

    @Input() enabled: boolean;
    @Input() visible = false;
    @Input() leftSibling_queryFieldName: string;
    @Output() droppedFieldItemEvent: EventEmitter<DroppedFieldItemModel> = new EventEmitter<DroppedFieldItemModel>();
    @Output() dragEndEvent: EventEmitter<void> = new EventEmitter<void>();

    pointerNear: boolean;
    mouseOverDropArea$: Observable<boolean>;
    browserNotSupportingDragNDropMousePosition;

    constructor(private mouseOverDropAreaService: MousePointerOverDropAreaService) {
        this.mouseOverDropArea$ = mouseOverDropAreaService.getMousePointerOverDropArea();
    }

    ngOnInit() {
        this.browserNotSupportingDragNDropMousePosition = false;
        if (browser && browser.name === 'firefox') {
            this.browserNotSupportingDragNDropMousePosition = true;
            this.pointerNear = true;
        }
    }

    onPointerNear(value) {
        this.pointerNear = value;
    }

    onDrop(data: any) {
        const draggedData: DraggedDataI = data.dragData;

        let droppedFieldItem: DroppedFieldItemModel;
        let fieldItem: FieldItemModel;
        let dragEndEmit = false; // This is used because drop event was not fired by drag-and-drop library
        switch (draggedData.constructor) {
            case DraggedReportItemDataModel:
                const draggedReportItemData: DraggedReportItemDataModel = <DraggedReportItemDataModel> draggedData;
                const reportItem: ReportItemModel = draggedReportItemData.reportItem;

                if (!reportItem.field) {
                    console.error('OrderReportsFieldDropAreaComponent :: dropped item is not field');
                    return;
                }

                fieldItem = new FieldItemModel(reportItem.description, reportItem.queryFieldName, reportItem.summable, reportItem.groupable);
                droppedFieldItem = new DroppedFieldItemModel(this.leftSibling_queryFieldName, fieldItem);
                dragEndEmit = true;
                break;
            case DraggedFieldDataModel:
                const draggedFieldItemData: DraggedFieldDataModel = <DraggedFieldDataModel> draggedData;
                fieldItem = draggedFieldItemData.fieldItem;
                droppedFieldItem = new DroppedFieldItemModel(this.leftSibling_queryFieldName, fieldItem);
                break;
            default:
                console.error('OrderReportsFilterDropAreaComponent :: Unexpected dragged data');
                return;
        }

        this.droppedFieldItemEvent.emit(droppedFieldItem);

        if (dragEndEmit) {
            this.dragEndEvent.emit();
        }
    }

    onDragOver() {
        this.mouseOverDropAreaService.setMousePointerOverDropArea(true);
    }

    onDragLeave() {
        this.mouseOverDropAreaService.setMousePointerOverDropArea(false);
    }
}
