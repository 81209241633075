<app-abstract-dialog-container>
    <app-add-sale-group-domain-form
        [saleGroup]="saleGroup"
        [saleGroupId]="saleGroupId"
        [saleGroupName]="saleGroupName"
        (submitEvent)="onSubmit($event)"
        (closeEvent)="onClose()">
    </app-add-sale-group-domain-form>
</app-abstract-dialog-container>


