import * as moment from "moment";

export class DateTimeDescriptor {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
    second: number;
    millisecond;

    constructor(year: number, month: number, day: number, hour: number, minute: number, second: number, millisecond: number) {
        this.year = year;
        this.month = month;
        this.day = day;
        this.hour = hour;
        this.minute = minute;
        this.second = second;
        this.millisecond = millisecond;
    }
}
