import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {FormGroup, FormControl, Validators, FormArray} from "@angular/forms";
import {PassSimpleResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {LocationListItemDescriptorResponseModel} from "../../../../../../../../../core/modules/rest/location-list/response/location-list-item-descriptor-response.model";
import {SubmitFormDialogDataModel} from "../../model/submit-form-dialog-data.model";
import {TierWithQuantityModel} from '../../model/tier-with-quantity.model';
import {QuickSellingButtonPassResponseModel} from '../../../../../../../../../core/modules/rest/quick-selling-button-pass/response/quick-selling-button-pass-response.model';
import {QuickSellingButtonPassTierResponseModel} from '../../../../../../../../../core/modules/rest/quick-selling-button-pass/response/quick-selling-button-pass-tier-response.model';
import {TierResponseModel} from '../../../../../../../../../core/modules/rest/tier/response/tier-response.model';

@Component({
    selector: 'app-edit-quick-selling-button-pass-form',
    templateUrl: './edit-quick-selling-button-pass-form.component.html',
    styleUrls: ['./edit-quick-selling-button-pass-form.component.scss']
})
export class EditQuickSellingButtonPassFormComponent implements OnInit, OnChanges {

    // --------------------------------------------------------
    // Input information
    // --------------------------------------------------------

    // Information about quick selling button pass
    @Input() quickSellingButtonPass: QuickSellingButtonPassResponseModel;

    // Data
    @Input() tiers: TierResponseModel[];
    @Input() passes: PassSimpleResponseModel[];
    @Input() pickupLocations: LocationListItemDescriptorResponseModel[];
    @Input() dropoffLocations: LocationListItemDescriptorResponseModel[];

    // Loading status for dropdown and tiers
    @Input() tiersLoading: boolean;
    @Input() passesLoading: boolean;
    @Input() pickupLocationsLoading: boolean;
    @Input() dropoffLocationsLoading: boolean;

    @Input() errorMessage: string;

    @Input() editMode: boolean;

    // --------------------------------------------------------
    // Output event information
    // --------------------------------------------------------

    // Selected events
    @Output() selectedPassChangedEvent = new EventEmitter<number>();
    @Output() selectedPickupLocationChangedEvent = new EventEmitter<number>();

    // Dialog submit/close events
    @Output() submitEvent = new EventEmitter<SubmitFormDialogDataModel>();
    @Output() closeEvent = new EventEmitter<void>();

    // Component helpers
    form: FormGroup;

    // Model for HTML
    passOptions: DropdownOptionModel[];
    pickupLocationOptions: DropdownOptionModel[];
    dropoffLocationOptions: DropdownOptionModel[];

    // Currently selected values for pass and pickup location, to track selection change
    private selectedPassId: number = null;
    private selectedPickupLocationId: number = null;

    constructor() {
    }

    ngOnInit() {

        this.form = new FormGroup({
            description: new FormControl("", Validators.required),
            color: new FormControl("#000000", Validators.required),
            selectedPassId: new FormControl(null, Validators.required),
            selectedPickupLocationId: new FormControl(null, Validators.required),
            selectedDropoffLocationId: new FormControl(null, Validators.required),
            tiersWithQuantities: new FormArray([]),
            useLastName: new FormControl(false),
            lastNameRequired: new FormControl(false),
            useEmail: new FormControl(false),
            emailRequired: new FormControl(false),
            usePhone: new FormControl(false),
            phoneRequired: new FormControl(false),
            useDiscountCode: new FormControl(false)
        });
    }

    ngOnChanges(changes: SimpleChanges) {

        // Check if form created
        if (!this.form) {
            return;
        }

        // Check if initial changes or later changes (if dialog data loaded or if some "in-form" load triggered)
        if ((!this.editMode || (this.editMode && changes.quickSellingButtonPass && changes.pickupLocations && changes.dropoffLocations)) && changes.tiers && changes.passes) {

            if (this.editMode && changes.quickSellingButtonPass) {
                this.selectedPassId = changes.quickSellingButtonPass.currentValue.passId;
                this.selectedPickupLocationId = changes.quickSellingButtonPass.currentValue.pickupLocationId;
            } else {
                this.selectedPassId = null;
                this.selectedPickupLocationId = null;
            }

            // Populate passes dropdown
            this.passOptions = changes.passes.currentValue
                .map((pass: PassSimpleResponseModel) => new DropdownOptionModel(pass.description, pass.passId.toString()));

            // Populate pickup locations dropdown
            this.pickupLocationOptions = changes.pickupLocations.currentValue
                .map((pickupLocation: LocationListItemDescriptorResponseModel) => new DropdownOptionModel(pickupLocation.locationDescription, pickupLocation.locationId.toString()));

            // Populate dropoff locations dropdown
            this.dropoffLocationOptions = changes.dropoffLocations.currentValue
                .map((dropoffLocation: LocationListItemDescriptorResponseModel) => new DropdownOptionModel(dropoffLocation.locationDescription, dropoffLocation.locationId.toString()));

            // Populate tiers list
            let tiersWithQuantities = [];
            let tierWithQuantity: TierWithQuantityModel;
            let quickSellingButtonPassTier: QuickSellingButtonPassTierResponseModel;

            for (let tier of changes.tiers.currentValue) {

                quickSellingButtonPassTier = null;

                // If edit mode and quick selling button pass provided, try to find current tier among quick selling button pass tiers
                if (this.editMode && changes.quickSellingButtonPass) {
                    for (let qsbpTier of changes.quickSellingButtonPass.currentValue.quickSellingButtonPassTiers) {
                        if (tier.tierId === qsbpTier.tierId) {
                            quickSellingButtonPassTier = qsbpTier;
                            break;
                        }
                    }
                }

                tierWithQuantity = new TierWithQuantityModel(tier.tierId, tier.description, quickSellingButtonPassTier != null ? quickSellingButtonPassTier.tierQuantity : null);
                tiersWithQuantities.push(tierWithQuantity);
            }

            // Patch form - description, color, selected pass, selected pickup location, selected dropoff location and quick selling button pass flags
            if (this.editMode && changes.quickSellingButtonPass && Object.keys(changes.quickSellingButtonPass.currentValue).length !== 0) {

                this.form.patchValue({
                    description: this.quickSellingButtonPass.description,
                    color: this.quickSellingButtonPass.color,
                    selectedPassId: this.quickSellingButtonPass.passId,
                    selectedPickupLocationId: this.quickSellingButtonPass.pickupLocationId,
                    selectedDropoffLocationId: this.quickSellingButtonPass.dropoffLocationId,
                    useLastName: this.quickSellingButtonPass.useLastName,
                    lastNameRequired: this.quickSellingButtonPass.lastNameRequired,
                    useEmail: this.quickSellingButtonPass.useEmail,
                    emailRequired: this.quickSellingButtonPass.emailRequired,
                    usePhone: this.quickSellingButtonPass.usePhone,
                    phoneRequired: this.quickSellingButtonPass.phoneRequired,
                    useDiscountCode: this.quickSellingButtonPass.useDiscountCode
                });
            }

            // Patch form - tiers
            let tiersWithQuantitiesFormArray: FormArray = this.form.get('tiersWithQuantities') as FormArray;
            tiersWithQuantitiesFormArray.reset([]);
            for (let tierWithQuantity of tiersWithQuantities) {
                tiersWithQuantitiesFormArray.push(new FormGroup({
                    tierId: new FormControl(tierWithQuantity.tierId),
                    tierDescription: new FormControl(tierWithQuantity.tierDescription),
                    tierQuantity: new FormControl(tierWithQuantity.tierQuantity)
                }));
            }
        } else {

            if (changes.pickupLocations) {

                // Populate dropdown values
                this.pickupLocationOptions = changes.pickupLocations.currentValue
                    .map((pickupLocation: LocationListItemDescriptorResponseModel) => new DropdownOptionModel(pickupLocation.locationDescription, pickupLocation.locationId.toString()));

                this.selectedPickupLocationId = null;

                // Patch form - selected pickup location
                this.form.patchValue({
                    selectedPickupLocationId: null,
                    selectedDropoffLocationId: null
                });
            }

            if (changes.dropoffLocations) {

                // Populate dropdown values
                this.dropoffLocationOptions = changes.dropoffLocations.currentValue
                    .map((dropoffLocation: LocationListItemDescriptorResponseModel) => new DropdownOptionModel(dropoffLocation.locationDescription, dropoffLocation.locationId.toString()));

                // Patch form - selected dropoff location
                this.form.patchValue({
                    selectedDropoffLocationId: null
                });
            }
        }
    }

    onSelectedPassChanged(value: string) {
        let newSelectedPassId = value != null ? +value : null;
        if (this.selectedPassId !== newSelectedPassId) {
            this.selectedPassId = newSelectedPassId;
            this.selectedPassChangedEvent.emit(this.selectedPassId);
        }
    }

    onSelectedPickupLocationChanged(value: string) {
        let newSelectedPickupLocationId = value != null ? +value : null;
        if (this.selectedPickupLocationId !== newSelectedPickupLocationId) {
            this.selectedPickupLocationId = newSelectedPickupLocationId;
            this.selectedPickupLocationChangedEvent.emit(this.selectedPickupLocationId);
        }
    }

    onCancel() {
        this.closeEvent.emit();
    }

    onSubmit() {

        // Collect tiers
        let tiersWithQuantities: TierWithQuantityModel[] = [];
        let tierWithQuantity: TierWithQuantityModel;
        for (let control of (<FormArray>this.form.get('tiersWithQuantities')).controls) {
            if (control.value.tierQuantity !== null && control.value.tierQuantity !== undefined) {
                tierWithQuantity = control.value;
                tiersWithQuantities.push(tierWithQuantity);
            }
        }

        // Validate form
        if (!this.form.valid) {
            if (!this.form.get("description").valid) {
                this.errorMessage = "Description not provided!";
                return;
            }
            if (!this.form.get("color").valid) {
                this.errorMessage = "Color not provided!";
                return;
            }
            if (!this.form.get("selectedPassId").valid) {
                this.errorMessage = "Pass not selected!";
                return;
            }
            if (!this.form.get("selectedPickupLocationId").valid) {
                this.errorMessage = "Pickup location not selected!";
                return;
            }
            if (!this.form.get("selectedDropoffLocationId").valid) {
                this.errorMessage = "Dropoff location not selected!";
                return;
            }
        }

        // Validate tiers
        if (tiersWithQuantities.length === 0) {
            this.errorMessage = "At least one tier has to have quantity specified!";
            return;
        }

        // Validate quick selling button pass flags combinations
        if (!this.form.get('useLastName').value && this.form.get('lastNameRequired').value) {
            this.errorMessage = "Last name required, but last name not set to be used!";
            return;
        }
        if (!this.form.get('useEmail').value && this.form.get('emailRequired').value) {
            this.errorMessage = "Email required, but email not set to be used!";
            return;
        }
        if (!this.form.get('usePhone').value && this.form.get('phoneRequired').value) {
            this.errorMessage = "Phone required, but phone not set to be used!";
            return;
        }

        const data = new SubmitFormDialogDataModel(
            this.form.get('description').value,
            this.form.get('selectedPassId').value,
            this.form.get('selectedPickupLocationId').value,
            this.form.get('selectedDropoffLocationId').value,
            this.form.get('useLastName').value,
            this.form.get('lastNameRequired').value,
            this.form.get('useEmail').value,
            this.form.get('emailRequired').value,
            this.form.get('usePhone').value,
            this.form.get('phoneRequired').value,
            this.form.get('useDiscountCode').value,
            this.form.get('color').value,
            tiersWithQuantities
        );

        this.submitEvent.emit(data);
    }

    getTiersWithQuantitiesFormControls() {
        return (<FormArray>this.form.get('tiersWithQuantities')).controls;
    }
}
