<app-abstract-dialog-container>
    <div class="closeButton_wrapper">
        <a class="closeButton_container" (click)="onClose()">
            <span class="closeButton">
                <i class="fal fa-times"></i>
            </span>
        </a>
    </div>
    <div class="revealContent_wrapper">
        <div class="loader_wrapper remove">
            <app-loader></app-loader>
        </div>
        <div class="edit-pass-cards-dialog">
            <form class="form_container">
                <div class="content_wrapper">
                    <div class="mb-16px">
                        <h1>{{passCards && passCards.length > 1 ? "Edit MultiPass Cards" : "Edit MultiPass Card"}}</h1>
                    </div>

                    <div class="order-form-items-wrapper mb-16px">
                        <div class="order-form-item">
                            <label class="order-label">Remaining Quantity</label>
                            <div class="order-input-wrapper">
                                <input type="text"
                                       name="Remaining Quantity Value"
                                       placeholder="Enter new value"
                                       [(ngModel)]="remainingQuantityValue">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="footer_wrapper">
                    <div class="flex-container align-center-middle">
                        <div class="mr-16px">
                            <button (click)="onClose()" class="button secondary">Cancel</button>
                        </div>
                        <div class="">
                            <button (click)="onSubmit()" class="button primary">Update</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</app-abstract-dialog-container>


