<div class="table_container"
     #tableContainer>
    <!--    data-simplebar-->
    <!--    data-simplebar-autohide="false"-->
    <!--    data-simplebar-horizontal-->

    <table class="table_striped">

        <!-- Table Header :: BEGIN-->
        <thead>
            <th class="fixedWidthCell_s">
            </th>

            <th class="tableHeaderColumn">
                <a class="tableHeaderColumnLink_container"
                   [ngClass]="{'tableHeaderSortable': true}"
                   (click)="sortByHeaderClick('Order Number')">

                    <div class="tableHeaderColumnLabel">
                        Order Number

                        <!-- defaultSortIcon, sortAscending or sortDescending -->
                        <div class="sortIcon"

                             [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Order Number'"
                             [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Order Number' && currentSearchDataSortBy.order === 'ASC'"
                             [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Order Number' && currentSearchDataSortBy.order === 'DESC'">
                            <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                            <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </a>
            </th>

            <th class="tableHeaderColumn">
                <a class="tableHeaderColumnLink_container"
                   [ngClass]="{'tableHeaderSortable': true}"
                   (click)="sortByHeaderClick('Time')">

                    <div class="tableHeaderColumnLabel">
                        Time

                        <!-- defaultSortIcon, sortAscending or sortDescending -->
                        <div class="sortIcon"

                             [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Time'"
                             [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Time' && currentSearchDataSortBy.order === 'ASC'"
                             [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Time' && currentSearchDataSortBy.order === 'DESC'">
                            <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                            <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </a>
            </th>

            <th class="tableHeaderColumn">
                <a class="tableHeaderColumnLink_container"
                   [ngClass]="{'tableHeaderSortable': true}"
                   (click)="sortByHeaderClick('Pick Up')">

                    <div class="tableHeaderColumnLabel">
                        Pick Up

                        <!-- defaultSortIcon, sortAscending or sortDescending -->
                        <div class="sortIcon"

                             [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Pick Up'"
                             [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Pick Up' && currentSearchDataSortBy.order === 'ASC'"
                             [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Pick Up' && currentSearchDataSortBy.order === 'DESC'">
                            <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                            <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </a>
            </th>

            <th class="tableHeaderColumn">
                <a class="tableHeaderColumnLink_container"
                   [ngClass]="{'tableHeaderSortable': true}"
                   (click)="sortByHeaderClick('Destination')">

                    <div class="tableHeaderColumnLabel">
                        Destination

                        <!-- defaultSortIcon, sortAscending or sortDescending -->
                        <div class="sortIcon"

                             [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Destination'"
                             [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Destination' && currentSearchDataSortBy.order === 'ASC'"
                             [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Destination' && currentSearchDataSortBy.order === 'DESC'">
                            <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                            <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </a>
            </th>

            <th class="tableHeaderColumn">
                <a class="tableHeaderColumnLink_container"
                   [ngClass]="{'tableHeaderSortable': true}"
                   (click)="sortByHeaderClick('# in Party')">

                    <div class="tableHeaderColumnLabel">
                        # in Party

                        <!-- defaultSortIcon, sortAscending or sortDescending -->
                        <div class="sortIcon"

                             [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== '# in Party'"
                             [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === '# in Party' && currentSearchDataSortBy.order === 'ASC'"
                             [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === '# in Party' && currentSearchDataSortBy.order === 'DESC'">
                            <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                            <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </a>
            </th>

            <th class="tableHeaderColumn">
                <a class="tableHeaderColumnLink_container"
                   [ngClass]="{'tableHeaderSortable': true}"
                   (click)="sortByHeaderClick('Name')">

                    <div class="tableHeaderColumnLabel">
                        Name

                        <!-- defaultSortIcon, sortAscending or sortDescending -->
                        <div class="sortIcon"

                             [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Name'"
                             [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Name' && currentSearchDataSortBy.order === 'ASC'"
                             [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Name' && currentSearchDataSortBy.order === 'DESC'">
                            <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                            <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </a>
            </th>

            <th class="tableHeaderColumn">
                <a class="tableHeaderColumnLink_container"
                   [ngClass]="{'tableHeaderSortable': true}"
                   (click)="sortByHeaderClick('Phone')">

                    <div class="tableHeaderColumnLabel">
                        Phone

                        <!-- defaultSortIcon, sortAscending or sortDescending -->
                        <div class="sortIcon"

                             [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Phone'"
                             [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Phone' && currentSearchDataSortBy.order === 'ASC'"
                             [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Phone' && currentSearchDataSortBy.order === 'DESC'">
                            <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                            <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </a>
            </th>

            <th class="tableHeaderColumn"
                *ngFor="let customField of currentSearchData.allCustomFields">
                <div class="tableHeaderColumnLabel">
                    {{ customField.fieldName }}
                </div>
            </th>
        </thead><!-- Table Header :: END-->

        <!-- Table Body :: BEGIN -->
        <tr *ngFor="let driverManifestReportItemTableData of driverManifestReportItemsTableData">
            <td class="fixedWidthCell_s">
                <a class="icon_container tableIconContainer"
                   (click)="showSummary(driverManifestReportItemTableData)">
                    <i class="fal fa-file-alt"></i>
                </a>
            </td>

            <td>
                <span class="td_content">
                    {{ driverManifestReportItemTableData.orderExternalId }}
                </span>
            </td>

            <td>
                <span class="td_content">
                    {{ driverManifestReportItemTableData.startTimeFriendly }}
                </span>
            </td>

            <td>
                <span class="td_content">
                    {{ driverManifestReportItemTableData.pickupLocationDescription }}
                </span>
            </td>

            <td>
                <span class="td_content">
                    {{ driverManifestReportItemTableData.dropoffLocationDescription }}
                </span>
            </td>

            <td>
                <span class="td_content">
                    {{ driverManifestReportItemTableData.tierHeadCount }}
                </span>
            </td>

            <td>
                <span class="td_content">
                    {{ driverManifestReportItemTableData.lastName }}
                </span>
            </td>

            <td>
                <span class="td_content">
                    {{ driverManifestReportItemTableData.phone }}
                </span>
            </td>

            <td *ngFor="let driverManifestReportItemCustomFieldValue of driverManifestReportItemTableData.customFields">
                <span class="td_content">
                    {{ driverManifestReportItemCustomFieldValue }}
                </span>
            </td>
        </tr><!-- Table Body :: END -->

    </table>
</div>
