import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MappingCostDialogComponent} from "./container/mapping-cost-dialog.component";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {MappingCostDialogFormComponent} from './components/mapping-cost-dialog-form/mapping-cost-dialog-form.component';
import {MappingCostDialogSandbox} from "./sandbox/mapping-cost-dialog.sandbox";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        MappingCostDialogSandbox
    ],
    exports: [
        MappingCostDialogComponent
    ],
    declarations: [
        MappingCostDialogComponent,
        MappingCostDialogFormComponent
    ],
    entryComponents: [
        MappingCostDialogComponent
    ]
})
export class MappingCostDialogModule {
}
