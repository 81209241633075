import {DateTimeDescriptor} from "../../../../../shared/model/date-time-descriptor.model";
import {CreateCashCollectedPaymentMethodRequestModel} from "./create-cash-collected-payment-method-request.model";
import {EndOfShiftReportOrderRevenueDescriptorRequestModel} from "./end-of-shift-report-order-revenue-descriptor-request.model";

export class CreateCashCollectedRequestModel {

    startDateTime: DateTimeDescriptor;
    dateTimeCollected: DateTimeDescriptor;
    locationId: number;
    cashCollectedForUserId: number;
    cashCollectedByUserId: number;
    reason: string;
    reconciled: boolean;
    cashCollectedPaymentMethods: CreateCashCollectedPaymentMethodRequestModel[];
    orderRevenueDescriptors: EndOfShiftReportOrderRevenueDescriptorRequestModel[];

    constructor(startDateTime: DateTimeDescriptor,
                dateTimeCollected: DateTimeDescriptor,
                locationId: number,
                cashCollectedForUserId: number,
                cashCollectedByUserId: number,
                reason: string,
                reconciled: boolean,
                cashCollectedPaymentMethods: CreateCashCollectedPaymentMethodRequestModel[],
                orderRevenueDescriptors: EndOfShiftReportOrderRevenueDescriptorRequestModel[]) {

        this.startDateTime = startDateTime;
        this.dateTimeCollected = dateTimeCollected;
        this.locationId = locationId;
        this.cashCollectedForUserId = cashCollectedForUserId;
        this.cashCollectedByUserId = cashCollectedByUserId;
        this.reason = reason;
        this.reconciled = reconciled;
        this.cashCollectedPaymentMethods = cashCollectedPaymentMethods;
        this.orderRevenueDescriptors = orderRevenueDescriptors;
    }
}
