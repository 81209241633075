import {CreateKioskConfigurationDropoffLocationPassTierRequestModel} from "./create-kiosk-configuration-dropoff-location-pass-tier-request.model";

export class CreateKioskConfigurationDropoffLocationPassTiersRequestModel {
    kioskConfigurationDropoffLocationId: number;
    passId: number;
    passTiers: CreateKioskConfigurationDropoffLocationPassTierRequestModel[];

    constructor(kioskConfigurationDropoffLocationId: number, passId: number, passTiers: CreateKioskConfigurationDropoffLocationPassTierRequestModel[]) {
        this.kioskConfigurationDropoffLocationId = kioskConfigurationDropoffLocationId;
        this.passId = passId;
        this.passTiers = passTiers;
    }
}
