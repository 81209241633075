<div class="order-report-sidebar-favorite flex-container flex-dir-column"
     [ngClass]="{'animate-out' : !openSidebar, 'animate-in': openSidebar}">
    <div class="favorite_sidebar_container">

        <div class="search_wrapper">

            <app-search
                [placeholder]="'Search Favorites'"
                (searchTextChanged)="onSearchTextChanged($event)">
            </app-search>
        </div>

        <div class="accordion_wrapper">
            <ul class="accordion bordered">
                <ng-container *ngFor="let favoriteGroup of listOfAllFavoriteGroups">
                    <app-order-report-sidbar-favorite-group
                        *ngIf="favoriteGroup.favorites.length > 0"
                        [listOfFavorites]="favoriteGroup.favorites"
                        [groupName]="favoriteGroup.groupDescription"
                        [selectedFavoriteId]="selectedFavoriteId"
                        (selectedFavoriteChangedEvent)="onSelectedFavoriteChanged($event)"
                        [openAccordion]="openAccordion">
                    </app-order-report-sidbar-favorite-group>
                </ng-container>
            </ul>
        </div>


    </div>
    <div class="order-report-sidebar-favorite-action-buttons-wrapper flex-container p-16px">
        <button class="button ghost-button" (click)="onCloseClicked()">Cancel</button>
    </div>
</div>
