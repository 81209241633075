
import { of as observableOf, Observable, Subject } from 'rxjs';

import { mergeMap } from 'rxjs/operators';
import { Component, OnInit, Optional } from '@angular/core';
import { AppRoutesService } from '../../../../core/services/app-routes.service';
import { SharedSandbox } from '../../../shared.sandbox';
import { UserInfoResponseModel } from '../../../../core/modules/rest/user/response/user-info-response.model';
import { ShoppingCartSandbox } from "../../../../modules/secured/sales/place-order/components/shopping-cart/sandbox/shopping-cart.sandbox";
import { ShoppingCartDataModel } from "../../../../modules/secured/sales/place-order/order-long-form/model/shopping-cart/shopping-cart-data.model";
import { isCreditCardPayment } from "../../../enums/payment-method.enum";
import {
    YesNoDialogComponent,
    YesNoDialogData
} from "../../../dialogs/common-dialogs/yes-no-dialog/container/yes-no-dialog.component";
import { AppDialogsService } from "../../../../core/services/app-dialogs.service";
import { ChangePasswordDialogComponent, ChangePasswordDialogData } from '../../../dialogs/common-dialogs/change-password-dialog/container/change-password-dialog.component';
import { ChangePasswordRequest } from '../../../../core/modules/rest/auth/change-password-request.model';
import { RootSandbox } from '../../../../core/store/root.sandbox';


@Component({
    selector: 'app-header-user-menu-dropdown',
    templateUrl: './header-user-menu-dropdown.component.html',
    styleUrls: ['./header-user-menu-dropdown.component.scss']
})
export class HeaderUserMenuDropdownComponent implements OnInit {

    user$: Observable<UserInfoResponseModel>;

    constructor(private appRoutesService: AppRoutesService, private appDialogsService: AppDialogsService, private sharedSandbox: SharedSandbox, @Optional() private shoppingCartSandbox: ShoppingCartSandbox, private rootSandbox: RootSandbox) {
    }

    ngOnInit() {
        this.user$ = this.sharedSandbox.getUserInfo();
    }

    logoutClick() {

        if (this.shoppingCartSandbox) {
            this.shoppingCartSandbox.shoppingCart$.pipe().pipe(
                mergeMap((shoppingCart: ShoppingCartDataModel) => {
                    if ((shoppingCart.shoppingCartItems && shoppingCart.shoppingCartItems.length > 0) || (shoppingCart.paymentItems && shoppingCart.paymentItems.length > 0)) {
                        return this.renderDialog(shoppingCart.paymentItems.findIndex(pi => isCreditCardPayment(pi.paymentMethod)) !== -1).asObservable();
                    } else {
                        this.sharedSandbox.webSocketDisconnect();
                        return observableOf(this.logout());
                    }
                })).subscribe().unsubscribe();
        } else {
            this.sharedSandbox.webSocketDisconnect();
            this.logout();
        }
    }

    onChangePasswordClick() {
        let data: ChangePasswordDialogData = new ChangePasswordDialogData(
            "Change Password",
            "Cancel",
            "Update",
            "primaryButton",
            (confirmed: boolean, currentPassword: string, newPassword: string, confirmedPassword: string) => {
                if (confirmed) {
                    this.changePassword(currentPassword, newPassword, confirmedPassword)
                }
            }
        );

        this.appDialogsService.renderDialog(ChangePasswordDialogComponent, data);
    }

    changePassword(currentPassword: string, newPassword: string, confirmedPassword: string) {

        if (currentPassword === undefined || currentPassword.trim().length == 0) {
            return this.rootSandbox.addErrorNotification("Error while changing password: Current password can't be empty");
        }

        if (newPassword === undefined || newPassword.trim().length == 0) {
            return this.rootSandbox.addErrorNotification("Error while changing password: New password can't be empty");
        }

        if (/\s/.test(newPassword)) {
            return this.rootSandbox.addErrorNotification("Error while changing password: New password can't contain whitespace");
        }

        if (newPassword !== confirmedPassword) {
            return this.rootSandbox.addErrorNotification("Error while changing password: Passwords do not match");
        }

        this.user$.subscribe(user => {
            const changePasswordRequest = new ChangePasswordRequest(user.username, currentPassword, newPassword);
            this.sharedSandbox.changePassword(changePasswordRequest);
        })

    }


    private renderDialog(creditCardPaymentPresent: boolean): Subject<boolean> {

        let subject: Subject<boolean> = new Subject();

        let data: YesNoDialogData = new YesNoDialogData(
            "Confirm Navigation",
            `You have items/payments in shopping cart! Are you sure you want to leave this page?`,
            "Stay on this Page",
            "Leave this Page",
            "primary",
            (confirmed: boolean) => {

                if (confirmed) {
                    this.logout();
                }

                subject.next(confirmed);
                subject.complete();
            }
        );

        this.appDialogsService.renderDialog(YesNoDialogComponent, data);

        return subject;
    }

    private logout() {
        this.appRoutesService.goToLogoutPage(true);
        this.sharedSandbox.logout().subscribe(null, null);
    }

    onUseTouchScreenLayoutChanged(useTouchScreenLayout: boolean) {
        this.sharedSandbox.updateUseTouchScreenLayout(useTouchScreenLayout);
    }
}
