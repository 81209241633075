import {Action} from "@ngrx/store";
import {PassSimpleResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";

export const RESET_STATE = '[Dialog - Edit Pass Validity :: MAIN] Reset State';
export const SET_PASS = '[Dialog - Edit Pass Validity :: MAIN] Set Pass';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetPass implements Action {
    readonly type = SET_PASS;

    constructor(public pass: PassSimpleResponseModel) {
    }
}

export type All = ResetState | SetPass;
