<app-abstract-dialog-container>
    <app-edit-quick-selling-button-pass-form
    
        [editMode]="editMode"

        [quickSellingButtonPass]="(quickSellingButtonPass$ | async)"

        [tiers]="tiers$ | async"
        [tiersLoading]="tiersLoading$ | async"
        [passes]="passes$ | async"
        [passesLoading]="passesLoading$ | async"
        [pickupLocations]="pickupLocations$ | async"
        [pickupLocationsLoading]="pickupLocationsLoading$ | async"
        [dropoffLocations]="dropoffLocations$ | async"
        [dropoffLocationsLoading]="dropoffLocationsLoading$ | async"

        [errorMessage]="errorMessage$ | async"

        (selectedPassChangedEvent)="onSelectedPassChanged($event)"
        (selectedPickupLocationChangedEvent)="onSelectedPickupLocationChanged($event)"

        (submitEvent)="onSubmit($event)"
        (closeEvent)="onClose()">
    </app-edit-quick-selling-button-pass-form>
</app-abstract-dialog-container>
