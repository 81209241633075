const KioskConfigurationWarningTypeEnum = {
    PICKUP_LOCATION_NOT_ACTIVE: 'PICKUP_LOCATION_NOT_ACTIVE' as 'PICKUP_LOCATION_NOT_ACTIVE',
    NO_VALID_DROPOFF_LOCATION: 'NO_VALID_DROPOFF_LOCATION' as 'NO_VALID_DROPOFF_LOCATION',
    KIOSK_CONFIGURATION_DROPOFF_LOCATION_NOT_ACTIVE: 'KIOSK_CONFIGURATION_DROPOFF_LOCATION_NOT_ACTIVE' as 'KIOSK_CONFIGURATION_DROPOFF_LOCATION_NOT_ACTIVE',

    ROUTE__PRODUCTS_LIST_IS_EMPTY: 'ROUTE__PRODUCTS_LIST_IS_EMPTY' as 'ROUTE__PRODUCTS_LIST_IS_EMPTY',
    ROUTE__KIOSK_CONFIGURATION_PICKUP_LOCATION_IS_NOT_AS_FIRST_PRODUCT_PICKUP_LOCATION: 'ROUTE__KIOSK_CONFIGURATION_PICKUP_LOCATION_IS_NOT_AS_FIRST_PRODUCT_PICKUP_LOCATION' as 'ROUTE__KIOSK_CONFIGURATION_PICKUP_LOCATION_IS_NOT_AS_FIRST_PRODUCT_PICKUP_LOCATION',
    ROUTE__KIOSK_CONFIGURATION_DROPOFF_LOCATION_IS_NOT_AS_LAST_PRODUCT_DROPOFF_LOCATION: 'ROUTE__KIOSK_CONFIGURATION_DROPOFF_LOCATION_IS_NOT_AS_LAST_PRODUCT_DROPOFF_LOCATION' as 'ROUTE__KIOSK_CONFIGURATION_DROPOFF_LOCATION_IS_NOT_AS_LAST_PRODUCT_DROPOFF_LOCATION',
    ROUTE__INACTIVE_PRODUCT: 'ROUTE__INACTIVE_PRODUCT' as 'ROUTE__INACTIVE_PRODUCT',
    ROUTE__PRODUCT_NOT_USING_PICKUP_OR_DROPOFF_LOCATIONS: 'ROUTE__PRODUCT_NOT_USING_PICKUP_OR_DROPOFF_LOCATIONS' as 'ROUTE__PRODUCT_NOT_USING_PICKUP_OR_DROPOFF_LOCATIONS',
    ROUTE__ALL_PRODUCT_SHOULD_BE_ROUND_TRIP_OR_ALL_PRODUCTS_SHOULD_BE_ONE_WAY: 'ROUTE__ALL_PRODUCT_SHOULD_BE_ROUND_TRIP_OR_ALL_PRODUCTS_SHOULD_BE_ONE_WAY' as 'ROUTE__ALL_PRODUCT_SHOULD_BE_ROUND_TRIP_OR_ALL_PRODUCTS_SHOULD_BE_ONE_WAY',
    ROUTE__PRODUCT_PICKUP_LOCATION_IS_NOT_EQUAL_TO_PREVIOUS_PRODUCT_DROPOFF_LOCATION: 'ROUTE__PRODUCT_PICKUP_LOCATION_IS_NOT_EQUAL_TO_PREVIOUS_PRODUCT_DROPOFF_LOCATION' as 'ROUTE__PRODUCT_PICKUP_LOCATION_IS_NOT_EQUAL_TO_PREVIOUS_PRODUCT_DROPOFF_LOCATION',
    ROUTE__PRODUCT_PICKUP_LOCATION_IS_NOT_ACTIVE: 'ROUTE__PRODUCT_PICKUP_LOCATION_IS_NOT_ACTIVE' as 'ROUTE__PRODUCT_PICKUP_LOCATION_IS_NOT_ACTIVE',
    ROUTE__PRODUCT_DROPOFF_LOCATION_IS_NOT_ACTIVE: 'ROUTE__PRODUCT_DROPOFF_LOCATION_IS_NOT_ACTIVE' as 'ROUTE__PRODUCT_DROPOFF_LOCATION_IS_NOT_ACTIVE',
    ROUTE__PRODUCT_PICKUP_LOCATION_IS_NOT_PART_OF_PICKUP_LOCATIONS_LIST: 'ROUTE__PRODUCT_PICKUP_LOCATION_IS_NOT_PART_OF_PICKUP_LOCATIONS_LIST' as 'ROUTE__PRODUCT_PICKUP_LOCATION_IS_NOT_PART_OF_PICKUP_LOCATIONS_LIST',
    ROUTE__PRODUCT_DROPOFF_LOCATION_IS_NOT_PART_OF_DROPOFF_LOCATIONS_LIST: 'ROUTE__PRODUCT_DROPOFF_LOCATION_IS_NOT_PART_OF_DROPOFF_LOCATIONS_LIST' as 'ROUTE__PRODUCT_DROPOFF_LOCATION_IS_NOT_PART_OF_DROPOFF_LOCATIONS_LIST',

    ONE_WAY_TIER__NOT_ACTIVE: 'ONE_WAY_TIER__NOT_ACTIVE' as 'ONE_WAY_TIER__NOT_ACTIVE',
    ONE_WAY_TIER__PRICING_NOT_AVAILABLE_FOR_PRODUCT_AND_TIER: 'ONE_WAY_TIER__PRICING_NOT_AVAILABLE_FOR_PRODUCT_AND_TIER' as 'ONE_WAY_TIER__PRICING_NOT_AVAILABLE_FOR_PRODUCT_AND_TIER',

    ROUND_TRIP_TIER__NOT_ACTIVE: 'ROUND_TRIP_TIER__NOT_ACTIVE' as 'ROUND_TRIP_TIER__NOT_ACTIVE',
    ROUND_TRIP_TIER__PRICING_NOT_AVAILABLE_FOR_PRODUCT_AND_TIER: 'ROUND_TRIP_TIER__PRICING_NOT_AVAILABLE_FOR_PRODUCT_AND_TIER' as 'ROUND_TRIP_TIER__PRICING_NOT_AVAILABLE_FOR_PRODUCT_AND_TIER',

    PASS__NOT_ACTIVE: 'PASS__NOT_ACTIVE' as 'PASS__NOT_ACTIVE',
    PASS__NOT_VALID_FOR_ALL_PRODUCTS_IN_ROUTE: 'PASS__NOT_VALID_FOR_ALL_PRODUCTS_IN_ROUTE' as 'PASS__NOT_VALID_FOR_ALL_PRODUCTS_IN_ROUTE',
    PASS__TIER_NOT_ACTIVE: 'PASS__TIER_NOT_ACTIVE' as 'PASS__TIER_NOT_ACTIVE',
    PASS__PRICING_NOT_AVAILABLE_FOR_TIER: 'PASS__PRICING_NOT_AVAILABLE_FOR_TIER' as 'PASS__PRICING_NOT_AVAILABLE_FOR_TIER',
};
type KioskConfigurationWarningTypeEnum = (typeof KioskConfigurationWarningTypeEnum)[keyof typeof KioskConfigurationWarningTypeEnum];
export {KioskConfigurationWarningTypeEnum};


const messagesMap: Map<KioskConfigurationWarningTypeEnum, string> = new Map();

messagesMap.set(KioskConfigurationWarningTypeEnum.PICKUP_LOCATION_NOT_ACTIVE, 'Pickup location is not active');
messagesMap.set(KioskConfigurationWarningTypeEnum.NO_VALID_DROPOFF_LOCATION, 'No valid dropoff location');
messagesMap.set(KioskConfigurationWarningTypeEnum.KIOSK_CONFIGURATION_DROPOFF_LOCATION_NOT_ACTIVE, 'Dropoff location is not active');

messagesMap.set(KioskConfigurationWarningTypeEnum.ROUTE__PRODUCTS_LIST_IS_EMPTY, 'Route is empty');
messagesMap.set(KioskConfigurationWarningTypeEnum.ROUTE__KIOSK_CONFIGURATION_PICKUP_LOCATION_IS_NOT_AS_FIRST_PRODUCT_PICKUP_LOCATION, 'Kiosk configuration pickup location is not same as pickup location of first product');
messagesMap.set(KioskConfigurationWarningTypeEnum.ROUTE__KIOSK_CONFIGURATION_DROPOFF_LOCATION_IS_NOT_AS_LAST_PRODUCT_DROPOFF_LOCATION, 'Kiosk configuration dropoff location is not same as dropoff location of last product');
messagesMap.set(KioskConfigurationWarningTypeEnum.ROUTE__INACTIVE_PRODUCT, 'Inactive route');
messagesMap.set(KioskConfigurationWarningTypeEnum.ROUTE__PRODUCT_NOT_USING_PICKUP_OR_DROPOFF_LOCATIONS, 'Product is not using pickup or dropoff locations');
messagesMap.set(KioskConfigurationWarningTypeEnum.ROUTE__ALL_PRODUCT_SHOULD_BE_ROUND_TRIP_OR_ALL_PRODUCTS_SHOULD_BE_ONE_WAY, 'All products should be round trip or all products should be one way');
messagesMap.set(KioskConfigurationWarningTypeEnum.ROUTE__PRODUCT_PICKUP_LOCATION_IS_NOT_EQUAL_TO_PREVIOUS_PRODUCT_DROPOFF_LOCATION, 'Product pickup location is not equal to previous product dropoff location');
messagesMap.set(KioskConfigurationWarningTypeEnum.ROUTE__PRODUCT_PICKUP_LOCATION_IS_NOT_ACTIVE, 'Product pickup location is not active');
messagesMap.set(KioskConfigurationWarningTypeEnum.ROUTE__PRODUCT_DROPOFF_LOCATION_IS_NOT_ACTIVE, 'Product dropoff location is not active');
messagesMap.set(KioskConfigurationWarningTypeEnum.ROUTE__PRODUCT_PICKUP_LOCATION_IS_NOT_PART_OF_PICKUP_LOCATIONS_LIST, 'Product pickup location is not part of product pickup locations list');
messagesMap.set(KioskConfigurationWarningTypeEnum.ROUTE__PRODUCT_DROPOFF_LOCATION_IS_NOT_PART_OF_DROPOFF_LOCATIONS_LIST, 'Product dropoff location is not part of product dropoff locations list');

messagesMap.set(KioskConfigurationWarningTypeEnum.ONE_WAY_TIER__NOT_ACTIVE, 'One Way Tier is not active');
messagesMap.set(KioskConfigurationWarningTypeEnum.ONE_WAY_TIER__PRICING_NOT_AVAILABLE_FOR_PRODUCT_AND_TIER, 'One Way Tier pricing is not available for product and tier');

messagesMap.set(KioskConfigurationWarningTypeEnum.ROUND_TRIP_TIER__NOT_ACTIVE, 'Round Trip Tier is not active');
messagesMap.set(KioskConfigurationWarningTypeEnum.ROUND_TRIP_TIER__PRICING_NOT_AVAILABLE_FOR_PRODUCT_AND_TIER, 'Round Trip Tier pricing is not available for product and tier');

messagesMap.set(KioskConfigurationWarningTypeEnum.PASS__NOT_ACTIVE, 'Pass is not active');
messagesMap.set(KioskConfigurationWarningTypeEnum.PASS__NOT_VALID_FOR_ALL_PRODUCTS_IN_ROUTE, 'Pass is not valid for all products in route');
messagesMap.set(KioskConfigurationWarningTypeEnum.PASS__TIER_NOT_ACTIVE, 'Pass tier is not active');
messagesMap.set(KioskConfigurationWarningTypeEnum.PASS__PRICING_NOT_AVAILABLE_FOR_TIER, 'Pass pricing is not available for tier');


export function getKioskConfigurationWarningTypeEnumDescription(warningType: KioskConfigurationWarningTypeEnum) {
    if (!messagesMap.has(warningType)) {
        return warningType + ' ####### MESSAGE_NOT_DEFINED #######';
    }
    return messagesMap.get(warningType);
}
