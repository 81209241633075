export class CreateLocationRequestModel {
    description: string;
    zipCode: string;
    latitude: number;
    longitude: number;

    constructor(description: string, zipCode: string, latitude: number, longitude: number) {
        this.description = description;
        this.zipCode = zipCode;
        this.latitude = latitude;
        this.longitude = longitude;
    }
}
