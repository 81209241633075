import {NgModule} from "@angular/core";
import {EditFavoriteDialogComponent} from "./container/edit-favorite-dialog.component";
import {SharedModule} from "../../../shared.module";
import {EditFavoriteDialogFormComponent} from './components/edit-favorite-dialog-form/edit-favorite-dialog-form.component';
import {StoreModule} from "@ngrx/store";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {EditFavoriteDialogSandbox} from "./sandbox/edit-favorite-dialog.sandbox";
import {OrderReportsModule} from "../../../../modules/secured/reports/general/order-reports/order-reports.module";
import {CommissionReportModule} from "../../../../modules/secured/reports/financial/commission-report/commission-report.module";
import {DriverManifestReportModule} from "../../../../modules/secured/reports/operational/driver-manifest-report/driver-manifest-report.module";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer),
        OrderReportsModule,
        CommissionReportModule,
        DriverManifestReportModule
    ],
    providers: [
        EditFavoriteDialogSandbox
    ],
    declarations: [
        EditFavoriteDialogComponent,
        EditFavoriteDialogFormComponent,
    ],
    entryComponents: [
        EditFavoriteDialogComponent
    ]
})
export class EditFavoriteDialogModule {
}

