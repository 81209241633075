import {OccurrenceTicketsResponseModel} from "../../../../../../../../../core/modules/rest/occurrence/response/occurrence-tickets-response.model";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import * as actions from './actions';

export const REDUCER_NAME: string = "dialog_occurrences_manifest";

export interface State {

    occurrenceTickets: OccurrenceTicketsResponseModel;
}

const INITIAL_STATE: State = {

    occurrenceTickets: null
};

// -----------
// Selectors
// -----------

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const occurrenceTickets_selector = createSelector(state_selector, (state: State) => state.occurrenceTickets);

// ---------
// Reducer
// ---------

export function reducer(state: State = INITIAL_STATE, action: actions.All): State {

    switch (action.type) {
        case actions.SET_OCCURRENCE_TICKETS:
            return {
                ...state,
                occurrenceTickets: action.occurrenceTickets
            };

        default:
            return state;
    }
}
