import {IncludedCostResponseModel} from "../../../../../../../../core/modules/rest/cost/response/included-cost-response.model";
import {CostTypeEnum} from "../../../../../../../../shared/enums/cost-type.enum";
import {AmountTypeEnum} from "../../../../../../../../shared/enums/amount-type.enum";

export class IncludedCostModel implements IncludedCostResponseModel {
    costId: number;
    costType: CostTypeEnum;
    description: string;
    amount: number;
    amountType: AmountTypeEnum;
    glCode: string;
    active: boolean;

    constructor() {
    }
}
