import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {CategoryResponseModel} from "./response/category-response.model";
import {CategoryFullResponseModel} from "./response/category-full-response.model";
import {ActivateCategoryRequestModel} from "./request/activate-category-request.model";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {CreateCategoryRequestModel} from "./request/create-category-request.model";
import {UpdateCategoryBasicInfoRequestModel} from "./request/update-category-basic-info-request.model";
import {UpdateCategoryEmailHtmlTemplatesRequestModel} from "./request/update-category-email-html-templates-request.model";
import {UpdateCategoryCostsRequestModel} from "../cost/request/update-category-costs-request.model";
import {ProductCostResponseModel} from "../product/response/product-cost-response.model";

@Injectable()
export class CategoryRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<CategoryResponseModel[]> {
        return this.httpClient.get<CategoryResponseModel[]>(environment.server_api_url + '/api/secured/category/getAll');
    }

    getAllActive(): Observable<CategoryResponseModel[]> {
        return this.httpClient.get<CategoryResponseModel[]>(environment.server_api_url + '/api/secured/category/getAllActive');
    }

    getCategoriesByCurrentUser(): Observable<CategoryResponseModel[]> {
        return this.httpClient.get<CategoryResponseModel[]>(environment.server_api_url + '/api/secured/category/getCategoriesByCurrentUser');
    }

    getCategoriesByCurrentUserSortedByDescription(): Observable<CategoryResponseModel[]> {
        return this.httpClient.get<CategoryResponseModel[]>(environment.server_api_url + '/api/secured/category/getCategoriesByCurrentUserSortedByDescription');
    }

    getCategoryById(categoryId: number): Observable<CategoryResponseModel> {
        return this.httpClient.get<CategoryResponseModel>(environment.server_api_url + '/api/secured/category/getCategoryById/' + categoryId);
    }

    getCategoryFullById(categoryId: number): Observable<CategoryFullResponseModel> {
        return this.httpClient.get<CategoryFullResponseModel>(environment.server_api_url + '/api/secured/category/getCategoryFullById/' + categoryId);
    }

    getCategoryCostsByCategoryId(categoryId: number): Observable<ProductCostResponseModel[]> {
        return this.httpClient.get<ProductCostResponseModel[]>(environment.server_api_url + '/api/secured/category/getCategoryCostsByCategoryId/' + categoryId);
    }

    create(request: CreateCategoryRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + "/api/secured/category/create", request);
    }

    updateCategoryBasicInfo(request: UpdateCategoryBasicInfoRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/category/updateCategoryBasicInfo', request, {responseType: 'text'});
    }

    updateCategoryEmailHtmlTemplates(request: UpdateCategoryEmailHtmlTemplatesRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/category/updateCategoryEmailHtmlTemplates', request, {responseType: 'text'});
    }

    updateCategoryCosts(request: UpdateCategoryCostsRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/category/updateCategoryCosts', request, {responseType: 'text'});
    }

    activate(request: ActivateCategoryRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/category/activate', request, {responseType: 'text'});
    }

    deactivate(categoryId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/category/deactivate', categoryId, {responseType: 'text'});
    }
}
