import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {ProductAvailabilityRestService} from "../../../../../../../../core/modules/rest/product-availability/product-availability-rest.service";
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {ProductAvailabilityResponseModel} from "../../../../../../../../core/modules/rest/product-availability/response/product-availability-response.model";
import {SubmitCloneProductAvailabilitiesData} from "../components/clone-product-availabilities-dialog-form/clone-product-availabilities-dialog-form.component";
import {CloneProductAvailabilityDataRequestModel} from "../../../../../../../../core/modules/rest/product-availability/request/clone-product-availability-data-request.model";
import moment from "moment";

@Injectable()
export class CloneProductAvailabilitiesDialogSandbox extends DialogSandbox {

    productAvailabilities$: Observable<ProductAvailabilityResponseModel[]> = this.store.pipe(select(reducer.productAvailabilities_selector));

    constructor(appDialogsService: AppDialogsService,
                private productAvailabilityRestService: ProductAvailabilityRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(productAvailabilityIds: number[]) {

        this.productAvailabilityRestService.getProductAvailabilitiesByProductAvailabilityIds(productAvailabilityIds)
            .subscribe((productAvailabilities: ProductAvailabilityResponseModel[]) => {
                this.store.dispatch(new actions.SetProductAvailabilities(productAvailabilities));
            });
    }

    cloneProductAvailabilities(data: SubmitCloneProductAvailabilitiesData): Observable<any> {

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return this.productAvailabilityRestService.cloneProductAvailabilities({
            startDateMS: data.startDate ? moment.parseZone(data.startDate, timeZone).valueOf() : null,
            endDateMS: data.endDate ? moment.parseZone(data.endDate, timeZone).valueOf() : null,
            productAvailabilitiesData: data.productAvailabilitiesData.map(pa => new CloneProductAvailabilityDataRequestModel(pa.productAvailabilityId, pa.description))
        });
    }
}
