import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {PassSimpleResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {FormControl, FormGroup} from "@angular/forms";

export interface SubmitPassEmailHtmlTemplatesData {
    passId: number;
    createOrderEmailHtmlTemplateId: number;
    voidOrderEmailHtmlTemplateId: number;
    termsAndConditionsEmailHtmlTemplateId: number;
}

@Component({
    selector: 'app-edit-pass-email-html-templates-dialog-form',
    templateUrl: './edit-pass-email-html-templates-dialog-form.component.html'
})

export class EditPassEmailHtmlTemplatesDialogFormComponent implements OnChanges {

    @Input() pass: PassSimpleResponseModel;
    @Input() createOrderEmailHtmlTemplates: DropdownOptionModel[];
    @Input() voidOrderEmailHtmlTemplates: DropdownOptionModel[];
    @Input() termsAndConditionsEmailHtmlTemplates: DropdownOptionModel[];

    @Output() submit: EventEmitter<SubmitPassEmailHtmlTemplatesData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    constructor() {

        this.form = new FormGroup({
            createOrderEmailHtmlTemplateId: new FormControl(),
            voidOrderEmailHtmlTemplateId: new FormControl(),
            termsAndConditionsEmailHtmlTemplateId: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pass) {
            this.initializeFormData();
        }
    }

    onSubmit() {

        let createOrderEmailHtmlTemplateId: number = this.form.value.createOrderEmailHtmlTemplateId ? +this.form.value.createOrderEmailHtmlTemplateId : null;
        let voidOrderEmailHtmlTemplateId: number = this.form.value.voidOrderEmailHtmlTemplateId ? +this.form.value.voidOrderEmailHtmlTemplateId : null;
        let termsAndConditionsEmailHtmlTemplateId: number = this.form.value.termsAndConditionsEmailHtmlTemplateId ? +this.form.value.termsAndConditionsEmailHtmlTemplateId : null;

        this.submit.emit({
            passId: this.pass.passId,
            createOrderEmailHtmlTemplateId: createOrderEmailHtmlTemplateId,
            voidOrderEmailHtmlTemplateId: voidOrderEmailHtmlTemplateId,
            termsAndConditionsEmailHtmlTemplateId: termsAndConditionsEmailHtmlTemplateId
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {

        this.form.patchValue({
            createOrderEmailHtmlTemplateId: this.pass.createOrderEmailHtmlTemplateId ? this.pass.createOrderEmailHtmlTemplateId.toString() : null,
            voidOrderEmailHtmlTemplateId: this.pass.voidOrderEmailHtmlTemplateId ? this.pass.voidOrderEmailHtmlTemplateId.toString() : null,
            termsAndConditionsEmailHtmlTemplateId: this.pass.termsAndConditionsEmailHtmlTemplateId ? this.pass.termsAndConditionsEmailHtmlTemplateId.toString() : null
        });
    }
}
