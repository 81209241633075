import * as actions from './actions';
import { SaleGroupResponseModel } from '../../../../../../../core/modules/rest/sale-group/response/sale-group-response.model';

export interface State {
    saleGroups: SaleGroupResponseModel[];
    showInactiveSaleGroups: boolean;
}

const initialState: State = {
    saleGroups: [],
    showInactiveSaleGroups: false
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.UPDATE_SALE_GROUP_ACTION:
            return handleUpdateSaleGroupsAction(state, action);
        case actions.UPDATE_SHOW_INACTIVE_SALE_GROUP_ACTION:
            return handleUpdateShowInactiveSaleGroupsAction(state, action);
        default:
            return state;
    }
}


// #################################################### SELECTORS ####################################################
export const saleGroups_selector = (state: State) => state.saleGroups;
export const showInactiveSaleGroups_selector = (state: State) => state.showInactiveSaleGroups;


// #################################################### HANDLERS ####################################################
function handleUpdateSaleGroupsAction(state: State, action: actions.UpdateSaleGroupManagment) {
    return {
        ...state,
        saleGroups: action.saleGroups
    };
}

function handleUpdateShowInactiveSaleGroupsAction(state: State, action: actions.UpdateShowInactiveSaleGroup) {
    return {
        ...state,
        showInactiveSaleGroups: action.show
    };
}
