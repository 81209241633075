import {Action} from "@ngrx/store";
import {TierResponseModel} from "../../../../../../../../../core/modules/rest/tier/response/tier-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Tier :: MAIN] Reset State';
export const SET_TIER_ACTION = '[Dialog - Create/Edit Tier :: MAIN] Set Tier';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetTier implements Action {
    readonly type = SET_TIER_ACTION;
    constructor(public tier: TierResponseModel) {}
}

export type All = ResetState | SetTier;
