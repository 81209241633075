import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {CashoutEntrySandbox} from "../../sandbox/cashout-entry.sandbox";
import {AddNewEntryDialogContentComponent} from "./components/add-new-entry-dialog-content/add-new-entry-dialog-content.component";
import {AddNewEntryDialogComponent} from "./container/add-new-entry-dialog.component";
import {AddNewEntryDialogSandbox} from "./sandbox/add-new-entry-dialog.sandbox";

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        AddNewEntryDialogSandbox
    ],
    declarations: [
        AddNewEntryDialogComponent,
        AddNewEntryDialogContentComponent
    ],
    exports: [
        AddNewEntryDialogComponent
    ],
    entryComponents: [
        AddNewEntryDialogComponent
    ]
})
export class AddNewEntryDialogModule {

}
