import {Action} from "@ngrx/store";
import {ProductForRouteSetupResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-for-route-setup-response.model";
import {KioskConfigurationDropoffLocationProductModel} from "../../model/kiosk-configuration-dropoff-location-product.model";
import {KioskConfigurationWarningModel} from "../../../../model/kiosk-configuration-warning.model";
import {LocationListItemDescriptorModel} from "../../model/location-list-item-descriptor.model";

export const RESET_STATE_ACTION                                                                     = "[Dialog Kiosk Route Setup Dialog :: MAIN] Reset State";
export const SET_ALL_PRODUCTS                                                                       = "[Dialog Kiosk Route Setup Dialog :: MAIN] Set All Products";
export const SET_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCTS                                      = "[Dialog Kiosk Route Setup Dialog :: MAIN] Set Kiosk Configuration Dropoff Location Products";
export const SET_WARNINGS                                                                           = "[Dialog Kiosk Route Setup Dialog :: MAIN] Set Warnings";
export const UPDATE_PRODUCT_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT                        = "[Dialog Kiosk Route Setup Dialog :: MAIN] Update Product for Kiosk Configuration Dropoff Location Product]";
export const UPDATE_DIRECTION_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT                      = "[Dialog Kiosk Route Setup Dialog :: MAIN] Update Direction for Kiosk Configuration Dropoff Location Product]";
export const UPDATE_PICKUP_LOCATIONS_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT               = "[Dialog Kiosk Route Setup Dialog :: MAIN] Update Pickup Locations for Kiosk Configuration Dropoff Location Product]";
export const UPDATE_DROPOFF_LOCATIONS_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT              = "[Dialog Kiosk Route Setup Dialog :: MAIN] Update Dropoff Locations for Kiosk Configuration Dropoff Location Product]";
export const UPDATE_SELECTED_DROPOFF_LOCATION_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT      = "[Dialog Kiosk Route Setup Dialog :: MAIN] Update Selected Dropoff Location for Kiosk Configuration Dropoff Location Product]";
export const KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT_ADDED                                     = "[Dialog Kiosk Route Setup Dialog :: MAIN] Kiosk Configuration Dropoff Location Product Added";
export const KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT_DELETED                                   = "[Dialog Kiosk Route Setup Dialog :: MAIN] Kiosk Configuration Dropoff Location Product Deleted";

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetAllProducts implements Action {
    readonly type = SET_ALL_PRODUCTS;

    constructor(public allProducts: ProductForRouteSetupResponseModel[]) {
    }
}

export class SetKioskConfigurationDropoffLocationProducts implements Action {
    readonly type = SET_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCTS;

    constructor(public kioskConfigurationDropoffLocationProducts: KioskConfigurationDropoffLocationProductModel[]) {
    }
}

export class SetWarnings implements Action {
    readonly type = SET_WARNINGS;

    constructor(public warnings: KioskConfigurationWarningModel[]) {
    }
}

export class UpdateProductForKioskConfigurationDropoffLocationProduct implements Action {
    readonly type = UPDATE_PRODUCT_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT;

    constructor(public index: number, public product: ProductForRouteSetupResponseModel) {
    }
}

export class UpdateDirectionForKioskConfigurationDropoffLocationProduct implements Action {
    readonly type = UPDATE_DIRECTION_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT;

    constructor(public index: number, public isReturnTrip: boolean) {
    }
}

export class UpdatePickupLocationsForKioskConfigurationDropoffLocationProduct implements Action {
    readonly type = UPDATE_PICKUP_LOCATIONS_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT;

    constructor(public index: number, public locations: LocationListItemDescriptorModel[]) {
    }
}

export class UpdateDropoffLocationsForKioskConfigurationDropoffLocationProduct implements Action {
    readonly type = UPDATE_DROPOFF_LOCATIONS_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT;

    constructor(public index: number, public pickupLocationId: number, public locations: LocationListItemDescriptorModel[]) {
    }
}

export class UpdateSelectedDropoffLocationForKioskConfigurationDropoffLocationProduct implements Action {
    readonly type = UPDATE_SELECTED_DROPOFF_LOCATION_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT;

    constructor(public index: number, public dropoffLocationId: number) {
    }
}

export class KioskConfigurationDropoffLocationProductAdded implements Action {
    readonly type = KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT_ADDED;

    constructor() {
    }
}

export class KioskConfigurationDropoffLocationProductDeleted implements Action {
    readonly type = KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT_DELETED;

    constructor(public index: number) {
    }
}


export type All = ResetState
                | SetAllProducts
                | SetKioskConfigurationDropoffLocationProducts
                | SetWarnings
                | UpdateProductForKioskConfigurationDropoffLocationProduct
                | UpdateDirectionForKioskConfigurationDropoffLocationProduct
                | UpdatePickupLocationsForKioskConfigurationDropoffLocationProduct
                | UpdateDropoffLocationsForKioskConfigurationDropoffLocationProduct
                | UpdateSelectedDropoffLocationForKioskConfigurationDropoffLocationProduct
                | KioskConfigurationDropoffLocationProductAdded
                | KioskConfigurationDropoffLocationProductDeleted;

