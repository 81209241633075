import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {ProductRestService} from "../../../../../../../../core/modules/rest/product/product-rest.service";
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {ProductSimpleResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {SubmitProductFlagsData} from "../components/edit-product-flags-dialog-form/edit-product-flags-dialog-form.component";

@Injectable()
export class EditProductFlagsDialogSandbox extends DialogSandbox {

    product$: Observable<ProductSimpleResponseModel> = this.store.pipe(select(reducer.product_selector));

    constructor(appDialogsService: AppDialogsService,
                private productRestService: ProductRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(productId: number) {

        this.productRestService.getProductSimpleById(productId)
            .subscribe((product: ProductSimpleResponseModel) => {
                this.store.dispatch(new actions.SetProduct(product));
            });
    }

    updateProductFlags(data: SubmitProductFlagsData): Observable<any> {

        return this.productRestService.updateProductFlags({
            productId: data.productId,
            requireCheckIn: data.requireCheckIn,
            availableForSale: data.availableForSale,
            isFlexibleProduct: data.isFlexibleProduct,
            isRoundTripProduct: data.isRoundTripProduct,
            manualAcceptanceForFailedScanRequired: data.manualAcceptanceForFailedScanRequired,
            shouldSendBarcodeQRCodeImageAttachment: data.shouldSendBarcodeQRCodeImageAttachment,
            shouldPrintProductImageOnPdfTicket: data.shouldPrintProductImageOnPdfTicket
        });
    }
}
