import {LevelEnum, ValueTypeEnum} from '../response/report-search-response.model';
import {BaseExpressionRequestModel} from './base-expression-request.model';

export class ReportSumRequestModel {
    searchExpression: BaseExpressionRequestModel[];
    elastcsearchFieldName: string;
    fieldName: string;
    sumValueType: ValueTypeEnum;
    level: LevelEnum;

    constructor(searchExpression: BaseExpressionRequestModel[], elastcsearchFieldName, fieldName, sumValueType: ValueTypeEnum, level: LevelEnum) {
        this.searchExpression = searchExpression;
        this.elastcsearchFieldName = elastcsearchFieldName;
        this.fieldName = fieldName;
        this.sumValueType = sumValueType;
        this.level = level;
    }
}
