import {NgModule} from "@angular/core";
import {EditProductPricingDateRangesDialogSandbox} from "./sandbox/edit-product-pricing-date-ranges-dialog.sandbox";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {EditProductPricingDateRangesDialogFormComponent} from './components/edit-product-pricing-date-ranges-dialog-form/edit-product-pricing-date-ranges-dialog-form.component';
import {EditProductPricingDateRangesDialogComponent} from "./edit-product-pricing-date-ranges-dialog.component";
import {EditProductTierDialogFormComponent} from "./components/edit-product-pricing-date-ranges-dialog-form/edit-product-tier-dialog-form/edit-product-tier-dialog-form.component";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    declarations: [
        EditProductPricingDateRangesDialogComponent,
        EditProductPricingDateRangesDialogFormComponent,
        EditProductTierDialogFormComponent
    ],
    providers: [
        EditProductPricingDateRangesDialogSandbox
    ],
    exports: [
        EditProductPricingDateRangesDialogComponent
    ],
    entryComponents: [
        EditProductPricingDateRangesDialogComponent
    ]
})
export class EditProductPricingDateRangesDialogModule {
}
