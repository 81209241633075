import {Action} from "@ngrx/store";
import {PassSimpleResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export const RESET_STATE = '[Dialog - Edit Pass Email HTML Templates :: MAIN] Reset State';
export const SET_PASS = '[Dialog - Edit Pass Email HTML Templates :: MAIN] Set Pass';
export const SET_CREATE_ORDER_EMAIL_HTML_TEMPLATES = '[Dialog - Edit Pass Email HTML Templates :: MAIN] Set Create Order Email HTML Templates';
export const SET_VOID_ORDER_EMAIL_HTML_TEMPLATES = '[Dialog - Edit Pass Email HTML Templates :: MAIN] Set Void Order Email HTML Templates';
export const SET_TERMS_AND_CONDITIONS_EMAIL_HTML_TEMPLATES = '[Dialog - Edit Pass Email HTML Templates :: MAIN] Set Terms And Conditions Email HTML Templates';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetPass implements Action {
    readonly type = SET_PASS;

    constructor(public pass: PassSimpleResponseModel) {
    }
}

export class SetCreateOrderEmailHtmlTemplates implements Action {
    readonly type = SET_CREATE_ORDER_EMAIL_HTML_TEMPLATES;

    constructor(public createOrderEmailHtmlTemplates: DropdownOptionModel[]) {
    }
}

export class SetVoidOrderEmailHtmlTemplates implements Action {
    readonly type = SET_VOID_ORDER_EMAIL_HTML_TEMPLATES;

    constructor(public voidOrderEmailHtmlTemplates: DropdownOptionModel[]) {
    }
}

export class SetTermsAndConditionsEmailHtmlTemplates implements Action {
    readonly type = SET_TERMS_AND_CONDITIONS_EMAIL_HTML_TEMPLATES;

    constructor(public termsAndConditionsEmailHtmlTemplates: DropdownOptionModel[]) {
    }
}

export type All = ResetState
    | SetPass
    | SetCreateOrderEmailHtmlTemplates
    | SetVoidOrderEmailHtmlTemplates
    | SetTermsAndConditionsEmailHtmlTemplates;
