export class UpdateHolidayRequestModel {
    holidayId: number;
    description: string;
    year: number;
    month: number;
    day: number;

    constructor(holidayId: number, description: string, year: number, month: number, day: number) {
        this.holidayId = holidayId;
        this.description = description;
        this.year = year;
        this.month = month;
        this.day = day;
    }
}
