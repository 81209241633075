import {EditAdditionalCostDialogSandbox} from "../sandbox/edit-additional-cost-dialog.sandbox";
import {Observable} from "rxjs";
import {AdditionalCostModel} from "../model/additional-cost.model";
import {MultiselectDropdownOptionModel} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import { DialogData } from "../../../../../../../../shared/dialogs/model/dialog.data";
import { SystemPermissionsResponseModel } from "../../../../../../../../core/modules/rest/system-permissions/response/system-permissions-response.model";
import { SystemPermissionsRestService } from "../../../../../../../../core/modules/rest/system-permissions/system-permissions-rest.service";
import { SystemPermissionEnum } from "../../../../../../../../shared/enums/system-permission.enum";

export class EditAdditionalCostDialogData {

    costId: number;
    callbackSuccessFn: () => void;

    constructor(callbackSuccessFn: () => void, costId?: number) {
        this.callbackSuccessFn = callbackSuccessFn;
        this.costId = costId;
    }
}


@Component({
    selector: 'app-edit-additional-cost-dialog',
    templateUrl: './edit-additional-cost-dialog.component.html',
    styleUrls: ['./edit-additional-cost-dialog.component.scss']
})
export class EditAdditionalCostDialogComponent implements OnInit, OnDestroy {

    private costId: number;
    private callbackSuccessFn: () => void;

    dialogDataLoaded$: Observable<boolean>;
    editAdditionalCost$: Observable<boolean>;
    allLocations$: Observable<MultiselectDropdownOptionModel[]>;
    allPlatforms$: Observable<MultiselectDropdownOptionModel[]>;
    additionalCost$: Observable<AdditionalCostModel>;
    amountTypes$: Observable<MultiselectDropdownOptionModel[]>;
    errorMessage$: Observable<string>;

    constructor(@Inject(DialogData) dialogData: EditAdditionalCostDialogData, private sandbox: EditAdditionalCostDialogSandbox, private systemPermissionsRestService: SystemPermissionsRestService) {

        this.dialogDataLoaded$ = this.sandbox.getDialogDataLoaded();
        this.editAdditionalCost$ = this.sandbox.getEditAdditionalCost();
        this.allLocations$ = this.sandbox.getAllLocationsForMultiselectDropdown();
        this.allPlatforms$ = this.sandbox.getBasePlatformsForMultiselectDropdown();
        this.additionalCost$ = this.sandbox.getAdditionalCost();
        this.amountTypes$ = this.sandbox.getAmountTypesForMultiselectDropdown();
        this.errorMessage$ = this.sandbox.getErrorMessage();
        this.costId = dialogData.costId;
        this.callbackSuccessFn = dialogData.callbackSuccessFn;
    }

    ngOnInit() {
        this.sandbox.loadDialogData(this.costId);
        this.systemPermissionsRestService.getSystemPermissions().subscribe((systemPermissions: SystemPermissionsResponseModel[]) => {
            let useKiosk =  systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.KIOSK)?.active ?? false;
            let useCustomers =  systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.USE_CUSTOMERS)?.active ?? false;
            this.allPlatforms$ = this.sandbox.getAllPlatformsForMultiselectDropdown(useKiosk, useCustomers);
         });

    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onAdditionalCostChanged(additionalCost: AdditionalCostModel) {
        this.sandbox.updateAdditionalCostProperty(additionalCost);
    }

    onSubmit() {
        this.sandbox.save().subscribe(this.saveSuccessCallback.bind(this));
    }

    onDialogClosed() {
        this.sandbox.destroyDialog();
    }

    private saveSuccessCallback(success: boolean) {
        if (success) {
            this.callbackSuccessFn();
            this.sandbox.destroyDialog();
        }
    }
}
