<app-abstract-dialog-container
    [sizeClass]="'large'"
    [shouldOverrideStyle]="true"
    [classList]="'order-summary-dialog'">

    <ng-container alt-design>

        <div *ngIf="!canViewOrderSummary(currentUser$ | async)" class="order-summary-no-permission-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 125 125" style="enable-background:new 0 0 125 125;" xml:space="preserve">
                <style type="text/css">.st0{opacity:0.5;fill:#EFF2FC;}.st1{fill:#D3DAEF;}</style>
                <g>
                    <path class="st0" d="M96.7,98c-0.3-0.3-0.5-0.6-0.5-1c-4.1,3.3-9.3,5.2-14.6,5.2c-7.6,0-14.3-3.6-18.5-9.2h-3.3h-8.8H26.8   c-4.5,0-8.2-3.7-8.2-8.2V35.5c0-4.5,3.7-8.2,8.2-8.2h27.4l11,11h27.4c4.5,0,8.2,3.7,8.2,8.2v8.2h8.2c6.4,0,10.3,7,7,12.4l-7.6,12.6   l0,0l-1,1.7c0,0,0,0,0-0.1c-1.3,2-3.4,5.5-5.2,8.3c-0.6,1.1-1.3,2.2-2,3.2c-0.2,0.3-0.4,0.6-0.5,0.7c0.3,0.1,0.7,0.3,0.9,0.5   l9.2,9.2c9.4-10.9,15.2-25.2,15.2-40.8C125,28,97,0,62.5,0S0,28,0,62.5S28,125,62.5,125c16.9,0,32.3-6.7,43.5-17.7L96.7,98z"/>
                    <g>
                        <circle class="st1" cx="81.4" cy="88.3" r="2.3"/>
                        <path class="st1" d="M82.1,83.9h-1.3c-0.6,0-1-0.4-1-1v0c0-5.8,6.4-5.3,6.4-8.9c0-1.7-1.5-3.3-4.8-3.3c-2.4,0-3.7,0.8-4.9,2.4    c-0.3,0.4-0.9,0.5-1.4,0.2L74,72.5c-0.5-0.3-0.6-1-0.2-1.4c1.8-2.3,3.9-3.7,7.6-3.7c4.3,0,8.1,2.5,8.1,6.7c0,5.6-6.4,5.3-6.4,8.9    v0C83,83.5,82.6,83.9,82.1,83.9L82.1,83.9z"/>
                    </g>
                    <path class="st1" d="M108.9,54.6h-8.2v-8.2c0-4.5-3.7-8.2-8.2-8.2H65.1l-11-11H26.8c-4.5,0-8.2,3.7-8.2,8.2v49.3   c0,4.5,3.7,8.2,8.2,8.2h33c-1.1-1.7-2-3.5-2.7-5.5H26.2l14-23.4c1.5-2.5,4.2-4,7-4h16.4c2.5-2.4,5.5-4.2,8.8-5.5H47.9   c-4.8,0-9.3,2.5-11.7,6.6L24,81.4V35.5c0-1.5,1.2-2.7,2.7-2.7h25.1l11,11h29.7c1.5,0,2.7,1.2,2.7,2.7v8.2h-4.6   c3.3,1.2,6.3,3.1,8.8,5.5h9.5c2.1,0,3.4,2.3,2.3,4.1l-4.7,7.8c0.6,2.2,0.9,4.5,0.9,6.8c0,0.8,0,1.7-0.1,2.5l8.6-14.3   C119.2,61.6,115.3,54.6,108.9,54.6z"/>
                    <path class="st1" d="M114.6,108.1l-14-14c-0.3-0.3-0.6-0.5-1-0.5c3.3-4.1,5.2-9.3,5.2-14.6c0-12.8-10.4-23.2-23.2-23.2   S58.3,66.1,58.3,78.9c0,12.8,10.4,23.2,23.2,23.2c5.3,0,10.5-1.8,14.6-5.2c0.1,0.4,0.3,0.7,0.5,1l14,14c0.4,0.4,0.9,0.6,1.4,0.6   c0.5,0,1-0.2,1.4-0.6l1.1-1.1C115.4,110.1,115.4,108.8,114.6,108.1z M99.9,78.9c0,10.1-8.2,18.3-18.3,18.3   c-10.1,0-18.3-8.2-18.3-18.3c0-10.1,8.2-18.3,18.3-18.3C91.7,60.6,99.9,68.8,99.9,78.9z"/>
                </g>
            </svg>
            <h1>You don't have permission to view this page</h1>
            <p>Contact your administrator for necessary permissions.</p>
        </div>

        <div *ngIf="canViewOrderSummary(currentUser$ | async)">
            <div class="dialog-header">
                <h1>Order Summary</h1>
            </div>
            <div class="dialog-body">
                <div class="dialog-default">

                    <app-general-info-dialog-summary
                        [orderSummary]="orderSummary$ | async"
                        [hasEditPermission]="!!permissionService.getPermission(CAN_EDIT_ORDER_DETAILS)"
                        [hasCompletedEditPermission]="!!permissionService.getPermission(CAN_EDIT_COMPLETED_ORDER)"
                        [soldByUsers]="users$ | async"
                        [soldAtLocations]="locations$ | async"
                        (orderDataEdit)="onOrderDataEdit($event)">
                    </app-general-info-dialog-summary>

                    <app-product-details-dialog-summary
                        [allowFixedTimeForFlexibleAndMultipleCheckInProducts]="allowFixedTimeForFlexibleAndMultipleCheckInProducts$ | async"
                        [startTimesForEditedOrderDetailLoading]="startTimesForEditedOrderDetailLoading$ | async"
                        [startTimesForEditedOrderDetail]="startTimesForEditedOrderDetail$ | async"
                        [products]="products$ | async"
                        [orderDetails]="orderDetails"
                        [hasEditPermission]="!!permissionService.getPermission(CAN_EDIT_ORDER_DETAILS)"
                        [hasCompletedEditPermission]="!!permissionService.getPermission(CAN_EDIT_COMPLETED_ORDER)"
                        (orderDetailDataEdit)="onOrderDetailDataEdit($event)"
                        (noShowClicked)="onNoShowClicked($event)"
                        (selectedDateChanged)="onSelectedDateChanged($event)">
                    </app-product-details-dialog-summary>

                    <app-pass-details-dialog-summary
                        [passOrderDetails]="passOrderDetails"
                        [hasEditPermission]="!!permissionService.getPermission(CAN_EDIT_ORDER_DETAILS)"
                        [hasCompletedEditPermission]="!!permissionService.getPermission(CAN_EDIT_COMPLETED_ORDER)"
                        (passOrderDetailDataEdit)="onPassOrderDetailDataEdit($event)" *ngIf="!legacyOrder">
                    </app-pass-details-dialog-summary>
                
                    <app-payment-info-dialog-summary
                        [orderStatus]="(orderSummary$ | async).orderStatus"
                        [orderPayments]="(orderSummary$ | async).orderPayments"
                        [discounts]="(orderSummary$ | async).discount">
                    </app-payment-info-dialog-summary>
                
                    <app-printing-info-dialog-summary
                        [printingInfos]="(orderSummary$ | async).printingInfos" *ngIf="!legacyOrder">
                    </app-printing-info-dialog-summary>
            
                    <app-email-sending-info-dialog-summary
                        [emailSendingInfos]="(orderSummary$ | async).emailSendingInfos" *ngIf="!legacyOrder">
                    </app-email-sending-info-dialog-summary>
        
                    <app-voiding-info-dialog-summary
                        *ngIf="(!relatedOrders || relatedOrders.length === 0) && !legacyOrder"
                        [ngClass]="'no-related-orders'"
                        [voidingInfos]="(orderSummary$ | async).voidingInfos">
                    </app-voiding-info-dialog-summary>

                    <app-related-orders-info-dialog-summary
                        *ngIf="(orderSummary$ | async).relatedOrders && (orderSummary$ | async).relatedOrders.length !== 0"
                        [ngClass]="'related-orders-present'"
                        [relatedOrders]="(orderSummary$ | async).relatedOrders"
                        [parentOrder]="(orderSummary$ | async).parentOrder">
                    </app-related-orders-info-dialog-summary>
    
                </div>
            </div>

            <div class="dialog-footer" *ngIf="!legacyOrder">
                <div class="dialog-checkboxes-wrapper">
                    <app-checkbox [labelName]="'Do Not Print Receipt'">
                    </app-checkbox>
                    <app-checkbox [labelName]="'Print One Receipt Per Payment'">
                    </app-checkbox>
                    <app-checkbox [labelName]="'Print Signature Line on Receipt'">
                    </app-checkbox>
                    <app-checkbox [labelName]="'Print Using Image'">
                    </app-checkbox>
                </div>

                <app-action-buttons-dialog-summary
                    [user]="currentUser$ | async"
                    [orderSummary]="orderSummary$ | async"
                    [canPrintOnce]="!!permissionService.getPermission(CAN_PRINT_ORDER_ONCE)"
                    [canPrintTwice]="!!permissionService.getPermission(CAN_PRINT_ORDER_TWICE)"
                    [canPrintMultipleTimes]="!!permissionService.getPermission(CAN_PRINT_ORDER_MULTIPLE_TIMES)"
                    [canEmailOrder]="!!permissionService.getPermission(CAN_EMAIL_ORDER)"
                    [canVoidOrder]="!!permissionService.getPermission(CAN_VOID_ORDER)"
                    [canVoidCompletedOrder]="!!permissionService.getPermission(CAN_VOID_COMPLETED_ORDER)"
                    [canVoidOwnOrderInLast24Hours]="!!permissionService.getPermission(CAN_VOID_OWN_ORDER_IN_LAST_24_HOURS)"
                    [canVoidExpiredOrder]="!!permissionService.getPermission(CAN_VOID_EXPIRED_ORDER)"
                    [canPrintOrderSummary]="!!permissionService.getPermission(CAN_PRINT_ORDER_SUMMARY)"
                    (printOrderClick)="printOrderClick()"
                    (emailOrderClick)="sendEmailOrderClick()"
                    (printSummaryClick)="printSummaryClick()"
                    (voidOrderClick)="voidOrderClick()"
                    (cloneOrderClick)="cloneOrderClick()"
                    (reloadOrderSummary)="reloadOrderSummary()"
                    [canCloneOrder]="!!permissionService.getPermission(CAN_CLONE_ORDER)"
                    [canEditOrder]="!!permissionService.getPermission(CAN_EDIT_ORDER)"
                 *ngIf="!legacyOrder">
                </app-action-buttons-dialog-summary>
            </div>
        </div>
    </ng-container>
</app-abstract-dialog-container>
