

<div class="selectedFieldsArea_wrapper">
    <div class="detectLeftEdge"
         appMousePointerNear [disabled]="!dropAreasVisible" [distance]="50" (pointerNearEvent)="onMousePointerNearLeftEdge($event)">
    </div>


    <div class="selectedFieldsArea row-padding"
        [ngClass]="{'active': dropAreasVisible,
                    'disabled': !dropAreasVisible && draggedData}"
        #selectedFieldsArea>


        <div class="emptyState"
             *ngIf="selectedFields.length === 0 && !dropAreasVisible">
            <h3>Display Data</h3>
            <p class="font-black">
                Drag and drop items from list which you want to display in the result.
            </p>
        </div>


        <ul class="selectedFieldsList"
            [class.remove]="selectedFields.length === 0  && !dropAreasVisible">

            <app-order-reports-field-drop-area
                [enabled]="dropAreasVisible && !isFirstFieldItemDragged()"
                [visible]="dropAreasVisible && selectedFields.length === 0"
                [leftSibling_queryFieldName]="null"
                (droppedFieldItemEvent)="onDroppedFieldItem($event)"
                (dragEndEvent)="onDragEnd()">
            </app-order-reports-field-drop-area>


            <ng-container
                *ngFor="let fieldItem of selectedFields; let index = index">
                <app-order-reports-field-item
                    [fieldItem]="fieldItem"
                    [hasRunOrderReportFullPermission]="hasRunOrderReportFullPermission"
                    [draggedData]="draggedData"
                    [dropAreasVisible]="dropAreasVisible"
                    (dragStartEvent)="onDragStart($event)"
                    (dragEndEvent)="onDragEnd()"
                    (deleteFieldItemEvent)="onDeleteFieldItem($event)">
                </app-order-reports-field-item>



                <app-order-reports-field-drop-area
                    [enabled]="dropAreasVisible && !isThisFieldItemCurrentlyInDrag(fieldItem) && !isNextFieldItemCurrentlyInDrag(index)"
                    [leftSibling_queryFieldName]="fieldItem.queryFieldName"
                    (droppedFieldItemEvent)="onDroppedFieldItem($event)"
                    (dragEndEvent)="onDragEnd()">
                </app-order-reports-field-drop-area>
            </ng-container>
        </ul>
    </div>



    <div class="detectRightEdge"
         appMousePointerNear [disabled]="!dropAreasVisible" [distance]="50" (pointerNearEvent)="onMousePointerNearRightEdge($event)">
    </div>



    <app-order-reports-fields-info-message
        *ngIf="selectedFields.length < 2">
    </app-order-reports-fields-info-message>
</div>
