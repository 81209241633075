<app-accordion>
    <app-accordion-item>
        <div class="accordion--item--header accordionItemHeader">
            Auto Applied Discounts
        </div>

        <div class="accordion--item--body accordionItemBody">

            <div class="table_wrapper">
                <div class="table_caption">
                    <!-- Table Header :: BEGIN -->
                    <div class="text-right">
                        <div class="toolboxButtons_container">
                            <ul class="toolboxButtons_list">
                                <li class="toolboxButtons_li">

                                    <a class="icon_button"
                                       (click)="addNewAutoAppliedDiscountClick()">
                                        <div class="icon_container">
                                            <i class="fal fa-plus-circle"></i>
                                        </div>
                                        Add Auto Applied Discount
                                    </a>
                                </li>


                                <li class="toolboxButtons_li">
                                    <app-switch
                                        [switchLabel]="'Show Inactive'"
                                        [value]="showInactiveAutoAppliedDiscounts"
                                        (switchValueChanged)="onShowInactiveAutoAppliedDiscounts($event)">
                                    </app-switch>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div><!-- Table Header :: END -->


                <app-auto-applied-discounts-table
                    *ngIf="autoAppliedDiscounts.length !== 0"
                    [autoAppliedDiscounts]="autoAppliedDiscounts"
                    [inactiveShown]="inactiveShown"
                    (mappingAutoAppliedDiscountEvent)="onMappingAutoAppliedDiscount($event)"
                    (editAutoAppliedDiscountEvent)="onEditAutoAppliedDiscount($event)"
                    (editAutoAppliedDiscountActivityEvent)="onEditAutoAppliedDiscountActivity($event)"
                    (priorityChanged)="onAutoAppliedDiscountPriorityChanged($event)">
                </app-auto-applied-discounts-table>

            </div>


            <!-- Empty State component :: BEGIN -->
            <div class="grid-x grid-margin-x"
                 *ngIf="autoAppliedDiscounts.length === 0">
                <div class="medium-12 cell">

                    <div class="emptyState_wrapper">
                        <div class="emptyState_container">
                            <img src="{{emptyStateIcon}}" alt="" class="emptyStateImage">
                            <h1>No Auto Applied Discounts to Display!</h1>
                            <p>To add new Auto Applied Discount click on </p>
                            <p>Add New Auto Applied Discount</p>
                        </div>
                    </div>
                </div>
            </div><!-- Empty State component :: END -->

        </div>
    </app-accordion-item>
</app-accordion>
