import { Component, Inject, OnDestroy, OnInit, ViewContainerRef  } from '@angular/core';
import {EditIncludedCostDialogSandbox} from "../sandbox/edit-included-cost-dialog.sandbox";
import {Observable} from "rxjs";
import {MultiselectDropdownOptionModel} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import {IncludedCostModel} from "../model/included-cost.model";
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";

export class EditIncludedCostDialogData extends DialogData {
    costId: number;
    callbackSuccessFn: () => void;

    constructor(callbackSuccessFn: () => void, costId?: number) {
        super();
        this.callbackSuccessFn = callbackSuccessFn;
        this.costId = costId;
    }
}


@Component({
    selector: 'app-edit-included-cost-dialog',
    templateUrl: './edit-included-cost-dialog.component.html'
})
export class EditIncludedCostDialogComponent implements OnInit, OnDestroy {

    private costId: number;
    private callbackSuccessFn: () => void;

    dialogDataLoaded$: Observable<boolean>;
    editIncludedCost$: Observable<boolean>;
    allLocations$: Observable<MultiselectDropdownOptionModel[]>;
    includedCost$: Observable<IncludedCostModel>;
    amountTypes$: Observable<MultiselectDropdownOptionModel[]>;
    errorMessage$: Observable<string>;

    constructor(@Inject(DialogData) dialogData: EditIncludedCostDialogData, private sandbox: EditIncludedCostDialogSandbox) {

        this.costId = dialogData.costId;
        this.callbackSuccessFn = dialogData.callbackSuccessFn;

        this.dialogDataLoaded$ = this.sandbox.getDialogDataLoaded();
        this.editIncludedCost$ = this.sandbox.getEditIncludedCost();
        this.allLocations$ = this.sandbox.getAllLocationsForMultiselectDropdown();
        this.includedCost$ = this.sandbox.getIncludedCost();
        this.amountTypes$ = this.sandbox.getAmountTypesForMultiselectDropdown();
        this.errorMessage$ = this.sandbox.getErrorMessage();
    }

    ngOnInit() {
        this.sandbox.loadDialogData(this.costId);
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onIncludedCostChanged(includedCost: IncludedCostModel) {
        this.sandbox.updateIncludedCostProperty(includedCost);
    }

    onSubmit() {
        this.sandbox.save().subscribe(this.saveSuccessCallback.bind(this));
    }

    onClose() {
        this.sandbox.destroyDialog();
    }

    private saveSuccessCallback(success: boolean) {
        if (success) {
            this.callbackSuccessFn();
            this.sandbox.destroyDialog();
        }
    }
}
