import {Action} from "@ngrx/store";
import {ProductCostResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-cost-response.model";
import {MultiselectDropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

export const RESET_STATE = '[Dialog - Edit Category Costs :: MAIN] Reset State';
export const SET_LOADING = '[Dialog - Edit Category Costs :: MAIN] Loading';
export const SET_CATEGORY_ADDITIONAL_COSTS = '[Dialog - Edit Category Costs :: MAIN] Set Category Additional Costs';
export const SET_ADDITIONAL_COSTS = '[Dialog - Edit Category Costs :: MAIN] Set Additional Costs';
export const SET_CATEGORY_INCLUDED_COSTS = '[Dialog - Edit Category Costs :: MAIN] Set Category Included Costs';
export const SET_INCLUDED_COSTS = '[Dialog - Edit Category Costs :: MAIN] Set Included Costs';
export const SET_CATEGORY_DISCOUNTS = '[Dialog - Edit Category Costs :: MAIN] Set Category Discounts';
export const SET_DISCOUNTS = '[Dialog - Edit Category Costs :: MAIN] Set Discounts';
export const SET_CATEGORY_AUTO_APPLIED_DISCOUNTS = '[Dialog - Edit Category Costs :: MAIN] Set Category Auto Applied Discounts';
export const SET_AUTO_APPLIED_DISCOUNTS = '[Dialog - Edit Category Costs :: MAIN] Set Auto Applied Discounts';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetLoading implements Action {
    readonly type = SET_LOADING;
    constructor(public loading: boolean) {}
}

export class SetCategoryAdditionalCosts implements Action {
    readonly type = SET_CATEGORY_ADDITIONAL_COSTS;

    constructor(public categoryAdditionalCosts: ProductCostResponseModel[]) {
    }
}

export class SetAdditionalCosts implements Action {
    readonly type = SET_ADDITIONAL_COSTS;

    constructor(public additionalCosts: MultiselectDropdownOptionModel[]) {
    }
}

export class SetCategoryIncludedCosts implements Action {
    readonly type = SET_CATEGORY_INCLUDED_COSTS;

    constructor(public categoryIncludedCosts: ProductCostResponseModel[]) {
    }
}

export class SetIncludedCosts implements Action {
    readonly type = SET_INCLUDED_COSTS;

    constructor(public includedCosts: MultiselectDropdownOptionModel[]) {
    }
}

export class SetCategoryDiscounts implements Action {
    readonly type = SET_CATEGORY_DISCOUNTS;

    constructor(public categoryDiscounts: ProductCostResponseModel[]) {
    }
}

export class SetDiscounts implements Action {
    readonly type = SET_DISCOUNTS;

    constructor(public discounts: MultiselectDropdownOptionModel[]) {
    }
}

export class SetCategoryAutoAppliedDiscounts implements Action {
    readonly type = SET_CATEGORY_AUTO_APPLIED_DISCOUNTS;

    constructor(public categoryAutoAppliedDiscounts: ProductCostResponseModel[]) {
    }
}

export class SetAutoAppliedDiscounts implements Action {
    readonly type = SET_AUTO_APPLIED_DISCOUNTS;

    constructor(public autoAppliedDiscounts: MultiselectDropdownOptionModel[]) {
    }
}

export type All = ResetState
    | SetLoading
    | SetCategoryAdditionalCosts
    | SetAdditionalCosts
    | SetCategoryIncludedCosts
    | SetIncludedCosts
    | SetCategoryDiscounts
    | SetDiscounts
    | SetCategoryAutoAppliedDiscounts
    | SetAutoAppliedDiscounts;
