import {CustomFieldScopeEnum} from "../../../../../../shared/enums/custom-field-scope.enum";
import {TemplateItemResponseModel} from "../../../../../../core/modules/rest/template/response/template-item-response.model";
import {CustomFieldTypeEnum} from "../../../../../../shared/enums/custom-field-type.enum";

export class TemplateItemDataModel {
    templateId: number;
    templateItemId: number;
    active: boolean;
    locationListId: number;
    description: string;
    fieldType: CustomFieldTypeEnum;
    fieldScope: CustomFieldScopeEnum;
    required: boolean;
    sortOrder: number;
    defaultValue: string;
    displayOnTicket: boolean;

    constructor(templateItem: TemplateItemResponseModel) {
        this.templateId = templateItem.templateId;
        this.templateItemId = templateItem.templateItemId;
        this.active = templateItem.active;
        this.locationListId = templateItem.locationListId;
        this.description = templateItem.description;
        this.fieldType = templateItem.fieldType;
        this.fieldScope = templateItem.fieldScope;
        this.required = templateItem.required;
        this.sortOrder = templateItem.sortOrder;
        this.defaultValue = templateItem.defaultValue;
        this.displayOnTicket = templateItem.displayOnTicket;
    }
}
