import * as actions from './actions';
import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";
import {createFeatureSelector, createSelector} from "@ngrx/store";


export const REDUCER_NAME = "dialog_create-kiosk-configuration-details-dropoff-location";

export interface State {
    dropoffLocations: LocationResponseModel[];
}

const initialState: State = {
    dropoffLocations: []
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const dropoffLocations_selector = createSelector(state_selector, (state: State) => state.dropoffLocations);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_DROPOFF_LOCATIONS: return { ... state, dropoffLocations: action.dropoffLocations };

        default: return state;
    }
}

