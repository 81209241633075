import {Action} from "@ngrx/store";
import {RoleResponseModel} from "../../../../../../../../../core/modules/rest/role/response/role-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {UserResponseModel} from "../../../../../../../../../core/modules/rest/user/response/user-response.model";

export const RESET_STATE = '[Dialog - Create/Edit User/Role :: MAIN] Reset State';
export const SET_USER = '[Dialog - Create/Edit User/Role :: MAIN] Set User';
export const SET_ROLE = '[Dialog - Create/Edit User/Role] Set Role';
export const SET_ROLES = '[Dialog - Create/Edit User/Role] Set Roles';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetUser implements Action {
    readonly type = SET_USER;

    constructor(public user: UserResponseModel) {
    }
}

export class SetRole implements Action {
    readonly type = SET_ROLE;

    constructor(public role: RoleResponseModel) {
    }
}

export class SetRoles implements Action {
    readonly type = SET_ROLES;

    constructor(public roles: RoleResponseModel[]) {
    }
}

export type All = ResetState
    | SetUser
    | SetRole
    | SetRoles;
