import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild  } from '@angular/core';

import {
    CellValueResponseModel,
    HeaderResponseModel,
    LevelEnum,
    RowResponseModel,
    ValueTypeEnum
} from '../../../../../../../core/modules/rest/reporting/response/report-search-response.model';
import {DecimalPipe} from '@angular/common';
import {SortByModel} from '../../../../../../../shared/model/sort-by.model';
import SimpleBar from 'simplebar';

declare var $: any;



@Component({
    selector: 'app-order-reports-table-element',
    templateUrl: './order-reports-table-element.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrderReportsTableElementComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

    // Infinite Scroll
    scrollThrottle = 150;

    @Input() headers: HeaderResponseModel[];
    @Input() rows: RowResponseModel[] = [];
    @Input() level: LevelEnum;
    @Input() groupSearch: boolean;
    @Input() summableFieldsExist: boolean;
    @Input() sortBy: SortByModel;

    @Output() headerSortEvent: EventEmitter<HeaderResponseModel> = new EventEmitter();
    @Output() showSummaryEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() scrollTable: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('tableContainer') tableContainer;

    activeRow = undefined;

    scrollBar: SimpleBar;
    scrollContainer = document.getElementsByClassName('mainContent_wrapper')[0];
    simplebarContent: HTMLElement;
    positionHorizontalScrollBarHandler: any;
    scrollTableHandler: any;
    headersToShowNumber: number;

    private decimalPipe = new DecimalPipe('en');

    private formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });

    constructor() {
    }

    ngOnInit() {
        this.headersToShowNumber = this.headers.length;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.headers) {
            if (this.headersToShowNumber != this.headers.length) {
                for (let row in this.rows) {
                    this.iterateRows(row);
                }
                this.headersToShowNumber = this.headers.length;
            }
        }
    }

    ngOnDestroy(): void {
        this.scrollBar = null;
        this.scrollContainer.removeEventListener('scroll', this.positionHorizontalScrollBarHandler);
        if ( this.simplebarContent ) {
            this.simplebarContent.removeEventListener('scroll', this.scrollTableHandler);
        }
        this.positionHorizontalScrollBarHandler = null;
        this.scrollTableHandler = null;
    }

    ngAfterViewInit() {
        this.positionHorizontalScrollBarHandler = this.positionHorizontalScrollBar.bind(this);
        this.scrollTableHandler = this.onScrollTable.bind(this);
        this.scrollContainer.addEventListener('scroll', this.positionHorizontalScrollBarHandler);

        // Initialization of horizontal scroll bar needs to be delayed because table header renders "too late"
        let that = this;
        setTimeout(() => {
            that.scrollBar = new SimpleBar(that.tableContainer.nativeElement, {
                autoHide: false,
            });
            that.scrollBar.recalculate();

            that.addScrollEventOnTableContainer();
            that.positionHorizontalScrollBar();
        }, 300);
    }

    ngAfterViewChecked(): void {
        this.positionHorizontalScrollBar();
    }

    positionHorizontalScrollBar() {

        const windowH = window.innerHeight;
        const tableBottom = this.tableContainer.nativeElement.getBoundingClientRect().bottom;
        const horizontalScroll = this.tableContainer.nativeElement.querySelector('.simplebar-horizontal');

        if (horizontalScroll !== undefined) {
            if (windowH < tableBottom) {
                $(horizontalScroll).offset({
                    top: windowH - 15
                });
            } else {
                $(horizontalScroll).offset({
                    top: tableBottom + 2
                });
            }
        }
    }

    activateTableRow(row: RowResponseModel) {
        this.activeRow = row;
    }

    clickOutsideActiveRow() {
        this.activeRow = undefined;
    }

    showSummary(row: RowResponseModel) {
        this.showSummaryEvent.emit(+row.rowMeta['orderId']);
    }

    headerToRight(header: HeaderResponseModel): boolean {
        return header.valueType === ValueTypeEnum.DOLLARS;
    }

    columnToRight(cell: CellValueResponseModel, index: number): boolean {
        const valueType: ValueTypeEnum = this.headers[index].valueType;
        return valueType === ValueTypeEnum.DOLLARS;
    }

    sortByHeaderClick(header: HeaderResponseModel) {
        if (!header.sortable) {
            return;
        }

        if (this.groupSearch) {
            return;
        }

        this.headerSortEvent.emit(header);
    }

    getCellValueForPrinting(cell: CellValueResponseModel, index: number): string {
        const valueType: ValueTypeEnum = this.headers[index].valueType;
        if (valueType === ValueTypeEnum.DOLLARS) {
            return this.formatter.format(cell.value);
        } else if (valueType === ValueTypeEnum.LONG) {
            return this.decimalPipe.transform(cell.value);
        } else {
            return cell.value;
        }
    }

    addScrollEventOnTableContainer() {
        this.simplebarContent = this.scrollBar.getScrollElement()
        this.simplebarContent.addEventListener('scroll', this.scrollTableHandler);

    }

    onScrollTable() {
        const verticalScrollBottom = this.tableContainer.nativeElement.querySelector('.simplebar-vertical > div').getBoundingClientRect().bottom;
        const tableContainerBottom = this.tableContainer.nativeElement.getBoundingClientRect().bottom;

        if (tableContainerBottom - verticalScrollBottom < this.scrollThrottle) {
            this.scrollTable.emit();
        }
    }

    private iterateRows(row: string) {
        if (this.rows[row].cellValues.length != this.headers.length) {
            let emptyRow = {
                cellValueMeta: null,
                header: null,
                type: null,
                value: ""
            }
            this.rows[row].cellValues.push(emptyRow);
            this.iterateRows(row);
        }
    }
}
