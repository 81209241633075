import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-edit-quick-selling-button-product-tier-form',
    templateUrl: './edit-quick-selling-button-product-tier-form.component.html',
    styleUrls: ['./edit-quick-selling-button-product-tier-form.component.scss']
})
export class EditQuickSellingButtonProductTierFormComponent implements OnInit {

    @Input() tierWithQuantityForm: FormGroup;

    constructor() {}

    ngOnInit() {
        this.tierWithQuantityForm.get('tierQuantity').valueChanges.subscribe(value => {
            // Convert value to number
            const numberValue = parseFloat(value);

            if (isNaN(numberValue)) {
                // If value is not a number, reset to null
                this.tierWithQuantityForm.get('tierQuantity').setValue(null, { emitEvent: false });
                return;
            }

            // Handle non-numeric values and enforce minimum and maximum values
            if (numberValue < 1) {
                this.tierWithQuantityForm.get('tierQuantity').setValue(1, { emitEvent: false });
            } else if (numberValue > 99) {
                this.tierWithQuantityForm.get('tierQuantity').setValue(99, { emitEvent: false });
            }
        });
    }
}
