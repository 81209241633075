import {Action} from "@ngrx/store";
import {LocationListFullResponseModel} from "../../../../../../../../../core/modules/rest/location-list/response/location-list-full-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Location List :: MAIN] Reset State';
export const SET_LOCATION_LIST_ACTION = '[Dialog - Create/Edit Location List :: MAIN] Set Location List';
export const SET_ALL_LOCATIONS_ACTION = '[Dialog - Create/Edit Location List :: MAIN] Set All Locations';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetLocationList implements Action {
    readonly type = SET_LOCATION_LIST_ACTION;
    constructor(public locationList: LocationListFullResponseModel) {}
}

export class SetAllLocations implements Action {
    readonly type = SET_ALL_LOCATIONS_ACTION;
    constructor(public allLocations: DropdownOptionModel[]) {}
}

export type All = ResetState
    | SetLocationList
    | SetAllLocations;
