<div class="category-and-product-dialog-content">
    <h1 class="mb-8px">Edit Product Templates</h1>
    <form class="pl-8px pr-8px" [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="order-form-item">
                    <label class="order-label">Terms & Conditions Template (HTML)</label>
                    <div class="order-input-wrapper">
                        <app-dropdown
                            class="main--element"
                            [customClasses]="'dropdown_large'"
                            [options]="termsAndConditionsEmailHtmlTemplates"
                            [preSelectedValue]="product.termsAndConditionsEmailHtmlTemplateId ? product.termsAndConditionsEmailHtmlTemplateId.toString() : null"
                            formControlName="termsAndConditionsEmailHtmlTemplateId">
                        </app-dropdown>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">Create Order Email Template (HTML)</label>
                    <div class="order-input-wrapper">
                        <app-dropdown
                            class="main--element"
                            [customClasses]="'dropdown_large'"
                            [options]="createOrderEmailHtmlTemplates"
                            [preSelectedValue]="product.createOrderEmailHtmlTemplateId ? product.createOrderEmailHtmlTemplateId.toString() : null"
                            formControlName="createOrderEmailHtmlTemplateId">
                        </app-dropdown>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">Void Order Email Template (HTML)</label>
                    <div class="order-input-wrapper">
                        <app-dropdown
                            class="main--element"
                            [customClasses]="'dropdown_large'"
                            [options]="voidOrderEmailHtmlTemplates"
                            [preSelectedValue]="product.voidOrderEmailHtmlTemplateId ? product.voidOrderEmailHtmlTemplateId.toString() : null"
                            formControlName="voidOrderEmailHtmlTemplateId">
                        </app-dropdown>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Update</button>
    </div>
</div>
