import * as actions from './actions';
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CustomerResponseModel } from '../../../../../../../../../core/modules/rest/customer/response/customer-response.model';

export const REDUCER_NAME = 'dialog_create/edit-customer';

export interface State {
    customer: CustomerResponseModel;
}

const initialState: State = {
    customer: {} as CustomerResponseModel
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const customer_selector = createSelector(state_selector, (state: State) => state.customer);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_CUSTOMER_ACTION: return { ... state, customer: action.customer };

        default: return state;
    }
}
