import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../../../../../core/store/reducers';

import * as fromMain from './main/reducer';
import * as fromUi from './ui/reducer';

export const REDUCER_NAME = 'feature_sale_group';

export interface FeatureState {
    ui: fromUi.State;
    main: fromMain.State;
}

export interface State extends fromRoot.AppState {
    REDUCER_NAME: FeatureState;
}

export const reducers: ActionReducerMap<FeatureState> = {
    ui: fromUi.reducer,
    main: fromMain.reducer
};


const saleGroupState_selector = createFeatureSelector<FeatureState>(REDUCER_NAME);


// ######### MAIN STATE #########
const mainState_selector = createSelector(saleGroupState_selector, (state: FeatureState) => state.main);

export const mainState_SaleGroup_selector = createSelector(mainState_selector, fromMain.saleGroups_selector);
export const mainState_DomainTypeSaleGroup_selector = createSelector(mainState_selector, fromMain.domainTypeSaleGroups_selector);
export const mainState_QSBPasses_selector = createSelector(mainState_selector, fromMain.qsbPasses_selector);
export const mainState_QSBProducts_selector = createSelector(mainState_selector, fromMain.qsbProducts_selector);
export const mainState_Customers_selector = createSelector(mainState_selector, fromMain.customers_selector);
export const mainState_Domains_selector = createSelector(mainState_selector, fromMain.domains_selector);
export const mainState_CurrentSeachData_selector = createSelector(mainState_selector, fromMain.current_search_data_selector)

// ######### UI STATE #########
const uiState_selector = createSelector(saleGroupState_selector, (state: FeatureState) => state.ui);

export const uiState_pageDataLoaded_selector = createSelector(uiState_selector, fromUi.pageDataLoaded_selector);



