const RemoteCommandEnum = {
    SEND_ANDROID_DB_BACKUP: 'SEND_ANDROID_DB_BACKUP' as 'SEND_ANDROID_DB_BACKUP',
    GET_AND_RESTORE_ANDROID_DB: 'GET_AND_RESTORE_ANDROID_DB' as 'GET_AND_RESTORE_ANDROID_DB',
    CLEAR_ANDROID_DEVICE_NUMBER_ORDER_COUNTER_PASS_CARD_COUNTER: 'CLEAR_ANDROID_DEVICE_NUMBER_ORDER_COUNTER_PASS_CARD_COUNTER' as 'CLEAR_ANDROID_DEVICE_NUMBER_ORDER_COUNTER_PASS_CARD_COUNTER',
    EXPORT_LOCALLY_ANDROID_DB_BACKUP: 'EXPORT_LOCALLY_ANDROID_DB_BACKUP' as 'EXPORT_LOCALLY_ANDROID_DB_BACKUP',
    FORCE_FULL_SYNCHRONIZATION: 'FORCE_FULL_SYNCHRONIZATION' as 'FORCE_FULL_SYNCHRONIZATION',
    SEND_ANDROID_LOGS_TO_S3: 'SEND_ANDROID_LOGS_TO_S3' as 'SEND_ANDROID_LOGS_TO_S3'
};
type RemoteCommandEnum = (typeof RemoteCommandEnum)[keyof typeof RemoteCommandEnum];
export {RemoteCommandEnum};
