import {Action} from "@ngrx/store";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export const RESET_STATE_ACTION = '[Dialog - Activate Role :: MAIN] Reset State';
export const SET_USERS_ACTION = '[Dialog - Activate Role :: MAIN] Set Users';

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class SetUsers implements Action {
    readonly type = SET_USERS_ACTION;

    constructor(public users: DropdownOptionModel[]) {
    }
}

export type All = ResetState | SetUsers;
