<app-abstract-dialog-container [shouldOverrideCloseClicked]="true" (closeClicked)="onClose()">
    <app-deactivate-occurrences-dialog-form
        [occurrencesOrders]="occurrencesOrders$ | async"
        [emailHtmlTemplates]="emailHtmlTemplates$ | async"
        (orderClicked)="onOrderClicked($event)"
        (submitClicked)="onSubmit($event)"
        (cancelClicked)="onClose()">
    </app-deactivate-occurrences-dialog-form>
</app-abstract-dialog-container>


