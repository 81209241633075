<app-abstract-dialog-container [shouldOverrideCloseClicked]="true" (closeClicked)="onCloseClicked()">
    <div class="form_container">
        <!-- Header -->
        <div class="header_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <h1>{{ header }}</h1>
                </div>
            </div>
        </div>

        <!-- Message -->
        <div class="content_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12 fs-16">
                    {{ message }}
                </div>
            </div>
        </div>

        <!-- Footer container -->
        <div class="footer_wrapper flex-container">
            <!-- Ok button -->
            <app-button class="m-auto"
                        [buttonType]="buttonType"
                        (clickEvent)="onCloseClicked()">
                {{ okLabel }}
            </app-button>
        </div>
    </div>
</app-abstract-dialog-container>
