<app-abstract-dialog-container>
    <app-post-bill-type-deposit
     [addMode]="addMode"
     [postBillType]="postBillType$ | async"
     (submitEvent)="onSubmit($event)"
     (closeEvent)="onClose()">
    </app-post-bill-type-deposit>
 </app-abstract-dialog-container>
 
 
 