import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {UUID} from "angular2-uuid";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageUploadComponent),
            multi: true,
        }
    ]
})
export class ImageUploadComponent implements ControlValueAccessor {

    @Input() file: ImageUploadFile;

    @Output() fileSelected: EventEmitter<ImageUploadFile> = new EventEmitter();

    @ViewChild('fileInput') fileInput: ElementRef;

    guid: string = UUID.UUID().toString();

    private _onChange: (val: ImageUploadFile) => void;

    private _onTouch: (val: ImageUploadFile) => void;

    constructor() {

    }

    onFileSelected(event: any) {

        let rawFile: File = <File>event.target.files[0];

        this.file = {
            file: rawFile,
            name: rawFile.name
        };

        this.fileSelected.emit(this.file);

        if (this._onChange) {
            this._onChange(this.file);
        }

        // HTML input element keeps previously selected file(s) and therefore does not behave properly
        //   (if selecting same file(s) again, HTML input element does not trigger this event)
        //   (if clicking "cancel" on file dialog, HTML input element triggers this event)
        //   clear "manually" previously selected file(s)
        this.fileInput.nativeElement.value = "";
    }

    onUploadClick() {
        this.fileInput.nativeElement.click();
    }

    writeValue(value: ImageUploadFile): void {
        this.file = value;
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }
}

export interface ImageUploadFile {
    name: string;
    file: File;
}
