<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? 'Edit Holiday' : 'Add Holiday' }}</h1>
            </div>
        </div>
    </div>


    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Description</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Description"
                               formControlName="description">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-4">
                    <app-input-label-group>
                        <label class="label">Year</label>
                        <app-dropdown class="main--element"
                                      [options]="yearOptions"
                                      formControlName="year">
                        </app-dropdown>
                    </app-input-label-group>
                </div>
                <div class="cell medium-4">
                    <app-input-label-group>
                        <label class="label">Month</label>
                        <app-dropdown class="main--element"
                                      [options]="monthOptions"
                                      formControlName="month">
                        </app-dropdown>
                    </app-input-label-group>
                </div>
                <div class="cell medium-4">
                    <app-input-label-group>
                        <label class="label">Day</label>
                        <app-dropdown class="main--element"
                                      [options]="dayOptions"
                                      formControlName="day">
                        </app-dropdown>
                    </app-input-label-group>
                </div>
            </div>
        </div>

    </div><!-- Dialog Content :: END -->

    <p *ngIf="hasError" class="error">{{ errorMessage }}</p>

    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>


            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
