<!-- TODO: Add tableScrollDirective to order reports table -->
<div class="table_container table-of-order-report"
     #tableContainer>

    <table class="table_striped"
           (clickOutside)="clickOutsideActiveRow()">

        <!-- Table Header :: BEGIN-->
        <thead>
        <th class="fixedWidthCell_s"
            *ngIf="!groupSearch">
        </th>

        <th class="tableHeaderColumn"
            *ngFor="let header of headers">

            <a class="tableHeaderColumnLink_container"
               [ngClass]="{'tableHeaderSortable': header.sortable && !groupSearch}"
               (click)="sortByHeaderClick(header)">

                <div class="tableHeaderColumnLabel">
                    {{ header.displayName }}


                    <!-- defaultSortIcon, sortAscending or sortDescending -->
                    <div class="sortIcon"

                         [class.defaultSortIcon]="!sortBy || sortBy.fieldName !== header.fieldName"
                         [class.sortAscending]="sortBy && sortBy.fieldName === header.fieldName && sortBy.order === 'ASC'"
                         [class.sortDescending]="sortBy && sortBy.fieldName === header.fieldName && sortBy.order === 'DESC'">
                        <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                        <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                        <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                    </div>

                </div>
            </a>
        </th>
        </thead><!-- Table Header :: END-->


        <!-- Summable Row :: BEGIN -->
        <tr class="tr_highlighted"
            *ngIf="summableFieldsExist">

            <td class="fixedWidthCell_s"
                *ngIf="!groupSearch">
            </td>

            <td
                *ngFor="let header of headers"
                [class.text-right]="headerToRight(header)">
                <app-sum-header-item
                    [level]="level"
                    [header]="header">
                </app-sum-header-item>
            </td>
        </tr><!-- Summable Row :: BEGIN -->


        <!-- Table Body :: BEGIN -->
        <tr *ngFor="let row of rows"
            (click)="activateTableRow(row)"
            [ngClass]="{'tr-active': activeRow == row}">

            <td class="fixedWidthCell_s"
                *ngIf="!groupSearch">
                <a class="icon_container tableIconContainer"
                   (click)="showSummary(row)">
                    <i class="fal fa-file-alt"></i>
                </a>
            </td>

            <td
                *ngFor="let cell of row.cellValues; let i = index"
                [class.text-right]="columnToRight(cell, i)">
                <span class="td_content">
                    {{ getCellValueForPrinting(cell, i) }}
                </span>
            </td>
        </tr><!-- Table Body :: END -->
    </table>
</div>

