<div class="category-and-product-dialog-content">
    <h1 class="mb-8px">Edit Product Options</h1>
    <form class="pl-8px pr-8px" [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="flex-container">
                    <div class="medium-6 mr-16px">
                        <div class="cell medium-12 mb-8px">
                            <app-checkbox
                                [labelName]="'Require Check-in'"
                                [formControlName]="'requireCheckIn'">
                            </app-checkbox>
                        </div>
                        <div class="cell medium-12 mb-8px">
                            <app-checkbox
                                [labelName]="'Enable for Purchase'"
                                [formControlName]="'availableForSale'">
                            </app-checkbox>
                        </div>
                        <div class="cell medium-12 mb-8px">
                            <app-checkbox
                                [labelName]="'Flexible Product'"
                                [formControlName]="'isFlexibleProduct'">
                            </app-checkbox>
                        </div>
                        <div class="cell medium-12 mb-8px">
                            <app-checkbox
                                [labelName]="'Round Trip Product'"
                                [formControlName]="'isRoundTripProduct'">
                            </app-checkbox>
                        </div>
                    </div>

                    <div class="medium-6 ml-8px">
                        <div class="cell medium-12 mb-8px">
                            <app-checkbox
                                [labelName]="'Manual Acceptance for Failed Scans'"
                                [formControlName]="'manualAcceptanceForFailedScanRequired'">
                            </app-checkbox>
                        </div>
                        <div class="cell medium-12 mb-8px">
                            <app-checkbox
                                [labelName]="'Attach QR Code Image'"
                                [formControlName]="'shouldSendBarcodeQRCodeImageAttachment'">
                            </app-checkbox>
                        </div>
                        <div class="cell medium-12 mb-8px">
                            <app-checkbox
                                [labelName]="'Print Product Image on PDF Ticket'"
                                [formControlName]="'shouldPrintProductImageOnPdfTicket'">
                            </app-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Update</button>
    </div>
</div>
