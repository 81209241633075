import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../../../../../core/store/reducers';

import * as fromMain from './main/reducer';
import * as fromUi from './ui/reducer';

export const REDUCER_NAME = 'feature_domain_managment';

export interface FeatureState {
    ui: fromUi.State;
    main: fromMain.State;
}

export interface State extends fromRoot.AppState {
    REDUCER_NAME: FeatureState;
}

export const reducers: ActionReducerMap<FeatureState> = {
    ui: fromUi.reducer,
    main: fromMain.reducer
};


const domainManagmentState_selector = createFeatureSelector<FeatureState>(REDUCER_NAME);


// ######### MAIN STATE #########
const mainState_selector = createSelector(domainManagmentState_selector, (state: FeatureState) => state.main);

export const mainState_Domain_selector = createSelector(mainState_selector, fromMain.domains_selector);
export const mainState_showInactiveDomain_selector = createSelector(mainState_selector, fromMain.showInactiveDomains_selector);


// ######### UI STATE #########
const uiState_selector = createSelector(domainManagmentState_selector, (state: FeatureState) => state.ui);

export const uiState_pageDataLoaded_selector = createSelector(uiState_selector, fromUi.pageDataLoaded_selector);



