
import {catchError} from 'rxjs/operators';
import * as reducer from "../../../../notifications/dialogs/edit-notification-dialog/sandbox/store/reducer";
import * as actions from "../../../../notifications/dialogs/edit-notification-dialog/sandbox/store/actions";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {NotificationRestService} from "../../../../../../../../core/modules/rest/notification/notification-rest.service";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {NotificationResponseModel} from "../../../../../../../../core/modules/rest/notification/response/notification-response.model";
import {NotificationFormSubmitDataModel} from "../model/notification-form-submit-data.model";
import {CreateNotificationRequestModel} from "../../../../../../../../core/modules/rest/notification/request/create-notification-request.model";
import {UpdateNotificationRequestModel} from "../../../../../../../../core/modules/rest/notification/request/update-notification-request.model";
import { NotificationBaseUserResponseModel } from '../../../../../../../../core/modules/rest/notification/response/notification-base-user-response.model';

@Injectable()
export class EditNotificationDialogSandbox extends DialogSandbox {

    notification$: Observable<NotificationResponseModel> = this.store.select(reducer.notification_selector);

    notificationBaseUser$: Observable<NotificationBaseUserResponseModel[]> = this.store.select(reducer.notification_base_user_selector);

    constructor(appDialogsService: AppDialogsService,
                private notificationRestService: NotificationRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);

    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadNotification(notificationId: number) {

        this.showLoader();

        this.notificationRestService.getById(notificationId).subscribe((notification: NotificationResponseModel) => {
            this.store.dispatch(new actions.SetNotification(notification));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    loadNotificationBaseUsers(notificationId: number) {

        this.showLoader();

        this.notificationRestService.getNotificationBaseUsers(notificationId).subscribe((notificationBaseUser: NotificationBaseUserResponseModel[]) => {
            this.store.dispatch(new actions.SetNotificationBaseUser(notificationBaseUser));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    createNotification(notificationData: NotificationFormSubmitDataModel): Observable<CreateBaseResponseModel> {

        const request = new CreateNotificationRequestModel(
            notificationData.subject,
            notificationData.description,
            notificationData.notificationType,
            notificationData.platform,
            notificationData.fromDateTime,
            notificationData.toDateTime,
            notificationData.sendEmail,
            notificationData.baseUserIds,
            notificationData.templateId
        );

        return this.notificationRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating notification', error);
            }));
    }

    updateNotification(notificationId: number, notificationData: NotificationFormSubmitDataModel): Observable<any> {

        const request = new UpdateNotificationRequestModel(
            notificationId,
            notificationData.subject,
            notificationData.description,
            notificationData.notificationType,
            notificationData.platform,
            notificationData.fromDateTime,
            notificationData.toDateTime,
            notificationData.sendEmail,
            notificationData.baseUserIds,
            notificationData.templateId
        );

        return this.notificationRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating notificaiton', error);
            }));
    }
}
