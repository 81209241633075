import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as actions from './actions';

export const REDUCER_NAME = 'dialog_changePassword';

export interface State {
    showLoader: boolean;
}

// Define initial state
export const initialState: State = {
    showLoader: false
};

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const showLoader_selector = createSelector(state_selector, (state: State) => state.showLoader);

// Define the reducer function
export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.SET_CHANGE_PASSWORD_LOADER_ACTION:
            // Update showLoader property based on the action
            return {
                ...state,
                showLoader: action.showLoader
            };
        default:
            return state;
    }
}

