import { Component, Input, OnInit  } from '@angular/core';
import {OrderPrintingInfoSummaryResponseModel} from "../../../../../../core/modules/rest/order-summary/response/order-printing-info-summary-response.model";


@Component({
    selector: 'app-printing-info-dialog-summary',
    templateUrl: './printing-info-dialog-summary.component.html'
})
export class PrintingInfoDialogSummaryComponent implements OnInit {

    @Input() printingInfos: OrderPrintingInfoSummaryResponseModel[];

    constructor() {
    }

    ngOnInit() {
    }
}
