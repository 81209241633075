const NotificationTypeEnum = {
    TEXT: 'TEXT' as 'TEXT',
    TEMPLATE: 'TEMPLATE' as 'TEMPLATE'
};
type NotificationTypeEnum = (typeof NotificationTypeEnum)[keyof typeof NotificationTypeEnum];
export {NotificationTypeEnum};

export function getDescription(notificationType: NotificationTypeEnum): string {
    if (notificationType === NotificationTypeEnum.TEXT) {
        return "Text";
    }
    else if (notificationType === NotificationTypeEnum.TEMPLATE) {
        return "Template";
    }
    else {
        return "";
    }
}
