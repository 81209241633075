import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output  } from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {SortByModel} from '../../../../../../../../shared/model/sort-by.model';
import {CommissionReportCurrentSearchDataModel} from '../../../model/commission-report-current-search-data.model';
import {NO_RESULTS_ICON} from "../../../../../../../../core/services/app-properties.service";

const SORT_ASC = 'ASC';
const SORT_DESC = 'DESC';


@Component({
    selector: 'app-commission-report-table-wrapper',
    templateUrl: './commission-report-table-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommissionReportTableWrapperComponent implements OnInit, OnDestroy {

    @Input() currentSearchData: CommissionReportCurrentSearchDataModel;
    @Input() currentSearchDataSortBy: SortByModel;

    @Output() generateUrlForExportToXlsCommissionReportEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() headerSortEvent: EventEmitter<SortByModel> = new EventEmitter<SortByModel>();

    noResultImage = NO_RESULTS_ICON;

    private decimalPipe = new DecimalPipe('en');

    constructor() {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    onGenerateUrlForExportToXlsCommissionReport() {
        this.generateUrlForExportToXlsCommissionReportEvent.emit();
    }

    onHeaderSort(header: string) {
        let newSortBy: SortByModel;

        if (!this.currentSearchDataSortBy) {
            newSortBy = new SortByModel(header, SORT_ASC);
        } else if (this.currentSearchDataSortBy.fieldName !== header) {
            newSortBy = new SortByModel(header, SORT_ASC);
        } else {
            if (this.currentSearchDataSortBy.order === SORT_ASC) {
                newSortBy = new SortByModel(header, SORT_DESC);
            } else {
                newSortBy = new SortByModel(header, SORT_ASC);
            }
        }

        this.headerSortEvent.emit(newSortBy);
    }

    getDisplayMessage(): string {
        let displayMessage: string = "";

        displayMessage = this.decimalPipe.transform(this.currentSearchData.totalNumberOfResults) + ' Items';

        return displayMessage;
    }
}
