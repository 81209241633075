<app-abstract-dialog-container>
    <app-edit-favorite-dialog-form
        [editMode]="editMode"
        [favoriteType]="favoriteType"
        [favorite]="favorite$ | async"
        [descriptionAvailable]="descriptionAvailable$ | async"
        (descriptionChanged)="onFavoriteDescriptionChanged($event)"
        (submitEvent)="onSubmit($event)"
        (closeEvent)="onClose()">
    </app-edit-favorite-dialog-form>
</app-abstract-dialog-container>

