import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { DialogData } from "../../../../../../../../shared/dialogs/model/dialog.data";
import { CloudPaymentDeviceResponseModel } from '../../../../../../../../core/modules/rest/cloud-payment/response/cloud-payment-response.model';
import { EditCloudPaymentDeviceDialogSandbox } from '../sandbox/edit-cloud-payment-device-dialog.sandbox';
import { CloudPaymentDeviceFormSubmitDataModel } from '../model/cloud-payment-device-form-submit-data.model';

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditCloudPaymentDeviceDialogData extends DialogData {

    cloudPaymentDeviceId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, cloudPaymentDeviceId?: number) {
        super();
        this.cloudPaymentDeviceId = cloudPaymentDeviceId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-cloud-payment-device-dialog',
    templateUrl: './edit-cloud-payment-device-dialog.component.html'
})
export class EditCloudPaymentDeviceDialogComponent implements OnInit, OnDestroy {

    cloudPaymentDevice$: Observable<CloudPaymentDeviceResponseModel>;
    cloudPaymentDeviceId: number;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditCloudPaymentDeviceDialogData, private sandbox: EditCloudPaymentDeviceDialogSandbox) {
        this.cloudPaymentDeviceId = dialogData.cloudPaymentDeviceId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {

        if (this.cloudPaymentDeviceId && !isNaN(this.cloudPaymentDeviceId)) {
            this.editMode = true;
            this.sandbox.loadCloudPaymentDevice(this.cloudPaymentDeviceId);
            this.cloudPaymentDevice$ = this.sandbox.cloudPaymentDevice$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: CloudPaymentDeviceFormSubmitDataModel) {

        if (this.editMode) {
            this.sandbox.updateCloudPaymentDevice( data.cloudPaymentDeviceId, data.nmiPaymentDeviceId, data.description ).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createCloudPaymentDevice( data.nmiPaymentDeviceId, data.description, data.securityKey ).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
