import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {Observable} from "rxjs";
import {BusResponseModel} from "../../../../../../../../core/modules/rest/bus/response/bus-response.model";
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {EditBusDialogSandbox} from "../sandbox/edit-bus-dialog.sandbox";
import {BusFormSubmitDataModel} from "../model/bus-form-submit-data.model";


// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditBusDialogData extends DialogData {

    busId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, busId?: number) {
        super();
        this.busId = busId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-bus-dialog',
    templateUrl: './edit-bus-dialog.component.html'
})
export class EditBusDialogComponent implements OnInit, OnDestroy {

    bus$: Observable<BusResponseModel>;
    busId: number;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditBusDialogData, private sandbox: EditBusDialogSandbox) {
        this.busId = dialogData.busId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
        if (this.busId && !isNaN(this.busId)) {
            this.editMode = true;
            this.sandbox.loadBus(this.busId);
            this.bus$ = this.sandbox.bus$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: BusFormSubmitDataModel) {
        if (this.editMode) {
            this.sandbox.updateBus(this.busId, data.number, data.description, data.capacity).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createBus(data.number, data.description, data.capacity).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
