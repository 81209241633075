import {Action} from '@ngrx/store';

export const AVAILABLE_FIELDS_LOADING_START_ACTION = '[Order Reports UI] Available fields loading start';
export const AVAILABLE_FIELDS_LOADING_END_ACTION = '[Order Reports UI] Available fields loading end';
export const CHANGE_REPORT_GROUP_ACTIVITY_STATUS_ACTION = '[Order Reports UI] Change report group activity status';


export class AvailableFieldsLoadingStart implements Action {
    readonly type = AVAILABLE_FIELDS_LOADING_START_ACTION;

    constructor() {
    }
}

export class AvailableFieldsLoadingEnd implements Action {
    readonly type = AVAILABLE_FIELDS_LOADING_END_ACTION;

    constructor() {
    }
}

export class ChangeReportGroupActivityStatus implements Action {
    readonly type = CHANGE_REPORT_GROUP_ACTIVITY_STATUS_ACTION;

    constructor(public name: string) {
    }
}


export type Actions =
    AvailableFieldsLoadingStart |
    AvailableFieldsLoadingEnd |
    ChangeReportGroupActivityStatus;
