<ng-container>
    <div class="dropdown_container {{customClasses}}"
         [ngClass]="{
            'dropdown-active'  : opened,
            'dropdown-disabled': disabled || hasToBeDisabled(),
            'dropdown-loader'  : !disabled && loading,
            'dropdown-fixed-option-list': positionedOptionsList }"
         [delayClickOutsideInit]="true"
         (clickOutside)="closeDropdown()"
         #dropdownContainer>


        <div class="dropdownHeader_wrapper">
            <a class="dropdownHeader_container"
               (click)="toggleDropdown()">

                <input class="dropdownHeader_label input_element"
                    [value]="getPrintValue()"
                    [readonly]="!autocomplete || !opened"
                    (keyup)="onAutocompleteInput($event)">

                <div class="icon_container">
                    <div class="icon_content default_icon">
                        <i class="fa fa-sort" aria-hidden="true"></i>
                    </div>

                    <div class="icon_content loading_icon">
                        <i class="fas fa-circle-notch fa-spin"></i>
                    </div>
                </div>
            </a>
        </div>

        <div class="dropdownList_container" #dropdownOptionsList>
            <ul class="dropdownList_content dropdown--list--content"
                *ngIf="getOptions().length !== 0">
                <li class="dropdownLi_container dropdown--list--element" *ngFor='let option of getOptions(); let i = index; trackBy: getOptionId' [ngClass]='{"hoverDropdownOption": i === hoverOptionIndex }'>

                    <a class="dropdownLink"
                       (click)='dropdownOptionClick(option, $event)'>
                        <div class="checkboxLabelGroup paymentCheckbox">
                            <input type="checkbox" [checked]="isOptionActive(option)"/>
                            <label class='dropdownLink_label' [innerHTML]="option.label">
                            </label>
                        </div>
                    </a>
                </li>
            </ul>

            <!-- Empty Search results-->
            <ul class="dropdownList_content"
                *ngIf="getOptions().length === 0">
                <li class="dropdownLi_container disabled">
                    <a class="dropdownLink">
                        No matching results were found.
                    </a>
                </li>
            </ul>
            <!-- Empty Search results :: END -->
        </div>
    </div>
</ng-container>
