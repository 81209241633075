import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from "moment";

import _ from 'lodash';
import { MultiselectDropdownOptionModel } from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import { DiscountCostModel } from "../../model/discount-cost.model";
import { AmountTypeEnum } from "../../../../../../../../../shared/enums/amount-type.enum";
import { PlatformEnum } from "../../../../../../../../../shared/enums/platform.enum";


@Component({
    selector: 'app-edit-discount-dialog-content',
    templateUrl: './edit-discount-dialog-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDiscountDialogContentComponent implements OnInit, OnChanges {

    @Input() discount: DiscountCostModel;
    @Input() amountTypes: MultiselectDropdownOptionModel[];
    @Input() allPlatforms: MultiselectDropdownOptionModel[];

    @Output() discountChangedEvent: EventEmitter<DiscountCostModel> = new EventEmitter();

    discountLocalCopy: DiscountCostModel;
    startDate: moment.Moment = null;
    endDate: moment.Moment = null;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.discount) {

            this.discountLocalCopy = _.cloneDeep(changes.discount.currentValue);

            if (changes.discount.currentValue.startDateInt) {
                this.startDate = moment(changes.discount.currentValue.startDateInt, 'YYYYMMDD');
            } else {
                this.startDate = null;
            }

            if (changes.discount.currentValue.endDateInt) {
                this.endDate = moment(changes.discount.currentValue.endDateInt, 'YYYYMMDD');
            } else {
                this.endDate = null;
            }
        }
    }

    getOnlyActivePlatforms() {
        if (this.discount === undefined || this.discount.platforms === undefined) {
            return [];
        }
        if (this.discountLocalCopy === undefined || this.discountLocalCopy.platforms === undefined) {
            return this.discount.platforms.filter((value: PlatformEnum) => {
                if (this.allPlatforms.some(platform => platform.value == value)) {
                    return true;
                }
                return false;
            })
        }
        return this.discountLocalCopy.platforms.filter((value: PlatformEnum) => {
            if (this.allPlatforms.some(platform => platform.value == value)) {
                return true;
            }
            return false;
        })
    }

    onInputChanged() {
        this.discountChangedEvent.emit(this.discountLocalCopy);
    }

    onAmountTypeChanged(value: AmountTypeEnum) {
        this.discountLocalCopy.amountType = value;
        this.discountChangedEvent.emit(this.discountLocalCopy);
    }

    onStartDateChanged(date: moment.Moment) {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const ms = date ? moment.parseZone(date, timeZone).valueOf() : null;
        this.discountLocalCopy.startDateMS = ms;
        const int = date ? parseInt(date.format("YYYYMMDD")) : null;
        this.discountLocalCopy.startDateInt = int;
        this.discountChangedEvent.emit(this.discountLocalCopy);
    }

    onEndDateChanged(date: moment.Moment) {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const ms = date ? moment.parseZone(date, timeZone).valueOf() : null;
        this.discountLocalCopy.endDateMS = ms;
        const int = date ? parseInt(date.format("YYYYMMDD")) : null;
        this.discountLocalCopy.endDateInt = int;
        this.discountChangedEvent.emit(this.discountLocalCopy);
}

    onIsOrderLevelChanged(value: boolean) {
        this.discountLocalCopy.isOrderLevel = value;
        this.discountChangedEvent.emit(this.discountLocalCopy);
    }

    onAllPlatformsChanged(value: boolean) {
        this.discountLocalCopy.allPlatforms = value;
        this.discountChangedEvent.emit(this.discountLocalCopy);
    }

    onSelectedPlatformsChanged(values: string[]) {
        this.discountLocalCopy.platforms = values.map((value: string) => <PlatformEnum>value);
        this.discountChangedEvent.emit(this.discountLocalCopy);
    }
}
