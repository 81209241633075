
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {catchError, take} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AutoReportResponseModel} from "../../../../../../../../core/modules/rest/reporting/general/auto-reports/response/auto-report-response.model";
import {FavoritesResponseModel} from "../../../../../../../../core/modules/rest/favorite/response/favorites-response.model";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {select, Store} from "@ngrx/store";
import {AutoReportRestService} from "../../../../../../../../core/modules/rest/reporting/general/auto-reports/auto-report-rest.service";
import {FavoriteRestService} from "../../../../../../../../core/modules/rest/favorite/favorite-rest.service";
import {AutoReportFormSubmitDataModel} from "../model/auto-report-form-submit-data.model";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {CreateAutoReportRequestModel} from "../../../../../../../../core/modules/rest/reporting/general/auto-reports/request/create-auto-report-request.model";
import {UpdateAutoReportRequestModel} from "../../../../../../../../core/modules/rest/reporting/general/auto-reports/request/update-auto-report-request.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {DropdownOptionModel} from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {UserInfoResponseModel} from "../../../../../../../../core/modules/rest/user/response/user-info-response.model";
import * as appReducer from "../../../../../../../../core/store/reducers";
import {RolesResponseModel} from "../../../../../../../../core/modules/rest/role/response/roles-response.model";
import {DateTimeUtility} from "../../../../../../../../shared/utils/date-time-utility";
import * as moment from "moment";

@Injectable()
export class EditAutoReportDialogSandbox extends DialogSandbox {

    autoReport$: Observable<AutoReportResponseModel> = this.store.select(reducer.autoReport_selector);
    favorites$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.favorites_selector));
    currentUserEmail$: Observable<string> = this.store.pipe(select(reducer.currentUserEmail_selector));
    currentUser$: Observable<UserInfoResponseModel> = this.appState.pipe(select(appReducer.userState_userInfo_selector));

    constructor(appDialogsService: AppDialogsService,
                private appState: Store<appReducer.AppState>,
                private autoReportRestService: AutoReportRestService,
                private favoriteRestService: FavoriteRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(autoReportId: number) {

        this.showLoader();

        if (!autoReportId) {
            observableCombineLatest(this.currentUser$, this.favoriteRestService.getActiveFavoritesByCurrentUser()).pipe(
                take(1))
                .subscribe(([currentUser, favorites]: [UserInfoResponseModel, FavoritesResponseModel]) => {
                    this.store.dispatch(new actions.SetFavorites(favorites.favorites.map(f => new DropdownOptionModel(f.description, f.favoriteId.toString()))));
                    this.store.dispatch(new actions.SetCurrentUserEmail(currentUser.email));
                    this.hideLoader();
                },
                () => this.hideLoader());
        } else {
            observableCombineLatest(this.currentUser$, this.autoReportRestService.getById(autoReportId), this.favoriteRestService.getActiveFavoritesByCurrentUser()).pipe(
                take(1))
                .subscribe(([currentUser, autoReport, favorites]: [UserInfoResponseModel, AutoReportResponseModel, FavoritesResponseModel]) => {
                    this.store.dispatch(new actions.SetAutoReport(autoReport));
                    this.store.dispatch(new actions.SetFavorites(favorites.favorites.map(f => new DropdownOptionModel(f.description, f.favoriteId.toString()))));
                    this.store.dispatch(new actions.SetCurrentUserEmail(currentUser.email));
                    this.hideLoader();
                },
                () => this.hideLoader());
        }
    }

    createAutoReport(data: AutoReportFormSubmitDataModel): Observable<CreateBaseResponseModel> {

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const request = new CreateAutoReportRequestModel(
            data.description,
            data.nth,
            data.dayOfWeek,
            data.hour,
            data.startDate ? moment.parseZone(data.startDate, timeZone).valueOf() : null,
            data.endDate ? moment.parseZone(data.endDate, timeZone).valueOf() : null,
            data.exportType,
            data.email,
            data.favoriteId);

        return this.autoReportRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating auto-report', error);
            }));
    }

    updateAutoReport(autoReportId: number, data: AutoReportFormSubmitDataModel): Observable<any> {

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const request = new UpdateAutoReportRequestModel(
            autoReportId,
            data.description,
            data.nth,
            data.dayOfWeek,
            data.hour,
            data.startDate ? moment.parseZone(data.startDate, timeZone).valueOf() : null,
            data.endDate ? moment.parseZone(data.endDate, timeZone).valueOf() : null,
            data.exportType,
            data.email,
            data.favoriteId);

        return this.autoReportRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating auto-report', error);
            }));
    }
}
