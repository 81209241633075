import {Action} from '@ngrx/store';
import {MultiselectDropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

export const RESET_STATE = '[Dialog - Mapping Cost :: MAIN] Reset State';
export const SET_LOADING = '[Dialog - Mapping Cost :: MAIN] Loading';
export const SET_CATEGORIES = '[Dialog - Mapping Cost :: MAIN] Set Categories';
export const SET_MAPPED_CATEGORIES = '[Dialog - Mapping Cost :: MAIN] Set Mapped Categories';
export const SET_PRODUCTS = '[Dialog - Mapping Cost :: MAIN] Set Products';
export const SET_MAPPED_PRODUCTS = '[Dialog - Mapping Cost :: MAIN] Set Mapped Products';
export const SET_PASSES = '[Dialog - Mapping Cost :: MAIN] Set Passes';
export const SET_MAPPED_PASSES = '[Dialog - Mapping Cost :: MAIN] Set Mapped Passes';

export class ResetState implements Action {
    readonly type = RESET_STATE;
    constructor() {}
}

export class SetLoading implements Action {
    readonly type = SET_LOADING;
    constructor(public loading: boolean) {}
}

export class SetCategories implements Action {
    readonly type = SET_CATEGORIES;
    constructor(public categories: MultiselectDropdownOptionModel[]) {}
}

export class SetMappedCategories implements Action {
    readonly type = SET_MAPPED_CATEGORIES;
    constructor(public mappedCategories: number[]) {}
}

export class SetProducts implements Action {
    readonly type = SET_PRODUCTS;
    constructor(public products: MultiselectDropdownOptionModel[]) {}
}

export class SetMappedProducts implements Action {
    readonly type = SET_MAPPED_PRODUCTS;
    constructor(public mappedProducts: number[]) {}
}

export class SetPasses implements Action {
    readonly type = SET_PASSES;
    constructor(public passes: MultiselectDropdownOptionModel[]) {}
}

export class SetMappedPasses implements Action {
    readonly type = SET_MAPPED_PASSES;
    constructor(public mappedPasses: number[]) {}
}

export type All = ResetState
    | SetLoading
    | SetCategories
    | SetMappedCategories
    | SetProducts
    | SetMappedProducts
    | SetPasses
    | SetMappedPasses;
