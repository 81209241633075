import * as actions from './actions';
import {FilterItemModel} from '../../model/filter-item.model';
import {FieldItemModel} from '../../model/field-item.model';
import {FavoriteResponseModel} from '../../../../../../../core/modules/rest/favorite/response/favorite-response.model';
import {ExpressionItemModel} from '../../model/expression-item.model';
import {ExpressionItemTypeEnum} from '../../model/expression-item-type.enum';
import {ExpressionBracketModel} from '../../model/expression-bracket.model';
import {ExpressionGroupModel} from '../../model/expression-group.model';
import {ExpressionFilterModel} from '../../model/expression-filter.model';
import {ExpressionOperatorModel} from '../../model/expression-operator.model';
import {ExpressionOperatorTypeEnum} from '../../model/expression-operator-type.enum';
import {DroppedReportItemDataModel} from '../../model/dropped-report-item-data.model';
import {DroppedFilterDataModel} from '../../model/dropped-filter-data.model';
import {DroppedGroupDataModel} from '../../model/dropped-group-data.model';
import {ReportItemModel} from '../../model/report-item.model';
import {UpdateExpressionItemValueModel} from '../../model/update-expression-item-value.model';
import {DroppedFieldItemModel} from '../../model/dropped-field-item.model';
import {DraggedDataI} from '../../model/dragged-data.model';
import {CurrentSearchDataModel} from '../../model/current-search-data.model';
import {FieldRequestModel} from '../../../../../../../core/modules/rest/reporting/request/field-request.model';
import {findFieldItemByQueryFieldName, findFilterItemByQueryFieldName} from '../../model/data';
import {ReportSearchRequestModel} from '../../../../../../../core/modules/rest/reporting/request/report-search-request.model';
import {BaseExpressionRequestModel} from '../../../../../../../core/modules/rest/reporting/request/base-expression-request.model';
import {OperatorExpressionRequestModel} from '../../../../../../../core/modules/rest/reporting/request/operator-expression-request.model';
import {FilterExpressionRequestModel} from '../../../../../../../core/modules/rest/reporting/request/filter-expression-request.model';
import {GroupExpressionRequestModel} from '../../../../../../../core/modules/rest/reporting/request/group-expression-request.model';
import {PaymentMethodSummaryResponse} from "../../../../../../../core/modules/rest/reporting/response/payment-method-summary-response";
import {DISABLE_SHOW_PAYMENT_SUMMARY} from "./actions";


export interface State {
    expressionItems: ExpressionItemModel[];
    selectedFields: FieldItemModel[];
    draggedData: DraggedDataI;
    allUserFavorites: FavoriteResponseModel[];
    selectedFavoriteId: number;
    currentSearchData: CurrentSearchDataModel;
    reportItemsSearchText: string;
    showPaymentSummary: boolean;
    showPaymentSummaryDisabled: boolean;
    paymentSummary: PaymentMethodSummaryResponse[];
}

const initialState: State = {
    expressionItems: [],
    selectedFields: [],
    draggedData: null,
    allUserFavorites: [],
    selectedFavoriteId: null,
    currentSearchData: null,
    reportItemsSearchText: '',
    showPaymentSummary: false,
    showPaymentSummaryDisabled: false,
    paymentSummary: []
};


export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.UPDATE_EXPRESSION_ITEM_VALUE_ACTION:
            return handleUpdateExpressionItemValue(state, action);
        case actions.DELETE_EXPRESSION_ITEM_BY_UUID_ACTION:
            return handleDeleteExpressionItemByUUID(state, action);
        case actions.ADD_EXPRESSION_ITEM_ACTION:
            return handleAddExpressionItem(state, action);
        case actions.ADD_FIELD_ACTION:
            return handleAddFieldAction(state, action);
        case actions.DELETE_FIELD_ACTION:
            return handleDeleteFieldAction(state, action);
        case actions.UPDATE_DRAGGED_DATA_ACTION:
            return handleUpdateDraggedDataAction(state, action);
        case actions.UPDATE_USER_FAVORITES_ACTION:
            return handleUpdateUserFavoritesAction(state, action);
        case actions.UPDATE_SELECTED_FAVORITE_ACTION:
            return handleUpdateSelectedFavoriteAction(state, action);
        case actions.UPDATE_REPORT_ITEMS_SEARCH_TEXT_ACTION:
            return handleReportItemsSearchTextAction(state, action);
        case actions.UPDATE_CURRENT_SEARCH_DATA_SORT_BY_ACTION:
            return handleUpdateCurrentSearchDataSortByAction(state, action);
        case actions.CREATE_INTIIAL_CURRENT_SEARCH_DATA_ACTION:
            return handleCreateInitialCurrentSearchDataAction(state, action);
        case actions.CREATE_FIRST_RESPONSE_FOR_CURRENT_SEARCH_DATA_ACTION:
            return handleCreateFirstResponseForCurrentSearchDataAction(state, action);
        case actions.LOADING_MORE_ITEMS_FOR_CURRENT_SEARCH_DATA_ACTION:
            return handleLoadingMoreItemsForCurrentSearchDataAction(state, action);
        case actions.ADD_NEXT_RESPONSE_ITEMS_FOR_CURRENT_SEARCH_DATA_ACTION:
            return handleAddNextResponseItemsForCurrentSearchDataAction(state, action);
        case actions.RESET_CURRENT_SEARCH_DATA_ACTION:
            return handleResetCurrentSearchDataAction(state, action);
        case actions.RESET_STATE_ACTION:
            return handleResetStateAction(state, action);
        case actions.SHOW_PAYMENT_SUMMARY:
            return {... state, showPaymentSummary: action.value};
        case DISABLE_SHOW_PAYMENT_SUMMARY:
            return {... state, showPaymentSummaryDisabled: action.value};
        default:
            return state;
    }
}


// #################################################### SELECTORS ####################################################
export const expressionItems_selector = (state: State) => state.expressionItems;
export const selectedFields_selector = (state: State) => state.selectedFields;
export const draggedData_selector = (state: State) => state.draggedData;
export const selectedFavoriteId_selector = (state: State) => state.selectedFavoriteId;
export const allUserFavorites_selector = (state: State) => state.allUserFavorites;
export const currentSearchData_selector = (state: State) => state.currentSearchData;
export const reportItemsSearchText_selector = (state: State) => state.reportItemsSearchText;
export const showPaymentSummary_selector = (state: State) => state.showPaymentSummary;
export const showPaymentSummaryDisabled_selector = (state: State) => state.showPaymentSummaryDisabled;


// #################################################### HANDLERS ####################################################
function handleUpdateExpressionItemValue(state: State, action: actions.UpdateExpressionItemValue) {

    const updateExpressionItemModel: UpdateExpressionItemValueModel = action.data;

    const flatList: ExpressionItemModel[] = convertExpressionItemsToFlatList(state.expressionItems);
    const itemIndex = flatList.findIndex((item: ExpressionItemModel) => item.uuid === updateExpressionItemModel.uuid);
    const expressionItem: ExpressionItemModel = flatList[itemIndex];

    let newExpressionItem: ExpressionItemModel;
    switch (expressionItem.itemType) {
        case ExpressionItemTypeEnum.FILTER:
            const oldFilterItem: FilterItemModel = (<ExpressionFilterModel> flatList[itemIndex]).filter;
            const newFilterItem: FilterItemModel = new FilterItemModel(oldFilterItem.name, oldFilterItem.queryFieldName, oldFilterItem.type, updateExpressionItemModel.data);
            newExpressionItem = new ExpressionFilterModel(newFilterItem, expressionItem.uuid);
            break;
        case ExpressionItemTypeEnum.OPERATOR:
            const operatorType: ExpressionOperatorTypeEnum = <ExpressionOperatorTypeEnum>updateExpressionItemModel.data[0];
            newExpressionItem = new ExpressionOperatorModel(operatorType);
            break;
        default:
            console.error('handleUpdateExpressionItemValue :: unexpected item type for updating value');
            return state;
    }

    const updatedFlatList: ExpressionItemModel[] = [
        ...flatList.slice(0, itemIndex),
        newExpressionItem,
        ...flatList.slice(itemIndex + 1)
    ];

    const updatedExpressionItemsList: ExpressionItemModel[] = convertFlatListToExpressionItems(updatedFlatList);

    return {
        ...state,
        expressionItems: updatedExpressionItemsList
    };
}

function handleDeleteExpressionItemByUUID(state: State, action: actions.DeleteExpressionItemByUUID) {
    const deletingItemUUID: string = action.uuid;

    const flatList: ExpressionItemModel[] = convertExpressionItemsToFlatList(state.expressionItems);

    let deleteRangeStartIndex = flatList.findIndex((item: ExpressionItemModel) => item.uuid === deletingItemUUID);

    const expressionItem: ExpressionItemModel = flatList[deleteRangeStartIndex];

    let groupNumberOfItems = 0;
    // When deleting group, delete all it's items
    if (expressionItem.itemType === ExpressionItemTypeEnum.OPENED_BRACKET) {
        const closedBracketIndex = arrayLastIndexOf(flatList, (item: ExpressionItemModel) => item.uuid === deletingItemUUID);
        groupNumberOfItems = (closedBracketIndex - deleteRangeStartIndex);
    }


    // Find parent group start index
    let parentGroupStartIndex = deleteRangeStartIndex - 1;
    while (parentGroupStartIndex >= 0) {
        // If some sibling is group then skip it
        if (flatList[parentGroupStartIndex].itemType === ExpressionItemTypeEnum.CLOSED_BRACKET) {
            parentGroupStartIndex--;
            while (flatList[parentGroupStartIndex].itemType !== ExpressionItemTypeEnum.OPENED_BRACKET) {
                parentGroupStartIndex--;
            }
            parentGroupStartIndex--;
            continue;
        }

        // Parent opened bracket has been found
        if (flatList[parentGroupStartIndex].itemType === ExpressionItemTypeEnum.OPENED_BRACKET) {
            parentGroupStartIndex++;
            break;
        }

        parentGroupStartIndex--;
    }

    // If filter is in root container
    if (parentGroupStartIndex === -1) {
        parentGroupStartIndex = 0;
    }


    // Find parent group end index
    let parentGroupEndIndex = arrayLastIndexOf(flatList, (item: ExpressionItemModel) => item.uuid === deletingItemUUID) + 1;
    while (parentGroupEndIndex < flatList.length) {
        // If some sibling is group then skip it
        if (flatList[parentGroupEndIndex].itemType === ExpressionItemTypeEnum.OPENED_BRACKET) {
            parentGroupEndIndex++;
            while (flatList[parentGroupEndIndex].itemType !== ExpressionItemTypeEnum.CLOSED_BRACKET) {
                parentGroupEndIndex++;
            }
            parentGroupEndIndex++;
            continue;
        }

        // Parent closed bracket has been found
        if (flatList[parentGroupEndIndex].itemType === ExpressionItemTypeEnum.CLOSED_BRACKET) {
            parentGroupEndIndex--;
            break;
        }

        parentGroupEndIndex++;
    }

    // If filter is in root container
    if (parentGroupEndIndex === flatList.length) {
        parentGroupEndIndex--;
    }


    let deleteRangeEndIndex;
    // Is item first in parent list
    if (parentGroupStartIndex === deleteRangeStartIndex) {
        // Is item only one item in parent list
        if (deleteRangeStartIndex === parentGroupEndIndex - groupNumberOfItems) {
            deleteRangeEndIndex = deleteRangeStartIndex;
        } else {
            deleteRangeEndIndex = deleteRangeStartIndex + 1;
        }
    } else {
        deleteRangeEndIndex = deleteRangeStartIndex;
        deleteRangeStartIndex = deleteRangeStartIndex - 1;
    }

    const updatedFlatList = [
        ...flatList.slice(0, deleteRangeStartIndex),
        ...flatList.slice(deleteRangeEndIndex + groupNumberOfItems + 1),
    ];

    const updatedExpressionItemsList: ExpressionItemModel[] = convertFlatListToExpressionItems(updatedFlatList);

    return {
        ...state,
        expressionItems: updatedExpressionItemsList
    };
}

function handleAddExpressionItem(state: State, action: actions.AddExpressionItem) {
    const parentGroupUUID = action.data.parentGroupUUID;
    const leftSiblingUUID = action.data.leftSiblingUUID;

    // Create expression item
    let newExpressionItem: ExpressionItemModel;
    switch (action.data.constructor) {
        case DroppedReportItemDataModel:
            const droppedReportItem: DroppedReportItemDataModel = <DroppedReportItemDataModel> action.data;
            if (!droppedReportItem.reportItem.filter) {
                console.error('handleAddExpressionItem :: Dropped report item is not filter !!!');
                return state;
            }
            const reportItem: ReportItemModel = droppedReportItem.reportItem;
            const filterItem: FilterItemModel = new FilterItemModel(reportItem.description, reportItem.queryFieldName, reportItem.filterType, []);
            newExpressionItem = new ExpressionFilterModel(filterItem);
            break;
        case DroppedFilterDataModel:

            const droppedFilterItem: DroppedFilterDataModel = <DroppedFilterDataModel> action.data;
            newExpressionItem = new ExpressionFilterModel(droppedFilterItem.filterItem);
            break;
        case DroppedGroupDataModel:
            const droppedGroupItem: DroppedGroupDataModel = <DroppedGroupDataModel> action.data;
            newExpressionItem = new ExpressionGroupModel(droppedGroupItem.items,  0);
            break;
        default:
            console.error('handleAddExpressionItem :: Unexpected dropped item');
            return state;
    }


    const flatList: ExpressionItemModel[] = convertExpressionItemsToFlatList(state.expressionItems);

    let updatedFlatList = [];

    if (parentGroupUUID === null && leftSiblingUUID === null) {

        if (flatList.length === 0) {
            updatedFlatList = [
                newExpressionItem
            ];
        } else {
            updatedFlatList = [
                newExpressionItem,
                new ExpressionOperatorModel(ExpressionOperatorTypeEnum.AND),
                ...flatList
            ];
        }

    } else if (parentGroupUUID === null && leftSiblingUUID !== null) {

        const leftSiblingItemIndex = arrayLastIndexOf(flatList, (item: ExpressionItemModel) => item.uuid === leftSiblingUUID);

        updatedFlatList = [
            ...flatList.slice(0, leftSiblingItemIndex + 1),
            new ExpressionOperatorModel(ExpressionOperatorTypeEnum.AND),
            newExpressionItem,
            ...flatList.slice(leftSiblingItemIndex + 1),
        ];

    } else if (parentGroupUUID !== null && leftSiblingUUID === null) {

        const parentGroupStartIndex = flatList.findIndex((item: ExpressionItemModel) => item.uuid === parentGroupUUID);
        const parentGroupEndIndex = arrayLastIndexOf(flatList, (item: ExpressionItemModel) => item.uuid === parentGroupUUID);

        if (parentGroupStartIndex === parentGroupEndIndex - 1) {
            // Empty group
            updatedFlatList = [
                ...flatList.slice(0, parentGroupStartIndex + 1),
                newExpressionItem,
                ...flatList.slice(parentGroupEndIndex),
            ];
        } else {
            // Non empty group
            updatedFlatList = [
                ...flatList.slice(0, parentGroupStartIndex + 1),
                newExpressionItem,
                new ExpressionOperatorModel(ExpressionOperatorTypeEnum.AND),
                ...flatList.slice(parentGroupStartIndex + 1),
            ];
        }

    } else if (parentGroupUUID !== null && leftSiblingUUID !== null) {

        const leftSiblingItemIndex = arrayLastIndexOf(flatList, (item: ExpressionItemModel) => item.uuid === leftSiblingUUID);

        updatedFlatList = [
            ...flatList.slice(0, leftSiblingItemIndex + 1),
            new ExpressionOperatorModel(ExpressionOperatorTypeEnum.AND),
            newExpressionItem,
            ...flatList.slice(leftSiblingItemIndex + 1),
        ];
    }

    const updatedExpressionItemsList: ExpressionItemModel[] = convertFlatListToExpressionItems(updatedFlatList);

    return {
        ...state,
        expressionItems: updatedExpressionItemsList
    };
}

function handleAddFieldAction(state: State, action: actions.AddField) {
    const selectedFields = state.selectedFields;

    const droppedFieldItem: DroppedFieldItemModel = action.data;
    const fieldItem: FieldItemModel = droppedFieldItem.fieldItem;

    const updatedSelectedFields: FieldItemModel[] = selectedFields.filter((field: FieldItemModel) => field.queryFieldName !== fieldItem.queryFieldName);

    const insertIndex = selectedFields.findIndex((field: FieldItemModel) => field.queryFieldName === droppedFieldItem.leftSibbling_queryFieldName);

    return {
        ...state,
        selectedFields: [
            ...updatedSelectedFields.slice(0, insertIndex + 1),
            fieldItem,
            ...updatedSelectedFields.slice(insertIndex + 1)
        ]
    };
}

function handleDeleteFieldAction(state: State, action: actions.DeleteField) {
    const selectedFields = state.selectedFields;

    const updatedSelectedFields: FieldItemModel[] = selectedFields.filter((field: FieldItemModel) => field.queryFieldName !== action.queryFieldName);

    return {
        ...state,
        selectedFields: updatedSelectedFields
    };
}

function handleUpdateDraggedDataAction(state: State, action: actions.UpdateDraggedData) {
    return {
        ...state,
        draggedData: action.data
    };
}

function handleUpdateUserFavoritesAction(state: State, action: actions.UpdateUserFavorites) {

    const newState = {
        ...state,
        allUserFavorites: action.favorites
    };

    if (action.favoriteId) {
        newState.selectedFavoriteId = action.favoriteId;
    }

    return newState;
}

function handleUpdateSelectedFavoriteAction(state: State, action: actions.UpdateSelectedFavorite) {

    const newState = { ...state };

    if (action.favoriteId) {
        const favorite: FavoriteResponseModel = state.allUserFavorites.find((favorite: FavoriteResponseModel) => favorite.favoriteId === action.favoriteId);
        const favoriteData: ReportSearchRequestModel = JSON.parse(favorite.favoriteData);

        const expressionItems: ExpressionItemModel[] = convertBaseExpressionRequestsIntoExpressionItems(favoriteData.searchExpression);

        const selectedFields: FieldItemModel[] = favoriteData.fieldRequests.map((fieldRequest: FieldRequestModel) => {
            return findFieldItemByQueryFieldName(fieldRequest.fieldName);
        });

        newState.expressionItems = expressionItems;
        newState.selectedFields = selectedFields;
    }

    newState.selectedFavoriteId = action.favoriteId;
    newState.currentSearchData = null;

    return newState;
}

function handleReportItemsSearchTextAction(state: State, action: actions.UpdateReportItemsSearchText) {
    return {
        ...state,
        reportItemsSearchText: action.text
    };
}

function handleUpdateCurrentSearchDataSortByAction(state: State, action: actions.UpdateCurrentSearchDataSortBy) {

    const newCurrentSearchData: CurrentSearchDataModel = { ...state.currentSearchData };
    newCurrentSearchData.sortBy = action.sortBy;

    return {
        ...state,
        currentSearchData: newCurrentSearchData
    };
}

function handleCreateInitialCurrentSearchDataAction(state: State, action: actions.CreateInitialCurrentSearchData) {

    const data: CurrentSearchDataModel = new CurrentSearchDataModel();

    // Remain previous sort value
    if (state.currentSearchData) {
        data.sortBy = state.currentSearchData.sortBy;
    }

    data.loadingReport = true;

    return {
        ...state,
        currentSearchData: data
    };
}

function handleCreateFirstResponseForCurrentSearchDataAction(state: State, action: actions.CreateFirstResponseForCurrentSearchData) {

    const data: CurrentSearchDataModel = new CurrentSearchDataModel();
    data.scrollId = action.scrollId;
    data.firstSearch = true;
    data.generatedReport = true;
    data.headers = action.headers;
    data.rows = action.rows;
    data.level = action.level;
    data.approximateNumberOfResults = action.approximateNumberOfResults;
    data.from = action.from;
    data.size = action.size;
    data.groupSearch = action.groupSearch;
    data.summableFieldsExist = action.summableFieldsExist;
    data.paymentSummary = action.paymentSummary;

    // Remain previous sort value
    if (state.currentSearchData) {
        data.sortBy = state.currentSearchData.sortBy;
    }

    return {
        ...state,
        currentSearchData: data
    };
}

function handleLoadingMoreItemsForCurrentSearchDataAction(state: State, action: actions.LoadingMoreItemsForCurrentSearchData) {

    const data: CurrentSearchDataModel = { ...state.currentSearchData };
    data.loadingMoreItems = true;

    return {
        ...state,
        currentSearchData: data
    };
}

function handleAddNextResponseItemsForCurrentSearchDataAction(state: State, action: actions.AddNextResponseItemsForCurrentSearchData) {

    const data: CurrentSearchDataModel = { ...state.currentSearchData };
    data.scrollId = action.scrollId;
    data.rows = [...data.rows, ...action.rows];
    data.from = action.from;
    data.size = action.size;
    data.loadingMoreItems = false;

    if (action.rows.length === 0) {
        data.noMoreItemsForLoading = true;
    }

    return {
        ...state,
        currentSearchData: data
    };
}

function handleResetCurrentSearchDataAction(state: State, action: actions.ResetCurrentSearchData) {

    return {
        ...state,
        currentSearchData: null
    };
}

function handleResetStateAction(state: State, action: actions.ResetState) {
    return {
        ...state,
        expressionItems: [],
        selectedFields: [],
        draggedData: null,
        selectedFavoriteId: null,
        currentSearchData: null
    };
}

function convertBaseExpressionRequestsIntoExpressionItems(baseExpressionRequests: BaseExpressionRequestModel[]): ExpressionItemModel[] {

    const resultList: ExpressionItemModel[] = [];

    for (const item of baseExpressionRequests) {
        switch (item.expressionType) {
            case ExpressionItemTypeEnum.OPERATOR:
                const operatorExpressionRequest: OperatorExpressionRequestModel = <OperatorExpressionRequestModel> item;
                resultList.push(new ExpressionOperatorModel(<ExpressionOperatorTypeEnum> operatorExpressionRequest.operator));
                break;
            case ExpressionItemTypeEnum.FILTER:
                const filterExpressionRequest: FilterExpressionRequestModel = <FilterExpressionRequestModel> item;
                const filterItem: FilterItemModel = findFilterItemByQueryFieldName(filterExpressionRequest.fieldName, filterExpressionRequest.data);
                resultList.push(new ExpressionFilterModel(filterItem));
                break;
            case ExpressionItemTypeEnum.GROUP:
                const groupExpressionRequest: GroupExpressionRequestModel = <GroupExpressionRequestModel> item;
                const groupExpressionItems: ExpressionItemModel[] = convertBaseExpressionRequestsIntoExpressionItems(groupExpressionRequest.groupExpression);
                const groupHeight: number = findExpressionItemsMaxHeight(groupExpressionItems) + 1;
                resultList.push(new ExpressionGroupModel(groupExpressionItems, groupHeight));
                break;
            default:
                console.error('convertBaseExpressionRequestsIntoExpressionItems :: unexpected item type');
        }
    }

    return resultList;
}

function convertExpressionItemsToFlatList(expressionItems: ExpressionItemModel[]): ExpressionItemModel[] {

    const resultList = [];

    for (const item of expressionItems) {
        if (item.itemType === ExpressionItemTypeEnum.GROUP) {
            const group: ExpressionGroupModel = <ExpressionGroupModel> item;
            resultList.push(
                new ExpressionBracketModel(group.uuid, ExpressionItemTypeEnum.OPENED_BRACKET),
                ...convertExpressionItemsToFlatList(group.items),
                new ExpressionBracketModel(group.uuid, ExpressionItemTypeEnum.CLOSED_BRACKET)
            );
        } else {
            resultList.push(item);
        }
    }

    return resultList;
}

function convertFlatListToExpressionItems(flatList: ExpressionItemModel[]): ExpressionItemModel[] {

    const resultList = [];

    let i = 0;
    while (i < flatList.length) {

        if (flatList[i].itemType === ExpressionItemTypeEnum.OPENED_BRACKET) {

            const openedBracketIndex: number = i;
            const closedBracketIndex: number = flatList.findIndex((item: ExpressionItemModel) =>
                item.uuid === flatList[i].uuid && item.itemType === ExpressionItemTypeEnum.CLOSED_BRACKET);
            const groupItemUUID = flatList[i].uuid;

            const groupExpressionItemsFlatList: ExpressionItemModel[] = flatList.slice(openedBracketIndex + 1, closedBracketIndex);
            const groupExpressionItems: ExpressionItemModel[] = convertFlatListToExpressionItems(groupExpressionItemsFlatList);
            const groupHeight = findExpressionItemsMaxHeight(groupExpressionItems) + 1;

            resultList.push(new ExpressionGroupModel(groupExpressionItems, groupHeight, groupItemUUID));

            i = closedBracketIndex + 1;
        } else if (flatList[i].itemType === ExpressionItemTypeEnum.GROUP) {

            const groupExpressionItem: ExpressionGroupModel = <ExpressionGroupModel> flatList[i];
            const groupExpressionItems: ExpressionItemModel[] = groupExpressionItem.items;
            const groupHeight = findExpressionItemsMaxHeight(groupExpressionItems) + 1;

            resultList.push(new ExpressionGroupModel(groupExpressionItems, groupHeight, groupExpressionItem.uuid));
            i++;
        } else {
            resultList.push(flatList[i]);
            i++;
        }
    }

    return resultList;
}

function arrayLastIndexOf(array: ExpressionItemModel[], searchFunction: (item: ExpressionItemModel) => boolean) {

    for (let i = array.length - 1; i >= 0; i--) {
        if (searchFunction(array[i])) {
            return i;
        }
    }

    return -1;
}

function findExpressionItemsMaxHeight(expressionItems: ExpressionItemModel[]): number {

    let maxHeight = 0;

    for (const expressionItem of expressionItems) {
        if (expressionItem.itemType === ExpressionItemTypeEnum.GROUP) {
            const groupExpressionItem: ExpressionGroupModel = <ExpressionGroupModel> expressionItem;
            if (groupExpressionItem.groupHeight > maxHeight) {
                maxHeight = groupExpressionItem.groupHeight;
            }
        }
    }

    return maxHeight;
}
