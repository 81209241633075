import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {ProductAvailabilityResponseModel} from "../../../../../../../../../core/modules/rest/product-availability/response/product-availability-response.model";
import {FormControl, FormGroup} from "@angular/forms";
import {DirectionEnum} from "../../../../../../../../../shared/enums/direction.enum";
import * as moment from "moment";
import {DayOfWeekEnum} from "../../../../../../../../../shared/enums/day-of-week.enum";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {DateTimeUtility} from "../../../../../../../../../shared/utils/date-time-utility";
import {
    MultiselectDropdownOptionModel,
    OPTION_ALL
} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

export interface SubmitProductAvailabilitiesData {
    isEditMode: boolean;
    productId: number;
    productAvailabilityIds: number[];
    description: string;
    isReturnTrip: boolean;
    isReturnTripChanged: boolean;
    startDate: moment.Moment;
    startDateChanged: boolean;
    endDate: moment.Moment;
    endDateChanged: boolean;
    startTime: number;
    startTimeChanged: boolean;
    daysOfWeek: DayOfWeekEnum[];
    daysOfWeekChanged: boolean;
    unlimitedQuantity: boolean;
    unlimitedQuantityChanged: boolean;
    totalQuantity: number;
    totalQuantityChanged: boolean;
    departureGroupId: number;
    departureGroupIdChanged: boolean;
    letterGroupId: number;
    letterGroupIdChanged: boolean;
    bidId: number;
    bidIdChanged: boolean;
}

@Component({
    selector: 'app-edit-product-availabilities-dialog-form',
    templateUrl: './edit-product-availabilities-dialog-form.component.html'
})

export class EditProductAvailabilitiesDialogFormComponent implements OnChanges {

    @Input() isEditMode: boolean;
    @Input() productId: number;
    @Input() productAvailabilities: ProductAvailabilityResponseModel[];
    @Input() departureGroups: DropdownOptionModel[];
    @Input() letterGroups: DropdownOptionModel[];
    @Input() bids: DropdownOptionModel[];

    @Output() submit: EventEmitter<SubmitProductAvailabilitiesData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    DIRECTION_REGULAR: DirectionEnum = DirectionEnum.REGULAR;
    DIRECTION_RETURN: DirectionEnum = DirectionEnum.RETURN;

    form: FormGroup;

    directions: DropdownOptionModel[] = [];
    daysOfWeek: MultiselectDropdownOptionModel[] = [];

    hasError: boolean = false;
    errorMessage: string = "";

    isDescriptionDisabled: boolean = false;

    private originalIsReturnTrip: boolean = false;
    private originalStartDate: moment.Moment = null;
    private originalEndDate: moment.Moment = null;
    private originalStartTime: number = null;
    private originalDaysOfWeek: DayOfWeekEnum[] = [];
    private originalUnlimitedQuantity: boolean = false;
    private originalTotalQuantity: number = null;
    private originalDepartureGroupId: number = null;
    private originalLetterGroupId: number = null;
    private originalBidId: number = null;

    constructor() {

        // Populate "Directions" dropdown
        this.directions = [
            new DropdownOptionModel("Outgoing", this.DIRECTION_REGULAR.toString(), null),
            new DropdownOptionModel("Return", this.DIRECTION_RETURN.toString(), null)
        ];

        // Populate "Days of Week" dropdown
        this.daysOfWeek = [
            new MultiselectDropdownOptionModel("Sunday", DayOfWeekEnum.SUNDAY),
            new MultiselectDropdownOptionModel("Monday", DayOfWeekEnum.MONDAY),
            new MultiselectDropdownOptionModel("Tuesday", DayOfWeekEnum.TUESDAY),
            new MultiselectDropdownOptionModel("Wednesday", DayOfWeekEnum.WEDNESDAY),
            new MultiselectDropdownOptionModel("Thursday", DayOfWeekEnum.THURSDAY),
            new MultiselectDropdownOptionModel("Friday", DayOfWeekEnum.FRIDAY),
            new MultiselectDropdownOptionModel("Saturday", DayOfWeekEnum.SATURDAY)
        ];

        this.form = new FormGroup({
            description: new FormControl(),
            direction: new FormControl(),
            startDate: new FormControl(),
            endDate: new FormControl(),
            startTime: new FormControl(),
            daysOfWeek: new FormControl(),
            totalQuantity: new FormControl(),
            departureGroupId: new FormControl(),
            letterGroupId: new FormControl(),
            bidId: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.productAvailabilities) {
            this.initializeFormData();
            this.isDescriptionDisabled = this.isEditMode && this.productAvailabilities.length > 1;
        }
    }

    getHeader(): string {
        if (this.isEditMode) {
            if (this.productAvailabilities.length > 1) {
                return "Edit Schedules";
            } else {
                return "Edit Schedule";
            }
        } else {
            return "Add Schedule";
        }
    }

    getSubmitButtonLabel(): string {
        if (this.isEditMode) {
            return "Update";
        } else {
            return "Save";
        }
    }

    onSubmit() {

        let description: string = !this.isDescriptionDisabled ? this.form.value.description : null;
        let isReturnTrip: boolean = this.form.value.direction ? (<DirectionEnum>Number(this.form.value.direction) === DirectionEnum.RETURN) : null;
        let startDate: moment.Moment = this.form.value.startDate;
        let endDate: moment.Moment = this.form.value.endDate;
        let startTime: number = DateTimeUtility.parseTime(this.form.value.startTime);
        let daysOfWeek: DayOfWeekEnum[] = <DayOfWeekEnum[]>this.form.value.daysOfWeek.filter(dow => dow !== OPTION_ALL);
        let totalQuantity: number = this.form.value.totalQuantity ? +this.form.value.totalQuantity : null;
        let unlimitedQuantity: boolean = totalQuantity !== null && totalQuantity !== undefined && !isNaN(totalQuantity) && totalQuantity > 0 ? false : true;
        let departureGroupId: number = this.form.value.departureGroupId ? +this.form.value.departureGroupId : null;
        let letterGroupId: number = this.form.value.letterGroupId ? +this.form.value.letterGroupId : null;
        let bidId: number = this.form.value.bidId ? +this.form.value.bidId : null;

        let isReturnTripChanged: boolean = this.originalIsReturnTrip !== isReturnTrip;
        let startDateChanged: boolean = (this.originalStartDate === null && startDate !== null) || (this.originalStartDate !== null && startDate === null) || (this.originalStartDate !== null && startDate !== null && !this.originalStartDate.isSame(startDate));
        let endDateChanged: boolean = (this.originalEndDate === null && endDate !== null) || (this.originalEndDate !== null && endDate === null) || (this.originalEndDate !== null && endDate !== null && !this.originalEndDate.isSame(endDate));
        let startTimeChanged: boolean = this.originalStartTime !== startTime;
        let daysOfWeekChanged: boolean = this.originalDaysOfWeek.some((dow: DayOfWeekEnum) => daysOfWeek.indexOf(dow) === -1) || daysOfWeek.some((dow: DayOfWeekEnum) => this.originalDaysOfWeek.indexOf(dow) === -1);
        let totalQuantityChanged: boolean = this.originalTotalQuantity !== totalQuantity;
        let unlimitedQuantityChanged: boolean = this.originalUnlimitedQuantity !== unlimitedQuantity;
        let departureGroupIdChanged: boolean = this.originalDepartureGroupId !== departureGroupId;
        let letterGroupIdChanged: boolean = this.originalLetterGroupId !== letterGroupId;
        let bidIdChanged: boolean = this.originalBidId !== bidId;

        // Product availability's description is mandatory
        if (!this.isDescriptionDisabled && (!description || description.trim().length === 0)) {
            this.hasError = true;
            this.errorMessage = "Please enter schedule name";
            return;
        }

        if (isReturnTrip === null) {
            this.hasError = true;
            this.errorMessage = "Please choose direction";
            return;
        }

        if (startDate != null && endDate != null && startDate > endDate) {
            this.hasError = true;
            this.errorMessage = "Start date can't be after end date";
            return;
        }

        if (isNaN(startTime) || startTime < 0 || startTime > 2359) {
            this.hasError = true;
            this.errorMessage = "Please enter valid start time (00:00 AM - 11:59 PM, or 00:00 - 23:59, or 0000 - 2359)";
            return;
        }

        if (daysOfWeek === null || daysOfWeek === undefined || daysOfWeek.length === 0) {
            this.hasError = true;
            this.errorMessage = "Please choose day(s) of week";
            return;
        }
        if (totalQuantity != null && (totalQuantity <= 0 || isNaN(totalQuantity))) {
            this.hasError = true;
            this.errorMessage = "Please enter valid total quantity";
            return;
        }

        if (!unlimitedQuantity && (isNaN(totalQuantity) || totalQuantity <= 0)) {
            this.hasError = true;
            this.errorMessage = "Please enter valid total quantity";
            return;
        }

        let productAvailabilityIds: number[] = null;
        if (this.isEditMode) {
            productAvailabilityIds = this.productAvailabilities.map((pa) => pa.productAvailabilityId);
        }

        this.submit.emit({
            isEditMode: this.isEditMode,
            productId: this.productId,
            productAvailabilityIds: productAvailabilityIds,
            description: description,
            isReturnTrip: isReturnTrip,
            isReturnTripChanged: isReturnTripChanged,
            startDate: startDate,
            startDateChanged: startDateChanged,
            endDate: endDate,
            endDateChanged: endDateChanged,
            startTime: startTime,
            startTimeChanged: startTimeChanged,
            daysOfWeek: daysOfWeek,
            daysOfWeekChanged: daysOfWeekChanged,
            unlimitedQuantity: unlimitedQuantity,
            unlimitedQuantityChanged: unlimitedQuantityChanged,
            totalQuantity: totalQuantity,
            totalQuantityChanged: totalQuantityChanged,
            departureGroupId: departureGroupId,
            departureGroupIdChanged: departureGroupIdChanged,
            letterGroupId: letterGroupId,
            letterGroupIdChanged: letterGroupIdChanged,
            bidId: bidId,
            bidIdChanged: bidIdChanged
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {

        let description: string = "";
        let direction: DirectionEnum = DirectionEnum.REGULAR;
        let startDate: moment.Moment = null;
        let endDate: moment.Moment = null;
        let startTime: number = null;
        let daysOfWeek: DayOfWeekEnum[] = [];
        let totalQuantity: number = null;
        let departureGroupId: number = null;
        let letterGroupId: number = null;
        let bidId: number = null;

        if (this.isEditMode) {
            if (this.productAvailabilities.length === 1) {

                description = this.productAvailabilities[0].description;
                direction = this.productAvailabilities[0].isReturnTrip ? DirectionEnum.RETURN : DirectionEnum.REGULAR;
                startDate = this.productAvailabilities[0].startDateInt ? DateTimeUtility.convertIntToDate(this.productAvailabilities[0].startDateInt) : null;
                endDate = this.productAvailabilities[0].endDateInt ? DateTimeUtility.convertIntToDate(this.productAvailabilities[0].endDateInt) : null;
                startTime = this.productAvailabilities[0].startTime;
                daysOfWeek = this.productAvailabilities[0].daysAvailableList;
                totalQuantity = this.productAvailabilities[0].unlimitedQuantity ? null : this.productAvailabilities[0].totalQuantity;
                departureGroupId = this.productAvailabilities[0].departureGroupId;
                letterGroupId = this.productAvailabilities[0].letterGroupId;
                bidId = this.productAvailabilities[0].bidId;

            } else if (this.productAvailabilities.length > 1) {

                description = "[";
                for (let i = 0; i < this.productAvailabilities.length; i++) {
                    description += this.productAvailabilities[i].description;
                    if (i < this.productAvailabilities.length - 1) {
                        description += ", ";
                    }
                }
                description += "]";

                let isReturnTripFirstValue: boolean = this.productAvailabilities[0].isReturnTrip;
                let isReturnTripAllItemsSameValue: boolean = !this.productAvailabilities.some(pa => pa.isReturnTrip !== isReturnTripFirstValue);
                if (isReturnTripAllItemsSameValue) {
                    direction = isReturnTripFirstValue ? DirectionEnum.RETURN : DirectionEnum.REGULAR;
                }

                let startDateIntFirstValue: number = this.productAvailabilities[0].startDateInt;
                let startDateIntAllItemsSameValue: boolean = !this.productAvailabilities.some(pa => pa.startDateInt !== startDateIntFirstValue);
                if (startDateIntAllItemsSameValue) {
                    startDate = DateTimeUtility.convertIntToDate(startDateIntFirstValue);
                }

                let endDateIntFirstValue: number = this.productAvailabilities[0].endDateInt;
                let endDateIntAllItemsSameValue: boolean = !this.productAvailabilities.some(pa => pa.endDateInt !== endDateIntFirstValue);
                if (endDateIntAllItemsSameValue) {
                    endDate = DateTimeUtility.convertIntToDate(endDateIntFirstValue);
                }

                let startTimeFirstValue: number = this.productAvailabilities[0].startTime;
                let startTimeAllItemsSameValue: boolean = !this.productAvailabilities.some(pa => pa.startTime !== startTimeFirstValue);
                if (startTimeAllItemsSameValue) {
                    startTime = startTimeFirstValue;
                }

                let daysAvailableFirstValue: number = this.productAvailabilities[0].daysAvailable;
                let daysAvailableAllItemsSameValue: boolean = !this.productAvailabilities.some(pa => pa.daysAvailable !== daysAvailableFirstValue);
                if (daysAvailableAllItemsSameValue) {
                    daysOfWeek = this.productAvailabilities[0].daysAvailableList;
                }

                let totalQuantityFirstValue: number = this.productAvailabilities[0].totalQuantity;
                let totalQuantityAllItemsSameValue: boolean = !this.productAvailabilities.some(pa => pa.totalQuantity !== totalQuantityFirstValue);
                if (totalQuantityAllItemsSameValue) {
                    totalQuantity = totalQuantityFirstValue;
                }

                let departureGroupIdFirstValue: number = this.productAvailabilities[0].departureGroupId;
                let departureGroupIdAllItemsSameValue: boolean = !this.productAvailabilities.some(pa => pa.departureGroupId !== departureGroupIdFirstValue);
                if (departureGroupIdAllItemsSameValue) {
                    departureGroupId = departureGroupIdFirstValue;
                }

                let letterGroupIdFirstValue: number = this.productAvailabilities[0].letterGroupId;
                let letterGroupIdAllItemsSameValue: boolean = !this.productAvailabilities.some(pa => pa.letterGroupId !== letterGroupIdFirstValue);
                if (letterGroupIdAllItemsSameValue) {
                    letterGroupId = letterGroupIdFirstValue;
                }

                let bidIdFirstValue: number = this.productAvailabilities[0].bidId;
                let bidIdAllItemsSameValue: boolean = !this.productAvailabilities.some(pa => pa.bidId !== bidIdFirstValue);
                if (bidIdAllItemsSameValue) {
                    bidId = bidIdFirstValue;
                }
            }
        }

        // Populate original values
        this.originalIsReturnTrip = direction === DirectionEnum.RETURN;
        this.originalStartDate = startDate;
        this.originalEndDate = endDate;
        this.originalStartTime = startTime;
        this.originalDaysOfWeek = [...daysOfWeek];
        this.originalTotalQuantity = totalQuantity;
        this.originalDepartureGroupId = departureGroupId;
        this.originalLetterGroupId = letterGroupId;
        this.originalBidId = bidId;

        this.form.patchValue({
            description: description,
            direction: direction.toString(),
            startDate: startDate,
            endDate: endDate,
            startTime: startTime !== null && startTime !== undefined ? DateTimeUtility.getTimeFriendly(startTime) : null,
            daysOfWeek: daysOfWeek,
            totalQuantity: totalQuantity,
            departureGroupId: departureGroupId,
            letterGroupId: letterGroupId,
            bidId: bidId
        });
    }
}
