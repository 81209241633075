import { catchError } from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { DialogSandbox } from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import { DomainResponseModel } from '../../../../../../../../core/modules/rest/domain/response/domain-response.model';
import { AppDialogsService } from "../../../../../../../../core/services/app-dialogs.service";
import { Store } from "@ngrx/store";
import { DomainRestService } from '../../../../../../../../core/modules/rest/domain/domain-rest.service';
import { RootSandbox } from "../../../../../../../../core/store/root.sandbox";
import { CreateBaseResponseModel } from "../../../../../../../../core/modules/rest/create-base-response.model";
import { CreateDomainRequestModel } from '../../../../../../../../core/modules/rest/domain/request/create-domain-request.model';
import { HttpErrorModel } from "../../../../../../../../core/modules/rest/http-error.model";
import { UpdateDomainRequestModel } from '../../../../../../../../core/modules/rest/domain/request/update-domain-request.model';
import { DomainFormSubmitDataModel } from '../model/domain-form-submit-data.model';

@Injectable()
export class EditDomainDialogSandbox extends DialogSandbox {

    domain$: Observable<DomainResponseModel> = this.store.select(reducer.domain_selector);

    constructor(appDialogsService: AppDialogsService,
                private domainRestService: DomainRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadDomain( domainId: number ) {

        this.showLoader();

        this.domainRestService.getById( domainId ).subscribe((domain: DomainResponseModel) => {
            this.store.dispatch(new actions.SetDomain( domain ));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    createDomain( domainName: string ): Observable<CreateBaseResponseModel> {
        const request = new CreateDomainRequestModel( domainName );

        return this.domainRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while creating domain", error);
            }));
    }

    updateDomain( domain: DomainFormSubmitDataModel ): Observable<any> {
        const request = new UpdateDomainRequestModel( domain.domainId, domain.domainName );

        return this.domainRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while updating domain", error);
            }));
    }
}
