export class PassTierDataModel {
    tierId: number;
    tierDescription: string;
    cost: number;

    constructor(tierId: number, tierDescription: string, cost: number) {
        this.tierId = tierId;
        this.tierDescription = tierDescription;
        this.cost = cost;
    }
}
