export class UpdatePricingDateRangePriorityRequestModel {
    pricingDateRangeId: number;
    productId: number;
    passId: number;
    up: boolean;

    constructor(pricingDateRangeId: number, productId: number, passId: number, up: boolean) {
        this.pricingDateRangeId = pricingDateRangeId;
        this.productId = productId;
        this.passId = passId;
        this.up = up;
    }
}
