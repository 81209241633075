import {TreeData} from "../../../../../../shared/components/tree/tree.component";
import {TreeItemTypeEnum} from "./tree-item-type.enum";

export class TreeNodeDataModel implements TreeData {

    type: TreeItemTypeEnum;
    id: number;
    parentId: number;
    label: string;

    constructor(type: TreeItemTypeEnum, id: number, parentId: number, label: string) {
        this.type = type;
        this.id = id;
        this.parentId = parentId;
        this.label = label;
    }
}
