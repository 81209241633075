import {Action} from '@ngrx/store';
import {SortByModel} from '../../../../../../../shared/model/sort-by.model';
import {ProductSimpleResponseModel} from '../../../../../../../core/modules/rest/product/response/product-simple-response.model';
import {CommissionReportResponseModel} from '../../../../../../../core/modules/rest/reporting/financial/commission-report/response/commission-report-response.model';
import {RoleResponseModel} from '../../../../../../../core/modules/rest/role/response/role-response.model';
import {UserResponseModel} from '../../../../../../../core/modules/rest/user/response/user-response.model';
import {PassSimpleResponseModel} from "../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {FavoriteResponseModel} from "../../../../../../../core/modules/rest/favorite/response/favorite-response.model";
import * as moment from "moment";

export const UPDATE_FAVORITES_LOADER_ACTION = '[Commission Report] Update favorites loader';
export const UPDATE_FAVORITES_ACTION = '[Commission Report] Update favorites';
export const UPDATE_SELECTED_FAVORITE_ID_ACTION = '[Commission Report] Update selected favorite id';
export const UPDATE_ROLES_LOADER_ACTION = '[Commission Report] Update roles loader';
export const UPDATE_ROLES_ACTION = '[Commission Report] Update roles';
export const UPDATE_USERS_LOADER_ACTION = '[Commission Report] Update users loader';
export const UPDATE_USERS_ACTION = '[Commission Report] Update users';
export const UPDATE_USERS_FOR_SELECTED_ROLES_ACTION = '[Commission Report] Update users for selected roles';
export const UPDATE_PASSES_LOADER_ACTION = '[Commission Report] Update passes loader';
export const UPDATE_PASSES_ACTION = '[Commission Report] Update passes';
export const UPDATE_PRODUCTS_LOADER_ACTION = '[Commission Report] Update products loader';
export const UPDATE_PRODUCTS_ACTION = '[Commission Report] Update products';
export const UPDATE_SELECTED_START_DATE_ACTION = '[Commission Report] Update selected start date';
export const UPDATE_SELECTED_END_DATE_ACTION = '[Commission Report] Update selected end date';
export const UPDATE_SELECTED_ROLE_IDS_ACTION = '[Commission Report] Update selected role ids';
export const UPDATE_SELECTED_USER_IDS_ACTION = '[Commission Report] Update selected user ids';
export const UPDATE_SELECTED_PASS_IDS_ACTION = '[Commission Report] Update selected pass ids';
export const UPDATE_SELECTED_PRODUCT_IDS_ACTION = '[Commission Report] Update selected product ids';
export const INIT_SEARCH_ACTION = '[Commission Report] Init search';
export const UPDATE_CURRENT_SEARCH_DATA_ACTION = '[Commission Report] Update current search data';
export const UPDATE_CURRENT_SEARCH_DATA_SORT_BY_ACTION = '[Commission Report] Update current search data sort by';
export const CLEAR_SEARCH_DATA_ACTION = '[Commission Report] Clear search data';
export const RESET_STATE_ACTION = '[Commission Report] Reset state';


export class UpdateFavoritesLoader implements Action {
    readonly type = UPDATE_FAVORITES_LOADER_ACTION;

    constructor(public payload: { loadingFavorites: boolean }) {
    }
}

export class UpdateFavorites implements Action {
    readonly type = UPDATE_FAVORITES_ACTION;

    constructor(public payload: { favorites: FavoriteResponseModel[], favoriteId: number, favoriteCreatedUpdated: boolean }) {
    }
}

export class UpdateSelectedFavoriteId implements Action {
    readonly type = UPDATE_SELECTED_FAVORITE_ID_ACTION;

    constructor(public payload: { favoriteId: number }) {
    }
}

export class UpdateRolesLoader implements Action {
    readonly type = UPDATE_ROLES_LOADER_ACTION;

    constructor(public payload: { loadingRoles: boolean }) {
    }
}

export class UpdateRoles implements Action {
    readonly type = UPDATE_ROLES_ACTION;

    constructor(public payload: { roles: RoleResponseModel[] }) {
    }
}

export class UpdateUsersLoader implements Action {
    readonly type = UPDATE_USERS_LOADER_ACTION;

    constructor(public payload: { loadingUsers: boolean }) {
    }
}

export class UpdateUsers implements Action {
    readonly type = UPDATE_USERS_ACTION;

    constructor(public payload: { users: UserResponseModel[] }) {
    }
}

export class UpdateUsersForSelectedRoles implements Action {
    readonly type = UPDATE_USERS_FOR_SELECTED_ROLES_ACTION;

    constructor(public payload: { usersForSelectedRoles: UserResponseModel[] }) {
    }
}

export class UpdatePassesLoader implements Action {
    readonly type = UPDATE_PASSES_LOADER_ACTION;

    constructor(public payload: { loadingPasses: boolean }) {
    }
}

export class UpdatePasses implements Action {
    readonly type = UPDATE_PASSES_ACTION;

    constructor(public payload: { passes: PassSimpleResponseModel[] }) {
    }
}

export class UpdateProductsLoader implements Action {
    readonly type = UPDATE_PRODUCTS_LOADER_ACTION;

    constructor(public payload: { loadingProducts: boolean }) {
    }
}

export class UpdateProducts implements Action {
    readonly type = UPDATE_PRODUCTS_ACTION;

    constructor(public payload: { products: ProductSimpleResponseModel[] }) {
    }
}

export class UpdateSelectedStartDate implements Action {
    readonly type = UPDATE_SELECTED_START_DATE_ACTION;

    constructor(public payload: { selectedStartDate: moment.Moment }) {
    }
}

export class UpdateSelectedEndDate implements Action {
    readonly type = UPDATE_SELECTED_END_DATE_ACTION;

    constructor(public payload: { selectedEndDate: moment.Moment }) {
    }
}

export class UpdateSelectedRoleIds implements Action {
    readonly type = UPDATE_SELECTED_ROLE_IDS_ACTION;

    constructor(public payload: { selectedRoleIds: number[] }) {
    }
}

export class UpdateSelectedUserIds implements Action {
    readonly type = UPDATE_SELECTED_USER_IDS_ACTION;

    constructor(public payload: { selectedUserIds: number[] }) {
    }
}

export class UpdateSelectedPassIds implements Action {
    readonly type = UPDATE_SELECTED_PASS_IDS_ACTION;

    constructor(public payload: { selectedPassIds: number[] }) {
    }
}

export class UpdateSelectedProductIds implements Action {
    readonly type = UPDATE_SELECTED_PRODUCT_IDS_ACTION;

    constructor(public payload: { selectedProductIds: number[] }) {
    }
}

export class InitSearch implements Action {
    readonly type = INIT_SEARCH_ACTION;

    constructor() {
    }
}

export class UpdateCurrentSearchData implements Action {
    readonly type = UPDATE_CURRENT_SEARCH_DATA_ACTION;

    constructor(public payload: { response: CommissionReportResponseModel }) {
    }
}

export class UpdateCurrentSearchDataSortBy implements Action {
    readonly type = UPDATE_CURRENT_SEARCH_DATA_SORT_BY_ACTION;

    constructor(public payload: { sortBy: SortByModel }) {
    }
}

export class ClearSearchData implements Action {
    readonly type = CLEAR_SEARCH_DATA_ACTION;

    constructor() {
    }
}

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}


export type Actions =
    UpdateFavoritesLoader |
    UpdateFavorites |
    UpdateSelectedFavoriteId |
    UpdateRolesLoader |
    UpdateRoles |
    UpdateUsersLoader |
    UpdateUsers |
    UpdateUsersForSelectedRoles |
    UpdatePassesLoader |
    UpdatePasses |
    UpdateProductsLoader |
    UpdateProducts |
    UpdateSelectedStartDate |
    UpdateSelectedEndDate |
    UpdateSelectedRoleIds |
    UpdateSelectedUserIds |
    UpdateSelectedPassIds |
    UpdateSelectedProductIds |
    InitSearch |
    UpdateCurrentSearchData |
    UpdateCurrentSearchDataSortBy |
    ClearSearchData |
    ResetState;
