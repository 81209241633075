import {PermissionEnum} from '../../../../../shared/enums/permission.enum';

export class UserInfoResponseModel {
    userId: number;
    roleId: number;
    username: string;
    displayName: string;
    email: string;
    printOrder: boolean;
    showOrderSummary: boolean;
    useTouchScreenLayout: boolean;
    isLocked: boolean;
    failedLoginAttemptsCount: number;
    roleUseComplexPassword: boolean;
    roleUseMfa: boolean;
    roleUseLoginLocations: boolean;
    isMfaActivated: boolean;
    useLoginLocations: boolean;
    locationId: number;
    locationDescription: string;
    permissions: PermissionEnum[];
}
