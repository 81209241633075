import {Injectable} from "@angular/core";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {DepartureGroupResponseModel} from "./response/departure-group-response.model";
import {CreateDepartureGroupRequestModel} from "./request/create-departure-group-request.model";
import {UpdateDepartureGroupRequestModel} from "./request/update-departure-group-request.model";

@Injectable()
export class DepartureGroupRestService {

    constructor(private httpClient: HttpClient) {
    }

    getById(departureGroupId: number): Observable<DepartureGroupResponseModel> {
        return this.httpClient.get<DepartureGroupResponseModel>(environment.server_api_url + '/api/secured/departureGroup/getById/' + departureGroupId);
    }

    getAll(): Observable<DepartureGroupResponseModel[]> {
        return this.httpClient.get<DepartureGroupResponseModel[]>(environment.server_api_url + '/api/secured/departureGroup/getAll');
    }

    getAllActive(): Observable<DepartureGroupResponseModel[]> {
        return this.httpClient.get<DepartureGroupResponseModel[]>(environment.server_api_url + '/api/secured/departureGroup/getAllActive');
    }

    create(request: CreateDepartureGroupRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/departureGroup/create', request);
    }

    update(request: UpdateDepartureGroupRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/departureGroup/update', request, {responseType: 'text'});
    }

    activate(departureGroupId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/departureGroup/activate', departureGroupId, {responseType: 'text'});
    }

    deactivate(departureGroupId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/departureGroup/deactivate', departureGroupId, {responseType: 'text'});
    }
}
