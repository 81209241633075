export class ChangePasswordRequest {
    private username: string;
    private password: string;
    private newPassword: string;

    constructor(username: string, password: string, newPassword: string) {
        this.username = username;
        this.password = password;
        this.newPassword = newPassword;
    }
}
