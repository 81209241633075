import { Action } from "@ngrx/store";
import { CustomerResponseModel } from "../../../../../../../../../core/modules/rest/customer/response/customer-response.model"; 

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Customer :: MAIN] Reset State';
export const SET_CUSTOMER_ACTION = '[Dialog - Create/Edit Customer :: MAIN] Set Customer';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetCustomer implements Action {
    readonly type = SET_CUSTOMER_ACTION;
    constructor(public customer: CustomerResponseModel) {}
}

export type All = ResetState | SetCustomer;
