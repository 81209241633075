
import { mergeMap, take, flatMap, map } from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { TreeNode } from "../../../../../../shared/components/tree/tree.component";
import { select, Store } from "@ngrx/store";
import { PassSimpleResponseModel } from "../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import { AppDialogsService } from "../../../../../../core/services/app-dialogs.service";
import { RootSandbox } from "../../../../../../core/store/root.sandbox";
import { PassRestService } from "../../../../../../core/modules/rest/pass/pass-rest.service";
import { TreeItemTypeEnum } from "../model/tree-item-type.enum";
import { TreeNodeDataModel } from "../model/tree-node-data.model";
import { PassPricingDateRangesDataModel } from "../model/pass-pricing-date-ranges-data.model";
import { TemplateItemResponseModel } from "../../../../../../core/modules/rest/template/response/template-item-response.model";
import { TemplateItemDataModel } from "../model/template-item-data.model";
import { PassResponseModel } from "../../../../../../core/modules/rest/pass/response/pass-response.model";
import { PricingDateRangeRestService } from "../../../../../../core/modules/rest/pricing-date-range/pricing-date-range-rest.service";
import { PricingDateRangeFullResponseModel } from "../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-full-response.model";
import { PricingDateRangeDataModel } from "../model/pricing-date-range-data.model";
import { TemplateRestService } from "../../../../../../core/modules/rest/template/template-rest.service";
import { PassTabItemEnum } from "../model/pass-tab-item.enum";
import { PassBasicInfoDataModel } from "../model/pass-basic-info-data.model";
import { PassProductsDataModel } from "../model/pass-products-data.model";
import { PassImagesDataModel } from "../model/pass-images-data.model";
import { PassFlagsDataModel } from "../model/pass-flags-data.model";
import { PassValidityDataModel } from "../model/pass-validity-data.model";
import { PassEmailHtmlTemplatesDataModel } from "../model/pass-email-html-templates-data.model";
import { PassTemplateItemsDataModel } from "../model/pass-template-items-data.model";
import { SetPassTemplateItemsFlagDataModel } from "../model/set-pass-template-items-flag-data.model";
import { SetPassPricingDateRangesFlagDataModel } from "../model/set-pass-pricing-date-ranges-flag-data.model";
import { ImageResponseModel } from "../../../../../../core/modules/rest/image-response.model";
import { DateTimeUtility } from "../../../../../../shared/utils/date-time-utility";
import * as moment from "moment";
import { LocationRestService } from "../../../../../../core/modules/rest/location/location-rest.service";
import { LocationsResponseModel } from "../../../../../../core/modules/rest/location/response/locations-response.model";
import { LocationResponseModel } from "../../../../../../core/modules/rest/location/response/location-response.model";
import { TierRestService } from "../../../../../../core/modules/rest/tier/tier-rest.service";
import { TiersResponseModel } from "../../../../../../core/modules/rest/tier/response/tiers-response.model";
import { TierResponseModel } from "../../../../../../core/modules/rest/tier/response/tier-response.model";
import { PassPricingDateRangesSidebarFilterTypeEnum } from "../model/pass-pricing-date-ranges-sidebar-filter-type.enum";
import { PassPricingDateRangesSidebarFilterDataModel } from "../model/pass-pricing-date-ranges-sidebar-filter-data.model";
import { GetPricingDateRangesFilteredRequestModel } from "../../../../../../core/modules/rest/pricing-date-range/request/get-pricing-date-ranges-filtered-request.model";
import { UpdatePricingDateRangePriorityRequestModel } from "../../../../../../core/modules/rest/pricing-date-range/request/update-pricing-date-range-priority-request.model";
import { SetPassPricingDateRangeFlagDataModel } from "../model/set-pass-pricing-date-range-flag-data.model";
import { DateTimeDescriptor } from "../../../../../../shared/model/date-time-descriptor.model";
import { PassProductResponseModel } from "../../../../../../core/modules/rest/pass/response/pass-product-response.model";
import { ProductDataModel } from "../model/product-data.model";
import { PassCostsDataModel } from "../model/pass-costs-data.model";
import { PassCostResponseModel } from "../../../../../../core/modules/rest/pass/response/pass-cost-response.model";
import { CostDataModel } from "../model/cost-data.model";
import { CostTypeEnum } from "../../../../../../shared/enums/cost-type.enum";
import { PricingDateRangesFullResponseModel } from "../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-ranges-full-response.model";
import { FilterDataModel } from "../../pass-card-management/model/filter-data-model";
import { SortByModel } from "../../../../../../shared/model/sort-by.model";
import { ProductPricingDateRangesDataModel } from "../../categories-and-products/model/product-pricing-date-ranges-data.model";
import { ProductPricingDateRangesSidebarFilterDataModel } from "../../categories-and-products/model/product-pricing-date-ranges-sidebar-filter-data.model";
import { SortByRequestModel } from "../../../../../../core/modules/rest/reporting/request/sort-by-request.model";

@Injectable()
export class PassesSandbox {

    loadingPasses$: Observable<boolean> = this.store.pipe(select(reducer.loading_passes_selector));

    passesTree$: Observable<TreeNode[]> = this.store.pipe(select(reducer.passes_tree_selector));

    showInactiveItems$: Observable<boolean> = this.store.pipe(select(reducer.show_inactive_items_selector));

    item$: Observable<TreeNode> = this.store.pipe(select(reducer.item_selector));

    node$: Observable<TreeNodeMaterial> = this.store.pipe(select(reducer.node_selector));

    itemType$: Observable<TreeItemTypeEnum> = this.store.pipe(select(reducer.item_type_selector));

    itemId$: Observable<number> = this.store.pipe(select(reducer.item_id_selector));

    itemActive$: Observable<boolean> = this.store.pipe(select(reducer.item_active_selector));

    loadingPassFull$: Observable<boolean> = this.store.pipe(select(reducer.loading_pass_full_selector));

    loadingPassImages$: Observable<boolean> = this.store.pipe(select(reducer.loading_pass_images_selector));

    loadingPassTemplateItems$: Observable<boolean> = this.store.pipe(select(reducer.loading_pass_template_items_selector));

    loadingPassPricingDateRanges$: Observable<boolean> = this.store.pipe(select(reducer.loading_pass_pricing_date_ranges_selector));

    selectedPassTabItem$: Observable<PassTabItemEnum> = this.store.pipe(select(reducer.selected_pass_tab_item_selector));

    itemPassBasicInfo$: Observable<PassBasicInfoDataModel> = this.store.pipe(select(reducer.item_pass_basic_info_selector));

    itemPassProducts$: Observable<PassProductsDataModel> = this.store.pipe(select(reducer.item_pass_products_selector));

    itemPassImages$: Observable<PassImagesDataModel> = this.store.pipe(select(reducer.item_pass_images_selector));

    itemPassFlags$: Observable<PassFlagsDataModel> = this.store.pipe(select(reducer.item_pass_flags_selector));

    itemPassValidity$: Observable<PassValidityDataModel> = this.store.pipe(select(reducer.item_pass_validity_selector));

    itemPassEmailHtmlTemplates$: Observable<PassEmailHtmlTemplatesDataModel> = this.store.pipe(select(reducer.item_pass_email_html_templates_selector));

    itemPassCosts$: Observable<PassCostsDataModel> = this.store.pipe(select(reducer.item_pass_costs_selector));

    itemPassTemplateItems$: Observable<PassTemplateItemsDataModel> = this.store.pipe(select(reducer.item_pass_template_items_selector));

    itemPassPricingDateRanges$: Observable<PassPricingDateRangesDataModel> = this.store.pipe(select(reducer.item_pass_pricing_date_ranges_selector));

    passPricingDateRangesFrom$: Observable<number> = this.store.pipe(select(reducer.pass_pricing_date_ranges_from_selector));

    passPricingDateRangesSidebarFilterOpen$: Observable<boolean> = this.store.pipe(select(reducer.pass_pricing_date_ranges_sidebar_filter_open_selector));

    passPricingDateRangesSidebarFilterPickupLocations$: Observable<LocationResponseModel[]> = this.store.pipe(select(reducer.pass_pricing_date_ranges_sidebar_filter_pickup_locations_selector));

    passPricingDateRangesSidebarFilterDropoffLocations$: Observable<LocationResponseModel[]> = this.store.pipe(select(reducer.pass_pricing_date_ranges_sidebar_filter_dropoff_locations_selector));

    passPricingDateRangesSidebarFilterTiers$: Observable<TierResponseModel[]> = this.store.pipe(select(reducer.pass_pricing_date_ranges_sidebar_filter_tiers_selector));

    passPricingDateRangesSidebarFilterData$: Observable<PassPricingDateRangesSidebarFilterDataModel> = this.store.pipe(select(reducer.pass_pricing_date_ranges_sidebar_filter_data_selector));

    passPricingDateRangesSidebarFilterCurrentFocus$: Observable<PassPricingDateRangesSidebarFilterTypeEnum> = this.store.pipe(select(reducer.pass_pricing_date_ranges_sidebar_filter_current_focus_selector));

    passPricingDateRangesCurrentSearchDataSortBy$: Observable<SortByModel> = this.store.pipe(select(reducer.pass_pricing_date_ranges_current_search_data_sort_by_selector));

    readonly PASS_PRICING_DATE_RANGES_PAGE_SIZE: number = 40;

    constructor(private store: Store<reducer.State>,
        private passRestService: PassRestService,
        private templateRestService: TemplateRestService,
        private pricingDateRangeRestService: PricingDateRangeRestService,
        private locationRestService: LocationRestService,
        private tierRestService: TierRestService,
        private appDialogsService: AppDialogsService,
        private rootSandbox: RootSandbox) {
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    /**
     * PASS TREE ACTIONS
     */

    loadPasses() {

        this.store.dispatch(new actions.SetLoadingPasses(true));

        this.passRestService.getAll()
            .subscribe((passes: PassSimpleResponseModel[]) => {
                let nodes: TreeNode[] = this.getPassesTree(passes);
                this.store.dispatch(new actions.SetLoadingPasses(false));
                this.store.dispatch(new actions.SetPassesTree(nodes));
            },
            () => this.store.dispatch(new actions.SetLoadingPasses(false)));
    }

    passNodeSelected(itemPass: TreeNode<TreeNodeDataModel>) {
        this.item$.pipe(
            take(1))
            .subscribe((item: TreeNode<TreeNodeDataModel>) => {
                if (!item || !item.data || item.data.type !== itemPass.data.type || item.data.id !== itemPass.data.id) {
                    this.setPass(itemPass);
                }
            });
    }

    loadNode(passedNode: TreeNodeMaterial) {
        this.node$.pipe(
            take(1),
            map((node: TreeNodeMaterial) => {
                node = passedNode
                this.store.dispatch(new actions.SetSelectedNode(passedNode));
            })
        ).subscribe();
    }

    setPass(item: TreeNode<TreeNodeDataModel>) {

        let passId: number = item.data.id;

        this.store.dispatch(new actions.SetSelectedItem(item, TreeItemTypeEnum.PASS, passId, item.active));
        this.store.dispatch(new actions.SetLoadingPassFull(true));

        combineLatest([this.passRestService.getPassById(passId), this.passRestService.getPassProductsByPassId(passId), this.passRestService.getPassCostsByPassId(passId), this.templateRestService.getTemplateItemsByPassId(passId), this.passPricingDateRangesSidebarFilterData$]).pipe(
            take(1),
            map(([pass, aPassProducts, aPassCosts, templateItems, passPricingDateRangesSidebarFilterData]: [PassResponseModel, PassProductResponseModel[], PassCostResponseModel[], TemplateItemResponseModel[], PassPricingDateRangesSidebarFilterDataModel]) => {

                let passBasicInfo: PassBasicInfoDataModel = {
                    passId: pass.passId,
                    active: pass.active,
                    description: pass.description,
                    startDateInt: pass.startDateInt,
                    startDateMS: pass.startDateMS,
                    startDateFriendly: pass.startDateFriendly,
                    endDateInt: pass.endDateInt,
                    endDateMS: pass.endDateMS,
                    endDateFriendly: pass.endDateFriendly,
                    details: pass.details,
                    purchaseType: pass.purchaseType
                };

                let passProducts: PassProductsDataModel = {
                    passId: pass.passId,
                    products: aPassProducts.map((pp: PassProductResponseModel) => new ProductDataModel(pp))
                };

                let passImages: PassImagesDataModel = {
                    passId: pass.passId,
                    passImage: {
                        imageAmazonFilePath: pass.passImageAmazonFilePath,
                        imageBase64: pass.passImageBase64
                    },
                    passCardBackgroundImage: {
                        imageAmazonFilePath: pass.passCardBackgroundImageAmazonFilePath,
                        imageBase64: pass.passCardBackgroundImageBase64
                    }
                };

                let passFlags: PassFlagsDataModel = {
                    passId: pass.passId,
                    shouldSendBarcodeQRCodeImageAttachment: pass.shouldSendBarcodeQRCodeImageAttachment,
                    usesPregeneratedBarcodeOnAndroid: pass.usesPregeneratedBarcodeOnAndroid
                };

                let passValidity: PassValidityDataModel = {
                    passId: pass.passId,
                    isValidForDateInterval: pass.isValidForDateInterval,
                    validForStartDate: pass.validForStartDate,
                    validForStartDateMS: pass.validForStartDateMS,
                    validForStartDateFriendly: pass.validForStartDateFriendly,
                    validForEndDate: pass.validForEndDate,
                    validForEndDateMS: pass.validForEndDateMS,
                    validForEndDateFriendly: pass.validForEndDateFriendly,
                    isValidSincePurchaseForHours: pass.isValidSincePurchaseForHours,
                    validForHours: pass.validForHours,
                    isUsageLimiterPerOccurrence: pass.isUsageLimiterPerOccurrence,
                    usageLimiterForMinutes: pass.usageLimiterForMinutes,
                    isUnlimited: pass.isUnlimited,
                    initialQuantity: pass.initialQuantity,
                    predictedQuantity: pass.predictedQuantity
                };

                let passEmailHtmlTemplates: PassEmailHtmlTemplatesDataModel = {
                    passId: pass.passId,
                    createOrderEmailHtmlTemplateId: pass.createOrderEmailHtmlTemplateId,
                    createOrderEmailHtmlTemplateDescription: pass.createOrderEmailHtmlTemplateDescription,
                    voidOrderEmailHtmlTemplateId: pass.voidOrderEmailHtmlTemplateId,
                    voidOrderEmailHtmlTemplateDescription: pass.voidOrderEmailHtmlTemplateDescription,
                    termsAndConditionsEmailHtmlTemplateId: pass.termsAndConditionsEmailHtmlTemplateId,
                    termsAndConditionsEmailHtmlTemplateDescription: pass.termsAndConditionsEmailHtmlTemplateDescription,
                    createOrderEmailHtmlTemplateActive: pass.createOrderEmailHtmlTemplateActive,
                    voidOrderEmailHtmlTemplateActive: pass.voidOrderEmailHtmlTemplateActive,
                    termsAndConditionsEmailHtmlTemplateActive: pass.termsAndConditionsEmailHtmlTemplateActive

                };

                // Group pass costs by cost type and sort them by description
                let passAdditionalCosts: CostDataModel[] = aPassCosts.filter(pc => pc.costType === CostTypeEnum.ADDITIONAL).sort((pc1, pc2) => (pc1.costDescription > pc2.costDescription) ? 1 : -1);
                let passIncludedCosts: CostDataModel[] = aPassCosts.filter(pc => pc.costType === CostTypeEnum.INCLUDED).sort((pc1, pc2) => (pc1.costDescription > pc2.costDescription) ? 1 : -1);
                let passDiscounts: CostDataModel[] = aPassCosts.filter(pc => pc.costType === CostTypeEnum.DISCOUNT).sort((pc1, pc2) => (pc1.costDescription > pc2.costDescription) ? 1 : -1);
                let passAutoAppliedDiscounts: CostDataModel[] = aPassCosts.filter(pc => pc.costType === CostTypeEnum.AUTO_APPLIED_DISCOUNT).sort((pc1, pc2) => (pc1.costDescription > pc2.costDescription) ? 1 : -1);

                let passCosts: PassCostsDataModel = {
                    passId: pass.passId,
                    costs: [...passAdditionalCosts, ...passIncludedCosts, ...passDiscounts, ...passAutoAppliedDiscounts]
                };

                let passTemplateItems: PassTemplateItemsDataModel = {
                    passId: pass.passId,
                    templateId: pass.templateId,
                    templateItems: templateItems.map((ti: TemplateItemResponseModel) => new TemplateItemDataModel(ti))
                };

                this.store.dispatch(new actions.SetPassBasicInfo(passBasicInfo));
                this.store.dispatch(new actions.SetPassProducts(passProducts));
                this.store.dispatch(new actions.SetPassImages(passImages));
                this.store.dispatch(new actions.SetPassFlags(passFlags));
                this.store.dispatch(new actions.SetPassValidity(passValidity));
                this.store.dispatch(new actions.SetPassEmailHtmlTemplates(passEmailHtmlTemplates));
                this.store.dispatch(new actions.SetPassCosts(passCosts));
                this.store.dispatch(new actions.SetPassTemplateItems(passTemplateItems));
                this.store.dispatch(new actions.SetLoadingPassFull(false));

                // Trigger initial search for pricing date ranges
                this.loadPassPricingDateRanges(passPricingDateRangesSidebarFilterData);
            })
        ).subscribe();
    }

    /**
     * PASS ACTIONS
     */

    selectPassTabItem(passTabItem: PassTabItemEnum) {
        this.store.dispatch(new actions.SetSelectedPassTabItem(passTabItem));
    }

    enablePass(passId: number) {

        this.store.dispatch(new actions.SetLoadingPasses(true));

        this.passRestService.activate(passId).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("MultiPass can't be enabled");
                }

                return this.passRestService.getAll();
            }),
            take(1),
            map((passes: PassSimpleResponseModel[]) => {

                let nodes: TreeNode<TreeNodeDataModel>[] = this.getPassesTree(passes);

                this.store.dispatch(new actions.SetLoadingPasses(false));
                this.store.dispatch(new actions.SetPassesTree(nodes));
                this.store.dispatch(new actions.SetShowInactiveItems(true));

                let enabledPassNode: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(passId, TreeItemTypeEnum.PASS, nodes);

                // enabledPassNode.selected = true;

                this.setPass(enabledPassNode);
            })
        ).subscribe();
    }

    disablePass(passId: number) {

        this.store.dispatch(new actions.SetLoadingPasses(true));

        this.passRestService.deactivate(passId).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("MultiPass can't be disabled");
                }

                return this.passRestService.getAll();
            }),
            take(1),
            map((passes: PassSimpleResponseModel[]) => {

                let nodes: TreeNode<TreeNodeDataModel>[] = this.getPassesTree(passes);

                this.store.dispatch(new actions.SetLoadingPasses(false));
                this.store.dispatch(new actions.SetPassesTree(nodes));
                this.store.dispatch(new actions.SetShowInactiveItems(true));

                let disabledPassNode: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(passId, TreeItemTypeEnum.PASS, nodes);

                // disabledPassNode.selected = true;

                this.setPass(disabledPassNode);
            })
        ).subscribe();
    }

    updatePassImage(passId: number, image: File) {

        this.store.dispatch(new actions.SetLoadingPassImages(true));

        let request: FormData = new FormData();
        request.append("image", image);

        this.passRestService.updatePassImage(passId, request)
            .subscribe((response: ImageResponseModel) => {

                this.store.dispatch(new actions.SetLoadingPassImages(false));
                this.store.dispatch(new actions.SetPassImage(response));
                this.rootSandbox.addInfoNotification("Pass Image Uploaded!");
            },
            () => this.store.dispatch(new actions.SetLoadingPassImages(false)));
    }

    removePassImage(passId: number) {

        this.store.dispatch(new actions.SetLoadingPassImages(true));

        this.passRestService.removePassImage(passId)
            .subscribe(() => {

                this.store.dispatch(new actions.SetLoadingPassImages(false));
                this.store.dispatch(new actions.SetPassImage({
                    imageAmazonFilePath: null,
                    imageBase64: null
                }));
                this.rootSandbox.addInfoNotification("Pass Image Removed!");
            },
            () => this.store.dispatch(new actions.SetLoadingPassImages(false)));
    }

    updatePassCardBackgroundImage(passId: number, image: File) {

        this.store.dispatch(new actions.SetLoadingPassImages(true));

        let request: FormData = new FormData();
        request.append("image", image);

        this.passRestService.updatePassCardBackgroundImage(passId, request)
            .subscribe((response: ImageResponseModel) => {

                this.store.dispatch(new actions.SetLoadingPassImages(false));
                this.store.dispatch(new actions.SetPassCardBackgroundImage(response));
                this.rootSandbox.addInfoNotification("PassCard Background Image Uploaded!");
            },
            () => this.store.dispatch(new actions.SetLoadingPassImages(false)));
    }

    removePassCardBackgroundImage(passId: number) {

        this.store.dispatch(new actions.SetLoadingPassImages(true));

        this.passRestService.removePassCardBackgroundImage(passId)
            .subscribe(() => {

                this.store.dispatch(new actions.SetLoadingPassImages(false));
                this.store.dispatch(new actions.SetPassCardBackgroundImage({
                    imageAmazonFilePath: null,
                    imageBase64: null
                }));
                this.rootSandbox.addInfoNotification("PassCard Background Image Removed!");
            },
            () => this.store.dispatch(new actions.SetLoadingPassImages(false)));
    }

    setActivePassTemplateItems(setPassTemplateItemsFlagData: SetPassTemplateItemsFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingPassTemplateItems(true));

        this.templateRestService.activateTemplateItems(setPassTemplateItemsFlagData.templateItemIds).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Custom field(s) can't be activated");
                }

                return combineLatest([this.item$, this.templateRestService.getTemplateItemsByTemplateId(setPassTemplateItemsFlagData.templateId)]);
            }),
            take(1),
            map(([item, templateItems]: [TreeNode<TreeNodeDataModel>, TemplateItemResponseModel[]]) => {

                let passTemplateItems: PassTemplateItemsDataModel = {
                    passId: item.data.id,
                    templateId: setPassTemplateItemsFlagData.templateId,
                    templateItems: templateItems.map((ti: TemplateItemResponseModel) => new TemplateItemDataModel(ti))
                };

                this.store.dispatch(new actions.SetPassTemplateItems(passTemplateItems));
                this.store.dispatch(new actions.SetLoadingPassTemplateItems(false));
            })
        ).subscribe();
    }

    setNotActivePassTemplateItems(setPassTemplateItemsFlagData: SetPassTemplateItemsFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingPassTemplateItems(true));

        this.templateRestService.deactivateTemplateItems(setPassTemplateItemsFlagData.templateItemIds).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Custom field(s) can't be deactivated");
                }

                return combineLatest([this.item$, this.templateRestService.getTemplateItemsByTemplateId(setPassTemplateItemsFlagData.templateId)]);
            }),
            take(1),
            map(([item, templateItems]: [TreeNode<TreeNodeDataModel>, TemplateItemResponseModel[]]) => {

                let passTemplateItems: PassTemplateItemsDataModel = {
                    passId: item.data.id,
                    templateId: setPassTemplateItemsFlagData.templateId,
                    templateItems: templateItems.map((ti: TemplateItemResponseModel) => new TemplateItemDataModel(ti))
                };

                this.store.dispatch(new actions.SetPassTemplateItems(passTemplateItems));
                this.store.dispatch(new actions.SetLoadingPassTemplateItems(false));
            })
        ).subscribe();
    }

    setRequiredPassTemplateItems(setPassTemplateItemsFlagData: SetPassTemplateItemsFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingPassTemplateItems(true));

        this.templateRestService.setRequiredTemplateItems(setPassTemplateItemsFlagData.templateItemIds).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Custom field(s) can't be set required");
                }

                return combineLatest([this.item$, this.templateRestService.getTemplateItemsByTemplateId(setPassTemplateItemsFlagData.templateId)]);
            }),
            take(1),
            map(([item, templateItems]: [TreeNode<TreeNodeDataModel>, TemplateItemResponseModel[]]) => {

                let passTemplateItems: PassTemplateItemsDataModel = {
                    passId: item.data.id,
                    templateId: setPassTemplateItemsFlagData.templateId,
                    templateItems: templateItems.map((ti: TemplateItemResponseModel) => new TemplateItemDataModel(ti))
                };

                this.store.dispatch(new actions.SetPassTemplateItems(passTemplateItems));
                this.store.dispatch(new actions.SetLoadingPassTemplateItems(false));
            })
        ).subscribe();
    }

    setNotRequiredPassTemplateItems(setPassTemplateItemsFlagData: SetPassTemplateItemsFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingPassTemplateItems(true));

        this.templateRestService.setNotRequiredTemplateItems(setPassTemplateItemsFlagData.templateItemIds).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Custom field(s) can't be set not required");
                }

                return combineLatest([this.item$, this.templateRestService.getTemplateItemsByTemplateId(setPassTemplateItemsFlagData.templateId)]);
            }),
            take(1),
            map(([item, templateItems]: [TreeNode<TreeNodeDataModel>, TemplateItemResponseModel[]]) => {

                let passTemplateItems: PassTemplateItemsDataModel = {
                    passId: item.data.id,
                    templateId: setPassTemplateItemsFlagData.templateId,
                    templateItems: templateItems.map((ti: TemplateItemResponseModel) => new TemplateItemDataModel(ti))
                };

                this.store.dispatch(new actions.SetPassTemplateItems(passTemplateItems));
                this.store.dispatch(new actions.SetLoadingPassTemplateItems(false));
            })
        ).subscribe();
    }

    setDisplayOnTicketPassTemplateItems(setPassTemplateItemsFlagData: SetPassTemplateItemsFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingPassTemplateItems(true));

        this.templateRestService.setDisplayOnTicketTemplateItems(setPassTemplateItemsFlagData.templateItemIds).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Custom field(s) can't be set to be displayed on ticket");
                }

                return combineLatest([this.item$, this.templateRestService.getTemplateItemsByTemplateId(setPassTemplateItemsFlagData.templateId)]);
            }),
            take(1),
            map(([item, templateItems]: [TreeNode<TreeNodeDataModel>, TemplateItemResponseModel[]]) => {

                let passTemplateItems: PassTemplateItemsDataModel = {
                    passId: item.data.id,
                    templateId: setPassTemplateItemsFlagData.templateId,
                    templateItems: templateItems.map((ti: TemplateItemResponseModel) => new TemplateItemDataModel(ti))
                };

                this.store.dispatch(new actions.SetPassTemplateItems(passTemplateItems));
                this.store.dispatch(new actions.SetLoadingPassTemplateItems(false));
            })
        ).subscribe();
    }

    setNotDisplayOnTicketPassTemplateItems(setPassTemplateItemsFlagData: SetPassTemplateItemsFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingPassTemplateItems(true));

        this.templateRestService.setNotDisplayOnTicketTemplateItems(setPassTemplateItemsFlagData.templateItemIds).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Custom field(s) can't be set to not be displayed on ticket");
                }

                return combineLatest([this.item$, this.templateRestService.getTemplateItemsByTemplateId(setPassTemplateItemsFlagData.templateId)]);
            }),
            take(1),
            map(([item, templateItems]: [TreeNode<TreeNodeDataModel>, TemplateItemResponseModel[]]) => {

                let passTemplateItems: PassTemplateItemsDataModel = {
                    passId: item.data.id,
                    templateId: setPassTemplateItemsFlagData.templateId,
                    templateItems: templateItems.map((ti: TemplateItemResponseModel) => new TemplateItemDataModel(ti))
                };

                this.store.dispatch(new actions.SetPassTemplateItems(passTemplateItems));
                this.store.dispatch(new actions.SetLoadingPassTemplateItems(false));
            })
        ).subscribe();
    }

    /**
     * PRICING DATE RANGE ACTIONS
     */

    loadPassPricingDateRangesSidebarFilterData(initial: boolean) {

        combineLatest([this.locationRestService.getAll(), this.tierRestService.getAll()]).pipe(
            take(1),
            map(([locations, tiers]: [LocationsResponseModel, TiersResponseModel]) => {

                this.store.dispatch(new actions.SetPassPricingDateRangesSidebarFilterPickupLocations(locations.locations));
                this.store.dispatch(new actions.SetPassPricingDateRangesSidebarFilterDropoffLocations(locations.locations));
                this.store.dispatch(new actions.SetPassPricingDateRangesSidebarFilterTiers(tiers.tiers));

                // Set initial pass pricing date ranges sidebar filter data
                let initialPassPricingDateRangesSidebarFilterData: PassPricingDateRangesSidebarFilterDataModel = this._createInitialPassPricingDateRangesSidebarFilterData(locations.locations, tiers.tiers);
                this.store.dispatch(new actions.SetPassPricingDateRangesSidebarFilterData(initialPassPricingDateRangesSidebarFilterData));
                if (!initial) {
                    this.loadPassPricingDateRanges(initialPassPricingDateRangesSidebarFilterData);
                }
            })
        ).subscribe();
    }

    togglePassPricingDateRangesSidebarFilterOpen() {
        this.passPricingDateRangesSidebarFilterOpen$.pipe(take(1)).subscribe((passPricingDateRangesSidebarFilterOpen: boolean) => {
            this.store.dispatch(new actions.SetPassPricingDateRangesSidebarFilterOpen(!passPricingDateRangesSidebarFilterOpen));
        });
    }

    showPassPricingDateRangesSidebarFilter(passPricingDateRangesFocusedFilter: PassPricingDateRangesSidebarFilterTypeEnum) {
        this.store.dispatch(new actions.SetPassPricingDateRangesSidebarFilterOpen(true, passPricingDateRangesFocusedFilter));
    }

    passPricingDateRangesClearFilter() {
        this.loadPassPricingDateRangesSidebarFilterData(false)
    }

    loadPassPricingDateRanges(passPricingDateRangesSidebarFilterData: PassPricingDateRangesSidebarFilterDataModel) {

        // Keep passId
        let passId: number;

        // Clear current results
        let passPricingDateRanges: PassPricingDateRangesDataModel = {
            passId: passId,
            totalNumberOfResults: 0,
            pricingDateRanges: []
        };
        this.store.dispatch(new actions.SetPassPricingDateRanges(false, passPricingDateRanges));

        this.store.dispatch(new actions.SetLoadingPassPricingDateRanges(true));

        this.item$.pipe(
            take(1),
            flatMap((treeNode: TreeNode<TreeNodeDataModel>) => {

                // Keep passId
                passId = treeNode.data.id;

                return this._loadPassPricingDateRanges(passId, passPricingDateRangesSidebarFilterData, 0);
            }),
            take(1),
            map((pricingDateRangesFull: PricingDateRangesFullResponseModel) => {

                let passPricingDateRanges: PassPricingDateRangesDataModel = {
                    passId: passId,
                    totalNumberOfResults: pricingDateRangesFull.totalNumberOfResults,
                    pricingDateRanges: pricingDateRangesFull.pricingDateRanges.map((pdr: PricingDateRangeFullResponseModel) => new PricingDateRangeDataModel(pdr))
                };

                this.store.dispatch(new actions.SetPassPricingDateRanges(false, passPricingDateRanges));
                this.store.dispatch(new actions.SetPassPricingDateRangesFrom(0));
                this.store.dispatch(new actions.SetPassPricingDateRangesSidebarFilterData(passPricingDateRangesSidebarFilterData));
                this.store.dispatch(new actions.SetLoadingPassPricingDateRanges(false));
                this.store.dispatch(new actions.SetPassPricingDateRangesSidebarFilterOpen(false));
            })
        ).subscribe();
    }

    loadMorePassPricingDateRanges() {

        // Keep passId and passPricingDateRangesFrom
        let passId: number;
        let passPricingDateRangesFrom: number;

        this.store.dispatch(new actions.SetLoadingPassPricingDateRanges(true));

        combineLatest([this.item$, this.passPricingDateRangesSidebarFilterData$, this.passPricingDateRangesFrom$]).pipe(
            take(1),
            flatMap(([treeNode, passPricingDateRangesSidebarFilterData, aPassPricingDateRangesFrom]: [TreeNode<TreeNodeDataModel>, PassPricingDateRangesSidebarFilterDataModel, number]) => {

                // Keep passId and passPricingDateRangesFrom
                passId = treeNode.data.id;
                passPricingDateRangesFrom = aPassPricingDateRangesFrom;

                return this._loadPassPricingDateRanges(passId, passPricingDateRangesSidebarFilterData, passPricingDateRangesFrom + this.PASS_PRICING_DATE_RANGES_PAGE_SIZE);
            }),
            take(1),
            map((pricingDateRangesFull: PricingDateRangesFullResponseModel) => {

                let passPricingDateRanges: PassPricingDateRangesDataModel = {
                    passId: passId,
                    totalNumberOfResults: pricingDateRangesFull.totalNumberOfResults,
                    pricingDateRanges: pricingDateRangesFull.pricingDateRanges.map((pdr: PricingDateRangeFullResponseModel) => new PricingDateRangeDataModel(pdr))
                };

                this.store.dispatch(new actions.SetPassPricingDateRanges(true, passPricingDateRanges));
                this.store.dispatch(new actions.SetPassPricingDateRangesFrom(passPricingDateRangesFrom + this.PASS_PRICING_DATE_RANGES_PAGE_SIZE));
                this.store.dispatch(new actions.SetLoadingPassPricingDateRanges(false));
            })
        ).subscribe();
    }

    updatePassPricingDateRangesSortBy(passPricingDateRangesSortBy: SortByModel) {

        this.store.dispatch(new actions.UpdatePassPricingDateRangesCurrentSearchDataSortBy(passPricingDateRangesSortBy));
        this.passPricingDateRangesSidebarFilterData$.pipe(
            take(1))
            .subscribe((passPricingDateRangesSidebarFilterData: PassPricingDateRangesSidebarFilterDataModel) => {
                this.loadPassPricingDateRanges(passPricingDateRangesSidebarFilterData);
            });
    }

    priorityUpPassPricingDateRange(setPassPricingDateRangeFlagData: SetPassPricingDateRangeFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingPassPricingDateRanges(true));

        let updatePricingDateRangePriorityRequest: UpdatePricingDateRangePriorityRequestModel = new UpdatePricingDateRangePriorityRequestModel(
            setPassPricingDateRangeFlagData.pricingDateRangeId,
            null,
            setPassPricingDateRangeFlagData.passId,
            true
        );

        combineLatest([this.pricingDateRangeRestService.updatePricingDateRangePriority(updatePricingDateRangePriorityRequest), this.passPricingDateRangesSidebarFilterData$]).pipe(
            take(1),
            flatMap(([response, passPricingDateRangesSidebarFilterData]: [any, PassPricingDateRangesSidebarFilterDataModel]) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Pricing(s)' priority can't be changed");
                }

                return this._loadPassPricingDateRanges(setPassPricingDateRangeFlagData.passId, passPricingDateRangesSidebarFilterData, 0);
            }),
            take(1),
            map((pricingDateRangesFull: PricingDateRangesFullResponseModel) => {

                let passPricingDateRanges: PassPricingDateRangesDataModel = {
                    passId: setPassPricingDateRangeFlagData.passId,
                    totalNumberOfResults: pricingDateRangesFull.totalNumberOfResults,
                    pricingDateRanges: pricingDateRangesFull.pricingDateRanges.map((pdr: PricingDateRangeFullResponseModel) => new PricingDateRangeDataModel(pdr))
                };

                this.store.dispatch(new actions.SetPassPricingDateRanges(false, passPricingDateRanges));
                this.store.dispatch(new actions.SetPassPricingDateRangesFrom(0));
                this.store.dispatch(new actions.SetLoadingPassPricingDateRanges(false));
            })
        ).subscribe();
    }

    priorityDownPassPricingDateRange(setPassPricingDateRangeFlagData: SetPassPricingDateRangeFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingPassPricingDateRanges(true));

        let updatePricingDateRangePriorityRequest: UpdatePricingDateRangePriorityRequestModel = new UpdatePricingDateRangePriorityRequestModel(
            setPassPricingDateRangeFlagData.pricingDateRangeId,
            null,
            setPassPricingDateRangeFlagData.passId,
            false
        );

        combineLatest([this.pricingDateRangeRestService.updatePricingDateRangePriority(updatePricingDateRangePriorityRequest), this.passPricingDateRangesSidebarFilterData$]).pipe(
            take(1),
            flatMap(([response, passPricingDateRangesSidebarFilterData]: [any, PassPricingDateRangesSidebarFilterDataModel]) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Pricing(s)' priority can't be changed");
                }

                return this._loadPassPricingDateRanges(setPassPricingDateRangeFlagData.passId, passPricingDateRangesSidebarFilterData, 0);
            }),
            take(1),
            map((pricingDateRangesFull: PricingDateRangesFullResponseModel) => {

                let passPricingDateRanges: PassPricingDateRangesDataModel = {
                    passId: setPassPricingDateRangeFlagData.passId,
                    totalNumberOfResults: pricingDateRangesFull.totalNumberOfResults,
                    pricingDateRanges: pricingDateRangesFull.pricingDateRanges.map((pdr: PricingDateRangeFullResponseModel) => new PricingDateRangeDataModel(pdr))
                };

                this.store.dispatch(new actions.SetPassPricingDateRanges(false, passPricingDateRanges));
                this.store.dispatch(new actions.SetPassPricingDateRangesFrom(0));
                this.store.dispatch(new actions.SetLoadingPassPricingDateRanges(false));
            })
        ).subscribe();
    }

    setActivePassPricingDateRanges(setPassPricingDateRangesFlagData: SetPassPricingDateRangesFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingPassPricingDateRanges(true));

        combineLatest([this.pricingDateRangeRestService.activatePricingDateRanges(setPassPricingDateRangesFlagData.pricingDateRangeIds), this.passPricingDateRangesSidebarFilterData$]).pipe(
            take(1),
            flatMap(([response, passPricingDateRangesSidebarFilterData]: [any, PassPricingDateRangesSidebarFilterDataModel]) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Pricing(s) can't be activated");
                }

                return this._loadPassPricingDateRanges(setPassPricingDateRangesFlagData.passId, passPricingDateRangesSidebarFilterData, 0);
            }),
            take(1),
            map((pricingDateRangesFull: PricingDateRangesFullResponseModel) => {

                let passPricingDateRanges: PassPricingDateRangesDataModel = {
                    passId: setPassPricingDateRangesFlagData.passId,
                    totalNumberOfResults: pricingDateRangesFull.totalNumberOfResults,
                    pricingDateRanges: pricingDateRangesFull.pricingDateRanges.map((pdr: PricingDateRangeFullResponseModel) => new PricingDateRangeDataModel(pdr))
                };

                this.store.dispatch(new actions.SetPassPricingDateRanges(false, passPricingDateRanges));
                this.store.dispatch(new actions.SetPassPricingDateRangesFrom(0));
                this.store.dispatch(new actions.SetLoadingPassPricingDateRanges(false));
            })
        ).subscribe();
    }

    setNotActivePassPricingDateRanges(setPassPricingDateRangesFlagData: SetPassPricingDateRangesFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingPassPricingDateRanges(true));

        combineLatest([this.pricingDateRangeRestService.deactivatePricingDateRanges(setPassPricingDateRangesFlagData.pricingDateRangeIds), this.passPricingDateRangesSidebarFilterData$]).pipe(
            take(1),
            flatMap(([response, passPricingDateRangesSidebarFilterData]: [any, PassPricingDateRangesSidebarFilterDataModel]) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Pricing(s) can't be deactivated");
                }

                return this._loadPassPricingDateRanges(setPassPricingDateRangesFlagData.passId, passPricingDateRangesSidebarFilterData, 0);
            }),
            take(1),
            map((pricingDateRangesFull: PricingDateRangesFullResponseModel) => {

                let passPricingDateRanges: PassPricingDateRangesDataModel = {
                    passId: setPassPricingDateRangesFlagData.passId,
                    totalNumberOfResults: pricingDateRangesFull.totalNumberOfResults,
                    pricingDateRanges: pricingDateRangesFull.pricingDateRanges.map((pdr: PricingDateRangeFullResponseModel) => new PricingDateRangeDataModel(pdr))
                };

                this.store.dispatch(new actions.SetPassPricingDateRanges(false, passPricingDateRanges));
                this.store.dispatch(new actions.SetPassPricingDateRangesFrom(0));
                this.store.dispatch(new actions.SetLoadingPassPricingDateRanges(false));
            })
        ).subscribe();
    }

    /**
     * COMMON METHODS
     */

    refreshViewAndSelect(id: number, type: TreeItemTypeEnum) {

        this.store.dispatch(new actions.SetLoadingPasses(true));

        this.passRestService.getAll()
            .subscribe((passes: PassSimpleResponseModel[]) => {

                let nodes: TreeNode<TreeNodeDataModel>[] = this.getPassesTree(passes);
                let node: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(id, type, nodes);

                this.store.dispatch(new actions.SetLoadingPasses(false));
                this.store.dispatch(new actions.SetPassesTree(nodes));

                // If inactive node has been selected/edited, set flag ShowInactiveItems to true
                if (!node.active) {
                    this.store.dispatch(new actions.SetShowInactiveItems(true));
                }

                if (type === TreeItemTypeEnum.PASS) {
                    // node.setSelected();
                    this.setPass(node);
                    return;
                }
            },
            () => this.store.dispatch(new actions.SetLoadingPasses(false)));
    }

    private getPassesTree(passes: PassSimpleResponseModel[]): TreeNode<TreeNodeDataModel>[] {

        let result: TreeNode<TreeNodeDataModel>[] = [];

        passes.sort((p1, p2) => (p1.description.toLowerCase() > p2.description.toLowerCase()) ? 1 : -1);

        passes.forEach((pass: PassSimpleResponseModel) => {

            let passChildren: TreeNode<TreeNodeDataModel>[] = [];

            result.push(new TreeNode<TreeNodeDataModel>(new TreeNodeDataModel(TreeItemTypeEnum.PASS, pass.passId, null, pass.description), passChildren, pass.active, true));
        });

        result.push(new TreeNode<TreeNodeDataModel>(new TreeNodeDataModel(TreeItemTypeEnum.ADD_NEW_PASS, null, null, "Add New MultiPass"), [new TreeNode<TreeNodeDataModel>(new TreeNodeDataModel(null, null, null, null), [])], true, true));

        return result;
    }

    private findNodeByIdAndType(id: number, type: TreeItemTypeEnum, nodes: TreeNode<TreeNodeDataModel>[]): TreeNode<TreeNodeDataModel> {

        let result: TreeNode<TreeNodeDataModel> = null;

        for (let i = 0; result === null && i < nodes.length; i++) {
            result = this._findNodeByIdAndType(id, type, nodes[i]);
        }

        return result;
    }

    private _findNodeByIdAndType(id: number, type: TreeItemTypeEnum, node: TreeNode<TreeNodeDataModel>): TreeNode<TreeNodeDataModel> {

        if (node.data.id === id && node.data.type === type) {
            return node;
        } else if (node.children.length !== 0) {

            let result: TreeNode<TreeNodeDataModel> = null;

            for (let i = 0; result === null && i < node.children.length; i++) {
                result = this._findNodeByIdAndType(id, type, node.children[i]);
            }

            return result;
        }

        return null;
    }

    private _createInitialPassPricingDateRangesSidebarFilterData(locations: LocationResponseModel[], tiers: TierResponseModel[]): PassPricingDateRangesSidebarFilterDataModel {

        let selectedShowInactive: boolean = false;
        let selectedShowInactiveTiers: boolean = false;
        let selectedShowInactivePickupLocations: boolean = false;
        let selectedShowInactiveDropoffLocations: boolean = false;
        let selectedShowInactiveDepartureGroups: boolean = false;
        let allDates: boolean = false;
        let selectedStartDate: moment.Moment = moment();
        let selectedEndDate: moment.Moment = moment();
        let selectedPickupLocationIds: number[] = locations.map(l => l.locationId);
        let selectedPickupLocationDescriptions: string[] = locations.map(l => l.description);
        let selectedDropoffLocationIds: number[] = locations.map(l => l.locationId);
        let selectedDropoffLocationDescriptions: string[] = locations.map(l => l.description);
        let selectedTierIds: number[] = tiers.map(t => t.tierId);
        let selectedTierDescriptions: string[] = tiers.map(t => t.description);

        let initialPassPricingDateRangesSidebarFilterData: PassPricingDateRangesSidebarFilterDataModel = {
            showInactive: selectedShowInactive,
            showInactiveTiers: selectedShowInactiveTiers,
            showInactivePickupLocations: selectedShowInactivePickupLocations,
            showInactiveDropoffLocations: selectedShowInactiveDropoffLocations,
            showInactiveDepartureGroups: selectedShowInactiveDepartureGroups,
            allDates: allDates,
            startDate: selectedStartDate,
            startDateFriendly: DateTimeUtility.getDateFriendly(selectedStartDate),
            endDate: selectedEndDate,
            endDateFriendly: DateTimeUtility.getDateFriendly(selectedEndDate),
            pickupLocationIds: selectedPickupLocationIds,
            pickupLocationDescriptions: selectedPickupLocationDescriptions,
            dropoffLocationIds: selectedDropoffLocationIds,
            dropoffLocationDescriptions: selectedDropoffLocationDescriptions,
            tierIds: selectedTierIds,
            tierDescriptions: selectedTierDescriptions
        };

        return initialPassPricingDateRangesSidebarFilterData;
    }

    private _loadPassPricingDateRanges(passId: number, passPricingDateRangesSidebarFilterData: PassPricingDateRangesSidebarFilterDataModel, passPricingDateRangesFrom: number): Observable<PricingDateRangesFullResponseModel> {

        this.store.dispatch(new actions.SetLoadingPassPricingDateRanges(true));

        return this.passPricingDateRangesCurrentSearchDataSortBy$.pipe(
            take(1),
            mergeMap((passPricingDateRangesCurrentSearchDataSortBy: SortByModel) => {

                let fieldName: string = null;
                let order: string = null;
                if (passPricingDateRangesCurrentSearchDataSortBy != null && passPricingDateRangesCurrentSearchDataSortBy !== undefined) {
                    if (passPricingDateRangesCurrentSearchDataSortBy.fieldName != null && passPricingDateRangesCurrentSearchDataSortBy.fieldName !== undefined) {
                        fieldName = passPricingDateRangesCurrentSearchDataSortBy.fieldName;
                    }
                    if (passPricingDateRangesCurrentSearchDataSortBy.order != null && passPricingDateRangesCurrentSearchDataSortBy.order !== undefined) {
                        order = passPricingDateRangesCurrentSearchDataSortBy.order;
                    }
                }

                const sortByRequest = new SortByRequestModel(fieldName, order);

                // If flag "allDates" is on, set start and end date to proper values
                let startDateDescriptor: DateTimeDescriptor = passPricingDateRangesSidebarFilterData.allDates ?
                    new DateTimeDescriptor(1, 1, 1, 0, 0, 0, 0) :
                    DateTimeUtility.convertMomentToDateTimeDescriptor(passPricingDateRangesSidebarFilterData.startDate);
                let endDateDescriptor: DateTimeDescriptor = passPricingDateRangesSidebarFilterData.allDates ?
                    new DateTimeDescriptor(9999, 1, 1, 0, 0, 0, 0) :
                    DateTimeUtility.convertMomentToDateTimeDescriptor(passPricingDateRangesSidebarFilterData.endDate);

                let request: GetPricingDateRangesFilteredRequestModel = new GetPricingDateRangesFilteredRequestModel(
                    passPricingDateRangesFrom,
                    this.PASS_PRICING_DATE_RANGES_PAGE_SIZE,
                    null,
                    passId,
                    startDateDescriptor,
                    endDateDescriptor,
                    passPricingDateRangesSidebarFilterData.pickupLocationIds,
                    passPricingDateRangesSidebarFilterData.dropoffLocationIds,
                    passPricingDateRangesSidebarFilterData.tierIds,
                    passPricingDateRangesSidebarFilterData.showInactive,
                    passPricingDateRangesSidebarFilterData.showInactiveTiers,
                    passPricingDateRangesSidebarFilterData.showInactivePickupLocations,
                    passPricingDateRangesSidebarFilterData.showInactiveDropoffLocations,
                    passPricingDateRangesSidebarFilterData.showInactiveDepartureGroups,
                    sortByRequest
                );

                return this.pricingDateRangeRestService.getPricingDateRangesFiltered(request);
            }),);
    }
}
