<app-abstract-dialog-container [shouldOverrideCloseClicked]="true" (closeClicked)="onCloseClicked()">
    <div class="form_container">
        <!-- Header -->
        <div class="header_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <h1>{{ header }}</h1>
                </div>
            </div>
        </div>

        <!-- Message -->
        <div class="content_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12 fs-16">
                    {{ message }}
                </div>
            </div>
        </div>

        <!-- Input -->
        <div class="content_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12 fs-16">
                    <input type="text"
                    class="main--element input-tall input-large"
                    style="margin-top: 20px;"
                    placeholder= {{placeholder}}
                    id="inputForm_text"
                    value="{{ orderExternalID }}"
                    #inputValue (keyup)="valueChanged(inputValue.value)"
                    (change)="valueChanged(inputValue.value)">
                </div>
            </div>
        </div>

        <!-- Footer container -->
        <div class="footer_wrapper flex-container" style="display: flex; justify-content: center;">
            <!-- No button -->
            <app-button class="mr-16px"
                        [buttonType]="'button ghost-button'"
                        (clickEvent)="onNoClicked()">
                {{ noLabel }}
            </app-button>

            <!-- Yes button -->

            <app-button [disabled]="!inputValue.value" [buttonType]="buttonType"
                        (clickEvent)="onYesClicked()">
                {{ yesLabel }}
            </app-button>
        </div>
    </div>
</app-abstract-dialog-container>
