import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild  } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import Map = google.maps.Map;
import MapTypeId = google.maps.MapTypeId;
import MapOptions = google.maps.MapOptions;
import LatLng = google.maps.LatLng;
import Marker = google.maps.Marker;
import Geocoder = google.maps.Geocoder;


@Component({
    selector: 'app-input-google-maps-lat-lng',
    templateUrl: './input-google-maps-lat-lng.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputGoogleMapsLatLngComponent,
            multi: true,
        }
    ]
})
export class InputGoogleMapsLatLngComponent implements OnInit, ControlValueAccessor {

    @Input() latitude: number = null;
    @Input() longitude: number = null;
    @Input() halfWidth: boolean = false;

    @Output() zipCodeChanged: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild("map") mapHtmlElement: any;

    private map: Map;
    private marker: Marker;
    private geocoder: Geocoder;

    private _onChange: (val: GoogleMapsLatLng) => void;
    private _onTouch: (val: GoogleMapsLatLng) => void;

    constructor() {
    }

    ngAfterViewInit() { 
        let markerLatLng: LatLng = new LatLng(this.latitude, this.longitude);

        let config: MapOptions = {
            center: markerLatLng,
            zoom: 13,
            mapTypeId: MapTypeId.ROADMAP,
            zoomControl: false,
            mapTypeControl: false,
            streetViewControl: false
        };

        this.map = new Map(this.mapHtmlElement.nativeElement, config);

        this.marker = new Marker({
            position: markerLatLng,
            draggable: true,
            map: this.map
        });

        this.geocoder = new Geocoder();

        this.map.addListener("click", e => {
            // this.geocodePosition(e.latLng);
            this.placeMarker(e.latLng);
        });

        this.marker.addListener("dragend", e => {
            // this.geocodePosition(e.latLng);
            this.placeMarker(e.latLng);
        });
    }
    
    ngOnInit() {
    }

    /*geocodePosition(location: LatLng) {

        this.geocoder.geocode({location: location}, (results, status) => {

            if (status === 'OK' && results && results.length > 0) {
                const zipCode: string = results[0].address_components[results[0].address_components.length - 1].long_name;
                this.zipCodeChanged.emit(zipCode);
            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    }*/

    onCoordinatesChange() {

        if (this.latitude === null || this.latitude === undefined || this.latitude.toString().trim().length === 0 || isNaN(this.latitude) ||
            this.longitude === null || this.longitude === undefined || this.longitude.toString().trim().length === 0 || isNaN(this.longitude)) {

            if (this._onChange) {
                this._onChange({
                    lat: this.latitude,
                    lng: this.longitude
                });
            }

            return;
        }

        this.placeMarker(new LatLng(this.latitude, this.longitude));
    }

    writeValue(value: GoogleMapsLatLng): void {

        if (value === null || value.lat === null || value.lng === null) {

            if (this._onChange) {
                this._onChange({
                    lat: this.latitude,
                    lng: this.longitude
                });
            }

            return;
        }

        this.placeMarker(new LatLng(value.lat, value.lng));
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    private placeMarker(latLng: LatLng) {

        this.latitude = parseFloat(latLng.lat().toFixed(10));
        this.longitude = parseFloat(latLng.lng().toFixed(10));

        this.marker.setPosition(latLng);
        this.map.panTo(latLng);

        if (this._onChange) {
            this._onChange({
                lat: this.latitude,
                lng: this.longitude
            });
        }
    }
}

export interface GoogleMapsLatLng {
    lat: number;
    lng: number;
}
