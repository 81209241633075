export class TierFormSubmitDataModel {

    description: string;
    minimumAge: number;
    maximumAge: number;
    useAlternateCheckInSound: boolean;
    manualAcceptanceRequired: boolean;

    constructor(description: string, minimumAge: number, maximumAge: number, useAlternateCheckInSound: boolean, manualAcceptanceRequired: boolean) {
        this.description = description;
        this.minimumAge = minimumAge;
        this.maximumAge = maximumAge;
        this.useAlternateCheckInSound = useAlternateCheckInSound;
        this.manualAcceptanceRequired = manualAcceptanceRequired;
    }
}
