import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SortByModel} from '../../../../../../../shared/model/sort-by.model';
import {DriverManifestReportCurrentSearchDataModel} from '../../model/driver-manifest-report-current-search-data.model';
import {FavoriteResponseModel} from "../../../../../../../core/modules/rest/favorite/response/favorite-response.model";
import {ReportSearchRequestModel} from "../../../../../../../core/modules/rest/reporting/request/report-search-request.model";
import {ExpressionItemModel} from "../../../../general/order-reports/model/expression-item.model";
import {findFilterItemByQueryFieldName} from "../../../../general/order-reports/model/data";
import {BaseExpressionRequestModel} from "../../../../../../../core/modules/rest/reporting/request/base-expression-request.model";
import {ExpressionItemTypeEnum} from "../../../../general/order-reports/model/expression-item-type.enum";
import {OperatorExpressionRequestModel} from "../../../../../../../core/modules/rest/reporting/request/operator-expression-request.model";
import {ExpressionOperatorModel} from "../../../../general/order-reports/model/expression-operator.model";
import {ExpressionOperatorTypeEnum} from "../../../../general/order-reports/model/expression-operator-type.enum";
import {FilterExpressionRequestModel} from "../../../../../../../core/modules/rest/reporting/request/filter-expression-request.model";
import {FilterItemModel} from "../../../../general/order-reports/model/filter-item.model";
import {ExpressionFilterModel} from "../../../../general/order-reports/model/expression-filter.model";
import {GroupExpressionRequestModel} from "../../../../../../../core/modules/rest/reporting/request/group-expression-request.model";
import {ExpressionGroupModel} from "../../../../general/order-reports/model/expression-group.model";
import {ProductDirectionDataModel} from "../../model/product-direction-data.model";
import * as data from '../../model/data';
import * as actions from './actions';
import * as moment from "moment";

export const REDUCER_NAME = 'feature_driverManifestReport';

export interface State {
    loadingFavorites: boolean;
    favorites: FavoriteResponseModel[];
    selectedFavoriteId: number;
    favoriteCreatedUpdated: boolean;
    loadingProducts: boolean;
    productDirections: ProductDirectionDataModel[];
    selectedDateType: string;
    selectedDate: moment.Moment;
    selectedProductDirections: string[];
    currentSearchData: DriverManifestReportCurrentSearchDataModel;
    currentSearchDataSortBy: SortByModel;
}

const initialState: State = {
    loadingFavorites: false,
    favorites: [],
    selectedFavoriteId: null,
    favoriteCreatedUpdated: false,
    loadingProducts: false,
    productDirections: [],
    selectedDateType: data.DATE_CUSTOM,
    selectedDate: moment(),
    selectedProductDirections: [],
    currentSearchData: null,
    currentSearchDataSortBy: null
};

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const loadingFavorites_selector = createSelector(state_selector, (state: State) => state.loadingFavorites);
export const favorites_selector = createSelector(state_selector, (state: State) => state.favorites);
export const selectedFavoriteId_selector = createSelector(state_selector, (state: State) => state.selectedFavoriteId);
export const favoriteCreatedUpdated_selector = createSelector(state_selector, (state: State) => state.favoriteCreatedUpdated);
export const loadingProducts_selector = createSelector(state_selector, (state: State) => state.loadingProducts);
export const productDirections_selector = createSelector(state_selector, (state: State) => state.productDirections);
export const selectedDateType_selector = createSelector(state_selector, (state: State) => state.selectedDateType);
export const selectedDate_selector = createSelector(state_selector, (state: State) => state.selectedDate);
export const selectedProductDirections_selector = createSelector(state_selector, (state: State) => state.selectedProductDirections);
export const currentSearchData_selector = createSelector(state_selector, (state: State) => state.currentSearchData);
export const currentSearchDataSortBy_selector = createSelector(state_selector, (state: State) => state.currentSearchDataSortBy);

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.UPDATE_FAVORITES_LOADER_ACTION:
            return {
                ...state,
                loadingFavorites: action.payload.loadingFavorites
            };
        case actions.UPDATE_FAVORITES_ACTION:
            if (action.payload.favoriteId) {
                return {
                    ...state,
                    favorites: action.payload.favorites,
                    selectedFavoriteId: action.payload.favoriteId,
                    favoriteCreatedUpdated: action.payload.favoriteCreatedUpdated
                };
            } else {
                return {
                    ...state,
                    favorites: action.payload.favorites,
                    favoriteCreatedUpdated: false
                };
            }
        case actions.UPDATE_SELECTED_FAVORITE_ID_ACTION:
            return handleUpdateSelectedFavoriteAction(state, action);
        case actions.UPDATE_PRODUCTS_LOADER_ACTION:
            return {
                ...state,
                loadingProducts: action.payload.loadingProducts
            };
        case actions.UPDATE_PRODUCT_DIRECTIONS_ACTION:
            return {
                ...state,
                productDirections: action.payload.productDirections
            };
        case actions.UPDATE_SELECTED_DATE_TYPE_ACTION:
            return {
                ...state,
                selectedDateType: action.payload.selectedDateType
            };
        case actions.UPDATE_SELECTED_DATE_ACTION:
            return {
                ...state,
                selectedDate: action.payload.selectedDate
            };
        case actions.UPDATE_SELECTED_PRODUCT_DIRECTIONS_ACTION:
            return {
                ...state,
                selectedProductDirections: action.payload.selectedProductDirections
            };
        case actions.INIT_SEARCH_ACTION:
            return {
                ...state,
                currentSearchData: {
                    loadingReport: true,
                    loadingMoreItems: false,
                    noMoreItemsForLoading: false,
                    totalNumberOfResults: 0,
                    driverManifestReportItems: [],
                    allCustomFields: []
                }
            };
        case actions.UPDATE_CURRENT_SEARCH_DATA_ACTION:
            return {
                ...state,
                currentSearchData: {
                    ...state.currentSearchData,
                    loadingReport: false,
                    totalNumberOfResults: action.payload.response.totalNumberOfResults,
                    driverManifestReportItems: [...action.payload.response.driverManifestReportItems],
                    allCustomFields: [...action.payload.response.allCustomFields]
                }
            };
        case actions.UPDATE_CURRENT_SEARCH_DATA_SORT_BY_ACTION:
            return {
                ...state,
                currentSearchDataSortBy: action.payload.sortBy
            };
        case actions.CLEAR_SEARCH_DATA_ACTION:
            return {
                ...state,
                currentSearchData: null,
                currentSearchDataSortBy: null
            };
        case actions.RESET_STATE_ACTION:
            return initialState;
        default:
            return state;
    }
}

function handleUpdateSelectedFavoriteAction(state: State, action: actions.UpdateSelectedFavoriteId) {

    const newState = {...state};

    if (action.payload.favoriteId) {
        const favorite: FavoriteResponseModel = state.favorites.find((favorite: FavoriteResponseModel) => favorite.favoriteId === action.payload.favoriteId);
        const favoriteData: ReportSearchRequestModel = JSON.parse(favorite.favoriteData);

        const expressionItems: ExpressionItemModel[] = convertBaseExpressionRequestsIntoExpressionItems(favoriteData.searchExpression);

        for (let expressionItem of expressionItems) {
            if (expressionItem.itemType === ExpressionItemTypeEnum.FILTER) {

                let filter: FilterItemModel = (<ExpressionFilterModel>expressionItem).filter;
                switch (filter.name) {
                    case "Departure Date Product":
                        if (filter.data[0] === data.DATE_TODAY) {
                            newState.selectedDateType = data.DATE_TODAY;
                            newState.selectedDate = moment();
                        } else if (filter.data[0] === data.DATE_TOMORROW) {
                            newState.selectedDateType = data.DATE_TOMORROW;
                            newState.selectedDate = moment().add(1, 'days');
                        } else {
                            newState.selectedDateType = data.DATE_CUSTOM;
                            const date: moment.Moment = getDateFromJsonObject(JSON.parse(filter.data[0]));
                            newState.selectedDate = date;
                        }
                        break;
                    case "Product-Direction":
                        newState.selectedProductDirections = [];
                        filter.data.map((selectedProductDirectionString: string) => {
                            newState.selectedProductDirections.push(selectedProductDirectionString);
                        });
                        break;
                }
            }
        }
    }

    newState.selectedFavoriteId = action.payload.favoriteId;
    newState.favoriteCreatedUpdated = false;
    newState.currentSearchData = null;
    newState.currentSearchDataSortBy = null;

    return newState;
}

function convertBaseExpressionRequestsIntoExpressionItems(baseExpressionRequests: BaseExpressionRequestModel[]): ExpressionItemModel[] {

    const resultList: ExpressionItemModel[] = [];

    for (const item of baseExpressionRequests) {
        switch (item.expressionType) {
            case ExpressionItemTypeEnum.OPERATOR:
                const operatorExpressionRequest: OperatorExpressionRequestModel = <OperatorExpressionRequestModel>item;
                resultList.push(new ExpressionOperatorModel(<ExpressionOperatorTypeEnum>operatorExpressionRequest.operator));
                break;
            case ExpressionItemTypeEnum.FILTER:
                const filterExpressionRequest: FilterExpressionRequestModel = <FilterExpressionRequestModel>item;
                const filterItem: FilterItemModel = findFilterItemByQueryFieldName(filterExpressionRequest.fieldName, filterExpressionRequest.data);
                resultList.push(new ExpressionFilterModel(filterItem));
                break;
            case ExpressionItemTypeEnum.GROUP:
                const groupExpressionRequest: GroupExpressionRequestModel = <GroupExpressionRequestModel>item;
                const groupExpressionItems: ExpressionItemModel[] = convertBaseExpressionRequestsIntoExpressionItems(groupExpressionRequest.groupExpression);
                const groupHeight: number = findExpressionItemsMaxHeight(groupExpressionItems) + 1;
                resultList.push(new ExpressionGroupModel(groupExpressionItems, groupHeight));
                break;
            default:
                console.error('convertBaseExpressionRequestsIntoExpressionItems :: unexpected item type');
        }
    }

    return resultList;
}

function findExpressionItemsMaxHeight(expressionItems: ExpressionItemModel[]): number {

    let maxHeight = 0;

    for (const expressionItem of expressionItems) {
        if (expressionItem.itemType === ExpressionItemTypeEnum.GROUP) {
            const groupExpressionItem: ExpressionGroupModel = <ExpressionGroupModel>expressionItem;
            if (groupExpressionItem.groupHeight > maxHeight) {
                maxHeight = groupExpressionItem.groupHeight;
            }
        }
    }

    return maxHeight;
}

function getDateFromJsonObject(dateJson): moment.Moment {
    const date = moment();
    date.year(dateJson.year);
    date.month(dateJson.month - 1);
    date.date(dateJson.day);

    return date;
}
