<div class="grid-x grid-padding-x ml--25px">
    <div class="secondaryNavColumns_container cell medium-3 large-2" *ngIf="userSystemPermissions(allSystemPermissions().RUN_ORDER_REPORT)">
        <div class="secondaryNav_column">

            <div class="secondaryNav_label">
                <h2> General </h2>
            </div>

            <ul class="secondaryNav_list">
                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().RUN_ORDER_REPORT_FULL)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.orderReportsPageUrl() }}"
                       routerLinkActive="secondaryNavActive">
                       Order Reports
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().RUN_AUTO_REPORTS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.autoReportsPageUrl() }}"
                       routerLinkActive="secondaryNavActive">
                       Auto Reports
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().RUN_AUDITLOG_REPORT)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.auditLogReportsPageUrl() }}"
                       routerLinkActive="secondaryNavActive">
                       Audit Log Report
                    </a>
                </li>

            </ul>
        </div>
    </div>


    <div class="secondaryNavColumns_container cell medium-3 large-2" *ngIf="userSystemPermissions(allSystemPermissions().RUN_OPERATIONAL_REPORTS)">
        <div class="secondaryNav_column">

            <div class="secondaryNav_label">
                <h2> Operational </h2>
            </div>

            <ul class="secondaryNav_list">
                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().RUN_OPERATIONS_REPORT)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.operationsReportPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Operations Report
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().RUN_OVERRIDE_REPORT)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.overrideReportPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Override Report
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().RUN_DRIVER_MANIFEST_REPORT)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.driverManifestReportPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Driver Manifest Report
                    </a>
                </li>
            </ul>
        </div>
    </div>


    <div class="secondaryNavColumns_container cell medium-3 large-2" *ngIf="userSystemPermissions(allSystemPermissions().RUN_FINANCIAL_REPORTS)">
        <div class="secondaryNav_column">

            <div class="secondaryNav_label">
                <h2> Financial </h2>
            </div>

            <ul class="secondaryNav_list">
                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().RUN_TRIP_REVENUE_REPORT)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.tripRevenueReportPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Trip Revenue Report
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().RUN_TICKET_TYPE_REVENUE_REPORT)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.ticketTypeRevenueReportPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Ticket Type Revenue Report
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().RUN_COLLECTED_AND_EARNED_REVENUE_REPORT)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.collectedAndEarnedRevenueReportPageUrl() }}"
                       routerLinkActive="secondaryNavActive">
                       Collected & Earned Revenue Report
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().RUN_COMMISSION_REPORT)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.commissionReportPageUrl() }}"
                       routerLinkActive="secondaryNavActive">
                       Commission Report
                    </a>
                </li>
            </ul>
        </div>
    </div>


    <div class="secondaryNavColumns_container cell medium-3 small-2 " *ngIf="userSystemPermissions(allSystemPermissions().RUN_CUSTOM_REPORTS) && customReportPermission() && ( userSystemPermissions(allSystemPermissions().RUN_REVENUE_REPORT) || userSystemPermissions(allSystemPermissions().RUN_NET_SUITE_EXPORT_REPORT) || userSystemPermissions(allSystemPermissions().RUN_CUSTOM_BILLING_REPORT) )">
        <div class="secondaryNav_column">

            <div class="secondaryNav_label">
                <h2> Custom </h2>
            </div>

            <ul class="secondaryNav_list">
                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().RUN_REVENUE_REPORT) && customRevenueReportPermission()">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.revenueReportPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Revenue Report
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().RUN_NET_SUITE_EXPORT_REPORT) && customNetSuiteExportReportPermission()">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.netSuiteExportReportPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       NetSuite Export Report
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().RUN_CUSTOM_BILLING_REPORT) && customCustomBillingReportReportPermission()">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.customBillingReportPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Monthly Billing Report
                    </a>
                </li>
            </ul>
        </div>
    </div>


    <div class="secondaryNavColumns_container cell medium-3 large-2" *ngIf="userSystemPermissions(allSystemPermissions().RUN_HARDWARE_REPORTS)">
        <div class="secondaryNav_column">

            <div class="secondaryNav_label">
                <h2> Hardware </h2>
            </div>

            <ul class="secondaryNav_list">

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().RUN_INVALID_SCAN_REPORT)" >
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.invalidScanReportPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Invalid Scan Report
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().ANDROID_DEBUG_LOGS)" >
                    <a class="secondaryNav_link second--nav--link"
                        routerLink="{{ appRoutesService.androidDebugLogsPageUrl() }}"
                        routerLinkActive="secondaryNavActive active--nav">
                        Android Debug Logs
                    </a>
                </li>

            </ul>
        </div>
    </div>

