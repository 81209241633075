import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {SubmitProductCostsData} from "./components/edit-product-costs-dialog-form/edit-product-costs-dialog-form.component";
import {EditProductCostsDialogSandbox} from "./sandbox/edit-product-costs-dialog.sandbox";
import {ProductCostResponseModel} from "../../../../../../../core/modules/rest/product/response/product-cost-response.model";
import {DropdownOptionModel} from "../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export class EditProductCostsDialogData extends DialogData {

    productId: number;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, productId: number) {
        super();
        this.productId = productId;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-edit-product-costs-dialog',
    templateUrl: './edit-product-costs-dialog.component.html'
})
export class EditProductCostsDialogComponent implements OnDestroy {

    productId: number;
    successCallbackFn: Function;

    loading$: Observable<boolean>;
    productAdditionalCosts$: Observable<ProductCostResponseModel[]>;
    additionalCosts$: Observable<DropdownOptionModel[]>;
    productIncludedCosts$: Observable<ProductCostResponseModel[]>;
    includedCosts$: Observable<DropdownOptionModel[]>;
    productDiscounts$: Observable<ProductCostResponseModel[]>;
    discounts$: Observable<DropdownOptionModel[]>;
    productAutoAppliedDiscounts$: Observable<ProductCostResponseModel[]>;
    autoAppliedDiscounts$: Observable<DropdownOptionModel[]>;

    constructor(@Inject(DialogData) data: EditProductCostsDialogData, private sandbox: EditProductCostsDialogSandbox) {

        this.productId = data.productId;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.productId);

        this.loading$ = this.sandbox.loading$;
        this.productAdditionalCosts$ = this.sandbox.productAdditionalCosts$;
        this.additionalCosts$ = this.sandbox.additionalCosts$;
        this.productIncludedCosts$ = this.sandbox.productIncludedCosts$;
        this.includedCosts$ = this.sandbox.includedCosts$;
        this.productDiscounts$ = this.sandbox.productDiscounts$;
        this.discounts$ = this.sandbox.discounts$;
        this.productAutoAppliedDiscounts$ = this.sandbox.productAutoAppliedDiscounts$;
        this.autoAppliedDiscounts$ = this.sandbox.autoAppliedDiscounts$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitProductCostsData) {
        this.sandbox.updateProductCosts(data).subscribe(() => {
            this.successCallbackFn(data.productId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
