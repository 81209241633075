import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {PricingTierSimpleResponseModel} from "./response/pricing-tier-simple-response.model";
import {PricingDateRangeFullResponseModel} from "./response/pricing-date-range-full-response.model";
import {GetPricingDateRangesFilteredRequestModel} from "./request/get-pricing-date-ranges-filtered-request.model";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {CreatePricingDateRangeRequestModel} from "./request/create-pricing-date-range-request.model";
import {UpdatePricingDateRangeRequestModel} from "./request/update-pricing-date-range-request.model";
import {UpdatePricingDateRangesRequestModel} from "./request/update-pricing-date-ranges-request.model";
import {UpdatePricingDateRangePriorityRequestModel} from "./request/update-pricing-date-range-priority-request.model";
import {CloneProductAvailabilitiesRequestModel} from "../product-availability/request/clone-product-availabilities-request.model";
import {ClonePricingDateRangesRequestModel} from "./request/clone-pricing-date-ranges-request.model";
import {PricingDateRangeResponseModel} from "./response/pricing-date-range-response.model";
import {PricingDateRangesFullResponseModel} from "./response/pricing-date-ranges-full-response.model";

@Injectable()
export class PricingDateRangeRestService {

    constructor(private httpClient: HttpClient) {
    }

    getPricingTiersByPassIdPickupLocationIdAndDropoffLocationIdNow(passId: number, pickupLocationId: number, dropoffLocationId: number): Observable<PricingTierSimpleResponseModel[]> {
        return this.get<PricingTierSimpleResponseModel[]>("getPricingTiersByPassIdPickupLocationIdAndDropoffLocationIdNow", passId, pickupLocationId, dropoffLocationId);
    }

    getPricingTiersByProductIdAndTripTypeNow(productId: number, isRoundTrip: boolean): Observable<PricingTierSimpleResponseModel[]> {
        return this.get("getPricingTiersByProductIdAndTripTypeNow", productId, isRoundTrip);
    }

    getPricingTiersByProductIdTripTypeDateIntAndProductAvailabilityId(productId: number, isRoundTrip: boolean, dateInt: number, productAvailabilityId: number): Observable<PricingTierSimpleResponseModel[]> {
        return this.get("getPricingTiersByProductIdTripTypeDateIntAndProductAvailabilityId", productId, isRoundTrip, dateInt, productAvailabilityId);
    }

    getPricingTiersByProductIdPickupLocationIdDropoffLocationIdAndDirectionNow(productId: number, pickupLocationId: number, dropoffLocationId: number, isReturnTrip: boolean): Observable<PricingTierSimpleResponseModel[]> {
        return this.get<PricingTierSimpleResponseModel[]>("getPricingTiersByProductIdPickupLocationIdDropoffLocationIdAndDirectionNow", productId, pickupLocationId, dropoffLocationId, isReturnTrip);
    }

    getPricingTiersByProductIdPickupLocationIdDropoffLocationIdDirectionDateIntAndProductAvailabilityId(productId: number, pickupLocationId: number, dropoffLocationId: number, isReturnTrip: boolean, dateInt: number, productAvailabilityId: number): Observable<PricingTierSimpleResponseModel[]> {
        return this.get<PricingTierSimpleResponseModel[]>("getPricingTiersByProductIdPickupLocationIdDropoffLocationIdDirectionDateIntAndProductAvailabilityId", productId, pickupLocationId, dropoffLocationId, isReturnTrip, dateInt, productAvailabilityId);
    }

    getPricingTiersByProductIdPickupLocationIdRegularDropoffLocationIdRegularPickupLocationIdReturnDropoffLocationIdReturnAndDirectionNow(productId: number, pickupLocationIdRegular: number, dropoffLocationIdRegular: number, pickupLocationIdReturn: number, dropoffLocationIdReturn: number, isReturnTrip: boolean): Observable<PricingTierSimpleResponseModel[]> {
        return this.get<PricingTierSimpleResponseModel[]>("getPricingTiersByProductIdPickupLocationIdRegularDropoffLocationIdRegularPickupLocationIdReturnDropoffLocationIdReturnAndDirectionNow", productId, pickupLocationIdRegular, dropoffLocationIdRegular, pickupLocationIdReturn, dropoffLocationIdReturn, isReturnTrip);
    }

    getPricingTiersByProductIdPickupLocationIdRegularDropoffLocationIdRegularDateIntRegularProductAvailabilityIdRegularPickupLocationIdReturnDropoffLocationIdReturnAndDirection(productId: number, pickupLocationIdRegular: number, dropoffLocationIdRegular: number, dateIntRegular: number, productAvailabilityIdRegular: number, pickupLocationIdReturn: number, dropoffLocationIdReturn: number, isReturnTrip: boolean): Observable<PricingTierSimpleResponseModel[]> {
        return this.get<PricingTierSimpleResponseModel[]>("getPricingTiersByProductIdPickupLocationIdRegularDropoffLocationIdRegularDateIntRegularProductAvailabilityIdRegularPickupLocationIdReturnDropoffLocationIdReturnAndDirection", productId, pickupLocationIdRegular, dropoffLocationIdRegular, dateIntRegular, productAvailabilityIdRegular, pickupLocationIdReturn, dropoffLocationIdReturn, isReturnTrip);
    }

    getPricingTiersByProductIdPickupLocationIdRegularDropoffLocationIdRegularPickupLocationIdReturnDropoffLocationIdReturnDateIntReturnProductAvailabilityIdReturnAndDirection(productId: number, pickupLocationIdRegular: number, dropoffLocationIdRegular: number, pickupLocationIdReturn: number, dropoffLocationIdReturn: number, dateIntReturn: number, productAvailabilityIdReturn: number, isReturnTrip: boolean): Observable<PricingTierSimpleResponseModel[]> {
        return this.get<PricingTierSimpleResponseModel[]>("getPricingTiersByProductIdPickupLocationIdRegularDropoffLocationIdRegularPickupLocationIdReturnDropoffLocationIdReturnDateIntReturnProductAvailabilityIdReturnAndDirection", productId, pickupLocationIdRegular, dropoffLocationIdRegular, pickupLocationIdReturn, dropoffLocationIdReturn, dateIntReturn, productAvailabilityIdReturn, isReturnTrip);
    }

    getPricingTiersByProductIdPickupLocationIdRegularDropoffLocationIdRegularDateIntRegularProductAvailabilityIdRegularPickupLocationIdReturnDropoffLocationIdReturnDateIntReturnProductAvailabilityIdReturnAndDirection(productId: number, pickupLocationIdRegular: number, dropoffLocationIdRegular: number, dateIntRegular: number, productAvailabilityIdRegular: number, pickupLocationIdReturn: number, dropoffLocationIdReturn: number, dateIntReturn: number, productAvailabilityIdReturn: number, isReturnTrip: boolean): Observable<PricingTierSimpleResponseModel[]> {
        return this.get<PricingTierSimpleResponseModel[]>("getPricingTiersByProductIdPickupLocationIdRegularDropoffLocationIdRegularDateIntRegularProductAvailabilityIdRegularPickupLocationIdReturnDropoffLocationIdReturnDateIntReturnProductAvailabilityIdReturnAndDirection", productId, pickupLocationIdRegular, dropoffLocationIdRegular, dateIntRegular, productAvailabilityIdRegular, pickupLocationIdReturn, dropoffLocationIdReturn, dateIntReturn, productAvailabilityIdReturn, isReturnTrip);
    }

    getPricingDateRangesFiltered(request: GetPricingDateRangesFilteredRequestModel): Observable<PricingDateRangesFullResponseModel> {
        return this.httpClient.post<PricingDateRangesFullResponseModel>(environment.server_api_url + `/api/secured/pricingDateRange/getPricingDateRangesFiltered`, request);
    }

    getPricingDateRangesByPricingDateRangeIds(pricingDateRangeIds: number[]): Observable<PricingDateRangeResponseModel[]> {
        return this.httpClient.post<PricingDateRangeFullResponseModel[]>(environment.server_api_url + '/api/secured/pricingDateRange/getPricingDateRangesByPricingDateRangeIds', pricingDateRangeIds);
    }

    getPricingDateRangesFullByPricingDateRangeIds(pricingDateRangeIds: number[]): Observable<PricingDateRangeFullResponseModel[]> {
        return this.httpClient.post<PricingDateRangeFullResponseModel[]>(environment.server_api_url + '/api/secured/pricingDateRange/getPricingDateRangesFullByPricingDateRangeIds', pricingDateRangeIds);
    }

    getPricingDateRangesFullByProductId(productId: number): Observable<PricingDateRangeFullResponseModel[]> {
        return this.get<PricingDateRangeFullResponseModel[]>("getPricingDateRangesFullByProductId", productId);
    }

    getPricingDateRangesFullByPassId(passId: number): Observable<PricingDateRangeFullResponseModel[]> {
        return this.get<PricingDateRangeFullResponseModel[]>("getPricingDateRangesFullByPassId", passId);
    }

    createPricingDateRange(request: CreatePricingDateRangeRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/pricingDateRange/create', request);
    }

    clonePricingDateRanges(request: ClonePricingDateRangesRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pricingDateRange/clonePricingDateRanges', request, {responseType: 'text'});
    }

    updatePricingDateRange(request: UpdatePricingDateRangeRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pricingDateRange/update', request, {responseType: 'text'});
    }

    updatePricingDateRanges(request: UpdatePricingDateRangesRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pricingDateRange/updatePricingDateRanges', request, {responseType: 'text'});
    }

    updatePricingDateRangePriority(request: UpdatePricingDateRangePriorityRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pricingDateRange/updatePricingDateRangePriority', request, {responseType: 'text'});
    }

    activatePricingDateRanges(pricingDateRangeIds: number[]): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pricingDateRange/activatePricingDateRanges', pricingDateRangeIds, {responseType: 'text'});
    }

    deactivatePricingDateRanges(pricingDateRangeIds: number[]): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pricingDateRange/deactivatePricingDateRanges', pricingDateRangeIds, {responseType: 'text'});
    }

    private get<T>(endpoint: string, ...params: any[]): Observable<T> {

        let path: string = endpoint;

        for (let p of params) {
            path += "/" + p;
        }

        return this.httpClient.get<T>(`${environment.server_api_url}/api/secured/pricingDateRange/${path}`);
    }
}
