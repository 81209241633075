import {Action} from "@ngrx/store";
import {QuickSellingButtonPassResponseModel} from "../../../../../../../../../core/modules/rest/quick-selling-button-pass/response/quick-selling-button-pass-response.model";
import {PassSimpleResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {LocationListItemDescriptorResponseModel} from "../../../../../../../../../core/modules/rest/location-list/response/location-list-item-descriptor-response.model";
import {TierResponseModel} from "../../../../../../../../../core/modules/rest/tier/response/tier-response.model";

export const RESET_STATE_ACTION = '[Dialog - Edit Quick Selling Button Pass :: MAIN] Reset State';

export const SET_DATA_ACTION = '[Dialog - Edit Quick Selling Button Pass :: MAIN] Set Data';

export const SET_PICKUP_LOCATIONS_ACTION = '[Dialog - Edit Quick Selling Button Pass :: MAIN] Set Pickup Locations';
export const SET_PICKUP_LOCATIONS_LOADING_ACTION = '[Dialog - Edit Quick Selling Button Pass :: MAIN] Set Pickup Locations Loading';

export const SET_DROPOFF_LOCATIONS_ACTION = '[Dialog - Edit Quick Selling Button Pass :: MAIN] Set Dropoff Locations';
export const SET_DROPOFF_LOCATIONS_LOADING_ACTION = '[Dialog - Edit Quick Selling Button Pass :: MAIN] Set Dropoff Locations Loading';

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class SetData implements Action {
    readonly type = SET_DATA_ACTION;

    constructor(public quickSellingButtonPass: QuickSellingButtonPassResponseModel,
                public tiers: TierResponseModel[],
                public passes: PassSimpleResponseModel[],
                public pickupLocations: LocationListItemDescriptorResponseModel[],
                public dropoffLocations: LocationListItemDescriptorResponseModel[]) {
    }
}

export class SetPickupLocations implements Action {
    readonly type = SET_PICKUP_LOCATIONS_ACTION;

    constructor(public pickupLocations: LocationListItemDescriptorResponseModel[]) {
    }
}

export class SetPickupLocationsLoading implements Action {
    readonly type = SET_PICKUP_LOCATIONS_LOADING_ACTION;

    constructor(public loading: boolean) {
    }
}

export class SetDropoffLocations implements Action {
    readonly type = SET_DROPOFF_LOCATIONS_ACTION;

    constructor(public dropoffLocations: LocationListItemDescriptorResponseModel[]) {
    }
}

export class SetDropoffLocationsLoading implements Action {
    readonly type = SET_DROPOFF_LOCATIONS_LOADING_ACTION;

    constructor(public loading: boolean) {
    }
}

export type All = ResetState
    | SetData
    | SetPickupLocations
    | SetPickupLocationsLoading
    | SetDropoffLocations
    | SetDropoffLocationsLoading;
