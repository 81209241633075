import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AdminPanelRequestModel } from './request/admin-panel-request.model';
import { AdminPanelResponseModel } from './response/admin-panel-response.model';
import { VoidOrderRequestModel } from '../../../../core/modules/rest/order-summary/request/void-order-request.model';
import { MicroservicesStatusResponseModel } from './response/microservices-status-response.model';
import { SystemHealthMemoryStatusResponse } from './response/system-health-memory-status-response.model';
import { RedisMemoryStatusResponse } from './response/redis-memory-status-response.model';

@Injectable()
export class AdminPanelRestService {

    constructor(private httpClient: HttpClient) {
    }

    // Order :: BEGIN
    sendAllOrdersToReporting(): Observable<String> {
        return this.httpClient.get<String>(environment.server_api_url + '/api/public/reporting/sendAllOrdersToReporting');
    }

    sendOrderToReporting(orderExternalID: string): Observable<String> {
        return this.httpClient.post<String>(environment.server_api_url + `/api/public/reporting/sendOrderToReporting?orderExternalID=` + orderExternalID, {});
    }


    voidOrderStatusUpdate(orderExternalID: string): Observable<String> {
        return this.httpClient.post<String>(environment.server_api_url + `/api/secured/orderSummary/voidOrderStatusUpdate`, orderExternalID);
    }

    voidAndRefundOrderInSmartStubsOnly(voidOrderRequest: VoidOrderRequestModel): Observable<any> {
        return this.httpClient.post<any>(environment.server_api_url + '/api/secured/orderSummary/voidAndRefundOrderInSmartStubsOnly', voidOrderRequest);
    }

    getOrdersForAdminPanelKpiChart(adminPanelRequest: AdminPanelRequestModel): Observable<AdminPanelResponseModel> {
        return this.httpClient.post<AdminPanelResponseModel>(environment.server_api_url + '/api/secured/controlPanel/getOrdersForAdminPanelKpiChart', adminPanelRequest);
    }

    sendFailedTicketCheckinsToReporting(): Observable<String> {
        return this.httpClient.get<String>(environment.server_api_url + '/api/secured/controlPanel/sendFailedTicketCheckinsToReporting');
    }

    sendOrdersToReportingWithinDateRangeConfirmed(adminPanelRequest: AdminPanelRequestModel): Observable<AdminPanelResponseModel> {
        return this.httpClient.post<AdminPanelResponseModel>(environment.server_api_url + '/api/secured/controlPanel/sendOrdersToReportingWithinDateRange', adminPanelRequest);
    }

    updateOrderExpirationStatusWithinDateRange(adminPanelRequest: AdminPanelRequestModel): Observable<AdminPanelResponseModel> {
        return this.httpClient.post<AdminPanelResponseModel>(environment.server_api_url + '/api/secured/controlPanel/updateOrderExpirationStatusWithinDateRange', adminPanelRequest);
    }

    fixOrderExpirationStatusWithinDateRange(adminPanelRequest: AdminPanelRequestModel): Observable<AdminPanelResponseModel> {
        return this.httpClient.post<AdminPanelResponseModel>(environment.server_api_url + '/api/secured/controlPanel/fixOrderStatusAfterWrongExpirationWithinDateRange', adminPanelRequest);
    }

    // Order :: END

    // Audit Log :: BEGIN
    sendAllAuditLogsToReporting(): Observable<String> {
        return this.httpClient.get<String>(environment.server_api_url + '/api/public/reporting/sendAllAuditLogsToReporting');
    }
    // Audit Log :: END

    // System Health Check :: BEGIN
    getMicroservicesStatus(): Observable<MicroservicesStatusResponseModel> {
        return this.httpClient.get<MicroservicesStatusResponseModel>(environment.server_api_url + '/api/secured/controlPanel/getMicroservicesStatus');
    }

    reportingServicePing(): Observable<boolean> {
        return this.httpClient.get<boolean>(environment.server_api_url + '/api/secured/systemHealthCheck/reportingServicePing');
    }

    pdfGeneratorServicePing(): Observable<boolean> {
        return this.httpClient.get<boolean>(environment.server_api_url + '/api/secured/systemHealthCheck/pdfGeneratorServicePing');
    }

    emailServicePing(): Observable<boolean> {
        return this.httpClient.get<boolean>(environment.server_api_url + '/api/secured/systemHealthCheck/emailServicePing');
    }

    paymentServicePing(): Observable<boolean> {
        return this.httpClient.get<boolean>(environment.server_api_url + '/api/secured/systemHealthCheck/paymentServicePing');
    }

    checkDataBaseConnection(): Observable<boolean> {
        return this.httpClient.get<boolean>(environment.server_api_url + '/api/secured/systemHealthCheck/checkDatabaseConnection');
    }

    getMemoryStatus(): Observable<SystemHealthMemoryStatusResponse> {
        return this.httpClient.get<SystemHealthMemoryStatusResponse>(environment.server_api_url + '/api/secured/systemHealthCheck/getMemoryStatus');
    }

    getRedisStatus(): Observable<RedisMemoryStatusResponse> {
        return this.httpClient.get<RedisMemoryStatusResponse>(environment.server_api_url + '/api/secured/systemHealthCheck/getRedisStatus');
    }

    redisServicePing(): Observable<boolean> {
        return this.httpClient.get<boolean>(environment.server_api_url + '/api/secured/systemHealthCheck/redisServicePing');
    }

    // System Health Check :: END

    // Analytics :: BEGIN
    getOrdersForAdminPanel(adminPanelRequest: AdminPanelRequestModel): Observable<AdminPanelResponseModel> {
        return this.httpClient.post<AdminPanelResponseModel>(environment.server_api_url + '/api/secured/controlPanel/getOrdersForAdminPanel', adminPanelRequest);
    }

    getOrdersByStatusForAdminPanel(adminPanelRequest: AdminPanelRequestModel): Observable<AdminPanelResponseModel> {
        return this.httpClient.post<AdminPanelResponseModel>(environment.server_api_url + '/api/secured/controlPanel/getOrdersByStatusForAdminPanel', adminPanelRequest);
    }

    getTicketAndPassCardCheckInsForAdminPanel(adminPanelRequest: AdminPanelRequestModel): Observable<AdminPanelResponseModel> {
        return this.httpClient.post<AdminPanelResponseModel>(environment.server_api_url + '/api/secured/controlPanel/getTicketAndPassCardCheckInsForAdminPanel', adminPanelRequest);
    }
    // Analytics :: END

    //Tools :: BEGIN
    clearCache(): Observable<string> {
        return this.httpClient.get(environment.server_api_url + '/api/secured/clearCache', { responseType: 'text' });
    }

    clearMemory(): Observable<string> {
        return this.httpClient.get(environment.server_api_url + '/api/secured/systemHealthCheck/clearMemory', { responseType: 'text' });
    }

    fixStatusOfOrders(adminPanelRequest: AdminPanelRequestModel): Observable<any> {
        return this.httpClient.post<any>(environment.server_api_url + '/api/secured/controlPanel/fixStatusOfOrders', adminPanelRequest);
    }
    
    fixOrderStatus(orderExternalID: string): Observable<String> {
        return this.httpClient.post<String>(environment.server_api_url + `/api/secured/controlPanel/fixOrderStatus`, orderExternalID);
    }

    sendUnsentEmails(adminPanelRequest: AdminPanelRequestModel): Observable<AdminPanelResponseModel> {
        return this.httpClient.post<AdminPanelResponseModel>(environment.server_api_url + '/api/secured/controlPanel/sendEmailsToOrders', adminPanelRequest);
    }

    createCustomBillingReportForDate(adminPanelRequest: AdminPanelRequestModel): Observable<AdminPanelResponseModel> {
        return this.httpClient.post<AdminPanelResponseModel>(environment.server_api_url + '/api/secured/controlPanel/createCustomBillingReportForDate', adminPanelRequest);
    }
  
    removeElasticSearchForClient() {
        return this.httpClient.post<any>(environment.server_api_url + '/api/secured/controlPanel/removeElasticSearchForClient', null);
    }

    removeMongoDataForClient() {
        return this.httpClient.post<any>(environment.server_api_url + '/api/secured/controlPanel/removeMongoDataForClient', null);
    }


    removeEmails() {
        return this.httpClient.post<any>(environment.server_api_url + '/api/secured/controlPanel/removeEmails', null);
    }

    fixAllDuplicateOccurrences() {
        return this.httpClient.post<any>(environment.server_api_url + '/api/secured/controlPanel/fixAllDuplicateOccurrences', null);
    }

    fixDuplicateOccurrencesInDateRange(adminPanelRequest: AdminPanelRequestModel): Observable<AdminPanelResponseModel> {
        return this.httpClient.post<AdminPanelResponseModel>(environment.server_api_url + '/api/secured/controlPanel/fixDuplicateOccurrencesInDateRange', adminPanelRequest);
    }

    removeOccurrencesAfterSpecificDate(adminPanelRequest: AdminPanelRequestModel): Observable<AdminPanelResponseModel> {
        return this.httpClient.post<AdminPanelResponseModel>(environment.server_api_url + '/api/secured/controlPanel/removeOccurrencesAfterSpecificDate', adminPanelRequest);
    }

    fixAppleAndGoogleWalletAfterWrongExpirationWithinDateRange(adminPanelRequest: AdminPanelRequestModel): Observable<AdminPanelResponseModel> {
        return this.httpClient.post<AdminPanelResponseModel>(environment.server_api_url + '/api/secured/controlPanel/fixAppleAndGoogleWalletAfterWrongExpirationWithinDateRange', adminPanelRequest);
    }
    // Tools :: END
}
