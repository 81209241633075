import { Component, EventEmitter, Input, OnInit, Output  } from "@angular/core";
import {FormGroup} from "@angular/forms";


@Component({
    selector: 'app-edit-location-list-item-form',
    templateUrl: './edit-location-list-item-form.component.html'
})
export class EditLocationListItemFormComponent implements OnInit {

    @Input() locationListItemForm: FormGroup;

    @Output() locationListItemUpEvent = new EventEmitter<number>();
    @Output() locationListItemDownEvent = new EventEmitter<number>();
    @Output() locationListItemRemovedEvent = new EventEmitter<[number, string]>();

    constructor() {}

    ngOnInit() {}

    onLocationListItemUp() {
        this.locationListItemUpEvent.emit(this.locationListItemForm.value.locationId);
    }

    onLocationListItemDown() {
        this.locationListItemDownEvent.emit(this.locationListItemForm.value.locationId);
    }

    onLocationListItemRemoved() {
        this.locationListItemRemovedEvent.emit([this.locationListItemForm.value.locationId, this.locationListItemForm.value.locationDescription]);
    }
}
