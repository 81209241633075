import * as actions from './actions';
import {UserInfoResponseModel} from '../../modules/rest/user/response/user-info-response.model';
export interface State {
    userInfo: UserInfoResponseModel;
    webSocketNotifications: any[];
    webSocketAlertIndicator: {};
}

const initialState: State = {
    userInfo: null,
    webSocketNotifications: [],
    webSocketAlertIndicator: {
        alertIndicator: false,
        notification: null,
    },
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.SET_USER_INFO_ACTION:
            return {
                ...state,
                userInfo: action.user
            };
        case actions.SET_USER_WEBSOCKET_NOTIFICATIONS:
            return {
                ...state,
                webSocketNotifications: action.notifications
            };
        case actions.SET_USER_WEBSOCKET_ALERT_INDICATOR:
            return {
                ...state,
                webSocketAlertIndicator: {
                    alertIndicator: action.alertIndicator,
                    notification: action.notification
                }
            };
        case actions.SET_USER_FLAG_PRINT_ORDER_ACTION:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    printOrder: action.printOrder
                }
            };
        case actions.SET_USER_FLAG_SHOW_ORDER_SUMMARY_ACTION:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    showOrderSummary: action.showOrderSummary
                }
            };
        case actions.SET_USER_FLAG_USE_TOUCH_SCREEN_LAYOUT:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    useTouchScreenLayout: action.useTouchScreenLayout
                }
            };
        default:
            return state;
    }
}

// #################################################### SELECTORS ####################################################
export const userInfo_selector = (state: State) => state.userInfo;
export const webSocketNotifications_selector = (state: State) => state.webSocketNotifications;
export const webSocketAlertIndicator_selector = (state: State) => state.webSocketAlertIndicator;
