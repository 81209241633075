import {Action} from "@ngrx/store";
import {FavoriteResponseModel} from "../../../../../../core/modules/rest/favorite/response/favorite-response.model";

export const RESET_STATE_ACTION = "[Dialog - Update Favorite :: MAIN] Reset State";
export const SET_FAVORITE = "[Dialog - Update Favorite :: MAIN] Set Favorite";
export const SET_DESCRIPTION_AVAILABLE = "[Dialog - Update Favorite :: MAIN] Set Description Available";

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class SetFavorite implements Action {
    readonly type = SET_FAVORITE;

    constructor(public favorite: FavoriteResponseModel) {
    }
}

export class SetDescriptionAvailable implements Action {
    readonly type = SET_DESCRIPTION_AVAILABLE;

    constructor(public descriptionAvailable: boolean) {
    }
}

export type All = ResetState | SetFavorite | SetDescriptionAvailable;
