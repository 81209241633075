import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {EmailHtmlTemplateResponseModel} from "../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {Observable} from "rxjs";
import {EmailHtmlTemplateRestService} from "../../../../../../../../core/modules/rest/email-html-template/email-html-template-rest.service";
import {select, Store} from "@ngrx/store";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {Injectable} from "@angular/core";

@Injectable()
export class PreviewEmailHtmlTemplateDialogSandbox extends DialogSandbox {

    emailHtmlTemplate$: Observable<EmailHtmlTemplateResponseModel> = this.store.pipe(
        select(reducer.emailHtmlTemplate_selector)
    );

    constructor(
        appDialogsService: AppDialogsService,
        private emailHtmlTemplateRestService: EmailHtmlTemplateRestService,
        private store: Store<reducer.State>) {

        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    destroyDialog() {
        this.store.dispatch(new actions.ResetState());
        super.destroyDialog();
    }

    loadEmailHtmlTemplate(emailHtmlTemplateId: number) {

        this.showLoader();

        this.emailHtmlTemplateRestService.getById(emailHtmlTemplateId)
            .subscribe((emailHtmlTemplate: EmailHtmlTemplateResponseModel) => {
                this.store.dispatch(new actions.SetEmailHtmlTemplate(emailHtmlTemplate));
                this.hideLoader();
            },
            () => this.hideLoader());
    }
}
