import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {PricingDateRangeResponseModel} from "../../../../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-response.model";

export const REDUCER_NAME = 'dialog_clone-pass-pricing-date-ranges';

export interface State {
    pricingDateRanges: PricingDateRangeResponseModel[];
}

const INITIAL_STATE: State = {
    pricingDateRanges: []
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const pricingDateRanges_selector = createSelector(state_selector, (state: State) => state.pricingDateRanges);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;

        case actions.SET_PRICING_DATE_RANGES:
            return {...state, pricingDateRanges: action.pricingDateRanges};

        default:
            return state;
    }
}
