import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {PassSimpleResponseModel} from "./response/pass-simple-response.model";
import {PassResponseModel} from "./response/pass-response.model";
import {PassProductResponseModel} from "./response/pass-product-response.model";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {ImageResponseModel} from "../image-response.model";
import {CreatePassRequestModel} from "./request/create-pass-request.model";
import {ClonePassRequestModel} from "./request/clone-pass-request.model";
import {UpdatePassBasicInfoRequestModel} from "./request/update-pass-basic-info-request.model";
import {UpdatePassFlagsRequestModel} from "./request/update-pass-flags-request.model";
import {UpdatePassValidityRequestModel} from "./request/update-pass-validity-request.model";
import {UpdatePassEmailHtmlTemplatesRequestModel} from "./request/update-pass-email-html-templates-request.model";
import {UpdatePassProductsRequestModel} from "./request/update-pass-products-request.model";
import {PassCostResponseModel} from "./response/pass-cost-response.model";
import {UpdatePassCostsRequestModel} from "./request/update-pass-costs-request.model";

@Injectable()
export class PassRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<PassSimpleResponseModel[]> {
        return this.httpClient.get<PassSimpleResponseModel[]>(environment.server_api_url + '/api/secured/pass/getAll');
    }

    getAllActive(): Observable<PassSimpleResponseModel[]> {
        return this.httpClient.get<PassSimpleResponseModel[]>(environment.server_api_url + '/api/secured/pass/getAllActive');
    }

    getPassSimpleById(passId: number): Observable<PassSimpleResponseModel> {
        return this.httpClient.get<PassSimpleResponseModel>(environment.server_api_url + '/api/secured/pass/getPassSimpleById/' + passId);
    }

    getPassById(passId: number): Observable<PassResponseModel> {
        return this.httpClient.get<PassResponseModel>(environment.server_api_url + '/api/secured/pass/getPassById/' + passId);
    }

    getPassesByCurrentUser(): Observable<PassSimpleResponseModel[]> {
        return this.httpClient.get<PassSimpleResponseModel[]>(environment.server_api_url + '/api/secured/pass/getPassesByCurrentUser');
    }

    getPassesByCurrentUserSortedByDescription(): Observable<PassSimpleResponseModel[]> {
        return this.httpClient.get<PassSimpleResponseModel[]>(environment.server_api_url + '/api/secured/pass/getPassesByCurrentUserSortedByDescription');
    }

    getPassProductsByPassId(passId: number): Observable<PassProductResponseModel[]> {
        return this.httpClient.get<PassProductResponseModel[]>(environment.server_api_url + '/api/secured/pass/getPassProductsByPassId/' + passId);
    }

    getPassCostsByPassId(passId: number): Observable<PassCostResponseModel[]> {
        return this.httpClient.get<PassCostResponseModel[]>(environment.server_api_url + '/api/secured/pass/getPassCostsByPassId/' + passId);
    }

    create(request: CreatePassRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + "/api/secured/pass/create", request);
    }

    clonePass(request: ClonePassRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + "/api/secured/pass/clonePass", request);
    }

    updatePassBasicInfo(request: UpdatePassBasicInfoRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pass/updatePassBasicInfo', request, {responseType: 'text'});
    }

    updatePassProducts(request: UpdatePassProductsRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pass/updatePassProducts', request, {responseType: 'text'});
    }

    updatePassImage(passId: number, request: FormData): Observable<ImageResponseModel> {
        return this.httpClient.post<ImageResponseModel>(environment.server_api_url + '/api/secured/pass/updatePassImage/' + passId, request);
    }

    removePassImage(passId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pass/removePassImage/' + passId, null, {responseType: 'text'});
    }

    updatePassCardBackgroundImage(passId: number, request: FormData): Observable<ImageResponseModel> {
        return this.httpClient.post<ImageResponseModel>(environment.server_api_url + '/api/secured/pass/updatePassCardBackgroundImage/' + passId, request);
    }

    removePassCardBackgroundImage(passId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pass/removePassCardBackgroundImage/' + passId, null, {responseType: 'text'});
    }

    updatePassFlags(request: UpdatePassFlagsRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pass/updatePassFlags', request, {responseType: 'text'});
    }

    updatePassValidity(request: UpdatePassValidityRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pass/updatePassValidity', request, {responseType: 'text'});
    }

    updatePassEmailHtmlTemplates(request: UpdatePassEmailHtmlTemplatesRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pass/updatePassEmailHtmlTemplates', request, {responseType: 'text'});
    }

    updatePassCosts(request: UpdatePassCostsRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pass/updatePassCosts', request, {responseType: 'text'});
    }

    activate(passId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pass/activate', passId, {responseType: 'text'});
    }

    deactivate(passId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/pass/deactivate', passId, {responseType: 'text'});
    }
}
