import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared.module";
import {OrderSummaryDialogComponent} from "./container/order-summary-dialog.component";
import {EditableFieldsComponentDialogSummaryComponent} from "./components/editable-fields-dialog-summary/editable-fields-dialog-summary.component";
import {PassDetailsDialogSummaryComponent} from "./components/pass-details-dialog-summary/pass-details-dialog-summary.component";
import {ProductDetailsDialogSummaryComponent} from "./components/product-details-dialog-summary/product-details-dialog-summary.component";
import {OrderSummaryDialogSandbox} from "./sandbox/order-summary-dialog.sandbox";
import {StoreModule} from "@ngrx/store";
import * as reducer from './sandbox/store/reducer';
import {GeneralInfoDialogSummaryComponent} from './components/general-info-dialog-summary/general-info-dialog-summary.component';
import {PaymentInfoDialogSummaryComponent} from './components/payment-info-dialog-summary/payment-info-dialog-summary.component';
import {PrintingInfoDialogSummaryComponent} from './components/printing-info-dialog-summary/printing-info-dialog-summary.component';
import {EmailSendingInfoDialogSummaryComponent} from './components/email-sending-info-dialog-summary/email-sending-info-dialog-summary.component';
import {VoidingInfoDialogSummaryComponent} from './components/voiding-info-dialog-summary/voiding-info-dialog-summary.component';
import {ActionButtonsDialogSummaryComponent} from './components/action-buttons-dialog-summary/action-buttons-dialog-summary.component';
import {ProductDetailDialogSummaryComponent} from './components/product-details-dialog-summary/product-detail-dialog-summary/product-detail-dialog-summary.component';
import { PassDetailDialogSummaryComponent } from './components/pass-details-dialog-summary/pass-detail-dialog-summary/pass-detail-dialog-summary.component';
import { RelatedOrdersInfoDialogSummaryComponent } from "./components/related-orders-info-dialog-summary/related-orders-info-dialog-summary.component";
import { RelatedOrderInfoDialogSummaryComponent } from "./components/related-orders-info-dialog-summary/related-order-info-dialog-summary/related-order-info-dialog-summary.component";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(reducer.REDUCER_NAME, reducer.state_reducer)
    ],
    exports: [
        OrderSummaryDialogComponent
    ],
    providers: [
        OrderSummaryDialogSandbox
    ],
    declarations: [
        OrderSummaryDialogComponent,
        EditableFieldsComponentDialogSummaryComponent,
        PassDetailsDialogSummaryComponent,
        ProductDetailsDialogSummaryComponent,
        GeneralInfoDialogSummaryComponent,
        PaymentInfoDialogSummaryComponent,
        PrintingInfoDialogSummaryComponent,
        EmailSendingInfoDialogSummaryComponent,
        VoidingInfoDialogSummaryComponent,
        ActionButtonsDialogSummaryComponent,
        ProductDetailDialogSummaryComponent,
        PassDetailDialogSummaryComponent,
        RelatedOrdersInfoDialogSummaryComponent,
        RelatedOrderInfoDialogSummaryComponent
    ],
    entryComponents: [
        OrderSummaryDialogComponent
    ]
})
export class OrderSummaryDialogModule {

}
