<div class="table_container"
     #tableContainer>
    <!--    data-simplebar-->
    <!--    data-simplebar-autohide="false"-->
    <!--    data-simplebar-horizontal-->

    <table class="table_striped">

        <!-- Table Header :: BEGIN-->
        <thead>
            <th class="tableHeaderColumn">
                <a class="tableHeaderColumnLink_container"
                   [ngClass]="{'tableHeaderSortable': true}"
                   (click)="sortByHeaderClick('Sold By Role')">

                    <div class="tableHeaderColumnLabel">
                        Sold By Role

                        <!-- defaultSortIcon, sortAscending or sortDescending -->
                        <div class="sortIcon"

                             [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Sold By Role'"
                             [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Sold By Role' && currentSearchDataSortBy.order === 'ASC'"
                             [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Sold By Role' && currentSearchDataSortBy.order === 'DESC'">
                            <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                            <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </a>
            </th>

            <th class="tableHeaderColumn">
                <a class="tableHeaderColumnLink_container"
                   [ngClass]="{'tableHeaderSortable': true}"
                   (click)="sortByHeaderClick('Sold By')">

                    <div class="tableHeaderColumnLabel">
                        Sold By

                        <!-- defaultSortIcon, sortAscending or sortDescending -->
                        <div class="sortIcon"

                             [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Sold By'"
                             [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Sold By' && currentSearchDataSortBy.order === 'ASC'"
                             [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Sold By' && currentSearchDataSortBy.order === 'DESC'">
                            <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                            <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </a>
            </th>

            <th class="tableHeaderColumn">
                <a class="tableHeaderColumnLink_container"
                   [ngClass]="{'tableHeaderSortable': true}"
                   (click)="sortByHeaderClick('Product/Pass')">

                    <div class="tableHeaderColumnLabel">
                        Product/Pass

                        <!-- defaultSortIcon, sortAscending or sortDescending -->
                        <div class="sortIcon"

                             [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Product/Pass'"
                             [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Product/Pass' && currentSearchDataSortBy.order === 'ASC'"
                             [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Product/Pass' && currentSearchDataSortBy.order === 'DESC'">
                            <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                            <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </a>
            </th>

            <th class="tableHeaderColumn">
                <a class="tableHeaderColumnLink_container"
                   [ngClass]="{'tableHeaderSortable': true}"
                   (click)="sortByHeaderClick('Total Tickets/PassCards Sold')">

                    <div class="tableHeaderColumnLabel">
                        Total Tickets/PassCards Sold

                        <!-- defaultSortIcon, sortAscending or sortDescending -->
                        <div class="sortIcon"

                             [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Total Tickets/PassCards Sold'"
                             [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Total Tickets/PassCards Sold' && currentSearchDataSortBy.order === 'ASC'"
                             [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Total Tickets/PassCards Sold' && currentSearchDataSortBy.order === 'DESC'">
                            <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                            <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </a>
            </th>

            <th class="tableHeaderColumn">
                <a class="tableHeaderColumnLink_container"
                   [ngClass]="{'tableHeaderSortable': true}"
                   (click)="sortByHeaderClick('Total Revenue')">

                    <div class="tableHeaderColumnLabel">
                        Total Revenue

                        <!-- defaultSortIcon, sortAscending or sortDescending -->
                        <div class="sortIcon"

                             [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Total Revenue'"
                             [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Total Revenue' && currentSearchDataSortBy.order === 'ASC'"
                             [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Total Revenue' && currentSearchDataSortBy.order === 'DESC'">
                            <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                            <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </a>
            </th>

            <th class="tableHeaderColumn">
                <a class="tableHeaderColumnLink_container"
                   [ngClass]="{'tableHeaderSortable': true}"
                   (click)="sortByHeaderClick('Seller Share')">

                    <div class="tableHeaderColumnLabel">
                        Seller Share

                        <!-- defaultSortIcon, sortAscending or sortDescending -->
                        <div class="sortIcon"

                             [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Seller Share'"
                             [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Seller Share' && currentSearchDataSortBy.order === 'ASC'"
                             [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Seller Share' && currentSearchDataSortBy.order === 'DESC'">
                            <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                            <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </a>
            </th>

            <th class="tableHeaderColumn">
                <a class="tableHeaderColumnLink_container"
                   [ngClass]="{'tableHeaderSortable': true}"
                   (click)="sortByHeaderClick('Referrer Share')">

                    <div class="tableHeaderColumnLabel">
                        Referrer Share

                        <!-- defaultSortIcon, sortAscending or sortDescending -->
                        <div class="sortIcon"

                             [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Referrer Share'"
                             [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Referrer Share' && currentSearchDataSortBy.order === 'ASC'"
                             [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Referrer Share' && currentSearchDataSortBy.order === 'DESC'">
                            <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                            <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </a>
            </th>
        </thead><!-- Table Header :: END-->

        <!-- Table Body :: BEGIN -->
        <tr *ngFor="let commissionReportItem of currentSearchData.commissionReportItems">
            <td>
                <span class="td_content">
                    {{ commissionReportItem.soldByRoleDescription }}
                </span>
            </td>

            <td>
                <span class="td_content">
                    {{ commissionReportItem.soldByUserDisplayName }}
                </span>
            </td>

            <td>
                <span class="td_content">
                    {{ commissionReportItem.productId ? commissionReportItem.productDescription : commissionReportItem.passDescription }}
                </span>
            </td>

            <td>
                <span class="td_content">
                    {{ commissionReportItem.totalTicketsPassCardsSold }}
                </span>
            </td>

            <td>
                <span class="td_content">
                    {{ getCellValueForPrintingCurrency(commissionReportItem.totalRevenue) }}
                </span>
            </td>

            <td>
                <span class="td_content">
                    {{ getCellValueForPrintingCurrency(commissionReportItem.sellerShare) }}
                </span>
            </td>

            <td>
                <span class="td_content">
                    {{ getCellValueForPrintingCurrency(commissionReportItem.referrerShare) }}
                </span>
            </td>
        </tr><!-- Table Body :: END -->

    </table>
</div>
