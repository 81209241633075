import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ProductAvailabilityResponseModel} from "../../../../../../../../../core/modules/rest/product-availability/response/product-availability-response.model";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import * as moment from "moment";

export interface SubmitCloneProductAvailabilitiesData {
    productId: number;
    startDate: moment.Moment;
    endDate: moment.Moment;
    productAvailabilitiesData: SubmitCloneProductAvailabilityData[];
}

export interface SubmitCloneProductAvailabilityData {
    productAvailabilityId: number;
    description: string;
}

@Component({
    selector: 'app-clone-product-availabilities-dialog-form',
    templateUrl: './clone-product-availabilities-dialog-form.component.html'
})

export class CloneProductAvailabilitiesDialogFormComponent implements OnChanges {

    @Input() productId: number;
    @Input() productAvailabilities: ProductAvailabilityResponseModel[];

    @Output() submit: EventEmitter<SubmitCloneProductAvailabilitiesData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        this.form = new FormGroup({
            startDate: new FormControl(),
            endDate: new FormControl(),
            suffix: new FormControl(),
            productAvailabilities: new FormArray([])
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.productAvailabilities) {
            this.initializeFormData();
        }
    }

    getHeader(): string {
        if (this.productAvailabilities.length > 1) {
            return "Clone Schedules";
        } else {
            return "Clone Schedule";
        }
    }

    getProductAvailabilitiesFormControls() {
        return (<FormArray>this.form.get('productAvailabilities')).controls;
    }

    onSubmit() {

        let startDate: moment.Moment = this.form.value.startDate;
        let endDate: moment.Moment = this.form.value.endDate;
        let suffix: string = this.form.value.suffix;

        let productAvailabilitiesData: SubmitCloneProductAvailabilityData[] = [];

        for (let control of (<FormArray>this.form.get('productAvailabilities')).controls) {

            let productAvailabilityId: number = +control.value.productAvailabilityId;
            let description: string;
            if ( suffix != null && suffix.trim() != ""){
                description = control.value.cloneDescription + suffix;
            }else{
                description = control.value.cloneDescription
            }

            productAvailabilitiesData.push({
                productAvailabilityId: productAvailabilityId,
                description: description
            });
        }

        this.submit.emit({
            productId: this.productId,
            startDate: startDate,
            endDate: endDate,
            productAvailabilitiesData: productAvailabilitiesData
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {

        let startDate: moment.Moment = null;
        let endDate: moment.Moment = null;
        let suffix: string = null;

        let productAvailabilitiesFormArray: FormArray = <FormArray>this.form.get('productAvailabilities');
        while (productAvailabilitiesFormArray.length > 0) {
            productAvailabilitiesFormArray.removeAt(0);
        }
        this.productAvailabilities.map((pa: ProductAvailabilityResponseModel) => {
            productAvailabilitiesFormArray.push(new FormGroup({
                productAvailabilityId: new FormControl(pa.productAvailabilityId),
                originalDescription: new FormControl(pa.description),
                cloneDescription: new FormControl(pa.description)
            }));
        });

        this.form.patchValue({
            startDate: startDate,
            endDate: endDate,
            suffix: suffix
        });
    }
}
