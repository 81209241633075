<div class="component-input-wrapper component-input-image-upload flex-container align-middle">
    <input value="{{file? file.name : ''}}" (click)="onUploadClick()" type="text" placeholder="Upload Image" readonly>

    <label class="image-upload-icon ml-8px mr-8px" for="file-input-{{guid}}">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#40B1D1"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1429 10.5714C17.1429 10.8438 17.0915 11.1049 17 11.3438C18.3036 11.6071 19.2857 12.7612 19.2857 14.1429C19.2857 15.721 18.0067 17 16.4286 17H8.21429C6.43973 17 5 15.5603 5 13.7857C5 12.3839 5.89732 11.192 7.14732 10.7522L7.14732 10.7522C7.14509 10.692 7.14286 10.6317 7.14286 10.5714C7.14286 8.59821 8.74107 7 10.7143 7C12.0379 7 13.192 7.71875 13.8103 8.79018C14.1496 8.5625 14.5603 8.42857 15 8.42857C16.183 8.42857 17.1429 9.38839 17.1429 10.5714ZM12.3214 12.7143H13.7812C14.0982 12.7143 14.2589 12.3304 14.0335 12.1049L11.6808 9.75223C11.5424 9.61384 11.3147 9.61384 11.1763 9.75223L8.82366 12.1049C8.59821 12.3304 8.7567 12.7143 9.07589 12.7143H10.5357V15.2143C10.5357 15.4107 10.6964 15.5714 10.8929 15.5714H11.9643C12.1607 15.5714 12.3214 15.4107 12.3214 15.2143V12.7143Z" fill="white"/>
        </svg>
    </label>

    <input (change)="onFileSelected($event)" type="file" id="file-input-{{guid}}" class="remove" #fileInput accept="image/*">
</div>
