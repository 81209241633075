
import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {Store} from "@ngrx/store";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {HolidayResponseModel} from "../../../../../../../../core/modules/rest/holiday/response/holiday-response.model";
import {HolidayRestService} from "../../../../../../../../core/modules/rest/holiday/holiday-rest.service";
import {CreateHolidayRequestModel} from "../../../../../../../../core/modules/rest/holiday/request/create-holiday-request.model";
import {UpdateHolidayRequestModel} from "../../../../../../../../core/modules/rest/holiday/request/update-holiday-request.model";


@Injectable()
export class EditHolidayDialogSandbox extends DialogSandbox {

    holiday$: Observable<HolidayResponseModel> = this.store.select(reducer.holiday_selector);

    constructor(appDialogsService: AppDialogsService,
                private holidayRestService: HolidayRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadHoliday(holidayId: number) {

        this.showLoader();

        this.holidayRestService.getById(holidayId).subscribe((holiday: HolidayResponseModel) => {
            this.store.dispatch(new actions.SetHoliday(holiday));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    createHoliday(description: string, selectedYear: number, selectedMonth: number, selectedDay: number): Observable<CreateBaseResponseModel> {
        const request = new CreateHolidayRequestModel(description, selectedYear, selectedMonth, selectedDay);

        return this.holidayRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating holiday', error);
            }));
    }

    updateHoliday(holidayId: number, description: string, selectedYear: number, selectedMonth: number, selectedDay: number): Observable<any> {
        const request = new UpdateHolidayRequestModel(holidayId, description, selectedYear, selectedMonth, selectedDay);

        return this.holidayRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating holiday', error);
            }));
    }
}
