import {Injectable, Injector} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {RenderDialogModel} from "../../shared/dialogs/model/render-dialog.model";
import {DialogData} from "../../shared/dialogs/model/dialog.data";

@Injectable()
export class AppDialogsService {

    private dialogLoaderVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private activeDialog: BehaviorSubject<RenderDialogModel> = new BehaviorSubject(null);

    dialogLoaderVisible$: Observable<boolean> = this.dialogLoaderVisible.asObservable();
    activeDialog$: Observable<RenderDialogModel> = this.activeDialog.asObservable();

    private renderDialogModels: RenderDialogModel[] = [];

    constructor(private injector: Injector) {}

    renderDialog(componentToRender: any, data?: DialogData) {

        const injector: Injector = Injector.create({
            providers: [{provide: DialogData, useValue: data}],
            parent: this.injector
        });

        // Create new dialog
        const renderDialogModel: RenderDialogModel = new RenderDialogModel(componentToRender, injector);

        // Push to stack
        this.renderDialogModels.push(renderDialogModel);

        // Set active dialog
        this.activeDialog.next(renderDialogModel);
    }

    destroyActiveDialog() {

        // If there are more dialogs on stack, set active dialog to next one from stack
        setTimeout(() => {

            this.renderDialogModels.pop();

            if (this.renderDialogModels.length !== 0) {
                this.activeDialog.next(this.renderDialogModels[this.renderDialogModels.length - 1]);
            } else {
                this.activeDialog.next(null);
            }
        });
    }

    showDialogLoader() {
        this.dialogLoaderVisible.next(true);
    }

    hideDialogLoader() {
        this.dialogLoaderVisible.next(false);
    }
}
