import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

export interface SubmitClonePassData {
    passId: number;
    description: string;
}

@Component({
    selector: 'app-clone-pass-dialog-form',
    templateUrl: './clone-pass-dialog-form.component.html'
})

export class ClonePassDialogFormComponent {

    @Input() passId: number;

    @Output() submit: EventEmitter<SubmitClonePassData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        this.form = new FormGroup({
            description: new FormControl()
        });
    }

    onSubmit() {

        let description: string = this.form.value.description;

        if (!description) {
            this.hasError = true;
            this.errorMessage = "Please enter multipass name";
            return;
        }

        this.submit.emit({
            passId: this.passId,
            description: description
        });
    }

    onCancel() {
        this.cancel.emit();
    }
}
