import * as actions from './actions';

import {KioskDeviceInfoResponseModel} from "../../../../../../../../../core/modules/rest/kiosk-device-info/response/kiosk-device-info-response.model";
import {KioskConfigurationResponseModel} from "../../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-response.model";
import {UserResponseModel} from "../../../../../../../../../core/modules/rest/user/response/user-response.model";
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {createFeatureSelector, createSelector} from "@ngrx/store";

export const REDUCER_NAME = "dialog_create/edit-kiosk-device-info";

export interface State {
    kioskDeviceInfo: KioskDeviceInfoResponseModel;
    kioskConfigurations: KioskConfigurationResponseModel[];
    users: UserResponseModel[];
    termsAndConditionsTemplates: EmailHtmlTemplateResponseModel[];
}

export const initialState: State = {
    kioskDeviceInfo: {} as KioskDeviceInfoResponseModel,
    kioskConfigurations: [],
    users: [],
    termsAndConditionsTemplates: []
};


// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const kioskDeviceInfo_selector = createSelector(state_selector, (state: State) => state.kioskDeviceInfo);
export const kioskConfigurations_selector = createSelector(state_selector, (state: State) => state.kioskConfigurations);
export const users_selector = createSelector(state_selector, (state: State) => state.users);
export const termsAndConditionsTemplates_selector = createSelector(state_selector, (state: State) => state.termsAndConditionsTemplates);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_KIOSK_DEVICE_INFO: return { ... state, kioskDeviceInfo: action.kioskDeviceInfo };

        case actions.SET_KIOSK_CONFIGURATIONS: return { ... state, kioskConfigurations: action.kioskConfigurations };

        case actions.SET_USERS: return { ... state, users: action.users };

        case actions.SET_TERMS_AND_CONDITIONS_HTML_TEMPLATES: return { ... state, termsAndConditionsTemplates: action.termsAndConditionsTemplates };

        default: return state;
    }
}
