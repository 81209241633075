import {ErrorCodeEnum} from '../../../shared/enums/error-code.enum';

const messagesMap: Map<ErrorCodeEnum, string> = new Map();

messagesMap.set(ErrorCodeEnum.FRONT_UNKNOWN_ERROR, 'Front unknown error');
messagesMap.set(ErrorCodeEnum.FRONT_CONNECTION_LOST_ERROR, 'Unable to reach server');

messagesMap.set(ErrorCodeEnum.SMARTSTUBS_INTERNAL_SERVER_ERROR, 'Internal server error');
messagesMap.set(ErrorCodeEnum.SMARTSTUBS_UNEXPECTED_SERVER_ERROR, 'Unexpected server error');

messagesMap.set(ErrorCodeEnum.SMARTSTUBS_UNAUTHORIZED, 'Unauthorized');
messagesMap.set(ErrorCodeEnum.SMARTSTUBS_INVALID_CREDENTIALS, 'Invalid credentials');
messagesMap.set(ErrorCodeEnum.SMARTSTUBS_USER_IS_LOCKED, 'User is locked');
messagesMap.set(ErrorCodeEnum.SMARTSTUBS_OTP_NOT_VALID, 'MFA code not valid');
messagesMap.set(ErrorCodeEnum.SMARTSTUBS_LOGIN_LOCATION_NOT_PROVIDED, 'Login location not provided');
messagesMap.set(ErrorCodeEnum.SMARTSTUBS_ACCESS_TOKEN_EXPIRED, 'Access token expired');
messagesMap.set(ErrorCodeEnum.SMARTSTUBS_REFRESH_TOKEN_EXPIRED, 'Refresh token expired');

messagesMap.set(ErrorCodeEnum.SMARTSTUBS_INVALID_EMAIL_ADDRESS, 'Email address not found');
messagesMap.set(ErrorCodeEnum.SMARTSTUBS_RESET_PASSWORD_ERROR, 'Reset password failed');
messagesMap.set(ErrorCodeEnum.SMARTSTUBS_PASSWORD_COMPROMISED, 'Password is compromised');
messagesMap.set(ErrorCodeEnum.SMARTSTUBS_ACTIVATE_MFA_ERROR, 'MFA activation failed');

messagesMap.set(ErrorCodeEnum.SMARTSTUBS_ENTITY_ITEM_NOT_FOUND, 'Entity not found');

messagesMap.set(ErrorCodeEnum.SMARTSTUBS_NON_EXISTING_TIER, 'Trying to find non-existing tier');
messagesMap.set(ErrorCodeEnum.SMARTSTUBS_NON_EXISTING_KIOSK_DEVICE_INFO, 'Trying to find non-existing kiosk device info');



export function getErrorMessageForCode(code: ErrorCodeEnum) {

    if (!messagesMap.has(code)) {
        return "ERROR_MESSAGE_NOT_DEFINED";
    }

    return messagesMap.get(code);
}
