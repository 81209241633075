import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {AddProductDialogSandbox} from "./sandbox/add-product-dialog.sandbox";
import {SubmitProductData} from "./components/add-product-dialog-form/add-product-dialog-form.component";
import {CreateBaseResponseModel} from "../../../../../../../core/modules/rest/create-base-response.model";
import {Observable} from "rxjs";
import {DropdownOptionModel} from "../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export class AddProductDialogData extends DialogData {

    categoryId: number;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, categoryId: number) {
        super();
        this.categoryId = categoryId;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-add-product-dialog',
    templateUrl: './add-product-dialog.component.html'
})
export class AddProductDialogComponent implements OnDestroy {

    categoryId: number;
    successCallbackFn: Function;

    categories$: Observable<DropdownOptionModel[]>;
    pickupDropoffLocationLists$: Observable<DropdownOptionModel[]>;
    pickupLocationLists$: Observable<DropdownOptionModel[]>;
    dropoffLocationLists$: Observable<DropdownOptionModel[]>;

    constructor(@Inject(DialogData) data: AddProductDialogData, private sandbox: AddProductDialogSandbox) {

        this.categoryId = data.categoryId;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData();

        this.categories$ = this.sandbox.categories$;
        this.pickupDropoffLocationLists$ = this.sandbox.pickupDropoffLocationLists$;
        this.pickupLocationLists$ = this.sandbox.pickupLocationLists$;
        this.dropoffLocationLists$ = this.sandbox.dropoffLocationLists$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitProductData) {
        this.sandbox.createProduct(data).subscribe((response: CreateBaseResponseModel) => {
            this.successCallbackFn(response.id);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
