import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { RoleResponseModel } from "../../../../../../../../../core/modules/rest/role/response/role-response.model";
import { DropdownOptionModel } from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import { TreeItemTypeEnum } from "../../../../model/tree-item-type.enum";
import { FormControl, FormGroup } from "@angular/forms";
import { UserResponseModel } from "../../../../../../../../../core/modules/rest/user/response/user-response.model";
import { EmailAddressUtility } from "../../../../../../../../../shared/utils/email-address-utility";
import { UserRestService } from "../../../../../../../../../core/modules/rest/user/user-rest.service";
import { LocationListRestService } from '../../../../../../../../../core/modules/rest/location-list/location-list-rest.service';

export interface SubmitRoleData {
    roleId: number;
    description: string;
    useComplexPassword: boolean;
    useMfa: boolean;
    useLoginLocations: boolean;
}

export interface SubmitUserData {
    userId: number;
    roleId: number;
    displayName: string;
    username: string;
    password: string;
    email: string;
    isLocked: boolean;
}

export type SubmitData = SubmitUserData | SubmitRoleData;


@Component({
    selector: 'app-edit-role-or-user-dialog-form',
    templateUrl: './edit-role-or-user-dialog-form.component.html'
})

export class EditRoleOrUserDialogFormComponent implements OnChanges {

    @Input() editMode: boolean;
    @Input() type: TreeItemTypeEnum;
    @Input() user: UserResponseModel;
    @Input() role: RoleResponseModel;
    @Input() roles: RoleResponseModel[];
    @Input() useLoginLocations: boolean;

    @Output() submit: EventEmitter<SubmitData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    readonly USER: TreeItemTypeEnum = TreeItemTypeEnum.USER;
    readonly ADD_NEW_USER: TreeItemTypeEnum = TreeItemTypeEnum.ADD_NEW_USER;

    readonly ROLE: TreeItemTypeEnum = TreeItemTypeEnum.ROLE;
    readonly ADD_NEW_ROLE: TreeItemTypeEnum = TreeItemTypeEnum.ADD_NEW_ROLE;

    form: FormGroup;

    roleOptions: DropdownOptionModel[];

    hasError: boolean = false;
    errorMessage: string = "";

    constructor(private userRestService: UserRestService, private locationListRestService: LocationListRestService) {

        this.form = new FormGroup({

            // ROLE DATA
            description: new FormControl(),
            useComplexPassword: new FormControl(),
            useMfa: new FormControl(),
            useLoginLocations: new FormControl(),

            // USER DATA
            roleId: new FormControl(),
            displayName: new FormControl(),
            username: new FormControl(),
            password: new FormControl(),
            passwordConfirm: new FormControl(),
            email: new FormControl(),
            isLocked: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.roles) {
            this.roleOptions = this.roles.map(r => new DropdownOptionModel(r.description, r.roleId.toString()));
        }

        if (changes.role || changes.user) {

            this.initializeFormData();
        }
    }

    getHeader(): string {

        switch (this.type) {
            case TreeItemTypeEnum.ADD_NEW_ROLE:
                return "Create New Role";
            case TreeItemTypeEnum.ADD_NEW_USER:
                return "Create New User";
            case TreeItemTypeEnum.ROLE:
                return "Edit Role Basic Info";
            case TreeItemTypeEnum.USER:
                return "Edit User Basic Info";
        }
    }

    onSubmit() {

        if (this.type === TreeItemTypeEnum.ROLE || this.type === TreeItemTypeEnum.ADD_NEW_ROLE) {

            let description: string = this.form.value.description;
            let useComplexPassword: boolean = this.form.value.useComplexPassword;
            let useMfa: boolean = this.form.value.useMfa;
            let useLoginLocations: boolean = this.form.value.useLoginLocations;

            if (!description) {
                this.hasError = true;
                this.errorMessage = "Please enter description";
            }

            this.submit.emit({
                roleId: this.editMode ? this.role.roleId : null,
                description: description,
                useComplexPassword: useComplexPassword,
                useMfa: useMfa,
                useLoginLocations: useLoginLocations
            });
        }

        if (this.type === TreeItemTypeEnum.USER || this.type === TreeItemTypeEnum.ADD_NEW_USER) {

            let roleId: number = Number(this.form.value.roleId);
            let selectedRole: RoleResponseModel = this.roles.find((r) => r.roleId === roleId);
            let displayName: string = this.form.value.displayName.trim();
            let username: string = this.form.value.username.trim();
            let password: string = this.form.value.password.trim();
            let passwordConfirm: string = this.form.value.passwordConfirm.trim();
            let email: string = this.form.value.email.trim();
            let isLocked: boolean = this.form.value.isLocked;

            if (!roleId || isNaN(roleId) || roleId <= 0) {
                this.hasError = true;
                this.errorMessage = "Please select role";
                return;
            }

            if (!displayName) {
                this.hasError = true;
                this.errorMessage = "Please enter display name";
                return;
            }

            if (!username) {
                this.hasError = true;
                this.errorMessage = "Please enter username";
                return;
            }

            if ((!this.editMode || password.length > 0) && !password) {
                this.hasError = true;
                this.errorMessage = "Please enter password";
                return;
            }

            if (selectedRole.useComplexPassword && (!this.editMode || password.length > 0 ) && this.type === TreeItemTypeEnum.USER && password.length < 8) {
                this.hasError = true;
                this.errorMessage = "Password has to be at least 8 characters long";
                return;
            }

            if ((!this.editMode || passwordConfirm.length > 0) && !passwordConfirm) {
                this.hasError = true;
                this.errorMessage = "Please confirm password";
                return;
            }

            if (password !== passwordConfirm) {
                this.hasError = true;
                this.errorMessage = "Passwords don't match";
                return;
            }

            // Email is not mandatory field, but if it is provided, it has to be in valid format
            if (email !== null && email.length !== 0 && !EmailAddressUtility.EMAIL_REGEXP.test(email)) {
                this.hasError = true;
                this.errorMessage = "Invalid email address";
                return;
            }

            this.hasError = false;
            this.errorMessage = null;

            this.submit.emit({
                userId: this.editMode ? this.user.userId : null,
                roleId: roleId,
                displayName: displayName,
                username: username,
                password: password,
                email: email,
                isLocked: isLocked
            });
        }
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {

        if (this.type === TreeItemTypeEnum.ROLE || this.type === TreeItemTypeEnum.ADD_NEW_ROLE) {
            this.form.patchValue({
                description: this.role.description,
                useComplexPassword: this.role.useComplexPassword,
                useMfa: this.role.useMfa,
                useLoginLocations: this.role.useLoginLocations
            });
        } else {
            this.form.patchValue({
                roleId: this.user.roleId ? this.user.roleId.toString() : null,
                displayName: this.user.displayName,
                username: this.user.username,
                password: "",
                passwordConfirm: "",
                email: this.user.email,
                isLocked: this.user.isLocked
            });
        }
    }
}
