import {AutoReportExportTypeEnum} from "../../../../../../../../shared/enums/auto-report-export-type.enum";
import * as moment from "moment";

export class AutoReportFormSubmitDataModel {

    favoriteId: number;
    description: string;
    email: string;
    nth: number;
    dayOfWeek: number;
    hour: number;
    startDate: moment.Moment;
    endDate: moment.Moment;
    exportType: AutoReportExportTypeEnum;

    constructor(favoriteId: number, description: string, email: string, nth: number, dayOfWeek: number, hour: number, startDate: moment.Moment, endDate: moment.Moment, exportType: AutoReportExportTypeEnum) {
        this.favoriteId = favoriteId;
        this.description = description;
        this.email = email;
        this.nth = nth;
        this.dayOfWeek = dayOfWeek;
        this.hour = hour;
        this.startDate = startDate;
        this.endDate = endDate;
        this.exportType = exportType;
    }
}
