import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MappingCommissionDialogComponent} from "./container/mapping-commission-dialog.component";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {MappingCommissionDialogFormComponent} from './components/mapping-commission-dialog-form/mapping-commission-dialog-form.component';
import {MappingCommissionDialogSandbox} from "./sandbox/mapping-commission-dialog.sandbox";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        MappingCommissionDialogSandbox
    ],
    exports: [
        MappingCommissionDialogComponent
    ],
    declarations: [
        MappingCommissionDialogComponent,
        MappingCommissionDialogFormComponent
    ],
    entryComponents: [
        MappingCommissionDialogComponent
    ]
})
export class MappingCommissionDialogModule {
}
