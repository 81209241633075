import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {OrderSummaryResponseModel} from "../../../../../../core/modules/rest/order-summary/response/order-summary-response.model";
import {OrderStatusEnum} from "../../../../../enums/order-status.enum";
import * as moment from "moment";
import {UserInfoResponseModel} from "../../../../../../core/modules/rest/user/response/user-info-response.model";
import { AppRoutesService } from '../../../../../../core/services/app-routes.service';
import { AppDialogsService } from '../../../../../../core/services/app-dialogs.service';

@Component({
    selector: 'app-action-buttons-dialog-summary',
    templateUrl: './action-buttons-dialog-summary.component.html'
})
export class ActionButtonsDialogSummaryComponent implements OnChanges {

    @Input() user: UserInfoResponseModel;
    @Input() orderSummary: OrderSummaryResponseModel;

    @Input() canPrintOnce: boolean;
    @Input() canPrintTwice: boolean;
    @Input() canPrintMultipleTimes: boolean;
    @Input() canEmailOrder: boolean;
    @Input() canVoidOrder: boolean;
    @Input() canVoidCompletedOrder: boolean;
    @Input() canVoidOwnOrderInLast24Hours: boolean;
    @Input() canVoidExpiredOrder: boolean;
    @Input() canPrintOrderSummary: boolean;
    @Input() canCloneOrder: boolean;
    @Input() canEditOrder: boolean;

    @Output() printOrderClick: EventEmitter<void> = new EventEmitter();
    @Output() emailOrderClick: EventEmitter<void> = new EventEmitter();
    @Output() printSummaryClick: EventEmitter<void> = new EventEmitter();
    @Output() voidOrderClick: EventEmitter<void> = new EventEmitter();
    @Output() cloneOrderClick: EventEmitter<void> = new EventEmitter();
    @Output() reloadOrderSummary: EventEmitter<void> = new EventEmitter();

    printEnabled: boolean = false;
    appRoutesService: AppRoutesService;
    editOrderHover: string;

    constructor(
        appRoutesService: AppRoutesService,
        private appDialogsService: AppDialogsService,
        private cdr: ChangeDetectorRef
    ) {
        this.appRoutesService = appRoutesService;
        this.appDialogsService = appDialogsService;
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.orderSummary) {
            this.printEnabled = this.isPrintOrderEnabled();
            this.cdr.detectChanges();
        }
    }

    isPrintOrderEnabled(): boolean {

        if (!this.orderSummary) {
            return false;
        }

        let count: number = this.orderSummary.printingCount;

        if (this.canPrintMultipleTimes) {
            return true;
        }

        if (this.canPrintTwice && count < 2) {
            return true;
        }

        // noinspection RedundantIfStatementJS
        if (this.canPrintOnce && count < 1) {
            return true;
        }

        return false;
    }

    onPrintOrderClick(): void {
        if (this.printEnabled) {
            this.printOrderClick.emit();
        }
    }

    isSendEmailEnabled(): boolean {
        return this.canEmailOrder;
    }

    onEmailOrderClick(): void {
        if (this.canEmailOrder) {
            this.emailOrderClick.emit();
        }
    }

    onPrintSummaryClick(): void {
        if (this.canPrintOrderSummary) {
            this.printSummaryClick.emit();
        }
    }

    isVoidOrderEnabled(): boolean {
        if (this.orderSummary.createDateTimeDescriptor) {
            let result: boolean;

            // Calculate isOrderCreatedInLast24Hours (do not get daylight saving into calculation)
            const now: moment.Moment = moment();

            const orderCreateDateTimeDateObject = new Date(
                this.orderSummary.createDateTimeDescriptor.year,
                this.orderSummary.createDateTimeDescriptor.month - 1,
                this.orderSummary.createDateTimeDescriptor.day,
                this.orderSummary.createDateTimeDescriptor.hour,
                this.orderSummary.createDateTimeDescriptor.minute,
                this.orderSummary.createDateTimeDescriptor.second,
                this.orderSummary.createDateTimeDescriptor.millisecond);

            const orderCreateDateTimePlus24Hours = moment(orderCreateDateTimeDateObject).add(1, 'day');

            let isOrderCreatedInLast24Hours: boolean = (now.isBefore(orderCreateDateTimePlus24Hours) || now.isSame(orderCreateDateTimePlus24Hours));

            //Check if current user has created this order, in last 24 hours
            let orderCreatedByCurrentUser = this.orderSummary.createdById === this.user.userId;

            if ((this.canVoidOrder && this.orderSummary.orderStatus === OrderStatusEnum.NEW || ( this.canVoidOrder && this.orderSummary.orderStatus === OrderStatusEnum.PARTIALLY_COMPLETE ) || (this.canVoidCompletedOrder && this.orderSummary.orderStatus === OrderStatusEnum.COMPLETE))) {
                if (orderCreatedByCurrentUser && isOrderCreatedInLast24Hours && this.canVoidOwnOrderInLast24Hours) {
                    result = true;
                }
                if (orderCreatedByCurrentUser && !isOrderCreatedInLast24Hours) {
                    result = true;
                }
                if (!orderCreatedByCurrentUser) {
                    result = true;
                }
            }
    
            else if (this.canVoidOwnOrderInLast24Hours && this.orderSummary.orderStatus === OrderStatusEnum.NEW && orderCreatedByCurrentUser && isOrderCreatedInLast24Hours) {
                result = true;
            }

            else if (this.canVoidOwnOrderInLast24Hours && this.orderSummary.orderStatus === OrderStatusEnum.PARTIALLY_COMPLETE && orderCreatedByCurrentUser && isOrderCreatedInLast24Hours) {
                result = true;
            }

            else if (this.orderSummary.orderStatus === OrderStatusEnum.EXPIRED && this.canVoidExpiredOrder) {
                result = true;
            }

            else {
                result = false;
            }

            return result;
        }

    }

    isPrintOrderSummaryEnabled(): boolean {
        return this.canPrintOrderSummary;
    }

    onVoidOrderClick(): void {
        if (this.isVoidOrderEnabled()) {
            this.voidOrderClick.emit();
        }
    }

    async onCloneOrderClick(): Promise<void> {
        if (this.canCloneOrder) {
            var orderExternalId = this.orderSummary.orderExternalId;
            this.appDialogsService.destroyActiveDialog();
            await new Promise(f => setTimeout(f, 100));
            this.appRoutesService.goToOrderFormPage(orderExternalId, 'copy');
        }
    }

    onReloadOrderClick(): void {
        this.reloadOrderSummary.emit();
    }

    async onEditOrderClick(): Promise<void> {
        if ( this.isEditOrderEnabled() && this.canEditOrder && this.orderSummary.orderEditable) {
            var orderExternalId = this.orderSummary.orderExternalId;
            this.appDialogsService.destroyActiveDialog();
            await new Promise(f => setTimeout(f, 100));
            this.appRoutesService.goToOrderFormPage(orderExternalId, 'edit');
        }
    }

    isCloneOrderEnabled(): boolean {
        return this.canCloneOrder;
    }

    isEditOrderEnabled(): boolean {

        if( this.orderSummary.orderStatus === OrderStatusEnum.NEW || this.orderSummary.orderStatus === OrderStatusEnum.PENDING || this.orderSummary.orderStatus === OrderStatusEnum.PARTIALLY_COMPLETE ) {
            let isEditOrderEnabled = this.canEditOrder && this.orderSummary.orderEditable;
        
            this.editOrderHover = isEditOrderEnabled ? '': 'Order details already used so it cant be edited';
            return isEditOrderEnabled;
        }
        
        return false;
        
    }

}
