<div class="dragNdropCard draggableItem expressionGroup"
     [ngClass]="{'dragItem, dragItemPlaceholder': isDragged}"
     draggable="true"
     [dragEnabled]="hasRunOrderReportFullPermission"
     [dragData]="getDragData()"
     (onDragStart)="onGroupDragStart()"
     (onDragEnd)="onGroupDragEnd()"
     [dragImage]=" !isIE ? dragImage : null"
     [dragTransitClass]="isIE ? 'is-ie-drag' : null">

    <div class="dragNdropCardHeader">
        <div class="icon_container"
             [ngClass]="{'disabled':!hasRunOrderReportFullPermission}">
            <img src="{{draggableIconDefault}}">
        </div>

        <app-button *ngIf="itemDepth < maxDepth"
                    [buttonType]="'secondary small'"
                    [disabled]="!hasRunOrderReportFullPermission"
                    (clickEvent)="onAddGroupClick()">
            + Add Group
        </app-button>

        <a class="closeButton_container"
           [ngClass]="{'disabled':!hasRunOrderReportFullPermission}"
           (click)="deleteThisItem()">
            <span class="closeButton">
               <i class="fal fa-times"></i>
            </span>
        </a>
    </div>



    <ul class="groupContent">
        <app-order-reports-filter-drop-area
            [enabled]="dropAreasVisible && !isFirstGroupExpressionItemCurrentlyInDrag()"
            [visible]="dropAreasVisible && expressionGroupItem.items.length === 0"
            [itemDepth]="itemDepth"
            [draggedItemHeight]="draggedItemHeight"
            [parentGroupUUID]="expressionGroupItem.uuid"
            [leftSiblingUUID]="null"
            (deleteExpressionItemEvent)="onDeleteExpressionItem($event)"
            (droppedDataEvent)="onDroppedData($event)"
            (dragEndEvent)="onDragEnd()">
        </app-order-reports-filter-drop-area>


        <li class="expressionItem"
            *ngFor="let expressionItem of expressionGroupItem.items; trackBy: trackByExpressionItems; let i = index">
            <app-order-reports-expression-item
                [itemDepth]="itemDepth"
                [draggedItemHeight]="draggedItemHeight"
                [expressionItem]="expressionItem"
                [leftExpressionItem]="i > 0 ? expressionGroupItem.items[i-1] : null"
                [rightExpressionItem]="i < expressionGroupItem.items.length - 1 ? expressionGroupItem.items[i+1] : null"
                [draggedData]="draggedData"
                [dropAreasVisible]="dropAreasVisible"
                [parentGroupUUID]="expressionGroupItem.uuid"
                [leftSiblingUUID]="i === 0 ? null : expressionGroupItem.items[i-1].uuid"
                [hasRunOrderReportFullPermission]="hasRunOrderReportFullPermission "
                (deleteExpressionItemEvent)="onDeleteExpressionItem($event)"
                (droppedDataEvent)="onDroppedData($event)"
                (dragStartEvent)="onDragStart($event)"
                (dragEndEvent)="onDragEnd()"
                (expressionItemValueChangedEvent)="onExpressionItemValueChanged($event)">
            </app-order-reports-expression-item>
        </li>

        <ng-container
            *ngIf="expressionGroupItem.items.length !== 0">
            <app-order-reports-filter-drop-area
                [enabled]="dropAreasVisible && expressionGroupItem.items.length !== 0 && !isLastGroupExpressionItemCurrentlyInDrag()"
                [itemDepth]="itemDepth"
                [draggedItemHeight]="draggedItemHeight"
                [parentGroupUUID]="expressionGroupItem.uuid"
                [leftSiblingUUID]="expressionGroupItem.items[expressionGroupItem.items.length - 1].uuid"
                (deleteExpressionItemEvent)="onDeleteExpressionItem($event)"
                (droppedDataEvent)="onDroppedData($event)"
                (dragEndEvent)="onDragEnd()">
            </app-order-reports-filter-drop-area>
        </ng-container>
    </ul>
</div>
