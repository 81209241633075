
import {of as observableOf, combineLatest as observableCombineLatest, forkJoin as observableForkJoin, Observable} from 'rxjs';

import {mergeMap, take, map, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {RootSandbox} from '../../../../../../core/store/root.sandbox';
import {KioskConfigurationRestService} from '../../../../../../core/modules/rest/kiosk-configuration/kiosk-configuration-rest.service';
import {HttpErrorModel} from "../../../../../../core/modules/rest/http-error.model";
import {KioskConfigurationValidatedResponseModel} from "../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-validated-response.model";
import {KioskDeviceInfoRestService} from "../../../../../../core/modules/rest/kiosk-device-info/kiosk-device-info-rest.service";
import {KioskDeviceInfoResponseModel} from "../../../../../../core/modules/rest/kiosk-device-info/response/kiosk-device-info-response.model";
import {KioskConfigurationDropoffLocationValidatedResponseModel} from "../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-dropoff-location-validated-response.model";
import {LocationResponseModel} from "../../../../../../core/modules/rest/location/response/location-response.model";
import {UpdateKioskDeviceInfoConfigurationRequestModel} from "../../../../../../core/modules/rest/kiosk-device-info/request/update-kiosk-device-info-configuration-request.model";
import {PassSimpleResponseModel} from "../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {CreateKioskConfigurationDropoffLocationRequestModel} from "../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-dropoff-location-request.model";
import {CreateBaseResponseModel} from "../../../../../../core/modules/rest/create-base-response.model";

import * as fromFeature from './reducers';
import * as fromFeatureMainActions from './main/actions';
import * as fromFeatureUiActions from './ui/actions';

import _ from 'lodash';
import {UpdateKioskConfigurationDropoffLocationSortOrderRequestModel} from "../../../../../../core/modules/rest/kiosk-configuration/request/update-kiosk-configuration-dropoff-location-sort-order-request.model";
import {KioskConfigurationDropoffLocationProductModel} from "../dialogs/route-setup-dialog/model/kiosk-configuration-dropoff-location-product.model";
import {CreateKioskConfigurationDropoffLocationProductsRequestModel} from "../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-dropoff-location-products-request.model";
import {CreateKioskConfigurationDropoffLocationProductRequestModel} from "../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-dropoff-location-product-request.model";
import {KioskConfigurationDropoffLocationProductTierResponseModel} from "../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-dropoff-location-product-tier-response.model";
import {TierResponseModel} from "../../../../../../core/modules/rest/tier/response/tier-response.model";
import {CreateKioskConfigurationDropoffLocationProductTiersRequestModel} from "../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-dropoff-location-product-tiers-request.model";
import {KioskConfigurationDropoffLocationProductTierModel} from "../dialogs/product-tier-setup-dialog/model/kiosk-configuration-dropoff-location-product-tier.model";
import {CreateKioskConfigurationDropoffLocationProductTierRequestModel} from "../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-dropoff-location-product-tier-request.model";
import {KioskConfigurationDropoffLocationPassTierResponseModel} from "../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-dropoff-location-pass-tier-response.model";
import {KioskConfigurationDropoffLocationPassTierModel} from "../dialogs/pass-tier-setup-dialog/model/kiosk-configuration-dropoff-location-pass-tier.model";
import {CreateKioskConfigurationDropoffLocationPassTiersRequestModel} from "../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-dropoff-location-pass-tiers-request.model";
import {CreateKioskConfigurationDropoffLocationPassTierRequestModel} from "../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-dropoff-location-pass-tier-request.model";
import {ProductRestService} from "../../../../../../core/modules/rest/product/product-rest.service";
import {ProductForRouteSetupResponseModel} from "../../../../../../core/modules/rest/product/response/product-for-route-setup-response.model";
import {KioskDeviceInfoSimpleModel} from "../model/kiosk-device-info-simple.model";
import {LocationListRestService} from "../../../../../../core/modules/rest/location-list/location-list-rest.service";
import {LocationListItemDescriptorResponseModel} from "../../../../../../core/modules/rest/location-list/response/location-list-item-descriptor-response.model";
import {KioskConfigurationDropoffLocationProductWithLocationsResponseModel} from "../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-dropoff-location-product-with-locations-response.model";
import {KioskConfigurationModel} from "../model/kiosk-configuration.model";
import {KioskConfigurationDropoffLocationValidatedModel} from "../model/kiosk-configuration-dropoff-location-validated.model";
import {KioskConfigurationWarningModel} from "../model/kiosk-configuration-warning.model";
import {KioskConfigurationWarningResponseModel} from "../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-warning-response.model";

@Injectable()
export class KioskConfigurationDetailsSandbox {

    constructor(private store: Store<fromFeature.State>, private rootSandbox: RootSandbox,
                private kioskConfigurationRestService: KioskConfigurationRestService, private kioskDeviceInfoRestService: KioskDeviceInfoRestService,
                private productRestService: ProductRestService, private locationListRestService: LocationListRestService) {
    }

    loadPageData(kioskConfigurationId: number) {
        // Load kiosk configuration by id
        const getByIdRequest = this.kioskConfigurationRestService.getValidatedById(kioskConfigurationId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('error while loading kiosk configuration by id', error);
            }));

        // Load kiosks by kioskConfigurationId
        const getKioskDeviceInfosByKioskConfigurationIdRequest = this.kioskDeviceInfoRestService.getKioskDeviceInfosByKioskConfigurationId(kioskConfigurationId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('error while loading kiosks by kioskConfigurationId', error);
            }),
            map((kiosks: KioskDeviceInfoResponseModel[]) => {
                return kiosks.map((kiosk) => new KioskDeviceInfoSimpleModel(kiosk.kioskDeviceInfoId, kiosk.description, true, kiosk.active));
            }),);

        // Load kiosks without kiosk configuration
        const getKioskDeviceInfosWithoutKioskConfigurationRequest = this.kioskDeviceInfoRestService.getKioskDeviceInfosWithoutKioskConfiguration().pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('error while loading kiosks without kioskConfiguration', error);
            }),
            map((kiosks: KioskDeviceInfoResponseModel[]) => {
                return kiosks.map((kiosk) => new KioskDeviceInfoSimpleModel(kiosk.kioskDeviceInfoId, kiosk.description, false, kiosk.active));
            }),);

        // Load all locations
        const getAllLocationsRequest = this.rootSandbox.getAllLocations();

        // Load all kioskConfigurationDropoffLocationPasses
        const getAllPassesSimpleRequest = this.rootSandbox.getAllPassesSimple();


        observableForkJoin([getByIdRequest, getKioskDeviceInfosByKioskConfigurationIdRequest, getKioskDeviceInfosWithoutKioskConfigurationRequest,
            getAllLocationsRequest, getAllPassesSimpleRequest])
            .subscribe(results => {
                const kioskConfigurationValidated: KioskConfigurationValidatedResponseModel = results[0];
                const kiosksWithThisConfiguration: KioskDeviceInfoSimpleModel[] = results[1];
                const kiosksWithoutConfiguration: KioskDeviceInfoSimpleModel[] = results[2];
                const locations: LocationResponseModel[] = results[3];
                const passes: PassSimpleResponseModel[] = results[4];

                const kioskConfigurationDropoffLocationsValidatedResponse: KioskConfigurationDropoffLocationValidatedResponseModel[] = kioskConfigurationValidated.kioskConfigurationDropoffLocations;

                // Create KioskConfigurationDropoffLocationValidatedModel
                const kioskConfigurationDropoffLocationsValidated: KioskConfigurationDropoffLocationValidatedModel[] = kioskConfigurationDropoffLocationsValidatedResponse.map((data: KioskConfigurationDropoffLocationValidatedResponseModel) => {
                    return this.convertKioskConfigurationDropoffLocationValidatedResponseModelIntoModel(data);
                });

                // Create KioskConfigurationWarningModel
                const warnings: KioskConfigurationWarningModel[] = kioskConfigurationValidated.warnings.map((warning: KioskConfigurationWarningResponseModel) => {
                    return new KioskConfigurationWarningModel(warning.type, warning.additionalDescription);
                });

                const kioskConfiguration: KioskConfigurationModel = new KioskConfigurationModel(
                    kioskConfigurationValidated.kioskConfigurationId,
                    kioskConfigurationValidated.createdById,
                    kioskConfigurationValidated.lastUpdatedById,
                    kioskConfigurationValidated.active,
                    kioskConfigurationValidated.description,
                    kioskConfigurationValidated.pickupLocationId,
                    kioskConfigurationValidated.pickupLocationDescription,
                    kioskConfigurationValidated.valid,
                    warnings,
                );

                this.store.dispatch(new fromFeatureMainActions.UpdateKioskConfiguration(kioskConfiguration));
                this.store.dispatch(new fromFeatureMainActions.UpdateKioskConfigurationDropoffLocations(kioskConfigurationDropoffLocationsValidated));
                this.store.dispatch(new fromFeatureMainActions.UpdateKiosksWithThisConfiguration(kiosksWithThisConfiguration));
                this.store.dispatch(new fromFeatureMainActions.UpdateKiosksWithoutConfiguration(kiosksWithoutConfiguration));
                this.store.dispatch(new fromFeatureMainActions.UpdateLocations(locations));
                this.store.dispatch(new fromFeatureMainActions.UpdatePasses(passes));

                this.updatePageDataLoaded(true);
            });
    }

    updatePageDataLoaded(loaded: boolean) {
        this.store.dispatch(new fromFeatureUiActions.PageDataLoaded(loaded));
    }

    getPageDataLoaded(): Observable<boolean> {
        return this.store.select(fromFeature.uiState_pageDataLoaded_selector);
    }

    getKioskConfiguration(): Observable<KioskConfigurationModel> {
        return this.store.select(fromFeature.mainState_kioskConfiguration_selector);
    }

    getKioskConfigurationId(): Observable<number> {
        return this.getKioskConfiguration().pipe(
            map((kioskConfiguration: KioskConfigurationModel) => {
                return kioskConfiguration.kioskConfigurationId;
            }));
    }

    getKiosksWithThisAndAllWithoutConfiguration(): Observable<KioskDeviceInfoSimpleModel[]> {
        return this.store.select(fromFeature.mainState_kiosksWithThisAndAllWithoutConfiguration_selector);
    }

    getKioskConfigurationDropoffLocations(): Observable<KioskConfigurationDropoffLocationValidatedModel[]> {
        return this.store.select(fromFeature.mainState_kioskConfigurationDropoffLocations_selector);
    }

    getAllLocations(): Observable<LocationResponseModel[]> {
        return this.store.select(fromFeature.mainState_allLocations_selector);
    }

    getAllPossibleLocationsForCreateDropoffLocationDialog(): Observable<LocationResponseModel[]> {
        return observableCombineLatest(
            this.getAllLocations(),
            this.getKioskConfiguration(),
            this.getKioskConfigurationDropoffLocations()
        ).pipe(
            map(([allLocations, kioskConfiguration, dropoffLocations]) => {
                const allLocationsParameterized: LocationResponseModel[] = allLocations;
                const kioskConfigurationParameterized: KioskConfigurationModel = kioskConfiguration;
                const dropoffLocationsParameterized: KioskConfigurationDropoffLocationValidatedModel[] = dropoffLocations;

                // Filter-out all location from which dropoff location is already created
                return allLocationsParameterized.filter((location: LocationResponseModel) => {
                    const alreadyDropoffLocation = _.some(dropoffLocationsParameterized, (dropoffLocation: KioskConfigurationDropoffLocationValidatedModel) => dropoffLocation.dropoffLocationId === location.locationId);
                    const usedAsPickupLocation = location.locationId === kioskConfigurationParameterized.pickupLocationId;

                    return !alreadyDropoffLocation && !usedAsPickupLocation;
                });
            }));
    }

    getAllPasses(): Observable<PassSimpleResponseModel[]> {
        return this.store.select(fromFeature.mainState_allPasses_selector);
    }

    updateSelectedKioskConfigurationDropoffLocationId(id: number) {
        this.store.dispatch(new fromFeatureMainActions.UpdateSelectedKioskConfigurationDropoffLocationId(id));
    }

    getSelectedKioskConfigurationDropoffLocationId() {
        return this.store.select(fromFeature.mainState_selectedKioskConfigurationDropoffLocationId_selector);
    }

    updateKioskConfigurationId(kioskDeviceInfoId: number, updateToNull: boolean) {
        this.getKioskConfigurationId().pipe(
            take(1),
            mergeMap((kioskConfigurationId: number) => {
                if (updateToNull) {
                    kioskConfigurationId = null;
                }

                // Optimistic update - update store state
                this.store.dispatch(new fromFeatureMainActions.UpdateKioskCheckedStatus(kioskDeviceInfoId, !updateToNull));

                // Update kiosk to new kioskConfigurationId
                const data: UpdateKioskDeviceInfoConfigurationRequestModel = new UpdateKioskDeviceInfoConfigurationRequestModel(kioskDeviceInfoId, kioskConfigurationId);
                return this.kioskDeviceInfoRestService.updateKioskConfigurationId(data);
            }),
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('error while updating kiosk configuration id:', error);
            }),)
            .subscribe();
    }

    updateKioskConfigurationDropoffLocationActivityStatus(kioskConfigurationDropoffLocationId: number, active: boolean) {

        let request;
        if (active) {
            request = this.kioskConfigurationRestService.activateKioskConfigurationDropoffLocation(kioskConfigurationDropoffLocationId);
        } else {
            request = this.kioskConfigurationRestService.deactivateKioskConfigurationDropoffLocation(kioskConfigurationDropoffLocationId);
        }

        request
            .pipe(
                catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('error while updating kiosk configuration dropoff location activity status', error);
            }))
            .subscribe(() => {
                this.store.dispatch(new fromFeatureMainActions.UpdateKioskConfigurationDropoffLocationActivityStatus(kioskConfigurationDropoffLocationId, active));
            });
    }

    createKioskConfigurationDropoffLocation(locationId: number): Observable<void> {
        return this.getKioskConfigurationId().pipe(take(1),
            mergeMap((kioskConfigurationId: number) => {
                // Create kiosk configuration dropoff location
                const data: CreateKioskConfigurationDropoffLocationRequestModel = new CreateKioskConfigurationDropoffLocationRequestModel(kioskConfigurationId, locationId);
                return this.kioskConfigurationRestService.createKioskConfigurationDropoffLocation(data);
            }),
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('error while creating kiosk configuration dropoff location', error);
            }),
            mergeMap((createBaseResponse: CreateBaseResponseModel) => {
                // Get created dropoff location
                return this.kioskConfigurationRestService.getKioskConfigurationDropoffLocationValidatedById(createBaseResponse.id);
            }),
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading kiosk configuration dropoff location by id', error);
            }),
            map((kioskConfigurationDropoffLocation: KioskConfigurationDropoffLocationValidatedResponseModel) => {

                const converted: KioskConfigurationDropoffLocationValidatedModel = this.convertKioskConfigurationDropoffLocationValidatedResponseModelIntoModel(kioskConfigurationDropoffLocation);

                // Dispatch action
                this.store.dispatch(new fromFeatureMainActions.AddKioskConfigurationDropoffLocation(converted));
            }),);
    }

    updateKioskConfigurationDropoffLocationSortOrder(kioskConfigurationDropoffLocationId: number, up: boolean) {
        // Optimistic update
        this.store.dispatch(new fromFeatureMainActions.SwapKioskConfigurationDropoffLocationsPlaces(kioskConfigurationDropoffLocationId, up));

        const data: UpdateKioskConfigurationDropoffLocationSortOrderRequestModel = new UpdateKioskConfigurationDropoffLocationSortOrderRequestModel(kioskConfigurationDropoffLocationId, up);
        this.kioskConfigurationRestService.updateKioskConfigurationDropoffLocationSortOrder(data).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('error while updating kiosk configuration dropoff location sort order', error);
            }))
            .subscribe();
    }

    getKioskConfigurationDropoffLocationProductsWithLocationsByKioskConfigurationDropoffLocationId(kioskConfigurationDropoffLocationId: number): Observable<KioskConfigurationDropoffLocationProductWithLocationsResponseModel[]> {
        return this.kioskConfigurationRestService.getKioskConfigurationDropoffLocationProductsWithLocationsByKioskConfigurationDropoffLocationId(kioskConfigurationDropoffLocationId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading kiosk configuration dropoff location products', error);
            }));
    }

    getProductsForKioskConfigurationDropoffLocationRouteSetup(): Observable<ProductForRouteSetupResponseModel[]> {
        return this.productRestService.getProductsForKioskConfigurationDropoffLocationRouteSetup().pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading products for route setup', error);
            }));
    }

    getPickupLocationsByProductIdAndDirection(productId: number, isReturnTrip: boolean): Observable<LocationListItemDescriptorResponseModel[]> {
        return this.locationListRestService.getPickupLocationsByProductIdAndDirection(productId, isReturnTrip).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading pickup locations', error);
            }));
    }

    getDropoffLocationsByProductIdPickupLocationIdAndDirection(productId: number, pickupLocationId: number, isReturnTrip: boolean): Observable<LocationListItemDescriptorResponseModel[]> {
        return this.locationListRestService.getDropoffLocationsByProductIdPickupLocationIdAndDirection(productId, pickupLocationId, isReturnTrip).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading dropoff locations', error);
            }));
    }

    createKioskConfigurationDropoffLocationProduct(kioskConfigurationDropoffLocationId: number, dropoffLocationId: number, products: KioskConfigurationDropoffLocationProductModel[]): Observable<KioskConfigurationWarningModel[]> {

        let sortOrder = 1;
        const productsRequest: CreateKioskConfigurationDropoffLocationProductRequestModel[] = _.map(products, (product: KioskConfigurationDropoffLocationProductModel) =>
            new CreateKioskConfigurationDropoffLocationProductRequestModel(product.productId, product.isReturnTrip, product.pickupLocationId, product.dropoffLocationId, sortOrder++));

        const data: CreateKioskConfigurationDropoffLocationProductsRequestModel = new CreateKioskConfigurationDropoffLocationProductsRequestModel(kioskConfigurationDropoffLocationId, dropoffLocationId, productsRequest);

        return this.kioskConfigurationRestService.createKioskConfigurationDropoffLocationProduct(data).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating kiosk configuration dropoff location product', error);
            }),
            mergeMap((warningsResponse: KioskConfigurationWarningResponseModel[]) => {

                const warnings: KioskConfigurationWarningModel[] = warningsResponse.map((warning: KioskConfigurationWarningResponseModel) => {
                    return new KioskConfigurationWarningModel(warning.type, warning.additionalDescription);
                });

                // Reload kiosk configuration dropoff location
                const reloadKioskConfigurationDropoffLocationByIdRequest = this.reloadKioskConfigurationDropoffLocationById(kioskConfigurationDropoffLocationId);

                return observableCombineLatest(observableOf(warnings), reloadKioskConfigurationDropoffLocationByIdRequest);
            }),
            map(([warnings]) => {
                const warningsParameterized: KioskConfigurationWarningModel[] = warnings;

                return warningsParameterized;
            }),);
    }

    getKioskConfigurationDropoffLocationProductTiers(kioskConfigurationDropoffLocationId: number, roundTrip: boolean): Observable<KioskConfigurationDropoffLocationProductTierResponseModel[]> {
        return this.kioskConfigurationRestService.getKioskConfigurationDropoffLocationProductTiersByDropoffLocationIdAndTripType(kioskConfigurationDropoffLocationId, roundTrip).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading kiosk configuration dropoff location product tiers', error);
            }));
    }

    getAllTiers(): Observable<TierResponseModel[]> {
        return this.rootSandbox.getAllTiers();
    }

    createKioskConfigurationDropoffLocationProductTier(kioskConfigurationDropoffLocationId: number, roundTrip: boolean, productTiers: KioskConfigurationDropoffLocationProductTierModel[]): Observable<any> {

        const productTiersRequest: CreateKioskConfigurationDropoffLocationProductTierRequestModel[] = _.map(productTiers, (productTier: KioskConfigurationDropoffLocationProductTierModel) =>
            new CreateKioskConfigurationDropoffLocationProductTierRequestModel(productTier.tierId, productTier.defaultQuantity, productTier.minQuantity, productTier.maxQuantity));

        const data: CreateKioskConfigurationDropoffLocationProductTiersRequestModel =
            new CreateKioskConfigurationDropoffLocationProductTiersRequestModel(kioskConfigurationDropoffLocationId, roundTrip, productTiersRequest);

        return this.kioskConfigurationRestService.createKioskConfigurationDropoffLocationProductTier(data).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating kiosk configuration dropoff location product tier', error);
            }),
            mergeMap(() => {
                return this.reloadKioskConfigurationDropoffLocationById(kioskConfigurationDropoffLocationId);
            }),);
    }

    getKioskConfigurationDropoffLocationPassTiers(kioskConfigurationDropoffLocationId: number, passId: number): Observable<KioskConfigurationDropoffLocationPassTierResponseModel[]> {
        return this.kioskConfigurationRestService.getKioskConfigurationDropoffLocationPassTiersByKioskConfigurationDropoffLocationIdAndPassId(kioskConfigurationDropoffLocationId, passId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading kiosk configuration dropoff location pass tiers', error);
            }));
    }

    createKioskConfigurationDropoffLocationPassTier(kioskConfigurationDropoffLocationId: number, passId: number, passTiers: KioskConfigurationDropoffLocationPassTierModel[]) {

        const passTiersRequest: CreateKioskConfigurationDropoffLocationPassTierRequestModel[] = _.map(passTiers, (passTier: KioskConfigurationDropoffLocationPassTierModel) =>
            new CreateKioskConfigurationDropoffLocationPassTierRequestModel(passTier.tierId, passTier.defaultQuantity, passTier.minQuantity, passTier.maxQuantity));

        const data: CreateKioskConfigurationDropoffLocationPassTiersRequestModel =
            new CreateKioskConfigurationDropoffLocationPassTiersRequestModel(kioskConfigurationDropoffLocationId, passId, passTiersRequest);

        return this.kioskConfigurationRestService.createKioskConfigurationDropoffLocationPassTier(data).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating kiosk configuration dropoff location pass tiers', error);
            }),
            mergeMap(() => {
                return this.reloadKioskConfigurationDropoffLocationById(kioskConfigurationDropoffLocationId);
            }),
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while reloading kiosk configuration dropoff location', error);
            }),);
    }

    deleteKioskConfigurationDropoffLocation(kioskConfigurationDropoffLocationId: number) {

        // Optimistic delete
        this.store.dispatch(new fromFeatureMainActions.DeleteKioskConfigurationDropoffLocation(kioskConfigurationDropoffLocationId));

        this.getKioskConfigurationId().pipe(
            take(1),
            mergeMap((kioskConfigurationId: number) => {
                return this.kioskConfigurationRestService.deleteKioskConfigurationDropoffLocation(kioskConfigurationId, kioskConfigurationDropoffLocationId);
            }),
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while deleting kiosk configuration dropoff location', error);
            }),)
            .subscribe();
    }

    reloadKioskConfigurationDropoffLocationById(kioskConfigurationDropoffLocationId: number): Observable<any> {
        return this.kioskConfigurationRestService.getKioskConfigurationDropoffLocationValidatedById(kioskConfigurationDropoffLocationId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading kiosk configuration dropoff location by id', error);
            }),
            map((data: KioskConfigurationDropoffLocationValidatedResponseModel) => {

                const converted: KioskConfigurationDropoffLocationValidatedModel = this.convertKioskConfigurationDropoffLocationValidatedResponseModelIntoModel(data);

                this.store.dispatch(new fromFeatureMainActions.UpdateKioskConfigurationDropoffLocation(converted));
                return observableOf();
            }),);
    }

    convertKioskConfigurationDropoffLocationValidatedResponseModelIntoModel(data: KioskConfigurationDropoffLocationValidatedResponseModel): KioskConfigurationDropoffLocationValidatedModel {

        const warnings: KioskConfigurationWarningModel[] = data.warnings.map((warning: KioskConfigurationWarningResponseModel) => {
            return new KioskConfigurationWarningModel(warning.type, warning.additionalDescription);
        });

        return new KioskConfigurationDropoffLocationValidatedModel(
            data.kioskConfigurationDropoffLocationId,
            data.kioskConfigurationId,
            data.dropoffLocationId,
            data.dropoffLocationDescription,
            data.active,
            data.sortOrder,
            data.kioskConfigurationDropoffLocationProducts,
            data.kioskConfigurationDropoffLocationProductTiersOneWay,
            data.kioskConfigurationDropoffLocationProductTiersRoundTrip,
            data.kioskConfigurationDropoffLocationPasses,
            warnings,
            data.valid
        );
    }
}
