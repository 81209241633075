import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {OccurrenceRestService} from "../../../../../../../../core/modules/rest/occurrence/occurrence-rest.service";
import {Observable} from "rxjs";

@Injectable()
export class EditOccurrencesDialogSandbox extends DialogSandbox {

    constructor(appDialogsService: AppDialogsService, private occurrenceRestService: OccurrenceRestService) {
        super(appDialogsService);
    }

    resetState() {
    }

    updateOccurrenceQuantity(occurrenceIds: number[], totalQuantity: number): Observable<void> {
        return this.occurrenceRestService.updateOccurrencesQuantity({
            occurrenceIds: occurrenceIds,
            totalQuantity: totalQuantity
        });
    }
}
