import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared.module";
import {VoidOrderDialogComponent} from "./container/void-order-dialog.component";
import {VoidOrderDialogSandbox} from "./sandbox/void-order-dialog.sandbox";
import {StoreModule} from "@ngrx/store";
import * as reducer from './sandbox/store/reducer';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(reducer.REDUCER_NAME, reducer.state_reducer)
    ],
    exports: [
        VoidOrderDialogComponent
    ],
    providers: [
        VoidOrderDialogSandbox
    ],
    declarations: [
        VoidOrderDialogComponent
    ],
    entryComponents: [
        VoidOrderDialogComponent
    ]
})
export class VoidOrderDialogModule {

}
