import {DroppedDataI} from './dropped-data.model';
import {ReportItemModel} from './report-item.model';

export class DroppedReportItemDataModel implements DroppedDataI {
    parentGroupUUID: string;
    leftSiblingUUID: string;
    reportItem: ReportItemModel;

    constructor(parentGroupUUID: string, leftSiblingUUID: string, reportItem: ReportItemModel) {
        this.parentGroupUUID = parentGroupUUID;
        this.leftSiblingUUID = leftSiblingUUID;
        this.reportItem = reportItem;
    }
}
