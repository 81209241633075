export class ActivateCategoryRequestModel {

    categoryId: number;
    enableAllProducts: boolean;
    productIds: number[];

    constructor(categoryId: number, enableAllProducts: boolean, productIds: number[]) {
        this.categoryId = categoryId;
        this.enableAllProducts = enableAllProducts;
        this.productIds = productIds;
    }
}
