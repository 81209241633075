
import {of as observableOf, combineLatest as observableCombineLatest, forkJoin as observableForkJoin, Observable} from 'rxjs';

import {mergeMap, catchError, map} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {KioskConfigurationDropoffLocationProductModel} from "../model/kiosk-configuration-dropoff-location-product.model";
import {ProductForRouteSetupResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-for-route-setup-response.model";
import {KioskConfigurationWarningModel} from "../../../model/kiosk-configuration-warning.model";
import {KioskConfigurationRestService} from "../../../../../../../../core/modules/rest/kiosk-configuration/kiosk-configuration-rest.service";
import {KioskConfigurationDropoffLocationProductWithLocationsResponseModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-dropoff-location-product-with-locations-response.model";
import {ProductRestService} from "../../../../../../../../core/modules/rest/product/product-rest.service";
import {LocationListItemDescriptorResponseModel} from '../../../../../../../../core/modules/rest/location-list/response/location-list-item-descriptor-response.model';
import {LocationListItemDescriptorModel} from "../model/location-list-item-descriptor.model";
import {Store} from "@ngrx/store";
import {LocationListRestService} from "../../../../../../../../core/modules/rest/location-list/location-list-rest.service";
import {CreateKioskConfigurationDropoffLocationProductRequestModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-dropoff-location-product-request.model";
import {CreateKioskConfigurationDropoffLocationProductsRequestModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-dropoff-location-products-request.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {KioskConfigurationWarningResponseModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-warning-response.model";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {KioskConfigurationDropoffLocationValidatedResponseModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-dropoff-location-validated-response.model";
import {KioskConfigurationDropoffLocationValidatedModel} from "../../../model/kiosk-configuration-dropoff-location-validated.model";
import * as fromFeatureMainActions from "../../../store/main/actions";

@Injectable()
export class RouteSetupDialogSandbox extends DialogSandbox {

    kioskConfigurationDropoffLocationProducts$: Observable<KioskConfigurationDropoffLocationProductModel[]> = this.store.select(reducer.kioskConfigurationDropoffLocationProducts_selector);
    allProducts$: Observable<ProductForRouteSetupResponseModel[]> = this.store.select(reducer.allProducts_selector);
    warningsWhileCreating$: Observable<KioskConfigurationWarningModel[]> = this.store.select(reducer.warnings_selector);

    constructor(private kioskConfigurationRestService: KioskConfigurationRestService,
                private productRestService: ProductRestService,
                private locationListRestService: LocationListRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox,
                appDialogsService: AppDialogsService) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadDialogData(kioskConfigurationDropoffLocationId: number) {

        this.showLoader();

        // Load all possible products for route
        const getProductsForKioskConfigurationDropoffLocationRouteSetup_request = this.productRestService.getProductsForKioskConfigurationDropoffLocationRouteSetup();

        // Load kiosk configuration dropoff location products
        const getKioskConfigurationDropoffLocationProductsWithLocationsByKioskConfigurationDropoffLocationId_request = this.kioskConfigurationRestService.getKioskConfigurationDropoffLocationProductsWithLocationsByKioskConfigurationDropoffLocationId(kioskConfigurationDropoffLocationId);

        observableForkJoin(getProductsForKioskConfigurationDropoffLocationRouteSetup_request, getKioskConfigurationDropoffLocationProductsWithLocationsByKioskConfigurationDropoffLocationId_request)
            .subscribe(([productResponse, kioskConfigurationDropoffLocationProductsResponse]: [ProductForRouteSetupResponseModel[], KioskConfigurationDropoffLocationProductWithLocationsResponseModel[]]) => {
                let kioskConfigurationDropoffLocationProducts: KioskConfigurationDropoffLocationProductModel[] = this.transformDataForKioskConfigurationDropoffLocationProducts(kioskConfigurationDropoffLocationProductsResponse);
                let activeProducts = productResponse.filter((product: ProductForRouteSetupResponseModel) => product.active == true);
                this.store.dispatch(new actions.SetAllProducts(activeProducts));
                this.store.dispatch(new actions.SetKioskConfigurationDropoffLocationProducts(kioskConfigurationDropoffLocationProducts));
                this.hideLoader();
            },
            () =>  this.hideLoader());
    }

    productSelectionChanged(index: number, product: ProductForRouteSetupResponseModel) {

        this.store.dispatch(new actions.UpdateProductForKioskConfigurationDropoffLocationProduct(index, product));

        if (product !== undefined) {
            this.productDirectionChanged(index, product.productId, false);
        }
    }

    productDirectionChanged(index: number, productId: number, isReturnTrip: boolean) {

        this.store.dispatch(new actions.UpdateDirectionForKioskConfigurationDropoffLocationProduct(index, isReturnTrip));

        if (isReturnTrip !== null) {
            this.locationListRestService.getPickupLocationsByProductIdAndDirection(productId, isReturnTrip).pipe(
                map((locationsResponse: LocationListItemDescriptorResponseModel[]) => locationsResponse.map((location: LocationListItemDescriptorResponseModel) => new LocationListItemDescriptorModel(location.locationId, location.locationDescription))))
                .subscribe((locationsResponse: LocationListItemDescriptorModel[]) => {
                    this.store.dispatch(new actions.UpdatePickupLocationsForKioskConfigurationDropoffLocationProduct(index, locationsResponse));
                });
        }
    }

    productPickupLocationChanged(index: number, pickupLocationId: number, productId: number, isReturnTrip: boolean) {

        if (pickupLocationId !== null) {
            this.locationListRestService.getDropoffLocationsByProductIdPickupLocationIdAndDirection(productId, pickupLocationId, isReturnTrip).pipe(
                map((locationsResponse: LocationListItemDescriptorResponseModel[]) => locationsResponse.map((location: LocationListItemDescriptorResponseModel) => new LocationListItemDescriptorModel(location.locationId, location.locationDescription))))
                .subscribe((locationsResponse: LocationListItemDescriptorModel[]) => {
                    this.store.dispatch(new actions.UpdateDropoffLocationsForKioskConfigurationDropoffLocationProduct(index, pickupLocationId, locationsResponse));
                });
        } else {
            this.store.dispatch(new actions.UpdateDropoffLocationsForKioskConfigurationDropoffLocationProduct(index, null, []));
        }

    }

    productDropoffLocationChanged(index: number, dropoffLocationId: number) {
        this.store.dispatch(new actions.UpdateSelectedDropoffLocationForKioskConfigurationDropoffLocationProduct(index, dropoffLocationId));
    }

    productAdded() {
        this.store.dispatch(new actions.KioskConfigurationDropoffLocationProductAdded());
    }

    productDeleted(index: number) {
        this.store.dispatch(new actions.KioskConfigurationDropoffLocationProductDeleted(index));
    }

    createKioskConfigurationDropoffLocationProduct(kioskConfigurationDropoffLocationId: number, dropoffLocationId: number, products: KioskConfigurationDropoffLocationProductModel[]): Observable<boolean> {

        let sortOrder = 1;
        const productsRequest: CreateKioskConfigurationDropoffLocationProductRequestModel[] = products.map((product: KioskConfigurationDropoffLocationProductModel) => new CreateKioskConfigurationDropoffLocationProductRequestModel(product.productId, product.isReturnTrip, product.pickupLocationId, product.dropoffLocationId, sortOrder++));

        const data: CreateKioskConfigurationDropoffLocationProductsRequestModel = new CreateKioskConfigurationDropoffLocationProductsRequestModel(kioskConfigurationDropoffLocationId, dropoffLocationId, productsRequest);

        return this.kioskConfigurationRestService.createKioskConfigurationDropoffLocationProduct(data).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating kiosk configuration dropoff location product', error);
            }),
            mergeMap((warningsResponse: KioskConfigurationWarningResponseModel[]) => {

                const warnings: KioskConfigurationWarningModel[] = warningsResponse.map((warning: KioskConfigurationWarningResponseModel) => {
                    return new KioskConfigurationWarningModel(warning.type, warning.additionalDescription);
                });

                // Reload kiosk configuration dropoff location
                const reloadKioskConfigurationDropoffLocationByIdRequest = this.reloadKioskConfigurationDropoffLocationById(kioskConfigurationDropoffLocationId);

                return observableCombineLatest(observableOf(warnings), reloadKioskConfigurationDropoffLocationByIdRequest);
            }),
            map(([warnings]) => {
                const warningsParameterized: KioskConfigurationWarningModel[] = warnings;
                this.store.dispatch(new actions.SetWarnings(warningsParameterized));

                return warningsParameterized.length === 0;
            }),);
    }


    private transformDataForKioskConfigurationDropoffLocationProducts(kioskConfigurationDropoffLocationProducts: KioskConfigurationDropoffLocationProductWithLocationsResponseModel[]): KioskConfigurationDropoffLocationProductModel[] {
        return kioskConfigurationDropoffLocationProducts.map((productResponse: KioskConfigurationDropoffLocationProductWithLocationsResponseModel) => {
            const pickupLocations: LocationListItemDescriptorModel[]  = productResponse.pickupLocations.map((location: LocationListItemDescriptorResponseModel) => new LocationListItemDescriptorModel(location.locationId, location.locationDescription));
            const dropoffLocations: LocationListItemDescriptorModel[] = productResponse.dropoffLocations.map((location: LocationListItemDescriptorResponseModel) => new LocationListItemDescriptorModel(location.locationId, location.locationDescription));
            return new KioskConfigurationDropoffLocationProductModel(productResponse.productId, productResponse.isRoundTripProduct, productResponse.isReturnTrip, productResponse.pickupLocationId, productResponse.dropoffLocationId, pickupLocations, dropoffLocations);
        });
    }

    private reloadKioskConfigurationDropoffLocationById(kioskConfigurationDropoffLocationId: number): Observable<any> {
        return this.kioskConfigurationRestService.getKioskConfigurationDropoffLocationValidatedById(kioskConfigurationDropoffLocationId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading kiosk configuration dropoff location by id', error);
            }),
            map((data: KioskConfigurationDropoffLocationValidatedResponseModel) => {

                const converted: KioskConfigurationDropoffLocationValidatedModel = this.convertKioskConfigurationDropoffLocationValidatedResponseModelIntoModel(data);

                this.store.dispatch(new fromFeatureMainActions.UpdateKioskConfigurationDropoffLocation(converted));
                return observableOf();
            }),);
    }

    private convertKioskConfigurationDropoffLocationValidatedResponseModelIntoModel(data: KioskConfigurationDropoffLocationValidatedResponseModel): KioskConfigurationDropoffLocationValidatedModel {

        const warnings: KioskConfigurationWarningModel[] = data.warnings.map((warning: KioskConfigurationWarningResponseModel) => {
            return new KioskConfigurationWarningModel(warning.type, warning.additionalDescription);
        });

        return new KioskConfigurationDropoffLocationValidatedModel(
            data.kioskConfigurationDropoffLocationId,
            data.kioskConfigurationId,
            data.dropoffLocationId,
            data.dropoffLocationDescription,
            data.active,
            data.sortOrder,
            data.kioskConfigurationDropoffLocationProducts,
            data.kioskConfigurationDropoffLocationProductTiersOneWay,
            data.kioskConfigurationDropoffLocationProductTiersRoundTrip,
            data.kioskConfigurationDropoffLocationPasses,
            warnings,
            data.valid
        );
    }
}
