export class UpdateBusRequestModel {
    busId: number;
    number: number;
    description: string;
    capacity: number;

    constructor(busId: number, number: number, description: string, capacity: number) {
        this.busId = busId;
        this.number = number;
        this.description = description;
        this.capacity = capacity;
    }
}
