


<input type="{{ filterItem.type === 'INPUT_STRING' ? 'text' : 'number'}}"
       class="main--element"
       [(ngModel)]="inputValue"
       (input)="inputValueChanged()"
       (focusin)="onFocus()"
       (focusout)="onBlur()"
       [disabled]="!(hasRunOrderReportFullPermission || !hadInitialValue)"
       #inputField>
