import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {OccurrenceTicketsResponseModel} from "../../../../../../../../../core/modules/rest/occurrence/response/occurrence-tickets-response.model";
import {OccurrenceTicketResponseModel} from "../../../../../../../../../core/modules/rest/occurrence/response/occurrence-ticket-response.model";
import {TicketStatusEnum} from "../../../../../../../../../shared/enums/ticket-status.enum";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import { UserInfoResponseModel } from '../../../../../../../../../core/modules/rest/user/response/user-info-response.model';
import { PermissionEnum } from '../../../../../../../../../shared/enums/permission.enum';
import { CheckInTypeEnum } from '../../../../../../../../../shared/enums/checkin-type.enum';


@Component({
    selector: 'app-occurrence-manifest-dialog-form',
    templateUrl: './occurrence-manifest-dialog-form.component.html'
})
export class OccurrenceManifestDialogFormComponent implements OnInit, OnChanges {

    @Input() occurrenceTickets: OccurrenceTicketsResponseModel = null;

    @Input() currentUser: UserInfoResponseModel = null;

    @Output() printClicked: EventEmitter<void> = new EventEmitter();
    @Output() emailClicked: EventEmitter<void> = new EventEmitter();
    @Output() showOrderSummaryClicked: EventEmitter<number> = new EventEmitter();
    @Output() doCheckInClicked: EventEmitter<[number, number]> = new EventEmitter();
    @Output() checkInAllClick: EventEmitter<[number, number[]]> = new EventEmitter();
    @Output() undoCheckInClicked: EventEmitter<[number, number]> = new EventEmitter();
    @Output() doNoShowClicked: EventEmitter<[number, number]> = new EventEmitter();
    @Output() undoNoShowClicked: EventEmitter<[number, number]> = new EventEmitter();
    @Output() showInfoMessage: EventEmitter<string> = new EventEmitter();

    reservedTickets: OccurrenceTicketResponseModel[] = [];
    checkedInTickets: OccurrenceTicketResponseModel[] = [];
    noShowTickets: OccurrenceTicketResponseModel[] = [];
    passCardCheckIns: OccurrenceTicketResponseModel[] = [];

    reservedTicketStatusOptions: DropdownOptionModel[];
    checkedInTicketStatusOptions: DropdownOptionModel[];
    noShowTicketStatusOptions: DropdownOptionModel[];

    RESERVED: string = 'RESERVED';
    CHECKED_IN: TicketStatusEnum = TicketStatusEnum.CHECKED_IN;
    NO_SHOW: TicketStatusEnum = TicketStatusEnum.NO_SHOW;

    constructor() {
    }

    ngOnInit() {

        // Ticket status RESERVED does not exist, it is artificial ticket status that is used to represent ticket with no ticket status, CHECKED_OUT and REJECTED ticket statuses

        this.reservedTicketStatusOptions = [
            new DropdownOptionModel("Reserved", "RESERVED"),
            new DropdownOptionModel("Checked-in", TicketStatusEnum.CHECKED_IN),
            new DropdownOptionModel("No-Show", TicketStatusEnum.NO_SHOW)
        ];

        this.checkedInTicketStatusOptions = [
            new DropdownOptionModel("Reserved", "RESERVED"),
            new DropdownOptionModel("Checked-in", TicketStatusEnum.CHECKED_IN)
        ];

        this.noShowTicketStatusOptions = [
            new DropdownOptionModel("Reserved", "RESERVED"),
            new DropdownOptionModel("No-Show", TicketStatusEnum.NO_SHOW)
        ];

        if (this.occurrenceTickets && this.occurrenceTickets.occurrenceTickets) {
            for(let occurrenceCheckIn of this.occurrenceTickets.occurrenceTickets ){
                if( occurrenceCheckIn.checkInType == CheckInTypeEnum.PASS_CARD ){
                    this.passCardCheckIns.push( occurrenceCheckIn );
                }
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.occurrenceTickets) {

            this.passCardCheckIns = [];
            this.reservedTickets = [];
            this.checkedInTickets = [];
            this.noShowTickets = [];

            if (this.occurrenceTickets && this.occurrenceTickets.occurrenceTickets) {
                for (let occurrenceTicket of this.occurrenceTickets.occurrenceTickets) {
                    if(occurrenceTicket.checkInType == CheckInTypeEnum.TICKET){
                        if (occurrenceTicket.lastTicketStatus === null || occurrenceTicket.lastTicketStatus === TicketStatusEnum.CHECKED_OUT || occurrenceTicket.lastTicketStatus === TicketStatusEnum.REJECTED) {
                            this.reservedTickets.push(occurrenceTicket);
                        } else if (occurrenceTicket.lastTicketStatus === TicketStatusEnum.CHECKED_IN) {
                            this.checkedInTickets.push(occurrenceTicket);
                        } else if (occurrenceTicket.lastTicketStatus === TicketStatusEnum.NO_SHOW) {
                            this.noShowTickets.push(occurrenceTicket);
                        }
                    }
                    else{
                        this.passCardCheckIns.push(occurrenceTicket);
                    }
                }
            }
        }
    }

    onPrintClick() {
        this.printClicked.emit();
    }

    onEmailClick() {
        this.emailClicked.emit();
    }

    onShowOrderSummaryClick(orderId: number) {
        this.showOrderSummaryClicked.emit(orderId);
    }

    onSelectedTicketStatusChanged(value: string, occurrenceTicket: OccurrenceTicketResponseModel) {

        // Determine which status change is being performed. Allowed:
        // RESERVED => CHECKED_IN, NO_SHOW
        // CHECKED_IN => RESERVED
        // NO_SHOW => RESERVED

        if (occurrenceTicket.lastTicketStatus === null || occurrenceTicket.lastTicketStatus === undefined ||
            occurrenceTicket.lastTicketStatus === TicketStatusEnum.CHECKED_OUT ||
            occurrenceTicket.lastTicketStatus === TicketStatusEnum.REJECTED) {

            if (value === "CHECKED_IN") {
                this.doCheckInClicked.emit([this.occurrenceTickets.occurrenceId, occurrenceTicket.ticketId]);
            } else if (value === "NO_SHOW") {
                this.doNoShowClicked.emit([this.occurrenceTickets.occurrenceId, occurrenceTicket.ticketId]);
            }
        }

        if (occurrenceTicket.lastTicketStatus === TicketStatusEnum.CHECKED_IN) {
            if (value === "RESERVED") {
                this.undoCheckInClicked.emit([this.occurrenceTickets.occurrenceId, occurrenceTicket.ticketId]);
            }
        }

        if (occurrenceTicket.lastTicketStatus === TicketStatusEnum.NO_SHOW) {
            if (value === "RESERVED") {
                this.undoNoShowClicked.emit([this.occurrenceTickets.occurrenceId, occurrenceTicket.ticketId]);
            }
        }
    }

    onCheckInAllClick() {
        if (this.canDoBulkCheckIn()) {
            let ticketIds: number[] = [];
            for (let reservedTicket of this.reservedTickets) {
                ticketIds.push(reservedTicket.ticketId);
            }
            if (ticketIds.length > 0) {
                this.checkInAllClick.emit([this.occurrenceTickets.occurrenceId, ticketIds]);
            }
            else {
                this.showInfoMessage.emit("There are no reserved tickets");
            }
        }
    }

    getDialogSubtitle() {
        return this.occurrenceTickets
            ? this.occurrenceTickets.occurrenceDescription
            : "N/A";
    }

    canDoBulkCheckIn() {
        return this.currentUser.permissions.includes(PermissionEnum.BULK_CHECK_IN);
    }


}
