<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmit()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>Add Command for Device: {{androidDeviceNumber}}</h1>
            </div>
        </div>
    </div>

    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">
        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Remote Command</label>
                        <app-dropdown class="main--element"
                            [customClasses]="'dropdown_large'"
                            [options]='remoteCommandOptions'
                            formControlName="remoteCommand">
                        </app-dropdown>
                    </app-input-label-group>
                </div>
            </div>
        </div>
        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Timeout Hours</label>
                        <input 
                            type="number" 
                            formControlName="timeoutHours" 
                            min="1" 
                            class="main--element"/>
                    </app-input-label-group>
                </div>
            </div>
        </div>        
    </div><!-- Dialog Content :: END -->

    <div class="errorMessage_wrapper"
         *ngIf="errorMessage">
        <label class="error">
            {{ errorMessage }}
        </label>
    </div>

    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onClose()">
                    Cancel
                </app-button>
            </div>

            <div class="cell medium-6">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid"
                    (clickEvent)="onSubmit()">
                    Save
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
