import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {PassCardReportRequestModel} from "./request/pass-card-report-request.model";
import {PassCardReportResponseModel} from "./response/pass-card-report-response.model";
import {UpdatePassCardsRequestModel} from "./request/update-pass-cards-request.model";
import {EmailPassCardRequestModel} from "./request/email-pass-card-request.model";
import {PrintPdfPassCardRequestModel} from "./request/print-pdf-pass-card-request.model";

@Injectable()
export class PassCardRestService {

    constructor(private httpClient: HttpClient) {
    }

    getPassCardsByDateRangePassIdsAndEmail(request: PassCardReportRequestModel): Observable<PassCardReportResponseModel> {
        return this.httpClient.post<PassCardReportResponseModel>(environment.server_api_url + '/api/secured/passCard/getPassCardsByDateRangePassIdsAndEmail', request);
    }

    generateUrlForPrintPdfPassCard(request: PrintPdfPassCardRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/passCard/generateUrlForPrintPdfPassCard', request, {responseType: 'text'});
    }

    emailPassCard(request: EmailPassCardRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/passCard/sendEmailPassCardToEmailAddress', request, {responseType: 'text'});
    }

    updatePassCards(request: UpdatePassCardsRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/passCard/updatePassCards', request, {responseType: 'text'});
    }

    activatePassCards(passCardIds: number[]): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/passCard/activatePassCards', passCardIds, {responseType: 'text'});
    }

    deactivatePassCards(passCardIds: number[]): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/passCard/deactivatePassCards', passCardIds, {responseType: 'text'});
    }
}
