export class CreateUpdateLocationListItemRequestModel {
    locationId: number;
    offset: number;
    isCustomLocation: boolean;

    constructor(locationId: number, offset: number, isCustomLocation: boolean) {
        this.locationId = locationId;
        this.offset = offset;
        this.isCustomLocation = isCustomLocation;
    }
}
