import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromRoot from '../../../../../../core/store/reducers';

import * as fromMain from './main/reducer';
import * as fromUi from './ui/reducer';

export const REDUCER_NAME = 'feature_costs';

export interface FeatureState {
    ui: fromUi.State;
    main: fromMain.State;
}

export interface State extends fromRoot.AppState {
    REDUCER_NAME: FeatureState;
}

export const reducers: ActionReducerMap<FeatureState> = {
    ui: fromUi.reducer,
    main: fromMain.reducer,
};


const costsState_selector = createFeatureSelector<FeatureState>(REDUCER_NAME);


// ######### MAIN STATE #########
const mainState_selector = createSelector(costsState_selector, (state: FeatureState) => state.main);

export const mainState_allAdditionalCosts_selector = createSelector(mainState_selector, fromMain.allAdditionalCosts_selector);
export const mainState_allIncludedCosts_selector = createSelector(mainState_selector, fromMain.allIncludedCosts_selector);
export const mainState_allDiscounts_selector = createSelector(mainState_selector, fromMain.allDiscounts_selector);
export const mainState_allAutoAppliedDiscounts_selector = createSelector(mainState_selector, fromMain.allAutoAppliedDiscounts_selector);


// ######### UI STATE #########
const uiState_selector = createSelector(costsState_selector, (state: FeatureState) => state.ui);

export const uiState_pageDataLoaded_selector = createSelector(uiState_selector, fromUi.pageDataLoaded_selector);
export const uiState_activeCostType_selector = createSelector(uiState_selector, fromUi.activeCostType_selector);
export const uiState_showInactiveAdditionalCosts_selector = createSelector(uiState_selector, fromUi.showInactiveAdditionalCosts_selector);
export const uiState_showInactiveIncludedCosts_selector = createSelector(uiState_selector, fromUi.showInactiveIncludedCosts_selector);
export const uiState_showInactiveDiscounts_selector = createSelector(uiState_selector, fromUi.showInactiveDiscounts_selector);
export const uiState_showInactiveAutoAppliedDiscounts_selector = createSelector(uiState_selector, fromUi.showInactiveAutoAppliedDiscounts_selector);
