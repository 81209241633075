export class CreateUpdateProductTierRequestModel {
    tierId: number;
    cost: number;
    costRoundTrip: number;

    constructor(tierId: number, cost: number, costRoundTrip: number) {
        this.tierId = tierId;
        this.cost = cost;
        this.costRoundTrip = costRoundTrip;
    }
}
