import { PostBillTypeAdditionalActionEnum } from "../../../../../shared/enums/post-bill-type-additional-action.enum";

export class CreatePostBillTypeRequestModel {
    description: string;
    details: string;
    postBillTypeAdditionalAction: PostBillTypeAdditionalActionEnum;

    constructor(description: string, details: string, postBillTypeAdditionalAction: PostBillTypeAdditionalActionEnum) {
        this.description = description;
        this.details = details;
        this.postBillTypeAdditionalAction = postBillTypeAdditionalAction;
    }
}
