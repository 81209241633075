import {Action} from "@ngrx/store";
import {ProductAvailabilityResponseModel} from "../../../../../../../../../core/modules/rest/product-availability/response/product-availability-response.model";

export const RESET_STATE = '[Dialog - Clone Product Availabilities :: MAIN] Reset State';
export const SET_PRODUCT_AVAILABILITIES = '[Dialog - Clone Product Availabilities :: MAIN] Set Product Availabilities';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetProductAvailabilities implements Action {
    readonly type = SET_PRODUCT_AVAILABILITIES;

    constructor(public productAvailabilities: ProductAvailabilityResponseModel[]) {
    }
}

export type All = ResetState
    | SetProductAvailabilities;
