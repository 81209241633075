import {Action} from "@ngrx/store";
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";


export const RESET_STATE_ACTION = "[Dialog - Preview Email Html Template :: MAIN] Reset State";
export const SET_EMAIL_HTML_TEMPLATE_ACTION = "[Dialog - Preview Email Html Template :: MAIN] Set Email Template";

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class SetEmailHtmlTemplate implements Action {
    readonly type = SET_EMAIL_HTML_TEMPLATE_ACTION;

    constructor(public emailHtmlTemplate: EmailHtmlTemplateResponseModel) {
    }
}


export type All = ResetState | SetEmailHtmlTemplate;
