<app-abstract-dialog-container [shouldOverrideCloseClicked]="true" (closeClicked)="onCloseClicked()">

    <app-loader *ngIf="showLoader$ | async">
        Loading...
    </app-loader>

    <div class="form_container">
        <!-- Header -->
        <div class="header_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <h1>{{ header }}</h1>
                </div>
            </div>
        </div>

        <!-- Input -->
        <div class="content_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12 fs-16">
                    <span class="dark-grey-text fs-14">Current Password</span>
                    <input type="password"
                    class="main--element input-tall input-large mt-5px mb-10px"
                    placeholder= "Enter Current Password"
                    id="currentPassword_text"
                    value="{{ currentPassword }}"
                    #currentPasswordValue (keyup)="currentPassswordChanged(currentPasswordValue.value)"
                    (change)="currentPassswordChanged(currentPasswordValue.value)">
                    <span class="eye-icon" (click)="toggleCurrentPasswordVisibility()">
                        <i *ngIf="currentPasswordVisible" class="fa fa-eye fs-14" aria-hidden="true"></i>
                        <i *ngIf="!currentPasswordVisible" class="fa fa-eye-slash fs-14" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        </div>

        <!-- Input -->
        <div class="content_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12 fs-16">
                    <span class="dark-grey-text fs-14">New Password</span>
                    <input type="password"
                    class="main--element input-tall input-large mt-5px mb-10px"
                    placeholder= "Enter New Password"
                    id="newPassword_text"
                    value="{{ newPassword }}"
                    #newPasswordValue (keyup)="newPasswordChanged(newPasswordValue.value)"
                    (change)="newPasswordChanged(newPasswordValue.value)">
                    <span class="eye-icon" (click)="toggleNewPasswordVisibility()">
                        <i *ngIf="newPasswordVisible" class="fa fa-eye fs-14" aria-hidden="true"></i>
                        <i *ngIf="!newPasswordVisible" class="fa fa-eye-slash fs-14" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        </div>

        <!-- Input -->
        <div class="content_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12 fs-16">
                    <span class="dark-grey-text fs-14">Confirm Password</span>
                    <input type="password"
                    class="main--element input-tall input-large mt-5px mb-10px"
                    placeholder= "Enter Confirm Password"
                    id="confirmedPassword_text"
                    value="{{ confirmedPassword }}"
                    #confirmedPasswordValue (keyup)="confirmedPasswordChanged(confirmedPasswordValue.value)"
                    (change)="confirmedPasswordChanged(confirmedPasswordValue.value)">
                    <span class="eye-icon" (click)="toggleConfirmedPasswordVisibility()">
                        <i *ngIf="confirmedPasswordVisible" class="fa fa-eye fs-14" aria-hidden="true"></i>
                        <i *ngIf="!confirmedPasswordVisible" class="fa fa-eye-slash fs-14" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        </div>

        <!-- Footer container -->
        <div class="footer_wrapper flex-container">
            <!-- No button -->
            <app-button class="mr-16px ml-16px"
                        [buttonType]="'button ghost-button'"
                        (clickEvent)="onNoClicked()">
                {{ noLabel }}
            </app-button>

            <!-- Yes button -->
            <app-button [buttonType]="buttonType"
                        (clickEvent)="onYesClicked()">
                {{ yesLabel }}
            </app-button>
        </div>
    </div>
</app-abstract-dialog-container>
