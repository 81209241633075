import {Action} from '@ngrx/store';
import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";
import {AdditionalCostModel} from "../../model/additional-cost.model";


export const UPDATE_DIALOG_DATA_LOADED_ACTION = '[Dialog Edit Additional Cost] Update dialog data loaded';
export const UPDATE_EDIT_ADDITIONAL_COST_ACTION = '[Dialog Edit Additional Cost] Update edit additional cost';

export const RESET_STATE_ACTION = '[Dialog Edit Additional Cost] Reset state';

export const UPDATE_ADDITIONAL_COST_ACTION = '[Dialog Edit Additional Cost] Update additional cost';
export const UPDATE_ALL_LOCATIONS_ACTION = '[Dialog Edit Additional Cost] Update all locations';


export class UpdateDialogDataLoaded implements Action {
    readonly type = UPDATE_DIALOG_DATA_LOADED_ACTION;

    constructor(public value: boolean) {
    }
}

export class UpdateEditAdditionalCost implements Action {
    readonly type = UPDATE_EDIT_ADDITIONAL_COST_ACTION;

    constructor(public value: boolean) {
    }
}

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class UpdateAdditionalCost implements Action {
    readonly type = UPDATE_ADDITIONAL_COST_ACTION;

    constructor(public additionalCost: AdditionalCostModel) {
    }
}

export class UpdateAllLocations implements Action {
    readonly type = UPDATE_ALL_LOCATIONS_ACTION;

    constructor(public locations: LocationResponseModel[]) {
    }
}


export type Actions =
    UpdateDialogDataLoaded |
    ResetState |
    UpdateAdditionalCost |
    UpdateAllLocations |
    UpdateEditAdditionalCost;
