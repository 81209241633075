import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {QuickSellingButtonPassResponseModel} from "../../../../../../../../core/modules/rest/quick-selling-button-pass/response/quick-selling-button-pass-response.model";
import {EditQuickSellingButtonPassDialogSandbox} from "../sandbox/edit-quick-selling-button-pass-dialog.sandbox";
import {PassSimpleResponseModel} from "../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {LocationListItemDescriptorResponseModel} from "../../../../../../../../core/modules/rest/location-list/response/location-list-item-descriptor-response.model";
import {TierResponseModel} from '../../../../../../../../core/modules/rest/tier/response/tier-response.model';
import {SubmitFormDialogDataModel} from "../model/submit-form-dialog-data.model";


// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditQuickSellingButtonPassDialogData extends DialogData {

    updateCallbackFn: () => void;
    createCallbackFn: () => void;
    editMode: boolean;
    quickSellingButtonPassId: number;
    passId: number;
    pickupLocationId: number;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, editMode: boolean, quickSellingButtonPassId?: number, passId?: number, pickupLocationId?: number) {
        super();
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
        this.editMode = editMode;
        this.quickSellingButtonPassId = quickSellingButtonPassId;
        this.passId = passId;
        this.pickupLocationId = pickupLocationId;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-quick-selling-button-pass-dialog',
    templateUrl: './edit-quick-selling-button-pass-dialog.component.html',
    styleUrls: ['./edit-quick-selling-button-pass-dialog.component.scss']
})
export class EditQuickSellingButtonPassDialogComponent implements OnInit, OnDestroy {

    quickSellingButtonPass$: Observable<QuickSellingButtonPassResponseModel>;

    tiers$: Observable<TierResponseModel[]>;
    tiersLoading$: Observable<boolean>;

    passes$: Observable<PassSimpleResponseModel[]>;
    passesLoading$: Observable<boolean>;

    pickupLocations$: Observable<LocationListItemDescriptorResponseModel[]>;
    pickupLocationsLoading$: Observable<boolean>;

    dropoffLocations$: Observable<LocationListItemDescriptorResponseModel[]>;
    dropoffLocationsLoading$: Observable<boolean>;

    errorMessage$: Observable<string>;

    editMode: boolean = false;

    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    quickSellingButtonPassId: number;
    selectedPassId: number;
    selectedPickupLocationId: number;

    constructor(@Inject(DialogData) dialogData: EditQuickSellingButtonPassDialogData, private sandbox: EditQuickSellingButtonPassDialogSandbox) {

        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
        this.editMode = dialogData.editMode;
        this.quickSellingButtonPassId = dialogData.quickSellingButtonPassId;
        this.selectedPassId = dialogData.passId;
        this.selectedPickupLocationId = dialogData.pickupLocationId;
    }

    ngOnInit() {

        this.sandbox.loadData(this.editMode, this.quickSellingButtonPassId, this.selectedPassId, this.selectedPickupLocationId);

        this.quickSellingButtonPass$ = this.sandbox.quickSellingButtonPass$;
        this.tiers$ = this.sandbox.tiers$;
        this.tiersLoading$ = this.sandbox.tiersLoading$;
        this.passes$ = this.sandbox.passes$;
        this.passesLoading$ = this.sandbox.passesLoading$;
        this.pickupLocations$ = this.sandbox.pickupLocations$;
        this.pickupLocationsLoading$ = this.sandbox.pickupLocationsLoading$;
        this.dropoffLocations$ = this.sandbox.dropoffLocations$;
        this.dropoffLocationsLoading$ = this.sandbox.dropoffLocationsLoading$;
        this.errorMessage$ = this.sandbox.errorMessage$;
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSelectedPassChanged(passId: number) {
        this.selectedPassId = passId;
        this.sandbox.loadPickupLocationsByPassId(this.selectedPassId);
    }

    onSelectedPickupLocationChanged(pickupLocationId: number) {
        this.selectedPickupLocationId = pickupLocationId;
        this.sandbox.loadDropoffLocationsByPassIdAndPickupLocationId(this.selectedPassId, this.selectedPickupLocationId);
    }

    onClose() {
        this.sandbox.destroyDialog();
    }

    onSubmit(data: SubmitFormDialogDataModel) {

        if (this.editMode) {
            this.sandbox.updateQuickSellingButtonPass(this.quickSellingButtonPassId, data).subscribe(() => {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createQuickSellingButtonPass(data).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }
}
