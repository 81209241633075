import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {HolidayResponseModel} from "../../../../../../../../../core/modules/rest/holiday/response/holiday-response.model";

export const REDUCER_NAME = 'dialog_create/edit-holiday';

export interface State {
    holiday: HolidayResponseModel;
}

const initialState: State = {
    holiday: {} as HolidayResponseModel,
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const holiday_selector = createSelector(state_selector, (state: State) => state.holiday);
// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_HOLIDAY_ACTION:  return { ... state, holiday: action.holiday };

        default: return state;
    }
}
