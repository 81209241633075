import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {OrderDetailSummaryResponseModel} from "../../../../../../../core/modules/rest/order-summary/response/order-detail-summary-response.model";
import {
    CustomFieldDataModel,
    CustomFieldsEventDataModel
} from "../../general-info-dialog-summary/general-info-dialog-summary.component";
import {CustomFieldSummaryResponseModel} from "../../../../../../../core/modules/rest/order-summary/response/custom-field-summary-response.model";
import {OrderStatusEnum} from "../../../../../../enums/order-status.enum";
import {ProductSimpleResponseModel} from "../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {TicketCheckInSummaryResponseModel} from "../../../../../../../core/modules/rest/order-summary/response/ticket-check-in-summary-response.model";
import {StartTimeResponseModel} from "../../../../../../../core/modules/rest/product-availability/response/start-time-response.model";
import {DateTimeUtility} from "../../../../../../utils/date-time-utility";
import {DateChangedDataModel} from "../../../model/date-changed-data.model";
import {DropdownOptionModel} from "../../../../../../components/form-elements/dropdown/dropdown-option.model";
import * as moment from "moment";
import {ANY_TIME} from "../../../sandbox/order-summary-dialog.sandbox";
import { RootSandbox } from '../../../../../../../core/store/root.sandbox';

export interface EditOrderDetailEventDataModel {
    orderDetailId: number;
    shouldUpdateOrderDetailOccurrence: boolean;
    dateInt: number;
    productAvailabilityId: number;
    customFields: CustomFieldsEventDataModel[];
}


@Component({
    selector: 'app-product-detail-dialog-summary',
    templateUrl: './product-detail-dialog-summary.component.html'
})
export class ProductDetailDialogSummaryComponent implements OnChanges {

    @Input() product: ProductSimpleResponseModel;
    @Input() allowFixedTimeForFlexibleAndMultipleCheckInProducts: boolean;
    @Input() startTimesLoading: boolean;
    @Input() startTimes: StartTimeResponseModel[];
    @Input() orderDetail: OrderDetailSummaryResponseModel;
    @Input() hasEditPermission: boolean;
    @Input() hasCompletedEditPermission: boolean;
    @Input() editEnabled: boolean;

    @Output() saveClicked: EventEmitter<EditOrderDetailEventDataModel> = new EventEmitter();
    @Output() editClicked: EventEmitter<number> = new EventEmitter();
    @Output() cancelClicked: EventEmitter<any> = new EventEmitter();
    @Output() noShowClicked: EventEmitter<number> = new EventEmitter();
    @Output() selectedDateChanged: EventEmitter<DateChangedDataModel> = new EventEmitter();

    readonly AVAILABILITY_ANY_TIME = ANY_TIME;

    editMode: boolean = false;

    saveEnabled: boolean = false;

    canEditOrderDetailOccurrence: boolean = false;

    customFields: CustomFieldDataModel[] = [];

    invalidTicketCheckinExists: boolean = false;

    // minDateMoment: moment.Moment = moment();
    selectedDateMoment: moment.Moment = moment();
    startTimesOptions: DropdownOptionModel[];
    selectedProductAvailabilityId: number;

    private shouldUpdateOrderDetailOccurrence: boolean  = false;

    constructor(private rootSandbox: RootSandbox) {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.orderDetail && Object.keys(changes.orderDetail.currentValue).length !== 0) {

            if (this.orderDetail.occurrenceDateTimeDescriptor) {
                this.selectedDateMoment = DateTimeUtility.convertDateTimeDescriptorToMoment(this.orderDetail.occurrenceDateTimeDescriptor);

                if (this.startTimesOptions && this.startTimesOptions.length > 0) {
                    this.selectedProductAvailabilityId = this.orderDetail.productAvailabilityId;
                } else {
                    this.selectedProductAvailabilityId = null;
                }
            } else {
                this.selectedDateMoment = moment();
            }

            if (this.orderDetail.orderStatus !== OrderStatusEnum.COMPLETE && this.hasCompletedEditPermission) {
                this.hasCompletedEditPermission = false;
            }

            if (this.orderDetail.orderStatus === OrderStatusEnum.COMPLETE && this.hasEditPermission) {
                this.hasEditPermission = false;
            }

            if (this.product) {
                this.canEditOrderDetailOccurrence = this.orderDetail.orderStatus === OrderStatusEnum.NEW && (!this.product.isFlexibleProduct || this.allowFixedTimeForFlexibleAndMultipleCheckInProducts);
            }

            this.initCustomFields();
        }

        if (changes.startTimes) {

            this.startTimesOptions = this.getStartTimes(this.startTimes);

            if (this.orderDetail && this.orderDetail.occurrenceDateTimeDescriptor && this.startTimesOptions && this.startTimesOptions.length > 0) {
                this.selectedProductAvailabilityId = this.orderDetail.productAvailabilityId;
            } else {
                this.selectedProductAvailabilityId = null;
            }

            // Calculate flags shouldUpdateOrderDetailOccurrence and saveEnabled
            this.onSelectedProductAvailabilityChanged(this.selectedProductAvailabilityId !== null && this.selectedProductAvailabilityId !== undefined ? String(this.selectedProductAvailabilityId) : "");
        }

        if (changes.product) {
            if (this.orderDetail) {
                this.canEditOrderDetailOccurrence = this.orderDetail.orderStatus === OrderStatusEnum.NEW && (!this.product.isFlexibleProduct || this.allowFixedTimeForFlexibleAndMultipleCheckInProducts);
            }
        }

        if (changes.allowFixedTimeForFlexibleAndMultipleCheckInProducts) {
            if (this.product && this.orderDetail) {
                this.canEditOrderDetailOccurrence = this.orderDetail.orderStatus === OrderStatusEnum.NEW && (!this.product.isFlexibleProduct || this.allowFixedTimeForFlexibleAndMultipleCheckInProducts);
            }
        }
    }

    isEditEnabled() {
        return !this.hasEditPermission && !this.hasCompletedEditPermission
    }

    onEditClick() {
        this.editMode = true;

        this.editClicked.emit(this.orderDetail.orderDetailId);

        if (this.orderDetail.occurrenceDateTimeDescriptor) {
            this.selectedDateMoment = DateTimeUtility.convertDateTimeDescriptorToMoment(this.orderDetail.occurrenceDateTimeDescriptor);
        } else {
            this.selectedDateMoment = moment();
        }

        // Calculate flags shouldUpdateOrderDetailOccurrence and saveEnabled, and trigger initial get of start times for edited order detail
        this.onSelectedDateChanged(this.selectedDateMoment);
    }

    onCancelClick() {
        this.editMode = false;
        this.initCustomFields();
        this.cancelClicked.emit();
    }

    onSaveClick() {

        if(this.selectedDateMoment == null) {
            return this.rootSandbox.addErrorNotification("Departure Date can't be empty");
        }

        this.saveClicked.emit({
            orderDetailId: this.orderDetail.orderDetailId,
            shouldUpdateOrderDetailOccurrence: this.shouldUpdateOrderDetailOccurrence,
            dateInt: DateTimeUtility.convertDateToInt(this.selectedDateMoment),
            productAvailabilityId: this.selectedProductAvailabilityId,
            customFields: this.customFields.map(cf => {
                return {
                    fieldValue: cf.fieldValue,
                    templateItemId: cf.templateItemId,
                    partyMember: cf.partyMember
                };
            })
        });

        this.editMode = false;
    }

    onDataEdit(): void {
        this.saveEnabled = this.hasChanges();
    }

    onNoShowClicked(orderDetailId: number) {
        this.noShowClicked.emit(orderDetailId);
    }
    onSelectedDateChanged(date: moment.Moment) {

        this.selectedDateMoment = date;

        if (isNaN(this.selectedProductAvailabilityId)) {
            this.selectedProductAvailabilityId = null;
        }

        // Calculate whether occurrence should be edited
        const dateChanged: boolean = !((this.orderDetail.occurrenceDateTimeDescriptor === null && date === null) ||
            (this.orderDetail.occurrenceDateTimeDescriptor !== null && date !== null && DateTimeUtility.convertDateTimeDescriptorToInt(this.orderDetail.occurrenceDateTimeDescriptor) === DateTimeUtility.convertDateToInt(date)));
        const productAvailabilityChanged: boolean = !((this.orderDetail.productAvailabilityId === null && this.selectedProductAvailabilityId === null) ||
            (this.orderDetail.productAvailabilityId !== null && this.selectedProductAvailabilityId !== null && this.orderDetail.productAvailabilityId === this.selectedProductAvailabilityId));

        this.shouldUpdateOrderDetailOccurrence = (dateChanged || productAvailabilityChanged) && this.orderDetail.orderStatus === OrderStatusEnum.NEW && (!this.product.isFlexibleProduct || this.allowFixedTimeForFlexibleAndMultipleCheckInProducts);

        this.onDataEdit();

        this.selectedDateChanged.emit({
            product: this.product,
            orderDetail: this.orderDetail,
            dateInt: DateTimeUtility.convertDateToInt(date)
        });
    }

    onSelectedProductAvailabilityChanged(productAvailabilityId: string) {

        this.selectedProductAvailabilityId = productAvailabilityId !== null && productAvailabilityId !== undefined ? parseInt(productAvailabilityId, 10) : null;

        if (isNaN(this.selectedProductAvailabilityId)) {
            this.selectedProductAvailabilityId = null;
        }

        // Calculate whether occurrence should be edited
        const dateChanged: boolean = !((this.orderDetail.occurrenceDateTimeDescriptor === null && this.selectedDateMoment === null) ||
            (this.orderDetail.occurrenceDateTimeDescriptor !== null && this.selectedDateMoment !== null && DateTimeUtility.convertDateTimeDescriptorToInt(this.orderDetail.occurrenceDateTimeDescriptor) === DateTimeUtility.convertDateToInt(this.selectedDateMoment)));
        const productAvailabilityChanged: boolean = !((this.orderDetail.productAvailabilityId === null && this.selectedProductAvailabilityId === null) ||
            (this.orderDetail.productAvailabilityId !== null && this.selectedProductAvailabilityId !== null && this.orderDetail.productAvailabilityId === this.selectedProductAvailabilityId));

        this.shouldUpdateOrderDetailOccurrence = (dateChanged || productAvailabilityChanged) && this.orderDetail.orderStatus === OrderStatusEnum.NEW && (!this.product.isFlexibleProduct || this.allowFixedTimeForFlexibleAndMultipleCheckInProducts);

        this.onDataEdit();
    }

    getLocationDescription(startLocationDescription: string, customStartLocationDescription: string): string {

        if (customStartLocationDescription && customStartLocationDescription.length > 0) {
            return customStartLocationDescription;
        } else if (startLocationDescription && startLocationDescription.length > 0) {
            return startLocationDescription;
        } else {
            return "N/A";
        }
    }

    getLocationDescriptionForTicketCheckIn(ticketCheckIn: TicketCheckInSummaryResponseModel): string {
        if (ticketCheckIn.startLocationDescription && ticketCheckIn.startLocationDescription.length > 0) {
            return ticketCheckIn.startLocationDescription;
        } else {
            return "N/A";
        }
    }

    showPickupLocation(): boolean {
        return (this.orderDetail.usesPickupLocations && !this.orderDetail.isReturnTrip) || (this.orderDetail.usesDropoffLocations && this.orderDetail.isReturnTrip);
    }

    showDropoffLocation(): boolean {
        return (this.orderDetail.usesPickupLocations && this.orderDetail.isReturnTrip) || (this.orderDetail.usesDropoffLocations && !this.orderDetail.isReturnTrip);
    }

    ticketCheckInsExist(): boolean {

        for (let ticket of this.orderDetail.tickets) {
            if (ticket.ticketCheckIns !== null && ticket.ticketCheckIns !== undefined && ticket.ticketCheckIns.length !== 0) {
                return true;
            }
        }

        return false;
    }

    invalidTicketCheckInExist(){
        for (let ticket of this.orderDetail.tickets) {
                for(let ticketCheckin of ticket.ticketCheckIns){
                    if(ticketCheckin.failedScan){
                        this.invalidTicketCheckinExists = true;
                        return true;
                    }
                }
            }
        return false;
    }

    checkIsNoShowDisabled(): boolean {
        return this.orderDetail.orderStatus !== OrderStatusEnum.NEW;
    }

    private hasChanges(): boolean {

        let hasChanges: boolean = false;

        // Check if any custom field has been changed
        this.customFields.forEach(cf => {

            let odf: CustomFieldSummaryResponseModel = this.orderDetail.orderDetailFields
                .find(of => of.templateItemId === cf.templateItemId && of.partyMember === cf.partyMember);

            if (odf.fieldValue !== cf.fieldValue) {
                hasChanges = true;
                return;
            }
        });

        // Check if order detail's occurrence should be updated
        hasChanges = hasChanges || this.shouldUpdateOrderDetailOccurrence;

        return hasChanges;
    }

    private initCustomFields() {

        this.customFields = [];

        this.orderDetail.orderDetailFields.forEach(odf => {
            this.customFields.push({
                templateItemId: odf.templateItemId,
                templateItemDescription: odf.templateItemDescription,
                partyMember: odf.partyMember,
                fieldValue: odf.fieldValue
            });
        });
    }

    private getStartTimes(startTimes: StartTimeResponseModel[]): DropdownOptionModel[] {

        if (!startTimes || startTimes.length === 0) {
            return [];
        }

        return startTimes.map((startTime) => {

            let remainingQuantityLabel: string = "";

            if (startTime.unlimitedQuantity) {
                remainingQuantityLabel = " - Unlimited";
            } else {
                remainingQuantityLabel = startTime.remainingQuantity !== null ? " - " + startTime.remainingQuantity : "";
            }

            return new DropdownOptionModel(startTime.startTimeWithOffsetFriendly + remainingQuantityLabel, startTime.productAvailabilityId.toString());
        });
    }
}
