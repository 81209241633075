export class SubmitFormDialogDataModel {

    selectedProducts: number[];
    selectedProductAvailabilityHolidayMappingItems: number[];
    unselectedProductAvailabilityHolidayMappingItems: number[];

    constructor(selectedProducts: number[], selectedProductAvailabilityHolidayMappingItems: number[], unselectedProductAvailabilityHolidayMappingItems: number[]) {
        this.selectedProducts = selectedProducts;
        this.selectedProductAvailabilityHolidayMappingItems = selectedProductAvailabilityHolidayMappingItems;
        this.unselectedProductAvailabilityHolidayMappingItems = unselectedProductAvailabilityHolidayMappingItems;
    }
}
