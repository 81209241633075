import { NgModule } from "@angular/core";
import { SharedModule } from "../../../shared.module";
import { Sandbox } from "./sandbox/sandbox";
import { DateDialogComponent } from "./container/date-dialog.component";
import { StoreModule } from "@ngrx/store";
import { REDUCER_NAME, reducer } from "../change-password-dialog/sandbox/store/reducer";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer),
    ],
    providers: [
        Sandbox
    ],
    exports: [
        DateDialogComponent
    ],
    declarations: [
        DateDialogComponent
    ],
    entryComponents: [
        DateDialogComponent
    ]
})
export class DateDialogModule {

}
