import {Injector} from '@angular/core';

export class RenderDialogModel {
    component: any;
    injector: Injector;

    constructor(component: any, injector: Injector) {
        this.component = component;
        this.injector = injector;
    }
}
