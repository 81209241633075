import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootSandbox } from '../../../../../../core/store/root.sandbox';
import * as fromFeature from './reducers';
import * as fromFeatureMainActions from './main/actions';
import * as fromFeatureUiActions from './ui/actions';
import { HttpErrorModel } from '../../../../../../core/modules/rest/http-error.model';
import { SaleGroupRestService } from '../../../../../../core/modules/rest/sale-group/sale-group-rest.service';
import { SaleGroupResponseModel } from '../../../../../../core/modules/rest/sale-group/response/sale-group-response.model';
import { QuickSellingButtonProductResponseModel } from "../../../../../../core/modules/rest/quick-selling-button-product/response/quick-selling-button-product-response.model";
import { QuickSellingButtonPassResponseModel } from "../../../../../../core/modules/rest/quick-selling-button-pass/response/quick-selling-button-pass-response.model"
import { DomainResponseModel } from '../../../../../../core/modules/rest/domain/response/domain-response.model';
import { CustomerItemResponseModel } from '../../../../../../core/modules/rest/customer/response/customer-item-response.model';

@Injectable()
export class SaleGroupsSandbox {

    currentSearchData$ = this.store.select(fromFeature.mainState_CurrentSeachData_selector);

    constructor(
        private store: Store<fromFeature.State>,
        private rootSandbox: RootSandbox,
        private saleGroupRestService: SaleGroupRestService
    ){
    }

    loadPageData() {
        this.saleGroupRestService.getAllActive().pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while loading all sale groups", error);
            }))
            .subscribe((saleGroups: SaleGroupResponseModel[]) => {
                this.store.dispatch(new fromFeatureMainActions.UpdateSaleGroup(saleGroups));
                this.updatePageDataLoaded(true);
            }
        );
    }

    loadDomainTypePageData() {
        this.saleGroupRestService.getAllDomainTypeSaleGroups().pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while loading domain type sale groups", error);
            }))
            .subscribe((saleGroups: SaleGroupResponseModel[]) => {
                this.store.dispatch(new fromFeatureMainActions.UpdateDomainTypeSaleGroup(saleGroups));
                this.updatePageDataLoaded(true);
            }
        );
    }

    updatePageDataLoaded(loaded: boolean) {
        this.store.dispatch(new fromFeatureUiActions.PageDataLoaded(loaded));
    }

    getPageDataLoaded(): Observable<boolean> {
        return this.store.select(fromFeature.uiState_pageDataLoaded_selector);
    }

    getAllSaleGroups(): Observable<SaleGroupResponseModel[]> {
        return this.store.select(fromFeature.mainState_SaleGroup_selector);
    }

    getAllDomainTypeSaleGroups(): Observable<SaleGroupResponseModel[]> {
        return this.store.select(fromFeature.mainState_DomainTypeSaleGroup_selector);
    }   

    getAllQSBPasses(): Observable<QuickSellingButtonPassResponseModel[]>{
        return this.store.select(fromFeature.mainState_QSBPasses_selector);
    }

    getAllQSBProducts(): Observable<QuickSellingButtonProductResponseModel[]>{
        return this.store.select(fromFeature.mainState_QSBProducts_selector);
    }

    getAllCustomers(): Observable<CustomerItemResponseModel[]>{
        return this.store.select(fromFeature.mainState_Customers_selector);
    }

    getAllDomains(): Observable<DomainResponseModel[]>{
        return this.store.select(fromFeature.mainState_Domains_selector);
    }

    removeQSBPass(){

    }

    loadCustomersForSaleGroup(saleGroupId: number, offset: number, limit: number, search?: string): Observable<CustomerItemResponseModel[]> {
        const request = { saleGroupId, from: offset, size: limit, search };

        return this.saleGroupRestService.getCustomersForSaleGroupInRange(request).pipe(
            map((response: CustomerItemResponseModel[]) => {
                if (!response) {
                    throw new Error('Invalid server response');
                }
                this.store.dispatch(new fromFeatureMainActions.UpdateCustomers(response));
                return response;
            }),
            catchError((error: HttpErrorModel) => {
                console.error('Error loading customers:', error);
                return [];
            })
        );
    }

    loadCustomersWithoutSaleGroup(): Observable<CustomerItemResponseModel[]> {
        return
    }

}
