import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {TemplateItemResponseModel} from "../../../../../../../../../core/modules/rest/template/response/template-item-response.model";
import {FormControl, FormGroup} from "@angular/forms";
import {CustomFieldScopeEnum} from "../../../../../../../../../shared/enums/custom-field-scope.enum";
import {RadioButtonGroupDataModel} from "../../../../../../../../../shared/components/form-elements/radio-button-group/radio-button-group-data.model";

export interface SubmitPassTemplateItemsData {
    isEditMode: boolean;
    passId: number;
    templateId: number;
    templateItemIds: number[];
    description: string;
    required: boolean;
    requiredChanged: boolean;
    displayOnTicket: boolean;
    displayOnTicketChanged: boolean;
    fieldScope: CustomFieldScopeEnum;
    fieldScopeChanged: boolean;
}


@Component({
    selector: 'app-edit-pass-template-items-dialog-form',
    templateUrl: './edit-pass-template-items-dialog-form.component.html'
})

export class EditPassTemplateItemsDialogFormComponent implements OnChanges {

    @Input() isEditMode: boolean;
    @Input() passId: number;
    @Input() templateId: number;
    @Input() templateItems: TemplateItemResponseModel[];

    @Output() submit: EventEmitter<SubmitPassTemplateItemsData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    CUSTOM_FIELD_SCOPE_ONCE_PER_ORDER: CustomFieldScopeEnum = CustomFieldScopeEnum.ONCE_PER_ORDER;
    CUSTOM_FIELD_SCOPE_ONCE_PER_PRODUCT: CustomFieldScopeEnum = CustomFieldScopeEnum.ONCE_PER_PRODUCT;
    CUSTOM_FIELD_SCOPE_ONCE_PER_TICKET: CustomFieldScopeEnum = CustomFieldScopeEnum.ONCE_PER_TICKET;

    form: FormGroup;

    fieldScopes: RadioButtonGroupDataModel[] = [
        {
            label: "Once per order",
            value: this.CUSTOM_FIELD_SCOPE_ONCE_PER_ORDER,
            disabled: false
        },
        {
            label: "Once per product/pass",
            value: this.CUSTOM_FIELD_SCOPE_ONCE_PER_PRODUCT,
            disabled: false
        },
        {
            label: "Once per ticket/passcard",
            value: this.CUSTOM_FIELD_SCOPE_ONCE_PER_TICKET,
            disabled: false
        }
    ];

    hasError: boolean = false;
    errorMessage: string = "";

    isDescriptionDisabled: boolean = false;

    private originalRequired: boolean = false;
    private originalDisplayOnTicket: boolean = false;
    private originalFieldScope: CustomFieldScopeEnum = CustomFieldScopeEnum.ONCE_PER_ORDER;

    constructor() {

        this.form = new FormGroup({
            description: new FormControl(),
            required: new FormControl(),
            displayOnTicket: new FormControl(),
            fieldScope: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.templateItems) {
            this.initializeFormData();
            this.isDescriptionDisabled = this.isEditMode && this.templateItems.length > 1;
        }
    }

    getHeader(): string {
        if (this.isEditMode) {
            if (this.templateItems.length > 0) {
                return "Edit Custom Fields";
            } else {
                return "Edit Custom Field";
            }
        } else {
            return "Add Custom Field";
        }
    }

    getSubmitButtonLabel(): string {
        if (this.isEditMode) {
            return "Update";
        } else {
            return "Save";
        }
    }

    onSubmit() {

        let description: string = !this.isDescriptionDisabled ? this.form.value.description : null;
        let required: boolean = this.form.value.required;
        let displayOnTicket: boolean = this.form.value.displayOnTicket;
        let fieldScope: CustomFieldScopeEnum = null;
        switch (this.form.value.fieldScope) {
            case this.CUSTOM_FIELD_SCOPE_ONCE_PER_ORDER:
                fieldScope = CustomFieldScopeEnum.ONCE_PER_ORDER;
                break;
            case this.CUSTOM_FIELD_SCOPE_ONCE_PER_PRODUCT:
                fieldScope = CustomFieldScopeEnum.ONCE_PER_PRODUCT;
                break;
            case this.CUSTOM_FIELD_SCOPE_ONCE_PER_TICKET:
                fieldScope = CustomFieldScopeEnum.ONCE_PER_TICKET;
                break;
        }

        let requiredChanged: boolean = this.originalRequired !== required;
        let displayOnTicketChanged: boolean = this.originalDisplayOnTicket !== displayOnTicket;
        let fieldScopeChanged: boolean = this.originalFieldScope !== fieldScope;

        if (!this.isDescriptionDisabled && !description) {
            this.hasError = true;
            this.errorMessage = "Please enter custom field name";
            return;
        }

        if (fieldScope === null || fieldScope === undefined) {
            this.hasError = true;
            if (this.isEditMode && this.templateItems.length > 1) {
                this.errorMessage = "Please select custom field(s) scope";
            } else {
                this.errorMessage = "Please select custom field scope";
            }
            return;
        }

        let templateItemIds: number[] = null;
        if (this.isEditMode) {
            templateItemIds = this.templateItems.map((ti) => ti.templateItemId);
        }

        this.submit.emit({
            isEditMode: this.isEditMode,
            passId: this.passId,
            templateId: this.templateId,
            templateItemIds: templateItemIds,
            description: description,
            required: required,
            requiredChanged: requiredChanged,
            displayOnTicket: displayOnTicket,
            displayOnTicketChanged: displayOnTicketChanged,
            fieldScope: fieldScope,
            fieldScopeChanged: fieldScopeChanged
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {

        let description: string = "";
        let required: boolean = false;
        let displayOnTicket: boolean = false;
        let fieldScope: string = this.CUSTOM_FIELD_SCOPE_ONCE_PER_ORDER;

        if (this.isEditMode) {
            if (this.templateItems.length === 1) {

                description = this.templateItems[0].description;
                required = this.templateItems[0].required;
                displayOnTicket = this.templateItems[0].displayOnTicket;
                switch (this.templateItems[0].fieldScope) {
                    case CustomFieldScopeEnum.ONCE_PER_ORDER:
                        fieldScope = this.CUSTOM_FIELD_SCOPE_ONCE_PER_ORDER;
                        break;
                    case CustomFieldScopeEnum.ONCE_PER_PRODUCT:
                        fieldScope = this.CUSTOM_FIELD_SCOPE_ONCE_PER_PRODUCT;
                        break;
                    case CustomFieldScopeEnum.ONCE_PER_TICKET:
                        fieldScope = this.CUSTOM_FIELD_SCOPE_ONCE_PER_TICKET;
                        break;
                }
            } else if (this.templateItems.length > 1) {

                description = "[";
                for (let i = 0; i < this.templateItems.length; i++) {
                    description += this.templateItems[i].description;
                    if (i < this.templateItems.length - 1) {
                        description += ", ";
                    }
                }
                description += "]";

                let requiredFirstValue: boolean = this.templateItems[0].required;
                let requiredAllItemsSameValue: boolean = !this.templateItems.some(ti => ti.required !== requiredFirstValue);
                if (requiredAllItemsSameValue) {
                    required = requiredFirstValue;
                }

                let displayOnTicketFirstValue: boolean = this.templateItems[0].displayOnTicket;
                let displayOnTicketAllItemsSameValue: boolean = !this.templateItems.some(ti => ti.displayOnTicket !== displayOnTicketFirstValue);
                if (displayOnTicketAllItemsSameValue) {
                    displayOnTicket = displayOnTicketFirstValue;
                }

                let fieldScopeFirstValue: CustomFieldScopeEnum = this.templateItems[0].fieldScope;
                let fieldScopeAllItemsSameValue: boolean = !this.templateItems.some(ti => ti.fieldScope !== fieldScopeFirstValue);
                if (fieldScopeAllItemsSameValue) {
                    switch (this.templateItems[0].fieldScope) {
                        case CustomFieldScopeEnum.ONCE_PER_ORDER:
                            fieldScope = this.CUSTOM_FIELD_SCOPE_ONCE_PER_ORDER;
                            break;
                        case CustomFieldScopeEnum.ONCE_PER_PRODUCT:
                            fieldScope = this.CUSTOM_FIELD_SCOPE_ONCE_PER_PRODUCT;
                            break;
                        case CustomFieldScopeEnum.ONCE_PER_TICKET:
                            fieldScope = this.CUSTOM_FIELD_SCOPE_ONCE_PER_TICKET;
                            break;
                    }
                }
            }
        }

        // Populate original values
        this.originalRequired = required;
        this.originalDisplayOnTicket = displayOnTicket;
        this.originalFieldScope = <CustomFieldScopeEnum>fieldScope;

        this.form.patchValue({
            description: description,
            required: required,
            displayOnTicket: displayOnTicket,
            fieldScope: fieldScope
        });
    }
}
