import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {KioskConfigurationDropoffLocationProductModel} from "../../model/kiosk-configuration-dropoff-location-product.model";
import {ProductForRouteSetupResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-for-route-setup-response.model";
import {KioskConfigurationWarningModel} from "../../../../model/kiosk-configuration-warning.model";
import {ProductChangedData} from "../../model/emit/product-changed-data.emit";
import {DirectionChangedData} from "../../model/emit/direction-changed-data.emit";
import {PickupLocationChangedData} from "../../model/emit/pickup-location-changed-data.emit";
import {DropoffLocationChangedData} from "../../model/emit/dropoff-location-changed-data.emit";
import _ from 'lodash';

@Component({
    selector: 'app-route-setup-dialog-content',
    templateUrl: './route-setup-dialog-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteSetupDialogContentComponent {

    @Input() kioskConfigurationDropoffLocationProducts: KioskConfigurationDropoffLocationProductModel[];
    @Input() allProducts: ProductForRouteSetupResponseModel[];
    @Input() warningsWhileCreating: KioskConfigurationWarningModel[];

    @Output() closeEvent = new EventEmitter<void>();
    @Output() submitEvent = new EventEmitter<KioskConfigurationDropoffLocationProductModel[]>();

    @Output() productChangedEvent = new EventEmitter<ProductChangedData>();
    @Output() directionChangedEvent = new EventEmitter<DirectionChangedData>();
    @Output() pickupLocationChangedEvent = new EventEmitter<PickupLocationChangedData>();
    @Output() dropoffLocationChangedEvent = new EventEmitter<DropoffLocationChangedData>();

    @Output() productAddedEvent = new EventEmitter<any>();
    @Output() productDeletedEvent = new EventEmitter<number>();

    errorMessage: string = null;
    disabledSaveButton: boolean = false;

    constructor() {
    }

    // -----------------------------------------------------------------------
    // Events
    // -----------------------------------------------------------------------

    onSubmitClick() {

        this.errorMessage = null;

        if (this.kioskConfigurationDropoffLocationProducts.length === 0) {
            this.errorMessage = 'Please select at least one product';
            return;
        } else {
            for (const item of this.kioskConfigurationDropoffLocationProducts) {
                if (item.productId === null || item.isReturnTrip === null || item.pickupLocationId === null || item.dropoffLocationId === null) {
                    this.errorMessage = 'Not all items selected';
                    return;
                }
            }
        }

        this.submitEvent.emit(this.kioskConfigurationDropoffLocationProducts);
    }

    onCloseClick() {
        this.closeEvent.emit();
    }

    onProductChanged(data: ProductChangedData) {

        // Fill additional information about product
        data.product = _.find(this.allProducts, (p: ProductForRouteSetupResponseModel) => p.productId === data.productId);

        this.productChangedEvent.emit(data);
    }

    onDirectionChanged(data: DirectionChangedData) {
        this.directionChangedEvent.emit(data);
    }

    onPickupLocationChanged(data: PickupLocationChangedData) {
        this.pickupLocationChangedEvent.emit(data);
    }

    onDropoffLocationChanged(data: DropoffLocationChangedData) {
        this.dropoffLocationChangedEvent.emit(data);
    }

    onAddProductClick() {
        this.productAddedEvent.emit();
    }

    onDeleteProductClick(index: number) {
        this.productDeletedEvent.emit(index);
    }
}
