import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {ProductTierSetupDialogSandbox} from "../sandbox/product-tier-setup-dialog.sandbox";
import {Observable} from "rxjs";
import {KioskConfigurationDropoffLocationProductTierModel} from "../model/kiosk-configuration-dropoff-location-product-tier.model";


// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class ProductTierSetupDialogData extends DialogData {

    kioskConfigurationDropoffLocationId: number;
    roundTrip: boolean;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function,
                kioskConfigurationDropoffLocationId?: number,
                roundTrip?: boolean) {
        super();
        this.kioskConfigurationDropoffLocationId = kioskConfigurationDropoffLocationId;
        this.roundTrip = roundTrip;
        this.successCallbackFn = successCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-product-tier-setup-dialog',
    templateUrl: './product-tier-setup-dialog.component.html'
})
export class ProductTierSetupDialogComponent implements OnInit, OnDestroy {

    kioskConfigurationDropoffLocationId: number;
    roundTrip: boolean;
    successCallbackFn: Function;

    productTiers$: Observable<KioskConfigurationDropoffLocationProductTierModel[]>;

    constructor(@Inject(DialogData) dialogData: ProductTierSetupDialogData,
                private sandbox: ProductTierSetupDialogSandbox) {

        this.kioskConfigurationDropoffLocationId = dialogData.kioskConfigurationDropoffLocationId;
        this.roundTrip = dialogData.roundTrip;
        this.successCallbackFn = dialogData.successCallbackFn;

        this.productTiers$ = this.sandbox.productTiers$;
    }

    ngOnInit() {
        this.sandbox.loadDialogData(this.kioskConfigurationDropoffLocationId, this.roundTrip);
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(productTiers: KioskConfigurationDropoffLocationProductTierModel[]) {
        this.sandbox.createKioskConfigurationDropoffLocationProductTier(this.kioskConfigurationDropoffLocationId, this.roundTrip, productTiers)
            .subscribe(() => {
                this.sandbox.hideLoader();
                this.sandbox.destroyDialog();
                this.successCallbackFn();
            },
            () => this.sandbox.hideLoader());
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
