<div class="pass-dialog-content">
    <h1 class="mb-8px">Create New MultiPass</h1>
    <form [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="grid-x grid-margin-x">
                    <div class="cell medium-12">
                        <app-input-label-group>
                            <label class="label">MultiPass Name</label>
                            <input class="main--element input-large" placeholder="Enter MultiPass Name" formControlName="description"/>
                        </app-input-label-group>
                    </div>
                </div>
            </div>
        </ng-container>

        <p *ngIf="hasError" class="error">{{ errorMessage }}</p>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Create</button>
    </div>
</div>
