import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {AccordionItemService} from "../../../../../../../shared/components/accordion/accordion-item/accordion-item.service";
import {Subscription} from "rxjs";
import {AdditionalCostResponseModel} from "../../../../../../../core/modules/rest/cost/response/additional-cost-response.model";
import {EMPTY_STATE_ICON_CREATE_NEW} from "../../../../../../../core/services/app-properties.service";
import {CostTypeEnum} from "../../../../../../../shared/enums/cost-type.enum";
import { YesNoDialogComponent, YesNoDialogData } from '../../../../../../../shared/dialogs/common-dialogs/yes-no-dialog/container/yes-no-dialog.component';
import { AppDialogsService } from '../../../../../../../core/services/app-dialogs.service';
import { CostRestService } from '../../../../../../../core/modules/rest/cost/cost-rest.service';


@Component({
    selector: 'app-additional-costs-container',
    templateUrl: './additional-costs-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        AccordionItemService
    ],
})
export class AdditionalCostsContainerComponent implements OnInit, OnChanges, OnDestroy {
    emptyStateIcon = EMPTY_STATE_ICON_CREATE_NEW;

    @Input() activeCostType: CostTypeEnum; // Used in ngOnChanges
    @Input() additionalCosts: AdditionalCostResponseModel[];
    @Input() showInactiveAdditionalCosts: boolean;

    @Output() activateCostTypeEvent: EventEmitter<CostTypeEnum> = new EventEmitter();
    @Output() addNewAdditionalCostEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() showInactiveAdditionalCostsEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() mappingAdditionalCostEvent: EventEmitter<[number, string]> = new EventEmitter();
    @Output() editAdditionalCostEvent: EventEmitter<number> = new EventEmitter();
    @Output() editAdditionalCostActivityEvent: EventEmitter<number> = new EventEmitter();

    private thisCostType: CostTypeEnum = CostTypeEnum.ADDITIONAL;
    private accordionHeaderClickSubscription: Subscription;

    constructor(private accordionItemService: AccordionItemService, private appDialogsService: AppDialogsService,  private costRestService: CostRestService) {
        this.accordionHeaderClickSubscription = this.accordionItemService.listenOnHeaderClick().subscribe(this.activateCostType.bind(this));
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeCostType) {
            this.updateAccordionActivity();
        }
    }

    ngOnDestroy(): void {
        if (this.accordionHeaderClickSubscription) {
            this.accordionHeaderClickSubscription.unsubscribe();
        }
    }

    addNewAdditionalCostClick() {
        this.addNewAdditionalCostEvent.emit();
    }

    onShowInactiveAdditionalCosts(value: boolean) {
        this.showInactiveAdditionalCostsEvent.emit(value);
    }

    onMappingAdditionalCost(costInfo: [number, string]) {
        this.mappingAdditionalCostEvent.emit(costInfo);
    }

    onEditAdditionalCost(costId: number) {
        this.editAdditionalCostEvent.emit(costId);
    }

    onEditAdditionalCostActivity(additionalCost: AdditionalCostResponseModel) {
        if( additionalCost.active ) {
            this.costRestService.getAllReferencesByCostId(additionalCost.costId).subscribe((res) => {
                let message: string = '\n'
                if(res.responseListCategory.length > 0) {
                    message += 'This cost is still used in some categories:\n\n'
                    for (let category in res.responseListCategory) {
                        message += '• ' + res.responseListCategory[category].description + "\n"
                    }
                    message += '\n'
                }
                if (res.responseListProduct.length > 0) {
                    message += 'This cost is still used in some products:\n\n'
                    for (let product in res.responseListProduct) {
                        message += '• ' + res.responseListProduct[product].description + "\n"
                    }
                    message += '\n'
                }
                if (res.responseListPass.length > 0) {
                    message += 'This cost is still used in some passes:\n\n'
                    for (let pass in res.responseListPass) {
                        message += '• ' + res.responseListPass[pass].description + "\n"
                    }
                    message += '\n'
                }
                if (message != '\n') {
                    message += 'PLEASE CHANGE ALL COSTS MANUALLY!\n'
                    let data: YesNoDialogData = new YesNoDialogData(
                        "Cost",
                        message,
                        "Ok",
                        "Cancel",
                        "costPrimaryButton",
                        (confirmed: boolean) => {
                        }
                    );
                    this.appDialogsService.renderDialog(YesNoDialogComponent, data)
                }
            });
        }
        this.editAdditionalCostActivityEvent.emit(additionalCost.costId);
    }

    private updateAccordionActivity() {
        this.accordionItemService.changeItemActivityStatus(this.activeCostType === this.thisCostType);
    }

    private activateCostType() {
        this.activateCostTypeEvent.emit(this.activeCostType !== this.thisCostType ? this.thisCostType : null);
    }
}
