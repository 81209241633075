import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../../../shared/shared.module";
import { AddAndroidDeviceInfoRemoteCommandDialogSandbox } from "./sandbox/add-android-device-info-remote-command-dialog.sandbox";
import { AddAndroidDeviceInfoRemoteCommandDialogComponent } from "./container/add-android-device-info-remote-command-dialog.component";
import { StoreModule } from "@ngrx/store";
import { reducer, REDUCER_NAME } from "./sandbox/store/reducer";
import { AddAndroidDeviceInfoRemoteCommandDialogFormComponent } from './components/add-android-device-info-remote-command-dialog-form/add-android-device-info-remote-command-dialog-form.component';
import { ConnectMerchantAndroidDeviceInfoDialogDataDialogComponent } from "./container/merchant-account-container/connect-merchant-dialog.component";
import { ConnectMerchantAndroidDeviceInfoDialogFormComponent } from "./components/merchant-account/connect-merchant-form.component";

@NgModule({

    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        AddAndroidDeviceInfoRemoteCommandDialogSandbox
    ],
    declarations: [
        AddAndroidDeviceInfoRemoteCommandDialogComponent,
        AddAndroidDeviceInfoRemoteCommandDialogFormComponent,
        ConnectMerchantAndroidDeviceInfoDialogDataDialogComponent,
        ConnectMerchantAndroidDeviceInfoDialogFormComponent
    ],
    exports: [
        AddAndroidDeviceInfoRemoteCommandDialogComponent,
        ConnectMerchantAndroidDeviceInfoDialogDataDialogComponent
    ],
    entryComponents: [
        AddAndroidDeviceInfoRemoteCommandDialogComponent
    ]
})
export class AddAndroidDeviceInfoRemoteCommandDialogModule {
}
