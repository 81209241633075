<div class="roles-and-users-dialog-content">
    <h1 class="mb-8px">{{getHeader()}}</h1>
    <form [formGroup]="form">
        <ng-container *ngIf="type === ROLE || type === ADD_NEW_ROLE">
            <div class="cell medium-12 mb-16px">
                <app-input-label-group>
                    <label class="label">Description</label>
                    <input class="main--element input-large" placeholder="Enter Description" formControlName="description">
                </app-input-label-group>
            </div>

            <div class="cell medium-12 mb-16px">
                <app-checkbox
                    [ngClass]="'mb-8px'"
                    [labelName]="'Use Complex Password'"
                    formControlName="useComplexPassword">
                </app-checkbox>
                <app-checkbox
                    [labelName]="'Use MFA'"
                    formControlName="useMfa">
                </app-checkbox>
                <div class="flex-row">
                    <app-checkbox
                        [labelName]="'Use Login Locations'"
                        formControlName="useLoginLocations">
                    </app-checkbox>
                    <span *ngIf="!useLoginLocations" class="mt-3px ml--15px">(<span class="deactiveWarning">Disabled in System Options</span>)</span>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="type === USER || type === ADD_NEW_USER">
            <div class="cell medium-12 mb-8px">
                <app-input-label-group>
                    <label class="label">Role</label>
                    <app-dropdown class="main--element"
                                  [customClasses]="'dropdown_large'"
                                  [disabled]="!editMode"
                                  [options]="roleOptions"
                                  [preSelectedValue]="user.roleId ? user.roleId.toString() : null"
                                  formControlName="roleId"></app-dropdown>
                </app-input-label-group>
            </div>

            <div class="cell medium-12 mb-16px">
                <app-input-label-group>
                    <label class="label">Display name</label>
                    <input class="main--element input-large" placeholder="Enter Display name" formControlName="displayName">
                </app-input-label-group>
            </div>

            <div class="cell medium-12 mb-16px">
                <app-input-label-group>
                    <label class="label">Username</label>
                    <input class="main--element input-large" placeholder="Enter Username" formControlName="username">
                </app-input-label-group>
            </div>

            <div class="cell medium-12 mb-16px">
                <app-input-label-group>
                    <label class="label">Password</label>
                    <input class="main--element input-large" type="password" placeholder="Enter Password" formControlName="password">
                </app-input-label-group>
            </div>

            <div class="cell medium-12 mb-16px">
                <app-input-label-group>
                    <label class="label">Confirm Password</label>
                    <input class="main--element input-large" type="password" placeholder="Confirm Password" formControlName="passwordConfirm">
                </app-input-label-group>
            </div>

            <div class="cell medium-12 mb-16px">
                <app-input-label-group>
                    <label class="label">Email</label>
                    <input class="main--element input-large" placeholder="Enter Email Address" formControlName="email">
                </app-input-label-group>
            </div>

            <div class="cell medium-12 mb-16px">
                <app-checkbox
                    [labelName]="'Is Locked'"
                    formControlName="isLocked">
                </app-checkbox>
            </div>
        </ng-container>

        <p *ngIf="hasError" class="error">{{ errorMessage }}</p>
    </form>

    <div class="users-and-permissions-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">{{type === ROLE || type === USER ? "Update" : "Create"}}</button>
    </div>
</div>
