import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {SubmitProductAvailabilitiesData} from "./components/edit-product-availabilities-dialog-form/edit-product-availabilities-dialog-form.component";
import {EditProductAvailabilitiesDialogSandbox} from "./sandbox/edit-product-availabilities-dialog.sandbox";
import {DropdownOptionModel} from "../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {ProductAvailabilityResponseModel} from "../../../../../../../core/modules/rest/product-availability/response/product-availability-response.model";

export class EditProductAvailabilitiesDialogData extends DialogData {

    isEditMode: boolean;
    productId: number;
    productAvailabilityIds: number[];
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, isEditMode: boolean, productId: number, productAvailabilityIds: number[]) {
        super();
        this.isEditMode = isEditMode;
        this.productId = productId;
        this.productAvailabilityIds = productAvailabilityIds;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-edit-product-availabilities-dialog',
    templateUrl: './edit-product-availabilities-dialog.component.html'
})
export class EditProductAvailabilitiesDialogComponent implements OnDestroy {

    isEditMode: boolean;
    productId: number;
    productAvailabilityIds: number[];
    successCallbackFn: Function;

    productAvailabilities$: Observable<ProductAvailabilityResponseModel[]>;
    departureGroups$: Observable<DropdownOptionModel[]>;
    letterGroups$: Observable<DropdownOptionModel[]>;
    bids$: Observable<DropdownOptionModel[]>;

    constructor(@Inject(DialogData) data: EditProductAvailabilitiesDialogData, private sandbox: EditProductAvailabilitiesDialogSandbox) {

        this.isEditMode = data.isEditMode;
        this.productId = data.productId;
        this.productAvailabilityIds = data.productAvailabilityIds;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.productAvailabilityIds);

        this.productAvailabilities$ = this.sandbox.productAvailabilities$;
        this.departureGroups$ = this.sandbox.departureGroups$;
        this.letterGroups$ = this.sandbox.letterGroups$;
        this.bids$ = this.sandbox.bids$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitProductAvailabilitiesData) {
        this.sandbox.updateProductAvailabilities(data).subscribe(() => {
            this.successCallbackFn(data.productId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
