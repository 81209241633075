<div class="grid-x grid-margin-x align-middle medium-uncollapse">

    <div class="cell medium-3">
        <app-input-label-group>
            <label class="label">Product</label>
            <app-dropdown class="main--element"
                          [customClasses]="'dropdown-fix-width'"
                          [preSelectedValue]="product.productId"
                          [options]="productsForDropdown"
                          (changed)="onProductChanged($event)">
            </app-dropdown>
        </app-input-label-group>
    </div>

    <div class="cell medium-3">
        <app-input-label-group>
            <label class="label">Direction</label>
            <app-dropdown class="main--element"
                          [customClasses]="'dropdown-fix-width'"
                          [preSelectedValue]="getDirectionOfProduct()"
                          [options]="directionsForDropdown"
                          (changed)="onDirectionChanged($event)">
            </app-dropdown>
        </app-input-label-group>
    </div>

    <div class="cell medium-3">
        <app-input-label-group>
            <label class="label">From</label>
            <app-dropdown class="main--element"
                          [customClasses]="'dropdown-fix-width'"
                          [preSelectedValue]="product.pickupLocationId"
                          [loading]="product.loadingPickupLocations"
                          [options]="pickupLocationsForDropdown"
                          (changed)="onPickupLocationChanged($event)">
            </app-dropdown>
        </app-input-label-group>
    </div>

    <div class="cell medium-3">
        <app-input-label-group>
            <label class="label">To</label>
            <app-dropdown class="main--element"
                          [customClasses]="'dropdown-fix-width'"
                          [preSelectedValue]="product.dropoffLocationId"
                          [loading]="product.loadingDropoffLocations"
                          [options]="dropoffLocationsForDropdown"
                          (changed)="onDropoffLocationChanged($event)">
            </app-dropdown>
        </app-input-label-group>
    </div>
</div>

<div class="removeRowIcon_wrapper">
    <a class="icon_container removeRowIconContainer"
       (click)="deleteProductClick($event)">
        <i class="fal fa-trash-alt"></i>
    </a>
</div>
