import { catchError } from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { DialogSandbox } from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import { CustomerResponseModel } from '../../../../../../../../core/modules/rest/customer/response/customer-response.model';
import { AppDialogsService } from "../../../../../../../../core/services/app-dialogs.service";
import { Store } from "@ngrx/store";
import { CustomerRestService } from '../../../../../../../../core/modules/rest/customer/customer-rest.service';
import { RootSandbox } from "../../../../../../../../core/store/root.sandbox";
import { CreateBaseResponseModel } from "../../../../../../../../core/modules/rest/create-base-response.model";
import { CreateCustomerRequestModel } from '../../../../../../../../core/modules/rest/customer/request/create-customer-request.model';
import { HttpErrorModel } from "../../../../../../../../core/modules/rest/http-error.model";
import { UpdateCustomerRequestModel } from '../../../../../../../../core/modules/rest/customer/request/update-customer-request.model';

@Injectable()
export class EditCustomerDialogSandbox extends DialogSandbox {

    customer$: Observable<CustomerResponseModel> = this.store.select(reducer.customer_selector);

    constructor(appDialogsService: AppDialogsService,
                private customerRestService: CustomerRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadCustomer( customerId: number ) {

        this.showLoader();

        this.customerRestService.getById( customerId ).subscribe((customer: CustomerResponseModel) => {
            this.store.dispatch(new actions.SetCustomer( customer ));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    createCustomer( email: string, displayName: string, phoneNumber: string, forPlugin: boolean, isCustomerOwner: boolean ): Observable<CreateBaseResponseModel> {
        const request = new CreateCustomerRequestModel( email, displayName, phoneNumber, forPlugin, isCustomerOwner );

        return this.customerRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while creating customer", error);
            }));
    }

    updateCustomer( email: string, displayName: string, phoneNumber: string, isCustomerOwner: boolean ): Observable<any> {
        const request = new UpdateCustomerRequestModel( 1, true, email, true, displayName , phoneNumber, isCustomerOwner);
        return this.customerRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while updating customer", error);
            }));
    }
}
