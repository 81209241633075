import {Action} from "@ngrx/store";
import {BidResponseModel} from "../../../../../../../../../core/modules/rest/bid/response/bid-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Bid :: MAIN] Reset State';
export const SET_BID_ACTION = '[Dialog - Create/Edit Bid :: MAIN] Set Bid';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetBid implements Action {
    readonly type = SET_BID_ACTION;
    constructor(public bid: BidResponseModel) {}
}

export type All = ResetState | SetBid;
