import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export const REDUCER_NAME = 'dialog_activate-category';

export interface State {
    products: DropdownOptionModel[];
}

const initialState: State = {
    products: []
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const products_selector = createSelector(state_selector, (state: State) => state.products);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION:
            return initialState;

        case actions.SET_PRODUCTS_ACTION:
            return {...state, products: action.products};

        default:
            return state;
    }
}
