import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {AutoReportResponseModel} from "../../../../../../../../../core/modules/rest/reporting/general/auto-reports/response/auto-report-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export const REDUCER_NAME = 'dialog_create/edit-auto-report';

export interface State {
    currentUserEmail: string;
    favorites: DropdownOptionModel[];
    autoReport: AutoReportResponseModel;
}

const initialState: State = {
    currentUserEmail: "",
    favorites: [],
    autoReport: {} as AutoReportResponseModel
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const currentUserEmail_selector = createSelector(state_selector, (state: State) => state.currentUserEmail);
export const favorites_selector = createSelector(state_selector, (state: State) => state.favorites);
export const autoReport_selector = createSelector(state_selector, (state: State) => state.autoReport);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_CURRENT_USER_EMAIL: return { ...state, currentUserEmail: action.currentUserEmail };

        case actions.SET_FAVORITES: return { ...state, favorites: action.favorites };

        case actions.SET_AUTO_REPORT_ACTION: return { ... state, autoReport: action.autoReport };

        default: return state;
    }
}
