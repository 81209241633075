import * as actions from './actions';
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {createFeatureSelector, createSelector} from "@ngrx/store";

export const REDUCER_NAME = 'dialog_create/edit-email-html-template';

export interface State {
    emailHtmlTemplate: EmailHtmlTemplateResponseModel;
}

export const initialState: State = {
    emailHtmlTemplate: {} as EmailHtmlTemplateResponseModel
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
export const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const emailHtmlTemplate_selector = createSelector(state_selector, (state: State) => state.emailHtmlTemplate);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_EMAIL_HTML_TEMPLATE_ACTION:  return { ... state, emailHtmlTemplate: action.emailHtmlTemplate };

        default: return state;
    }
}
