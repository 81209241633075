
import {of as observableOf, Observable} from 'rxjs';

import {map, take} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, OnInit  } from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {HeaderResponseModel, LevelEnum, ValueTypeEnum} from '../../../../../../../core/modules/rest/reporting/response/report-search-response.model';
import {OrderReportsSandbox} from '../../store/order-reports.sandbox';
import {ReportSumResponseModel} from '../../../../../../../core/modules/rest/reporting/response/report-sum-response.model';


@Component({
    selector: 'app-sum-header-item',
    templateUrl: './sum-header-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SumHeaderItemComponent implements OnInit {

    @Input() level: LevelEnum;
    @Input() header: HeaderResponseModel;

    columnSum$: Observable<string> = observableOf('');

    private decimalPipe = new DecimalPipe('en');

    private formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });

    constructor(private orderReportsSandbox: OrderReportsSandbox) {
    }

    ngOnInit() {
        this.initHeaderSum();
    }

    private initHeaderSum() {
        if (this.header.summable) {
            this.columnSum$ = this.orderReportsSandbox.getOrderReportColumnSum(this.header, this.level).pipe(
                take(1),
                map((res: ReportSumResponseModel) => this.getLabelValue(res)),);
        } else {
            this.columnSum$ = observableOf('');
        }
    }

    private getLabelValue(res: ReportSumResponseModel): string {
        if (res.sumType === ValueTypeEnum.DOLLARS) {
            return this.formatter.format(res.value);
        } else if (res.sumType === ValueTypeEnum.LONG) {
            return this.decimalPipe.transform(res.value);
        } else {
            return res.value;
        }
    }
}
