export class EditKioskConfigurationDialogFormSubmitData {

    description: string;
    pickupLocationId: number;

    constructor(description: string, pickupLocationId: number) {
        this.description = description;
        this.pickupLocationId = pickupLocationId;
    }
}
