import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {FilterItemModel} from '../../../../../model/filter-item.model';



@Component({
    selector: 'app-dynamic-filter',
    templateUrl: './dynamic-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFilterComponent implements OnInit {

    @Input() filterItem: FilterItemModel;
    @Input() hasRunOrderReportFullPermission: boolean;
    @Output() changed: EventEmitter<string[]> = new EventEmitter<string[]>();

    constructor() {
    }

    ngOnInit() {
    }

    onChanged(values: string[]) {
        this.changed.emit(values);
    }
}
