
import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {Store} from "@ngrx/store";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {DepartureGroupResponseModel} from "../../../../../../../../core/modules/rest/departure-group/response/departure-group-response.model";
import {DepartureGroupRestService} from "../../../../../../../../core/modules/rest/departure-group/departure-group-rest.service";
import {CreateDepartureGroupRequestModel} from "../../../../../../../../core/modules/rest/departure-group/request/create-departure-group-request.model";
import {UpdateDepartureGroupRequestModel} from "../../../../../../../../core/modules/rest/departure-group/request/update-departure-group-request.model";


@Injectable()
export class EditDepartureGroupDialogSandbox extends DialogSandbox {

    departureGroup$: Observable<DepartureGroupResponseModel> = this.store.select(reducer.departure_group_selector);

    constructor(appDialogsService: AppDialogsService,
                private departureGroupRestService: DepartureGroupRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadDepartureGroup(departureGroupId: number) {

        this.showLoader();

        this.departureGroupRestService.getById(departureGroupId).subscribe((departureGroup: DepartureGroupResponseModel) => {
            this.store.dispatch(new actions.SetDepartureGroup(departureGroup));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    createDepartureGroup(description: string): Observable<CreateBaseResponseModel> {
        const request = new CreateDepartureGroupRequestModel(description);

        return this.departureGroupRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating departure group', error);
            }));
    }

    updateDepartureGroup(departureGroupId: number, description: string): Observable<any> {
        const request = new UpdateDepartureGroupRequestModel(departureGroupId, description);

        return this.departureGroupRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating departure group', error);
            }));
    }
}
