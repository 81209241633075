import {NgModule} from "@angular/core";
import {AddPassDialogComponent} from './add-pass-dialog.component';
import {AddPassDialogSandbox} from "./sandbox/add-pass-dialog.sandbox";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {AddPassDialogFormComponent} from './components/add-pass-dialog-form/add-pass-dialog-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    declarations: [
        AddPassDialogComponent,
        AddPassDialogFormComponent
    ],
    providers: [
        AddPassDialogSandbox
    ],
    exports: [
        AddPassDialogComponent
    ],
    entryComponents: [
        AddPassDialogComponent
    ]
})
export class AddPassDialogModule {
}
