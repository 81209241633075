<div class="category-and-product-dialog-content">
    <h1 class="mb-8px">{{getHeader()}}</h1>
    <form class="pl-8px pr-8px" [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="order-form-item">
                    <label class="order-label">Name</label>
                    <div class="order-input-wrapper">
                        <input class="main--element input-large"
                               placeholder="Enter Schedule name"
                               formControlName="description"
                               [ngClass]="{'disabled': isDescriptionDisabled}"/>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">Direction</label>
                    <div class="order-input-wrapper">
                        <app-dropdown
                            class="main--element"
                            [customClasses]="'dropdown_large'"
                            [options]="directions"
                            [preSelectedValue]="form.value.direction"
                            formControlName="direction">
                        </app-dropdown>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">Start Date</label>
                    <div class="order-input-wrapper">
                        <app-datetimepicker
                            class="main--element"
                            [placeholderText]="'Choose Start Date'"
                            formControlName="startDate">
                        </app-datetimepicker>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">End Date</label>
                    <div class="order-input-wrapper">
                        <app-datetimepicker
                            class="main--element"
                            [placeholderText]="'Choose End Date'"
                            formControlName="endDate">
                        </app-datetimepicker>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">Time (leave blank for "All Day")</label>
                    <div class="order-input-wrapper">
                        <input class="main--element input-large" placeholder="Enter Start time" formControlName="startTime"/>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">Days of Week</label>
                    <div class="order-input-wrapper">
                        <app-multiselect-dropdown
                            class="main--element"
                            [allOptionEnabled]="true"
                            [options]="daysOfWeek"
                            [formControlName]="'daysOfWeek'">
                        </app-multiselect-dropdown>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">Quantity (leave blank for unlimited)</label>
                    <div class="order-input-wrapper">
                        <input class="main--element input-large" placeholder="Enter Quantity" formControlName="totalQuantity"/>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">Departure Group</label>
                    <div class="order-input-wrapper">
                        <app-dropdown
                            class="main--element"
                            [customClasses]="'dropdown_large'"
                            [options]="departureGroups"
                            [sort]="'ASC'"
                            [preSelectedValue]="form.value.departureGroupId"
                            formControlName="departureGroupId">
                        </app-dropdown>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">Letter Group</label>
                    <div class="order-input-wrapper">
                        <app-dropdown
                            class="main--element"
                            [customClasses]="'dropdown_large'"
                            [options]="letterGroups"
                            [preSelectedValue]="form.value.letterGroupId"
                            formControlName="letterGroupId">
                        </app-dropdown>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">Bid</label>
                    <div class="order-input-wrapper">
                        <app-dropdown
                            class="main--element"
                            [customClasses]="'dropdown_large'"
                            [options]="bids"
                            [preSelectedValue]="form.value.bidId"
                            formControlName="bidId">
                        </app-dropdown>
                    </div>
                </div>
            </div>
        </ng-container>

        <p *ngIf="hasError" class="error">{{ errorMessage }}</p>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">{{getSubmitButtonLabel()}}</button>
    </div>
</div>
