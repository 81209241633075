import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PassOrderDetailSummaryResponseModel} from "../../../../../../core/modules/rest/order-summary/response/pass-order-detail-summary-response.model";
import {EditPassOrderDetailEventDataModel} from "./pass-detail-dialog-summary/pass-detail-dialog-summary.component";

@Component({
    selector: 'app-pass-details-dialog-summary',
    templateUrl: './pass-details-dialog-summary.component.html'
})
export class PassDetailsDialogSummaryComponent {

    @Input() passOrderDetails: PassOrderDetailSummaryResponseModel[];
    @Input() hasEditPermission: boolean = false;
    @Input() hasCompletedEditPermission: boolean = false;

    @Output() passOrderDetailDataEdit: EventEmitter<EditPassOrderDetailEventDataModel> = new EventEmitter();

    passOrderDetailIdEditing: number = null;

    constructor() {
    }

    onSaveClicked(data: EditPassOrderDetailEventDataModel) {
        this.passOrderDetailIdEditing = null;
        this.passOrderDetailDataEdit.emit(data);
    }

    onCancelClick() {
        this.passOrderDetailIdEditing = null;
    }

    onEditClick(orderDetailId: number) {
        this.passOrderDetailIdEditing = orderDetailId;
    }

    isEditEnabled(passOrderDetailId: number): boolean {

        if (this.passOrderDetailIdEditing === null) {
            let passOrderDetail: PassOrderDetailSummaryResponseModel = this.passOrderDetails.find((pod) => pod.passOrderDetailId === passOrderDetailId);
            return passOrderDetail.orderStatus !== "CANCELLED" && (passOrderDetail.passOrderDetailFields !== null && passOrderDetail.passOrderDetailFields.length > 0);
        } else {
            return false;
        }
    }
}
