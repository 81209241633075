import {Action} from "@ngrx/store";
import {ProductAvailabilityResponseModel} from "../../../../../../../../../core/modules/rest/product-availability/response/product-availability-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export const RESET_STATE = '[Dialog - Edit Product Availabilities :: MAIN] Reset State';
export const SET_PRODUCT_AVAILABILITIES = '[Dialog - Edit Product Availabilities :: MAIN] Set Product Availabilities';
export const SET_DEPARTURE_GROUPS = '[Dialog - Edit Product Availabilities :: MAIN] Set Departure Groups';
export const SET_LETTER_GROUPS = '[Dialog - Edit Product Availabilities :: MAIN] Set Letter Groups';
export const SET_BIDS = '[Dialog - Edit Product Availabilities :: MAIN] Set Bids';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetProductAvailabilities implements Action {
    readonly type = SET_PRODUCT_AVAILABILITIES;

    constructor(public productAvailabilities: ProductAvailabilityResponseModel[]) {
    }
}

export class SetDepartureGroups implements Action {
    readonly type = SET_DEPARTURE_GROUPS;

    constructor(public departureGroups: DropdownOptionModel[]) {
    }
}

export class SetLetterGroups implements Action {
    readonly type = SET_LETTER_GROUPS;

    constructor(public letterGroups: DropdownOptionModel[]) {
    }
}

export class SetBids implements Action {
    readonly type = SET_BIDS;

    constructor(public bids: DropdownOptionModel[]) {
    }
}

export type All = ResetState
    | SetProductAvailabilities
    | SetDepartureGroups
    | SetLetterGroups
    | SetBids;
