import {Action} from '@ngrx/store';
import {UserInfoResponseModel} from '../../modules/rest/user/response/user-info-response.model';

export const SET_USER_INFO_ACTION = '[Root User] Set user info';
export const SET_USER_FLAG_PRINT_ORDER_ACTION = '[Root User] Set user flag print order';
export const SET_USER_FLAG_SHOW_ORDER_SUMMARY_ACTION = '[Root User] Set user flag show order summary';
export const SET_USER_FLAG_USE_TOUCH_SCREEN_LAYOUT = '[Root User] Set user flag use touch screen layout';
export const SET_USER_WEBSOCKET_NOTIFICATIONS = '[Root User] Set notifications websocket';
export const SET_USER_WEBSOCKET_ALERT_INDICATOR = '[Root User] Set alert indicator websocket';


export class SetUserInfo implements Action {
    readonly type = SET_USER_INFO_ACTION;

    constructor(public user: UserInfoResponseModel) {
    }
}

export class SetUserWebSocketNotifications implements Action {
    readonly type = SET_USER_WEBSOCKET_NOTIFICATIONS;
    constructor(public notifications: any[]) {
    }
}

export class SetUserWebSocketAlertIndicator implements Action {
    readonly type = SET_USER_WEBSOCKET_ALERT_INDICATOR;
    constructor(public alertIndicator: boolean, public notification: any) {
    }
}

export class SetUserFlagPrintOrder implements Action {
    readonly type = SET_USER_FLAG_PRINT_ORDER_ACTION;

    constructor(public printOrder: boolean) {
    }
}

export class SetUserFlagShowOrderSummary implements Action {
    readonly type = SET_USER_FLAG_SHOW_ORDER_SUMMARY_ACTION;

    constructor(public showOrderSummary: boolean) {
    }
}

export class SetUserFlagUseTouchScreenLayout implements Action {
    readonly type = SET_USER_FLAG_USE_TOUCH_SCREEN_LAYOUT

    constructor(public useTouchScreenLayout: boolean) {
    }
}

export type Actions =
    SetUserInfo
    | SetUserWebSocketNotifications
    | SetUserWebSocketAlertIndicator
    | SetUserFlagPrintOrder
    | SetUserFlagShowOrderSummary
    | SetUserFlagUseTouchScreenLayout;
