import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {PassSimpleResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";

export interface SubmitPassFlagsData {
    passId: number;
    shouldSendBarcodeQRCodeImageAttachment: boolean;
    usesPregeneratedBarcodeOnAndroid: boolean;
}


@Component({
    selector: 'app-edit-pass-flags-dialog-form',
    templateUrl: './edit-pass-flags-dialog-form.component.html'
})

export class EditPassFlagsDialogFormComponent implements OnChanges {

    @Input() pass: PassSimpleResponseModel;

    @Output() submit: EventEmitter<SubmitPassFlagsData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        this.form = new FormGroup({
            shouldSendBarcodeQRCodeImageAttachment: new FormControl(),
            usesPregeneratedBarcodeOnAndroid: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pass) {
            this.initializeFormData();
        }
    }

    onSubmit() {

        let shouldSendBarcodeQRCodeImageAttachment: boolean = this.form.value.shouldSendBarcodeQRCodeImageAttachment;
        let usesPregeneratedBarcodeOnAndroid: boolean = this.form.value.usesPregeneratedBarcodeOnAndroid;

        this.submit.emit({
            passId: this.pass.passId,
            shouldSendBarcodeQRCodeImageAttachment: shouldSendBarcodeQRCodeImageAttachment,
            usesPregeneratedBarcodeOnAndroid: usesPregeneratedBarcodeOnAndroid
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {
        this.form.patchValue({
            shouldSendBarcodeQRCodeImageAttachment: this.pass.shouldSendBarcodeQRCodeImageAttachment,
            usesPregeneratedBarcodeOnAndroid: this.pass.usesPregeneratedBarcodeOnAndroid
        });
    }
}
