import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {DropdownOptionModel} from "../dropdown/dropdown-option.model";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";


@Component({
    selector: 'app-dropdown-tag',
    templateUrl: './dropdown-tag.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownTagComponent),
            multi: true,
        }
    ]
})
export class DropdownTagComponent implements ControlValueAccessor, OnChanges {

    @Input() options: DropdownOptionModel[] = [];
    @Input() selectedOptions: string[] = [];

    @Output() optionAdded: EventEmitter<string> = new EventEmitter();
    @Output() optionRemoved: EventEmitter<string> = new EventEmitter();
    @Output() optionsChanged: EventEmitter<string[]> = new EventEmitter();

    selectedOptionsPrint: DropdownOptionModel[] = [];

    private _originalOptions: DropdownOptionModel[] = [];
    private _onChange: (val: string[]) => void;
    private _onTouch: (val: string[]) => void;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.options) {

            this._originalOptions = this.options ? Array.from(this.options) : [];

            this.selectedOptions.forEach((id) => {
                let index: number = this.options.findIndex((o) => o.value === id);
                if (index > -1) {
                    this.selectedOptionsPrint.push(this.options[index]);
                    this.options.splice(index, 1);
                }
            });
        }
    }

    onOptionSelected(id: string) {

        if (!id) {
            return;
        }

        let index: number = this.options.findIndex((o) => o.value === id);
        let option: DropdownOptionModel = this.options[index];

        this.options.splice(index, 1);
        this.selectedOptions.push(option.value);
        this.selectedOptionsPrint.push(option);

        this.options = Array.from(this.options);

        this.optionAdded.emit(option.value);
        this.optionsChanged.emit(this.selectedOptions);
        this._onChange(this.selectedOptions);
    }

    onOptionRemoved(id: string) {

        if (!id) {
            return;
        }

        let indexFromSelected: number = this.selectedOptions.findIndex((o) => o === id);
        let indexOptionFromOriginal: number = this._originalOptions.findIndex((o) => o.value === id);
        let selectedOptionFromOriginal: DropdownOptionModel = this._originalOptions[indexOptionFromOriginal];

        this.options.push(selectedOptionFromOriginal);
        this.selectedOptions.splice(indexFromSelected, 1);
        this.selectedOptionsPrint.splice(indexFromSelected, 1);

        this.options = Array.from(this.options);

        this.optionRemoved.emit(selectedOptionFromOriginal.value);
        this.optionsChanged.emit(this.selectedOptions);
        this._onChange(this.selectedOptions);
    }

    writeValue(value: string[]): void {

        if (value === null) {
            return;
        }

        this.selectedOptionsPrint = [];
        this.selectedOptions = value;
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }
}
