import {Action} from "@ngrx/store";
import {CategoryResponseModel} from "../../../../../../../../../core/modules/rest/category/response/category-response.model";

export const RESET_STATE = '[Dialog - Edit Category Basic Info :: MAIN] Reset State';
export const SET_CATEGORY = '[Dialog - Edit Category Basic Info :: MAIN] Set Category';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetCategory implements Action {
    readonly type = SET_CATEGORY;

    constructor(public category: CategoryResponseModel) {
    }
}

export type All = ResetState | SetCategory;
