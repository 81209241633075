import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {
    MultiselectDropdownOptionModel,
    OPTION_ALL
} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import {PassCostResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-cost-response.model";

export interface SubmitPassCostsData {
    passId: number;
    costIds: number[];
}


@Component({
    selector: 'app-edit-pass-costs-dialog-form',
    templateUrl: './edit-pass-costs-dialog-form.component.html'
})

export class EditPassCostsDialogFormComponent implements OnChanges {

    @Input() passId: number;
    @Input() loading: boolean;
    @Input() passAdditionalCosts: PassCostResponseModel[];
    @Input() additionalCosts: MultiselectDropdownOptionModel[];
    @Input() passIncludedCosts: PassCostResponseModel[];
    @Input() includedCosts: MultiselectDropdownOptionModel[];
    @Input() passDiscounts: PassCostResponseModel[];
    @Input() discounts: MultiselectDropdownOptionModel[];
    @Input() passAutoAppliedDiscounts: PassCostResponseModel[];
    @Input() autoAppliedDiscounts: MultiselectDropdownOptionModel[];

    @Output() submit: EventEmitter<SubmitPassCostsData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    constructor() {

        this.form = new FormGroup({
            additionalCostIds: new FormControl(),
            includedCostIds: new FormControl(),
            discountIds: new FormControl(),
            autoAppliedDiscountIds: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.passAdditionalCosts && changes.passIncludedCosts && changes.passDiscounts && changes.passAutoAppliedDiscounts) {
            this.initializeFormData();
        }
    }

    onSubmit() {

        let additionalCostIds: number[] = this.form.value.additionalCostIds.filter(acid => acid !== OPTION_ALL).map(acid => +acid);
        let includedCostIds: number[] = this.form.value.includedCostIds.filter(icid => icid !== OPTION_ALL).map(icid => +icid);
        let discountIds: number[] = this.form.value.discountIds.filter(did => did !== OPTION_ALL).map(did => +did);
        let autoAppliedDiscountIds: number[] = this.form.value.autoAppliedDiscountIds.filter(aadid => aadid !== OPTION_ALL).map(aadid => +aadid);

        let costIds: number[] = [...additionalCostIds, ...includedCostIds, ...discountIds, ...autoAppliedDiscountIds];

        this.submit.emit({
            passId: this.passId,
            costIds: costIds
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {
        let additionalCostIds: number[] = this.passAdditionalCosts.map(pac => pac.costId);
        let includedCostIds: number[] = this.passIncludedCosts.map(pic => pic.costId);
        let discountIds: number[] = this.passDiscounts.map(pd => pd.costId);
        let autoAppliedDiscountIds: number[] = this.passAutoAppliedDiscounts.map(paad => paad.costId);
        this.form.patchValue({
            additionalCostIds: additionalCostIds,
            includedCostIds: includedCostIds,
            discountIds: discountIds,
            autoAppliedDiscountIds: autoAppliedDiscountIds
        });
    }
}
