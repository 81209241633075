export class CreateCloudPaymentDeviceRequestModel {
    nmiPaymentDeviceId: string;
    description: string;
    securityKey: string;

    constructor( nmiPaymentDeviceId: string, description: string ,securityKey: string ) {
        this.nmiPaymentDeviceId = nmiPaymentDeviceId;
        this.description = description;
        this.securityKey = securityKey;
    }
}
