import * as actions from './actions';
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SaleGroupResponseModel } from '../../../../../../../../../core/modules/rest/sale-group/response/sale-group-response.model';

export const REDUCER_NAME = 'dialog_create/edit-sale-group';

export interface State {
    saleGroup: SaleGroupResponseModel;
}

const initialState: State = {
    saleGroup: {} as SaleGroupResponseModel
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const saleGroup_selector = createSelector(state_selector, (state: State) => state.saleGroup);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_SALE_GROUP_ACTION: return { ... state, saleGroup: action.saleGroup };

        default: return state;
    }
}
