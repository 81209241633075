import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { PostBillTypeResponseModel } from "./response/post-bill-type-response.model";
import { CreatePostBillTypeRequestModel } from "./request/create-post-bill-type-request.model";
import { CreateBaseResponseModel } from "../create-base-response.model";
import { UpdatePostBillTypeRequestModel } from "./request/update-post-bill-type-request.model";

@Injectable()
export class PostBillTypeRestService {

    constructor(private httpClient: HttpClient) {
    }

    getById(postBillTypeId: number): Observable<PostBillTypeResponseModel> {
        return this.httpClient.get<PostBillTypeResponseModel>(environment.server_api_url + '/api/secured/postBillType/getById/' + postBillTypeId);
    }

    getAll(): Observable<PostBillTypeResponseModel[]> {
        return this.httpClient.get<PostBillTypeResponseModel[]>(environment.server_api_url + '/api/secured/postBillType/getAll');
    }

    getPostBillTypesByCurrentUserSortedByDescription(): Observable<PostBillTypeResponseModel[]> {
        return this.httpClient.get<PostBillTypeResponseModel[]>(environment.server_api_url + '/api/secured/postBillType/getPostBillTypesByCurrentUserSortedByDescription');
    }

    create(request: CreatePostBillTypeRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/postBillType/create', request);
    }

    update(request: UpdatePostBillTypeRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/postBillType/update', request, { responseType: 'text' });
    }

    activate(postBillTypeId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/postBillType/activate', postBillTypeId, { responseType: 'text' });
    }

    deactivate(postBillTypeId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/postBillType/deactivate', postBillTypeId, { responseType: 'text' });
    }

    addToDeposit(request: UpdatePostBillTypeRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/postBillType/addToDeposit', request, {responseType: 'text'});
    }

    substractFromDeposit(request: UpdatePostBillTypeRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/postBillType/substractFromDeposit', request, {responseType: 'text'});
    }
}
