import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {CommissionResponseModel} from "../../../../../../../../../core/modules/rest/commission/response/commission-response.model";

export const REDUCER_NAME = 'dialog_create/edit-commission';

export interface State {
    commission: CommissionResponseModel;
}

const initialState: State = {
    commission: {} as CommissionResponseModel
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const commission_selector = createSelector(state_selector, (state: State) => state.commission);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_COMMISSION_ACTION: return { ... state, commission: action.commission };

        default: return state;
    }
}
