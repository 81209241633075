<mat-tree
    class="mat-tree-checkbox"
    [dataSource]="dataSource"
    [treeControl]="treeControl"
>
    <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodeToggle
        matTreeNodePadding
    >
        <button mat-icon-button disabled></button>
        <mat-checkbox
            class="checklist-leaf-node mat-checkbox"
            [checked]="checklistSelection.isSelected(node)"
            (change)="todoLeafItemSelectionToggle(node)"
        >
            {{ node.item }}
        </mat-checkbox>
        <ng-container *ngIf="itemType != 'ROLE'">
            <div
                *ngIf="node.isChecked == node.roleActive"
                class="user-inheritance-indicator"
                title="Inherited from role"
            >
                <i class="fas fa-info"></i>
            </div>
        </ng-container>
    </mat-tree-node>

    <mat-tree-node
        class="mat-tree-node-def"
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
    >
        <button
            class="mat-tree-child-node cursorAuto"
            mat-icon-button
            [attr.aria-label]="'Toggle ' + node.item"
            (click)="toggleNode(node)"
        >
            <mat-icon
                matTreeNodeToggle
                *ngIf="node.children.length !== 0"
                class="cursorPointer"
                [class]="
                    treeControl.isExpanded(node)
                        ? 'fas fa-chevron-down'
                        : 'fas fa-chevron-right'
                "
                (click)="toggleNode(node)"
            >
            </mat-icon>
        </button>
        <mat-checkbox
            class="mat-checkbox"
            [checked]="
                descendantsAllSelected(node) || !descendantsAllUnselected(node)
            "
            [indeterminate]="descendantsPartiallySelected(node)"
            (change)="todoItemSelectionToggle(node)"
            >{{ node.item }}
        </mat-checkbox>
        <ng-container *ngIf="itemType != 'ROLE' && itemType != 'CUSTOMER'">
                <div
                    *ngIf="node.isChecked == node.roleActive"
                    class="user-inheritance-indicator"
                    title="Inherited from role"
                >
                    <i class="fas fa-info"></i>
                </div>
                <div
                    class="user-inheritance-action-button"
                    (click)="inheritFromRole(node)"
                >
                    Inherit from role
                </div>
        </ng-container>
    </mat-tree-node>
</mat-tree>
