
import {catchError, mergeMap} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {DialogSandbox} from "../../../services/dialog.sandbox";
import {AppDialogsService} from "../../../../../core/services/app-dialogs.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {FavoriteResponseModel} from "../../../../../core/modules/rest/favorite/response/favorite-response.model";
import {FavoriteRestService} from "../../../../../core/modules/rest/favorite/favorite-rest.service";
import {FavoriteDescriptionUniqueRequestModel} from "../../../../../core/modules/rest/reporting/request/favorite-description-unique-request.model";
import {RootSandbox} from "../../../../../core/store/root.sandbox";
import {OrderReportsSandbox} from "../../../../../modules/secured/reports/general/order-reports/store/order-reports.sandbox";
import {CreateBaseResponseModel} from "../../../../../core/modules/rest/create-base-response.model";
import {ReportSearchRequestModel} from "../../../../../core/modules/rest/reporting/request/report-search-request.model";
import {CreateFavoriteRequestModel} from "../../../../../core/modules/rest/favorite/request/create-favorite-request.model";
import {HttpErrorModel} from "../../../../../core/modules/rest/http-error.model";
import {UpdateFavoriteRequestModel} from "../../../../../core/modules/rest/favorite/request/update-favorite-request.model";
import {select, Store} from "@ngrx/store";
import {FavoriteTypeEnum} from "../../../../enums/favorite-type.enum";
import {CommissionReportSandbox} from "../../../../../modules/secured/reports/financial/commission-report/sandbox/commission-report.sandbox";
import {DriverManifestReportSandbox} from "../../../../../modules/secured/reports/operational/driver-manifest-report/sandbox/driver-manifest-report.sandbox";

@Injectable()
export class EditFavoriteDialogSandbox extends DialogSandbox {

    favorite$: Observable<FavoriteResponseModel> = this.store.pipe(select(reducer.favorite_selector));

    descriptionAvailable$: Observable<boolean> = this.store.pipe(select(reducer.descriptionAvailable_selector));

    constructor(appDialogsService: AppDialogsService,
                private store: Store<reducer.State>,
                private favoriteRestService: FavoriteRestService,
                private rootSandbox: RootSandbox,
                private orderReportsSandbox: OrderReportsSandbox,
                private commissionReportSandbox: CommissionReportSandbox,
                private driverManifestReportSandbox: DriverManifestReportSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadFavorite(favoriteId: number) {

        this.favoriteRestService.getById(favoriteId).subscribe((favorite: FavoriteResponseModel) => {
            this.store.dispatch(new actions.SetFavorite(favorite));
        });
    }

    isFavoriteDescriptionUnique(value: string) {

        const request: FavoriteDescriptionUniqueRequestModel = new FavoriteDescriptionUniqueRequestModel(value);

        this.favoriteRestService.isFavoriteDescriptionUnique(request).subscribe((available: boolean) => {
            this.store.dispatch(new actions.SetDescriptionAvailable(available));
        });
    }

    createFavorite(favoriteDescription: string, groupDescription: string, favoriteType: FavoriteTypeEnum): Observable<CreateBaseResponseModel> {

        let result: Observable<any>;

        if (favoriteType === FavoriteTypeEnum.ORDER_REPORTS) {
            result = this.orderReportsSandbox.createReportSearchRequest(null, null, true).pipe(
                mergeMap((search: { searchRequest: ReportSearchRequestModel, groupSearch: boolean }) => {
                    const createFavoriteRequest: CreateFavoriteRequestModel = new CreateFavoriteRequestModel(favoriteDescription, groupDescription, favoriteType, JSON.stringify(search.searchRequest));
                    return this.favoriteRestService.create(createFavoriteRequest).pipe(
                        catchError((error: HttpErrorModel) => {
                            return this.rootSandbox.handleHttpError('error while createFavorite', error);
                        }));
                }));
        } else if (favoriteType === FavoriteTypeEnum.DRIVER_MANIFEST_REPORT) {
            result = this.driverManifestReportSandbox.createReportSearchRequest().pipe(
                mergeMap((request: ReportSearchRequestModel) => {
                    const createFavoriteRequest: CreateFavoriteRequestModel = new CreateFavoriteRequestModel(favoriteDescription, groupDescription, favoriteType, JSON.stringify(request));
                    return this.favoriteRestService.create(createFavoriteRequest).pipe(
                        catchError((error: HttpErrorModel) => {
                            return this.rootSandbox.handleHttpError('error while createFavorite', error);
                        }));
                }));
        } else if (favoriteType === FavoriteTypeEnum.COMMISSION_REPORT) {
            result = this.commissionReportSandbox.createReportSearchRequest().pipe(
                mergeMap((request: ReportSearchRequestModel) => {
                    const createFavoriteRequest: CreateFavoriteRequestModel = new CreateFavoriteRequestModel(favoriteDescription, groupDescription, favoriteType, JSON.stringify(request));
                    return this.favoriteRestService.create(createFavoriteRequest).pipe(
                        catchError((error: HttpErrorModel) => {
                            return this.rootSandbox.handleHttpError('error while createFavorite', error);
                        }));
                }));
        }

        return result;
    }

    updateFavorite(favoriteId: number, favoriteDescription: string, groupDescription: string, favoriteType: FavoriteTypeEnum): Observable<any> {

        let result: Observable<any>;

        if (favoriteType === FavoriteTypeEnum.ORDER_REPORTS) {
            result = this.orderReportsSandbox.createReportSearchRequest(null, null, true).pipe(
                mergeMap((search: { searchRequest: ReportSearchRequestModel, groupSearch: boolean }) => {
                        const updateFavoriteRequest: UpdateFavoriteRequestModel = new UpdateFavoriteRequestModel(favoriteId, favoriteDescription, groupDescription, favoriteType, JSON.stringify(search.searchRequest));
                        return this.favoriteRestService.update(updateFavoriteRequest).pipe(
                            catchError((error: HttpErrorModel) => {
                                return this.rootSandbox.handleHttpError('error while updateFavorite', error);
                            }));
                    }));
        } else if (favoriteType === FavoriteTypeEnum.COMMISSION_REPORT) {
            result = this.commissionReportSandbox.createReportSearchRequest().pipe(
                mergeMap((request: ReportSearchRequestModel) => {
                        const updateFavoriteRequest: UpdateFavoriteRequestModel = new UpdateFavoriteRequestModel(favoriteId, favoriteDescription, groupDescription, favoriteType, JSON.stringify(request));
                        return this.favoriteRestService.update(updateFavoriteRequest).pipe(
                            catchError((error: HttpErrorModel) => {
                                return this.rootSandbox.handleHttpError('error while updateFavorite', error);
                            }));
                    }));
        } else if (favoriteType === FavoriteTypeEnum.DRIVER_MANIFEST_REPORT) {
            result = this.driverManifestReportSandbox.createReportSearchRequest().pipe(
                mergeMap((request: ReportSearchRequestModel) => {
                    const updateFavoriteRequest: UpdateFavoriteRequestModel = new UpdateFavoriteRequestModel(favoriteId, favoriteDescription, groupDescription, favoriteType, JSON.stringify(request));
                    return this.favoriteRestService.update(updateFavoriteRequest).pipe(
                        catchError((error: HttpErrorModel) => {
                            return this.rootSandbox.handleHttpError('error while updateFavorite', error);
                        }));
                }));
        }

        return result;
    }
}
