<form class="form_container dialog-preview-email">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1> {{ header }} </h1>
            </div>
        </div>
    </div>

    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <div class="previewContent" [innerHTML]="emailHtmlTemplate.html">
                    </div>
                </div>
            </div>
        </div>

    </div><!-- Dialog Content :: END -->


    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12 text-center">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onClose()">
                    Close
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
