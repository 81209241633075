
import {combineLatest as observableCombineLatest, of as observableOf, Observable} from 'rxjs';

import {catchError, take} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {LocationListRestService} from "../../../../../../../../core/modules/rest/location-list/location-list-rest.service";
import {LocationListFullResponseModel} from "../../../../../../../../core/modules/rest/location-list/response/location-list-full-response.model";
import {Store} from "@ngrx/store";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {CreateLocationListRequestModel} from "../../../../../../../../core/modules/rest/location-list/request/create-location-list-request.model";
import {UpdateLocationListRequestModel} from "../../../../../../../../core/modules/rest/location-list/request/update-location-list-request.model";
import {CreateUpdateLocationListItemRequestModel} from "../../../../../../../../core/modules/rest/location-list/request/create-update-location-list-item-request.model";
import {Injectable} from "@angular/core";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {LocationListFormSubmitDataModel} from "../model/location-list-form-submit-data.model";
import {LocationsResponseModel} from "../../../../../../../../core/modules/rest/location/response/locations-response.model";
import {LocationRestService} from "../../../../../../../../core/modules/rest/location/location-rest.service";
import {DropdownOptionModel} from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

@Injectable()
export class EditLocationListDialogSandbox extends DialogSandbox {

    locationList$: Observable<LocationListFullResponseModel> = this.store.select(reducer.locationList_selector);
    allLocations$: Observable<DropdownOptionModel[]> = this.store.select(reducer.allLocations_selector);

    constructor(appDialogsService: AppDialogsService,
                private locationListRestService: LocationListRestService,
                private locationRestService: LocationRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(locationListId: number) {

        this.showLoader();

        // Check whether add or edit mode
        let getLocationList$: Observable<LocationListFullResponseModel> = observableOf(null);
        if (locationListId && !isNaN(locationListId)) {
            getLocationList$ = this.locationListRestService.getLocationListFullById(locationListId);
        }

        observableCombineLatest(getLocationList$, this.locationRestService.getAllActive()).pipe(
            take(1))
            .subscribe(([locationList, locations]: [LocationListFullResponseModel, LocationsResponseModel]) => {
                this.store.dispatch(new actions.SetLocationList(locationList));
                this.store.dispatch(new actions.SetAllLocations(locations.locations.map(l => new DropdownOptionModel(l.description, l.locationId.toString()))));
                this.hideLoader();
            },
            () => this.hideLoader());
    }

    createLocationList(data: LocationListFormSubmitDataModel): Observable<CreateBaseResponseModel> {
        const locationListItemRequests = data.locationListItems.map(lli => new CreateUpdateLocationListItemRequestModel(lli.locationId, lli.offset, lli.isCustomLocation));
        const request = new CreateLocationListRequestModel(data.description, locationListItemRequests);

        return this.locationListRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating location list', error);
            }));
    }

    updateLocationList(locationListId: number, data: LocationListFormSubmitDataModel): Observable<any> {
        const locationListItemRequests = data.locationListItems.map(lli => new CreateUpdateLocationListItemRequestModel(lli.locationId, lli.offset, lli.isCustomLocation));
        const request = new UpdateLocationListRequestModel(locationListId, data.description, locationListItemRequests);

        return this.locationListRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating location list', error);
            }));
    }
}
