import {NgModule} from "@angular/core";
import {PrintOrderDialogComponent} from './print-order-dialog.component';
import {PrintOrderDialogSandbox} from "./print-order-dialog.sandbox";
import {SharedModule} from "../../../shared.module";

@NgModule({
    providers: [
        PrintOrderDialogSandbox
    ],
    declarations: [
        PrintOrderDialogComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        PrintOrderDialogComponent
    ],
    entryComponents: [
        PrintOrderDialogComponent
    ]
})
export class PrintOrderDialogModule {

}
