<div class="component-item-wrapper">
    <div class="component-input-wrapper without-label">
        <app-dropdown
            [options]="options"
            (changed)="onOptionSelected($event)">
        </app-dropdown>
    </div>
</div>
<div class="component-item-wrapper">
    <div class="dropdown-tag-wrapper scrollable-content">
        <div *ngFor="let selectedOption of selectedOptionsPrint" class="login-location">
            <span>{{selectedOption.label}}</span>
            <span (click)="onOptionRemoved(selectedOption.value)" class="closeButton closeButtonPosition">
                <i class="fal fa-times"></i>
            </span>
        </div>
    </div>
</div>
