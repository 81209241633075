import { ShoppingCartPassTierRequestModel } from "./shopping-cart-pass-tier-request.model";
import { ShoppingCartPassCardRequestModel } from "./shopping-cart-pass-card-request.model";
import { ShoppingCartCustomFieldRequestModel } from "./shopping-cart-custom-field-request.model";

export class ShoppingCartPassRequestModel {
    passId: number;
    pickupLocationId: number;
    dropoffLocationId: number;

    passTiers: ShoppingCartPassTierRequestModel[];
    passCards: ShoppingCartPassCardRequestModel[];
    customFields: ShoppingCartCustomFieldRequestModel[];
    discountCodes: string[];

    constructor() {
    }
}
