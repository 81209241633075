import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export interface SubmitProductBasicInfoData {
    productId: number;
    categoryId: number;
    description: string;
    descriptionRegularTrip: string;
    descriptionReturnTrip: string;
    details: string;
    detailsHtml: string;
    note: string;
    emails: string;
}


@Component({
    selector: 'app-edit-product-basic-info-dialog-form',
    templateUrl: './edit-product-basic-info-dialog-form.component.html'
})

export class EditProductBasicInfoDialogFormComponent implements OnChanges {

    @Input() product: ProductSimpleResponseModel;
    @Input() categories: DropdownOptionModel[];

    @Output() submit: EventEmitter<SubmitProductBasicInfoData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        this.form = new FormGroup({
            categoryId: new FormControl(),
            description: new FormControl(),
            descriptionRegularTrip: new FormControl(),
            descriptionReturnTrip: new FormControl(),
            details: new FormControl(),
            detailsHtml: new FormControl(),
            note: new FormControl(),
            emails: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.product) {
            this.initializeFormData();
        }
    }

    onSubmit() {

        let categoryId: number = this.form.value.categoryId ? +this.form.value.categoryId : null;
        let description: string = this.form.value.description;
        let descriptionRegularTrip: string = this.form.value.descriptionRegularTrip;
        let descriptionReturnTrip: string = this.form.value.descriptionReturnTrip;
        let details: string = this.form.value.details;
        let detailsHtml: string = this.form.value.detailsHtml;
        let note: string = this.form.value.note;
        let emails: string = this.form.value.emails;

        if (!categoryId) {
            this.hasError = true;
            this.errorMessage = "Please choose category";
            return;
        }

        if (!description) {
            this.hasError = true;
            this.errorMessage = "Please enter product name";
            return;
        }

        if (this.product.isRoundTripProduct) {
            if (!descriptionRegularTrip) {
                this.hasError = true;
                this.errorMessage = "Please enter product's outbound trip name";
                return;
            }

            if (!descriptionReturnTrip) {
                this.hasError = true;
                this.errorMessage = "Please enter product's return trip name";
                return;
            }
        }

        this.submit.emit({
            productId: this.product.productId,
            categoryId: categoryId,
            description: description,
            descriptionRegularTrip: descriptionRegularTrip,
            descriptionReturnTrip: descriptionReturnTrip,
            details: details,
            detailsHtml: detailsHtml,
            note: note,
            emails: emails
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {
        this.form.patchValue({
            categoryId: this.product.categoryId,
            description: this.product.description,
            descriptionRegularTrip: this.product.descriptionRegularTrip,
            descriptionReturnTrip: this.product.descriptionReturnTrip,
            details: this.product.details,
            detailsHtml: this.product.detailsHtml,
            note: this.product.note,
            emails: this.product.emails
        });
    }
}
