import { ShoppingCartCustomFieldRequestModel } from "../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-custom-field-request.model";
import { ShoppingCartProductTierRequestModel } from "../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-product-tier-request.model";
import { ShoppingCartTicketRequestModel } from "../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-ticket-request.model";
import { ShoppingCartProductRequestModel } from "../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-product-request.model";
import { UUID } from "angular2-uuid";
import { ShoppingCartPassTierRequestModel } from "../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-pass-tier-request.model";
import { ShoppingCartPassCardRequestModel } from "../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-pass-card-request.model";
import { ShoppingCartPassRequestModel } from "../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-pass-request.model";
import { TemplateItemResponseModel } from "../../../../../../core/modules/rest/template/response/template-item-response.model";
import { CustomFieldScopeEnum } from "../../../../../../shared/enums/custom-field-scope.enum";

export class ShoppingCartRequestHelper {

    static createShoppingCartProductRequest(productId: number, isRoundTrip: boolean, is1stLegReturnTrip: boolean, pickupLocationId1stLeg: number, customPickupLocationDescription1stLeg: string, customPickupLocationLatitude1stLeg: number, customPickupLocationLongitude1stLeg: number, dropoffLocationId1stLeg: number, customDropoffLocationDescription1stLeg: string, customDropoffLocationLatitude1stLeg: number, customDropoffLocationLongitude1stLeg: number, dateInt1stLeg: number, productAvailabilityId1stLeg: number, customFields1stLeg: ShoppingCartCustomFieldRequestModel[], pickupLocationId2ndLeg: number, customPickupLocationDescription2ndLeg: string, customPickupLocationLatitude2ndLeg: number, customPickupLocationLongitude2ndLeg: number, dropoffLocationId2ndLeg: number, customDropoffLocationDescription2ndLeg: string, customDropoffLocationLatitude2ndLeg: number, customDropoffLocationLongitude2ndLeg: number, dateInt2ndLeg: number, productAvailabilityId2ndLeg: number, customFields2ndLeg: ShoppingCartCustomFieldRequestModel[], tiers: ShoppingCartProductTierRequestModel[], tickets: ShoppingCartTicketRequestModel[], discountCodes: string[]): ShoppingCartProductRequestModel[] {

        let firstLeg: ShoppingCartProductRequestModel = new ShoppingCartProductRequestModel();

        firstLeg.productId = productId;

        firstLeg.isRoundTrip = isRoundTrip;
        firstLeg.isReturnTrip = is1stLegReturnTrip;

        firstLeg.pickupLocationId = pickupLocationId1stLeg != null ? pickupLocationId1stLeg : 0;
        firstLeg.customPickupLocationDescription = customPickupLocationDescription1stLeg;
        firstLeg.customPickupLocationLatitude = customPickupLocationLatitude1stLeg;
        firstLeg.customPickupLocationLongitude = customPickupLocationLongitude1stLeg;

        firstLeg.dropoffLocationId = dropoffLocationId1stLeg != null ? dropoffLocationId1stLeg : 0;
        firstLeg.customDropoffLocationDescription = customDropoffLocationDescription1stLeg;
        firstLeg.customDropoffLocationLatitude = customDropoffLocationLatitude1stLeg;
        firstLeg.customDropoffLocationLongitude = customDropoffLocationLongitude1stLeg;

        firstLeg.productAvailabilityId = productAvailabilityId1stLeg;
        firstLeg.dateInt = dateInt1stLeg;

        firstLeg.productTiers = tiers;

        firstLeg.tickets = tickets;
        firstLeg.customFields = customFields1stLeg;
        firstLeg.discountCodes = discountCodes;

        firstLeg.parentGuid = null;
        firstLeg.childGuid = null;

        let secondLeg: ShoppingCartProductRequestModel = null;

        if (isRoundTrip) {

            secondLeg = new ShoppingCartProductRequestModel();

            secondLeg.productId = productId;
            secondLeg.productAvailabilityId = productAvailabilityId2ndLeg;
            secondLeg.dateInt = dateInt2ndLeg;

            secondLeg.pickupLocationId = pickupLocationId2ndLeg;
            secondLeg.customPickupLocationDescription = customPickupLocationDescription2ndLeg;
            secondLeg.customPickupLocationLatitude = customPickupLocationLatitude2ndLeg;
            secondLeg.customPickupLocationLongitude = customPickupLocationLongitude2ndLeg;

            secondLeg.dropoffLocationId = dropoffLocationId2ndLeg;
            secondLeg.customDropoffLocationDescription = customDropoffLocationDescription2ndLeg;
            secondLeg.customDropoffLocationLatitude = customDropoffLocationLatitude2ndLeg;
            secondLeg.customDropoffLocationLongitude = customDropoffLocationLongitude2ndLeg;

            secondLeg.isRoundTrip = isRoundTrip;
            secondLeg.isReturnTrip = !firstLeg.isReturnTrip;

            secondLeg.productTiers = tiers;

            secondLeg.tickets = tickets;
            secondLeg.customFields = customFields2ndLeg;
            secondLeg.discountCodes = discountCodes;

            firstLeg.parentGuid = UUID.UUID();
            firstLeg.childGuid = null;

            secondLeg.parentGuid = null;
            secondLeg.childGuid = firstLeg.parentGuid;
        }

        let shoppingCartProductRequests: ShoppingCartProductRequestModel[] = [firstLeg];

        if (secondLeg !== null) {
            shoppingCartProductRequests.push(secondLeg);
        }

        return shoppingCartProductRequests;
    }

    static createShoppingCartPassRequest(passId: number, pickupLocationId: number, dropoffLocationId: number, passTiers: ShoppingCartPassTierRequestModel[], passCards: ShoppingCartPassCardRequestModel[], customFields: ShoppingCartCustomFieldRequestModel[], discountCodes: string[]): ShoppingCartPassRequestModel {

        let shoppingCartPassRequest: ShoppingCartPassRequestModel = new ShoppingCartPassRequestModel();

        shoppingCartPassRequest.passId = passId;
        shoppingCartPassRequest.pickupLocationId = pickupLocationId;
        shoppingCartPassRequest.dropoffLocationId = dropoffLocationId;

        shoppingCartPassRequest.passTiers = passTiers;

        shoppingCartPassRequest.passCards = passCards;
        shoppingCartPassRequest.customFields = customFields;
        shoppingCartPassRequest.discountCodes = discountCodes;

        return shoppingCartPassRequest;
    }

    static createShoppingCartCustomFieldRequests(tierCount: number, templateItems: TemplateItemResponseModel[]): ShoppingCartCustomFieldRequestModel[] {

        let customFields: ShoppingCartCustomFieldRequestModel[] = templateItems
            .filter((ti: TemplateItemResponseModel) => ti.fieldScope === CustomFieldScopeEnum.ONCE_PER_PRODUCT)
            .map((ti: TemplateItemResponseModel) => {
                return new ShoppingCartCustomFieldRequestModel(ti.templateItemId, null, 0);
            });

        templateItems
            .filter((ti: TemplateItemResponseModel) => ti.fieldScope === CustomFieldScopeEnum.ONCE_PER_TICKET)
            .forEach((ti: TemplateItemResponseModel) => {

                let i = 1;
                while (i <= tierCount) {
                    let cf: ShoppingCartCustomFieldRequestModel = new ShoppingCartCustomFieldRequestModel(ti.templateItemId, null, i);
                    customFields.push(cf);
                    i++;
                }
            });

        return customFields;
    }
}
