<app-abstract-dialog-container>
    <app-edit-pass-pricing-date-ranges-dialog-form
        [isEditMode]="isEditMode"
        [passId]="passId"
        [pricingDateRanges]="pricingDateRanges$ | async"
        [pickupLocations]="pickupLocations$ | async"
        [dropoffLocations]="dropoffLocations$ | async"
        [tiers]="tiers$ | async"
        (submit)="onSubmit($event)"
        (cancel)="onCancel()">
    </app-edit-pass-pricing-date-ranges-dialog-form>
</app-abstract-dialog-container>
