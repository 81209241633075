<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? 'Edit Auto-Report' : 'Add New Auto-Report' }}</h1>
            </div>
        </div>
    </div>

    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Favorite</label>
                        <app-dropdown class="main--element"
                                      [customClasses]="'dropdown_large'"
                                      [options]="favorites"
                                      [sort]="'ASC'"
                                      [preSelectedValue]="autoReport && autoReport.favoriteId ? autoReport.favoriteId.toString() : null"
                                      formControlName="favoriteId"></app-dropdown>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Description</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Name"
                               formControlName="description">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Email</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Email"
                               formControlName="email">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-4">
                    <app-input-label-group>
                        <label class="label">Frequency</label>
                        <app-dropdown class="main--element"
                                      [customClasses]="'dropdown_m'"
                                      [options]="nthOptions"
                                      [preSelectedValue]="autoReport && autoReport.nth ? autoReport.nth.toString() : null"
                                      formControlName="nth"></app-dropdown>
                    </app-input-label-group>
                </div>
                <div class="cell medium-4">
                    <app-input-label-group>
                        <label class="label">day</label>
                        <app-dropdown class="main--element"
                                      [customClasses]="'dropdown_m'"
                                      [options]="dayOfWeekOptions"
                                      [preSelectedValue]="autoReport && autoReport.dayOfWeek ? autoReport.dayOfWeek.toString() : null"
                                      formControlName="dayOfWeek"></app-dropdown>
                    </app-input-label-group>
                </div>
                <div class="cell medium-4">
                    <app-input-label-group>
                        <label class="label">at</label>
                        <app-dropdown class="main--element"
                                      [customClasses]="'dropdown_m'"
                                      [options]="hourOptions"
                                      [preSelectedValue]="autoReport && autoReport.hour !== null && autoReport.hour !== undefined ? autoReport.hour.toString() : null"
                                      formControlName="hour"></app-dropdown>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-6">
                    <label for="override-report-start-date-datepicker" class="label">Start Date</label>
                    <div class="datepicker_wrapper single-day-datepicker">
                        <app-datetimepicker
                            id="override-report-start-date-datepicker"
                            [placeholderText]="'Start Date'"
                            formControlName="startDate">
                        </app-datetimepicker>
                    </div>
                </div>

                <div class="cell medium-6">
                    <label for="override-report-end-date-datepicker" class="label">End Date</label>
                    <div class="datepicker_wrapper single-day-datepicker">
                        <app-datetimepicker
                            id="override-report-end-date-datepicker"
                            [placeholderText]="'End Date'"
                            [opensPosition]="'left'"
                            formControlName="endDate">
                        </app-datetimepicker>
                    </div>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Export Type</label>
                        <app-dropdown class="main--element"
                                      [customClasses]="'dropdown_large'"
                                      [options]="exportTypeOptions"
                                      [preSelectedValue]="autoReport && autoReport.exportType ? autoReport.exportType : null"
                                      formControlName="exportType"></app-dropdown>
                    </app-input-label-group>
                </div>
            </div>
        </div>
    </div><!-- Dialog Content :: END -->

    <p *ngIf="hasError" class="error mt-16px">{{ errorMessage }}</p>

    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>


            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
