import {Action} from "@ngrx/store";
import {HolidayResponseModel} from "../../../../../../../../../core/modules/rest/holiday/response/holiday-response.model";
import {CategoryResponseModel} from "../../../../../../../../../core/modules/rest/category/response/category-response.model";
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {ProductAvailabilityHolidayMappingResponseModel} from "../../../../../../../../../core/modules/rest/product-availability/response/product-availability-holiday-mapping-response.model";

export const RESET_STATE_ACTION = '[Dialog - Mapping Holiday :: MAIN] Reset State';

export const SET_HOLIDAY_ACTION = '[Dialog - Mapping Holiday :: MAIN] Set Holiday Info';

export const SET_CATEGORIES_ACTION = '[Dialog - Mapping Holiday :: MAIN] Set Category Info';
export const SET_CATEGORIES_LOADING_ACTION = '[Dialog - Mapping Holiday :: MAIN] Set Category Loading Info';

export const SET_PRODUCTS_ACTION = '[Dialog - Mapping Holiday :: MAIN] Set Products Info';
export const SET_PRODUCTS_LOADING_ACTION = '[Dialog - Mapping Holiday :: MAIN] Set Products Loading Info';

export const SET_PRODUCT_AVAILABILITY_HM_ITEMS_ACTION = '[Dialog - Mapping Holiday :: MAIN] Set Product Availability HM Items Info';
export const SET_PRODUCT_AVAILABILITY_HM_ITEMS_LOADING_ACTION = '[Dialog - Mapping Holiday :: MAIN] Set Product Availability HM Items Loading Info';

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetHoliday implements Action {
    readonly type = SET_HOLIDAY_ACTION;
    constructor(public holiday: HolidayResponseModel) {}
}

export class SetCategories implements Action {
    readonly type = SET_CATEGORIES_ACTION;
    constructor(public categories: CategoryResponseModel[]) {}
}

export class SetCategoriesLoading implements Action {
    readonly type = SET_CATEGORIES_LOADING_ACTION;
    constructor(public loading: boolean) {}
}

export class SetProducts implements Action {
    readonly type = SET_PRODUCTS_ACTION;
    constructor(public products: ProductSimpleResponseModel[]) {}
}

export class SetProductsLoading implements Action {
    readonly type = SET_PRODUCTS_LOADING_ACTION;
    constructor(public loading: boolean) {}
}

export class SetProductAvailabilityHmItems implements Action {
    readonly type = SET_PRODUCT_AVAILABILITY_HM_ITEMS_ACTION;
    constructor(public productAvailabilityItemsHolidayMappingItems: ProductAvailabilityHolidayMappingResponseModel[]) {}
}

export class SetProductAvailabilityHmItemsLoading implements Action {
    readonly type = SET_PRODUCT_AVAILABILITY_HM_ITEMS_LOADING_ACTION;
    constructor(public loading: boolean) {}
}

export type All = ResetState
    | SetHoliday
    | SetCategories
    | SetCategoriesLoading
    | SetProducts
    | SetProductsLoading
    | SetProductAvailabilityHmItems
    | SetProductAvailabilityHmItemsLoading;
