<div class="dragNdropCard draggableItem expressionFilter"
     [ngClass]="{'dragItem, dragItemPlaceholder': isDragged}"
     draggable="true" [dragData]="getDragData()"
     [dragEnabled]="hasRunOrderReportFullPermission"
     (onDragStart)="onDragStart()" (onDragEnd)="onDragEnd()"
     [dragImage]=" !isIE ? dragImage : null"
     [dragTransitClass]="isIE ? 'is-ie-drag' : null">

    <div class="dragNdropCardHeader">
        <div class="icon_container"
             [ngClass]="{'disabled':!hasRunOrderReportFullPermission}">
            <img src="{{draggableIconDefault}}">
        </div>

        <h5 class="dragNdropCardLabel"> {{ expressionFilterItem.filter.name }} </h5>

        <a class="closeButton_container"
           [ngClass]="{'disabled':!hasRunOrderReportFullPermission}"
           (click)="deleteThisItem()">
            <span class="closeButton">
               <i class="fal fa-times"></i>
            </span>
        </a>
    </div>


    <div class="filterContent">
        <app-dynamic-filter
            [filterItem]="expressionFilterItem.filter"
            [hasRunOrderReportFullPermission]="hasRunOrderReportFullPermission"
            (changed)="onExpressionFilterValueChanged($event)">
        </app-dynamic-filter>
    </div>
</div>
