import {NgModule} from "@angular/core";
import {EditPassPricingDateRangesDialogSandbox} from "./sandbox/edit-pass-pricing-date-ranges-dialog.sandbox";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {EditPassPricingDateRangesDialogFormComponent} from './components/edit-pass-pricing-date-ranges-dialog-form/edit-pass-pricing-date-ranges-dialog-form.component';
import {EditPassPricingDateRangesDialogComponent} from "./edit-pass-pricing-date-ranges-dialog.component";
import {EditPassTierDialogFormComponent} from "./components/edit-pass-pricing-date-ranges-dialog-form/edit-pass-tier-dialog-form/edit-pass-tier-dialog-form.component";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    declarations: [
        EditPassPricingDateRangesDialogComponent,
        EditPassPricingDateRangesDialogFormComponent,
        EditPassTierDialogFormComponent
    ],
    providers: [
        EditPassPricingDateRangesDialogSandbox
    ],
    exports: [
        EditPassPricingDateRangesDialogComponent
    ],
    entryComponents: [
        EditPassPricingDateRangesDialogComponent
    ]
})
export class EditPassPricingDateRangesDialogModule {
}
