
import {from as observableFrom, Observable, Subscription} from 'rxjs';

import {take, tap, map} from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef  } from '@angular/core';
import {OrderReportsSandbox} from './store/order-reports.sandbox';
import {BreadcrumbItemModel} from '../../../../../shared/components/breadcrumbs/breadcrumb-item.model';
import {AppRoutesService} from '../../../../../core/services/app-routes.service';
import {ExpressionItemModel} from './model/expression-item.model';
import {ReportGroupModel} from './model/report-group.model';
import {DroppedDataI} from './model/dropped-data.model';
import {UpdateExpressionItemValueModel} from './model/update-expression-item-value.model';
import {FieldItemModel} from './model/field-item.model';
import {DroppedFieldItemModel} from './model/dropped-field-item.model';
import {MousePointerOverDropAreaService} from './service/mouse-pointer-over-drop-area.service';
import {DraggedDataI} from './model/dragged-data.model';
import {FavoriteResponseModel} from '../../../../../core/modules/rest/favorite/response/favorite-response.model';
import {CurrentSearchDataModel} from './model/current-search-data.model';
import {NgxPermissionsService} from 'ngx-permissions';
import {PermissionEnum} from '../../../../../shared/enums/permission.enum';
import {SortByModel} from '../../../../../shared/model/sort-by.model';
import {AppDialogsService} from '../../../../../core/services/app-dialogs.service';
import {
    OrderSummaryDialogComponent,
    OrderSummaryDialogData
} from '../../../../../shared/dialogs/common-dialogs/order-summary-dialog/container/order-summary-dialog.component';
import {
    EditFavoriteDialogComponent,
    EditFavoriteDialogData
} from "../../../../../shared/dialogs/common-dialogs/edit-favorite-dialog/container/edit-favorite-dialog.component";
import {
    YesNoDialogComponent,
    YesNoDialogData
} from "../../../../../shared/dialogs/common-dialogs/yes-no-dialog/container/yes-no-dialog.component";
import {FavoriteTypeEnum} from "../../../../../shared/enums/favorite-type.enum";
import { RowResponseModel } from '../../../../../core/modules/rest/reporting/response/report-search-response.model';

const SCROLL_TOP_OFFSET = 10;
const SCROLL_INTERVAL = 10;


@Component({
    selector: 'app-order-reports',
    templateUrl: './order-reports.component.html'
})
export class OrderReportsComponent implements OnInit, OnDestroy {

    @ViewChild('pageContentWrapper', { read: ViewContainerRef }) pageContentWrapper: ViewContainerRef;

    pageBreadcrumbItems: BreadcrumbItemModel[];

    disableCreateReport$: Observable<boolean>;
    reportGroups$: Observable<ReportGroupModel[]>;
    expressionItems$: Observable<ExpressionItemModel[]>;
    selectedFields$: Observable<FieldItemModel[]>;
    draggedData$: Observable<DraggedDataI>;
    selectedFavoriteId$: Observable<number>;
    listOfFavorites$: Observable<FavoriteResponseModel[]>;
    currentSearchData$: Observable<CurrentSearchDataModel>;
    reportItemsSearchText$: Observable<string>;
    activeReportGroups$: Observable<string[]>;
    areEnoughFieldsSelected$: Observable<boolean>;
    hasRunOrderReportFullPermission$: Observable<boolean>;
    hasUseFavoritesPermission$: Observable<boolean>;
    hasCreateFavoritePermission$: Observable<boolean>;
    hasEditFavoritePermission$: Observable<boolean>;
    hasRemoveFavoritePermission$: Observable<boolean>;
    showPaymentSummary$: Observable<boolean>;
    showPaymentSummaryDisabled$: Observable<boolean>;

    isSidebarFavoriteOpen: boolean = false;
    private expressionItemsOrSelectedFieldsChangedSubscription: Subscription;
    private currentSearchData: CurrentSearchDataModel;
    private rowsToShow: RowResponseModel[];
    private scrollTopInterval;

    constructor(private orderReportsSandbox: OrderReportsSandbox,
                private appDialogsService: AppDialogsService,
                private appRoutesService: AppRoutesService,
                private mouseOverDropAreaService: MousePointerOverDropAreaService,
                private permissionsService: NgxPermissionsService) {

        this.orderReportsSandbox.loadPageData();

        this.pageBreadcrumbItems = appRoutesService.createOrderReportsPageBreadcrumbs();
        this.disableCreateReport$ = this.orderReportsSandbox.canCreateOrderReport().pipe(
            map((value: boolean) => !value));
        this.reportGroups$ = orderReportsSandbox.getAllReportGroups();
        this.expressionItems$ = this.orderReportsSandbox.getExpressionItems();
        this.selectedFields$ = this.orderReportsSandbox.getSelectedFields();
        this.draggedData$ = this.orderReportsSandbox.getDraggedData();
        this.selectedFavoriteId$ = this.orderReportsSandbox.getSelectedFavoriteId();
        this.listOfFavorites$ = this.orderReportsSandbox.getAllFavoritesByUser();
        this.currentSearchData$ = this.orderReportsSandbox.getCurrentSearchData().pipe(
            tap((data: CurrentSearchDataModel) => {
                // Has sort by value changed
                if (this.currentSearchData && data && this.currentSearchData.sortBy !== data.sortBy) {
                    this.orderReportsSandbox.createReport();
                }

                this.currentSearchData = data;
                if (this.currentSearchData != null) {
                    if (this.currentSearchData.rows != null) {
                        this.rowsToShow  = JSON.parse(JSON.stringify(this.currentSearchData.rows));
                    }
                    let headersToShow = [];
                    let cellToShow = [];
                    for (let row in this.currentSearchData.rows) {
                        for (let cell in this.currentSearchData.rows[row].cellValues) {
                            let cellFormated = {
                                position: cell,
                                value: this.currentSearchData.rows[row].cellValues[cell].value
                            }
                            cellToShow.push(cellFormated)
                        }
                    }
                    var deletedCellCounter = 0;
                    for (let header in this.currentSearchData.headers) {
                        let headersData = cellToShow.filter(item => item.position === header && item.value != "");
                        if (headersData.length === 0 && this.currentSearchData.headers[header].customField) {
                            for(let rowToDelete in this.currentSearchData.rows) {
                                this.rowsToShow[rowToDelete].cellValues.splice(parseInt(header) - deletedCellCounter, 1);
                            }
                            deletedCellCounter++;
                        }
                        else {
                            headersToShow.push(this.currentSearchData.headers[header]);
                        }
                    }
                    this.currentSearchData.rowsToShow = this.rowsToShow;
                    this.currentSearchData.headersToShow = headersToShow;
                }
            }));
        this.reportItemsSearchText$ = this.orderReportsSandbox.getReportItemsSearchText();
        this.activeReportGroups$ = this.orderReportsSandbox.getActiveReportGroups();
        this.areEnoughFieldsSelected$ = this.orderReportsSandbox.areEnoughFieldsSelected();

        this.expressionItemsOrSelectedFieldsChangedSubscription = this.orderReportsSandbox.getExpressionItemsOrSelectedFieldsChanged()
            .subscribe(() => {
                this.orderReportsSandbox.resetCurrentSearch();
            });

        this.hasRunOrderReportFullPermission$ = observableFrom(permissionsService.hasPermission(PermissionEnum.RUN_ORDER_REPORT_FULL));
        this.hasUseFavoritesPermission$ = observableFrom(permissionsService.hasPermission(PermissionEnum.USE_FAVORITES));
        this.hasCreateFavoritePermission$ = observableFrom(permissionsService.hasPermission(PermissionEnum.CREATE_FAVORITE));
        this.hasEditFavoritePermission$ = observableFrom(permissionsService.hasPermission(PermissionEnum.EDIT_FAVORITE));
        this.hasRemoveFavoritePermission$ = observableFrom(permissionsService.hasPermission(PermissionEnum.REMOVE_FAVORITE));

        this.showPaymentSummary$ = this.orderReportsSandbox.getShowPaymentSummary();
        this.showPaymentSummaryDisabled$ = this.orderReportsSandbox.getShowPaymentSummaryDisabled();
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        if (this.expressionItemsOrSelectedFieldsChangedSubscription) {
            this.expressionItemsOrSelectedFieldsChangedSubscription.unsubscribe();
        }
    }

    // ----------------------------------------------------------------------
    // DRAG AND DROP - START
    // ----------------------------------------------------------------------

    onDragStart(data: DraggedDataI) {
        this.orderReportsSandbox.updateDraggingData(data);
    }

    onDragEnd() {
        this.orderReportsSandbox.updateDraggingData(null);
        this.mouseOverDropAreaService.setMousePointerOverDropArea(false);
    }

    onDroppedData(droppedData: DroppedDataI) {
        this.orderReportsSandbox.onDroppedData(droppedData);
    }

    onDeleteExpressionItem(uuid: string) {
        this.orderReportsSandbox.deleteExpressionItem(uuid);
    }

    onExpressionItemValueChanged(item: UpdateExpressionItemValueModel) {
        this.orderReportsSandbox.updateExpressionItemValue(item);
    }

    onDroppedFieldItem(data: DroppedFieldItemModel) {
        this.orderReportsSandbox.addField(data);
    }

    onDeleteFieldItem(queryFieldName: string) {
        this.orderReportsSandbox.deleteSelectedField(queryFieldName);
    }

    // ----------------------------------------------------------------------
    // DRAG AND DROP - END
    // ----------------------------------------------------------------------

    onSearchTextChanged(text: string) {
        this.orderReportsSandbox.updateReportItemsSearchText(text);
    }

    onChangeReportGroupActivityStatus(name: string) {
        this.orderReportsSandbox.changeReportGroupActivityStatus(name);
    }

    onSelectedFavoriteChanged(favoriteId: number) {
        this.orderReportsSandbox.setSelectedFavoriteId(favoriteId);
    }

    onEditFavorite(favoriteId: number) {
        this.appDialogsService.renderDialog(EditFavoriteDialogComponent, new EditFavoriteDialogData(this.onFavoritesChanged.bind(this), this.onFavoritesChanged.bind(this), favoriteId, FavoriteTypeEnum.ORDER_REPORTS));
    }

    onRemoveFavorite(favoriteId: number) {

        let data: YesNoDialogData = new YesNoDialogData(
            "Disable Favorite",
            "Are you sure you want to disable favorite?",
            "Cancel",
            "Disable",
            "danger",
            (confirm: boolean) => {
                if (confirm) {
                    this.orderReportsSandbox.deactivateFavorite(favoriteId)
                        .subscribe(() => {
                            this.onFavoritesChanged(null);
                            this.orderReportsSandbox.resetState();
                        });
                }
            }
        );

        this.appDialogsService.renderDialog(YesNoDialogComponent, data);
    }

    onFavoritesChanged(favoriteId: number) {
        this.orderReportsSandbox.loadAllOrderReportsFavoritesByUser(favoriteId);
    }

    onHeaderSort(sortBy: SortByModel) {
        this.orderReportsSandbox.updateSortBy(sortBy);
    }

    createReportClick() {
        this.orderReportsSandbox.createReport();
    }

    onSearchForNextReportItems() {
        if (this.isInfiniteScrollEnabled()) {
            this.orderReportsSandbox.searchForNextReportItems();
        }
    }

    onShowSummary(orderId: number) {
        this.appDialogsService.renderDialog(OrderSummaryDialogComponent, new OrderSummaryDialogData(orderId));
    }

    onGenerateUrlForExportToXlsx() {
        this.orderReportsSandbox.generateUrlForExportToXlsx().pipe(
            take(1))
            .subscribe(this.generateUrlForExportToXlsxSuccessCallback.bind(this));
    }

    onResetAll() {
        this.orderReportsSandbox.resetState();
    }

    onPointerNearTopEdgeEvent(value: boolean) {
        if (value) {
            this.scrollTopInterval = setInterval(() => {
                this.pageContentWrapper.element.nativeElement.scrollTop -= SCROLL_TOP_OFFSET;
            }, SCROLL_INTERVAL);
        } else {
            clearInterval(this.scrollTopInterval);
        }
    }

    onShowPaymentSummaryChanged(value: boolean) {
        this.orderReportsSandbox.updateShowPaymentSummary(value);
    }

    private generateUrlForExportToXlsxSuccessCallback(url: string) {
        window.open(url);
    }

    private isInfiniteScrollEnabled(): boolean {
        if (this.currentSearchData) {
            return this.currentSearchData.generatedReport && !this.currentSearchData.noMoreItemsForLoading && !this.currentSearchData.loadingMoreItems;
        }

        return false;
    }

    onOpenCloseSidebarFavorite() {
        this.isSidebarFavoriteOpen = !this.isSidebarFavoriteOpen;
    }
}
