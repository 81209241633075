
import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {BidRestService} from "../../../../../../../../core/modules/rest/bid/bid-rest.service";
import {Observable} from "rxjs";
import {BidResponseModel} from "../../../../../../../../core/modules/rest/bid/response/bid-response.model";
import {Store} from "@ngrx/store";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {CreateBidRequestModel} from "../../../../../../../../core/modules/rest/bid/request/create-bid-request.model";
import {UpdateBidRequestModel} from "../../../../../../../../core/modules/rest/bid/request/update-bid-request.model";
import {Injectable} from "@angular/core";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";

@Injectable()
export class EditBidDialogSandbox extends DialogSandbox {

    bid$: Observable<BidResponseModel> = this.store.select(reducer.bid_selector);

    constructor(appDialogsService: AppDialogsService,
                private bidRestService: BidRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadBid(bidId: number) {

        this.showLoader();

        this.bidRestService.getById(bidId).subscribe((bid: BidResponseModel) => {
            this.store.dispatch(new actions.SetBid(bid));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    createBid(description: string): Observable<CreateBaseResponseModel> {
        const request = new CreateBidRequestModel(description);

        return this.bidRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating bid', error);
            }));
    }

    updateBid(bidId: number, description: string): Observable<any> {
        const request = new UpdateBidRequestModel(bidId, description);

        return this.bidRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating bid', error);
            }));
    }
}
