import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SortByModel} from '../../../../../../../shared/model/sort-by.model';
import {CommissionReportCurrentSearchDataModel} from '../../model/commission-report-current-search-data.model';
import {RoleResponseModel} from '../../../../../../../core/modules/rest/role/response/role-response.model';
import {UserResponseModel} from '../../../../../../../core/modules/rest/user/response/user-response.model';
import {PassSimpleResponseModel} from "../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {ProductSimpleResponseModel} from '../../../../../../../core/modules/rest/product/response/product-simple-response.model';
import {FavoriteResponseModel} from "../../../../../../../core/modules/rest/favorite/response/favorite-response.model";
import {ReportSearchRequestModel} from "../../../../../../../core/modules/rest/reporting/request/report-search-request.model";
import {ExpressionItemModel} from "../../../../general/order-reports/model/expression-item.model";
import {findFilterItemByQueryFieldName} from "../../../../general/order-reports/model/data";
import {BaseExpressionRequestModel} from "../../../../../../../core/modules/rest/reporting/request/base-expression-request.model";
import {ExpressionItemTypeEnum} from "../../../../general/order-reports/model/expression-item-type.enum";
import {OperatorExpressionRequestModel} from "../../../../../../../core/modules/rest/reporting/request/operator-expression-request.model";
import {ExpressionOperatorModel} from "../../../../general/order-reports/model/expression-operator.model";
import {ExpressionOperatorTypeEnum} from "../../../../general/order-reports/model/expression-operator-type.enum";
import {FilterExpressionRequestModel} from "../../../../../../../core/modules/rest/reporting/request/filter-expression-request.model";
import {FilterItemModel} from "../../../../general/order-reports/model/filter-item.model";
import {ExpressionFilterModel} from "../../../../general/order-reports/model/expression-filter.model";
import {GroupExpressionRequestModel} from "../../../../../../../core/modules/rest/reporting/request/group-expression-request.model";
import {ExpressionGroupModel} from "../../../../general/order-reports/model/expression-group.model";
import * as actions from './actions';
import * as moment from "moment";

export const REDUCER_NAME = 'feature_commissionReport';

export interface State {
    loadingFavorites: boolean;
    favorites: FavoriteResponseModel[];
    selectedFavoriteId: number;
    favoriteCreatedUpdated: boolean;
    loadingRoles: boolean;
    roles: RoleResponseModel[];
    loadingUsers: boolean;
    users: UserResponseModel[];
    usersForSelectedRoles: UserResponseModel[];
    loadingPasses: boolean;
    passes: PassSimpleResponseModel[];
    loadingProducts: boolean;
    products: ProductSimpleResponseModel[];
    selectedStartDate: moment.Moment;
    selectedEndDate: moment.Moment;
    selectedRoleIds: number[];
    selectedUserIds: number[];
    selectedPassIds: number[];
    selectedProductIds: number[];
    currentSearchData: CommissionReportCurrentSearchDataModel;
    currentSearchDataSortBy: SortByModel;
}

const initialState: State = {
    loadingFavorites: false,
    favorites: [],
    selectedFavoriteId: null,
    favoriteCreatedUpdated: false,
    loadingRoles: false,
    roles: [],
    loadingUsers: false,
    users: [],
    usersForSelectedRoles: [],
    loadingPasses: false,
    passes: [],
    loadingProducts: false,
    products: [],
    selectedStartDate: moment(),
    selectedEndDate: moment(),
    selectedRoleIds: [],
    selectedUserIds: [],
    selectedPassIds: [],
    selectedProductIds: [],
    currentSearchData: null,
    currentSearchDataSortBy: null
};

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const loadingFavorites_selector = createSelector(state_selector, (state: State) => state.loadingFavorites);
export const favorites_selector = createSelector(state_selector, (state: State) => state.favorites);
export const selectedFavoriteId_selector = createSelector(state_selector, (state: State) => state.selectedFavoriteId);
export const favoriteCreatedUpdated_selector = createSelector(state_selector, (state: State) => state.favoriteCreatedUpdated);
export const loadingRoles_selector = createSelector(state_selector, (state: State) => state.loadingRoles);
export const roles_selector = createSelector(state_selector, (state: State) => state.roles);
export const loadingUsers_selector = createSelector(state_selector, (state: State) => state.loadingUsers);
export const users_selector = createSelector(state_selector, (state: State) => state.users);
export const usersForSelectedRoles_selector = createSelector(state_selector, (state: State) => state.usersForSelectedRoles);
export const loadingPasses_selector = createSelector(state_selector, (state: State) => state.loadingPasses);
export const passes_selector = createSelector(state_selector, (state: State) => state.passes);
export const loadingProducts_selector = createSelector(state_selector, (state: State) => state.loadingProducts);
export const products_selector = createSelector(state_selector, (state: State) => state.products);
export const selectedStartDate_selector = createSelector(state_selector, (state: State) => state.selectedStartDate);
export const selectedEndDate_selector = createSelector(state_selector, (state: State) => state.selectedEndDate);
export const selectedRoleIds_selector = createSelector(state_selector, (state: State) => state.selectedRoleIds);
export const selectedUserIds_selector = createSelector(state_selector, (state: State) => state.selectedUserIds);
export const selectedPassIds_selector = createSelector(state_selector, (state: State) => state.selectedPassIds);
export const selectedProductIds_selector = createSelector(state_selector, (state: State) => state.selectedProductIds);
export const currentSearchData_selector = createSelector(state_selector, (state: State) => state.currentSearchData);
export const currentSearchDataSortBy_selector = createSelector(state_selector, (state: State) => state.currentSearchDataSortBy);

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.UPDATE_FAVORITES_LOADER_ACTION:
            return {
                ...state,
                loadingFavorites: action.payload.loadingFavorites
            };
        case actions.UPDATE_FAVORITES_ACTION:
            if (action.payload.favoriteId) {
                return {
                    ...state,
                    favorites: action.payload.favorites,
                    selectedFavoriteId: action.payload.favoriteId,
                    favoriteCreatedUpdated: action.payload.favoriteCreatedUpdated
                };
            } else {
                return {
                    ...state,
                    favorites: action.payload.favorites,
                    favoriteCreatedUpdated: false
                };
            }
        case actions.UPDATE_SELECTED_FAVORITE_ID_ACTION:
            return handleUpdateSelectedFavoriteAction(state, action);
        case actions.UPDATE_ROLES_LOADER_ACTION:
            return {
                ...state,
                loadingRoles: action.payload.loadingRoles
            };
        case actions.UPDATE_ROLES_ACTION:
            return {
                ...state,
                roles: action.payload.roles
            };
        case actions.UPDATE_USERS_LOADER_ACTION:
            return {
                ...state,
                loadingUsers: action.payload.loadingUsers
            };
        case actions.UPDATE_USERS_ACTION:
            return {
                ...state,
                users: action.payload.users
            };
        case actions.UPDATE_USERS_FOR_SELECTED_ROLES_ACTION:
            return {
                ...state,
                usersForSelectedRoles: action.payload.usersForSelectedRoles
            };
        case actions.UPDATE_PASSES_LOADER_ACTION:
            return {
                ...state,
                loadingPasses: action.payload.loadingPasses
            };
        case actions.UPDATE_PASSES_ACTION:
            return {
                ...state,
                passes: action.payload.passes
            };
        case actions.UPDATE_PRODUCTS_LOADER_ACTION:
            return {
                ...state,
                loadingProducts: action.payload.loadingProducts
            };
        case actions.UPDATE_PRODUCTS_ACTION:
            return {
                ...state,
                products: action.payload.products
            };
        case actions.UPDATE_SELECTED_START_DATE_ACTION:
            return {
                ...state,
                selectedStartDate: action.payload.selectedStartDate
            };
        case actions.UPDATE_SELECTED_END_DATE_ACTION:
            return {
                ...state,
                selectedEndDate: action.payload.selectedEndDate
            };
        case actions.UPDATE_SELECTED_ROLE_IDS_ACTION:
            return {
                ...state,
                selectedRoleIds: action.payload.selectedRoleIds
            };
        case actions.UPDATE_SELECTED_USER_IDS_ACTION:
            return {
                ...state,
                selectedUserIds: action.payload.selectedUserIds
            };
        case actions.UPDATE_SELECTED_PASS_IDS_ACTION:
            return {
                ...state,
                selectedPassIds: action.payload.selectedPassIds
            };
        case actions.UPDATE_SELECTED_PRODUCT_IDS_ACTION:
            return {
                ...state,
                selectedProductIds: action.payload.selectedProductIds
            };
        case actions.INIT_SEARCH_ACTION:
            return {
                ...state,
                currentSearchData: {
                    loadingReport: true,
                    loadingMoreItems: false,
                    noMoreItemsForLoading: false,
                    totalNumberOfResults: 0,
                    commissionReportItems: []
                }
            };
        case actions.UPDATE_CURRENT_SEARCH_DATA_ACTION:
            return {
                ...state,
                currentSearchData: {
                    ...state.currentSearchData,
                    loadingReport: false,
                    totalNumberOfResults: action.payload.response.totalNumberOfResults,
                    commissionReportItems: [...action.payload.response.commissionReportItems]
                }
            };
        case actions.UPDATE_CURRENT_SEARCH_DATA_SORT_BY_ACTION:
            return {
                ...state,
                currentSearchDataSortBy: action.payload.sortBy
            };
        case actions.CLEAR_SEARCH_DATA_ACTION:
            return {
                ...state,
                currentSearchData: null,
                currentSearchDataSortBy: null
            };
        case actions.RESET_STATE_ACTION:
            return initialState;
        default:
            return state;
    }
}

function handleUpdateSelectedFavoriteAction(state: State, action: actions.UpdateSelectedFavoriteId) {

    const newState = { ...state };

    if (action.payload.favoriteId) {
        const favorite: FavoriteResponseModel = state.favorites.find((favorite: FavoriteResponseModel) => favorite.favoriteId === action.payload.favoriteId);
        const favoriteData: ReportSearchRequestModel = JSON.parse(favorite.favoriteData);

        const expressionItems: ExpressionItemModel[] = convertBaseExpressionRequestsIntoExpressionItems(favoriteData.searchExpression);

        for (let expressionItem of expressionItems) {
            if (expressionItem.itemType === ExpressionItemTypeEnum.FILTER) {

                let filter: FilterItemModel = (<ExpressionFilterModel> expressionItem).filter;
                switch (filter.name) {
                    case "Order Date":
                        const startDate: moment.Moment = getDateFromJsonObject(JSON.parse(filter.data[0]));
                        const endDate: moment.Moment = getDateFromJsonObject(JSON.parse(filter.data[1]));
                        newState.selectedStartDate = startDate;
                        newState.selectedEndDate = endDate;
                        break;
                    case "Role Sold By":
                        const roleIds: number[] = filter.data.map(roleId => +roleId);
                        newState.selectedRoleIds = roleIds;
                        break;
                    case "Sold By":
                        const userIds: number[] = filter.data.map(userId => +userId);
                        newState.selectedUserIds = userIds;
                        break;
                    case "Purchased MultiPass":
                        const passIds: number[] = filter.data.map(passId => +passId);
                        newState.selectedPassIds = passIds;
                        break;
                    case "Product":
                        const productIds: number[] = filter.data.map(productId => +productId);
                        newState.selectedProductIds = productIds;
                        break;
                }
            }
        }
    }

    newState.selectedFavoriteId = action.payload.favoriteId;
    newState.favoriteCreatedUpdated = false;
    newState.currentSearchData = null;
    newState.currentSearchDataSortBy = null;

    return newState;
}

function convertBaseExpressionRequestsIntoExpressionItems(baseExpressionRequests: BaseExpressionRequestModel[]): ExpressionItemModel[] {

    const resultList: ExpressionItemModel[] = [];

    for (const item of baseExpressionRequests) {
        switch (item.expressionType) {
            case ExpressionItemTypeEnum.OPERATOR:
                const operatorExpressionRequest: OperatorExpressionRequestModel = <OperatorExpressionRequestModel>item;
                resultList.push(new ExpressionOperatorModel(<ExpressionOperatorTypeEnum>operatorExpressionRequest.operator));
                break;
            case ExpressionItemTypeEnum.FILTER:
                const filterExpressionRequest: FilterExpressionRequestModel = <FilterExpressionRequestModel>item;
                const filterItem: FilterItemModel = findFilterItemByQueryFieldName(filterExpressionRequest.fieldName, filterExpressionRequest.data);
                resultList.push(new ExpressionFilterModel(filterItem));
                break;
            case ExpressionItemTypeEnum.GROUP:
                const groupExpressionRequest: GroupExpressionRequestModel = <GroupExpressionRequestModel> item;
                const groupExpressionItems: ExpressionItemModel[] = convertBaseExpressionRequestsIntoExpressionItems(groupExpressionRequest.groupExpression);
                const groupHeight: number = findExpressionItemsMaxHeight(groupExpressionItems) + 1;
                resultList.push(new ExpressionGroupModel(groupExpressionItems, groupHeight));
                break;
            default:
                console.error('convertBaseExpressionRequestsIntoExpressionItems :: unexpected item type');
        }
    }

    return resultList;
}

function findExpressionItemsMaxHeight(expressionItems: ExpressionItemModel[]): number {

    let maxHeight = 0;

    for (const expressionItem of expressionItems) {
        if (expressionItem.itemType === ExpressionItemTypeEnum.GROUP) {
            const groupExpressionItem: ExpressionGroupModel = <ExpressionGroupModel> expressionItem;
            if (groupExpressionItem.groupHeight > maxHeight) {
                maxHeight = groupExpressionItem.groupHeight;
            }
        }
    }

    return maxHeight;
}

function getDateFromJsonObject(dateJson): moment.Moment {
    const date = moment();
    date.year(dateJson.year);
    date.month(dateJson.month - 1);
    date.date(dateJson.day);

    return date;
}
