export class TierWithQuantityModel {

    tierId: number;
    tierDescription: string;
    tierQuantity: number;

    constructor(tierId: number, tierDescription: string, tierQuantity: number) {
        this.tierId = tierId;
        this.tierDescription = tierDescription;
        this.tierQuantity = tierQuantity;
    }
}
