import { Component, OnInit, Input } from '@angular/core';
import { OrderVoidingInfoSummaryResponseModel } from '../../../../../../core/modules/rest/order-summary/response/order-voiding-info-summary-response.model';

@Component({
  selector: 'app-voiding-info-dialog-summary',
  templateUrl: './voiding-info-dialog-summary.component.html'
})
export class VoidingInfoDialogSummaryComponent implements OnInit {

  @Input() voidingInfos: OrderVoidingInfoSummaryResponseModel[];

  constructor() { }

  ngOnInit() {
  }

}
