import {ActionReducer, MetaReducer} from "@ngrx/store";

export function metaReducerForStateReset(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {

        if (action.type === 'LOGOUT') {
            state = {};
        }

        return reducer(state, action);
    };
}
export const metaReducers: MetaReducer<any>[] = [metaReducerForStateReset];
