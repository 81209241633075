import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { CloudPaymentDeviceResponseModel } from '../../../../../../../../core/modules/rest/cloud-payment/response/cloud-payment-response.model';
import { CloudPaymentDeviceFormSubmitDataModel } from '../model/cloud-payment-device-form-submit-data.model';
import { RootSandbox } from '../../../../../../../../core/store/root.sandbox';

@Component({
    selector: 'app-edit-cloud-payment-device-form',
    templateUrl: './edit-cloud-payment-device-form.component.html'
})
export class EditCloudPaymentDeviceFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() cloudPaymentDevice: CloudPaymentDeviceResponseModel;

    @Output() submitEvent = new EventEmitter<CloudPaymentDeviceFormSubmitDataModel>();
    @Output() closeEvent = new EventEmitter<void>();

    form: FormGroup;

    constructor(private rootSandbox: RootSandbox) {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.editMode && changes.cloudPaymentDevice !== null && Object.keys(changes.cloudPaymentDevice.currentValue).length !== 0) {
            this.form.patchValue({
                nmiPaymentDeviceId: changes.cloudPaymentDevice.currentValue.nmiPaymentDeviceId,
                description: changes.cloudPaymentDevice.currentValue.description,

            });
        }
    }

    ngOnInit() {

        let nmiPaymentDeviceId: string = '';
        let description: string = '';
        let securityKey: string = '';

        if (this.editMode) {
            nmiPaymentDeviceId = this.cloudPaymentDevice.nmiPaymentDeviceId;
            description = this.cloudPaymentDevice.description;

        }

        this.form = new FormGroup({
            nmiPaymentDeviceId: new FormControl(nmiPaymentDeviceId),
            description: new FormControl(description),
            securityKey: new FormControl(securityKey)
        });
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        const nmiPaymentDeviceId = this.form.value.nmiPaymentDeviceId;
        const description = this.form.value.description;
        const securityKey = this.form.value.securityKey;

        if ( nmiPaymentDeviceId == null || nmiPaymentDeviceId.trim() === '' ) {
            this.rootSandbox.addErrorNotification("Error while creating cloud payment device: NMI Payment Device ID can't be null or empty");
            return;
		}

        if ( description == null || description.trim() === '' ) {
            this.rootSandbox.addErrorNotification("Error while creating cloud payment device: Description can't be null or empty");
            return;
		}

        if ( securityKey == null || securityKey.trim() === '' ) {
            this.rootSandbox.addErrorNotification("Error while creating cloud payment device: Security Key can't be null or empty");
            return;
		}


        const data: CloudPaymentDeviceFormSubmitDataModel = new CloudPaymentDeviceFormSubmitDataModel(this.cloudPaymentDevice !== undefined && this.cloudPaymentDevice ? this.cloudPaymentDevice.cloudPaymentDeviceId : null, nmiPaymentDeviceId, description, securityKey);

        this.submitEvent.emit(data);
    }
}
