

<!--Adult-->
<div class="grid-x grid-margin-x align-middle">
    <div class="cell medium-6">
        <p>{{ productTier.description }}</p>
    </div>


    <div class="cell medium-6">
        <div class="grid-x grid-margin-x">
            <div class="cell auto text-center">
                <input class="input-xs" placeholder="-"
                       value="{{ productTier.defaultQuantity }}"
                       #defaultQuantityInput (keyup)="defaultQuantityChanged(defaultQuantityInput.value)"
                       (change)="defaultQuantityChanged(defaultQuantityInput.value)">
            </div>
            <div class="cell auto text-center">
                <input class="input-xs" placeholder="-" value="{{ productTier.minQuantity }}"
                       #minQuantityInput (keyup)="minQuantityChanged(minQuantityInput.value)"
                       (change)="minQuantityChanged(minQuantityInput.value)">
            </div>
            <div class="cell auto text-center">
                <input class="input-xs" placeholder="-" value="{{ productTier.maxQuantity }}"
                       #maxQuantityInput (keyup)="maxQuantityChanged(maxQuantityInput.value)"
                       (change)="maxQuantityChanged(maxQuantityInput.value)">
            </div>
        </div>
    </div>
</div><!--Adult :: END-->
