
import { combineLatest as observableCombineLatest, of as observableOf, forkJoin as observableForkJoin, BehaviorSubject, Observable } from 'rxjs';

import { map, mergeMap, take, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";

import { Store } from "@ngrx/store";
import { RootSandbox } from "../../../../../../../../core/store/root.sandbox";
import { CostRestService } from "../../../../../../../../core/modules/rest/cost/cost-rest.service";
import { LocationResponseModel } from "../../../../../../../../core/modules/rest/location/response/location-response.model";
import { MultiselectDropdownOptionModel } from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

import * as fromFeature from './store/reducer';
import * as fromFeatureActions from './store/actions';

import { DiscountCostModel } from "../model/discount-cost.model";
import { HttpErrorModel } from "../../../../../../../../core/modules/rest/http-error.model";
import { AmountTypeEnum } from "../../../../../../../../shared/enums/amount-type.enum";
import { CostTypeEnum } from "../../../../../../../../shared/enums/cost-type.enum";
import { DiscountResponseModel } from "../../../../../../../../core/modules/rest/cost/response/discount-response.model";
import { DialogSandbox } from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import { AppDialogsService } from "../../../../../../../../core/services/app-dialogs.service";
import { UpdateCostRequestModel } from "../../../../../../../../core/modules/rest/cost/request/update-cost-request.model";
import { CreateCostRequestModel } from "../../../../../../../../core/modules/rest/cost/request/create-cost-request.model";
import * as actions from "../../../../quick-selling-button-products/dialogs/edit-quick-selling-button-product-dialog/sandbox/store/actions";
import { PlatformEnum } from "../../../../../../../../shared/enums/platform.enum";
import moment from 'moment';

@Injectable()
export class EditDiscountDialogSandbox extends DialogSandbox {

    private errorMessage$: BehaviorSubject<string>;

    constructor(appDialogsService: AppDialogsService,
        private store: Store<fromFeature.State>,
        private rootSandbox: RootSandbox,
        private costRestService: CostRestService) {
        super(appDialogsService);
        this.errorMessage$ = new BehaviorSubject<string>(null);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadDialogData(costId: number) {

        this.showLoader();

        const editDiscount = !!costId;

        // Clear error message
        this.errorMessage$.next('');

        // Dialog data loading
        this.store.dispatch(new fromFeatureActions.UpdateDialogDataLoaded(false));

        // Update edit discount
        this.store.dispatch(new fromFeatureActions.UpdateEditDiscount(editDiscount));

        // Get discount by id
        const getDiscountById_Request = this.costRestService.getDiscountById(costId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading discount by id', error);
            }));

        // Get all locations
        const getAllLocations_Request = this.rootSandbox.getAllLocations();

        observableForkJoin([getAllLocations_Request, editDiscount ? getDiscountById_Request : observableOf(null)])
            .subscribe(results => {

                const allLocations: LocationResponseModel[] = results[0];
                let discount: DiscountResponseModel = results[1];

                this.store.dispatch(new fromFeatureActions.UpdateAllLocations(allLocations));

                if (!discount) {
                    discount = new DiscountCostModel();
                }

                this.store.dispatch(new fromFeatureActions.UpdateDiscount(discount));

                // Dialog data loaded
                this.store.dispatch(new fromFeatureActions.UpdateDialogDataLoaded(true));

                this.hideLoader();
            },
                () => this.hideLoader());
    }

    updateDiscountProperty(discount: DiscountCostModel) {
        this.store.dispatch(new fromFeatureActions.UpdateDiscount(discount));
    }

    getDialogDataLoaded(): Observable<boolean> {
        return this.store.select(fromFeature.dialogDataLoaded_selector);
    }

    getEditDiscount(): Observable<boolean> {
        return this.store.select(fromFeature.editDiscount_selector);
    }

    getBasePlatformsForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        const array: MultiselectDropdownOptionModel[] = [
            new MultiselectDropdownOptionModel('Android', PlatformEnum.ANDROID),
            new MultiselectDropdownOptionModel('Website', PlatformEnum.WEBSITE),
            new MultiselectDropdownOptionModel('Web Application', PlatformEnum.WEB_APPLICATION)
        ];
        return observableOf(array);
    }

    getAllPlatformsForMultiselectDropdown(hasKiosk: boolean, hasCustomerApplication: boolean): Observable<MultiselectDropdownOptionModel[]> {
        const array: MultiselectDropdownOptionModel[] = [
            new MultiselectDropdownOptionModel('Android', PlatformEnum.ANDROID),
            new MultiselectDropdownOptionModel('Website', PlatformEnum.WEBSITE),
            new MultiselectDropdownOptionModel('Web Application', PlatformEnum.WEB_APPLICATION)
        ];
        if (hasCustomerApplication) {
            array.push(new MultiselectDropdownOptionModel('Customer Application', PlatformEnum.CUSTOMER_APPLICATION));
        }
        if (hasKiosk) {
            array.push(new MultiselectDropdownOptionModel('Kiosk', PlatformEnum.KIOSK));
        }
        return observableOf(array);
    }

    getDiscount(): Observable<DiscountCostModel> {
        return this.store.select(fromFeature.discount_selector);
    }

    getAmountTypesForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        const array: MultiselectDropdownOptionModel[] = [
            new MultiselectDropdownOptionModel('Percent', AmountTypeEnum.PERCENT),
            new MultiselectDropdownOptionModel('Dollars', AmountTypeEnum.DOLLARS)
        ];

        return observableOf(array);
    }

    getErrorMessage(): Observable<string> {
        return this.errorMessage$.asObservable();
    }

    save(): Observable<boolean> {
        return observableCombineLatest(
            this.getEditDiscount(),
            this.getDiscount()
        ).pipe(
            take(1),
            mergeMap(([editDiscount, discount]) => {
                const editDiscountParameterized: boolean = editDiscount;
                const discountParameterized: DiscountCostModel = discount;

                let platforms: PlatformEnum[] = discountParameterized.allPlatforms ? [] : discountParameterized.platforms;
                platforms = platforms ? platforms : [];

                // Validate data
                if (!discountParameterized.description || discountParameterized.description.trim().length === 0) {
                    this.errorMessage$.next('Please enter description');
                    return observableOf(false);
                }

                if (!discountParameterized.discountCode || discountParameterized.discountCode.trim().length === 0) {
                    this.errorMessage$.next('Please enter discount code');
                    return observableOf(false);
                }

                if (discountParameterized.amount === null) {
                    this.errorMessage$.next('Please enter amount');
                    return observableOf(false);
                }

                if (!discountParameterized.amountType) {
                    this.errorMessage$.next('Please select amount type');
                    return observableOf(false);
                }

                // Clear error message
                this.errorMessage$.next('');

                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const startDate = moment(discountParameterized.startDateMS);
                const endDate = moment(discountParameterized.endDateMS);

                if (editDiscountParameterized) {
                    const data: UpdateCostRequestModel = new UpdateCostRequestModel(
                        discountParameterized.costId,
                        discountParameterized.costType,
                        discountParameterized.description,
                        discountParameterized.amount,
                        discountParameterized.amountType,
                        discountParameterized.isOrderLevel,
                        discountParameterized.glCode,
                        discountParameterized.discountCode,
                        discountParameterized.startDateInt ? discountParameterized.startDateInt : null,
                        discountParameterized.endDateInt ? discountParameterized.endDateInt : null,
                        null,
                        null,
                        null,
                        false,
                        null,
                        discountParameterized.allPlatforms,
                        platforms);

                    return this.costRestService.update(data).pipe(
                        map(() => {
                            return true;
                        }),
                        catchError((error: HttpErrorModel) => {
                            return this.rootSandbox.handleHttpError('Error while updating discount', error);
                        }),);
                } else {
                    const data: CreateCostRequestModel = new CreateCostRequestModel(
                        CostTypeEnum.DISCOUNT,
                        discountParameterized.description,
                        discountParameterized.amount,
                        discountParameterized.amountType,
                        discountParameterized.isOrderLevel,
                        discountParameterized.glCode,
                        discountParameterized.discountCode,
                        discountParameterized.startDateInt ? discountParameterized.startDateInt : null,
                        discountParameterized.endDateInt ? discountParameterized.endDateInt : null,
                        null,
                        null,
                        null,
                        false,
                        null,
                        discountParameterized.allPlatforms,
                        platforms);

                    return this.costRestService.create(data).pipe(
                        map(() => {
                            return true;
                        }),
                        catchError((error: HttpErrorModel) => {
                            return this.rootSandbox.handleHttpError('Error while creating discount', error);
                        }),);
                }
            }),);
    }
}
