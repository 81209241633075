import {NgModule} from "@angular/core";
import {ClonePassDialogComponent} from './clone-pass-dialog.component';
import {ClonePassDialogSandbox} from "./sandbox/clone-pass-dialog.sandbox";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {ClonePassDialogFormComponent} from './components/clone-pass-dialog-form/clone-pass-dialog-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    declarations: [
        ClonePassDialogComponent,
        ClonePassDialogFormComponent
    ],
    providers: [
        ClonePassDialogSandbox
    ],
    exports: [
        ClonePassDialogComponent
    ],
    entryComponents: [
        ClonePassDialogComponent
    ]
})
export class ClonePassDialogModule {
}
