import { Component, OnInit  } from '@angular/core';


@Component({
  selector: 'app-secondary-nav-history',
  templateUrl: './secondary-nav-history.component.html'
})
export class SecondaryNavHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
