import { Component, OnInit  } from '@angular/core';



@Component({
  selector: 'app-tab-header-item',
  templateUrl: './tab-header-item.component.html',
  styleUrls: ['./tab-header-item.component.scss']
})
export class TabHeaderItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
