<div class="summary-general-info-wrapper">
    <div class="summary-general-info">
        <div class="summary-data-wrapper">
            <table style="width:100%">
                <thead>
                    <tr>
                        <th>Customer Name</th>
                        <th>Order Number</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container>
                        <tr>
                            <td class="related-order-td">{{relatedOrder.customerName ? relatedOrder.customerName : 'N/A'}}</td>
                            <td>{{relatedOrder.orderExternalNumber ? relatedOrder.orderExternalNumber : 'N/A'}}</td>
                            <td>{{relatedOrder.orderStatus ? relatedOrder.orderStatus : 'N/A'}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
