<app-accordion>
    <app-accordion-item>
        <div class="accordion--item--header accordionItemHeader">
            Additional Costs
        </div>

        <div class="accordion--item--body accordionItemBody">

            <div class="table_wrapper">
                <div class="table_caption">
                    <!-- Table Header :: BEGIN -->
                    <div class="text-right">
                        <div class="toolboxButtons_container">
                            <ul class="toolboxButtons_list">
                                <li class="toolboxButtons_li">

                                    <a class="icon_button"
                                       (click)="addNewAdditionalCostClick()">
                                        <div class="icon_container">
                                            <i class="fal fa-plus-circle"></i>
                                        </div>
                                        Add Additional Cost
                                    </a>
                                </li>


                                <li class="toolboxButtons_li">
                                    <app-switch
                                        [switchLabel]="'Show Inactive'"
                                        [value]="showInactiveAdditionalCosts"
                                        (switchValueChanged)="onShowInactiveAdditionalCosts($event)">
                                    </app-switch>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div><!-- Table Header :: END -->


                <app-additional-costs-table
                    *ngIf="additionalCosts.length !== 0"
                    [additionalCosts]="additionalCosts"
                    (mappingAdditionalCostEvent)="onMappingAdditionalCost($event)"
                    (editAdditionalCostEvent)="onEditAdditionalCost($event)"
                    (editAdditionalCostActivityEvent)="onEditAdditionalCostActivity($event)">
                </app-additional-costs-table>

            </div>


            <!-- Empty State component :: BEGIN -->
            <div class="grid-x grid-margin-x"
                 *ngIf="additionalCosts.length === 0">
                <div class="medium-12 cell">

                    <div class="emptyState_wrapper">
                        <div class="emptyState_container">
                            <img src="{{emptyStateIcon}}" alt="" class="emptyStateImage">
                            <h1>No Additional Costs to Display!</h1>
                            <p>To add new Additional Cost click on </p>
                            <p>Add New Additioanl Cost</p>
                        </div>
                    </div>
                </div>
            </div><!-- Empty State component :: END -->

        </div>
    </app-accordion-item>
</app-accordion>
