
import { of as observableOf, combineLatest, Observable } from 'rxjs';

import { mergeMap, filter, flatMap, map, take } from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import { Injectable } from "@angular/core";
import { TreeNode } from "../../../../../../shared/components/tree/tree.component";
import { select, Store } from "@ngrx/store";
import { CategoryResponseModel } from "../../../../../../core/modules/rest/category/response/category-response.model";
import { ProductSimpleResponseModel } from "../../../../../../core/modules/rest/product/response/product-simple-response.model";
import { ProductBasicInfoDataModel } from "../model/product-basic-info-data.model";
import { CategoryTemplateItemsDataModel } from "../model/category-template-items-data.model";
import { CategoryEmailHtmlTemplatesDataModel } from "../model/category-email-html-templates-data.model";
import { CategoryBasicInfoDataModel } from "../model/category-basic-info-data.model";
import { AppDialogsService } from "../../../../../../core/services/app-dialogs.service";
import { RootSandbox } from "../../../../../../core/store/root.sandbox";
import { CategoryRestService } from "../../../../../../core/modules/rest/category/category-rest.service";
import { ProductRestService } from "../../../../../../core/modules/rest/product/product-rest.service";
import { TreeItemTypeEnum } from "../model/tree-item-type.enum";
import { TreeNodeDataModel } from "../model/tree-node-data.model";
import { ProductAvailabilitiesDataModel } from "../model/product-availabilities-data.model";
import { ProductPricingDateRangesDataModel } from "../model/product-pricing-date-ranges-data.model";
import { CategoryFullResponseModel } from "../../../../../../core/modules/rest/category/response/category-full-response.model";
import { TemplateItemResponseModel } from "../../../../../../core/modules/rest/template/response/template-item-response.model";
import { TemplateItemDataModel } from "../model/template-item-data.model";
import { ProductResponseModel } from "../../../../../../core/modules/rest/product/response/product-response.model";
import { ProductAvailabilityDataModel } from "../model/product-availability-data.model";
import { ProductAvailabilityFullResponseModel } from "../../../../../../core/modules/rest/product-availability/response/product-availability-full-response.model";
import { ProductAvailabilityRestService } from "../../../../../../core/modules/rest/product-availability/product-availability-rest.service";
import { PricingDateRangeRestService } from "../../../../../../core/modules/rest/pricing-date-range/pricing-date-range-rest.service";
import { PricingDateRangeFullResponseModel } from "../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-full-response.model";
import { PricingDateRangeDataModel } from "../model/pricing-date-range-data.model";
import { TemplateRestService } from "../../../../../../core/modules/rest/template/template-rest.service";
import { SetCategoryTemplateItemsFlagDataModel } from "../model/set-category-template-items-flag-data.model";
import { CategoryTabItemEnum } from "../model/category-tab-item.enum";
import { ProductTabItemEnum } from "../model/product-tab-item.enum";
import { ProductImagesDataModel } from "../model/product-images-data.model";
import { ProductFlagsDataModel } from "../model/product-flags-data.model";
import { ProductValidityDataModel } from "../model/product-validity-data.model";
import { ProductEmailHtmlTemplatesDataModel } from "../model/product-email-html-templates-data.model";
import { ProductRoutesDataModel } from "../model/product-routes-data.model";
import { SetProductAvailabilitiesFlagDataModel } from "../model/set-product-availabilities-flag-data.model";
import { SetProductPricingDateRangesFlagDataModel } from "../model/set-product-pricing-date-ranges-flag-data.model";
import { ImageResponseModel } from "../../../../../../core/modules/rest/image-response.model";
import { ProductAvailabilitiesSidebarFilterTypeEnum } from "../model/product-availabilities-sidebar-filter-type.enum";
import { ProductAvailabilitiesSidebarFilterDataModel } from "../model/product-availabilities-sidebar-filter-data.model";
import { DepartureGroupResponseModel } from "../../../../../../core/modules/rest/departure-group/response/departure-group-response.model";
import { DepartureGroupRestService } from "../../../../../../core/modules/rest/departure-group/departure-group-rest.service";
import { GetProductAvailabilitiesFilteredRequestModel } from "../../../../../../core/modules/rest/product-availability/request/get-product-availabilities-filtered-request.model";
import { DateTimeUtility } from "../../../../../../shared/utils/date-time-utility";
import * as moment from "moment";
import { DayOfWeekEnum } from "../../../../../../shared/enums/day-of-week.enum";
import { LocationRestService } from "../../../../../../core/modules/rest/location/location-rest.service";
import { LocationsResponseModel } from "../../../../../../core/modules/rest/location/response/locations-response.model";
import { LocationResponseModel } from "../../../../../../core/modules/rest/location/response/location-response.model";
import { TierRestService } from "../../../../../../core/modules/rest/tier/tier-rest.service";
import { TiersResponseModel } from "../../../../../../core/modules/rest/tier/response/tiers-response.model";
import { TierResponseModel } from "../../../../../../core/modules/rest/tier/response/tier-response.model";
import { ProductPricingDateRangesSidebarFilterTypeEnum } from "../model/product-pricing-date-ranges-sidebar-filter-type.enum";
import { ProductPricingDateRangesSidebarFilterDataModel } from "../model/product-pricing-date-ranges-sidebar-filter-data.model";
import { GetPricingDateRangesFilteredRequestModel } from "../../../../../../core/modules/rest/pricing-date-range/request/get-pricing-date-ranges-filtered-request.model";
import { UpdatePricingDateRangePriorityRequestModel } from "../../../../../../core/modules/rest/pricing-date-range/request/update-pricing-date-range-priority-request.model";
import { SetProductPricingDateRangeFlagDataModel } from "../model/set-product-pricing-date-range-flag-data.model";
import { DateTimeDescriptor } from "../../../../../../shared/model/date-time-descriptor.model";
import { CostTypeEnum } from "../../../../../../shared/enums/cost-type.enum";
import { CategoryCostsDataModel } from "../model/category-costs-data.model";
import { CostDataModel } from "../model/cost-data.model";
import { ProductCostsDataModel } from "../model/product-costs-data.model";
import { ProductCostResponseModel } from "../../../../../../core/modules/rest/product/response/product-cost-response.model";
import { ProductAvailabilitiesFullResponseModel } from "../../../../../../core/modules/rest/product-availability/response/product-availabilities-full-response.model";
import { PricingDateRangesFullResponseModel } from "../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-ranges-full-response.model";
import { SortByModel } from "../../../../../../shared/model/sort-by.model";
import * as fromActions from "../../../../reports/operational/operations-report/sandbox/store/actions";
import { SortByRequestModel } from "../../../../../../core/modules/rest/reporting/request/sort-by-request.model";

@Injectable()
export class CategoriesAndProductsSandbox {

    loadingCategoriesAndProducts$: Observable<boolean> = this.store.pipe(select(reducer.loading_categories_and_products_selector));

    categoriesAndProductsTree$: Observable<TreeNode[]> = this.store.pipe(select(reducer.categories_and_products_tree_selector));

    showInactiveItems$: Observable<boolean> = this.store.pipe(select(reducer.show_inactive_items_selector));

    item$: Observable<TreeNode> = this.store.pipe(select(reducer.item_selector));

    node$: Observable<TreeNodeMaterial> = this.store.pipe(select(reducer.node_selector));

    itemType$: Observable<TreeItemTypeEnum> = this.store.pipe(select(reducer.item_type_selector));

    itemId$: Observable<number> = this.store.pipe(select(reducer.item_id_selector));

    itemActive$: Observable<boolean> = this.store.pipe(select(reducer.item_active_selector));

    loadingCategoryFull$: Observable<boolean> = this.store.pipe(select(reducer.loading_category_full_selector));

    loadingCategoryTemplateItems$: Observable<boolean> = this.store.pipe(select(reducer.loading_category_template_items_selector));

    selectedCategoryTabItem$: Observable<CategoryTabItemEnum> = this.store.pipe(select(reducer.selected_category_tab_item_selector));

    itemCategoryBasicInfo$: Observable<CategoryBasicInfoDataModel> = this.store.pipe(select(reducer.item_category_basic_info_selector));

    itemCategoryEmailHtmlTemplates$: Observable<CategoryEmailHtmlTemplatesDataModel> = this.store.pipe(select(reducer.item_category_email_html_templates_selector));

    itemCategoryCosts$: Observable<CategoryCostsDataModel> = this.store.pipe(select(reducer.item_category_costs));

    itemCategoryTemplateItems$: Observable<CategoryTemplateItemsDataModel> = this.store.pipe(select(reducer.item_category_template_items_selector));

    loadingProductFull$: Observable<boolean> = this.store.pipe(select(reducer.loading_product_full_selector));

    loadingProductImages$: Observable<boolean> = this.store.pipe(select(reducer.loading_product_images_selector));

    loadingProductAvailabilities$: Observable<boolean> = this.store.pipe(select(reducer.loading_product_availabilities_selector));

    loadingProductPricingDateRanges$: Observable<boolean> = this.store.pipe(select(reducer.loading_product_pricing_date_ranges_selector));

    selectedProductTabItem$: Observable<ProductTabItemEnum> = this.store.pipe(select(reducer.selected_product_tab_item_selector));

    itemProductBasicInfo$: Observable<ProductBasicInfoDataModel> = this.store.pipe(select(reducer.item_product_basic_info_selector));

    itemProductImages$: Observable<ProductImagesDataModel> = this.store.pipe(select(reducer.item_product_images_selector));

    itemProductFlags$: Observable<ProductFlagsDataModel> = this.store.pipe(select(reducer.item_product_flags_selector));

    itemProductValidity$: Observable<ProductValidityDataModel> = this.store.pipe(select(reducer.item_product_validity_selector));

    itemProductEmailHtmlTemplates$: Observable<ProductEmailHtmlTemplatesDataModel> = this.store.pipe(select(reducer.item_product_email_html_templates_selector));

    itemProductRoutes$: Observable<ProductRoutesDataModel> = this.store.pipe(select(reducer.item_product_routes_selector));

    itemProductCosts$: Observable<ProductCostsDataModel> = this.store.pipe(select(reducer.item_product_costs_selector));

    itemProductAvailabilities$: Observable<ProductAvailabilitiesDataModel> = this.store.pipe(select(reducer.item_product_availabilities_selector));

    productAvailabilitiesFrom$: Observable<number> = this.store.pipe(select(reducer.product_availabilities_from_selector));

    productAvailabilitiesSidebarFilterOpen$: Observable<boolean> = this.store.pipe(select(reducer.product_availabilities_sidebar_filter_open_selector));

    productAvailabilitiesSidebarFilterDepartureGroups$: Observable<DepartureGroupResponseModel[]> = this.store.pipe(select(reducer.product_availabilities_sidebar_filter_departure_groups_selector));

    productAvailabilitiesSidebarFilterData$: Observable<ProductAvailabilitiesSidebarFilterDataModel> = this.store.pipe(select(reducer.product_availabilities_sidebar_filter_data_selector));

    productAvailabilitiesSidebarFilterCurrentFocus$: Observable<ProductAvailabilitiesSidebarFilterTypeEnum> = this.store.pipe(select(reducer.product_availabilities_sidebar_filter_current_focus_selector));

    productAvailabilitiesCurrentSearchDataSortBy$: Observable<SortByModel> = this.store.pipe(select(reducer.product_availabilities_current_search_data_sort_by_selector));

    itemProductPricingDateRanges$: Observable<ProductPricingDateRangesDataModel> = this.store.pipe(select(reducer.item_product_pricing_date_ranges_selector));

    productPricingDateRangesFrom$: Observable<number> = this.store.pipe(select(reducer.product_pricing_date_ranges_from_selector));

    productPricingDateRangesSidebarFilterOpen$: Observable<boolean> = this.store.pipe(select(reducer.product_pricing_date_ranges_sidebar_filter_open_selector));

    productPricingDateRangesSidebarFilterPickupLocations$: Observable<LocationResponseModel[]> = this.store.pipe(select(reducer.product_pricing_date_ranges_sidebar_filter_pickup_locations_selector));

    productPricingDateRangesSidebarFilterDropoffLocations$: Observable<LocationResponseModel[]> = this.store.pipe(select(reducer.product_pricing_date_ranges_sidebar_filter_dropoff_locations_selector));

    productPricingDateRangesSidebarFilterTiers$: Observable<TierResponseModel[]> = this.store.pipe(select(reducer.product_pricing_date_ranges_sidebar_filter_tiers_selector));

    productPricingDateRangesSidebarFilterData$: Observable<ProductPricingDateRangesSidebarFilterDataModel> = this.store.pipe(select(reducer.product_pricing_date_ranges_sidebar_filter_data_selector));

    productPricingDateRangesSidebarFilterCurrentFocus$: Observable<ProductPricingDateRangesSidebarFilterTypeEnum> = this.store.pipe(select(reducer.product_pricing_date_ranges_sidebar_filter_current_focus_selector));

    productPricingDateRangesCurrentSearchDataSortBy$: Observable<SortByModel> = this.store.pipe(select(reducer.product_pricing_date_ranges_current_search_data_sort_by_selector));

    readonly PRODUCT_AVAILABILITIES_PAGE_SIZE: number = 40;
    readonly PRODUCT_PRICING_DATE_RANGES_PAGE_SIZE: number = 40;

    constructor(private store: Store<reducer.State>,
        private categoryRestService: CategoryRestService,
        private productRestService: ProductRestService,
        private templateRestService: TemplateRestService,
        private productAvailabilityRestService: ProductAvailabilityRestService,
        private departureGroupRestService: DepartureGroupRestService,
        private pricingDateRangeRestService: PricingDateRangeRestService,
        private locationRestService: LocationRestService,
        private tierRestService: TierRestService,
        private appDialogsService: AppDialogsService,
        private rootSandbox: RootSandbox) {
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    /**
     * CATEGORY & PRODUCT TREE ACTIONS
     */

    loadCategoriesAndProducts() {

        this.store.dispatch(new actions.SetLoadingCategoriesAndProducts(true));

        combineLatest([this.categoryRestService.getAll(), this.productRestService.getAll()]).pipe(
            take(1),
            map(([categories, products]: [CategoryResponseModel[], ProductSimpleResponseModel[]]) => {
                let nodes: TreeNode[] = this.getCategoriesAndProductsTree(categories, products);
                this.store.dispatch(new actions.SetLoadingCategoriesAndProducts(false));
                this.store.dispatch(new actions.SetCategoriesAndProductsTree(nodes));
            })
        ).subscribe();
    }

    categoryNodeSelected(itemCategory: TreeNode<TreeNodeDataModel>) {
        this.item$.pipe(
            take(1))
            .subscribe((item: TreeNode<TreeNodeDataModel>) => {
                if (!item || !item.data || item.data.type !== itemCategory.data.type || item.data.id !== itemCategory.data.id) {
                    this.setCategory(itemCategory);
                }
            });
    }

    loadNode(passedNode: TreeNodeMaterial) {
        this.node$.pipe(
            take(1),
            map((node: TreeNodeMaterial) => {
                node = passedNode
                this.store.dispatch(new actions.SetSelectedNode(passedNode));
            })
        ).subscribe();
    }

    productNodeSelected(itemProduct: TreeNode<TreeNodeDataModel>) {
        this.item$.pipe(
            take(1))
            .subscribe((item: TreeNode<TreeNodeDataModel>) => {
                if (!item || !item.data || item.data.type !== itemProduct.data.type || item.data.id !== itemProduct.data.id) {
                    this.setProduct(itemProduct);
                }
            });
    }

    setCategory(item: TreeNode<TreeNodeDataModel>) {

        let categoryId: number = item.data.id;

        this.store.dispatch(new actions.SetSelectedItem(item, TreeItemTypeEnum.CATEGORY, categoryId, item.active));
        this.store.dispatch(new actions.SetLoadingCategoryFull(true));

        this.categoryRestService.getCategoryFullById(categoryId)
            .subscribe((category: CategoryFullResponseModel) => {

                let categoryBasicInfo: CategoryBasicInfoDataModel = {
                    categoryId: category.categoryId,
                    active: category.active,
                    description: category.description
                };

                let categoryEmailHtmlTemplates: CategoryEmailHtmlTemplatesDataModel = {
                    categoryId: category.categoryId,
                    createOrderEmailHtmlTemplateId: category.createOrderEmailHtmlTemplateId,
                    createOrderEmailHtmlTemplateDescription: category.createOrderEmailHtmlTemplateDescription,
                    voidOrderEmailHtmlTemplateId: category.voidOrderEmailHtmlTemplateId,
                    voidOrderEmailHtmlTemplateDescription: category.voidOrderEmailHtmlTemplateDescription,
                    termsAndConditionsEmailHtmlTemplateId: category.termsAndConditionsEmailHtmlTemplateId,
                    termsAndConditionsEmailHtmlTemplateDescription: category.termsAndConditionsEmailHtmlTemplateDescription,
                    createOrderEmailHtmlTemplateActive: category.createOrderEmailHtmlTemplateActive,
                    voidOrderEmailHtmlTemplateActive: category.voidOrderEmailHtmlTemplateActive,
                    termsAndConditionsEmailHtmlTemplateActive: category.termsAndConditionsEmailHtmlTemplateActive

                };

                // Group category costs by cost type and sort them by description
                let categoryAdditionalCosts: CostDataModel[] = category.productCosts.filter(cc => cc.costType === CostTypeEnum.ADDITIONAL).sort((cc1, cc2) => (cc1.costDescription > cc2.costDescription) ? 1 : -1);
                let categoryIncludedCosts: CostDataModel[] = category.productCosts.filter(cc => cc.costType === CostTypeEnum.INCLUDED).sort((cc1, cc2) => (cc1.costDescription > cc2.costDescription) ? 1 : -1);
                let categoryDiscounts: CostDataModel[] = category.productCosts.filter(cc => cc.costType === CostTypeEnum.DISCOUNT).sort((cc1, cc2) => (cc1.costDescription > cc2.costDescription) ? 1 : -1);
                let categoryAutoAppliedDiscounts: CostDataModel[] = category.productCosts.filter(cc => cc.costType === CostTypeEnum.AUTO_APPLIED_DISCOUNT).sort((cc1, cc2) => (cc1.costDescription > cc2.costDescription) ? 1 : -1);

                let categoryCosts: CategoryCostsDataModel = {
                    categoryId: category.categoryId,
                    costs: [...categoryAdditionalCosts, ...categoryIncludedCosts, ...categoryDiscounts, ...categoryAutoAppliedDiscounts]
                };

                let categoryTemplateItems: CategoryTemplateItemsDataModel = {
                    categoryId: category.categoryId,
                    templateId: category.templateId,
                    templateItems: category.templateItems.map((ti: TemplateItemResponseModel) => new TemplateItemDataModel(ti))
                };

                this.store.dispatch(new actions.SetCategoryBasicInfo(categoryBasicInfo));
                this.store.dispatch(new actions.SetCategoryEmailHtmlTemplates(categoryEmailHtmlTemplates));
                this.store.dispatch(new actions.SetCategoryCosts(categoryCosts));
                this.store.dispatch(new actions.SetCategoryTemplateItems(categoryTemplateItems));
                this.store.dispatch(new actions.SetLoadingCategoryFull(false));
            });
    }

    setProduct(item: TreeNode<TreeNodeDataModel>) {

        let productId: number = item.data.id;

        this.store.dispatch(new actions.SetSelectedItem(item, TreeItemTypeEnum.PRODUCT, productId, item.active));
        this.store.dispatch(new actions.SetLoadingProductFull(true));

        combineLatest([this.productRestService.getProductById(productId), this.productRestService.getCategoryAndProductCostsByProductId(productId), this.productAvailabilitiesSidebarFilterData$, this.productPricingDateRangesSidebarFilterData$, this.productRestService.getProductSimpleById(productId)]).pipe(
            take(1),
            map(([product, aProductCosts, productAvailabilitiesSidebarFilterData, productPricingDateRangesSidebarFilterData, productOptions]: [ProductResponseModel, ProductCostResponseModel[], ProductAvailabilitiesSidebarFilterDataModel, ProductPricingDateRangesSidebarFilterDataModel, ProductSimpleResponseModel]) => {

                let productBasicInfo: ProductBasicInfoDataModel = {
                    productId: product.productId,
                    active: product.active,
                    categoryId: product.categoryId,
                    categoryDescription: product.categoryDescription,
                    description: product.description,
                    descriptionRegularTrip: product.descriptionRegularTrip,
                    descriptionReturnTrip: product.descriptionReturnTrip,
                    details: product.details,
                    detailsHtml: product.detailsHtml,
                    note: product.note,
                    emails: product.emails
                };

                let productImages: ProductImagesDataModel = {
                    productId: product.productId,
                    productImage: {
                        imageAmazonFilePath: product.productImageAmazonFilePath,
                        imageBase64: product.productImageBase64
                    },
                    ticketBackgroundImage: {
                        imageAmazonFilePath: product.ticketBackgroundImageAmazonFilePath,
                        imageBase64: product.ticketBackgroundImageBase64
                    }
                };

                let productFlags: ProductFlagsDataModel = {
                    productId: productOptions.productId,
                    requireCheckIn: productOptions.requireCheckIn,
                    availableForSale: productOptions.availableForSale,
                    isFlexibleProduct: productOptions.isFlexibleProduct,
                    isRoundTripProduct: productOptions.isRoundTripProduct,
                    manualAcceptanceForFailedScanRequired: productOptions.manualAcceptanceForFailedScanRequired,
                    shouldSendBarcodeQRCodeImageAttachment: productOptions.shouldSendBarcodeQRCodeImageAttachment,
                    shouldPrintProductImageOnPdfTicket: productOptions.shouldPrintProductImageOnPdfTicket
                };

                let productValidity: ProductValidityDataModel = {
                    productId: product.productId,
                    maximalDaysValidFor: product.maximalDaysValidFor,
                    maximalDaysValidForRoundTrip: product.maximalDaysValidForRoundTrip
                };

                let productEmailHtmlTemplates: ProductEmailHtmlTemplatesDataModel = {
                    productId: product.productId,
                    createOrderEmailHtmlTemplateId: product.createOrderEmailHtmlTemplateId,
                    createOrderEmailHtmlTemplateDescription: product.createOrderEmailHtmlTemplateDescription,
                    voidOrderEmailHtmlTemplateId: product.voidOrderEmailHtmlTemplateId,
                    voidOrderEmailHtmlTemplateDescription: product.voidOrderEmailHtmlTemplateDescription,
                    termsAndConditionsEmailHtmlTemplateId: product.termsAndConditionsEmailHtmlTemplateId,
                    termsAndConditionsEmailHtmlTemplateDescription: product.termsAndConditionsEmailHtmlTemplateDescription,
                    createOrderEmailHtmlTemplateActive: product.createOrderEmailHtmlTemplateActive,
                    voidOrderEmailHtmlTemplateActive: product.voidOrderEmailHtmlTemplateActive,
                    termsAndConditionsEmailHtmlTemplateActive: product.termsAndConditionsEmailHtmlTemplateActive
                };

                let productRoutes: ProductRoutesDataModel = {
                    productId: product.productId,
                    tracksQuantityBetweenStops: product.tracksQuantityBetweenStops,
                    usesPickupLocations: product.usesPickupLocations,
                    pickupLocationListId: product.pickupLocationListId,
                    pickupLocationListDescription: product.pickupLocationListDescription,
                    pickupLocationListActive: product.pickupLocationListActive,
                    usesDropoffLocations: product.usesDropoffLocations,
                    dropoffLocationListId: product.dropoffLocationListId,
                    dropoffLocationListDescription: product.dropoffLocationListDescription,
                    dropoffLocationListActive: product.dropoffLocationListActive
                };

                // Group product costs by cost type and sort them by description
                let productAdditionalCosts: CostDataModel[] = aProductCosts.filter(pc => pc.costType === CostTypeEnum.ADDITIONAL).sort((pc1, pc2) => (pc1.costDescription > pc2.costDescription) ? 1 : -1);
                let productIncludedCosts: CostDataModel[] = aProductCosts.filter(pc => pc.costType === CostTypeEnum.INCLUDED).sort((pc1, pc2) => (pc1.costDescription > pc2.costDescription) ? 1 : -1);
                let productDiscounts: CostDataModel[] = aProductCosts.filter(pc => pc.costType === CostTypeEnum.DISCOUNT).sort((pc1, pc2) => (pc1.costDescription > pc2.costDescription) ? 1 : -1);
                let productAutoAppliedDiscounts: CostDataModel[] = aProductCosts.filter(pc => pc.costType === CostTypeEnum.AUTO_APPLIED_DISCOUNT).sort((pc1, pc2) => (pc1.costDescription > pc2.costDescription) ? 1 : -1);

                let productCosts: ProductCostsDataModel = {
                    productId: product.productId,
                    costs: [...productAdditionalCosts, ...productIncludedCosts, ...productDiscounts, ...productAutoAppliedDiscounts]
                };

                this.store.dispatch(new actions.SetProductBasicInfo(productBasicInfo));
                this.store.dispatch(new actions.SetProductImages(productImages));
                this.store.dispatch(new actions.SetProductFlags(productFlags));
                this.store.dispatch(new actions.SetProductValidity(productValidity));
                this.store.dispatch(new actions.SetProductEmailHtmlTemplates(productEmailHtmlTemplates));
                this.store.dispatch(new actions.SetProductRoutes(productRoutes));
                this.store.dispatch(new actions.SetProductCosts(productCosts));
                this.store.dispatch(new actions.SetLoadingProductFull(false));

                // Trigger initial search for product availabilities and pricing date ranges
                this.loadProductAvailabilities(productAvailabilitiesSidebarFilterData);
                this.loadProductPricingDateRanges(productPricingDateRangesSidebarFilterData);
            })
        ).subscribe();
    }

    /**
     * CATEGORY ACTIONS
     */

    selectCategoryTabItem(categoryTabItem: CategoryTabItemEnum) {
        this.store.dispatch(new actions.SetSelectedCategoryTabItem(categoryTabItem));
    }

    enableCategory(categoryId: number) {

        this.store.dispatch(new actions.SetLoadingCategoriesAndProducts(true));

        combineLatest([this.categoryRestService.getAll(), this.productRestService.getAll()]).pipe(
            take(1),
            map(([categories, products]: [CategoryResponseModel[], ProductSimpleResponseModel[]]) => {

                let nodes: TreeNode<TreeNodeDataModel>[] = this.getCategoriesAndProductsTree(categories, products);

                this.store.dispatch(new actions.SetLoadingCategoriesAndProducts(false));
                this.store.dispatch(new actions.SetCategoriesAndProductsTree(nodes));

                let enabledCategoryNode: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(categoryId, TreeItemTypeEnum.CATEGORY, nodes);

                enabledCategoryNode.toggleOpen(true);

                this.setCategory(enabledCategoryNode);
            })
        ).subscribe();
    }

    disableCategory(categoryId: number) {

        this.store.dispatch(new actions.SetLoadingCategoriesAndProducts(true));

        this.categoryRestService.deactivate(categoryId).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Category can't be disabled");
                }

                return combineLatest([this.categoryRestService.getAll(), this.productRestService.getAll()]);
            }),
            take(1),
            map(([categories, products]: [CategoryResponseModel[], ProductSimpleResponseModel[]]) => {

                let nodes: TreeNode<TreeNodeDataModel>[] = this.getCategoriesAndProductsTree(categories, products);

                this.store.dispatch(new actions.SetLoadingCategoriesAndProducts(false));
                this.store.dispatch(new actions.SetCategoriesAndProductsTree(nodes));
                this.store.dispatch(new actions.SetShowInactiveItems(true));

                let disabledCategoryNode: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(categoryId, TreeItemTypeEnum.CATEGORY, nodes);

                disabledCategoryNode.toggleOpen(true);

                this.setCategory(disabledCategoryNode);
            })
        ).subscribe();
    }

    setActiveCategoryTemplateItems(setCategoryTemplateItemsFlagData: SetCategoryTemplateItemsFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingCategoryTemplateItems(true));

        this.templateRestService.activateTemplateItems(setCategoryTemplateItemsFlagData.templateItemIds).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Custom field(s) can't be activated");
                }

                return combineLatest([this.item$, this.templateRestService.getTemplateItemsByTemplateId(setCategoryTemplateItemsFlagData.templateId)]);
            }),
            take(1),
            map(([item, templateItems]: [TreeNode<TreeNodeDataModel>, TemplateItemResponseModel[]]) => {

                let categoryTemplateItems: CategoryTemplateItemsDataModel = {
                    categoryId: item.data.id,
                    templateId: setCategoryTemplateItemsFlagData.templateId,
                    templateItems: templateItems.map((ti: TemplateItemResponseModel) => new TemplateItemDataModel(ti))
                };

                this.store.dispatch(new actions.SetCategoryTemplateItems(categoryTemplateItems));
                this.store.dispatch(new actions.SetLoadingCategoryTemplateItems(false));
            })
        ).subscribe();
    }

    setNotActiveCategoryTemplateItems(setCategoryTemplateItemsFlagData: SetCategoryTemplateItemsFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingCategoryTemplateItems(true));

        this.templateRestService.deactivateTemplateItems(setCategoryTemplateItemsFlagData.templateItemIds).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Custom field(s) can't be deactivated");
                }

                return combineLatest([this.item$, this.templateRestService.getTemplateItemsByTemplateId(setCategoryTemplateItemsFlagData.templateId)]);
            }),
            take(1),
            map(([item, templateItems]: [TreeNode<TreeNodeDataModel>, TemplateItemResponseModel[]]) => {

                let categoryTemplateItems: CategoryTemplateItemsDataModel = {
                    categoryId: item.data.id,
                    templateId: setCategoryTemplateItemsFlagData.templateId,
                    templateItems: templateItems.map((ti: TemplateItemResponseModel) => new TemplateItemDataModel(ti))
                };

                this.store.dispatch(new actions.SetCategoryTemplateItems(categoryTemplateItems));
                this.store.dispatch(new actions.SetLoadingCategoryTemplateItems(false));
            })
        ).subscribe();
    }

    setRequiredCategoryTemplateItems(setCategoryTemplateItemsFlagData: SetCategoryTemplateItemsFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingCategoryTemplateItems(true));

        this.templateRestService.setRequiredTemplateItems(setCategoryTemplateItemsFlagData.templateItemIds).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Custom field(s) can't be set required");
                }

                return combineLatest([this.item$, this.templateRestService.getTemplateItemsByTemplateId(setCategoryTemplateItemsFlagData.templateId)]);
            }),
            take(1),
            map(([item, templateItems]: [TreeNode<TreeNodeDataModel>, TemplateItemResponseModel[]]) => {

                let categoryTemplateItems: CategoryTemplateItemsDataModel = {
                    categoryId: item.data.id,
                    templateId: setCategoryTemplateItemsFlagData.templateId,
                    templateItems: templateItems.map((ti: TemplateItemResponseModel) => new TemplateItemDataModel(ti))
                };

                this.store.dispatch(new actions.SetCategoryTemplateItems(categoryTemplateItems));
                this.store.dispatch(new actions.SetLoadingCategoryTemplateItems(false));
            })
        ).subscribe();
    }

    setNotRequiredCategoryTemplateItems(setCategoryTemplateItemsFlagData: SetCategoryTemplateItemsFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingCategoryTemplateItems(true));

        this.templateRestService.setNotRequiredTemplateItems(setCategoryTemplateItemsFlagData.templateItemIds).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Custom field(s) can't be set not required");
                }

                return combineLatest([this.item$, this.templateRestService.getTemplateItemsByTemplateId(setCategoryTemplateItemsFlagData.templateId)]);
            }),
            take(1),
            map(([item, templateItems]: [TreeNode<TreeNodeDataModel>, TemplateItemResponseModel[]]) => {

                let categoryTemplateItems: CategoryTemplateItemsDataModel = {
                    categoryId: item.data.id,
                    templateId: setCategoryTemplateItemsFlagData.templateId,
                    templateItems: templateItems.map((ti: TemplateItemResponseModel) => new TemplateItemDataModel(ti))
                };

                this.store.dispatch(new actions.SetCategoryTemplateItems(categoryTemplateItems));
                this.store.dispatch(new actions.SetLoadingCategoryTemplateItems(false));
            })
        ).subscribe();
    }

    setDisplayOnTicketCategoryTemplateItems(setCategoryTemplateItemsFlagData: SetCategoryTemplateItemsFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingCategoryTemplateItems(true));

        this.templateRestService.setDisplayOnTicketTemplateItems(setCategoryTemplateItemsFlagData.templateItemIds).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Custom field(s) can't be set to be displayed on ticket");
                }

                return combineLatest([this.item$, this.templateRestService.getTemplateItemsByTemplateId(setCategoryTemplateItemsFlagData.templateId)]);
            }),
            take(1),
            map(([item, templateItems]: [TreeNode<TreeNodeDataModel>, TemplateItemResponseModel[]]) => {

                let categoryTemplateItems: CategoryTemplateItemsDataModel = {
                    categoryId: item.data.id,
                    templateId: setCategoryTemplateItemsFlagData.templateId,
                    templateItems: templateItems.map((ti: TemplateItemResponseModel) => new TemplateItemDataModel(ti))
                };

                this.store.dispatch(new actions.SetCategoryTemplateItems(categoryTemplateItems));
                this.store.dispatch(new actions.SetLoadingCategoryTemplateItems(false));
            })
        ).subscribe();
    }

    setNotDisplayOnTicketCategoryTemplateItems(setCategoryTemplateItemsFlagData: SetCategoryTemplateItemsFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingCategoryTemplateItems(true));

        this.templateRestService.setNotDisplayOnTicketTemplateItems(setCategoryTemplateItemsFlagData.templateItemIds).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Custom field(s) can't be set to not be displayed on ticket");
                }

                return combineLatest([this.item$, this.templateRestService.getTemplateItemsByTemplateId(setCategoryTemplateItemsFlagData.templateId)]);
            }),
            take(1),
            map(([item, templateItems]: [TreeNode<TreeNodeDataModel>, TemplateItemResponseModel[]]) => {

                let categoryTemplateItems: CategoryTemplateItemsDataModel = {
                    categoryId: item.data.id,
                    templateId: setCategoryTemplateItemsFlagData.templateId,
                    templateItems: templateItems.map((ti: TemplateItemResponseModel) => new TemplateItemDataModel(ti))
                };

                this.store.dispatch(new actions.SetCategoryTemplateItems(categoryTemplateItems));
                this.store.dispatch(new actions.SetLoadingCategoryTemplateItems(false));
            })
        ).subscribe();
    }

    /**
     * PRODUCT ACTIONS
     */

    selectProductTabItem(productTabItem: ProductTabItemEnum) {
        this.store.dispatch(new actions.SetSelectedProductTabItem(productTabItem));
    }

    enableProduct(productId: number) {

        this.categoriesAndProductsTree$.pipe(
            take(1),
            flatMap((categoriesAndProductsTree: TreeNode<TreeNodeDataModel>[]) => {

                let productNode: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(productId, TreeItemTypeEnum.PRODUCT, categoriesAndProductsTree);
                let categoryNode: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(productNode.data.parentId, TreeItemTypeEnum.CATEGORY, categoriesAndProductsTree);

                if (!categoryNode.active) {
                    this.rootSandbox.addInfoNotification(`You can't activate product because product's category (${categoryNode.data.label}) is inactive! Try activating category first`);
                    return observableOf(null);
                }

                this.store.dispatch(new actions.SetLoadingCategoriesAndProducts(true));

                return this.productRestService.activate(productId);
            }),
            take(1),
            filter((response: any) => response !== null),
            flatMap((response: any) => {

                return combineLatest([this.categoryRestService.getAll(), this.productRestService.getAll()]);
            }),
            take(1),
            map(([categories, products]: [CategoryResponseModel[], ProductSimpleResponseModel[]]) => {

                let nodes: TreeNode<TreeNodeDataModel>[] = this.getCategoriesAndProductsTree(categories, products);

                this.store.dispatch(new actions.SetLoadingCategoriesAndProducts(false));
                this.store.dispatch(new actions.SetCategoriesAndProductsTree(nodes));

                let enabledProductNode: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(productId, TreeItemTypeEnum.PRODUCT, nodes);

                this.setProduct(enabledProductNode);
            })
        ).subscribe();
    }

    disableProduct(productId: number) {

        this.store.dispatch(new actions.SetLoadingCategoriesAndProducts(true));

        this.productRestService.deactivate(productId).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Product can't be disabled");
                }

                return combineLatest([this.categoryRestService.getAll(), this.productRestService.getAll()]);
            }),
            take(1),
            map(([categories, products]: [CategoryResponseModel[], ProductSimpleResponseModel[]]) => {

                let nodes: TreeNode<TreeNodeDataModel>[] = this.getCategoriesAndProductsTree(categories, products);

                this.store.dispatch(new actions.SetLoadingCategoriesAndProducts(false));
                this.store.dispatch(new actions.SetCategoriesAndProductsTree(nodes));
                this.store.dispatch(new actions.SetShowInactiveItems(true));

                let disabledProductNode: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(productId, TreeItemTypeEnum.PRODUCT, nodes);

                this.setProduct(disabledProductNode);
            })
        ).subscribe();
    }

    updateProductImage(productId: number, image: File) {

        this.store.dispatch(new actions.SetLoadingProductImages(true));

        let request: FormData = new FormData();
        request.append("image", image);

        this.productRestService.updateProductImage(productId, request)
            .subscribe((response: ImageResponseModel) => {

                this.store.dispatch(new actions.SetLoadingProductImages(false));
                this.store.dispatch(new actions.SetProductImage(response));
                this.rootSandbox.addInfoNotification("Product Image Uploaded!");
            });
    }

    removeProductImage(productId: number) {

        this.store.dispatch(new actions.SetLoadingProductImages(true));

        this.productRestService.removeProductImage(productId)
            .subscribe(() => {

                this.store.dispatch(new actions.SetLoadingProductImages(false));
                this.store.dispatch(new actions.SetProductImage({
                    imageAmazonFilePath: null,
                    imageBase64: null
                }));
                this.rootSandbox.addInfoNotification("Product Image Removed!");
            });
    }

    updateTicketBackgroundImage(productId: number, image: File) {

        this.store.dispatch(new actions.SetLoadingProductImages(true));

        let request: FormData = new FormData();
        request.append("image", image);

        this.productRestService.updateTicketBackgroundImage(productId, request)
            .subscribe((response: ImageResponseModel) => {

                this.store.dispatch(new actions.SetLoadingProductImages(false));
                this.store.dispatch(new actions.SetTicketBackgroundImage(response));
                this.rootSandbox.addInfoNotification("Ticket Background Image Uploaded!");
            });
    }

    removeTicketBackgroundImage(productId: number) {

        this.store.dispatch(new actions.SetLoadingProductImages(true));

        this.productRestService.removeTicketBackgroundImage(productId)
            .subscribe(() => {

                this.store.dispatch(new actions.SetLoadingProductImages(false));
                this.store.dispatch(new actions.SetTicketBackgroundImage({
                    imageAmazonFilePath: null,
                    imageBase64: null
                }));
                this.rootSandbox.addInfoNotification("Ticket Background Image Removed!");
            });
    }

    /**
     * PRODUCT AVAILABILITY ACTIONS
     */

    loadProductAvailabilitiesSidebarFilterData(initial: boolean) {

        this.departureGroupRestService.getAll().pipe(
            take(1))
            .subscribe((departureGroups: DepartureGroupResponseModel[]) => {

                this.store.dispatch(new actions.SetProductAvailabilitiesSidebarFilterDepartureGroups(departureGroups));

                // Set initial product availabilities sidebar filter data
                let initialProductAvailabilitiesSidebarFilterData: ProductAvailabilitiesSidebarFilterDataModel = this._createInitialProductAvailabilitiesSidebarFilterData(departureGroups);
                this.store.dispatch(new actions.SetProductAvailabilitiesSidebarFilterData(initialProductAvailabilitiesSidebarFilterData));
                if (!initial) {
                    this.loadProductAvailabilities(initialProductAvailabilitiesSidebarFilterData);
                }
            });
    }

    toggleProductAvailabilitiesSidebarFilterOpen() {
        this.productAvailabilitiesSidebarFilterOpen$.pipe(take(1)).subscribe((productAvailabilitiesSidebarFilterOpen: boolean) => {
            this.store.dispatch(new actions.SetProductAvailabilitiesSidebarFilterOpen(!productAvailabilitiesSidebarFilterOpen));
        });
    }

    showProductAvailabilitiesSidebarFilter(productAvailabilitiesFocusedFilter: ProductAvailabilitiesSidebarFilterTypeEnum) {
        this.store.dispatch(new actions.SetProductAvailabilitiesSidebarFilterOpen(true, productAvailabilitiesFocusedFilter));
    }

    productAvailabilitiesClearFilter() {
        this.loadProductAvailabilitiesSidebarFilterData(false)
    }

    loadProductAvailabilities(productAvailabilitiesSidebarFilterData: ProductAvailabilitiesSidebarFilterDataModel) {

        // Keep productId
        let productId: number;

        // Clear current results
        let productAvailabilities: ProductAvailabilitiesDataModel = {
            productId: productId,
            totalNumberOfResults: 0,
            productAvailabilities: []
        };
        this.store.dispatch(new actions.SetProductAvailabilities(false, productAvailabilities));

        this.store.dispatch(new actions.SetLoadingProductAvailabilities(true));

        this.item$.pipe(
            take(1),
            flatMap((treeNode: TreeNode<TreeNodeDataModel>) => {

                // Keep productId
                productId = treeNode.data.id;

                return this._loadProductAvailabilities(productId, productAvailabilitiesSidebarFilterData, 0);
            }),
            take(1),
            map((productAvailabilitiesFull: ProductAvailabilitiesFullResponseModel) => {

                let productAvailabilities: ProductAvailabilitiesDataModel = {
                    productId: productId,
                    totalNumberOfResults: productAvailabilitiesFull.totalNumberOfResults,
                    productAvailabilities: productAvailabilitiesFull.productAvailabilities.map((pa: ProductAvailabilityFullResponseModel) => new ProductAvailabilityDataModel(pa))
                };

                this.store.dispatch(new actions.SetProductAvailabilities(false, productAvailabilities));
                this.store.dispatch(new actions.SetProductAvailabilitiesFrom(0));
                this.store.dispatch(new actions.SetProductAvailabilitiesSidebarFilterData(productAvailabilitiesSidebarFilterData));
                this.store.dispatch(new actions.SetLoadingProductAvailabilities(false));
                this.store.dispatch(new actions.SetProductAvailabilitiesSidebarFilterOpen(false));
            })
        ).subscribe();
    }

    loadMoreProductAvailabilities() {

        // Keep productId and productAvailabilitiesFrom
        let productId: number;
        let productAvailabilitiesFrom: number;

        this.store.dispatch(new actions.SetLoadingProductAvailabilities(true));

        combineLatest([this.item$, this.productAvailabilitiesSidebarFilterData$, this.productAvailabilitiesFrom$]).pipe(
            take(1),
            flatMap(([treeNode, productAvailabilitiesSidebarFilterData, aProductAvailabilitiesFrom]: [TreeNode<TreeNodeDataModel>, ProductAvailabilitiesSidebarFilterDataModel, number]) => {

                // Keep productId and productAvailabilitiesFrom
                productId = treeNode.data.id;
                productAvailabilitiesFrom = aProductAvailabilitiesFrom;

                return this._loadProductAvailabilities(productId, productAvailabilitiesSidebarFilterData, productAvailabilitiesFrom + this.PRODUCT_AVAILABILITIES_PAGE_SIZE);
            }),
            take(1),
            map((productAvailabilitiesFull: ProductAvailabilitiesFullResponseModel) => {

                let productAvailabilities: ProductAvailabilitiesDataModel = {
                    productId: productId,
                    totalNumberOfResults: productAvailabilitiesFull.totalNumberOfResults,
                    productAvailabilities: productAvailabilitiesFull.productAvailabilities.map((pa: ProductAvailabilityFullResponseModel) => new ProductAvailabilityDataModel(pa))
                };

                this.store.dispatch(new actions.SetProductAvailabilities(true, productAvailabilities));
                this.store.dispatch(new actions.SetProductAvailabilitiesFrom(productAvailabilitiesFrom + this.PRODUCT_AVAILABILITIES_PAGE_SIZE));
                this.store.dispatch(new actions.SetLoadingProductAvailabilities(false));
            })
        ).subscribe();
    }

    updateProductAvailabilitiesSortBy(productAvailabilitiesSortBy: SortByModel) {

        this.store.dispatch(new actions.UpdateProductAvailabilitiesCurrentSearchDataSortBy(productAvailabilitiesSortBy));
        this.productAvailabilitiesSidebarFilterData$.pipe(
            take(1))
            .subscribe((productAvailabilitiesSidebarFilterData: ProductAvailabilitiesSidebarFilterDataModel) => {
                this.loadProductAvailabilities(productAvailabilitiesSidebarFilterData);
            });
    }

    setActiveProductAvailabilities(setProductAvailabilitiesFlagData: SetProductAvailabilitiesFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingProductAvailabilities(true));

        combineLatest([this.productAvailabilityRestService.activateProductAvailabilities(setProductAvailabilitiesFlagData.productAvailabilityIds), this.productAvailabilitiesSidebarFilterData$]).pipe(
            take(1),
            flatMap(([response, productAvailabilitiesSidebarFilterData]: [any, ProductAvailabilitiesSidebarFilterDataModel]) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Schedule(s) can't be activated");
                }

                return this._loadProductAvailabilities(setProductAvailabilitiesFlagData.productId, productAvailabilitiesSidebarFilterData, 0);
            }),
            take(1),
            map((productAvailabilitiesFull: ProductAvailabilitiesFullResponseModel) => {

                let productAvailabilities: ProductAvailabilitiesDataModel = {
                    productId: setProductAvailabilitiesFlagData.productId,
                    totalNumberOfResults: productAvailabilitiesFull.totalNumberOfResults,
                    productAvailabilities: productAvailabilitiesFull.productAvailabilities.map((pa: ProductAvailabilityFullResponseModel) => new ProductAvailabilityDataModel(pa))
                };

                this.store.dispatch(new actions.SetProductAvailabilities(false, productAvailabilities));
                this.store.dispatch(new actions.SetProductAvailabilitiesFrom(0));
                this.store.dispatch(new actions.SetLoadingProductAvailabilities(false));
            })
        ).subscribe();
    }

    setNotActiveProductAvailabilities(setProductAvailabilitiesFlagData: SetProductAvailabilitiesFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingProductAvailabilities(true));

        combineLatest([this.productAvailabilityRestService.deactivateProductAvailabilities(setProductAvailabilitiesFlagData.productAvailabilityIds), this.productAvailabilitiesSidebarFilterData$]).pipe(
            take(1),
            flatMap(([response, productAvailabilitiesSidebarFilterData]: [any, ProductAvailabilitiesSidebarFilterDataModel]) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Schedule(s) can't be deactivated");
                }

                return this._loadProductAvailabilities(setProductAvailabilitiesFlagData.productId, productAvailabilitiesSidebarFilterData, 0);
            }),
            take(1),
            map((productAvailabilitiesFull: ProductAvailabilitiesFullResponseModel) => {

                let productAvailabilities: ProductAvailabilitiesDataModel = {
                    productId: setProductAvailabilitiesFlagData.productId,
                    totalNumberOfResults: productAvailabilitiesFull.totalNumberOfResults,
                    productAvailabilities: productAvailabilitiesFull.productAvailabilities.map((pa: ProductAvailabilityFullResponseModel) => new ProductAvailabilityDataModel(pa))
                };

                this.store.dispatch(new actions.SetProductAvailabilities(false, productAvailabilities));
                this.store.dispatch(new actions.SetProductAvailabilitiesFrom(0));
                this.store.dispatch(new actions.SetLoadingProductAvailabilities(false));
            })
        ).subscribe();
    }

    /**
     * PRICING DATE RANGE ACTIONS
     */

    loadProductPricingDateRangesSidebarFilterData(initial: boolean) {

        combineLatest([this.locationRestService.getAll(), this.tierRestService.getAll()]).pipe(
            take(1),
            map(([locations, tiers]: [LocationsResponseModel, TiersResponseModel]) => {

                this.store.dispatch(new actions.SetProductPricingDateRangesSidebarFilterPickupLocations(locations.locations));
                this.store.dispatch(new actions.SetProductPricingDateRangesSidebarFilterDropoffLocations(locations.locations));
                this.store.dispatch(new actions.SetProductPricingDateRangesSidebarFilterTiers(tiers.tiers));

                // Set initial product pricing date ranges sidebar filter data
                let initialProductPricingDateRangesSidebarFilterData: ProductPricingDateRangesSidebarFilterDataModel = this._createInitialProductPricingDateRangesSidebarFilterData(locations.locations, tiers.tiers);
                this.store.dispatch(new actions.SetProductPricingDateRangesSidebarFilterData(initialProductPricingDateRangesSidebarFilterData));
                if (!initial) {
                    this.loadProductPricingDateRanges(initialProductPricingDateRangesSidebarFilterData);
                }
            })
        ).subscribe();
    }

    toggleProductPricingDateRangesSidebarFilterOpen() {
        this.productPricingDateRangesSidebarFilterOpen$.pipe(take(1)).subscribe((productPricingDateRangesSidebarFilterOpen: boolean) => {
            this.store.dispatch(new actions.SetProductPricingDateRangesSidebarFilterOpen(!productPricingDateRangesSidebarFilterOpen));
        });
    }

    showProductPricingDateRangesSidebarFilter(productPricingDateRangesFocusedFilter: ProductPricingDateRangesSidebarFilterTypeEnum) {
        this.store.dispatch(new actions.SetProductPricingDateRangesSidebarFilterOpen(true, productPricingDateRangesFocusedFilter));
    }

    productPricingDateRangesClearFilter() {
        this.loadProductPricingDateRangesSidebarFilterData(false)
    }

    loadProductPricingDateRanges(productPricingDateRangesSidebarFilterData: ProductPricingDateRangesSidebarFilterDataModel) {

        // Keep productId
        let productId: number;

        // Clear current results
        let productPricingDateRanges: ProductPricingDateRangesDataModel = {
            productId: productId,
            totalNumberOfResults: 0,
            pricingDateRanges: []
        };
        this.store.dispatch(new actions.SetProductPricingDateRanges(false, productPricingDateRanges));

        this.store.dispatch(new actions.SetLoadingProductPricingDateRanges(true));

        this.item$.pipe(
            take(1),
            flatMap((treeNode: TreeNode<TreeNodeDataModel>) => {

                // Keep productId
                productId = treeNode.data.id;

                return this._loadProductPricingDateRanges(productId, productPricingDateRangesSidebarFilterData, 0);
            }),
            take(1),
            map((pricingDateRangesFull: PricingDateRangesFullResponseModel) => {

                let productPricingDateRanges: ProductPricingDateRangesDataModel = {
                    productId: productId,
                    totalNumberOfResults: pricingDateRangesFull.totalNumberOfResults,
                    pricingDateRanges: pricingDateRangesFull.pricingDateRanges.map((pdr: PricingDateRangeFullResponseModel) => new PricingDateRangeDataModel(pdr))
                };

                this.store.dispatch(new actions.SetProductPricingDateRanges(false, productPricingDateRanges));
                this.store.dispatch(new actions.SetProductPricingDateRangesFrom(0));
                this.store.dispatch(new actions.SetProductPricingDateRangesSidebarFilterData(productPricingDateRangesSidebarFilterData));
                this.store.dispatch(new actions.SetLoadingProductPricingDateRanges(false));
                this.store.dispatch(new actions.SetProductPricingDateRangesSidebarFilterOpen(false));
            })
        ).subscribe();
    }

    loadMoreProductPricingDateRanges() {

        // Keep productId and productPricingDateRangesFrom
        let productId: number;
        let productPricingDateRangesFrom: number;

        this.store.dispatch(new actions.SetLoadingProductPricingDateRanges(true));

        combineLatest([this.item$, this.productPricingDateRangesSidebarFilterData$, this.productPricingDateRangesFrom$]).pipe(
            take(1),
            flatMap(([treeNode, productPricingDateRangesSidebarFilterData, aProductPricingDateRangesFrom]: [TreeNode<TreeNodeDataModel>, ProductPricingDateRangesSidebarFilterDataModel, number]) => {

                // Keep productId and productPricingDateRangesFrom
                productId = treeNode.data.id;
                productPricingDateRangesFrom = aProductPricingDateRangesFrom;

                return this._loadProductPricingDateRanges(productId, productPricingDateRangesSidebarFilterData, productPricingDateRangesFrom + this.PRODUCT_PRICING_DATE_RANGES_PAGE_SIZE);
            }),
            take(1),
            map((pricingDateRangesFull: PricingDateRangesFullResponseModel) => {

                let productPricingDateRanges: ProductPricingDateRangesDataModel = {
                    productId: productId,
                    totalNumberOfResults: pricingDateRangesFull.totalNumberOfResults,
                    pricingDateRanges: pricingDateRangesFull.pricingDateRanges.map((pdr: PricingDateRangeFullResponseModel) => new PricingDateRangeDataModel(pdr))
                };

                this.store.dispatch(new actions.SetProductPricingDateRanges(true, productPricingDateRanges));
                this.store.dispatch(new actions.SetProductPricingDateRangesFrom(productPricingDateRangesFrom + this.PRODUCT_PRICING_DATE_RANGES_PAGE_SIZE));
                this.store.dispatch(new actions.SetLoadingProductPricingDateRanges(false));
            })
        ).subscribe();
    }

    updateProductPricingDateRangesSortBy(productPricingDateRangesSortBy: SortByModel) {

        this.store.dispatch(new actions.UpdateProductPricingDateRangesCurrentSearchDataSortBy(productPricingDateRangesSortBy));
        this.productPricingDateRangesSidebarFilterData$.pipe(
            take(1))
            .subscribe((productPricingDateRangesSidebarFilterData: ProductPricingDateRangesSidebarFilterDataModel) => {
                this.loadProductPricingDateRanges(productPricingDateRangesSidebarFilterData);
            });
    }

    priorityUpProductPricingDateRange(setProductPricingDateRangeFlagData: SetProductPricingDateRangeFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingProductPricingDateRanges(true));

        let updatePricingDateRangePriorityRequest: UpdatePricingDateRangePriorityRequestModel = new UpdatePricingDateRangePriorityRequestModel(
            setProductPricingDateRangeFlagData.pricingDateRangeId,
            setProductPricingDateRangeFlagData.productId,
            null,
            true
        );

        combineLatest([this.pricingDateRangeRestService.updatePricingDateRangePriority(updatePricingDateRangePriorityRequest), this.productPricingDateRangesSidebarFilterData$]).pipe(
            take(1),
            flatMap(([response, productPricingDateRangesSidebarFilterData]: [any, ProductPricingDateRangesSidebarFilterDataModel]) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Pricing(s)' priority can't be changed");
                }

                return this._loadProductPricingDateRanges(setProductPricingDateRangeFlagData.productId, productPricingDateRangesSidebarFilterData, 0);
            }),
            take(1),
            map((pricingDateRangesFull: PricingDateRangesFullResponseModel) => {

                let productPricingDateRanges: ProductPricingDateRangesDataModel = {
                    productId: setProductPricingDateRangeFlagData.productId,
                    totalNumberOfResults: pricingDateRangesFull.totalNumberOfResults,
                    pricingDateRanges: pricingDateRangesFull.pricingDateRanges.map((pdr: PricingDateRangeFullResponseModel) => new PricingDateRangeDataModel(pdr))
                };

                this.store.dispatch(new actions.SetProductPricingDateRanges(false, productPricingDateRanges));
                this.store.dispatch(new actions.SetProductPricingDateRangesFrom(0));
                this.store.dispatch(new actions.SetLoadingProductPricingDateRanges(false));
            })
        ).subscribe();
    }

    priorityDownProductPricingDateRange(setProductPricingDateRangeFlagData: SetProductPricingDateRangeFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingProductPricingDateRanges(true));

        let updatePricingDateRangePriorityRequest: UpdatePricingDateRangePriorityRequestModel = new UpdatePricingDateRangePriorityRequestModel(
            setProductPricingDateRangeFlagData.pricingDateRangeId,
            setProductPricingDateRangeFlagData.productId,
            null,
            false
        );

        combineLatest([this.pricingDateRangeRestService.updatePricingDateRangePriority(updatePricingDateRangePriorityRequest), this.productPricingDateRangesSidebarFilterData$]).pipe(
            take(1),
            flatMap(([response, productPricingDateRangesSidebarFilterData]: [any, ProductPricingDateRangesSidebarFilterDataModel]) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Pricing(s)' priority can't be changed");
                }

                return this._loadProductPricingDateRanges(setProductPricingDateRangeFlagData.productId, productPricingDateRangesSidebarFilterData, 0);
            }),
            take(1),
            map((pricingDateRangesFull: PricingDateRangesFullResponseModel) => {

                let productPricingDateRanges: ProductPricingDateRangesDataModel = {
                    productId: setProductPricingDateRangeFlagData.productId,
                    totalNumberOfResults: pricingDateRangesFull.totalNumberOfResults,
                    pricingDateRanges: pricingDateRangesFull.pricingDateRanges.map((pdr: PricingDateRangeFullResponseModel) => new PricingDateRangeDataModel(pdr))
                };

                this.store.dispatch(new actions.SetProductPricingDateRanges(false, productPricingDateRanges));
                this.store.dispatch(new actions.SetProductPricingDateRangesFrom(0));
                this.store.dispatch(new actions.SetLoadingProductPricingDateRanges(false));
            })
        ).subscribe();
    }

    setActiveProductPricingDateRanges(setProductPricingDateRangesFlagData: SetProductPricingDateRangesFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingProductPricingDateRanges(true));

        combineLatest([this.pricingDateRangeRestService.activatePricingDateRanges(setProductPricingDateRangesFlagData.pricingDateRangeIds), this.productPricingDateRangesSidebarFilterData$]).pipe(
            take(1),
            flatMap(([response, productPricingDateRangesSidebarFilterData]: [any, ProductPricingDateRangesSidebarFilterDataModel]) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Pricing(s) can't be activated");
                }

                return this._loadProductPricingDateRanges(setProductPricingDateRangesFlagData.productId, productPricingDateRangesSidebarFilterData, 0);
            }),
            take(1),
            map((pricingDateRangesFull: PricingDateRangesFullResponseModel) => {

                let productPricingDateRanges: ProductPricingDateRangesDataModel = {
                    productId: setProductPricingDateRangesFlagData.productId,
                    totalNumberOfResults: pricingDateRangesFull.totalNumberOfResults,
                    pricingDateRanges: pricingDateRangesFull.pricingDateRanges.map((pdr: PricingDateRangeFullResponseModel) => new PricingDateRangeDataModel(pdr))
                };

                this.store.dispatch(new actions.SetProductPricingDateRanges(false, productPricingDateRanges));
                this.store.dispatch(new actions.SetProductPricingDateRangesFrom(0));
                this.store.dispatch(new actions.SetLoadingProductPricingDateRanges(false));
            })
        ).subscribe();
    }

    setNotActiveProductPricingDateRanges(setProductPricingDateRangesFlagData: SetProductPricingDateRangesFlagDataModel) {

        this.store.dispatch(new actions.SetLoadingProductPricingDateRanges(true));

        combineLatest([this.pricingDateRangeRestService.deactivatePricingDateRanges(setProductPricingDateRangesFlagData.pricingDateRangeIds), this.productPricingDateRangesSidebarFilterData$]).pipe(
            take(1),
            flatMap(([response, productPricingDateRangesSidebarFilterData]: [any, ProductPricingDateRangesSidebarFilterDataModel]) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Pricing(s) can't be deactivated");
                }

                return this._loadProductPricingDateRanges(setProductPricingDateRangesFlagData.productId, productPricingDateRangesSidebarFilterData, 0);
            }),
            take(1),
            map((pricingDateRangesFull: PricingDateRangesFullResponseModel) => {

                let productPricingDateRanges: ProductPricingDateRangesDataModel = {
                    productId: setProductPricingDateRangesFlagData.productId,
                    totalNumberOfResults: pricingDateRangesFull.totalNumberOfResults,
                    pricingDateRanges: pricingDateRangesFull.pricingDateRanges.map((pdr: PricingDateRangeFullResponseModel) => new PricingDateRangeDataModel(pdr))
                };

                this.store.dispatch(new actions.SetProductPricingDateRanges(false, productPricingDateRanges));
                this.store.dispatch(new actions.SetProductPricingDateRangesFrom(0));
                this.store.dispatch(new actions.SetLoadingProductPricingDateRanges(false));
            })
        ).subscribe();
    }

    /**
     * COMMON METHODS
     */

    refreshViewAndSelect(id: number, type: TreeItemTypeEnum) {

        this.store.dispatch(new actions.SetLoadingCategoriesAndProducts(true));

        combineLatest([this.categoryRestService.getAll(), this.productRestService.getAll()]).pipe(
            take(1),
            map(([categories, products]: [CategoryResponseModel[], ProductSimpleResponseModel[]]) => {

                let nodes: TreeNode<TreeNodeDataModel>[] = this.getCategoriesAndProductsTree(categories, products);
                let node: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(id, type, nodes);

                this.store.dispatch(new actions.SetLoadingCategoriesAndProducts(false));
                this.store.dispatch(new actions.SetCategoriesAndProductsTree(nodes));

                // If inactive node has been selected/edited, set flag ShowInactiveItems to true
                if (!node.active) {
                    this.store.dispatch(new actions.SetShowInactiveItems(true));
                }

                if (type === TreeItemTypeEnum.CATEGORY) {
                    node.toggleOpen(true);
                    this.setCategory(node);
                    return;
                }

                if (type === TreeItemTypeEnum.PRODUCT) {
                    this.setProduct(node);
                    return;
                }
            })
        ).subscribe();
    }

    private getCategoriesAndProductsTree(categories: CategoryResponseModel[], products: ProductSimpleResponseModel[]): TreeNode<TreeNodeDataModel>[] {

        let result: TreeNode<TreeNodeDataModel>[] = [];

        categories = categories.sort((c1, c2) => (c1.description.toLowerCase() > c2.description.toLowerCase()) ? 1 : -1);

        categories.forEach((category: CategoryResponseModel) => {

            let categoryChildren: TreeNode<TreeNodeDataModel>[] = products
                .filter((p) => p.categoryId === category.categoryId)
                .sort((p1, p2) => (p1.description.toLowerCase() > p2.description.toLowerCase()) ? 1 : -1)
                .map((p) => {

                    let data: TreeNodeDataModel = new TreeNodeDataModel(TreeItemTypeEnum.PRODUCT, p.productId, p.categoryId, p.description);

                    return new TreeNode<TreeNodeDataModel>(data, [], p.active, false);
                });

            categoryChildren.push(new TreeNode<TreeNodeDataModel>(new TreeNodeDataModel(TreeItemTypeEnum.ADD_NEW_PRODUCT, null, category.categoryId, "Add New Product"), [], true, false));

            result.push(new TreeNode<TreeNodeDataModel>(new TreeNodeDataModel(TreeItemTypeEnum.CATEGORY, category.categoryId, null, category.description), categoryChildren, category.active, true));
        });

        result.push(new TreeNode<TreeNodeDataModel>(new TreeNodeDataModel(TreeItemTypeEnum.ADD_NEW_CATEGORY, null, null, "Add New Category"), [new TreeNode<TreeNodeDataModel>(new TreeNodeDataModel(null, null, null, null), [])], true, true));

        return result;
    }

    private findNodeByIdAndType(id: number, type: TreeItemTypeEnum, nodes: TreeNode<TreeNodeDataModel>[]): TreeNode<TreeNodeDataModel> {

        let result: TreeNode<TreeNodeDataModel> = null;

        for (let i = 0; result === null && i < nodes.length; i++) {
            result = this._findNodeByIdAndType(id, type, nodes[i]);
        }

        return result;
    }

    private _findNodeByIdAndType(id: number, type: TreeItemTypeEnum, node: TreeNode<TreeNodeDataModel>): TreeNode<TreeNodeDataModel> {

        if (node.data.id === id && node.data.type === type) {
            return node;
        } else if (node.children.length !== 0) {

            let result: TreeNode<TreeNodeDataModel> = null;

            for (let i = 0; result === null && i < node.children.length; i++) {
                result = this._findNodeByIdAndType(id, type, node.children[i]);
            }

            return result;
        }

        return null;
    }

    private _createInitialProductAvailabilitiesSidebarFilterData(departureGroups: DepartureGroupResponseModel[]): ProductAvailabilitiesSidebarFilterDataModel {

        let selectedShowInactive: boolean = false;
        let selectedShowInactiveTiers: boolean = false;
        let selectedShowInactivePickupLocations: boolean = false;
        let selectedShowInactiveDropoffLocations: boolean = false;
        let selectedShowInactiveDepartureGroups: boolean = false;
        let selectedStartDate: moment.Moment = moment();
        let selectedEndDate: moment.Moment = moment();
        let selectedStartTimeFrom: string = "0";
        let selectedStartTimeTo: string = "0";
        let selectedDaysOfWeek: DayOfWeekEnum[] = [DayOfWeekEnum.SUNDAY, DayOfWeekEnum.MONDAY, DayOfWeekEnum.TUESDAY, DayOfWeekEnum.WEDNESDAY, DayOfWeekEnum.THURSDAY, DayOfWeekEnum.FRIDAY, DayOfWeekEnum.SATURDAY];
        let selectedDepartureGroupIds: number[] = departureGroups.length > 0 ? [0, ...departureGroups.map(dg => dg.departureGroupId)] : departureGroups.map(dg => dg.departureGroupId);
        let selectedDepartureGroupDescriptions: string[] = departureGroups.length > 0 ? ["None", ...departureGroups.map(dg => dg.description)] : departureGroups.map(dg => dg.description);
        let selectedProductAvailabilityDescription: string = null;

        let initialProductAvailabilitiesSidebarFilterData: ProductAvailabilitiesSidebarFilterDataModel = {
            showInactive: selectedShowInactive,
            showInactiveTiers: selectedShowInactiveTiers,
            showInactivePickupLocations: selectedShowInactivePickupLocations,
            showInactiveDropoffLocations: selectedShowInactiveDropoffLocations,
            showInactiveDepartureGroups: selectedShowInactiveDepartureGroups,
            startDate: selectedStartDate,
            startDateFriendly: DateTimeUtility.getDateFriendly(selectedStartDate),
            endDate: selectedEndDate,
            endDateFriendly: DateTimeUtility.getDateFriendly(selectedEndDate),
            startTimeFrom: Number(selectedStartTimeFrom),
            startTimeFromFriendly: DateTimeUtility.getTimeFriendly(Number(selectedStartTimeFrom)),
            startTimeTo: Number(selectedStartTimeTo),
            startTimeToFriendly: DateTimeUtility.getTimeFriendly(Number(selectedStartTimeTo)),
            daysOfWeek: selectedDaysOfWeek,
            departureGroupIds: selectedDepartureGroupIds,
            departureGroupDescriptions: selectedDepartureGroupDescriptions,
            productAvailabilityDescription: selectedProductAvailabilityDescription
        };

        return initialProductAvailabilitiesSidebarFilterData;
    }

    private _loadProductAvailabilities(productId: number, productAvailabilitiesSidebarFilterData: ProductAvailabilitiesSidebarFilterDataModel, productAvailabilitiesFrom: number): Observable<ProductAvailabilitiesFullResponseModel> {

        this.store.dispatch(new actions.SetLoadingProductAvailabilities(true));

        return this.productAvailabilitiesCurrentSearchDataSortBy$.pipe(
            take(1),
            mergeMap((productAvailabilitiesCurrentSearchDataSortBy: SortByModel) => {

                let fieldName: string = null;
                let order: string = null;
                if (productAvailabilitiesCurrentSearchDataSortBy != null && productAvailabilitiesCurrentSearchDataSortBy !== undefined) {
                    if (productAvailabilitiesCurrentSearchDataSortBy.fieldName != null && productAvailabilitiesCurrentSearchDataSortBy.fieldName !== undefined) {
                        fieldName = productAvailabilitiesCurrentSearchDataSortBy.fieldName;
                    }
                    if (productAvailabilitiesCurrentSearchDataSortBy.order != null && productAvailabilitiesCurrentSearchDataSortBy.order !== undefined) {
                        order = productAvailabilitiesCurrentSearchDataSortBy.order;
                    }
                }

                const sortByRequest = new SortByRequestModel(fieldName, order);

                let request: GetProductAvailabilitiesFilteredRequestModel = new GetProductAvailabilitiesFilteredRequestModel(
                    productAvailabilitiesFrom,
                    this.PRODUCT_AVAILABILITIES_PAGE_SIZE,
                    productId,
                    DateTimeUtility.convertMomentToDateTimeDescriptor(productAvailabilitiesSidebarFilterData.startDate),
                    DateTimeUtility.convertMomentToDateTimeDescriptor(productAvailabilitiesSidebarFilterData.endDate),
                    productAvailabilitiesSidebarFilterData.startTimeFrom,
                    productAvailabilitiesSidebarFilterData.startTimeTo,
                    productAvailabilitiesSidebarFilterData.daysOfWeek,
                    productAvailabilitiesSidebarFilterData.departureGroupIds,
                    productAvailabilitiesSidebarFilterData.productAvailabilityDescription,
                    productAvailabilitiesSidebarFilterData.showInactive,
                    productAvailabilitiesSidebarFilterData.showInactiveTiers,
                    productAvailabilitiesSidebarFilterData.showInactivePickupLocations,
                    productAvailabilitiesSidebarFilterData.showInactiveDropoffLocations,
                    productAvailabilitiesSidebarFilterData.showInactiveDepartureGroups,
                    sortByRequest
                );

                return this.productAvailabilityRestService.getProductAvailabilitiesFiltered(request);
            }),);
    }

    private _createInitialProductPricingDateRangesSidebarFilterData(locations: LocationResponseModel[], tiers: TierResponseModel[]): ProductPricingDateRangesSidebarFilterDataModel {

        let selectedShowInactive: boolean = false;
        let selectedShowInactiveTiers: boolean = false;
        let selectedShowInactivePickupLocations: boolean = false;
        let selectedShowInactiveDropoffLocations: boolean = false;
        let selectedShowInactiveDepartureGroups: boolean = false;
        let allDates: boolean = false;
        let selectedStartDate: moment.Moment = moment();
        let selectedEndDate: moment.Moment = moment();
        let selectedPickupLocationIds: number[] = locations.map(l => l.locationId);
        let selectedPickupLocationDescriptions: string[] = locations.map(l => l.description);
        let selectedDropoffLocationIds: number[] = locations.map(l => l.locationId);
        let selectedDropoffLocationDescriptions: string[] = locations.map(l => l.description);
        let selectedTierIds: number[] = tiers.map(t => t.tierId);
        let selectedTierDescriptions: string[] = tiers.map(t => t.description);

        let initialProductPricingDateRangesSidebarFilterData: ProductPricingDateRangesSidebarFilterDataModel = {
            showInactive: selectedShowInactive,
            showInactiveTiers: selectedShowInactiveTiers,
            showInactivePickupLocations: selectedShowInactivePickupLocations,
            showInactiveDropoffLocations: selectedShowInactiveDropoffLocations,
            showInactiveDepartureGroups: selectedShowInactiveDepartureGroups,
            allDates: allDates,
            startDate: selectedStartDate,
            startDateFriendly: DateTimeUtility.getDateFriendly(selectedStartDate),
            endDate: selectedEndDate,
            endDateFriendly: DateTimeUtility.getDateFriendly(selectedEndDate),
            pickupLocationIds: selectedPickupLocationIds,
            pickupLocationDescriptions: selectedPickupLocationDescriptions,
            dropoffLocationIds: selectedDropoffLocationIds,
            dropoffLocationDescriptions: selectedDropoffLocationDescriptions,
            tierIds: selectedTierIds,
            tierDescriptions: selectedTierDescriptions
        };

        return initialProductPricingDateRangesSidebarFilterData;
    }

    private _loadProductPricingDateRanges(productId: number, productPricingDateRangesSidebarFilterData: ProductPricingDateRangesSidebarFilterDataModel, productPricingDateRangesFrom: number): Observable<PricingDateRangesFullResponseModel> {

        this.store.dispatch(new actions.SetLoadingProductPricingDateRanges(true));

        return this.productPricingDateRangesCurrentSearchDataSortBy$.pipe(
            take(1),
            mergeMap((productPricingDateRangesCurrentSearchDataSortBy: SortByModel) => {

                let fieldName: string = null;
                let order: string = null;
                if (productPricingDateRangesCurrentSearchDataSortBy != null && productPricingDateRangesCurrentSearchDataSortBy !== undefined) {
                    if (productPricingDateRangesCurrentSearchDataSortBy.fieldName != null && productPricingDateRangesCurrentSearchDataSortBy.fieldName !== undefined) {
                        fieldName = productPricingDateRangesCurrentSearchDataSortBy.fieldName;
                    }
                    if (productPricingDateRangesCurrentSearchDataSortBy.order != null && productPricingDateRangesCurrentSearchDataSortBy.order !== undefined) {
                        order = productPricingDateRangesCurrentSearchDataSortBy.order;
                    }
                }

                const sortByRequest = new SortByRequestModel(fieldName, order);

                // If flag "allDates" is on, set start and end date to proper values
                let startDateDescriptor: DateTimeDescriptor = productPricingDateRangesSidebarFilterData.allDates ?
                    new DateTimeDescriptor(1, 1, 1, 0, 0, 0, 0) :
                    DateTimeUtility.convertMomentToDateTimeDescriptor(productPricingDateRangesSidebarFilterData.startDate);
                let endDateDescriptor: DateTimeDescriptor = productPricingDateRangesSidebarFilterData.allDates ?
                    new DateTimeDescriptor(9999, 1, 1, 0, 0, 0, 0) :
                    DateTimeUtility.convertMomentToDateTimeDescriptor(productPricingDateRangesSidebarFilterData.endDate);

                let request: GetPricingDateRangesFilteredRequestModel = new GetPricingDateRangesFilteredRequestModel(
                    productPricingDateRangesFrom,
                    this.PRODUCT_PRICING_DATE_RANGES_PAGE_SIZE,
                    productId,
                    null,
                    startDateDescriptor,
                    endDateDescriptor,
                    productPricingDateRangesSidebarFilterData.pickupLocationIds,
                    productPricingDateRangesSidebarFilterData.dropoffLocationIds,
                    productPricingDateRangesSidebarFilterData.tierIds,
                    productPricingDateRangesSidebarFilterData.showInactive,
                    productPricingDateRangesSidebarFilterData.showInactiveTiers,
                    productPricingDateRangesSidebarFilterData.showInactivePickupLocations,
                    productPricingDateRangesSidebarFilterData.showInactiveDropoffLocations,
                    productPricingDateRangesSidebarFilterData.showInactiveDepartureGroups,
                    sortByRequest
                );

                return this.pricingDateRangeRestService.getPricingDateRangesFiltered(request);
            }),);
    }
}
