import { Component, OnInit } from '@angular/core';
import { RootSandbox } from '../../../../../core/store/root.sandbox';
import { AppRoutesService } from '../../../../../core/services/app-routes.service';
import { PermissionEnum } from "../../../../../shared/enums/permission.enum";


@Component({
    selector: 'app-secondary-nav-purchase',
    templateUrl: './secondary-nav-purchase.component.html'
})
export class SecondaryNavPurchaseComponent implements OnInit {

    appRoutesService: AppRoutesService;

    constructor(appRoutesService: AppRoutesService,
        private rootSandbox: RootSandbox) {
        this.appRoutesService = appRoutesService;
    }

    userPermissions: string[];

    ngOnInit() {
        this.rootSandbox.getUserInfo().subscribe(res => {
            if (res) {
                this.userPermissions = res.permissions
            }
        });

    }
    userSystemPermissions(item: string) {
        let isPresent: boolean = this.userPermissions.includes(item)
        return isPresent;
    }
    allSystemPermissions() {
        return PermissionEnum;
    }
}
