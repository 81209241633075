<app-dropdown
    [positionedOptionsList]="true"
    [options]='listOfFilterOptions'
    [usePlaceholder]="true"
    [preSelectedValue]="selectedOptionValue"
    [openedOnInit]="openedOnInit"
    [disabled]="!(hasRunOrderReportFullPermission || !hadInitialValue)"
    (changed)='dropdownOptionSelected($event)'>
</app-dropdown>

<app-datetimepicker
    #dateTimePicker
    [isSingleDatePicker]="false"
    [endDateValue]="toDate"
    [startDateValue]="fromDate"
    [hiddenInput]="true"
    [openDatePicker]="isCustomOption"
    (dateChanged)="onDateChange($event)"
    (datePickerClosed)="onCloseDatePicker()">
</app-datetimepicker>
