<div class="dialog-section">
    <h2>Printing Info</h2>

    <div class="placeholderText-emptySection"
        *ngIf="printingInfos === null || printingInfos === undefined || printingInfos.length === 0">
        No Printing Info to Display
    </div>

    <div class="summary-general-info-wrapper"
        *ngIf="!(printingInfos === null || printingInfos === undefined || printingInfos.length === 0)">
        <table style="width:100%">
            <thead>
                <tr>
                    <th>User</th>
                    <th>Printing Date/Time</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let printingInfo of printingInfos">
                    <tr>
                        <td>{{printingInfo.printedByDisplayName}}</td>
                        <td>{{printingInfo.printingDateTimeFriendly}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>