import {ExpressionItemModel} from './expression-item.model';
import {UUID} from 'angular2-uuid';
import {ExpressionOperatorTypeEnum} from './expression-operator-type.enum';
import {ExpressionItemTypeEnum} from './expression-item-type.enum';

export class ExpressionOperatorModel implements ExpressionItemModel {
    readonly itemType: ExpressionItemTypeEnum = ExpressionItemTypeEnum.OPERATOR;
    readonly uuid: string = UUID.UUID();

    operator: ExpressionOperatorTypeEnum;

    constructor(operator: ExpressionOperatorTypeEnum) {
        this.operator = operator;
    }
}
