import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

@Component({
    selector: 'app-create-dropoff-location-dialog-content',
    templateUrl: './create-dropoff-location-dialog-content.component.html'
})
export class CreateDropoffLocationDialogContentComponent implements OnInit {

    @Input() allLocations: LocationResponseModel[];

    @Output() submitEvent = new EventEmitter<number>();
    @Output() cancelEvent = new EventEmitter<void>();

    form: FormGroup;
    allLocationsOptions: DropdownOptionModel[];

    constructor() {
    }

    ngOnInit() {

        this.form = new FormGroup({
            dropoffLocation: new FormControl(null, Validators.required),
        });

        this.allLocationsOptions = this.allLocations.map((location: LocationResponseModel) => {
            return new DropdownOptionModel(location.description, location.locationId.toString());
        });
    }

    onSubmitClick() {
        this.submitEvent.emit(Number(this.form.value.dropoffLocation));
    }

    onCloseClick() {
        this.cancelEvent.emit();
    }
}
