<div class="page-wrapper driver-manifest-report-page">

    <div class="header_wrapper">
        <app-header [pageBreadcrumbItems]="pageBreadcrumbItems">
        </app-header>
    </div>

    <app-driver-manifest-report-content
        [pageBreadcrumbItems]="pageBreadcrumbItems"
        [favorites]="favorites$ | async"
        [loadingFavorites]="loadingFavorites$ | async"
        [selectedFavoriteId]="selectedFavoriteId$ | async"
        [hasUseFavoritesPermission]="hasUseFavoritesPermission$ | async"
        [hasCreateFavoritePermission]="hasCreateFavoritePermission$ | async"
        [hasEditFavoritePermission]="hasEditFavoritePermission$ | async"
        [hasRemoveFavoritePermission]="hasRemoveFavoritePermission$ | async"
        [productDirections]="productDirections$ | async"
        [loadingProducts]="loadingProducts$ | async"
        [selectedDateType]="selectedDateType$ | async"
        [selectedDate]="selectedDate$ | async"
        [selectedProductDirections]="selectedProductDirections$ | async"
        [currentSearchData]="currentSearchData$ | async"
        [currentSearchDataSortBy]="currentSearchDataSortBy$ | async"
        [favoriteCreatedUpdated]="favoriteCreatedUpdated$ | async"
        (filterChangedEvent)="onFilterChanged($event)"
        (clearSearchDataEvent)="onClearSearchData()"
        (runSearchEvent)="onRunSearch()"
        (generateUrlForExportToXlsxDriverManifestReportEvent)="onGenerateUrlForExportToXlsxDriverManifestReport()"
        (generateUrlForExportToPdfDriverManifestReportEvent)="onGenerateUrlForExportToPdfDriverManifestReport()"
        (printDriverManifestReportEvent)="onPrintDriverManifestReport()"
        (headerSortEvent)="onHeaderSort($event)"
        (showSummaryEvent)="onShowSummary($event)"
        (selectedFavoriteChangedEvent)="onSelectedFavoriteChanged($event)"
        (editFavoriteEvent)="onEditFavorite($event)"
        (removeFavoriteEvent)="onRemoveFavorite($event)">
    </app-driver-manifest-report-content>

</div>
