
import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {EmailHtmlTemplateRestService} from "../../../../../../../../core/modules/rest/email-html-template/email-html-template-rest.service";
import {Observable} from "rxjs";
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {select, Store} from "@ngrx/store";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {CreateEmailHtmlTemplateRequestModel} from "../../../../../../../../core/modules/rest/email-html-template/request/create-email-html-template-request.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {UpdateEmailHtmlTemplateRequestModel} from "../../../../../../../../core/modules/rest/email-html-template/request/update-email-html-template-request.model";
import {EditHtmlTemplateFormSubmitDataModel} from "../model/edit-html-template-form-submit-data.model";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";

@Injectable()
export class EditEmailHtmlTemplateDialogSandbox extends DialogSandbox {


    emailHtmlTemplate$: Observable<EmailHtmlTemplateResponseModel> = this.store.pipe(
        select(reducer.emailHtmlTemplate_selector)
    );

    constructor(appDialogsService: AppDialogsService,
                private emailHtmlTemplateRestService: EmailHtmlTemplateRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {

        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadEmailHtmlTemplate(emailHtmlTemplateId: number) {

        this.showLoader();

        this.emailHtmlTemplateRestService.getById(emailHtmlTemplateId)
            .subscribe((emailHtmlTemplate: EmailHtmlTemplateResponseModel) => {
                this.store.dispatch(new actions.SetEmailHtmlTemplate(emailHtmlTemplate));
                this.hideLoader();
            },
            () => this.hideLoader());
    }

    createEmailHtmlTemplate(data: EditHtmlTemplateFormSubmitDataModel): Observable<CreateBaseResponseModel> {

        const request = new CreateEmailHtmlTemplateRequestModel(
            data.emailHtmlTemplateType,
            data.description,
            data.senderEmailAddress,
            data.senderEmailName,
            data.emailSubject,
            data.html
        );

        return this.emailHtmlTemplateRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating email html template', error);
            }));
    }

    updateEmailHtmlTemplate(emailHtmlTemplateId: number, data: EditHtmlTemplateFormSubmitDataModel): Observable<any> {

        const request = new UpdateEmailHtmlTemplateRequestModel(
            emailHtmlTemplateId,
            data.emailHtmlTemplateType,
            data.description,
            data.senderEmailAddress,
            data.senderEmailName,
            data.emailSubject,
            data.html
        );

        return this.emailHtmlTemplateRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating email html template', error);
            }));
    }
}
