import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {PostBillTypeResponseModel} from "../../../../../../../../../core/modules/rest/post-bill-type/response/post-bill-type-response.model";

export const REDUCER_NAME = 'dialog_create/edit-post-bill-type';

export interface State {
    postBillType: PostBillTypeResponseModel;
}

const initialState: State = {
    postBillType: {} as PostBillTypeResponseModel
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const post_bill_type_selector = createSelector(state_selector, (state: State) => state.postBillType);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_POST_BILL_TYPE_ACTION: return { ... state, postBillType: action.postBillType };

        default: return state;
    }
}
