export class UpdateTierRequestModel {
    tierId: number;
    minimumAge: number;
    maximumAge: number;
    description: string;
    useAlternateCheckInSound: boolean;
    manualAcceptanceRequired: boolean;

    constructor(tierId: number, minimumAge: number, maximumAge: number, description: string, useAlternateCheckInSound: boolean, manualAcceptanceRequired: boolean) {
        this.tierId = tierId;
        this.minimumAge = minimumAge;
        this.maximumAge = maximumAge;
        this.description = description;
        this.useAlternateCheckInSound = useAlternateCheckInSound;
        this.manualAcceptanceRequired = manualAcceptanceRequired;
    }
}
