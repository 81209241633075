import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {Observable} from "rxjs";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {CommissionResponseModel} from "./response/commission-response.model";
import {CreateCommissionRequestModel} from "./request/create-commission-request.model";
import {UpdateCommissionRequestModel} from "./request/update-commission-request.model";
import {UpdateCommissionPriorityRequestModel} from "./request/update-commission-priority-request.model";
import {UpdateCommissionMappingsRequestModel} from "./request/update-commission-mappings-request.model";
import {CommissionMappingsResponseModel} from "./response/commission-mappings-response.model";

@Injectable()
export class CommissionRestService {

    constructor(private httpClient: HttpClient) {
    }

    getCommissionById(commissionId: number): Observable<CommissionResponseModel> {
        return this.httpClient.get<CommissionResponseModel>(environment.server_api_url + '/api/secured/commission/getCommissionById/' + commissionId);
    }

    getAllCommissions(): Observable<CommissionResponseModel[]> {
        return this.httpClient.get<CommissionResponseModel[]>(environment.server_api_url + '/api/secured/commission/getAllCommissions');
    }

    getCommissionMappings(commissionId: number): Observable<CommissionMappingsResponseModel> {
        return this.httpClient.get<CommissionMappingsResponseModel>(environment.server_api_url + '/api/secured/commission/getCommissionMappings/' + commissionId);
    }

    create(request: CreateCommissionRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/commission/create', request);
    }

    update(request: UpdateCommissionRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/commission/update', request, {responseType: 'text'});
    }

    updateCommissionPriority(request: UpdateCommissionPriorityRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/commission/updateCommissionPriority', request, {responseType: 'text'});
    }

    updateCommissionMappings(request: UpdateCommissionMappingsRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/commission/updateCommissionMappings', request, {responseType: 'text'});
    }

    activate(commissionId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/commission/activate', commissionId, {responseType: 'text'});
    }

    deactivate(commissionId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/commission/deactivate', commissionId, {responseType: 'text'});
    }
}
