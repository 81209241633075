import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {QuickSellingButtonProductResponseModel} from "../../../../../../../../core/modules/rest/quick-selling-button-product/response/quick-selling-button-product-response.model";
import {EditQuickSellingButtonProductDialogSandbox} from "../sandbox/edit-quick-selling-button-product-dialog.sandbox";
import {CategoryResponseModel} from "../../../../../../../../core/modules/rest/category/response/category-response.model";
import {ProductSimpleResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {LocationListItemDescriptorResponseModel} from "../../../../../../../../core/modules/rest/location-list/response/location-list-item-descriptor-response.model";
import {StartTimeResponseModel} from "../../../../../../../../core/modules/rest/product-availability/response/start-time-response.model";
import {TierResponseModel} from '../../../../../../../../core/modules/rest/tier/response/tier-response.model';
import {SubmitFormDialogDataModel} from "../model/submit-form-dialog-data.model";


// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditQuickSellingButtonProductDialogData extends DialogData {

    updateCallbackFn: () => void;
    createCallbackFn: () => void;
    editMode: boolean;
    quickSellingButtonProductId: number;
    categoryId: number;
    productId: number;
    pickupLocationId: number;
    dropoffLocationId: number;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, editMode: boolean, quickSellingButtonProductId?: number, categoryId?: number, productId?: number, pickupLocationId?: number, dropoffLocationId?: number) {
        super();
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
        this.editMode = editMode;
        this.quickSellingButtonProductId = quickSellingButtonProductId;
        this.categoryId = categoryId;
        this.productId = productId;
        this.pickupLocationId = pickupLocationId;
        this.dropoffLocationId = dropoffLocationId;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-quick-selling-button-product-dialog',
    templateUrl: './edit-quick-selling-button-product-dialog.component.html',
    styleUrls: ['./edit-quick-selling-button-product-dialog.component.scss']
})
export class EditQuickSellingButtonProductDialogComponent implements OnInit, OnDestroy {

    // OBSERVABLES

    quickSellingButtonProduct$: Observable<QuickSellingButtonProductResponseModel>;

    product$: Observable<ProductSimpleResponseModel>;

    tiers$: Observable<TierResponseModel[]>;
    tiersLoading$: Observable<boolean>;

    categories$: Observable<CategoryResponseModel[]>;
    categoriesLoading$: Observable<boolean>;

    products$: Observable<ProductSimpleResponseModel[]>;
    productsLoading$: Observable<boolean>;

    pickupLocations$: Observable<LocationListItemDescriptorResponseModel[]>;
    pickupLocationsLoading$: Observable<boolean>;

    dropoffLocations$: Observable<LocationListItemDescriptorResponseModel[]>;
    dropoffLocationsLoading$: Observable<boolean>;

    startTimes$: Observable<StartTimeResponseModel[]>;
    startTimesLoading$: Observable<boolean>;

    startTimesReturnTrip$: Observable<StartTimeResponseModel[]>;
    startTimesReturnTripLoading$: Observable<boolean>;

    errorMessage$: Observable<string>;

    // COMPONENT DATA

    editMode: boolean = false;

    quickSellingButtonProductId: number;
    selectedCategoryId: number;
    selectedProductId: number;
    selectedPickupLocationId: number;
    selectedDropoffLocationId: number;

    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditQuickSellingButtonProductDialogData, private sandbox: EditQuickSellingButtonProductDialogSandbox) {

        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
        this.editMode = dialogData.editMode;
        this.quickSellingButtonProductId = dialogData.quickSellingButtonProductId;
        this.selectedCategoryId = dialogData.categoryId;
        this.selectedProductId = dialogData.productId;
        this.selectedPickupLocationId = dialogData.pickupLocationId;
        this.selectedDropoffLocationId = dialogData.dropoffLocationId;
    }

    ngOnInit() {

        this.sandbox.loadData(this.editMode, this.quickSellingButtonProductId, this.selectedCategoryId, this.selectedProductId, this.selectedPickupLocationId, this.selectedDropoffLocationId);

        this.quickSellingButtonProduct$ = this.sandbox.quickSellingButtonProduct$;
        this.product$ = this.sandbox.product$;
        this.tiers$ = this.sandbox.tiers$;
        this.tiersLoading$ = this.sandbox.tiersLoading$;
        this.categories$ = this.sandbox.categories$;
        this.categoriesLoading$ = this.sandbox.categoriesLoading$;
        this.products$ = this.sandbox.products$;
        this.productsLoading$ = this.sandbox.productsLoading$;
        this.pickupLocations$ = this.sandbox.pickupLocations$;
        this.pickupLocationsLoading$ = this.sandbox.pickupLocationsLoading$;
        this.dropoffLocations$ = this.sandbox.dropoffLocations$;
        this.dropoffLocationsLoading$ = this.sandbox.dropoffLocationsLoading$;
        this.startTimes$ = this.sandbox.startTimes$;
        this.startTimesLoading$ = this.sandbox.startTimesLoading$;
        this.startTimesReturnTrip$ = this.sandbox.startTimesReturnTrip$;
        this.startTimesReturnTripLoading$ = this.sandbox.startTimesReturnTripLoading$;
        this.errorMessage$ = this.sandbox.errorMessage$;
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    // Category has been selected
    onSelectedCategoryChanged(categoryId: number) {
        this.selectedCategoryId = categoryId;
        this.sandbox.selectedCategoryChanged(this.selectedCategoryId);
    }

    // Product has been selected
    onSelectedProductChanged(productId: number) {
        this.selectedProductId = productId;
        this.sandbox.selectedProductChanged(this.selectedProductId);
    }

    // Pickup location has been selected
    onSelectedPickupLocationChanged(pickupLocationId: number) {
        this.selectedPickupLocationId = pickupLocationId;
        this.sandbox.selectedPickupLocationChanged(this.selectedProductId, this.selectedPickupLocationId);
    }

    // Dropoff location has been selected
    onSelectedDropoffLocationChanged(dropoffLocationId: number) {
        this.selectedDropoffLocationId = dropoffLocationId;
        this.sandbox.selectedDropoffLocationChanged(this.selectedProductId, this.selectedDropoffLocationId);
    }

    onClose() {
        this.sandbox.destroyDialog();
    }

    onSubmit(data: SubmitFormDialogDataModel) {

        if (this.editMode) {
            this.sandbox.updateQuickSellingButtonProduct(this.quickSellingButtonProductId, data).subscribe(() => {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createQuickSellingButtonProduct(data).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }
}
