import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {PassSimpleResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {PurchaseTypeEnum} from "../../../../../../../../../shared/enums/purchase-type.enum";
import * as moment from "moment";
import {DateTimeUtility} from "../../../../../../../../../shared/utils/date-time-utility";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export interface SubmitPassBasicInfoData {
    passId: number;
    description: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
    details: string;
    purchaseType: PurchaseTypeEnum;
}


@Component({
    selector: 'app-edit-pass-basic-info-dialog-form',
    templateUrl: './edit-pass-basic-info-dialog-form.component.html'
})

export class EditPassBasicInfoDialogFormComponent implements OnChanges {

    @Input() pass: PassSimpleResponseModel;

    @Output() submit: EventEmitter<SubmitPassBasicInfoData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    purchaseTypes: DropdownOptionModel[] = [];

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        // Populate "Purchase Type" dropdown
        this.purchaseTypes = [
            new DropdownOptionModel("One Way", PurchaseTypeEnum.ONE_WAY.toString(), null),
            new DropdownOptionModel("Round Trip", PurchaseTypeEnum.ROUND_TRIP.toString(), null),
            new DropdownOptionModel("MultiPass", PurchaseTypeEnum.MULTIPASS.toString(), null),
            new DropdownOptionModel("Mixed", PurchaseTypeEnum.MIXED.toString(), null)
        ];

        this.form = new FormGroup({
            description: new FormControl(),
            startDate: new FormControl(),
            endDate: new FormControl(),
            details: new FormControl(),
            purchaseType: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pass) {
            this.initializeFormData();
        }
    }

    onSubmit() {

        let description: string = this.form.value.description;
        let startDate: moment.Moment = this.form.value.startDate;
        let endDate: moment.Moment = this.form.value.endDate;
        let details: string = this.form.value.details;
        let purchaseType: PurchaseTypeEnum = <PurchaseTypeEnum>this.form.value.purchaseType;

        if (!description) {
            this.hasError = true;
            this.errorMessage = "Please enter multipass name";
            return;
        }

        if (purchaseType === null || purchaseType === undefined) {
            this.hasError = true;
            this.errorMessage = "Please select multipass purchase type";
            return;
        }

        this.submit.emit({
            passId: this.pass.passId,
            description: description,
            startDate: startDate,
            endDate: endDate,
            details: details,
            purchaseType: purchaseType
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {
        this.form.patchValue({
            description: this.pass.description,
            startDate: this.pass.startDateInt ? DateTimeUtility.convertIntToDate(this.pass.startDateInt) : null,
            endDate: this.pass.endDateInt ? DateTimeUtility.convertIntToDate(this.pass.endDateInt) : null,
            details: this.pass.details,
            purchaseType: this.pass.purchaseType
        });
    }
}
