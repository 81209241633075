import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef  } from '@angular/core';
import {ExpressionItemModel} from '../../model/expression-item.model';
import {DroppedDataI} from '../../model/dropped-data.model';
import {DroppedGroupDataModel} from '../../model/dropped-group-data.model';
import {UpdateExpressionItemValueModel} from '../../model/update-expression-item-value.model';
import {DraggedDataI} from '../../model/dragged-data.model';
import {DraggedFilterDataModel} from '../../model/dragged-filter-data.model';
import {DraggedGroupDataModel} from '../../model/dragged-group-data.model';
import {DraggedReportItemDataModel} from '../../model/dragged-report-item-data.model';
import {ReportItemModel} from '../../model/report-item.model';

const SCROLL_OFFSET = 5;
const SCROLL_INTERVAL = 10;


@Component({
    selector: 'app-order-reports-selected-filters-area',
    templateUrl: './order-reports-selected-filters-area.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReportsSelectedFiltersAreaComponent implements OnInit, OnChanges {

    @Input() expressionItems: ExpressionItemModel[];
    @Input() draggedData: DraggedDataI; // Used in ngOnChanges
    @Input() hasRunOrderReportFullPermission: boolean;
    @Output() droppedDataEvent: EventEmitter<DroppedDataI> = new EventEmitter<DroppedDataI>();
    @Output() deleteExpressionItemEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() dragStartEvent: EventEmitter<DraggedDataI> = new EventEmitter<DraggedDataI>();
    @Output() dragEndEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() expressionItemValueChangedEvent: EventEmitter<UpdateExpressionItemValueModel> = new EventEmitter<UpdateExpressionItemValueModel>();
    @ViewChild('selectedFiltersArea', { read: ViewContainerRef }) selectedFiltersArea: ViewContainerRef;

    dropAreasVisible = false;
    draggedItemHeight = 0;
    private scrollLeftInterval;
    private scrollRightInterval;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        // Dragging data changed
        if (changes.draggedData) {
            if (this.draggedData) {
                this.draggedItemHeight = 0;

                switch (this.draggedData.constructor) {
                    case DraggedFilterDataModel:
                        this.dropAreasVisible = true;
                        break;

                    case DraggedGroupDataModel:
                        const draggedGroupData: DraggedGroupDataModel = <DraggedGroupDataModel> this.draggedData;
                        this.draggedItemHeight = draggedGroupData.groupHeight;
                        this.dropAreasVisible = true;
                        break;

                    case DraggedReportItemDataModel:
                        const draggedReportItem: DraggedReportItemDataModel = <DraggedReportItemDataModel> this.draggedData;
                        const reportItem: ReportItemModel = draggedReportItem.reportItem;

                        if (!reportItem.filter) {
                            this.dropAreasVisible = false;
                        } else {
                            this.dropAreasVisible = true;
                        }
                        break;
                    default:
                        this.dropAreasVisible = false;
                }
            } else {
                this.dropAreasVisible = false;
            }
        }
    }

    onDroppedData(droppedData: DroppedDataI) {
        this.droppedDataEvent.emit(droppedData);
    }

    onDeleteExpressionItem(uuid: string) {
        this.deleteExpressionItemEvent.emit(uuid);
    }

    onAddGroupClick() {
        const leftSiblingUUID: string = this.expressionItems.length === 0 ? null : this.expressionItems[this.expressionItems.length - 1].uuid;
        const newGroup: DroppedDataI = new DroppedGroupDataModel(null, leftSiblingUUID, []);
        this.droppedDataEvent.emit(newGroup);
    }

    onDragStart(data: DraggedDataI) {
        this.dragStartEvent.emit(data);
    }

    onDragEnd() {
        this.dragEndEvent.emit();
    }

    onExpressionItemValueChanged(item: UpdateExpressionItemValueModel) {
        this.expressionItemValueChangedEvent.emit(item);
    }

    trackByExpressionItems(index: number, expressionItem: ExpressionItemModel) {
        return expressionItem.uuid;
    }

    isFirstExpressionItemCurrentlyInDrag(): boolean {
        if (this.draggedData) {
            if (this.expressionItems.length > 0) {
                const firstExpressionItemUUID = this.expressionItems[0].uuid;

                switch (this.draggedData.constructor) {
                    case DraggedFilterDataModel:
                        const draggedFilter: DraggedFilterDataModel = <DraggedFilterDataModel> this.draggedData;
                        return draggedFilter.uuid === firstExpressionItemUUID;
                    case DraggedGroupDataModel:
                        const draggedGroupData: DraggedGroupDataModel = <DraggedGroupDataModel> this.draggedData;
                        return draggedGroupData.uuid === firstExpressionItemUUID;
                }
            }
        }

        return false;
    }

    isLastExpressionItemCurrentlyInDrag(): boolean {
        if (this.draggedData) {
            if (this.expressionItems.length > 0) {
                const lastExpressionItemUUID = this.expressionItems[this.expressionItems.length - 1].uuid;

                switch (this.draggedData.constructor) {
                    case DraggedFilterDataModel:
                        const draggedFilter: DraggedFilterDataModel = <DraggedFilterDataModel> this.draggedData;
                        return draggedFilter.uuid === lastExpressionItemUUID;
                    case DraggedGroupDataModel:
                        const draggedGroupData: DraggedGroupDataModel = <DraggedGroupDataModel> this.draggedData;
                        return draggedGroupData.uuid === lastExpressionItemUUID;
                }
            }
        }

        return false;
    }

    onMousePointerNearLeftEdge(value: boolean) {
        if (value) {
            this.scrollLeftInterval = setInterval(() => {
                this.selectedFiltersArea.element.nativeElement.scrollLeft -= SCROLL_OFFSET;
            }, SCROLL_INTERVAL);
        } else {
            clearInterval(this.scrollLeftInterval);
        }
    }

    onMousePointerNearRightEdge(value: boolean) {
        if (value) {
            this.scrollRightInterval = setInterval(() => {
                this.selectedFiltersArea.element.nativeElement.scrollLeft += SCROLL_OFFSET;
            }, SCROLL_INTERVAL);
        } else {
            clearInterval(this.scrollRightInterval);
        }
    }
}
