import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ProductAvailabilityResponseModel} from "../../../../../../../../../core/modules/rest/product-availability/response/product-availability-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export const REDUCER_NAME = 'dialog_edit-product-availabilities';

export interface State {
    productAvailabilities: ProductAvailabilityResponseModel[];
    departureGroups: DropdownOptionModel[];
    letterGroups: DropdownOptionModel[];
    bids: DropdownOptionModel[];
}

const INITIAL_STATE: State = {
    productAvailabilities: [],
    departureGroups: [],
    letterGroups: [],
    bids: []
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const productAvailabilities_selector = createSelector(state_selector, (state: State) => state.productAvailabilities);
export const departureGroups_selector = createSelector(state_selector, (state: State) => state.departureGroups);
export const letterGroups_selector = createSelector(state_selector, (state: State) => state.letterGroups);
export const bids_selector = createSelector(state_selector, (state: State) => state.bids);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;

        case actions.SET_PRODUCT_AVAILABILITIES:
            return {...state, productAvailabilities: action.productAvailabilities};

        case actions.SET_DEPARTURE_GROUPS:
            return {...state, departureGroups: action.departureGroups};

        case actions.SET_LETTER_GROUPS:
            return {...state, letterGroups: action.letterGroups};

        case actions.SET_BIDS:
            return {...state, bids: action.bids};

        default:
            return state;
    }
}
