import {CreateBaseResponseModel} from "../create-base-response.model";
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {UpdateQuickSellingButtonPassOrdinalNumberRequestModel} from "./request/update-quick-selling-button-pass-ordinal-number-request.model";
import {QuickSellingButtonPassResponseModel} from "./response/quick-selling-button-pass-response.model";
import {CreateQuickSellingButtonPassRequestModel} from "./request/create-quick-selling-button-pass-request.model";
import {UpdateQuickSellingButtonPassRequestModel} from "./request/update-quick-selling-button-pass-request.model";

@Injectable()
export class QuickSellingButtonPassRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<QuickSellingButtonPassResponseModel[]> {
        return this.httpClient.get<QuickSellingButtonPassResponseModel[]>(environment.server_api_url + '/api/secured/quickSellingButtonPass/getAll');
    }

    getQuickSellingButtonPassesByCurrentUser(): Observable<QuickSellingButtonPassResponseModel[]> {
        return this.httpClient.get<QuickSellingButtonPassResponseModel[]>(environment.server_api_url + '/api/secured/quickSellingButtonPass/getQuickSellingButtonPassesByCurrentUser');
    }

    getById(quickSellingButtonPassId: number): Observable<QuickSellingButtonPassResponseModel> {
        return this.httpClient.get<QuickSellingButtonPassResponseModel>(environment.server_api_url + '/api/secured/quickSellingButtonPass/getById/' + quickSellingButtonPassId);
    }

    create(request: CreateQuickSellingButtonPassRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/quickSellingButtonPass/create', request);
    }

    update(request: UpdateQuickSellingButtonPassRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/quickSellingButtonPass/update', request, {responseType: 'text'});
    }

    updateQuickSellingButtonPassOrdinalNumber(request: UpdateQuickSellingButtonPassOrdinalNumberRequestModel) {
        return this.httpClient.post(environment.server_api_url + '/api/secured/quickSellingButtonPass/updateQuickSellingButtonPassOrdinalNumber', request, {responseType: 'text'});
    }

    activate(quickSellingButtonId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/quickSellingButtonPass/activate', quickSellingButtonId, {responseType: 'text'});
    }

    deactivate(quickSellingButtonId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/quickSellingButtonPass/deactivate', quickSellingButtonId, {responseType: 'text'});
    }
}
