import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {CategoryRestService} from "../../../../../../../../core/modules/rest/category/category-rest.service";
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {CategoryResponseModel} from "../../../../../../../../core/modules/rest/category/response/category-response.model";
import {SubmitCategoryBasicInfoData} from "../components/edit-category-basic-info-dialog-form/edit-category-basic-info-dialog-form.component";

@Injectable()
export class EditCategoryBasicInfoDialogSandbox extends DialogSandbox {

    category$: Observable<CategoryResponseModel> = this.store.pipe(select(reducer.category_selector));

    constructor(appDialogsService: AppDialogsService,
                private categoryRestService: CategoryRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(categoryId: number) {
        this.categoryRestService.getCategoryById(categoryId)
            .subscribe((category: CategoryResponseModel) => {
                this.store.dispatch(new actions.SetCategory(category));
            });
    }

    updateCategoryBasicInfo(data: SubmitCategoryBasicInfoData): Observable<any> {
        return this.categoryRestService.updateCategoryBasicInfo({
            categoryId: data.categoryId,
            description: data.description
        });
    }
}
