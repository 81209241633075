import {Observable} from "rxjs";
import {MultiselectDropdownOptionModel} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {MappingCommissionDialogSandbox} from "../sandbox/mapping-commission-dialog.sandbox";
import {SubmitCommissionMappingsData} from "../components/mapping-commission-dialog-form/mapping-commission-dialog-form.component";

export class MappingCommissionDialogData {

    commissionId: number;
    commissionDescription: string;
    successCallbackFn: Function;

    constructor(successCallbackFn: () => void, commissionId: number, commissionDescription: string) {
        this.commissionId = commissionId;
        this.commissionDescription = commissionDescription;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-mapping-commission-dialog',
    templateUrl: './mapping-commission-dialog.component.html'
})
export class MappingCommissionDialogComponent implements OnDestroy {

    commissionId: number;
    commissionDescription: string;
    successCallbackFn: Function;

    loading$: Observable<boolean>;
    categories$: Observable<MultiselectDropdownOptionModel[]>;
    mappedCategories$: Observable<number[]>;
    products$: Observable<MultiselectDropdownOptionModel[]>;
    mappedProducts$: Observable<number[]>;
    passes$: Observable<MultiselectDropdownOptionModel[]>;
    mappedPasses$: Observable<number[]>;
    tiers$: Observable<MultiselectDropdownOptionModel[]>;
    mappedTiers$: Observable<number[]>;
    roles$: Observable<MultiselectDropdownOptionModel[]>;
    mappedRoles$: Observable<number[]>;
    users$: Observable<MultiselectDropdownOptionModel[]>;
    mappedUsers$: Observable<number[]>;
    locations$: Observable<MultiselectDropdownOptionModel[]>;
    mappedLocations$: Observable<number[]>;

    constructor(@Inject(DialogData) data: MappingCommissionDialogData, private sandbox: MappingCommissionDialogSandbox) {

        this.commissionId = data.commissionId;
        this.commissionDescription = data.commissionDescription;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.commissionId);

        this.loading$ = this.sandbox.loading$;
        this.categories$ = this.sandbox.categories$;
        this.mappedCategories$ = this.sandbox.mappedCategories$;
        this.products$ = this.sandbox.products$;
        this.mappedProducts$ = this.sandbox.mappedProducts$;
        this.passes$ = this.sandbox.passes$;
        this.mappedPasses$ = this.sandbox.mappedPasses$;
        this.tiers$ = this.sandbox.tiers$;
        this.mappedTiers$ = this.sandbox.mappedTiers$;
        this.roles$ = this.sandbox.roles$;
        this.mappedRoles$ = this.sandbox.mappedRoles$;
        this.users$ = this.sandbox.users$;
        this.mappedUsers$ = this.sandbox.mappedUsers$;
        this.locations$ = this.sandbox.locations$;
        this.mappedLocations$ = this.sandbox.mappedLocations$;
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitCommissionMappingsData) {
        this.sandbox.updateCommissionMappings(data).subscribe(() => {
            this.successCallbackFn(data.commissionId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
