<div class="pass-dialog-content">
    <h1 class="mb-8px">Edit Pass Basic info</h1>
    <form [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="grid-x grid-margin-x">
                    <div class="order-form-item">
                        <label class="order-label">MultiPass Name</label>
                        <div class="order-input-wrapper">
                            <input type="text" placeholder="Enter name" formControlName="description"/>
                        </div>
                    </div>
                    <div class="order-form-item">
                        <label class="order-label">Start Date</label>
                        <div class="order-input-wrapper">
                            <app-datetimepicker
                                class="main--element"
                                [placeholderText]="'Choose Start Date'"
                                formControlName="startDate">
                            </app-datetimepicker>
                        </div>
                    </div>
                    <div class="order-form-item">
                        <label class="order-label">End Date</label>
                        <div class="order-input-wrapper">
                            <app-datetimepicker
                                class="main--element"
                                [placeholderText]="'Choose End Date'"
                                formControlName="endDate">
                            </app-datetimepicker>
                        </div>
                    </div>
                    <div class="order-form-item">
                        <label class="order-label">Details</label>
                        <div class="order-input-wrapper">
                            <input type="text" placeholder="Enter details" formControlName="details"/>
                        </div>
                    </div>
                    <div class="order-form-item">
                        <label class="order-label">Purchase Type</label>
                        <div class="order-input-wrapper">
                            <app-dropdown
                                class="main--element"
                                [customClasses]="'dropdown_large'"
                                [options]="purchaseTypes"
                                [preSelectedValue]="pass.purchaseType"
                                formControlName="purchaseType">
                            </app-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <p *ngIf="hasError" class="error">{{ errorMessage }}</p>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Update</button>
    </div>
</div>
