import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {KioskConfigurationDropoffLocationProductModel} from "../model/kiosk-configuration-dropoff-location-product.model";
import {ProductForRouteSetupResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-for-route-setup-response.model";
import {KioskConfigurationWarningModel} from "../../../model/kiosk-configuration-warning.model";
import {Observable} from "rxjs";
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {RouteSetupDialogSandbox} from "../sandbox/route-setup-dialog.sandbox";
import {ProductChangedData} from "../model/emit/product-changed-data.emit";
import {DirectionChangedData} from "../model/emit/direction-changed-data.emit";
import {PickupLocationChangedData} from "../model/emit/pickup-location-changed-data.emit";
import {DropoffLocationChangedData} from "../model/emit/dropoff-location-changed-data.emit";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class RouteSetupDialogData extends DialogData {

    dropoffLocationId: number;
    kioskConfigurationPickupLocationId: number;
    kioskConfigurationDropoffLocationId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void,
                createCallbackFn: () => void,
                dropoffLocationId?: number,
                kioskConfigurationPickupLocationId?: number,
                kioskConfigurationDropoffLocationId?: number) {

        super();
        this.dropoffLocationId = dropoffLocationId;
        this.kioskConfigurationPickupLocationId = kioskConfigurationPickupLocationId;
        this.kioskConfigurationDropoffLocationId = kioskConfigurationDropoffLocationId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-route-setup-dialog',
    templateUrl: './route-setup-dialog.component.html'
})
export class RouteSetupDialogComponent implements OnInit, OnDestroy {

    kioskConfigurationPickupLocationId: number;
    kioskConfigurationDropoffLocationId: number;
    dropoffLocationId: number;

    kioskConfigurationDropoffLocationProducts$: Observable<KioskConfigurationDropoffLocationProductModel[]>;
    allProducts$: Observable<ProductForRouteSetupResponseModel[]>;
    warningsWhileCreating$: Observable<KioskConfigurationWarningModel[]>;

    constructor(@Inject(DialogData) dialogData: RouteSetupDialogData, private sandbox: RouteSetupDialogSandbox) {

        this.dropoffLocationId = dialogData.dropoffLocationId;
        this.kioskConfigurationDropoffLocationId = dialogData.kioskConfigurationDropoffLocationId;
        this.kioskConfigurationPickupLocationId = dialogData.kioskConfigurationPickupLocationId;
    }

    ngOnInit() {

        this.sandbox.loadDialogData(this.kioskConfigurationDropoffLocationId);

        this.kioskConfigurationDropoffLocationProducts$ = this.sandbox.kioskConfigurationDropoffLocationProducts$;
        this.allProducts$ = this.sandbox.allProducts$;
        this.warningsWhileCreating$ = this.sandbox.warningsWhileCreating$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onProductChanged(data: ProductChangedData) {
        this.sandbox.productSelectionChanged(data.index, data.product);
    }

    onDirectionChanged(data: DirectionChangedData) {
        this.sandbox.productDirectionChanged(data.index, data.productId, data.returnTrip);
    }

    onPickupLocationChanged(data: PickupLocationChangedData) {
        this.sandbox.productPickupLocationChanged(data.index, data.pickupLocationId, data.productId, data.returnTrip);
    }

    onDropoffLocationChanged(data: DropoffLocationChangedData) {
        this.sandbox.productDropoffLocationChanged(data.index, data.dropoffLocationId);
    }

    onProductAdded() {
        this.sandbox.productAdded();
    }

    onProductDeleted(index: number) {
        this.sandbox.productDeleted(index);
    }

    onSubmit(products: KioskConfigurationDropoffLocationProductModel[]) {
        this.sandbox.createKioskConfigurationDropoffLocationProduct(
            this.kioskConfigurationDropoffLocationId,
            this.dropoffLocationId,
            products
        ).subscribe((successful: boolean) => {
            if (successful) {
                this.sandbox.destroyDialog();
            }
        });
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
