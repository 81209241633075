<app-abstract-dialog-container>
    <app-edit-customer-form
        [editMode]="editMode"
        [customer]="customer$ | async"
        (submitEvent)="onSubmit($event)"
        (closeEvent)="onClose()">
    </app-edit-customer-form>
</app-abstract-dialog-container>


