import {Action} from "@ngrx/store";
import {AutoReportResponseModel} from "../../../../../../../../../core/modules/rest/reporting/general/auto-reports/response/auto-report-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit AutoReport :: MAIN] Reset State';
export const SET_AUTO_REPORT_ACTION = '[Dialog - Create/Edit AutoReport :: MAIN] Set AutoReport';
export const SET_FAVORITES = '[Dialog - Create/Edit AutoReport] Set Favorites';
export const SET_CURRENT_USER_EMAIL = '[Dialog - Create/Edit AutoReport] Set Current User Email';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetAutoReport implements Action {
    readonly type = SET_AUTO_REPORT_ACTION;
    constructor(public autoReport: AutoReportResponseModel) {}
}

export class SetFavorites implements Action {
    readonly type = SET_FAVORITES;

    constructor(public favorites: DropdownOptionModel[]) {
    }
}

export class SetCurrentUserEmail implements Action {
    readonly type = SET_CURRENT_USER_EMAIL;

    constructor(public currentUserEmail: string) {
    }
}

export type All = ResetState
    | SetAutoReport
    | SetFavorites
    | SetCurrentUserEmail;
