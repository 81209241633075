import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import SimpleBar from 'simplebar';
import {SortByModel} from '../../../../../../../../../shared/model/sort-by.model';
import {DriverManifestReportCurrentSearchDataModel} from "../../../../../driver-manifest-report/model/driver-manifest-report-current-search-data.model";
import {DriverManifestReportItemResponseModel} from "../../../../../../../../../core/modules/rest/reporting/operational/driver-manifest-report/response/driver-manifest-report-item-response.model";
import {DriverManifestReportCustomFieldResponseModel} from "../../../../../../../../../core/modules/rest/reporting/operational/driver-manifest-report/response/driver-manifest-report-custom-field-response.model";

declare var $: any;

@Component({
    selector: 'app-driver-manifest-report-table-element',
    templateUrl: './driver-manifest-report-table-element.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DriverManifestReportTableElementComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

    @Input() currentSearchData: DriverManifestReportCurrentSearchDataModel; // Used in ngOnChanges
    @Input() currentSearchDataSortBy: SortByModel;

    @Output() headerSortEvent: EventEmitter<string> = new EventEmitter();
    @Output() showSummaryEvent: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('tableContainer') tableContainer;

    driverManifestReportItemsTableData: DriverManifestReportItemTableDataModel[] = [];

    scrollBar: SimpleBar;
    scrollContainer = document.getElementsByClassName('infiniteScroll_container')[0];
    positionHorizontalScrollBarHandler: any;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.scrollBar = null;
        this.scrollContainer.removeEventListener('scroll', this.positionHorizontalScrollBarHandler);
        this.positionHorizontalScrollBarHandler = null;
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.currentSearchData) {
            this.driverManifestReportItemsTableData = this.getDriverManifestReportItemsForTable(this.currentSearchData);
        }
    }

    ngAfterViewInit() {

        this.positionHorizontalScrollBarHandler = this.positionHorizontalScrollBar.bind(this);
        this.scrollContainer.addEventListener('scroll', this.positionHorizontalScrollBarHandler);

        // Initialization of horizontal scroll bar needs to be delayed because table header renders "too late"
        let that = this;
        setTimeout(() => {
            that.scrollBar = new SimpleBar(that.tableContainer.nativeElement, {
                autoHide: false,
            });
            that.scrollBar.recalculate();

            that.positionHorizontalScrollBar();
        }, 300);
    }

    positionHorizontalScrollBar() {

        const windowH = window.innerHeight;
        const tableBottom = this.tableContainer.nativeElement.getBoundingClientRect().bottom;
        const horizontalScroll = this.tableContainer.nativeElement.querySelector('.simplebar-horizontal');

        if (horizontalScroll !== undefined) {
            if (windowH < tableBottom) {
                $(horizontalScroll).offset({
                    top: windowH - 15
                });
            } else {
                $(horizontalScroll).offset({
                    top: tableBottom + 2
                });
            }
        }
    }

    sortByHeaderClick(header: string) {
        this.headerSortEvent.emit(header);
    }

    showSummary(driverManifestReportItem: DriverManifestReportItemTableDataModel) {
        this.showSummaryEvent.emit(+driverManifestReportItem.orderId);
    }

    private getDriverManifestReportItemsForTable(currentSearchData: DriverManifestReportCurrentSearchDataModel): DriverManifestReportItemTableDataModel[] {

        if (!currentSearchData || currentSearchData.driverManifestReportItems.length === 0) {
            return [];
        }

        let data: DriverManifestReportItemTableDataModel[] = currentSearchData.driverManifestReportItems
            .map((driverManifestReportItem: DriverManifestReportItemResponseModel) => {

                let driverManifestReportItemCustomFieldValues: string[] = [];

                // Make sure each driver manifest report item has complete list of custom fields, in same order
                for (let customField of currentSearchData.allCustomFields) {
                    let driverManifestReportItemCustomField: DriverManifestReportCustomFieldResponseModel = driverManifestReportItem.customFields.find((cf) => cf.templateItemId === customField.templateItemId);
                    if (driverManifestReportItemCustomField) {
                        driverManifestReportItemCustomFieldValues.push(driverManifestReportItemCustomField.fieldValue);
                    } else {
                        driverManifestReportItemCustomFieldValues.push("");
                    }
                }

                return {
                    orderId: driverManifestReportItem.orderId,
                    orderExternalId: driverManifestReportItem.orderExternalId,
                    startTimeFriendly: driverManifestReportItem.startTimeFriendly,
                    pickupLocationDescription: driverManifestReportItem.pickupLocationDescription,
                    dropoffLocationDescription: driverManifestReportItem.dropoffLocationDescription,
                    tierHeadCount: driverManifestReportItem.tierHeadCount,
                    lastName: driverManifestReportItem.lastName,
                    phone: driverManifestReportItem.phone,
                    customFields: driverManifestReportItemCustomFieldValues
                };
            });

        return data;
    }
}

interface DriverManifestReportItemTableDataModel {

    orderId: number;
    orderExternalId: string;
    startTimeFriendly: string;
    pickupLocationDescription: string;
    dropoffLocationDescription: string;
    tierHeadCount: number;
    lastName: string;
    phone: string;
    customFields: string[];
}
