import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { SaleGroupResponseModel } from '../../../../../../../../core/modules/rest/sale-group/response/sale-group-response.model';
import { DialogData } from "../../../../../../../../shared/dialogs/model/dialog.data";
import { EditSaleGroupDialogSandbox } from '../sandbox/edit-sale-group-dialog.sandbox';
import { SaleGroupFormSubmitDataModel } from '../model/sale-group-form-submit-data.model';

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditSaleGroupDialogData extends DialogData {

    saleGroup: SaleGroupResponseModel;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, saleGroup?: SaleGroupResponseModel) {
        super();
        this.saleGroup = saleGroup;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-sale-group-dialog',
    templateUrl: './edit-sale-group-dialog.component.html'
})
export class EditSaleGroupDialogComponent implements OnInit, OnDestroy {

    saleGroup$: Observable<SaleGroupResponseModel>;
    saleGroup: SaleGroupResponseModel;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditSaleGroupDialogData, private sandbox: EditSaleGroupDialogSandbox) {
        this.saleGroup = dialogData.saleGroup;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
        if (this.saleGroup) {
            this.editMode = true;
            this.sandbox.loadSaleGroup(this.saleGroup.saleGroupId);
            this.saleGroup$ = this.sandbox.saleGroup$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: SaleGroupFormSubmitDataModel) {
        if (this.editMode) {
            this.sandbox.updateSaleGroup( data ).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createSaleGroup( data.saleGroupName, data.useOwnerTypeGroup, data.useDomainTypeGroup ).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
