import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {EditBidDialogSandbox} from "../sandbox/edit-bid-dialog.sandbox";
import {Observable} from "rxjs";
import {BidResponseModel} from "../../../../../../../../core/modules/rest/bid/response/bid-response.model";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditBidDialogData extends DialogData {

    bidId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, bidId?: number) {
        super();
        this.bidId = bidId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
// Component that represents it
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-bid-dialog',
    templateUrl: './edit-bid-dialog.component.html'
})
export class EditBidDialogComponent implements OnDestroy, OnInit {

    bid$: Observable<BidResponseModel>;
    bidId: number;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditBidDialogData, private sandbox: EditBidDialogSandbox) {
        this.bidId = dialogData.bidId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {

        if (this.bidId && !isNaN(this.bidId)) {
            this.editMode = true;
            this.sandbox.loadBid(this.bidId);
            this.bid$ = this.sandbox.bid$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(description: string) {

        if (this.editMode) {
            this.sandbox.updateBid(this.bidId, description).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createBid(description).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}

