<!-- Dialog Content :: BEGIN -->
<div class="content_wrapper">
    <div class="formRow">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <app-input-label-group>
                    <label class="label">Description</label>
                    <input class="main--element input-large"
                           type="text"
                           name="Description"
                           [(ngModel)]="includedCost.description"
                           (change)="onInputChanged()">
                </app-input-label-group>
            </div>
        </div>
    </div>

    <div class="formRow">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
                <app-input-label-group>
                    <label class="label">Amount</label>
                    <input class="main--element input-large"
                           appNumericInput
                           numericType="decimal"
                           name="Amount"
                           [(ngModel)]="includedCost.amount"
                           (change)="onInputChanged()">
                </app-input-label-group>
            </div>

            <div class="cell medium-6">
                <app-input-label-group>
                    <label class="label">&nbsp;</label>
                    <app-dropdown class="main--element"
                                  [preSelectedValue]="includedCost.amountType"
                                  [customClasses]="'dropdown_large'"
                                  [options]="amountTypes"
                                  (changed)="onAmountTypeChanged($event)">
                    </app-dropdown>
                </app-input-label-group>
            </div>
        </div>
    </div>

    <div class="formRow">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <app-input-label-group>
                    <label class="label">GL Code (optional)</label>
                    <input class="main--element input-large"
                           type="text"
                           name="GL Code"
                           [(ngModel)]="includedCost.glCode"
                           (change)="onInputChanged()">
                </app-input-label-group>
            </div>
        </div>
    </div>

</div><!-- Dialog Content :: END -->

