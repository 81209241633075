import { ChangeDetectionStrategy, Component, Input, OnInit  } from '@angular/core';


@Component({
    selector: 'app-drop-area',
    templateUrl: './drop-area.component.html',
    styleUrls: ['./drop-area.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropAreaComponent implements OnInit {

    @Input() active: boolean;

    constructor() {
    }

    ngOnInit() {
    }
}
