<app-abstract-dialog-container
    [sizeClass]="'large'"
    [shouldOverrideStyle]="true"
    [classList]="'void-order-dialog'">

    <ng-container alt-design>
        <div class="dialog-header">
            <h1>Void Order</h1>
        </div>

        <div class="dialog-body">
            <div class="dialog-default">

                <div class="display-inline-block">
                    <h4>Payments on Order (Total: {{getCellValueForPrintingCurrency(totalAmount)}})</h4>
                    <div class="summary-general-info-wrapper">
                        <div class="summary-general-info">
                            <ng-container *ngFor="let orderPayment of orderPayments">
                                <div class="summary-data-wrapper">
                                    <p *ngIf="orderPayment.status != PARTIAL_REFUND ">{{orderPayment.paymentMethodDescription}}</p>
                                    <p *ngIf="orderPayment.status == PARTIAL_REFUND ">{{orderPayment.paymentMethodDescription}}</p>
                                    <p *ngIf="orderPayment.status == PARTIAL_REFUND ">{{getCellValueForPrintingCurrency(orderPayment.revenue)}}</p>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="dialog-checkboxes-wrapper">
                    <app-checkbox
                        [disabled]="!hasEmail"
                        [ngClass]="{'disabled': !hasEmail}"
                        [labelName]="'Send Email to Customer'"
                        [checked]="shouldSendEmailToCustomer"
                        (valueChanged)="onShouldSendEmailToCustomerChanged($event)">
                    </app-checkbox>
                    <app-checkbox
                        [labelName]="'Refund Payment(s)'"
                        [checked]="shouldRefundPayments"
                        (valueChanged)="onShouldRefundPaymentsChanged($event)">
                    </app-checkbox>
                </div>
                <div class="display-inline-block">
                    <app-input-label-group>
                        <label class="label">Amount to Refund</label>
                        <input class="main--element input-large"
                            [disabled]="!shouldRefundPayments"
                            [ngClass]="{'disabled': !shouldRefundPayments}"
                            appNumericInput
                            numericType="decimal"
                            name="Amount"
                            [(ngModel)]="refundAmount">
                    </app-input-label-group>
                </div>

                <div class="display-inline-block">
                    <app-input-label-group>
                        <label class="label">Reason</label>
                        <app-dropdown
                            class="main--element"
                            [options]="statusReasonOptions"
                            [preSelectedValue]="selectedStatusReason"
                            (changed)="onSelectedStatusReasonChanged($event)">
                        </app-dropdown>
                    </app-input-label-group>
                </div>

                <div class="display-inline-block">
                    <app-input-label-group>
                        <label class="label">Additional Info (optional)</label>
                        <input class="main--element input-large"
                            type="text"
                            name="AdditionalInfo"
                            [(ngModel)]="additionalInfo">
                    </app-input-label-group>
                </div>

                <div class="display-inline-block" *ngIf="hasError">
                    <label class="label error-msg">{{errorMessage}}</label>
                </div>

            </div>
        </div>

        <!-- Footer :: BEGIN -->
        <div class="footer_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-6 text-right">
                    <app-button
                        [buttonType]='"secondary"'
                        (clickEvent)="onCancelClick()">
                        Cancel
                    </app-button>
                </div>


                <div class="cell medium-6 text-left">
                    <app-button
                        [buttonType]='"button button-solid-danger"'
                        (clickEvent)="onVoidClick()">
                        Void
                    </app-button>
                </div>
            </div>
        </div><!-- Footer :: END -->
    </ng-container>
</app-abstract-dialog-container>
