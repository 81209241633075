import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from "rxjs";
import {KioskConfigurationValidatedResponseModel} from "./response/kiosk-configuration-validated-response.model";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {CreateKioskConfigurationRequestModel} from "./request/create-kiosk-configuration-request.model";
import {UpdateKioskConfigurationRequestModel} from "./request/update-kiosk-configuration-request.model";
import {KioskConfigurationDropoffLocationValidatedResponseModel} from "./response/kiosk-configuration-dropoff-location-validated-response.model";
import {CreateKioskConfigurationDropoffLocationRequestModel} from "./request/create-kiosk-configuration-dropoff-location-request.model";
import {UpdateKioskConfigurationDropoffLocationSortOrderRequestModel} from "./request/update-kiosk-configuration-dropoff-location-sort-order-request.model";
import {CreateKioskConfigurationDropoffLocationProductsRequestModel} from "./request/create-kiosk-configuration-dropoff-location-products-request.model";
import {CreateKioskConfigurationDropoffLocationProductTiersRequestModel} from "./request/create-kiosk-configuration-dropoff-location-product-tiers-request.model";
import {KioskConfigurationDropoffLocationProductTierResponseModel} from "./response/kiosk-configuration-dropoff-location-product-tier-response.model";
import {KioskConfigurationDropoffLocationPassTierResponseModel} from "./response/kiosk-configuration-dropoff-location-pass-tier-response.model";
import {CreateKioskConfigurationDropoffLocationPassTiersRequestModel} from "./request/create-kiosk-configuration-dropoff-location-pass-tiers-request.model";
import {KioskConfigurationDropoffLocationProductWithLocationsResponseModel} from "./response/kiosk-configuration-dropoff-location-product-with-locations-response.model";
import {KioskConfigurationResponseModel} from "./response/kiosk-configuration-response.model";
import {KioskConfigurationValidatedSimpleResponseModel} from "./response/kiosk-configuration-validated-simple-response.model";
import {KioskConfigurationWarningResponseModel} from "./response/kiosk-configuration-warning-response.model";

@Injectable()
export class KioskConfigurationRestService {

    constructor(private httpClient: HttpClient) {
    }

    getById(kioskConfigurationId: number): Observable<KioskConfigurationResponseModel> {
        return this.httpClient.get<KioskConfigurationResponseModel>(environment.server_api_url + `/api/secured/kioskConfiguration/getById/${kioskConfigurationId}`);
    }

    getAll(): Observable<KioskConfigurationResponseModel[]> {
        return this.httpClient.get<KioskConfigurationResponseModel[]>(environment.server_api_url + '/api/secured/kioskConfiguration/getAll');
    }

    getAllValidatedSimple(): Observable<KioskConfigurationValidatedSimpleResponseModel[]> {
        return this.httpClient.get<KioskConfigurationValidatedSimpleResponseModel[]>(environment.server_api_url + '/api/secured/kioskConfiguration/getAllValidatedSimple');
    }

    getAllValidated(): Observable<KioskConfigurationValidatedResponseModel[]> {
        return this.httpClient.get<KioskConfigurationValidatedResponseModel[]>(environment.server_api_url + '/api/secured/kioskConfiguration/getAllValidated');
    }

    getValidatedById(kioskConfigurationId: number): Observable<KioskConfigurationValidatedResponseModel> {
        return this.httpClient.get<KioskConfigurationValidatedResponseModel>(environment.server_api_url + '/api/secured/kioskConfiguration/getValidatedById/' + kioskConfigurationId);
    }

    create(data: CreateKioskConfigurationRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/kioskConfiguration/create', data);
    }

    update(data: UpdateKioskConfigurationRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/kioskConfiguration/update', data, {responseType: 'text'});
    }

    activate(kioskId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/kioskConfiguration/activate', kioskId, {responseType: 'text'});
    }

    deactivate(kioskId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/kioskConfiguration/deactivate', kioskId, {responseType: 'text'});
    }

    getKioskConfigurationDropoffLocationValidatedById(kioskConfigurationDropoffLocationId: number): Observable<KioskConfigurationDropoffLocationValidatedResponseModel> {
        return this.httpClient.get<KioskConfigurationDropoffLocationValidatedResponseModel>(environment.server_api_url + '/api/secured/kioskConfiguration/getKioskConfigurationDropoffLocationValidatedById/' + kioskConfigurationDropoffLocationId);
    }

    activateKioskConfigurationDropoffLocation(kioskConfigurationDropoffLocationId: number) {
        return this.httpClient.get(environment.server_api_url + '/api/secured/kioskConfiguration/activateKioskConfigurationDropoffLocation/' + kioskConfigurationDropoffLocationId, {responseType: 'text'});
    }

    deactivateKioskConfigurationDropoffLocation(kioskConfigurationDropoffLocationId: number) {
        return this.httpClient.get(environment.server_api_url + '/api/secured/kioskConfiguration/deactivateKioskConfigurationDropoffLocation/' + kioskConfigurationDropoffLocationId, {responseType: 'text'});
    }

    deleteKioskConfigurationDropoffLocation(kioskConfigurationId: number, kioskConfigurationDropoffLocationId: number) {
        return this.httpClient.get(environment.server_api_url + '/api/secured/kioskConfiguration/deleteKioskConfigurationDropoffLocation/' + kioskConfigurationId + '/' + kioskConfigurationDropoffLocationId, {responseType: 'text'});
    }

    createKioskConfigurationDropoffLocation(data: CreateKioskConfigurationDropoffLocationRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/kioskConfiguration/createKioskConfigurationDropoffLocation', data);
    }

    updateKioskConfigurationDropoffLocationSortOrder(data: UpdateKioskConfigurationDropoffLocationSortOrderRequestModel) {
        return this.httpClient.post(environment.server_api_url + '/api/secured/kioskConfiguration/updateKioskConfigurationDropoffLocationSortOrder', data, {responseType: 'text'});
    }

    createKioskConfigurationDropoffLocationProduct(data: CreateKioskConfigurationDropoffLocationProductsRequestModel): Observable<KioskConfigurationWarningResponseModel[]> {
        return this.httpClient.post<KioskConfigurationWarningResponseModel[]>(environment.server_api_url + '/api/secured/kioskConfiguration/createKioskConfigurationDropoffLocationProduct', data);
    }

    getKioskConfigurationDropoffLocationProductsWithLocationsByKioskConfigurationDropoffLocationId(kioskConfigurationDropoffLocationId: number): Observable<KioskConfigurationDropoffLocationProductWithLocationsResponseModel[]> {
        return this.httpClient.get<KioskConfigurationDropoffLocationProductWithLocationsResponseModel[]>(environment.server_api_url + '/api/secured/kioskConfiguration/getKioskConfigurationDropoffLocationProductsWithLocationsByKioskConfigurationDropoffLocationId/' + kioskConfigurationDropoffLocationId);
    }

    createKioskConfigurationDropoffLocationProductTier(data: CreateKioskConfigurationDropoffLocationProductTiersRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/kioskConfiguration/createKioskConfigurationDropoffLocationProductTier', data, {responseType: 'text'});
    }

    getKioskConfigurationDropoffLocationProductTiersByDropoffLocationIdAndTripType(kioskConfigurationDropoffLocationId: number, roundTrip: boolean): Observable<KioskConfigurationDropoffLocationProductTierResponseModel[]> {
        return this.httpClient.get<KioskConfigurationDropoffLocationProductTierResponseModel[]>(environment.server_api_url + '/api/secured/kioskConfiguration/getKioskConfigurationDropoffLocationProductTiersByDropoffLocationIdAndTripType/' + kioskConfigurationDropoffLocationId + '/' + roundTrip);
    }

    getKioskConfigurationDropoffLocationPassTiersByKioskConfigurationDropoffLocationIdAndPassId(kioskConfigurationDropoffLocationId: number, passId: number): Observable<KioskConfigurationDropoffLocationPassTierResponseModel[]> {
        return this.httpClient.get<KioskConfigurationDropoffLocationPassTierResponseModel[]>(environment.server_api_url + '/api/secured/kioskConfiguration/getKioskConfigurationDropoffLocationPassTiersByKioskConfigurationDropoffLocationIdAndPassId/' + kioskConfigurationDropoffLocationId + '/' + passId);
    }

    createKioskConfigurationDropoffLocationPassTier(data: CreateKioskConfigurationDropoffLocationPassTiersRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/kioskConfiguration/createKioskConfigurationDropoffLocationPassTier', data, {responseType: 'text'});
    }
}
