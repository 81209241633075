import {EmailHtmlTemplateTypeEnum} from "../../../../../../../../shared/enums/email-html-template-type.enum";

export class EditHtmlTemplateFormSubmitDataModel {

    emailHtmlTemplateType: EmailHtmlTemplateTypeEnum;
    description: string;
    senderEmailAddress: string;
    senderEmailName: string;
    emailSubject: string;
    html: string;

    constructor(emailHtmlTemplateType: EmailHtmlTemplateTypeEnum, description: string, senderEmailAddress: string, senderEmailName: string, emailSubject: string, html: string) {
        this.emailHtmlTemplateType = emailHtmlTemplateType;
        this.description = description;
        this.senderEmailAddress = senderEmailAddress;
        this.senderEmailName = senderEmailName;
        this.emailSubject = emailSubject;
        this.html = html;
    }
}
