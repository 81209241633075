import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild  } from '@angular/core';
import {CommissionReportFilterComponent} from './commission-report-filter/commission-report-filter.component';
import {FilterFormValueModel} from '../../model/filter-form-value.model';
import {SortByModel} from '../../../../../../../shared/model/sort-by.model';
import {ProductSimpleResponseModel} from '../../../../../../../core/modules/rest/product/response/product-simple-response.model';
import {BreadcrumbItemModel} from '../../../../../../../shared/components/breadcrumbs/breadcrumb-item.model';
import {CommissionReportCurrentSearchDataModel} from '../../model/commission-report-current-search-data.model';
import {RoleResponseModel} from '../../../../../../../core/modules/rest/role/response/role-response.model';
import {UserResponseModel} from '../../../../../../../core/modules/rest/user/response/user-response.model';
import {PassSimpleResponseModel} from "../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {FavoriteResponseModel} from "../../../../../../../core/modules/rest/favorite/response/favorite-response.model";
import * as moment from "moment";


@Component({
    selector: 'app-commission-report-content',
    templateUrl: './commission-report-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommissionReportContentComponent implements OnInit {

    @Input() pageBreadcrumbItems: BreadcrumbItemModel[];
    @Input() favorites: FavoriteResponseModel[];
    @Input() loadingFavorites: boolean;
    @Input() selectedFavoriteId: number;
    @Input() favoriteCreatedUpdated: boolean;
    @Input() hasUseFavoritesPermission: boolean;
    @Input() hasCreateFavoritePermission: boolean;
    @Input() hasEditFavoritePermission: boolean;
    @Input() hasRemoveFavoritePermission: boolean;
    @Input() roles: RoleResponseModel[];
    @Input() loadingRoles: boolean;
    @Input() usersForSelectedRoles: UserResponseModel[];
    @Input() loadingUsers: boolean;
    @Input() passes: PassSimpleResponseModel[];
    @Input() loadingPasses: boolean;
    @Input() products: ProductSimpleResponseModel[];
    @Input() loadingProducts: boolean;
    @Input() selectedStartDate: moment.Moment;
    @Input() selectedEndDate: moment.Moment;
    @Input() selectedRoleIds: number[];
    @Input() selectedUserIds: number[];
    @Input() selectedPassIds: number[];
    @Input() selectedProductIds: number[];
    @Input() currentSearchData: CommissionReportCurrentSearchDataModel;
    @Input() currentSearchDataSortBy: SortByModel;

    @Output() selectedFavoriteChangedEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() editFavoriteEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() removeFavoriteEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() clearSearchDataEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() runSearchEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() generateUrlForExportToXlsCommissionReportEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() filterChangedEvent: EventEmitter<FilterFormValueModel> = new EventEmitter();
    @Output() searchForNextReportItemsEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() headerSortEvent: EventEmitter<SortByModel> = new EventEmitter<SortByModel>();

    @ViewChild(CommissionReportFilterComponent) commissionReportFilterComponent: CommissionReportFilterComponent;

    // Infinite Scroll
    scrollDistance = 2;
    scrollThrottle = 300;

    private filterFormValue: FilterFormValueModel;

    constructor() {
    }

    ngOnInit() {
    }

    onSelectedFavoriteChanged(favoriteId: number) {
        this.selectedFavoriteChangedEvent.emit(favoriteId);
    }

    onEditFavorite(favoriteId: number) {
        this.editFavoriteEvent.emit(favoriteId);
    }

    onRemoveFavorite(favoriteId) {
        this.removeFavoriteEvent.emit(favoriteId);
    }

    onClearSearchData() {

        // Clear filter
        this.commissionReportFilterComponent.clearFilter();

        // Clear search data
        this.clearSearchDataEvent.emit();
    }

    onRunSearch() {
        this.runSearchEvent.emit();
    }

    onSearchForNextReportItems() {
        if (this.isInfiniteScrollEnabled()) {
            this.searchForNextReportItemsEvent.emit();
        }
    }

    onGenerateUrlForExportToXlsCommissionReport() {
        this.generateUrlForExportToXlsCommissionReportEvent.emit();
    }

    onFilterChanged(filterFormValue: FilterFormValueModel) {
        this.filterFormValue = filterFormValue;
        this.filterChangedEvent.emit(filterFormValue);
    }

    onHeaderSort(sortBy: SortByModel) {
        this.headerSortEvent.emit(sortBy);
    }

    isDisableRun() {
        // At least one pass or product has to be selected
        let hasSelectedAtLeastOnePass: boolean = this.filterFormValue && this.filterFormValue.selectedPassIds && this.filterFormValue.selectedPassIds.length > 0;
        let hasSelectedAtLeastOneProduct: boolean = this.filterFormValue && this.filterFormValue.selectedProductIds && this.filterFormValue.selectedProductIds.length > 0;

        return !this.filterFormValue ||
            !this.filterFormValue.selectedStartDateEndDate.startDate || !this.filterFormValue.selectedStartDateEndDate.endDate ||
            !this.filterFormValue.selectedRoleIds || (this.filterFormValue.selectedRoleIds && this.filterFormValue.selectedRoleIds.length === 0) ||
            !this.filterFormValue.selectedUserIds || (this.filterFormValue.selectedUserIds && this.filterFormValue.selectedUserIds.length === 0) ||
            !(hasSelectedAtLeastOnePass || hasSelectedAtLeastOneProduct);
    }

    private isInfiniteScrollEnabled(): boolean {
        if (this.currentSearchData) {
            return !this.currentSearchData.noMoreItemsForLoading && !this.currentSearchData.loadingMoreItems;
        }

        return false;
    }
}
