import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {SubmitProductPricingDateRangesData} from "./components/edit-product-pricing-date-ranges-dialog-form/edit-product-pricing-date-ranges-dialog-form.component";
import {EditProductPricingDateRangesDialogSandbox} from "./sandbox/edit-product-pricing-date-ranges-dialog.sandbox";
import {DropdownOptionModel} from "../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {PricingDateRangeFullResponseModel} from "../../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-full-response.model";
import {TierResponseModel} from "../../../../../../../core/modules/rest/tier/response/tier-response.model";

export class EditProductPricingDateRangesDialogData extends DialogData {

    isEditMode: boolean;
    isRoundTripProduct: boolean;
    productId: number;
    pricingDateRangeIds: number[];
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, isEditMode: boolean, isRoundTripProduct: boolean, productId: number, pricingDateRangeIds: number[]) {
        super();
        this.isEditMode = isEditMode;
        this.isRoundTripProduct = isRoundTripProduct;
        this.productId = productId;
        this.pricingDateRangeIds = pricingDateRangeIds;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-edit-product-pricing-date-ranges-dialog',
    templateUrl: './edit-product-pricing-date-ranges-dialog.component.html'
})
export class EditProductPricingDateRangesDialogComponent implements OnDestroy {

    isEditMode: boolean;
    isRoundTripProduct: boolean;
    productId: number;
    pricingDateRangeIds: number[];
    successCallbackFn: Function;

    pricingDateRanges$: Observable<PricingDateRangeFullResponseModel[]>;
    pickupLocations$: Observable<DropdownOptionModel[]>;
    dropoffLocations$: Observable<DropdownOptionModel[]>;
    tiers$: Observable<TierResponseModel[]>;

    constructor(@Inject(DialogData) data: EditProductPricingDateRangesDialogData, private sandbox: EditProductPricingDateRangesDialogSandbox) {

        this.isEditMode = data.isEditMode;
        this.isRoundTripProduct = data.isRoundTripProduct;
        this.productId = data.productId;
        this.pricingDateRangeIds = data.pricingDateRangeIds;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.pricingDateRangeIds);

        this.pricingDateRanges$ = this.sandbox.pricingDateRanges$;
        this.pickupLocations$ = this.sandbox.pickupLocations$;
        this.dropoffLocations$ = this.sandbox.dropoffLocations$;
        this.tiers$ = this.sandbox.tiers$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitProductPricingDateRangesData) {
        this.sandbox.updateProductPricingDateRanges(data).subscribe(() => {
            this.successCallbackFn(data.productId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
