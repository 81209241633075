import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export interface SubmitProductData {
    categoryId: number;
    description: string;
    descriptionRegularTrip: string;
    descriptionReturnTrip: string;
    isFlexibleProduct: boolean;
    isRoundTripProduct: boolean;
    tracksQuantityBetweenStops: boolean;
    usesPickupLocations: boolean;
    pickupLocationListId: number;
    usesDropoffLocations: boolean;
    dropoffLocationListId: number;
}


@Component({
    selector: 'app-add-product-dialog-form',
    templateUrl: './add-product-dialog-form.component.html'
})

export class AddProductDialogFormComponent implements OnChanges {

    @Input() categoryId: number;
    @Input() categories: DropdownOptionModel[];
    @Input() pickupDropoffLocationLists: DropdownOptionModel[];
    @Input() pickupLocationLists: DropdownOptionModel[];
    @Input() dropoffLocationLists: DropdownOptionModel[];

    @Output() submit: EventEmitter<SubmitProductData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        this.form = new FormGroup({
            categoryId: new FormControl(),
            description: new FormControl(),
            descriptionRegularTrip: new FormControl(),
            descriptionReturnTrip: new FormControl(),
            isFlexibleProduct: new FormControl(),
            isRoundTripProduct: new FormControl(),
            tracksQuantityBetweenStops: new FormControl(),
            pickupDropoffLocationListId: new FormControl(),
            usesPickupLocations: new FormControl(),
            pickupLocationListId: new FormControl(),
            usesDropoffLocations: new FormControl(),
            dropoffLocationListId: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.categories) {
            this.initializeFormData();
        }
    }

    onSubmit() {

        let categoryId: number = Number(this.form.value.categoryId);
        let description: string = this.form.value.description;
        let descriptionRegularTrip: string = this.form.value.descriptionRegularTrip;
        let descriptionReturnTrip: string = this.form.value.descriptionReturnTrip;
        let isFlexibleProduct: boolean = this.form.value.isFlexibleProduct;
        let isRoundTripProduct: boolean = this.form.value.isRoundTripProduct;
        let tracksQuantityBetweenStops: boolean = this.form.value.tracksQuantityBetweenStops;
        let pickupDropoffLocationListId: number = this.form.value.pickupDropoffLocationListId;
        let usesPickupLocations: boolean = this.form.value.usesPickupLocations;
        let pickupLocationListId: number = this.form.value.pickupLocationListId;
        let usesDropoffLocations: boolean = this.form.value.usesDropoffLocations;
        let dropoffLocationListId: number = this.form.value.dropoffLocationListId;

        if (!categoryId || isNaN(categoryId) || categoryId <= 0) {
            this.hasError = true;
            this.errorMessage = "Please select category";
            return;
        }

        if (!description) {
            this.hasError = true;
            this.errorMessage = "Please enter product name";
            return;
        }

        if (isRoundTripProduct) {
            if (!descriptionRegularTrip) {
                this.hasError = true;
                this.errorMessage = "Please enter outgoing trip name";
                return;
            }
            if (!descriptionReturnTrip) {
                this.hasError = true;
                this.errorMessage = "Please enter return trip name";
                return;
            }
        }

        if (tracksQuantityBetweenStops && !pickupDropoffLocationListId) {
            this.hasError = true;
            this.errorMessage = "Please choose pickup/dropoff location list";
            return;
        }

        if (!tracksQuantityBetweenStops) {
            if (usesPickupLocations && !pickupLocationListId) {
                this.hasError = true;
                this.errorMessage = "Please choose pickup location list";
                return;
            }
            if (usesDropoffLocations && !dropoffLocationListId) {
                this.hasError = true;
                this.errorMessage = "Please choose dropoff location list";
                return;
            }
        }

        // If product tracks quantity between stops, it has to use both pickup and dropoff location lists, and pickup and dropoff location lists have to be the same list
        if (tracksQuantityBetweenStops) {
            usesPickupLocations = true;
            pickupLocationListId = pickupDropoffLocationListId;
            usesDropoffLocations = true;
            dropoffLocationListId = pickupDropoffLocationListId;
        }

        this.submit.emit({
            categoryId: categoryId,
            description: description,
            descriptionRegularTrip: descriptionRegularTrip,
            descriptionReturnTrip: descriptionReturnTrip,
            isFlexibleProduct: isFlexibleProduct,
            isRoundTripProduct: isRoundTripProduct,
            tracksQuantityBetweenStops: tracksQuantityBetweenStops,
            usesPickupLocations: usesPickupLocations,
            pickupLocationListId: pickupLocationListId,
            usesDropoffLocations: usesDropoffLocations,
            dropoffLocationListId: dropoffLocationListId
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {

        this.form.patchValue({
            categoryId: this.categoryId
        });
    }
}
