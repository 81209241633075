import {ExpressionItemTypeEnum} from './expression-item-type.enum';
import {ExpressionItemModel} from './expression-item.model';

export class ExpressionBracketModel implements ExpressionItemModel {
    readonly itemType: ExpressionItemTypeEnum;
    readonly uuid: string;

    constructor(groupUUID: string, itemType: ExpressionItemTypeEnum) {
        this.uuid = groupUUID;
        this.itemType = itemType;
    }
}
