import { Action } from "@ngrx/store";
import { OverrideReasonResponseModel } from "../../../../../../../../../core/modules/rest/over-ride-reason/response/over-ride-reason-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Override reason :: MAIN] Reset State';
export const SET_OVERRIDE_REASON_ACTION = '[Dialog - Create/Edit Override reason :: MAIN] Set Override reason';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetOverrideReason implements Action {
    readonly type = SET_OVERRIDE_REASON_ACTION;
    constructor(public overrideReason: OverrideReasonResponseModel) {}
}

export type All = ResetState | SetOverrideReason;
