import * as actions from './actions';
import {ActionReducer, createFeatureSelector, createSelector} from "@ngrx/store";
import {LocationListFullResponseModel} from "../../../../../../../../../core/modules/rest/location-list/response/location-list-full-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";


export const REDUCER_NAME = 'dialog_create/edit-location-list';

export interface State {
    locationList: LocationListFullResponseModel;
    allLocations: DropdownOptionModel[];
}

const initialState: State = {
    locationList: {} as LocationListFullResponseModel,
    allLocations: []
};

export const state_reducer: ActionReducer<State> = reducer;

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const locationList_selector = createSelector(state_selector, (state: State) => state.locationList);

export const allLocations_selector = createSelector(state_selector, (state: State) => state.allLocations);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_LOCATION_LIST_ACTION: return handleSetLocationList(state, action);

        case actions.SET_ALL_LOCATIONS_ACTION: return handleSetAllLocations(state, action);

        default: return state;
    }
}

function handleSetLocationList(state: State, action: actions.SetLocationList): State {
    return {
        ... state,
        locationList: action.locationList
    };
}

function handleSetAllLocations(state: State, action: actions.SetAllLocations): State {
    return {
        ... state,
        allLocations: action.allLocations
    };
}
