import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderSummaryResponseModel } from './response/order-summary-response.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { VoidOrderRequestModel } from './request/void-order-request.model';
import { VoidOrderResponseModel } from './response/void-order-response.model';
import { EmailOrderRequestModel } from "./request/email-order-request.model";
import { UpdateOrderBasicInformationRequestModel } from "./request/update-order-basic-information-request.model";
import { UpdateCustomFieldsRequestModel } from "./request/update-custom-fields-request.model";
import { UpdateOrderDetailOccurrenceRequestModel } from "./request/update-order-detail-occurrence-request.model";
import { OrderSearchHistoryRequestModel } from './request/order-search-history-request.model';
import { OrderSearchHistoryResponseModel } from './response/order-search-history-response.model';

@Injectable()
export class OrderSummaryRestService {

    constructor(private httpClient: HttpClient) {
    }

    getById(orderId: number): Observable<OrderSummaryResponseModel> {
        return this.httpClient.get<OrderSummaryResponseModel>(environment.server_api_url + '/api/secured/orderSummary/getById/' + orderId);
    }

    searchOrders(criteria: string): Observable<{ orders: OrderSummaryResponseModel[] }> {
        return this.httpClient.post<{ orders: OrderSummaryResponseModel[] }>(environment.server_api_url + '/api/secured/orderSummary/searchOrders', criteria);
    }

    getOrdersByCustomerId(customerId: number, sortOrder: string): Observable<{ orders: OrderSummaryResponseModel[], totalNumberInDatabase: number }> {
        return this.httpClient.get<{ orders: OrderSummaryResponseModel[], totalNumberInDatabase: number }>(environment.server_api_url + '/api/secured/orderSummary/getAllOrdersForCustomer/' + customerId + '?sortOrder=' + sortOrder);
    }

    regenerateBarcodesForOrder(orderId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + "/api/secured/orderSummary/regenerateBarcodesForOrder", orderId, { responseType: 'text' });
    }

    generateUrlForPrintPdfOrder(orderId: number): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/orderSummary/generateUrlForPrintPdfOrder', orderId, { responseType: 'text' });
    }

    sendEmailOrderToEmailAddress(request: EmailOrderRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/orderSummary/sendEmailOrderToEmailAddress', request, { responseType: 'text' });
    }

    voidOrder(voidOrderRequest: VoidOrderRequestModel): Observable<VoidOrderResponseModel> {
        return this.httpClient.post<VoidOrderResponseModel>(environment.server_api_url + '/api/secured/orderSummary/voidOrder', voidOrderRequest);
    }

    updateOrderBasicInformation(request: UpdateOrderBasicInformationRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/orderSummary/updateOrderBasicInformation', request, { responseType: 'text' });
    }

    checkOrderDetailTotalCostForOldVsNewOccurrence(request: UpdateOrderDetailOccurrenceRequestModel): Observable<number> {
        return this.httpClient.post<number>(environment.server_api_url + '/api/secured/orderSummary/checkOrderDetailTotalCostForOldVsNewOccurrence', request);
    }

    updateOrderDetailOccurrence(request: UpdateOrderDetailOccurrenceRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/orderSummary/updateOrderDetailOccurrence', request, { responseType: 'text' });
    }

    updateCustomFields(request: UpdateCustomFieldsRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/orderSummary/updateCustomFields', request, { responseType: 'text' });
    }

    getOrderSearchHistoryByUser(userId: number): Observable<OrderSearchHistoryResponseModel> {
        return this.httpClient.get<OrderSearchHistoryResponseModel>(environment.server_api_url + '/api/secured/orderSummary/getAllOrderSearchHistoryByUser/' + userId);
    }

    createOrderSearchHistory(request: OrderSearchHistoryRequestModel): Observable<void> {
        return this.httpClient.post<void>(environment.server_api_url + '/api/secured/orderSummary/createOrderSearchHistory', request);
    }

    updateOrderSearchHistoryCreatedDate(orderSearchHistoryId: number): Observable<void> {
        return this.httpClient.put<void>(environment.server_api_url + '/api/secured/orderSummary/updateOrderSearchHistory', orderSearchHistoryId);
    }

    deleteOrderSearchHistory(orderSearchHistoryId: number): Observable<void> {
        return this.httpClient.delete<void>(environment.server_api_url + '/api/secured/orderSummary/deleteOrderSearchHistory/' + orderSearchHistoryId);
    }

}
