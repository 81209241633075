import {Action} from "@ngrx/store";
import {SystemOptionsResponseModel} from "../../modules/rest/system-options/response/system-options-response.model";

export const SET_SYSTEM_OPTIONS = "[Root] Set System Options";

export class SetSystemOptions implements Action {
    readonly type = SET_SYSTEM_OPTIONS;

    constructor(public systemOptions: SystemOptionsResponseModel) {
    }
}


export type All = SetSystemOptions;
