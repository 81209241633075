<div class="page-wrapper full-width-layout {{pageStyleId}}">

    <div class="header_wrapper">
        <app-header [pageBreadcrumbItems]="pageBreadcrumbItems">
        </app-header>
    </div>

    <div class="pageContent_wrapper width1050" data-table-scroll-container>

        <div class="mainContent_wrapper">

            <div class="mainContent_container">

                <ng-container *ngIf="pageDataLoaded">
                    <div class="pageTitle_container">
                        <h1>{{pageTitle}}</h1>
                    </div>
                </ng-container>

                <ng-content *ngIf="pageDataLoaded">
                </ng-content>
            </div>

            <app-loader *ngIf="!pageDataLoaded">{{loadingText}}</app-loader>
        </div>
    </div>
</div>
