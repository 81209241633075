<app-abstract-dialog-container>
    <app-edit-location-form
        class="edit-location-dialog"
        [editMode]="editMode"
        [location]="location$ | async"
        (submitEvent)="onSubmit($event)"
        (closeEvent)="onClose()">
    </app-edit-location-form>
</app-abstract-dialog-container>


