export class KioskDeviceInfoSimpleModel {
    kioskDeviceInfoId: number;
    description: string;
    checked: boolean;
    active: boolean;

    constructor(kioskDeviceInfoId: number, description: string, checked: boolean, active: boolean) {
        this.kioskDeviceInfoId = kioskDeviceInfoId;
        this.description = description;
        this.checked = checked;
        this.active = active;
    }
}
