import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {OccurrenceOrdersResponseModel} from "../../../../../../../../../core/modules/rest/occurrence/response/occurrence-orders-response.model";
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {EmailHtmlTemplateTypeEnum} from "../../../../../../../../../shared/enums/email-html-template-type.enum";

export interface DeactivateOrdersData {
    voidOrders: boolean;
    emailHtmlTemplateId: number;
}


@Component({
    selector: 'app-deactivate-occurrences-dialog-form',
    templateUrl: './deactivate-occurrences-dialog-form.component.html'
})
export class DeactivateOccurrencesDialogFormComponent implements OnInit, OnChanges {

    @Input() occurrencesOrders: OccurrenceOrdersResponseModel[] = [];
    @Input() emailHtmlTemplates: EmailHtmlTemplateResponseModel[] = [];

    @Output() submitClicked: EventEmitter<DeactivateOrdersData> = new EventEmitter();
    @Output() cancelClicked: EventEmitter<void> = new EventEmitter();
    @Output() orderClicked: EventEmitter<number> = new EventEmitter();

    emailHtmlTemplatesOptions: DropdownOptionModel[] = [];
    emailHtmlTemplateId: number;
    voidOrders: boolean = false;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.emailHtmlTemplates) {
            this.emailHtmlTemplatesOptions = this.emailHtmlTemplates
                .filter(et => et.emailHtmlTemplateType === EmailHtmlTemplateTypeEnum.VOID_ORDER)
                .map(et => new DropdownOptionModel(et.description, et.emailHtmlTemplateId.toString()));
        }
    }

    onEmailHtmlTemplateSelected(emailHtmlTemplateId: string) {
        this.emailHtmlTemplateId = Number(emailHtmlTemplateId);
    }

    onCancelClick() {
        this.cancelClicked.emit();
    }

    onSubmitClick() {
        this.submitClicked.emit({
            voidOrders: this.voidOrders,
            emailHtmlTemplateId: this.emailHtmlTemplateId
        });
    }

    onOrderExternalIdClicked(orderId: number) {
        this.orderClicked.emit(orderId);
    }

    getDialogTitle() {
        return this.occurrencesOrders && this.occurrencesOrders.length === 1
            ? "Deactivate Occurrence"
            : "Deactivate Occurrences";
    }

    getDialogSubtitle() {
        return this.occurrencesOrders && this.occurrencesOrders.length === 1
            ? "You're about to disable following occurrence"
            : "You're about to disable following occurrences";
    }

    onVoidAndRefundOccurrenceClicked() {
        this.voidOrders = !this.voidOrders;
    }

    canVoidAndRefund() {
        return this.occurrencesOrders.some(ol => ol.occurrenceOrders.length !== 0);
    }
}
