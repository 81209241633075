import {NgModule} from "@angular/core";
import {EditCategoryBasicInfoDialogComponent} from './edit-category-basic-info-dialog.component';
import {EditCategoryBasicInfoDialogSandbox} from "./sandbox/edit-category-basic-info-dialog.sandbox";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {EditCategoryBasicInfoDialogFormComponent} from './components/edit-category-basic-info-dialog-form/edit-category-basic-info-dialog-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    declarations: [
        EditCategoryBasicInfoDialogComponent,
        EditCategoryBasicInfoDialogFormComponent
    ],
    providers: [
        EditCategoryBasicInfoDialogSandbox
    ],
    exports: [
        EditCategoryBasicInfoDialogComponent
    ],
    entryComponents: [
        EditCategoryBasicInfoDialogComponent
    ]
})
export class EditCategoryBasicInfoDialogModule {
}
