export class UpdateCustomerRequestModel {
    customerId: number;
    active: boolean;
    email: string;
    emailVerified: boolean;
    displayName: string;
    phoneNumber: string;
    isCustomerOwner: boolean;

    constructor( customerId: number, active: boolean, email: string, emailVerified: boolean, displayName: string, phoneNumber: string, isCustomerOwner: boolean ) {
        this.customerId = customerId;
        this.active = active;
        this.email = email;
        this.emailVerified = emailVerified;
        this.displayName = displayName;
        this.phoneNumber = phoneNumber;
        this.isCustomerOwner = isCustomerOwner;
    }
}
