<div class="category-and-product-dialog-content">
    <h1 class="mb-8px">Create New Product</h1>
    <form [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="grid-x grid-margin-x">
                    <div class="cell medium-12 mb-8px disabled">
                        <app-input-label-group>
                            <label class="label">Category</label>
                            <app-dropdown class="main--element"
                                [customClasses]="'dropdown_large'"
                                [disabled]="true"
                                [options]="categories"
                                [preSelectedValue]="categoryId"
                                formControlName="categoryId"></app-dropdown>
                        </app-input-label-group>
                    </div>
                    <div class="cell medium-12 mb-16px">
                        <app-input-label-group>
                            <label class="label">Product name</label>
                            <input class="main--element input-large" placeholder="Enter product name" formControlName="description"/>
                        </app-input-label-group>
                    </div>
                    <div class="cell medium-12 mb-16px">
                        <app-checkbox
                            [ngClass]="'mb-8px'"
                            [labelName]="'Flexible Product'"
                            formControlName="isFlexibleProduct">
                        </app-checkbox>
                        <app-checkbox
                            [ngClass]="'mb-8px'"
                            [labelName]="'Round Trip Product'"
                            formControlName="isRoundTripProduct">
                        </app-checkbox>
                    </div>
                    <div *ngIf="form.value.isRoundTripProduct" class="cell medium-12 mb-16px">
                        <app-input-label-group>
                            <label class="label">Outgoing trip name</label>
                            <input class="main--element input-large" placeholder="Enter outgoing trip name" formControlName="descriptionRegularTrip"/>
                        </app-input-label-group>
                    </div>
                    <div *ngIf="form.value.isRoundTripProduct" class="cell medium-12 mb-16px">
                        <app-input-label-group>
                            <label class="label">Return trip name</label>
                            <input class="main--element input-large" placeholder="Enter return trip name" formControlName="descriptionReturnTrip"/>
                        </app-input-label-group>
                    </div>
                    <div class="cell medium-12 mb-16px">
                        <app-switch
                            [switchLabel]="'Tracks Quantity Between Stops'"
                            formControlName="tracksQuantityBetweenStops">
                        </app-switch>
                    </div>
                    <div *ngIf="form.value.tracksQuantityBetweenStops" class="cell medium-12 mb-8px">
                        <app-input-label-group>
                            <label class="label">Pickup & Drop-off Location List</label>
                            <app-dropdown
                                class="main--element"
                                [customClasses]="'dropdown_large'"
                                [options]="pickupDropoffLocationLists"
                                formControlName="pickupDropoffLocationListId">
                            </app-dropdown>
                        </app-input-label-group>
                    </div>
                    <div *ngIf="!form.value.tracksQuantityBetweenStops" class="cell medium-12 mb-8px">
                        <div class="flex-container space-between mb-8px flex-dir-column align-stretch ">
                            <app-checkbox
                                [labelName]="'Uses Pickup Locations'"
                                formControlName="usesPickupLocations">
                            </app-checkbox>
                            <app-dropdown
                                class="main--element"
                                [customClasses]="'dropdown_large'"
                                [disabled]="!form.value.usesPickupLocations"
                                [options]="pickupLocationLists"
                                formControlName="pickupLocationListId">
                            </app-dropdown>
                        </div>
                        <div class="flex-container space-between flex-dir-column align-stretch ">
                            <app-checkbox
                                [labelName]="'Uses Drop-off Locations'"
                                formControlName="usesDropoffLocations">
                            </app-checkbox>
                            <app-dropdown
                                class="main--element"
                                [customClasses]="'dropdown_large'"
                                [disabled]="!form.value.usesDropoffLocations"
                                [options]="dropoffLocationLists"
                                formControlName="dropoffLocationListId">
                            </app-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <p *ngIf="hasError" class="error">{{ errorMessage }}</p>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Create</button>
    </div>
</div>
