<div class="dialog-section">
        <h2>Email Sending Info</h2>

        <div class="placeholderText-emptySection"
            *ngIf="emailSendingInformation === null || emailSendingInformation === undefined || emailSendingInformation.length === 0">
            No Email Sending Info to Display
        </div>

        <div class="summary-general-info-wrapper"
            *ngIf="!(emailSendingInformation === null || emailSendingInformation === undefined || emailSendingInformation.length === 0)">
            <table style="width:100%">
                <thead>
                    <tr>
                        <th>Sent From</th>
                        <th>Sent From IP</th>
                        <th>Sent To</th>
                        <th>Sent To IP</th>
                        <th>Sent by</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Opened</th>
                        <th>From external source</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let emailSendingInfo of emailSendingInformation">
                        <tr>
                            <td>{{emailSendingInfo.sentFromEmailAddress}}</td>
                            <td>{{emailSendingInfo.sentFromIp}}</td>
                            <td>{{emailSendingInfo.sentToEmailAddress}}</td>
                            <td>{{emailSendingInfo.sentToIp}}</td>
                            <td>{{emailSendingInfo.sentByUser}}</td>
                            <td>{{emailSendingInfo.dateTimeFriendly}}</td>
                            <td>{{emailSendingInfo.status}}</td>
                            <td>{{emailSendingInfo.viewCount}}</td>
                            <td>{{emailSendingInfo.sentFromExternalSource}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
