import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../../../shared/shared.module";
import { StoreModule } from "@ngrx/store";
import { reducer, REDUCER_NAME } from "./sandbox/store/reducer";
import { EditOverrideReasonDialogSandbox } from "./sandbox/edit-over-ride-reason-dialog.sandbox";
import { EditOverrideReasonFormComponent } from "./components/edit-override-reason-form.component";
import { EditOverrideReasonDialogComponent } from "./container/edit-override-reason-dialog.component";




@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditOverrideReasonDialogSandbox
    ],
    declarations: [
        EditOverrideReasonDialogComponent,
        EditOverrideReasonFormComponent
    ],
    entryComponents: [
        EditOverrideReasonDialogComponent
    ],
    exports: [
      EditOverrideReasonDialogComponent
    ]
})
export class EditOverrideReasonDialogModule {

}
