<div class="category-and-product-dialog-content">
    <h1 class="mb-8px">Clone Product</h1>
    <form [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="grid-x grid-margin-x">
                    <div class="cell medium-12" [ngClass]="{'mb-16px' : isRoundTripProduct}">
                        <app-input-label-group>
                            <label class="label">Product Name</label>
                            <input class="main--element input-large" placeholder="Enter product name" formControlName="description"/>
                        </app-input-label-group>
                    </div>
                    <div *ngIf="isRoundTripProduct" class="cell medium-12 mb-16px">
                        <app-input-label-group>
                            <label class="label">Outgoing trip name</label>
                            <input class="main--element input-large" placeholder="Enter outgoing trip name" formControlName="descriptionRegularTrip"/>
                        </app-input-label-group>
                    </div>
                    <div *ngIf="isRoundTripProduct" class="cell medium-12">
                        <app-input-label-group>
                            <label class="label">Return trip name</label>
                            <input class="main--element input-large" placeholder="Enter return trip name" formControlName="descriptionReturnTrip"/>
                        </app-input-label-group>
                    </div>
                </div>
            </div>
        </ng-container>

        <p *ngIf="hasError" class="error">{{ errorMessage }}</p>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Clone</button>
    </div>
</div>
