import {HeaderResponseModel, LevelEnum, RowResponseModel} from '../../../../../../core/modules/rest/reporting/response/report-search-response.model';
import { SortByModel } from '../../../../../../shared/model/sort-by.model';
import {PaymentMethodSummaryResponse} from "../../../../../../core/modules/rest/reporting/response/payment-method-summary-response";

export class CurrentSearchDataModel {
    scrollId: string;

    firstSearch: boolean;
    generatedReport: boolean;

    headers: HeaderResponseModel[];
    headersToShow: HeaderResponseModel[];
    rows: RowResponseModel[];
    rowsToShow: RowResponseModel[];
    level: LevelEnum;
    approximateNumberOfResults: number;

    sortBy: SortByModel;

    from: number;
    size: number;

    groupSearch: boolean;
    summableFieldsExist: boolean;

    loadingReport: boolean;
    loadingMoreItems: boolean;

    noMoreItemsForLoading: boolean;

    paymentSummary: PaymentMethodSummaryResponse[];
}
