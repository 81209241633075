import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {LetterGroupResponseModel} from "../../../../../../../../../core/modules/rest/letter-group/response/letter-group-response.model";

export const REDUCER_NAME = 'dialog_create/edit-letter-group';

export interface State {
    letterGroup: LetterGroupResponseModel;
}

const initialState: State = {
    letterGroup: {} as LetterGroupResponseModel
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const letter_group_selector = createSelector(state_selector, (state: State) => state.letterGroup);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_LETTER_GROUP_ACTION: return { ... state, letterGroup: action.letterGroup };

        default: return state;
    }
}
