import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {take, tap} from "rxjs/operators";
import {environment} from "../../../environments/environment";

interface ExternalConfig {
    server_api_url: string;
    payment_api_url: string;
}

@Injectable()
export class AppConfig {

    constructor(private http: HttpClient) {
    }

    /**
     * Loads config file from asset folder and overwrites environment with proper values.
     * Happens with APP_INITIALIZER when loading application.
     */
    loadConfig(): Promise<any> {
        return this.http.get("./configuration/config.json").pipe(
            take(1),
            tap((config: ExternalConfig) => {
                // Override environment variables
                environment.server_api_url = config.server_api_url;
                environment.payment_api_url = config.payment_api_url;
            })
        ).toPromise();
    }
}
