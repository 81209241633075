
<div class="grid-x grid-padding-x ml--25px">
    <div class="secondaryNavColumns_container cell medium-3 large-2" *ngIf="userSystemPermissions(allSystemPermissions().CREATE_ORDER_LONG_FORM_PRODUCT) ||
    userSystemPermissions(allSystemPermissions().CREATE_ORDER_LONG_FORM_PASS) ||
    userSystemPermissions(allSystemPermissions().CREATE_ORDER_QSB_PRODUCT) ||
    userSystemPermissions(allSystemPermissions().CREATE_ORDER_QSB_PASS)">
        <div class="secondaryNav_column">

            <div class="secondaryNav_label">
                <h2> Place Order </h2>
            </div>

            <ul class="secondaryNav_list">
                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().CREATE_ORDER_LONG_FORM_PRODUCT) || userSystemPermissions(allSystemPermissions().CREATE_ORDER_LONG_FORM_PASS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.orderLongFormPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Order - Long Form
                    </a>
                </li>
                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().CREATE_ORDER_QSB_PRODUCT) || userSystemPermissions(allSystemPermissions().CREATE_ORDER_QSB_PASS)">
                    <a class="secondaryNav_link second--nav--link"
                        routerLink="{{ appRoutesService.orderQSBUrl() }}"
                        routerLinkActive="secondaryNavActive active--nav">
                       Order - QSB
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="secondaryNavColumns_container cell medium-3 large-2" *ngIf="userSystemPermissions(allSystemPermissions().CAN_DO_CASHOUT_ENTRY) || userSystemPermissions(allSystemPermissions().CAN_DO_CASHOUT_RECONCILIATION)">
        <div class="secondaryNav_column">

            <div class="secondaryNav_label">
                <h2> End of Shift </h2>
            </div>

            <ul class="secondaryNav_list">
                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().CAN_DO_CASHOUT_ENTRY)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.purchaseCashoutEntryPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Cashout Entry
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().CAN_DO_CASHOUT_RECONCILIATION)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{ appRoutesService.purchaseCashoutReconciliationPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Cashout Reconciliation
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
