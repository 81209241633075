import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {FavoriteResponseModel} from "../../../../../../core/modules/rest/favorite/response/favorite-response.model";

interface FavoriteGroup {
    groupDescription: string;
    favorites: FavoriteResponseModel[];
}

const FavoriteGroupEnum = {
    OTHER: 'Other' as 'OTHER',
};


@Component({
    selector: 'app-order-report-sidebar-favorite',
    templateUrl: './order-report-sidebar-favorite.component.html',
})
export class OrderReportSidebarFavoriteComponent implements OnInit, OnChanges {
    @Input() openSidebar: boolean;
    @Input() listOfFavorites: FavoriteResponseModel[];
    @Input() selectedFavoriteId: number;

    @Output() sidebarOpenStatusChanged: EventEmitter<void> = new EventEmitter();
    @Output() selectedFavoriteChangedEvent: EventEmitter<number> = new EventEmitter();

    listOfAllFavoriteGroups: FavoriteGroup[];
    openAccordion: boolean = true;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.listOfFavorites && this.listOfFavorites) {
            this.listOfAllFavoriteGroups = this.getListOfFavoriteGroups(this.listOfFavorites);
        }
    }

    onCloseClicked() {
        this.sidebarOpenStatusChanged.emit();
    }

    onSelectedFavoriteChanged(favoriteId: number) {
        this.onCloseClicked();
        this.selectedFavoriteChangedEvent.emit(favoriteId);
    }

    onSearchTextChanged(text: string) {
        let filteredListFavorites = this.listOfFavorites.filter(favorite => favorite.description.toLowerCase().includes(text.toLowerCase()));
        this.listOfAllFavoriteGroups = this.getListOfFavoriteGroups(filteredListFavorites);
        this.openAccordion = true;
        //  text ? this.openAccordion = !!filteredListFavorites : this.openAccordion = false;
    }

    private getListOfFavoriteGroups(favorites: FavoriteResponseModel[]): FavoriteGroup[] {

        let favoriteGroups: FavoriteGroup[] = [];

        for (let favorite of favorites) {

            // Check favorite's group description
            let groupDescription: string = null;
            if (favorite.groupDescription === null || favorite.groupDescription === undefined || favorite.groupDescription.trim().length === 0) {
                groupDescription = FavoriteGroupEnum.OTHER;
            } else {
                groupDescription = favorite.groupDescription;
            }

            // Check if favorite's groups is already added
            const index = favoriteGroups.findIndex(i => i.groupDescription === groupDescription);
            if (index === -1) {
                favoriteGroups.push({
                    groupDescription: groupDescription,
                    favorites: []
                });
                favoriteGroups[favoriteGroups.length - 1].favorites.push(favorite);
            } else {
                favoriteGroups[index].favorites.push(favorite);
            }
        }

        // Sort favorite groups
        favoriteGroups.sort((fg1: FavoriteGroup, fg2: FavoriteGroup) => fg1.groupDescription.localeCompare(fg2.groupDescription));

        // If exists, put "Other" group of favorites at the end
        const index = favoriteGroups.findIndex(i => i.groupDescription === FavoriteGroupEnum.OTHER);
        if (index > -1) {
            const groupFavoriteOther: FavoriteGroup = favoriteGroups[index];
            favoriteGroups.splice(index, 1);
            favoriteGroups.push(groupFavoriteOther);
        }

        // Sort favorites in every group
        favoriteGroups.forEach((groupedFavorite) => groupedFavorite.favorites.sort((f1: FavoriteResponseModel, f2: FavoriteResponseModel) => f1.description.localeCompare(f2.description)));

        return favoriteGroups;
    }
}
