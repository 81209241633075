import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { LocationListItemDescriptorResponseModel } from "./response/location-list-item-descriptor-response.model";
import { LocationListResponseModel } from "./response/location-list-response.model";
import { LocationListFullResponseModel } from "./response/location-list-full-response.model";
import { CreateBaseResponseModel } from "../create-base-response.model";
import { CreateLocationListRequestModel } from "./request/create-location-list-request.model";
import { UpdateLocationListRequestModel } from "./request/update-location-list-request.model";
import { ProductSimpleResponseModel } from '../../../modules/rest/product/response/product-simple-response.model';

@Injectable()
export class LocationListRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<LocationListResponseModel[]> {
        return this.httpClient.get<LocationListResponseModel[]>(environment.server_api_url + '/api/secured/locationList/getAll');
    }

    getAllActive(): Observable<LocationListResponseModel[]> {
        return this.httpClient.get<LocationListResponseModel[]>(environment.server_api_url + '/api/secured/locationList/getAllActive');
    }

    getAllReferencesByLocationList(locationListId: number): Observable<ProductSimpleResponseModel[]> {
        return this.httpClient.post<ProductSimpleResponseModel[]>(environment.server_api_url + '/api/secured/locationList/getAllReferencesByLocationList', locationListId);
    }

    getAllLocationListsFull(): Observable<LocationListFullResponseModel[]> {
        return this.httpClient.get<LocationListFullResponseModel[]>(environment.server_api_url + '/api/secured/locationList/getAllLocationListsFull');
    }

    getLocationListFullById(locationListId: number): Observable<LocationListFullResponseModel> {
        return this.httpClient.get<LocationListFullResponseModel>(environment.server_api_url + '/api/secured/locationList/getLocationListFullById/' + locationListId);
    }

    getLoginLocationList(): Observable<LocationListItemDescriptorResponseModel[]> {
        return this.httpClient.get<LocationListItemDescriptorResponseModel[]>(environment.server_api_url + '/api/secured/locationList/getLoginLocationList');
    }

    getLocationListItemsByLocationListId(locationListId: number): Observable<LocationListItemDescriptorResponseModel[]> {
        return this.httpClient.get<LocationListItemDescriptorResponseModel[]>(environment.server_api_url + '/api/secured/locationList/getLocationListItemsByLocationListId/' + locationListId);
    }

    getPickupLocationsByProductIdAndDirection(productId: number, isReturnTrip: boolean): Observable<LocationListItemDescriptorResponseModel[]> {
        return this.httpClient.get<LocationListItemDescriptorResponseModel[]>(environment.server_api_url + '/api/secured/locationList/getPickupLocationsByProductIdAndDirection/' + productId + '/' + isReturnTrip);
    }

    getDropoffLocationsByProductIdAndDirection(productId: number, isReturnTrip: boolean): Observable<LocationListItemDescriptorResponseModel[]> {
        return this.httpClient.get<LocationListItemDescriptorResponseModel[]>(environment.server_api_url + '/api/secured/locationList/getDropoffLocationsByProductIdAndDirection/' + productId + '/' + isReturnTrip);
    }

    getDropoffLocationsByProductIdPickupLocationIdAndDirection(productId: number, pickupLocationId: number, isReturnTrip: boolean): Observable<LocationListItemDescriptorResponseModel[]> {
        return this.httpClient.get<LocationListItemDescriptorResponseModel[]>(environment.server_api_url + '/api/secured/locationList/getDropoffLocationsByProductIdPickupLocationIdAndDirection/' + productId + '/' + pickupLocationId + '/' + isReturnTrip);
    }

    getPickupLocationsByPassId(passId: number): Observable<LocationListItemDescriptorResponseModel[]> {
        return this.httpClient.get<LocationListItemDescriptorResponseModel[]>(environment.server_api_url + '/api/secured/locationList/getPickupLocationsByPassIdNow/' + passId);
    }

    getDropoffLocationsByPassIdAndPickupLocationId(passId: number, pickupLocationId: number): Observable<LocationListItemDescriptorResponseModel[]> {
        return this.httpClient.get<LocationListItemDescriptorResponseModel[]>(environment.server_api_url + '/api/secured/locationList/getDropoffLocationsByPassIdAndPickupLocationIdNow/' + passId + '/' + pickupLocationId);
    }

    getValidForLocationsByPassIdPickupLocationIdAndDropoffLocationId(passId: number, pickupLocationId: number, dropoffLocationId: number): Observable<any> {
        return this.httpClient.get<any>(environment.server_api_url + `/api/secured/locationList/getValidForLocationsByPassIdPickupLocationIdAndDropoffLocationId/${passId}/${pickupLocationId}/${dropoffLocationId}`);
    }

    create(request: CreateLocationListRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/locationList/create', request);
    }

    update(request: UpdateLocationListRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/locationList/update', request);
    }

    activate(locationListId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/locationList/activate', locationListId, { responseType: 'text' });
    }

    deactivate(locationListId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/locationList/deactivate', locationListId, { responseType: 'text' });
    }
}
