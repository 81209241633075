import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ShoppingCartDataModel} from "../../../../order-long-form/model/shopping-cart/shopping-cart-data.model";
import {ShoppingCartItemDataModel} from "../../../../order-long-form/model/shopping-cart/shopping-cart-item-data.model";

export const REDUCER_NAME: string = "feature_shopping_cart";

export interface State {
    loading: boolean;
    shoppingCart: ShoppingCartDataModel;
}

const INITIAL_STATE: State = {
    loading: false,
    shoppingCart: initializeShoppingCart()
};

function initializeShoppingCart(): ShoppingCartDataModel {

    let initialShoppingCart: ShoppingCartDataModel = new ShoppingCartDataModel();

    initialShoppingCart.shoppingCartItems = [];
    initialShoppingCart.shoppingCartResponse = null;

    initialShoppingCart.paymentItems = [];

    initialShoppingCart.orderLevelAdditionalCostItems = [];
    initialShoppingCart.orderLevelDiscountItems = [];

    return initialShoppingCart;
}

// ########################################################################################################
// #                                            SELECTORS                                                 #
// ########################################################################################################

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const loading_selector = createSelector(state_selector, (state: State) => state.loading);

export const shoppingCart_selector = createSelector(state_selector, (state: State) => state.shoppingCart);

export function selectShoppingCartStateProperties(prop: string): any {
    return createSelector(state_selector, (state: State) => state.shoppingCart[prop]);
}

// ########################################################################################################
// #                                             REDUCER                                                  #
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;

        case actions.SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };

        case actions.SHOPPING_CART_SET_SHOPPING_CART:
            return {
                ...state,
                shoppingCart: action.shoppingCart
            };

        case actions.SHOPPING_CART_SET_SHOPPING_CART_RESPONSE:
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    shoppingCartResponse: action.shoppingCartResponse
                }
            };

        case actions.SHOPPING_CART_SET_SHOPPING_CART_ITEMS:
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    shoppingCartItems: action.shoppingCartItems
                }
            };

        case actions.SHOPPING_CART_SET_SHOPPING_CART_ITEM_EDIT_MODE:

            let scItems: ShoppingCartItemDataModel[] = state.shoppingCart.shoppingCartItems;

            let itemIndex: number = scItems.findIndex((i) => i.isEqualTo(action.shoppingCartItem));

            scItems[itemIndex].editMode = true;

            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    shoppingCartItems: scItems
                }
            };

        case actions.SHOPPING_CART_SET_PAYMENT_ITEMS:
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    paymentItems: action.paymentItems
                }
            };

        case actions.SHOPPING_CART_SET_ORDER_LEVEL_ADDITIONAL_COST_ITEMS:
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    orderLevelAdditionalCostItems: action.orderLevelAdditionalCostItems
                }
            };

        case actions.SHOPPING_CART_SET_ORDER_LEVEL_DISCOUNT_ITEMS:
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    orderLevelDiscountItems: action.orderLevelDiscountItems
                }
            };

        default:
            return state;
    }
}
