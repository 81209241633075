import {Action} from "@ngrx/store";

export const RESET_STATE_ACTION = '[Dialog - Void Order :: MAIN] Reset state';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export type All = ResetState;
