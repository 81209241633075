import {CostTypeEnum} from "../../../../../shared/enums/cost-type.enum";
import {AmountTypeEnum} from "../../../../../shared/enums/amount-type.enum";
import {DiscountApplyTypeEnum} from "../../../../../shared/enums/discount-apply-type.enum";
import {PlatformEnum} from "../../../../../shared/enums/platform.enum";

export class UpdateCostRequestModel {
    costId: number;
    costType: CostTypeEnum;
    description: string;
    amount: number;
    amountType: AmountTypeEnum;
    isOrderLevel: boolean;
    glCode: string;
    discountCode: string;
    startDateMS: number;
    endDateMS: number;
    fromHeadCount: number;
    toHeadCount: number;
    discountApplyType: DiscountApplyTypeEnum;
    allLocations: boolean;
    locationIds: number[];
    allPlatforms: boolean;
    platforms: PlatformEnum[];

    constructor(costId: number,
                costType: CostTypeEnum,
                description: string,
                amount: number,
                amountType: AmountTypeEnum,
                isOrderLevel: boolean,
                glCode: string,
                discountCode: string,
                startDateMS: number,
                endDateMS: number,
                fromHeadCount: number,
                toHeadCount: number,
                discountApplyType: DiscountApplyTypeEnum,
                allLocations: boolean,
                locationIds: number[],
                allPlatforms: boolean,
                platforms: PlatformEnum[]) {
        this.costId = costId;
        this.costType = costType;
        this.description = description;
        this.amount = amount;
        this.amountType = amountType;
        this.isOrderLevel = isOrderLevel;
        this.glCode = glCode;
        this.discountCode = discountCode;
        this.startDateMS = startDateMS;
        this.endDateMS = endDateMS;
        this.fromHeadCount = fromHeadCount;
        this.toHeadCount = toHeadCount;
        this.discountApplyType = discountApplyType;
        this.allLocations = allLocations;
        this.locationIds = locationIds;
        this.allPlatforms = allPlatforms;
        this.platforms = platforms;
    }
}
