import * as actions from './actions';
import {LocationResponseModel} from "../../../../../../../core/modules/rest/location/response/location-response.model";
import {PassSimpleResponseModel} from "../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";

import _ from 'lodash';
import {KioskDeviceInfoSimpleModel} from "../../model/kiosk-device-info-simple.model";
import {KioskConfigurationModel} from "../../model/kiosk-configuration.model";
import {KioskConfigurationDropoffLocationValidatedModel} from "../../model/kiosk-configuration-dropoff-location-validated.model";

export interface State {
    kioskConfiguration: KioskConfigurationModel;
    kiosksWithThisAndAllWithoutConfiguration: KioskDeviceInfoSimpleModel[];
    kioskConfigurationDropoffLocations: KioskConfigurationDropoffLocationValidatedModel[];
    selectedKioskConfigurationDropoffLocationId: number;
    allLocations: LocationResponseModel[];
    allPasses: PassSimpleResponseModel[];
}

const initialState: State = {
    kioskConfiguration: null,
    kiosksWithThisAndAllWithoutConfiguration: [],
    kioskConfigurationDropoffLocations: [],
    selectedKioskConfigurationDropoffLocationId: null,
    allLocations: [],
    allPasses: []
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.UPDATE_KIOSK_CONFIGURATION_ACTION:
            return handleUpdateKioskConfigurationAction(state, action);
        case actions.UPDATE_KIOSKS_WITH_THIS_CONFIGURATION_ACTION:
            return handleUpdateKiosksWithThisConfiguration(state, action);
        case actions.UPDATE_KIOSKS_WITHOUT_CONFIGURATION_ACTION:
            return handleUpdateKiosksWithoutConfiguration(state, action);
        case actions.UPDATE_KIOSK_CHECKED_STATUS_ACTION:
            return handleUpdateKioskCheckedStatus(state, action);
        case actions.UPDATE_KIOSK_CONFIGURATION_DROPOFF_LOCATIONS_ACTION:
            return handleUpdateKioskConfigurationDropoffLocations(state, action);
        case actions.UPDATE_KIOSK_CONFIGURATION_DROPOFF_LOCATION_ACTION:
            return handleUpdateKioskConfigurationDropoffLocation(state, action);
        case actions.UPDATE_SELECTED_KIOSK_CONFIGURATION_DROPOFF_LOCATION_ID_ACTION:
            return handleUpdateSelectedKioskConfigurationDropoffLocationId(state, action);
        case actions.UPDATE_LOCATIONS_ACTION:
            return handleUpdateLocations(state, action);
        case actions.UPDATE_PASSES_ACTION:
            return handleUpdatePasses(state, action);
        case actions.UPDATE_KIOSK_CONFIGURATION_DROPOFF_LOCATION_ACTIVITY_STATUS:
            return handleUpdateKioskConfigurationDropoffLocationActivityStatus(state, action);
        case actions.ADD_KIOSK_CONFIGURATION_DROPOFF_LOCATION:
            return handleAddKioskConfigurationDropoffLocation(state, action);
        case actions.SWAP_KIOSK_CONFIGURATION_DROPOFF_LOCATIONS_PLACES:
            return handleSwapKioskConfigurationDropoffLocationsPlaces(state, action);
        case actions.DELETE_KIOSK_CONFIGURATION_DROPOFF_LOCATION:
            return handleDeleteKioskConfigurationDropoffLocation(state, action);
        default:
            return state;
    }
}


// #################################################### SELECTORS ####################################################
export const kioskConfiguration_selector = (state: State) => state.kioskConfiguration;
export const kiosksWithThisAndAllWithoutConfiguration_selector = (state: State) => state.kiosksWithThisAndAllWithoutConfiguration;
export const kioskConfigurationDropoffLocations_selector = (state: State) => state.kioskConfigurationDropoffLocations;
export const allLocations_selector = (state: State) => state.allLocations;
export const allPasses_selector = (state: State) => state.allPasses;
export const selectedKioskConfigurationDropoffLocationId_selector = (state: State) => state.selectedKioskConfigurationDropoffLocationId;


// #################################################### HANDLERS ####################################################
function handleUpdateKioskConfigurationAction(state: State, action: actions.UpdateKioskConfiguration) {
    return {
        ...state,
        kioskConfiguration: action.kioskConfiguration
    };
}

function handleUpdateKiosksWithThisConfiguration(state: State, action: actions.UpdateKiosksWithThisConfiguration) {
    return {
        ...state,
        kiosksWithThisAndAllWithoutConfiguration: [
            ...action.kioskDeviceInfos
        ]
    };
}

function handleUpdateKiosksWithoutConfiguration(state: State, action: actions.UpdateKiosksWithoutConfiguration) {
    return {
        ...state,
        kiosksWithThisAndAllWithoutConfiguration: [
            ...state.kiosksWithThisAndAllWithoutConfiguration,
            ...action.kioskDeviceInfos
        ]
    };
}

function handleUpdateKioskCheckedStatus(state: State, action: actions.UpdateKioskCheckedStatus) {

    const index = state.kiosksWithThisAndAllWithoutConfiguration.findIndex((kiosk: KioskDeviceInfoSimpleModel) =>
        kiosk.kioskDeviceInfoId === action.kioskDeviceInfoId);

    const kioskDeviceInfo: KioskDeviceInfoSimpleModel = _.cloneDeep(state.kiosksWithThisAndAllWithoutConfiguration[index]);
    kioskDeviceInfo.checked = action.checked;

    return {
        ...state,
        kiosksWithThisAndAllWithoutConfiguration: [
            ...state.kiosksWithThisAndAllWithoutConfiguration.slice(0, index),
            kioskDeviceInfo,
            ...state.kiosksWithThisAndAllWithoutConfiguration.slice(index + 1),
        ]
    };
}

function handleUpdateKioskConfigurationDropoffLocations(state: State, action: actions.UpdateKioskConfigurationDropoffLocations) {
    return {
        ...state,
        kioskConfigurationDropoffLocations: action.kioskConfigurationDropoffLocations
    };
}

function handleUpdateKioskConfigurationDropoffLocation(state: State, action: actions.UpdateKioskConfigurationDropoffLocation) {
    const index = _.findIndex(state.kioskConfigurationDropoffLocations, (dropoffLocation: KioskConfigurationDropoffLocationValidatedModel) => dropoffLocation.kioskConfigurationDropoffLocationId === action.kioskConfigurationDropoffLocation.kioskConfigurationDropoffLocationId);
    return {
        ...state,
        kioskConfigurationDropoffLocations: [
            ...state.kioskConfigurationDropoffLocations.slice(0, index),
            action.kioskConfigurationDropoffLocation,
            ...state.kioskConfigurationDropoffLocations.slice(index + 1)
        ]
    };
}

function handleUpdateSelectedKioskConfigurationDropoffLocationId(state: State, action: actions.UpdateSelectedKioskConfigurationDropoffLocationId) {
    return {
        ...state,
        selectedKioskConfigurationDropoffLocationId: action.selectedKioskConfigurationDropoffLocationId
    };
}

function handleUpdateLocations(state: State, action: actions.UpdateLocations) {
    return {
        ...state,
        allLocations: action.locations
    };
}

function handleUpdatePasses(state: State, action: actions.UpdatePasses) {
    return {
        ...state,
        allPasses: action.passes
    };
}

function handleUpdateKioskConfigurationDropoffLocationActivityStatus(state: State, action: actions.UpdateKioskConfigurationDropoffLocationActivityStatus) {

    const index = _.findIndex(state.kioskConfigurationDropoffLocations, (dropoffLocation: KioskConfigurationDropoffLocationValidatedModel) => dropoffLocation.kioskConfigurationDropoffLocationId === action.kioskConfigurationDropoffLocationId);
    const kioskConfigurationDropoffLocationClone: KioskConfigurationDropoffLocationValidatedModel = _.cloneDeep(state.kioskConfigurationDropoffLocations[index]);

    kioskConfigurationDropoffLocationClone.active = action.active;

    return {
        ...state,
        kioskConfigurationDropoffLocations: [
            ...state.kioskConfigurationDropoffLocations.slice(0, index),
            kioskConfigurationDropoffLocationClone,
            ...state.kioskConfigurationDropoffLocations.slice(index + 1)
        ]
    };
}

function handleAddKioskConfigurationDropoffLocation(state: State, action: actions.AddKioskConfigurationDropoffLocation) {
    return {
        ...state,
        kioskConfigurationDropoffLocations: [
            ...state.kioskConfigurationDropoffLocations,
            action.kioskConfigurationDropoffLocation
        ]
    };
}

function handleSwapKioskConfigurationDropoffLocationsPlaces(state: State, action: actions.SwapKioskConfigurationDropoffLocationsPlaces) {
    let index = _.findIndex(state.kioskConfigurationDropoffLocations, (dropoffLocation: KioskConfigurationDropoffLocationValidatedModel) => dropoffLocation.kioskConfigurationDropoffLocationId === action.kioskConfigurationDropoffLocationId);

    if (action.up) {
        index = index - 1;
    }

    const firstItem: KioskConfigurationDropoffLocationValidatedModel = state.kioskConfigurationDropoffLocations[index];
    const secondItem: KioskConfigurationDropoffLocationValidatedModel = state.kioskConfigurationDropoffLocations[index + 1];

    return {
        ...state,
        kioskConfigurationDropoffLocations: [
            ...state.kioskConfigurationDropoffLocations.slice(0, index),
            secondItem,
            firstItem,
            ...state.kioskConfigurationDropoffLocations.slice(index + 2)
        ]
    };
}


function handleDeleteKioskConfigurationDropoffLocation(state: State, action: actions.DeleteKioskConfigurationDropoffLocation) {

    let index = _.findIndex(state.kioskConfigurationDropoffLocations, (dropoffLocation: KioskConfigurationDropoffLocationValidatedModel) =>
        dropoffLocation.kioskConfigurationDropoffLocationId === action.kioskConfigurationDropoffLocationId);

    return {
        ...state,
        kioskConfigurationDropoffLocations: [
            ...state.kioskConfigurationDropoffLocations.slice(0, index),
            ...state.kioskConfigurationDropoffLocations.slice(index + 1)
        ]
    };
}
