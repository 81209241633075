import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {TemplateItemResponseModel} from "./response/template-item-response.model";
import {environment} from "../../../../../environments/environment";
import {GetTemplateItemsForProductsAndPassesRequest} from "./request/get-template-items-for-products-and-passes-request";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {CreateTemplateItemRequestModel} from "./request/create-template-item-request.model";
import {UpdateTemplateItemRequestModel} from "./request/update-template-item-request.model";
import {UpdateTemplateItemsRequestModel} from "./request/update-template-items-request.model";

@Injectable()
export class TemplateRestService {

    constructor(private httpClient: HttpClient) {

    }

    getTemplateItemsByTemplateId(templateId: number): Observable<TemplateItemResponseModel[]> {
        return this.httpClient.get<TemplateItemResponseModel[]>(environment.server_api_url + '/api/secured/template/getTemplateItemsByTemplateId/' + templateId);
    }

    getTemplateItemsByPassId(passId: number): Observable<TemplateItemResponseModel[]> {
        return this.httpClient.get<TemplateItemResponseModel[]>(environment.server_api_url + '/api/secured/template/getTemplateItemsByPassId/' + passId);
    }

    getTemplateItemsForProductsAndPasses(request: GetTemplateItemsForProductsAndPassesRequest): Observable<TemplateItemResponseModel[]> {
        return this.httpClient.post<TemplateItemResponseModel[]>(environment.server_api_url + '/api/secured/template/getTemplateItemsForProductsAndPasses', request);
    }

    getTemplateItemsByTemplateItemIds(templateItemIds: number[]): Observable<TemplateItemResponseModel[]> {
        return this.httpClient.post<TemplateItemResponseModel[]>(environment.server_api_url + '/api/secured/template/getTemplateItemsByTemplateItemIds', templateItemIds);
    }

    createTemplateItem(request: CreateTemplateItemRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/template/createTemplateItem', request);
    }

    updateTemplateItem(request: UpdateTemplateItemRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/template/updateTemplateItem', request, {responseType: 'text'});
    }

    updateTemplateItems(request: UpdateTemplateItemsRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/template/updateTemplateItems', request, {responseType: 'text'});
    }

    activateTemplateItems(templateItemIds: number[]): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/template/activateTemplateItems', templateItemIds, {responseType: 'text'});
    }

    deactivateTemplateItems(templateItemIds: number[]): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/template/deactivateTemplateItems', templateItemIds, {responseType: 'text'});
    }

    setRequiredTemplateItems(templateItemIds: number[]): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/template/setRequiredTemplateItems', templateItemIds, {responseType: 'text'});
    }

    setNotRequiredTemplateItems(templateItemIds: number[]): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/template/setNotRequiredTemplateItems', templateItemIds, {responseType: 'text'});
    }

    setDisplayOnTicketTemplateItems(templateItemIds: number[]): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/template/setDisplayOnTicketTemplateItems', templateItemIds, {responseType: 'text'});
    }

    setNotDisplayOnTicketTemplateItems(templateItemIds: number[]): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/template/setNotDisplayOnTicketTemplateItems', templateItemIds, {responseType: 'text'});
    }
}
