import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {SubmitClonePassPricingDateRangesData} from "./components/clone-pass-pricing-date-ranges-dialog-form/clone-pass-pricing-date-ranges-dialog-form.component";
import {ClonePassPricingDateRangesDialogSandbox} from "./sandbox/clone-pass-pricing-date-ranges-dialog.sandbox";
import {PricingDateRangeResponseModel} from "../../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-response.model";

export class ClonePassPricingDateRangesDialogData extends DialogData {

    passId: number;
    pricingDateRangeIds: number[];
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, passId: number, pricingDateRangeIds: number[]) {
        super();
        this.passId = passId;
        this.pricingDateRangeIds = pricingDateRangeIds;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-clone-pass-pricing-date-ranges-dialog',
    templateUrl: './clone-pass-pricing-date-ranges-dialog.component.html'
})
export class ClonePassPricingDateRangesDialogComponent implements OnDestroy {

    passId: number;
    pricingDateRangeIds: number[];
    successCallbackFn: Function;

    pricingDateRanges$: Observable<PricingDateRangeResponseModel[]>;

    constructor(@Inject(DialogData) data: ClonePassPricingDateRangesDialogData, private sandbox: ClonePassPricingDateRangesDialogSandbox) {

        this.passId = data.passId;
        this.pricingDateRangeIds = data.pricingDateRangeIds;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.pricingDateRangeIds);

        this.pricingDateRanges$ = this.sandbox.pricingDateRanges$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitClonePassPricingDateRangesData) {
        this.sandbox.clonePassPricingDateRanges(data).subscribe(() => {
            this.successCallbackFn(data.passId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
