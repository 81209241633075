import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ShoppingCartRequestModel} from "./request/shopping-cart-request.model";
import {ShoppingCartResponseModel} from "./response/shopping-cart-response.model";
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import { ModifyShoppingCartRequestModel } from './response/modify-shopping-cart-request.model copy';

@Injectable()
export class ShoppingCartRestService {

    constructor(private httpClient: HttpClient) {
    }

    updateShoppingCart(shoppingCartRequest: ShoppingCartRequestModel, orderExternalId: string): Observable<ShoppingCartResponseModel> {
        if(orderExternalId != "" && orderExternalId != undefined) {
            shoppingCartRequest.orderExternalId = orderExternalId;
            shoppingCartRequest.shouldGenerateExternalIds = false;
        }
        return this.httpClient.post<ShoppingCartResponseModel>(environment.server_api_url + '/api/secured/shoppingCart/updateShoppingCart', shoppingCartRequest);
    }

    getOrderForShoppingCart(modifyShoppingCartRequestModel: ModifyShoppingCartRequestModel): Observable<ShoppingCartResponseModel> {
        return this.httpClient.post<ShoppingCartResponseModel>(environment.server_api_url + '/api/secured/shoppingCart/getOrderForShoppingCart', modifyShoppingCartRequestModel);
    }
    
}
