import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditAdditionalCostDialogComponent} from "./container/edit-additional-cost-dialog.component";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditAdditionalCostDialogContentComponent} from './components/edit-additional-cost-dialog-content/edit-additional-cost-dialog-content.component';
import {EditAdditionalCostDialogSandbox} from "./sandbox/edit-additional-cost-dialog.sandbox";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditAdditionalCostDialogSandbox
    ],
    exports: [
        EditAdditionalCostDialogComponent
    ],
    declarations: [
        EditAdditionalCostDialogComponent,
        EditAdditionalCostDialogContentComponent
    ],
    entryComponents: [
        EditAdditionalCostDialogComponent
    ]
})
export class EditAdditionalCostDialogModule {
}
