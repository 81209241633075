import * as actions from './actions';
import {ActionReducer, createFeatureSelector, createSelector} from "@ngrx/store";
import {BidResponseModel} from "../../../../../../../../../core/modules/rest/bid/response/bid-response.model";


export const REDUCER_NAME = 'dialog_create/edit-bid';

export interface State {
    bid: BidResponseModel;
}

const initialState: State = {
    bid: {} as BidResponseModel
};

export const state_reducer: ActionReducer<State> = reducer;

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const bid_selector = createSelector(state_selector, (state: State) => state.bid);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_BID_ACTION: return handleSetBid(state, action);

        default: return state;
    }
}

function handleSetBid(state: State, action: actions.SetBid): State {
    return {
        ... state,
        bid: action.bid
    };
}
