import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {PassSimpleResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {DateTimeUtility} from "../../../../../../../../../shared/utils/date-time-utility";
import * as moment from "moment";

export interface SubmitPassValidityData {
    passId: number;
    isValidForDateInterval: boolean;
    validForStartDate: moment.Moment;
    validForEndDate: moment.Moment;
    isValidSincePurchaseForHours: boolean;
    validForHours: number;
    isUsageLimiterPerOccurrence: boolean;
    usageLimiterForMinutes: number;
    isUnlimited: boolean;
    initialQuantity: number;
    predictedQuantity: number;
}

@Component({
    selector: 'app-edit-pass-validity-dialog-form',
    templateUrl: './edit-pass-validity-dialog-form.component.html'
})

export class EditPassValidityDialogFormComponent implements OnChanges {

    @Input() pass: PassSimpleResponseModel;

    @Output() submit: EventEmitter<SubmitPassValidityData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    VALIDITY_TYPE_VALID_FOR_DATE_INTERVAL: string = "VALID_FOR_DATE_INTERVAL";
    VALIDITY_TYPE_VALID_FOR_HOURS: string = "VALID_FOR_HOURS";

    VALIDITY_FOR_HOURS_TYPE_VALID_SINCE_PURCHASE_FOR_HOURS: string = "VALID_SINCE_PURCHASE_FOR_HOURS";
    VALIDITY_FOR_HOURS_TYPE_VALID_SINCE_FIRST_CHECK_IN_FOR_HOURS: string = "VALID_SINCE_FIRST_CHECK_IN_FOR_HOURS";

    USAGE_LIMITER_TYPE_USAGE_LIMITER_PER_OCCURRENCE: string = "USAGE_LIMITER_PER_OCCURRENCE";
    USAGE_LIMITER_TYPE_USAGE_LIMITER_FOR_MINUTES: string = "USAGE_LIMITER_FOR_MINUTES";

    form: FormGroup;

    validityTypes: DropdownOptionModel[] = [];
    validityForHoursTypes: DropdownOptionModel[] = [];
    usageLimiterTypes: DropdownOptionModel[] = [];

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        // Populate "Validity Type" dropdown
        this.validityTypes = [
            new DropdownOptionModel("Date Range", this.VALIDITY_TYPE_VALID_FOR_DATE_INTERVAL, null),
            new DropdownOptionModel("Set Amount of Hours", this.VALIDITY_TYPE_VALID_FOR_HOURS, null)
        ];

        // Populate "Hours Since" dropdown
        this.validityForHoursTypes = [
            new DropdownOptionModel("Purchase", this.VALIDITY_FOR_HOURS_TYPE_VALID_SINCE_PURCHASE_FOR_HOURS, null),
            new DropdownOptionModel("First Check-in", this.VALIDITY_FOR_HOURS_TYPE_VALID_SINCE_FIRST_CHECK_IN_FOR_HOURS, null)
        ];

        // Populate "Usage Limit Type" dropdown
        this.usageLimiterTypes = [
            new DropdownOptionModel("Once per Occurrence", this.USAGE_LIMITER_TYPE_USAGE_LIMITER_PER_OCCURRENCE, null),
            new DropdownOptionModel("Once per Time Period", this.USAGE_LIMITER_TYPE_USAGE_LIMITER_FOR_MINUTES, null)
        ];

        this.form = new FormGroup({
            validityType: new FormControl(),
            validForStartDate: new FormControl(),
            validForEndDate: new FormControl(),
            validityForHoursType: new FormControl(),
            validForHours: new FormControl(),
            usageLimiterType: new FormControl(),
            usageLimiterForMinutes: new FormControl(),
            isUnlimited: new FormControl(),
            initialQuantity: new FormControl(),
            predictedQuantity: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pass) {
            this.initializeFormData();
        }
    }

    onSubmit() {

        let validityType: string = this.form.value.validityType;
        let validForStartDate: moment.Moment = this.form.value.validForStartDate;
        let validForEndDate: moment.Moment = this.form.value.validForEndDate;
        let validityForHoursType: string = this.form.value.validityForHoursType;
        let validForHours: number = this.form.value.validForHours !== null && this.form.value.validForHours !== undefined && String(this.form.value.validForHours).trim().length > 0 && !isNaN(parseInt(this.form.value.validForHours, 10)) ? +this.form.value.validForHours : null;
        let usageLimiterType: string = this.form.value.usageLimiterType;
        let usageLimiterForMinutes: number = this.form.value.usageLimiterForMinutes !== null && this.form.value.usageLimiterForMinutes !== undefined && String(this.form.value.usageLimiterForMinutes).trim().length > 0 && !isNaN(parseInt(this.form.value.usageLimiterForMinutes, 10)) ? +this.form.value.usageLimiterForMinutes : null;
        let isUnlimited: boolean = this.form.value.isUnlimited;
        let initialQuantity: number = +this.form.value.initialQuantity;
        let predictedQuantity: number = +this.form.value.predictedQuantity;

        if (validityType === null || validityType === undefined) {
            this.hasError = true;
            this.errorMessage = "Please select validity type";
            return;
        }

        if (validityForHoursType === null || validityForHoursType === undefined) {
            this.hasError = true;
            this.errorMessage = "Please select hours since type";
            return;
        }

        if (validityType === this.VALIDITY_TYPE_VALID_FOR_HOURS && validForHours <= 0) {
            this.hasError = true;
            this.errorMessage = "Please enter valid amount of hours for validity hours";
            return;
        }

        if (usageLimiterType === null || usageLimiterType === undefined) {
            this.hasError = true;
            this.errorMessage = "Please select usage limiter type";
            return;
        }

        if (usageLimiterType === this.USAGE_LIMITER_TYPE_USAGE_LIMITER_FOR_MINUTES && usageLimiterForMinutes <= 0) {
            this.hasError = true;
            this.errorMessage = "Please enter valid amount of minutes for usage limiter";
            return;
        }

        if (!isUnlimited && (initialQuantity === null || initialQuantity === undefined || isNaN(initialQuantity) || initialQuantity <= 0)) {
            this.hasError = true;
            this.errorMessage = "Please enter valid initial quantity";
            return;
        }

        if (isUnlimited && (predictedQuantity === null || predictedQuantity === undefined || isNaN(predictedQuantity) || predictedQuantity <= 0)) {
            this.hasError = true;
            this.errorMessage = "Please enter valid initial quantity";
            return;
        }

        this.submit.emit({
            passId: this.pass.passId,
            isValidForDateInterval: validityType === this.VALIDITY_TYPE_VALID_FOR_DATE_INTERVAL,
            validForStartDate: validForStartDate,
            validForEndDate: validForEndDate,
            isValidSincePurchaseForHours: validityForHoursType === this.VALIDITY_FOR_HOURS_TYPE_VALID_SINCE_PURCHASE_FOR_HOURS,
            validForHours: validForHours,
            isUsageLimiterPerOccurrence: usageLimiterType === this.USAGE_LIMITER_TYPE_USAGE_LIMITER_PER_OCCURRENCE,
            usageLimiterForMinutes: usageLimiterForMinutes,
            isUnlimited: isUnlimited,
            initialQuantity: initialQuantity,
            predictedQuantity: predictedQuantity
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {
        this.form.patchValue({
            validityType: this.pass.isValidForDateInterval ? this.VALIDITY_TYPE_VALID_FOR_DATE_INTERVAL : this.VALIDITY_TYPE_VALID_FOR_HOURS,
            validForStartDate: this.pass.validForStartDate ? DateTimeUtility.convertIntToDate(this.pass.validForStartDate) : null,
            validForEndDate: this.pass.validForEndDate ? DateTimeUtility.convertIntToDate(this.pass.validForEndDate) : null,
            validityForHoursType: this.pass.isValidSincePurchaseForHours ? this.VALIDITY_FOR_HOURS_TYPE_VALID_SINCE_PURCHASE_FOR_HOURS : this.VALIDITY_FOR_HOURS_TYPE_VALID_SINCE_FIRST_CHECK_IN_FOR_HOURS,
            validForHours: this.pass.validForHours,
            usageLimiterType: this.pass.isUsageLimiterPerOccurrence ? this.USAGE_LIMITER_TYPE_USAGE_LIMITER_PER_OCCURRENCE : this.USAGE_LIMITER_TYPE_USAGE_LIMITER_FOR_MINUTES,
            usageLimiterForMinutes: this.pass.usageLimiterForMinutes,
            isUnlimited: this.pass.isUnlimited,
            initialQuantity: this.pass.initialQuantity,
            predictedQuantity: this.pass.predictedQuantity
        });
    }
}
