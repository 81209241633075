import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild  } from '@angular/core';


@Component({
    selector: 'app-input-label-group',
    templateUrl: './input-label-group.component.html',
    styleUrls: ['./input-label-group.component.scss']
})
export class InputLabelGroupComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('elementWrapper')
    elementWrapper: ElementRef;

    @ViewChild('labelWrapper')
    labelWrapper: ElementRef;

    focusListener;
    blurListener;

    constructor(private renderer2: Renderer2) {
    }

    ngAfterViewInit(): void {
        const inputElement = this.elementWrapper.nativeElement.querySelector('.main--element');        
        this.focusListener = this.renderer2.listen(inputElement, 'focus', this.inputFocus.bind(this));
        this.blurListener = this.renderer2.listen(inputElement, 'blur', this.inputBlur.bind(this));
    }

    ngOnInit() {
    }

    inputFocus(event) {
        event.stopPropagation();
        const labelElement = this.labelWrapper.nativeElement.querySelector('label');
        this.renderer2.addClass(labelElement, 'labelActive');
    }

    inputBlur(event) {
        event.stopPropagation();
        const labelElement = this.labelWrapper.nativeElement.querySelector('label');
        this.renderer2.removeClass(labelElement, 'labelActive');
    }

    ngOnDestroy() {
        if (this.focusListener) {
            this.focusListener();
        }
        if (this.blurListener) {
            this.blurListener();
        }
    }
}
