import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {FavoriteResponseModel} from '../../../../../../core/modules/rest/favorite/response/favorite-response.model';


@Component({
    selector: 'app-order-report-header',
    templateUrl: './order-report-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReportHeaderComponent implements OnInit {

    @Input() listOfFavorites: FavoriteResponseModel[];
    @Input() selectedFavoriteId: number;
    @Input() expressionItemsListEmpty: boolean;
    @Input() areEnoughFieldsSelected: boolean;
    @Input() hasUseFavoritesPermission: boolean;
    @Input() hasCreateFavoritePermission: boolean;
    @Input() hasEditFavoritePermission: boolean;
    @Input() hasRemoveFavoritePermission: boolean;
    @Output() selectedFavoriteChangedEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() editFavoriteEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() removeFavoriteEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() sidebarOpenStatusChanged: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit() {
    }

    onSelectedFavoriteChanged(favoriteId: number) {
        this.selectedFavoriteChangedEvent.emit(favoriteId);
    }

    onEditFavorite(favoriteId: number) {
        this.editFavoriteEvent.emit(favoriteId);
    }

    onRemoveFavorite(favoriteId) {
        this.removeFavoriteEvent.emit(favoriteId);
    }

    onOpenCloseSidebarFavorite() {
        this.sidebarOpenStatusChanged.emit();
    }
}
