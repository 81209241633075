import {DateTimeDescriptor} from "../../../../../shared/model/date-time-descriptor.model";
import {PaymentMethodEnum} from "../../../../../shared/enums/payment-method.enum";

export class CreateCashCollectedPaymentMethodRequestModel {

    paymentMethod: PaymentMethodEnum;
    postBillTypeId: number;
    usd: number;
    totalCash: number;

    constructor(paymentMethod: PaymentMethodEnum, postBillTypeId: number, usd: number, totalCash: number) {
        this.paymentMethod = paymentMethod;
        this.postBillTypeId = postBillTypeId;
        this.usd = usd;
        this.totalCash = totalCash;
    }
}
