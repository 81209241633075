import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {TierResponseModel} from "../../../../../../../../../core/modules/rest/tier/response/tier-response.model";

export const REDUCER_NAME = 'dialog_create/edit-tier';

export interface State {
    tier: TierResponseModel;
}

const initialState: State = {
    tier: {} as TierResponseModel
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const tier_selector = createSelector(state_selector, (state: State) => state.tier);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_TIER_ACTION: return { ... state, tier: action.tier };

        default: return state;
    }
}
