export class ProductAndInactivitySelectedModel {

    selectedProducts: number[];
    showInactiveProductAvailabilities: boolean;

    constructor(selectedProducts: number[], showInactiveProductAvailabilities: boolean) {
        this.selectedProducts = selectedProducts;
        this.showInactiveProductAvailabilities = showInactiveProductAvailabilities;
    }
}
