import {AutoReportExportTypeEnum} from "../../../../../../../shared/enums/auto-report-export-type.enum";

export class CreateAutoReportRequestModel {
    description: string;
    nth: number;
    dayOfWeek: number;
    hour: number;
    startDateMS: number;
    endDateMS: number;
    exportType: AutoReportExportTypeEnum;
    email: string;
    favoriteId: number;

    constructor(description: string, nth: number, dayOfWeek: number, hour: number, startDateMS: number, endDateMS: number, exportType: AutoReportExportTypeEnum, email: string, favoriteId: number) {
        this.description = description;
        this.nth = nth;
        this.dayOfWeek = dayOfWeek;
        this.hour = hour;
        this.startDateMS = startDateMS;
        this.endDateMS = endDateMS;
        this.exportType = exportType;
        this.email = email;
        this.favoriteId = favoriteId;
    }
}
