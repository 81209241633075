import {Action} from "@ngrx/store";
import {PricingDateRangeFullResponseModel} from "../../../../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-full-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {TierResponseModel} from "../../../../../../../../../core/modules/rest/tier/response/tier-response.model";

export const RESET_STATE = '[Dialog - Edit Pass Pricing Date Ranges :: MAIN] Reset State';
export const SET_PRICING_DATE_RANGES = '[Dialog - Edit Pass Pricing Date Ranges :: MAIN] Set Pricing Date Ranges';
export const SET_PICKUP_LOCATIONS = '[Dialog - Edit Pass Pricing Date Ranges :: MAIN] Set Pickup Locations';
export const SET_DROPOFF_LOCATIONS = '[Dialog - Edit Pass Pricing Date Ranges :: MAIN] Set Dropoff Locations';
export const SET_TIERS = '[Dialog - Edit Pass Pricing Date Ranges :: MAIN] Set Tiers';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetPricingDateRanges implements Action {
    readonly type = SET_PRICING_DATE_RANGES;

    constructor(public pricingDateRanges: PricingDateRangeFullResponseModel[]) {
    }
}

export class SetPickupLocations implements Action {
    readonly type = SET_PICKUP_LOCATIONS;

    constructor(public pickupLocations: DropdownOptionModel[]) {
    }
}

export class SetDropoffLocations implements Action {
    readonly type = SET_DROPOFF_LOCATIONS;

    constructor(public dropoffLocations: DropdownOptionModel[]) {
    }
}

export class SetTiers implements Action {
    readonly type = SET_TIERS;

    constructor(public tiers: TierResponseModel[]) {
    }
}

export type All = ResetState
    | SetPricingDateRanges
    | SetPickupLocations
    | SetDropoffLocations
    | SetTiers;
