import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { DateTimeDescriptor } from "../../../../shared/model/date-time-descriptor.model";
import { EndOfShiftReportRequestModel } from "./request/end-of-shift-report-request.model";
import { UserForCashoutEntryDescriptorResponseModel } from "./response/user-for-cashout-entry-descriptor-response.model";
import { EndOfShiftReportResponseModel } from "./response/end-of-shift-report-response.model";
import { CreateCashCollectedRequestModel } from "./request/create-cash-collected-request.model";
import { CreateBaseResponseModel } from "../create-base-response.model";
import { CashCollectedReportRequestModel } from "./request/cash-collected-report-request.model";
import { CashCollectedReportResponseModel } from "./response/cash-collected-report-response.model";

@Injectable()
export class EndOfShiftRestService {

    constructor(private httpClient: HttpClient) {
    }

    getUsersForCashoutEntry(): Observable<UserForCashoutEntryDescriptorResponseModel[]> {
        return this.httpClient.get<UserForCashoutEntryDescriptorResponseModel[]>(environment.server_api_url + '/api/secured/endOfShift/getUsersForCashoutEntry');
    }

    getEndOfShiftReport(request: EndOfShiftReportRequestModel): Observable<EndOfShiftReportResponseModel> {
        return this.httpClient.post<EndOfShiftReportResponseModel>(environment.server_api_url + '/api/secured/endOfShift/getEndOfShiftReport', request);
    }

    generateUrlForExportToCsvEndOfShiftValidReport(request: EndOfShiftReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/endOfShift/generateUrlForExportToCsvEndOfShiftValidReport', request, { responseType: 'text' });
    }

    generateUrlForExportToCsvEndOfShiftInvalidReport(request: EndOfShiftReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/endOfShift/generateUrlForExportToCsvEndOfShiftInvalidReport', request, { responseType: 'text' });
    }

    generateUrlForExportToCsvEndOfShiftOrderCostsReport(request: EndOfShiftReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/endOfShift/generateUrlForExportToCsvEndOfShiftOrderCostsReport', request, { responseType: 'text' });
    }

    createCashCollected(createCashCollectedRequest: CreateCashCollectedRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/endOfShift/createCashCollected', createCashCollectedRequest);
    }

    generateUrlForPrintPdfCashoutEntry(cashCollectedId: number): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/endOfShift/generateUrlForPrintPdfCashoutEntry', cashCollectedId, { responseType: 'text' });
    }

    getCashCollectedReport(request: CashCollectedReportRequestModel): Observable<CashCollectedReportResponseModel> {
        return this.httpClient.post<CashCollectedReportResponseModel>(environment.server_api_url + '/api/secured/endOfShift/getCashCollectedReport', request);
    }

    generateUrlForExportToXlsxCashCollectedReport(request: CashCollectedReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/endOfShift/generateUrlForExportToXlsxCashCollectedReport', request, { responseType: 'text' });
    }

    reconcileCashCollected(cashCollectedId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/endOfShift/reconcileCashCollected', cashCollectedId, { responseType: 'text' });
    }

    dereconcileCashCollected(cashCollectedId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/endOfShift/dereconcileCashCollected', cashCollectedId, { responseType: 'text' });
    }
}
