import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditQuickSellingButtonPassDialogComponent} from "./container/edit-quick-selling-button-pass-dialog.component";
import {EditQuickSellingButtonPassFormComponent } from './components/edit-quick-selling-button-pass-form/edit-quick-selling-button-pass-form.component';
import {EditQuickSellingButtonPassTierFormComponent } from './components/edit-quick-selling-button-pass-form/edit-quick-selling-button-pass-tier-form/edit-quick-selling-button-pass-tier-form.component';
import {EditQuickSellingButtonPassDialogSandbox} from "./sandbox/edit-quick-selling-button-pass-dialog.sandbox";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditQuickSellingButtonPassDialogSandbox
    ],
    declarations: [
        EditQuickSellingButtonPassDialogComponent,
        EditQuickSellingButtonPassFormComponent,
        EditQuickSellingButtonPassTierFormComponent
    ],
    exports: [
        EditQuickSellingButtonPassDialogComponent
    ],
    entryComponents: [
        EditQuickSellingButtonPassDialogComponent
    ]
})
export class EditQuickSellingButtonPassDialogModule {

}
