import {NgModule} from "@angular/core";
import {AddCategoryDialogComponent} from './add-category-dialog.component';
import {AddCategoryDialogSandbox} from "./sandbox/add-category-dialog.sandbox";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {AddCategoryDialogFormComponent} from './components/add-category-dialog-form/add-category-dialog-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    declarations: [
        AddCategoryDialogComponent,
        AddCategoryDialogFormComponent
    ],
    providers: [
        AddCategoryDialogSandbox
    ],
    exports: [
        AddCategoryDialogComponent
    ],
    entryComponents: [
        AddCategoryDialogComponent
    ]
})
export class AddCategoryDialogModule {
}
