import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrderPaymentSummaryResponseModel } from "../../../../../../core/modules/rest/order-summary/response/order-payment-summary-response.model";
import { PaymentMethodEnum } from '../../../../../enums/payment-method.enum';
import { OrderStatusEnum } from "../../../../../enums/order-status.enum";
import { DiscountSummaryResponseModel } from "../../../../../../core/modules/rest/order-summary/response/discount-summary-response.model"
import { AmountTypeEnum } from '../../../../../enums/amount-type.enum';
import { PaymentStatusEnum } from '../../../../../enums/payment-status.enum';


@Component({
    selector: 'app-payment-info-dialog-summary',
    templateUrl: './payment-info-dialog-summary.component.html'
})
export class PaymentInfoDialogSummaryComponent implements OnInit, OnChanges {

    @Input() orderStatus: OrderStatusEnum;
    @Input() orderPayments: OrderPaymentSummaryResponseModel[];
    @Input() discounts: DiscountSummaryResponseModel[];

    orderPaymentsDialogData: OrderPaymentSummaryDialogData[];
    discountDialogData: DiscountSummaryDialogData[];
    ipAddress: String = "";

    readonly ORDER_STATUS_VOID: OrderStatusEnum = OrderStatusEnum.VOID;

    private formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.orderPayments) {
            this.orderPaymentsDialogData = this.getOrderPaymentSummaryDialogData(this.orderPayments);

            if (this.orderPayments != null && this.orderPayments.length > 0) {
                this.ipAddress = this.orderPayments[0].ipAddress;
            }
        }
        if (changes.discounts) {
            this.discountDialogData = this.getDiscountSummaryDialogData(this.discounts);
        }
    }

    getCellValueForPrintingCurrency(value: number): string {
        return this.formatter.format(value);
    }

    getPaymentMethodDescription(orderPayment: OrderPaymentSummaryResponseModel): string {

        let paymentMethodDescription: string = orderPayment.paymentMethodDescription;

        if (orderPayment.paymentMethod === PaymentMethodEnum.MULTI_PASS) {
            paymentMethodDescription += ": " + orderPayment.passCardExternalId;
        }

        return paymentMethodDescription;
    }

    isCreditCardPaymentMethod(paymentMethod: PaymentMethodEnum): boolean {
        if (paymentMethod === PaymentMethodEnum.VISA || paymentMethod === PaymentMethodEnum.MASTER_CARD || paymentMethod === PaymentMethodEnum.DISCOVER ||
            paymentMethod === PaymentMethodEnum.AMERICAN_EXPRESS || paymentMethod === PaymentMethodEnum.DINERS_CLUB || paymentMethod === PaymentMethodEnum.JCB) {
            return true;
        } else {
            return false;
        }
    }

    isPostBillPaymentMethod(paymentMethod: PaymentMethodEnum): boolean {
        if (paymentMethod === PaymentMethodEnum.POST_BILL) {
            return true;
        } else {
            return false;
        }
    }

    onToggle(orderPayment: OrderPaymentSummaryDialogData) {
        orderPayment.opened = !orderPayment.opened;
    }

    orderPaymentEdited(orderPayment: OrderPaymentSummaryDialogData) {

        if( orderPayment.refundedAmount > 0 && orderPayment.paymentStatus == PaymentStatusEnum.PARTIAL_REFUND ){
            return true;
        }
        return false;
        
    }

    getOrderPaymentSummaryDialogData(orderPayments: OrderPaymentSummaryResponseModel[]): OrderPaymentSummaryDialogData[] {

        if (!orderPayments) {
            return [];
        }

        let data: OrderPaymentSummaryDialogData[] = orderPayments.map((orderPayments: OrderPaymentSummaryResponseModel) => {
            return {
                amount: orderPayments.amount,
                cp: orderPayments.cp,
                createDateTime: orderPayments.createDateTime,
                createDateTimeFriendly: orderPayments.createDateTimeFriendly,
                createdByDisplayName: orderPayments.createdByDisplayName,
                createdById: orderPayments.createdById,
                createdByRoleDescription: orderPayments.createdByRoleDescription,
                lastFourOfCard: orderPayments.lastFourOfCard,
                lastUpdateDateTime: orderPayments.lastUpdateDateTime,
                lastUpdateDateTimeFriendly: orderPayments.lastUpdateDateTimeFriendly,
                lastUpdatedByDisplayName: orderPayments.lastUpdatedByDisplayName,
                lastUpdatedById: orderPayments.lastUpdatedById,
                lastUpdatedByRoleDescription: orderPayments.lastUpdatedByRoleDescription,
                nameOnCard: orderPayments.nameOnCard,
                orderId: orderPayments.orderId,
                orderPaymentDetails: [...orderPayments.orderPaymentDetails],
                orderPaymentId: orderPayments.orderPaymentId,
                orderPaymentPostBillDetails: [...orderPayments.orderPaymentPostBillDetails],
                passCardCheckInDateTime: orderPayments.passCardCheckInDateTime,
                passCardCheckInDateTimeFriendly: orderPayments.passCardCheckInDateTimeFriendly,
                passCardExternalId: orderPayments.passCardExternalId,
                passCardId: orderPayments.passCardId,
                passDescription: orderPayments.passDescription,
                passId: orderPayments.passId,
                paymentDateTimeFriendly: orderPayments.paymentDateTimeFriendly,
                paymentMethod: orderPayments.paymentMethod,
                paymentMethodDescription: orderPayments.paymentMethodDescription,
                paymentStatus: orderPayments.paymentStatus,
                paymentStatusDescription: orderPayments.paymentStatusDescription,
                refundedAmount: orderPayments.refundedAmount,
                revenue: orderPayments.revenue,
                opened: true,
                ipAddress: orderPayments.ipAddress,
            };
        });

        return data;
    }
    getDiscountSummaryDialogData(discounts: DiscountSummaryResponseModel[]): DiscountSummaryDialogData[] {

        if (!discounts) {
            return [];
        }

        let data: DiscountSummaryDialogData[] = discounts.map((discounts: DiscountSummaryResponseModel) => {

            let amountPrintValue: string = "N/A";

            if (discounts.costAmount) {
                if (discounts.amountType.toUpperCase() === AmountTypeEnum.DOLLARS) {
                    amountPrintValue = '$' + discounts.costAmount.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
                } else if (discounts.amountType.toUpperCase() === AmountTypeEnum.PERCENT) {
                    amountPrintValue = discounts.costAmount.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1') + '%';
                }
            }

            return {
                costDescription: discounts.costDescription,
                costAmount: amountPrintValue,
                amountType: discounts.amountType,
                discountCode: discounts.discountCode,
                opened: true
            };
        });

        return data;
    }
}

interface OrderPaymentSummaryDialogData extends OrderPaymentSummaryResponseModel {
    opened: boolean;
}

interface DiscountSummaryDialogData extends DiscountSummaryResponseModel {
    opened: boolean;
}
