<div class="toolboxButtons_container">
    <ul class="toolboxButtons_list clearfix">
        <li class="toolboxButtons_li float-left" [ngClass]="{'remove' : !hasCreateNotificationPermission}">
            <a class="icon_button"
               *ngIf="hasCreateNotificationPermission"
               [class.disabled]="selectedNotificationId || expressionItemsListEmpty || !areEnoughFieldsSelected"
               (click)="addNotificationClick()">

                <div class="icon_container">
                    <i class="fal fa-star"></i>
                </div>
                <span class="iconButtonLabel">
                    Add To Notification
                </span>
            </a>
        </li>

        <li class="toolboxButtons_li float-left" [ngClass]="{'remove' : !hasEditNotificationPermission}">
            <a class="icon_button"
               *ngIf="hasEditNotificationPermission"
               [class.disabled]="!selectedNotificationId || expressionItemsListEmpty || !areEnoughFieldsSelected"
               (click)="updateNotificationClick()">

                <div class="icon_container">
                    <i class="fal fa-sync"></i>
                </div>
                <span class="iconButtonLabel">
                    Update Notification
                </span>
            </a>
        </li>

        <li class="toolboxButtons_li float-left" [ngClass]="{'remove' : !hasRemoveNotificationPermission}">
            <a class="icon_button"
               *ngIf="hasRemoveNotificationPermission"
               [class.disabled]="!selectedNotificationId"
               (click)="removeNotificationClick()">

                <div class="icon_container">
                    <i class="fal fa-times-circle"></i>
                </div>
                <span class="iconButtonLabel">
                    Remove Notification
                </span>
            </a>
        </li>

        <li class="toolboxButtons_li float-left" [ngClass]="{'remove' : !hasUseNotificationsPermission}">
            <div class="dropdown_wrapper" *ngIf="!showSpanSelectNotification">
                <app-dropdown
                    [disabled]="listOfNotificationsForDropdown.length === 0"
                    [placeholderText]="'Select a notification'"
                    [preSelectedValue]="selectedNotificationId"
                    [options]="listOfNotificationsForDropdown"
                    [sort]="'ASC'"
                    (changed)='onNotificationSelected($event)'>
                </app-dropdown>
            </div>
            <a class="icon_button"
               *ngIf="showSpanSelectNotification"
               (click)="onOpenCloseSidebarNotification()">
                <div class="icon_container">
                    <i class="fal fa-bars"></i>
                </div>
                <span *ngIf="!notificationName" class="iconButtonLabel">
                    Select a notification
                </span>
                <span *ngIf="notificationName" class="iconButtonLabel">
                    {{notificationName}}
                </span>
            </a>
        </li>
    </ul>
</div>
