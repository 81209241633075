import {CreateBaseResponseModel} from "../create-base-response.model";
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {UpdateQuickSellingButtonProductOrdinalNumberRequestModel} from "./request/update-quick-selling-button-product-ordinal-number-request.model";
import {QuickSellingButtonProductResponseModel} from "./response/quick-selling-button-product-response.model";
import {CreateQuickSellingButtonProductRequestModel} from "./request/create-quick-selling-button-product-request.model";
import {UpdateQuickSellingButtonProductRequestModel} from "./request/update-quick-selling-button-product-request.model";

@Injectable()
export class QuickSellingButtonProductRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<QuickSellingButtonProductResponseModel[]> {
        return this.httpClient.get<QuickSellingButtonProductResponseModel[]>(environment.server_api_url + '/api/secured/quickSellingButtonProduct/getAll');
    }

    getQuickSellingButtonProductsByCurrentUser(): Observable<QuickSellingButtonProductResponseModel[]> {
        return this.httpClient.get<QuickSellingButtonProductResponseModel[]>(environment.server_api_url + '/api/secured/quickSellingButtonProduct/getQuickSellingButtonProductsByCurrentUser');
    }
    getById(quickSellingButtonProductId: number): Observable<QuickSellingButtonProductResponseModel> {
        return this.httpClient.get<QuickSellingButtonProductResponseModel>(environment.server_api_url + '/api/secured/quickSellingButtonProduct/getById/' + quickSellingButtonProductId);
    }

    create(request: CreateQuickSellingButtonProductRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/quickSellingButtonProduct/create', request);
    }

    update(request: UpdateQuickSellingButtonProductRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/quickSellingButtonProduct/update', request, {responseType: 'text'});
    }

    updateQuickSellingButtonProductOrdinalNumber(request: UpdateQuickSellingButtonProductOrdinalNumberRequestModel) {
        return this.httpClient.post(environment.server_api_url + '/api/secured/quickSellingButtonProduct/updateQuickSellingButtonProductOrdinalNumber', request, {responseType: 'text'});
    }

    activate(busId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/quickSellingButtonProduct/activate', busId, {responseType: 'text'});
    }

    deactivate(busId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/quickSellingButtonProduct/deactivate', busId, {responseType: 'text'});
    }
}
