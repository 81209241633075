import {HolidayDataModel} from "./holiday-data.model";
import {ProductAvailabilityFullResponseModel} from "../../../../../../core/modules/rest/product-availability/response/product-availability-full-response.model";
import {HolidayResponseModel} from "../../../../../../core/modules/rest/holiday/response/holiday-response.model";

export class ProductAvailabilityDataModel {
    productAvailabilityId: number;
    active: boolean;
    productId: number;
    description: string;
    startDateInt: number;
    startDateMS: number;
    startDateFriendly: string;
    endDateInt: number;
    endDateMS: number;
    endDateFriendly: string;
    daysAvailable: number;
    daysAvailableFriendly: string;
    startTime: number;
    startTimeFriendly: string;
    unlimitedQuantity: boolean;
    totalQuantity: number;
    isReturnTrip: boolean;
    departureGroupId: number;
    departureGroupDescription: string;
    letterGroupId: number;
    letterGroupDescription: string;
    bidId: number;
    bidDescription: string;

    holidays: HolidayDataModel[];

    constructor(productAvailabilityFull: ProductAvailabilityFullResponseModel) {
        this.productAvailabilityId = productAvailabilityFull.productAvailabilityId;
        this.active = productAvailabilityFull.active;
        this.productId = productAvailabilityFull.productId;
        this.description = productAvailabilityFull.description;
        this.startDateInt = productAvailabilityFull.startDateInt;
        this.startDateMS = productAvailabilityFull.startDateMS;
        this.startDateFriendly = productAvailabilityFull.startDateFriendly;
        this.endDateInt = productAvailabilityFull.endDateInt;
        this.endDateMS = productAvailabilityFull.endDateMS;
        this.endDateFriendly = productAvailabilityFull.endDateFriendly;
        this.daysAvailable = productAvailabilityFull.daysAvailable;
        this.daysAvailableFriendly = productAvailabilityFull.daysAvailableFriendly;
        this.startTime = productAvailabilityFull.startTime;
        this.startTimeFriendly = productAvailabilityFull.startTimeFriendly;
        this.unlimitedQuantity = productAvailabilityFull.unlimitedQuantity;
        this.totalQuantity = productAvailabilityFull.totalQuantity;
        this.isReturnTrip = productAvailabilityFull.isReturnTrip;
        this.departureGroupId = productAvailabilityFull.departureGroupId;
        this.departureGroupDescription = productAvailabilityFull.departureGroupDescription;
        this.letterGroupId = productAvailabilityFull.letterGroupId;
        this.letterGroupDescription = productAvailabilityFull.letterGroupDescription;
        this.bidId = productAvailabilityFull.bidId;
        this.bidDescription = productAvailabilityFull.bidDescription;

        this.holidays = productAvailabilityFull.holidays ?
            productAvailabilityFull.holidays.map((h: HolidayResponseModel) => new HolidayDataModel(h)) :
            [];
    }
}
