import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { SaleGroupResponseModel } from '../../../../../../../../core/modules/rest/sale-group/response/sale-group-response.model';
import { DialogData } from "../../../../../../../../shared/dialogs/model/dialog.data";
import { SaleGroupDomainFormSubmitDataModel } from '../model/sale-group-domain-form-submit-data.model';
import { AddSaleGroupItemsDialogSandbox } from '../../sandbox/add-sale-group-items-dialog.sandbox';

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class AddSaleGroupDomainDialogData extends DialogData {

    saleGroupId: number;
    saleGroupName: string;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, saleGroupId?: number, saleGroupName?: string) {
        super();
        this.saleGroupId = saleGroupId;
        this.saleGroupName = saleGroupName;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-add-sale-group-domain-dialog',
    templateUrl: './add-sale-group-domain-dialog.component.html'
})
export class AddSaleGroupDomainDialogComponent implements OnInit, OnDestroy {

    saleGroup$: Observable<SaleGroupResponseModel>;
    saleGroup: SaleGroupResponseModel;
    saleGroupId: number;
    saleGroupName: string;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: AddSaleGroupDomainDialogComponent, private sandbox: AddSaleGroupItemsDialogSandbox) {
        this.saleGroupId = dialogData.saleGroupId;
        this.saleGroupName = dialogData.saleGroupName;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
        if (this.saleGroupId && !isNaN(this.saleGroupId)) {
            this.sandbox.loadSaleGroup(this.saleGroupId);
            this.saleGroup$ = this.sandbox.saleGroup$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit( data: SaleGroupDomainFormSubmitDataModel ) {
            data.saleGroupId = this.saleGroupId;
            this.sandbox.addDomainsToSaleGroup( data ).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
