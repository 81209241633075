const NotificationAlertTypeEnum = {
    INFO: 'info' as 'info',
    ERROR: 'error' as 'error',
    SUCCESS: 'success' as 'success'
};
type NotificationAlertTypeEnum = (typeof NotificationAlertTypeEnum)[keyof typeof NotificationAlertTypeEnum];
export {NotificationAlertTypeEnum};


export class NotificationAlertModel {
    type: NotificationAlertTypeEnum;
    message: string;

    constructor(type: NotificationAlertTypeEnum, message: string) {
        this.type = type;
        this.message = message;
    }
}
