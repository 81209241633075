<div class="grid-x grid-padding-x ml--25px">
    <div class="secondaryNavColumns_container cell medium-3 large-2" *ngIf="checkInventoryUserSystemPermissions()">
        <div class="secondaryNav_column">

            <div class="secondaryNav_label">
                <h2> Inventory </h2>
            </div>

            <ul class="secondaryNav_list">
                 <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_CATEGORIES_AND_PRODUCTS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementCategoriesAndProductsPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Product Management
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_OCCURRENCE)">
                    <a class="secondaryNav_link second--nav--link"
                        routerLink="{{appRoutesService.managementOccurrenceManagementPageUrl()}}"
                        routerLinkActive="secondaryNavActive active--nav">
                        Occurrence Management
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_PASSES)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementPassesPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       MultiPass Management
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_PASS_CARDS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementPassCardManagementPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       MultiPass Card Management
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_BUSES)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementBusesPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Buses
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_HOLIDAYS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementHolidaysPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Holidays
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_LOCATIONS_AND_ROUTES)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementLocationsAndLocationListsPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Locations & Routes
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_TRIP_GROUPINGS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementGroupsPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Groups
                    </a>
                </li>
            </ul>
        </div>
    </div>


    <div class="secondaryNavColumns_container cell medium-3 large-2" *ngIf="checkSalesUserSystemPermissions()">
        <div class="secondaryNav_column">

            <div class="secondaryNav_label">
                <h2> Sales </h2>
            </div>

            <ul class="secondaryNav_list">
                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_QSB_PRODUCTS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementQuickSellingButtonProductsPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                        QSB Products
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_QSB_PASSES)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementQuickSellingButtonPassesPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       QSB Passes
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_TIERS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementTiersPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Tiers
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_COSTS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementCostsPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Costs
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_POST_BILL_TYPES)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementPostBillTypesPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Post Bill Types
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_COMMISSIONS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementCommissionsPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Commissions
                    </a>
                </li>
            </ul>
        </div>
    </div>


    <div class="secondaryNavColumns_container cell medium-3 large-2" *ngIf="checkSystemUserSystemPermissions()">
        <div class="secondaryNav_column">

            <div class="secondaryNav_label">
                <h2> System </h2>
            </div>

            <ul class="secondaryNav_list">

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_EMAIL_HTML_TEMPLATES)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementEmailHtmlTemplatesPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Templates
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_ROLES_AND_USERS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementRolesAndUsersPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       User Management
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_FAVORITES)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementFavoritesPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Favorites Management
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_NOTIFICATIONS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementNotificationsPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Notifications Management
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="secondaryNavColumns_container cell medium-3 large-2" *ngIf="checkHardwareUserSystemPermissions()">
        <div class="secondaryNav_column">

            <div class="secondaryNav_label">
                <h2> Hardware </h2>
            </div>

            <ul class="secondaryNav_list">
                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_ANDROIDS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementAndroidDeviceInfosPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Android Devices
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_OVERRIDE_REASON)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementOverrideReasonPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Override Reasons
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_KIOSKS) && customKioskPermission()">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementKioskDeviceInfosPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Kiosks
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_KIOSK_CONFIGURATION_DETAILS) && customKioskConfigurationPermission()">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementKioskConfigurationsPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Kiosk Configurations
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_CLOUD_PAYMENTS) && customCloudPaymentPermission()">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementCloudPaymentDevicesPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Cloud Payment Devices
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="secondaryNavColumns_container cell medium-3 large-2" *ngIf="checkCustomersUserSystemPermissions() ">
        <div class="secondaryNav_column">

            <div class="secondaryNav_label">
                <h2> Customers </h2>
            </div>

            <ul class="secondaryNav_list">
                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_CUSTOMERS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementCustomersPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Customers
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().EDIT_SALE_GROUP)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementSaleGroupsManagmentPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Sale Groups Management
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_DOMAINS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementDomainsPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Domains Management
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_CUSTOMER_OWNER)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementCustomerOwnerPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Customer Owner Management
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="userSystemPermissions(allSystemPermissions().MANAGE_SALE_GROUPS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementSaleGroupsPageUrl()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Sale Groups
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="secondaryNavColumns_container cell medium-3 large-2" *ngIf="adminPanelPermission()">
        <div class="secondaryNav_column">

            <div class="secondaryNav_label">
                <h2> Admin Panel </h2>
            </div>

            <ul class="secondaryNav_list">

                <li class="secondaryNav_li">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementAdminPanelConfigurations()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Configuration
                    </a>
                </li>

                <li class="secondaryNav_li">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementAdminPanelAnalytics()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Analytics
                    </a>
                </li>

                <li class="secondaryNav_li">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementAdminPanelMonitoring()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Monitoring
                    </a>
                </li>

                <li class="secondaryNav_li">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementAdminPanelMerchants()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Merchants
                    </a>
                </li>

                <li class="secondaryNav_li">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementAdminPanelTools()}}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Tools
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="isUserAdmin() && userSystemPermissions(allSystemPermissions().MANAGE_SYSTEM_OPTIONS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementSystemOptionsPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       System Options
                    </a>
                </li>

                <li class="secondaryNav_li" *ngIf="isUserAdmin() && userSystemPermissions(allSystemPermissions().MANAGE_COMPANY_INFOS)">
                    <a class="secondaryNav_link second--nav--link"
                       routerLink="{{appRoutesService.managementCompanyInfosPageUrl() }}"
                       routerLinkActive="secondaryNavActive active--nav">
                       Company Infos
                    </a>
                </li>
            </ul>
        </div>
    </div>

</div>
