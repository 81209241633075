import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import SimpleBar from 'simplebar';
import {SortByModel} from '../../../../../../../../../shared/model/sort-by.model';
import {CommissionReportCurrentSearchDataModel} from "../../../../../commission-report/model/commission-report-current-search-data.model";

declare var $: any;

@Component({
    selector: 'app-commission-report-table-element',
    templateUrl: './commission-report-table-element.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CommissionReportTableElementComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() currentSearchData: CommissionReportCurrentSearchDataModel; // Used in ngOnChanges
    @Input() currentSearchDataSortBy: SortByModel;

    @Output() headerSortEvent: EventEmitter<string> = new EventEmitter();

    @ViewChild('tableContainer', {static: false}) tableContainer;

    scrollBar: SimpleBar;
    scrollContainer = document.getElementsByClassName('infiniteScroll_container')[0];
    positionHorizontalScrollBarHandler: any;

    private formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });

    constructor() {
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.scrollBar = null;
        this.scrollContainer.removeEventListener('scroll', this.positionHorizontalScrollBarHandler);
        this.positionHorizontalScrollBarHandler = null;
    }

    ngAfterViewInit() {

        this.positionHorizontalScrollBarHandler = this.positionHorizontalScrollBar.bind(this);
        this.scrollContainer.addEventListener('scroll', this.positionHorizontalScrollBarHandler);

        // Initialization of horizontal scroll bar needs to be delayed because table header renders "too late"
        let that = this;
        setTimeout(() => {
            that.scrollBar = new SimpleBar(that.tableContainer.nativeElement, {
                autoHide: false,
            });
            that.scrollBar.recalculate();

            that.positionHorizontalScrollBar();
        }, 300);
    }

    positionHorizontalScrollBar() {

        const windowH = window.innerHeight;
        const tableBottom = this.tableContainer.nativeElement.getBoundingClientRect().bottom;
        const horizontalScroll = this.tableContainer.nativeElement.querySelector('.simplebar-horizontal');

        if (horizontalScroll !== undefined) {
            if (windowH < tableBottom) {
                $(horizontalScroll).offset({
                    top: windowH - 15
                });
            } else {
                $(horizontalScroll).offset({
                    top: tableBottom + 2
                });
            }
        }
    }

    sortByHeaderClick(header: string) {
        this.headerSortEvent.emit(header);
    }

    getCellValueForPrintingCurrency(value: number): string {
        return this.formatter.format(value);
    }
}
