import {Subject, Observable, BehaviorSubject} from 'rxjs';
import { Injectable } from "@angular/core";


@Injectable()
export class AccordionItemService {

    private itemActivityStatus: Subject<boolean> = new BehaviorSubject(false);
    private itemHeaderClick: Subject<void> = new Subject<void>();

    constructor() {
    }

    listenOnItemActivityStatus(): Observable<boolean> {
        return this.itemActivityStatus;
    }

    changeItemActivityStatus(active: boolean) {
        this.itemActivityStatus.next(active);
    }

    listenOnHeaderClick(): Observable<void> {
        return this.itemHeaderClick;
    }

    headerClick() {
        this.itemHeaderClick.next();
    }
}
