


<app-standard-page-layout
    [pageDataLoaded]="pageDataLoaded$ | async"
    [pageBreadcrumbItems]="pageBreadcrumbItems"
    [pageTitle]="'System Costs'">


    <app-additional-costs-container
        [additionalCosts]="additionalCosts$ | async"
        [activeCostType]="activeCostType$ | async"
        [showInactiveAdditionalCosts]="showInactiveAdditionalCosts$ | async"
        (activateCostTypeEvent)="onActivateCostType($event)"
        (addNewAdditionalCostEvent)="onAddNewAdditionalCost()"
        (showInactiveAdditionalCostsEvent)="onShowInactiveAdditionalCosts($event)"
        (mappingAdditionalCostEvent)="onMappingAdditionalCost($event)"
        (editAdditionalCostEvent)="onEditAdditionalCost($event)"
        (editAdditionalCostActivityEvent)="onEditAdditionalCostActivity($event)">
    </app-additional-costs-container>


    <!-- <app-included-costs-container
        [includedCosts]="includedCosts$ | async"
        [activeCostType]="activeCostType$ | async"
        [showInactiveIncludedCosts]="showInactiveIncludedCosts$ | async"
        (activateCostTypeEvent)="onActivateCostType($event)"
        (addNewIncludedCostEvent)="onAddNewIncludedCost()"
        (showInactiveIncludedCostsEvent)="onShowInactiveIncludedCosts($event)"
        (mappingIncludedCostEvent)="onMappingIncludedCost($event)"
        (editIncludedCostEvent)="onEditIncludedCost($event)"
        (editIncludedCostActivityEvent)="onEditIncludedCostActivity($event)">
    </app-included-costs-container> -->


    <app-discounts-container
        [discounts]="discounts$ | async"
        [activeCostType]="activeCostType$ | async"
        [showInactiveDiscounts]="showInactiveDiscounts$ | async"
        (activateCostTypeEvent)="onActivateCostType($event)"
        (addNewDiscountEvent)="onAddNewDiscount()"
        (showInactiveDiscountsEvent)="onShowInactiveDiscounts($event)"
        (mappingDiscountEvent)="onMappingDiscount($event)"
        (editDiscountEvent)="onEditDiscount($event)"
        (editDiscountActivityEvent)="onEditDiscountActivity($event)">
    </app-discounts-container>


    <app-auto-applied-discounts-container
        [autoAppliedDiscounts]="autoAppliedDiscounts$ | async"
        [activeCostType]="activeCostType$ | async"
        [showInactiveAutoAppliedDiscounts]="showInactiveAutoAppliedDiscounts$ | async"
        (activateCostTypeEvent)="onActivateCostType($event)"
        (addNewAutoAppliedDiscountEvent)="onAddNewAutoAppliedDiscount()"
        (showInactiveAutoAppliedDiscountsEvent)="onShowInactiveAutoAppliedDiscounts($event)"
        (mappingAutoAppliedDiscountEvent)="onMappingAutoAppliedDiscount($event)"
        (editAutoAppliedDiscountEvent)="onEditAutoAppliedDiscount($event)"
        (editAutoAppliedDiscountActivityEvent)="onEditAutoAppliedDiscountActivity($event)"
        (autoAppliedDiscountPriorityEvent)="onAutoAppliedDiscountPriorityChanged($event)">
    </app-auto-applied-discounts-container>


</app-standard-page-layout>

<ng-template #dialogContainer></ng-template>
