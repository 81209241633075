
<div class="reportItemContainer">
    <!-- dragTransitClass - fallback for IE - dragImage not supported -->
    <p class="reportItem draggableItem" 
    draggable="true" [dragData]="getDragData()" (onDragStart)="onDragStart()" (onDragEnd)="onDragEnd()" 
                     [dragImage]= "!isIE ? dragImage : null"
                     [dragTransitClass]="isIE ? 'is-ie-drag' : null">
       {{reportItem.description}}
    </p>

    <!-- Hover icons pointing to available drop areas -->
    <div class="reportItemIconsContainer">
        <div class="icon_container filterIcon"
             [class.disabled]="!reportItem.filter">
        </div>
        <div class="icon_container fieldIcon"
             [class.disabled]="!reportItem.field || reportItem.fieldDisabled">
        </div>
    </div>
</div>
