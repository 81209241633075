import {CreateQuickSellingButtonPassTierRequestModel} from "./create-quick-selling-button-pass-tier-request.model";

export class CreateQuickSellingButtonPassRequestModel {
    description: string;
    passId: number;
    pickupLocationId: number;
    dropoffLocationId: number;
    useLastName: boolean;
    lastNameRequired: boolean;
    useEmail: boolean;
    emailRequired: boolean;
    usePhone: boolean;
    phoneRequired: boolean;
    useDiscountCode: boolean;
    color: string;
    quickSellingButtonPassTiers: CreateQuickSellingButtonPassTierRequestModel[];

    constructor(description: string, passId: number, pickupLocationId: number, dropoffLocationId: number, useLastName: boolean, lastNameRequired: boolean, useEmail: boolean, emailRequired: boolean, usePhone: boolean, phoneRequired: boolean, useDiscountCode: boolean, color: string, quickSellingButtonPassTiers: CreateQuickSellingButtonPassTierRequestModel[]) {
        this.description = description;
        this.passId = passId;
        this.pickupLocationId = pickupLocationId;
        this.dropoffLocationId = dropoffLocationId;
        this.useLastName = useLastName;
        this.lastNameRequired = lastNameRequired;
        this.useEmail = useEmail;
        this.emailRequired = emailRequired;
        this.usePhone = usePhone;
        this.phoneRequired = phoneRequired;
        this.useDiscountCode = useDiscountCode;
        this.color = color;
        this.quickSellingButtonPassTiers = quickSellingButtonPassTiers;
    }
}
