import { Component, OnInit  } from '@angular/core';
import {BreadcrumbItemModel} from '../../../../../shared/components/breadcrumbs/breadcrumb-item.model';
import {Observable} from 'rxjs';
import {AppRoutesService} from '../../../../../core/services/app-routes.service';
import {CostsSandbox} from './store/costs.sandbox';
import {AccordionItemService} from '../../../../../shared/components/accordion/accordion-item/accordion-item.service';
import {
    EditAdditionalCostDialogComponent,
    EditAdditionalCostDialogData
} from "./dialogs/edit-additional-cost-dialog/container/edit-additional-cost-dialog.component";
import {AppDialogsService} from "../../../../../core/services/app-dialogs.service";
import {
    EditIncludedCostDialogComponent,
    EditIncludedCostDialogData
} from "./dialogs/edit-included-cost-dialog/container/edit-included-cost-dialog.component";
import {
    EditDiscountDialogComponent,
    EditDiscountDialogData
} from "./dialogs/edit-discount-dialog/container/edit-discount-dialog.component";
import {
    EditAutoAppliedDiscountDialogComponent,
    EditAutoAppliedDiscountDialogData
} from "./dialogs/edit-auto-applied-discount-dialog/container/edit-auto-applied-discount-dialog.component";
import {CostTypeEnum} from "../../../../../shared/enums/cost-type.enum";
import {AdditionalCostResponseModel} from "../../../../../core/modules/rest/cost/response/additional-cost-response.model";
import {AutoAppliedDiscountResponseModel} from "../../../../../core/modules/rest/cost/response/auto-applied-discount-response.model";
import {DiscountResponseModel} from "../../../../../core/modules/rest/cost/response/discount-response.model";
import {IncludedCostResponseModel} from "../../../../../core/modules/rest/cost/response/included-cost-response.model";
import {AutoAppliedDiscountPriorityEventDataModel} from "./model/auto-applied-discount-priority-event-data.model";
import {
    MappingCostDialogComponent,
    MappingCostDialogData
} from "./dialogs/mapping-cost-dialog/container/mapping-cost-dialog.component";


export const USE_AMOUNT_AS_DISCOUNT_PER_PERSON_PRINT_STRING = 'Use amount as discount per person ($ or % amount type)';
export const USE_AMOUNT_AS_TOTAL_PER_PERSON_PRINT_STRING = 'Use amount as total per person (only for $ amount type!)';
export const USE_AMOUNT_AS_TOTAL_PER_ORDER_DETAIL_OR_PASS_ORDER_DETAIL_PRINT_STRING = 'Use amount as total per order detail or pass order detail (only for $ amount type!)';



@Component({
    selector: 'app-costs',
    templateUrl: './costs.component.html',
    providers: [AccordionItemService]
})
export class CostsComponent implements OnInit {

    pageBreadcrumbItems: BreadcrumbItemModel[];

    pageDataLoaded$: Observable<boolean>;
    activeCostType$: Observable<CostTypeEnum>;
    additionalCosts$: Observable<AdditionalCostResponseModel[]>;
    autoAppliedDiscounts$: Observable<AutoAppliedDiscountResponseModel[]>;
    discounts$: Observable<DiscountResponseModel[]>;
    includedCosts$: Observable<IncludedCostResponseModel[]>;

    showInactiveAdditionalCosts$: Observable<boolean>;
    showInactiveIncludedCosts$: Observable<boolean>;
    showInactiveDiscounts$: Observable<boolean>;
    showInactiveAutoAppliedDiscounts$: Observable<boolean>;


    constructor(private costsSandbox: CostsSandbox,
                private appDialogsService: AppDialogsService,
                private appRoutesService: AppRoutesService) {
        this.pageBreadcrumbItems = this.appRoutesService.createCostsPageBreadcrumbs();

        this.pageDataLoaded$ = this.costsSandbox.getPageDataLoaded();
        this.activeCostType$ = this.costsSandbox.getActiveCostType();
        this.additionalCosts$ = this.costsSandbox.getAllAdditionalCostsDependingOnActivity();
        this.autoAppliedDiscounts$ = this.costsSandbox.getAllAutoAppliedDiscountsDependingOnActivity();
        this.discounts$ = this.costsSandbox.getAllDiscountsDependingOnActivity();
        this.includedCosts$ = this.costsSandbox.getAllIncludedCostsDependingOnActivity();

        this.showInactiveAdditionalCosts$ = this.costsSandbox.getShowInactiveAdditionalCosts();
        this.showInactiveIncludedCosts$ = this.costsSandbox.getShowInactiveIncludedCosts();
        this.showInactiveDiscounts$ = this.costsSandbox.getShowInactiveDiscounts();
        this.showInactiveAutoAppliedDiscounts$ = this.costsSandbox.getShowInactiveAutoAppliedDiscounts();
    }

    ngOnInit() {
        // Load page data
        this.costsSandbox.loadPageData();
    }

    onActivateCostType(costType: CostTypeEnum) {
        this.costsSandbox.updateActiveCostType(costType);
    }

    onCostsChanged() {
        this.costsSandbox.loadPageData();
    }

    // -------------------------------------------------------------------------------------------------------------------------------------------
    // ADDITIONAL COSTS
    // -------------------------------------------------------------------------------------------------------------------------------------------

    onShowInactiveAdditionalCosts(show: boolean) {
        this.costsSandbox.updateShowInactiveAdditionalCosts(show);
    }

    onAddNewAdditionalCost() {
        this.appDialogsService.renderDialog(EditAdditionalCostDialogComponent, new EditAdditionalCostDialogData(this.onCostsChanged.bind(this)));
    }

    onMappingAdditionalCost(costInfo: [number, string]) {
        this.appDialogsService.renderDialog(MappingCostDialogComponent, new MappingCostDialogData(this.onCostsChanged.bind(this), costInfo[0], costInfo[1]));
    }

    onEditAdditionalCost(costId: number) {
        this.appDialogsService.renderDialog(EditAdditionalCostDialogComponent, new EditAdditionalCostDialogData(this.onCostsChanged.bind(this), costId));
    }

    onEditAdditionalCostActivity(costId: number) {
        this.costsSandbox.updateAdditionalCostActivity(costId);
    }

    // -------------------------------------------------------------------------------------------------------------------------------------------
    // INCLUDED COSTS
    // -------------------------------------------------------------------------------------------------------------------------------------------

    onShowInactiveIncludedCosts(show: boolean) {
        this.costsSandbox.updateShowInactiveIncludedCosts(show);
    }

    onAddNewIncludedCost() {
        this.appDialogsService.renderDialog(EditIncludedCostDialogComponent, new EditIncludedCostDialogData(this.onCostsChanged.bind(this)));
    }

    onMappingIncludedCost(costInfo: [number, string]) {
        this.appDialogsService.renderDialog(MappingCostDialogComponent, new MappingCostDialogData(this.onCostsChanged.bind(this), costInfo[0], costInfo[1]));
    }

    onEditIncludedCost(costId: number) {
        this.appDialogsService.renderDialog(EditIncludedCostDialogComponent, new EditIncludedCostDialogData(this.onCostsChanged.bind(this), costId));
    }

    onEditIncludedCostActivity(costId: number) {
        this.costsSandbox.updateIncludedCostActivity(costId);
    }

    // -------------------------------------------------------------------------------------------------------------------------------------------
    // DISCOUNTS
    // -------------------------------------------------------------------------------------------------------------------------------------------

    onShowInactiveDiscounts(show: boolean) {
        this.costsSandbox.updateShowInactiveDiscounts(show);
    }

    onAddNewDiscount() {
        this.appDialogsService.renderDialog(EditDiscountDialogComponent, new EditDiscountDialogData(this.onCostsChanged.bind(this)));
    }

    onMappingDiscount(costInfo: [number, string]) {
        this.appDialogsService.renderDialog(MappingCostDialogComponent, new MappingCostDialogData(this.onCostsChanged.bind(this), costInfo[0], costInfo[1]));
    }

    onEditDiscount(costId: number) {
        this.appDialogsService.renderDialog(EditDiscountDialogComponent, new EditDiscountDialogData(this.onCostsChanged.bind(this), costId));
    }

    onEditDiscountActivity(costId: number) {
        this.costsSandbox.updateDiscountActivity(costId);
    }

    // -------------------------------------------------------------------------------------------------------------------------------------------
    // AUTO APPLIED DISCOUNTS
    // -------------------------------------------------------------------------------------------------------------------------------------------

    onShowInactiveAutoAppliedDiscounts(show: boolean) {
        this.costsSandbox.updateShowInactiveAutoAppliedDiscounts(show);
    }

    onAddNewAutoAppliedDiscount() {
        this.appDialogsService.renderDialog(EditAutoAppliedDiscountDialogComponent, new EditAutoAppliedDiscountDialogData(this.onCostsChanged.bind(this)));
    }

    onMappingAutoAppliedDiscount(costInfo: [number, string]) {
        this.appDialogsService.renderDialog(MappingCostDialogComponent, new MappingCostDialogData(this.onCostsChanged.bind(this), costInfo[0], costInfo[1]));
    }

    onEditAutoAppliedDiscount(costId: number) {
        this.appDialogsService.renderDialog(EditAutoAppliedDiscountDialogComponent, new EditAutoAppliedDiscountDialogData(this.onCostsChanged.bind(this), costId));
    }

    onEditAutoAppliedDiscountActivity(costId: number) {
        this.costsSandbox.updateAutoAppliedDiscountActivity(costId);
    }

    onAutoAppliedDiscountPriorityChanged(data: AutoAppliedDiscountPriorityEventDataModel) {
        this.costsSandbox.updateAutoAppliedDiscountPriority(data.costId, data.up);
    }
}
