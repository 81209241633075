import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {SubmitPassCostsData} from "./components/edit-pass-costs-dialog-form/edit-pass-costs-dialog-form.component";
import {EditPassCostsDialogSandbox} from "./sandbox/edit-pass-costs-dialog.sandbox";
import {PassCostResponseModel} from "../../../../../../../core/modules/rest/pass/response/pass-cost-response.model";
import {DropdownOptionModel} from "../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export class EditPassCostsDialogData extends DialogData {

    passId: number;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, passId: number) {
        super();
        this.passId = passId;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-edit-pass-costs-dialog',
    templateUrl: './edit-pass-costs-dialog.component.html'
})
export class EditPassCostsDialogComponent implements OnDestroy {

    passId: number;
    successCallbackFn: Function;

    loading$: Observable<boolean>;
    passAdditionalCosts$: Observable<PassCostResponseModel[]>;
    additionalCosts$: Observable<DropdownOptionModel[]>;
    passIncludedCosts$: Observable<PassCostResponseModel[]>;
    includedCosts$: Observable<DropdownOptionModel[]>;
    passDiscounts$: Observable<PassCostResponseModel[]>;
    discounts$: Observable<DropdownOptionModel[]>;
    passAutoAppliedDiscounts$: Observable<PassCostResponseModel[]>;
    autoAppliedDiscounts$: Observable<DropdownOptionModel[]>;

    constructor(@Inject(DialogData) data: EditPassCostsDialogData, private sandbox: EditPassCostsDialogSandbox) {

        this.passId = data.passId;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.passId);

        this.loading$ = this.sandbox.loading$;
        this.passAdditionalCosts$ = this.sandbox.passAdditionalCosts$;
        this.additionalCosts$ = this.sandbox.additionalCosts$;
        this.passIncludedCosts$ = this.sandbox.passIncludedCosts$;
        this.includedCosts$ = this.sandbox.includedCosts$;
        this.passDiscounts$ = this.sandbox.passDiscounts$;
        this.discounts$ = this.sandbox.discounts$;
        this.passAutoAppliedDiscounts$ = this.sandbox.passAutoAppliedDiscounts$;
        this.autoAppliedDiscounts$ = this.sandbox.autoAppliedDiscounts$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitPassCostsData) {
        this.sandbox.updatePassCosts(data).subscribe(() => {
            this.successCallbackFn(data.passId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
