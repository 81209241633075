export class FieldItemModel {
    name: string;
    queryFieldName: string;
    summable: boolean;
    groupable: boolean;

    constructor(name: string, queryFieldName: string, summable: boolean, groupable: boolean) {
        this.name = name;
        this.queryFieldName = queryFieldName;
        this.summable = summable;
        this.groupable = groupable;
    }
}
