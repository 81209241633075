import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {TemplateItemResponseModel} from "../../../../../../../core/modules/rest/template/response/template-item-response.model";
import {SubmitCategoryTemplateItemsData} from "./components/edit-category-template-items-dialog-form/edit-category-template-items-dialog-form.component";
import {EditCategoryTemplateItemsDialogSandbox} from "./sandbox/edit-category-template-items-dialog.sandbox";

export class EditCategoryTemplateItemsDialogData extends DialogData {

    isEditMode: boolean;
    categoryId: number;
    templateId: number;
    templateItemIds: number[];
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, isEditMode: boolean, categoryId: number, templateId: number, templateItemIds: number[]) {
        super();
        this.isEditMode = isEditMode;
        this.categoryId = categoryId;
        this.templateId = templateId;
        this.templateItemIds = templateItemIds;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-edit-category-template-items-dialog',
    templateUrl: './edit-category-template-items-dialog.component.html'
})
export class EditCategoryTemplateItemsDialogComponent implements OnDestroy {

    isEditMode: boolean;
    categoryId: number;
    templateId: number;
    templateItemIds: number[];
    successCallbackFn: Function;

    templateItems$: Observable<TemplateItemResponseModel[]>;

    constructor(@Inject(DialogData) data: EditCategoryTemplateItemsDialogData, private sandbox: EditCategoryTemplateItemsDialogSandbox) {

        this.isEditMode = data.isEditMode;
        this.categoryId = data.categoryId;
        this.templateId = data.templateId;
        this.templateItemIds = data.templateItemIds;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.templateItemIds);

        this.templateItems$ = this.sandbox.templateItems$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitCategoryTemplateItemsData) {
        this.sandbox.updateCategoryTemplateItems(data).subscribe(() => {
            this.successCallbackFn(data.categoryId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
