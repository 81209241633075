<app-abstract-dialog-container>
    <app-edit-sale-group-form
        [editMode]="editMode"
        [saleGroup]="saleGroup"
        (submitEvent)="onSubmit($event)"
        (closeEvent)="onClose()">
    </app-edit-sale-group-form>
</app-abstract-dialog-container>


