<ng-container *ngFor="let rb of data">
    <div class="radio-input-wrapper {{containerClassList}}" [ngClass]="{'disabled': rb.disabled}">
        <input
            (click)="radioButtonClick(rb.value)"
            name="radio-group-{{RADIO_GROUP_ID}}"
            type="radio"
            class="radio-btn radio-btn-primary {{inputClassList}}"
            [value]="rb.value"
            [id]="rb.value"
            [checked]="_selectedValue === rb.value">

        <label [for]="rb.value">
            {{rb.label}}
        </label>
    </div>
</ng-container>
