export class DropdownOptionModel {
    label: string;
    value: string;
    openDropdownLabel: string;

    constructor(label: string, value: string, openDropdownLabel?: string) {
        this.label = label;
        this.value = value;
        this.openDropdownLabel = openDropdownLabel;
    }
}
