<app-abstract-dialog-container>
    <app-edit-quick-selling-button-product-form

        [editMode]="editMode"

        [quickSellingButtonProduct]="quickSellingButtonProduct$ | async"
        [product]="product$ | async"

        [tiers]="tiers$ | async"
        [tiersLoading]="tiersLoading$ | async"
        [categories]="categories$ | async"
        [categoriesLoading]="categoriesLoading$ | async"
        [products]="products$ | async"
        [productsLoading]="productsLoading$ | async"
        [pickupLocations]="pickupLocations$ | async"
        [pickupLocationsLoading]="pickupLocationsLoading$ | async"
        [dropoffLocations]="dropoffLocations$ | async"
        [dropoffLocationsLoading]="dropoffLocationsLoading$ | async"
        [startTimes]="startTimes$ | async"
        [startTimesLoading]="startTimesLoading$ | async"
        [startTimesReturnTrip]="startTimesReturnTrip$ | async"
        [startTimesReturnTripLoading]="startTimesReturnTripLoading$ | async"

        [errorMessage]="errorMessage$ | async"

        (selectedCategoryChangedEvent)="onSelectedCategoryChanged($event)"
        (selectedProductChangedEvent)="onSelectedProductChanged($event)"
        (selectedPickupLocationChangedEvent)="onSelectedPickupLocationChanged($event)"
        (selectedDropoffLocationChangedEvent)="onSelectedDropoffLocationChanged($event)"

        (submitEvent)="onSubmit($event)"
        (closeEvent)="onClose()">
    </app-edit-quick-selling-button-product-form>
</app-abstract-dialog-container>
