<div class="selectedFiltersArea_wrapper"
     [ngClass]="{'active'  : dropAreasVisible,
                 'disabled': draggedData && !dropAreasVisible }">
    <div class="detectLeftEdge"
         appMousePointerNear [disabled]="!dropAreasVisible" [distance]="50" (pointerNearEvent)="onMousePointerNearLeftEdge($event)">
    </div>

    <div class="fixedButton">

        <ng-container *ngIf="hasRunOrderReportFullPermission">
            <app-button
                [buttonType]="'secondary small'"
                (clickEvent)="onAddGroupClick()">
                + Add Group
            </app-button>
        </ng-container>
    </div>

    <div class="selectedFiltersArea row-padding"
        [class.remove]="(expressionItems.length === 0 && !dropAreasVisible)"
        appScrollDirective
        #selectedFiltersArea>

       <ul class="expressionItemsList">
           <app-order-reports-filter-drop-area
               [enabled]="dropAreasVisible && !isFirstExpressionItemCurrentlyInDrag()"
               [visible]="dropAreasVisible && expressionItems.length === 0"
               [itemDepth]="0"
               [draggedItemHeight]="draggedItemHeight"
               [parentGroupUUID]="null"
               [leftSiblingUUID]="null"
               (deleteExpressionItemEvent)="onDeleteExpressionItem($event)"
               (droppedDataEvent)="onDroppedData($event)"
               (dragEndEvent)="onDragEnd()">
           </app-order-reports-filter-drop-area>

           <ng-container
               *ngFor="let expressionItem of expressionItems; trackBy: trackByExpressionItems; let i = index">
               <app-order-reports-expression-item
                   [itemDepth]="0"
                   [draggedItemHeight]="draggedItemHeight"
                   [expressionItem]="expressionItem"
                   [leftExpressionItem]="i > 0 ? expressionItems[i-1] : null"
                   [rightExpressionItem]="i < expressionItems.length - 1 ? expressionItems[i+1] : null"
                   [draggedData]="draggedData"
                   [dropAreasVisible]="dropAreasVisible"
                   [parentGroupUUID]="null"
                   [leftSiblingUUID]="i === 0 ? null : expressionItems[i-1].uuid"
                   [hasRunOrderReportFullPermission]="hasRunOrderReportFullPermission"
                   (droppedDataEvent)="onDroppedData($event)"
                   (deleteExpressionItemEvent)="onDeleteExpressionItem($event)"
                   (dragStartEvent)="onDragStart($event)"
                   (dragEndEvent)="onDragEnd()"
                   (expressionItemValueChangedEvent)="onExpressionItemValueChanged($event)">
               </app-order-reports-expression-item>
           </ng-container>

           <ng-container
               *ngIf="expressionItems.length !== 0">
               <app-order-reports-filter-drop-area
                   [enabled]="dropAreasVisible && expressionItems.length !== 0 && !isLastExpressionItemCurrentlyInDrag()"
                   [itemDepth]="0"
                   [draggedItemHeight]="draggedItemHeight"
                   [parentGroupUUID]="null"
                   [leftSiblingUUID]="expressionItems[expressionItems.length - 1].uuid"
                   (deleteExpressionItemEvent)="onDeleteExpressionItem($event)"
                   (droppedDataEvent)="onDroppedData($event)"
                   (dragEndEvent)="onDragEnd()">
               </app-order-reports-filter-drop-area>
           </ng-container>
       </ul>
    </div>


    <div class="emptyState row-padding"
         *ngIf="expressionItems.length === 0 && !dropAreasVisible">
        <h3>Filters</h3>
        <p>Drag and drop items from list which you want to filter.</p>
    </div>


    <div class="detectRightEdge"
        appMousePointerNear [disabled]="!dropAreasVisible" [distance]="50" (pointerNearEvent)="onMousePointerNearRightEdge($event)">
    </div>
</div>
