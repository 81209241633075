import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

export interface SubmitCloneProductData {
    productId: number;
    description: string;
    descriptionRegularTrip: string;
    descriptionReturnTrip: string;
}

@Component({
    selector: 'app-clone-product-dialog-form',
    templateUrl: './clone-product-dialog-form.component.html'
})

export class CloneProductDialogFormComponent {

    @Input() productId: number;
    @Input() isRoundTripProduct: boolean;

    @Output() submit: EventEmitter<SubmitCloneProductData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        this.form = new FormGroup({
            description: new FormControl(),
            descriptionRegularTrip: new FormControl(),
            descriptionReturnTrip: new FormControl()
        });
    }

    onSubmit() {

        let description: string = this.form.value.description;
        let descriptionRegularTrip: string = this.form.value.descriptionRegularTrip;
        let descriptionReturnTrip: string = this.form.value.descriptionReturnTrip;

        if (!description) {
            this.hasError = true;
            this.errorMessage = "Please enter product name";
            return;
        }

        if (this.isRoundTripProduct) {
            if (!descriptionRegularTrip) {
                this.hasError = true;
                this.errorMessage = "Please enter outgoing trip name";
                return;
            }
            if (!descriptionReturnTrip) {
                this.hasError = true;
                this.errorMessage = "Please enter return trip name";
                return;
            }
        }

        this.submit.emit({
            productId: this.productId,
            description: description,
            descriptionRegularTrip: descriptionRegularTrip,
            descriptionReturnTrip: descriptionReturnTrip
        });
    }

    onCancel() {
        this.cancel.emit();
    }
}
