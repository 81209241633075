
import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {TierResponseModel} from "../../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {Store} from "@ngrx/store";
import {TierRestService} from "../../../../../../../../core/modules/rest/tier/tier-rest.service";
import {TierFormSubmitDataModel} from "../model/tier-form-submit-data.model";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {CreateTierRequestModel} from "../../../../../../../../core/modules/rest/tier/request/create-tier-request.model";
import {UpdateTierRequestModel} from "../../../../../../../../core/modules/rest/tier/request/update-tier-request.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";

@Injectable()
export class EditTierDialogSandbox extends DialogSandbox {

    tier$: Observable<TierResponseModel> = this.store.select(reducer.tier_selector);

    constructor(appDialogsService: AppDialogsService,
                private tierRestService: TierRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadTier(tierId: number) {

        this.showLoader();

        this.tierRestService.getById(tierId).subscribe((tier: TierResponseModel) => {
            this.store.dispatch(new actions.SetTier(tier));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    createTier(data: TierFormSubmitDataModel): Observable<CreateBaseResponseModel> {

        const request = new CreateTierRequestModel(data.description, data.minimumAge, data.maximumAge, data.useAlternateCheckInSound, data.manualAcceptanceRequired);

        return this.tierRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating tier', error);
            }));
    }

    updateTier(tierId: number, data: TierFormSubmitDataModel): Observable<any> {

        const request = new UpdateTierRequestModel(tierId, data.minimumAge, data.maximumAge, data.description, data.useAlternateCheckInSound, data.manualAcceptanceRequired);

        return this.tierRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating tier', error);
            }));
    }
}
