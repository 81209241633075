import * as actions from "../../../categories-and-products/sandbox/store/actions";
import {TreeNode} from "../../../../../../../shared/components/tree/tree.component";
import {TreeItemTypeEnum} from "../../model/tree-item-type.enum";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {CategoryBasicInfoDataModel} from "../../model/category-basic-info-data.model";
import {CategoryEmailHtmlTemplatesDataModel} from "../../model/category-email-html-templates-data.model";
import {CategoryTemplateItemsDataModel} from "../../model/category-template-items-data.model";
import {ProductBasicInfoDataModel} from "../../model/product-basic-info-data.model";
import {ProductAvailabilitiesDataModel} from "../../model/product-availabilities-data.model";
import {ProductPricingDateRangesDataModel} from "../../model/product-pricing-date-ranges-data.model";
import {ProductFlagsDataModel} from "../../model/product-flags-data.model";
import {ProductValidityDataModel} from "../../model/product-validity-data.model";
import {ProductEmailHtmlTemplatesDataModel} from "../../model/product-email-html-templates-data.model";
import {ProductRoutesDataModel} from "../../model/product-routes-data.model";
import {CategoryTabItemEnum} from "../../model/category-tab-item.enum";
import {ProductTabItemEnum} from "../../model/product-tab-item.enum";
import {ProductImagesDataModel} from "../../model/product-images-data.model";
import {ProductAvailabilitiesSidebarFilterDataModel} from "../../model/product-availabilities-sidebar-filter-data.model";
import {ProductAvailabilitiesSidebarFilterTypeEnum} from "../../model/product-availabilities-sidebar-filter-type.enum";
import {DepartureGroupResponseModel} from "../../../../../../../core/modules/rest/departure-group/response/departure-group-response.model";
import {ProductPricingDateRangesSidebarFilterDataModel} from "../../model/product-pricing-date-ranges-sidebar-filter-data.model";
import {ProductPricingDateRangesSidebarFilterTypeEnum} from "../../model/product-pricing-date-ranges-sidebar-filter-type.enum";
import {TierResponseModel} from "../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {LocationResponseModel} from "../../../../../../../core/modules/rest/location/response/location-response.model";
import {CategoryCostsDataModel} from "../../model/category-costs-data.model";
import {ProductCostsDataModel} from "../../model/product-costs-data.model";
import {SortByModel} from "../../../../../../../shared/model/sort-by.model";

export const REDUCER_NAME: string = "feature_manage_categories_and_products";

export interface State {

    loadingCategoriesAndProducts: boolean;
    categoriesAndProductsTree: TreeNode[];
    showInactiveItems: boolean;

    item: TreeNode;
    node: TreeNodeMaterial;
    itemType: TreeItemTypeEnum;
    itemId: number;
    itemActive: boolean;

    selectedCategoryTabItem: CategoryTabItemEnum;
    loadingCategoryFull: boolean;
    loadingCategoryTemplateItems: boolean;
    itemCategoryBasicInfo: CategoryBasicInfoDataModel;
    itemCategoryEmailHtmlTemplates: CategoryEmailHtmlTemplatesDataModel;
    itemCategoryCosts: CategoryCostsDataModel;
    itemCategoryTemplateItems: CategoryTemplateItemsDataModel;

    selectedProductTabItem: ProductTabItemEnum;
    loadingProductFull: boolean;
    loadingProductImages: boolean;
    loadingProductAvailabilities: boolean;
    loadingProductPricingDateRanges: boolean;
    itemProductBasicInfo: ProductBasicInfoDataModel;
    itemProductImages: ProductImagesDataModel;
    itemProductFlags: ProductFlagsDataModel;
    itemProductValidity: ProductValidityDataModel;
    itemProductEmailHtmlTemplates: ProductEmailHtmlTemplatesDataModel;
    itemProductRoutes: ProductRoutesDataModel;
    itemProductCosts: ProductCostsDataModel;
    itemProductAvailabilities: ProductAvailabilitiesDataModel;
    productAvailabilitiesFrom: number;
    productAvailabilitiesSidebarFilterOpen: boolean;
    productAvailabilitiesSidebarFilterDepartureGroups: DepartureGroupResponseModel[];
    productAvailabilitiesSidebarFilterData: ProductAvailabilitiesSidebarFilterDataModel;
    productAvailabilitiesSidebarFilterCurrentFocus: ProductAvailabilitiesSidebarFilterTypeEnum;
    productAvailabilitiesCurrentSearchDataSortBy: SortByModel;
    itemProductPricingDateRanges: ProductPricingDateRangesDataModel;
    productPricingDateRangesFrom: number;
    productPricingDateRangesSidebarFilterOpen: boolean;
    productPricingDateRangesSidebarFilterPickupLocations: LocationResponseModel[];
    productPricingDateRangesSidebarFilterDropoffLocations: LocationResponseModel[];
    productPricingDateRangesSidebarFilterTiers: TierResponseModel[];
    productPricingDateRangesSidebarFilterData: ProductPricingDateRangesSidebarFilterDataModel;
    productPricingDateRangesSidebarFilterCurrentFocus: ProductPricingDateRangesSidebarFilterTypeEnum;
    productPricingDateRangesCurrentSearchDataSortBy: SortByModel;
}

export const INITIAL_STATE: State = {

    loadingCategoriesAndProducts: false,
    categoriesAndProductsTree: [],
    showInactiveItems: false,

    item: {} as TreeNode,
    node: {} as TreeNodeMaterial,
    itemType: null,
    itemId: null,
    itemActive: false,

    selectedCategoryTabItem: CategoryTabItemEnum.PROPERTIES_AND_OPTIONS,
    loadingCategoryFull: false,
    loadingCategoryTemplateItems: false,
    itemCategoryBasicInfo: {
        categoryId: null,
        active: true,
        description: null
    },
    itemCategoryEmailHtmlTemplates: {
        categoryId: null,
        createOrderEmailHtmlTemplateId: null,
        createOrderEmailHtmlTemplateDescription: null,
        voidOrderEmailHtmlTemplateId: null,
        voidOrderEmailHtmlTemplateDescription: null,
        termsAndConditionsEmailHtmlTemplateId: null,
        termsAndConditionsEmailHtmlTemplateDescription: null,
        createOrderEmailHtmlTemplateActive: null,
        voidOrderEmailHtmlTemplateActive: null,
        termsAndConditionsEmailHtmlTemplateActive: null
    },
    itemCategoryCosts: {
        categoryId: null,
        costs: []
    },
    itemCategoryTemplateItems: {
        categoryId: null,
        templateId: null,
        templateItems: []
    },

    selectedProductTabItem: ProductTabItemEnum.PROPERTIES,
    loadingProductFull: false,
    loadingProductImages: false,
    loadingProductAvailabilities: false,
    loadingProductPricingDateRanges: false,
    itemProductBasicInfo: {
        productId: null,
        active: true,
        categoryId: null,
        categoryDescription: null,
        description: null,
        descriptionRegularTrip: null,
        descriptionReturnTrip: null,
        details: null,
        detailsHtml: null,
        note: null,
        emails: null
    },
    itemProductImages: {
        productId: null,
        productImage: {
            imageAmazonFilePath: null,
            imageBase64: null,
        },
        ticketBackgroundImage: {
            imageAmazonFilePath: null,
            imageBase64: null
        }
    },
    itemProductFlags: {
        productId: null,
        requireCheckIn: true,
        availableForSale: false,
        isFlexibleProduct: false,
        isRoundTripProduct: false,
        manualAcceptanceForFailedScanRequired: false,
        shouldSendBarcodeQRCodeImageAttachment: false,
        shouldPrintProductImageOnPdfTicket: false
    },
    itemProductValidity: {
        productId: null,
        maximalDaysValidFor: null,
        maximalDaysValidForRoundTrip: null
    },
    itemProductEmailHtmlTemplates: {
        productId: null,
        createOrderEmailHtmlTemplateId: null,
        createOrderEmailHtmlTemplateDescription: null,
        voidOrderEmailHtmlTemplateId: null,
        voidOrderEmailHtmlTemplateDescription: null,
        termsAndConditionsEmailHtmlTemplateId: null,
        termsAndConditionsEmailHtmlTemplateDescription: null,
        createOrderEmailHtmlTemplateActive: null,
        voidOrderEmailHtmlTemplateActive: null,
        termsAndConditionsEmailHtmlTemplateActive: null
    },
    itemProductRoutes: {
        productId: null,
        tracksQuantityBetweenStops: false,
        usesPickupLocations: true,
        pickupLocationListId: null,
        pickupLocationListDescription: null,
        pickupLocationListActive: null,
        usesDropoffLocations: true,
        dropoffLocationListId: null,
        dropoffLocationListDescription: null,
        dropoffLocationListActive: null
    },
    itemProductCosts: {
        productId: null,
        costs: []
    },
    itemProductAvailabilities: {
        productId: null,
        totalNumberOfResults: 0,
        productAvailabilities: []
    },
    productAvailabilitiesFrom: 0,
    productAvailabilitiesSidebarFilterOpen: false,
    productAvailabilitiesSidebarFilterDepartureGroups: [],
    productAvailabilitiesSidebarFilterData: null,
    productAvailabilitiesSidebarFilterCurrentFocus: null,
    productAvailabilitiesCurrentSearchDataSortBy: null,
    itemProductPricingDateRanges: {
        productId: null,
        totalNumberOfResults: 0,
        pricingDateRanges: []
    },
    productPricingDateRangesFrom: 0,
    productPricingDateRangesSidebarFilterOpen: false,
    productPricingDateRangesSidebarFilterPickupLocations: [],
    productPricingDateRangesSidebarFilterDropoffLocations: [],
    productPricingDateRangesSidebarFilterTiers: [],
    productPricingDateRangesSidebarFilterData: null,
    productPricingDateRangesSidebarFilterCurrentFocus: null,
    productPricingDateRangesCurrentSearchDataSortBy: null
};

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const loading_categories_and_products_selector = createSelector(state_selector, (state: State) => state.loadingCategoriesAndProducts);

export const categories_and_products_tree_selector = createSelector(state_selector, (state: State) => state.categoriesAndProductsTree);

export const show_inactive_items_selector = createSelector(state_selector, (state: State) => state.showInactiveItems);

export const item_selector = createSelector(state_selector, (state: State) => state.item);

export const node_selector = createSelector(state_selector, (state: State) => state.node);

export const item_type_selector = createSelector(state_selector, (state: State) => state.itemType);

export const item_id_selector = createSelector(state_selector, (state: State) => state.itemId);

export const item_active_selector = createSelector(state_selector, (state: State) => state.itemActive);

export const loading_category_full_selector = createSelector(state_selector, (state: State) => state.loadingCategoryFull);

export const loading_category_template_items_selector = createSelector(state_selector, (state: State) => state.loadingCategoryTemplateItems);

export const selected_category_tab_item_selector = createSelector(state_selector, (state: State) => state.selectedCategoryTabItem);

export const item_category_basic_info_selector = createSelector(state_selector, (state: State) => state.itemCategoryBasicInfo);

export const item_category_email_html_templates_selector = createSelector(state_selector, (state: State) => state.itemCategoryEmailHtmlTemplates);

export const item_category_costs = createSelector(state_selector, (state: State) => state.itemCategoryCosts);

export const item_category_template_items_selector = createSelector(state_selector, (state: State) => state.itemCategoryTemplateItems);

export const loading_product_full_selector = createSelector(state_selector, (state: State) => state.loadingProductFull);

export const loading_product_images_selector = createSelector(state_selector, (state: State) => state.loadingProductImages);

export const loading_product_availabilities_selector = createSelector(state_selector, (state: State) => state.loadingProductAvailabilities);

export const loading_product_pricing_date_ranges_selector = createSelector(state_selector, (state: State) => state.loadingProductPricingDateRanges);

export const selected_product_tab_item_selector = createSelector(state_selector, (state: State) => state.selectedProductTabItem);

export const item_product_basic_info_selector = createSelector(state_selector, (state: State) => state.itemProductBasicInfo);

export const item_product_images_selector = createSelector(state_selector, (state: State) => state.itemProductImages);

export const item_product_flags_selector = createSelector(state_selector, (state: State) => state.itemProductFlags);

export const item_product_validity_selector = createSelector(state_selector, (state: State) => state.itemProductValidity);

export const item_product_email_html_templates_selector = createSelector(state_selector, (state: State) => state.itemProductEmailHtmlTemplates);

export const item_product_routes_selector = createSelector(state_selector, (state: State) => state.itemProductRoutes);

export const item_product_costs_selector = createSelector(state_selector, (state: State) => state.itemProductCosts);

export const item_product_availabilities_selector = createSelector(state_selector, (state: State) => state.itemProductAvailabilities);

export const product_availabilities_from_selector = createSelector(state_selector, (state: State) => state.productAvailabilitiesFrom);

export const product_availabilities_sidebar_filter_open_selector = createSelector(state_selector, (state: State) => state.productAvailabilitiesSidebarFilterOpen);

export const product_availabilities_sidebar_filter_departure_groups_selector = createSelector(state_selector, (state: State) => state.productAvailabilitiesSidebarFilterDepartureGroups);

export const product_availabilities_sidebar_filter_data_selector = createSelector(state_selector, (state: State) => state.productAvailabilitiesSidebarFilterData);

export const product_availabilities_sidebar_filter_current_focus_selector = createSelector(state_selector, (state: State) => state.productAvailabilitiesSidebarFilterCurrentFocus);

export const product_availabilities_current_search_data_sort_by_selector = createSelector(state_selector, (state: State) => state.productAvailabilitiesCurrentSearchDataSortBy);

export const item_product_pricing_date_ranges_selector = createSelector(state_selector, (state: State) => state.itemProductPricingDateRanges);

export const product_pricing_date_ranges_from_selector = createSelector(state_selector, (state: State) => state.productPricingDateRangesFrom);

export const product_pricing_date_ranges_sidebar_filter_open_selector = createSelector(state_selector, (state: State) => state.productPricingDateRangesSidebarFilterOpen);

export const product_pricing_date_ranges_sidebar_filter_pickup_locations_selector = createSelector(state_selector, (state: State) => state.productPricingDateRangesSidebarFilterPickupLocations);

export const product_pricing_date_ranges_sidebar_filter_dropoff_locations_selector = createSelector(state_selector, (state: State) => state.productPricingDateRangesSidebarFilterDropoffLocations);

export const product_pricing_date_ranges_sidebar_filter_tiers_selector = createSelector(state_selector, (state: State) => state.productPricingDateRangesSidebarFilterTiers);

export const product_pricing_date_ranges_sidebar_filter_data_selector = createSelector(state_selector, (state: State) => state.productPricingDateRangesSidebarFilterData);

export const product_pricing_date_ranges_sidebar_filter_current_focus_selector = createSelector(state_selector, (state: State) => state.productPricingDateRangesSidebarFilterCurrentFocus);

export const product_pricing_date_ranges_current_search_data_sort_by_selector = createSelector(state_selector, (state: State) => state.productPricingDateRangesCurrentSearchDataSortBy);

export function reducer(state: State = INITIAL_STATE, action: actions.All): State {

    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;
        case actions.SET_LOADING_CATEGORIES_AND_PRODUCTS:
            return {
                ...state,
                loadingCategoriesAndProducts: action.loading
            };
        case actions.SET_CATEGORIES_AND_PRODUCTS_TREE:
            return {
                ...state,
                categoriesAndProductsTree: action.tree
            };
        case actions.SET_SHOW_INACTIVE_ITEMS:
            return {
                ...state,
                showInactiveItems: action.showInactiveItems
            };
        case actions.SET_SELECTED_ITEM:
            return {
                ...state,
                item: action.item,
                itemType: action.itemType,
                itemId: action.itemId,
                itemActive: action.itemActive
            };
        case actions.SET_SELECTED_NODE:
            return {
                 ...state,
                 node: action.node
            };
        case actions.SET_LOADING_CATEGORY_FULL:
            return {
                ...state,
                loadingCategoryFull: action.loading
            };
        case actions.SET_LOADING_CATEGORY_TEMPLATE_ITEMS:
            return {
                ...state,
                loadingCategoryTemplateItems: action.loading
            };
        case actions.SET_SELECTED_CATEGORY_TAB_ITEM:
            return {
                ...state,
                selectedCategoryTabItem: action.categoryTabItem
            };
        case actions.SET_CATEGORY_BASIC_INFO:
            return {
                ...state,
                itemCategoryBasicInfo: {
                    categoryId: action.categoryBasicInfo.categoryId,
                    active: action.categoryBasicInfo.active,
                    description: action.categoryBasicInfo.description
                }
            };
        case actions.SET_CATEGORY_EMAIL_HTML_TEMPLATES:
            return {
                ...state,
                itemCategoryEmailHtmlTemplates: {
                    categoryId: action.categoryEmailHtmlTemplates.categoryId,
                    createOrderEmailHtmlTemplateId: action.categoryEmailHtmlTemplates.createOrderEmailHtmlTemplateId,
                    createOrderEmailHtmlTemplateDescription: action.categoryEmailHtmlTemplates.createOrderEmailHtmlTemplateDescription,
                    voidOrderEmailHtmlTemplateId: action.categoryEmailHtmlTemplates.voidOrderEmailHtmlTemplateId,
                    voidOrderEmailHtmlTemplateDescription: action.categoryEmailHtmlTemplates.voidOrderEmailHtmlTemplateDescription,
                    termsAndConditionsEmailHtmlTemplateId: action.categoryEmailHtmlTemplates.termsAndConditionsEmailHtmlTemplateId,
                    termsAndConditionsEmailHtmlTemplateDescription: action.categoryEmailHtmlTemplates.termsAndConditionsEmailHtmlTemplateDescription,
                    createOrderEmailHtmlTemplateActive: action.categoryEmailHtmlTemplates.createOrderEmailHtmlTemplateActive,
                    voidOrderEmailHtmlTemplateActive: action.categoryEmailHtmlTemplates.voidOrderEmailHtmlTemplateActive,
                    termsAndConditionsEmailHtmlTemplateActive: action.categoryEmailHtmlTemplates.termsAndConditionsEmailHtmlTemplateActive

                }
            };
        case actions.SET_CATEGORY_COSTS:
            return {
                ...state,
                itemCategoryCosts: {
                    categoryId: action.categoryCosts.categoryId,
                    costs: [...action.categoryCosts.costs]
                }
            };
        case actions.SET_CATEGORY_TEMPLATE_ITEMS:
            return {
                ...state,
                itemCategoryTemplateItems: {
                    categoryId: action.categoryTemplateItems.categoryId,
                    templateId: action.categoryTemplateItems.templateId,
                    templateItems: [...action.categoryTemplateItems.templateItems]
                }
            };
        case actions.SET_LOADING_PRODUCT_FULL:
            return {
                ...state,
                loadingProductFull: action.loading
            };
        case actions.SET_LOADING_PRODUCT_IMAGES:
            return {
                ...state,
                loadingProductImages: action.loading
            };
        case actions.SET_LOADING_PRODUCT_AVAILABILITIES:
            return {
                ...state,
                loadingProductAvailabilities: action.loading
            };
        case actions.SET_LOADING_PRODUCT_PRICING_DATE_RANGES:
            return {
                ...state,
                loadingProductPricingDateRanges: action.loading
            };
        case actions.SET_SELECTED_PRODUCT_TAB_ITEM:
            return {
                ...state,
                selectedProductTabItem: action.productTabItem
            };
        case actions.SET_PRODUCT_BASIC_INFO:
            return {
                ...state,
                itemProductBasicInfo: {
                    productId: action.productBasicInfo.productId,
                    active: action.productBasicInfo.active,
                    categoryId: action.productBasicInfo.categoryId,
                    categoryDescription: action.productBasicInfo.categoryDescription,
                    description: action.productBasicInfo.description,
                    descriptionRegularTrip: action.productBasicInfo.descriptionRegularTrip,
                    descriptionReturnTrip: action.productBasicInfo.descriptionReturnTrip,
                    details: action.productBasicInfo.details,
                    detailsHtml: action.productBasicInfo.detailsHtml,
                    note: action.productBasicInfo.note,
                    emails: action.productBasicInfo.emails
                }
            };
        case actions.SET_PRODUCT_IMAGES:
            return {
                ...state,
                itemProductImages: {
                    productId: action.productImages.productId,
                    productImage: {
                        imageAmazonFilePath: action.productImages.productImage.imageAmazonFilePath,
                        imageBase64: action.productImages.productImage.imageBase64,
                    },
                    ticketBackgroundImage: {
                        imageAmazonFilePath: action.productImages.ticketBackgroundImage.imageAmazonFilePath,
                        imageBase64: action.productImages.ticketBackgroundImage.imageBase64
                    }
                }
            };
        case actions.SET_PRODUCT_IMAGE:
            return {
                ...state,
                itemProductImages: {
                    ...state.itemProductImages,
                    productImage: {
                        imageAmazonFilePath: action.productImage.imageAmazonFilePath,
                        imageBase64: action.productImage.imageBase64,
                    }
                }
            };
        case actions.SET_TICKET_BACKGROUND_IMAGE:
            return {
                ...state,
                itemProductImages: {
                    ...state.itemProductImages,
                    ticketBackgroundImage: {
                        imageAmazonFilePath: action.ticketBackgroundImage.imageAmazonFilePath,
                        imageBase64: action.ticketBackgroundImage.imageBase64,
                    }
                }
            };
        case actions.SET_PRODUCT_FLAGS:
            return {
                ...state,
                itemProductFlags: {
                    productId: action.productFlags.productId,
                    requireCheckIn: action.productFlags.requireCheckIn,
                    availableForSale: action.productFlags.availableForSale,
                    isFlexibleProduct: action.productFlags.isFlexibleProduct,
                    isRoundTripProduct: action.productFlags.isRoundTripProduct,
                    manualAcceptanceForFailedScanRequired: action.productFlags.manualAcceptanceForFailedScanRequired,
                    shouldSendBarcodeQRCodeImageAttachment: action.productFlags.shouldSendBarcodeQRCodeImageAttachment,
                    shouldPrintProductImageOnPdfTicket: action.productFlags.shouldPrintProductImageOnPdfTicket
                }
            };
        case actions.SET_PRODUCT_VALIDITY:
            return {
                ...state,
                itemProductValidity: {
                    productId: action.productValidity.productId,
                    maximalDaysValidFor: action.productValidity.maximalDaysValidFor,
                    maximalDaysValidForRoundTrip: action.productValidity.maximalDaysValidForRoundTrip
                }
            };
        case actions.SET_PRODUCT_EMAIL_HTML_TEMPLATES:
            return {
                ...state,
                itemProductEmailHtmlTemplates: {
                    productId: action.productEmailHtmlTemplates.productId,
                    createOrderEmailHtmlTemplateId: action.productEmailHtmlTemplates.createOrderEmailHtmlTemplateId,
                    createOrderEmailHtmlTemplateDescription: action.productEmailHtmlTemplates.createOrderEmailHtmlTemplateDescription,
                    voidOrderEmailHtmlTemplateId: action.productEmailHtmlTemplates.voidOrderEmailHtmlTemplateId,
                    voidOrderEmailHtmlTemplateDescription: action.productEmailHtmlTemplates.voidOrderEmailHtmlTemplateDescription,
                    termsAndConditionsEmailHtmlTemplateId: action.productEmailHtmlTemplates.termsAndConditionsEmailHtmlTemplateId,
                    termsAndConditionsEmailHtmlTemplateDescription: action.productEmailHtmlTemplates.termsAndConditionsEmailHtmlTemplateDescription,
                    createOrderEmailHtmlTemplateActive: action.productEmailHtmlTemplates.createOrderEmailHtmlTemplateActive,
                    voidOrderEmailHtmlTemplateActive: action.productEmailHtmlTemplates.voidOrderEmailHtmlTemplateActive,
                    termsAndConditionsEmailHtmlTemplateActive: action.productEmailHtmlTemplates.termsAndConditionsEmailHtmlTemplateActive
                }
            };
        case actions.SET_PRODUCT_ROUTES:
            return {
                ...state,
                itemProductRoutes: {
                    productId: action.productRoutes.productId,
                    tracksQuantityBetweenStops: action.productRoutes.tracksQuantityBetweenStops,
                    usesPickupLocations: action.productRoutes.usesPickupLocations,
                    pickupLocationListId: action.productRoutes.pickupLocationListId,
                    pickupLocationListDescription: action.productRoutes.pickupLocationListDescription,
                    pickupLocationListActive: action.productRoutes.pickupLocationListActive,
                    usesDropoffLocations: action.productRoutes.usesDropoffLocations,
                    dropoffLocationListId: action.productRoutes.dropoffLocationListId,
                    dropoffLocationListDescription: action.productRoutes.dropoffLocationListDescription,
                    dropoffLocationListActive: action.productRoutes.dropoffLocationListActive
                }
            };
        case actions.SET_PRODUCT_COSTS:
            return {
                ...state,
                itemProductCosts: {
                    productId: action.productCosts.productId,
                    costs: [...action.productCosts.costs]
                }
            };
        case actions.RESET_PRODUCT_AVAILABILITIES:
            return {
                ...state,
                itemProductAvailabilities: {
                    productId: null,
                    totalNumberOfResults: 0,
                    productAvailabilities: []
                }
            };
        case actions.SET_PRODUCT_AVAILABILITIES:
            return {
                ...state,
                itemProductAvailabilities: {
                    productId: action.productAvailabilities.productId,
                    totalNumberOfResults: action.productAvailabilities.totalNumberOfResults,
                    productAvailabilities: action.append ? [...state.itemProductAvailabilities.productAvailabilities, ...action.productAvailabilities.productAvailabilities] : [...action.productAvailabilities.productAvailabilities]
                }
            };
        case actions.SET_PRODUCT_AVAILABILITIES_FROM:
            return {
                ...state,
                productAvailabilitiesFrom: action.productAvailabilitiesFrom
            };
        case actions.SET_PRODUCT_AVAILABILITIES_SIDEBAR_FILTER_OPEN:
            return {
                ...state,
                productAvailabilitiesSidebarFilterOpen: action.productAvailabilitiesSidebarFilterOpen,
                productAvailabilitiesSidebarFilterCurrentFocus: action.productAvailabilitiesSidebarFilterCurrentFocus
            };
        case actions.SET_PRODUCT_AVAILABILITIES_SIDEBAR_FILTER_DEPARTURE_GROUPS:
            return {
                ...state,
                productAvailabilitiesSidebarFilterDepartureGroups: action.productAvailabilitiesSidebarFilterDepartureGroups
            };
        case actions.SET_PRODUCT_AVAILABILITIES_SIDEBAR_FILTER_DATA:
            return {
                ...state,
                productAvailabilitiesSidebarFilterData: {
                    showInactive: action.productAvailabilitiesSidebarFilterData.showInactive,
                    showInactiveTiers: action.productAvailabilitiesSidebarFilterData.showInactiveTiers,
                    showInactivePickupLocations: action.productAvailabilitiesSidebarFilterData.showInactivePickupLocations,
                    showInactiveDropoffLocations: action.productAvailabilitiesSidebarFilterData.showInactiveDropoffLocations,
                    showInactiveDepartureGroups: action.productAvailabilitiesSidebarFilterData.showInactiveDepartureGroups,
                    startDate: action.productAvailabilitiesSidebarFilterData.startDate,
                    startDateFriendly: action.productAvailabilitiesSidebarFilterData.startDateFriendly,
                    endDate: action.productAvailabilitiesSidebarFilterData.endDate,
                    endDateFriendly: action.productAvailabilitiesSidebarFilterData.endDateFriendly,
                    startTimeFrom: action.productAvailabilitiesSidebarFilterData.startTimeFrom,
                    startTimeFromFriendly: action.productAvailabilitiesSidebarFilterData.startTimeFromFriendly,
                    startTimeTo: action.productAvailabilitiesSidebarFilterData.startTimeTo,
                    startTimeToFriendly: action.productAvailabilitiesSidebarFilterData.startTimeToFriendly,
                    daysOfWeek: action.productAvailabilitiesSidebarFilterData.daysOfWeek,
                    departureGroupIds: action.productAvailabilitiesSidebarFilterData.departureGroupIds,
                    departureGroupDescriptions: action.productAvailabilitiesSidebarFilterData.departureGroupDescriptions,
                    productAvailabilityDescription: action.productAvailabilitiesSidebarFilterData.productAvailabilityDescription
                }
            };
        case actions.UPDATE_PRODUCT_AVAILABILITIES_CURRENT_SEARCH_DATA_SORT_BY:
            return {
                ...state,
                productAvailabilitiesCurrentSearchDataSortBy: action.productAvailabilitiesSortBy
            };
        case actions.RESET_PRODUCT_PRICING_DATE_RANGES:
            return {
                ...state,
                itemProductPricingDateRanges: {
                    productId: null,
                    totalNumberOfResults: 0,
                    pricingDateRanges: []
                }
            };
        case actions.SET_PRODUCT_PRICING_DATE_RANGES:
            return {
                ...state,
                itemProductPricingDateRanges: {
                    productId: action.productPricingDateRanges.productId,
                    totalNumberOfResults: action.productPricingDateRanges.totalNumberOfResults,
                    pricingDateRanges: action.append ? [...state.itemProductPricingDateRanges.pricingDateRanges, ...action.productPricingDateRanges.pricingDateRanges] : [...action.productPricingDateRanges.pricingDateRanges]
                }
            };
        case actions.SET_PRODUCT_PRICING_DATE_RANGES_FROM:
            return {
                ...state,
                productPricingDateRangesFrom: action.productPricingDateRangesFrom
            };
        case actions.SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_OPEN:
            return {
                ...state,
                productPricingDateRangesSidebarFilterOpen: action.productPricingDateRangesSidebarFilterOpen,
                productPricingDateRangesSidebarFilterCurrentFocus: action.productPricingDateRangesSidebarFilterCurrentFocus
            };
        case actions.SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_PICKUP_LOCATIONS:
            return {
                ...state,
                productPricingDateRangesSidebarFilterPickupLocations: action.productPricingDateRangesSidebarFilterPickupLocations
            };
        case actions.SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_DROPOFF_LOCATIONS:
            return {
                ...state,
                productPricingDateRangesSidebarFilterDropoffLocations: action.productPricingDateRangesSidebarFilterDropoffLocations
            };
        case actions.SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_TIERS:
            return {
                ...state,
                productPricingDateRangesSidebarFilterTiers: action.productPricingDateRangesSidebarFilterTiers
            };
        case actions.SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_DATA:
            return {
                ...state,
                productPricingDateRangesSidebarFilterData: {
                    showInactive: action.productPricingDateRangesSidebarFilterData.showInactive,
                    showInactiveTiers: action.productPricingDateRangesSidebarFilterData.showInactiveTiers,
                    showInactivePickupLocations: action.productPricingDateRangesSidebarFilterData.showInactivePickupLocations,
                    showInactiveDropoffLocations: action.productPricingDateRangesSidebarFilterData.showInactiveDropoffLocations,
                    showInactiveDepartureGroups: action.productPricingDateRangesSidebarFilterData.showInactiveDepartureGroups,
                    allDates: action.productPricingDateRangesSidebarFilterData.allDates,
                    startDate: action.productPricingDateRangesSidebarFilterData.startDate,
                    startDateFriendly: action.productPricingDateRangesSidebarFilterData.startDateFriendly,
                    endDate: action.productPricingDateRangesSidebarFilterData.endDate,
                    endDateFriendly: action.productPricingDateRangesSidebarFilterData.endDateFriendly,
                    pickupLocationIds: action.productPricingDateRangesSidebarFilterData.pickupLocationIds,
                    pickupLocationDescriptions: action.productPricingDateRangesSidebarFilterData.pickupLocationDescriptions,
                    dropoffLocationIds: action.productPricingDateRangesSidebarFilterData.dropoffLocationIds,
                    dropoffLocationDescriptions: action.productPricingDateRangesSidebarFilterData.dropoffLocationDescriptions,
                    tierIds: action.productPricingDateRangesSidebarFilterData.tierIds,
                    tierDescriptions: action.productPricingDateRangesSidebarFilterData.tierDescriptions
                }
            };
        case actions.UPDATE_PRODUCT_PRICING_DATE_RANGES_CURRENT_SEARCH_DATA_SORT_BY:
            return {
                ...state,
                productPricingDateRangesCurrentSearchDataSortBy: action.productPricingDateRangesSortBy
            };
        default:
            return state;
    }
}
