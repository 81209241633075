
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {CategoryRestService} from "../../../../../../../../core/modules/rest/category/category-rest.service";
import {ProductRestService} from "../../../../../../../../core/modules/rest/product/product-rest.service";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {CategoryResponseModel} from "../../../../../../../../core/modules/rest/category/response/category-response.model";
import {ProductSimpleResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {DropdownOptionModel} from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {SubmitProductBasicInfoData} from "../components/edit-product-basic-info-dialog-form/edit-product-basic-info-dialog-form.component";

@Injectable()
export class EditProductBasicInfoDialogSandbox extends DialogSandbox {

    product$: Observable<ProductSimpleResponseModel> = this.store.pipe(select(reducer.product_selector));
    categories$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.categories_selector));

    constructor(appDialogsService: AppDialogsService,
                private productRestService: ProductRestService,
                private categoryRestService: CategoryRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(productId: number) {

        observableCombineLatest(this.productRestService.getProductSimpleById(productId), this.categoryRestService.getAllActive()).pipe(
            take(1))
            .subscribe(([product, categories]: [ProductSimpleResponseModel, CategoryResponseModel[]]) => {
                this.store.dispatch(new actions.SetProduct(product));
                this.store.dispatch(new actions.SetCategories(categories.map(c => new DropdownOptionModel(c.description, c.categoryId.toString()))));
            });
    }

    updateProductBasicInfo(data: SubmitProductBasicInfoData): Observable<any> {

        return this.productRestService.updateProductBasicInfo({
            productId: data.productId,
            categoryId: data.categoryId,
            description: data.description,
            descriptionRegularTrip: data.descriptionRegularTrip,
            descriptionReturnTrip: data.descriptionReturnTrip,
            details: data.details,
            detailsHtml: data.detailsHtml,
            note: data.note,
            emails: data.emails
        });
    }
}
