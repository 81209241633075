import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {CreateBidRequestModel} from "./request/create-bid-request.model";
import {BidResponseModel} from "./response/bid-response.model";
import {UpdateBidRequestModel} from "./request/update-bid-request.model";

@Injectable()
export class BidRestService {

    constructor(private httpClient: HttpClient) {
    }

    getById(bidId: number): Observable<BidResponseModel> {
        return this.httpClient.get<BidResponseModel>(environment.server_api_url + '/api/secured/bid/getById/' + bidId);
    }

    getAll(): Observable<BidResponseModel[]> {
        return this.httpClient.get<BidResponseModel[]>(environment.server_api_url + '/api/secured/bid/getAll');
    }

    getAllActive(): Observable<BidResponseModel[]> {
        return this.httpClient.get<BidResponseModel[]>(environment.server_api_url + '/api/secured/bid/getAllActive');
    }

    create(request: CreateBidRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/bid/create', request);
    }

    update(request: UpdateBidRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/bid/update', request);
    }

    activate(bidId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/bid/activate', bidId, {responseType: 'text'});
    }

    deactivate(bidId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/bid/deactivate', bidId, {responseType: 'text'});
    }
}
