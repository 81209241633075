import {RemoteCommandEnum} from "../../../../../../../../shared/enums/remote-command.enum";

export class AddAndroidDeviceInfoRemoteCommandDialogFormSubmitDataModel {

    androidDeviceInfoId: number;
    remoteCommand: RemoteCommandEnum;
    timeoutHours: number;

    constructor(androidDeviceInfoId: number, remoteCommand: RemoteCommandEnum, timeoutHours: number) {
        this.androidDeviceInfoId = androidDeviceInfoId;
        this.remoteCommand = remoteCommand;
        this.timeoutHours = timeoutHours;
    }
}
