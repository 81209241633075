import {Action} from '@ngrx/store';
import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";
import {AutoAppliedDiscountCostModel} from "../../model/auto-applied-discount-cost.model";


export const UPDATE_DIALOG_DATA_LOADED_ACTION = '[Dialog Edit Auto Applied Discount] Update dialog data loaded';
export const UPDATE_EDIT_AUTO_APPLIED_DISCOUNT_ACTION = '[Dialog Edit Auto Applied Discount] Update edit auto applied discount';

export const RESET_STATE_ACTION = '[Dialog Edit Auto Applied Discount] Reset state';

export const UPDATE_AUTO_APPLIED_DISCOUNT_ACTION = '[Dialog Edit Auto Applied Discount] Update auto applied discount';
export const UPDATE_ALL_LOCATIONS_ACTION = '[Dialog Edit Auto Applied Discount] Update all locations';


export class UpdateDialogDataLoaded implements Action {
    readonly type = UPDATE_DIALOG_DATA_LOADED_ACTION;

    constructor(public value: boolean) {
    }
}

export class UpdateEditAutoAppliedDiscount implements Action {
    readonly type = UPDATE_EDIT_AUTO_APPLIED_DISCOUNT_ACTION;

    constructor(public value: boolean) {
    }
}

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class UpdateAutoAppliedDiscount implements Action {
    readonly type = UPDATE_AUTO_APPLIED_DISCOUNT_ACTION;

    constructor(public autoAppliedDiscount: AutoAppliedDiscountCostModel) {
    }
}

export class UpdateAllLocations implements Action {
    readonly type = UPDATE_ALL_LOCATIONS_ACTION;

    constructor(public locations: LocationResponseModel[]) {
    }
}


export type Actions =
    UpdateDialogDataLoaded |
    ResetState |
    UpdateAutoAppliedDiscount |
    UpdateAllLocations |
    UpdateEditAutoAppliedDiscount;
