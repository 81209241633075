import {
    NotificationAlertModel,
    NotificationAlertTypeEnum
} from '../../../shared/notifications/notification-alert.model';
import * as actions from './actions';

export interface State {
    notifications: NotificationAlertModel[];
}

const initialState: State = {
    notifications: []
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.DISPOSE_FIRST_NOTIFICATION_ACTION:
            return handleDisposeFirstNotificationAction(state, action);
        case actions.ADD_ERROR_NOTIFICATION:
            return handleAddHttpErrorAction(state, action);
        case actions.ADD_INFO_NOTIFICATION:
            return handleInfoNotification(state, action);
        default:
            return state;
    }
}

// #################################################### SELECTORS ####################################################
export const notifications_selector = (state: State) => state.notifications;


// #################################################### HANDLERS ####################################################
function handleDisposeFirstNotificationAction(state: State, action: actions.DisposeFirstNotification) {
    return {
        ...state,
        notifications: [
            ...state.notifications.slice(1)
        ]
    };
}

function handleAddHttpErrorAction(state: State, action: actions.AddErrorNotification) {

    const notificationAlert: NotificationAlertModel = new NotificationAlertModel(NotificationAlertTypeEnum.ERROR, action.httpError.message);

    if (action.httpError.serverMessage) {
        notificationAlert.message = action.httpError.serverMessage;
    }

    return {
        ...state,
        notifications: [
            notificationAlert
        ]
    };
}

function handleInfoNotification(state: State, action: actions.AddInfoNotification): State {
    return {
        ...state,
        notifications: [
            action.info
        ]
    };
}
