import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef  } from '@angular/core';
import {FieldItemModel} from '../../model/field-item.model';
import {DroppedFieldItemModel} from '../../model/dropped-field-item.model';
import {DraggedDataI} from '../../model/dragged-data.model';
import {DraggedReportItemDataModel} from '../../model/dragged-report-item-data.model';
import {ReportItemModel} from '../../model/report-item.model';
import {DraggedFieldDataModel} from '../../model/dragged-field-data.model';

const SCROLL_OFFSET = 5;
const SCROLL_INTERVAL = 10;



@Component({
    selector: 'app-order-reports-selected-fields-area',
    templateUrl: './order-reports-selected-fields-area.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReportsSelectedFieldsAreaComponent implements OnInit, OnChanges {

    @Input() selectedFields: FieldItemModel[];
    @Input() draggedData: DraggedDataI; // Used in ngOnChanges
    @Input() hasRunOrderReportFullPermission: boolean;

    @Output() dragStartEvent: EventEmitter<DraggedDataI> = new EventEmitter<DraggedDataI>();
    @Output() dragEndEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() droppedFieldItemEvent: EventEmitter<DroppedFieldItemModel> = new EventEmitter<DroppedFieldItemModel>();
    @Output() deleteFieldItemEvent: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('selectedFieldsArea', { read: ViewContainerRef }) selectedFieldsArea: ViewContainerRef;

    dropAreasVisible = false;
    private scrollLeftInterval;
    private scrollRightInterval;

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        // Dragging data changed
        if (changes.draggedData) {
            if (this.draggedData) {
                switch (this.draggedData.constructor) {
                    case DraggedReportItemDataModel:
                        const draggedReportItem: DraggedReportItemDataModel = <DraggedReportItemDataModel> this.draggedData;
                        const reportItem: ReportItemModel = draggedReportItem.reportItem;

                        if (!reportItem.field) {
                            this.dropAreasVisible = false;
                        } else if (reportItem.fieldDisabled) {
                            this.dropAreasVisible = false;
                        } else {
                            this.dropAreasVisible = true;
                        }
                        break;
                    case DraggedFieldDataModel:
                        this.dropAreasVisible = true;
                        break;
                    default:
                        this.dropAreasVisible = false;
                }
            } else {
                this.dropAreasVisible = false;
            }
        }
    }

    onDroppedFieldItem(data: DroppedFieldItemModel) {
        this.droppedFieldItemEvent.emit(data);
    }

    onDeleteFieldItem(queryFieldName: string) {
        this.deleteFieldItemEvent.emit(queryFieldName);
    }

    onDragStart(data: DraggedDataI) {
        this.dragStartEvent.emit(data);
    }

    onDragEnd() {
        this.dragEndEvent.emit();
    }

    isFirstFieldItemDragged(): boolean {
        if (this.draggedData) {
            if (this.draggedData.constructor === DraggedFieldDataModel) {
                if (this.selectedFields.length !== 0 && this.draggedData) {
                    const draggedFieldData: DraggedFieldDataModel = <DraggedFieldDataModel> this.draggedData;
                    if (draggedFieldData.fieldItem && this.selectedFields[0].queryFieldName === draggedFieldData.fieldItem.queryFieldName) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    isThisFieldItemCurrentlyInDrag(fieldItem: FieldItemModel): boolean {
        if (this.draggedData) {
            if (this.draggedData.constructor === DraggedFieldDataModel) {
                const draggedFieldData: DraggedFieldDataModel = <DraggedFieldDataModel> this.draggedData;
                return fieldItem.queryFieldName === draggedFieldData.fieldItem.queryFieldName;
            }
        }

        return false;
    }

    isNextFieldItemCurrentlyInDrag(index: number): boolean {
        if (this.draggedData) {
            if (this.draggedData.constructor === DraggedFieldDataModel) {
                const draggedFieldData: DraggedFieldDataModel = <DraggedFieldDataModel> this.draggedData;
                if (index < this.selectedFields.length - 1) {
                    if (this.selectedFields[index + 1].queryFieldName === draggedFieldData.fieldItem.queryFieldName) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    onMousePointerNearLeftEdge(value: boolean) {
        if (value) {
            this.scrollLeftInterval = setInterval(() => {
                this.selectedFieldsArea.element.nativeElement.scrollLeft -= SCROLL_OFFSET;
            }, SCROLL_INTERVAL);
        } else {
            clearInterval(this.scrollLeftInterval);
        }
    }

    onMousePointerNearRightEdge(value: boolean) {
        if (value) {
            this.scrollRightInterval = setInterval(() => {
                this.selectedFieldsArea.element.nativeElement.scrollLeft += SCROLL_OFFSET;
            }, SCROLL_INTERVAL);
        } else {
            clearInterval(this.scrollRightInterval);
        }
    }
}
