import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {StoreModule} from "@ngrx/store";
import {EnableCategoryDialogSandbox} from "./sandbox/enable-category-dialog.sandbox";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {EnableCategoryDialogComponent} from "./enable-category-dialog.component";
import {EnableCategoryDialogFormComponent} from './components/enable-category-dialog-form/enable-category-dialog-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EnableCategoryDialogSandbox
    ],
    declarations: [
        EnableCategoryDialogComponent,
        EnableCategoryDialogFormComponent
    ],
    exports: [
        EnableCategoryDialogComponent
    ],
    entryComponents: [
        EnableCategoryDialogComponent
    ]
})
export class EnableCategoryDialogModule {

}
