<form [formGroup]="productTierForm">
    <p class="edit-product-tier-label">{{productTierForm.value.tierDescription}}</p>
    <div class="edit-product-tier-input-wrapper"
         [class.edit-product-tier-full-width-input-wrapper]="!isRoundTripProduct">
        <input class="main--element"
               placeholder="Enter cost"
               [formControlName]="'cost'"
               [ngClass]="{'disabled': areProductTiersDisabled}"/>
        <input *ngIf="isRoundTripProduct" class="main--element"
               placeholder="Enter cost (RT)"
               [formControlName]="'costRoundTrip'"
               [ngClass]="{'disabled': areProductTiersDisabled}"/>
    </div>
</form>
