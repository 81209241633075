import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from "moment";
import { Moment } from "moment";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DateRangeModel } from "../../model/date-range.model";
import { DaterangepickerDirective } from "ngx-daterangepicker-material";


@Component({
    selector: 'app-datetimepicker',
    templateUrl: './datetimepicker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatetimepickerComponent),
            multi: true,
        }
    ]
})

export class DatetimepickerComponent implements AfterViewInit, ControlValueAccessor, OnChanges {
    @Input() showCustomRangeLabel: boolean = false;
    @Input() isSingleDatePicker: boolean = true;
    @Input() linkedCalendars: boolean = false;
    @Input() showClearButton: boolean = true;
    @Input() ranges: any = null;
    @Input() opensPosition: string = null;
    @Input() showDropdowns: boolean = true;
    @Input() showDefaultRanges: boolean = false;
    @Input() startDateValue: Moment = null;
    @Input() endDateValue: Moment = null;
    @Input() timePicker: boolean = false;
    @Input() timePickerSeconds: boolean = false;
    @Input() disabled: boolean = false;
    @Input() placeholderText: string = 'Select date';
    @Input() drops: string = 'down';
    @Input() loading: boolean = false;
    @Input() minDate: Moment = null;
    @Input() maxDate: Moment = null;
    @Input() hiddenInput: boolean = false;
    @Input() openDatePicker: boolean = false;

    @Output() dateChanged: EventEmitter<DateRangeModel | Moment> = new EventEmitter();
    @Output() datePickerClosed: EventEmitter<void> = new EventEmitter();

    @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;

    firstTry: boolean = true;
    selected: DateRangeModel;
    private registeredChange: (value: DateRangeModel) => void;

    defaultRanges: any = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 14 Days': [moment().subtract(13, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'Next 30 Days': [moment(), moment().add(30, 'days')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')]
    };

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.startDateValue && changes.endDateValue && !this.isSingleDatePicker) {
            this.createSelectedObjectAndSetValue(changes.startDateValue.currentValue, changes.endDateValue.currentValue);
        }
        if (this.isSingleDatePicker) {
            if (changes.startDateValue) {
                this.createSelectedObjectAndSetValue(changes.startDateValue.currentValue);
            }
            if (changes.endDateValue) {
                this.createSelectedObjectAndSetValue(changes.endDateValue.currentValue);
            }
        }
        if (changes.disabled) {
            this.pickerDirective.setDisabledState(this.disabled);
        }
        if (changes.openDatePicker) {
            if (changes.openDatePicker.currentValue) {
                this.pickerDirective.open();
            }
        }
    }

    changeDate(event: DateRangeModel | any) {
        // Date picker works with UTC, so we need to convert it to current time zone
        if (this.timePicker && this.timePickerSeconds) {
            if (event.startDate) {
                let startDateUtcFormat = event.startDate.utc().format('YYYY-MM-DDTHH:mm:ss');
                event.startDate = moment(startDateUtcFormat, 'YYYY-MM-DDTHH:mm:ss');
            }
            /*
            if (event.endDate) {
                let endDateUtcFormat = event.endDate.utc().format('YYYY-MM-DD');
                event.endDate = moment(endDateUtcFormat, 'YYYY-MM-DD');
            }*/
            if (event.endDate) {
                let endDateUtcFormat = event.endDate.utc().format('YYYY-MM-DDTHH:mm:ss');
                event.endDate = moment(endDateUtcFormat, 'YYYY-MM-DDTHH:mm:ss');
            }
        } else if (this.timePicker) {
            if (event.startDate) {
                let startDateUtcFormat = event.startDate.utc().format('YYYY-MM-DDTHH:mm');
                event.startDate = moment(startDateUtcFormat, 'YYYY-MM-DDTHH:mm');
            }

            if (event.endDate) {
                let endDateUtcFormat = event.endDate.utc().format('YYYY-MM-DDTHH:mm');
                event.endDate = moment(endDateUtcFormat, 'YYYY-MM-DDTHH:mm');
            }
        } else {
            if (event.startDate) {
                let startDateUtcFormat = event.startDate.utc().format('YYYY-MM-DD');
                event.startDate = moment(startDateUtcFormat, 'YYYY-MM-DD');
            }

            if (event.endDate) {
                let endDateUtcFormat = event.endDate.utc().format('YYYY-MM-DD');
                event.endDate = moment(endDateUtcFormat, 'YYYY-MM-DD');
            }

        }
        
        if (!this.firstTry) {
            this.isSingleDatePicker ? this.createSelectedObjectAndSetValue(event.startDate) : this.createSelectedObjectAndSetValue(event.startDate, event.endDate);
            this.isSingleDatePicker ? this.sendChangedDate(event.startDate) : this.sendChangedDate(event);
        }

        this.firstTry = false;

    }

    ngAfterViewInit() {
        this.setClearButtonText();
    }

    private setClearButtonText() {
        if (this.showClearButton) {
            let clearLabel = document.querySelectorAll('.md-drppicker .clear');
            for (let i = 0; i < clearLabel.length; i++) {
                clearLabel[i].innerHTML = `Clear &nbsp;<i class="fal fa-trash-alt"></i>`;
            }
        }
    }

    private sendChangedDate(value: DateRangeModel | Moment | any) {
        this.dateChanged.emit(value);
        if (this.registeredChange) {
            this.registeredChange(value);
        }
    }

    private createSelectedObjectAndSetValue(startDate: Moment, endDate?: Moment) {
        if (this.isSingleDatePicker && startDate) {
            this.selected = {
                startDate: startDate,
                endDate: startDate
            };
            this.startDateValue = startDate;

        } else if (!this.isSingleDatePicker && startDate && endDate) {
            this.selected = {
                startDate: startDate,
                endDate: endDate
            };
            this.startDateValue = startDate;
            this.endDateValue = endDate;

        } else if (this.selected && this.selected.startDate && this.selected.endDate) {
            this.pickerDirective.clear();
        }
    }

    onClose() {
        this.datePickerClosed.emit();
    }

    openDatepicker() {
        this.pickerDirective.open();
    }

    writeValue(value: DateRangeModel | Moment | any) {
        this.isSingleDatePicker ? this.createSelectedObjectAndSetValue(value) : this.createSelectedObjectAndSetValue(value.startDate, value.endDate);
        this.cdr.detectChanges();
    }

    registerOnChange(fn: () => void) {
        this.registeredChange = fn;
    }

    registerOnTouched(fn: any) {
    }
}
