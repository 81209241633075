import * as actions from './actions';
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CloudPaymentDeviceResponseModel } from '../../../../../../../../../core/modules/rest/cloud-payment/response/cloud-payment-response.model';

export const REDUCER_NAME = 'dialog_create/edit-cloud-payment-device';

export interface State {
    cloudPaymentDevice: CloudPaymentDeviceResponseModel;
}

const initialState: State = {
    cloudPaymentDevice: {} as CloudPaymentDeviceResponseModel
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const cloud_payment_device_selector = createSelector(state_selector, (state: State) => state.cloudPaymentDevice);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_CLOUD_PAYMENT_DEVICE_ACTION: return { ... state, cloudPaymentDevice: action.cloudPaymentDevice };

        default: return state;
    }
}
