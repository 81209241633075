<app-abstract-dialog-container [shouldOverrideCloseClicked]="true" (closeClicked)="onCloseClicked()">
    <div class="form_container">
        <!-- Header -->
        <div class="header_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <h1>{{ header }}</h1>
                </div>
            </div>
        </div>

        <!-- Message -->
        <div class="yes_no_content_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12 fs-16">
                    {{ message }}
                </div>
            </div>
        </div>

        <!-- Footer container -->
        <div class="footer_wrapper flex-container">
            <!-- No button -->
            <app-button class="mr-16px"
                        [buttonType]="'button ghost-button'"
                        (clickEvent)="onNoClicked()">
                {{ noLabel }}
            </app-button>

            <!-- Yes button -->
            <app-button [buttonType]="buttonType"
                        (clickEvent)="onYesClicked()">
                {{ yesLabel }}
            </app-button>
        </div>
    </div>
</app-abstract-dialog-container>
