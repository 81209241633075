import {Action} from "@ngrx/store";
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export const RESET_STATE = '[Dialog - Edit Product Routes :: MAIN] Reset State';
export const SET_PRODUCT = '[Dialog - Edit Product Routes :: MAIN] Set Product';
export const SET_PICKUP_DROPOFF_LOCATION_LISTS = '[Dialog - Edit Product Routes :: MAIN] Set Pickup & Dropoff Location Lists';
export const SET_PICKUP_LOCATION_LISTS = '[Dialog - Edit Product Routes :: MAIN] Set Pickup Location Lists';
export const SET_DROPOFF_LOCATION_LISTS = '[Dialog - Edit Product Routes :: MAIN] Set Dropoff Location Lists';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetProduct implements Action {
    readonly type = SET_PRODUCT;

    constructor(public product: ProductSimpleResponseModel) {
    }
}

export class SetPickupDropoffLocationLists implements Action {
    readonly type = SET_PICKUP_DROPOFF_LOCATION_LISTS;

    constructor(public pickupDropoffLocationLists: DropdownOptionModel[]) {
    }
}

export class SetPickupLocationLists implements Action {
    readonly type = SET_PICKUP_LOCATION_LISTS;

    constructor(public pickupLocationLists: DropdownOptionModel[]) {
    }
}

export class SetDropoffLocationLists implements Action {
    readonly type = SET_DROPOFF_LOCATION_LISTS;

    constructor(public dropoffLocationLists: DropdownOptionModel[]) {
    }
}

export type All = ResetState
    | SetProduct
    | SetPickupDropoffLocationLists
    | SetPickupLocationLists
    | SetDropoffLocationLists;
