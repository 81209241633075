
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {MultiselectDropdownOptionModel} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import {CommissionRestService} from "../../../../../../../../core/modules/rest/commission/commission-rest.service";
import {CategoryRestService} from "../../../../../../../../core/modules/rest/category/category-rest.service";
import {ProductRestService} from "../../../../../../../../core/modules/rest/product/product-rest.service";
import {PassRestService} from "../../../../../../../../core/modules/rest/pass/pass-rest.service";
import {TierRestService} from "../../../../../../../../core/modules/rest/tier/tier-rest.service";
import {RoleRestService} from "../../../../../../../../core/modules/rest/role/role-rest.service";
import {UserRestService} from "../../../../../../../../core/modules/rest/user/user-rest.service";
import {LocationRestService} from "../../../../../../../../core/modules/rest/location/location-rest.service";
import {CommissionMappingsResponseModel} from "../../../../../../../../core/modules/rest/commission/response/commission-mappings-response.model";
import {CategoryResponseModel} from "../../../../../../../../core/modules/rest/category/response/category-response.model";
import {ProductSimpleResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {PassSimpleResponseModel} from "../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {SubmitCommissionMappingsData} from "../components/mapping-commission-dialog-form/mapping-commission-dialog-form.component";
import {TiersResponseModel} from "../../../../../../../../core/modules/rest/tier/response/tiers-response.model";
import {RolesResponseModel} from "../../../../../../../../core/modules/rest/role/response/roles-response.model";
import {UsersResponseModel} from "../../../../../../../../core/modules/rest/user/response/users-response.model";
import {LocationsResponseModel} from "../../../../../../../../core/modules/rest/location/response/locations-response.model";
import { MultiselectDropdownOptionActiveModel } from '../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option-active.model';

@Injectable()
export class MappingCommissionDialogSandbox extends DialogSandbox {

    loading$: Observable<boolean> = this.store.pipe(select(reducer.loading_selector));
    categories$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.categories_selector));
    mappedCategories$: Observable<number[]> = this.store.pipe(select(reducer.mappedCategories_selector));
    products$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.products_selector));
    mappedProducts$: Observable<number[]> = this.store.pipe(select(reducer.mappedProducts_selector));
    passes$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.passes_selector));
    mappedPasses$: Observable<number[]> = this.store.pipe(select(reducer.mappedPasses_selector));
    tiers$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.tiers_selector));
    mappedTiers$: Observable<number[]> = this.store.pipe(select(reducer.mappedTiers_selector));
    roles$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.roles_selector));
    mappedRoles$: Observable<number[]> = this.store.pipe(select(reducer.mappedRoles_selector));
    users$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.users_selector));
    mappedUsers$: Observable<number[]> = this.store.pipe(select(reducer.mappedUsers_selector));
    locations$: Observable<MultiselectDropdownOptionModel[]> = this.store.pipe(select(reducer.locations_selector));
    mappedLocations$: Observable<number[]> = this.store.pipe(select(reducer.mappedLocations_selector));

    constructor(appDialogsService: AppDialogsService,
                private commissionRestService: CommissionRestService,
                private categoryRestService: CategoryRestService,
                private productRestService: ProductRestService,
                private passRestService: PassRestService,
                private tierRestService: TierRestService,
                private roleRestService: RoleRestService,
                private userRestService: UserRestService,
                private locationRestService: LocationRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(commissionId: number) {

        this.store.dispatch(new actions.SetLoading(true));

        observableCombineLatest(this.commissionRestService.getCommissionMappings(commissionId), this.categoryRestService.getAll(), this.productRestService.getAll(), this.passRestService.getAll(), this.tierRestService.getAll(), this.roleRestService.getAll(), this.userRestService.getAll(), this.locationRestService.getAll()).pipe(
            take(1))
            .subscribe(([commissionMappings, categories, products, passes, tiers, roles, users, locations]: [CommissionMappingsResponseModel, CategoryResponseModel[], ProductSimpleResponseModel[], PassSimpleResponseModel[], TiersResponseModel, RolesResponseModel, UsersResponseModel, LocationsResponseModel]) => {
                this.store.dispatch(new actions.SetCategories(categories.map(c => new MultiselectDropdownOptionActiveModel(c.description, c.categoryId.toString(), c.active))));
                this.store.dispatch(new actions.SetMappedCategories(commissionMappings.commissionWhatMappings.filter(cwm => cwm.categoryId !== null && cwm.categoryId > 0).map(cwm => cwm.categoryId)));
                this.store.dispatch(new actions.SetProducts(products.map(p => new MultiselectDropdownOptionActiveModel(p.description, p.productId.toString(), p.active))));
                this.store.dispatch(new actions.SetMappedProducts(commissionMappings.commissionWhatMappings.filter(cwm => cwm.productId !== null && cwm.productId > 0).map(cwm => cwm.productId)));
                this.store.dispatch(new actions.SetPasses(passes.map(p => new MultiselectDropdownOptionActiveModel(p.description, p.passId.toString(), p.active))));
                this.store.dispatch(new actions.SetMappedPasses(commissionMappings.commissionWhatMappings.filter(cwm => cwm.passId !== null && cwm.passId > 0).map(cwm => cwm.passId)));
                this.store.dispatch(new actions.SetTiers(tiers.tiers.map(t => new MultiselectDropdownOptionActiveModel(t.description, t.tierId.toString(), t.active))));
                this.store.dispatch(new actions.SetMappedTiers(commissionMappings.commissionWhatMappings.filter(cwm => cwm.tierId !== null && cwm.tierId > 0).map(cwm => cwm.tierId)));
                this.store.dispatch(new actions.SetRoles(roles.roles.map(r => new MultiselectDropdownOptionActiveModel(r.description, r.roleId.toString(), r.active))));
                this.store.dispatch(new actions.SetMappedRoles(commissionMappings.commissionWhoMappings.filter(cwm => cwm.roleId !== null && cwm.roleId > 0).map(cwm => cwm.roleId)));
                this.store.dispatch(new actions.SetUsers(users.users.map(u => new MultiselectDropdownOptionActiveModel(u.displayName, u.userId.toString(), u.active))));
                this.store.dispatch(new actions.SetMappedUsers(commissionMappings.commissionWhoMappings.filter(cwm => cwm.userId !== null && cwm.userId > 0).map(cwm => cwm.userId)));
                this.store.dispatch(new actions.SetLocations(locations.locations.map(l => new MultiselectDropdownOptionActiveModel(l.description, l.locationId.toString(), l.active))));
                this.store.dispatch(new actions.SetMappedLocations(commissionMappings.commissionWhereMappings.filter(cwm => cwm.locationId !== null && cwm.locationId > 0).map(cwm => cwm.locationId)));
                this.store.dispatch(new actions.SetLoading(false));
            },
            () => this.store.dispatch(new actions.SetLoading(false)));
    }

    updateCommissionMappings(data: SubmitCommissionMappingsData): Observable<any> {

        return this.commissionRestService.updateCommissionMappings({
            commissionId: data.commissionId,
            commissionWhatMappings: data.commissionWhatMappings,
            commissionWhoMappings: data.commissionWhoMappings,
            commissionWhereMappings: data.commissionWhereMappings
        });
    }
}
