import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RolesResponseModel} from './response/roles-response.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {RoleMappingResponseModel} from "./response/role-mapping-response.model";
import {ActivateRoleRequestModel} from "./request/activate-role-request.model";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {CreateRoleRequestModel} from "./request/create-role-request.model";
import {UpdateRoleBasicInfoRequestModel} from "./request/update-role-basic-info-request.model";
import {RoleResponseModel} from "./response/role-response.model";
import {UpdateRoleMappingRequestModel} from "./request/update-role-mapping-request.model";

@Injectable()
export class RoleRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<RolesResponseModel> {
        return this.httpClient.get<RolesResponseModel>(environment.server_api_url + '/api/secured/role/getAll');
    }

    getAllActive(): Observable<RolesResponseModel> {
        return this.httpClient.get<RolesResponseModel>(environment.server_api_url + '/api/secured/role/getAllActive');
    }

    getById(roleId: number): Observable<RoleResponseModel> {
        return this.httpClient.get<RoleResponseModel>(environment.server_api_url + "/api/secured/role/getById/" + roleId);
    }

    getRoleMapping(roleId: number): Observable<RoleMappingResponseModel> {
        return this.httpClient.get<RoleMappingResponseModel>(environment.server_api_url + "/api/secured/role/getRoleMapping/" + roleId);
    }

    create(request: CreateRoleRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + "/api/secured/role/create", request);
    }

    updateRoleBasicInfo(request: UpdateRoleBasicInfoRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/role/updateRoleBasicInfo', request, {responseType: 'text'});
    }

    updateRoleMapping(request: UpdateRoleMappingRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/role/updateRoleMapping', request, {responseType: 'text'});
    }

    activate(request: ActivateRoleRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/role/activate', request, {responseType: 'text'});
    }

    deactivate(roleId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/role/deactivate', roleId, {responseType: 'text'});
    }
}
