import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CommissionResponseModel } from "../../../../../../../../../core/modules/rest/commission/response/commission-response.model";
import { CommissionFormSubmitDataModel } from "../../model/commission-form-submit-data.model";
import * as moment from "moment";
import { AmountTypeEnum } from "../../../../../../../../../shared/enums/amount-type.enum";
import { DateTimeUtility } from "../../../../../../../../../shared/utils/date-time-utility";
import { MultiselectDropdownOptionModel } from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import { NumberUtility } from "../../../../../../../../../shared/utils/number-utility";


@Component({
    selector: 'app-edit-commission-form',
    templateUrl: './edit-commission-form.component.html'
})
export class EditCommissionFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() commission: CommissionResponseModel;
    @Input() amountTypes: MultiselectDropdownOptionModel[];

    @Output() submitEvent = new EventEmitter<CommissionFormSubmitDataModel>();
    @Output() closeEvent = new EventEmitter<void>();

    form: FormGroup;
    displayErrorMessage: boolean;
    errorMessage: string;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.editMode && changes.commission !== null && Object.keys(changes.commission.currentValue).length !== 0) {
            this.form.patchValue({
                description: changes.commission.currentValue.description,
                commissionAmount: changes.commission.currentValue.commissionAmount,
                commissionAmountType: changes.commission.currentValue.commissionAmountType,
                referralAmount: changes.commission.currentValue.referralAmount,
                referralAmountType: changes.commission.currentValue.referralAmountType,
                startDate: changes.commission.currentValue.startDateMS ? moment(changes.commission.currentValue.startDateMS) : null,
                endDate: changes.commission.currentValue.endDateMS ? moment(changes.commission.currentValue.endDateMS) : null,
                startTime: changes.commission.currentValue.startTime ? this.formatNumberToTime(changes.commission.currentValue.startTime) : null,
                endTime: changes.commission.currentValue.endTime ? this.formatNumberToTime(changes.commission.currentValue.endTime) : null
            });
        }
    }

    ngOnInit() {

        let description: string = '';
        let commissionAmount: number = null;
        let commissionAmountType: AmountTypeEnum = AmountTypeEnum.PERCENT;
        let referralAmount: number = null;
        let referralAmountType: AmountTypeEnum = AmountTypeEnum.PERCENT;
        let startDate: moment.Moment = null;
        let endDate: moment.Moment = null;
        let startTime: number = null;
        let endTime: number = null;
        this.displayErrorMessage = false;

        if (this.editMode) {
            description = this.commission.description;
            commissionAmount = this.commission.commissionAmount;
            commissionAmountType = this.commission.commissionAmountType;
            referralAmount = this.commission.referralAmount;
            referralAmountType = this.commission.referralAmountType;
            startDate = this.commission.startDateMS ? moment(this.commission.startDateMS) : null;
            endDate = this.commission.endDateMS ? moment(this.commission.endDateMS) : null;
            startTime = this.commission.startTime;
            endTime = this.commission.endTime;
        }

        this.form = new FormGroup({
            description: new FormControl(description),
            commissionAmount: new FormControl(commissionAmount),
            commissionAmountType: new FormControl(commissionAmountType),
            referralAmount: new FormControl(referralAmount),
            referralAmountType: new FormControl(referralAmountType),
            startDate: new FormControl(startDate),
            endDate: new FormControl(endDate),
            startTime: new FormControl(startTime),
            endTime: new FormControl(endTime),
        });
    }

    formatNumberToTime(number) {
        const numStr = number.toString();
        if (numStr.length === 1) {
          return `0:${numStr.padStart(2, '0')}`;
        } else if (numStr.length === 2) {
          return `${numStr}:00`;
        } else if (numStr.length === 3) {
          const hours = numStr.charAt(0);
          const minutes = numStr.substring(1);
          return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
        } else if (numStr.length === 4) {
          const hours = numStr.substring(0, 2);
          const minutes = numStr.substring(2);
          return `${hours}:${minutes}`;
        } else {
          return 'Invalid Input';
        }
      }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        this.clearErrorMessage();
        const validAmountRegex = /^[0-9]{0,14}(\.[0-9]+)?$/;
        const description: string = this.form.value.description;
        const commissionAmount: number = this.form.value.commissionAmount;
        const commissionAmountType: AmountTypeEnum = this.form.value.commissionAmountType;
        let referralAmount: string = this.form.value.referralAmount;
        const referralAmountType: AmountTypeEnum = this.form.value.referralAmountType;
        const startDate: number = this.form.value.startDate ? parseInt(this.form.value.startDate.format("YYYYMMDD")): null;
        const endDate: number = this.form.value.endDate ? parseInt (this.form.value.endDate.format("YYYYMMDD")) : null;
        const startTime: number = DateTimeUtility.parseTime(this.form.value.startTime);
        const endTime: number = DateTimeUtility.parseTime(this.form.value.endTime);

        if (!description || description.trim().length === 0) {
            this.displayErrorMessage = true;
            this.editErrorMessage("Description is mandatory!");
            return;
        }

        if (!commissionAmount && !referralAmount) {
            this.editErrorMessage("Commission and referral amount are mandatory!");
            this.displayErrorMessage = true;
            return;
        }

        if (commissionAmount) {
            if (!commissionAmountType) {
                this.editErrorMessage("Commission amount type is mandatory!");
                this.displayErrorMessage = true;
                return;
            }
            if (commissionAmount < NumberUtility.EPSILON) {
                this.editErrorMessage("Commission amount must be greater than!" + NumberUtility.EPSILON);
                this.displayErrorMessage = true;
                return;
            }
            if (commissionAmountType === AmountTypeEnum.PERCENT && commissionAmount >= 100) {
                this.editErrorMessage("Commission amount percentage can't go over 100%");
                this.displayErrorMessage = true;
                return;
            }
            if (!validAmountRegex.test(this.form.value.commissionAmount) || this.form.value.commissionAmount.length > 17) {
                this.editErrorMessage("Commission amount can have at most 14 digits followed by the decimal part at most 16 digits in total!");
                this.displayErrorMessage = true;
            }
            referralAmount = referralAmount.toString();
            if (referralAmount) {
                this.displayErrorMessage = true;
                if (!referralAmountType) {
                    this.editErrorMessage("Referral amount type is mandatory!");
                    this.displayErrorMessage = true;
                    return;
                }
                if (parseFloat(referralAmount) < NumberUtility.EPSILON) {
                    this.editErrorMessage("Referral amount must be greater than! " + NumberUtility.EPSILON);
                    this.displayErrorMessage = true;
                    return;
                }
                if (referralAmountType === AmountTypeEnum.PERCENT && parseFloat(referralAmount) >= 100) {
                    this.editErrorMessage("Referral amount percentage can't go over 100%");
                    this.displayErrorMessage = true;
                    return;
                }
                if (!validAmountRegex.test(this.form.value.referralAmount) || this.form.value.referralAmount.length > 17) {
                    this.editErrorMessage("Referral amount can have at most 14 digits followed by the decimal part at most 16 digits in total!");
                    this.displayErrorMessage = true;
                    return
                }

                if (isNaN(startTime) || startTime < 0 || startTime > 2359) {
                    this.editErrorMessage("Start time must be between 0 and 2359!");
                    this.displayErrorMessage = true;
                    return;
                }

                if (isNaN(endTime) || endTime < 0 || endTime > 2359) {
                    this.editErrorMessage("End time must be between 0 and 2359!");
                    this.displayErrorMessage = true;
                    return;
                }

                const data: CommissionFormSubmitDataModel = new CommissionFormSubmitDataModel(description, commissionAmount, commissionAmountType, parseFloat(referralAmount), referralAmountType, startDate, endDate, startTime, endTime);

                this.submitEvent.emit(data);
            }
        }
    }

    clearErrorMessage() {
        this.errorMessage = null;
    }

    editErrorMessage(message: string) {
        if (!this.errorMessage || this.errorMessage.length == 0) {
            this.errorMessage = message;
        } else {
            this.errorMessage = this.errorMessage.concat("/n");
            this.errorMessage = this.errorMessage.concat(message);
        }
    }
}
