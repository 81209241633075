<form class="form_container deactivate-occurrences-dialog">
    <div class="content_wrapper">
        <div class="mb-16px">
            <h1>{{getDialogTitle()}}</h1>
        </div>

        <div class="mb-16px">
            <p>{{getDialogSubtitle()}}</p>
        </div>

        <br>
        <div class="payment-summary-table overflow">
            <ng-container *ngFor="let occurrenceOrders of occurrencesOrders">
                <div>
                    <h3>{{occurrenceOrders.occurrenceDescription}}</h3>
                    <ng-container *ngIf="occurrenceOrders.occurrenceOrders.length !== 0">
                        <ul>
                            <ng-container *ngFor="let occurrenceOrder of occurrenceOrders.occurrenceOrders">
                                <li>
                                    <a (click)="onOrderExternalIdClicked(occurrenceOrder.orderId)">{{occurrenceOrder.orderExternalId}}</a>
                                </li>
                            </ng-container>
                        </ul>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="canVoidAndRefund()">
            <div class="mb-16px">
                <div class="checkboxLabelGroup">
                    <input type="checkbox" id="checkbox_92a94d22-e8c1-d6af-8f68-fa4e37289191" [value]="voidOrders" (click)="onVoidAndRefundOccurrenceClicked()">
                    <label class="ss-checkbox" for="checkbox_92a94d22-e8c1-d6af-8f68-fa4e37289191">Void and refund occurrence</label>
                </div>
            </div>

            <div class="mb-16px flex-container align-items-center align-items-justify" [ngClass]="{'disabled': !voidOrders}">
                <label class="mr-16px">Select a Void HTML template</label>
                <app-dropdown
                    [options]="emailHtmlTemplatesOptions"
                    (changed)="onEmailHtmlTemplateSelected($event)">
                </app-dropdown>
            </div>
        </ng-container>
    </div>

    <div class="footer_wrapper">
        <div class="flex-container align-center-middle">
            <div class="mr-16px">
                <button class="button secondary" (click)="onCancelClick()">Cancel</button>
            </div>
            <div [ngClass]="{'disabled': voidOrders && !emailHtmlTemplateId}" class="mr-16px">
                <button class="button primary" (click)="onSubmitClick()">Deactivate</button>
            </div>
        </div>
    </div>
</form>
