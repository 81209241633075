import {Injectable} from "@angular/core";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {CompanyInfoResponseModel} from "./response/company-info-response.model";
import {CreateCompanyInfoRequestModel} from "./request/create-company-info-request.model";
import {UpdateCompanyInfoRequestModel} from "./request/update-company-info-request.model";

@Injectable()
export class CompanyInfoRestService {

    constructor(private httpClient: HttpClient) {
    }

    getById(companyInfoId: number): Observable<CompanyInfoResponseModel> {
        return this.httpClient.get<CompanyInfoResponseModel>(environment.server_api_url + '/api/secured/companyInfo/getById/' + companyInfoId);
    }

    getAll(): Observable<CompanyInfoResponseModel[]> {
        return this.httpClient.get<CompanyInfoResponseModel[]>(environment.server_api_url + '/api/secured/companyInfo/getAll');
    }

    create(request: CreateCompanyInfoRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/companyInfo/create', request);
    }

    update(request: UpdateCompanyInfoRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/companyInfo/update', request, {responseType: 'text'});
    }

    activate(companyInfoId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/companyInfo/activate', companyInfoId, {responseType: 'text'});
    }

    deactivate(companyInfoId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/companyInfo/deactivate', companyInfoId, {responseType: 'text'});
    }
}
