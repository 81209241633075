
import {combineLatest as observableCombineLatest, forkJoin as observableForkJoin, Observable} from 'rxjs';

import {mergeMap, map, take, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {RootSandbox} from '../../../../../../core/store/root.sandbox';

import * as fromFeature from './reducers';
import * as fromFeatureMainActions from './main/actions';
import * as fromFeatureUiActions from './ui/actions';
import {CostRestService} from "../../../../../../core/modules/rest/cost/cost-rest.service";
import {HttpErrorModel} from "../../../../../../core/modules/rest/http-error.model";
import {AdditionalCostResponseModel} from "../../../../../../core/modules/rest/cost/response/additional-cost-response.model";
import {IncludedCostResponseModel} from "../../../../../../core/modules/rest/cost/response/included-cost-response.model";
import {DiscountResponseModel} from "../../../../../../core/modules/rest/cost/response/discount-response.model";
import {AutoAppliedDiscountResponseModel} from "../../../../../../core/modules/rest/cost/response/auto-applied-discount-response.model";
import {CostTypeEnum} from "../../../../../../shared/enums/cost-type.enum";
import {UpdateAutoAppliedDiscountPriorityRequestModel} from "../../../../../../core/modules/rest/cost/request/update-auto-applied-discount-priority-request.model";

@Injectable()
export class CostsSandbox {

    constructor(private store: Store<fromFeature.State>, private rootSandbox: RootSandbox,
                private costRestService: CostRestService) {
    }

    loadPageData() {

        // Page data loaded - false
        this.updatePageDataLoaded(false);

        // Create requests
        const getAllAdditionalCosts_Request: Observable<AdditionalCostResponseModel[] | HttpErrorModel> = this.costRestService.getAllAdditionalCosts().pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading all additional costs', error);
            }));

        const getAllIncludedCosts_Request: Observable<IncludedCostResponseModel[] | HttpErrorModel> = this.costRestService.getAllIncludedCosts().pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading all included costs', error);
            }));

        const getAllDiscounts_Request: Observable<DiscountResponseModel[] | HttpErrorModel> = this.costRestService.getAllDiscounts().pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading all discounts', error);
            }));

        const getAllAutoAppliedDiscounts_Request: Observable<AutoAppliedDiscountResponseModel[] | HttpErrorModel> = this.costRestService.getAllAutoAppliedDiscounts().pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading all auto applied discounts', error);
            }));

        observableForkJoin([getAllAdditionalCosts_Request, getAllIncludedCosts_Request, getAllDiscounts_Request, getAllAutoAppliedDiscounts_Request])
            .subscribe((results: any[]) => {

                const allAdditionalCosts: AdditionalCostResponseModel[] = results[0];
                const allIncludedCosts: IncludedCostResponseModel[] = results[1];
                const allDiscounts: DiscountResponseModel[] = results[2];

                let allAutoAppliedDiscounts: AutoAppliedDiscountResponseModel[] = results[3];

                allAutoAppliedDiscounts = allAutoAppliedDiscounts.sort((a1, a2) => a1.priority - a2.priority);

                // Dispatch actions to store
                this.store.dispatch(new fromFeatureMainActions.UpdateAdditionalCosts(allAdditionalCosts));
                this.store.dispatch(new fromFeatureMainActions.UpdateIncludedCosts(allIncludedCosts));
                this.store.dispatch(new fromFeatureMainActions.UpdateDiscounts(allDiscounts));
                this.store.dispatch(new fromFeatureMainActions.UpdateAutoAppliedDiscounts(allAutoAppliedDiscounts));

                this.updatePageDataLoaded(true);
            });
    }

    updatePageDataLoaded(loaded: boolean) {
        this.store.dispatch(new fromFeatureUiActions.PageDataLoaded(loaded));
    }

    updateActiveCostType(costType: CostTypeEnum) {
        this.store.dispatch(new fromFeatureUiActions.UpdateActiveCostType(costType));
    }

    updateShowInactiveAdditionalCosts(show: boolean) {
        this.store.dispatch(new fromFeatureUiActions.UpdateShowInactiveAdditionalCosts(show));
    }

    updateShowInactiveIncludedCosts(show: boolean) {
        this.store.dispatch(new fromFeatureUiActions.UpdateShowInactiveIncludedCosts(show));
    }

    updateShowInactiveDiscounts(show: boolean) {
        this.store.dispatch(new fromFeatureUiActions.UpdateShowInactiveDiscounts(show));
    }

    updateShowInactiveAutoAppliedDiscounts(show: boolean) {
        this.store.dispatch(new fromFeatureUiActions.UpdateShowInactiveAutoAppliedDiscounts(show));
    }

    updateAdditionalCostActivity(costId: number) {
        this.getAllAdditionalCosts().pipe(take(1),
            map((costs: AdditionalCostResponseModel[]) => {
                const filtered: AdditionalCostResponseModel[] = costs.filter((cost: AdditionalCostResponseModel) => cost.costId === costId);
                return filtered[0];
            }),
            mergeMap((cost: AdditionalCostResponseModel) => {
                const active: boolean = !cost.active;

                // Optimistic update
                this.store.dispatch(new fromFeatureMainActions.UpdateAdditionalCostActivity(costId, active));

                if (active) {
                    return this.costRestService.activate(costId);
                } else {
                    return this.costRestService.deactivate(costId);
                }
            }),
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating additional cost activity', error);
            }),)
            .subscribe();
    }

    updateIncludedCostActivity(costId: number) {
        this.getAllIncludedCosts().pipe(take(1),
            map((costs: IncludedCostResponseModel[]) => {
                const filtered: IncludedCostResponseModel[] = costs.filter((cost: IncludedCostResponseModel) => cost.costId === costId);
                return filtered[0];
            }),
            mergeMap((cost: IncludedCostResponseModel) => {
                const active: boolean = !cost.active;

                // Optimistic update
                this.store.dispatch(new fromFeatureMainActions.UpdateIncludedCostActivity(costId, active));

                if (active) {
                    return this.costRestService.activate(costId);
                } else {
                    return this.costRestService.deactivate(costId);
                }
            }),
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating included cost activity', error);
            }),)
            .subscribe();
    }

    updateDiscountActivity(costId: number) {
        this.getAllDiscounts().pipe(take(1),
            map((costs: DiscountResponseModel[]) => {
                const filtered: DiscountResponseModel[] = costs.filter((cost: DiscountResponseModel) => cost.costId === costId);
                return filtered[0];
            }),
            mergeMap((cost: DiscountResponseModel) => {
                const active: boolean = !cost.active;

                // Optimistic update
                this.store.dispatch(new fromFeatureMainActions.UpdateDiscountActivity(costId, active));

                if (active) {
                    return this.costRestService.activate(costId);
                } else {
                    return this.costRestService.deactivate(costId);
                }
            }),
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating discount activity', error);
            }),)
            .subscribe();
    }

    updateAutoAppliedDiscountActivity(costId: number) {

        this.getAllAutoAppliedDiscounts().pipe(take(1),
            map((costs: AutoAppliedDiscountResponseModel[]) => {
                const filtered: AutoAppliedDiscountResponseModel[] = costs.filter((cost: AutoAppliedDiscountResponseModel) => cost.costId === costId);
                return filtered[0];
            }),
            mergeMap((cost: AutoAppliedDiscountResponseModel) => {

                const active: boolean = !cost.active;

                if (active) {
                    return this.costRestService.activate(costId);
                } else {
                    return this.costRestService.deactivate(costId);
                }
            }),
            mergeMap(() => this.costRestService.getAllAutoAppliedDiscounts()),)
            .subscribe((autoAppliedDiscounts: AutoAppliedDiscountResponseModel[]) => {

                autoAppliedDiscounts = autoAppliedDiscounts.sort((a1, a2) => a1.priority - a2.priority);
                this.store.dispatch(new fromFeatureMainActions.UpdateAutoAppliedDiscounts(autoAppliedDiscounts));
            });
    }

    updateAutoAppliedDiscountPriority(costId: number, up: boolean) {

        let request: UpdateAutoAppliedDiscountPriorityRequestModel = new UpdateAutoAppliedDiscountPriorityRequestModel(costId, up);

        this.costRestService.updateAutoAppliedDiscountPriority(request).pipe(
            mergeMap(() => this.costRestService.getAllAutoAppliedDiscounts()))
            .subscribe((autoAppliedDiscounts: AutoAppliedDiscountResponseModel[]) => {

                autoAppliedDiscounts = autoAppliedDiscounts.sort((a1, a2) => a1.priority - a2.priority);
                this.store.dispatch(new fromFeatureMainActions.UpdateAutoAppliedDiscounts(autoAppliedDiscounts));
            });
    }

    getPageDataLoaded(): Observable<boolean> {
        return this.store.select(fromFeature.uiState_pageDataLoaded_selector);
    }

    getActiveCostType(): Observable<CostTypeEnum> {
        return this.store.select(fromFeature.uiState_activeCostType_selector);
    }

    getAllAdditionalCostsDependingOnActivity(): Observable<AdditionalCostResponseModel[]> {
        return observableCombineLatest(
            this.getAllAdditionalCosts(),
            this.getShowInactiveAdditionalCosts()).pipe(
            map(([allAdditionalCosts, showInactiveAdditionalCosts]) => {
                const costsParameterized: AdditionalCostResponseModel[] = allAdditionalCosts;
                const showInactiveParameterized: boolean = showInactiveAdditionalCosts;

                return costsParameterized.filter((cost: AdditionalCostResponseModel) => ((showInactiveParameterized && !cost.active) || cost.active));
            }));
    }

    getAllIncludedCostsDependingOnActivity(): Observable<IncludedCostResponseModel[]> {
        return observableCombineLatest(
            this.getAllIncludedCosts(),
            this.getShowInactiveIncludedCosts()).pipe(
            map(([allIncludedCosts, showInactiveAdditionalCosts]) => {
                const costsParameterized: IncludedCostResponseModel[] = allIncludedCosts;
                const showInactiveParameterized: boolean = showInactiveAdditionalCosts;

                return costsParameterized.filter((cost: IncludedCostResponseModel) => ((showInactiveParameterized && !cost.active) || cost.active));
            }));
    }

    getAllDiscountsDependingOnActivity(): Observable<DiscountResponseModel[]> {
        return observableCombineLatest(
            this.getAllDiscounts(),
            this.getShowInactiveDiscounts()).pipe(
            map(([allDiscounts, showInactiveAdditionalCosts]) => {
                const costsParameterized: DiscountResponseModel[] = allDiscounts;
                const showInactiveParameterized: boolean = showInactiveAdditionalCosts;

                return costsParameterized.filter((cost: DiscountResponseModel) => ((showInactiveParameterized && !cost.active) || cost.active));
            }));
    }

    getAllAutoAppliedDiscountsDependingOnActivity(): Observable<AutoAppliedDiscountResponseModel[]> {
        return observableCombineLatest(
            this.getAllAutoAppliedDiscounts(),
            this.getShowInactiveAutoAppliedDiscounts()).pipe(
            map(([allAutoAppliedDiscounts, showInactiveAdditionalCosts]) => {
                const costsParameterized: AutoAppliedDiscountResponseModel[] = allAutoAppliedDiscounts;
                const showInactiveParameterized: boolean = showInactiveAdditionalCosts;

                return costsParameterized.filter((cost: AutoAppliedDiscountResponseModel) => ((showInactiveParameterized && !cost.active) || cost.active));
            }));
    }

    getShowInactiveAdditionalCosts(): Observable<boolean> {
        return this.store.select(fromFeature.uiState_showInactiveAdditionalCosts_selector);
    }

    getShowInactiveIncludedCosts(): Observable<boolean> {
        return this.store.select(fromFeature.uiState_showInactiveIncludedCosts_selector);
    }

    getShowInactiveDiscounts(): Observable<boolean> {
        return this.store.select(fromFeature.uiState_showInactiveDiscounts_selector);
    }

    getShowInactiveAutoAppliedDiscounts(): Observable<boolean> {
        return this.store.select(fromFeature.uiState_showInactiveAutoAppliedDiscounts_selector);
    }

    private getAllAdditionalCosts(): Observable<AdditionalCostResponseModel[]> {
        return this.store.select(fromFeature.mainState_allAdditionalCosts_selector);
    }

    private getAllIncludedCosts(): Observable<IncludedCostResponseModel[]> {
        return this.store.select(fromFeature.mainState_allIncludedCosts_selector);
    }

    private getAllDiscounts(): Observable<DiscountResponseModel[]> {
        return this.store.select(fromFeature.mainState_allDiscounts_selector);
    }

    private getAllAutoAppliedDiscounts(): Observable<AutoAppliedDiscountResponseModel[]> {
        return this.store.select(fromFeature.mainState_allAutoAppliedDiscounts_selector);
    }
}
