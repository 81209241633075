import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {Observable} from "rxjs";
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {KioskConfigurationDropoffLocationPassTierModel} from "../model/kiosk-configuration-dropoff-location-pass-tier.model";
import {PassTierSetupDialogSandbox} from "../sandbox/pass-tier-setup-dialog.sandbox";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class PassTierSetupDialogData extends DialogData {

    kioskConfigurationDropoffLocationId: number;
    passId: number;
    description: string;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function,
                kioskConfigurationDropoffLocationId: number,
                passId: number,
                description: string) {
        super();
        this.successCallbackFn = successCallbackFn;
        this.kioskConfigurationDropoffLocationId = kioskConfigurationDropoffLocationId;
        this.passId = passId;
        this.description = description;
    }
}


@Component({
    selector: 'app-pass-tier-setup-dialog',
    templateUrl: './pass-tier-setup-dialog.component.html'
})
export class PassTierSetupDialogComponent implements OnInit, OnDestroy {

    kioskConfigurationDropoffLocationId: number;
    passId: number;
    description: string;
    successCallbackFn: Function;

    passTiers$: Observable<KioskConfigurationDropoffLocationPassTierModel[]>;

    constructor(@Inject(DialogData) dialogData: PassTierSetupDialogData,
                private sandbox: PassTierSetupDialogSandbox) {

        this.kioskConfigurationDropoffLocationId = dialogData.kioskConfigurationDropoffLocationId;
        this.passId = dialogData.passId;
        this.description = dialogData.description;
        this.successCallbackFn = dialogData.successCallbackFn;

        this.passTiers$ = this.sandbox.passTiers$;
    }

    ngOnInit() {
        this.sandbox.loadDialogData(this.kioskConfigurationDropoffLocationId, this.passId);
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(passTiers: KioskConfigurationDropoffLocationPassTierModel[]) {
        this.sandbox.createKioskConfigurationDropoffLocationProductTier(this.kioskConfigurationDropoffLocationId, this.passId, passTiers)
            .subscribe(() => {
                this.sandbox.hideLoader();
                this.successCallbackFn();
                this.sandbox.destroyDialog();
            },
            () => this.sandbox.hideLoader());
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
