import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {PassRestService} from "../../../../../../../../core/modules/rest/pass/pass-rest.service";
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {PassSimpleResponseModel} from "../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {SubmitPassFlagsData} from "../components/edit-pass-flags-dialog-form/edit-pass-flags-dialog-form.component";

@Injectable()
export class EditPassFlagsDialogSandbox extends DialogSandbox {

    pass$: Observable<PassSimpleResponseModel> = this.store.pipe(select(reducer.pass_selector));

    constructor(appDialogsService: AppDialogsService,
                private passRestService: PassRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(passId: number) {

        this.passRestService.getPassSimpleById(passId)
            .subscribe((pass: PassSimpleResponseModel) => {
                this.store.dispatch(new actions.SetPass(pass));
            });
    }

    updatePassFlags(data: SubmitPassFlagsData): Observable<any> {

        return this.passRestService.updatePassFlags({
            passId: data.passId,
            shouldSendBarcodeQRCodeImageAttachment: data.shouldSendBarcodeQRCodeImageAttachment,
            usesPregeneratedBarcodeOnAndroid: data.usesPregeneratedBarcodeOnAndroid
        });
    }
}
