<div class="page-wrapper order-reports-page">

    <div class="header_wrapper">
        <app-header [pageBreadcrumbItems]="pageBreadcrumbItems">
        </app-header>
    </div>

    <div class="pageContent_wrapper"
         [ngClass]="{'hasSidebar': hasRunOrderReportFullPermission$ | async}"
         appMousePointerNearTopEdge [disabled]="!(draggedData$ | async)" [distance]="100"
         (pointerNearTopEdgeEvent)="onPointerNearTopEdgeEvent($event)"
         #pageContentWrapper>

        <div class="infiniteScroll_container">

            <div class="row-padding">
                <div class="mainContentHeader_wrapper active">
                    <app-order-report-header
                        [selectedFavoriteId]="selectedFavoriteId$ | async"
                        [expressionItemsListEmpty]="(expressionItems$ | async).length === 0"
                        [listOfFavorites]="listOfFavorites$ | async"
                        [hasUseFavoritesPermission]="hasUseFavoritesPermission$ | async"
                        [hasCreateFavoritePermission]="hasCreateFavoritePermission$ | async"
                        [hasEditFavoritePermission]="hasEditFavoritePermission$ | async"
                        [hasRemoveFavoritePermission]="hasRemoveFavoritePermission$ | async"
                        [areEnoughFieldsSelected]="areEnoughFieldsSelected$ | async"
                        (editFavoriteEvent)="onEditFavorite($event)"
                        (removeFavoriteEvent)="onRemoveFavorite($event)"
                        (sidebarOpenStatusChanged)="onOpenCloseSidebarFavorite()">
                    </app-order-report-header>
                </div>
            </div>

            <div class="sidebar_wrapper">
                <app-order-reports-sidebar
                    [reportGroups]="reportGroups$ | async"
                    [reportItemsSearchText]="reportItemsSearchText$ | async"
                    [activeReportGroups]="activeReportGroups$ | async"
                    [showPaymentSummary]="showPaymentSummary$ | async"
                    [showPaymentSummaryDisabled]="showPaymentSummaryDisabled$ | async"
                    (dragStartEvent)="onDragStart($event)"
                    (dragEndEvent)="onDragEnd()"
                    (searchTextChanged)="onSearchTextChanged($event)"
                    (changeReportGroupActivityStatusEvent)="onChangeReportGroupActivityStatus($event)"
                    (showPaymentSummaryChangedEvent)="onShowPaymentSummaryChanged($event)">
                </app-order-reports-sidebar>
            </div>

            <div class="mainContent_wrapper">
                <div class="mainContent_container">
                    <app-order-reports-selected-filters-area
                        [expressionItems]="expressionItems$ | async"
                        [draggedData]="draggedData$ | async"
                        [hasRunOrderReportFullPermission]="hasRunOrderReportFullPermission$ | async"
                        (droppedDataEvent)="onDroppedData($event)"
                        (deleteExpressionItemEvent)="onDeleteExpressionItem($event)"
                        (dragStartEvent)="onDragStart($event)"
                        (dragEndEvent)="onDragEnd()"
                        (expressionItemValueChangedEvent)="onExpressionItemValueChanged($event)">
                    </app-order-reports-selected-filters-area>

                    <app-order-reports-selected-fields-area
                        [ngClass]="{'remove':!(hasRunOrderReportFullPermission$ | async)}"
                        [selectedFields]="selectedFields$ | async"
                        [hasRunOrderReportFullPermission]="hasRunOrderReportFullPermission$ | async"
                        [draggedData]="draggedData$ | async"
                        (dragStartEvent)="onDragStart($event)"
                        (dragEndEvent)="onDragEnd()"
                        (droppedFieldItemEvent)="onDroppedFieldItem($event)"
                        (deleteFieldItemEvent)="onDeleteFieldItem($event)">
                    </app-order-reports-selected-fields-area>

                    <div class="buttons_wrapper">
                        <div class="grid-x grid-margin-x">
                            <div class="cell medium-6 text-right">
                                <app-button [buttonType]="'secondary large'"
                                            (clickEvent)="onResetAll()">
                                    Clear
                                </app-button>
                            </div>

                            <div class="cell medium-6">
                                <app-button [buttonType]='"primary large"'
                                            [disabled]="disableCreateReport$ | async"
                                            (clickEvent)="createReportClick()">
                                    Run
                                </app-button>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="(currentSearchData$ | async)">


                        <!-- Loader :: BEGIN -->
                        <div class='loader_wrapper'
                             *ngIf="currentSearchData.loadingReport">

                            <app-loader>
                                Loading Report
                            </app-loader>

                        </div><!-- Loader :: END -->

                        <div class="orderReportTable_wrapper"
                             *ngIf="!currentSearchData.loadingReport">
                            <app-order-reports-table-wrapper
                                (scrollTable)="onSearchForNextReportItems()"
                                [currentSearchData]="currentSearchData$ | async"
                                (showSummaryEvent)="onShowSummary($event)"
                                (generateUrlForExportToXlsxEvent)="onGenerateUrlForExportToXlsx()"
                                (headerSortEvent)="onHeaderSort($event)">
                            </app-order-reports-table-wrapper>
                        </div>

                    </ng-container>
                </div>
            </div>

            <div class="sidebar_wrapper_favorite">
                <app-order-report-sidebar-favorite
                    [openSidebar]="isSidebarFavoriteOpen"
                    [selectedFavoriteId]="selectedFavoriteId$| async"
                    [listOfFavorites]="listOfFavorites$ | async"
                    (sidebarOpenStatusChanged)="onOpenCloseSidebarFavorite()"
                    (selectedFavoriteChangedEvent)="onSelectedFavoriteChanged($event)">
                </app-order-report-sidebar-favorite>
            </div>

        </div><!-- Main Content :: END -->
    </div>
</div>
