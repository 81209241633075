<app-abstract-dialog-container>
   <app-edit-post-bill-type-form
    [editMode]="editMode"
    [postBillType]="postBillType$ | async"
    (submitEvent)="onSubmit($event)"
    (closeEvent)="onClose()">
   </app-edit-post-bill-type-form>
</app-abstract-dialog-container>


