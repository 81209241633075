<div class="app-enter-submission-amounts">
    <div class="user-details-wrapper">
        <h1>Cashout Entry for {{cashCollectedData.cashCollectedForUserDisplayName}}</h1>
        <div class="form-row-wrapper">
            <div *ngFor="let cashCollectedPaymentMethod of cashCollectedData.cashCollectedPaymentMethods" class="order-form-item">
                <label class="order-label">{{getPaymentMethodFriendly(cashCollectedPaymentMethod)}}</label>
                <div class="order-input-wrapper">
                    <div class="input-money" [ngClass]="{'remove': !cashCollectedData.reconciled}">
                        <input
                            class="form-input"
                            type="text"
                            placeholder="0.00"
                            [attr.data-cash-collected-payment-method]="cashCollectedPaymentMethod.paymentMethod"
                            [attr.data-cash-collected-post-bill-type-id]="cashCollectedPaymentMethod.postBillTypeId"
                            (focusout)="amountChanged($event)"
                            value="{{cashCollectedPaymentMethod.usd | number : '1.2-2'}}"/>
                    </div>
                    <p placeholder="$0.00" [ngClass]="{'remove': cashCollectedData.reconciled}">${{cashCollectedPaymentMethod.usd | number : '1.2-2'}}</p>
                </div>

            </div>
            <div class="order-form-item">
                <label class="order-label hide"></label>
                <div class="order-input-wrapper">
                    <app-checkbox
                        [labelName]="'Print after submitting'"
                        [checked]="cashCollectedData.shouldPrintAfterSubmit"
                        (valueChanged)="onPrintAfterSubmitChanged($event)">
                    </app-checkbox>
                </div>
            </div>
            <div class="textarea-row order-form-item">
                 <textarea
                     placeholder="Please enter a reason for the discrepancy"
                     [ngClass]="{'remove': cashCollectedData.reconciled}"
                     [(ngModel)]="cashCollectedData.reason">
                </textarea>
            </div>
            <p class="error">{{errorMessage}}</p>
        </div>
        <div class="dialog-action-buttons-wrapper">
            <div class="mr-16px">
                <app-button
                    [buttonType]="'ghost-button '"
                    (clickEvent)="onCancel()">
                    Cancel
                </app-button>
            </div>
            <div>
                <app-button
                 [buttonType]="'primary'"
                 (clickEvent)="onSubmit()">
                    Submit
                </app-button>
            </div>
        </div>
    </div>
</div>
