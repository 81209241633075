
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TierResponseModel} from "../../../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {TierFormSubmitDataModel} from "../../model/tier-form-submit-data.model";
import {untilDestroyed} from "ngx-take-until-destroy";


@Component({
    selector: 'app-edit-tier-form',
    templateUrl: './edit-tier-form.component.html'
})
export class EditTierFormComponent implements OnInit, OnDestroy, OnChanges {

    @Input() editMode: boolean;
    @Input() tier: TierResponseModel;

    @Output() submitEvent = new EventEmitter<TierFormSubmitDataModel>();
    @Output() closeEvent = new EventEmitter<void>();

    form: FormGroup;

    ageRangeValid: boolean = true;

    constructor() {
    }

    ngOnInit() {

        let description: string;
        let minimumAge: number;
        let maximumAge: number;
        let useAlternateCheckInSound: boolean;
        let manualAcceptanceRequired: boolean;

        if (this.editMode) {
            description = this.tier.description;
            minimumAge = this.tier.minimumAge;
            maximumAge = this.tier.maximumAge;
            useAlternateCheckInSound = this.tier.useAlternateCheckInSound;
            manualAcceptanceRequired = this.tier.manualAcceptanceRequired;
        }

        this.form = new FormGroup({
            description: new FormControl(description, Validators.required),
            minimumAge: new FormControl(minimumAge),
            maximumAge: new FormControl(maximumAge),
            useAlternateCheckInSound: new FormControl(useAlternateCheckInSound),
            manualAcceptanceRequired: new FormControl(manualAcceptanceRequired),
        });

        observableCombineLatest(this.form.controls['minimumAge'].valueChanges, this.form.controls['maximumAge'].valueChanges)
            .pipe(untilDestroyed(this))
            .subscribe(([minimumAge, maximumAge]: [number, number]) => {

                if ((minimumAge && !maximumAge) || (!minimumAge && maximumAge)) {
                    this.ageRangeValid = true;
                }

                if (minimumAge !== null && maximumAge != null) {
                    this.ageRangeValid = minimumAge <= maximumAge;
                }
            });
    }

    ngOnDestroy(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.editMode && changes.tier !== null && Object.keys(changes.tier.currentValue).length !== 0) {

            const tier: TierResponseModel = changes.tier.currentValue;

            this.form.patchValue({
                description: tier.description,
                minimumAge: tier.minimumAge,
                maximumAge: tier.maximumAge,
                useAlternateCheckInSound: tier.useAlternateCheckInSound,
                manualAcceptanceRequired: tier.manualAcceptanceRequired
            });
        }
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        const description: string = this.form.value.description;
        const minimumAge: number = this.form.value.minimumAge;
        const maximumAge: number = this.form.value.maximumAge;
        const useAlternateCheckInSound: boolean = this.form.value.useAlternateCheckInSound;
        const manualAcceptanceRequired: boolean = this.form.value.manualAcceptanceRequired;

        const data: TierFormSubmitDataModel = new TierFormSubmitDataModel(description, minimumAge, maximumAge, useAlternateCheckInSound, manualAcceptanceRequired);

        this.submitEvent.emit(data);
    }
}
