import {Action} from '@ngrx/store';
import {FavoriteResponseModel} from '../../../../../../../core/modules/rest/favorite/response/favorite-response.model';
import {DroppedDataI} from '../../model/dropped-data.model';
import {UpdateExpressionItemValueModel} from '../../model/update-expression-item-value.model';
import {DroppedFieldItemModel} from '../../model/dropped-field-item.model';
import {DraggedDataI} from '../../model/dragged-data.model';
import {HeaderResponseModel, LevelEnum, RowResponseModel} from '../../../../../../../core/modules/rest/reporting/response/report-search-response.model';
import { SortByModel } from '../../../../../../../shared/model/sort-by.model';
import {PaymentMethodSummaryResponse} from "../../../../../../../core/modules/rest/reporting/response/payment-method-summary-response";

export const UPDATE_EXPRESSION_ITEM_VALUE_ACTION = '[Order Reports Main] Update expression item value';
export const DELETE_EXPRESSION_ITEM_BY_UUID_ACTION = '[Order Reports Main] Delete expression item by uuid';
export const ADD_EXPRESSION_ITEM_ACTION = '[Order Reports Main] Add expression item';
export const ADD_FIELD_ACTION = '[Order Reports Main] Add field';
export const DELETE_FIELD_ACTION = '[Order Reports Main] Delete field';
export const UPDATE_DRAGGED_DATA_ACTION = '[Order Reports Main] Update dragged data';
export const UPDATE_USER_FAVORITES_ACTION = '[Order Reports Main] Update favorites';
export const UPDATE_SELECTED_FAVORITE_ACTION = '[Order Reports Main] Update selected favorite';
export const UPDATE_REPORT_ITEMS_SEARCH_TEXT_ACTION = '[Order Reports Main] Update report items search text';
export const SHOW_PAYMENT_SUMMARY = '[Order Reports main] Show payment Summary';
export const DISABLE_SHOW_PAYMENT_SUMMARY = '[Order Reports main] Disable Payment Summary';

export const UPDATE_CURRENT_SEARCH_DATA_SORT_BY_ACTION = '[Order Reports Main] Update current search data - sort by';
export const CREATE_INTIIAL_CURRENT_SEARCH_DATA_ACTION = '[Order Reports Main] Create initial current search data';
export const CREATE_FIRST_RESPONSE_FOR_CURRENT_SEARCH_DATA_ACTION = '[Order Reports Main] Create first response for current search data';
export const LOADING_MORE_ITEMS_FOR_CURRENT_SEARCH_DATA_ACTION = '[Order Reports Main] Loading more items for current search data';
export const ADD_NEXT_RESPONSE_ITEMS_FOR_CURRENT_SEARCH_DATA_ACTION = '[Order Reports Main] Add next response items for current search data';
export const RESET_CURRENT_SEARCH_DATA_ACTION = '[Order Reports Main] Reset current search data';

export const RESET_STATE_ACTION = '[Order Reports Main] Reset state';

export class UpdateExpressionItemValue implements Action {
    readonly type = UPDATE_EXPRESSION_ITEM_VALUE_ACTION;

    constructor(public data: UpdateExpressionItemValueModel) {
    }
}

export class DeleteExpressionItemByUUID implements Action {
    readonly type = DELETE_EXPRESSION_ITEM_BY_UUID_ACTION;

    constructor(public uuid: string) {
    }
}

export class AddExpressionItem implements Action {
    readonly type = ADD_EXPRESSION_ITEM_ACTION;

    constructor(public data: DroppedDataI) {
    }
}

export class AddField implements Action {
    readonly type = ADD_FIELD_ACTION;

    constructor(public data: DroppedFieldItemModel) {
    }
}

export class DeleteField implements Action {
    readonly type = DELETE_FIELD_ACTION;

    constructor(public queryFieldName: string) {
    }
}

export class UpdateDraggedData implements Action {
    readonly type = UPDATE_DRAGGED_DATA_ACTION;

    constructor(public data: DraggedDataI) {
    }
}

export class UpdateUserFavorites implements Action {
    readonly type = UPDATE_USER_FAVORITES_ACTION;

    constructor(public favorites: FavoriteResponseModel[], public favoriteId: number) {
    }
}

export class UpdateSelectedFavorite implements Action {
    readonly type = UPDATE_SELECTED_FAVORITE_ACTION;

    constructor(public favoriteId: number) {
    }
}

export class UpdateReportItemsSearchText implements Action {
    readonly type = UPDATE_REPORT_ITEMS_SEARCH_TEXT_ACTION;

    constructor(public text: string) {
    }
}

export class UpdateCurrentSearchDataSortBy implements Action {
    readonly type = UPDATE_CURRENT_SEARCH_DATA_SORT_BY_ACTION;

    constructor(public sortBy: SortByModel) {
    }
}

export class CreateInitialCurrentSearchData implements Action {
    readonly type = CREATE_INTIIAL_CURRENT_SEARCH_DATA_ACTION;

    constructor() {
    }
}

export class CreateFirstResponseForCurrentSearchData implements Action {
    readonly type = CREATE_FIRST_RESPONSE_FOR_CURRENT_SEARCH_DATA_ACTION;

    constructor(public headers: HeaderResponseModel[], public rows: RowResponseModel[], public level: LevelEnum,
                public approximateNumberOfResults: number, public from: number, public size: number, public groupSearch: boolean,
                public summableFieldsExist: boolean, public scrollId: string, public paymentSummary: PaymentMethodSummaryResponse[]) {
    }
}

export class LoadingMoreItemsForCurrentSearchData implements Action {
    readonly type = LOADING_MORE_ITEMS_FOR_CURRENT_SEARCH_DATA_ACTION;

    constructor() {
    }
}

export class AddNextResponseItemsForCurrentSearchData implements Action {
    readonly type = ADD_NEXT_RESPONSE_ITEMS_FOR_CURRENT_SEARCH_DATA_ACTION;

    constructor(public headers: HeaderResponseModel[], public rows: RowResponseModel[],
                public from: number, public size: number, public scrollId: string) {
    }
}

export class ResetCurrentSearchData implements Action {
    readonly type = RESET_CURRENT_SEARCH_DATA_ACTION;

    constructor() {
    }
}

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class ShowPaymentSummary implements Action {
    readonly type = SHOW_PAYMENT_SUMMARY;

    constructor(public value: boolean) {
    }
}

export class DisableShowPaymentSummary implements Action {
    readonly type = DISABLE_SHOW_PAYMENT_SUMMARY;

    constructor(public value: boolean) {
    }
}


export type Actions =
    UpdateExpressionItemValue |
    DeleteExpressionItemByUUID |
    AddExpressionItem |
    AddField |
    DeleteField |
    UpdateDraggedData |
    UpdateUserFavorites |
    UpdateSelectedFavorite |
    UpdateReportItemsSearchText |
    UpdateCurrentSearchDataSortBy |
    CreateInitialCurrentSearchData |
    CreateFirstResponseForCurrentSearchData |
    LoadingMoreItemsForCurrentSearchData |
    AddNextResponseItemsForCurrentSearchData |
    ResetCurrentSearchData |
    ShowPaymentSummary |
    DisableShowPaymentSummary |
    ResetState;

