<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? 'Edit Cloud Payment Device' : 'Add New Cloud Payment Device' }}</h1>
            </div>
        </div>
    </div>

    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">

        <div *ngIf="!editMode" class="mb-20px error">
            <div>The NMI Payment Device ID and Security Key are critical and one-time inputs.</div>
            <div>Please ensure accuracy when populating the form, as errors may impact payment processing.</div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">NMI Payment Device ID</label>
                        <input class="main--element input-large"
                               [ngClass]="{'disabled': editMode}"
                               type="text"
                               name="Nmi Payment Device Id"
                               formControlName="nmiPaymentDeviceId">
                    </app-input-label-group>
                </div>
            </div>

            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Description</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Description"
                               formControlName="description">
                    </app-input-label-group>
                </div>
            </div>

            <div *ngIf="!editMode" class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Security Key</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Security Key"
                               formControlName="securityKey">
                    </app-input-label-group>
                </div>
            </div>

        </div>

    </div>
    <!-- Dialog Content :: END -->


    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>


            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div>
    <!-- Footer :: END -->

</form>
