import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../services/dialog.sandbox";
import {AppDialogsService} from "../../../../core/services/app-dialogs.service";

@Injectable()
export class PrintOrderDialogSandbox extends DialogSandbox {

    constructor(appDialogsService: AppDialogsService) {
        super(appDialogsService);
    }

    resetState() {
    }
}
