import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditEmailHtmlTemplateDialogComponent} from "./container/edit-email-html-template-dialog.component";
import {EditEmailHtmlTemplateDialogSandbox} from "./sandbox/edit-email-html-template-dialog.sandbox";
import {StoreModule} from "@ngrx/store";
import {REDUCER_NAME, reducer} from "./sandbox/store/reducer";
import {EditEmailHtmlTemplateFormComponent} from './components/edit-email-html-template-form/edit-email-html-template-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditEmailHtmlTemplateDialogSandbox
    ],
    declarations: [
        EditEmailHtmlTemplateDialogComponent,
        EditEmailHtmlTemplateFormComponent
    ],
    exports: [
        EditEmailHtmlTemplateDialogComponent
    ],
    entryComponents: [
        EditEmailHtmlTemplateDialogComponent
    ]
})
export class EditEmailHtmlTemplateDialogModule {

}
