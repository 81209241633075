<app-abstract-dialog-container>
    <app-edit-product-costs-dialog-form
        [productId]="productId"
        [loading]="loading$ | async"
        [productAdditionalCosts]="productAdditionalCosts$ | async"
        [additionalCosts]="additionalCosts$ | async"
        [productIncludedCosts]="productIncludedCosts$ | async"
        [includedCosts]="includedCosts$ | async"
        [productDiscounts]="productDiscounts$ | async"
        [discounts]="discounts$ | async"
        [productAutoAppliedDiscounts]="productAutoAppliedDiscounts$ | async"
        [autoAppliedDiscounts]="autoAppliedDiscounts$ | async"
        (submit)="onSubmit($event)"
        (cancel)="onCancel()">
    </app-edit-product-costs-dialog-form>
</app-abstract-dialog-container>
