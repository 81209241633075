<app-abstract-dialog-container>
    <app-edit-pass-costs-dialog-form
        [passId]="passId"
        [loading]="loading$ | async"
        [passAdditionalCosts]="passAdditionalCosts$ | async"
        [additionalCosts]="additionalCosts$ | async"
        [passIncludedCosts]="passIncludedCosts$ | async"
        [includedCosts]="includedCosts$ | async"
        [passDiscounts]="passDiscounts$ | async"
        [discounts]="discounts$ | async"
        [passAutoAppliedDiscounts]="passAutoAppliedDiscounts$ | async"
        [autoAppliedDiscounts]="autoAppliedDiscounts$ | async"
        (submit)="onSubmit($event)"
        (cancel)="onCancel()">
    </app-edit-pass-costs-dialog-form>
</app-abstract-dialog-container>
