import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {TreeItemTypeEnum} from "../../model/tree-item-type.enum";
import {RoleResponseModel} from "../../../../../../../core/modules/rest/role/response/role-response.model";
import {EditRoleOrUserDialogSandbox} from "./sandbox/edit-role-or-user-dialog.sandbox";
import {Observable} from "rxjs";
import {UserResponseModel} from "../../../../../../../core/modules/rest/user/response/user-response.model";
import {
    SubmitData,
    SubmitRoleData,
    SubmitUserData
} from "./components/edit-role-or-user-dialog-form/edit-role-or-user-dialog-form.component";
import {CreateBaseResponseModel} from "../../../../../../../core/modules/rest/create-base-response.model";

export class EditRoleOrUserDialogData extends DialogData {

    id: number;
    useLoginLocations: boolean;
    type: TreeItemTypeEnum;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, type: TreeItemTypeEnum, id: number, useLoginLocations: boolean) {
        super();
        this.id = id;
        this.useLoginLocations = useLoginLocations;
        this.type = type;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-edit-role-or-user-dialog',
    templateUrl: './edit-role-or-user-dialog.component.html'
})
export class EditRoleOrUserDialogComponent implements OnDestroy {

    id: number;
    useLoginLocations: boolean;
    type: TreeItemTypeEnum;
    successCallbackFn: Function;
    editMode: boolean = false;

    role$: Observable<RoleResponseModel>;
    user$: Observable<UserResponseModel>;
    roles$: Observable<RoleResponseModel[]>;

    constructor(@Inject(DialogData) data: EditRoleOrUserDialogData, private sandbox: EditRoleOrUserDialogSandbox) {

        this.id = data.id;
        this.useLoginLocations = data.useLoginLocations;
        this.type = data.type;
        this.successCallbackFn = data.successCallbackFn;

        if (data.type === TreeItemTypeEnum.USER || data.type === TreeItemTypeEnum.ROLE) {
            this.editMode = true;
        }

        this.sandbox.loadData(this.id, this.type);

        this.role$ = this.sandbox.role$;
        this.user$ = this.sandbox.user$;
        this.roles$ = this.sandbox.roles$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitData) {

        switch (this.type) {

            case TreeItemTypeEnum.ROLE:
                this.sandbox.updateRole(<SubmitRoleData>data).subscribe(() => {
                    this.successCallbackFn((<SubmitUserData>data).roleId);
                    this.sandbox.destroyDialog();
                });
                return;

            case TreeItemTypeEnum.ADD_NEW_ROLE:
                this.sandbox.createRole(<SubmitRoleData>data).subscribe((response: CreateBaseResponseModel) => {
                    this.successCallbackFn(response.id);
                    this.sandbox.destroyDialog();
                });
                return;

            case TreeItemTypeEnum.USER:
                this.sandbox.updateUser(<SubmitUserData>data).subscribe(() => {
                    this.successCallbackFn((<SubmitUserData>data).userId);
                    this.sandbox.destroyDialog();
                });
                return;

            case TreeItemTypeEnum.ADD_NEW_USER:
                this.sandbox.createUser(<SubmitUserData>data).subscribe((response: CreateBaseResponseModel) => {
                    this.successCallbackFn(response.id);
                    this.sandbox.destroyDialog();
                });
                return;
        }
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
