import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared.module";
import {OkDialogComponent} from "./container/ok-dialog.component";
import {Sandbox} from "./sandbox/sandbox";

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        Sandbox
    ],
    exports: [
        OkDialogComponent
    ],
    declarations: [
        OkDialogComponent
    ],
    entryComponents: [
        OkDialogComponent
    ]
})
export class OkDialogModule {

}
