<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? 'Edit Post Bill Type' : 'Add New Post Bill Type' }}</h1>
            </div>
        </div>
    </div>


    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Description</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Description"
                               formControlName="description">
                    </app-input-label-group>
                </div>
            </div>
        </div>


        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Details</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Details"
                               formControlName="details">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <!-- Dialog Content :: BEGIN -->
        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Additional Action</label>
                        <app-dropdown class="main--element"
                              [customClasses]="'dropdown_large'"
                              [preSelectedValue]="selectedPostBillTypeAdditionalAction"
                              [options]='postBillTypeAdditionalActionOptions'
                              (changed)="onSelectedAdditionalAction($event)">
                        </app-dropdown>
                    </app-input-label-group>
                </div>
            </div>
        </div>
        <!-- Dialog Content :: END -->
        
        <div class="cell medium-12 mt-10px">
            <app-checkbox
                [labelName]="'Enable Deposit Account'"
                [checked]="form.value.useDeposit"
                [formControlName]="'useDeposit'">
            </app-checkbox>
        </div>
    </div>
    <!-- Dialog Content :: END -->


    <div class="errorMessage_wrapper"
         *ngIf="errorMessage">
        <label class="error">
            {{ errorMessage }}
        </label>
    </div>


    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>


            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid || saveButtonDisabled"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div>
    <!-- Footer :: END -->
</form>
