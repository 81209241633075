import {Action} from "@ngrx/store";
import {KioskConfigurationResponseModel} from "../../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-response.model";
import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";

export const RESET_STATE_ACTION         = '[Dialog - Create/Edit Kiosk Configuration :: MAIN] Reset State';
export const SET_KIOSK_CONFIGURATION    = '[Dialog - Create/Edit Kiosk Configuration :: MAIN] Set Kiosk Configuration';
export const SET_LOCATIONS              = '[Dialog - Create/Edit Kiosk Configuration :: MAIN] Set Locations';

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class SetKioskConfiguration implements Action {
    readonly type = SET_KIOSK_CONFIGURATION;

    constructor(public kioskConfiguration: KioskConfigurationResponseModel) {
    }
}

export class SetLocations implements Action {
    readonly type = SET_LOCATIONS;

    constructor(public locations: LocationResponseModel[]) {
    }
}

export type All = ResetState | SetKioskConfiguration | SetLocations;
