import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DialogData } from "../../../../../../../../shared/dialogs/model/dialog.data";
import { Observable } from "rxjs";
import { PostBillTypeResponseModel } from "../../../../../../../../core/modules/rest/post-bill-type/response/post-bill-type-response.model";
import { EditPostBillTypeDialogSandbox } from "../sandbox/edit-post-bill-type-dialog.sandbox";
import { YesNoDialogComponent, YesNoDialogData } from '../../../../../../../../shared/dialogs/common-dialogs/yes-no-dialog/container/yes-no-dialog.component';
import { AppDialogsService } from '../../../../../../../../core/services/app-dialogs.service';
import { PostBillTypeFormDepositSubmitData } from '../models/post-bill-type-form-deposit-submit-data';

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class PostBillTypeDepositDialogData extends DialogData {

    postBillTypeId: number;
    addMode: boolean;
    updateCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, postBillTypeId?: number, addMode?: boolean) {
        super();
        this.postBillTypeId = postBillTypeId;
        this.addMode = addMode;
        this.updateCallbackFn = updateCallbackFn;
    }
}


@Component({
    selector: 'app-post-bill-type-deposit-dialog',
    templateUrl: './post-bill-type-deposit-dialog.component.html'
})
export class PostBillTypeDepositDialogComponent implements OnInit, OnDestroy {

    postBillTypeId: number;
    postBillType$: Observable<PostBillTypeResponseModel>;

    addMode: boolean = false;

    updateCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: PostBillTypeDepositDialogData,
        private sandbox: EditPostBillTypeDialogSandbox,
        private appDialogsService: AppDialogsService ) {
        this.postBillTypeId = dialogData.postBillTypeId;
        this.addMode = dialogData.addMode;
        this.updateCallbackFn = dialogData.updateCallbackFn;
    }

    ngOnInit() {
        if (this.postBillTypeId && !isNaN(this.postBillTypeId)) {
            this.sandbox.loadPostBillType(this.postBillTypeId);
            this.postBillType$ = this.sandbox.postBillType$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: PostBillTypeFormDepositSubmitData) {

        if (this.addMode) {
            let dialog: YesNoDialogData = new YesNoDialogData(
                "Add Funds",
                "Are you sure you want add funds?",
                "Cancel",
                "Yes",
                "primaryButton",
                (confirmed: boolean) => {
                    if (confirmed) {
                        this.addFundsToPostBill(data);
                        this.sandbox.destroyDialog();
                        this.sandbox.resetDepositAmount();
                    }
                }
            );

            this.appDialogsService.renderDialog(YesNoDialogComponent, dialog);
        }
        else {
            let dialog: YesNoDialogData = new YesNoDialogData(
                "Substract Funds",
                "Are you sure you want substract funds?",
                "Cancel",
                "Yes",
                "primaryButton",
                (confirmed: boolean) => {
                    if (confirmed) {
                        this.substractFundsfromPostBill(data);
                        this.sandbox.destroyDialog();
                        this.sandbox.resetDepositAmount();
                    }
                }
            );

            this.appDialogsService.renderDialog(YesNoDialogComponent, dialog);
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }

    addFundsToPostBill(data: PostBillTypeFormDepositSubmitData) {
        this.sandbox.addToDeposit(data.postBillTypeId, data.depositAmount).subscribe({
            next: () => {
                this.updateCallbackFn();
            }
        });
    }

    substractFundsfromPostBill(data: PostBillTypeFormDepositSubmitData) {
        this.sandbox.substractFromDeposit(data.postBillTypeId, data.depositAmount).subscribe({
            next: () => {
                this.updateCallbackFn();
            }
        });
    }
}

