import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {DepartureGroupResponseModel} from "../../../../../../../../../core/modules/rest/departure-group/response/departure-group-response.model";

export const REDUCER_NAME = 'dialog_create/edit-departure-group';

export interface State {
    departureGroup: DepartureGroupResponseModel;
}

const initialState: State = {
    departureGroup: {} as DepartureGroupResponseModel
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const departure_group_selector = createSelector(state_selector, (state: State) => state.departureGroup);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_DEPARTURE_GROUP_ACTION: return { ... state, departureGroup: action.departureGroup };

        default: return state;
    }
}
