import {NgModule} from "@angular/core";
import {EditProductEmailHtmlTemplatesDialogComponent} from './edit-product-email-html-templates-dialog.component';
import {EditProductEmailHtmlTemplatesDialogSandbox} from "./sandbox/edit-product-email-html-templates-dialog.sandbox";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {EditProductEmailHtmlTemplatesDialogFormComponent} from './components/edit-product-email-html-templates-dialog-form/edit-product-email-html-templates-dialog-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    declarations: [
        EditProductEmailHtmlTemplatesDialogComponent,
        EditProductEmailHtmlTemplatesDialogFormComponent
    ],
    providers: [
        EditProductEmailHtmlTemplatesDialogSandbox
    ],
    exports: [
        EditProductEmailHtmlTemplatesDialogComponent
    ],
    entryComponents: [
        EditProductEmailHtmlTemplatesDialogComponent
    ]
})
export class EditProductEmailHtmlTemplatesDialogModule {
}
