
import { combineLatest as observableCombineLatest, forkJoin as observableForkJoin, of as observableOf, BehaviorSubject, Observable } from 'rxjs';

import { mergeMap, take, map, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";

import { Store } from "@ngrx/store";
import { RootSandbox } from "../../../../../../../../core/store/root.sandbox";
import { CostRestService } from "../../../../../../../../core/modules/rest/cost/cost-rest.service";
import { LocationResponseModel } from "../../../../../../../../core/modules/rest/location/response/location-response.model";
import { MultiselectDropdownOptionModel } from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

import * as fromFeature from './store/reducer';
import * as fromFeatureActions from './store/actions';

import { AdditionalCostModel } from "../model/additional-cost.model";
import { AdditionalCostResponseModel } from "../../../../../../../../core/modules/rest/cost/response/additional-cost-response.model";
import { HttpErrorModel } from "../../../../../../../../core/modules/rest/http-error.model";
import { CreateCostRequestModel } from "../../../../../../../../core/modules/rest/cost/request/create-cost-request.model";
import { AmountTypeEnum } from "../../../../../../../../shared/enums/amount-type.enum";
import { CostTypeEnum } from "../../../../../../../../shared/enums/cost-type.enum";
import { DialogSandbox } from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import { AppDialogsService } from "../../../../../../../../core/services/app-dialogs.service";
import { UpdateCostRequestModel } from "../../../../../../../../core/modules/rest/cost/request/update-cost-request.model";
import * as actions from "../../../../quick-selling-button-products/dialogs/edit-quick-selling-button-product-dialog/sandbox/store/actions";
import { PlatformEnum } from "../../../../../../../../shared/enums/platform.enum";
import { SystemOptionsRestService } from '../../../../../../../../core/modules/rest/system-options/system-options-rest.service';
import { SystemOptionsResponseModel } from '../../../../../../../../core/modules/rest/system-options/response/system-options-response.model';
import moment from 'moment';

@Injectable()
export class EditAdditionalCostDialogSandbox extends DialogSandbox {

    private errorMessage$: BehaviorSubject<string>;
    array: MultiselectDropdownOptionModel[];


    constructor(appDialogsService: AppDialogsService,
        private store: Store<fromFeature.State>,
        private rootSandbox: RootSandbox,
        private costRestService: CostRestService,
        private systemOptionsRestService: SystemOptionsRestService) {
        super(appDialogsService);
        this.errorMessage$ = new BehaviorSubject<string>(null);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadDialogData(costId: number) {

        this.showLoader();

        const editAdditionalCost = !!costId;

        // Clear error message
        this.errorMessage$.next('');

        // Dialog data loading
        this.store.dispatch(new fromFeatureActions.UpdateDialogDataLoaded(false));

        // Update edit additional cost
        this.store.dispatch(new fromFeatureActions.UpdateEditAdditionalCost(editAdditionalCost));

        // Get additional cost by id
        const getAdditionalCostById_Request = this.costRestService.getAdditionalCostById(costId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading additional cost by id', error);
            }));

        const getAllLocations_request = this.rootSandbox.getAllLocations();

        const editAdditionalCost_request = editAdditionalCost ? getAdditionalCostById_Request : observableOf(null);

        observableForkJoin([getAllLocations_request, editAdditionalCost_request])
            .subscribe((results: [LocationResponseModel[], AdditionalCostResponseModel]) => {

                const allLocations: LocationResponseModel[] = results[0];
                let additionalCost: AdditionalCostResponseModel = results[1];

                this.store.dispatch(new fromFeatureActions.UpdateAllLocations(allLocations));

                if (!additionalCost) {
                    additionalCost = new AdditionalCostModel();
                }

                this.store.dispatch(new fromFeatureActions.UpdateAdditionalCost(additionalCost));

                this.store.dispatch(new fromFeatureActions.UpdateDialogDataLoaded(true));

                this.hideLoader();
            },
                () => this.hideLoader());
    }

    updateAdditionalCostProperty(additionalCost: AdditionalCostModel) {
        this.store.dispatch(new fromFeatureActions.UpdateAdditionalCost(additionalCost));
    }

    getDialogDataLoaded(): Observable<boolean> {
        return this.store.select(fromFeature.dialogDataLoaded_selector);
    }

    getEditAdditionalCost(): Observable<boolean> {
        return this.store.select(fromFeature.editAdditionalCost_selector);
    }

    getAllLocationsForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        return this.store.select(fromFeature.allLocations_selector).pipe(
            map((locations: LocationResponseModel[]) => {
                return locations.map((location: LocationResponseModel) => {
                    return new MultiselectDropdownOptionModel(location.description, location.locationId.toString());
                });
            }));
    }

    getBasePlatformsForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        const array: MultiselectDropdownOptionModel[] = [
            new MultiselectDropdownOptionModel('Android', PlatformEnum.ANDROID),
            new MultiselectDropdownOptionModel('Website', PlatformEnum.WEBSITE),
            new MultiselectDropdownOptionModel('Web Application', PlatformEnum.WEB_APPLICATION),
        ];
        return observableOf(array);
    }

    getAllPlatformsForMultiselectDropdown(hasKiosk: boolean, hasCustomerApplication: boolean): Observable<MultiselectDropdownOptionModel[]> {
        const array: MultiselectDropdownOptionModel[] = [
            new MultiselectDropdownOptionModel('Android', PlatformEnum.ANDROID),
            new MultiselectDropdownOptionModel('Website', PlatformEnum.WEBSITE),
            new MultiselectDropdownOptionModel('Web Application', PlatformEnum.WEB_APPLICATION),
        ];
        if (hasCustomerApplication) {
            array.push(new MultiselectDropdownOptionModel('Customer Application', PlatformEnum.CUSTOMER_APPLICATION));
        }
        if (hasKiosk) {
            array.push(new MultiselectDropdownOptionModel('Kiosk', PlatformEnum.KIOSK));
        }

        return observableOf(array);
    }

    getAdditionalCost(): Observable<AdditionalCostModel> {
        return this.store.select(fromFeature.additionalCost_selector);
    }

    getAmountTypesForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        const array: MultiselectDropdownOptionModel[] = [
            new MultiselectDropdownOptionModel('Percent', AmountTypeEnum.PERCENT),
            new MultiselectDropdownOptionModel('Dollars', AmountTypeEnum.DOLLARS)
        ];

        return observableOf(array);
    }

    getErrorMessage(): Observable<string> {
        return this.errorMessage$.asObservable();
    }

    save(): Observable<boolean> {
        return observableCombineLatest(
            this.getEditAdditionalCost(),
            this.getAdditionalCost()
        ).pipe(
            take(1),
            mergeMap(([editAdditionalCost, additionalCost]) => {
                const editAdditionalCostParameterized: boolean = editAdditionalCost;
                const additionalCostParameterized: AdditionalCostModel = additionalCost;

                let locationIds: number[] = additionalCostParameterized.allLocations ? [] : additionalCostParameterized.locationIds;
                locationIds = locationIds ? locationIds : [];

                let platforms: PlatformEnum[] = additionalCostParameterized.allPlatforms ? [] : additionalCostParameterized.platforms;
                platforms = platforms ? platforms : [];

                // Validate data
                if (!additionalCostParameterized.description || additionalCostParameterized.description.trim().length === 0) {
                    this.errorMessage$.next('Please enter description');
                    return observableOf(false);
                }

                if (additionalCostParameterized.amount === null) {
                    this.errorMessage$.next('Please enter amount');
                    return observableOf(false);
                }

                if (!additionalCostParameterized.amountType) {
                    this.errorMessage$.next('Please select amount type');
                    return observableOf(false);
                }

                // Clear error message
                this.errorMessage$.next('');

                if (editAdditionalCostParameterized) {
                    const data: UpdateCostRequestModel = new UpdateCostRequestModel(
                        additionalCostParameterized.costId,
                        additionalCostParameterized.costType,
                        additionalCostParameterized.description,
                        additionalCostParameterized.amount,
                        additionalCostParameterized.amountType,
                        additionalCostParameterized.isOrderLevel,
                        additionalCostParameterized.glCode,
                        null,
                        additionalCostParameterized.startDateInt ? additionalCostParameterized.startDateInt : null,
                        additionalCostParameterized.endDateInt ? additionalCostParameterized.endDateInt : null,
                        null,
                        null,
                        null,
                        additionalCostParameterized.allLocations,
                        locationIds,
                        additionalCostParameterized.allPlatforms,
                        platforms);

                    return this.costRestService.update(data).pipe(
                        map(() => {
                            return true;
                        }),
                        catchError((error: HttpErrorModel) => {
                            return this.rootSandbox.handleHttpError('Error while updating additional cost', error);
                        }),);
                } else {
                    const data: CreateCostRequestModel = new CreateCostRequestModel(
                        CostTypeEnum.ADDITIONAL,
                        additionalCostParameterized.description,
                        additionalCostParameterized.amount,
                        additionalCostParameterized.amountType,
                        additionalCostParameterized.isOrderLevel,
                        additionalCostParameterized.glCode,
                        null,
                        additionalCostParameterized.startDateInt ? additionalCostParameterized.startDateInt : null,
                        additionalCostParameterized.endDateInt ? additionalCostParameterized.endDateInt : null,
                        null,
                        null,
                        null,
                        additionalCostParameterized.allLocations,
                        locationIds,
                        additionalCostParameterized.allPlatforms,
                        platforms);

                    return this.costRestService.create(data).pipe(
                        map(() => {
                            return true;
                        }),
                        catchError((error: HttpErrorModel) => {
                            return this.rootSandbox.handleHttpError('Error while creating additional cost', error);
                        }),);
                }
            }),);
    }
}
