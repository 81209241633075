<div class="pass-dialog-content">
    <h1 class="mb-8px">{{getHeader()}}</h1>
    <form class="pl-8px pr-8px" [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="order-form-item">
                    <label class="order-label">Start date</label>
                    <div class="order-input-wrapper">
                        <app-datetimepicker
                            class="main--element"
                            [placeholderText]="'Choose Start Date'"
                            formControlName="startDate">
                        </app-datetimepicker>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">End Date</label>
                    <div class="order-input-wrapper">
                        <app-datetimepicker
                            class="main--element"
                            [placeholderText]="'Choose End Date'"
                            formControlName="endDate">
                        </app-datetimepicker>
                    </div>
                </div>
            </div>
        </ng-container>

        <p *ngIf="hasError" class="error">{{ errorMessage }}</p>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Clone</button>
    </div>
</div>
