<div class="pageContent_wrapper">
    <div class="infiniteScroll_container"
         data-table-scroll-container
         #tableScrollContainer
         appScrollDirective [scrollWindow]="false"
         infiniteScroll [infiniteScrollDistance]="scrollDistance" [infiniteScrollThrottle]="scrollThrottle" (scrolled)="onSearchForNextReportItems()">

        <div class="mainContent_wrapper">
            <div class="mainContent_container">

                <div class="row-padding">
                    <div class="mainContentHeader_wrapper active">
                        <div class="title_wrapper">
                            <div class="grid-x grid-margin-x">
                                <div class="cell medium-12 large-2">
                                    <div class="title_container">
                                        <h1>Driver Manifest Report</h1>
                                    </div>
                                </div>

                                <div class="cell medium-12 large-10">
                                    <div class="titleToolbox_wrapper">
                                        <div class="favorites_wrapper">
                                            <app-favorites
                                                [listOfFavorites]="favorites"
                                                [selectedFavoriteId]="selectedFavoriteId"
                                                [expressionItemsListEmpty]="isDisableRun()"
                                                [areEnoughFieldsSelected]="true"
                                                [hasUseFavoritesPermission]="hasUseFavoritesPermission"
                                                [hasCreateFavoritePermission]="hasCreateFavoritePermission"
                                                [hasEditFavoritePermission]="hasEditFavoritePermission"
                                                [hasRemoveFavoritePermission]="hasRemoveFavoritePermission"
                                                (selectedFavoriteChangedEvent)="onSelectedFavoriteChanged($event)"
                                                (editFavoriteEvent)="onEditFavorite($event)"
                                                (removeFavoriteEvent)="onRemoveFavorite($event)">
                                            </app-favorites>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="selectedFiltersArea_wrapper">
                    <div class="selectedFiltersArea row-padding">
                        <app-driver-manifest-report-filter
                            [productDirections]="productDirections"
                            [loadingProducts]="loadingProducts"
                            [selectedFavoriteId]="selectedFavoriteId"
                            [selectedDateType]="selectedDateType"
                            [selectedDate]="selectedDate"
                            [selectedProductDirections]="selectedProductDirections"
                            [favoriteCreatedUpdated]="favoriteCreatedUpdated"
                            (filterChangedEvent)="onFilterChanged($event)">
                        </app-driver-manifest-report-filter>
                    </div>
                </div>

                <div class="buttons_wrapper">
                    <div class="grid-x grid-margin-x">
                        <div class="cell medium-6 text-right">
                            <app-button [buttonType]="'secondary large'"
                                        (clickEvent)="onClearSearchData()">
                                Clear
                            </app-button>
                        </div>

                        <div class="cell medium-6">
                            <app-button [buttonType]='"primary large"'
                                        [disabled]="isDisableRun()"
                                        (clickEvent)="onRunSearch()">
                                Run
                            </app-button>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="currentSearchData">

                    <app-loader *ngIf="currentSearchData && currentSearchData.loadingReport">
                        Loading Report
                    </app-loader>

                    <div class="orderReportTable_wrapper"
                         *ngIf="currentSearchData && !currentSearchData.loadingReport">

                        <app-driver-manifest-report-table-wrapper
                            [currentSearchData]="currentSearchData"
                            [currentSearchDataSortBy]="currentSearchDataSortBy"
                            (generateUrlForExportToXlsxDriverManifestReportEvent)="onGenerateUrlForExportToXlsxDriverManifestReport()"
                            (generateUrlForExportToPdfDriverManifestReportEvent)="onGenerateUrlForExportToPdfDriverManifestReport()"
                            (printDriverManifestReportEvent)="onPrintDriverManifestReport()"
                            (headerSortEvent)="onHeaderSort($event)"
                            (showSummaryEvent)="onShowSummary($event)">
                        </app-driver-manifest-report-table-wrapper>
                    </div>

                </ng-container>

            </div>
        </div><!-- Main Content :: END -->
    </div>
</div>
