import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {PassSimpleResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";

export const REDUCER_NAME = 'dialog_edit-pass-validity';

export interface State {
    pass: PassSimpleResponseModel;
}

const INITIAL_STATE: State = {
    pass: {} as PassSimpleResponseModel
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const pass_selector = createSelector(state_selector, (state: State) => state.pass);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;

        case actions.SET_PASS:
            return {...state, pass: action.pass};

        default:
            return state;
    }
}
