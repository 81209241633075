import {Action} from "@ngrx/store";
import {CompanyInfoResponseModel} from "../../../../../../../../../core/modules/rest/company-info/response/company-info-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Company Info :: MAIN] Reset State';
export const SET_COMPANY_INFO_ACTION = '[Dialog - Create/Edit Company Info :: MAIN] Set Company Info';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetCompanyInfo implements Action {
    readonly type = SET_COMPANY_INFO_ACTION;
    constructor(public companyInfo: CompanyInfoResponseModel) {}
}

export type All = ResetState | SetCompanyInfo;
