<app-abstract-dialog-container>
    <form class="form_container">

        <div class="header_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <h1>{{ (editAutoAppliedDiscount$ | async) ? 'Edit Auto Applied Discount' : 'Add Auto Applied Discount' }}</h1>
                </div>
            </div>
        </div>

        <app-edit-auto-applied-discount-dialog-content
            [autoAppliedDiscount]="autoAppliedDiscount$ | async"
            [allLocations]="allLocations$ | async"
            (autoAppliedDiscountChangedEvent)="onAutoAppliedDiscountChanged($event)">
        </app-edit-auto-applied-discount-dialog-content>

        <div class="errorMessage_wrapper"
             *ngIf="errorMessage$ | async">
            <label class="error">
                {{ errorMessage$ | async }}
            </label>
        </div>

        <!-- Footer :: BEGIN -->
        <div class="footer_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-6 text-right-flex">
                    <app-button
                        [buttonType]='"secondary"'
                        (clickEvent)="onDialogClosed()">
                        Cancel
                    </app-button>
                </div>


                <div class="cell medium-6 text-left-flex">
                    <app-button
                        [buttonType]='"primary"'
                        (clickEvent)="onSubmit()">
                        Save
                    </app-button>
                </div>
            </div>
        </div>
        <!-- Footer :: END -->
    </form>
</app-abstract-dialog-container>

