
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {ProductAvailabilityRestService} from "../../../../../../../../core/modules/rest/product-availability/product-availability-rest.service";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {ProductAvailabilityResponseModel} from "../../../../../../../../core/modules/rest/product-availability/response/product-availability-response.model";
import {SubmitProductAvailabilitiesData} from "../components/edit-product-availabilities-dialog-form/edit-product-availabilities-dialog-form.component";
import {DropdownOptionModel} from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {BidRestService} from "../../../../../../../../core/modules/rest/bid/bid-rest.service";
import {DepartureGroupRestService} from "../../../../../../../../core/modules/rest/departure-group/departure-group-rest.service";
import {LetterGroupRestService} from "../../../../../../../../core/modules/rest/letter-group/letter-group-rest.service";
import {BidResponseModel} from "../../../../../../../../core/modules/rest/bid/response/bid-response.model";
import {DepartureGroupResponseModel} from "../../../../../../../../core/modules/rest/departure-group/response/departure-group-response.model";
import {LetterGroupResponseModel} from "../../../../../../../../core/modules/rest/letter-group/response/letter-group-response.model";
import moment from 'moment';

@Injectable()
export class EditProductAvailabilitiesDialogSandbox extends DialogSandbox {

    productAvailabilities$: Observable<ProductAvailabilityResponseModel[]> = this.store.pipe(select(reducer.productAvailabilities_selector));
    departureGroups$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.departureGroups_selector));
    letterGroups$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.letterGroups_selector));
    bids$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.bids_selector));

    constructor(appDialogsService: AppDialogsService,
                private productAvailabilityRestService: ProductAvailabilityRestService,
                private departureGroupRestService: DepartureGroupRestService,
                private letterGroupRestService: LetterGroupRestService,
                private bidRestService: BidRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(productAvailabilityIds: number[]) {

        observableCombineLatest(this.productAvailabilityRestService.getProductAvailabilitiesByProductAvailabilityIds(productAvailabilityIds), this.departureGroupRestService.getAllActive(), this.letterGroupRestService.getAllActive(), this.bidRestService.getAllActive()).pipe(
            take(1))
            .subscribe(([productAvailabilities, departureGroups, letterGroups, bids]: [ProductAvailabilityResponseModel[], DepartureGroupResponseModel[], LetterGroupResponseModel[], BidResponseModel[]]) => {
                this.store.dispatch(new actions.SetProductAvailabilities(productAvailabilities));
                this.store.dispatch(new actions.SetDepartureGroups(departureGroups.map(dg => new DropdownOptionModel(dg.description, dg.departureGroupId.toString()))));
                this.store.dispatch(new actions.SetLetterGroups(letterGroups.map(lg => new DropdownOptionModel(lg.description, lg.letterGroupId.toString()))));
                this.store.dispatch(new actions.SetBids(bids.map(b => new DropdownOptionModel(b.description, b.bidId.toString()))));
            });
    }

    updateProductAvailabilities(data: SubmitProductAvailabilitiesData): Observable<any> {

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // Check if create or edit
        if (data.isEditMode) {

            // Check if single edit or multiple edit
            if (data.productAvailabilityIds.length > 1) {
                return this.productAvailabilityRestService.updateProductAvailabilities({
                    productAvailabilityIds: data.productAvailabilityIds,
                    productId: data.productId,
                    isReturnTrip: data.isReturnTrip,
                    isReturnTripChanged: data.isReturnTripChanged,
                    startDateMS: data.startDate ? moment.parseZone(data.startDate, timeZone).valueOf() : null,
                    startDateChanged: data.startDateChanged,
                    endDateMS: data.endDate ? moment.parseZone(data.endDate, timeZone).valueOf() : null,
                    endDateChanged: data.endDateChanged,
                    startTime: data.startTime,
                    startTimeChanged: data.startTimeChanged,
                    daysOfWeek: data.daysOfWeek,
                    daysOfWeekChanged: data.daysOfWeekChanged,
                    unlimitedQuantity: data.unlimitedQuantity,
                    unlimitedQuantityChanged: data.unlimitedQuantityChanged,
                    totalQuantity: data.totalQuantity,
                    totalQuantityChanged: data.totalQuantityChanged,
                    departureGroupId: data.departureGroupId,
                    departureGroupIdChanged: data.departureGroupIdChanged,
                    letterGroupId: data.letterGroupId,
                    letterGroupIdChanged: data.letterGroupIdChanged,
                    bidId: data.bidId,
                    bidIdChanged: data.bidIdChanged
                });
            } else {
                return this.productAvailabilityRestService.updateProductAvailability({
                    productAvailabilityId: data.productAvailabilityIds[0],
                    productId: data.productId,
                    description: data.description,
                    isReturnTrip: data.isReturnTrip,
                    startDateMS: data.startDate ? moment.parseZone(data.startDate, timeZone).valueOf() : null,
                    endDateMS: data.endDate ? moment.parseZone(data.endDate, timeZone).valueOf() : null,
                    startTime: data.startTime,
                    daysOfWeek: data.daysOfWeek,
                    unlimitedQuantity: data.unlimitedQuantity,
                    totalQuantity: data.totalQuantity,
                    departureGroupId: data.departureGroupId,
                    letterGroupId: data.letterGroupId,
                    bidId: data.bidId
                });
            }
        } else {
            return this.productAvailabilityRestService.createProductAvailability({
                productId: data.productId,
                description: data.description,
                isReturnTrip: data.isReturnTrip,
                startDateMS: data.startDate ? moment.parseZone(data.startDate, timeZone).valueOf() : null,
                endDateMS: data.endDate ? moment.parseZone(data.endDate, timeZone).valueOf() : null,
                startTime: data.startTime,
                daysOfWeek: data.daysOfWeek,
                unlimitedQuantity: data.unlimitedQuantity,
                totalQuantity: data.totalQuantity,
                departureGroupId: data.departureGroupId,
                letterGroupId: data.letterGroupId,
                bidId: data.bidId
            });
        }
    }
}
