import {Action} from '@ngrx/store';
import {CostTypeEnum} from "../../../../../../../shared/enums/cost-type.enum";


export const PAGE_DATA_LOADED = '[Costs UI] Page Data Loaded';
export const UPDATE_ACTIVE_COST_TYPE_ACTION = '[Costs UI] Update active cost type';
export const UPDATE_SHOW_INACTIVE_ADDITIONAL_COSTS_ACTION = '[Costs UI] Update show inactive additional costs';
export const UPDATE_SHOW_INACTIVE_INCLUDED_COSTS_ACTION = '[Costs UI] Update show inactive included costs';
export const UPDATE_SHOW_INACTIVE_DISCOUNTS_ACTION = '[Costs UI] Update show inactive discounts';
export const UPDATE_SHOW_INACTIVE_AUTO_APPLIED_DISCOUNTS_ACTION = '[Costs UI] Update show inactive auto applied discounts';


export class PageDataLoaded implements Action {
    readonly type = PAGE_DATA_LOADED;

    constructor(public loaded: boolean) {
    }
}

export class UpdateActiveCostType implements Action {
    readonly type = UPDATE_ACTIVE_COST_TYPE_ACTION;

    constructor(public costType: CostTypeEnum) {
    }
}

export class UpdateShowInactiveAdditionalCosts implements Action {
    readonly type = UPDATE_SHOW_INACTIVE_ADDITIONAL_COSTS_ACTION;

    constructor(public show: boolean) {
    }
}

export class UpdateShowInactiveIncludedCosts implements Action {
    readonly type = UPDATE_SHOW_INACTIVE_INCLUDED_COSTS_ACTION;

    constructor(public show: boolean) {
    }
}

export class UpdateShowInactiveDiscounts implements Action {
    readonly type = UPDATE_SHOW_INACTIVE_DISCOUNTS_ACTION;

    constructor(public show: boolean) {
    }
}

export class UpdateShowInactiveAutoAppliedDiscounts implements Action {
    readonly type = UPDATE_SHOW_INACTIVE_AUTO_APPLIED_DISCOUNTS_ACTION;

    constructor(public show: boolean) {
    }
}


export type Actions =
    PageDataLoaded |
    UpdateActiveCostType |
    UpdateShowInactiveAdditionalCosts |
    UpdateShowInactiveIncludedCosts |
    UpdateShowInactiveDiscounts |
    UpdateShowInactiveAutoAppliedDiscounts;
