<!-- When History Section implemented:
                    - remove comments from vertical line in _header-secondary-nav.scss (secondaryNav_container :after)
                    - remove comments from primary nav first empty cell -->

<div class="columns small-2">
    <div class="secondaryNav_column">

        <div class="secondaryNav_label">
            <h2> History </h2>
        </div>


        <ul class="secondaryNav_list">
            <li class="secondaryNav_li">
                <a class="secondaryNav_link">
                    Tickets
                </a>
            </li>

            <li class="secondaryNav_li">
                <a class="secondaryNav_link">
                    Order Report
                </a>
            </li>

            <li class="secondaryNav_li">
                <a class="secondaryNav_link">
                    End of Day
                </a>
            </li>

            <li class="secondaryNav_li">
                <a class="secondaryNav_link">
                    Setup User
                </a>
            </li>

            <li class="secondaryNav_li">
                <a class="secondaryNav_link">
                    Costs
                </a>
            </li>
        </ul>

        <div class="ctaLink_container">
            <a class="ctaLink">
                Clear History
            </a>
        </div>
    </div>
</div>
