
import {catchError, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {OrderSummaryRestService} from '../core/modules/rest/order-summary/order-summary-rest.service';
import {RootSandbox} from '../core/store/root.sandbox';
import {Observable} from 'rxjs';
import {OrderSummaryResponseModel} from '../core/modules/rest/order-summary/response/order-summary-response.model';
import {NotificationAlertModel} from './notifications/notification-alert.model';
import {HttpErrorModel} from '../core/modules/rest/http-error.model';
import {AuthRestService} from '../core/modules/rest/auth/auth-rest.service';
import {UserInfoResponseModel} from '../core/modules/rest/user/response/user-info-response.model';
import {SystemOptionsResponseModel} from "../core/modules/rest/system-options/response/system-options-response.model";
import { OrderSearchHistoryRequestModel } from '../core/modules/rest/order-summary/request/order-search-history-request.model';
import { OrderSearchHistoryResponseModel } from '../core/modules/rest/order-summary/response/order-search-history-response.model';
import { Store } from '@ngrx/store';
import { UserRestService } from '../core/modules/rest/user/user-rest.service';
import * as appReducer from "../core/store/reducers";
import * as userAction from "../core/store/user/actions";
import { ChangePasswordRequest } from '../core/modules/rest/auth/change-password-request.model';
import { ChangePasswordSandbox } from '../shared/dialogs/common-dialogs/change-password-dialog/sandbox/sandbox';
import { NotificationRestService } from '../core/modules/rest/notification/notification-rest.service';
import { NotificationResponseModel } from '../core/modules/rest/notification/response/notification-response.model';


@Injectable()
export class SharedSandbox {

    currentUser$: Observable<UserInfoResponseModel> = this.appStore.select(appReducer.userState_userInfo_selector);

    constructor(private rootSandbox: RootSandbox, private authRestService: AuthRestService,
                private orderSummaryRestService: OrderSummaryRestService,
                private appStore: Store<appReducer.AppState>,
                private userRestService: UserRestService,
                private changePasswordSandbox: ChangePasswordSandbox,
                private notificationRestService: NotificationRestService) {
    }

    logout() {
        this.rootSandbox.logout();

        return this.authRestService.logout().pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while logging out", error);
            }));
    }

    isUserLoggedIn(): Observable<boolean> {
        return this.authRestService.isUserLoggedIn();
    }

    loadUserInfo(): Observable<UserInfoResponseModel> {
        return this.rootSandbox.loadUserInfo();
    }

    loadSystemOptions(): Observable<SystemOptionsResponseModel> {
        return this.rootSandbox.loadSystemOptions();
    }

    webSocketConnect(userId: number, companyCode: string) {
        return this.rootSandbox.webSocketConnect(userId, companyCode);
    }

    webSocketDisconnect() {
        return this.rootSandbox.webSocketDisconnect();
    }

    getUserInfo(): Observable<UserInfoResponseModel> {
        return this.rootSandbox.getUserInfo();
    }

    getAlertIndicator(): Observable<boolean> {
        return this.rootSandbox.getAlertIndicator();
    }

    searchOrderByCriteria(criteria: string): Observable<{orders: OrderSummaryResponseModel[]}> {
        return this.orderSummaryRestService.searchOrders(criteria).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while searching for orders", error);
            }));
    }

    getAllOrderSearchHistoryByUser(userId: number): Observable<{orders: OrderSearchHistoryResponseModel[]}> {
        return this.orderSummaryRestService.getOrderSearchHistoryByUser(userId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while searching for orders", error);
            }));
    }

    getAllNotificationHistoryByUser(userId: number): Observable<NotificationResponseModel[]> {
        return this.notificationRestService.getNotificationHistoryByUser(userId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while searching for notificatons", error);
            }));
    }

    createOrderSearchHistory(request: OrderSearchHistoryRequestModel): Observable<void> {
        return this.orderSummaryRestService.createOrderSearchHistory(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while creating order", error);
            }));
    }

    updateOrderSearchHistoryCreatedDate(orderSearchHistoryId: number): Observable<void> {
        return this.orderSummaryRestService.updateOrderSearchHistoryCreatedDate(orderSearchHistoryId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while creating order", error);
            }));
    }

    deleteOrderSearchHistory(orderSearchHistoryId: number): Observable<void> {
        return this.orderSummaryRestService.deleteOrderSearchHistory(orderSearchHistoryId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while deleting order", error);
            }));
    }

    getNotifications(): Observable<NotificationAlertModel[]> {
        return this.rootSandbox.getNotifications();
    }

    disposeFirstNotification() {
        this.rootSandbox.disposeFirstNotification();
    }

    updateUseTouchScreenLayout(useTouchScreenLayout: boolean): void {
        this.appStore.dispatch(new userAction.SetUserFlagUseTouchScreenLayout(useTouchScreenLayout));

        this.currentUser$.pipe(take(1)).subscribe((currentUser: UserInfoResponseModel) => {
            this.userRestService.updateUserFlags({
                userId: currentUser.userId,
                shouldUpdatePrintOrder: false,
                printOrder: false,
                shouldUpdateShowOrderSummary: false,
                showOrderSummary: false,
                shouldUpdateLocationId: false,
                locationId: null,
                shouldUpdateUseTouchScreenLayout: true,
                useTouchScreenLayout: useTouchScreenLayout
            }).pipe(take(1)).subscribe();

        });
    }

    changePassword(changePasswordRequest: ChangePasswordRequest): void {
        this.changePasswordSandbox.setShowLoader(true);
        this.userRestService.changePassword(changePasswordRequest).pipe(
            catchError((error: HttpErrorModel) => {
                this.changePasswordSandbox.setShowLoader(false);
                return this.rootSandbox.handleHttpError("Error while changing password", error);
            })).subscribe(() => {
                this.changePasswordSandbox.setShowLoader(false);
                this.rootSandbox.addInfoNotification("Password changed successfully!");
                this.changePasswordSandbox.destroyDialog();
            });
    }
}
