<app-abstract-dialog-container>
    <app-add-product-dialog-form
        [categoryId]="categoryId"
        [categories]="categories$ | async"
        [pickupDropoffLocationLists]="pickupDropoffLocationLists$ | async"
        [pickupLocationLists]="pickupLocationLists$ | async"
        [dropoffLocationLists]="dropoffLocationLists$ | async"
        (submit)="onSubmit($event)"
        (cancel)="onCancel()">
    </app-add-product-dialog-form>
</app-abstract-dialog-container>
