import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {UUID} from "angular2-uuid";

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        }
    ]
})
export class CheckboxComponent implements ControlValueAccessor {

    @Input() labelName = '';
    @Input() checked = false;
    @Input() disabled = false;

    @Output() valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() checkedChange: EventEmitter<boolean> = new EventEmitter();

    readonly checkboxId = 'checkbox_' + UUID.UUID().toString();
    private _onChange: (val: boolean) => void;
    private _onTouch: (val: boolean) => void;

    constructor(private cdr: ChangeDetectorRef) {

    }

    onCheckboxValueChanged() {

        this.checked = !this.checked;

        this.checkedChange.emit(this.checked);
        this.valueChanged.emit(this.checked);

        if (this._onChange) {
            this._onChange(this.checked);
        }
    }

    writeValue(value: boolean): void {

        if (value === null || value === undefined) {
            return;
        }

        this.checked = value;

        if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
        }
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }
}
