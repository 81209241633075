import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {RoleResponseModel} from "../../../../../../../../../core/modules/rest/role/response/role-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {UserResponseModel} from "../../../../../../../../../core/modules/rest/user/response/user-response.model";

export const REDUCER_NAME = 'dialog_create/edit-role/user';

export interface State {
    role: RoleResponseModel;
    roles: RoleResponseModel[];
    user: UserResponseModel;
}

const INITIAL_STATE: State = {
    role: {} as RoleResponseModel,
    roles: [],
    user: {} as UserResponseModel
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const role_selector = createSelector(state_selector, (state: State) => state.role);
export const roles_selector = createSelector(state_selector, (state: State) => state.roles);
export const user_selector = createSelector(state_selector, (state: State) => state.user);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;

        case actions.SET_ROLE:
            return {...state, role: action.role};

        case actions.SET_ROLES:
            return {...state, roles: action.roles};

        case actions.SET_USER:
            return {...state, user: action.user};

        default:
            return state;
    }
}
