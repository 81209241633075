import {EnsureModuleLoadedOnceGuard} from '../shared/guards/ensure-module-loaded-once-guard';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {AppRoutesService} from './services/app-routes.service';
import {LocalStorageService} from './services/local-storage.service';
import {RestModule} from './modules/rest/rest.module';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';

import {reducers} from './store/reducers';
import {environment} from '../../environments/environment';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {RootSandbox} from './store/root.sandbox';
import {CustomSerializer} from './store/router/reducer';
import {AppDialogsService} from "./services/app-dialogs.service";
import {metaReducers} from "./store/meta-reducers";
import {AppPrintingService} from "./services/app-printing-service";

@NgModule({
    imports: [
        RestModule,
        StoreModule.forRoot(reducers, {
            metaReducers: metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
              },
        }),
        StoreRouterConnectingModule,
        !environment.production ? StoreDevtoolsModule.instrument() : []
    ],
    providers: [
        AppRoutesService,
        AppDialogsService,
        AppPrintingService,
        LocalStorageService,
        RootSandbox,
        {provide: RouterStateSerializer, useClass: CustomSerializer}
    ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        super(parentModule);
    }
}
