import {Action} from '@ngrx/store';
export const SET_CHANGE_PASSWORD_LOADER_ACTION = '[Change Password] Set show loader';


export class SetChangePasswordLoader implements Action {
    readonly type = SET_CHANGE_PASSWORD_LOADER_ACTION;
    constructor(public showLoader: boolean) {
    }
}

export type Actions = SetChangePasswordLoader
