<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? 'Edit Notification' : 'Add Notification' }}</h1>
            </div>
        </div>
    </div>


    <div *ngIf="!showNotificationSetup" class="content_wrapper content_wrapper_mapping_unevaliable">
        <div class="order-form-item-date" *ngIf="showToDateTime">
            <label class="order-label mt-5px">To Date Time</label>
            <div class="data-picker-width">
                <app-datetimepicker
                    class="main--element"
                    [placeholderText]="'To Date Time'"
                    [timePicker]="true"
                    formControlName="toDateTime"
                    (ngModelChange)="onToDateTimeChange($event)">
                </app-datetimepicker>
                <p *ngIf="validation.toDateTime.error" class="error">{{ validation.toDateTime.label }}</p>
            </div>
        </div>
        <label *ngIf="showToDateTime" class="label mt-5px mb-30px">If an End Date Time is selected, the Notification will expire on that date.</label>

        <div class="formRow mt-10px">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <label class="label bold-text">Preview Notification</label>
                    <div class="previewContent"
                    [innerHTML]="form.value.description"></div>
                </div>
            </div>
        </div>

        <div class="footer_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-6 text-right">
                    <app-button
                        [buttonType]='"secondary"'
                        (clickEvent)="onCancelClick()">
                        Cancel
                    </app-button>
                </div>


                <div class="cell medium-6 text-left">
                    <app-button
                    [buttonType]='"primary"'
                    [disabled]="!saveSentNotification"
                        (clickEvent)="onSubmitClick()">
                        Save
                    </app-button>
                </div>
            </div>
        </div><!-- Footer :: END -->
    </div>


    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper" *ngIf="showNotificationSetup">

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Subject</label>
                        <input class="main--element input-large"
                               type="text"
                               placeholder="Enter Subject"
                               name="Subject"
                               formControlName="subject">
                               <p *ngIf="validation.subject.error" class="error">{{ validation.subject.label }}</p>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow" [ngClass]="{'disabled': editMode}">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Notification Type</label>
                        <app-dropdown class="main--element drop-down-height"
                                name="NotificationType"
                                formControlName="notificationType"
                                [customClasses]="'dropdown_large'"
                                [preSelectedValue]="form.value.notificationType"
                                [options]='notificationOptions'
                                (ngModelChange)="onNotificationTypeChange($event)">
                        </app-dropdown>
                        <p *ngIf="validation.notificationType.error" class="error">{{ validation.notificationType.label }}</p>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow" *ngIf="form.value.notificationType == 'TEXT'">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Description</label>
                        <textarea class="main--element input-large text-area-resize-none scrollable-textarea"
                               type="text"
                               rows="5"
                               name="Description"
                               formControlName="description">
                        </textarea>
                        <p *ngIf="validation.description.error" class="error">{{ validation.description.label }}</p>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow" *ngIf="form.value.notificationType == 'TEMPLATE'">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Templates</label>
                        <app-dropdown class="main--element drop-down-height"
                                name="TemplateId"
                                formControlName="templateId"
                                [customClasses]="'dropdown_large'"
                                [preSelectedValue]="form.value.templateId"
                                [options]='templateOptions'
                                (ngModelChange)="onTemplateChange($event)">
                        </app-dropdown>
                        <p *ngIf="validation.template.error" class="error">{{ validation.template.label }}</p>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow" [ngClass]="{'disabled': editMode}">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Platform</label>
                        <app-dropdown class="main--element drop-down-height"
                                name="Platform"
                                formControlName="platform"
                                [customClasses]="'dropdown_large'"
                                [preSelectedValue]="form.value.platform"
                                [options]='platformOptions'
                                (ngModelChange)="onPlatformChange($event)">
                        </app-dropdown>
                        <p *ngIf="validation.platform.error" class="error">{{ validation.platform.label }}</p>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="order-form-item-date">
            <label class="order-label mt-5px">From Date Time</label>
            <div class="order-input-wrapper">
                <app-datetimepicker
                    class="main--element"
                    [placeholderText]="'From Date Time'"
                    [timePicker]="true"
                    formControlName="fromDateTime">
                </app-datetimepicker>
                <p *ngIf="validation.fromDateTime.error" class="error">{{ validation.fromDateTime.label }}</p>
            </div>
        </div>

        <div class="order-form-item-date">
            <label class="order-label mt-5px">To Date Time</label>
            <div class="order-input-wrapper">
                <app-datetimepicker
                    class="main--element"
                    [placeholderText]="'To Date Time'"
                    [timePicker]="true"
                    formControlName="toDateTime">
                </app-datetimepicker>
                <p *ngIf="validation.toDateTime.error" class="error">{{ validation.toDateTime.label }}</p>
            </div>
        </div>
        <label class="label mt-5px mb-5px">If an End Date Time is selected, the Notification will expire on that date.</label>

        <div class="system-options-item-wrapper mb-10px mt-10px">
            <app-checkbox
                class="checkBoxWrapper"
                [disabled]="baseUserIds.length === 0"
                [labelName]="'Send Email'"
                formControlName="sendEmail">
            </app-checkbox>
        </div>

        <div *ngIf="form.value.platform == 'WEBSITE'" class="cell medium-4">
            <app-input-label-group>
                <label class="label">Customers</label>
                <app-multiselect-dropdown
                    class="main--element max-width-unset"
                    [allOptionEnabled]="true"
                    [preSelectedValues]="baseUserIds"
                    [options]="customerOptions"
                    [loading]="areCustomersLoading"
                    (changed)="onSelectedCustomersChanged($event)">
                </app-multiselect-dropdown>
                </app-input-label-group>
            <label class="label mt-5px">If no Customers are selected, the Notification will be made public.</label>
        </div>

        <div *ngIf="form.value.platform == 'WEB_APPLICATION'" class="cell medium-4">
            <app-input-label-group>
                <label class="label">Users</label>
                <app-multiselect-dropdown
                    class="main--element max-width-unset"
                    [allOptionEnabled]="true"
                    [preSelectedValues]="baseUserIds"
                    [options]="userOptions"
                    [loading]="areUsersLoading"
                    (changed)="onSelectedUsersChanged($event)">
                </app-multiselect-dropdown>
                <p *ngIf="validation.users.error" class="error">{{ validation.users.label }}</p>
            </app-input-label-group>
        </div>

        <div *ngIf="form.value.platform == 'ALL_PLATFORMS'" class="cell medium-4">
            <app-input-label-group>
                <label class="label">Users and Customers</label>
                <app-multiselect-dropdown
                    class="main--element max-width-unset"
                    [allOptionEnabled]="true"
                    [preSelectedValues]="baseUserIds"
                    [options]="baseUserOptions"
                    [loading]="areUsersLoading && areCustomersLoading"
                    (changed)="onSelectedUsersChanged($event)">
                </app-multiselect-dropdown>
                <p *ngIf="validation.users.error" class="error">{{ validation.users.label }}</p>
            </app-input-label-group>
        </div>

    </div><!-- Dialog Content :: END -->


    <div class="errorMessage_wrapper"
         *ngIf="errorMessage">
        <label class="error">
            {{ errorMessage }}
        </label>
    </div>


    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper" *ngIf="showNotificationSetup">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>


            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!isFormValid"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
