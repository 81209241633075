

<div class="table_container">
    <table class="table_striped">

        <thead>
            <th class="tableHeaderColumn fixedWidthCell_s"
                *ngIf = "editColumn">
                {{editColumn}}
            </th>

            <th class="tableHeaderColumn fixedWidthCell_s"
                *ngIf="statusColumn">
                {{statusColumn}}
            </th>



            <th *ngFor = "let header of tableHeaders"
                class="tableHeaderColumn ">
                {{header}}
            </th>
        </thead><!--Head :: END-->






        <tr *ngFor="let row of tableRows"
            (click) = 'activeTableRow(row)'>

            <td class="fixedWidthCell_s"
                *ngIf = "editColumn">
                <a class="icon_container tableIconContainer"
                   (click)="editIconClicked(row)">
                    <i class="fal fa-edit"></i>
                </a>
            </td>

            <td class="fixedWidthCell_s">
                <app-switch
                    (switchValueChanged)="statusValueChanged(row)">
                </app-switch>
            </td>


           <td *ngFor="let td of row">
                {{td}}
           </td>
        </tr><!--Table Body :: END-->

    </table>
</div>
