import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {Observable} from 'rxjs';
import {FilterItemModel} from '../../../../../../model/filter-item.model';
import {MultiselectDropdownOptionModel} from '../../../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model';
import {OrderReportsSandbox} from '../../../../../../store/order-reports.sandbox';



@Component({
    selector: 'app-filter-dropdown-multiselect',
    templateUrl: './filter-dropdown-multiselect.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterDropdownMultiselectComponent implements OnInit, OnChanges {

    @Input() filterItem: FilterItemModel;
    @Input() hasRunOrderReportFullPermission: boolean;
    @Output() changed: EventEmitter<string[]> = new EventEmitter<string[]>();

    dropdownOptions$: Observable<MultiselectDropdownOptionModel[]>;
    hadInitialValue: boolean;

    constructor(private orderReportsSandbox: OrderReportsSandbox) {
    }

    ngOnInit() {
        this.dropdownOptions$ = this.orderReportsSandbox.getFilterOptions(this.filterItem);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterItem && changes.filterItem.firstChange) {
            if (this.filterItem.data) {
                this.hadInitialValue = this.filterItem.data.length !== 0;
            }
        }
    }

    onValuesSelected(values: string[]) {
        this.changed.emit(values);
    }
}


