import {VoidOrderDialogSandbox} from "../sandbox/void-order-dialog.sandbox";
import {OrderPaymentSummaryResponseModel} from "../../../../../core/modules/rest/order-summary/response/order-payment-summary-response.model";
import { Component, Inject, OnDestroy, OnInit  } from "@angular/core";
import {DialogData} from "../../../model/dialog.data";
import {
    getAllStatusReasons as StatusReasonEnum_getAllStatusReasons,
    getDescription as StatusReasonEnum_getDescription,
    StatusReasonEnum
} from "../../../../enums/status-reason.enum";
import {DropdownOptionModel} from "../../../../components/form-elements/dropdown/dropdown-option.model";
import {VoidOrderResponseModel} from "../../../../../core/modules/rest/order-summary/response/void-order-response.model";
import {NumberUtility} from "../../../../utils/number-utility";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class VoidOrderDialogData extends DialogData {

    orderId: number;
    orderPayments: OrderPaymentSummaryResponseModel[];
    hasEmail: boolean;

    constructor(orderId: number, orderPayments: OrderPaymentSummaryResponseModel[], hasEmail: boolean) {
        super();
        this.orderId = orderId;
        this.orderPayments = orderPayments;
        this.hasEmail = hasEmail;
    }
}

// -------------------------------------------------------------------------------------------------
// Smart component that represents void order dialog
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-void-order-dialog',
    templateUrl: './void-order-dialog.component.html'
})
export class VoidOrderDialogComponent implements OnInit, OnDestroy {

    orderId: number;
    orderPayments: OrderPaymentSummaryResponseModel[];
    hasEmail: boolean;

    shouldSendEmailToCustomer: boolean;
    shouldRefundPayments: boolean;

    totalAmount: number;
    refundAmount: number;

    selectedStatusReason: StatusReasonEnum;
    statusReasonOptions: DropdownOptionModel[];

    additionalInfo: string;

    hasError: boolean;
    errorMessage: string;

    private formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });

    constructor(@Inject(DialogData) dialogData: VoidOrderDialogData, private sandbox: VoidOrderDialogSandbox) {

        this.orderId = dialogData.orderId;
        this.orderPayments = dialogData.orderPayments;
        this.hasEmail = dialogData.hasEmail;

        this.shouldSendEmailToCustomer = this.hasEmail;
        this.shouldRefundPayments = true;

        this.totalAmount = 0;
        if (this.orderPayments !== null && this.orderPayments !== undefined && this.orderPayments.length > 0) {
            //this.totalAmount = this.orderPayments.map(op => op.amount).reduce((accumulator, currentValue) => accumulator + currentValue);
            this,this.orderPayments.forEach(payment =>{
                if(payment.refundedAmount > 0){
                    this.totalAmount += payment.revenue;
                }
                else{
                    this.totalAmount += payment.amount
                }
            });
            
        } else {
            this.totalAmount = 0;
        }

        this.refundAmount = parseFloat(this.totalAmount.toFixed(2));

        // Collect all status reasons except RESERVATION_EXPIRED
        this.selectedStatusReason = null;
        this.statusReasonOptions = StatusReasonEnum_getAllStatusReasons()
            .filter((statusReason: StatusReasonEnum) => statusReason !== StatusReasonEnum.RESERVATION_EXPIRED)
            .map((statusReason: StatusReasonEnum) => new DropdownOptionModel(StatusReasonEnum_getDescription(statusReason), statusReason));

        this.additionalInfo = null;

        this.hasError = false;
        this.errorMessage = null;
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    getCellValueForPrintingCurrency(value: number): string {
        return this.formatter.format(value);
    }

    onShouldSendEmailToCustomerChanged(value: boolean) {
        this.shouldSendEmailToCustomer = value;
    }

    onShouldRefundPaymentsChanged(value: boolean) {
        this.shouldRefundPayments = value;
    }

    onSelectedStatusReasonChanged(value: StatusReasonEnum) {
        this.selectedStatusReason = value;
    }

    onCancelClick() {
        this.sandbox.destroyDialog();
    }

    onVoidClick() {

        // Validate data
        this.hasError = false;
        this.errorMessage = null;

        if (this.selectedStatusReason === null || this.selectedStatusReason === undefined) {
            this.hasError = true;
            this.errorMessage = "Please choose voiding reason";
        }

        if (this.shouldRefundPayments) {
            if (this.refundAmount <= Math.abs(NumberUtility.EPSILON)) {
                this.hasError = true;
                this.errorMessage = "Refund amount cannot be 0";
            }
            if (this.refundAmount > this.totalAmount) {
                this.hasError = true;
                this.errorMessage = "Refund amount cannot be greater than total amount";
            }
        }

        // Check if all data are valid
        if (!this.hasError) {

            const shouldIncreaseRemainingQuantities = true;
            const shouldUnusePassCards = true;

            this.sandbox.showLoader();

            // Void order
            this.sandbox.voidOrder(
                this.orderId,
                this.selectedStatusReason,
                this.additionalInfo,
                this.refundAmount,
                this.shouldRefundPayments,
                shouldIncreaseRemainingQuantities,
                shouldUnusePassCards,
                this.shouldSendEmailToCustomer
            ).subscribe((voidOrderResponse: VoidOrderResponseModel) => {

                this.sandbox.hideLoader();

                // Check if void was successful
                if (voidOrderResponse.hasError) {
                    this.hasError = voidOrderResponse.hasError;
                    this.errorMessage = voidOrderResponse.errorMessage;
                } else {
                    this.sandbox.destroyDialog();
                }
            },
            () => this.sandbox.hideLoader());
        }
    }
}
