import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromRoot from '../../../../../../core/store/reducers';

import * as fromMain from './main/reducer';
import * as fromUi from './ui/reducer';

export const REDUCER_NAME = 'feature_kioskConfigurationDetails';

export interface FeatureState {
    ui: fromUi.State;
    main: fromMain.State;
}

export interface State extends fromRoot.AppState {
    REDUCER_NAME: FeatureState;
}

export const reducers: ActionReducerMap<FeatureState> = {
    ui: fromUi.reducer,
    main: fromMain.reducer
};


const kioskConfigurationDetailsState_selector = createFeatureSelector<FeatureState>(REDUCER_NAME);


// ######### MAIN STATE #########
const mainState_selector = createSelector(kioskConfigurationDetailsState_selector, (state: FeatureState) => state.main);

export const mainState_kioskConfiguration_selector = createSelector(mainState_selector, fromMain.kioskConfiguration_selector);
export const mainState_kiosksWithThisAndAllWithoutConfiguration_selector = createSelector(mainState_selector, fromMain.kiosksWithThisAndAllWithoutConfiguration_selector);
export const mainState_kioskConfigurationDropoffLocations_selector = createSelector(mainState_selector, fromMain.kioskConfigurationDropoffLocations_selector);
export const mainState_allLocations_selector = createSelector(mainState_selector, fromMain.allLocations_selector);
export const mainState_allPasses_selector = createSelector(mainState_selector, fromMain.allPasses_selector);
export const mainState_selectedKioskConfigurationDropoffLocationId_selector = createSelector(mainState_selector, fromMain.selectedKioskConfigurationDropoffLocationId_selector);


// ######### UI STATE #########
const uiState_selector = createSelector(kioskConfigurationDetailsState_selector, (state: FeatureState) => state.ui);

export const uiState_pageDataLoaded_selector = createSelector(uiState_selector, fromUi.pageDataLoaded_selector);


