export class UpdateSaleGroupRequestModel {
    saleGroupId: number;
    saleGroupName: string;
    useOwnerTypeGroup: boolean;
    useDomainTypeGroup: boolean;

    constructor( saleGroupId: number, saleGroupName: string, useOwnerTypeGroup: boolean, useDomainTypeGroup: boolean) {
        this.saleGroupId = saleGroupId;
        this.saleGroupName = saleGroupName;
        this.useOwnerTypeGroup = useOwnerTypeGroup;
        this.useDomainTypeGroup = useDomainTypeGroup;
    }
}
