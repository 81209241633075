<app-abstract-dialog-container
    [classList]="'occurrence-manifest-dialog'"
    [shouldOverrideCloseClicked]="true"
    (closeClicked)="onCloseClicked()">
    <app-occurrence-manifest-dialog-form
        [occurrenceTickets]="occurrenceTickets$ | async"
        [currentUser]="currentUser$ | async"
        (printClicked)="onPrintClicked()"
        (emailClicked)="onEmailClicked()"
        (checkInAllClick)="onCheckInAllClick($event)"
        (showInfoMessage)="onShowInfoMessage($event)"
        (showOrderSummaryClicked)="onShowOrderSummaryClicked($event)"
        (doCheckInClicked)="onDoCheckInClicked($event)"
        (undoCheckInClicked)="onUndoCheckInClicked($event)"
        (doNoShowClicked)="onDoNoShowClicked($event)"
        (undoNoShowClicked)="onUndoNoShowClicked($event)">
    </app-occurrence-manifest-dialog-form>
</app-abstract-dialog-container>


