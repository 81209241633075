<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>Create Dropoff Location</h1>
            </div>
        </div>
    </div>

    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <app-input-label-group>
                    <label class="label">Dropoff Location</label>
                    <app-dropdown class="main--element"
                                  [customClasses]="'dropdown_large'"
                                  [options]='allLocationsOptions'
                                  formControlName="dropoffLocation">
                    </app-dropdown>
                </app-input-label-group>
            </div>
        </div>
    </div>
    <!-- Dialog Content :: END -->

    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCloseClick()">
                    Cancel
                </app-button>
            </div>


            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>

        </div>
    </div>
    <!-- Footer :: END -->
</form>
