import {Action} from "@ngrx/store";
import {OccurrenceOrdersResponseModel} from "../../../../../../../../../core/modules/rest/occurrence/response/occurrence-orders-response.model";
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";

export const SET_OCCURRENCES_ORDERS = "[Dialog :: Deactivate Occurrences] Set Occurrences Orders";
export const SET_EMAIL_HTML_TEMPLATES = "[Dialog :: Deactivate Occurrences] Set Email Html Templates";

export class SetOccurrencesOrders implements Action {
    readonly type = SET_OCCURRENCES_ORDERS;

    constructor(public occurrencesOrders: OccurrenceOrdersResponseModel[]) {
    }
}

export class SetEmailHtmlTemplates implements Action {
    readonly type = SET_EMAIL_HTML_TEMPLATES;

    constructor(public emailHtmlTemplates: EmailHtmlTemplateResponseModel[]) {
    }
}

export type All = SetOccurrencesOrders | SetEmailHtmlTemplates;
