
import {catchError, take} from 'rxjs/operators';
import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {EditPassCardsDialogSandbox} from "./sandbox/edit-pass-cards-dialog.sandbox";
import {EditPassCardDataModel} from "../../model/edit-pass-card-data.model";
import {HttpErrorModel} from "../../../../../../../core/modules/rest/http-error.model";
import {RootSandbox} from "../../../../../../../core/store/root.sandbox";

export class EditPassCardsDialogData extends DialogData {

    passCards: EditPassCardDataModel[];
    updateCallbackFn: Function;

    constructor(passCards: EditPassCardDataModel[], updateCallbackFn: Function) {
        super();
        this.passCards = passCards;
        this.updateCallbackFn = updateCallbackFn;
    }
}


@Component({
    selector: 'app-edit-pass-cards-dialog',
    templateUrl: './edit-pass-cards-dialog.component.html'
})
export class EditPassCardsDialogComponent implements OnInit, OnDestroy {

    passCards: EditPassCardDataModel[];

    updateCallbackFn: Function;

    remainingQuantityValue: number = null;

    constructor(@Inject(DialogData) data: EditPassCardsDialogData,
                private sandbox: EditPassCardsDialogSandbox,
                private rootSandbox: RootSandbox) {

        this.passCards = data.passCards;
        this.updateCallbackFn = data.updateCallbackFn;

        if (this.passCards.length !== 0) {

            let firstValue: number = this.passCards[0].remainingQuantity;

            let allSameValues: EditPassCardDataModel[] = this.passCards.filter(epc => epc.remainingQuantity === firstValue);

            if (allSameValues.length === this.passCards.length) {
                this.remainingQuantityValue = firstValue;
            }
        }
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit() {

        // Check remaining quantity
        if (!this.remainingQuantityValue || isNaN(this.remainingQuantityValue) || Number(this.remainingQuantityValue) < 0) {
            return;
        }

        this.sandbox.showLoader();

        this.sandbox.updatePassCards(this.passCards.map(pc => pc.passCardId), Number(this.remainingQuantityValue)).pipe(
            take(1),
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while changing pass card remaining quantity', error);
            }),)
            .subscribe(() => {
                this.sandbox.hideLoader();
                this.updateCallbackFn(Number(this.remainingQuantityValue));
                this.sandbox.destroyDialog();
            },
            () => this.sandbox.hideLoader());
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
