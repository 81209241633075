import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {PricingDateRangeResponseModel} from "../../../../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-response.model";
import {FormControl, FormGroup} from "@angular/forms";
import * as moment from "moment";

export interface SubmitCloneProductPricingDateRangesData {
    productId: number;
    startDate: moment.Moment;
    endDate: moment.Moment;
    pricingDateRangeIds: number[];
}


@Component({
    selector: 'app-clone-product-pricing-date-ranges-dialog-form',
    templateUrl: './clone-product-pricing-date-ranges-dialog-form.component.html'
})

export class CloneProductPricingDateRangesDialogFormComponent implements OnChanges {

    @Input() productId: number;
    @Input() pricingDateRanges: PricingDateRangeResponseModel[];

    @Output() submit: EventEmitter<SubmitCloneProductPricingDateRangesData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        this.form = new FormGroup({
            startDate: new FormControl(),
            endDate: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pricingDateRanges) {
            this.initializeFormData();
        }
    }

    getHeader(): string {
        if (this.pricingDateRanges.length > 1) {
            return "Clone Pricings";
        } else {
            return "Clone Pricing";
        }
    }

    onSubmit() {

        let startDate: moment.Moment = this.form.value.startDate;
        let endDate: moment.Moment = this.form.value.endDate;

        let pricingDateRangeIds: number[] = this.pricingDateRanges.map((pdr: PricingDateRangeResponseModel) => pdr.pricingDateRangeId);

        this.submit.emit({
            productId: this.productId,
            startDate: startDate,
            endDate: endDate,
            pricingDateRangeIds: pricingDateRangeIds
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {

        let startDate: moment.Moment = null;
        let endDate: moment.Moment = null;

        this.form.patchValue({
            startDate: startDate,
            endDate: endDate
        });
    }
}
