import {NgModule} from "@angular/core";
import {EditPassCardsDialogComponent} from "./edit-pass-cards-dialog.component";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditPassCardsDialogSandbox} from "./sandbox/edit-pass-cards-dialog.sandbox";

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        EditPassCardsDialogSandbox
    ],
    declarations: [
        EditPassCardsDialogComponent
    ],
    exports: [
        EditPassCardsDialogComponent
    ],
    entryComponents: [
        EditPassCardsDialogComponent
    ]

})
export class EditPassCardsDialogModule {

}
