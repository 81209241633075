import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {EditLocationListDialogSandbox} from "../sandbox/edit-location-list-dialog.sandbox";
import {Observable} from "rxjs";
import {LocationListFullResponseModel} from "../../../../../../../../core/modules/rest/location-list/response/location-list-full-response.model";
import {LocationListFormSubmitDataModel} from "../model/location-list-form-submit-data.model";
import {DropdownOptionModel} from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditLocationListDialogData extends DialogData {

    locationListId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, locationListId?: number) {
        super();
        this.locationListId = locationListId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
// Component that represents it
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-location-list-dialog',
    templateUrl: './edit-location-list-dialog.component.html'
})
export class EditLocationListDialogComponent implements OnDestroy, OnInit {

    locationList$: Observable<LocationListFullResponseModel>;
    allLocations$: Observable<DropdownOptionModel[]>;
    locationListId: number;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditLocationListDialogData, private sandbox: EditLocationListDialogSandbox) {
        this.locationListId = dialogData.locationListId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {

        if (this.locationListId && !isNaN(this.locationListId)) {
            this.editMode = true;
        }

        this.locationList$ = this.sandbox.locationList$;
        this.allLocations$ = this.sandbox.allLocations$;

        this.sandbox.loadData(this.locationListId);
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: LocationListFormSubmitDataModel) {

        if (this.editMode) {
            this.sandbox.updateLocationList(this.locationListId, data).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createLocationList(data).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}

