import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FavoritesResponseModel} from './response/favorites-response.model';
import {CreateBaseResponseModel} from '../create-base-response.model';
import {CreateFavoriteRequestModel} from './request/create-favorite-request.model';
import {UpdateFavoriteRequestModel} from './request/update-favorite-request.model';
import {FavoriteDescriptionUniqueRequestModel} from '../reporting/request/favorite-description-unique-request.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {FavoriteTypeEnum} from "../../../../shared/enums/favorite-type.enum";
import {FavoriteResponseModel} from "./response/favorite-response.model";

@Injectable()
export class FavoriteRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<FavoritesResponseModel> {
        return this.httpClient.get<FavoritesResponseModel>(environment.server_api_url + '/api/secured/favorite/getAll');
    }

    getById(favoriteId: number): Observable<FavoriteResponseModel> {
        return this.httpClient.get<FavoriteResponseModel>(environment.server_api_url + '/api/secured/favorite/getById/' + favoriteId);
    }

    getActiveFavoritesByCurrentUserAndFavoriteType(favoriteType: FavoriteTypeEnum): Observable<FavoritesResponseModel> {
        return this.httpClient.get<FavoritesResponseModel>(environment.server_api_url + '/api/secured/favorite/getActiveFavoritesByCurrentUserAndFavoriteType/' + favoriteType);
    }

    getActiveFavoritesByCurrentUser(): Observable<FavoritesResponseModel> {
        return this.httpClient.get<FavoritesResponseModel>(environment.server_api_url + '/api/secured/favorite/getActiveFavoritesByCurrentUser');
    }

    isFavoriteDescriptionUnique(favoriteDescriptionUniqueRequest: FavoriteDescriptionUniqueRequestModel): Observable<boolean> {
        return this.httpClient.post<boolean>(environment.server_api_url + '/api/secured/favorite/isFavoriteDescriptionUnique', favoriteDescriptionUniqueRequest);
    }

    create(createFavoriteRequest: CreateFavoriteRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/favorite/create', createFavoriteRequest);
    }

    update(updateFavoriteRequest: UpdateFavoriteRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/favorite/update', updateFavoriteRequest, {responseType: 'text'});
    }

    delete(favoriteId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/favorite/delete', favoriteId, {responseType: 'text'});
    }

    activate(favoriteId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/favorite/activate', favoriteId, {responseType: 'text'});
    }

    deactivate(favoriteId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/favorite/deactivate', favoriteId, {responseType: 'text'});
    }
}
