import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from "moment";
import { FormControl, FormGroup } from '@angular/forms';
import { FilterFormValueModel } from '../../../model/filter-form-value.model';
import { ProductSimpleResponseModel } from '../../../../../../../../core/modules/rest/product/response/product-simple-response.model';
import { Subscription } from 'rxjs';
import { RoleResponseModel } from '../../../../../../../../core/modules/rest/role/response/role-response.model';
import { UserResponseModel } from '../../../../../../../../core/modules/rest/user/response/user-response.model';
import {
    MultiselectDropdownOptionModel,
    OPTION_ALL
} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import { PassSimpleResponseModel } from "../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";


@Component({
    selector: 'app-commission-report-filter',
    templateUrl: './commission-report-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommissionReportFilterComponent implements OnInit, OnDestroy, OnChanges {

    @Input() roles: RoleResponseModel[];
    @Input() loadingRoles: boolean;

    @Input() usersForSelectedRoles: UserResponseModel[];
    @Input() loadingUsers: boolean;

    @Input() passes: PassSimpleResponseModel[];
    @Input() loadingPasses: boolean;

    @Input() products: ProductSimpleResponseModel[];
    @Input() loadingProducts: boolean;

    @Input() favoriteCreatedUpdated: boolean;
    @Input() selectedFavoriteId: number;
    @Input() selectedStartDate: moment.Moment;
    @Input() selectedEndDate: moment.Moment;
    @Input() selectedRoleIds: number[];
    @Input() selectedUserIds: number[];
    @Input() selectedPassIds: number[];
    @Input() selectedProductIds: number[];

    private previousSelectedStartDate: moment.Moment = moment();
    private previousSelectedEndDate: moment.Moment = moment();
    private previousSelectedRoleIds: number[] = [];
    private previousSelectedUserIds: number[] = [];
    private previousSelectedPassIds: number[] = [];
    private previousSelectedProductIds: number[] = [];

    @Output() filterChangedEvent: EventEmitter<FilterFormValueModel> = new EventEmitter();

    filterForm: FormGroup;
    roleOptions: MultiselectDropdownOptionModel[];
    userOptions: MultiselectDropdownOptionModel[];
    passOptions: MultiselectDropdownOptionModel[];
    productOptions: MultiselectDropdownOptionModel[];

    private filterFormChangeSubscription: Subscription;
    private showInactiveProducts: boolean;

    constructor() {
        this.initForm();
    }

    ngOnInit() {

        this.showInactiveProducts = false;
        this.filterFormChangeSubscription = this.filterForm.valueChanges.subscribe((filterFormValue: FilterFormValueModel) => {

            if (filterFormValue.showInactiveProducts != this.showInactiveProducts) {
                this.showInactiveProducts = filterFormValue.showInactiveProducts;
                this.populateDropdownMenu(true, true, true, true);
                filterFormValue.selectedPassIds = [];
                filterFormValue.selectedProductIds = [];
                filterFormValue.selectedRoleIds = [];
                filterFormValue.selectedUserIds = [];
            }

            // 1) Collect selected values (convert strings to numbers)
            // 1.1) Selected role(s)
            // Get list of selected role ids as numbers
            let selectedRoleIds: number[] = filterFormValue.selectedRoleIds
                .filter((selectedRoleId: any) => selectedRoleId !== OPTION_ALL)
                .map((selectedRoleId: any) => {
                    return +selectedRoleId;
                });

            // Update selectedRoleIds with real selected role id numbers
            filterFormValue.selectedRoleIds = selectedRoleIds;

            // 1.2) Selected user(s)
            // Get list of selected user ids as numbers
            let selectedUserIds: number[] = filterFormValue.selectedUserIds
                .filter((selectedUserId: any) => selectedUserId !== OPTION_ALL)
                .map((selectedUserId: any) => {
                    return +selectedUserId;
                });

            // Update selectedUserIds with real selected user id numbers
            filterFormValue.selectedUserIds = selectedUserIds;

            // 1.3) Selected pass(es)
            // Get list of selected pass ids as numbers
            let selectedPassIds: number[] = filterFormValue.selectedPassIds
                .filter((selectedPassId: any) => selectedPassId !== OPTION_ALL)
                .map((selectedPassId: any) => {
                    return +selectedPassId;
                });

            // Update selectedPassIds with real selected pass id numbers
            filterFormValue.selectedPassIds = selectedPassIds;

            // 1.4) Selected product(s)
            // Get list of selected product ids as numbers
            let selectedProductIds: number[] = filterFormValue.selectedProductIds
                .filter((selectedProductId: any) => selectedProductId !== OPTION_ALL)
                .map((selectedProductId: any) => {
                    return +selectedProductId;
                });

            // Update selectedProductIds with real selected product id numbers
            filterFormValue.selectedProductIds = selectedProductIds;

            // 2) Check if filter values actually have changed; if not, do not fire event
            // 2.1) Check if selected roles changed
            let selectedRoleIdsChanged: boolean = false;
            for (let roleId of this.previousSelectedRoleIds) {
                if (filterFormValue.selectedRoleIds.indexOf(roleId) === -1) {
                    selectedRoleIdsChanged = true;
                    break;
                }
            }
            if (!selectedRoleIdsChanged) {
                for (let roleId of filterFormValue.selectedRoleIds) {
                    if (this.previousSelectedRoleIds.indexOf(roleId) === -1) {
                        selectedRoleIdsChanged = true;
                        break;
                    }
                }
            }

            // 2.2) Check if selected users changed
            let selectedUserIdsChanged: boolean = false;
            for (let userId of this.previousSelectedUserIds) {
                if (filterFormValue.selectedUserIds.indexOf(userId) === -1) {
                    selectedUserIdsChanged = true;
                    break;
                }
            }
            if (!selectedUserIdsChanged) {
                for (let userId of filterFormValue.selectedUserIds) {
                    if (this.previousSelectedUserIds.indexOf(userId) === -1) {
                        selectedUserIdsChanged = true;
                        break;
                    }
                }
            }

            // 2.3) Check if selected passes changed
            let selectedPassIdsChanged: boolean = false;
            for (let passId of this.previousSelectedPassIds) {
                if (filterFormValue.selectedPassIds.indexOf(passId) === -1) {
                    selectedPassIdsChanged = true;
                    break;
                }
            }
            if (!selectedPassIdsChanged) {
                for (let passId of filterFormValue.selectedPassIds) {
                    if (this.previousSelectedPassIds.indexOf(passId) === -1) {
                        selectedPassIdsChanged = true;
                        break;
                    }
                }
            }

            // 2.4) Check if selected products changed
            let selectedProductIdsChanged: boolean = false;
            for (let productId of this.previousSelectedProductIds) {
                if (filterFormValue.selectedProductIds.indexOf(productId) === -1) {
                    selectedProductIdsChanged = true;
                    break;
                }
            }
            if (!selectedProductIdsChanged) {
                for (let productId of filterFormValue.selectedProductIds) {
                    if (this.previousSelectedProductIds.indexOf(productId) === -1) {
                        selectedProductIdsChanged = true;
                        break;
                    }
                }
            }

            // If any filter value changed, emit event
            if (((this.previousSelectedStartDate === null && filterFormValue.selectedStartDateEndDate.startDate !== null) || (this.previousSelectedStartDate !== null && filterFormValue.selectedStartDateEndDate.startDate === null)) || this.showInactiveProducts != filterFormValue.showInactiveProducts ||
                ((this.previousSelectedEndDate === null && filterFormValue.selectedStartDateEndDate.endDate !== null) || (this.previousSelectedEndDate !== null && filterFormValue.selectedStartDateEndDate.endDate === null)) ||
                (this.previousSelectedStartDate !== null && filterFormValue.selectedStartDateEndDate.startDate !== null && (this.previousSelectedStartDate.year() !== filterFormValue.selectedStartDateEndDate.startDate.year() || this.previousSelectedStartDate.month() !== filterFormValue.selectedStartDateEndDate.startDate.month() || this.previousSelectedStartDate.date() !== filterFormValue.selectedStartDateEndDate.startDate.date())) ||
                (this.previousSelectedEndDate !== null && filterFormValue.selectedStartDateEndDate.endDate !== null && (this.previousSelectedEndDate.year() !== filterFormValue.selectedStartDateEndDate.endDate.year() || this.previousSelectedEndDate.month() !== filterFormValue.selectedStartDateEndDate.endDate.month() || this.previousSelectedEndDate.date() !== filterFormValue.selectedStartDateEndDate.endDate.date())) ||
                selectedRoleIdsChanged ||
                selectedUserIdsChanged ||
                selectedPassIdsChanged ||
                selectedProductIdsChanged) {

                this.previousSelectedStartDate = filterFormValue.selectedStartDateEndDate.startDate;
                this.previousSelectedEndDate = filterFormValue.selectedStartDateEndDate.endDate;
                this.previousSelectedRoleIds = [...filterFormValue.selectedRoleIds];
                this.previousSelectedUserIds = [...filterFormValue.selectedUserIds];
                this.previousSelectedPassIds = [...filterFormValue.selectedPassIds];
                this.previousSelectedProductIds = [...filterFormValue.selectedProductIds];

                this.filterChangedEvent.emit({
                    selectedStartDateEndDate: { startDate: filterFormValue.selectedStartDateEndDate.startDate, endDate: filterFormValue.selectedStartDateEndDate.endDate },
                    selectedRoleIds: [...filterFormValue.selectedRoleIds],
                    selectedUserIds: [...filterFormValue.selectedUserIds],
                    selectedPassIds: [...filterFormValue.selectedPassIds],
                    selectedProductIds: [...filterFormValue.selectedProductIds],
                    isFavoriteSelected: false,
                    showInactiveProducts: this.showInactiveProducts

                });
            }
        });
    }

    ngOnDestroy() {
        if (this.filterFormChangeSubscription) {
            this.filterFormChangeSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes) {

        this.populateDropdownMenu(changes.roles, changes.usersForSelectedRoles, changes.passes, changes.products);

        // 5) Update filter
        // Set filter to provided values (but don't create new form group!)
        this.previousSelectedStartDate = this.selectedStartDate;
        this.previousSelectedEndDate = this.selectedEndDate;
        this.previousSelectedRoleIds = [...this.selectedRoleIds];
        this.previousSelectedUserIds = [...this.selectedUserIds];
        this.previousSelectedPassIds = [...this.selectedPassIds];
        this.previousSelectedProductIds = [...this.selectedProductIds];

        this.filterForm.patchValue({
            selectedStartDateEndDate: { startDate: this.selectedStartDate, endDate: this.selectedEndDate },
            selectedRoleIds: [...this.selectedRoleIds],
            selectedUserIds: [...this.selectedUserIds],
            selectedPassIds: [...this.selectedPassIds],
            selectedProductIds: [...this.selectedProductIds]
        });

        if (changes.selectedFavoriteId && !changes.favoriteCreatedUpdated) {

            // If not new favorite added, trigger filter changed event with flag isFavoriteSelected = true, in order to refresh UI
            this.filterChangedEvent.emit({
                selectedStartDateEndDate: { startDate: this.selectedStartDate, endDate: this.selectedEndDate },
                selectedRoleIds: [...this.selectedRoleIds],
                selectedUserIds: [...this.selectedRoleIds],
                selectedPassIds: [...this.selectedRoleIds],
                selectedProductIds: [...this.selectedRoleIds],
                isFavoriteSelected: true,
                showInactiveProducts: this.showInactiveProducts
            });
        }
    }

    populateDropdownMenu(populateRoles: boolean, populateUsers: boolean, populatePasses: boolean, populateProducts: boolean) {
        // 1) Selected role
        if (populateRoles) {

            this.roleOptions = this.roles
                .filter((role: RoleResponseModel) => {
                    if (this.filterForm.value.showInactiveProducts || role.active) {
                        return true;
                    }
                    return false;
                })
                .map((role: RoleResponseModel) => {
                    return new MultiselectDropdownOptionModel(role.description, role.roleId.toString());
                });
        }

        // 2) Selected user
        if (populateUsers) {

            this.userOptions = this.usersForSelectedRoles
                .filter((user: UserResponseModel) => {
                    if (this.filterForm.value.showInactiveProducts || user.active) {
                        return true;
                    }
                    return false;
                })
                .map((user: UserResponseModel) => {
                    return new MultiselectDropdownOptionModel(user.displayName, user.userId.toString());
                });
        }

        // 3) Selected pass
        if (populatePasses) {

            this.passOptions = this.passes
                .filter((pass: PassSimpleResponseModel) => {
                    if (this.filterForm.value.showInactiveProducts || pass.active) {
                        return true;
                    }
                    return false;
                })
                .map((pass: PassSimpleResponseModel) => {
                    return new MultiselectDropdownOptionModel(pass.description, pass.passId.toString());
                });
        }

        // 4) Selected product
        if (populateProducts) {

            this.productOptions = this.products
                .filter((product: ProductSimpleResponseModel) => {
                    if (this.filterForm.value.showInactiveProducts || product.active) {
                        return true;
                    }
                    return false;
                })
                .map((product: ProductSimpleResponseModel) => {
                    return new MultiselectDropdownOptionModel(product.description, product.productId.toString());
                });
        }
    }

    clearFilter() {
        // Reset filter to initial state (but don't create new form group!)
        this.filterForm.patchValue({
            selectedStartDateEndDate: { startDate: moment(), endDate: moment() },
            selectedRoleIds: [],
            selectedUserIds: [],
            selectedPassIds: [],
            selectedProductIds: []
        });
    }

    private initForm() {
        this.filterForm = new FormGroup({
            selectedStartDateEndDate: new FormControl({ startDate: moment(), endDate: moment() }),
            selectedRoleIds: new FormControl([]),
            selectedUserIds: new FormControl([]),
            selectedPassIds: new FormControl([]),
            selectedProductIds: new FormControl([]),
            showInactiveProducts: new FormControl(false)
        });
    }
}
