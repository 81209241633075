import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[appMousePointerNear]'
})
export class MousePointerNearDirective {

    @Input() distance: number;
    @Input() disabled: boolean;
    @Output() pointerNearEvent: EventEmitter<boolean> = new EventEmitter();

    private previousValue: boolean;

    constructor(private elRef: ElementRef) {
        this.previousValue = false;
    }

    @HostListener('document:drag', ['$event'])
    @HostListener('document:mousemove', ['$event'])
    onDrag(event: MouseEvent) {
        if (this.disabled) {
            return;
        }

        const mouseX = event.clientX;
        const mouseY = event.clientY;

        const elPosition = this.getPosition();
        const elHeight = this.elRef.nativeElement.offsetHeight;
        const elWidth = this.elRef.nativeElement.offsetWidth;

        const elCenterX = elPosition.left + (elWidth / 2);
        const elCenterY = elPosition.top + (elHeight / 2);

        const dx = Math.max(Math.abs(mouseX - elCenterX) - elWidth / 2, 0);
        const dy = Math.max(Math.abs(mouseY - elCenterY) - elHeight / 2, 0);

        const pointerDistance = Math.sqrt(dx * dx + dy * dy);

        const valueToEmit = pointerDistance < this.distance;

        if (valueToEmit !== this.previousValue) {
            this.previousValue = valueToEmit;
            this.pointerNearEvent.emit(valueToEmit);
        }
    }

    private getPosition() {
        const rect = this.elRef.nativeElement.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }
}
