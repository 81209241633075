import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {PassRestService} from "../../../../../../../../core/modules/rest/pass/pass-rest.service";
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {PassSimpleResponseModel} from "../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {SubmitPassBasicInfoData} from "../components/edit-pass-basic-info-dialog-form/edit-pass-basic-info-dialog-form.component";
import moment from "moment";

@Injectable()
export class EditPassBasicInfoDialogSandbox extends DialogSandbox {

    pass$: Observable<PassSimpleResponseModel> = this.store.pipe(select(reducer.pass_selector));

    constructor(appDialogsService: AppDialogsService,
                private passRestService: PassRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(passId: number) {

        this.passRestService.getPassSimpleById(passId)
            .subscribe((pass: PassSimpleResponseModel) => {
                this.store.dispatch(new actions.SetPass(pass));
            });
    }

    updatePassBasicInfo(data: SubmitPassBasicInfoData): Observable<any> {

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return this.passRestService.updatePassBasicInfo({
            passId: data.passId,
            description: data.description,
            startDateMS: data.startDate ? moment.parseZone(data.startDate, timeZone).valueOf() : null,
            endDateMS: data.endDate ? moment.parseZone(data.endDate, timeZone).valueOf() : null,
            details: data.details,
            purchaseType: data.purchaseType
        });
    }
}
