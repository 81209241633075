<div class="enable-role-dialog">
    <h1 class="mb-16px">Enable role</h1>
    <p class="mb-16px">You are about to re-enable a role, this action will add access to all users.</p>
    <div class="flex-container role-radio-option">
        <div class="radio-input-wrapper" (click)="setType(ALL_USERS)">
            <input type="radio" [checked]="type === ALL_USERS">
            <label>Enable all users</label>
        </div>
    </div>
    <div class="flex-container role-radio-option">
        <div class="radio-input-wrapper" (click)="setType(SOME_USERS)">
            <input type="radio" [checked]="type === SOME_USERS">
            <label>Enable some users</label>
        </div>
        <div>
            <app-multiselect-dropdown
                [disabled]="type !== SOME_USERS"
                [options]="users"
                [preSelectedValues]="selectedUsers"
                (changed)="onUsersSelected($event)"></app-multiselect-dropdown>
        </div>
    </div>
    <div class="flex-container role-radio-option">
        <div class="radio-input-wrapper" (click)="setType(NONE)">
            <input type="radio" [checked]="type === NONE">
            <label>Enable only role without any users in particular</label>
        </div>
    </div>
    <div class="flex-container enable-role-action-buttons-wrapper">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Enable</button>
    </div>
</div>
