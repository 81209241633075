import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {AutoReportResponseModel} from "../../../../../../../../../core/modules/rest/reporting/general/auto-reports/response/auto-report-response.model";
import {AutoReportFormSubmitDataModel} from "../../model/auto-report-form-submit-data.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {AutoReportExportTypeEnum} from "../../../../../../../../../shared/enums/auto-report-export-type.enum";
import * as moment from "moment";
import {DateTimeUtility} from "../../../../../../../../../shared/utils/date-time-utility";
import {UserInfoResponseModel} from "../../../../../../../../../core/modules/rest/user/response/user-info-response.model";


@Component({
    selector: 'app-edit-auto-report-form',
    templateUrl: './edit-auto-report-form.component.html'
})
export class EditAutoReportFormComponent implements OnChanges {

    @Input() editMode: boolean;
    @Input() autoReport: AutoReportResponseModel;
    @Input() favorites: DropdownOptionModel[];
    @Input() currentUserEmail: string;
    @Input() currentUser: UserInfoResponseModel;

    @Output() submitEvent = new EventEmitter<AutoReportFormSubmitDataModel>();
    @Output() closeEvent = new EventEmitter<void>();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    nthOptions: DropdownOptionModel[];
    dayOfWeekOptions: DropdownOptionModel[];
    hourOptions: DropdownOptionModel[];
    exportTypeOptions: DropdownOptionModel[];

    constructor() {

        this.nthOptions = [
            new DropdownOptionModel("Every", "-1"),
            new DropdownOptionModel("1st", "1"),
            new DropdownOptionModel("2nd", "2"),
            new DropdownOptionModel("3rd", "3"),
            new DropdownOptionModel("4th", "4"),
            new DropdownOptionModel("Last", "5"),
        ];

        this.dayOfWeekOptions = [
            new DropdownOptionModel("Day of Month", "-1"),
            new DropdownOptionModel("Sunday", "7"),
            new DropdownOptionModel("Monday", "1"),
            new DropdownOptionModel("Tuesday", "2"),
            new DropdownOptionModel("Wednesday", "3"),
            new DropdownOptionModel("Thursday", "4"),
            new DropdownOptionModel("Friday", "5"),
            new DropdownOptionModel("Saturday", "6")
        ];

        this.hourOptions = [];
        for (let i = 0; i <= 23; i++) {
            if (i === 0) {
                this.hourOptions.push(new DropdownOptionModel("12:00 AM", "0"));
            } else if (i > 0 && i < 12) {
                this.hourOptions.push(new DropdownOptionModel(i + ":00 AM", i.toString()));
            } else if (i === 12) {
                this.hourOptions.push(new DropdownOptionModel("12:00 PM", "12"));
            } else if (i > 12 && i <= 23) {
                this.hourOptions.push(new DropdownOptionModel(i + ":00 PM", i.toString()));
            }
        }

        this.hourOptions = [
            new DropdownOptionModel("12:00 AM", "0"),
            new DropdownOptionModel("1:00 AM", "1"),
            new DropdownOptionModel("2:00 AM", "2"),
            new DropdownOptionModel("3:00 AM", "3"),
            new DropdownOptionModel("4:00 AM", "4"),
            new DropdownOptionModel("5:00 AM", "5"),
            new DropdownOptionModel("6:00 AM", "6"),
            new DropdownOptionModel("7:00 AM", "7"),
            new DropdownOptionModel("8:00 AM", "8"),
            new DropdownOptionModel("9:00 AM", "9"),
            new DropdownOptionModel("10:00 AM", "10"),
            new DropdownOptionModel("11:00 AM", "11"),
            new DropdownOptionModel("12:00 PM", "12"),
            new DropdownOptionModel("1:00 PM", "13"),
            new DropdownOptionModel("2:00 PM", "14"),
            new DropdownOptionModel("3:00 PM", "15"),
            new DropdownOptionModel("4:00 PM", "16"),
            new DropdownOptionModel("5:00 PM", "17"),
            new DropdownOptionModel("6:00 PM", "18"),
            new DropdownOptionModel("7:00 PM", "19"),
            new DropdownOptionModel("8:00 PM", "20"),
            new DropdownOptionModel("9:00 PM", "21"),
            new DropdownOptionModel("10:00 PM", "22"),
            new DropdownOptionModel("11:00 PM", "23")
        ];

        // For now, only Excel export type is supported
        this.exportTypeOptions = [
            new DropdownOptionModel('Excel', AutoReportExportTypeEnum.EXCEL)/*,
            new DropdownOptionModel('PDF', AutoReportExportTypeEnum.PDF),
            new DropdownOptionModel('HTML', AutoReportExportTypeEnum.HTML)*/
        ];

        this.form = new FormGroup({
            favoriteId: new FormControl(),
            description: new FormControl(),
            email: new FormControl(),
            nth: new FormControl(),
            dayOfWeek: new FormControl(),
            hour: new FormControl(),
            startDate: new FormControl(),
            endDate: new FormControl(),
            exportType: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.editMode && changes.autoReport !== null && Object.keys(changes.autoReport.currentValue).length !== 0) {
            this.initializeFormData();
        }
        if (!this.editMode && changes.currentUser && changes.currentUser.currentValue) {
            this.form.patchValue({
                email: this.currentUser.email
            });
        }
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        const favoriteId: number = Number(this.form.value.favoriteId);
        const description: string = this.form.value.description;
        const email: string = this.form.value.email;
        const nth: number = this.form.value.nth;
        const dayOfWeek: number = this.form.value.dayOfWeek;
        const hour: number = this.form.value.hour;
        const startDate: moment.Moment = this.form.value.startDate;
        const endDate: moment.Moment = this.form.value.endDate;
        const exportType: AutoReportExportTypeEnum = this.form.value.exportType;

        if (!favoriteId || isNaN(favoriteId) || favoriteId <= 0) {
            this.hasError = true;
            this.errorMessage = "Please select favorite";
            return;
        }

        if (!description || description.trim().length === 0) {
            this.hasError = true;
            this.errorMessage = "Please enter description";
            return;
        }

        if (!email || email.trim().length === 0) {
            this.hasError = true;
            this.errorMessage = "Please enter email";
            return;
        }

        if (!nth || isNaN(nth)) {
            this.hasError = true;
            this.errorMessage = "Please select frequency";
            return;
        }

        if (!dayOfWeek || isNaN(dayOfWeek)) {
            this.hasError = true;
            this.errorMessage = "Please select day of week";
            return;
        }

        // O is valid value for hour
        // if (!hour || isNaN(hour)) {
        if (hour === null || hour === undefined || isNaN(hour)) {
            this.hasError = true;
            this.errorMessage = "Please select hour";
            return;
        }

        if (!exportType) {
            this.hasError = true;
            this.errorMessage = "Please select export type";
            return;
        }

        this.hasError = false;
        this.errorMessage = null;

        const data: AutoReportFormSubmitDataModel = new AutoReportFormSubmitDataModel(favoriteId, description, email, nth, dayOfWeek, hour, startDate, endDate, exportType);

        this.submitEvent.emit(data);
    }

    private initializeFormData(): void {

        let favoriteId: string = null;
        let description: string = "";
        let email: string = this.currentUserEmail;
        let nth: number = -1;
        let dayOfWeek: number = -1;
        let hour: number = 0;
        let startDate: moment.Moment = null;
        let endDate: moment.Moment = null;
        let exportType: AutoReportExportTypeEnum = AutoReportExportTypeEnum.EXCEL;

        if (this.editMode) {
            favoriteId = this.autoReport.favoriteId.toString();
            description = this.autoReport.description;
            email = this.autoReport.email;
            nth = this.autoReport.nth;
            dayOfWeek = this.autoReport.dayOfWeek;
            hour = this.autoReport.hour;
            startDate = this.autoReport.startDateInt ? DateTimeUtility.convertIntToDate(this.autoReport.startDateInt) : null;
            endDate = this.autoReport.endDateInt ? DateTimeUtility.convertIntToDate(this.autoReport.endDateInt) : null;
            exportType = this.autoReport.exportType;
        }

        this.form.patchValue({
            favoriteId: favoriteId,
            description: description,
            email: email,
            nth: nth,
            dayOfWeek: dayOfWeek,
            hour: hour,
            startDate: startDate,
            endDate: endDate,
            exportType: exportType
        });
    }
}
