import {ReportItemModel} from './report-item.model';
import {FilterItemModel} from './filter-item.model';
import {ReportGroupModel} from './report-group.model';
import {FieldItemModel} from './field-item.model';
import {FilterItemTypeEnum} from "./filter-item-type.enum";
import _ from 'lodash';

export const ORDER_REPORT_PAGE_SIZE = 100;
export const MAX_DEPTH = 2;

// Exporting const that are filters because we need to provide data for them (only for filters that need data)

const REPORT_ITEM_ORDER_EXTERNAL_ID: ReportItemModel = new ReportItemModel(false, true, "Order Number", "orderExternalId", false, false, null);

export const REPORT_ITEM_TIER_HEAD_COUNT: ReportItemModel = new ReportItemModel(true, false, "Tier Head Count", "tierHeadCount", false, false, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_TIER_HEAD_COUNT_ORDER_DETAIL: ReportItemModel = new ReportItemModel(false, true, "Tier Head Count Product", "tierHeadCountOrderDetail", true, false, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_TIER_HEAD_COUNT_PASS: ReportItemModel = new ReportItemModel(false, true, "Tier Head Count Pass", "tierHeadCountPassOrderDetail", true, false, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_TIER_HEAD_COUNT_CHECKIN: ReportItemModel = new ReportItemModel(false, true, "Tier Head Count Check-in Product", "tierHeadCountTicketCheckIn", true, false, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_TIER_HEAD_COUNT_PASS_CARD_CHECKIN: ReportItemModel = new ReportItemModel(false, true, "Tier Head Count Check-in Pass", "tierHeadCountPassCardCheckIn", true, false, FilterItemTypeEnum.DROPDOWN_MULTISELECT);

export const REPORT_ITEM_CATEGORY: ReportItemModel = new ReportItemModel(true, true, "Category", "category", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_PRODUCT: ReportItemModel = new ReportItemModel(true, true, "Product", "product", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_PRODUCT_DIRECTION: ReportItemModel = new ReportItemModel(true, true, "Product-Direction", "productDirection", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
const REPORT_ITEM_DIRECTION: ReportItemModel = new ReportItemModel(false, true, "Direction", "direction", false, true, null);
const REPORT_ITEM_TRIP_NAME_PRODUCT: ReportItemModel = new ReportItemModel(false, true, "Trip Name Product", "tripNameProduct", false, true, null);
const REPORT_ITEM_TRIP_NAME_CHECKIN: ReportItemModel = new ReportItemModel(false, true, "Trip Name Check-in", "tripNameCheckIn", false, true, null);
export const REPORT_ITEM_STATUS: ReportItemModel = new ReportItemModel(true, true, "Status", "orderStatus", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
const REPORT_ITEM_STATUS_REASON: ReportItemModel = new ReportItemModel(false, true, "Status Reason", "statusReason", false, false, null);

const REPORT_ITEM_PRINTING_COUNT: ReportItemModel = new ReportItemModel(false, true, "Printing Count", "printingCount", false, false, null);
const REPORT_ITEM_BUS: ReportItemModel = new ReportItemModel(false, true, "Bus", "bus", false, true, null);
const REPORT_ITEM_DRIVER: ReportItemModel = new ReportItemModel(false, true, "Driver", "driver", false, true, null);

const REPORT_ITEM_PASSENGER_NAME: ReportItemModel = new ReportItemModel(true, true, "Passenger Name", "partyName", false, false, FilterItemTypeEnum.INPUT_STRING);
const REPORT_ITEM_EMAIL: ReportItemModel = new ReportItemModel(true, true, "Email Address", "emailAddress", false, false, FilterItemTypeEnum.INPUT_STRING);
const REPORT_ITEM_PHONE: ReportItemModel = new ReportItemModel(false, true, "Phone Number", "phoneNumber", false, false, null);

const REPORT_ITEM_ORDER_DATE: ReportItemModel = new ReportItemModel(true, true, "Order Date", "createDateTime", false, true, FilterItemTypeEnum.DATE);
const REPORT_ITEM_LAST_UPDATE_DATE: ReportItemModel = new ReportItemModel(true, true, "Last Update Date", "lastUpdateDateTime", false, true, FilterItemTypeEnum.DATE);
const REPORT_ITEM_ORDER_TIME: ReportItemModel = new ReportItemModel(false, true, "Order Time", "orderTime", false, false, null);

const REPORT_ITEM_PAYMENT_DATE: ReportItemModel = new ReportItemModel(true, true, "Payment Date", "paymentDate", false, true, FilterItemTypeEnum.DATE);

const REPORT_ITEM_DEPARTURE_DATE_ORDER_DETAIL: ReportItemModel = new ReportItemModel(true, true, "Departure Date Product", "occurrenceDateOrderDetail", false, true, FilterItemTypeEnum.DATE);
const REPORT_ITEM_DEPARTURE_TIME_ORDER_DETAIL: ReportItemModel = new ReportItemModel(false, true, "Departure Time Product", "occurrenceTimeOrderDetail", false, true, null);

const REPORT_ITEM_DEPARTURE_DATE_TICKET_CHECKIN: ReportItemModel = new ReportItemModel(true, true, "Check-in Date Product", "occurrenceDateTicketCheckIn", false, true, FilterItemTypeEnum.DATE);
const REPORT_ITEM_DEPARTURE_TIME_TICKET_CHECKIN: ReportItemModel = new ReportItemModel(false, true, "Departure Time Check-in", "occurrenceTimeTicketCheckIn", false, true, FilterItemTypeEnum.DATE);
const REPORT_ITEM_CHECKIN_DATE: ReportItemModel = new ReportItemModel(true, true, "Check-in Date Pass", "occurrenceDatePassCardCheckIn", false, true, FilterItemTypeEnum.DATE);

const REPORT_ITEM_TAX_ORDER: ReportItemModel = new ReportItemModel(false, true, "Added Costs/Discounts", "taxOrder", true, false, null);
const REPORT_ITEM_SUB_TOTAL_ORDER: ReportItemModel = new ReportItemModel(false, true, "SubTotal Order", "subTotalOrder", true, false, null);
const REPORT_ITEM_TOTAL_COST_ORDER: ReportItemModel = new ReportItemModel(false, true, "Gross Amount", "totalCostOrder", true, false, null);
const REPORT_ITEM_NET_AMOUNT: ReportItemModel = new ReportItemModel(false, true, "Net Amount", "netAmount", true, false, null);
export const REPORT_ITEM_REFUNDED_AMOUNT: ReportItemModel = new ReportItemModel(false, true, "Refunded Amount", "refundedAmount", true, false, null);

const REPORT_ITEM_TOTAL_ORDER_DETAIL: ReportItemModel = new ReportItemModel(false, true, "Total Cost Product", "totalCostOrderDetail", true, false, null);
const REPORT_ITEM_SUBTOTAL_ORDER_DETAIL: ReportItemModel = new ReportItemModel(false, true, "SubTotal Product", "subTotalOrderDetail", true, false, null);
const REPORT_ITEM_TAX_ORDER_DETAIL: ReportItemModel = new ReportItemModel(false, true, "Tax Product", "taxOrderDetail", true, false, null);

const REPORT_ITEM_TOTAL_PASS_ORDER_DETAIL: ReportItemModel = new ReportItemModel(false, true, "Total Cost Pass", "totalCostPassOrderDetail", true, false, null);
const REPORT_ITEM_SUBTOTAL_PASS_ORDER_DETAIL: ReportItemModel = new ReportItemModel(false, true, "SubTotal Pass", "subTotalPassOrderDetail", true, false, null);
const REPORT_ITEM_TAX_PASS_ORDER_DETAIL: ReportItemModel = new ReportItemModel(false, true, "Tax Pass", "taxPassOrderDetail", true, false, null);

export const REPORT_ITEM_PAYMENT_METHOD: ReportItemModel = new ReportItemModel(true, true, "Payment Method", "paymentMethod", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_PAYMENT_STATUS: ReportItemModel = new ReportItemModel(true, true, "Payment Status", "paymentStatus", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_PAYMENT_AMOUNT: ReportItemModel = new ReportItemModel(false, true, "Payment Amount", "paymentAmount", true, false, null);

export const REPORT_ITEM_SALES_PLATFORM: ReportItemModel = new ReportItemModel(true, true, "Sales Platform", "soldFrom", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_SOLD_BY: ReportItemModel = new ReportItemModel(true, true, "Sold By User", "soldBy", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_ROLE_SOLD_BY: ReportItemModel = new ReportItemModel(true, true, "Role Sold By", "roleSoldBy", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
const REPORT_ITEM_REFERRED_BY: ReportItemModel = new ReportItemModel(false, true, "Referred By", "referredBy", false, true, null);

const REPORT_ITEM_DISCOUNT_CODE_ORDER_DETAIL: ReportItemModel = new ReportItemModel(false, true, "Discount Code(s) Product", "discountCodeOrderDetail", false, false, null);
const REPORT_ITEM_DISCOUNT_CODE_PASS_ORDER_DETAIL: ReportItemModel = new ReportItemModel(false, true, "Discount Code(s) Pass", "discountCodePassOrderDetail", false, false, null);

const REPORT_ITEM_CASH_ACCOUNTED: ReportItemModel = new ReportItemModel(false, true, "Cash Accounted", "cashAccounted", false, true, null);
export const REPORT_ITEM_PURCHASED_MULTIPASS: ReportItemModel = new ReportItemModel(true, true, "Purchased MultiPass", "purchasedMultiPass", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
const REPORT_ITEM_MULTIPASS_TYPE: ReportItemModel = new ReportItemModel(false, true, "MultiPass Type", "multiPassType", false, true, null);
const REPORT_ITEM_MULTIPASS_CARD: ReportItemModel = new ReportItemModel(true, true, "MultiPass Card", "multiPassCard", false, true, FilterItemTypeEnum.INPUT_STRING);

export const REPORT_ITEM_PICKUP_LOCATION_ORDER_DETAIL: ReportItemModel = new ReportItemModel(true, true, "Pickup Location Product", "startLocationOrderDetail", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_DROPOFF_LOCATION_ORDER_DETAIL: ReportItemModel = new ReportItemModel(true, true, "Dropoff Location Product", "stopLocationOrderDetail", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_PICKUP_LOCATION_PASS_ORDER_DETAIL: ReportItemModel = new ReportItemModel(true, true, "Pickup Location Pass", "startLocationPassOrderDetail", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_DROPOFF_LOCATION_PASS_ORDER_DETAIL: ReportItemModel = new ReportItemModel(true, true, "Dropoff Location Pass", "stopLocationPassOrderDetail", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_LOCATION_SOLD: ReportItemModel = new ReportItemModel(true, true, "Location Sold", "soldAtLocation", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);
export const REPORT_ITEM_CHECKIN_LOCATION: ReportItemModel = new ReportItemModel(true, true, "Check-in Location", "checkInLocation", false, true, FilterItemTypeEnum.DROPDOWN_MULTISELECT);

const REPORT_ITEM_CUSTOM_FIELDS_ORDER: ReportItemModel = new ReportItemModel(false, true, "Custom Fields Order", "customFieldsOrder", false, false, null);
const REPORT_ITEM_CUSTOM_FIELDS_ORDER_DETAIL: ReportItemModel = new ReportItemModel(false, true, "Custom Fields Product", "customFieldsOrderDetail", false, false, null);
const REPORT_ITEM_CUSTOM_FIELDS_PASS_ORDER_DETAIL: ReportItemModel = new ReportItemModel(false, true, "Custom Fields Pass", "customFieldsPassOrderDetail", false, false, null);


const REPORT_ITEM_TRANSACTION_ID: ReportItemModel = new ReportItemModel(true, true, "Transaction ID", "transactionID", false, false, FilterItemTypeEnum.INPUT_STRING);

export const REPORTING_ITEM_GROUPS = [
    new ReportGroupModel(
        'Contact Info',
        [   
            REPORT_ITEM_EMAIL,
            REPORT_ITEM_PASSENGER_NAME,
            REPORT_ITEM_PHONE
        ]
    ),
    new ReportGroupModel(
        'Dates',
        [   
            REPORT_ITEM_CHECKIN_DATE,
            REPORT_ITEM_DEPARTURE_DATE_TICKET_CHECKIN,
            REPORT_ITEM_DEPARTURE_DATE_ORDER_DETAIL,
            REPORT_ITEM_DEPARTURE_TIME_ORDER_DETAIL,
            REPORT_ITEM_DEPARTURE_TIME_TICKET_CHECKIN,
            REPORT_ITEM_LAST_UPDATE_DATE,
            REPORT_ITEM_ORDER_DATE,
            REPORT_ITEM_ORDER_TIME,
            REPORT_ITEM_PAYMENT_DATE
        ]
    ),
    new ReportGroupModel(
        'General',
        [   
            REPORT_ITEM_BUS,
            REPORT_ITEM_CATEGORY,
            REPORT_ITEM_CUSTOM_FIELDS_ORDER,
            REPORT_ITEM_CUSTOM_FIELDS_PASS_ORDER_DETAIL,
            REPORT_ITEM_CUSTOM_FIELDS_ORDER_DETAIL,
            REPORT_ITEM_DIRECTION,
            REPORT_ITEM_DRIVER,
            REPORT_ITEM_ORDER_EXTERNAL_ID,
            REPORT_ITEM_PRINTING_COUNT,
            REPORT_ITEM_PRODUCT,
            REPORT_ITEM_STATUS,
            REPORT_ITEM_STATUS_REASON,
            REPORT_ITEM_TIER_HEAD_COUNT,
            REPORT_ITEM_TIER_HEAD_COUNT_CHECKIN,
            REPORT_ITEM_TIER_HEAD_COUNT_PASS_CARD_CHECKIN,
            REPORT_ITEM_TIER_HEAD_COUNT_ORDER_DETAIL,
            REPORT_ITEM_TIER_HEAD_COUNT_PASS, 
            REPORT_ITEM_TRIP_NAME_CHECKIN,
            REPORT_ITEM_TRIP_NAME_PRODUCT
        ]
    ),
    new ReportGroupModel(
        'Financial',
        [
            REPORT_ITEM_TAX_ORDER,
            REPORT_ITEM_CASH_ACCOUNTED,
            REPORT_ITEM_DISCOUNT_CODE_PASS_ORDER_DETAIL,
            REPORT_ITEM_DISCOUNT_CODE_ORDER_DETAIL,
            REPORT_ITEM_TOTAL_COST_ORDER,
            REPORT_ITEM_NET_AMOUNT, 
            REPORT_ITEM_PAYMENT_AMOUNT,
            REPORT_ITEM_PAYMENT_METHOD,
            REPORT_ITEM_PAYMENT_STATUS,
            REPORT_ITEM_REFERRED_BY,
            REPORT_ITEM_REFUNDED_AMOUNT,
            REPORT_ITEM_ROLE_SOLD_BY,
            REPORT_ITEM_SALES_PLATFORM,
            REPORT_ITEM_SOLD_BY,
            REPORT_ITEM_SUB_TOTAL_ORDER,
            REPORT_ITEM_SUBTOTAL_PASS_ORDER_DETAIL,
            REPORT_ITEM_SUBTOTAL_ORDER_DETAIL,  
            REPORT_ITEM_TAX_PASS_ORDER_DETAIL,
            REPORT_ITEM_TAX_ORDER_DETAIL,
            REPORT_ITEM_TOTAL_PASS_ORDER_DETAIL, 
            REPORT_ITEM_TOTAL_ORDER_DETAIL,
            REPORT_ITEM_TRANSACTION_ID
        ]
    ),
    new ReportGroupModel(
        'Locations',
        [
            REPORT_ITEM_CHECKIN_LOCATION,
            REPORT_ITEM_DROPOFF_LOCATION_ORDER_DETAIL,
            REPORT_ITEM_DROPOFF_LOCATION_PASS_ORDER_DETAIL,
            REPORT_ITEM_LOCATION_SOLD,
            REPORT_ITEM_PICKUP_LOCATION_ORDER_DETAIL,
            REPORT_ITEM_PICKUP_LOCATION_PASS_ORDER_DETAIL
        ]
    ),
    new ReportGroupModel(
        'MultiPasses',
        [   
            REPORT_ITEM_MULTIPASS_CARD,
            REPORT_ITEM_MULTIPASS_TYPE, 
            REPORT_ITEM_PURCHASED_MULTIPASS
        ]
    )
];

export function findFieldItemByQueryFieldName(queryFieldName: string): FieldItemModel {
    const reportItem: ReportItemModel = findReportItemByQueryFieldName(queryFieldName);

    if (reportItem) {
        return new FieldItemModel(reportItem.description, reportItem.queryFieldName, reportItem.summable, reportItem.groupable);
    }

    return null;
}

export function findFilterItemByQueryFieldName(queryFieldName: string, data: string[]): FilterItemModel {
    const reportItem: ReportItemModel = findReportItemByQueryFieldName(queryFieldName);

    if (reportItem) {
        return new FilterItemModel(reportItem.description, reportItem.queryFieldName, reportItem.filterType, data);
    }

    return null;
}

export function findReportItemByQueryFieldName(queryFieldName: string): ReportItemModel {
    for (const reportingItemGroup of REPORTING_ITEM_GROUPS) {
        for (const reportItem of reportingItemGroup.items) {
            if (reportItem.queryFieldName === queryFieldName) {
                return _.cloneDeep(reportItem);
            }
        }
    }

    // Workaround: Check items that are not added into reporting item groups
    if (REPORT_ITEM_PRODUCT_DIRECTION.queryFieldName === queryFieldName) {
        return _.cloneDeep(REPORT_ITEM_PRODUCT_DIRECTION);
    }

    return null;
}
