import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PostBillTypeResponseModel } from "../../../../../../../../../core/modules/rest/post-bill-type/response/post-bill-type-response.model";
import { PostBillTypeFormDepositSubmitData } from '../../models/post-bill-type-form-deposit-submit-data';
import { EditPostBillTypeDialogSandbox } from '../../sandbox/edit-post-bill-type-dialog.sandbox';

@Component({
    selector: 'app-post-bill-type-deposit',
    templateUrl: './post-bill-type-deposit.component.html'
})
export class PostBillTypeDepositComponent implements OnInit {

    @Input() addMode: boolean;
    @Input() postBillType: PostBillTypeResponseModel;

    @Output() submitEvent = new EventEmitter<PostBillTypeFormDepositSubmitData>();
    @Output() closeEvent = new EventEmitter<void>();

    form: FormGroup;

    saveButtonDisabled = false;
    errorMessage: string;

    constructor( private editSandbox: EditPostBillTypeDialogSandbox ) {
    }

    ngOnInit() {

        let depositAmount: number;

        this.form = new FormGroup({
            depositAmount: new FormControl('$0.00', Validators.required),
        });

        this.editSandbox.getDepositAmount().subscribe((value) => {
            this.form.get('depositAmount').setValue(value);
        });

    }

    onCancelClick() {
        this.closeEvent.emit();
        this.editSandbox.resetDepositAmount();
    }

    onSubmitClick() {

        this.saveButtonDisabled = true;
        const depositAmountStr = this.form.value.depositAmount.replace(/\$/g, '');
        const depositAmount = parseFloat(depositAmountStr);

        this.editSandbox.setDepositAmount(this.form.value.depositAmount);

        const data: PostBillTypeFormDepositSubmitData = new PostBillTypeFormDepositSubmitData( this.postBillType.postBillTypeId, depositAmount);

        this.submitEvent.emit(data);
    }

    formatCurrency(event: Event) {
        const input = event.target as HTMLInputElement;
        
        let value = input.value.replace(/\$/g, '').replace(/\.00/g, '');
        
        let numericValue = parseFloat(value);
        if (isNaN(numericValue)) {
          numericValue = 0;
        }
        
        const formattedValue = `$${numericValue.toFixed(2)}`;
        input.value = formattedValue;
        
        this.form.get('depositAmount').setValue(formattedValue);
      }
      
}
