import {FilterItemTypeEnum} from "./filter-item-type.enum";

export class FilterItemModel {
    name: string;
    queryFieldName: string;
    type: FilterItemTypeEnum;
    data: string[];

    constructor(name: string, queryFieldName: string, type: FilterItemTypeEnum, data: string[]) {
        this.name = name;
        this.queryFieldName = queryFieldName;
        this.type = type;
        this.data = data;
    }
}
