<div class="grid-x grid-margin-x">

    <!-- Generated Report Message :: BEGIN -->
    <div class="medium-6 large-6 cell">
        <div class="reportStatusMessage_container">
            <p class="reportStatusMessage">{{ displayMessage }}</p>
        </div>
    </div><!-- Generated Report Message :: END -->


    <!-- Reports Menu :: BEGIN -->
    <div class="medium-6 large-6 cell">

        <div class="float-right">
            <div class="toolboxButtons_container">

                <ul class="toolboxButtons_list">
                    <li class="toolboxButtons_li">

                        <a class="icon_button"
                           (click)="exportToExcelClick()">
                            <div class="icon_container">
                                <i class="fal fa-file-excel" aria-hidden="true"></i>
                            </div>
                            Export To Excel
                        </a>

                    </li>

                    <li class="toolboxButtons_li">

                        <a class="icon_button disabled">
                            <div class="icon_container">
                                <i class="fal fa-file-pdf" aria-hidden="true"></i>
                            </div>
                            Export To PDF
                        </a>

                    </li>

                    <li class="toolboxButtons_li">

                        <a class="icon_button disabled">
                            <div class="icon_container">
                                <i class="fal fa-file-image" aria-hidden="true"></i>
                            </div>
                            Download Post Bill Images
                        </a>

                    </li>

                    <li class="toolboxButtons_li">

                        <a class="icon_button disabled">
                            <div class="icon_container">
                                <i class="fal fa-print" aria-hidden="true"></i>
                            </div>
                            Print
                        </a>

                    </li>
                </ul>
            </div>
        </div><!-- Reports Menu :: END -->
    </div>
</div>

