<form
    [formGroup]="filterForm">

    <div class="content_wrapper">

        <div class="row-padding">
            <div class="display-inline-block">
                <app-input-label-group>
                    <label class="label">Date Type</label>
                    <app-dropdown
                        class="main--element"
                        [options]="dateTypeOptions"
                        [formControlName]="'selectedDateType'">
                    </app-dropdown>
                </app-input-label-group>
            </div>

            <div class="display-inline-block" [ngClass]="{'remove': !isDateTypeCustom}">
                <label for="driver-manifest-report-single-day-datepicker" class="label">Date</label>
                <div class="datepicker_wrapper single-day-datepicker">
                    <app-datetimepicker
                        id="driver-manifest-report-single-day-datepicker"
                        [placeholderText]="'Date'"
                        [formControlName]="'selectedDate'">
                    </app-datetimepicker>
                </div>
            </div>
        </div>

        <div class="row-padding">
            <div class="display-inline-block">
                <div class="flex-container">
                    <div class="dropdown-column">
                        <div class="display-inline-block">
                            <app-input-label-group>
                                <label class="label">Product</label>
                                <app-multiselect-dropdown
                                    class="main--element"
                                    [loading]="loadingProducts"
                                    [allOptionEnabled]="true"
                                    [options]="productDirectionOptions"
                                    [sort]="'ASC'"
                                    [formControlName]="'selectedProductDirections'">
                                </app-multiselect-dropdown>
                            </app-input-label-group>
                        </div>
                    </div>
                    <div class="switch-column">
                        <app-switch 
                            [switchLabel]="'Show Inactive'"
                            [formControlName]="'showInactiveProducts'" >
                        </app-switch>
                    </div>
                </div>
            </div>
        </div>
    </div>

</form>
