
import {throwError as observableThrowError, Observable} from 'rxjs';

import {switchMap, catchError} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AppRoutesService} from '../../services/app-routes.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {ErrorResponseModel} from './error-response.model';
import {ErrorCodeEnum} from '../../../shared/enums/error-code.enum';
import {LoginResponseModel} from './auth/login-response.model';
import {AuthRestService} from './auth/auth-rest.service';
import {HttpErrorModel} from './http-error.model';
import {getErrorMessageForCode} from './error-messages.data';

const BEARER = 'Bearer ';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    private authRestService: AuthRestService;

    constructor(inj: Injector, private appRoutesService: AppRoutesService, private localStorageService: LocalStorageService) {

        // Without this circular dependency will occur
        setTimeout(() => {
            this.authRestService = inj.get(AuthRestService);
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const clonedRequest = this.cloneRequest(request);

        return next.handle(clonedRequest).pipe(catchError((errorResponse: HttpErrorResponse) => {
            try {

                if (errorResponse.status === 500) {

                    // Some post methods return response as text, therefore we need to check whether response is json or text
                    const errorResponseModel: ErrorResponseModel = this.isJson(errorResponse.error) ? <ErrorResponseModel>errorResponse.error : JSON.parse(errorResponse.error);

                    if (errorResponseModel.errorCode === ErrorCodeEnum.SMARTSTUBS_ACCESS_TOKEN_EXPIRED) {

                        return this.authRestService.refreshToken().pipe(
                            catchError(
                                (refreshTokenErrorResponse: HttpErrorModel) => {
                                    // Refresh token is not valid (invalid, expired)
                                    if (refreshTokenErrorResponse.errorCode === ErrorCodeEnum.SMARTSTUBS_REFRESH_TOKEN_EXPIRED) {
                                        this.appRoutesService.goToLogoutPage(false);
                                    }
                                    const httpErrorResponse: HttpErrorResponse = new HttpErrorResponse({
                                        error: {
                                            source: refreshTokenErrorResponse.source,
                                            errorCode: refreshTokenErrorResponse.errorCode,
                                            message: refreshTokenErrorResponse.message,
                                            data: refreshTokenErrorResponse.serverMessage
                                        },
                                        status: 500
                                    });
                                    return this.handleError(httpErrorResponse);
                                }
                            ),switchMap(
                                (loginResponse: LoginResponseModel) => {
                                    // Update tokens and retry calling rest endpoint
                                    this.localStorageService.setAccessToken(loginResponse.accessToken);
                                    this.localStorageService.setRefreshToken(loginResponse.refreshToken);

                                    const clonedRequestRepeat = this.cloneRequest(request);
                                    return next.handle(clonedRequestRepeat);
                                }
                            ),);
                    } else if (errorResponseModel.errorCode === ErrorCodeEnum.SMARTSTUBS_UNAUTHORIZED) {
                        // Check if this rest end point is for logging out
                        if (!this.authRestService || this.authRestService.isUserLoggedInUrl() !== errorResponse.url) {
                            this.appRoutesService.goToLogoutPage(false);
                        }
                    }
                }

                return this.handleError(errorResponse);
            } catch (exc) {
                return this.handleError(errorResponse);
            }
        }));
    }

    private isJson(value: any): boolean {
        try {
            JSON.parse(value);
        } catch (e) {
            return true;
        }
        return false;
    }

    private cloneRequest(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                'X-Authorization': BEARER + this.localStorageService.getAccessToken(),
                'X-Authorization-Refresh': BEARER + this.localStorageService.getRefreshToken()
            },
            withCredentials: true
        });
    }

    private handleError(httpErrorResponse: HttpErrorResponse): Observable<any> {

        if (httpErrorResponse.status === 0) {

            return observableThrowError(new HttpErrorModel('FRONTEND', ErrorCodeEnum.FRONT_CONNECTION_LOST_ERROR, '', getErrorMessageForCode(ErrorCodeEnum.FRONT_CONNECTION_LOST_ERROR)));

        } else if (httpErrorResponse.status === 500) {

            const errorResponseModel: ErrorResponseModel = typeof httpErrorResponse.error === 'string' ? <ErrorResponseModel>(JSON.parse(httpErrorResponse.error)) : <ErrorResponseModel>httpErrorResponse.error;

            return observableThrowError(new HttpErrorModel(errorResponseModel.source, errorResponseModel.errorCode, errorResponseModel.message, getErrorMessageForCode(errorResponseModel.errorCode)));

        } else {

            const message = getErrorMessageForCode(ErrorCodeEnum.FRONT_UNKNOWN_ERROR) + ` (HTTP ${httpErrorResponse.status})`;

            return observableThrowError(new HttpErrorModel('FRONTEND', ErrorCodeEnum.FRONT_UNKNOWN_ERROR, httpErrorResponse.message, message));
        }
    }
}
