import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {EnableRoleDialogSandbox} from "./sandbox/enable-role-dialog.sandbox";
import {Observable} from "rxjs";
import {DropdownOptionModel} from "../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {EnableRoleUsersEventData} from "./components/enable-role-dialog-form/enable-role-dialog-form.component";

export class EnableRoleDialogData extends DialogData {
    roleId: number;
    successCallback: Function;

    constructor(roleId: number, successCalback: Function) {
        super();
        this.roleId = roleId;
        this.successCallback = successCalback;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-enable-role-dialog',
    templateUrl: './enable-role-dialog.component.html'
})
export class EnableRoleDialogComponent implements OnDestroy {

    roleId: number;

    successCallback: Function;

    users$: Observable<DropdownOptionModel[]>;

    constructor(@Inject(DialogData) data: EnableRoleDialogData, private sandbox: EnableRoleDialogSandbox) {

        this.roleId = data.roleId;
        this.successCallback = data.successCallback;

        this.sandbox.load(this.roleId);

        this.users$ = this.sandbox.users$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }

    onSubmit(data: EnableRoleUsersEventData) {

        this.sandbox.enableRole(this.roleId, data.type, data.userIds).subscribe(() => {
            this.successCallback();
            this.sandbox.destroyDialog();
        });
    }
}
