import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportSearchRequestModel } from './request/report-search-request.model';
import { ReportSearchResponseModel } from './response/report-search-response.model';
import { ReportSumRequestModel } from './request/report-sum-request.model';
import { ReportSumResponseModel } from './response/report-sum-response.model';
import { AvailableFieldsRequestModel } from './request/available-fields-request.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { OperationsReportRequestModel } from './operational/operations-report/request/operations-report-request.model';
import { OperationsReportResponseModel } from './operational/operations-report/response/operations-report-response.model';
import { OverrideReportRequestModel } from './operational/override-report/request/override-report-request.model';
import { OverrideReportResponseModel } from './operational/override-report/response/override-report-response.model';
import { RevenueReportRequestModel } from './custom/revenue-report/request/revenue-report-request.model';
import { RevenueReportResponseModel } from './custom/revenue-report/response/revenue-report-response.model';
import { TripRevenueReportProductAvailabilityRequestModel } from './financial/trip-revenue-report/request/trip-revenue-report-product-availability-request.model';
import { TripRevenueReportOccurrenceRequestModel } from './financial/trip-revenue-report/request/trip-revenue-report-occurrence-request.model';
import { TripRevenueReportProductAvailabilityResponseModel } from './financial/trip-revenue-report/response/trip-revenue-report-product-availability-response.model';
import { TripRevenueReportOccurrenceResponseModel } from './financial/trip-revenue-report/response/trip-revenue-report-occurrence-response.model';
import { TripRevenueReportProductAvailabilityLocationRequestModel } from './financial/trip-revenue-report/request/trip-revenue-report-product-availability-location-request.model';
import { TripRevenueReportProductAvailabilityLocationResponseModel } from './financial/trip-revenue-report/response/trip-revenue-report-product-availability-location-response.model';
import { TripRevenueReportOccurrenceLocationResponseModel } from './financial/trip-revenue-report/response/trip-revenue-report-occurrence-location-response.model';
import { TripRevenueReportOccurrenceLocationRequestModel } from './financial/trip-revenue-report/request/trip-revenue-report-occurrence-location-request.model';
import { TicketTypeRevenueReportRequestModel } from './financial/ticket-type-revenue-report/request/ticket-type-revenue-report-request.model';
import { TicketTypeRevenueReportResponseModel } from './financial/ticket-type-revenue-report/response/ticket-type-revenue-report-response.model';
import { AvailableFieldsResponseModel } from "./response/available-fields-response.model";
import { NetSuiteExportReportRequestModel } from "./custom/net-suite-export-report/request/net-suite-export-report-request.model";
import { CommissionReportRequestModel } from "./financial/commission-report/request/commission-report-request.model";
import { CommissionReportResponseModel } from "./financial/commission-report/response/commission-report-response.model";
import { DriverManifestReportRequestModel } from "./operational/driver-manifest-report/request/driver-manifest-report-request.model";
import { DriverManifestReportResponseModel } from "./operational/driver-manifest-report/response/driver-manifest-report-response.model";
import { CollectedAndEarnedRevenueReportRequestModel } from "./financial/collected-and-earned-revenue-report/request/collected-and-earned-revenue-report-request.model";
import { CollectedAndEarnedRevenueReportResponseModel } from "./financial/collected-and-earned-revenue-report/response/collected-and-earned-revenue-report-response.model";
import { AuditLogReportResponseModel } from './general/audit-log-report/response/audit-log-report-response.model';
import { AuditLogReportRequestModel } from './general/audit-log-report/request/audit-log-report-request.model';
import { InvalidScanReportRequestModel } from './hardware/invalid-scan-report/request/invalid-scan-report-request.model';
import { InvalidScanReportResponseModel } from './hardware/invalid-scan-report/response/invalid-scan-report-response.model';
import { AndroidDebugLogsRequestModel } from './hardware/android-debug-logs/request/android-debug-logs-request.model';
import { CustomBillingReportRequestModel } from './custom/custom-billing-report/request/custom-billing-report-request.model.ts';
import { CustomBillingReportResponseModel } from './custom/custom-billing-report/response/custom-billing-report-response.model';

@Injectable()
export class ReportingRestService {

    constructor(private httpClient: HttpClient) {
    }

    // Order reports :: BEGIN
    searchOrderReports(reportSearchRequest: ReportSearchRequestModel): Observable<ReportSearchResponseModel> {
        return this.httpClient.post<ReportSearchResponseModel>(environment.server_api_url + '/api/secured/reporting/searchOrderReports', JSON.stringify(reportSearchRequest));
    }

    groupOrderReports(reportSearchRequest: ReportSearchRequestModel): Observable<ReportSearchResponseModel> {
        return this.httpClient.post<ReportSearchResponseModel>(environment.server_api_url + '/api/secured/reporting/groupOrderReports', JSON.stringify(reportSearchRequest));
    }

    sum(reportSumRequest: ReportSumRequestModel): Observable<ReportSumResponseModel> {
        return this.httpClient.post<ReportSumResponseModel>(environment.server_api_url + '/api/secured/reporting/sum', JSON.stringify(reportSumRequest));
    }

    getAvailableFields(availableFieldsRequestModel: AvailableFieldsRequestModel): Observable<AvailableFieldsResponseModel> {
        return this.httpClient.post<AvailableFieldsResponseModel>(environment.server_api_url + '/api/secured/reporting/getAvailableFields', JSON.stringify(availableFieldsRequestModel));
    }

    generateUrlForExportToXlsx(reportSearchRequest: ReportSearchRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsx', JSON.stringify(reportSearchRequest), { responseType: 'text' });
    }
    // Order reports :: END

    // Audit log report :: BEGIN
    getAuditLogReport(auditLogReportRequest: AuditLogReportRequestModel): Observable<AuditLogReportResponseModel> {
        return this.httpClient.post<AuditLogReportResponseModel>(environment.server_api_url + '/api/secured/reporting/getAuditLogReport', auditLogReportRequest);
    }

    generateUrlForExportToXlsxAuditLogReport(auditLogReportRequest: AuditLogReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsxAuditLogReport', auditLogReportRequest, { responseType: 'text' });
    }
    // Audit log report :: END

    // Operations report :: BEGIN
    getOperationsReport(operationsReportRequest: OperationsReportRequestModel): Observable<OperationsReportResponseModel> {
        return this.httpClient.post<OperationsReportResponseModel>(environment.server_api_url + '/api/secured/reporting/getOperationsReport', operationsReportRequest);
    }

    generateUrlForExportToXlsOperationsReport(operationsReportRequest: OperationsReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsOperationsReport', operationsReportRequest, { responseType: 'text' });
    }

    generateUrlForExportToPdfOperationsReport(operationsReportRequest: OperationsReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToPdfOperationsReport', operationsReportRequest, { responseType: 'text' });
    }
    // Operations report :: END

    // Override report :: BEGIN
    getOverrideReport(overrideReportRequest: OverrideReportRequestModel): Observable<OverrideReportResponseModel> {
        return this.httpClient.post<OverrideReportResponseModel>(environment.server_api_url + '/api/secured/reporting/getOverrideReport', overrideReportRequest);
    }

    generateUrlForExportToXlsxOverrideReport(overrideReportRequest: OverrideReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsxOverrideReport', overrideReportRequest, { responseType: 'text' });
    }
    // Override report :: END

    // Driver manifest report :: BEGIN
    getDriverManifestReport(driverManifestReportRequest: DriverManifestReportRequestModel): Observable<DriverManifestReportResponseModel> {
        return this.httpClient.post<DriverManifestReportResponseModel>(environment.server_api_url + '/api/secured/reporting/getDriverManifestReport', driverManifestReportRequest);
    }

    generateUrlForExportToXlsxDriverManifestReport(driverManifestReportRequest: DriverManifestReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsxDriverManifestReport', driverManifestReportRequest, { responseType: 'text' });
    }

    generateUrlForExportToPdfDriverManifestReport(driverManifestReportRequest: DriverManifestReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToPdfDriverManifestReport', driverManifestReportRequest, { responseType: 'text' });
    }
    // Driver manifest report :: END

    // Trip revenue report :: BEGIN
    getTripRevenueReportProductAvailability(tripRevenueReportProductAvailabilityRequest: TripRevenueReportProductAvailabilityRequestModel): Observable<TripRevenueReportProductAvailabilityResponseModel> {
        return this.httpClient.post<TripRevenueReportProductAvailabilityResponseModel>(environment.server_api_url + '/api/secured/reporting/getTripRevenueReportProductAvailability', tripRevenueReportProductAvailabilityRequest);
    }

    generateUrlForExportToXlsxTripRevenueReportProductAvailability(tripRevenueReportProductAvailabilityRequest: TripRevenueReportProductAvailabilityRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsxTripRevenueReportProductAvailability', tripRevenueReportProductAvailabilityRequest, { responseType: 'text' });
    }

    getTripRevenueReportProductAvailabilityLocation(tripRevenueReportProductAvailabilityLocationRequest: TripRevenueReportProductAvailabilityLocationRequestModel): Observable<TripRevenueReportProductAvailabilityLocationResponseModel> {
        return this.httpClient.post<TripRevenueReportProductAvailabilityLocationResponseModel>(environment.server_api_url + '/api/secured/reporting/getTripRevenueReportProductAvailabilityLocation', tripRevenueReportProductAvailabilityLocationRequest);
    }

    generateUrlForExportToXlsxTripRevenueReportProductAvailabilityLocation(tripRevenueReportProductAvailabilityLocationRequest: TripRevenueReportProductAvailabilityLocationRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsxTripRevenueReportProductAvailabilityLocation', tripRevenueReportProductAvailabilityLocationRequest, { responseType: 'text' });
    }

    getTripRevenueReportOccurrence(tripRevenueReportOccurrenceRequest: TripRevenueReportOccurrenceRequestModel): Observable<TripRevenueReportOccurrenceResponseModel> {
        return this.httpClient.post<TripRevenueReportOccurrenceResponseModel>(environment.server_api_url + '/api/secured/reporting/getTripRevenueReportOccurrence', tripRevenueReportOccurrenceRequest);
    }

    generateUrlForExportToXlsxTripRevenueReportOccurrence(tripRevenueReportOccurrenceRequest: TripRevenueReportOccurrenceRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsxTripRevenueReportOccurrence', tripRevenueReportOccurrenceRequest, { responseType: 'text' });
    }

    getTripRevenueReportOccurrenceLocation(tripRevenueReportOccurrenceLocationRequest: TripRevenueReportOccurrenceLocationRequestModel): Observable<TripRevenueReportOccurrenceLocationResponseModel> {
        return this.httpClient.post<TripRevenueReportOccurrenceLocationResponseModel>(environment.server_api_url + '/api/secured/reporting/getTripRevenueReportOccurrenceLocation', tripRevenueReportOccurrenceLocationRequest);
    }

    generateUrlForExportToXlsxTripRevenueReportOccurrenceLocation(tripRevenueReportOccurrenceLocationRequest: TripRevenueReportOccurrenceLocationRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsxTripRevenueReportOccurrenceLocation', tripRevenueReportOccurrenceLocationRequest, { responseType: 'text' });
    }
    // Trip revenue report :: END

    // Ticket type revenue report :: BEGIN
    getTicketTypeRevenueReport(ticketTypeRevenueReportRequest: TicketTypeRevenueReportRequestModel): Observable<TicketTypeRevenueReportResponseModel> {
        return this.httpClient.post<TicketTypeRevenueReportResponseModel>(environment.server_api_url + '/api/secured/reporting/getTicketTypeRevenueReport', ticketTypeRevenueReportRequest);
    }

    generateUrlForExportToXlsTicketTypeRevenueReport(ticketTypeRevenueReportRequest: TicketTypeRevenueReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsTicketTypeRevenueReport', ticketTypeRevenueReportRequest, { responseType: 'text' });
    }
    // Ticket type revenue report :: END

    // Commission report :: BEGIN
    getCommissionReport(commissionReportRequest: CommissionReportRequestModel): Observable<CommissionReportResponseModel> {
        return this.httpClient.post<CommissionReportResponseModel>(environment.server_api_url + '/api/secured/reporting/getCommissionReport', commissionReportRequest);
    }

    generateUrlForExportToXlsCommissionReport(commissionReportRequest: CommissionReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsCommissionReport', commissionReportRequest, { responseType: 'text' });
    }
    // Commission report :: END

    // Collected & Earned Revenue report :: BEGIN
    getCollectedAndEarnedRevenueReport(collectedAndEarnedRevenueReportRequest: CollectedAndEarnedRevenueReportRequestModel): Observable<CollectedAndEarnedRevenueReportResponseModel> {
        return this.httpClient.post<CollectedAndEarnedRevenueReportResponseModel>(environment.server_api_url + '/api/secured/reporting/getCollectedAndEarnedRevenueReport', collectedAndEarnedRevenueReportRequest);
    }

    generateUrlForExportToXlsCollectedAndEarnedRevenueReport(collectedAndEarnedRevenueReportRequest: CollectedAndEarnedRevenueReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsCollectedAndEarnedRevenueReport', collectedAndEarnedRevenueReportRequest, { responseType: 'text' });
    }

    generateUrlForExportToPdfCollectedAndEarnedRevenueReport(collectedAndEarnedRevenueReportRequest: CollectedAndEarnedRevenueReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToPdfCollectedAndEarnedRevenueReport', collectedAndEarnedRevenueReportRequest, { responseType: 'text' });
    }
    // Collected & Earned Revenue report :: END

    // Revenue report :: BEGIN
    getRevenueReport(revenueReportRequest: RevenueReportRequestModel): Observable<RevenueReportResponseModel> {
        return this.httpClient.post<RevenueReportResponseModel>(environment.server_api_url + '/api/secured/reporting/getRevenueReport', revenueReportRequest);
    }

    generateUrlForExportToXlsRevenueReport(revenueReportRequest: RevenueReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsRevenueReport', revenueReportRequest, { responseType: 'text' });
    }

    generateUrlForExportToPdfRevenueReport(revenueReportRequest: RevenueReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToPdfRevenueReport', revenueReportRequest, { responseType: 'text' });
    }
    // Revenue report :: END

    // NetSuite export report :: BEGIN
    generateUrlForExportToXlsNetSuiteExportReport(netSuiteExportReportRequest: NetSuiteExportReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsNetSuiteExportReport', netSuiteExportReportRequest, { responseType: 'text' });
    }
    // NetSuite export report :: END

    // Custom billing report :: BEGIN
    getCustomBillingReport(customBillingReportRequestModel: CustomBillingReportRequestModel): Observable<CustomBillingReportResponseModel> {
        return this.httpClient.post<CustomBillingReportResponseModel>(environment.server_api_url + '/api/secured/reporting/getCustomBillingReport', customBillingReportRequestModel);
    }
     // Custom billing report :: END

    // Invalid scan report :: BEGIN
    getInvalidScanReport(invalidScanReportRequest: InvalidScanReportRequestModel): Observable<InvalidScanReportResponseModel> {
        return this.httpClient.post<InvalidScanReportResponseModel>(environment.server_api_url + '/api/secured/reporting/getInvalidScanReport', invalidScanReportRequest);
    }

    generateUrlForExportToXlsxInvalidScanReport(invalidScanReportRequest: InvalidScanReportRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsxInvalidScanReport', invalidScanReportRequest, { responseType: 'text' });
    }
    // Invalid scan report :: END

    // Android debug logs :: BEGIN
    getAndroidDebugLogs(androidDebugLogsRequest: AndroidDebugLogsRequestModel): Observable<InvalidScanReportResponseModel> {
        return this.httpClient.post<InvalidScanReportResponseModel>(environment.server_api_url + '/api/secured/reporting/getAndroidDebugLogs', androidDebugLogsRequest);
    }

    generateUrlForExportToXlsxAndroidDebugLogs(androidDebugLogsRequest: AndroidDebugLogsRequestModel): Observable<string> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/reporting/generateUrlForExportToXlsxAndroidDebugLogs', androidDebugLogsRequest, { responseType: 'text' });
    }
    // Android debug logs :: END
}
