export class SubmitFormDialogDataModel {

    selectedProductBusMappingItems: number[];
    unselectedProductBusMappingItems: number[];

    constructor(selectedProductBusMappingItems: number[], unselectedProductBusMappingItems: number[]) {
        this.selectedProductBusMappingItems = selectedProductBusMappingItems;
        this.unselectedProductBusMappingItems = unselectedProductBusMappingItems;
    }
}
