import {CreateUpdateLocationListItemRequestModel} from "./create-update-location-list-item-request.model";

export class CreateLocationListRequestModel {
    description: string;
    locationListItems: CreateUpdateLocationListItemRequestModel[];

    constructor(description: string, locationListItems: CreateUpdateLocationListItemRequestModel[]) {
        this.description = description;
        this.locationListItems = locationListItems;
    }
}
