import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {MultiselectDropdownOptionModel, OPTION_ALL} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import {FormGroup} from "@angular/forms";
import {CategoryResponseModel} from "../../../../../../../../../core/modules/rest/category/response/category-response.model";
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {ProductAvailabilityHolidayMappingResponseModel} from "../../../../../../../../../core/modules/rest/product-availability/response/product-availability-holiday-mapping-response.model";
import {ProductAndInactivitySelectedModel} from "../../model/product-and-inactivity-selected.model";
import {SubmitFormDialogDataModel} from "../../model/submit-form-dialog-data.model";
import { HolidayResponseModel } from '../../../../../../../../../core/modules/rest/holiday/response/holiday-response.model';


@Component({
    selector: 'app-mapping-holiday-dialog-form',
    templateUrl: './mapping-holiday-dialog-form.component.html'
})
export class MappingHolidayDialogFormComponent implements OnInit, OnChanges, AfterViewInit {


    // --------------------------------------------------------
    // Input information
    // --------------------------------------------------------

    // Information about holiday
    @Input() holiday: HolidayResponseModel;

    // Data
    @Input() categories: CategoryResponseModel[];
    @Input() products: ProductSimpleResponseModel[];
    @Input() productAvailabilityHolidayMappingItems: ProductAvailabilityHolidayMappingResponseModel[];

    // Loading status for dropdown
    @Input() areCategoriesLoading: boolean;
    @Input() areProductsLoading: boolean;
    @Input() areProductAvailabilityHolidayMappingItemsLoading: boolean;

    // --------------------------------------------------------
    // Output event information
    // --------------------------------------------------------

    // Multiselect selected events
    @Output() categoriesSelectedEvent = new EventEmitter<number[]>();
    @Output() productsSelectedEvent = new EventEmitter<ProductAndInactivitySelectedModel>();
    @Output() productAvailabilitiesSelectedEvent = new EventEmitter<number[]>();
    @Output() showInactiveCategoriesEvent = new EventEmitter<CategoryResponseModel>();
    @Output() showInactiveProductsEvent = new EventEmitter<ProductSimpleResponseModel>();
    @Output() showInactiveExpiredProductAvailabilitiesEvent = new EventEmitter<ProductAndInactivitySelectedModel>();

    // Dialog submit/close events
    @Output() submitEvent = new EventEmitter<SubmitFormDialogDataModel>();
    @Output() closeEvent = new EventEmitter<void>();

    // Component helpers
    form: FormGroup;

    // Model for HTML
    categoryOptions: MultiselectDropdownOptionModel[] = [];
    productOptions: MultiselectDropdownOptionModel[] = [];
    productAvailabilityHolidayMappingOptions: MultiselectDropdownOptionModel[] = [];

    // Selected values for dropdown
    selectedCategories: number[] = [];
    selectedProducts: number[] = [];
    selectedProductAvailabilityHolidayMappingItems: number[] = [];
    unselectedProductAvailabilityHolidayMappingItems: number[] = [];

    showInactiveCategories: boolean = false;
    showInactiveProducts: boolean = false;
    showInactiveExpiredProductAvailabilities: boolean = false;

    showMappingForHoliday: boolean = true;

    categoryIds: string[] = [];
    productIds: string[] = [];
    fromManualChange: boolean = false;

    constructor() {
    }

    ngOnInit() {
        this.form = new FormGroup({});
    }

    ngAfterViewInit() {
        if (this.holiday != null) {
            setTimeout(() => {
                for (let category in this.holiday.holidayCategoriesResponses) {
                    this.categoryIds.push(this.holiday.holidayCategoriesResponses[category].categoryId.toString());
                }
                this.selectedCategories = this.categoryIds.map((value: string) => Number(value));

                this.productIds = this.products
                .filter(product => this.selectedCategories.includes(product.categoryId))
                .filter(product => this.selectedProducts.includes(product.productId))
                .map(product => product.productId.toString());

                this.categoriesSelectedEvent.emit(this.selectedCategories);

                this.selectedProducts  = this.products.filter(product => this.selectedCategories.includes(product.categoryId)).map(value => Number(value.productId));

                this.productsSelectedEvent.emit(
                    new ProductAndInactivitySelectedModel(
                        this.selectedProducts,
                        this.showInactiveExpiredProductAvailabilities
                    ));
                this.onShowInactiveCategories(this.showInactiveCategories);

                for (let product in this.holiday.holidayProductsResponses) {
                    this.productIds.push(this.holiday.holidayProductsResponses[product].productId.toString());
                }
              this.selectedProducts = this.productIds.map((value: string) => Number(value));

              this.productsSelectedEvent.emit(
                  new ProductAndInactivitySelectedModel(
                      this.selectedProducts,
                      this.showInactiveExpiredProductAvailabilities
                  ));
            });
        }

      }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.holiday !== undefined) {
            const today = new Date();
            const todayIntFormat  = this.formatDate(today);
            const holidaydateIntFormat = this.formatDateHoliday(this.holiday.day, this.holiday.month, this.holiday.year);
            if (holidaydateIntFormat < todayIntFormat) {
                this.showMappingForHoliday = false;
            }
        }

        if (changes.categories !== undefined) {
            // Populate dropdown values
            this.onShowInactiveCategories(this.showInactiveCategories);
        }

        if (changes.products !== undefined) {
            // Populate dropdown values
            this.onShowInactiveProducts(this.showInactiveProducts);
        }
        if (changes.productAvailabilityHolidayMappingItems !== undefined && changes.productAvailabilityHolidayMappingItems.currentValue.length > 0) {
            // Populate dropdown values
            this.productAvailabilityHolidayMappingOptions = changes.productAvailabilityHolidayMappingItems.currentValue
                .map((availability: ProductAvailabilityHolidayMappingResponseModel) => new MultiselectDropdownOptionModel(availability.productAvailabilityDescription, availability.productAvailabilityId.toString()));
            // Update already selected items
            this.selectedProductAvailabilityHolidayMappingItems = changes.productAvailabilityHolidayMappingItems.currentValue
                .filter((availability: ProductAvailabilityHolidayMappingResponseModel) => availability.isAssigned)
                .map((availability: ProductAvailabilityHolidayMappingResponseModel) => availability.productAvailabilityId);
        }
        else {
            if (this.fromManualChange) {
                this.productAvailabilityHolidayMappingOptions = [];
                this.selectedProductAvailabilityHolidayMappingItems = [];
            }
        }
    }

    private formatDate(date: Date): Date {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const dateString = `${day}${month}${year}`;
        const dateFormated = new Date(
            parseInt(dateString.substring(4, 8)),
            parseInt(dateString.substring(2, 4)) - 1,
            parseInt(dateString.substring(0, 2))
          );
        return dateFormated;
    }

    private formatDateHoliday(day: number, month: number, year: number): Date {
        if (day !== undefined && month !== undefined && year !== undefined) {
            const formattedDay = day < 10 ? `0${day}` : `${day}`;
            const formattedMonth = month < 10 ? `0${month}` : `${month}`;
            const dateString = `${formattedDay}${formattedMonth}${year}`
            const dateFormated = new Date(
                parseInt(dateString.substring(4, 8)),
                parseInt(dateString.substring(2, 4)) - 1,
                parseInt(dateString.substring(0, 2))
              );
            return dateFormated;
        }
    }

    onSelectedCategoriesChanged(values: string[]) {
        this.fromManualChange = true;
        this.selectedCategories = values.map((value: string) => Number(value));

        this.productIds = this.products
        .filter(product => this.selectedCategories.includes(product.categoryId))
        .filter(product => this.selectedProducts.includes(product.productId))
        .map(product => product.productId.toString());

        this.categoriesSelectedEvent.emit(this.selectedCategories);

        if (this.productIds.length > 0) {
            this.selectedProducts = this.products.filter(product => this.selectedCategories.includes(product.categoryId)).map(value => Number(value.productId));
            this.productsSelectedEvent.emit(
                new ProductAndInactivitySelectedModel(
                    this.selectedProducts,
                    this.showInactiveExpiredProductAvailabilities
                ));
        }
        else {
            this.selectedProducts = [];
        }
    }


    onSelectedProductsChanged(values: string[]) {
        this.fromManualChange = true;
        this.selectedProducts = values.map((value: string) => Number(value));
        this.productsSelectedEvent.emit(
            new ProductAndInactivitySelectedModel(
                this.selectedProducts,
                this.showInactiveExpiredProductAvailabilities
            ));
    }

    onSelectedProductAvailabilityHolidayMappingItemsChanged(values: string[]) {

        // Update selected list
        this.selectedProductAvailabilityHolidayMappingItems = values.map((value: string) => Number(value));

        // Update unselected list == product availabilities that have been unselected
        this.unselectedProductAvailabilityHolidayMappingItems = this.productAvailabilityHolidayMappingItems
            .filter((av: ProductAvailabilityHolidayMappingResponseModel) => !this.selectedProductAvailabilityHolidayMappingItems.includes(av.productAvailabilityId) && av.isAssigned)
            .map((av: ProductAvailabilityHolidayMappingResponseModel) => av.productAvailabilityId);
    }

    onShowInactiveExpiredProductAvailabilities(value: boolean) {

        this.showInactiveExpiredProductAvailabilities = value;
        const selectedProducts = this.selectedProducts
        .filter(t => String(t) !== OPTION_ALL)
        .map(Number);
        this.showInactiveExpiredProductAvailabilitiesEvent.emit(
            new ProductAndInactivitySelectedModel(
                selectedProducts,
                this.showInactiveExpiredProductAvailabilities
            ));
    }

    onShowInactiveCategories(value: boolean) {

        this.showInactiveCategories = value;

        if (value) {
            this.categoryOptions = this.categories
            .map((category: CategoryResponseModel) => new MultiselectDropdownOptionModel(category.description, category.categoryId.toString()));
        }
        else {
            let activeCategories = this.categories.filter((category: CategoryResponseModel) => category.active == true);
            this.categoryOptions = activeCategories
            .map((category: CategoryResponseModel) => new MultiselectDropdownOptionModel(category.description, category.categoryId.toString()));
        }
    }

    onShowInactiveProducts(value: boolean) {

        this.showInactiveProducts = value;

        if (value) {
            this.productOptions = this.products
            .map((product: ProductSimpleResponseModel) => new MultiselectDropdownOptionModel(product.description, product.productId.toString()));
        }
        else {
            let activeProducts = this.products.filter((product: ProductSimpleResponseModel) => product.active == true);
            this.productOptions = activeProducts
            .map((product: ProductSimpleResponseModel) => new MultiselectDropdownOptionModel(product.description, product.productId.toString()));
        }
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        let productsIds: number[] = this.selectedProducts
        .filter(t => String(t) !== OPTION_ALL)
        .map(Number);

        let selectedProductAvailabilityHolidayMappingItemsIds: number[] = this.selectedProductAvailabilityHolidayMappingItems
        .filter(t => String(t) !== OPTION_ALL)
        .map(Number);

        let unselectedProductAvailabilityHolidayMappingItemsIds: number[] = this.unselectedProductAvailabilityHolidayMappingItems
        .filter(t => String(t) !== OPTION_ALL)
        .map(Number);

        const data = new SubmitFormDialogDataModel(
            productsIds,
            selectedProductAvailabilityHolidayMappingItemsIds,
            unselectedProductAvailabilityHolidayMappingItemsIds
        );

        this.submitEvent.emit(data);
    }
}
