import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { CustomerResponseModel } from "./response/customer-response.model";
import { CreateCustomerRequestModel } from "./request/create-customer-request.model";
import { UpdateCustomerRequestModel } from "./request/update-customer-request.model";
import { CustomerRequestModel } from "./request/customer-request.model";
import { CustomerItemResponseModel } from "./response/customer-item-response.model";
import { CustomerMappingResponseModel } from "./response/customer-mapping-response.model";
import { UpdateCustomerMappingRequestModel } from "./request/update-customer-mapping-request.model";

@Injectable()
export class CustomerRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<CustomerResponseModel[]> {
        return this.httpClient.get<CustomerResponseModel[]>(environment.server_api_url + '/api/secured/customer/getAll');
    }

    getAllBySearch(search: String): Observable<CustomerResponseModel[]> {
        return this.httpClient.get<CustomerResponseModel[]>(environment.server_api_url + '/api/secured/customer/getAllBySearch/' + search);
    }

    getById(customerId: number): Observable<CustomerResponseModel> {
        return this.httpClient.get<CustomerResponseModel>(environment.server_api_url + '/api/secured/customer/getById/' + customerId);
    }

    create(request: CreateCustomerRequestModel): Observable<CreateCustomerRequestModel> {
        return this.httpClient.post<CreateCustomerRequestModel>(environment.server_api_url + '/api/secured/customer/create', request);
    }

    update(request: UpdateCustomerRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/customer/update', request, {responseType: 'text'});
    }

    activate(customerId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/customer/activate', customerId, {responseType: 'text'});
    }

    deactivate(customerId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/customer/deactivate', customerId, {responseType: 'text'});
    }

    lock(customerId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/customer/lock', customerId, {responseType: 'text'});
    }

    unlock(customerId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/customer/unlock', customerId, {responseType: 'text'});
    }

    sendActivationEmail(customerEmail: string): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/customer/sendActivationEmail', customerEmail, {responseType: 'text'});
    }

    createResetPasswordRequest(customerEmail: string): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/customer/resetPasswordForCustomer', customerEmail, {responseType: 'text'});
    }

    getCustomers(customerRequest: CustomerRequestModel): Observable<CustomerResponseModel> {
        return this.httpClient.post<CustomerResponseModel>(environment.server_api_url + '/api/secured/customer/getAllinRange', customerRequest);
    }

    getAllCustomerOwners(): Observable<CustomerItemResponseModel[]> {
        return this.httpClient.get<CustomerItemResponseModel[]>(environment.server_api_url + '/api/secured/customer/getAllCustomerOwners');
    }

    getCustomerMapping(customerId: number): Observable<CustomerMappingResponseModel> {
        return this.httpClient.get<CustomerMappingResponseModel>(environment.server_api_url + "/api/secured/customer/getCustomerMapping/" + customerId);
    }

    updateCustomerMapping(request: UpdateCustomerMappingRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/customer/updateCustomerMapping', request, {responseType: 'text'});
    }
}
