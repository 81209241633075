import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {KioskConfigurationDropoffLocationProductModel} from "../../model/kiosk-configuration-dropoff-location-product.model";
import {ProductForRouteSetupResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-for-route-setup-response.model";
import {LocationListItemDescriptorModel} from "../../model/location-list-item-descriptor.model";
import {ProductChangedData} from "../../model/emit/product-changed-data.emit";
import {DirectionChangedData} from "../../model/emit/direction-changed-data.emit";
import {PickupLocationChangedData} from "../../model/emit/pickup-location-changed-data.emit";
import {DropoffLocationChangedData} from "../../model/emit/dropoff-location-changed-data.emit";


const REGULAR_DIRECTION_SELECT = new DropdownOptionModel('Regular', 'regular');
const RETURN_DIRECTION_SELECT = new DropdownOptionModel('Return', 'return');



@Component({
    selector: 'app-route-setup-product',
    templateUrl: './route-setup-product.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RouteSetupProductComponent implements OnInit, OnChanges {

    @Input() product: KioskConfigurationDropoffLocationProductModel;
    @Input() index: number;
    @Input() allProducts: ProductForRouteSetupResponseModel[] = [];

    @Output() productChangedEvent: EventEmitter<ProductChangedData> = new EventEmitter();
    @Output() directionChangedEvent: EventEmitter<DirectionChangedData> = new EventEmitter();
    @Output() pickupLocationChangedEvent: EventEmitter<PickupLocationChangedData> = new EventEmitter();
    @Output() dropoffLocationChangedEvent: EventEmitter<DropoffLocationChangedData> = new EventEmitter();
    @Output() deleteProductEvent: EventEmitter<number> = new EventEmitter();

    productsForDropdown: DropdownOptionModel[] = [];
    directionsForDropdown: DropdownOptionModel[] = [];
    pickupLocationsForDropdown: DropdownOptionModel[] = [];
    dropoffLocationsForDropdown: DropdownOptionModel[] = [];

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setProductsForDropdown();
        this.setDirectionsForDropdown();
        this.setPickupLocationsForDropdown();
        this.setDropoffLocationsForDropdown();
    }

    getDirectionOfProduct(): string {

        if (this.product.isReturnTrip === null) {
            return null;
        } else if (this.product.isReturnTrip) {
            return RETURN_DIRECTION_SELECT.value;
        } else {
            return REGULAR_DIRECTION_SELECT.value;
        }
    }

    onProductChanged(productId: string) {

        const selectedProductId = productId !== null ? +productId : null;

        // Prevent circular refresh because of change detection OnPush
        if (this.product.productId === selectedProductId) {
            return;
        }

        const obj: ProductChangedData = {
            index: this.index,
            productId: selectedProductId,
            product: null
        };

        this.product.loadingPickupLocations = true;
        this.product.loadingDropoffLocations = true;

        this.productChangedEvent.emit(obj);
    }

    onDirectionChanged(direction: string) {

        let isReturnTrip = null;

        if (direction === REGULAR_DIRECTION_SELECT.value) {
            isReturnTrip = false;
        } else if (direction === RETURN_DIRECTION_SELECT.value) {
            isReturnTrip = true;
        }

        // Prevent circular refresh because of change detection OnPush
        if (this.product.isReturnTrip === isReturnTrip) {
            return;
        }

        const obj: DirectionChangedData = {
            index: this.index,
            returnTrip: isReturnTrip,
            productId: this.product.productId
        };

        this.product.loadingPickupLocations = true;
        this.product.loadingDropoffLocations = true;

        this.directionChangedEvent.emit(obj);
    }

    onPickupLocationChanged(pickupLocationId: string) {

        const selectedPickupLocationId = pickupLocationId !== null ? +pickupLocationId : null;

        // Prevent circular refresh because of change detection OnPush
        if (this.product.pickupLocationId === selectedPickupLocationId) {
            return;
        }

        const obj: PickupLocationChangedData = {
            index: this.index,
            pickupLocationId: selectedPickupLocationId,
            productId: this.product.productId,
            returnTrip: this.product.isReturnTrip
        };

        this.product.loadingDropoffLocations = true;

        this.pickupLocationChangedEvent.emit(obj);
    }

    onDropoffLocationChanged(dropoffLocationId: string) {

        const selectedDropoffLocationId = dropoffLocationId !== null ? +dropoffLocationId : null;

        // Prevent circular refresh because of change detection OnPush
        if (this.product.dropoffLocationId === selectedDropoffLocationId) {
            return;
        }

        const obj: DropoffLocationChangedData = {
            index: this.index,
            dropoffLocationId: selectedDropoffLocationId
        };

        this.dropoffLocationChangedEvent.emit(obj);
    }

    deleteProductClick(event) {
        event.stopPropagation();
        this.deleteProductEvent.emit(this.index);
    }

    private setProductsForDropdown() {

        this.productsForDropdown = [];

        const allProductsDropdownOptions = this.allProducts.map((product: ProductForRouteSetupResponseModel) => {
            return new DropdownOptionModel(product.description, product.productId.toString());
        });

        this.productsForDropdown = [
            ...allProductsDropdownOptions.slice(0)
        ];
    }

    private setDirectionsForDropdown() {

        if (this.product.productId) {
            if (this.product.isRoundTripProduct) {
                this.directionsForDropdown = [
                    REGULAR_DIRECTION_SELECT,
                    RETURN_DIRECTION_SELECT
                ];
            } else {
                this.directionsForDropdown = [
                    REGULAR_DIRECTION_SELECT
                ];
            }
        } else {
            this.directionsForDropdown = [];
        }
    }

    private setPickupLocationsForDropdown() {

        if (this.product.isReturnTrip === null) {
            this.pickupLocationsForDropdown = [];
        } else {

            // Map to DropdownOptionModel
            this.pickupLocationsForDropdown = this.product.pickupLocations.map((location: LocationListItemDescriptorModel) => {
                return new DropdownOptionModel(location.description, location.locationId.toString());
            });
        }
    }

    private setDropoffLocationsForDropdown() {

        if (!this.product.pickupLocationId) {
            this.dropoffLocationsForDropdown = [];
        } else {

            // Map to DropdownOptionModel
            this.dropoffLocationsForDropdown = this.product.dropoffLocations.map((location: LocationListItemDescriptorModel) => {
                return new DropdownOptionModel(location.description, location.locationId.toString());
            });
        }
    }
}
