import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { APP_INITIALIZER, NgModule, Injector } from '@angular/core';

import { AppComponent } from './app.component';
import { AppModuleRoutes } from './app.routes';
import { SharedModule } from './shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { YesNoDialogModule } from "./shared/dialogs/common-dialogs/yes-no-dialog/yes-no-dialog.module";
import { OrderSummaryDialogModule } from "./shared/dialogs/common-dialogs/order-summary-dialog/order-summary-dialog.module";
import { EditBidDialogModule } from "./modules/secured/management/inventory/groups/dialogs/edit-bid-dialog/edit-bid-dialog.module";
import { EditBusDialogModule } from "./modules/secured/management/inventory/buses/dialogs/edit-bus-dialog/edit-bus-dialog.module";
import { EditCompanyInfoDialogModule } from './modules/secured/management/admin-panel/company-infos/dialogs/edit-company-info-dialog/edit-company-info-dialog.module';
import { EditTierDialogModule } from "./modules/secured/management/sales/tiers/dialogs/edit-tier-dialog/edit-tier-dialog.module";
import { EditDepartureGroupDialogModule } from "./modules/secured/management/inventory/groups/dialogs/edit-departure-group-dialog/edit-departure-group-dialog.module";
import { EditPostBillTypeDialogModule } from "./modules/secured/management/sales/post-bill-types/dialogs/edit-post-bill-type-dialog/edit-post-bill-type-dialog.module";
import { EditLetterGroupDialogModule } from "./modules/secured/management/inventory/groups/dialogs/edit-letter-group-dialog/edit-letter-group-dialog.module";
import { EditHolidayDialogModule } from "./modules/secured/management/inventory/holidays/dialogs/edit-holiday-dialog/edit-holiday-dialog.module";
import { EditAdditionalCostDialogModule } from "./modules/secured/management/sales/costs/dialogs/edit-additional-cost-dialog/edit-additional-cost-dialog.module";
import { EditIncludedCostDialogModule } from "./modules/secured/management/sales/costs/dialogs/edit-included-cost-dialog/edit-included-cost-dialog.module";
import { EditDiscountDialogModule } from "./modules/secured/management/sales/costs/dialogs/edit-discount-dialog/edit-discount-dialog.module";
import { EditAutoAppliedDiscountDialogModule } from "./modules/secured/management/sales/costs/dialogs/edit-auto-applied-discount-dialog/edit-auto-applied-discount-dialog.module";
import { MappingHolidayDialogModule } from "./modules/secured/management/inventory/holidays/dialogs/mapping-holiday-dialog/mapping-holiday-dialog.module";
import { CloseDropdownOnScrollService } from './shared/directives/scroll/close-dropdown-on-scroll.service';
import { PreviewEmailHtmlTemplateDialogModule } from "./modules/secured/management/system/email-html-templates/dialogs/preview-email-html-template-dialog/preview-email-html-template-dialog.module";
import { EditEmailHtmlTemplateDialogModule } from "./modules/secured/management/system/email-html-templates/dialogs/edit-email-html-template-dialog/edit-email-html-template-dialog.module";
import { EditKioskConfigurationDialogModule } from "./modules/secured/management/hardware/kiosk-configurations/dialogs/edit-kiosk-configuration-dialog/edit-kiosk-configuration-dialog.module";
import { EditKioskDeviceInfoDialogModule } from "./modules/secured/management/hardware/kiosk-device-infos/dialogs/edit-kiosk-device-info-dialog/edit-kiosk-device-info-dialog.module";
import { CreateDropoffLocationDialogModule } from "./modules/secured/management/hardware/kiosk-configuration-details/dialogs/create-dropoff-location-dialog/create-dropoff-location-dialog.module";
import { ProductTierSetupDialogModule } from "./modules/secured/management/hardware/kiosk-configuration-details/dialogs/product-tier-setup-dialog/product-tier-setup-dialog.module";
import { PassTierSetupDialogModule } from "./modules/secured/management/hardware/kiosk-configuration-details/dialogs/pass-tier-setup-dialog/pass-tier-setup-dialog.module";
import { EditQuickSellingButtonPassDialogModule } from './modules/secured/management/sales/quick-selling-button-passes/dialogs/edit-quick-selling-button-pass-dialog/edit-quick-selling-button-pass-dialog.module';
import { RouteSetupDialogModule } from "./modules/secured/management/hardware/kiosk-configuration-details/dialogs/route-setup-dialog/route-setup-dialog.module";
import { EditQuickSellingButtonProductDialogModule } from './modules/secured/management/sales/quick-selling-button-products/dialogs/edit-quick-selling-button-product-dialog/edit-quick-selling-button-product-dialog.module';
import { VoidOrderDialogModule } from './shared/dialogs/common-dialogs/void-order-dialog/void-order-dialog.module';
import { EditFavoriteDialogModule } from "./shared/dialogs/common-dialogs/edit-favorite-dialog/edit-favorite-dialog.module";
import { EnableRoleDialogModule } from "./modules/secured/management/system/roles-and-users/dialogs/enable-role-dialog/enable-role-dialog.module";
import { EditRoleOrUserDialogModule } from "./modules/secured/management/system/roles-and-users/dialogs/edit-role-or-user-dialog/edit-role-or-user-dialog.module";
import { PrintOrderDialogModule } from "./shared/dialogs/common-dialogs/print-order-dialog/print-order-dialog.module";
import { SendEmailDialogModule } from "./shared/dialogs/common-dialogs/send-email-dialog/send-email-dialog.module";
import { AddNewEntryDialogModule } from "./modules/secured/sales/end-of-shift/cashout-entry/dialogs/add-new-entry-dialog/add-new-entry-dialog.module";
import { AppConfig } from "./core/services/app-config.service";
import { EditOccurrencesDialogModule } from "./modules/secured/management/inventory/occurrence-management/dialogs/edit-occurrences-dialog/edit-occurrences-dialog.module";
import { DeactivateOccurrencesDialogModule } from "./modules/secured/management/inventory/occurrence-management/dialogs/deactivate-occurrences-dialog/deactivate-occurrences-dialog.module";
import { EnableCategoryDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/enable-category-dialog/enable-category-dialog.module";
import { AddCategoryDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/add-category-dialog/add-category-dialog.module";
import { EditCategoryBasicInfoDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/edit-category-basic-info-dialog/edit-category-basic-info-dialog.module";
import { EditCategoryEmailHtmlTemplatesDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/edit-category-email-html-templates-dialog/edit-category-email-html-templates-dialog.module";
import { EditCategoryTemplateItemsDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/edit-category-template-items-dialog/edit-category-template-items-dialog.module";
import { AddProductDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/add-product-dialog/add-product-dialog.module";
import { EditProductBasicInfoDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/edit-product-basic-info-dialog/edit-product-basic-info-dialog.module";
import { EditProductFlagsDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/edit-product-flags-dialog/edit-product-flags-dialog.module";
import { EditProductValidityDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/edit-product-validity-dialog/edit-product-validity-dialog.module";
import { EditProductEmailHtmlTemplatesDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/edit-product-email-html-templates-dialog/edit-product-email-html-templates-dialog.module";
import { EditProductRoutesDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/edit-product-routes-dialog/edit-product-routes-dialog.module";
import { EditProductAvailabilitiesDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/edit-product-availabilities-dialog/edit-product-availabilities-dialog.module";
import { CloneProductAvailabilitiesDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/clone-product-availabilities-dialog/clone-product-availabilities-dialog.module";
import { EditProductPricingDateRangesDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/edit-product-pricing-date-ranges-dialog/edit-product-pricing-date-ranges-dialog.module";
import { CloneProductPricingDateRangesDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/clone-product-pricing-date-ranges-dialog/clone-product-pricing-date-ranges-dialog.module";
import { AddPassDialogModule } from "./modules/secured/management/inventory/passes/dialogs/add-pass-dialog/add-pass-dialog.module";
import { EditPassBasicInfoDialogModule } from "./modules/secured/management/inventory/passes/dialogs/edit-pass-basic-info-dialog/edit-pass-basic-info-dialog.module";
import { EditPassProductsDialogModule } from "./modules/secured/management/inventory/passes/dialogs/edit-pass-products-dialog/edit-pass-products-dialog.module";
import { EditPassFlagsDialogModule } from "./modules/secured/management/inventory/passes/dialogs/edit-pass-flags-dialog/edit-pass-flags-dialog.module";
import { EditPassValidityDialogModule } from "./modules/secured/management/inventory/passes/dialogs/edit-pass-validity-dialog/edit-pass-validity-dialog.module";
import { EditPassEmailHtmlTemplatesDialogModule } from "./modules/secured/management/inventory/passes/dialogs/edit-pass-email-html-templates-dialog/edit-pass-email-html-templates-dialog.module";
import { EditPassTemplateItemsDialogModule } from "./modules/secured/management/inventory/passes/dialogs/edit-pass-template-items-dialog/edit-pass-template-items-dialog.module";
import { EditPassPricingDateRangesDialogModule } from "./modules/secured/management/inventory/passes/dialogs/edit-pass-pricing-date-ranges-dialog/edit-pass-pricing-date-ranges-dialog.module";
import { ClonePassPricingDateRangesDialogModule } from "./modules/secured/management/inventory/passes/dialogs/clone-pass-pricing-date-ranges-dialog/clone-pass-pricing-date-ranges-dialog.module";
import { CloneProductDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/clone-product-dialog/clone-product-dialog.module";
import { ClonePassDialogModule } from "./modules/secured/management/inventory/passes/dialogs/clone-pass-dialog/clone-pass-dialog.module";
import { MappingCostDialogModule } from "./modules/secured/management/sales/costs/dialogs/mapping-cost-dialog/mapping-cost-dialog.module";
import { EditPassCostsDialogModule } from "./modules/secured/management/inventory/passes/dialogs/edit-pass-costs-dialog/edit-pass-costs-dialog.module";
import { EditCategoryCostsDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/edit-category-costs-dialog/edit-category-costs-dialog.module";
import { EditProductCostsDialogModule } from "./modules/secured/management/inventory/categories-and-products/dialogs/edit-product-costs-dialog/edit-product-costs-dialog.module";
import { EditLocationDialogModule } from "./modules/secured/management/inventory/locations-and-location-lists/dialogs/edit-location-dialog/edit-location-dialog.module";
import { EditLocationListDialogModule } from "./modules/secured/management/inventory/locations-and-location-lists/dialogs/edit-location-list-dialog/edit-location-list-dialog.module";
import { OkDialogModule } from "./shared/dialogs/common-dialogs/ok-dialog/ok-dialog.module";
import { EditAutoReportDialogModule } from "./modules/secured/reports/general/auto-reports/dialogs/edit-auto-report-dialog/edit-auto-report-dialog.module";
import { EditCommissionDialogModule } from "./modules/secured/management/sales/commissions/dialogs/edit-commission-dialog/edit-commission-dialog.module";
import { MappingCommissionDialogModule } from "./modules/secured/management/sales/commissions/dialogs/mapping-commission-dialog/mapping-commission-dialog.module";
import { EditPassCardsDialogModule } from "./modules/secured/management/inventory/pass-card-management/dialogs/edit-pass-cards-dialog/edit-pass-cards-dialog.module";
import { OccurrenceManifestDialogModule } from "./modules/secured/management/inventory/occurrence-management/dialogs/occurrence-manifest-dialog/occurrence-manifest-dialog.module";
import {
    AddAndroidDeviceInfoRemoteCommandDialogModule
} from "./modules/secured/management/hardware/android-device-infos/dialogs/add-android-device-info-remote-command-dialog/add-android-device-info-remote-command-dialog.module";
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { MatTreeModule } from '@angular/material/tree';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { RolesAndUsersSandbox } from './modules/secured/management/system/roles-and-users/sandbox/roles-and-users.sandbox';
import { CategoriesAndProductsSandbox } from './modules/secured/management/inventory/categories-and-products/sandbox/categories-and-products.sandbox';
import { PassesSandbox } from './modules/secured/management/inventory/passes/sandbox/passes.sandbox';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InputDialogModule } from "./shared/dialogs/common-dialogs/input-dialog/input-dialog.module";
import { DropdownDialogModule } from './shared/dialogs/common-dialogs/dropdown-dialog/dropdown-dialog.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { EditCustomerDialogModule } from './modules/secured/management/customers/customers/dialogs/edit-customer-dialog/edit-customer-dialog.module';
import { EditSaleGroupDialogModule } from './modules/secured/management/customers/sale-groups-managment/dialogs/edit-sale-group-managment-dialog.module';
import { AddSaleGroupCustomerDialogModule } from './modules/secured/management/customers/sale-groups/dialogs/sale-group-customer/add-sale-group--customer-managment-dialog.module';
import { EditCloudPaymentDeviceDialogModule } from './modules/secured/management/hardware/cloud-payment/dialogs/edit-cloud-payment-device-dialog/edit-cloud-payment-device-dialog.module';
import { DateDialogModule } from './shared/dialogs/common-dialogs/date-dialog/date-dialog.module';
import { ChangePasswordDialogModule } from './shared/dialogs/common-dialogs/change-password-dialog/change-password-dialog.module';
import { EditNotificationDialogModule } from './modules/secured/management/system/notifications/dialogs/edit-notification-dialog/edit-notification-dialog.module';
import { EditOverrideReasonDialogModule } from './modules/secured/management/hardware/over-ride-reason/dialogs/over-ride-reason-dialog/edit-over-ride-reason-dialog.module';
import { EditDomainDialogModule } from './modules/secured/management/customers/domains/dialogs/edit-domain-managment-dialog.module';
import { AddSaleGroupDomainDialogModule } from './modules/secured/management/customers/sale-groups/dialogs/sale-group-domain/add-sale-group--domain-managment-dialog.module';
import { CustomerOwnersSandbox } from './modules/secured/management/customers/customer-owners/sandbox/customer-owners.sandbox';
import { MappingBusDialogModule } from './modules/secured/management/inventory/buses/dialogs/mapping-bus-dialog/mapping-bus-dialog.module';

export let AppInjector: Injector;

const DIALOG_MODULES: any[] = [
    OkDialogModule,
    YesNoDialogModule,
    OrderSummaryDialogModule,
    PrintOrderDialogModule,
    SendEmailDialogModule,
    VoidOrderDialogModule,
    EditLocationDialogModule,
    EditLocationListDialogModule,
    EditBidDialogModule,
    EditBusDialogModule,
    EditCompanyInfoDialogModule,
    EditTierDialogModule,
    EditDepartureGroupDialogModule,
    EditPostBillTypeDialogModule,
    EditLetterGroupDialogModule,
    EditHolidayDialogModule,
    MappingHolidayDialogModule,
    MappingBusDialogModule,
    EditAdditionalCostDialogModule,
    EditIncludedCostDialogModule,
    EditDiscountDialogModule,
    EditAutoAppliedDiscountDialogModule,
    MappingCostDialogModule,
    PreviewEmailHtmlTemplateDialogModule,
    EditEmailHtmlTemplateDialogModule,
    AddAndroidDeviceInfoRemoteCommandDialogModule,
    EditKioskDeviceInfoDialogModule,
    EditKioskConfigurationDialogModule,
    CreateDropoffLocationDialogModule,
    ProductTierSetupDialogModule,
    PassTierSetupDialogModule,
    EditQuickSellingButtonPassDialogModule,
    EditQuickSellingButtonProductDialogModule,
    RouteSetupDialogModule,
    EditFavoriteDialogModule,
    EditAutoReportDialogModule,
    EnableRoleDialogModule,
    EditRoleOrUserDialogModule,
    AddNewEntryDialogModule,
    EditOccurrencesDialogModule,
    DeactivateOccurrencesDialogModule,
    OccurrenceManifestDialogModule,
    EnableCategoryDialogModule,
    AddCategoryDialogModule,
    EditCategoryBasicInfoDialogModule,
    EditCategoryEmailHtmlTemplatesDialogModule,
    EditCategoryCostsDialogModule,
    EditCategoryTemplateItemsDialogModule,
    AddProductDialogModule,
    CloneProductDialogModule,
    EditProductBasicInfoDialogModule,
    EditProductFlagsDialogModule,
    EditProductValidityDialogModule,
    EditProductEmailHtmlTemplatesDialogModule,
    EditProductRoutesDialogModule,
    EditProductCostsDialogModule,
    EditProductAvailabilitiesDialogModule,
    CloneProductAvailabilitiesDialogModule,
    EditProductPricingDateRangesDialogModule,
    CloneProductPricingDateRangesDialogModule,
    AddPassDialogModule,
    ClonePassDialogModule,
    EditPassBasicInfoDialogModule,
    EditPassProductsDialogModule,
    EditPassFlagsDialogModule,
    EditPassValidityDialogModule,
    EditPassEmailHtmlTemplatesDialogModule,
    EditPassCostsDialogModule,
    EditPassTemplateItemsDialogModule,
    EditPassPricingDateRangesDialogModule,
    ClonePassPricingDateRangesDialogModule,
    EditPassCardsDialogModule,
    EditCommissionDialogModule,
    MappingCommissionDialogModule,
    InputDialogModule,
    DropdownDialogModule,
    EditCustomerDialogModule,
    EditSaleGroupDialogModule,
    AddSaleGroupCustomerDialogModule,
    EditCloudPaymentDeviceDialogModule,
    EditOverrideReasonDialogModule,
    DateDialogModule,
    ChangePasswordDialogModule,
    EditNotificationDialogModule,
    EditDomainDialogModule,
    AddSaleGroupDomainDialogModule
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppModuleRoutes,
        CoreModule,
        MatTreeModule,
        MatTabsModule,
        MatSelectModule,
        MatCheckboxModule,
        NgApexchartsModule,
        SharedModule.forRoot(),
        NgxPermissionsModule.forRoot(),
        StoreRouterConnectingModule.forRoot(),
        ...DIALOG_MODULES
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        AppConfig,
        RolesAndUsersSandbox,
        CustomerOwnersSandbox,
        CategoriesAndProductsSandbox,
        PassesSandbox,
        CloseDropdownOnScrollService,
        {
            provide: APP_INITIALIZER,
            useFactory: (appConfig: AppConfig) => {
                return () => appConfig.loadConfig();
            },
            deps: [AppConfig],
            multi: true
        }
    ]
})
export class AppModule {
    constructor(private injector: Injector) {
        AppInjector = this.injector;
    }
}
