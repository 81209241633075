import {Component, Inject} from '@angular/core';
import {DialogData} from "../../model/dialog.data";
import {PrintOrderDialogSandbox} from "./print-order-dialog.sandbox";

export class PrintOrderDialogData extends DialogData {

    confirmPrintFn: Function;

    constructor(confirmPrintFn: Function) {
        super();
        this.confirmPrintFn = confirmPrintFn;
    }
}

@Component({
    selector: 'app-print-order-dialog',
    templateUrl: './print-order-dialog.component.html'
})
export class PrintOrderDialogComponent {

    regenerateBarcode: boolean = false;

    confirmPrintFn: Function;

    constructor(@Inject(DialogData) data: PrintOrderDialogData, private sandbox: PrintOrderDialogSandbox) {
        this.confirmPrintFn = data.confirmPrintFn;
    }

    onPrintOrderClick() {
        this.confirmPrintFn(this.regenerateBarcode);
        this.sandbox.destroyDialog();
    }

    onCancelClick() {
        this.sandbox.destroyDialog();
    }

    onRegenerateBarcodeValueChanged(value: boolean) {
        this.regenerateBarcode = value;
    }
}
