import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {EditEmailHtmlTemplateDialogSandbox} from "../sandbox/edit-email-html-template-dialog.sandbox";
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {EditHtmlTemplateFormSubmitDataModel} from "../model/edit-html-template-form-submit-data.model";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditEmailHtmlTemplateDialogData extends DialogData {

    emailHtmlTemplateId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, emailHtmlTemplateId?: number) {
        super();
        this.emailHtmlTemplateId = emailHtmlTemplateId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-email-html-template-dialog',
    templateUrl: './edit-email-html-template-dialog.component.html'
})
export class EditEmailHtmlTemplateDialogComponent implements OnInit, OnDestroy {

    emailHtmlTemplateId: number;
    emailHtmlTemplate$: Observable<EmailHtmlTemplateResponseModel>;
    editMode: boolean = false;

    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) data: EditEmailHtmlTemplateDialogData, private sandbox: EditEmailHtmlTemplateDialogSandbox) {

        this.emailHtmlTemplateId = data.emailHtmlTemplateId;
        this.updateCallbackFn = data.updateCallbackFn;
        this.createCallbackFn = data.createCallbackFn;
    }

    ngOnInit() {
        if (this.emailHtmlTemplateId && !isNaN(this.emailHtmlTemplateId)) {
            this.editMode = true;
            this.sandbox.loadEmailHtmlTemplate(this.emailHtmlTemplateId);
            this.emailHtmlTemplate$ = this.sandbox.emailHtmlTemplate$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: EditHtmlTemplateFormSubmitDataModel) {

        if (this.editMode) {
            this.sandbox.updateEmailHtmlTemplate(this.emailHtmlTemplateId, data).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createEmailHtmlTemplate(data).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
