<div class="category-and-product-dialog-content">
    <h1 class="mb-8px">{{getHeader()}}</h1>
    <form class="pl-8px pr-8px" [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="order-form-item">
                    <label class="order-label">Start date</label>
                    <div class="order-input-wrapper">
                        <app-datetimepicker
                            class="main--element"
                            [placeholderText]="'Choose Start Date'"
                            formControlName="startDate">
                        </app-datetimepicker>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">End Date</label>
                    <div class="order-input-wrapper">
                        <app-datetimepicker
                            class="main--element"
                            [placeholderText]="'Choose End Date'"
                            formControlName="endDate">
                        </app-datetimepicker>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">Suffix to Append</label>
                    <div class="order-input-wrapper">
                        <input class="main--element input-large"
                               placeholder="Suffix"
                               formControlName="suffix"/>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label bold-text">Original Name</label>
                    <label class="order-label bold-text pl-16px">Clone Name</label>
                </div>
                <div class="overflow product-availability-descriptions-wrapper">
                    <div *ngFor="let productAvailabilityForm of getProductAvailabilitiesFormControls(); let i = index">
                        <app-clone-product-availability-dialog-form
                            formArrayName="productAvailabilities"
                            [productAvailabilityForm]="productAvailabilityForm">
                        </app-clone-product-availability-dialog-form>
                    </div>
                </div>
            </div>
        </ng-container>

        <p *ngIf="hasError" class="error">{{ errorMessage }}</p>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Clone</button>
    </div>
</div>
