import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from "moment";
import { FormControl, FormGroup } from '@angular/forms';
import { FilterFormValueModel } from '../../../model/filter-form-value.model';
import { Subscription } from 'rxjs';
import {
    MultiselectDropdownOptionModel,
    OPTION_ALL
} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import { ProductDirectionDataModel } from "../../../model/product-direction-data.model";
import { DropdownOptionModel } from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import * as data from '../../../model/data';


@Component({
    selector: 'app-driver-manifest-report-filter',
    templateUrl: './driver-manifest-report-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverManifestReportFilterComponent implements OnInit, OnDestroy, OnChanges {

    @Input() productDirections: ProductDirectionDataModel[];
    @Input() loadingProducts: boolean;

    @Input() selectedFavoriteId: number;
    @Input() selectedDateType: string;
    @Input() selectedDate: moment.Moment;
    @Input() selectedProductDirections: string[];
    @Input() favoriteCreatedUpdated: boolean;

    isDateTypeCustom: boolean = true;
    private previousSelectedDateType: string = data.DATE_CUSTOM;
    private previousSelectedDate: moment.Moment = moment();
    private previousSelectedProductDirections: string[] = [];
    private showInactiveProducts: boolean;

    @Output() filterChangedEvent: EventEmitter<FilterFormValueModel> = new EventEmitter();

    filterForm: FormGroup;
    dateTypeOptions: DropdownOptionModel[] = [
        new DropdownOptionModel("Custom", data.DATE_CUSTOM),
        new DropdownOptionModel('Today', data.DATE_TODAY),
        new DropdownOptionModel('Tomorrow', data.DATE_TOMORROW)
    ];
    productDirectionOptions: MultiselectDropdownOptionModel[];

    private filterFormChangeSubscription: Subscription;

    constructor() {
        this.initForm();
    }

    ngOnInit() {

        this.showInactiveProducts = false;

        this.filterFormChangeSubscription = this.filterForm.valueChanges.subscribe((filterFormValue: FilterFormValueModel) => {

            // 1) Collect selected values
            // 1.1) Selected product-direction data
            // Get list of selected product-direction data
            let selectedProductDirections: string[] = filterFormValue.selectedProductDirections
                .filter((selectedProductDirection: any) => selectedProductDirection !== OPTION_ALL)
                .map((selectedProductDirection: any) => {
                    return selectedProductDirection;
                });

            // Update selectedProductDirections with real selected product directions
            filterFormValue.selectedProductDirections = selectedProductDirections;

            // 1.2) Selected date type
            // Set selectedDate to specific date if TODAY or TOMORROW date type selected
            if (filterFormValue.selectedDateType === data.DATE_TODAY) {
                filterFormValue.selectedDate = moment();
                this.selectedDate = filterFormValue.selectedDate;
            } else if (filterFormValue.selectedDateType === data.DATE_TOMORROW) {
                filterFormValue.selectedDate = moment().add(1, 'days');
                this.selectedDate = filterFormValue.selectedDate;
            }

            // 2) Check if filter values actually have changed; if not, do not fire event
            // 2.1) Check if selected product directions changed
            let selectedProductDirectionsChanged: boolean = false;
            for (let productDirection of this.previousSelectedProductDirections) {
                if (filterFormValue.selectedProductDirections.indexOf(productDirection) === -1) {
                    selectedProductDirectionsChanged = true;
                    break;
                }
            }
            if (!selectedProductDirectionsChanged) {
                for (let productDirection of filterFormValue.selectedProductDirections) {
                    if (this.previousSelectedProductDirections.indexOf(productDirection) === -1) {
                        selectedProductDirectionsChanged = true;
                        break;
                    }
                }
            }

            // If any filter value changed, emit event
            if (this.previousSelectedDateType !== filterFormValue.selectedDateType || filterFormValue.showInactiveProducts != this.showInactiveProducts ||
                ((this.previousSelectedDate === null && filterFormValue.selectedDate !== null) || (this.previousSelectedDate !== null && filterFormValue.selectedDate === null)) ||
                (this.previousSelectedDate !== null && filterFormValue.selectedDate !== null && (this.previousSelectedDate.year() !== filterFormValue.selectedDate.year() || this.previousSelectedDate.month() !== filterFormValue.selectedDate.month() || this.previousSelectedDate.date() !== filterFormValue.selectedDate.date())) ||
                selectedProductDirectionsChanged) {

                if (filterFormValue.showInactiveProducts != this.showInactiveProducts) {
                    this.showInactiveProducts = filterFormValue.showInactiveProducts;
                    this.populateDropdownMenu();
                }

                // Update isDateTypeCustom flag
                this.isDateTypeCustom = filterFormValue.selectedDateType === data.DATE_CUSTOM;

                this.previousSelectedDateType = filterFormValue.selectedDateType;
                this.previousSelectedDate = filterFormValue.selectedDate;
                this.previousSelectedProductDirections = [...filterFormValue.selectedProductDirections];

                this.filterChangedEvent.emit({
                    selectedDateType: filterFormValue.selectedDateType,
                    selectedDate: filterFormValue.selectedDate,
                    selectedProductDirections: [...filterFormValue.selectedProductDirections],
                    isFavoriteSelected: false,
                    showInactiveProducts: filterFormValue.showInactiveProducts

                });
            }
        });
    }

    ngOnDestroy() {
        if (this.filterFormChangeSubscription) {
            this.filterFormChangeSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes) {

        // 1) Selected product-direction data
        if (changes.productDirections) {
            this.populateDropdownMenu();
        }

        // 2) Update filter
        // Set filter to provided values (but don't create new form group!)
        this.isDateTypeCustom = this.selectedDateType === data.DATE_CUSTOM;

        this.previousSelectedDateType = this.selectedDateType;
        this.previousSelectedDate = this.selectedDate;
        this.previousSelectedProductDirections = [...this.selectedProductDirections];

        this.filterForm.patchValue({
            selectedDateType: this.selectedDateType,
            selectedDate: this.selectedDate,
            selectedProductDirections: [...this.selectedProductDirections]
        });

        if (changes.selectedFavoriteId && !changes.favoriteCreatedUpdated) {

            // If not new favorite added, trigger filter changed event with flag isFavoriteSelected = true, in order to refresh UI
            this.filterChangedEvent.emit({
                selectedDateType: this.selectedDateType,
                selectedDate: this.selectedDate,
                selectedProductDirections: [...this.selectedProductDirections],
                isFavoriteSelected: true,
                showInactiveProducts: this.showInactiveProducts
            });
        }
    }

    populateDropdownMenu() {
        this.productDirectionOptions = this.productDirections
            .filter((productDirection: ProductDirectionDataModel) => {
                if (this.filterForm.value.showInactiveProducts || productDirection.isActive) {
                    return true;
                }
                return false;
            })
            .map((productDirection: ProductDirectionDataModel) => {
                return new MultiselectDropdownOptionModel(productDirection.description, productDirection.productId.toString() + "_" + productDirection.isReturnTrip);
            });
    }


    clearFilter() {
        // Reset filter to initial state (but don't create new form group!)
        this.filterForm.patchValue({
            selectedDateType: data.DATE_CUSTOM,
            selectedDate: moment(),
            selectedProductDirections: []
        });
    }

    private initForm() {
        this.filterForm = new FormGroup({
            selectedDateType: new FormControl(data.DATE_CUSTOM),
            selectedDate: new FormControl(moment()),
            selectedProductDirections: new FormControl([]),
            showInactiveProducts: new FormControl(false)
        });
    }
}
