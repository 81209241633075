<app-abstract-dialog-container alt-design>
    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>Print Order</h1>
            </div>
        </div>
    </div>

    <div class="body_wrapper formRow">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <div class="checkboxLabelGroup">
                    <app-checkbox
                        [labelName]="'Regenerate Barcode(s)'"
                        [checked]="regenerateBarcode"
                        (valueChanged)="onRegenerateBarcodeValueChanged($event)">
                    </app-checkbox>
                </div>
            </div>
        </div>
    </div>

    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>

            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    (clickEvent)="onPrintOrderClick()">
                    Print
                </app-button>
            </div>
        </div>
    </div>
</app-abstract-dialog-container>
