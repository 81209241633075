<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmit()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>Connect Merchant</h1>
            </div>
        </div>
    </div>

    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">
        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group *ngIf="merchants && !merchant">
                        <label class="label">Select Merchant Account</label>
                        <app-dropdown class="main--element"
                            [customClasses]="'dropdown_large'"
                            [options]='merchantsOptions'
                            formControlName="merchant"
                            (changed)="merchant = $event">
                        </app-dropdown>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="cell medium-4">
            <app-input-label-group *ngIf="merchant">
                <label class="label">Select Android devices</label>
                <app-multiselect-dropdown
                    class="main--element"
                    [allOptionEnabled]="true"
                    [options]="androidInfoDeviceOptions"
                    (changed)="onSelectedCustomersChanged($event)">
                </app-multiselect-dropdown>
            </app-input-label-group>
        </div>
    </div><!-- Dialog Content :: END -->

    
    <div class="errorMessage_wrapper"
         *ngIf="errorMessage">
        <label class="error">
            {{ errorMessage }}
        </label>
    </div>

    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">

            <div class="cell medium-6" *ngIf="merchant">
                <app-button
                    [buttonType]='"primary"'
                    (clickEvent)="onBack()">
                    Back
                </app-button>
            </div>

            <div class="cell medium-6" *ngIf="!merchant">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onClose()">
                    Cancel
                </app-button>
            </div>

            <div class="cell medium-6" *ngIf="merchant">
                <app-button
                    [buttonType]='"primary"'
                    (clickEvent)="onSubmit()">
                    Save
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
