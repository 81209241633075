import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {RouteSetupDialogSandbox} from "./sandbox/route-setup-dialog.sandbox";
import {RouteSetupDialogComponent} from "./container/route-setup-dialog.component";
import {StoreModule} from "@ngrx/store";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {RouteSetupDialogContentComponent} from './components/route-setup-dialog-content/route-setup-dialog-content.component';
import {RouteSetupProductComponent} from "./components/route-setup-product/route-setup-product.component";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        RouteSetupDialogSandbox
    ],
    declarations: [
        RouteSetupDialogComponent,
        RouteSetupDialogContentComponent,
        RouteSetupProductComponent
    ],
    exports: [
        RouteSetupDialogComponent
    ],
    entryComponents: [
        RouteSetupDialogComponent
    ]
})
export class RouteSetupDialogModule {

}
