import {Component, Input} from '@angular/core';
import {BreadcrumbItemModel} from '../../breadcrumbs/breadcrumb-item.model';

@Component({
    selector: 'app-standard-page-layout',
    templateUrl: './standard-page-layout.component.html',
    styleUrls: ['./standard-page-layout.component.scss']
})
export class StandardPageLayoutComponent {

    @Input() pageDataLoaded: boolean;
    @Input() pageStyleId = '';
    @Input() pageTitle;
    @Input() loadingText: string = "Loading...";
    @Input() pageBreadcrumbItems: BreadcrumbItemModel[];

    constructor() {
    }
}
