import {NgModule} from '@angular/core';
import {OrderReportsComponent} from './order-reports.component';

import {SharedModule} from '../../../../../shared/shared.module';
import {OrderReportsRoutes} from './order-reports.routes';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {StoreModule} from '@ngrx/store';
import {REDUCER_NAME, reducers} from './store/reducers';
import {OrderReportsSandbox} from './store/order-reports.sandbox';
import {NgxPermissionsModule} from 'ngx-permissions';
import {OrderReportsSidebarComponent} from './order-reports-sidebar/order-reports-sidebar.component';
import {OrderReportsExpressionItemComponent} from './order-reports-main-content/order-reports-selected-filters-area/order-reports-expression-item/order-reports-expression-item.component';
import {OrderReportsExpressionFilterComponent} from './order-reports-main-content/order-reports-selected-filters-area/order-reports-expression-item/order-reports-expression-filter/order-reports-expression-filter.component';
import {OrderReportsExpressionOperatorComponent} from './order-reports-main-content/order-reports-selected-filters-area/order-reports-expression-item/order-reports-expression-operator/order-reports-expression-operator.component';
import {OrderReportsExpressionGroupComponent} from './order-reports-main-content/order-reports-selected-filters-area/order-reports-expression-item/order-reports-expression-group/order-reports-expression-group.component';
import {OrderReportsFieldsInfoMessageComponent} from './order-reports-main-content/order-reports-selected-fields-area/order-reports-fields-info-message/order-reports-fields-info-message.component';
import {OrderReportsFieldItemComponent} from './order-reports-main-content/order-reports-selected-fields-area/order-reports-field-item/order-reports-field-item.component';
import {OrderReportsSelectedFieldsAreaComponent} from './order-reports-main-content/order-reports-selected-fields-area/order-reports-selected-fields-area.component';
import {OrderReportsSelectedFiltersAreaComponent} from './order-reports-main-content/order-reports-selected-filters-area/order-reports-selected-filters-area.component';
import {OrderReportsSidebarReportItemComponent} from './order-reports-sidebar/order-reports-sidebar-report-group/order-reports-sidebar-report-item/order-reports-sidebar-report-item.component';
import {DropAreaComponent} from './order-reports-main-content/drop-area/drop-area.component';
import {OrderReportsFieldDropAreaComponent} from './order-reports-main-content/order-reports-selected-fields-area/order-reports-field-drop-area/order-reports-field-drop-area.component';
import {OrderReportsFilterDropAreaComponent} from './order-reports-main-content/order-reports-selected-filters-area/order-reports-filter-drop-area/order-reports-filter-drop-area.component';
import {FilterDateComponent} from './order-reports-main-content/order-reports-selected-filters-area/order-reports-expression-item/order-reports-expression-filter/dynamic-filter/filter-date/filter-date.component';
import {DynamicFilterComponent} from './order-reports-main-content/order-reports-selected-filters-area/order-reports-expression-item/order-reports-expression-filter/dynamic-filter/dynamic-filter.component';
import {FilterDropdownMultiselectComponent} from './order-reports-main-content/order-reports-selected-filters-area/order-reports-expression-item/order-reports-expression-filter/dynamic-filter/filter-dropdown-multiselect/filter-dropdown-multiselect.component';
import {FilterInputComponent} from './order-reports-main-content/order-reports-selected-filters-area/order-reports-expression-item/order-reports-expression-filter/dynamic-filter/filter-input/filter-input.component';
import {OrderReportsSidebarReportGroupComponent} from './order-reports-sidebar/order-reports-sidebar-report-group/order-reports-sidebar-report-group.component';
import {MousePointerOverDropAreaService} from './service/mouse-pointer-over-drop-area.service';
import {OrderReportsTableWrapperComponent} from './order-reports-table-wrapper/order-reports-table-wrapper.component';
import {OrderReportsTableCaptionComponent} from './order-reports-table-wrapper/order-reports-table-caption/order-reports-table-caption.component';
import {SumHeaderItemComponent} from './order-reports-table-wrapper/sum-header-item/sum-header-item.component';
import {OrderReportHeaderComponent} from './order-report-header/order-report-header.component';
import {OrderReportsTableElementComponent} from './order-reports-table-wrapper/order-reports-table-element/order-reports-table-element.component';
import {NgDragDropModule} from 'ng-drag-drop';
import { OrderReportSidebarFavoriteComponent } from './order-report-sidebar-favorite/order-report-sidebar-favorite.component';
import { OrderReportSidebarFavoriteGroupComponent } from './order-report-sidebar-favorite/order-report-sidebar-favorite-group/order-report-sidebar-favorite-group.component';


@NgModule({
    imports: [
        SharedModule,
        OrderReportsRoutes,
        StoreModule.forFeature(REDUCER_NAME, reducers),
        NgxPermissionsModule.forChild(),
        NgDragDropModule.forRoot(),
        InfiniteScrollModule
    ],
    declarations: [
        OrderReportsComponent,
        OrderReportsTableWrapperComponent,
        FilterDateComponent,
        DynamicFilterComponent,
        FilterDropdownMultiselectComponent,
        FilterInputComponent,
        OrderReportsTableCaptionComponent,
        SumHeaderItemComponent,
        OrderReportHeaderComponent,
        OrderReportsTableElementComponent,
        OrderReportsSidebarComponent,
        OrderReportsSidebarReportItemComponent,

        OrderReportsExpressionItemComponent,
        OrderReportsExpressionFilterComponent,
        OrderReportsExpressionOperatorComponent,
        OrderReportsExpressionGroupComponent,
        OrderReportsFieldsInfoMessageComponent,
        OrderReportsFieldItemComponent,
        OrderReportsSelectedFieldsAreaComponent,
        OrderReportsSelectedFiltersAreaComponent,
        OrderReportsSidebarReportItemComponent,
        DropAreaComponent,
        OrderReportsFieldDropAreaComponent,
        OrderReportsFilterDropAreaComponent,
        OrderReportsSidebarReportGroupComponent,
        OrderReportSidebarFavoriteComponent,
        OrderReportSidebarFavoriteGroupComponent,
    ],
    providers: [
        OrderReportsSandbox,
        MousePointerOverDropAreaService,
    ],
    entryComponents: [
        FilterDateComponent,
        FilterDropdownMultiselectComponent,
        FilterInputComponent,
    ]
})
export class OrderReportsModule {

}
