<app-order-reports-filter-drop-area
    [enabled]="dropAreasVisible && expressionItem.itemType === 'OPERATOR' && !isRightExpressionItemCurrentlyInDrag() && !isLeftExpressionItemCurrentlyInDrag()"
    [itemDepth]="itemDepth"
    [draggedItemHeight]="draggedItemHeight"
    [parentGroupUUID]="parentGroupUUID"
    [leftSiblingUUID]="leftSiblingUUID"
    (deleteExpressionItemEvent)="onDeleteExpressionItem($event)"
    (droppedDataEvent)="onDroppedData($event)"
    (dragEndEvent)="onDragEnd()">
</app-order-reports-filter-drop-area>

<!-- Expression Item :: START -->
<li class="expressionItem">
    <app-order-reports-expression-group
        *ngIf="expressionItem.itemType === 'GROUP'"
        [itemDepth]="itemDepth + 1"
        [draggedItemHeight]="draggedItemHeight"
        [expressionGroupItem]="expressionItem"
        [draggedData]="draggedData"
        [dropAreasVisible]="dropAreasVisible"
        [hasRunOrderReportFullPermission]="hasRunOrderReportFullPermission"
        (droppedDataEvent)="onDroppedData($event)"
        (deleteExpressionItemEvent)="onDeleteExpressionItem($event)"
        (dragStartEvent)="onDragStart($event)"
        (dragEndEvent)="onDragEnd()"
        (expressionItemValueChangedEvent)="onExpressionItemValueChanged($event)">
    </app-order-reports-expression-group>

    <app-order-reports-expression-filter
        *ngIf="expressionItem.itemType === 'FILTER'"
        [expressionFilterItem]="expressionItem"
        [hasRunOrderReportFullPermission]="hasRunOrderReportFullPermission"
        (deleteExpressionItemEvent)="onDeleteExpressionItem($event)"
        (dragStartEvent)="onDragStart($event)"
        (dragEndEvent)="onDragEnd()"
        (filterValueChanged)="onExpressionItemValueChanged($event)">
    </app-order-reports-expression-filter>

    <app-order-reports-expression-operator
        *ngIf="expressionItem.itemType === 'OPERATOR'"
        [hasRunOrderReportFullPermission]="hasRunOrderReportFullPermission"
        [expressionOperatorItem]="expressionItem"
        (operatorValueChanged)="onExpressionItemValueChanged($event)">
    </app-order-reports-expression-operator>
</li>
<!-- Expression Item :: END-->
