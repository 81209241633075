import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { of as ofObservable, Observable, BehaviorSubject } from 'rxjs';
import { DynamicPermissionTypeEnum, PermissionNode } from '../../../modules/secured/management/system/roles-and-users/model/permission-node';
import { TreeItemTypeEnum } from '../../../modules/secured/management/system/roles-and-users/model/tree-item-type.enum';
import { RootSandbox } from '../../../core/store/root.sandbox';
import { RolesAndUsersSandbox } from '../../../modules/secured/management/system/roles-and-users/sandbox/roles-and-users.sandbox';
import { PermissionEnum } from "../../../shared/enums/permission.enum";
import { SharedSandbox } from '../../shared.sandbox';
import { UserInfoResponseModel } from '../../../core/modules/rest/user/response/user-info-response.model';
import { environment } from '../../../../environments/environment';
import { SystemPermissionsRestService } from '../../../core/modules/rest/system-permissions/system-permissions-rest.service';
import { SystemPermissionsResponseModel } from '../../../core/modules/rest/system-permissions/response/system-permissions-response.model';
import { SystemPermissionEnum } from '../../enums/system-permission.enum';
import { CustomerOwnersSandbox } from '../../../modules/secured/management/customers/customer-owners/sandbox/customer-owners.sandbox';

/**
 * Node for to-do item
 */
export class PermissionItemNode {
    children: PermissionItemNode[];
    item: string;
    level: number;
    expandable: boolean;
    isChecked: boolean;
    isOpen: boolean;
    id: number;
    subtype: DynamicPermissionTypeEnum;
    roleActive: boolean;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
    item: string;
    level: number;
    expandable: boolean;
    children: PermissionItemNode[];
    isChecked: boolean;
    isOpen: boolean;
    id: number;
    subtype: DynamicPermissionTypeEnum;
    roleActive: boolean;
}

const TREE_DATA = {};

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
    dataChange: BehaviorSubject<PermissionItemNode[]> = new BehaviorSubject<PermissionItemNode[]>([]);

    get data(): PermissionItemNode[] { return this.dataChange.value; }

    constructor() {
        this.initialize();
    }

    initialize() {
        // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
        //     file node as children.
        const data = this.buildFileTree(TREE_DATA, 0);

        // Notify the change.
        this.dataChange.next(data);
    }

    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `TodoItemNode`.
     */
    buildFileTree(value: any, level: number) {
        let data: any[] = [];
        for (let k in value) {
            let v = value[k];
            let node = new PermissionItemNode();
            node.item = `${k}`;
            if (v === null || v === undefined) {
                // no action
            } else if (typeof v === 'object') {
                node.children = this.buildFileTree(v, level + 1);
            } else {
                node.item = v;
            }
            data.push(node);
        }
        return data;
    }

    /** Add an item to to-do list */
    insertItem(parent: PermissionItemNode, name: string) {
        const child = <PermissionItemNode>{ item: name };
        if (parent.children) {
            parent.children.push(child);
            this.dataChange.next(this.data);
        }
    }

    updateItem(node: PermissionItemNode, name: string) {
        node.item = name;
        this.dataChange.next(this.data);
    }
}

/**
 * @title Tree with checkboxes
 */
@Component({
    selector: 'app-tree-permission',
    templateUrl: './tree-permission.component.html',
    providers: [ChecklistDatabase]
})
export class TreePermissionComponent implements OnInit {

    @Input() permissions: PermissionNode[] = [];

    @Input() rolePermissions: PermissionNode[] = [];

    @Input() itemType: TreeItemTypeEnum = TreeItemTypeEnum.USER;

    @Output() permissionChecked: EventEmitter<TodoItemFlatNode> = new EventEmitter();

    nodes: PermissionNode[] = [];



    /** Map from flat node to nested node. This helps us finding the nested node to be modified */
    flatNodeMap: Map<TodoItemFlatNode, PermissionItemNode> = new Map<TodoItemFlatNode, PermissionItemNode>();

    /** Map from nested node to flattened node. This helps us to keep the same object for selection */
    nestedNodeMap: Map<PermissionItemNode, TodoItemFlatNode> = new Map<PermissionItemNode, TodoItemFlatNode>();

    /** A selected parent node to be inserted */
    selectedParent: TodoItemFlatNode | null = null;

    /** The new item's name */
    newItemName: string = '';

    treeControl: FlatTreeControl<TodoItemFlatNode>;

    treeFlattener: MatTreeFlattener<PermissionItemNode, TodoItemFlatNode>;

    dataSource: MatTreeFlatDataSource<PermissionItemNode, TodoItemFlatNode>;

    systemPermissions: SystemPermissionsResponseModel[];

    user: UserInfoResponseModel

    /** The selection for checklist */
    checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

    node: TodoItemFlatNode;

    constructor(private database: ChecklistDatabase, private rootSandbox: RootSandbox, private rolesAndUsersandbox: RolesAndUsersSandbox, private customerOwnerssandbox: CustomerOwnersSandbox,  private systemPermissionsRestService: SystemPermissionsRestService, private sharedSandbox: SharedSandbox) {
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
            this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        database.dataChange.subscribe(data => {
            this.dataSource.data = data;
        });
    }

    toggleNode(node: any): void {
        node.isOpen = !node.isOpen;
    }

    ngOnInit(): void {

        this.systemPermissionsRestService.getSystemPermissions().subscribe((res: SystemPermissionsResponseModel[]) => {
            this.systemPermissions = res;
            this.nodes = this.permissions
            this.itemType === TreeItemTypeEnum.USER ? this.dataSource.data = this.prepareUserTree() : this.dataSource.data = this.prepareRoleTree()
            for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
                if (this.treeControl.dataNodes[i].isChecked)
                    this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
            }
        });
        this.sharedSandbox.getUserInfo().subscribe(res => {
            this.user = res
        });
    }


    prepareUserTree(parsedNodes = []) {
        this.nodes.forEach((childList, i) => {
            let parsedChildren = []
            if (childList.children && childList.children.length !== 0) {
                parsedChildren = this.iterateUserChildren(childList.children, this.rolePermissions[i].children)
            }
            const children = {
                id: childList.id,
                item: childList.label,
                children: parsedChildren,
                isChecked: childList.checked,
                isOpen: childList.open,
                subtype: childList.subtype,
                roleActive: this.rolePermissions[i].checked
            }
            parsedNodes.push(children);
        });
        return parsedNodes
    }

    prepareRoleTree(parsedNodes = []) {
        this.nodes.forEach((childList, i) => {
            let parsedChildren = []
            if (childList.children && childList.children.length !== 0) {
                parsedChildren = this.iterateRoleChildren(childList.children)
            }
            const children = {
                id: childList.id,
                item: childList.label,
                children: parsedChildren,
                isChecked: childList.checked,
                isOpen: childList.open,
                subtype: childList.subtype,
            }
            parsedNodes.push(children);
        });
        return parsedNodes
    }

    iterateRoleChildren(children: PermissionNode[]) {
        let parsedChildrenChildren = [];
        let returnData = [];
        for (let c in children) {
            if (children[c].children && children[c].children.length !== 0) {
                parsedChildrenChildren = this.iterateRoleChildren(children[c].children)
            }
            else {
                parsedChildrenChildren = null
            }
            const childrenChild = {
                id: children[c].id,
                item: children[c].label,
                children: parsedChildrenChildren,
                isChecked: children[c].checked,
                isOpen: children[c].open,
                subtype: children[c].subtype,
            };
            this.setTreeElement(childrenChild, returnData);
        }
        return returnData;
    }

    iterateUserChildren(children: PermissionNode[], roleChildren: PermissionNode[]) {
        let parsedChildrenChildren = [];
        let returnData = [];
        for (let c in children) {
            if (children[c].children && children[c].children.length !== 0) {
                parsedChildrenChildren = this.iterateUserChildren(children[c].children, roleChildren[c].children)
            }
            else {
                parsedChildrenChildren = null
            }
            const childrenChild = {
                id: children[c].id,
                item: children[c].label,
                children: parsedChildrenChildren,
                isChecked: children[c].checked,
                isOpen: children[c].open,
                subtype: children[c].subtype,
                roleActive: roleChildren[c].checked
            };
            this.setTreeElement(childrenChild, returnData);
        }
        return returnData;
    }

    private setTreeElement(childrenChild: { id: string | number; item: string; children: any[]; isChecked: boolean; isOpen: boolean; subtype: DynamicPermissionTypeEnum; }, returnData: any[]) {
        let useRevenueReport =  this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.REVENUE_REPORT)?.active ?? false;
        let useNetSuiteExportReport =  this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.NET_SUITE_EXPORT_REPORT)?.active ?? false;
        let useCustomBillingReport =  this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.CUSTOM_BILLING_REPORT)?.active ?? false;
        let useKiosk =  this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.KIOSK)?.active ?? false;
        let useKioskConfiguration =  this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.KIOSK_CONFIGURATION)?.active ?? false;
        let useCustomers =  this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.USE_CUSTOMERS)?.active ?? false;
        let useCloudPayment =  this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.CLOUD_PAYMENT)?.active ?? false;
        let useLegacyTicket =  this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.LEGACY_TICKET)?.active ?? false;
        let useOverrideReason =  this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.USE_OVERRIDE_REASON)?.active ?? false;
        switch (childrenChild.id) {
            case PermissionEnum.USE_LOGIN_LOCATIONS:
                break;
            case PermissionEnum.RUN_CUSTOM_REPORTS:
                if (useRevenueReport || useNetSuiteExportReport || useCustomBillingReport)
                    returnData.push(childrenChild);
                break;
            case PermissionEnum.RUN_REVENUE_REPORT:
                if (useRevenueReport)
                    returnData.push(childrenChild);
                break;
            case PermissionEnum.RUN_NET_SUITE_EXPORT_REPORT:
                if (useNetSuiteExportReport)
                    returnData.push(childrenChild);
                break;
            case PermissionEnum.RUN_CUSTOM_BILLING_REPORT:
                if(useCustomBillingReport)
                    returnData.push(childrenChild);
                break;
            case PermissionEnum.MANAGE_KIOSKS:
                if (useKiosk)
                    returnData.push(childrenChild);
                break;
            case PermissionEnum.MANAGE_KIOSK_CONFIGURATION_DETAILS:
                if (useKioskConfiguration)
                    returnData.push(childrenChild);
                break;
            case PermissionEnum.CUSTOMERS_ADMINISTRATION:
                if (useCustomers)
                    returnData.push(childrenChild);
                break;
            case PermissionEnum.ANDROID_DEBUG_LOGS:
                if (this.isAdminUser()) {
                    returnData.push(childrenChild);
                }
                break;
            case PermissionEnum.MANAGE_CLOUD_PAYMENTS:
                if (useCloudPayment)
                    returnData.push(childrenChild);
                break;
            case PermissionEnum.USE_CLOUD_PAYMENTS:
                if (useCloudPayment)
                    returnData.push(childrenChild);
                break;
            case PermissionEnum.CREATE_ORDER_LONG_FORM_CLOUD_PAYMENT_DEVICE:
                if (useCloudPayment)
                    returnData.push(childrenChild);
                break;
             case PermissionEnum.CAN_CREATE_LEGACY_TICKET_SCANS:
                if (useLegacyTicket)
                    returnData.push(childrenChild);
                break;
            case PermissionEnum.MANAGE_OVERRIDE_REASON:
                if (useOverrideReason)
                    returnData.push(childrenChild);
                break;
            case PermissionEnum.CAN_DO_OVERRIDE_SCAN:
                if (useOverrideReason)
                    returnData.push(childrenChild);
                break;
            default:
                returnData.push(childrenChild);
        }
    }

    isAdminUser() {
        return environment.adminUserId === this.user.userId;
    }

    getLevel = (node: TodoItemFlatNode) => { return node.level; };

    isExpandable = (node: TodoItemFlatNode) => { return node.expandable; };

    getChildren = (node: PermissionItemNode): Observable<PermissionItemNode[]> => {
        return ofObservable(node.children);
    }

    hasChild = (_: number, _nodeData: TodoItemFlatNode) => { return _nodeData.expandable; };

    hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => { return _nodeData.item === ''; };

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: PermissionItemNode, level: number) => {
        // Retrieve or create the flat node
        let flatNode = this.nestedNodeMap.has(node) && this.nestedNodeMap.get(node)!.item === node.item
            ? this.nestedNodeMap.get(node)!
            : new TodoItemFlatNode();

        // Map properties from the nested node to the flat node
        flatNode.item = node.item;
        flatNode.id = node.id;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        flatNode.isChecked = node.isChecked;
        flatNode.isOpen = node.isOpen;
        flatNode.subtype = node.subtype;
        flatNode.roleActive = node.roleActive;
        flatNode.children = node.children;

        // Update mappings between nested and flat nodes
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);

        // Adjust `isChecked` based on the state of the children
        if (node.children && node.children.length > 0) {
            const childNodes = node.children.map(child => this.transformer(child, level + 1)); // Recursively process children
            const allChildrenChecked = childNodes.every(child => child.isChecked);
            const anyChildChecked = childNodes.some(child => child.isChecked);

            // Update the `isChecked` state based on children's state
            flatNode.isChecked = allChildrenChecked ? true : anyChildChecked ? true : false;
        }

        return flatNode;
    };

    /** Whether all the descendants of the node are selected */
    descendantsAllSelected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        return descendants.every(child => this.checklistSelection.isSelected(child));
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    deepClone(obj, hash = new WeakMap()) {
        if (Object(obj) !== obj || obj instanceof Function) return obj;
        if (hash.has(obj)) return hash.get(obj);
        try {
            var result = new obj.constructor();
        } catch (e) {
            result = Object.create(Object.getPrototypeOf(obj));
        }
        if (obj instanceof Map)
            Array.from(obj, ([key, val]) => result.set(this.deepClone(key, hash),
                this.deepClone(val, hash)));
        else if (obj instanceof Set)
            Array.from(obj, (key) => result.add(this.deepClone(key, hash)));
        hash.set(obj, result);
        return Object.assign(result, ...Object.keys(obj).map(
            key => ({ [key]: this.deepClone(obj[key], hash) })));
    }

    descendantsAllUnselected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        return descendants.every(child => !this.checklistSelection.isSelected(child));
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    async todoItemSelectionToggle(node: TodoItemFlatNode): Promise<void> {
        this.permissionChecked.emit(node);
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        node.isChecked = !node.isChecked
        const checkSeleced = node.isChecked
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);
        this.checkAllParentsSelection(node);

        if (checkSeleced && node.children) {
            for (let child in node.children) {
                let treeNode = this.treeControl.dataNodes.filter(nodeLocal => nodeLocal.item === node.children[child].item && nodeLocal.id === node.children[child].id && nodeLocal.subtype === node.children[child].subtype);
                treeNode[0].isChecked = !treeNode[0].isChecked
                node.children[child].isChecked = !node.children[child].isChecked
                if (node.children[child].children) {
                    this.iterateTreeChildren(node.children[child].children);
                }
            }
        }

        let resultTrue = descendants.every((val, i, arr) => val.isChecked === true);
        let resultFalse = descendants.every((val, i, arr) => val.isChecked === false);
        if (!resultTrue && !resultFalse) {
            this.checklistSelection.deselect(node)
            node.isChecked = false;
            this.checklistSelection.deselect(...descendants)
            for (let descendant in descendants) {
                descendants[descendant].isChecked = false;
            }
        }
        let cloned = await this.deepClone(this.treeControl.dataNodes);
        if(this.itemType == TreeItemTypeEnum.CUSTOMER) {
            this.customerOwnerssandbox.setPermissionTreeMaterial(cloned);
        }
        else {
            this.rolesAndUsersandbox.setPermissionTreeMaterial(cloned);
        }
    }

    iterateTreeChildren(children) {
        for (let child in children) {
            let treeNode = this.treeControl.dataNodes.filter(nodeLocal => nodeLocal.item === children[child].item && nodeLocal.id === children[child].id && nodeLocal.subtype === children[child].subtype);
            treeNode[0].isChecked = !treeNode[0].isChecked
            if (children[child].children) {
                this.iterateTreeChildren(children[child].children)
            }
        }
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    async todoLeafItemSelectionToggle(node: TodoItemFlatNode): Promise<void> {
        node.isChecked = !node.isChecked
        this.permissionChecked.emit(this.node);
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
        let cloned = await this.deepClone(this.treeControl.dataNodes);
        if(this.itemType == TreeItemTypeEnum.CUSTOMER) {
            this.customerOwnerssandbox.setPermissionTreeMaterial(cloned);
        }
        else{
            this.rolesAndUsersandbox.setPermissionTreeMaterial(cloned);
        }
    }

    checkAllParentsSelection(node: TodoItemFlatNode): void {
        let parent: TodoItemFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: TodoItemFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        const descSomeSelected = descendants.some(child =>
            this.checklistSelection.isSelected(child)
        );
        if (descSomeSelected) {
            this.checklistSelection.select(node);
            node.isChecked = true;
        }
        else if (!descSomeSelected) {
            this.checklistSelection.deselect(node);
            node.isChecked = false;
        }
    }

    async inheritFromRole(node: TodoItemFlatNode) {

        this.permissionChecked.emit(node);

        if (node.roleActive) {
            this.checklistSelection.select(node);
            node.isChecked = true;
        }
        else {
            this.checklistSelection.deselect(node);
            node.isChecked = false;
        }
        this.checkAllParentsSelection(node);

        const descendants = this.treeControl.getDescendants(node);

        for (let descendant in descendants) {
            if (descendants[descendant].roleActive) {
                this.checklistSelection.select(descendants[descendant]);
                descendants[descendant].isChecked = true;
            }
            else {
                this.checklistSelection.deselect(descendants[descendant]);
                descendants[descendant].isChecked = false;
            }
        }

        let cloned = await this.deepClone(this.treeControl.dataNodes);
        if(this.itemType == TreeItemTypeEnum.CUSTOMER) {
            this.customerOwnerssandbox.setPermissionTreeMaterial(cloned);
        }
        else {
            this.rolesAndUsersandbox.setPermissionTreeMaterial(cloned);
        }
    }

}
