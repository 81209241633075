import { Component, OnInit  } from '@angular/core';



@Component({
  selector: 'app-tab-content-item',
  templateUrl: './tab-content-item.component.html',
  styleUrls: ['./tab-content-item.component.scss']
})
export class TabContentItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
