
import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import { DialogSandbox } from "../../../services/dialog.sandbox";
import { Injectable } from "@angular/core";
import { AppDialogsService } from "../../../../../core/services/app-dialogs.service";
import { RootSandbox } from "../../../../../core/store/root.sandbox";
import { Store } from "@ngrx/store";
import { OrderSummaryRestService } from '../../../../../core/modules/rest/order-summary/order-summary-rest.service';
import { VoidOrderRequestModel } from '../../../../../core/modules/rest/order-summary/request/void-order-request.model';
import { HttpErrorModel } from '../../../../../core/modules/rest/http-error.model';
import { VoidOrderResponseModel } from '../../../../../core/modules/rest/order-summary/response/void-order-response.model';
import { Observable } from 'rxjs';

@Injectable()
export class VoidOrderDialogSandbox extends DialogSandbox {

    constructor(appDialogsService: AppDialogsService,
                private rootSandbox: RootSandbox,
                private store: Store<reducer.State>,
                private orderSummaryRestService: OrderSummaryRestService) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    voidOrder(orderId: number, lastStatusReason: string, lastStatusExplanation: string, refundAmount: number,
              shouldRefundPayments: boolean, shouldIncreaseRemainingQuantities: boolean, shouldUnusePassCards: boolean, shouldSendCustomerEmail: boolean): Observable<VoidOrderResponseModel> {

        const voidOrderRequest = new VoidOrderRequestModel(orderId, lastStatusReason, lastStatusExplanation, refundAmount, shouldRefundPayments, shouldIncreaseRemainingQuantities, shouldUnusePassCards, shouldSendCustomerEmail);

        return this.orderSummaryRestService.voidOrder(voidOrderRequest).pipe(
            catchError((error: HttpErrorModel) => {
                this.hideLoader();
                return this.rootSandbox.handleHttpError("Error while voiding order", error);
            }));
    }
}
