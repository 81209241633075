<div class="dialog-section summary-scroll-wrapper">
    <div class="related-orders">
        <h2>Related Orders</h2>
        <button *ngIf="parentOrder"
            class="button ghost-button"
            (click)="onSubmit()">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-info" class="svg-inline--fa fa-circle-info fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336l24 0 0-64-24 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0c13.3 0 24 10.7 24 24l0 88 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
            <span>View Order Details</span>
        </button>
    </div>
    <ng-container>
        <ng-container *ngFor="let relatedOrder of relatedOrders">
            <app-related-order-info-dialog-summary [relatedOrder]="relatedOrder"></app-related-order-info-dialog-summary>
        </ng-container>
    </ng-container>
</div>
