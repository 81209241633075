
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {mergeMap, map, take, catchError} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {HttpErrorModel} from "../../../../../../core/modules/rest/http-error.model";
import {RootSandbox} from "../../../../../../core/store/root.sandbox";
import {ReportingRestService} from "../../../../../../core/modules/rest/reporting/reporting-rest.service";
import {ProductRestService} from "../../../../../../core/modules/rest/product/product-rest.service";
import {ProductSimpleResponseModel} from "../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {DateTimeDescriptor} from "../../../../../../shared/model/date-time-descriptor.model";
import {SortByRequestModel} from "../../../../../../core/modules/rest/reporting/request/sort-by-request.model";
import {SortByModel} from "../../../../../../shared/model/sort-by.model";
import {DriverManifestReportRequestModel} from "../../../../../../core/modules/rest/reporting/operational/driver-manifest-report/request/driver-manifest-report-request.model";
import {DriverManifestReportResponseModel} from "../../../../../../core/modules/rest/reporting/operational/driver-manifest-report/response/driver-manifest-report-response.model";
import {DriverManifestReportCurrentSearchDataModel} from "../model/driver-manifest-report-current-search-data.model";
import {FavoriteTypeEnum} from "../../../../../../shared/enums/favorite-type.enum";
import {FavoritesResponseModel} from "../../../../../../core/modules/rest/favorite/response/favorites-response.model";
import {FavoriteRestService} from "../../../../../../core/modules/rest/favorite/favorite-rest.service";
import {FavoriteResponseModel} from "../../../../../../core/modules/rest/favorite/response/favorite-response.model";
import {ReportSearchRequestModel} from "../../../../../../core/modules/rest/reporting/request/report-search-request.model";
import {FilterExpressionRequestModel} from "../../../../../../core/modules/rest/reporting/request/filter-expression-request.model";
import {BaseExpressionRequestModel} from "../../../../../../core/modules/rest/reporting/request/base-expression-request.model";
import {FieldRequestModel} from "../../../../../../core/modules/rest/reporting/request/field-request.model";
import {GroupByRequestModel} from "../../../../../../core/modules/rest/reporting/request/group-by-request.model";
import {OperatorExpressionRequestModel} from "../../../../../../core/modules/rest/reporting/request/operator-expression-request.model";
import {AppPrintingService} from "../../../../../../core/services/app-printing-service";
import {HttpClient} from "@angular/common/http";
import {ProductDirectionDescriptorRequestModel} from "../../../../../../core/modules/rest/reporting/operational/driver-manifest-report/request/product-direction-descriptor-request.model";
import {ProductDirectionDataModel} from "../model/product-direction-data.model";
import * as data from '../model/data';
import * as fromReducer from './store/reducer';
import * as fromActions from './store/actions';
import * as moment from "moment";

@Injectable()
export class DriverManifestReportSandbox {

    loadingFavorites$: Observable<boolean> = this.store.select(fromReducer.loadingFavorites_selector);
    favorites$: Observable<FavoriteResponseModel[]> = this.store.select(fromReducer.favorites_selector);
    selectedFavoriteId$: Observable<number> = this.store.select(fromReducer.selectedFavoriteId_selector);
    favoriteCreatedUpdated$: Observable<boolean> = this.store.select(fromReducer.favoriteCreatedUpdated_selector);
    loadingProducts$: Observable<boolean> = this.store.select(fromReducer.loadingProducts_selector);
    productDirections$: Observable<ProductDirectionDataModel[]> = this.store.select(fromReducer.productDirections_selector);
    selectedDateType$: Observable<string> = this.store.select(fromReducer.selectedDateType_selector);
    selectedDate$: Observable<moment.Moment> = this.store.select(fromReducer.selectedDate_selector);
    selectedProductDirections$: Observable<string[]> = this.store.select(fromReducer.selectedProductDirections_selector);
    currentSearchData$: Observable<DriverManifestReportCurrentSearchDataModel> = this.store.select(fromReducer.currentSearchData_selector);
    currentSearchDataSortBy$: Observable<SortByModel> = this.store.select(fromReducer.currentSearchDataSortBy_selector);

    constructor(private store: Store<fromReducer.State>, private rootSandbox: RootSandbox, private favoriteRestService: FavoriteRestService,
                private productRestService: ProductRestService, private reportingRestService: ReportingRestService,
                private http: HttpClient) {
    }

    loadData() {

        this.loadAllDriverManifestReportFavoritesByUser(null, false);

        this.store.dispatch(new fromActions.UpdateProductsLoader({loadingProducts: true}));
        this.productRestService.getAll()
            .subscribe((products: ProductSimpleResponseModel[]) => {
                let productDirections: ProductDirectionDataModel[] = [];
                products.map((product: ProductSimpleResponseModel) => {
                    if (!product.isRoundTripProduct) {
                        productDirections.push({
                            productId: product.productId,
                            description: product.description,
                            isReturnTrip: false,
                            isActive: product.active

                        });
                    } else {
                        productDirections.push({
                            productId: product.productId,
                            description: (product.descriptionRegularTrip !== null && product.descriptionRegularTrip.trim().length > 0 ? product.descriptionRegularTrip : product.description),
                            isReturnTrip: false,
                            isActive: product.active

                        });
                        productDirections.push({
                            productId: product.productId,
                            description: (product.descriptionReturnTrip !== null && product.descriptionReturnTrip.trim().length > 0 ? product.descriptionReturnTrip : product.description),
                            isReturnTrip: true,
                            isActive: product.active

                        });
                    }
                });
                this.store.dispatch(new fromActions.UpdateProductDirections({productDirections: productDirections}));
                this.store.dispatch(new fromActions.UpdateProductsLoader({loadingProducts: false}));
            },
            () => this.store.dispatch(new fromActions.UpdateProductsLoader({loadingProducts: false})));
    }

    loadAllDriverManifestReportFavoritesByUser(favoriteId: number, favoriteCreatedUpdated: boolean) {

        this.store.dispatch(new fromActions.UpdateFavoritesLoader({loadingFavorites: true}));
        this.favoriteRestService.getActiveFavoritesByCurrentUserAndFavoriteType(FavoriteTypeEnum.DRIVER_MANIFEST_REPORT)
            .subscribe((favoritesResponse: FavoritesResponseModel) => {
                this.store.dispatch(new fromActions.UpdateFavorites({favorites: favoritesResponse.favorites, favoriteId: favoriteId, favoriteCreatedUpdated: favoriteCreatedUpdated}));
                this.store.dispatch(new fromActions.UpdateFavoritesLoader({loadingFavorites: false}));
            },
            () => this.store.dispatch(new fromActions.UpdateFavoritesLoader({loadingFavorites: false})));
    }

    resetState() {
        this.store.dispatch(new fromActions.ResetState());
        this.loadData();
    }

    clearSearchData(clearSelectedFavorite: boolean) {
        if (clearSelectedFavorite) {
            this.store.dispatch(new fromActions.UpdateSelectedFavoriteId({favoriteId: null}));
        }
        this.store.dispatch(new fromActions.ClearSearchData());
    }

    updateFilter(selectedDateType: string, selectedDate: moment.Moment, selectedProductDirections: string[], isFavoriteSelected: boolean) {
        // If filter changed by selecting favorite, do not set filter fields again
        if (!isFavoriteSelected) {
            this.store.dispatch(new fromActions.UpdateSelectedDateType({selectedDateType: selectedDateType}));
            this.store.dispatch(new fromActions.UpdateSelectedDate({selectedDate: selectedDate}));
            this.store.dispatch(new fromActions.UpdateSelectedProductDirections({selectedProductDirections: selectedProductDirections}));
        }
    }

    setSelectedFavoriteId(favoriteId: number) {
        this.store.dispatch(new fromActions.UpdateSelectedFavoriteId({favoriteId: favoriteId}));
    }

    deactivateFavorite(favoriteId: number): Observable<any> {
        return this.favoriteRestService.deactivate(favoriteId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while deactivating favorite", error);
            }));
    }

    createReportSearchRequest(): Observable<ReportSearchRequestModel> {

        return observableCombineLatest(
            this.selectedDateType$,
            this.selectedDate$,
            this.selectedProductDirections$,
            this.currentSearchDataSortBy$
        ).pipe(
            take(1),
            map(([selectedDateType, selectedDate, selectedProductDirections, currentSearchDataSortBy]: [string, moment.Moment, string[], SortByModel]) => {

                let searchExpression: BaseExpressionRequestModel[] = [];
                let fieldRequests: FieldRequestModel[] = [];
                let sortRequests: SortByRequestModel[] = [];
                let groupByRequests: GroupByRequestModel[] = [];
                let from: number = 0;
                let size: number = 0;
                let scrollId: string = "";
                let showPaymentSummary: boolean = false;

                if (currentSearchDataSortBy) {
                    sortRequests = [new SortByRequestModel(currentSearchDataSortBy.fieldName, currentSearchDataSortBy.order)];
                }

                let startEndDateFilter: FilterExpressionRequestModel;
                if (selectedDateType === data.DATE_TODAY) {
                    startEndDateFilter = new FilterExpressionRequestModel("occurrenceDateOrderDetail", [data.DATE_TODAY]);
                } else if (selectedDateType === data.DATE_TOMORROW) {
                    startEndDateFilter = new FilterExpressionRequestModel("occurrenceDateOrderDetail", [data.DATE_TOMORROW]);
                } else {
                    let dateString = JSON.stringify(this.getDateObjectFromMomentDate(selectedDate));
                    startEndDateFilter = new FilterExpressionRequestModel("occurrenceDateOrderDetail", [dateString, dateString]);
                }
                searchExpression.push(startEndDateFilter);

                let and1Operator: OperatorExpressionRequestModel = new OperatorExpressionRequestModel("AND");
                searchExpression.push(and1Operator);

                let selectedProductDirectionsStrings: string[] = [...selectedProductDirections];
                let productDirectionsFilter: FilterExpressionRequestModel = new FilterExpressionRequestModel("productDirection", selectedProductDirectionsStrings);
                searchExpression.push(productDirectionsFilter);

                let request: ReportSearchRequestModel = new ReportSearchRequestModel(searchExpression, fieldRequests, sortRequests, groupByRequests, from, size, scrollId, showPaymentSummary);

                return request;
            }),);
    }

    private getDateObjectFromMomentDate(dateMoment: moment.Moment): any {
        return {
            day: dateMoment.date(),
            month: dateMoment.month() + 1,
            year: dateMoment.year()
        };
    }

    getDriverManifestReport(fromDate: DateTimeDescriptor, toDate: DateTimeDescriptor, productDirections: string[]): void {

        this.store.dispatch(new fromActions.InitSearch());

        this.currentSearchDataSortBy$.pipe(
            take(1),
            mergeMap((sortBy: SortByModel) => {

                let fieldName: string = null;
                let order: string = null;
                if (sortBy != null && sortBy !== undefined) {
                    if (sortBy.fieldName != null && sortBy.fieldName !== undefined) {
                        fieldName = sortBy.fieldName;
                    }
                    if (sortBy.order != null && sortBy.order !== undefined) {
                        order = sortBy.order;
                    }
                }

                const sortByRequest = new SortByRequestModel(fieldName, order);
                let productDirectionRequests: ProductDirectionDescriptorRequestModel[] = [];
                for (let productDirection of productDirections) {

                    const productDirectionParts: string[] = productDirection.split("_");
                    const productId: number = parseInt(productDirectionParts[0], 10);
                    const isReturnTrip: boolean = productDirectionParts[1].toLowerCase() === "true";

                    // Check if selected product is already added into request
                    let productDirectionRequest: ProductDirectionDescriptorRequestModel = productDirectionRequests.find((pdd) => pdd.productId === productId);
                    if (productDirectionRequest) {

                        // Update flag(s) for fetching directions for product already added into request
                        if (!productDirectionRequest.fetchRegularDirection && !isReturnTrip) {
                            productDirectionRequest.fetchRegularDirection = true;
                        }
                        if (!productDirectionRequest.fetchReturnDirection && isReturnTrip) {
                            productDirectionRequest.fetchReturnDirection = true;
                        }
                    } else {

                        // Add new product-direction into request
                        productDirectionRequests.push({
                            productId: productId,
                            fetchRegularDirection: !isReturnTrip,
                            fetchReturnDirection: isReturnTrip
                        });
                    }
                }
                const request: DriverManifestReportRequestModel = new DriverManifestReportRequestModel(fromDate, toDate, productDirectionRequests, sortByRequest);

                // Get driver manifest report
                return this.reportingRestService.getDriverManifestReport(request).pipe(
                    catchError((error: HttpErrorModel) => {
                        return this.rootSandbox.handleHttpError("Error while getting driver manifest report", error);
                    }));
            }),)
            .subscribe((response: DriverManifestReportResponseModel) => {
                this.store.dispatch(new fromActions.UpdateCurrentSearchData({response: response}));
            });
    }

    generateUrlForExportToXlsxDriverManifestReport(fromDate: DateTimeDescriptor, toDate: DateTimeDescriptor, productDirections: string[]): Observable<string> {

        return this.currentSearchDataSortBy$.pipe(
            take(1),
            mergeMap((sortBy: SortByModel) => {

                let fieldName: string = null;
                let order: string = null;
                if (sortBy != null && sortBy !== undefined) {
                    if (sortBy.fieldName != null && sortBy.fieldName !== undefined) {
                        fieldName = sortBy.fieldName;
                    }
                    if (sortBy.order != null && sortBy.order !== undefined) {
                        order = sortBy.order;
                    }
                }

                const sortByRequest = new SortByRequestModel(fieldName, order);
                let productDirectionRequests: ProductDirectionDescriptorRequestModel[] = [];
                for (let productDirection of productDirections) {

                    const productDirectionParts: string[] = productDirection.split("_");
                    const productId: number = parseInt(productDirectionParts[0], 10);
                    const isReturnTrip: boolean = productDirectionParts[1].toLowerCase() === "true";

                    // Check if selected product is already added into request
                    let productDirectionRequest: ProductDirectionDescriptorRequestModel = productDirectionRequests.find((pdd) => pdd.productId === productId);
                    if (productDirectionRequest) {

                        // Update flag(s) for fetching directions for product already added into request
                        if (!productDirectionRequest.fetchRegularDirection && !isReturnTrip) {
                            productDirectionRequest.fetchRegularDirection = true;
                        }
                        if (!productDirectionRequest.fetchReturnDirection && isReturnTrip) {
                            productDirectionRequest.fetchReturnDirection = true;
                        }
                    } else {

                        // Add new product-direction into request
                        productDirectionRequests.push({
                            productId: productId,
                            fetchRegularDirection: !isReturnTrip,
                            fetchReturnDirection: isReturnTrip
                        });
                    }
                }
                const request: DriverManifestReportRequestModel = new DriverManifestReportRequestModel(fromDate, toDate, productDirectionRequests, sortByRequest);

                // Get url for export to csv driver manifest report
                return this.reportingRestService.generateUrlForExportToXlsxDriverManifestReport(request).pipe(
                    catchError((error: HttpErrorModel) => {
                        return this.rootSandbox.handleHttpError("Error while generating url for export to xls driver manifest report", error);
                    }));
            }),);
    }

    generateUrlForExportToPdfDriverManifestReport(fromDate: DateTimeDescriptor, toDate: DateTimeDescriptor, productDirections: string[]): Observable<string> {

        return this.currentSearchDataSortBy$.pipe(
            take(1),
            mergeMap((sortBy: SortByModel) => {

                let fieldName: string = null;
                let order: string = null;
                if (sortBy != null && sortBy !== undefined) {
                    if (sortBy.fieldName != null && sortBy.fieldName !== undefined) {
                        fieldName = sortBy.fieldName;
                    }
                    if (sortBy.order != null && sortBy.order !== undefined) {
                        order = sortBy.order;
                    }
                }

                const sortByRequest = new SortByRequestModel(fieldName, order);
                let productDirectionRequests: ProductDirectionDescriptorRequestModel[] = [];
                for (let productDirection of productDirections) {

                    const productDirectionParts: string[] = productDirection.split("_");
                    const productId: number = parseInt(productDirectionParts[0], 10);
                    const isReturnTrip: boolean = productDirectionParts[1].toLowerCase() === "true";

                    // Check if selected product is already added into request
                    let productDirectionRequest: ProductDirectionDescriptorRequestModel = productDirectionRequests.find((pdd) => pdd.productId === productId);
                    if (productDirectionRequest) {

                        // Update flag(s) for fetching directions for product already added into request
                        if (!productDirectionRequest.fetchRegularDirection && !isReturnTrip) {
                            productDirectionRequest.fetchRegularDirection = true;
                        }
                        if (!productDirectionRequest.fetchReturnDirection && isReturnTrip) {
                            productDirectionRequest.fetchReturnDirection = true;
                        }
                    } else {

                        // Add new product-direction into request
                        productDirectionRequests.push({
                            productId: productId,
                            fetchRegularDirection: !isReturnTrip,
                            fetchReturnDirection: isReturnTrip
                        });
                    }
                }
                const request: DriverManifestReportRequestModel = new DriverManifestReportRequestModel(fromDate, toDate, productDirectionRequests, sortByRequest);

                // Get url for export to pdf driver manifest report
                return this.reportingRestService.generateUrlForExportToPdfDriverManifestReport(request).pipe(
                    catchError((error: HttpErrorModel) => {
                        return this.rootSandbox.handleHttpError("Error while generating url for export to pdf driver manifest report", error);
                    }));
            }),);
    }

    printDriverManifestReport(url: string): void {

        this.http.get(url, {responseType: 'blob'}).pipe(
            take(1))
            .subscribe((blob: Blob) => {
                AppPrintingService.openPdfDialog(blob);
            });
    }

    updateSortBy(sortBy: SortByModel, startDate: DateTimeDescriptor, endDate: DateTimeDescriptor, productDirections: string[]) {
        this.store.dispatch(new fromActions.UpdateCurrentSearchDataSortBy({sortBy: sortBy}));
        this.getDriverManifestReport(startDate, endDate, productDirections);
    }
}
