import { Component, Input } from '@angular/core';
import { RelatedOrderSummaryResponseModel } from '../../../../../../core/modules/rest/order-summary/response/related-order-summary-response.model';
import { Router } from '@angular/router';
import { AppDialogsService } from './../../../../../../core/services/app-dialogs.service';

@Component({
  selector: 'app-related-orders-info-dialog-summary',
  templateUrl: './related-orders-info-dialog-summary.component.html'
})
export class RelatedOrdersInfoDialogSummaryComponent {

  @Input() relatedOrders: RelatedOrderSummaryResponseModel[];
  @Input() parentOrder: boolean;

  constructor(private router: Router, private appDialogsService: AppDialogsService) {}

  onSubmit(){
    if (this.relatedOrders) {
      sessionStorage.setItem('relatedOrders', JSON.stringify(this.relatedOrders));
    }
    this.router.navigate(['/secured/related-orders']);
    this.appDialogsService.destroyActiveDialog();
  }
  
}
