import {Action} from '@ngrx/store';
import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";
import {IncludedCostModel} from "../../model/included-cost.model";


export const UPDATE_DIALOG_DATA_LOADED_ACTION = '[Dialog Edit Included Cost] Update dialog data loaded';
export const UPDATE_EDIT_INCLUDED_COST_ACTION = '[Dialog Edit Included Cost] Update edit included cost';

export const RESET_STATE_ACTION = '[Dialog Edit Included Cost] Reset state';

export const UPDATE_INCLUDED_COST_ACTION = '[Dialog Edit Included Cost] Update included cost';
export const UPDATE_ALL_LOCATIONS_ACTION = '[Dialog Edit Included Cost] Update all locations';


export class UpdateDialogDataLoaded implements Action {
    readonly type = UPDATE_DIALOG_DATA_LOADED_ACTION;

    constructor(public value: boolean) {
    }
}

export class UpdateEditIncludedCost implements Action {
    readonly type = UPDATE_EDIT_INCLUDED_COST_ACTION;

    constructor(public value: boolean) {
    }
}

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class UpdateIncludedCost implements Action {
    readonly type = UPDATE_INCLUDED_COST_ACTION;

    constructor(public includedCost: IncludedCostModel) {
    }
}

export class UpdateAllLocations implements Action {
    readonly type = UPDATE_ALL_LOCATIONS_ACTION;

    constructor(public locations: LocationResponseModel[]) {
    }
}


export type Actions =
    UpdateDialogDataLoaded |
    ResetState |
    UpdateIncludedCost |
    UpdateAllLocations |
    UpdateEditIncludedCost;
