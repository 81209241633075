import { Component, Input, OnInit, Output, EventEmitter  } from '@angular/core';
import { NotificationResponseModel } from '../../../../core/modules/rest/notification/response/notification-response.model';
import { YesNoDialogComponent, YesNoDialogData } from '../../../../shared/dialogs/common-dialogs/yes-no-dialog/container/yes-no-dialog.component';
import { AppDialogsService } from '../../../../core/services/app-dialogs.service';
import { NotificationRestService } from '../../../../core/modules/rest/notification/notification-rest.service';
import { SharedSandbox } from '../../../../shared/shared.sandbox';
import { PreviewEmailHtmlTemplateDialogComponent, PreviewEmailHtmlTemplateDialogData } from '../../../../modules/secured/management/system/email-html-templates/dialogs/preview-email-html-template-dialog/container/preview-email-html-template-dialog.component';
import { DateTimeUtility } from '../../../../shared/utils/date-time-utility';
import { EMPTY_STATE_ICON_CREATE_NEW } from '../../../../core/services/app-properties.service';
import { RootSandbox } from '../../../../core/store/root.sandbox';


@Component({
    selector: 'app-header-notification-dropdown',
    templateUrl: './header-notification-dropdown.component.html',
    styleUrls: ['./header-notification-dropdown.component.scss']
})
export class HeaderNotificationDropdownComponent implements OnInit {

    emptyStateIcon = EMPTY_STATE_ICON_CREATE_NEW;

    @Input() notificationHistoryResults: NotificationResponseModel[] = [];
    @Output() clickedOutsideNotificationDropdown: EventEmitter<void> = new EventEmitter<void>();
    @Output() getNotificationState: EventEmitter<any> = new EventEmitter<any>();


    constructor(private rootSandbox: RootSandbox, private appDialogsService: AppDialogsService, private notificationRestService: NotificationRestService, private sharedSandbox: SharedSandbox) {
    }

    ngOnInit() {
    }


    showNotification(notification: NotificationResponseModel) {

        if (!notification.displayed) {
            this.notificationRestService.preview(notification.notificationId).subscribe();
            this.clickedOutsideNotificationDropdown.emit();

            const foundNotification = this.notificationHistoryResults.find((item) => item.notificationId === notification.notificationId);

            if (foundNotification) {
                foundNotification.displayed = true;
            }

            this.getNotificationState.emit(this.notificationHistoryResults);
            this.rootSandbox.setNotifications(this.notificationHistoryResults);
        } else {
            this.clickedOutsideNotificationDropdown.emit();
        }

        switch (notification.notificationType) {
            case "TEXT":
                const textData: YesNoDialogData = new YesNoDialogData(
                    notification.subject,
                    notification.description,
                    "Close",
                    "Send",
                    "notification-button",
                    (confirmed: boolean) => {
                        if (confirmed) {
                        }
                    }
                );
                this.appDialogsService.renderDialog(YesNoDialogComponent, textData);
                break;

            case "TEMPLATE":
                const templateData = new PreviewEmailHtmlTemplateDialogData(notification.templateId, notification.subject);
                this.appDialogsService.renderDialog(PreviewEmailHtmlTemplateDialogComponent, templateData);
                break;

            default:
                break;
        }
    }

    getDateFriendly(date: number[]) {
        return DateTimeUtility.getDateTimeFriendly(date);
    }

}
