import {Action} from "@ngrx/store";
import {BusResponseModel} from "../../../../../../../../../core/modules/rest/bus/response/bus-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Bus :: MAIN] Reset State';
export const SET_BUS_ACTION = '[Dialog - Create/Edit Bus :: MAIN] Set Bus';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetBus implements Action {
    readonly type = SET_BUS_ACTION;
    constructor(public bus: BusResponseModel) {}
}

export type All = ResetState | SetBus;
