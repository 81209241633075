import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { DialogData } from "../../../../../../../../shared/dialogs/model/dialog.data";
import { EditOverrideReasonDialogSandbox } from '../sandbox/edit-over-ride-reason-dialog.sandbox';
import { OverrideReasonFormSubmitDataModel } from '../model/over-ride-reason-form-submit-data.model';
import { OverrideReasonResponseModel } from '../../../../../../../../core/modules/rest/over-ride-reason/response/over-ride-reason-response.model';

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditOverrideReasonDialogData extends DialogData {

    overrideReasonId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, overrideReasonId?: number) {
        super();
        this.overrideReasonId = overrideReasonId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-override-reason-dialog',
    templateUrl: './edit-override-reason-dialog.component.html'
})
export class EditOverrideReasonDialogComponent implements OnInit, OnDestroy {

    overrideReason$: Observable<OverrideReasonResponseModel>;
    overrideReasonId: number;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditOverrideReasonDialogData, private sandbox: EditOverrideReasonDialogSandbox) {
        this.overrideReasonId = dialogData.overrideReasonId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {

        if (this.overrideReasonId && !isNaN(this.overrideReasonId)) {
            this.editMode = true;
            this.sandbox.loadOverrideReason(this.overrideReasonId);
            this.overrideReason$ = this.sandbox.overrideReason$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: OverrideReasonFormSubmitDataModel) {

        if (this.editMode) {
            this.sandbox.updateOverrideReason(data.overrideReasonId, data.overrideReason).subscribe(() => {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createOverrideReason(data.overrideReason).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
