<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmit()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? 'Edit Quick Selling Button Product' : 'Add Quick Selling Button Product' }}</h1>
            </div>
        </div>
    </div>


    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper grid-x space-between">
        <div class="formSection_wrapper-1">
            <!-- Basic info :: BEGIN -->
            <div class="formSection">
                <div class="formRow">
                    <div class="grid-x grid-margin-x">
                        <div class="cell medium-10">
                            <app-input-label-group>
                                <label class="label">Description</label>
                                <input class="main--element input-large"
                                       type="text"
                                       name="Description"
                                       [formControlName]="'description'">
                            </app-input-label-group>
                        </div>

                        <div class="cell medium-2">
                            <app-input-label-group>
                                <label class="label">Color</label>
                                <input type ="color"
                                       style="width: 100%"
                                       class="main--element"
                                       [formControlName]="'color'">
                            </app-input-label-group>
                        </div>
                    </div>
                </div>

                <div class="formRow">
                    <div class="grid-x grid-margin-x">
                        <div class="cell medium-12">
                            <app-input-label-group>
                                <label class="label">Category</label>
                                <app-dropdown class="main--element input-large"
                                              [customClasses]="'dropdown_large'"
                                              [options]="categoryOptions"
                                              [loading]="categoriesLoading"
                                              [preSelectedValue]="form.value.selectedCategoryId"
                                              [formControlName]="'selectedCategoryId'"
                                              (changed)="onSelectedCategoryChanged($event)">
                                </app-dropdown>
                            </app-input-label-group>
                        </div>
                    </div>
                </div>

                <div class="formRow">
                    <div class="grid-x grid-margin-x">
                        <div class="cell medium-12">
                            <app-input-label-group>
                                <label class="label">Product</label>
                                <app-dropdown class="main--element input-large"
                                    [customClasses]="'dropdown_large'"
                                    [options]="productOptions"
                                    [loading]="productsLoading"
                                    [preSelectedValue]="form.value.selectedProductId"
                                    [formControlName]="'selectedProductId'"
                                    (changed)="onSelectedProductChanged($event)">
                                </app-dropdown>
                            </app-input-label-group>
                        </div>
                    </div>
                </div>

                <div class="formRow">
                    <div class="grid-x grid-margin-x">

                        <div class="cell medium-6" [ngClass]="{'remove': product && !product.usesPickupLocations}">
                            <app-input-label-group>
                                <label class="label">Pickup Location</label>
                                <app-dropdown class="main--element"
                                              [options]="pickupLocationOptions"
                                              [loading]="pickupLocationsLoading"
                                              [preSelectedValue]="form.value.selectedPickupLocationId"
                                              [formControlName]="'selectedPickupLocationId'"
                                              (changed)="onSelectedPickupLocationChanged($event)">
                                </app-dropdown>
                            </app-input-label-group>
                        </div>

                        <div class="cell medium-6" [ngClass]="{'remove': product && !product.usesDropoffLocations}">
                            <app-input-label-group>
                                <label class="label">Dropoff Location</label>
                                <app-dropdown class="main--element"
                                              [options]="dropoffLocationOptions"
                                              [loading]="dropoffLocationsLoading"
                                              [preSelectedValue]="form.value.selectedDropoffLocationId"
                                              [formControlName]="'selectedDropoffLocationId'"
                                              (changed)="onSelectedDropoffLocationChanged($event)">
                                </app-dropdown>
                            </app-input-label-group>
                        </div>
                    </div>
                </div>

                <div class="formRow">
                    <div class="grid-x grid-margin-x">
                        <div class="cell medium-6">
                            <app-input-label-group>
                                <label class="label">Start Time</label>
                                <app-dropdown class="main--element"
                                              [options]="startTimeOptions"
                                              [loading]="startTimesLoading"
                                              [preSelectedValue]="form.value.selectedStartTime"
                                              [formControlName]="'selectedStartTime'">
                                </app-dropdown>
                            </app-input-label-group>
                        </div>


                        <div class="cell medium-6" [ngClass]="{'remove': product && !product.isRoundTripProduct}">
                            <app-input-label-group>
                                <label class="label">Start Time [R]</label>
                                <app-dropdown class="main--element"
                                              [options]="startTimeReturnTripOptions"
                                              [loading]="startTimesReturnTripLoading"
                                              [preSelectedValue]="form.value.selectedStartTimeReturnTrip"
                                              [formControlName]="'selectedStartTimeReturnTrip'">
                                </app-dropdown>
                            </app-input-label-group>
                        </div>
                    </div>
                </div >
            </div><!-- Basic info :: END -->

            <!-- Checkbox Section :: BEGIN -->
            <div class="formSection">
                <div class="formRow">
                    <div class="grid-x grid-margin-x">
                        <div class="cell medium-6">
                            <app-checkbox
                                [labelName]="'Use Last Name'"
                                [checked]="form.value.useLastName"
                                [formControlName]="'useLastName'">
                            </app-checkbox>
                        </div>

                        <div class="cell medium-6">
                            <app-checkbox
                                [labelName]="'Last Name Required'"
                                [checked]="form.value.lastNameRequired"
                                [formControlName]="'lastNameRequired'">
                            </app-checkbox>
                        </div>
                    </div>
                </div>

                <div class="formRow">
                    <div class="grid-x grid-margin-x">
                        <div class="cell medium-6">
                            <app-checkbox
                                [labelName]="'Use Email'"
                                [checked]="form.value.useEmail"
                                [formControlName]="'useEmail'">
                            </app-checkbox>
                        </div>

                        <div class="cell medium-6">
                            <app-checkbox
                                [labelName]="'Email Required'"
                                [checked]="form.value.emailRequired"
                                [formControlName]="'emailRequired'">
                            </app-checkbox>
                        </div>
                    </div>
                </div>

                <div class="formRow">
                    <div class="grid-x grid-margin-x">
                        <div class="cell medium-6">
                            <app-checkbox
                                [labelName]="'Use Phone'"
                                [checked]="form.value.usePhone"
                                [formControlName]="'usePhone'">
                            </app-checkbox>
                        </div>

                        <div class="cell medium-6">
                            <app-checkbox
                                [labelName]="'Phone Required'"
                                [checked]="form.value.phoneRequired"
                                [formControlName]="'phoneRequired'">
                            </app-checkbox>
                        </div>
                    </div>
                </div>

                <div class="formRow">
                    <div class="grid-x grid-margin-x">
                        <div class="cell medium-6">
                            <app-checkbox
                                [labelName]="'Use Discount Code'"
                                [checked]="form.value.useDiscountCode"
                                [formControlName]="'useDiscountCode'">
                            </app-checkbox>
                        </div>

                        <div class="cell medium-6">
                            <app-checkbox
                                [labelName]="'Use Round Trip'"
                                [checked]="form.value.useRoundTrip"
                                [formControlName]="'useRoundTrip'">
                            </app-checkbox>
                        </div>
                    </div>
                </div>
            </div><!-- Checkbox Section :: END -->
        </div>
        <div class="formSection_wrapper-2">
            <!-- Tiers Section :: BEGIN -->
            <div class="formSection">
                <div class="formRow"
                     *ngFor="let tierWithQuantityForm of getTiersWithQuantitiesFormControls(); let i = index">
                    <app-edit-quick-selling-button-product-tier-form
                        formArrayName="tiersWithQuantities"
                        [tierWithQuantityForm]="tierWithQuantityForm">
                    </app-edit-quick-selling-button-product-tier-form>
                </div>
            </div><!-- Tiers Section :: END -->
        </div>
    </div><!-- Dialog Content :: END -->

    <div class="errorMessage_wrapper"
        *ngIf="errorMessage">
        <label class="error">
            {{ errorMessage }}
        </label>
    </div>


    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right-flex">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancel()">
                    Cancel
                </app-button>
            </div>


            <div class="cell medium-6 text-left-flex">
                <app-button
                    [buttonType]='"primary"'
                    (clickEvent)="onSubmit()">
                    Save
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
