import { NgModule } from "@angular/core";
import { AddSaleGroupCustomerDialogComponent } from "./container/add-sale-group-customer-dialog.component";
import { StoreModule } from "@ngrx/store";
import { AddSaleGroupItemsDialogSandbox } from "../sandbox/add-sale-group-items-dialog.sandbox";
import { REDUCER_NAME } from "../../store/reducers";
import { reducer } from "../../store/main/reducer";
import { SharedModule } from "../../../../../../../shared/shared.module";

import { AddSaleGroupCustomerFormComponent } from "./components/add-sale-group-customer-form.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { SaleGroupsSandbox } from "../../store/sale-groups.sandbox";



@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer),
        InfiniteScrollModule
    ],
    providers: [
        AddSaleGroupItemsDialogSandbox,
        SaleGroupsSandbox
    ],
    declarations: [
        AddSaleGroupCustomerDialogComponent,
        AddSaleGroupCustomerFormComponent
    ],
    entryComponents: [
        AddSaleGroupCustomerDialogComponent
    ],
    exports: [
        AddSaleGroupCustomerDialogComponent
    ]
})
export class AddSaleGroupCustomerDialogModule {

}
