import {DraggedDataI} from './dragged-data.model';
import {ExpressionItemModel} from './expression-item.model';

export class DraggedGroupDataModel implements DraggedDataI {
    uuid: string;
    items: ExpressionItemModel[];
    groupHeight: number;

    constructor(uuid: string, items: ExpressionItemModel[], groupHeight: number) {
        this.uuid = uuid;
        this.items = items;
        this.groupHeight = groupHeight;
    }
}

