import {Action} from "@ngrx/store";
import {QuickSellingButtonProductResponseModel} from "../../../../../../../../../core/modules/rest/quick-selling-button-product/response/quick-selling-button-product-response.model";
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {CategoryResponseModel} from "../../../../../../../../../core/modules/rest/category/response/category-response.model";
import {LocationListItemDescriptorResponseModel} from "../../../../../../../../../core/modules/rest/location-list/response/location-list-item-descriptor-response.model";
import {TierResponseModel} from "../../../../../../../../../core/modules/rest/tier/response/tier-response.model";
import { StartTimeResponseModel } from "../../../../../../../../../core/modules/rest/product-availability/response/start-time-response.model";

export const RESET_STATE_ACTION = '[Dialog - Edit Quick Selling Button Product :: MAIN] Reset State';

export const SET_DATA_ACTION = '[Dialog - Edit Quick Selling Button Product :: MAIN] Set Data';

export const SET_PRODUCT_ACTION = '[Dialog - Edit Quick Selling Button Product :: MAIN] Set Set Product';
export const SET_PRODUCTS_ACTION = '[Dialog - Edit Quick Selling Button Product :: MAIN] Set Products';
export const SET_PRODUCTS_LOADING_ACTION = '[Dialog - Edit Quick Selling Button Product :: MAIN] Set Products Loading';

export const SET_PICKUP_LOCATIONS_ACTION = '[Dialog - Edit Quick Selling Button Products :: MAIN] Set Pickup Locations';
export const SET_PICKUP_LOCATIONS_LOADING_ACTION = '[Dialog - Edit Quick Selling Button Products :: MAIN] Set Pickup Locations Loading';

export const SET_DROPOFF_LOCATIONS_ACTION = '[Dialog - Edit Quick Selling Button Pass :: MAIN] Set Dropoff Locations';
export const SET_DROPOFF_LOCATIONS_LOADING_ACTION = '[Dialog - Edit Quick Selling Button Pass :: MAIN] Set Dropoff Locations Loading';

export const SET_START_TIMES_ACTION = '[Dialog - Edit Quick Selling Button Products :: MAIN] Set Start Times';
export const SET_START_TIMES_LOADING_ACTION = '[Dialog - Edit Quick Selling Button Products :: MAIN] Set Start Times Loading';

export const SET_START_TIMES_RETURN_TRIP_ACTION = '[Dialog - Edit Quick Selling Button Products :: MAIN] Set Start Times Return Trip';
export const SET_START_TIMES_RETURN_TRIP_LOADING_ACTION = '[Dialog - Edit Quick Selling Button Products :: MAIN] Set Start Times Return Trip Loading';

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetData implements Action {
    readonly type = SET_DATA_ACTION;
    constructor(public quickSellingButtonProduct: QuickSellingButtonProductResponseModel,
                public tiers: TierResponseModel[],
                public categories: CategoryResponseModel[],
                public products: ProductSimpleResponseModel[],
                public pickupLocations: LocationListItemDescriptorResponseModel[],
                public dropoffLocations: LocationListItemDescriptorResponseModel[],
                public startTimes: StartTimeResponseModel[],
                public startTimesReturnTrip: StartTimeResponseModel[]) {}
}

export class SetProducts implements Action {
    readonly type = SET_PRODUCTS_ACTION;
    constructor(public products: ProductSimpleResponseModel[]) {}
}

export class SetProduct implements Action {
    readonly type = SET_PRODUCT_ACTION;
    constructor(public product: ProductSimpleResponseModel) {}
}

export class SetProductsLoading implements Action {
    readonly type = SET_PRODUCTS_LOADING_ACTION;
    constructor(public loading: boolean) {}
}

export class SetPickupLocations implements Action {
    readonly type = SET_PICKUP_LOCATIONS_ACTION;
    constructor(public pickupLocations: LocationListItemDescriptorResponseModel[]) {}
}

export class SetPickupLocationsLoading implements Action {
    readonly type = SET_PICKUP_LOCATIONS_LOADING_ACTION;
    constructor(public loading: boolean) {}
}

export class SetDropoffLocations implements Action {
    readonly type = SET_DROPOFF_LOCATIONS_ACTION;
    constructor(public dropoffLocations: LocationListItemDescriptorResponseModel[]) {}
}

export class SetDropoffLocationsLoading implements Action {
    readonly type = SET_DROPOFF_LOCATIONS_LOADING_ACTION;
    constructor(public loading: boolean) {}
}

export class SetStartTimes implements Action {
    readonly type = SET_START_TIMES_ACTION;
    constructor(public startTimes: StartTimeResponseModel[]) {}
}

export class SetStartTimesLoading implements Action {
    readonly type = SET_START_TIMES_LOADING_ACTION;
    constructor(public loading: boolean) {}
}

export class SetStartTimesReturnTrip implements Action {
    readonly type = SET_START_TIMES_RETURN_TRIP_ACTION;
    constructor(public startTimesReturnTrip: StartTimeResponseModel[]) {}
}

export class SetStartTimesReturnTripLoading implements Action {
    readonly type = SET_START_TIMES_RETURN_TRIP_LOADING_ACTION;
    constructor(public loading: boolean) {}
}

export type All = ResetState
    | SetData
    | SetProduct
    | SetProducts
    | SetProductsLoading
    | SetPickupLocations
    | SetPickupLocationsLoading
    | SetDropoffLocations
    | SetDropoffLocationsLoading
    | SetStartTimes| SetStartTimesLoading
    | SetStartTimesReturnTrip
    | SetStartTimesReturnTripLoading;
