import {ErrorCodeEnum} from '../../../shared/enums/error-code.enum';

export class HttpErrorModel {
    source: string;
    errorCode: ErrorCodeEnum;
    serverMessage: string;
    message: string;
    processed: boolean;

    constructor(source: string, errorCode: ErrorCodeEnum, serverMessage: string, message: string) {
        this.source = source;
        this.errorCode = errorCode;
        this.serverMessage = serverMessage;
        this.message = message;
        this.processed = false;
    }
}
