import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbItemModel } from '../../shared/components/breadcrumbs/breadcrumb-item.model';

const AUTH_ROOT_URL = '/auth';
const LOGIN_URL = AUTH_ROOT_URL + '/login';
const LOGOUT_URL = AUTH_ROOT_URL + '/logout';

// SECURED
const SECURED_ROOT_URL = '/secured';

// HOME
const HOME_URL = '/secured/home';

// >>> PURCHASE
export const SALES_ROOT_URL = SECURED_ROOT_URL + '/sales';

// >>> PLACE ORDER
const PLACE_ORDER_ROOT_URL = SALES_ROOT_URL + '/place-order';
const ORDER_LONG_FORM_URL = PLACE_ORDER_ROOT_URL + '/order-long-form';
const ORDER_QSB_URL = PLACE_ORDER_ROOT_URL + '/order-qsb';

// >>> END OF SHIFT
const END_OF_SHIFT_ROOT_URL = SALES_ROOT_URL + '/end-of-shift';
const CASHOUT_ENTRY_URL = END_OF_SHIFT_ROOT_URL + '/cashout-entry';
const CASHOUT_RECONCILIATION_URL = END_OF_SHIFT_ROOT_URL + '/cashout-reconciliation';

// >>> REPORTS
export const REPORTS_ROOT_URL = SECURED_ROOT_URL + '/reports';

// >>> GENERAL
const GENERAL_ROOT_URL = REPORTS_ROOT_URL + '/general';
const ORDER_REPORTS_URL = GENERAL_ROOT_URL + '/order-reports';
const AUTO_REPORTS_URL = GENERAL_ROOT_URL + '/auto-reports';
const AUDIT_LOG_REPORTS_URL = GENERAL_ROOT_URL + '/audit-log-report';

// >>> OPERATIONAL
const OPERATIONAL_ROOT_URL = REPORTS_ROOT_URL + '/operational';
const OPERATIONS_REPORT_URL = OPERATIONAL_ROOT_URL + '/operations-report';
const OVERRIDE_REPORT_URL = OPERATIONAL_ROOT_URL + '/override-report';
const DRIVER_MANIFEST_REPORT_URL = OPERATIONAL_ROOT_URL + '/driver-manifest-report';

// >>> FINANCIAL
const FINANCIAL_ROOT_URL = REPORTS_ROOT_URL + '/financial';
const TRIP_REVENUE_REPORT_URL = FINANCIAL_ROOT_URL + '/trip-revenue-report';
const TICKET_TYPE_REVENUE_REPORT_URL = FINANCIAL_ROOT_URL + '/ticket-type-revenue-report';
const COLLECTED_AND_EARNED_REVENUE_REPORT_URL = FINANCIAL_ROOT_URL + '/collected-and-earned-revenue-report';
const COMMISSION_REPORT_URL = FINANCIAL_ROOT_URL + '/commission-report';

// >>> CUSTOM
const CUSTOM_ROOT_URL = REPORTS_ROOT_URL + '/custom';
const REVENUE_REPORT_URL = CUSTOM_ROOT_URL + '/revenue-report';
const NET_SUITE_EXPORT_REPORT_URL = CUSTOM_ROOT_URL + '/net-suite-export-report';
const CUSTOM_BILLING_REPORT_URL = CUSTOM_ROOT_URL + '/monthly-billing-report';

// >>> HARDWARE
const HARDWARE_REPORT_ROOT_URL = REPORTS_ROOT_URL + '/hardware';
const INVALID_SCAN_REPORT_URL = HARDWARE_REPORT_ROOT_URL + '/invalid-scan-report';
const ANDROID_DEBUG_LOGS_URL = HARDWARE_REPORT_ROOT_URL + '/android-debug-logs';

// >>> MANAGEMENT
export const MANAGEMENT_ROOT_URL = SECURED_ROOT_URL + '/management';

// >>> INVENTORY
const INVENTORY_ROOT_URL = MANAGEMENT_ROOT_URL + '/inventory';
const CATEGORIES_AND_PRODUCTS_URL = INVENTORY_ROOT_URL + '/product-management';
const OCCURRENCE_MANAGEMENT_URL = INVENTORY_ROOT_URL + '/occurrence-management';
const PASSES_URL = INVENTORY_ROOT_URL + '/multipass-management';
const PASS_CARD_MANAGEMENT_URL = INVENTORY_ROOT_URL + '/pass-card-management';
const BUSES_URL = INVENTORY_ROOT_URL + '/buses';
const HOLIDAYS_URL = INVENTORY_ROOT_URL + '/holidays';
const LOCATIONS_AND_LOCATION_LISTS_URL = INVENTORY_ROOT_URL + '/locations-and-routes';
const GROUPS_URL = INVENTORY_ROOT_URL + '/groups';

// >>>>>> SALES
const SALES_MANAGEMENT_ROOT_URL = MANAGEMENT_ROOT_URL + '/sales';
const QUICK_SELLING_BUTTON_PRODUCTS_URL = SALES_MANAGEMENT_ROOT_URL + '/quick-selling-button-products';
const QUICK_SELLING_BUTTON_PASSES_URL = SALES_MANAGEMENT_ROOT_URL + '/quick-selling-button-passes';
const TIERS_URL = SALES_MANAGEMENT_ROOT_URL + '/tiers';
const COSTS_URL = SALES_MANAGEMENT_ROOT_URL + '/costs';
const POST_BILL_TYPES_URL = SALES_MANAGEMENT_ROOT_URL + '/post-bill-types';
const COMMISSIONS_URL = SALES_MANAGEMENT_ROOT_URL + '/commissions';

// >>> SYSTEM
const SYSTEM_ROOT_URL = MANAGEMENT_ROOT_URL + '/system';
const EMAIL_HTML_TEMPLATES_URL = SYSTEM_ROOT_URL + '/templates';
const ROLES_AND_USERS_URL = SYSTEM_ROOT_URL + '/user-management';
const FAVORITES_URL = SYSTEM_ROOT_URL + '/favorites-management';
const NOTIFICATIONS_URL = SYSTEM_ROOT_URL + '/notifications-management';

// >>>>> CUSTOMERS
const CUSTOMERS_ROOT_URL = MANAGEMENT_ROOT_URL + '/customers';
const CUSTOMERS_URL = CUSTOMERS_ROOT_URL + '/customer';
const SALE_GROUPS_MANAGMENT_URL = CUSTOMERS_ROOT_URL + '/sale-groups-management';
const SALE_GROUPS_URL = CUSTOMERS_ROOT_URL + '/sale-groups';
const DOMAINS_URL = CUSTOMERS_ROOT_URL +'/domains'
const CUSTOMER_OWNER_MANAGMENT_URL = CUSTOMERS_ROOT_URL + '/customer-owner-management';
const ORDER_HISTORY_URL = CUSTOMERS_ROOT_URL + '/order-history';

// >>>>>> HARDWARE
const HARDWARE_ROOT_URL = MANAGEMENT_ROOT_URL + '/hardware';
const ANDROID_DEVICE_INFOS_URL = HARDWARE_ROOT_URL + '/android-devices';
const KIOSK_DEVICE_INFOS_URL = HARDWARE_ROOT_URL + '/kiosks';
const KIOSK_CONFIGURATIONS_URL = HARDWARE_ROOT_URL + '/kiosk-configurations';
const KIOSK_CONFIGURATION_DETAILS_URL = KIOSK_CONFIGURATIONS_URL;
const CLOUD_PAYMENT_DEVICES_URL = HARDWARE_ROOT_URL + '/cloud-payment-devices';
const OVERRIDE_REASON_URL = HARDWARE_ROOT_URL + '/over-ride-reason';

// >>>>>> ADMIN PANEL
const ADMIN_PANEL_ROOT_URL = MANAGEMENT_ROOT_URL + '/admin-panel';
const ADMIN_PANEL_CONFIGURATIONS_URL = ADMIN_PANEL_ROOT_URL + '/configurations';
const ADMIN_PANEL_ANALYTICS_URL = ADMIN_PANEL_ROOT_URL + '/analytics';
const ADMIN_PANEL_MONITORING_URL = ADMIN_PANEL_ROOT_URL + '/monitoring';
const ADMIN_PANEL_TOOLS_URL = ADMIN_PANEL_ROOT_URL + '/tools';
const ADMIN_PANEL_MERCHANTS_URL = ADMIN_PANEL_ROOT_URL + '/merchants';
const SYSTEM_OPTIONS_URL = ADMIN_PANEL_ROOT_URL + '/system-options';
const COMPANY_INFOS_URL = ADMIN_PANEL_ROOT_URL + '/company-infos';

// >>>>>> RELATED ORDERS
const RELATED_ORDERS_URL = SECURED_ROOT_URL + '/related-orders';

@Injectable()
export class AppRoutesService {

    constructor(private router: Router) {
    }

    goToHomePage() {
        this.router.navigate([this.homePageUrl()]);
    }

    goToLoginPage() {
        this.router.navigate([LOGIN_URL]);
    }

    goToLogoutPage(logout: boolean) {
        this.router.navigate([LOGOUT_URL], { queryParams: { logout: logout } });
    }

    goToManagementKioskConfigurationDetailsPage(kioskConfigurationId: number) {
        this.router.navigate([KIOSK_CONFIGURATION_DETAILS_URL + '/' + kioskConfigurationId]);
    }

    goToOrderFormPage(orderExternalID: string, method: string){
        const data = {
            orderExternalID: orderExternalID,
            method: method
        };
        if (this.router.url.includes('order-long-form')) {
            this.router.navigateByUrl('', {skipLocationChange: true}).then(()=>{
                this.router.navigate([ORDER_LONG_FORM_URL], { state: data });
            })
        }
        else {
            this.router.navigate([ORDER_LONG_FORM_URL], { state: data });
        }
    }

    goToOrderForme(){
        this.router.navigate([ORDER_LONG_FORM_URL])
    }

    goToCustomerPage() {
        this.router.navigate([CUSTOMERS_URL])
    }

    homePageUrl() {
        return HOME_URL;
    }

    orderLongFormPageUrl() {
        return ORDER_LONG_FORM_URL;
    }
    orderQSBUrl() {
        return ORDER_QSB_URL;
    }

    purchaseCashoutEntryPageUrl() {
        return CASHOUT_ENTRY_URL;
    }

    purchaseCashoutReconciliationPageUrl() {
        return CASHOUT_RECONCILIATION_URL;
    }

    orderReportsPageUrl() {
        return ORDER_REPORTS_URL;
    }

    autoReportsPageUrl() {
        return AUTO_REPORTS_URL;
    }

    auditLogReportsPageUrl() {
        return AUDIT_LOG_REPORTS_URL;
    }

    invalidScanReportPageUrl() {
        return INVALID_SCAN_REPORT_URL;
    }

    androidDebugLogsPageUrl() {
        return ANDROID_DEBUG_LOGS_URL;
    }

    operationsReportPageUrl() {
        return OPERATIONS_REPORT_URL;
    }

    overrideReportPageUrl() {
        return OVERRIDE_REPORT_URL;
    }

    driverManifestReportPageUrl() {
        return DRIVER_MANIFEST_REPORT_URL;
    }

    tripRevenueReportPageUrl() {
        return TRIP_REVENUE_REPORT_URL;
    }

    ticketTypeRevenueReportPageUrl() {
        return TICKET_TYPE_REVENUE_REPORT_URL;
    }

    collectedAndEarnedRevenueReportPageUrl() {
        return COLLECTED_AND_EARNED_REVENUE_REPORT_URL;
    }

    commissionReportPageUrl() {
        return COMMISSION_REPORT_URL;
    }

    revenueReportPageUrl() {
        return REVENUE_REPORT_URL;
    }

    netSuiteExportReportPageUrl() {
        return NET_SUITE_EXPORT_REPORT_URL;
    }

    customBillingReportPageUrl(){
        return CUSTOM_BILLING_REPORT_URL;
    }

    managementCategoriesAndProductsPageUrl() {
        return CATEGORIES_AND_PRODUCTS_URL;
    }

    managementOccurrenceManagementPageUrl() {
        return OCCURRENCE_MANAGEMENT_URL;
    }

    managementPassesPageUrl() {
        return PASSES_URL;
    }

    managementPassCardManagementPageUrl() {
        return PASS_CARD_MANAGEMENT_URL;
    }

    managementBusesPageUrl() {
        return BUSES_URL;
    }

    managementHolidaysPageUrl() {
        return HOLIDAYS_URL;
    }

    managementLocationsAndLocationListsPageUrl() {
        return LOCATIONS_AND_LOCATION_LISTS_URL;
    }

    managementGroupsPageUrl() {
        return GROUPS_URL;
    }

    managementQuickSellingButtonProductsPageUrl() {
        return QUICK_SELLING_BUTTON_PRODUCTS_URL;
    }

    managementQuickSellingButtonPassesPageUrl() {
        return QUICK_SELLING_BUTTON_PASSES_URL;
    }

    managementTiersPageUrl() {
        return TIERS_URL;
    }

    managementCostsPageUrl() {
        return COSTS_URL;
    }

    managementPostBillTypesPageUrl() {
        return POST_BILL_TYPES_URL;
    }

    managementCommissionsPageUrl() {
        return COMMISSIONS_URL;
    }

    managementSystemOptionsPageUrl() {
        return SYSTEM_OPTIONS_URL;
    }

    managementCompanyInfosPageUrl() {
        return COMPANY_INFOS_URL;
    }

    managementEmailHtmlTemplatesPageUrl() {
        return EMAIL_HTML_TEMPLATES_URL;
    }

    managementRolesAndUsersPageUrl() {
        return ROLES_AND_USERS_URL;
    }

    managementFavoritesPageUrl() {
        return FAVORITES_URL;
    }

    managementNotificationsPageUrl() {
        return NOTIFICATIONS_URL;
    }

    managementAndroidDeviceInfosPageUrl() {
        return ANDROID_DEVICE_INFOS_URL;
    }

    managementOverrideReasonPageUrl() {
        return OVERRIDE_REASON_URL;
    }

    managementKioskDeviceInfosPageUrl() {
        return KIOSK_DEVICE_INFOS_URL;
    }

    managementKioskConfigurationsPageUrl() {
        return KIOSK_CONFIGURATIONS_URL;
    }

    managementAdminPanelConfigurations() {
        return ADMIN_PANEL_CONFIGURATIONS_URL;
    }

    managementAdminPanelAnalytics() {
        return ADMIN_PANEL_ANALYTICS_URL;
    }

    managementAdminPanelMonitoring() {
        return ADMIN_PANEL_MONITORING_URL;
    }

    managementAdminPanelMerchants() {
        return ADMIN_PANEL_MERCHANTS_URL;
    }

    managementAdminPanelTools() {
        return ADMIN_PANEL_TOOLS_URL;
    }

    managementCustomersPageUrl() {
        return CUSTOMERS_URL;
    }

    managementSaleGroupsManagmentPageUrl() {
        return SALE_GROUPS_MANAGMENT_URL;
    }

    managementSaleGroupsPageUrl() {
        return SALE_GROUPS_URL;
    }

    managementDomainsPageUrl() {
        return DOMAINS_URL;
    }

    managementCustomerOwnerPageUrl() {
        return CUSTOMER_OWNER_MANAGMENT_URL;
    }

    managementCloudPaymentDevicesPageUrl() {
        return CLOUD_PAYMENT_DEVICES_URL;
    }

    goToOrderHistoryForCustomer(data: number) {
        this.router.navigate([ORDER_HISTORY_URL], { queryParams: { data: data } });
    }

    // ###############################################################################################################################
    // ######################################################## PAGE BREADCRUMBS #####################################################
    // ###############################################################################################################################

    createHomePageBreadcrumbs(): BreadcrumbItemModel[] {
        return [];
    }

    createOrderLongFormBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Sales', SALES_ROOT_URL),
            new BreadcrumbItemModel('Place Order', PLACE_ORDER_ROOT_URL),
            new BreadcrumbItemModel('Order - Long Form', ORDER_LONG_FORM_URL)
        ];
    }

    createOrderQSBPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Sales', SALES_ROOT_URL),
            new BreadcrumbItemModel('Place Order', PLACE_ORDER_ROOT_URL),
            new BreadcrumbItemModel('Order - QSB', ORDER_QSB_URL)
        ];
    }

    createCashoutEntryBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Sales', SALES_ROOT_URL),
            new BreadcrumbItemModel('End of Shift', END_OF_SHIFT_ROOT_URL),
            new BreadcrumbItemModel('Cashout Entry', CASHOUT_ENTRY_URL)
        ];
    }

    createCashoutReconciliationBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Sales', SALES_ROOT_URL),
            new BreadcrumbItemModel('End of Shift', END_OF_SHIFT_ROOT_URL),
            new BreadcrumbItemModel('Cashout Reconciliation', CASHOUT_RECONCILIATION_URL)
        ];
    }

    createOrderReportsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('General', GENERAL_ROOT_URL),
            new BreadcrumbItemModel('Order Reports', ORDER_REPORTS_URL)
        ];
    }

    createAutoReportsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('General', GENERAL_ROOT_URL),
            new BreadcrumbItemModel('Auto Reports', ORDER_REPORTS_URL)
        ];
    }

    createAuditLogReportPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('General', GENERAL_ROOT_URL),
            new BreadcrumbItemModel('Audit Log Report', AUDIT_LOG_REPORTS_URL)
        ];
    }

    createInvalidScanReportsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('Hardware', HARDWARE_REPORT_ROOT_URL),
            new BreadcrumbItemModel('Invalid Scan Report', INVALID_SCAN_REPORT_URL)
        ];
    }

    createAndroidDebugLogsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('Hardware', HARDWARE_REPORT_ROOT_URL),
            new BreadcrumbItemModel('Android Debug Logs', ANDROID_DEBUG_LOGS_URL)
        ];
    }

    createOperationsReportPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('Operational', OPERATIONAL_ROOT_URL),
            new BreadcrumbItemModel('Operations Report', OPERATIONS_REPORT_URL)
        ];
    }

    createOverrideReportPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('Operational', OPERATIONAL_ROOT_URL),
            new BreadcrumbItemModel('Override Report', OVERRIDE_REPORT_URL)
        ];
    }

    createDriverManifestReportPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('Operational', OPERATIONAL_ROOT_URL),
            new BreadcrumbItemModel('Driver Manifest Report', DRIVER_MANIFEST_REPORT_URL)
        ];
    }

    createTripRevenueReportPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('Financial', FINANCIAL_ROOT_URL),
            new BreadcrumbItemModel('Trip Revenue Report', TRIP_REVENUE_REPORT_URL)
        ];
    }

    createTicketTypeRevenueReportPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('Financial', FINANCIAL_ROOT_URL),
            new BreadcrumbItemModel('Ticket Type Revenue Report', TICKET_TYPE_REVENUE_REPORT_URL)
        ];
    }

    createCollectedAndEarnedRevenueReportPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('Financial', FINANCIAL_ROOT_URL),
            new BreadcrumbItemModel('Collected & Earned Revenue Report', COLLECTED_AND_EARNED_REVENUE_REPORT_URL)
        ];
    }

    createCommissionReportPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('Financial', FINANCIAL_ROOT_URL),
            new BreadcrumbItemModel('Commission Report', COMMISSION_REPORT_URL)
        ];
    }

    createRevenueReportPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('Custom', CUSTOM_ROOT_URL),
            new BreadcrumbItemModel('Revenue Report', REVENUE_REPORT_URL)
        ];
    }

    createNetSuiteExportReportPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('Custom', CUSTOM_ROOT_URL),
            new BreadcrumbItemModel('NetSuite Export Report', NET_SUITE_EXPORT_REPORT_URL)
        ];
    }

    createCustomBillingReportPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Reporting', REPORTS_ROOT_URL),
            new BreadcrumbItemModel('Custom', CUSTOM_ROOT_URL),
            new BreadcrumbItemModel('Monthly Billing Report', CUSTOM_BILLING_REPORT_URL)
        ];
    }

    createCategoriesAndProductsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Inventory', INVENTORY_ROOT_URL),
            new BreadcrumbItemModel('Product Management', CATEGORIES_AND_PRODUCTS_URL)
        ];
    }

    createOccurrenceManagementPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Inventory', INVENTORY_ROOT_URL),
            new BreadcrumbItemModel('Occurrence Management', OCCURRENCE_MANAGEMENT_URL)
        ];
    }

    createPassesPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Inventory', INVENTORY_ROOT_URL),
            new BreadcrumbItemModel('MultiPass Management', PASSES_URL)
        ];
    }

    createPassCardManagementPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Inventory', INVENTORY_ROOT_URL),
            new BreadcrumbItemModel('MultiPass Card Management', PASS_CARD_MANAGEMENT_URL)
        ];
    }

    createBusesPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Inventory', INVENTORY_ROOT_URL),
            new BreadcrumbItemModel('Buses', BUSES_URL)
        ];
    }

    createHolidaysPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Inventory', INVENTORY_ROOT_URL),
            new BreadcrumbItemModel('Holidays', HOLIDAYS_URL)
        ];
    }

    createLocationsAndLocationListsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Inventory', INVENTORY_ROOT_URL),
            new BreadcrumbItemModel('Locations & Routes', LOCATIONS_AND_LOCATION_LISTS_URL)
        ];
    }

    createGroupsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Inventory', INVENTORY_ROOT_URL),
            new BreadcrumbItemModel('Groups', GROUPS_URL)
        ];
    }

    createQuickSellingButtonProductsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Sales', SALES_MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Quick Selling Button Products', QUICK_SELLING_BUTTON_PRODUCTS_URL)
        ];
    }

    createQuickSellingButtonPassesPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Sales', SALES_MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Quick Selling Button Passes', QUICK_SELLING_BUTTON_PASSES_URL)
        ];
    }

    createTiersPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Sales', SALES_MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Tiers', TIERS_URL)
        ];
    }

    createCostsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Sales', SALES_MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Costs', COSTS_URL)
        ];
    }

    createPostBillTypesPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Sales', SALES_MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Post Bill Types', POST_BILL_TYPES_URL)
        ];
    }

    createCommissionsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Sales', SALES_MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Commissions', COMMISSIONS_URL)
        ];
    }

    createEmailHtmlTemplatesPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('System', SYSTEM_ROOT_URL),
            new BreadcrumbItemModel('Templates', EMAIL_HTML_TEMPLATES_URL)
        ];
    }

    createRolesAndUsersPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('System', SYSTEM_ROOT_URL),
            new BreadcrumbItemModel('User Management', ROLES_AND_USERS_URL)
        ];
    }

    createFavoritesPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('System', SYSTEM_ROOT_URL),
            new BreadcrumbItemModel('Favorites Management', FAVORITES_URL)
        ];
    }

    createNotificationsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('System', SYSTEM_ROOT_URL),
            new BreadcrumbItemModel('Notifications Management', NOTIFICATIONS_URL)
        ];
    }

    createAndroidDeviceInfosPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Hardware', HARDWARE_ROOT_URL),
            new BreadcrumbItemModel('Android Devices', ANDROID_DEVICE_INFOS_URL)
        ];
    }

    createKioskDeviceInfosPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Hardware', HARDWARE_ROOT_URL),
            new BreadcrumbItemModel('Kiosks', KIOSK_DEVICE_INFOS_URL)
        ];
    }

    createKioskConfigurationsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Hardware', HARDWARE_ROOT_URL),
            new BreadcrumbItemModel('Kiosk Configurations', KIOSK_CONFIGURATIONS_URL)
        ];
    }

    createCloudPaymentsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Hardware', HARDWARE_ROOT_URL),
            new BreadcrumbItemModel('Cloud payments management', CLOUD_PAYMENT_DEVICES_URL)
        ];
    }

    createOverrideReasonsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Hardware', HARDWARE_ROOT_URL),
            new BreadcrumbItemModel('Override reason', OVERRIDE_REASON_URL)
        ];
    }

    createKioskConfigurationDetailsPageBreadcrumbs(id: number): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Hardware', HARDWARE_ROOT_URL),
            new BreadcrumbItemModel('Kiosk Configurations', KIOSK_CONFIGURATIONS_URL),
            new BreadcrumbItemModel('Kiosk Configuration Details', KIOSK_CONFIGURATION_DETAILS_URL + '/' + id),
        ];
    }

    createAdminPanelConfigurationsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Admin Panel', ADMIN_PANEL_ROOT_URL),
            new BreadcrumbItemModel('Configurations', ADMIN_PANEL_CONFIGURATIONS_URL)
        ];
    }

    createAdminPanelAnalyticsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Admin Panel', ADMIN_PANEL_ROOT_URL),
            new BreadcrumbItemModel('Analytics', ADMIN_PANEL_ANALYTICS_URL)
        ];
    }

    createAdminPanelMonitoringPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Admin Panel', ADMIN_PANEL_ROOT_URL),
            new BreadcrumbItemModel('Monitoring', ADMIN_PANEL_MONITORING_URL)
        ];
    }

    createAdminPanelToolsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Admin Panel', ADMIN_PANEL_ROOT_URL),
            new BreadcrumbItemModel('Tools', ADMIN_PANEL_TOOLS_URL)
        ];
    }

    createAdminPanelMerchantsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Admin Panel', ADMIN_PANEL_ROOT_URL),
            new BreadcrumbItemModel('Merchants', ADMIN_PANEL_MERCHANTS_URL)
        ];
    }

    createAdminPanelSystemOptionsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Admin Panel', ADMIN_PANEL_ROOT_URL),
            new BreadcrumbItemModel('System Options', SYSTEM_OPTIONS_URL)
        ];
    }

    createAdminPanelCompanyInfosPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Admin Panel', ADMIN_PANEL_ROOT_URL),
            new BreadcrumbItemModel('Company Infos', COMPANY_INFOS_URL)
        ];
    }

    createCustomerPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Customers', CUSTOMERS_ROOT_URL),
            new BreadcrumbItemModel('Customers', CUSTOMERS_ROOT_URL)
        ];
    }

    createCustomerOrderHistoryPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Customers', CUSTOMERS_ROOT_URL),
            new BreadcrumbItemModel('Customers', CUSTOMERS_ROOT_URL),
            new BreadcrumbItemModel('Order History', ORDER_HISTORY_URL),
        ];
    }

    createSaleGroupsManagementPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Customers', CUSTOMERS_ROOT_URL),
            new BreadcrumbItemModel('Sale Groups Management', SALE_GROUPS_MANAGMENT_URL)
        ];
    }

    createSaleGroupsPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Customers', CUSTOMERS_ROOT_URL),
            new BreadcrumbItemModel('Sale Group', SALE_GROUPS_URL)
        ];
    }

    createDomainsManagementPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Customers', CUSTOMERS_ROOT_URL),
            new BreadcrumbItemModel('Domains Management', DOMAINS_URL)
        ];
    }

    createCustomerOwnerManagementPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Management', MANAGEMENT_ROOT_URL),
            new BreadcrumbItemModel('Customers', CUSTOMERS_ROOT_URL),
            new BreadcrumbItemModel('Customer Owners Management', CUSTOMER_OWNER_MANAGMENT_URL)
        ];
    }

    createRelatedOrdersPageBreadcrumbs(): BreadcrumbItemModel[] {
        return [
            new BreadcrumbItemModel('Related Orders', RELATED_ORDERS_URL)
        ];
    }
}
