import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CreateBaseResponseModel} from '../create-base-response.model';
import {CreateNotificationRequestModel} from './request/create-notification-request.model';
import {UpdateNotificationRequestModel} from './request/update-notification-request.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {NotificationResponseModel} from "./response/notification-response.model";
import {NotificationBaseUserResponseModel} from './response/notification-base-user-response.model';

@Injectable()
export class NotificationRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<NotificationResponseModel[]> {
        return this.httpClient.get<NotificationResponseModel[]>(environment.server_api_url + '/api/secured/notification/getAll');
    }

    getById(notificationId: number): Observable<NotificationResponseModel> {
        return this.httpClient.get<NotificationResponseModel>(environment.server_api_url + '/api/secured/notification/getById/' + notificationId);
    }

    getActiveNotificationsByCurrentUser(): Observable<NotificationResponseModel[]> {
        return this.httpClient.get<NotificationResponseModel[]>(environment.server_api_url + '/api/secured/notification/getActiveNotificationsByCurrentUser');
    }

    create(createNotificationRequest: CreateNotificationRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/notification/create', createNotificationRequest);
    }

    update(updateNotificationRequest: UpdateNotificationRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/notification/update', updateNotificationRequest, {responseType: 'text'});
    }

    delete(notificationId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/notification/delete', notificationId, {responseType: 'text'});
    }

    activate(notificationId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/notification/activate', notificationId, {responseType: 'text'});
    }

    deactivate(notificationId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/notification/deactivate', notificationId, {responseType: 'text'});
    }

    getNotificationBaseUsers(notificationId: number): Observable<NotificationBaseUserResponseModel[]> {
        return this.httpClient.get<NotificationBaseUserResponseModel[]>(environment.server_api_url + '/api/secured/notification/getById/' + notificationId);
    }

    getNotificationHistoryByUser(baseUserId: number): Observable<NotificationResponseModel[]> {
        return this.httpClient.get<NotificationResponseModel[]>(environment.server_api_url + '/api/secured/notification/getAllNotificationByBaseUserId/' + baseUserId);
    }

    preview(notificationId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/notification/preview', notificationId, {responseType: 'text'});
    }

}
