import {Action} from '@ngrx/store';
import {LocationResponseModel} from "../../../../../../../core/modules/rest/location/response/location-response.model";
import {PassSimpleResponseModel} from "../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {KioskDeviceInfoSimpleModel} from "../../model/kiosk-device-info-simple.model";
import {KioskConfigurationModel} from "../../model/kiosk-configuration.model";
import {KioskConfigurationDropoffLocationValidatedModel} from "../../model/kiosk-configuration-dropoff-location-validated.model";


export const UPDATE_KIOSK_CONFIGURATION_ACTION = '[Kiosk Configuration Details Main] Update kiosk configuration';
export const UPDATE_KIOSKS_WITH_THIS_CONFIGURATION_ACTION = '[Kiosk Configuration Details Main] Update kiosks with this configuration';
export const UPDATE_KIOSKS_WITHOUT_CONFIGURATION_ACTION = '[Kiosk Configuration Details Main] Update kiosks without configuration';
export const UPDATE_KIOSK_CHECKED_STATUS_ACTION = '[Kiosk Configuration Details Main] Update kiosk checked status';
export const UPDATE_KIOSK_CONFIGURATION_DROPOFF_LOCATIONS_ACTION = '[Kiosk Configuration Details Main] Update kiosk configuration dropoff locations';
export const UPDATE_KIOSK_CONFIGURATION_DROPOFF_LOCATION_ACTION = '[Kiosk Configuration Details Main] Update kiosk configuration dropoff location';
export const UPDATE_SELECTED_KIOSK_CONFIGURATION_DROPOFF_LOCATION_ID_ACTION = '[Kiosk Configuration Details Main] Update selected kiosk configuration dropoff location id';
export const UPDATE_LOCATIONS_ACTION = '[Kiosk Configuration Details Main] Update locations';
export const UPDATE_PASSES_ACTION = '[Kiosk Configuration Details Main] Update kioskConfigurationDropoffLocationPasses';
export const UPDATE_KIOSK_CONFIGURATION_DROPOFF_LOCATION_ACTIVITY_STATUS = '[Kiosk Configuration Details Main] Update kiosk configuration dropoff location activity status';
export const ADD_KIOSK_CONFIGURATION_DROPOFF_LOCATION = '[Kiosk Configuration Details Main] Add kiosk configuration dropoff location';
export const SWAP_KIOSK_CONFIGURATION_DROPOFF_LOCATIONS_PLACES = '[Kiosk Configuration Details Main] Swap kiosk configuration dropoff locations places';
export const DELETE_KIOSK_CONFIGURATION_DROPOFF_LOCATION = '[Kiosk Configuration Details Main] Delete kiosk configuration dropoff location';


export class UpdateKioskConfiguration implements Action {
    readonly type = UPDATE_KIOSK_CONFIGURATION_ACTION;

    constructor(public kioskConfiguration: KioskConfigurationModel) {
    }
}

export class UpdateKiosksWithThisConfiguration implements Action {
    readonly type = UPDATE_KIOSKS_WITH_THIS_CONFIGURATION_ACTION;

    constructor(public kioskDeviceInfos: KioskDeviceInfoSimpleModel[]) {
    }
}

export class UpdateKiosksWithoutConfiguration implements Action {
    readonly type = UPDATE_KIOSKS_WITHOUT_CONFIGURATION_ACTION;

    constructor(public kioskDeviceInfos: KioskDeviceInfoSimpleModel[]) {
    }
}

export class UpdateKioskCheckedStatus implements Action {
    readonly type = UPDATE_KIOSK_CHECKED_STATUS_ACTION;

    constructor(public kioskDeviceInfoId: number, public checked: boolean) {
    }
}

export class UpdateKioskConfigurationDropoffLocations implements Action {
    readonly type = UPDATE_KIOSK_CONFIGURATION_DROPOFF_LOCATIONS_ACTION;

    constructor(public kioskConfigurationDropoffLocations: KioskConfigurationDropoffLocationValidatedModel[]) {
    }
}

export class UpdateKioskConfigurationDropoffLocation implements Action {
    readonly type = UPDATE_KIOSK_CONFIGURATION_DROPOFF_LOCATION_ACTION;

    constructor(public kioskConfigurationDropoffLocation: KioskConfigurationDropoffLocationValidatedModel) {
    }
}

export class UpdateSelectedKioskConfigurationDropoffLocationId implements Action {
    readonly type = UPDATE_SELECTED_KIOSK_CONFIGURATION_DROPOFF_LOCATION_ID_ACTION;

    constructor(public selectedKioskConfigurationDropoffLocationId: number) {
    }
}

export class UpdateLocations implements Action {
    readonly type = UPDATE_LOCATIONS_ACTION;

    constructor(public locations: LocationResponseModel[]) {
    }
}

export class UpdatePasses implements Action {
    readonly type = UPDATE_PASSES_ACTION;

    constructor(public passes: PassSimpleResponseModel[]) {
    }
}

export class UpdateKioskConfigurationDropoffLocationActivityStatus implements Action {
    readonly type = UPDATE_KIOSK_CONFIGURATION_DROPOFF_LOCATION_ACTIVITY_STATUS;

    constructor(public kioskConfigurationDropoffLocationId: number, public active: boolean) {
    }
}

export class AddKioskConfigurationDropoffLocation implements Action {
    readonly type = ADD_KIOSK_CONFIGURATION_DROPOFF_LOCATION;

    constructor(public kioskConfigurationDropoffLocation: KioskConfigurationDropoffLocationValidatedModel) {
    }
}

export class SwapKioskConfigurationDropoffLocationsPlaces implements Action {
    readonly type = SWAP_KIOSK_CONFIGURATION_DROPOFF_LOCATIONS_PLACES;

    constructor(public kioskConfigurationDropoffLocationId: number, public up: boolean) {
    }
}

export class DeleteKioskConfigurationDropoffLocation implements Action {
    readonly type = DELETE_KIOSK_CONFIGURATION_DROPOFF_LOCATION;

    constructor(public kioskConfigurationDropoffLocationId: number) {
    }
}

export type Actions =
    UpdateKioskConfiguration |
    UpdateKiosksWithThisConfiguration |
    UpdateKiosksWithoutConfiguration |
    UpdateKioskCheckedStatus |
    UpdateKioskConfigurationDropoffLocations |
    UpdateKioskConfigurationDropoffLocation |
    UpdateSelectedKioskConfigurationDropoffLocationId |
    UpdateLocations |
    UpdatePasses |
    UpdateKioskConfigurationDropoffLocationActivityStatus |
    AddKioskConfigurationDropoffLocation |
    SwapKioskConfigurationDropoffLocationsPlaces |
    DeleteKioskConfigurationDropoffLocation;
