
import { throwError as observableThrowError, of as observableOf, combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { tap, take, mergeMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootSandbox } from '../../../../../../core/store/root.sandbox';
import { CategoryResponseModel } from '../../../../../../core/modules/rest/category/response/category-response.model';
import { MultiselectDropdownOptionModel } from '../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model';
import { ProductSimpleResponseModel } from '../../../../../../core/modules/rest/product/response/product-simple-response.model';
import { PassSimpleResponseModel } from '../../../../../../core/modules/rest/pass/response/pass-simple-response.model';
import { RoleResponseModel } from '../../../../../../core/modules/rest/role/response/role-response.model';
import { UserResponseModel } from '../../../../../../core/modules/rest/user/response/user-response.model';
import { LocationResponseModel } from '../../../../../../core/modules/rest/location/response/location-response.model';
import { TierResponseModel } from '../../../../../../core/modules/rest/tier/response/tier-response.model';
import { OrderStatusEnum } from '../../../../../../shared/enums/order-status.enum';
import { PaymentMethodEnum } from '../../../../../../shared/enums/payment-method.enum';
import { PaymentStatusEnum } from '../../../../../../shared/enums/payment-status.enum';
import { PlatformEnum } from '../../../../../../shared/enums/platform.enum';
import { FilterItemModel } from '../model/filter-item.model';
import * as data from '../model/data';

import * as fromFeature from './reducers';
import * as fromMainActions from './main/actions';
import * as fromUiActions from './ui/actions';
import _ from 'lodash';
import { ReportSearchRequestModel } from '../../../../../../core/modules/rest/reporting/request/report-search-request.model';
import { FieldRequestModel } from '../../../../../../core/modules/rest/reporting/request/field-request.model';
import { ReportingRestService } from '../../../../../../core/modules/rest/reporting/reporting-rest.service';
import { AvailableFieldsRequestModel } from '../../../../../../core/modules/rest/reporting/request/available-fields-request.model';
import { ReportSumResponseModel } from '../../../../../../core/modules/rest/reporting/response/report-sum-response.model';
import {
    HeaderResponseModel,
    LevelEnum,
    ReportSearchResponseModel
} from '../../../../../../core/modules/rest/reporting/response/report-search-response.model';
import { SortByRequestModel } from '../../../../../../core/modules/rest/reporting/request/sort-by-request.model';
import { FavoriteRestService } from '../../../../../../core/modules/rest/favorite/favorite-rest.service';
import { FavoriteResponseModel } from '../../../../../../core/modules/rest/favorite/response/favorite-response.model';
import { GroupByRequestModel } from '../../../../../../core/modules/rest/reporting/request/group-by-request.model';
import { HttpErrorModel } from '../../../../../../core/modules/rest/http-error.model';
import { LocationListItemDescriptorResponseModel } from '../../../../../../core/modules/rest/location-list/response/location-list-item-descriptor-response.model';
import { ExpressionItemModel } from '../model/expression-item.model';
import { ReportGroupModel } from '../model/report-group.model';
import { FieldItemModel } from '../model/field-item.model';
import { ReportItemModel } from '../model/report-item.model';
import { DroppedDataI } from '../model/dropped-data.model';
import { UpdateExpressionItemValueModel } from '../model/update-expression-item-value.model';
import { DroppedFieldItemModel } from '../model/dropped-field-item.model';
import { DraggedDataI } from '../model/dragged-data.model';
import { BaseExpressionRequestModel } from '../../../../../../core/modules/rest/reporting/request/base-expression-request.model';
import { ExpressionItemTypeEnum } from '../model/expression-item-type.enum';
import { ExpressionFilterModel } from '../model/expression-filter.model';
import { FilterExpressionRequestModel } from '../../../../../../core/modules/rest/reporting/request/filter-expression-request.model';
import { ExpressionGroupModel } from '../model/expression-group.model';
import { GroupExpressionRequestModel } from '../../../../../../core/modules/rest/reporting/request/group-expression-request.model';
import { OperatorExpressionRequestModel } from '../../../../../../core/modules/rest/reporting/request/operator-expression-request.model';
import { ExpressionOperatorModel } from '../model/expression-operator.model';
import { ReportSumRequestModel } from '../../../../../../core/modules/rest/reporting/request/report-sum-request.model';
import { CurrentSearchDataModel } from '../model/current-search-data.model';
import { FavoritesResponseModel } from '../../../../../../core/modules/rest/favorite/response/favorites-response.model';
import { SortByModel } from '../../../../../../shared/model/sort-by.model';
import { AvailableFieldsResponseModel } from "../../../../../../core/modules/rest/reporting/response/available-fields-response.model";
import { FavoriteTypeEnum } from "../../../../../../shared/enums/favorite-type.enum";
import { SystemPermissionsRestService } from '../../../../../../core/modules/rest/system-permissions/system-permissions-rest.service';
import { SystemPermissionsResponseModel } from '../../../../../../core/modules/rest/system-permissions/response/system-permissions-response.model';
import { SystemPermissionEnum } from '../../../../../../shared/enums/system-permission.enum';

const NO_LOCATION_VALUE = '-1';
const IN_TRANSIT_VALUE = '-1';
@Injectable()
export class OrderReportsSandbox {

    systemPermissions: SystemPermissionsResponseModel[];

    constructor(private store: Store<fromFeature.State>, private rootSandbox: RootSandbox,
        private reportingRestService: ReportingRestService, private favoriteRestService: FavoriteRestService,
        private systemPermissionsRestService: SystemPermissionsRestService) {
            this.systemPermissionsRestService.getSystemPermissions().subscribe((systemPermissions: SystemPermissionsResponseModel[]) => {
            this.systemPermissions = systemPermissions;
        });

    }

    loadPageData() {
        this.loadAllOrderReportsFavoritesByUser(null);
    }

    loadAllOrderReportsFavoritesByUser(favoriteId: number) {
        this.favoriteRestService.getActiveFavoritesByCurrentUserAndFavoriteType(FavoriteTypeEnum.ORDER_REPORTS).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while getting all order reports favorites by user", error);
            }))
            .subscribe((res: FavoritesResponseModel) => {
                this.store.dispatch(new fromMainActions.UpdateUserFavorites(res.favorites, favoriteId));
            });
    }

    addField(field: DroppedFieldItemModel) {
        this.store.dispatch(new fromMainActions.AddField(field));
    }

    updateDraggingData(draggedData: DraggedDataI) {
        this.store.dispatch(new fromMainActions.UpdateDraggedData(draggedData));
    }

    onDroppedData(droppedData: DroppedDataI) {
        this.store.dispatch(new fromMainActions.AddExpressionItem(droppedData));
    }

    deleteExpressionItem(uuid: string) {
        this.store.dispatch(new fromMainActions.DeleteExpressionItemByUUID(uuid));
    }

    updateExpressionItemValue(item: UpdateExpressionItemValueModel) {
        this.store.dispatch(new fromMainActions.UpdateExpressionItemValue(item));
    }

    deleteSelectedField(queryFieldName: string) {
        this.store.dispatch(new fromMainActions.DeleteField(queryFieldName));
    }

    areEnoughFieldsSelected(): Observable<boolean> {
        return this.getSelectedFields().pipe(
            map((selectedFields: FieldItemModel[]) => {
                return selectedFields.length >= 2;
            }));
    }

    canCreateOrderReport() {
        return observableCombineLatest(
            this.getExpressionItems(),
            this.areEnoughFieldsSelected()
        ).pipe(map(([expressionItems, areEnoughFieldsSelected]) => {
            const expressionItemsParameterized: ExpressionItemModel[] = expressionItems;
            const areEnoughFieldsSelectedParameterized: boolean = areEnoughFieldsSelected;

            const allExpressionItemsHaveValue: boolean = this.doExpressionItemsHaveValue(expressionItemsParameterized);
            if (!allExpressionItemsHaveValue) {
                return false;
            }

            return areEnoughFieldsSelectedParameterized;
        }));
    }

    createReport() {

        // Initial object
        this.store.dispatch(new fromMainActions.CreateInitialCurrentSearchData());

        const from = 0;
        const size: number = data.ORDER_REPORT_PAGE_SIZE;

        // Perform request
        this.createReportSearchRequest(from, size, true).pipe(
            mergeMap((search: { searchRequest: ReportSearchRequestModel, groupSearch: boolean }) => {

                let request: Observable<ReportSearchResponseModel>;
                if (search.groupSearch) {
                    request = this.reportingRestService.groupOrderReports(search.searchRequest);
                } else {
                    request = this.reportingRestService.searchOrderReports(search.searchRequest);
                }

                // Do request
                return request.pipe(
                    catchError((error: HttpErrorModel) => {
                        return this.rootSandbox.handleHttpError("Error while getting order report", error);
                    }),
                    map((response: ReportSearchResponseModel) => {
                        return {
                            response: response,
                            groupSearch: search.groupSearch
                        };
                    }),);
            }),
            take(1),)
            .subscribe((search: { response: ReportSearchResponseModel, groupSearch: boolean }) => {

                if (search.response != null) {
                    const reportSearchResponse = search.response;

                    const summableFieldExist: boolean = reportSearchResponse.headers.some((header: HeaderResponseModel) => header.summable);

                    this.store.dispatch(new fromMainActions.CreateFirstResponseForCurrentSearchData(
                        reportSearchResponse.headers,
                        reportSearchResponse.rows,
                        reportSearchResponse.level,
                        reportSearchResponse.approximateNumberOfResults,
                        from,
                        size,
                        search.groupSearch,
                        summableFieldExist,
                        reportSearchResponse.scrollId,
                        reportSearchResponse.paymentSummary
                    ));
                }
                else {
                    this.store.dispatch(new fromMainActions.CreateFirstResponseForCurrentSearchData(
                        [],
                        [],
                        null,
                        0,
                        from,
                        size,
                        search.groupSearch,
                        false,
                        "",
                        []
                    ));
                }
            });
    }

    searchForNextReportItems() {

        // Loading more items
        this.store.dispatch(new fromMainActions.LoadingMoreItemsForCurrentSearchData());

        // Perform request
        this.getCurrentSearchData().pipe(
            mergeMap((currentSearchData: CurrentSearchDataModel) => {

                const from = currentSearchData.from + data.ORDER_REPORT_PAGE_SIZE;
                const size = currentSearchData.size;

                return this.createReportSearchRequest(from, size, true).pipe(
                    mergeMap((search: { searchRequest: ReportSearchRequestModel, groupSearch: boolean }) => {

                        let request: Observable<ReportSearchResponseModel>;
                        if (search.groupSearch) {
                            request = this.reportingRestService.groupOrderReports(search.searchRequest);
                        } else {
                            request = this.reportingRestService.searchOrderReports(search.searchRequest);
                        }

                        // Do request
                        return request.pipe(
                            catchError((error: HttpErrorModel) => {
                                return this.rootSandbox.handleHttpError("Error while getting order report", error);
                            }),
                            map((response: ReportSearchResponseModel) => {
                                return {
                                    response: response,
                                    from: from,
                                    size: size
                                };
                            }),);
                    }));

            }),
            take(1),)
            .subscribe((search: { response: ReportSearchResponseModel, from: number, size: number }) => {

                const reportSearchResponse = search.response;

                this.store.dispatch(new fromMainActions.AddNextResponseItemsForCurrentSearchData(
                    reportSearchResponse.headers,
                    reportSearchResponse.rows,
                    search.from,
                    search.size,
                    reportSearchResponse.scrollId
                ));
            });
    }

    generateUrlForExportToXlsx(): Observable<string> {
        return this.createReportSearchRequest(0, 1000, true).pipe(
            mergeMap((search: { searchRequest: ReportSearchRequestModel, groupSearch: boolean }) => {
                // Scroll id should be empty for this
                search.searchRequest.scrollId = null;

                // If we are sending search, remove group requests
                // since API backend decides on whether to do search or group by this
                if (!search.groupSearch) {
                    search.searchRequest.groupByRequests = [];
                }

                return this.reportingRestService.generateUrlForExportToXlsx(search.searchRequest).pipe(
                    catchError((error: HttpErrorModel) => {
                        return this.rootSandbox.handleHttpError("Error while generating url for export to Xlsx", error);
                    }));
            }
            ));
    }

    updateSortBy(sortBy: SortByModel) {
        this.store.dispatch(new fromMainActions.UpdateCurrentSearchDataSortBy(sortBy));
    }

    setSelectedFavoriteId(favoriteId: number) {
        this.store.dispatch(new fromMainActions.UpdateSelectedFavorite(favoriteId));
    }

    deactivateFavorite(favoriteId: number): Observable<any> {
        return this.favoriteRestService.deactivate(favoriteId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while deactivating favorite", error);
            }));
    }

    updateReportItemsSearchText(text: string) {
        this.store.dispatch(new fromMainActions.UpdateReportItemsSearchText(text));
    }

    updateShowPaymentSummary(value: boolean) {
        this.store.dispatch(new fromMainActions.ShowPaymentSummary(value));
    }

    changeReportGroupActivityStatus(name: string) {
        this.store.dispatch(new fromUiActions.ChangeReportGroupActivityStatus(name));
    }

    resetState() {
        this.store.dispatch(new fromMainActions.ResetState());
    }

    resetCurrentSearch() {
        this.store.dispatch(new fromMainActions.ResetCurrentSearchData());
    }

    getAllFavoritesByUser(): Observable<FavoriteResponseModel[]> {
        return this.store.select(fromFeature.mainState_allUserFavorites_selector);
    }

    getSelectedFavoriteId() {
        return this.store.select(fromFeature.mainState_selectedFavoriteId_selector);
    }

    getExpressionItems(): Observable<ExpressionItemModel[]> {
        return this.store.select(fromFeature.mainState_expressionItems_selector);
    }

    getExpressionItemsOrSelectedFieldsChanged(): Observable<void> {
        return observableCombineLatest(
            this.getExpressionItems(),
            this.getSelectedFields()
        ).pipe(
            map(() => {
                return;
            }));
    }

    getSelectedFields(): Observable<FieldItemModel[]> {
        return this.store.select(fromFeature.mainState_selectedFields_selector);
    }

    getDraggedData(): Observable<DraggedDataI> {
        return this.store.select(fromFeature.mainState_draggedData_selector);
    }

    getOrderReportColumnSum(header: HeaderResponseModel, level: LevelEnum): Observable<ReportSumResponseModel> {

        return this.getExpressionItemRequests().pipe(
            mergeMap((expressionItemRequests: BaseExpressionRequestModel[]) => {

                const reportSumRequest = new ReportSumRequestModel(expressionItemRequests, header.elasticsearchName,
                    header.fieldName, header.valueType, level);

                return this.reportingRestService.sum(reportSumRequest).pipe(
                    catchError((error: HttpErrorModel) => {
                        return this.rootSandbox.handleHttpError("Error while getting order report column sum", error);
                    }));
            }
            ));
    }

    getAvailableFields(selectedFields: string[]): Observable<AvailableFieldsResponseModel> {
        this.store.dispatch(new fromUiActions.AvailableFieldsLoadingStart());

        const request: AvailableFieldsRequestModel = new AvailableFieldsRequestModel(selectedFields);

        return this.reportingRestService.getAvailableFields(request).pipe(
            tap((res: AvailableFieldsResponseModel) => {

                this.store.dispatch(new fromUiActions.AvailableFieldsLoadingEnd());

                if (res.level !== LevelEnum.ORDER) {
                    this.store.dispatch(new fromMainActions.DisableShowPaymentSummary(true));
                    this.store.dispatch(new fromMainActions.ShowPaymentSummary(false));
                } else {
                    this.store.dispatch(new fromMainActions.DisableShowPaymentSummary(false));
                }
            }),
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while loading available fields", error);
            }),);
    }

    getAvailableFieldsLoading(): Observable<boolean> {
        return this.store.select(fromFeature.uiState_availableFieldsLoading_selector);
    }

    getReportItemsSearchText(): Observable<string> {
        return this.store.select(fromFeature.mainState_reportItemsSearchText_selector);
    }

    getActiveReportGroups(): Observable<string[]> {
        return this.store.select(fromFeature.uiState_activeReportGroups_selector);
    }

    getCurrentSearchData(): Observable<CurrentSearchDataModel> {
        return this.store.select(fromFeature.mainState_currentSearchData_selector);
    }

    getShowPaymentSummary(): Observable<boolean> {
        return this.store.select(fromFeature.mainState_showPaymentSummary_selector);
    }

    getShowPaymentSummaryDisabled(): Observable<boolean> {
        return this.store.select(fromFeature.mainState_showPaymentSummaryDisabled_selecor);
    }

    getAllReportGroups(): Observable<ReportGroupModel[]> {
        return this.getSelectedFields().pipe(
            mergeMap((selectedFields: FieldItemModel[]) => {

                const selectedFieldsStringArray: string[] = selectedFields.map((selectedField: FieldItemModel) => selectedField.queryFieldName);
                const availableFields: Observable<AvailableFieldsResponseModel> = this.getAvailableFields(selectedFieldsStringArray);

                return observableCombineLatest(observableOf(selectedFields), availableFields).pipe(take(1));
            }
            ),
            mergeMap(([selectedFields, availableFields]) => {
                return observableCombineLatest(
                    observableOf(selectedFields),
                    observableOf(availableFields),
                    this.getAvailableFieldsLoading()
                );
            }),
            map(([selectedFields, availableFields, availableFieldsLoading]) => {
                const reportingGroups: ReportGroupModel[] = data.REPORTING_ITEM_GROUPS;
                const selectedFieldsParameterized: FieldItemModel[] = selectedFields;
                const availableFieldsParameterized: string[] = availableFields.fields;
                const availableFieldsLoadingParameterized: boolean = availableFieldsLoading;

                return reportingGroups
                    .map((reportGroup: ReportGroupModel) => {
                        const groupItems = reportGroup.items;

                        reportGroup.items = groupItems.map((item: ReportItemModel) => {
                            const selected = selectedFieldsParameterized.some((selectedField: FieldItemModel) => selectedField.queryFieldName === item.queryFieldName);
                            const fieldAvailable = availableFieldsParameterized.some((availableField: string) => item.queryFieldName === availableField);

                            item.fieldDisabled = !(!selected && fieldAvailable && !availableFieldsLoadingParameterized);

                            return item;
                        });

                        return reportGroup;
                    });
            }),
            mergeMap((reportingGroups: ReportGroupModel[]) => {
                return observableCombineLatest(
                    observableOf(reportingGroups),
                    this.getReportItemsSearchText()
                );
            }),
            map(([reportingGroups, reportItemsSearchText]) => {
                const resultList: ReportGroupModel[] = [];

                for (const reportingGroup of reportingGroups) {
                    const reportGroupItems: ReportItemModel[] = reportingGroup.items.filter((item: ReportItemModel) =>
                        item.description.toLowerCase().indexOf(reportItemsSearchText.toLowerCase()) !== -1);

                    if (reportGroupItems.length !== 0) {
                        resultList.push(new ReportGroupModel(reportingGroup.name, reportGroupItems));
                    }
                }

                return resultList;
            }),);
    }

    getFilterOptions(filterItem: FilterItemModel): Observable<MultiselectDropdownOptionModel[]> {
        let observable: Observable<MultiselectDropdownOptionModel[]>;

        switch (filterItem.queryFieldName) {
            case data.REPORT_ITEM_CATEGORY.queryFieldName:
                observable = this.getAllCategoriesForMultiselectDropdown();
                break;
            case data.REPORT_ITEM_PRODUCT.queryFieldName:
                observable = this.getAllProductsForMultiselectDropdown();
                break;
            case data.REPORT_ITEM_STATUS.queryFieldName:
                observable = this.getAllOrderStatusForMultiselectDropdown();
                break;
            case data.REPORT_ITEM_TIER_HEAD_COUNT.queryFieldName:
            case data.REPORT_ITEM_TIER_HEAD_COUNT_ORDER_DETAIL.queryFieldName:
            case data.REPORT_ITEM_TIER_HEAD_COUNT_PASS.queryFieldName:
            case data.REPORT_ITEM_TIER_HEAD_COUNT_CHECKIN.queryFieldName:
                observable = this.getAllTiersForMultiselectDropdown();
                break;
            case data.REPORT_ITEM_PURCHASED_MULTIPASS.queryFieldName:
                observable = this.getAllPassesForMultiselectDropdown();
                break;
            case data.REPORT_ITEM_PAYMENT_METHOD.queryFieldName:
                observable = this.getAllPaymentMethodsForMultiselectDropdown();
                break;
            case data.REPORT_ITEM_PAYMENT_STATUS.queryFieldName:
                observable = this.getAllPaymentStatusesForMultiselectDropdown();
                break;
            case data.REPORT_ITEM_ROLE_SOLD_BY.queryFieldName:
                observable = this.getAllRolesForMultiselectDropdown();
                break;
            case data.REPORT_ITEM_SOLD_BY.queryFieldName:
                observable = this.getAllUsersForMultiselectDropdown();
                break;
            case data.REPORT_ITEM_LOCATION_SOLD.queryFieldName:
                observable = this.getLocationsForMultiselectDropdown();
                break;
            case data.REPORT_ITEM_SALES_PLATFORM.queryFieldName:
                observable = this.getAllPlatformsForMultiselectDropdown();
                break;
            case data.REPORT_ITEM_PICKUP_LOCATION_ORDER_DETAIL.queryFieldName:
            case data.REPORT_ITEM_PICKUP_LOCATION_PASS_ORDER_DETAIL.queryFieldName:
                observable = this.getAllPickupLocations();
                break;
            case data.REPORT_ITEM_DROPOFF_LOCATION_ORDER_DETAIL.queryFieldName:
            case data.REPORT_ITEM_DROPOFF_LOCATION_PASS_ORDER_DETAIL.queryFieldName:
                observable = this.getAllDropoffLocations();
                break;
            case data.REPORT_ITEM_CHECKIN_LOCATION.queryFieldName:
                observable = this.getAllCheckInLocationsForMultiselectDropdown();
                break;
            default:
                observable = observableThrowError('Non-existent filter item');
        }

        return observable.pipe(map(
            (values: MultiselectDropdownOptionModel[]) => {
                return _.cloneDeep(values);
            }
        ));
    }

    private doExpressionItemsHaveValue(expressionItems: ExpressionItemModel[]): boolean {

        for (const expressionItem of expressionItems) {
            switch (expressionItem.itemType) {
                case ExpressionItemTypeEnum.FILTER:
                    if ((<ExpressionFilterModel>expressionItem).filter.data.length === 0) {
                        return false;
                    }
                    break;
                case ExpressionItemTypeEnum.GROUP:
                    const group: ExpressionGroupModel = <ExpressionGroupModel>expressionItem;
                    if (group.items.length === 0) {
                        return false;
                    } else {
                        if (!this.doExpressionItemsHaveValue(group.items)) {
                            return false;
                        }
                    }
                    break;
                case ExpressionItemTypeEnum.OPERATOR:
                    break;
                default:
                    console.error('Sandbox :: doExpressionItemsHaveValue :: Unexpected expression item type');
                    return null;
            }
        }

        return true;
    }

    createReportSearchRequest(from: number, size: number, withSortBy: boolean): Observable<{ searchRequest: ReportSearchRequestModel, groupSearch: boolean }> {

        return observableCombineLatest(
            this.getExpressionItemRequests(),
            this.getFieldRequests(),
            this.getGroupableFieldsRequests(),
            this.getSelectedFields(),
            this.getCurrentSearchData(),
            this.getShowPaymentSummary()
        ).pipe(
            take(1),
            map(([expressionItemRequests, fieldRequests, groupableFieldsRequests, selectedFields, currentSearchData, showPaymentSummary]) => {

                const expressionItemRequestsParameterized: BaseExpressionRequestModel[] = expressionItemRequests;
                const fieldRequestsParameterized: FieldRequestModel[] = fieldRequests;
                let groupableFieldsRequestsParameterized: GroupByRequestModel[] = groupableFieldsRequests;
                const selectedFieldsParameterized: FieldItemModel[] = selectedFields;

                const currentSearchDataParameterized: CurrentSearchDataModel = currentSearchData;
                // if getCurrentSearchData() is not executed
                const scrollId: string = currentSearchData ? currentSearchData.scrollId : '';

                let sortRequests: SortByRequestModel[] = [];
                if (withSortBy && currentSearchDataParameterized) {
                    const sortBy: SortByModel = currentSearchDataParameterized.sortBy;
                    if (sortBy) {
                        sortRequests = [new SortByRequestModel(sortBy.fieldName, sortBy.order)];
                    }
                }

                let groupSearch: boolean;

                // Do search if we have more than 3 group requests
                if (groupableFieldsRequestsParameterized.length > 3) {

                    // When sending search request, group by requests must be empty,
                    // because export chooses whether to do search or group by checking empty state of group by requests.
                    groupableFieldsRequestsParameterized = [];
                    groupSearch = false;

                } else {

                    // If there is any field that is not groupable or summable, we need to do search instead
                    const selectedFieldsThatAreNotGroupable: FieldItemModel[] = selectedFieldsParameterized.filter(selectedField => {
                        return !selectedField.groupable && !selectedField.summable;
                    });

                    if (selectedFieldsThatAreNotGroupable.length !== 0) {
                        groupSearch = false;
                    } else {
                        groupSearch = true;
                        sortRequests = [];
                    }
                }

                const searchRequest: ReportSearchRequestModel = new ReportSearchRequestModel(
                    expressionItemRequestsParameterized,
                    fieldRequestsParameterized,
                    sortRequests,
                    groupableFieldsRequestsParameterized,
                    from,
                    size,
                    scrollId,
                    showPaymentSummary
                );

                return {
                    searchRequest: searchRequest,
                    groupSearch: groupSearch
                };
            }),);
    }

    private getExpressionItemRequests(): Observable<BaseExpressionRequestModel[]> {
        return this.getExpressionItems().pipe(
            take(1),
            map((expressionItems: ExpressionItemModel[]) => {
                return expressionItems.map((item: ExpressionItemModel) => this.createExpressionItemRequest(item));
            }),);
    }

    private createExpressionItemRequest(expressionItem: ExpressionItemModel): BaseExpressionRequestModel {
        switch (expressionItem.itemType) {
            case ExpressionItemTypeEnum.FILTER:
                return this.createExpressionFilterItemRequest(<ExpressionFilterModel>expressionItem);
            case ExpressionItemTypeEnum.GROUP:
                return this.createExpressionGroupItemRequest(<ExpressionGroupModel>expressionItem);
            case ExpressionItemTypeEnum.OPERATOR:
                return this.createExpressionOperatorItemRequest(<ExpressionOperatorModel>expressionItem);
            default:
                console.error('Sandbox :: Unexpected expression item type');
                return null;
        }
    }

    private createExpressionFilterItemRequest(expressionItem: ExpressionFilterModel): FilterExpressionRequestModel {
        return new FilterExpressionRequestModel(expressionItem.filter.queryFieldName, expressionItem.filter.data);
    }

    private createExpressionGroupItemRequest(expressionItem: ExpressionGroupModel): GroupExpressionRequestModel {
        const items: BaseExpressionRequestModel[] = expressionItem.items.map((item: ExpressionItemModel) => this.createExpressionItemRequest(item));
        return new GroupExpressionRequestModel(items);
    }

    private createExpressionOperatorItemRequest(expressionItem: ExpressionOperatorModel): OperatorExpressionRequestModel {
        return new OperatorExpressionRequestModel(expressionItem.operator);
    }

    private getFieldRequests(): Observable<FieldRequestModel[]> {
        return this.getSelectedFields().pipe(
            take(1),
            map((fields: FieldItemModel[]) => {
                return fields.map((fieldItem: FieldItemModel) => new FieldRequestModel(fieldItem.queryFieldName));
            }),);
    }

    private getGroupableFieldsRequests(): Observable<GroupByRequestModel[]> {
        return this.getSelectedFields().pipe(
            map((selectedFields: FieldItemModel[]) => {
                return selectedFields.filter((field: FieldItemModel) => field.groupable);
            }),
            map((fields: FieldItemModel[]) => {
                return fields.map((field: FieldItemModel) => new GroupByRequestModel(field.queryFieldName));
            }),);
    }

    private getAllCategoriesForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        return this.rootSandbox.getAllCategories().pipe(
            map((categories: CategoryResponseModel[]) => {
                return categories.map((category: CategoryResponseModel) =>
                    new MultiselectDropdownOptionModel(category.description, category.categoryId.toString()));
            }
            ));
    }

    private getAllTiersForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        return this.rootSandbox.getAllTiers().pipe(
            map((tiers: TierResponseModel[]) => {
                return tiers.map((tier: TierResponseModel) =>
                    new MultiselectDropdownOptionModel(tier.description, tier.tierId.toString()));
            }
            ));
    }

    private getAllProductsForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        return this.rootSandbox.getAllProducts().pipe(
            map((products: ProductSimpleResponseModel[]) => {
                return products.map((product: ProductSimpleResponseModel) =>
                    new MultiselectDropdownOptionModel(product.description, product.productId.toString()));
            }
            ));
    }

    private getAllPassesForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        return this.rootSandbox.getAllPassesSimple().pipe(
            map((passes: PassSimpleResponseModel[]) => {
                return passes.map((pass: PassSimpleResponseModel) =>
                    new MultiselectDropdownOptionModel(pass.description, pass.passId.toString()));
            }
            ));
    }

    private getAllRolesForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        return this.rootSandbox.getAllRoles().pipe(
            map((roles: RoleResponseModel[]) => {
                return roles.map((role: RoleResponseModel) =>
                    new MultiselectDropdownOptionModel(role.description, role.roleId.toString()));
            }
            ));
    }

    private getAllUsersForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        return this.rootSandbox.getAllUsers().pipe(
            map((users: UserResponseModel[]) => {
                return users.map((user: UserResponseModel) =>
                    new MultiselectDropdownOptionModel(user.displayName, user.userId.toString()));
            }
            ));
    }

    private getAllLocationsForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        return this.rootSandbox.getAllLocations().pipe(
            map((locations: LocationResponseModel[]) => {
                return locations.map((location: LocationResponseModel) =>
                    new MultiselectDropdownOptionModel(location.description, location.locationId.toString()));
            }
            ));
    }

    private getAllCheckInLocationsForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        return this.getAllLocationsForMultiselectDropdown().pipe(
            map((locations: MultiselectDropdownOptionModel[]) => {
                return [
                    ...locations,
                    new MultiselectDropdownOptionModel('In Transit', IN_TRANSIT_VALUE)
                ];
            }));
    }

    private getAllPickupLocations(): Observable<MultiselectDropdownOptionModel[]> {
        return this.getAllLocationsForMultiselectDropdown().pipe(
            map((locations: MultiselectDropdownOptionModel[]) => {
                return [
                    ...locations,
                    new MultiselectDropdownOptionModel('No Location', NO_LOCATION_VALUE)
                ];
            }));
    }

    private getAllDropoffLocations(): Observable<MultiselectDropdownOptionModel[]> {
        return this.getAllLocationsForMultiselectDropdown().pipe(
            map((locations: MultiselectDropdownOptionModel[]) => {
                return [
                    ...locations,
                    new MultiselectDropdownOptionModel('No Location', NO_LOCATION_VALUE)
                ];
            }));
    }

    private getLocationsForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        return this.rootSandbox.getLocations().pipe(
            map((locations: LocationResponseModel[]) => {
                return locations.map((location: LocationResponseModel) =>
                    new MultiselectDropdownOptionModel(location.description, location.locationId.toString()));
            }
            ));
    }

    private getAllOrderStatusForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {

        const options: MultiselectDropdownOptionModel[] = [];

        options.push(new MultiselectDropdownOptionModel('Pending', OrderStatusEnum.PENDING));
        options.push(new MultiselectDropdownOptionModel('On hold', OrderStatusEnum.ON_HOLD));
        options.push(new MultiselectDropdownOptionModel('New', OrderStatusEnum.NEW));
        options.push(new MultiselectDropdownOptionModel('Expired', OrderStatusEnum.EXPIRED));
        options.push(new MultiselectDropdownOptionModel('Cancelled', OrderStatusEnum.CANCELLED));
        options.push(new MultiselectDropdownOptionModel('Complete', OrderStatusEnum.COMPLETE));
        options.push(new MultiselectDropdownOptionModel('Void', OrderStatusEnum.VOID));
        options.push(new MultiselectDropdownOptionModel('No show', OrderStatusEnum.NO_SHOW));
        options.push(new MultiselectDropdownOptionModel('Partially Complete', OrderStatusEnum.PARTIALLY_COMPLETE));


        options.sort(this.sortMultiselectDropdownByLabel);

        return observableOf(options);
    }

    private getAllPaymentMethodsForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {

        const options: MultiselectDropdownOptionModel[] = [];

        options.push(new MultiselectDropdownOptionModel('Cash', PaymentMethodEnum.CASH));
        options.push(new MultiselectDropdownOptionModel('Visa', PaymentMethodEnum.VISA));
        options.push(new MultiselectDropdownOptionModel('Master Card', PaymentMethodEnum.MASTER_CARD));
        options.push(new MultiselectDropdownOptionModel('Discover', PaymentMethodEnum.DISCOVER));
        options.push(new MultiselectDropdownOptionModel('American Express', PaymentMethodEnum.AMERICAN_EXPRESS));
        options.push(new MultiselectDropdownOptionModel('Voucher', PaymentMethodEnum.VOUCHER));
        options.push(new MultiselectDropdownOptionModel('Giftcard', PaymentMethodEnum.GIFTCARD));
        options.push(new MultiselectDropdownOptionModel('Unknown', PaymentMethodEnum.UNKNOWN));
        options.push(new MultiselectDropdownOptionModel('Post Bill', PaymentMethodEnum.POST_BILL));
        options.push(new MultiselectDropdownOptionModel('Diners Club', PaymentMethodEnum.DINERS_CLUB));
        options.push(new MultiselectDropdownOptionModel('Discount', PaymentMethodEnum.DISCOUNT));
        options.push(new MultiselectDropdownOptionModel('Multi Pass', PaymentMethodEnum.MULTI_PASS));
        options.push(new MultiselectDropdownOptionModel('Custom', PaymentMethodEnum.CUSTOM));
        options.push(new MultiselectDropdownOptionModel('Jcb', PaymentMethodEnum.JCB));

        options.sort(this.sortMultiselectDropdownByLabel);

        return observableOf(options);
    }

    private getAllPaymentStatusesForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {

        const options: MultiselectDropdownOptionModel[] = [];

        options.push(new MultiselectDropdownOptionModel("Pending", PaymentStatusEnum.PENDING));
        options.push(new MultiselectDropdownOptionModel("Complete", PaymentStatusEnum.COMPLETE));
        options.push(new MultiselectDropdownOptionModel("Error", PaymentStatusEnum.ERROR));
        options.push(new MultiselectDropdownOptionModel("Refund", PaymentStatusEnum.REFUND));
        options.push(new MultiselectDropdownOptionModel("Partial Refund", PaymentStatusEnum.PARTIAL_REFUND));
        options.push(new MultiselectDropdownOptionModel("Cancelled", PaymentStatusEnum.CANCELLED));

        options.sort(this.sortMultiselectDropdownByLabel);

        return observableOf(options);
    }

    private getAllPlatformsForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {

        let options: MultiselectDropdownOptionModel[] = [];

        options.push(new MultiselectDropdownOptionModel('Android', PlatformEnum.ANDROID));
        options.push(new MultiselectDropdownOptionModel('Website', PlatformEnum.WEBSITE));
        options.push(new MultiselectDropdownOptionModel('Web Application', PlatformEnum.WEB_APPLICATION));
        let useCustomers =  this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.USE_CUSTOMERS)?.active ?? false;
        if (useCustomers) {
            options.push(new MultiselectDropdownOptionModel('Customer Application', PlatformEnum.CUSTOMER_APPLICATION));
            options = [...options];
        }
        let useKiosk =  this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.KIOSK)?.active ?? false;
        if (useKiosk) {
            options.push(new MultiselectDropdownOptionModel('Kiosk', PlatformEnum.KIOSK));
            options = [...options];
        }

        options.sort(this.sortMultiselectDropdownByLabel);

        return observableOf(options);
    }

    private sortMultiselectDropdownByLabel(ms1: MultiselectDropdownOptionModel, ms2: MultiselectDropdownOptionModel): number {
        return ms1.label.localeCompare(ms2.label);
    }
}
