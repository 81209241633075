import {NgModule} from "@angular/core";
import {DeactivateOccurrencesDialogComponent} from "./deactivate-occurrences-dialog.component";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {DeactivateOccurrencesDialogSandbox} from "./sandbox/deactivate-occurrences-dialog.sandbox";
import {StoreModule} from "@ngrx/store";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {DeactivateOccurrencesDialogFormComponent} from './components/deactivate-occurrences-dialog-form/deactivate-occurrences-dialog-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        DeactivateOccurrencesDialogSandbox
    ],
    declarations: [
        DeactivateOccurrencesDialogComponent,
        DeactivateOccurrencesDialogFormComponent
    ],
    exports: [
        DeactivateOccurrencesDialogComponent
    ],
    entryComponents: [
        DeactivateOccurrencesDialogComponent
    ]
})
export class DeactivateOccurrencesDialogModule {

}
