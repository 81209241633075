import { FilterItemTypeEnum } from "./filter-item-type.enum";

export class ReportItemModel {
    filter: boolean;
    field: boolean;
    description: string;
    queryFieldName: string;
    summable: boolean;
    groupable: boolean;
    filterType: FilterItemTypeEnum;

    // For field
    fieldDisabled: boolean;

    constructor(filter: boolean, field: boolean, description: string, queryFieldName: string, summable: boolean, groupable: boolean, filterType?: FilterItemTypeEnum) {
        this.filter = filter;
        this.field = field;
        this.description = description;
        this.queryFieldName = queryFieldName;
        this.summable = summable;
        this.groupable = groupable;
        this.filterType = filterType;
    }
}
