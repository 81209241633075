import {Action} from '@ngrx/store';
import {SortByModel} from '../../../../../../../shared/model/sort-by.model';
import {DriverManifestReportResponseModel} from '../../../../../../../core/modules/rest/reporting/operational/driver-manifest-report/response/driver-manifest-report-response.model';
import {FavoriteResponseModel} from "../../../../../../../core/modules/rest/favorite/response/favorite-response.model";
import * as moment from "moment";
import {ProductDirectionDataModel} from "../../model/product-direction-data.model";

export const UPDATE_FAVORITES_LOADER_ACTION = '[Driver Manifest Report] Update favorites loader';
export const UPDATE_FAVORITES_ACTION = '[Driver Manifest Report] Update favorites';
export const UPDATE_SELECTED_FAVORITE_ID_ACTION = '[Driver Manifest Report] Update selected favorite id';
export const UPDATE_PRODUCTS_LOADER_ACTION = '[Driver Manifest Report] Update products loader';
export const UPDATE_PRODUCT_DIRECTIONS_ACTION = '[Driver Manifest Report] Update product-direction data';
export const UPDATE_SELECTED_DATE_TYPE_ACTION = '[Driver Manifest Report] Update selected date type';
export const UPDATE_SELECTED_DATE_ACTION = '[Driver Manifest Report] Update selected date';
export const UPDATE_SELECTED_PRODUCT_DIRECTIONS_ACTION = '[Driver Manifest Report] Update selected product-direction data';
export const INIT_SEARCH_ACTION = '[Driver Manifest Report] Init search';
export const UPDATE_CURRENT_SEARCH_DATA_ACTION = '[Driver Manifest Report] Update current search data';
export const UPDATE_CURRENT_SEARCH_DATA_SORT_BY_ACTION = '[Driver Manifest Report] Update current search data sort by';
export const CLEAR_SEARCH_DATA_ACTION = '[Driver Manifest Report] Clear search data';
export const RESET_STATE_ACTION = '[Driver Manifest Report] Reset state';


export class UpdateFavoritesLoader implements Action {
    readonly type = UPDATE_FAVORITES_LOADER_ACTION;

    constructor(public payload: { loadingFavorites: boolean }) {
    }
}

export class UpdateFavorites implements Action {
    readonly type = UPDATE_FAVORITES_ACTION;

    constructor(public payload: { favorites: FavoriteResponseModel[], favoriteId: number, favoriteCreatedUpdated: boolean }) {
    }
}

export class UpdateSelectedFavoriteId implements Action {
    readonly type = UPDATE_SELECTED_FAVORITE_ID_ACTION;

    constructor(public payload: { favoriteId: number }) {
    }
}

export class UpdateProductsLoader implements Action {
    readonly type = UPDATE_PRODUCTS_LOADER_ACTION;

    constructor(public payload: { loadingProducts: boolean }) {
    }
}

export class UpdateProductDirections implements Action {
    readonly type = UPDATE_PRODUCT_DIRECTIONS_ACTION;

    constructor(public payload: { productDirections: ProductDirectionDataModel[] }) {
    }
}

export class UpdateSelectedDateType implements Action {
    readonly type = UPDATE_SELECTED_DATE_TYPE_ACTION;

    constructor(public payload: { selectedDateType: string }) {
    }
}

export class UpdateSelectedDate implements Action {
    readonly type = UPDATE_SELECTED_DATE_ACTION;

    constructor(public payload: { selectedDate: moment.Moment }) {
    }
}

export class UpdateSelectedProductDirections implements Action {
    readonly type = UPDATE_SELECTED_PRODUCT_DIRECTIONS_ACTION;

    constructor(public payload: { selectedProductDirections: string[] }) {
    }
}

export class InitSearch implements Action {
    readonly type = INIT_SEARCH_ACTION;

    constructor() {
    }
}

export class UpdateCurrentSearchData implements Action {
    readonly type = UPDATE_CURRENT_SEARCH_DATA_ACTION;

    constructor(public payload: { response: DriverManifestReportResponseModel }) {
    }
}

export class UpdateCurrentSearchDataSortBy implements Action {
    readonly type = UPDATE_CURRENT_SEARCH_DATA_SORT_BY_ACTION;

    constructor(public payload: { sortBy: SortByModel }) {
    }
}

export class ClearSearchData implements Action {
    readonly type = CLEAR_SEARCH_DATA_ACTION;

    constructor() {
    }
}

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}


export type Actions =
    UpdateFavoritesLoader |
    UpdateFavorites |
    UpdateSelectedFavoriteId |
    UpdateProductsLoader |
    UpdateProductDirections |
    UpdateSelectedDateType |
    UpdateSelectedDate |
    UpdateSelectedProductDirections |
    InitSearch |
    UpdateCurrentSearchData |
    UpdateCurrentSearchDataSortBy |
    ClearSearchData |
    ResetState;
