const PermissionCustomerEnum = {
    EMPTY_PERMISSION: 'EMPTY_PERMISSION' as 'EMPTY_PERMISSION',
    CAN_MAKE_BULK_ORDER: 'CAN_MAKE_BULK_ORDER' as 'CAN_MAKE_BULK_ORDER',
    CAN_REPEAT_ORDER: 'CAN_REPEAT_ORDER' as 'CAN_REPEAT_ORDER',
    CAN_USE_BANK: 'CAN_USE_BANK' as 'CAN_USE_BANK',
    MANAGE_SALE_GROUPS: 'MANAGE_SALE_GROUPS' as 'MANAGE_SALE_GROUPS',
    MANAGE_CUSTOMER_OWNER: 'MANAGE_CUSTOMER_OWNER' as 'MANAGE_CUSTOMER_OWNER',
    MANAGE_CUSTOMERS: 'MANAGE_CUSTOMERS' as 'MANAGE_CUSTOMERS'
};
type PermissionCustomerEnum = (typeof PermissionCustomerEnum)[keyof typeof PermissionCustomerEnum];
export {PermissionCustomerEnum};
