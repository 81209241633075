import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import { AuditLogResponseModel } from './response/audit-log-response.model';

@Injectable()
export class AuditLogRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<AuditLogResponseModel[]> {
        return this.httpClient.get<AuditLogResponseModel[]>(environment.server_api_url + '/api/secured/auditLogType/getAll');
    }

}