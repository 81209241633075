
import { catchError } from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { RootSandbox } from "../../../../../../../../core/store/root.sandbox";
import { DialogSandbox } from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import { AppDialogsService } from "../../../../../../../../core/services/app-dialogs.service";
import { HttpErrorModel } from "../../../../../../../../core/modules/rest/http-error.model";
import { PostBillTypeRestService } from "../../../../../../../../core/modules/rest/post-bill-type/post-bill-type-rest.service";
import { CreateBaseResponseModel } from "../../../../../../../../core/modules/rest/create-base-response.model";
import { PostBillTypeResponseModel } from "../../../../../../../../core/modules/rest/post-bill-type/response/post-bill-type-response.model";
import { CreatePostBillTypeRequestModel } from "../../../../../../../../core/modules/rest/post-bill-type/request/create-post-bill-type-request.model";
import { UpdatePostBillTypeRequestModel } from "../../../../../../../../core/modules/rest/post-bill-type/request/update-post-bill-type-request.model";
import { PostBillTypeAdditionalActionEnum } from "../../../../../../../../shared/enums/post-bill-type-additional-action.enum";

@Injectable()
export class EditPostBillTypeDialogSandbox extends DialogSandbox {

    postBillType$: Observable<PostBillTypeResponseModel> = this.store.select(reducer.post_bill_type_selector);
    depositAmount = new BehaviorSubject<string>('$0.00');

    constructor(appDialogsService: AppDialogsService,
        private postBillTypeRestService: PostBillTypeRestService,
        private store: Store<reducer.State>,
        private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    getDepositAmount() {
        return this.depositAmount.asObservable();
    }
    
    setDepositAmount(value: string) {
        this.depositAmount.next(value);
    }

    resetDepositAmount() {
        this.setDepositAmount('$0.00');
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadPostBillType(postBillTypeId: number) {

        this.showLoader();

        this.postBillTypeRestService.getById(postBillTypeId).subscribe((bill: PostBillTypeResponseModel) => {
            this.store.dispatch(new actions.SetPostBillType(bill));
            this.hideLoader();
        },
            () => this.hideLoader());
    }

    createPostBillType(description: string, details: string, additionalAction: PostBillTypeAdditionalActionEnum): Observable<CreateBaseResponseModel> {
        const request = new CreatePostBillTypeRequestModel(description, details, additionalAction);

        return this.postBillTypeRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating post bill type', error);
            }));
    }

    updatePostBillType(postBillTypeId: number, description: string, details: string, useDeposit: boolean, additionalAction: PostBillTypeAdditionalActionEnum): Observable<any> {
        const request = new UpdatePostBillTypeRequestModel(postBillTypeId, description, details, useDeposit, 0, additionalAction);

        return this.postBillTypeRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating post bill type', error);
            }));
    }
    
    addToDeposit(postBillTypeId: number, depositAmount: number): Observable<any> {
        const request = new UpdatePostBillTypeRequestModel(postBillTypeId, null, null, null, depositAmount, null);

        return this.postBillTypeRestService.addToDeposit(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while adding funds to post bill type', error);
            }));
    }

    substractFromDeposit(postBillTypeId: number, depositAmount: number): Observable<any> {
        const request = new UpdatePostBillTypeRequestModel(postBillTypeId, null, null, null, depositAmount, null);

        return this.postBillTypeRestService.substractFromDeposit(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while substracting funds from post bill type', error);
            }));
    }
}
