
<app-dropdown
    [disabled]="!hasRunOrderReportFullPermission"
    [customClasses]="'dropdown_xs'"
    [positionedOptionsList]="true"
    [usePlaceholder]="false"
    [preSelectedValue]="expressionOperatorItem.operator"
    [options]="getOptions()"
    (changed)="onChanged($event)">
</app-dropdown>
