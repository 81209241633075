import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {SubmitCloneProductPricingDateRangesData} from "./components/clone-product-pricing-date-ranges-dialog-form/clone-product-pricing-date-ranges-dialog-form.component";
import {CloneProductPricingDateRangesDialogSandbox} from "./sandbox/clone-product-pricing-date-ranges-dialog.sandbox";
import {PricingDateRangeResponseModel} from "../../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-response.model";

export class CloneProductPricingDateRangesDialogData extends DialogData {

    productId: number;
    pricingDateRangeIds: number[];
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, productId: number, pricingDateRangeIds: number[]) {
        super();
        this.productId = productId;
        this.pricingDateRangeIds = pricingDateRangeIds;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-clone-product-pricing-date-ranges-dialog',
    templateUrl: './clone-product-pricing-date-ranges-dialog.component.html'
})
export class CloneProductPricingDateRangesDialogComponent implements OnDestroy {

    productId: number;
    pricingDateRangeIds: number[];
    successCallbackFn: Function;

    pricingDateRanges$: Observable<PricingDateRangeResponseModel[]>;

    constructor(@Inject(DialogData) data: CloneProductPricingDateRangesDialogData, private sandbox: CloneProductPricingDateRangesDialogSandbox) {

        this.productId = data.productId;
        this.pricingDateRangeIds = data.pricingDateRangeIds;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.pricingDateRangeIds);

        this.pricingDateRanges$ = this.sandbox.pricingDateRanges$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitCloneProductPricingDateRangesData) {
        this.sandbox.cloneProductPricingDateRanges(data).subscribe(() => {
            this.successCallbackFn(data.productId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
