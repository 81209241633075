import {Directive, ElementRef, HostListener, Input, OnChanges, Renderer2} from '@angular/core';

@Directive({
  selector: '[appAutoFocusDirective]'
})
export class AutoFocusDirective implements OnChanges {

    @Input() isActive = false;

  constructor(private renderer: Renderer2,
              private element: ElementRef) {
  }

    @HostListener ('focus') ngOnChanges() {
        const input = this.renderer.selectRootElement(this.element.nativeElement);
        input.focus();
    }
}


