

<div class="breadcrumbs">
    <ul class="breadcrumbsList_container">

        <li class="breadcrumbsLi">
            <a class="breadcrumbsLink"
               (click)="goToHomeClick()">
                Home
            </a>
        </li>

        <li class="breadcrumbsLi"
            *ngFor="let item of items">
            <span>
                {{ item.description }}
            </span>
        </li>
    </ul>
</div>
