import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {KioskConfigurationDropoffLocationPassTierModel} from "../../model/kiosk-configuration-dropoff-location-pass-tier.model";


@Component({
    selector: 'app-edit-pass-tier-item',
    templateUrl: './pass-tier-dialog-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PassTierDialogItemComponent implements OnInit {

    @Input() passTier: KioskConfigurationDropoffLocationPassTierModel;

    @Output() defaultQuantityChangedEvent: EventEmitter<{ tierId: number, value: number }> = new EventEmitter();
    @Output() minQuantityChangedEvent: EventEmitter<{ tierId: number, value: number }> = new EventEmitter();
    @Output() maxQuantityChangedEvent: EventEmitter<{ tierId: number, value: number }> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    defaultQuantityChanged(value) {
        if (value === '') {
            value = null;
        }

        const obj = {
            tierId: this.passTier.tierId,
            value: value
        };
        this.defaultQuantityChangedEvent.emit(obj);
    }

    minQuantityChanged(value) {
        if (value === '') {
            value = null;
        }

        const obj = {
            tierId: this.passTier.tierId,
            value: value
        };
        this.minQuantityChangedEvent.emit(obj);
    }

    maxQuantityChanged(value) {
        if (value === '') {
            value = null;
        }

        const obj = {
            tierId: this.passTier.tierId,
            value: value
        };
        this.maxQuantityChangedEvent.emit(obj);
    }
}
