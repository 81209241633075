import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditCommissionDialogComponent} from "./container/edit-commission-dialog.component";
import {StoreModule} from "@ngrx/store";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {EditCommissionDialogSandbox} from "./sandbox/edit-commission-dialog.sandbox";
import {EditCommissionFormComponent} from './components/edit-commission-form/edit-commission-form.component';


@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditCommissionDialogSandbox
    ],
    declarations: [
        EditCommissionDialogComponent,
        EditCommissionFormComponent
    ],
    entryComponents: [
        EditCommissionDialogComponent
    ],
    exports: [
        EditCommissionDialogComponent
    ]
})
export class EditCommissionDialogModule {

}
