<div class="search_container">
    <input type="text"
           class="input-large"
           placeholder="&#xF002; {{placeholder}}"
           (input)="onTextChanged()"
           #searchInput>


    <a class="closeButton_container"
       *ngIf="searchInput.value"
       (click)="clearSearch()">
        <span class="closeButton">
           <i class="fal fa-times"></i>
        </span>
    </a>
</div>
