import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {Observable} from "rxjs";
import {KioskConfigurationResponseModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-response.model";
import {UserResponseModel} from "../../../../../../../../core/modules/rest/user/response/user-response.model";
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {KioskDeviceInfoResponseModel} from "../../../../../../../../core/modules/rest/kiosk-device-info/response/kiosk-device-info-response.model";
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {EditKioskDeviceInfoDialogSandbox} from "../sandbox/edit-kiosk-device-info-dialog.sandbox";
import {EditKioskDeviceInfoDialogFormSubmitDataModel} from "../model/edit-kiosk-device-info-dialog-form-submit-data.model";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditKioskDeviceInfoDialogData extends DialogData {

    kioskDeviceInfoId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, kioskDeviceInfoId?: number) {
        super();
        this.kioskDeviceInfoId = kioskDeviceInfoId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-kiosk-device-info-dialog',
    templateUrl: './edit-kiosk-device-info-dialog.component.html'
})
export class EditKioskDeviceInfoDialogComponent implements OnInit, OnDestroy {

    kioskDeviceInfoId: number;

    kioskDeviceInfo$: Observable<KioskDeviceInfoResponseModel>;
    kioskConfigurations$: Observable<KioskConfigurationResponseModel[]>;
    users$: Observable<UserResponseModel[]>;
    termsAndConditionsTemplates$: Observable<EmailHtmlTemplateResponseModel[]>;

    editMode: boolean = false;

    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditKioskDeviceInfoDialogData,
                private sandbox: EditKioskDeviceInfoDialogSandbox) {

        this.kioskDeviceInfoId = dialogData.kioskDeviceInfoId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {

        if (this.kioskDeviceInfoId) {
            this.editMode = true;
        }

        this.sandbox.loadData(this.kioskDeviceInfoId);

        this.kioskDeviceInfo$ = this.sandbox.kioskDeviceInfo$;
        this.kioskConfigurations$ = this.sandbox.kioskConfigurations$;
        this.users$ = this.sandbox.users$;
        this.termsAndConditionsTemplates$ = this.sandbox.termsAndConditionsTemplates$;
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: EditKioskDeviceInfoDialogFormSubmitDataModel) {

        if (this.editMode) {
            this.sandbox.updateKioskDeviceInfo(
                this.kioskDeviceInfoId,
                data.description,
                data.kioskDeviceNumber,
                data.guid,
                data.kioskSupportEmails,
                data.kioskSupportPhones,
                data.soldById,
                data.selectedTermsAndConditionsEmailHtmlTemplateId,
                data.kioskConfigurationId,
                data.kioskSenderEmail
            ).subscribe(() => {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createKioskDeviceInfo(
                data.description,
                data.kioskDeviceNumber,
                data.guid,
                data.kioskSupportEmails,
                data.kioskSupportPhones,
                data.soldById,
                data.selectedTermsAndConditionsEmailHtmlTemplateId,
                data.kioskConfigurationId,
                data.kioskSenderEmail
            ).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
