import {Component, Inject} from '@angular/core';
import {DialogData} from "../../model/dialog.data";
import {SendEmailDialogSandbox} from "./send-email-dialog.sandbox";

export class EmailOrderDialogData extends DialogData {

    emailAddress: string;
    emailOrderFn: Function;

    constructor(emailAddress: string, emailOrderFn: Function) {
        super();
        this.emailAddress = emailAddress;
        this.emailOrderFn = emailOrderFn;
    }
}

@Component({
    selector: 'app-send-email-dialog',
    templateUrl: './send-email-dialog.component.html'
})
export class SendEmailDialogComponent {

    emailAddress: string;
    emailOrderFn: Function;

    constructor(@Inject(DialogData) data: EmailOrderDialogData, private sandbox: SendEmailDialogSandbox) {

        this.emailAddress = data.emailAddress ? data.emailAddress : "";
        this.emailOrderFn = data.emailOrderFn;
    }

    onEmailOrderClick() {
        this.emailOrderFn(this.emailAddress);
        this.sandbox.destroyDialog();
    }

    onCancelClick() {
        this.sandbox.destroyDialog();
    }
}
