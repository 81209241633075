import {PaymentMethodSummaryResponse} from "./payment-method-summary-response";

export class ReportSearchResponseModel {
    level: LevelEnum;
    headers: HeaderResponseModel[];
    rows: RowResponseModel[];
    approximateNumberOfResults: number;
    tookInMillis: number;
    scrollId: string;
    paymentSummary: PaymentMethodSummaryResponse[];
}

const LevelEnum = {
    ORDER: 'ORDER' as 'ORDER',
    ORDER_PAYMENT: 'ORDER_PAYMENT' as 'ORDER_PAYMENT',
    ORDER_DETAIL: 'ORDER_DETAIL' as 'ORDER_DETAIL',
    PASS_ORDER_DETAIL: 'PASS_ORDER_DETAIL' as 'PASS_ORDER_DETAIL',
    ORDER_DETAIL_TIER: 'ORDER_DETAIL_TIER' as 'ORDER_DETAIL_TIER',
    ORDER_DETAIL_COST: 'ORDER_DETAIL_COST' as 'ORDER_DETAIL_COST',
    TICKET: 'TICKET' as 'TICKET',
    PASS_ORDER_DETAIL_TIER: 'PASS_ORDER_DETAIL_TIER' as 'PASS_ORDER_DETAIL_TIER',
    TICKET_CHECKIN: 'TICKET_CHECKIN' as 'TICKET_CHECKIN',
    PASS_CARD: 'PASS_CARD' as 'PASS_CARD',
    PASS_CARD_CHECKIN: 'PASS_CARD_CHECKIN' as 'PASS_CARD_CHECKIN'
};
type LevelEnum = (typeof LevelEnum)[keyof typeof LevelEnum];
export {LevelEnum};


export class HeaderResponseModel {
    displayName: string;
    fieldName: string;
    elasticsearchName: string;
    sortable: boolean;
    summable: boolean;
    valueType: ValueTypeEnum;
    collumnIndex: number;
    sortedType: SortedTypeEnum;
    customField: boolean;
}


const ValueTypeEnum = {
    DOLLARS: 'DOLLARS' as 'DOLLARS',
    LONG: 'LONG' as 'LONG',
    DOUBLE: 'DOUBLE' as 'DOUBLE',
    DATE: 'DATE' as 'DATE',
    DATE_AS_STRING: 'DATE_AS_STRING' as 'DATE_AS_STRING',
    DATE_AS_MILLIS: 'DATE_AS_MILLIS' as 'DATE_AS_MILLIS',
    STRING: 'STRING' as 'STRING',
    BOOLEAN: 'BOOLEAN' as 'BOOLEAN',
    STRING_LIST: 'STRING_LIST' as 'STRING_LIST'
};
type ValueTypeEnum = (typeof ValueTypeEnum)[keyof typeof ValueTypeEnum];
export {ValueTypeEnum};


const SortedTypeEnum = {
    LONG: 'LONG' as 'LONG',
    DOUBLE: 'DOUBLE' as 'DOUBLE',
    DATE: 'DATE' as 'DATE',
    DATE_AS_STRING: 'DATE_AS_STRING' as 'DATE_AS_STRING',
    DATE_AS_MILLIS: 'DATE_AS_MILLIS' as 'DATE_AS_MILLIS',
    STRING: 'STRING' as 'STRING',
    BOOLEAN: 'BOOLEAN' as 'BOOLEAN'
};
type SortedTypeEnum = (typeof SortedTypeEnum)[keyof typeof SortedTypeEnum];
export {SortedTypeEnum};


export class RowResponseModel {
    cellValues: CellValueResponseModel[];
    rowMeta: Map<string, string>;
}

export class CellValueResponseModel {
    // header: string;
    value;
    // type: ValueTypeEnum;
    cellValueMeta: CellValueMetaResponseModel[];
}

export class CellValueMetaResponseModel {
    metaKey: string;
    metaValue;
}
