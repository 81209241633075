import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {TierResponseModel} from "../../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {TierFormSubmitDataModel} from "../model/tier-form-submit-data.model";
import {EditTierDialogSandbox} from "../sandbox/edit-tier-dialog.sandbox";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditTierDialogData extends DialogData {

    tierId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, tierId?: number) {
        super();
        this.tierId = tierId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
  selector: 'app-edit-tier-dialog',
  templateUrl: './edit-tier-dialog.component.html'
})
export class EditTierDialogComponent implements OnInit, OnDestroy {

    tierId: number;
    tier$: Observable<TierResponseModel>;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditTierDialogData, private sandbox: EditTierDialogSandbox) {
        this.tierId = dialogData.tierId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
        if (this.tierId && !isNaN(this.tierId)) {
            this.editMode = true;
            this.sandbox.loadTier(this.tierId);
            this.tier$ = this.sandbox.tier$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: TierFormSubmitDataModel) {

        if (this.editMode) {
            this.sandbox.updateTier(this.tierId, data).subscribe(() => {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createTier(data).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
