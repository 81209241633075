import {AmountTypeEnum} from "../../../../../shared/enums/amount-type.enum";

export class CreateCommissionRequestModel {
    description: string;
    commissionAmount: number;
    commissionAmountType: AmountTypeEnum;
    referralAmount: number;
    referralAmountType: AmountTypeEnum;
    startDateInt: number;
    endDateInt: number;
    startTime: number;
    endTime: number;

    constructor(description: string,
                commissionAmount: number,
                commissionAmountType: AmountTypeEnum,
                referralAmount: number,
                referralAmountType: AmountTypeEnum,
                startDateInt: number,
                endDateInt: number,
                startTime: number,
                endTime: number) {
        this.description = description;
        this.commissionAmount = commissionAmount;
        this.commissionAmountType = commissionAmountType;
        this.referralAmount = referralAmount;
        this.referralAmountType = referralAmountType;
        this.startDateInt = startDateInt;
        this.endDateInt = endDateInt;
        this.startTime = startTime;
        this.endTime = endTime;
    }
}
