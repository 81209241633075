import { DynamicPermissionTypeEnum, PermissionNode, PermissionTypeEnum } from "./permission-node";
import { CustomerMappingResponseModel } from "../../../../../..//core/modules/rest/customer/response/customer-mapping-response.model";
import { PermissionCustomerEnum } from "../../../../../../shared/enums/permissionCustomer.enum";

export interface MappedSaleGroupModel {
    saleGroupId: number;
    active: boolean;
    description: string;
    inherited: boolean;
}

export interface MappedPostBillTypeModel {
    postBillTypeId: number;
    active: boolean;
    description: string;
    inherited: boolean;
}

export interface MappedPermissionCustomerModel {
    permissionCustomer: PermissionCustomerEnum;
    active: boolean;
    inherited: boolean;
}

export interface SelectedPermissionCustomerModel {
    permissionCustomer: PermissionCustomerEnum;
    active: boolean;
}

export interface SelectedSaleGroupModel {
    saleGroupId: number;
    active: boolean;
}

export interface SelectedPostBillTypeModel {
    postBillTypeId: number;
    active: boolean;
}

export interface MappedCustomerDataModel {
    saleGroups: MappedSaleGroupModel[];
    permissionsCustomer: MappedPermissionCustomerModel[];
    postBillTypes: SelectedPostBillTypeModel[];
}


export interface SelectedCustomerMappedDataModel {
    permissionsCustomer: SelectedPermissionCustomerModel[];
    saleGroups: SelectedSaleGroupModel[];
    postBillTypes: SelectedPostBillTypeModel[];
}


export class MappedData {

    dataCustomer: MappedCustomerDataModel;

    constructor() {
    }

    getPermissionTreeFromCustomerMapping(dataCustomer: CustomerMappingResponseModel): PermissionNode[] {

        this.dataCustomer = MappedData.convertCustomerMapping(dataCustomer);

        return this.createPermissionCustomerTree(this.dataCustomer);
    }

    getCustomerMappedDataFromPermissionTree(data: PermissionNode[]): SelectedCustomerMappedDataModel {
        let permissionsCustomer: SelectedPermissionCustomerModel[] = [];
        let saleGroups: SelectedSaleGroupModel[] = [];
        let postBillTypes: SelectedPostBillTypeModel[] = [];

        // -------------------------------------------------------------------------------------------------------------
        // SALE GROUPS
        // -------------------------------------------------------------------------------------------------------------

        let saleGroupParent: PermissionNode = this.findNodeById(PermissionCustomerEnum.MANAGE_SALE_GROUPS, data);

        if (saleGroupParent && saleGroupParent.children) {
            saleGroupParent.children.forEach(sg => {
                saleGroups.push({
                    saleGroupId: Number(sg.id),
                    active: sg.checked
                });
            });
        }

        // -------------------------------------------------------------------------------------------------------------
        // POST BILL TYPES
        // -------------------------------------------------------------------------------------------------------------

        let postBillTypeParent: PermissionNode = this.findNodeById(PermissionCustomerEnum.CAN_USE_BANK, data);

        if (postBillTypeParent && postBillTypeParent.children) {
            postBillTypeParent.children.forEach(sg => {
                postBillTypes.push({
                    postBillTypeId: Number(sg.id),
                    active: sg.checked
                });
            });
        }

        // -------------------------------------------------------------------------------------------------------------
        // PERMISSIONS CUSTOMER
        // -------------------------------------------------------------------------------------------------------------

        data.forEach(n => {
            this.collectCustomerPermissions(permissionsCustomer, n);
        });

        return {
            permissionsCustomer: permissionsCustomer,
            saleGroups: saleGroups,
            postBillTypes: postBillTypes
        };
    }


    private createPermissionCustomerTree(data: MappedCustomerDataModel): PermissionNode[] {

        // -------------------------------------------------------------------------------------------------------------
        // DYNAMIC LISTS
        // -------------------------------------------------------------------------------------------------------------

        let saleGroups: PermissionNode[] = data.saleGroups.map((saleGroup: MappedSaleGroupModel) => {
            return new PermissionNode(PermissionTypeEnum.DYNAMIC, DynamicPermissionTypeEnum.SALE_GROUPS, saleGroup.saleGroupId, saleGroup.description, saleGroup.active, [], saleGroup.inherited);
        });

        let postBillTypes: PermissionNode[] = data.postBillTypes.map((postBillType: MappedPostBillTypeModel) => {
            return new PermissionNode(PermissionTypeEnum.DYNAMIC, DynamicPermissionTypeEnum.POST_BILL_TYPES, postBillType.postBillTypeId, postBillType.description, postBillType.active, [], postBillType.inherited);
        });

        // -------------------------------------------------------------------------------------------------------------
        // SALES
        // -------------------------------------------------------------------------------------------------------------

        let manageCustomerOwner: PermissionNode = this.createPermissionCustomerNode("Customer Owner Management", PermissionCustomerEnum.MANAGE_CUSTOMER_OWNER, [
            this.createPermissionCustomerNode("Can Make Bulk Order", PermissionCustomerEnum.CAN_MAKE_BULK_ORDER),
            this.createPermissionCustomerNode("Can Repeat Order", PermissionCustomerEnum.CAN_REPEAT_ORDER),
            this.createPermissionCustomerNode("Can Manage Customers", PermissionCustomerEnum.MANAGE_CUSTOMERS)
        ]);

        let manageSaleGroups: PermissionNode | null = saleGroups.length > 0
        ? this.createPermissionCustomerNode("Sale Groups Management", PermissionCustomerEnum.MANAGE_SALE_GROUPS, saleGroups)
        : null;

        let managePostBillTypes: PermissionNode | null = postBillTypes.length > 0
            ? this.createPermissionCustomerNode("Can Use Deposit Account", PermissionCustomerEnum.CAN_USE_BANK, postBillTypes)
            : null;
        
            return [manageCustomerOwner, manageSaleGroups, managePostBillTypes].filter(node => node !== null);
    }

    private createPermissionCustomerNode(label: string, permissionCustomer: PermissionCustomerEnum, children: PermissionNode[] = []): PermissionNode {

        let mappedPermission: MappedPermissionCustomerModel = this.getPermissionCustomerByValue(permissionCustomer);

        if (!mappedPermission) {
            console.error(`PERMISSION NOT FOUND: ${permissionCustomer}`);
        }

        let node: PermissionNode = new PermissionNode(PermissionTypeEnum.PERMISSION, DynamicPermissionTypeEnum.PERMISSIONS_CUSTOMER, permissionCustomer, label, mappedPermission.active, children, mappedPermission.inherited);

        // Update selection (if any first-level node is checked, check parent)
        node.updateSelection(mappedPermission.active);

        return node;
    }

    private getPermissionCustomerByValue(permissionCustomer: PermissionCustomerEnum): MappedPermissionCustomerModel {
        return this.dataCustomer.permissionsCustomer.find((p: MappedPermissionCustomerModel) => p.permissionCustomer === permissionCustomer);
    }

    private static convertCustomerMapping(data: CustomerMappingResponseModel): MappedCustomerDataModel {
        let permissionsCustomer: MappedPermissionCustomerModel[] = data.customerPermissions.map(pc => {
            return {
                permissionCustomer: pc.permissionCustomer,
                active: pc.active,
                inherited: false
            };
        });

        let saleGroups: MappedSaleGroupModel[] = data.saleGroups.map(sg => {
            return {
                saleGroupId: sg.saleGroupId,
                active: sg.active,
                description: sg.saleGroupName,
                inherited: false
            };
        });

        let postBillTypes: MappedPostBillTypeModel[] = data.postBillTypes.map(sg => {
            return {
                postBillTypeId: sg.postBillTypeId,
                active: sg.active,
                description: sg.postBillTypeDescription,
                inherited: false
            };
        });

        return {
            permissionsCustomer: permissionsCustomer,
            saleGroups: saleGroups,
            postBillTypes: postBillTypes
        };
    }

    private findNodeById(id: string | number, permissionTree: PermissionNode[]): PermissionNode {

        let result: PermissionNode = null;

        for (let i = 0; result === null && i < permissionTree.length; i++) {
            result = this._find(id, permissionTree[i]);
        }

        return result;
    }

    private collectCustomerPermissions(permissionsCustomer: SelectedPermissionCustomerModel[], node: PermissionNode) {

        if (node.type === PermissionTypeEnum.PERMISSION) {
            permissionsCustomer.push({
                permissionCustomer: <PermissionCustomerEnum>node.id,
                active: node.checked
            });
        }

        node.children.forEach(n => {
            this.collectCustomerPermissions(permissionsCustomer, n);
        });
    }

    private _find(id: string | number, node: PermissionNode): PermissionNode {

        if (node.id === id) {
            return node;
        } else if (node.children.length !== 0) {

            let result: PermissionNode = null;

            for (let i = 0; result === null && i < node.children.length; i++) {
                result = this._find(id, node.children[i]);
            }

            return result;
        }

        return null;
    }
}





