import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {PassRestService} from "../../../../../../../../core/modules/rest/pass/pass-rest.service";
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {PassSimpleResponseModel} from "../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {SubmitPassValidityData} from "../components/edit-pass-validity-dialog-form/edit-pass-validity-dialog-form.component";
import moment from "moment";

@Injectable()
export class EditPassValidityDialogSandbox extends DialogSandbox {

    pass$: Observable<PassSimpleResponseModel> = this.store.pipe(select(reducer.pass_selector));

    constructor(appDialogsService: AppDialogsService,
                private passRestService: PassRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(passId: number) {

        this.passRestService.getPassSimpleById(passId)
            .subscribe((pass: PassSimpleResponseModel) => {
                this.store.dispatch(new actions.SetPass(pass));
            });
    }

    updatePassValidity(data: SubmitPassValidityData): Observable<any> {

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return this.passRestService.updatePassValidity({
            passId: data.passId,
            isValidForDateInterval: data.isValidForDateInterval,
            validForStartDateMS: data.validForStartDate ? moment.parseZone(data.validForStartDate, timeZone).valueOf() : null,
            validForEndDateMS: data.validForEndDate ? moment.parseZone(data.validForEndDate, timeZone).valueOf() : null,
            isValidSincePurchaseForHours: data.isValidSincePurchaseForHours,
            validForHours: data.validForHours,
            isUsageLimiterPerOccurrence: data.isUsageLimiterPerOccurrence,
            usageLimiterForMinutes: data.usageLimiterForMinutes,
            isUnlimited: data.isUnlimited,
            initialQuantity: data.initialQuantity,
            predictedQuantity: data.predictedQuantity
        });
    }
}
