import { Action } from "@ngrx/store";
import { DomainResponseModel } from "../../../../../../../../../core/modules/rest/domain/response/domain-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Domain :: MAIN] Reset State';
export const SET_DOMAIN_ACTION = '[Dialog - Create/Edit Domain :: MAIN] Set Domain';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetDomain implements Action {
    readonly type = SET_DOMAIN_ACTION;
    constructor(public domain: DomainResponseModel) {}
}

export type All = ResetState | SetDomain;
