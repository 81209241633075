import { Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';


@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

    @Input() buttonType = 'emptyStateButton';
    @Output() clickEvent: EventEmitter<any> = new EventEmitter();
    @Input() disabled: boolean;

    constructor() {
    }

    ngOnInit() {
    }

    buttonClicked(event) {
        this.clickEvent.emit(event);
    }
}
