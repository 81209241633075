import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditDiscountDialogComponent} from "./container/edit-discount-dialog.component";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditDiscountDialogContentComponent} from './components/edit-discount-dialog-content/edit-discount-dialog-content.component';
import {EditDiscountDialogSandbox} from "./sandbox/edit-discount-dialog.sandbox";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditDiscountDialogSandbox
    ],
    exports: [
        EditDiscountDialogComponent
    ],
    declarations: [
        EditDiscountDialogComponent,
        EditDiscountDialogContentComponent
    ],
    entryComponents: [
        EditDiscountDialogComponent
    ]
})
export class EditDiscountDialogModule {
}
