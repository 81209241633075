import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {BusResponseModel} from "./response/bus-response.model";
import {environment} from "../../../../../environments/environment";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {CreateBusRequestModel} from "./request/create-bus-request.model";
import {UpdateBusRequestModel} from "./request/update-bus-request.model";

@Injectable()
export class BusRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<BusResponseModel[]> {
        return this.httpClient.get<BusResponseModel[]>(environment.server_api_url + '/api/secured/bus/getAll');
    }

    getById(busId: number): Observable<BusResponseModel> {
        return this.httpClient.get<BusResponseModel>(environment.server_api_url + '/api/secured/bus/getById/' + busId);
    }

    create(request: CreateBusRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/bus/create', request);
    }

    update(request: UpdateBusRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/bus/update', request, {responseType: 'text'});
    }

    activate(busId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/bus/activate', busId, {responseType: 'text'});
    }

    deactivate(busId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/bus/deactivate', busId, {responseType: 'text'});
    }
}
