import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export const REDUCER_NAME = 'dialog_edit-product-email-html-templates';

export interface State {
    product: ProductSimpleResponseModel;
    createOrderEmailHtmlTemplates: DropdownOptionModel[];
    voidOrderEmailHtmlTemplates: DropdownOptionModel[];
    termsAndConditionsEmailHtmlTemplates: DropdownOptionModel[];
}

const INITIAL_STATE: State = {
    product: {} as ProductSimpleResponseModel,
    createOrderEmailHtmlTemplates: [],
    voidOrderEmailHtmlTemplates: [],
    termsAndConditionsEmailHtmlTemplates: []
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const product_selector = createSelector(state_selector, (state: State) => state.product);
export const createOrderEmailHtmlTemplates_selector = createSelector(state_selector, (state: State) => state.createOrderEmailHtmlTemplates);
export const voidOrderEmailHtmlTemplates_selector = createSelector(state_selector, (state: State) => state.voidOrderEmailHtmlTemplates);
export const termsAndConditionsEmailHtmlTemplates_selector = createSelector(state_selector, (state: State) => state.termsAndConditionsEmailHtmlTemplates);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;

        case actions.SET_PRODUCT:
            return {...state, product: action.product};

        case actions.SET_CREATE_ORDER_EMAIL_HTML_TEMPLATES:
            return {...state, createOrderEmailHtmlTemplates: action.createOrderEmailHtmlTemplates};

        case actions.SET_VOID_ORDER_EMAIL_HTML_TEMPLATES:
            return {...state, voidOrderEmailHtmlTemplates: action.voidOrderEmailHtmlTemplates};

        case actions.SET_TERMS_AND_CONDITIONS_EMAIL_HTML_TEMPLATES:
            return {...state, termsAndConditionsEmailHtmlTemplates: action.termsAndConditionsEmailHtmlTemplates};

        default:
            return state;
    }
}
