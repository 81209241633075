import { NgModule } from "@angular/core";
import { OccurrenceManifestDialogComponent } from "./occurrence-manifest-dialog.component";
import { SharedModule } from "../../../../../../../shared/shared.module";
import { OccurrenceManifestDialogSandbox } from "./sandbox/occurrence-manifest-dialog.sandbox";
import { StoreModule } from "@ngrx/store";
import { reducer, REDUCER_NAME } from "./sandbox/store/reducer";
import { OccurrenceManifestDialogFormComponent } from './components/occurrence-manifest-dialog-form/occurrence-manifest-dialog-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        OccurrenceManifestDialogSandbox
    ],
    declarations: [
        OccurrenceManifestDialogComponent,
        OccurrenceManifestDialogFormComponent
    ],
    exports: [
        OccurrenceManifestDialogComponent
    ],
    entryComponents: [
        OccurrenceManifestDialogComponent
    ]
})
export class OccurrenceManifestDialogModule {

}
