import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {MultiselectDropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

export const REDUCER_NAME = 'dialog_mapping-commission';

export interface State {
    loading: boolean;
    categories: MultiselectDropdownOptionModel[];
    mappedCategories: number[];
    products: MultiselectDropdownOptionModel[];
    mappedProducts: number[];
    passes: MultiselectDropdownOptionModel[];
    mappedPasses: number[];
    tiers: MultiselectDropdownOptionModel[];
    mappedTiers: number[];
    roles: MultiselectDropdownOptionModel[];
    mappedRoles: number[];
    users: MultiselectDropdownOptionModel[];
    mappedUsers: number[];
    locations: MultiselectDropdownOptionModel[];
    mappedLocations: number[];
}

const INITIAL_STATE: State = {
    loading: false,
    categories: [],
    mappedCategories: [],
    products: [],
    mappedProducts: [],
    passes: [],
    mappedPasses: [],
    tiers: [],
    mappedTiers: [],
    roles: [],
    mappedRoles: [],
    users: [],
    mappedUsers: [],
    locations: [],
    mappedLocations: []
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const loading_selector = createSelector(state_selector, (state: State) => state.loading);
export const categories_selector = createSelector(state_selector, (state: State) => state.categories);
export const mappedCategories_selector = createSelector(state_selector, (state: State) => state.mappedCategories);
export const products_selector = createSelector(state_selector, (state: State) => state.products);
export const mappedProducts_selector = createSelector(state_selector, (state: State) => state.mappedProducts);
export const passes_selector = createSelector(state_selector, (state: State) => state.passes);
export const mappedPasses_selector = createSelector(state_selector, (state: State) => state.mappedPasses);
export const tiers_selector = createSelector(state_selector, (state: State) => state.tiers);
export const mappedTiers_selector = createSelector(state_selector, (state: State) => state.mappedTiers);
export const roles_selector = createSelector(state_selector, (state: State) => state.roles);
export const mappedRoles_selector = createSelector(state_selector, (state: State) => state.mappedRoles);
export const users_selector = createSelector(state_selector, (state: State) => state.users);
export const mappedUsers_selector = createSelector(state_selector, (state: State) => state.mappedUsers);
export const locations_selector = createSelector(state_selector, (state: State) => state.locations);
export const mappedLocations_selector = createSelector(state_selector, (state: State) => state.mappedLocations);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;

        case actions.SET_LOADING:
            return {...state, loading: action.loading};

        case actions.SET_CATEGORIES:
            return {...state, categories: action.categories};

        case actions.SET_MAPPED_CATEGORIES:
            return {...state, mappedCategories: action.mappedCategories};

        case actions.SET_PRODUCTS:
            return {...state, products: action.products};

        case actions.SET_MAPPED_PRODUCTS:
            return {...state, mappedProducts: action.mappedProducts};

        case actions.SET_PASSES:
            return {...state, passes: action.passes};

        case actions.SET_MAPPED_PASSES:
            return {...state, mappedPasses: action.mappedPasses};

        case actions.SET_TIERS:
            return {...state, tiers: action.tiers};

        case actions.SET_MAPPED_TIERS:
            return {...state, mappedTiers: action.mappedTiers};

        case actions.SET_ROLES:
            return {...state, roles: action.roles};

        case actions.SET_MAPPED_ROLES:
            return {...state, mappedRoles: action.mappedRoles};

        case actions.SET_USERS:
            return {...state, users: action.users};

        case actions.SET_MAPPED_USERS:
            return {...state, mappedUsers: action.mappedUsers};

        case actions.SET_LOCATIONS:
            return {...state, locations: action.locations};

        case actions.SET_MAPPED_LOCATIONS:
            return {...state, mappedLocations: action.mappedLocations};

        default:
            return state;
    }
}
