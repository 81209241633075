import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SaleGroupResponseModel } from '../../../../../../../../../core/modules/rest/sale-group/response/sale-group-response.model';
import { SaleGroupFormSubmitDataModel } from '../../model/sale-group-form-submit-data.model';

@Component({
  selector: 'app-edit-sale-group-form',
  templateUrl: './edit-sale-group-form.component.html'
})
export class EditSaleGroupFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() saleGroup: SaleGroupResponseModel;

    @Output() submitEvent = new EventEmitter<SaleGroupFormSubmitDataModel>();
    @Output() closeEvent  = new EventEmitter<void>();

    form: FormGroup;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(!this.form) {
            this.form = new FormGroup({
                useOwnerTypeGroup: new FormControl(changes.saleGroup.currentValue.useOwnerTypeGroup),
                useDomainTypeGroup: new FormControl(changes.saleGroup.currentValue.useDomainTypeGroup),
                saleGroupName: new FormControl(changes.saleGroup.currentValue.saleGroupName, Validators.required)
            });
        }
        if (this.editMode && changes.saleGroup !== null && Object.keys(changes.saleGroup.currentValue).length !== 0) {
            this.form.patchValue({
                saleGroupName: changes.saleGroup.currentValue.saleGroupName,
                useOwnerTypeGroup: changes.saleGroup.currentValue.useOwnerTypeGroup,
                useDomainTypeGroup: changes.saleGroup.currentValue.useDomainTypeGroup
            });
        }
    }

    ngOnInit() {

        let saleGroupName: string = '';
        let useOwnerTypeGroup: boolean = false;
        let useDomainTypeGroup: boolean = false;

        if (this.editMode) {
            saleGroupName = this.saleGroup.saleGroupName;
            useOwnerTypeGroup = this.saleGroup.useOwnerTypeGroup;
            useDomainTypeGroup = this.saleGroup.useDomainTypeGroup;
        }
        if(!this.form) {
            this.form = new FormGroup({
                useOwnerTypeGroup: new FormControl(useOwnerTypeGroup),
                useDomainTypeGroup: new FormControl(useDomainTypeGroup),
                saleGroupName: new FormControl(saleGroupName, Validators.required)
            });
        }
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {
        const saleGroupName = this.form.value.saleGroupName;
        const useOwnerTypeGroup = this.form.value.useOwnerTypeGroup;
        const useDomainTypeGroup = this.form.value.useDomainTypeGroup;
        let saleGroupId = 0
        if (this.saleGroup !== null && this.saleGroup !== undefined) {
            saleGroupId = this.saleGroup.saleGroupId;
            this.saleGroup.saleGroupName = saleGroupName;
            this.saleGroup.useOwnerTypeGroup = useOwnerTypeGroup;
            this.saleGroup.useDomainTypeGroup = useDomainTypeGroup;
        }
        const data: SaleGroupFormSubmitDataModel = new SaleGroupFormSubmitDataModel( saleGroupId, saleGroupName, useOwnerTypeGroup, useDomainTypeGroup );

        this.submitEvent.emit(data);
    }
}
