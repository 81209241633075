export class UpdateOrderDetailOccurrenceRequestModel {

    orderDetailId: number;
    dateInt: number;
    productAvailabilityId: number;

    constructor(orderDetailId: number, dateInt: number, productAvailabilityId: number) {
        this.orderDetailId = orderDetailId;
        this.dateInt = dateInt;
        this.productAvailabilityId = productAvailabilityId;
    }
}
