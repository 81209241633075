const ShoppingCartPaymentMethodEnum = {
    CASH: "CASH" as "CASH",
    CREDIT_CARD: "CREDIT_CARD" as "CREDIT_CARD",
    CLOUD_PAYMENT_DEVICE: "CLOUD_PAYMENT_DEVICE" as "CLOUD_PAYMENT_DEVICE",
    POST_BILL: "POST_BILL" as "POST_BILL"
};

type ShoppingCartPaymentMethodEnum = (typeof ShoppingCartPaymentMethodEnum)[keyof typeof ShoppingCartPaymentMethodEnum];

export {ShoppingCartPaymentMethodEnum};
