import {
    AfterViewInit, Directive, ElementRef, OnDestroy, Input, AfterViewChecked, OnChanges, SimpleChanges, ViewChild
} from '@angular/core';
declare var $: any;
import SimpleBar from 'simplebar';


@Directive({
  selector: '[appTableScrollDirective]',
})

export class TableScrollDirective implements OnChanges, AfterViewInit, OnDestroy, AfterViewChecked {
    // detect changes on show/hide inactive rows
    @Input() tableData?: any;
    // @ViewChild('tableScrollContainer') tableScrollContainer: ElementRef;

    // scrollBar: SimpleBar;
    // tableContainer;
    // scrollContainer: Element = document.querySelector('[data-table-scroll-container]');
    // positionScrollBarHandler: any;

    constructor(private el: ElementRef) {
        // this.tableContainer = this.el.nativeElement;
    }

    ngOnDestroy(): void {
        // this.scrollBar = null;
        // this.scrollContainer.removeEventListener('scroll', this.positionScrollBarHandler);
        // this.positionScrollBarHandler = null;
    }

    ngAfterViewInit(): void {

        setTimeout(() => {
            // this.positionScrollBar();
        }, 300);

        // this.positionScrollBarHandler = this.positionScrollBar.bind(this);
        // this.scrollContainer.addEventListener('scroll', this.positionScrollBarHandler);
    }

    ngAfterViewChecked(): void {
        // this.initializeScrollBar();
        // this.positionScrollBar();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // if (changes.tableData && !changes.tableData.firstChange && this.tableData) {
        //     setTimeout(() => {
        //         this.positionScrollBar();
        //     }, 300);
        // }
    }

    // initializeScrollBar(): void {
    //     this.scrollBar = new SimpleBar(this.tableContainer, {
    //         autoHide: false,
    //         showScrollbar: 'X'
    //     }); // showScrollbar - position of scrollbar X or Y axis
    // }

    // positionScrollBar(): void {
    //
    //     const windowH = window.innerHeight;
    //     const tableContainerRec = this.tableContainer.getBoundingClientRect();
    //
    //     const tableBottom =  tableContainerRec.top + tableContainerRec.height ;
    //     const horizontalScroll = this.scrollBar.scrollbarX;
    //
    //      if (horizontalScroll !== undefined) {
    //             if (windowH < tableBottom) {
    //                 $(horizontalScroll).offset({
    //                     top: windowH - 15
    //                 });
    //             } else {
    //                 $(horizontalScroll).offset({
    //                     top: tableBottom + 5
    //                 });
    //             }
    //         }
    // }
}
