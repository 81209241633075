import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {UUID} from "angular2-uuid";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {RadioButtonGroupDataModel} from "./radio-button-group-data.model";


@Component({
    selector: 'app-radio-button-group',
    templateUrl: './radio-button-group.component.html',
    styleUrls: ['./radio-button-group.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioButtonGroupComponent),
            multi: true,
        }
    ]
})
export class RadioButtonGroupComponent implements ControlValueAccessor, OnInit, OnChanges {

    @Input() data: RadioButtonGroupDataModel[];
    @Input() selectedValue: string;
    @Input() containerClassList: string;
    @Input() inputClassList: string;

    @Output() changed = new EventEmitter<any>();

    private RADIO_GROUP_ID: string = UUID.UUID();
    private _selectedValue: string;
    private _onChange: (val: string) => void;
    private _onTouch: (val: string) => void;

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedValue) {
            this._selectedValue = this.selectedValue;
            this.cdr.detectChanges();
        }
    }

    ngOnInit(): void {
        if (this.selectedValue) {
            this._selectedValue = this.selectedValue;
        }
    }

    radioButtonClick(val: string) {

        if (this._onChange && val && val !== this._selectedValue) {
            this._onChange(val);
        }

        this._selectedValue = val;
        this.changed.emit(this._selectedValue);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouch = fn;
    }

    writeValue(val: string): void {
        this._selectedValue = val.toString();
    }
}
