import {Action} from "@ngrx/store";
import {AndroidDeviceInfoResponseModel} from "../../../../../../../../../core/modules/rest/android-device-info/response/android-device-info-response.model";


export const RESET_STATE_ACTION                       = '[Dialog - Add Android Device Info Remote Command :: MAIN] Reset State';
export const SET_ANDROID_DEVICE_INFO                  = '[Dialog - Add Android Device Info Remote Command :: MAIN] Set Android Device Info';

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class SetAndroidDeviceInfo implements Action {
    readonly type = SET_ANDROID_DEVICE_INFO;

    constructor(public androidDeviceInfo: AndroidDeviceInfoResponseModel) {
    }
}

export type All = ResetState | SetAndroidDeviceInfo;
