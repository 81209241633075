import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../../../shared/shared.module";
import { EditPostBillTypeDialogSandbox } from "./sandbox/edit-post-bill-type-dialog.sandbox";
import { EditPostBillTypeDialogComponent } from "./container/edit-post-bill-type-dialog.component";
import { reducer, REDUCER_NAME } from "./sandbox/store/reducer";
import { StoreModule } from "@ngrx/store";
import { EditPostBillTypeFormComponent } from './components/edit-post-bill-type-form/edit-post-bill-type-form.component';
import { PostBillTypeDepositComponent } from "./components/post-bill-type-deposit/post-bill-type-deposit.component";
import { PostBillTypeDepositDialogComponent } from "./container/post-bill-type-deposit-dialog.component";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditPostBillTypeDialogSandbox
    ],
    declarations: [
        EditPostBillTypeDialogComponent,
        EditPostBillTypeFormComponent,
        PostBillTypeDepositComponent,
        PostBillTypeDepositDialogComponent
    ],
    exports: [
        EditPostBillTypeDialogComponent,
        PostBillTypeDepositDialogComponent
    ],
    entryComponents: [
        EditPostBillTypeDialogComponent,
        PostBillTypeDepositDialogComponent
    ]
})
export class EditPostBillTypeDialogModule {

}
