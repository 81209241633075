
import {catchError} from 'rxjs/operators';
import * as reducer from "../../../../company-infos/dialogs/edit-company-info-dialog/sandbox/store/reducer";
import * as actions from "../../../../company-infos/dialogs/edit-company-info-dialog/sandbox/store/actions";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {CompanyInfoRestService} from "../../../../../../../../core/modules/rest/company-info/company-info-rest.service";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {CompanyInfoResponseModel} from "../../../../../../../../core/modules/rest/company-info/response/company-info-response.model";
import {CompanyInfoFormSubmitDataModel} from "../model/company-info-form-submit-data.model";
import {CreateCompanyInfoRequestModel} from "../../../../../../../../core/modules/rest/company-info/request/create-company-info-request.model";
import {UpdateCompanyInfoRequestModel} from "../../../../../../../../core/modules/rest/company-info/request/update-company-info-request.model";

@Injectable()
export class EditCompanyInfoDialogSandbox extends DialogSandbox {

    companyInfo$: Observable<CompanyInfoResponseModel> = this.store.select(reducer.company_info_selector);

    constructor(appDialogsService: AppDialogsService,
                private companyInfoRestService: CompanyInfoRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);

    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadCompanyInfo(companyInfoId: number) {

        this.showLoader();

        this.companyInfoRestService.getById(companyInfoId).subscribe((companyInfo: CompanyInfoResponseModel) => {
            this.store.dispatch(new actions.SetCompanyInfo(companyInfo));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    createCompanyInfo(companyInfoData: CompanyInfoFormSubmitDataModel): Observable<CreateBaseResponseModel> {

        const request = new CreateCompanyInfoRequestModel(
            companyInfoData.companyCode,
            companyInfoData.description,
            companyInfoData.emailInfo,
            companyInfoData.authorizeDescription,
            companyInfoData.ticketBackgroundImagePath,
            companyInfoData.androidLogoImagePath,
            companyInfoData.address,
            companyInfoData.website,
            companyInfoData.currency
        );

        return this.companyInfoRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating company info', error);
            }));
    }

    updateCompanyInfo(companyInfoId: number, companyInfoData: CompanyInfoFormSubmitDataModel): Observable<any> {

        const request = new UpdateCompanyInfoRequestModel(
            companyInfoId,
            companyInfoData.companyCode,
            companyInfoData.description,
            companyInfoData.emailInfo,
            companyInfoData.authorizeDescription,
            companyInfoData.ticketBackgroundImagePath,
            companyInfoData.androidLogoImagePath,
            companyInfoData.address,
            companyInfoData.website,
            companyInfoData.currency
        );

        return this.companyInfoRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating company info', error);
            }));
    }
}
