
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from './reducers';
import {AppState} from './reducers';
import {Observable, Subject} from 'rxjs';
import {TierRestService} from '../modules/rest/tier/tier-rest.service';
import {TiersResponseModel} from '../modules/rest/tier/response/tiers-response.model';
import {TierResponseModel} from '../modules/rest/tier/response/tier-response.model';
import {ProductRestService} from '../modules/rest/product/product-rest.service';
import {PassRestService} from '../modules/rest/pass/pass-rest.service';
import {RolesResponseModel} from '../modules/rest/role/response/roles-response.model';
import {UsersResponseModel} from '../modules/rest/user/response/users-response.model';
import {LocationsResponseModel} from '../modules/rest/location/response/locations-response.model';
import {RoleRestService} from '../modules/rest/role/role-rest.service';
import {UserRestService} from '../modules/rest/user/user-rest.service';
import {LocationRestService} from '../modules/rest/location/location-rest.service';
import {LocationListRestService} from '../modules/rest/location-list/location-list-rest.service';
import {CategoryRestService} from '../modules/rest/category/category-rest.service';
import {NotificationAlertModel, NotificationAlertTypeEnum} from '../../shared/notifications/notification-alert.model';
import {HttpErrorModel} from '../modules/rest/http-error.model';
import {CategoryResponseModel} from '../modules/rest/category/response/category-response.model';
import {ProductSimpleResponseModel} from '../modules/rest/product/response/product-simple-response.model';
import {PassSimpleResponseModel} from '../modules/rest/pass/response/pass-simple-response.model';
import {RoleResponseModel} from '../modules/rest/role/response/role-response.model';
import {UserResponseModel} from '../modules/rest/user/response/user-response.model';
import {LocationResponseModel} from '../modules/rest/location/response/location-response.model';
import {UserInfoResponseModel} from '../modules/rest/user/response/user-info-response.model';
import * as fromNotificationsActions from './notifications/actions';
import * as fromUserActions from './user/actions';
import * as fromSystemOptions from './system-options/actions';
import {ErrorObservable} from "rxjs/observable/ErrorObservable";
import {LocationListItemDescriptorResponseModel} from "../modules/rest/location-list/response/location-list-item-descriptor-response.model";
import {SystemOptionsResponseModel} from "../modules/rest/system-options/response/system-options-response.model";
import {SystemOptionsRestService} from "../modules/rest/system-options/system-options-rest.service";
import * as rootStore from "./actions";
import { environment } from '../../../environments/environment';
import { WebSocketService } from '../services/websocketService';
import { NotificationResponseModel } from '../modules/rest/notification/response/notification-response.model';
import { NotificationRestService } from '../modules/rest/notification/notification-rest.service';
import { NotificationStatusEnum } from '../../shared/enums/notification-status.enum';

@Injectable()
export class RootSandbox {

    wsService: any;
    notifications: any[] = [];

    constructor(private store: Store<AppState>,
                private tierRestService: TierRestService,
                private categoryRestService: CategoryRestService,
                private productRestService: ProductRestService,
                private passRestService: PassRestService,
                private roleRestService: RoleRestService,
                private userRestService: UserRestService,
                private locationRestService: LocationRestService,
                private locationListRestService: LocationListRestService,
                private systemOptionsRestService: SystemOptionsRestService,
                private notificationRestService: NotificationRestService) {
    }

    logout() {
        this.store.dispatch(new rootStore.Logout());
    }

    loadUserInfo() {
        const subject: Subject<UserInfoResponseModel> = new Subject();
        this.userRestService.getCurrentlyLoggedInUserInfo().subscribe(
            (res: UserInfoResponseModel) => {
                this.store.dispatch(new fromUserActions.SetUserInfo(res));
                subject.next(res);
            },
            (error: HttpErrorModel) => {
                return this.handleHttpError("Error while loading user info", error);
            }
        );

        return subject;
    }

    loadSystemOptions() {

        const subject: Subject<SystemOptionsResponseModel> = new Subject();

        this.systemOptionsRestService.getSystemOptions().subscribe(
            (res: SystemOptionsResponseModel) => {
                this.store.dispatch(new fromSystemOptions.SetSystemOptions(res));
                subject.next(res);
            },
            (error: HttpErrorModel) => {
                return this.handleHttpError("Error while loading system options", error);
            }
        );

        return subject;
    }

    async webSocketConnect(userId: number, companyCode: string): Promise<void> {
        if (!this.wsService || this.wsService.readyState !== WebSocket.OPEN) {
            this.wsService = new WebSocketService(environment.amazonWebsocketUrl + '?production=' + environment.production + '&baseUserId=' + userId + '&companyCode=' + companyCode + '&platform=WEB_APPLICATION');
            this.wsService.connect();

            this.wsService.messageReceived$.subscribe(
                (notification: any) => {
                    if ( notification.type == "PRIVATE" ) {
                        this.handleNotificationOutput(notification);

                    }
                }
            );
        }
        this.notificationRestService.getNotificationHistoryByUser(userId).subscribe(
            (response: NotificationResponseModel[]) => {
              this.notifications = response;
              this.store.dispatch(new fromUserActions.SetUserWebSocketNotifications(this.notifications));
            },
            (error) => {
              console.error('Error fetching notifications:', error);
            }
          );
    }

    transformDate(input: any) {
        const date = new Date(input);
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        const hour = date.getHours();
        const minute = date.getMinutes();
        const result = [
            year,
            month,
            day,
            hour,
            minute
        ];

        return result;
    }

    webSocketDisconnect() {
        this.wsService.close();
    }

    handleNotificationOutput(parsedNotification: any): void {
        let notification = {
            notificationId: parsedNotification.notificationId,
            subject: parsedNotification.subject,
            description: parsedNotification.description,
            notificationType: parsedNotification.notificationType,
            templateId: parsedNotification.templateId,
            fromDateTime: this.transformDate(parsedNotification.fromDateTime),
            displayed: false,
            notificationStatus: parsedNotification.notificationStatus
        };
        if (parsedNotification.notificationStatus == NotificationStatusEnum.CREATED) {
            this.notifications.unshift(notification);
        }
        if (parsedNotification.notificationStatus == NotificationStatusEnum.EXPIRED) {
            let index = this.notifications.findIndex(n => n.notificationId === parsedNotification.notificationId);
            this.notifications.splice(index, 1);
        }
        this.store.dispatch(new fromUserActions.SetUserWebSocketNotifications(this.notifications));
        let hasDisplayedNotification = this.notifications.some(n => n.displayed === false);
        this.store.dispatch(new fromUserActions.SetUserWebSocketAlertIndicator(hasDisplayedNotification, notification));
    }

    setNotifications(notifications: NotificationResponseModel[]) {
        this.notifications = notifications;
        this.store.dispatch(new fromUserActions.SetUserWebSocketNotifications(this.notifications));
    }


    getUserInfo(): Observable<UserInfoResponseModel> {
        return this.store.select(fromRoot.userState_userInfo_selector);
    }

    getAlertIndicator(): Observable<any> {
        return this.store.select(fromRoot.userState_webSocketAlertIndicator_selector);
    }

    setUserInfoToNull() {
        this.store.dispatch(new fromUserActions.SetUserInfo(null));
    }

    getAllTiers(): Observable<TierResponseModel[]> {
        return this.tierRestService.getAll().pipe(
            map((response: TiersResponseModel) => response.tiers),
            map((tiers: TierResponseModel[]) => tiers.sort((t1: TierResponseModel, t2: TierResponseModel) => t1.description.localeCompare(t2.description))),
            catchError((error: HttpErrorModel) => {
                    return this.handleHttpError("Error while loading tiers", error);
                }
            ),);
    }

    getAllActiveTiers(): Observable<TierResponseModel[]> {
        return this.tierRestService.getAllActive().pipe(
            map((response: TiersResponseModel) => response.tiers),
            map((tiers: TierResponseModel[]) => tiers.sort((t1: TierResponseModel, t2: TierResponseModel) => t1.description.localeCompare(t2.description))),
            catchError((error: HttpErrorModel) => {
                    return this.handleHttpError("Error while loading tiers", error);
                }
            ),);
    }

    getAllCategories(): Observable<CategoryResponseModel[]> {
        return this.categoryRestService.getAll().pipe(
            map((categories: CategoryResponseModel[]) => categories.sort((c1: CategoryResponseModel, c2: CategoryResponseModel) => c1.description.localeCompare(c2.description))),
            catchError((error: HttpErrorModel) => {
                    return this.handleHttpError("Error while loading categories", error);
                }
            ),);
    }

    getAllProducts(): Observable<ProductSimpleResponseModel[]> {
        return this.productRestService.getAll().pipe(
            map((products: ProductSimpleResponseModel[]) => products.sort((p1: ProductSimpleResponseModel, p2: ProductSimpleResponseModel) => p1.description.localeCompare(p2.description))),
            catchError((error: HttpErrorModel) => {
                    return this.handleHttpError("Error while loading products", error);
                }
            ),);
    }

    getAllPassesSimple(): Observable<PassSimpleResponseModel[]> {
        return this.passRestService.getAll().pipe(
            catchError((error: HttpErrorModel) => {
                    return this.handleHttpError("Error while loading passes", error);
                }
            ));
    }

    getAllRoles(): Observable<RoleResponseModel[]> {
        return this.roleRestService.getAll().pipe(
            map((response: RolesResponseModel) => response.roles),
            map((roles: RoleResponseModel[]) => roles.sort((r1: RoleResponseModel, r2: RoleResponseModel) => r1.description.localeCompare(r2.description))),
            catchError((error: HttpErrorModel) => {
                    return this.handleHttpError("Error while loading roles", error);
                }
            ),);
    }

    getAllUsers(): Observable<UserResponseModel[]> {
        return this.userRestService.getAllSortedByDisplayName().pipe(
            map((response: UsersResponseModel) => response.users),
            catchError((error: HttpErrorModel) => {
                    return this.handleHttpError("Error while loading users", error);
                }
            ),);
    }

    getAllActiveUsers(): Observable<UserResponseModel[]> {
        return this.userRestService.getAllActiveSortedByDisplayName().pipe(
            map((response: UsersResponseModel) => response.users),
            catchError((error: HttpErrorModel) => {
                    return this.handleHttpError("Error while loading users", error);
                }
            ),);
    }

    getAllLocations(): Observable<LocationResponseModel[]> {
        return this.locationRestService.getAllActive().pipe(
            map((response: LocationsResponseModel) => response.locations),
            map((locations: LocationResponseModel[]) => locations.sort((l1: LocationResponseModel, l2: LocationResponseModel) => l1.description.localeCompare(l2.description))),
            catchError((error: HttpErrorModel) => {
                    return this.handleHttpError("Error while loading locations", error);
                }
            ),);
    }

    getLocations(): Observable<LocationResponseModel[]> {
        return this.locationRestService.getAll().pipe(
            map((response: LocationsResponseModel) => response.locations),
            map((locations: LocationResponseModel[]) => locations.sort((l1: LocationResponseModel, l2: LocationResponseModel) => l1.description.localeCompare(l2.description))),
            catchError((error: HttpErrorModel) => {
                    return this.handleHttpError("Error while loading locations", error);
                }
            ),);
    }

    getAllLoginLocations(): Observable<LocationListItemDescriptorResponseModel[]> {
        return this.locationListRestService.getLoginLocationList().pipe(
            catchError((error: HttpErrorModel) => {
                    return this.handleHttpError("Error while loading login locations", error);
                }
            ));
    }

    getNotifications(): Observable<NotificationAlertModel[]> {
        return this.store.pipe(select(fromRoot.notificationsState_notifications_selector));
    }

    getAllowFixedTimeForFlexibleAndMultipleCheckInProducts(): Observable<boolean> {
        return this.store.pipe(select(fromRoot.allowFixedTimeForFlexibleAndMultipleCheckInProducts_systemOptions_selector));
    }

    getCompanyCode(): Observable<string> {
        return this.store.pipe(select(fromRoot.companyCode_systemOptions_selector));
    }

    disposeFirstNotification() {
        this.store.dispatch(new fromNotificationsActions.DisposeFirstNotification());
    }

    handleHttpError(message: string, httpError: HttpErrorModel, showNotification = true): ErrorObservable<any> {

        if (!httpError.processed) {
            console.error(message + ": ", httpError);

            if (showNotification) {
                this.addErrorNotification(message + ": " + (httpError.serverMessage ? httpError.serverMessage : httpError.message));
            }
        }

        httpError.processed = true;

        return ErrorObservable.create(httpError);
    }

    addInfoNotification(info: string) {

        let infoNotification: NotificationAlertModel = new NotificationAlertModel(NotificationAlertTypeEnum.INFO, info);

        this.store.dispatch(new fromNotificationsActions.AddInfoNotification(infoNotification));
    }

    addErrorNotification(error: string) {

        let errorNotification: NotificationAlertModel = new NotificationAlertModel(NotificationAlertTypeEnum.ERROR, error);

        this.store.dispatch(new fromNotificationsActions.AddInfoNotification(errorNotification));
    }
}
