import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export enum EnableCategoryProductsEnum {
    ALL_PRODUCTS,
    SOME_PRODUCTS,
    NONE
}

export interface EnableCategoryProductsEventData {
    type: EnableCategoryProductsEnum;
    productIds: number[];
}

@Component({
    selector: 'app-enable-category-dialog-form',
    templateUrl: './enable-category-dialog-form.component.html'
})
export class EnableCategoryDialogFormComponent {

    @Input() products: DropdownOptionModel[];

    @Output() submit: EventEmitter<EnableCategoryProductsEventData> = new EventEmitter();

    @Output() cancel: EventEmitter<void> = new EventEmitter();

    selectedProducts: string[] = [];

    type: EnableCategoryProductsEnum = EnableCategoryProductsEnum.ALL_PRODUCTS;

    readonly ALL_PRODUCTS: EnableCategoryProductsEnum = EnableCategoryProductsEnum.ALL_PRODUCTS;
    readonly SOME_PRODUCTS: EnableCategoryProductsEnum = EnableCategoryProductsEnum.SOME_PRODUCTS;
    readonly NONE: EnableCategoryProductsEnum = EnableCategoryProductsEnum.NONE;

    constructor() {
    }

    onProductsSelected(val: string[]) {

        this.type = EnableCategoryProductsEnum.SOME_PRODUCTS;
        this.selectedProducts = val;
    }

    setType(type: EnableCategoryProductsEnum) {

        if (type !== EnableCategoryProductsEnum.SOME_PRODUCTS) {
            this.selectedProducts = [];
        }

        this.type = type;
    }

    onCancel() {
        this.cancel.emit();
    }

    onSubmit() {

        this.submit.emit({
            type: this.type,
            productIds: this.selectedProducts.map(id => Number(id))
        });
    }
}
