import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {CategoryResponseModel} from "../../../../../../../../../core/modules/rest/category/response/category-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {FormControl, FormGroup} from "@angular/forms";

export interface SubmitCategoryEmailHtmlTemplatesData {
    categoryId: number;
    createOrderEmailHtmlTemplateId: number;
    voidOrderEmailHtmlTemplateId: number;
    termsAndConditionsEmailHtmlTemplateId: number;
}

@Component({
    selector: 'app-edit-category-email-html-templates-dialog-form',
    templateUrl: './edit-category-email-html-templates-dialog-form.component.html'
})

export class EditCategoryEmailHtmlTemplatesDialogFormComponent implements OnChanges {

    @Input() category: CategoryResponseModel;
    @Input() createOrderEmailHtmlTemplates: DropdownOptionModel[];
    @Input() voidOrderEmailHtmlTemplates: DropdownOptionModel[];
    @Input() termsAndConditionsEmailHtmlTemplates: DropdownOptionModel[];

    @Output() submit: EventEmitter<SubmitCategoryEmailHtmlTemplatesData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    constructor() {

        this.form = new FormGroup({
            createOrderEmailHtmlTemplateId: new FormControl(),
            voidOrderEmailHtmlTemplateId: new FormControl(),
            termsAndConditionsEmailHtmlTemplateId: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.category) {
            this.initializeFormData();
        }
    }

    onSubmit() {

        let createOrderEmailHtmlTemplateId: number = this.form.value.createOrderEmailHtmlTemplateId ? +this.form.value.createOrderEmailHtmlTemplateId : null;
        let voidOrderEmailHtmlTemplateId: number = this.form.value.voidOrderEmailHtmlTemplateId ? +this.form.value.voidOrderEmailHtmlTemplateId : null;
        let termsAndConditionsEmailHtmlTemplateId: number = this.form.value.termsAndConditionsEmailHtmlTemplateId ? +this.form.value.termsAndConditionsEmailHtmlTemplateId : null;

        this.submit.emit({
            categoryId: this.category.categoryId,
            createOrderEmailHtmlTemplateId: createOrderEmailHtmlTemplateId,
            voidOrderEmailHtmlTemplateId: voidOrderEmailHtmlTemplateId,
            termsAndConditionsEmailHtmlTemplateId: termsAndConditionsEmailHtmlTemplateId
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {

        this.form.patchValue({
            createOrderEmailHtmlTemplateId: this.category.createOrderEmailHtmlTemplateId ? this.category.createOrderEmailHtmlTemplateId.toString() : null,
            voidOrderEmailHtmlTemplateId: this.category.voidOrderEmailHtmlTemplateId ? this.category.voidOrderEmailHtmlTemplateId.toString() : null,
            termsAndConditionsEmailHtmlTemplateId: this.category.termsAndConditionsEmailHtmlTemplateId ? this.category.termsAndConditionsEmailHtmlTemplateId.toString() : null
        });
    }
}
