import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(private localStorageService: LocalStorageService, private router: Router) {}

    canActivate(): boolean {
        const userId = this.localStorageService.getUserIdFromToken();
        if (userId === environment.adminUserId) {
            return true;
        } else {
            this.router.navigate(['/auth/no-permission']);
            return false;
        }
    }
}
