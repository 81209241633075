import {Action} from "@ngrx/store";
import {LetterGroupResponseModel} from "../../../../../../../../../core/modules/rest/letter-group/response/letter-group-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Letter Group :: MAIN] Reset State';
export const SET_LETTER_GROUP_ACTION = '[Dialog - Create/Edit Letter Group :: MAIN] Set Letter Group';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetLetterGroup implements Action {
    readonly type = SET_LETTER_GROUP_ACTION;
    constructor(public letterGroup: LetterGroupResponseModel) {}
}

export type All = ResetState | SetLetterGroup;
