
<div class="inputLabelGroup">

    <div #labelWrapper>
        <ng-content select=".label"></ng-content>
    </div>

    <div #elementWrapper>
        <ng-content select=".main--element"></ng-content>
        <ng-content select=".error"></ng-content>
    </div>

    <ng-content select=".message"></ng-content>

</div>
