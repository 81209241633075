import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {DRAG_DATA_IMAGE, DRAG_N_DROP_DEFAULT_ICON} from '../../../../../../../../core/services/app-properties.service';
import {FieldItemModel} from '../../../model/field-item.model';
import {DraggedDataI} from '../../../model/dragged-data.model';
import {DraggedFieldDataModel} from '../../../model/dragged-field-data.model';
import {detect} from 'detect-browser';

const browser = detect();


@Component({
    selector   : 'app-order-reports-field-item',
    templateUrl: './order-reports-field-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReportsFieldItemComponent implements OnInit {

    @Input() fieldItem: FieldItemModel;
    @Input() draggedData: DraggedDataI;
    @Input() dropAreasVisible: boolean;
    @Input() hasRunOrderReportFullPermission: boolean;
    @Output() dragStartEvent: EventEmitter<DraggedDataI> = new EventEmitter<DraggedDataI>();
    @Output() dragEndEvent: EventEmitter<void>           = new EventEmitter<void>();
    @Output() deleteFieldItemEvent: EventEmitter<string> = new EventEmitter<string>();

    browser = browser;
    isIE = (this.browser.name === 'ie' || this.browser.name === 'edge') ? true : false;

    draggableIconDefault = DRAG_N_DROP_DEFAULT_ICON;
    dragImage = DRAG_DATA_IMAGE;
    isDragged = false;

    constructor() { }

    ngOnInit() {
    }

    onDragStart() {
        this.isDragged = true;
        const data: DraggedDataI = this.getDragData();
        this.dragStartEvent.emit(data);
    }

    onDragEnd() {
        this.isDragged = false;
        this.dragEndEvent.emit();
    }

    deleteFieldItemClick() {
        this.deleteFieldItemEvent.emit(this.fieldItem.queryFieldName);
    }

    getDragData(): DraggedDataI {
        return new DraggedFieldDataModel(this.fieldItem);
    }
}
