<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? 'Edit Letter Group' : 'Add Letter Group' }}</h1>
            </div>
        </div>
    </div>


    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Description</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Description"
                               formControlName="description">
                    </app-input-label-group>
                </div>
            </div>
        </div>


    </div><!-- Dialog Content :: END -->


    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>


            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>



