import { Component, OnInit  } from '@angular/core';


@Component({
  selector: 'app-tab-header',
  templateUrl: './tab-header.component.html'
})
export class TabHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
