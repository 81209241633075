import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {PostBillTypeResponseModel} from "../../../../../../../../core/modules/rest/post-bill-type/response/post-bill-type-response.model";
import {EditPostBillTypeDialogSandbox} from "../sandbox/edit-post-bill-type-dialog.sandbox";
import {PostBillTypeFormSubmitData} from "../models/post-bill-type-form-submit-data";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditPostBillTypeDialogData extends DialogData {

    postBillTypeId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, postBillTypeId?: number) {
        super();
        this.postBillTypeId = postBillTypeId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}


@Component({
  selector: 'app-edit-post-bill-type-dialog',
  templateUrl: './edit-post-bill-type-dialog.component.html'
})
export class EditPostBillTypeDialogComponent implements OnInit, OnDestroy {

    postBillTypeId: number;
    postBillType$: Observable<PostBillTypeResponseModel>;

    editMode: boolean = false;

    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditPostBillTypeDialogData, private sandbox: EditPostBillTypeDialogSandbox) {
        this.postBillTypeId = dialogData.postBillTypeId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
        if (this.postBillTypeId && !isNaN(this.postBillTypeId)) {
            this.editMode = true;
            this.sandbox.loadPostBillType(this.postBillTypeId);
            this.postBillType$ = this.sandbox.postBillType$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: PostBillTypeFormSubmitData) {
        if (this.editMode) {
            this.sandbox.updatePostBillType(this.postBillTypeId, data.description, data.details, data.useDeposit, data.additionalAction).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createPostBillType(data.description, data.details, data.additionalAction).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
