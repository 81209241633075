import {Action} from "@ngrx/store";
import {DepartureGroupResponseModel} from "../../../../../../../../../core/modules/rest/departure-group/response/departure-group-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Departure Group :: MAIN] Reset State';
export const SET_DEPARTURE_GROUP_ACTION = '[Dialog - Create/Edit Departure Group :: MAIN] Set Departure Group';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetDepartureGroup implements Action {
    readonly type = SET_DEPARTURE_GROUP_ACTION;
    constructor(public departureGroup: DepartureGroupResponseModel) {}
}

export type All = ResetState | SetDepartureGroup;
