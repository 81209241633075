
import {empty as observableEmpty, Observable} from 'rxjs';

import {catchError, take} from 'rxjs/operators';
import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {EditOccurrencesDialogSandbox} from "./sandbox/edit-occurrences-dialog.sandbox";
import {EditOccurrenceDataModel} from "../../model/edit-occurrence-data.model";
import {HttpErrorModel} from "../../../../../../../core/modules/rest/http-error.model";
import {ErrorCodeEnum} from "../../../../../../../shared/enums/error-code.enum";
import {RootSandbox} from "../../../../../../../core/store/root.sandbox";
import {ErrorObservable} from "rxjs/observable/ErrorObservable";

export class EditOccurrenceDialogData extends DialogData {

    occurrences: EditOccurrenceDataModel[];
    updateCallbackFn: Function;

    constructor(occurrences: EditOccurrenceDataModel[], updateCallbackFn: Function) {
        super();
        this.occurrences = occurrences;
        this.updateCallbackFn = updateCallbackFn;
    }
}


@Component({
    selector: 'app-edit-occurrences-dialog',
    templateUrl: './edit-occurrences-dialog.component.html'
})
export class EditOccurrencesDialogComponent implements OnInit, OnDestroy {

    occurrences: EditOccurrenceDataModel[];

    updateCallbackFn: Function;

    totalQuantityValue: number = null;

    constructor(@Inject(DialogData) data: EditOccurrenceDialogData,
                private sandbox: EditOccurrencesDialogSandbox,
                private rootSandbox: RootSandbox) {

        this.occurrences = data.occurrences;
        this.updateCallbackFn = data.updateCallbackFn;

        if (this.occurrences.length !== 0) {

            let firstValue: number = this.occurrences[0].total;

            let allSameValues: EditOccurrenceDataModel[] = this.occurrences.filter(eo => eo.total === firstValue);

            if (allSameValues.length === this.occurrences.length) {
                this.totalQuantityValue = firstValue;
            }
        }
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit() {

        // Check total quantity
        if (!this.totalQuantityValue || isNaN(this.totalQuantityValue) || Number(this.totalQuantityValue) < 0) {
            return;
        }

        this.sandbox.showLoader();

        this.sandbox.updateOccurrenceQuantity(this.occurrences.map(oc => oc.occurrenceId), Number(this.totalQuantityValue)).pipe(
            take(1),
            catchError((error: HttpErrorModel) => {
                if (error.errorCode === ErrorCodeEnum.SMARTSTUBS_CANT_CHANGE_OCCURRENCE_QUANTITY) {
                    this.sandbox.hideLoader();
                    this.rootSandbox.addErrorNotification("Can't change occurrence total. Total can't be less than reserved.");
                    return ErrorObservable.create(observableEmpty());
                } else {
                    return this.rootSandbox.handleHttpError('Error while changing occurrence total quantity', error);
                }
            }),)
            .subscribe(() => {

                this.sandbox.hideLoader();
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            },
            () => this.sandbox.hideLoader());
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
