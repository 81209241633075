
import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {UserRestService} from "../../../../../../../../core/modules/rest/user/user-rest.service";
import {UsersResponseModel} from "../../../../../../../../core/modules/rest/user/response/users-response.model";
import {DropdownOptionModel} from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {Observable} from "rxjs";
import {EnableRoleUsersEnum} from "../components/enable-role-dialog-form/enable-role-dialog-form.component";
import {RoleRestService} from "../../../../../../../../core/modules/rest/role/role-rest.service";
import {ActivateRoleRequestModel} from "../../../../../../../../core/modules/rest/role/request/activate-role-request.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";

@Injectable()
export class EnableRoleDialogSandbox extends DialogSandbox {

    users$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.users_selector));

    constructor(appDialogsService: AppDialogsService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox,
                private userRestService: UserRestService,
                private roleRestService: RoleRestService) {
        super(appDialogsService);
    }

    load(roleId: number) {
        this.userRestService.getAllUsersByRoleIdSortedByDisplayName(roleId).subscribe((response: UsersResponseModel) => {
            this.store.dispatch(new actions.SetUsers(response.users.map(u => new DropdownOptionModel(u.displayName, u.userId.toString()))));
        });
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    enableRole(roleId: number, type: EnableRoleUsersEnum, userIds: number[]): Observable<any> {

        return this.roleRestService.activate(new ActivateRoleRequestModel(roleId, type === EnableRoleUsersEnum.ALL_USERS, type === EnableRoleUsersEnum.SOME_USERS ? userIds : [])).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while activating role', error);
            }));
    }
}
