import { DynamicPermissionTypeEnum, PermissionNode, PermissionTypeEnum } from "./permission-node";
import { PermissionEnum } from "../../../../../../shared/enums/permission.enum";
import { RoleMappingResponseModel } from "../../../../../../core/modules/rest/role/response/role-mapping-response.model";
import { UserMappingResponseModel } from "../../../../../../core/modules/rest/user/response/user-mapping-response.model";

export interface MappedCategoryModel {
    categoryId: number;
    active: boolean;
    description: string;
    inherited: boolean;
}

export interface MappedProductModel {
    productId: number;
    categoryId: number;
    active: boolean;
    description: string;
    inherited: boolean;
}

export interface MappedPassModel {
    passId: number;
    active: boolean;
    description: string;
    inherited: boolean;
}
export interface MappedCloudPaymentDeviceModel {
    cloudPaymentDeviceId: number;
    active: boolean;
    description: string;
    inherited: boolean;
}

export interface MappedQuickSellingButtonProductModel {
    quickSellingButtonProductId: number;
    active: boolean;
    description: string;
    inherited: boolean;
}

export interface MappedQuickSellingButtonPassModel {
    quickSellingButtonPassId: number;
    active: boolean;
    description: string;
    inherited: boolean;
}

export interface MappedPostBillTypeModel {
    postBillTypeId: number;
    active: boolean;
    description: string;
    inherited: boolean;
}

export interface MappedFavoriteModel {
    favoriteId: number;
    active: boolean;
    description: string;
    inherited: boolean;
}

export interface MappedPermissionModel {
    permission: PermissionEnum;
    active: boolean;
    inherited: boolean;
}

export interface SelectedCategoryModel {
    categoryId: number;
    active: boolean;
}

export interface SelectedProductModel {
    productId: number;
    active: boolean;
}

export interface SelectedPassModel {
    passId: number;
    active: boolean;
}
export interface SelectedCloudPaymentDeviceModel {
    cloudPaymentDeviceId: number;
    active: boolean;
}

export interface SelectedQuickSellingButtonProductModel {
    quickSellingButtonProductId: number;
    active: boolean;
}

export interface SelectedQuickSellingButtonPassModel {
    quickSellingButtonPassId: number;
    active: boolean;
}

export interface SelectedPostBillTypeModel {
    postBillTypeId: number;
    active: boolean;
}

export interface SelectedFavoriteModel {
    favoriteId: number;
    active: boolean;
}

export interface SelectedPermissionModel {
    permission: PermissionEnum;
    active: boolean;
}

export interface MappedDataModel {
    categories: MappedCategoryModel[];
    products: MappedProductModel[];
    passes: MappedPassModel[];
    cloudPaymentDevices: MappedCloudPaymentDeviceModel[];
    quickSellingButtonProducts: MappedQuickSellingButtonProductModel[];
    quickSellingButtonPasses: MappedQuickSellingButtonPassModel[];
    postBillTypes: MappedPostBillTypeModel[];
    favorites: MappedFavoriteModel[];
    permissions: MappedPermissionModel[];
}

export interface SelectedRoleMappedDataModel {
    categories: number[];
    products: number[];
    passes: number[];
    cloudPaymentDevices: number[];
    qsbProducts: number[];
    qsbPasses: number[];
    postBillTypes: number[];
    favorites: number[];
    permissions: PermissionEnum[];
}

export interface SelectedUserMappedDataModel {
    categories: SelectedCategoryModel[];
    products: SelectedProductModel[];
    passes: SelectedPassModel[];
    cloudPaymentDevices: SelectedCloudPaymentDeviceModel[];
    qsbProducts: SelectedQuickSellingButtonProductModel[];
    qsbPasses: SelectedQuickSellingButtonPassModel[];
    postBillTypes: SelectedPostBillTypeModel[];
    favorites: SelectedFavoriteModel[];
    permissions: SelectedPermissionModel[];
}

export class MappedData {

    data: MappedDataModel;

    constructor() {
    }

    getPermissionTreeFromRoleMapping(data: RoleMappingResponseModel): PermissionNode[] {

        this.data = MappedData.convertRoleMapping(data);

        return this.createPermissionTree(this.data);
    }

    getPermissionTreeFromUserMapping(data: UserMappingResponseModel): PermissionNode[] {

        this.data = MappedData.convertUserMapping(data);

        return this.createPermissionTree(this.data);
    }

    getUserMappedDataFromPermissionTree(data: PermissionNode[]): SelectedUserMappedDataModel {
        let categories: SelectedCategoryModel[] = [];
        let products: SelectedProductModel[] = [];
        let passes: SelectedPassModel[] = [];
        let cloudPaymentDevices: SelectedCloudPaymentDeviceModel[] = [];
        let quickSellingButtonProducts: SelectedQuickSellingButtonProductModel[] = [];
        let quickSellingButtonPasses: SelectedQuickSellingButtonPassModel[] = [];
        let postBillTypes: SelectedPostBillTypeModel[] = [];
        let favorites: SelectedFavoriteModel[] = [];
        let permissions: SelectedPermissionModel[] = [];

        // -------------------------------------------------------------------------------------------------------------
        // CATEGORIES
        // -------------------------------------------------------------------------------------------------------------

        let categoryParent: PermissionNode = this.findNodeById(PermissionEnum.CREATE_ORDER_LONG_FORM_PRODUCT, data);

        categoryParent.children.forEach(c => {
            categories.push({
                categoryId: Number(c.id),
                active: c.checked
            });
        });

        // -------------------------------------------------------------------------------------------------------------
        // PRODUCTS
        // -------------------------------------------------------------------------------------------------------------

        categoryParent.children.forEach(c => {
            c.children.forEach(p => {
                products.push({
                    productId: Number(p.id),
                    active: p.checked
                });
            });
        });

        // -------------------------------------------------------------------------------------------------------------
        // PASSES
        // -------------------------------------------------------------------------------------------------------------

        let passParent: PermissionNode = this.findNodeById(PermissionEnum.CREATE_ORDER_LONG_FORM_PASS, data);

        passParent.children.forEach(p => {
            passes.push({
                passId: Number(p.id),
                active: p.checked
            });
        });

        // -------------------------------------------------------------------------------------------------------------
        // CLOUD PAYMENT DEVICES
        // -------------------------------------------------------------------------------------------------------------

        let cloudPaymentDeviceParent: PermissionNode = this.findNodeById(PermissionEnum.CREATE_ORDER_LONG_FORM_CLOUD_PAYMENT_DEVICE, data);

        cloudPaymentDeviceParent.children.forEach(c => {

            cloudPaymentDevices.push({
                cloudPaymentDeviceId: Number(c.id),
                active: c.checked
            });
        });

        // -------------------------------------------------------------------------------------------------------------
        // QSB - PRODUCTS
        // -------------------------------------------------------------------------------------------------------------

        let qsbProductParent: PermissionNode = this.findNodeById(PermissionEnum.CREATE_ORDER_QSB_PRODUCT, data);

        qsbProductParent.children.forEach(qsb => {
            quickSellingButtonProducts.push({
                quickSellingButtonProductId: Number(qsb.id),
                active: qsb.checked
            });
        });

        // -------------------------------------------------------------------------------------------------------------
        // QSB - PASS
        // -------------------------------------------------------------------------------------------------------------

        let qsbPassParent: PermissionNode = this.findNodeById(PermissionEnum.CREATE_ORDER_QSB_PASS, data);

        qsbPassParent.children.forEach(qsb => {
            quickSellingButtonPasses.push({
                quickSellingButtonPassId: Number(qsb.id),
                active: qsb.checked
            });
        });

        // -------------------------------------------------------------------------------------------------------------
        // POST BILL TYPES
        // -------------------------------------------------------------------------------------------------------------

        let postBillTypeParent: PermissionNode = this.findNodeById(PermissionEnum.CAN_ACCEPT_POST_BILL_PAYMENT, data);

        postBillTypeParent.children.forEach(pbt => {
            postBillTypes.push({
                postBillTypeId: Number(pbt.id),
                active: pbt.checked
            });
        });

        // -------------------------------------------------------------------------------------------------------------
        // FAVORITES
        // -------------------------------------------------------------------------------------------------------------

        let favoritesParent: PermissionNode = this.findNodeById(PermissionEnum.USE_FAVORITES, data);

        favoritesParent.children.forEach(f => {
            favorites.push({
                favoriteId: Number(f.id),
                active: f.checked
            });
        });

        // -------------------------------------------------------------------------------------------------------------
        // PERMISSIONS
        // -------------------------------------------------------------------------------------------------------------

        data.forEach(n => {
            this.collectUserPermissions(permissions, n);
        });

        return {
            categories: categories,
            products: products,
            passes: passes,
            cloudPaymentDevices: cloudPaymentDevices,
            qsbProducts: quickSellingButtonProducts,
            qsbPasses: quickSellingButtonPasses,
            postBillTypes: postBillTypes,
            favorites: favorites,
            permissions: permissions
        };
    }

    getRoleMappedDataFromPermissionTree(data: PermissionNode[]): SelectedRoleMappedDataModel {

        let categories: number[] = [];
        let products: number[] = [];
        let passes: number[] = [];
        let cloudPaymentDevices: number[] = [];
        let quickSellingButtonProducts: number[] = [];
        let quickSellingButtonPasses: number[] = [];
        let postBillTypes: number[] = [];
        let favorites: number[] = [];
        let permissions: PermissionEnum[] = [];

        // -------------------------------------------------------------------------------------------------------------
        // CATEGORIES
        // -------------------------------------------------------------------------------------------------------------

        let categoryParent: PermissionNode = this.findNodeById(PermissionEnum.CREATE_ORDER_LONG_FORM_PRODUCT, data);

        categoryParent.children.forEach(c => {
            if (c.checked) {
                categories.push(Number(c.id));
            }
        });

        // -------------------------------------------------------------------------------------------------------------
        // PRODUCTS
        // -------------------------------------------------------------------------------------------------------------

        categoryParent.children.forEach(c => {
            c.children.forEach(p => {
                if (p.checked) {
                    products.push(Number(p.id));
                }
            });
        });

        // -------------------------------------------------------------------------------------------------------------
        // PASSES
        // -------------------------------------------------------------------------------------------------------------

        let passParent: PermissionNode = this.findNodeById(PermissionEnum.CREATE_ORDER_LONG_FORM_PASS, data);

        passParent.children.forEach(p => {
            if (p.checked) {
                passes.push(Number(p.id));
            }
        });

        // -------------------------------------------------------------------------------------------------------------
        // CLOUD PAYMENT DEVICES
        // -------------------------------------------------------------------------------------------------------------

        let cloudPaymentDeviceParent: PermissionNode = this.findNodeById(PermissionEnum.CREATE_ORDER_LONG_FORM_CLOUD_PAYMENT_DEVICE, data);

        cloudPaymentDeviceParent.children.forEach(c => {
            if (c.checked) {
                cloudPaymentDevices.push(Number(c.id));
            }
        });

        // -------------------------------------------------------------------------------------------------------------
        // QSB - PRODUCTS
        // -------------------------------------------------------------------------------------------------------------

        let qsbProductParent: PermissionNode = this.findNodeById(PermissionEnum.CREATE_ORDER_QSB_PRODUCT, data);

        qsbProductParent.children.forEach(qsb => {
            if (qsb.checked) {
                quickSellingButtonProducts.push(Number(qsb.id));
            }
        });

        // -------------------------------------------------------------------------------------------------------------
        // QSB - PASS
        // -------------------------------------------------------------------------------------------------------------

        let qsbPassParent: PermissionNode = this.findNodeById(PermissionEnum.CREATE_ORDER_QSB_PASS, data);

        qsbPassParent.children.forEach(qsb => {
            if (qsb.checked) {
                quickSellingButtonPasses.push(Number(qsb.id));
            }
        });

        // -------------------------------------------------------------------------------------------------------------
        // POST BILL TYPES
        // -------------------------------------------------------------------------------------------------------------

        let postBillTypeParent: PermissionNode = this.findNodeById(PermissionEnum.CAN_ACCEPT_POST_BILL_PAYMENT, data);

        postBillTypeParent.children.forEach(pbt => {
            if (pbt.checked) {
                postBillTypes.push(Number(pbt.id));
            }
        });

        // -------------------------------------------------------------------------------------------------------------
        // FAVORITES
        // -------------------------------------------------------------------------------------------------------------

        let favoritesParent: PermissionNode = this.findNodeById(PermissionEnum.USE_FAVORITES, data);

        favoritesParent.children.forEach(f => {
            if (f.checked) {
                favorites.push(Number(f.id));
            }
        });

        // -------------------------------------------------------------------------------------------------------------
        // PERMISSIONS
        // -------------------------------------------------------------------------------------------------------------

        data.forEach(n => {
            this.collectRolePermissions(permissions, n);
        });

        return {
            categories: categories,
            products: products,
            passes: passes,
            cloudPaymentDevices: cloudPaymentDevices,
            qsbProducts: quickSellingButtonProducts,
            qsbPasses: quickSellingButtonPasses,
            postBillTypes: postBillTypes,
            favorites: favorites,
            permissions: permissions
        };
    }

    private createPermissionTree(data: MappedDataModel): PermissionNode[] {

        // -------------------------------------------------------------------------------------------------------------
        // DYNAMIC LISTS
        // -------------------------------------------------------------------------------------------------------------

        let categories: PermissionNode[] = data.categories.map((category: MappedCategoryModel) => {

            let products: PermissionNode[] = data.products
                .filter((p: MappedProductModel) => p.categoryId === category.categoryId)
                .map((p: MappedProductModel) => new PermissionNode(PermissionTypeEnum.DYNAMIC, DynamicPermissionTypeEnum.PRODUCTS, p.productId, p.description, p.active, [], p.inherited));

            let active: boolean = category.active || products.filter(p => p.checked).length !== 0;

            return new PermissionNode(PermissionTypeEnum.DYNAMIC, DynamicPermissionTypeEnum.CATEGORIES, category.categoryId, category.description, active, products, category.inherited);
        });

        let passes: PermissionNode[] = data.passes.map((pass: MappedPassModel) => {
            return new PermissionNode(PermissionTypeEnum.DYNAMIC, DynamicPermissionTypeEnum.PASSES, pass.passId, pass.description, pass.active, [], pass.inherited);
        });

        let cloudPaymentDevices: PermissionNode[] = data.cloudPaymentDevices.map((cloudPaymenDevice: MappedCloudPaymentDeviceModel) => {
            return new PermissionNode(PermissionTypeEnum.DYNAMIC, DynamicPermissionTypeEnum.PASSES, cloudPaymenDevice.cloudPaymentDeviceId, cloudPaymenDevice.description, cloudPaymenDevice.active, [], cloudPaymenDevice.inherited);
        });

        let qsbProducts: PermissionNode[] = data.quickSellingButtonProducts.map((qsbProduct: MappedQuickSellingButtonProductModel) => {
            return new PermissionNode(PermissionTypeEnum.DYNAMIC, DynamicPermissionTypeEnum.QSB_PRODUCTS, qsbProduct.quickSellingButtonProductId, qsbProduct.description, qsbProduct.active, [], qsbProduct.inherited);
        });

        let qsbPasses: PermissionNode[] = data.quickSellingButtonPasses.map((qsbPass: MappedQuickSellingButtonPassModel) => {
            return new PermissionNode(PermissionTypeEnum.DYNAMIC, DynamicPermissionTypeEnum.QSB_PASSES, qsbPass.quickSellingButtonPassId, qsbPass.description, qsbPass.active, [], qsbPass.inherited);
        });

        let postBillTypes: PermissionNode[] = data.postBillTypes.map((postBillType: MappedPostBillTypeModel) => {
            return new PermissionNode(PermissionTypeEnum.DYNAMIC, DynamicPermissionTypeEnum.POST_BILL_TYPES, postBillType.postBillTypeId, postBillType.description, postBillType.active, [], postBillType.inherited);
        });

        let favorites: PermissionNode[] = data.favorites.map((favorite: MappedFavoriteModel) => {
            return new PermissionNode(PermissionTypeEnum.DYNAMIC, DynamicPermissionTypeEnum.FAVORITES, favorite.favoriteId, favorite.description, favorite.active, [], favorite.inherited);
        });

        // -------------------------------------------------------------------------------------------------------------
        // SALES
        // -------------------------------------------------------------------------------------------------------------

        let createOrderLongFormProduct: PermissionNode = this.createPermissionNode("Create Order - Long Form - Product", PermissionEnum.CREATE_ORDER_LONG_FORM_PRODUCT, categories);

        let createOrderLongFormPass: PermissionNode = this.createPermissionNode("Create Order - Long Form - Pass", PermissionEnum.CREATE_ORDER_LONG_FORM_PASS, passes);

        let createOrderQsbProduct: PermissionNode = this.createPermissionNode("Create Order - QSB - Product", PermissionEnum.CREATE_ORDER_QSB_PRODUCT, qsbProducts);

        let createOrderQsbPass: PermissionNode = this.createPermissionNode("Create Order - QSB - Pass", PermissionEnum.CREATE_ORDER_QSB_PASS, qsbPasses);

        let createOrderLongFormCloudPaymentDevices: PermissionNode = this.createPermissionNode("Create Order - Cloud Payment Device", PermissionEnum.CREATE_ORDER_LONG_FORM_CLOUD_PAYMENT_DEVICE, cloudPaymentDevices);

        let canAcceptPostBillPayment: PermissionNode = this.createPermissionNode("Can Accept Post-Bill Payment", PermissionEnum.CAN_ACCEPT_POST_BILL_PAYMENT, postBillTypes);

        let editOrder: PermissionNode = this.createPermissionNode("Edit Order", PermissionEnum.CAN_EDIT_ORDERS, [
            this.createPermissionNode("Can Edit Order", PermissionEnum.EDIT_ORDER),
            this.createPermissionNode("View Cancelled Order Details", PermissionEnum.CAN_VIEW_CANCELLED_DETAILS),
            this.createPermissionNode("Can Edit Order Details", PermissionEnum.EDIT_ORDER_DETAILS),
            this.createPermissionNode("Can Edit Completed Order Details", PermissionEnum.EDIT_COMPLETED_ORDER)
        ]);

        let voidOrder: PermissionNode = this.createPermissionNode("Void Order", PermissionEnum.MANAGE_VOID_ORDER, [
            this.createPermissionNode("Can Void Order", PermissionEnum.VOID_ORDER),
            this.createPermissionNode("Void Own Order In Last 24 Hours", PermissionEnum.VOID_OWN_ORDER_IN_LAST_24_HOURS),
            this.createPermissionNode("Void Completed Order", PermissionEnum.VOID_COMPLETED_ORDER),
            this.createPermissionNode("Void Expired Order", PermissionEnum.VOID_EXPIRED_ORDER)
        ]);

        let printOrderOnce: PermissionNode = this.createPermissionNode("Print Order", PermissionEnum.PRINT_ORDER, [
            this.createPermissionNode("Print Order Once", PermissionEnum.PRINT_ORDER_ONCE),
            this.createPermissionNode("Print Order Twice", PermissionEnum.PRINT_ORDER_TWICE),
            this.createPermissionNode("Print Order Multiple Times", PermissionEnum.PRINT_ORDER_MULTIPLE_TIMES),
            this.createPermissionNode("Print Order Summary", PermissionEnum.PRINT_ORDER_SUMMARY)
        ]);

        let emailOrder: PermissionNode = this.createPermissionNode("Email Order", PermissionEnum.EMAIL_ORDER);

        let cloneOrder: PermissionNode = this.createPermissionNode("Can Clone Order", PermissionEnum.CLONE_ORDER);

        let viewOrder: PermissionNode = this.createPermissionNode("View Order", PermissionEnum.VIEW_ORDER, [
            editOrder,
            voidOrder,
            printOrderOnce,
            emailOrder,
            cloneOrder
        ]);

        let sales: PermissionNode = this.createPermissionNode("Sales", PermissionEnum.SALES, [
            createOrderLongFormProduct,
            createOrderLongFormPass,
            createOrderQsbProduct,
            createOrderQsbPass,
            createOrderLongFormCloudPaymentDevices,
            this.createPermissionNode("Can Accept Cash Payment", PermissionEnum.CAN_ACCEPT_CASH_PAYMENT),
            this.createPermissionNode("Can Accept Credit Card Payment - CNP", PermissionEnum.CAN_ACCEPT_CREDIT_CARD_PAYMENT),
            this.createPermissionNode("Can Accept Cloud Payment - CP", PermissionEnum.USE_CLOUD_PAYMENTS),
            canAcceptPostBillPayment,
            this.createPermissionNode("Can Enter Custom Price For Product", PermissionEnum.CAN_ENTER_CUSTOM_PRICE_FOR_PRODUCT),
            this.createPermissionNode("Can Enter Custom Price For Pass", PermissionEnum.CAN_ENTER_CUSTOM_PRICE_FOR_PASS),
            this.createPermissionNode("Can Apply Discount", PermissionEnum.CAN_APPLY_DISCOUNT),
            this.createPermissionNode("Can Be Referrer", PermissionEnum.CAN_BE_REFERRER),
            this.createPermissionNode("Can Choose Referrer", PermissionEnum.CAN_CHOOSE_REFERRER),
            this.createPermissionNode("Can Do Cashout Entry", PermissionEnum.CAN_DO_CASHOUT_ENTRY),
            this.createPermissionNode("Can Cashout All Users", PermissionEnum.CAN_CASHOUT_ALL_USERS),
            this.createPermissionNode("Can Do Cashout Reconciliation", PermissionEnum.CAN_DO_CASHOUT_RECONCILIATION),
            this.createPermissionNode("Use Login Locations", PermissionEnum.USE_LOGIN_LOCATIONS),
            viewOrder
        ]);

        // -------------------------------------------------------------------------------------------------------------
        // REPORTS
        // -------------------------------------------------------------------------------------------------------------

        let useFavorites: PermissionNode = this.createPermissionNode("Use Favorites", PermissionEnum.USE_FAVORITES, favorites);
        useFavorites.checked = favorites.filter(d => d.checked).length !== 0;

        let generalReports: PermissionNode = this.createPermissionNode("General", PermissionEnum.RUN_ORDER_REPORT, [
            this.createPermissionNode("Run Order Report Full", PermissionEnum.RUN_ORDER_REPORT_FULL),
            this.createPermissionNode("Run Auto Reports", PermissionEnum.RUN_AUTO_REPORTS),
            this.createPermissionNode("Run Audit Log Report", PermissionEnum.RUN_AUDITLOG_REPORT),
            this.createPermissionNode("Create Favorite", PermissionEnum.CREATE_FAVORITE),
            this.createPermissionNode("Edit Favorite", PermissionEnum.EDIT_FAVORITE),
            this.createPermissionNode("Remove Favorite", PermissionEnum.REMOVE_FAVORITE),
            useFavorites
        ]);

        let operationalReports: PermissionNode = this.createPermissionNode("Operational", PermissionEnum.RUN_OPERATIONAL_REPORTS, [
            this.createPermissionNode("Run Operations Report", PermissionEnum.RUN_OPERATIONS_REPORT),
            this.createPermissionNode("Run Override Report", PermissionEnum.RUN_OVERRIDE_REPORT),
            this.createPermissionNode("Run Driver Manifest Report", PermissionEnum.RUN_DRIVER_MANIFEST_REPORT)
        ]);

        let financialReports: PermissionNode = this.createPermissionNode("Financial", PermissionEnum.RUN_FINANCIAL_REPORTS, [
            this.createPermissionNode("Run Trip Revenue Report", PermissionEnum.RUN_TRIP_REVENUE_REPORT),
            this.createPermissionNode("Run Ticket Type Revenue Report", PermissionEnum.RUN_TICKET_TYPE_REVENUE_REPORT),
            this.createPermissionNode("Run Collected & Earned Revenue Report", PermissionEnum.RUN_COLLECTED_AND_EARNED_REVENUE_REPORT),
            this.createPermissionNode("Run Commission Report", PermissionEnum.RUN_COMMISSION_REPORT)
        ]);

        let customReports: PermissionNode = this.createPermissionNode("Custom", PermissionEnum.RUN_CUSTOM_REPORTS, [
            // this.createPermissionNode("Run Manifest Report", PermissionEnum.RUN_MANIFEST_REPORT), TODO: Permission not used, hide it form UI
            // this.createPermissionNode("Run Passenger Count Report", PermissionEnum.RUN_PASSENGER_COUNT_REPORT), TODO: Permission not used, hide it form UI
            this.createPermissionNode("Run Revenue Report", PermissionEnum.RUN_REVENUE_REPORT),
            this.createPermissionNode("Run NetSuite Export Report", PermissionEnum.RUN_NET_SUITE_EXPORT_REPORT),
            this.createPermissionNode("Run Monthly Billing Report", PermissionEnum.RUN_CUSTOM_BILLING_REPORT)
            // this.createPermissionNode("Run Occurrence Report", PermissionEnum.RUN_OCCURRENCE_REPORT), TODO: Permission not used, hide it form UI
            // this.createPermissionNode("Run Transaction Report", PermissionEnum.RUN_TRANSACTION_REPORT), TODO: Permission not used, hide it form UI
            // this.createPermissionNode("Run Reconcile Report", PermissionEnum.RUN_RECONCILE_REPORT), TODO: Permission not used, hide it form UI
            // this.createPermissionNode("Run PassCard Report", PermissionEnum.RUN_PASS_CARD_REPORT) TODO: Permission not used, hide it form UI
        ]);

        let hardwareReports: PermissionNode = this.createPermissionNode("Hardware", PermissionEnum.RUN_HARDWARE_REPORTS, [
            this.createPermissionNode("Run Invalid Scan Report", PermissionEnum.RUN_INVALID_SCAN_REPORT),
            this.createPermissionNode("Android Debug Logs", PermissionEnum.ANDROID_DEBUG_LOGS)
        ]);

        let reports: PermissionNode = this.createPermissionNode("Reports", PermissionEnum.REPORTS, [
            generalReports,
            operationalReports,
            financialReports,
            customReports,
            hardwareReports
        ]);

        // -------------------------------------------------------------------------------------------------------------
        // MANAGEMENT
        // -------------------------------------------------------------------------------------------------------------

        let manageInventory: PermissionNode = this.createPermissionNode("Manage Inventory", PermissionEnum.MANAGE_INVENTORY, [
            this.createPermissionNode("Manage Categories and Products", PermissionEnum.MANAGE_CATEGORIES_AND_PRODUCTS),
            this.createPermissionNode("Apply Category & Product Costs", PermissionEnum.APPLY_CATEGORY_AND_PRODUCT_COSTS),
            this.createPermissionNode("Manage Passes", PermissionEnum.MANAGE_PASSES),
            this.createPermissionNode("Manage Pass Cards", PermissionEnum.MANAGE_PASS_CARDS),
            this.createPermissionNode("Manage Occurrence", PermissionEnum.MANAGE_OCCURRENCE),
            this.createPermissionNode("Can Bulk Check-In", PermissionEnum.BULK_CHECK_IN),
            this.createPermissionNode("Apply Pass Costs", PermissionEnum.APPLY_PASS_COSTS),
            // this.createPermissionNode("Availability Calendar", PermissionEnum.AVAILABILITY_CALENDAR), TODO: Permission not used, hide it form UI
            this.createPermissionNode("Manage Buses", PermissionEnum.MANAGE_BUSES),
            this.createPermissionNode("Manage Holidays", PermissionEnum.MANAGE_HOLIDAYS),
            this.createPermissionNode("Manage Locations & Routes", PermissionEnum.MANAGE_LOCATIONS_AND_ROUTES),
            this.createPermissionNode("Manage Trip Groupings", PermissionEnum.MANAGE_TRIP_GROUPINGS)
        ]);

        let manageSales: PermissionNode = this.createPermissionNode("Manage Sales", PermissionEnum.MANAGE_SALES, [
            this.createPermissionNode("Manage QSB Products", PermissionEnum.MANAGE_QSB_PRODUCTS),
            this.createPermissionNode("Manage QSB Passes", PermissionEnum.MANAGE_QSB_PASSES),
            this.createPermissionNode("Manage Tiers", PermissionEnum.MANAGE_TIERS),
            this.createPermissionNode("Manage Costs", PermissionEnum.MANAGE_COSTS),
            this.createPermissionNode("Manage Post-Bill Types", PermissionEnum.MANAGE_POST_BILL_TYPES),
            this.createPermissionNode("Manage Commissions", PermissionEnum.MANAGE_COMMISSIONS)
        ]);

        let manageSystem: PermissionNode = this.createPermissionNode("Manage System", PermissionEnum.MANAGE_SYSTEM, [
            this.createPermissionNode("Manage System Options", PermissionEnum.MANAGE_SYSTEM_OPTIONS),
            this.createPermissionNode("Manage Company Options", PermissionEnum.MANAGE_COMPANY_INFOS),
            this.createPermissionNode("Manage Templates", PermissionEnum.MANAGE_EMAIL_HTML_TEMPLATES),
            this.createPermissionNode("Manage Roles & Users", PermissionEnum.MANAGE_ROLES_AND_USERS),
            this.createPermissionNode("Manage Favorites", PermissionEnum.MANAGE_FAVORITES),
            this.createPermissionNode("Manage Notifications", PermissionEnum.MANAGE_NOTIFICATIONS)
            // this.createPermissionNode("Manage Payment Gateways", PermissionEnum.MANAGE_PAYMENT_GATEWAYS) TODO: Permission not used, hide it form UI
        ]);

        let manageMobile: PermissionNode = this.createPermissionNode("Manage Mobile", PermissionEnum.MANAGE_MOBILE, [
            this.createPermissionNode("Manage Androids", PermissionEnum.MANAGE_ANDROIDS),
            this.createPermissionNode("Manage Override Reasons", PermissionEnum.MANAGE_OVERRIDE_REASON)
        ]);

        let manageHardware: PermissionNode = this.createPermissionNode("Manage Hardware", PermissionEnum.MANAGE_HARDWARE, [
            this.createPermissionNode("Manage Kiosks", PermissionEnum.MANAGE_KIOSKS),
            this.createPermissionNode("Manage Kiosk Configuration Details", PermissionEnum.MANAGE_KIOSK_CONFIGURATION_DETAILS),
            this.createPermissionNode("Manage Cloud Payment Devices", PermissionEnum.MANAGE_CLOUD_PAYMENTS),
            manageMobile
        ]);


        let manageCustomers: PermissionNode = this.createPermissionNode("Manage Customers", PermissionEnum.CUSTOMERS_ADMINISTRATION, [
            this.createPermissionNode("Manage Customers", PermissionEnum.MANAGE_CUSTOMERS),
            this.createPermissionNode("Edit Sale Groups", PermissionEnum.EDIT_SALE_GROUP),
            this.createPermissionNode("Manage Sale Groups", PermissionEnum.MANAGE_SALE_GROUPS),
            this.createPermissionNode("Managa Domains", PermissionEnum.MANAGE_DOMAINS),
            this.createPermissionNode("Manage Customer Owner", PermissionEnum.MANAGE_CUSTOMER_OWNER)
        ]);

        let management: PermissionNode = this.createPermissionNode("Management", PermissionEnum.MANAGEMENT, [
            manageInventory,
            manageSales,
            manageSystem,
            manageHardware,
            manageCustomers
        ]);

        // -------------------------------------------------------------------------------------------------------------
        // MOBILE
        // -------------------------------------------------------------------------------------------------------------

        let manageMobilePermissions: PermissionNode = this.createPermissionNode("Mobile Permissions", PermissionEnum.MOBILE, [
            this.createPermissionNode("Can Change Date on Trip Selector", PermissionEnum.CAN_CHANGE_DATE_ON_TRIP_SELECTOR),
            this.createPermissionNode("Can Create Legacy Ticket Scans", PermissionEnum.CAN_CREATE_LEGACY_TICKET_SCANS),
            this.createPermissionNode("Can Do Override Scan", PermissionEnum.CAN_DO_OVERRIDE_SCAN),
            this.createPermissionNode("Can Do Mobile Sales", PermissionEnum.CAN_DO_MOBILE_SALES_QSB)
        ]);

        let mobile: PermissionNode = this.createPermissionNode("Mobile", PermissionEnum.MOBILE, [
            manageMobilePermissions
        ]);

        return [sales, reports, management, mobile];
    }

    private createPermissionNode(label: string, permission: PermissionEnum, children: PermissionNode[] = []): PermissionNode {

        let mappedPermission: MappedPermissionModel = this.getPermissionByValue(permission);

        if (!mappedPermission) {
            console.error(`PERMISSION NOT FOUND: ${permission}`);
        }

        let node: PermissionNode = new PermissionNode(PermissionTypeEnum.PERMISSION, DynamicPermissionTypeEnum.PERMISSIONS, permission, label, mappedPermission.active, children, mappedPermission.inherited);

        // Update selection (if any first-level node is checked, check parent)
        node.updateSelection(mappedPermission.active);

        return node;
    }

    private getPermissionByValue(permission: PermissionEnum): MappedPermissionModel {
        return this.data.permissions.find((p: MappedPermissionModel) => p.permission === permission);
    }

    private static convertRoleMapping(data: RoleMappingResponseModel): MappedDataModel {
        let categories: MappedCategoryModel[] = data.categories.map(c => {
            return {
                categoryId: c.categoryId,
                active: c.assigned,
                description: c.description,
                inherited: false
            };
        });

        let products: MappedProductModel[] = data.products.map(p => {
            return {
                productId: p.productId,
                categoryId: p.categoryId,
                active: p.assigned,
                description: p.description,
                inherited: false
            };
        });

        let passes: MappedPassModel[] = data.passes.map(p => {
            return {
                passId: p.passId,
                active: p.assigned,
                description: p.description,
                inherited: false
            };
        });

        let cloudPaymentDevices: MappedCloudPaymentDeviceModel[] = data.cloudPaymentDevices.map(c => {
            return {
                cloudPaymentDeviceId: c.cloudPaymentDeviceId,
                active: c.assigned,
                description: c.description,
                inherited: false
            };
        });

        let qsbProducts: MappedQuickSellingButtonProductModel[] = data.quickSellingButtonProducts.map(qsb => {
            return {
                quickSellingButtonProductId: qsb.quickSellingButtonProductId,
                active: qsb.assigned,
                description: qsb.description,
                inherited: false
            };
        });

        let qsbPasses: MappedQuickSellingButtonPassModel[] = data.quickSellingButtonPasses.map(qsb => {
            return {
                quickSellingButtonPassId: qsb.quickSellingButtonPassId,
                active: qsb.assigned,
                description: qsb.description,
                inherited: false
            };
        });

        let postBillTypes: MappedPostBillTypeModel[] = data.postBillTypes.map(pbt => {
            return {
                postBillTypeId: pbt.postBillTypeId,
                active: pbt.assigned,
                description: pbt.description,
                inherited: false
            };
        });

        let favorites: MappedFavoriteModel[] = data.favorites.map(f => {
            return {
                favoriteId: f.favoriteId,
                active: f.assigned,
                description: f.description,
                inherited: false
            };
        });

        let permissions: MappedPermissionModel[] = data.permissions.map(p => {
            return {
                permission: p.permission,
                active: p.assigned,
                inherited: false
            };
        });

        return {
            categories: categories,
            products: products,
            passes: passes,
            cloudPaymentDevices: cloudPaymentDevices,
            quickSellingButtonProducts: qsbProducts,
            quickSellingButtonPasses: qsbPasses,
            postBillTypes: postBillTypes,
            favorites: favorites,
            permissions: permissions
        };
    }

    private static convertUserMapping(data: UserMappingResponseModel): MappedDataModel {
        let categories: MappedCategoryModel[] = data.categories.map(c => {
            return {
                categoryId: c.categoryId,
                active: c.active,
                description: c.description,
                inherited: !c.override
            };
        });

        let products: MappedProductModel[] = data.products.map(p => {
            return {
                productId: p.productId,
                categoryId: p.categoryId,
                active: p.active,
                description: p.description,
                inherited: !p.override
            };
        });

        let passes: MappedPassModel[] = data.passes.map(p => {
            return {
                passId: p.passId,
                active: p.active,
                description: p.description,
                inherited: !p.override
            };
        });

        let cloudPaymentDevices: MappedCloudPaymentDeviceModel[] = data.cloudPaymentDevices.map(c => {
            return {
                cloudPaymentDeviceId: c.cloudPaymentDeviceId,
                active: c.active,
                description: c.description,
                inherited: !c.override
            };
        });

        let qsbProducts: MappedQuickSellingButtonProductModel[] = data.quickSellingButtonProducts.map(qsb => {
            return {
                quickSellingButtonProductId: qsb.quickSellingButtonProductId,
                active: qsb.active,
                description: qsb.description,
                inherited: !qsb.override
            };
        });

        let qsbPasses: MappedQuickSellingButtonPassModel[] = data.quickSellingButtonPasses.map(qsb => {
            return {
                quickSellingButtonPassId: qsb.quickSellingButtonPassId,
                active: qsb.active,
                description: qsb.description,
                inherited: !qsb.override
            };
        });

        let postBillTypes: MappedPostBillTypeModel[] = data.postBillTypes.map(pbt => {
            return {
                postBillTypeId: pbt.postBillTypeId,
                active: pbt.active,
                description: pbt.description,
                inherited: !pbt.override
            };
        });

        let favorites: MappedFavoriteModel[] = data.favorites.map(f => {
            return {
                favoriteId: f.favoriteId,
                active: f.active,
                description: f.description,
                inherited: !f.override
            };
        });

        let permissions: MappedPermissionModel[] = data.permissions.map(p => {
            return {
                permission: p.permission,
                active: p.active,
                inherited: !p.override
            };
        });

        return {
            categories: categories,
            products: products,
            passes: passes,
            cloudPaymentDevices: cloudPaymentDevices,
            quickSellingButtonProducts: qsbProducts,
            quickSellingButtonPasses: qsbPasses,
            postBillTypes: postBillTypes,
            favorites: favorites,
            permissions: permissions
        };
    }

    private findNodeById(id: string | number, permissionTree: PermissionNode[]): PermissionNode {

        let result: PermissionNode = null;

        for (let i = 0; result === null && i < permissionTree.length; i++) {
            result = this._find(id, permissionTree[i]);
        }

        return result;
    }

    private collectRolePermissions(permissions: PermissionEnum[], node: PermissionNode) {

        if (node.type === PermissionTypeEnum.PERMISSION && node.checked) {
            permissions.push(<PermissionEnum>node.id);
        }

        node.children.forEach(n => {
            this.collectRolePermissions(permissions, n);
        });
    }

    private collectUserPermissions(permissions: SelectedPermissionModel[], node: PermissionNode) {

        if (node.type === PermissionTypeEnum.PERMISSION) {
            permissions.push({
                permission: <PermissionEnum>node.id,
                active: node.checked
            });
        }

        node.children.forEach(n => {
            this.collectUserPermissions(permissions, n);
        });
    }

    private _find(id: string | number, node: PermissionNode): PermissionNode {

        if (node.id === id) {
            return node;
        } else if (node.children.length !== 0) {

            let result: PermissionNode = null;

            for (let i = 0; result === null && i < node.children.length; i++) {
                result = this._find(id, node.children[i]);
            }

            return result;
        }

        return null;
    }
}





