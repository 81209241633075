import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { SaleGroupResponseModel } from "./response/sale-group-response.model";
import { CreateSaleGroupRequestModel } from "./request/create-sale-group-request.model";
import { UpdateSaleGroupRequestModel } from "./request/update-sale-group-request.model";
import { QuickSellingButtonPassResponseModel } from "../quick-selling-button-pass/response/quick-selling-button-pass-response.model";
import { QuickSellingButtonProductResponseModel } from "../quick-selling-button-product/response/quick-selling-button-product-response.model";
import { SaleGroupQSBResponseModel } from "./response/sale-group-qsb-response.model";
import { CustomerResponseModel } from "../customer/response/customer-response.model";
import { CreateSaleGroupQSBRequestModel } from "./request/create-sale-group-qsb-request.model";
import { RemoveSaleGroupCustomerRequestModel } from "./request/remove-sale-group-customer-request.model";
import { RemoveSaleGroupQSBRequestModel } from "./request/remove-sale-group-qsb-request.model";
import { DomainResponseModel } from "../domain/response/domain-response.model";
import { RemoveSaleGroupDomainRequestModel } from "./request/remove-sale-group-domain-request.model";
import { CreateSaleGroupCustomersRequestModel } from "./request/create-sale-group-customers-request.model";
import { CreateSaleGroupDomainsRequestModel } from "./request/create-sale-group-domains-request.model";
import { CustomerSaleGroupRequestModel } from "./request/get-sale-group-customers-request.model";
import { CustomerItemResponseModel } from "../customer/response/customer-item-response.model";
import { SaleGroupSearchRequestModel } from "./request/get-sale-group-search-request.model";
@Injectable()
export class SaleGroupRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<SaleGroupResponseModel[]> {
        return this.httpClient.get<SaleGroupResponseModel[]>(environment.server_api_url + '/api/secured/saleGroup/getAll');
    }

    getAllActive(): Observable<SaleGroupResponseModel[]> {
        return this.httpClient.get<SaleGroupResponseModel[]>(environment.server_api_url + '/api/secured/saleGroup/getAllActive');
    }

    getAllDomainTypeSaleGroups(): Observable<SaleGroupResponseModel[]> {
        return this.httpClient.get<SaleGroupResponseModel[]>(environment.server_api_url + '/api/secured/saleGroup/getAllDomainTypeSaleGroups');
    }

    getById( saleGroupId: number ): Observable<SaleGroupResponseModel> {
        return this.httpClient.get<SaleGroupResponseModel>(environment.server_api_url + '/api/secured/saleGroup/getById/' + saleGroupId);
    }

    getQSBPassesForSaleGroup( saleGroupId: number ){
        return this.httpClient.get<QuickSellingButtonPassResponseModel[]>(environment.server_api_url + '/api/secured/saleGroup/getSaleGroupQuickSellingButtonPassAll/' + saleGroupId );
    }

    getCustomersForSaleGroup( saleGroupId: number ){
        return this.httpClient.get<CustomerResponseModel[]>(environment.server_api_url + '/api/secured/saleGroup/getSaleGroupCustomers/' + saleGroupId );
    }

    getDomainsForSaleGroup( domainId: number, request?: SaleGroupSearchRequestModel ){
        return this.httpClient.post<DomainResponseModel[]>(environment.server_api_url + '/api/secured/saleGroup/getSaleGroupDomains/' + domainId, request );
    }

    getCustomersForSaleGroupInRange( saleGroupCustomersRequest: CustomerSaleGroupRequestModel ){
        return this.httpClient.post<CustomerItemResponseModel[]>(environment.server_api_url + '/api/secured/saleGroup/getSaleGroupCustomersInRange', saleGroupCustomersRequest );
    }

    getQSBProductsForSaleGroup( saleGroupId: number ){
        return this.httpClient.get<QuickSellingButtonProductResponseModel[]>(environment.server_api_url + '/api/secured/saleGroup/getSaleGroupQuickSellingButtonProductAll/' + saleGroupId );
    }

    getQSBPForSaleGroup( saleGroupId: number, request?: SaleGroupSearchRequestModel ){
        return this.httpClient.post<SaleGroupQSBResponseModel>(environment.server_api_url + '/api/secured/saleGroup/getSaleGroupQuickSellingButtons/' + saleGroupId, request );
    }

    getQSBPItems(){
        return this.httpClient.get<SaleGroupQSBResponseModel>(environment.server_api_url + '/api/secured/saleGroup/getSaleGroupQuickSellingButtons' );
    }

    create( request: CreateSaleGroupRequestModel ): Observable<CreateSaleGroupRequestModel> {
        return this.httpClient.post<CreateSaleGroupRequestModel>(environment.server_api_url + '/api/secured/saleGroup/create', request);
    }

    update( request: UpdateSaleGroupRequestModel ): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/saleGroup/update', request, {responseType: 'text'});
    }

    activate( saleGroupId: number ): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/saleGroup/activate', saleGroupId, {responseType: 'text'});
    }

    deactivate( saleGroupId: number ): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/saleGroup/deactivate', saleGroupId, {responseType: 'text'});
    }

    getAllCustomers(): Observable<CustomerResponseModel[]>{
        return this.httpClient.get<CustomerResponseModel[]>(environment.server_api_url + '/api/secured/customer/getAllActive')
    }


    getAllDomains(): Observable<DomainResponseModel[]>{
        return this.httpClient.get<DomainResponseModel[]>(environment.server_api_url + '/api/secured/domain/getAllActive')
    }

    addCustomersToSaleGroup( request: CreateSaleGroupCustomersRequestModel ): Observable<CreateSaleGroupCustomersRequestModel> {
        return this.httpClient.post<CreateSaleGroupCustomersRequestModel>(environment.server_api_url + '/api/secured/saleGroup/addCustomersToSaleGroup', request);
    }

    addDomainsToSaleGroup( request: CreateSaleGroupDomainsRequestModel ): Observable<CreateSaleGroupDomainsRequestModel> {
        return this.httpClient.post<CreateSaleGroupDomainsRequestModel>(environment.server_api_url + '/api/secured/saleGroup/addDomainsToSaleGroup', request);
    }

    getCustomersNotInSaleGroupInRange( saleGroupCustomersRequest: CustomerSaleGroupRequestModel ){
        return this.httpClient.post<CustomerItemResponseModel[]>(environment.server_api_url + '/api/secured/customer/getAvailableCustomersForSaleGroup', saleGroupCustomersRequest );
    }

    addQSBItemToSaleGroup( request: CreateSaleGroupQSBRequestModel ): Observable<CreateSaleGroupQSBRequestModel> {
        return this.httpClient.post<CreateSaleGroupQSBRequestModel>(environment.server_api_url + '/api/secured/saleGroup/addQuickSellingButton', request);
    }

    removeQSBItemFromSaleGroup( request: RemoveSaleGroupQSBRequestModel ): Observable<RemoveSaleGroupQSBRequestModel> {
        return this.httpClient.post<RemoveSaleGroupQSBRequestModel>(environment.server_api_url + '/api/secured/saleGroup/removeQuickSellingButton', request);
    }

    removeCustomerFromSaleGroup( request: RemoveSaleGroupCustomerRequestModel ): Observable<RemoveSaleGroupCustomerRequestModel> {
        return this.httpClient.post<RemoveSaleGroupCustomerRequestModel>(environment.server_api_url + '/api/secured/saleGroup/removeCustomerFromSaleGroup', request);
    }

    removeDomainFromSaleGroup( request: RemoveSaleGroupDomainRequestModel ): Observable<RemoveSaleGroupDomainRequestModel> {
        return this.httpClient.post<RemoveSaleGroupDomainRequestModel>(environment.server_api_url + '/api/secured/saleGroup/removeDomainFromSaleGroup', request);
    }

}
