import {PassProductResponseModel} from "../../../../../../core/modules/rest/pass/response/pass-product-response.model";

export class ProductDataModel {
    productId: number;
    productDescription: string;
    active: boolean;

    constructor(passProduct: PassProductResponseModel) {
        this.productId = passProduct.productId;
        this.productDescription = passProduct.productDescription;
        this.active = passProduct.active;
    }
}
