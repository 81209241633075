import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { NotificationResponseModel } from "../../../../../../../../../core/modules/rest/notification/response/notification-response.model";
import { NotificationFormSubmitDataModel } from "../../model/notification-form-submit-data.model";
import { DropdownOptionModel } from '../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model';
import { NotificationTypeEnum, getDescription as NotificationTypeEnum_getDescription } from '../../../../../../../../../shared/enums/notification-type.enum';
import { PlatformEnum, getDescription as PlatformEnum_getDescription } from '../../../../../../../../../shared/enums/platform.enum';
import moment from 'moment';
import { DateTimeUtility } from '../../../../../../../../../shared/utils/date-time-utility';
import { MultiselectDropdownOptionModel, OPTION_ALL } from '../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model';
import { forkJoin } from 'rxjs';
import { SaleGroupRestService } from '../../../../../../../../../core/modules/rest/sale-group/sale-group-rest.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorModel } from '../../../../../../../../../core/modules/rest/http-error.model';
import { CustomerItemResponseModel } from '../../../../../../../../../core/modules/rest/customer/response/customer-item-response.model';
import { RootSandbox } from '../../../../../../../../../core/store/root.sandbox';
import { UserResponseModel } from '../../../../../../../../../core/modules/rest/user/response/user-response.model';
import { UserRestService } from '../../../../../../../../../core/modules/rest/user/user-rest.service';
import { EmailHtmlTemplateRestService } from '../../../../../../../../../core/modules/rest/email-html-template/email-html-template-rest.service';
import { EmailHtmlTemplateResponseModel } from '../../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model';
import { NotificationBaseUserResponseModel } from '../../../../../../../../../core/modules/rest/notification/response/notification-base-user-response.model';


@Component({
  selector: 'app-edit-notification-form',
  templateUrl: './edit-notification-form.component.html'
})
export class EditNotificationFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() notification: NotificationResponseModel;
    @Input() notificationBaseUser: NotificationBaseUserResponseModel[];

    @Output() submitEvent = new EventEmitter<NotificationFormSubmitDataModel>();
    @Output() closeEvent  = new EventEmitter<void>();

    form: FormGroup;
    notificationOptions: DropdownOptionModel[] = this.initNotificationOptions();
    platformOptions: DropdownOptionModel[] = this.initPlatformOptions();
    errorMessage: string;
    customerOptions: MultiselectDropdownOptionModel[] = [];
    areCustomersLoading: boolean;
    isFormValid: boolean = false;
    customers: any;
    userOptions: MultiselectDropdownOptionModel[] = [];
    areUsersLoading: boolean;
    baseUserOptions: MultiselectDropdownOptionModel[] = [];
    baseUserIds: any[] = [];
    users: any;
    templateOptions: DropdownOptionModel[] = [];
    areTemplatesLoading: boolean;
    templateId: number;
    showNotificationSetup: boolean = true;
    showEndDate: boolean = true;
    saveSentNotification: boolean = true;
    showToDateTime: boolean = true;
    validation = {
        subject: {
          error: false,
          label: ""
        },
        notificationType: {
            error: false,
            label: ""
        },
        description: {
            error: false,
            label: ""
        },
        template: {
            error: false,
            label: ""
        },
        platform: {
            error: false,
            label: ""
        },
        fromDateTime: {
            error: false,
            label: ""
        },
        toDateTime: {
            error: false,
            label: ""
        },
        users: {
            error: false,
            label: ""
        }
      };

    constructor(private saleGroupRestService: SaleGroupRestService, private rootSandbox: RootSandbox, private userRestService: UserRestService, private emailHtmlTemplateRestService: EmailHtmlTemplateRestService) {
    }

    ngOnChanges(changes: SimpleChanges): void {


        if (this.editMode && changes.notification && changes.notification.currentValue && Object.keys(changes.notification.currentValue).length !== 0) {
            const loadedNotification: NotificationResponseModel = changes.notification.currentValue;

            this.form.patchValue({
                subject: loadedNotification.subject,
                description: loadedNotification.description,
                notificationType: loadedNotification.notificationType,
                platform: loadedNotification.platform,
                fromDateTime: loadedNotification.fromDateTime != null ? moment([loadedNotification.fromDateTime[0], loadedNotification.fromDateTime[1] - 1, loadedNotification.fromDateTime[2], loadedNotification.fromDateTime[3], loadedNotification.fromDateTime[4]]) : loadedNotification.fromDateTime,
                toDateTime: loadedNotification.toDateTime != null ? moment([loadedNotification.toDateTime[0], loadedNotification.toDateTime[1] - 1, loadedNotification.toDateTime[2], loadedNotification.toDateTime[3], loadedNotification.toDateTime[4]]) : loadedNotification.toDateTime,
                sendEmail: loadedNotification.sendEmail,
                templateId: loadedNotification.templateId,
            });
            const currentDate: Date = new Date();
            var currentDateMoment = moment(currentDate);
            if(this.form.value.toDateTime) {
                var formattedToDateTime = this.form.value.toDateTime.format('YYYY-MM-DDTHH:mm');
                var formattedCurrentDate = currentDateMoment.format('YYYY-MM-DDTHH:mm');
                if(formattedToDateTime <= formattedCurrentDate) {
                    this.saveSentNotification = false;
                    this.showToDateTime = false;
                }
            }
            var formattedFromDateTime = this.form.value.fromDateTime.format('YYYY-MM-DDTHH:mm');
            var formattedCurrentDate = currentDateMoment.format('YYYY-MM-DDTHH:mm');
            if(formattedFromDateTime <= formattedCurrentDate) {
                this.showNotificationSetup = false;
                return;
            }

            if (loadedNotification.platform == "WEB_APPLICATION" || loadedNotification.platform == "ALL_PLATFORMS") {
                this.onSelectedUsersChanged(loadedNotification.baseUsers.map(base => base.baseUserId.toString()));
            }

            if (loadedNotification.platform == "WEBSITE") {
                this.onSelectedCustomersChanged(loadedNotification.baseUsers.map(base => base.baseUserId.toString()));
            }
        }
    }

    ngOnInit() {

        let subject;
        let description;
        let notificationType;
        let platform;
        let fromDateTime;
        let toDateTime;
        let sendEmail = false;
        let templateId;

        if (this.editMode && this.notification) {
            subject = this.notification.subject;
            description = this.notification.description;
            notificationType = this.notification.notificationType;
            platform = this.notification.platform;
            fromDateTime = this.notification.fromDateTime;
            toDateTime = this.notification.toDateTime;
            sendEmail = this.notification.sendEmail;
            templateId = this.notification.templateId;
        }

        let customerId: number = 0;
        let userId: number = 0;

        this.form = new FormGroup({
            subject: new FormControl(subject),
            description: new FormControl(description),
            notificationType: new FormControl(notificationType),
            platform: new FormControl(platform),
            fromDateTime: new FormControl(fromDateTime),
            toDateTime: new FormControl(toDateTime),
            sendEmail: new FormControl(sendEmail),
            customerId: new FormControl(customerId),
            customer: new FormControl(),
            userId: new FormControl(userId),
            user: new FormControl(),
            templateId: new FormControl(templateId)
        });
        this.areCustomersLoading = true;

        forkJoin([
            this.saleGroupRestService.getAllCustomers().pipe(
                catchError((error: HttpErrorModel) => {
                    this.areCustomersLoading = false;
                    return this.rootSandbox.handleHttpError('Error while loading Customers', error);
                })
            ),
        ]).subscribe(([customers]: [CustomerItemResponseModel[]]) => {
            this.customers = customers.sort((a, b) => a.email.localeCompare(b.email));
            this.customerOptions = this.customers
            .map((customer: CustomerItemResponseModel) => new MultiselectDropdownOptionModel(customer.displayName + ' - ' + customer.email, customer.customerId.toString()));
            this.areCustomersLoading = false;
            this.updateUserAndCustomerOptions();
        });

        this.areUsersLoading = true;

        forkJoin([
            this.userRestService.getAllActive().pipe(
                catchError((error: HttpErrorModel) => {
                    this.areUsersLoading = false;
                    return this.rootSandbox.handleHttpError('Error while loading Users', error);
                })
            ),
        ]).subscribe(([users]: any) => {
            this.users = users.users.sort((a, b) => a.email.localeCompare(b.email));
            this.userOptions = this.users
            .map((user: UserResponseModel) => new MultiselectDropdownOptionModel(user.displayName + ' - ' + user.email, user.userId.toString()));
            this.areUsersLoading = false;
            this.updateUserAndCustomerOptions();
        });

        this.areTemplatesLoading = true;

        forkJoin([
            this.emailHtmlTemplateRestService.getAllNotificationsEmailHtmlTemplates().pipe(
                catchError((error: HttpErrorModel) => {
                    this.areTemplatesLoading = false;
                    return this.rootSandbox.handleHttpError('Error while loading Templates', error);
                })
            ),
        ]).subscribe(([templates]: any) => {
            this.templateOptions = templates.sort((a, b) => a.description.localeCompare(b.description))
            .map((template: EmailHtmlTemplateResponseModel) => new DropdownOptionModel(template.description, template.emailHtmlTemplateId.toString()));
            this.areTemplatesLoading = false;
        });

        this.form.valueChanges.subscribe(() => {
            this.isFormValid = this.validateForm();
        });
    }

    updateUserAndCustomerOptions(): void {
        this.baseUserOptions = [...(this.userOptions || []), ...(this.customerOptions || [])];
    }

    resetValidationErrors() {
        this.validation = {
            subject: {
              error: false,
              label: ""
            },
            notificationType: {
                error: false,
                label: ""
            },
            description: {
                error: false,
                label: ""
            },
            template: {
                error: false,
                label: ""
            },
            platform: {
                error: false,
                label: ""
            },
            fromDateTime: {
                error: false,
                label: ""
            },
            toDateTime: {
                error: false,
                label: ""
            },
            users: {
                error: false,
                label: ""
            }
          };;
    }

    validateForm() {
        this.resetValidationErrors();
        if(!this.form.value.subject) {
            this.validation.subject = {
                error: true,
                label: "Please enter Subject."
            };
            return false;
        }
        if(this.form.value.subject.trim().length == 0) {
            this.validation.subject = {
                error: true,
                label: "Please enter Subject."
            };
            return false;
        }
        if(!this.form.value.notificationType) {
            this.validation.notificationType = {
                error: true,
                label: "Please select Notification Type."
            };
            return false;
        }
        if(this.form.value.notificationType == "TEXT" && !this.form.value.description) {
            this.validation.description = {
                error: true,
                label: "Please enter Description."
            };
            return false;
        }
        if(this.form.value.notificationType == "TEMPLATE" && !this.form.value.templateId) {
            this.validation.template = {
                error: true,
                label: "Please select Template."
            };
            return false;
        }
        if(!this.form.value.platform) {
            this.validation.platform = {
                error: true,
                label: "Please select Platform."
            };
            return false;
        }
        if(!this.form.value.fromDateTime) {
            this.validation.fromDateTime = {
                error: true,
                label: "Please select From Date Time."
            };
            return false;
        }
        const currentDate: Date = new Date();
        var currentDateMoment = moment(currentDate);
        var formattedFromDateTime = this.form.value.fromDateTime.format('YYYY-MM-DDTHH:mm');
        var formattedCurrentDate = currentDateMoment.format('YYYY-MM-DDTHH:mm');
        if(formattedFromDateTime <= formattedCurrentDate) {
            this.validation.fromDateTime = {
                error: true,
                label: "From Date Time can't be in past."
            };
            return false;
        }
        if(this.form.value.toDateTime && this.form.value.toDateTime <= this.form.value.fromDateTime) {
            this.validation.toDateTime = {
                error: true,
                label: "Start Time must be before End Time."
            };
            return false;
        }
        if(this.form.value.platform == "WEB_APPLICATION" && this.baseUserIds.length == 0) {
            this.validation.users = {
                error: true,
                label: "Please select Users."
            };
            return false;
        }
        if(this.form.value.platform == "ALL_PLATFORMS" && this.baseUserIds.length == 0) {
            this.validation.users = {
                error: true,
                label: "Please select Users or Customers."
            };
            return false;
        }
        return true;
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onToDateTimeChange() {
        this.resetValidationErrors();
        if(this.form.value.toDateTime && this.form.value.toDateTime <= this.form.value.fromDateTime) {
            this.validation.toDateTime = {
                error: true,
                label: "Start Time must be before End Time."
            };
            this.saveSentNotification = false;
            return false;
        }
        this.saveSentNotification = true;
    }

    onSubmitClick() {

        const subject = this.form.value.subject
        const description = this.form.value.description;
        const notificationType = this.form.value.notificationType;
        const platform = this.form.value.platform;
        const fromDateTime = this.form.value.fromDateTime != null ? DateTimeUtility.getDateTimeStringFromMoment(this.form.value.fromDateTime) : this.form.value.fromDateTime;
        const toDateTime = this.form.value.toDateTime != null ? DateTimeUtility.getDateTimeStringFromMoment(this.form.value.toDateTime) : this.form.value.toDateTime;
        const sendEmail = this.form.value.sendEmail;
        const baseUserIds = this.baseUserIds.filter(bsid => bsid !== OPTION_ALL);
        const templateId = this.form.value.templateId;
        const notificationData: NotificationFormSubmitDataModel = new NotificationFormSubmitDataModel(subject, description, notificationType, platform, fromDateTime, toDateTime, sendEmail, baseUserIds, templateId);
        this.submitEvent.emit(notificationData);
    }

    onSelectedCustomersChanged(values: string[]) {
        this.baseUserIds = values;
    }

    onSelectedUsersChanged(values: string[]) {
        this.baseUserIds = values;
        this.isFormValid = this.validateForm();
    }

    onTemplateChange(value: number) {
        this.templateId = value;
    }

    onNotificationTypeChange(value: string) {
        if (value=="TEXT") {
            this.form.get('templateId').setValue(null);
        }
    }

    onPlatformChange(value: number) {
        this.baseUserIds = [];
    }

    initNotificationOptions(): DropdownOptionModel[] {

        return Object.values(NotificationTypeEnum).map((enumValue: NotificationTypeEnum) => {
            return new DropdownOptionModel(NotificationTypeEnum_getDescription(enumValue), enumValue);
        });
    }

    initPlatformOptions(): DropdownOptionModel[] {
        const filteredPlatforms = [PlatformEnum.WEBSITE, PlatformEnum.WEB_APPLICATION, PlatformEnum.ALL_PLATFORMS];

        return filteredPlatforms.map((enumValue: PlatformEnum) => {
            return new DropdownOptionModel(PlatformEnum_getDescription(enumValue), enumValue);
        });
    }
}
