<div class="category-and-product-dialog-content">
    <h1 class="mb-8px">Edit Product Validity</h1>
    <form class="pl-8px pr-8px" [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="order-form-item">
                    <label class="order-label">One Way Validity (Days)</label>
                    <div class="order-input-wrapper">
                        <input type="text" placeholder="Enter number of days" formControlName="maximalDaysValidFor">
                    </div>
                </div>
                <div class="order-form-item" *ngIf="product.isRoundTripProduct">
                    <label class="order-label">Round Trip Validity (Days)</label>
                    <div class="order-input-wrapper">
                        <input type="text" placeholder="Enter number of days" formControlName="maximalDaysValidForRoundTrip">
                    </div>
                </div>
            </div>
        </ng-container>

        <p *ngIf="hasError" class="error">{{ errorMessage }}</p>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Update</button>
    </div>
</div>
