
<!-- Header banner and dropdown Navigation  -->
<div (clickOutside)="_closeMenu()"
     appKeypressEscDirective
     (escKeypress)="_closeMenu()">
    <header class="header_container">
        <div class="header_content">

            <!-- Menu Bar :: BEGIN -->
            <a class="menuButton_wrapper"
                (click)="_openMenu()"
                [ngClass]="{'menu_open': isNavOpen}">

                <div class="menuButton_container">
                    <span class="menuIcon_bar bar1"></span>
                    <span class="menuIcon_bar bar2"></span>
                    <span class="menuIcon_bar bar3"></span>
                    <span class="menuIcon_bar bar4"></span>
                </div>
            </a><!-- Menu Bar:: BEGIN -->

            <!-- Logo :: BEGIN -->
            <a class="logo_wrapper" href="/">
                <div class="logo_container">
                    <img class='logo_image' src="{{ssLogo}}" alt="Smart Stubs Logo">
                </div>
            </a><!-- Logo :: END -->

            <app-breadcrumbs [items]="pageBreadcrumbItems"> </app-breadcrumbs>

            <!-- Widgets :: BEGIN -->
            <div class="widgets_wrapper">
                <div class="widgets_container">


                    <!--Help :: BEGIN-->
                    <div class="widgetItem_wrapper">
                        <div class="widgetItem_container">
                            <a class="widgetHelp_container widgetItem_content" href="https://support.smartstubs.com" target="_blank">
                                <div class="iconImage_container">
                                    <i class="fal fa-question-circle"></i>
                                </div>
                            </a>
                        </div>
                    </div><!--Help :: END-->



                    <!--Notification :: BEGIN-->
                    <div class="widgetItem_wrapper">
                        <div class="widgetItem_container widgetNotification_wrapper ml-5px"
                        (clickOutside)= "_clickedOutsideNotificationDropdown()">
                            <a class="widgetItem_content widgetNotification_container"
                                (click)="_openNotificationDropdown()"
                                [ngClass]="{ 'widgetItem_active': isNotificationDropdownOpen,
                                            'hasNewNotification': !isNotificationDropdownOpen && newNotificationAlert }">
                                <div class="iconImage_container">
                                    <i class="fal fa-bell"></i>
                                    <span *ngIf="!isNotificationDropdownOpen && unreadNotificationsCounter > 0 && unreadNotificationsCounter <= 99" class="bell-counter">{{unreadNotificationsCounter}}</span>
                                    <span *ngIf="!isNotificationDropdownOpen && unreadNotificationsCounter > 99" class="bell-counter">99+</span>
                                </div>
                            </a>

                            <!-- Dropdown :: BEGIN -->
                            <div *ngIf='isNotificationDropdownOpen '
                                class="headerDropdown_wrapper headerNotificationDropdown_wrapper">
                                <app-header-notification-dropdown (clickedOutsideNotificationDropdown)="_clickedOutsideNotificationDropdown()" (getNotificationState)="getNotificationState($event)" [notificationHistoryResults]="notificationHistoryResults"></app-header-notification-dropdown>
                            </div><!-- Dropdown :: END -->
                        </div>
                    </div><!--Notification :: END-->




                    <!--User :: BEGIN-->
                    <div class="widgetItem_wrapper userWidget_wrapper">
                        <div class="widgetItem_container ml-5px"
                            (clickOutside)="_clickedOutsideUserDropdown()">
                            <a class="user_container widgetItem_content"
                            [ngClass]="{'widgetItem_active': isUserDropdownOpen}"
                            (click)="_openUserDropdown()">

                                <div class="iconImage_container">
                                    <i class="fal fa-user-circle"></i>
                                </div>
                            </a>
                            <div *ngIf="isUserDropdownOpen"
                                class="headerDropdown_wrapper headerUserMenuDropdown_wrapper">
                                <app-header-user-menu-dropdown></app-header-user-menu-dropdown>
                            </div>
                        </div>
                    </div><!--User :: END-->

                </div>
            </div><!-- Widgets :: END -->

            <!-- Search :: BEGIN -->
            <div class="search_wrapper">
                <div class="searchGroup_wrapper"
                     [ngClass]="{'open-search': isSearchOpen }"
                     (clickOutside)="_clickedOutsideSearch($event)">
                    <div class="searchGroup_container is-inverted">
                        <input
                            #searchInput
                            class="searchGroup_input z-index-2"
                            type="text"
                            placeholder="Find Order"
                            [disabled]="searchDisabled"
                            [(ngModel)]="criteria"
                            [value]="criteria"
                            (keyup.enter)="_runSearch()"
                            (input)="_onSearchChanged()">

                            <div class="searchGroup_input iconImage_container" [ngClass]="{'showIcon': !isLoading, 'removeIcon': isLoading}">
                                <i (click)="_openDropDown()" class="fal fa-chevron-down searchGroup_input_icon z-index-3 cursorPointer mt-18px"></i>
                            </div>

                        <div class="searchGroup_iconWrapper">
                            <a class="widgetItem_content"
                               [ngClass]="{'widgetItem_active': isSearchOpen}"
                               (click)="_openSearch()">

                                <div class="iconImage_container" [ngClass]="{'showIcon': !isLoading, 'removeIcon': isLoading}">
                                    <i class="fal fa-search"></i>
                                </div>

                            </a>
                            <a class="search-loader-icon-wrapper" [ngClass]="{'showIcon': isLoading, 'removeIcon': !isLoading}">
                                <div class="iconImage_container">
                                    <i class="fas fa-circle-notch fa-spin"></i>
                                </div>
                            </a>

                        </div>

                        <ng-container *ngIf="multipleResults === true">
                            <div class="searchGroupResultsDropdown_wrapper showSearchResults">
                                <ul class="searchResultsList_container">
                                    <li class="searchResultLi" *ngFor="let item of result">
                                        <a class="searchResultLabel searchResultLink"
                                           (click)="showSummaryFromDropDown(item)">
                                            {{ item.orderExternalId }} - {{item.createDate[1]}}/{{item.createDate[2]}}/{{item.createDate[0]}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="noResults === true">
                            <div class="searchGroupResultsDropdown_wrapper showSearchResults">
                                <ul class="searchResultsList_container">
                                    <li class="searchResultLi">
                                        <span class="searchResultLabel">
                                            No results matching your search were found.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="isDropDownOpen">
                            <div class="searchGroupResultsDropdown_wrapper showSearchResults">
                                <ul class="searchResultsList_container">
                                    <div class="ml-10px" *ngIf="orderSearchHistoryResults.length == 0"> No Search Results</div>
                                    <li class="searchResultLi cursorPointer" *ngFor="let item of orderSearchHistoryResults">
                                        <span (click)="_runSearchFromDropDown(item.searchTerm)" class="searchResultLabelDropDown">
                                            {{item.searchTerm}}
                                        </span>
                                        <span class="searchResultIconDropDown">
                                            <i (click)="_deleteOrderSearchHistory(item.orderSearchHistoryId)"  class="fal fa-times"></i>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </header>


    <nav class="navigation_wrapper"
        *ngIf='isNavOpen'
        [@navigationState]>
        <app-dropdown-nav (closeNavEvent)="_closeMenu()"></app-dropdown-nav>
    </nav>
</div>
