import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {AndroidDeviceInfoResponseModel} from "./response/android-device-info-response.model";
import {CreateAndroidDeviceInfoRemoteCommandRequestModel} from "../android-device-info/request/create-android-device-info-remote-command-request.model";
import {CreateBaseResponseModel} from "../create-base-response.model";
import { AndroidDeviceInfoRequestModel } from './request/android-device-info-request.model';

@Injectable()
export class AndroidDeviceInfoRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<AndroidDeviceInfoResponseModel[]> {
        return this.httpClient.get<AndroidDeviceInfoResponseModel[]>(environment.server_api_url + '/api/secured/androidDeviceInfo/getAll');
    }

    getAllInRange(request: AndroidDeviceInfoRequestModel): Observable<AndroidDeviceInfoResponseModel[]> {
        return this.httpClient.post<AndroidDeviceInfoResponseModel[]>(environment.server_api_url + '/api/secured/androidDeviceInfo/getAllInRange', request);
    }

    createAndroidDeviceInfoRemoteCommand(data: CreateAndroidDeviceInfoRemoteCommandRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/androidDeviceInfo/createAndroidDeviceInfoRemoteCommand', data);
    }

    getQrCode(): Observable<any> {
        return this.httpClient.get<any>(environment.server_api_url + '/api/secured/androidDeviceInfo/getQrCode');
    }
}

