import { catchError } from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { DialogSandbox } from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import { AppDialogsService } from "../../../../../../../../core/services/app-dialogs.service";
import { Store } from "@ngrx/store";
import { RootSandbox } from "../../../../../../../../core/store/root.sandbox";
import { CreateBaseResponseModel } from "../../../../../../../../core/modules/rest/create-base-response.model";
import { HttpErrorModel } from "../../../../../../../../core/modules/rest/http-error.model";
import { OverrideReasonResponseModel } from '../../../../../../../../core/modules/rest/over-ride-reason/response/over-ride-reason-response.model';
import { OverrideReasonRestService } from '../../../../../../../../core/modules/rest/over-ride-reason/override-reason-rest.service';
import { CreateOverrideReasonRequestModel } from '../../../../../../../../core/modules/rest/over-ride-reason/request/create-override-reason-request.model';
import { UpdateOverrideRequestRequestModel } from '../../../../../../../../core/modules/rest/over-ride-reason/request/update-override-reason-request.model';

@Injectable()
export class EditOverrideReasonDialogSandbox extends DialogSandbox {

    overrideReason$: Observable<OverrideReasonResponseModel> = this.store.select(reducer.override_reason_selector);

    constructor(appDialogsService: AppDialogsService,
        private overrideReasonRestService: OverrideReasonRestService,
        private store: Store<reducer.State>,
        private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadOverrideReason(overrideReasonId: number) {

        this.showLoader();

        this.overrideReasonRestService.getById(overrideReasonId).subscribe((overrideReason: OverrideReasonResponseModel) => {
            this.store.dispatch(new actions.SetOverrideReason(overrideReason));
            this.hideLoader();
        },
            () => this.hideLoader());
    }

    createOverrideReason(overrideReason: string): Observable<CreateBaseResponseModel> {
        const request = new CreateOverrideReasonRequestModel(overrideReason);

        return this.overrideReasonRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while creating override reason", error);
            }));
    }

    updateOverrideReason(overrideReasonId: number, overrideReason: string): Observable<any> {
        const request = new UpdateOverrideRequestRequestModel(overrideReasonId, overrideReason);

        return this.overrideReasonRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while updating override reason", error);
            }));
    }
}
