
import {of as observableOf, empty as observableEmpty, Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {SharedSandbox} from '../shared.sandbox';
import {AppRoutesService} from '../../core/services/app-routes.service';
import {HttpErrorModel} from '../../core/modules/rest/http-error.model';
import {catchError, flatMap, mergeMap, tap} from "rxjs/operators";
import { UserInfoResponseModel } from '../../core/modules/rest/user/response/user-info-response.model';
import { SystemOptionsResponseModel } from '../../core/modules/rest/system-options/response/system-options-response.model';

@Injectable()
export class PreloadUserDataGuard implements CanActivate {

    constructor(private sharedSandbox: SharedSandbox, private appRoutesService: AppRoutesService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.sharedSandbox.isUserLoggedIn().pipe(
            catchError((error: HttpErrorModel) => {
                this.appRoutesService.goToLogoutPage(false);
                return throwError(error);
            }),
            flatMap((loggedIn: boolean) => {

                if (!loggedIn) {
                    this.appRoutesService.goToLogoutPage(false);
                    return observableEmpty();
                }

                return this.loadData();
            })
        );
    }

    private loadData(): Observable<boolean> {
        let userInfo: UserInfoResponseModel;
        let systemOptions: SystemOptionsResponseModel;

        return observableOf(true).pipe(
            mergeMap(() => {
                return this.sharedSandbox.loadUserInfo().pipe(
                    tap(info => userInfo = info)
                );
            }),
            mergeMap(() => {
                return this.sharedSandbox.loadSystemOptions().pipe(
                    tap(info => systemOptions = info)
                );
            }),
            tap(() => {
                this.sharedSandbox.webSocketConnect(userInfo.userId, systemOptions.companyCode);
            }),
            mergeMap(() => {
                return observableOf(true);
            })
        );
    }
}
