import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {MultiselectDropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

export const REDUCER_NAME = 'dialog_mapping-cost';

export interface State {
    loading: boolean;
    categories: MultiselectDropdownOptionModel[];
    mappedCategories: number[];
    products: MultiselectDropdownOptionModel[];
    mappedProducts: number[];
    passes: MultiselectDropdownOptionModel[];
    mappedPasses: number[];
}

const INITIAL_STATE: State = {
    loading: false,
    categories: [],
    mappedCategories: [],
    products: [],
    mappedProducts: [],
    passes: [],
    mappedPasses: []
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const loading_selector = createSelector(state_selector, (state: State) => state.loading);
export const categories_selector = createSelector(state_selector, (state: State) => state.categories);
export const mappedCategories_selector = createSelector(state_selector, (state: State) => state.mappedCategories);
export const products_selector = createSelector(state_selector, (state: State) => state.products);
export const mappedProducts_selector = createSelector(state_selector, (state: State) => state.mappedProducts);
export const passes_selector = createSelector(state_selector, (state: State) => state.passes);
export const mappedPasses_selector = createSelector(state_selector, (state: State) => state.mappedPasses);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;

        case actions.SET_LOADING:
            return {...state, loading: action.loading};

        case actions.SET_CATEGORIES:
            return {...state, categories: action.categories};

        case actions.SET_MAPPED_CATEGORIES:
            return {...state, mappedCategories: action.mappedCategories};

        case actions.SET_PRODUCTS:
            return {...state, products: action.products};

        case actions.SET_MAPPED_PRODUCTS:
            return {...state, mappedProducts: action.mappedProducts};

        case actions.SET_PASSES:
            return {...state, passes: action.passes};

        case actions.SET_MAPPED_PASSES:
            return {...state, mappedPasses: action.mappedPasses};

        default:
            return state;
    }
}
