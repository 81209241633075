import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DialogData} from "../../../model/dialog.data";
import {Sandbox} from "../sandbox/sandbox";


// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class YesNoDialogData extends DialogData {

    header: string;
    message: string;
    yesLabel: string;
    noLabel: string;
    buttonType: string;

    callbackFn: (yesClicked: boolean) => void;

    constructor(header: string, message: string, noLabel: string = 'No', yesLabel: string = 'Yes', buttonType: string = 'primary', callbackFn: (yesClicked: boolean) => void) {
        super();
        this.header = header;
        this.message = message;
        this.noLabel = noLabel;
        this.yesLabel = yesLabel;
        this.buttonType = buttonType;
        this.callbackFn = callbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
// Component that represents it
// -------------------------------------------------------------------------------------------------
@Component({
    selector: 'app-yes-no-dialog',
    templateUrl: './yes-no-dialog.component.html'
})
export class YesNoDialogComponent {

    header: string;
    message: string;
    noLabel: string;
    yesLabel: string;
    buttonType: string;

    // Function that gets called once yes or no button has been clicked
    callbackFn: (yesClicked: boolean) => void;

    constructor(@Inject(DialogData) dialogData: YesNoDialogData, private sandbox: Sandbox) {

        this.header = dialogData.header;
        this.message = dialogData.message;
        this.noLabel = dialogData.noLabel;
        this.yesLabel = dialogData.yesLabel;
        this.buttonType = dialogData.buttonType;
        this.callbackFn = dialogData.callbackFn;
    }

    onNoClicked() {
        this.callbackFn(false);
        this.sandbox.destroyDialog();
    }

    onYesClicked() {
        this.callbackFn(true);
        this.sandbox.destroyDialog();
    }

    onCloseClicked() {
        this.callbackFn(false);
        this.sandbox.destroyDialog();
    }
}
