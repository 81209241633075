import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";
import {LocationFormSubmitDataModel} from "../../model/location-form-submit-data.model";
import {GoogleMapsLatLng} from "../../../../../../../../../shared/components/input-google-maps-lat-lng/input-google-maps-lat-lng.component";
import {GoogleMapsLocation} from "../../../../../../../../../shared/components/input-google-maps-autocomplete/input-google-maps-autocomplete.component";


@Component({
    selector: 'app-edit-location-form',
    templateUrl: './edit-location-form.component.html'
})
export class EditLocationFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() location: LocationResponseModel;

    @Output() submitEvent = new EventEmitter<LocationFormSubmitDataModel>();
    @Output() closeEvent  = new EventEmitter<void>();

    form: FormGroup;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.form && this.editMode && changes.location !== null && Object.keys(changes.location.currentValue).length !== 0) {

            let googleMapsLatLng: GoogleMapsLatLng = {
                lat: null,
                lng: null
            };
            if (changes.location.currentValue.latitude !== null && changes.location.currentValue.latitude !== undefined && changes.location.currentValue.longitude !== null && changes.location.currentValue.longitude !== undefined) {
                googleMapsLatLng = {
                    lat: changes.location.currentValue.latitude,
                    lng: changes.location.currentValue.longitude
                };
            }

            this.form.patchValue({
                description: changes.location.currentValue.description,
                zipCode: changes.location.currentValue.zipCode,
                googleMapsLatLng: googleMapsLatLng
            });
        }
    }

    ngOnInit() {

        let description: string = "";
        let zipCode: string = "";
        let googleMapsLatLng: GoogleMapsLatLng = {
            lat: null,
            lng: null
        };

        if (this.editMode) {
            description = this.location.description;
            zipCode = this.location.zipCode;
            if (this.location.latitude !== null && this.location.latitude !== undefined && this.location.longitude !== null && this.location.longitude !== undefined) {
                googleMapsLatLng = {
                    lat: this.location.latitude,
                    lng: this.location.longitude
                };
            }
        }

        this.form = new FormGroup({
            description: new FormControl(description, Validators.required),
            zipCode: new FormControl(zipCode),
            googleMapsLatLng: new FormControl(googleMapsLatLng)
        });
    }

    onGoogleMapsLocationChanged(googleMapsLocation: GoogleMapsLocation) {

        this.form.patchValue({
            zipCode: googleMapsLocation.zipCode,
            googleMapsLatLng: {
                lat: googleMapsLocation.latitude,
                lng: googleMapsLocation.longitude
            }
        });
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {
        const description: string = this.form.value.description;
        const zipCode: string = this.form.value.zipCode;
        const latitude: number = this.form.value.googleMapsLatLng && this.form.value.googleMapsLatLng.lat !== null && this.form.value.googleMapsLatLng.lat !== undefined && this.form.value.googleMapsLatLng.lat.toString().trim().length > 0 && !isNaN(this.form.value.googleMapsLatLng.lat)
            ? +this.form.value.googleMapsLatLng.lat
            : null;
        const longitude: number = this.form.value.googleMapsLatLng && this.form.value.googleMapsLatLng.lng !== null && this.form.value.googleMapsLatLng.lng !== undefined && this.form.value.googleMapsLatLng.lng.toString().trim().length > 0 && !isNaN(this.form.value.googleMapsLatLng.lng)
            ? +this.form.value.googleMapsLatLng.lng
            : null;

        this.submitEvent.emit({
            description: description,
            zipCode: zipCode,
            latitude: latitude,
            longitude: longitude
        });
    }
}
