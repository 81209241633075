export class ConnectMerchantDialogFormSubmitDataModel {

    merchantId: number;
    androidDeviceInfos: string[];

    constructor(androidDeviceInfoId: number, androidDeviceInfos: string[]) {
        this.merchantId = androidDeviceInfoId;
        this.androidDeviceInfos = androidDeviceInfos;
    }
}
