import {NgModule} from "@angular/core";
import {CloneProductAvailabilitiesDialogSandbox} from "./sandbox/clone-product-availabilities-dialog.sandbox";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {CloneProductAvailabilitiesDialogFormComponent} from './components/clone-product-availabilities-dialog-form/clone-product-availabilities-dialog-form.component';
import {CloneProductAvailabilitiesDialogComponent} from "./clone-product-availabilities-dialog.component";
import {CloneProductAvailabilityDialogFormComponent} from "./components/clone-product-availabilities-dialog-form/clone-product-availability-dialog-form/clone-product-availability-dialog-form.component";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    declarations: [
        CloneProductAvailabilitiesDialogComponent,
        CloneProductAvailabilitiesDialogFormComponent,
        CloneProductAvailabilityDialogFormComponent
    ],
    providers: [
        CloneProductAvailabilitiesDialogSandbox
    ],
    exports: [
        CloneProductAvailabilitiesDialogComponent
    ],
    entryComponents: [
        CloneProductAvailabilitiesDialogComponent
    ]
})
export class CloneProductAvailabilitiesDialogModule {
}
