import * as actions from './actions';

export interface State {
    availableFieldsLoading: boolean;
    activeReportGroups: string[];
}

const initialState: State = {
    availableFieldsLoading: false,
    activeReportGroups: []
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.AVAILABLE_FIELDS_LOADING_START_ACTION:
            return handleAvailableFieldsLoadingStartAction(state, action);
        case actions.AVAILABLE_FIELDS_LOADING_END_ACTION:
            return handleAvailableFieldsLoadingEndAction(state, action);
        case actions.CHANGE_REPORT_GROUP_ACTIVITY_STATUS_ACTION:
            return handleChangeReportGroupActivityStatusAction(state, action);
        default:
            return state;
    }
}


// #################################################### SELECTORS ####################################################
export const availableFieldsLoading_selector = (state: State) => state.availableFieldsLoading;
export const activeReportGroups_selector = (state: State) => state.activeReportGroups;


// #################################################### HANDLERS ####################################################
function handleAvailableFieldsLoadingStartAction(state: State, action: actions.AvailableFieldsLoadingStart) {
    return {
        ...state,
        availableFieldsLoading: true
    };
}

function handleAvailableFieldsLoadingEndAction(state: State, action: actions.AvailableFieldsLoadingEnd) {
    return {
        ...state,
        availableFieldsLoading: false
    };
}

function handleChangeReportGroupActivityStatusAction(state: State, action: actions.ChangeReportGroupActivityStatus) {

    const index = state.activeReportGroups.indexOf(action.name);

    let newActiveReportGroups;
    if (index !== -1) {
        newActiveReportGroups = [
            ...state.activeReportGroups.slice(0, index),
            ...state.activeReportGroups.slice(index + 1)
        ];
    } else {
        newActiveReportGroups = [
            ...state.activeReportGroups,
            action.name
        ];
    }

    return {
        ...state,
        activeReportGroups: newActiveReportGroups
    };
}
