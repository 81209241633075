import {Component, EventEmitter, Input, Output} from '@angular/core';
import _ from 'lodash';
import {KioskConfigurationDropoffLocationPassTierModel} from "../../model/kiosk-configuration-dropoff-location-pass-tier.model";
import {TierUpdatedData} from "../../../product-tier-setup-dialog/components/product-tier-dialog-tier-item/product-tier-dialog-item.component";

@Component({
    selector: 'app-pass-tier-setup-dialog-form',
    templateUrl: './pass-tier-setup-dialog-form.component.html'
})
export class PassTierSetupDialogFormComponent {

    @Input() passTiers: KioskConfigurationDropoffLocationPassTierModel[];
    @Input() description: string;

    @Output() submitEvent = new EventEmitter<KioskConfigurationDropoffLocationPassTierModel[]>();
    @Output() closeEvent = new EventEmitter<void>();

    errorMessage: string = null;
    disabledSaveButton: boolean = false;

    constructor() {
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        this.errorMessage = null;

        for (let passTier of this.passTiers) {
            if (passTier.minQuantity) {
                if (passTier.defaultQuantity < passTier.minQuantity) {
                    this.errorMessage = "Default quantity must be greater than or equal to min quantity";
                    return;
                }
            }

            if (passTier.maxQuantity) {
                if (passTier.defaultQuantity > passTier.maxQuantity) {
                    this.errorMessage = "Default quantity must be less than or equal to max quantity";
                    return;
                }
            }

            if (passTier.minQuantity > passTier.maxQuantity) {
                this.errorMessage = "Min quantity must be less than or equal to max quantity";
                return;
            }
        }

        // Get only tiers that have default quantity
        this.passTiers = this.passTiers.filter((passTier) => {
            return passTier.defaultQuantity !== null && passTier.defaultQuantity >= 0;
        });

        this.submitEvent.emit(this.passTiers);
    }

    defaultTierQuantityChanged(data: TierUpdatedData) {
        const passTier: KioskConfigurationDropoffLocationPassTierModel = this.getPassTierById(data.tierId);
        passTier.defaultQuantity = Number(data.value);
        this.updatePassTierInArray(passTier);
    }

    minTierQuantityChanged(data: TierUpdatedData) {
        const passTier: KioskConfigurationDropoffLocationPassTierModel = this.getPassTierById(data.tierId);
        passTier.minQuantity = Number(data.value);
        this.updatePassTierInArray(passTier);
    }

    maxTierQuantityChanged(data: TierUpdatedData) {
        const passTier: KioskConfigurationDropoffLocationPassTierModel = this.getPassTierById(data.tierId);
        passTier.maxQuantity = Number(data.value);
        this.updatePassTierInArray(passTier);
    }

    private getPassTierById(tierId: number): KioskConfigurationDropoffLocationPassTierModel {
        return _.find(this.passTiers, (passTier: KioskConfigurationDropoffLocationPassTierModel) => passTier.tierId === tierId);
    }

    private updatePassTierInArray(newPassTier: KioskConfigurationDropoffLocationPassTierModel) {
        const index = _.findIndex(this.passTiers, (passTier: KioskConfigurationDropoffLocationPassTierModel) => passTier.tierId === newPassTier.tierId);

        this.passTiers = [
            ... this.passTiers.slice(0, index),
            newPassTier,
            ... this.passTiers.slice(index + 1)
        ];
    }
}
