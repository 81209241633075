import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditLetterGroupDialogComponent} from "./container/edit-letter-group-dialog.component";
import {EditLetterGroupDialogSandbox} from "./sandbox/edit-letter-group-dialog.sandbox";
import {StoreModule} from "@ngrx/store";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import { EditLetterGroupFormComponent } from './components/edit-letter-group-form/edit-letter-group-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditLetterGroupDialogSandbox
    ],
    declarations: [
        EditLetterGroupDialogComponent,
        EditLetterGroupFormComponent
    ],
    exports: [
        EditLetterGroupDialogComponent
    ],
    entryComponents: [
        EditLetterGroupDialogComponent
    ]
})
export class EditLetterGroupDialogModule {

}
