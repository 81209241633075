import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {PassCardRestService} from "../../../../../../../../core/modules/rest/pass-card/pass-card-rest.service";
import {Observable} from "rxjs";

@Injectable()
export class EditPassCardsDialogSandbox extends DialogSandbox {

    constructor(appDialogsService: AppDialogsService,
                private passCardRestService: PassCardRestService) {
        super(appDialogsService);
    }

    resetState() {
    }

    updatePassCards(passCardIds: number[], remainingQuantity: number): Observable<void> {
        return this.passCardRestService.updatePassCards({
            passCardIds: passCardIds,
            remainingQuantity: remainingQuantity
        });
    }
}
