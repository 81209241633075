import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {DroppedFilterDataModel} from '../../../model/dropped-filter-data.model';
import {DraggedDataI} from '../../../model/dragged-data.model';
import {DroppedDataI} from '../../../model/dropped-data.model';
import {DroppedReportItemDataModel} from '../../../model/dropped-report-item-data.model';
import {DraggedReportItemDataModel} from '../../../model/dragged-report-item-data.model';
import {DroppedGroupDataModel} from '../../../model/dropped-group-data.model';
import {DraggedFilterDataModel} from '../../../model/dragged-filter-data.model';
import {DraggedGroupDataModel} from '../../../model/dragged-group-data.model';
import {MousePointerOverDropAreaService} from '../../../service/mouse-pointer-over-drop-area.service';
import {Observable} from 'rxjs';
import {MAX_DEPTH} from '../../../model/data';
import {detect} from 'detect-browser';

const browser = detect();



@Component({
    selector: 'app-order-reports-filter-drop-area',
    templateUrl: './order-reports-filter-drop-area.component.html',
    styleUrls: ['./order-reports-filter-drop-area.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReportsFilterDropAreaComponent implements OnInit {

    @Input() enabled: boolean;
    @Input() visible: boolean = false;
    @Input() itemDepth: number;
    @Input() draggedItemHeight: number;
    @Input() parentGroupUUID: string;
    @Input() leftSiblingUUID: string;
    @Output() droppedDataEvent: EventEmitter<DroppedDataI> = new EventEmitter<DroppedDataI>();
    @Output() deleteExpressionItemEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() dragEndEvent: EventEmitter<void> = new EventEmitter<void>();

    maxDepth = MAX_DEPTH;
    pointerNear = false;
    mouseOverDropArea$: Observable<boolean>;
    browserNotSupportingDragNDropMousePosition;

    constructor(private mouseOverDropAreaService: MousePointerOverDropAreaService) {
        this.mouseOverDropArea$ = mouseOverDropAreaService.getMousePointerOverDropArea();
    }

    ngOnInit() {
        this.browserNotSupportingDragNDropMousePosition = false;
        if (browser && browser.name === 'firefox') {
            this.browserNotSupportingDragNDropMousePosition = true;
            this.pointerNear = true;
        }
    }

    onPointerNear(value) {
        if (this.browserNotSupportingDragNDropMousePosition) {
            this.pointerNear = true;
        } else {
            this.pointerNear = value;
        }
    }

    onDrop(data: any) {
        const draggedData: DraggedDataI = data.dragData;

        let droppedData: DroppedDataI;
        let deleteItemUUID;
        let dragEndEmit = false; // This is used because drop event was not fired by drag-and-drop library
        switch (draggedData.constructor) {
            case DraggedReportItemDataModel:
                const draggedReportItemData: DraggedReportItemDataModel = <DraggedReportItemDataModel> draggedData;
                deleteItemUUID = null;
                droppedData = new DroppedReportItemDataModel(this.parentGroupUUID, this.leftSiblingUUID, draggedReportItemData.reportItem);
                break;
            case DraggedFilterDataModel:
                const draggedFilterData: DraggedFilterDataModel = <DraggedFilterDataModel> draggedData;
                deleteItemUUID = draggedFilterData.uuid;
                droppedData = new DroppedFilterDataModel(this.parentGroupUUID, this.leftSiblingUUID, draggedFilterData.filterItem);
                dragEndEmit = true;
                break;
            case DraggedGroupDataModel:
                const draggedGroupData: DraggedGroupDataModel = <DraggedGroupDataModel> draggedData;
                deleteItemUUID = draggedGroupData.uuid;
                droppedData = new DroppedGroupDataModel(this.parentGroupUUID, this.leftSiblingUUID, draggedGroupData.items);
                dragEndEmit = true;
                break;
            default:
                console.error('OrderReportsFilterDropAreaComponent :: Unexpected dragged data type');
                return;
        }

        this.droppedDataEvent.emit(droppedData);

        if (deleteItemUUID) {
            this.deleteExpressionItemEvent.emit(deleteItemUUID);
        }

        if (dragEndEmit) {
            this.dragEndEvent.emit();
        }
    }

    onDragOver() {
        this.mouseOverDropAreaService.setMousePointerOverDropArea(true);
    }

    onDragLeave() {
        this.mouseOverDropAreaService.setMousePointerOverDropArea(false);
    }
}
