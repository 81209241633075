
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {ProductRestService} from "../../../../../../../../core/modules/rest/product/product-rest.service";
import {EmailHtmlTemplateRestService} from "../../../../../../../../core/modules/rest/email-html-template/email-html-template-rest.service";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {ProductSimpleResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {DropdownOptionModel} from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {SubmitProductEmailHtmlTemplatesData} from "../components/edit-product-email-html-templates-dialog-form/edit-product-email-html-templates-dialog-form.component";
import {EmailHtmlTemplateTypeEnum} from "../../../../../../../../shared/enums/email-html-template-type.enum";

@Injectable()
export class EditProductEmailHtmlTemplatesDialogSandbox extends DialogSandbox {

    product$: Observable<ProductSimpleResponseModel> = this.store.pipe(select(reducer.product_selector));
    createOrderEmailHtmlTemplates$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.createOrderEmailHtmlTemplates_selector));
    voidOrderEmailHtmlTemplates$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.voidOrderEmailHtmlTemplates_selector));
    termsAndConditionsEmailHtmlTemplates$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.termsAndConditionsEmailHtmlTemplates_selector));

    constructor(appDialogsService: AppDialogsService,
                private productRestService: ProductRestService,
                private emailHtmlTemplateRestService: EmailHtmlTemplateRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(productId: number) {

        observableCombineLatest(this.productRestService.getProductSimpleById(productId), this.emailHtmlTemplateRestService.getAllActive()).pipe(
            take(1))
            .subscribe(([product, emailHtmlTemplates]: [ProductSimpleResponseModel, EmailHtmlTemplateResponseModel[]]) => {
                this.store.dispatch(new actions.SetProduct(product));
                this.store.dispatch(new actions.SetCreateOrderEmailHtmlTemplates(emailHtmlTemplates.filter(eht => eht.emailHtmlTemplateType === EmailHtmlTemplateTypeEnum.CREATE_ORDER).map(eht => new DropdownOptionModel(eht.description, eht.emailHtmlTemplateId.toString()))));
                this.store.dispatch(new actions.SetVoidOrderEmailHtmlTemplates(emailHtmlTemplates.filter(eht => eht.emailHtmlTemplateType === EmailHtmlTemplateTypeEnum.VOID_ORDER).map(eht => new DropdownOptionModel(eht.description, eht.emailHtmlTemplateId.toString()))));
                this.store.dispatch(new actions.SetTermsAndConditionsEmailHtmlTemplates(emailHtmlTemplates.filter(eht => eht.emailHtmlTemplateType === EmailHtmlTemplateTypeEnum.TERMS_AND_CONDITIONS).map(eht => new DropdownOptionModel(eht.description, eht.emailHtmlTemplateId.toString()))));
            });
    }

    updateProductEmailHtmlTemplates(data: SubmitProductEmailHtmlTemplatesData): Observable<any> {

        return this.productRestService.updateProductEmailHtmlTemplates({
            productId: data.productId,
            createOrderEmailHtmlTemplateId: data.createOrderEmailHtmlTemplateId,
            voidOrderEmailHtmlTemplateId: data.voidOrderEmailHtmlTemplateId,
            termsAndConditionsEmailHtmlTemplateId: data.termsAndConditionsEmailHtmlTemplateId
        });
    }
}
