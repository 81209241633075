import {Action} from "@ngrx/store";
import {ProductCostResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-cost-response.model";
import {MultiselectDropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

export const RESET_STATE = '[Dialog - Edit Product Costs :: MAIN] Reset State';
export const SET_LOADING = '[Dialog - Edit Product Costs :: MAIN] Loading';
export const SET_PRODUCT_ADDITIONAL_COSTS = '[Dialog - Edit Product Costs :: MAIN] Set Product Additional Costs';
export const SET_ADDITIONAL_COSTS = '[Dialog - Edit Product Costs :: MAIN] Set Additional Costs';
export const SET_PRODUCT_INCLUDED_COSTS = '[Dialog - Edit Product Costs :: MAIN] Set Product Included Costs';
export const SET_INCLUDED_COSTS = '[Dialog - Edit Product Costs :: MAIN] Set Included Costs';
export const SET_PRODUCT_DISCOUNTS = '[Dialog - Edit Product Costs :: MAIN] Set Product Discounts';
export const SET_DISCOUNTS = '[Dialog - Edit Product Costs :: MAIN] Set Discounts';
export const SET_PRODUCT_AUTO_APPLIED_DISCOUNTS = '[Dialog - Edit Product Costs :: MAIN] Set Product Auto Applied Discounts';
export const SET_AUTO_APPLIED_DISCOUNTS = '[Dialog - Edit Product Costs :: MAIN] Set Auto Applied Discounts';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetLoading implements Action {
    readonly type = SET_LOADING;
    constructor(public loading: boolean) {}
}

export class SetProductAdditionalCosts implements Action {
    readonly type = SET_PRODUCT_ADDITIONAL_COSTS;

    constructor(public productAdditionalCosts: ProductCostResponseModel[]) {
    }
}

export class SetAdditionalCosts implements Action {
    readonly type = SET_ADDITIONAL_COSTS;

    constructor(public additionalCosts: MultiselectDropdownOptionModel[]) {
    }
}

export class SetProductIncludedCosts implements Action {
    readonly type = SET_PRODUCT_INCLUDED_COSTS;

    constructor(public productIncludedCosts: ProductCostResponseModel[]) {
    }
}

export class SetIncludedCosts implements Action {
    readonly type = SET_INCLUDED_COSTS;

    constructor(public includedCosts: MultiselectDropdownOptionModel[]) {
    }
}

export class SetProductDiscounts implements Action {
    readonly type = SET_PRODUCT_DISCOUNTS;

    constructor(public productDiscounts: ProductCostResponseModel[]) {
    }
}

export class SetDiscounts implements Action {
    readonly type = SET_DISCOUNTS;

    constructor(public discounts: MultiselectDropdownOptionModel[]) {
    }
}

export class SetProductAutoAppliedDiscounts implements Action {
    readonly type = SET_PRODUCT_AUTO_APPLIED_DISCOUNTS;

    constructor(public productAutoAppliedDiscounts: ProductCostResponseModel[]) {
    }
}

export class SetAutoAppliedDiscounts implements Action {
    readonly type = SET_AUTO_APPLIED_DISCOUNTS;

    constructor(public autoAppliedDiscounts: MultiselectDropdownOptionModel[]) {
    }
}

export type All = ResetState
    | SetLoading
    | SetProductAdditionalCosts
    | SetAdditionalCosts
    | SetProductIncludedCosts
    | SetIncludedCosts
    | SetProductDiscounts
    | SetDiscounts
    | SetProductAutoAppliedDiscounts
    | SetAutoAppliedDiscounts;
