import {Action} from "@ngrx/store";
import {CommissionResponseModel} from "../../../../../../../../../core/modules/rest/commission/response/commission-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Commission :: MAIN] Reset State';
export const SET_COMMISSION_ACTION = '[Dialog - Create/Edit Commission :: MAIN] Set Commission';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetCommission implements Action {
    readonly type = SET_COMMISSION_ACTION;
    constructor(public commission: CommissionResponseModel) {}
}

export type All = ResetState | SetCommission;
