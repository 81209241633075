import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {AuthorizeTransactionRequestModel} from "./request/authorize-transaction-request.model";
import {AuthorizeTransactionResponseModel} from "./response/authorize-transaction-response.model";
import {CsrfTokenResponseModel} from "./response/csrf-token-response.model";

@Injectable()
export class CreditCardPaymentRestService {

    constructor(private httpClient: HttpClient) {
    }

    getCsrfToken(): Observable<CsrfTokenResponseModel> {
        return this.httpClient.get<CsrfTokenResponseModel>(environment.server_api_url + '/api/secured/creditCardPayment/getCsrfToken');
    }

    authorizeTransaction(csrfToken: string, companyCode: string, authorizeTransactionRequest: AuthorizeTransactionRequestModel): Observable<AuthorizeTransactionResponseModel> {

        const httpOptions = {
            headers: new HttpHeaders({
                'X-CSRF-Token':  csrfToken,
                'X-Company-Code': companyCode
            })
        };

        return this.httpClient.post<AuthorizeTransactionResponseModel>(environment.payment_api_url + '/api/public/authorizeTransaction', authorizeTransactionRequest, httpOptions);
    }
}
