<div class="dialog-section">
    <h2>Product Details</h2>

    <ng-container *ngIf="!orderDetails || orderDetails.length === 0">
        <div class="placeholderText-emptySection">
            No Product Info to Display
        </div>
    </ng-container>

    <ng-container *ngIf="orderDetails && orderDetails.length !== 0">
        <ng-container *ngFor="let orderDetail of orderDetails">
            <app-product-detail-dialog-summary
                [allowFixedTimeForFlexibleAndMultipleCheckInProducts]="allowFixedTimeForFlexibleAndMultipleCheckInProducts"
                [startTimesLoading]="startTimesForEditedOrderDetailLoading"
                [startTimes]="startTimesForEditedOrderDetail"
                [product]="products[orderDetail.productId]"
                [orderDetail]="orderDetail"
                [editEnabled]="isEditEnabled(orderDetail.orderDetailId)"
                [hasEditPermission]="hasEditPermission"
                [hasCompletedEditPermission]="hasCompletedEditPermission"
                (saveClicked)="onSaveClicked($event)"
                (editClicked)="onEditClick($event)"
                (cancelClicked)="onCancelClick()"
                (noShowClicked)="onNoShowClicked($event)"
                (selectedDateChanged)="onSelectedDateChanged($event)"
                [ngClass]="[orderDetail.orderStatus === 'CANCELLED' ? 'canceled-details' : '']">
            </app-product-detail-dialog-summary>
        </ng-container>
    </ng-container>
</div>
