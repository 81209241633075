export class CreateKioskDeviceInfoRequestModel {
    description: string;
    kioskDeviceNumber: string;
    kioskDeviceUniqueIdentifier: string;
    kioskSupportEmails: string;
    kioskSupportPhones: string;
    soldById: number;
    termsAndConditionsEmailHtmlTemplateId: number;
    kioskConfigurationId: number;
    kioskSenderEmail: string;

    constructor(description: string, kioskDeviceNumber: string, kioskDeviceUniqueIdentifier: string, kioskSupportEmails: string, kioskSupportPhones: string, soldById: number, termsAndConditionsEmailHtmlTemplateId: number, kioskConfigurationId: number, kioskSenderEmail: string) {
        this.description = description;
        this.kioskDeviceNumber = kioskDeviceNumber;
        this.kioskDeviceUniqueIdentifier = kioskDeviceUniqueIdentifier;
        this.kioskSupportEmails = kioskSupportEmails;
        this.kioskSupportPhones = kioskSupportPhones;
        this.soldById = soldById;
        this.termsAndConditionsEmailHtmlTemplateId = termsAndConditionsEmailHtmlTemplateId;
        this.kioskConfigurationId = kioskConfigurationId;
        this.kioskSenderEmail = kioskSenderEmail;
    }
}
