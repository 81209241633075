<app-abstract-dialog-container>
    <app-edit-notification-form
        [editMode]="editMode"
        [notification]="notification$ | async"
        [notificationBaseUser]="notificationBaseUser$ | async"
        (submitEvent)="onSubmit($event)"
        (closeEvent)="onClose()">
    </app-edit-notification-form>
</app-abstract-dialog-container>


