import {Action} from '@ngrx/store';
import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";
import {DiscountCostModel} from "../../model/discount-cost.model";


export const UPDATE_DIALOG_DATA_LOADED_ACTION = '[Dialog Edit Discount] Update dialog data loaded';
export const UPDATE_EDIT_DISCOUNT_ACTION = '[Dialog Edit Discount] Update edit discount';

export const RESET_STATE_ACTION = '[Dialog Edit Discount] Reset state';

export const UPDATE_DISCOUNT_ACTION = '[Dialog Edit Discount] Update discount';
export const UPDATE_ALL_LOCATIONS_ACTION = '[Dialog Edit Discount] Update all locations';


export class UpdateDialogDataLoaded implements Action {
    readonly type = UPDATE_DIALOG_DATA_LOADED_ACTION;

    constructor(public value: boolean) {
    }
}

export class UpdateEditDiscount implements Action {
    readonly type = UPDATE_EDIT_DISCOUNT_ACTION;

    constructor(public value: boolean) {
    }
}

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class UpdateDiscount implements Action {
    readonly type = UPDATE_DISCOUNT_ACTION;

    constructor(public discount: DiscountCostModel) {
    }
}

export class UpdateAllLocations implements Action {
    readonly type = UPDATE_ALL_LOCATIONS_ACTION;

    constructor(public locations: LocationResponseModel[]) {
    }
}


export type Actions =
    UpdateDialogDataLoaded |
    ResetState |
    UpdateDiscount |
    UpdateAllLocations |
    UpdateEditDiscount;
