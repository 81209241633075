import {DialogSandbox} from "../../../services/dialog.sandbox";
import {Injectable} from "@angular/core";
import {AppDialogsService} from "../../../../../core/services/app-dialogs.service";

@Injectable()
export class Sandbox extends DialogSandbox {

    constructor(appDialogsService: AppDialogsService) {
        super(appDialogsService);
    }

    resetState() {
        // no state to reset
    }
}
