import {Action} from "@ngrx/store";
import {TreeItemTypeEnum} from "../../model/tree-item-type.enum";
import {TreeNode} from "../../../../../../../shared/components/tree/tree.component";
import {PermissionNode} from "../../model/permission-node";
import {BasicInformationDataModel} from "../../model/basic-information-data.model";
import { PermissionItemNode } from "../../../../../../../shared/components/tree-permission/tree-permission.component";

export const RESET_STATE = "[Manage Roles And Users :: MAIN] Reset State";
export const SET_SELECTED_ITEM = "[Manage Roles And Users :: MAIN] Set Selected Item";
export const SET_SELECTED_NODE = "[Manage Roles And Users :: MAIN] Set Selected Node";
export const SET_ROLE_OR_USER_FOR_EDIT = "[Manage Roles And Users :: MAIN] Set Role Or User For Edit";
export const SET_ROLES_AND_USERS_TREE = "[Manage Roles And Users :: MAIN] Set Roles And Users";
export const SET_BASIC_INFO = "[Manage Roles And Users :: MAIN] Set Basic Information";
export const SET_PERMISSION_TREE = "[Manage Roles And Users :: MAIN] Set Permission Tree";
export const SET_PERMISSION_TREE_MATERIAL = "[Manage Roles And Users :: MAIN] Set Permission Tree Material";
export const SET_LOADING_ROLES_AND_USERS = "[Manage Roles And Users :: MAIN] Loading Roles And Users";
export const SET_LOADING_PERMISSIONS_TREE = "[Manage Roles And Users :: MAIN] Loading Permissions Tree";
export const SET_USER_ROLE_PERMISSION_TREE = "[Manage Users And Roles:: MAIN] Set User Role Permission Tree";
export const SET_SHOW_INACTIVE_ITEMS = "[Manage Roles And Users :: MAIN] Show Inactive Items";
export const SET_FORM_HAS_CHANGES   = "[Manage Roles And Users :: MAIN] Set Form Has Changes";

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetSelectedItem implements Action {
    readonly type = SET_SELECTED_ITEM;

    constructor(public item: TreeNode) {
    }
}

export class SetSelectedNode implements Action {
    readonly type = SET_SELECTED_NODE;

    constructor(public node: TreeNodeMaterial) {
    }
}

export class SetRoleOrUserForEdit implements Action {
    readonly type = SET_ROLE_OR_USER_FOR_EDIT;

    constructor(public itemType: TreeItemTypeEnum, public itemId: number, public itemActive: boolean) {
    }
}

export class SetRolesAndUsersTree implements Action {
    readonly type = SET_ROLES_AND_USERS_TREE;

    constructor(public tree: TreeNode[]) {
    }
}

export class SetBasicInfo {
    readonly type = SET_BASIC_INFO;

    constructor(public info: BasicInformationDataModel) {
    }
}

export class SetPermissionTree implements Action {
    readonly type = SET_PERMISSION_TREE;

    constructor(public tree: PermissionNode[]) {
    }
}

export class SetPermissionTreeMaterial implements Action {
    readonly type = SET_PERMISSION_TREE_MATERIAL;

    constructor(public treeMaterial: PermissionItemNode[]) {
    }
}

export class SetLoadingRolesAndUsers implements Action {
    readonly type = SET_LOADING_ROLES_AND_USERS;

    constructor(public loading: boolean) {
    }
}

export class SetLoadingPermissionsTree implements Action {
    readonly type = SET_LOADING_PERMISSIONS_TREE;

    constructor(public loading: boolean) {
    }
}

export class SetShowInactiveItems implements Action {
    readonly type = SET_SHOW_INACTIVE_ITEMS;

    constructor(public showInactiveItems: boolean) {
    }
}

export class SetUserRolePermissionTree implements Action {
    readonly type = SET_USER_ROLE_PERMISSION_TREE;

    constructor(public tree: PermissionNode[]) {
    }
}

export class SetFormHasChanges implements Action {
    readonly type = SET_FORM_HAS_CHANGES;

    constructor(public data: boolean) {
    }
}

export type All = ResetState
    | SetSelectedItem
    | SetSelectedNode
    | SetRoleOrUserForEdit
    | SetRolesAndUsersTree
    | SetPermissionTree
    | SetPermissionTreeMaterial
    | SetBasicInfo
    | SetLoadingRolesAndUsers
    | SetLoadingPermissionsTree
    | SetShowInactiveItems
    | SetUserRolePermissionTree
    |SetFormHasChanges;
