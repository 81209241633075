import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditBidDialogComponent} from "./container/edit-bid-dialog.component";
import {EditBidDialogSandbox} from "./sandbox/edit-bid-dialog.sandbox";
import {StoreModule} from "@ngrx/store";
import {REDUCER_NAME, reducer} from './sandbox/store/reducer';
import {EditBidFormComponent} from './components/edit-bid-form/edit-bid-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditBidDialogSandbox
    ],
    exports: [
        EditBidDialogComponent
    ],
    declarations: [
        EditBidFormComponent,
        EditBidDialogComponent
    ],
    entryComponents: [
        EditBidDialogComponent
    ]
})
export class EditBidDialogModule {

}
