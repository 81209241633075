import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {EditLetterGroupDialogSandbox} from "../sandbox/edit-letter-group-dialog.sandbox";
import {LetterGroupResponseModel} from "../../../../../../../../core/modules/rest/letter-group/response/letter-group-response.model";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditLetterGroupDialogData extends DialogData {

    letterGroupId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, letterGroupId?: number) {
        super();
        this.letterGroupId = letterGroupId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
  selector: 'app-edit-letter-group-dialog',
  templateUrl: './edit-letter-group-dialog.component.html'
})
export class EditLetterGroupDialogComponent implements OnInit, OnDestroy {

    letterGroupId: number;
    letterGroup$: Observable<LetterGroupResponseModel>;

    editMode: boolean = false;

    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditLetterGroupDialogData, private sandbox: EditLetterGroupDialogSandbox) {
        this.letterGroupId = dialogData.letterGroupId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
        if (this.letterGroupId && !isNaN(this.letterGroupId)) {
            this.editMode = true;
            this.sandbox.loadDepartureGroup(this.letterGroupId);
            this.letterGroup$ = this.sandbox.letterGroup$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(description: string) {
        if (this.editMode) {
            this.sandbox.updateLetterGroup(this.letterGroupId, description).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createLetterGroup(description).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }

}
