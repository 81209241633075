import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DialogData } from "../../../../../../../../../shared/dialogs/model/dialog.data";
import { AddAndroidDeviceInfoRemoteCommandDialogSandbox } from "../../sandbox/add-android-device-info-remote-command-dialog.sandbox";
import { AddAndroidDeviceInfoRemoteCommandDialogFormSubmitDataModel } from "../../model/add-android-device-info-remote-command-dialog-form-submit-data.model";
import { MechantResponseModel } from '../../../../../../../../../core/modules/rest/merchants/response/merchant-response.model';
import { ConnectMerchantDialogFormSubmitDataModel } from '../../model/connect-merchant-account-dialog-form-submit-data.model';

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class ConnectMerchantAndroidDeviceInfoDialogData extends DialogData {

    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void ) {
        super();
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-connect-merchant-dialog',
    templateUrl: './connect-merchant-dialog.component.html'
})
export class ConnectMerchantAndroidDeviceInfoDialogDataDialogComponent implements OnInit, OnDestroy {
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: ConnectMerchantAndroidDeviceInfoDialogData,
        private sandbox: AddAndroidDeviceInfoRemoteCommandDialogSandbox) {

        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: ConnectMerchantDialogFormSubmitDataModel) {

        this.sandbox.connectAndroidDevicesToMerchantAccount(data).subscribe(() => {
            this.createCallbackFn();
            this.sandbox.destroyDialog();
        });
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
