import { UUID } from "angular2-uuid";

export class ShoppingCartItemDataEqualityModel {

    readonly guid: string;

    constructor() {
        this.guid = UUID.UUID();
    }

    isEqualTo(sci: ShoppingCartItemDataEqualityModel) {
        return this.guid === sci.guid;
    }
}
