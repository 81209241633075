import {Action} from "@ngrx/store";
import {OccurrenceTicketsResponseModel} from "../../../../../../../../../core/modules/rest/occurrence/response/occurrence-tickets-response.model";

export const SET_OCCURRENCE_TICKETS = "[Dialog :: Occurrence Manifest] Set Occurrence Tickets";

export class SetOccurrenceTickets implements Action {
    readonly type = SET_OCCURRENCE_TICKETS;

    constructor(public occurrenceTickets: OccurrenceTicketsResponseModel) {
    }
}

export type All = SetOccurrenceTickets;
