import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {
    MultiselectDropdownOptionModel,
    OPTION_ALL
} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import {PassProductResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-product-response.model";

export interface SubmitPassProductsData {
    passId: number;
    productIds: number[];
}


@Component({
    selector: 'app-edit-pass-products-dialog-form',
    templateUrl: './edit-pass-products-dialog-form.component.html'
})

export class EditPassProductsDialogFormComponent implements OnChanges {

    @Input() passId: number;
    @Input() passProducts: PassProductResponseModel[];
    @Input() products: MultiselectDropdownOptionModel[];

    @Output() submit: EventEmitter<SubmitPassProductsData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    constructor() {

        this.form = new FormGroup({
            productIds: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.passProducts) {
            this.initializeFormData();
        }
    }

    onSubmit() {

        let productIds: number[] = this.form.value.productIds.filter(pid => pid !== OPTION_ALL).map(pid => +pid);

        this.submit.emit({
            passId: this.passId,
            productIds: productIds
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {
        let productIds: number[] = this.passProducts.map(pp => pp.productId);
        this.form.patchValue({
            productIds: productIds
        });
    }
}
