import { Component, OnInit  } from '@angular/core';


@Component({
  selector: 'app-tab-container',
  templateUrl: './tab-container.component.html'
})
export class TabContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
