import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import _ from 'lodash';
import * as moment from 'moment';
import {Moment} from 'moment';
import {FilterItemModel} from '../../../../../../model/filter-item.model';
import {DropdownOptionModel} from '../../../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model';
import {DateRangeModel} from "../../../../../../../../../../../shared/model/date-range.model";


const DATE_FORMAT = 'MM/DD/YY';

const DATE_CUSTOM = 'custom';
const DATE_RECENT = 'recent';
const DATE_YESTERDAY = 'yesterday';
const DATE_TODAY = 'today';
const DATE_TOMORROW = 'tomorrow';
const DATE_LAST_WEEK = 'last_week';
const DATE_THIS_WEEK = 'this_week';
const DATE_NEXT_WEEK = 'next_week';
const DATE_NEXT_30_DAYS = 'next_30_days';
const DATE_LAST_MONTH = 'last_month';
const DATE_THIS_MONTH = 'this_month';
const DATE_NEXT_MONTH = 'next_month';
const DATE_LAST_YEAR = 'last_year';
const DATE_THIS_YEAR = 'this_year';
const DATE_NEXT_YEAR = 'next_year';

const DATE_CUSTOM_LABEL = 'Custom';

const OPTION_CUSTOM = new DropdownOptionModel(DATE_CUSTOM_LABEL, DATE_CUSTOM, DATE_CUSTOM_LABEL);
const OPTION_RECENT = new DropdownOptionModel('Recent', DATE_RECENT);
const OPTION_YESTERDAY = new DropdownOptionModel('Yesterday', DATE_YESTERDAY);
const OPTION_TODAY = new DropdownOptionModel('Today', DATE_TODAY);
const OPTION_TOMORROW = new DropdownOptionModel('Tomorrow', DATE_TOMORROW);
const OPTION_LAST_WEEK = new DropdownOptionModel('Last Week', DATE_LAST_WEEK);
const OPTION_THIS_WEEK = new DropdownOptionModel('This Week', DATE_THIS_WEEK);
const OPTION_NEXT_WEEK = new DropdownOptionModel('Next Week', DATE_NEXT_WEEK);
const OPTION_NEXT_30_DAYS = new DropdownOptionModel('Next 30 Days', DATE_NEXT_30_DAYS);
const OPTION_LAST_MONTH = new DropdownOptionModel('Last Month', DATE_LAST_MONTH);
const OPTION_THIS_MONTH = new DropdownOptionModel('This Month', DATE_THIS_MONTH);
const OPTION_NEXT_MONTH = new DropdownOptionModel('Next Month', DATE_NEXT_MONTH);
const OPTION_LAST_YEAR = new DropdownOptionModel('Last Year', DATE_LAST_YEAR);
const OPTION_THIS_YEAR = new DropdownOptionModel('This Year', DATE_THIS_YEAR);
const OPTION_NEXT_YEAR = new DropdownOptionModel('Next Year', DATE_NEXT_YEAR);

@Component({
    selector: 'app-filter-date',
    templateUrl: './filter-date.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterDateComponent implements OnInit, OnChanges {

    @Input() filterItem: FilterItemModel; // Used in ngOnChanges
    @Input() hasRunOrderReportFullPermission: boolean;
    @Output() changed: EventEmitter<string[]> = new EventEmitter<string[]>();
    @ViewChild('dateTimePicker', { read: ElementRef }) dateTimePicker: ElementRef;

    listOfFilterOptions: DropdownOptionModel[];

    hadInitialValue: boolean;
    selectedOptionValue: string = null;
    openedOnInit = false;
    isCustomOption: boolean = false;

    fromDate: Moment;
    toDate: Moment;
    private previouslySelectedOptionValue = null;

    constructor(private element: ElementRef<HTMLElement>) {
    }

    ngOnInit() {
        // Check if default data is available
        if (this.filterItem.data && this.filterItem.data.length !== 0) {
            this.openedOnInit = false;

            const data: string[] = this.filterItem.data;

            this.selectedOptionValue = this.getValueFromDataArray(true);

            if (this.selectedOptionValue === DATE_CUSTOM) {
                this.fromDate = this.getDateFromTimeObject(JSON.parse(data[0]));
                this.toDate = this.getDateFromTimeObject(JSON.parse(data[1]));
                // this.createFilterRequestFromStartAndEndDate();
            } else {
                // this.createFilterRequestFromPredefinedValue();
            }
        } else {
            // this.selectedOptionValue = DATE_TODAY;
            // this.createFilterRequestFromPredefinedValue();
        }

        this.initializeDateOptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterItem && changes.filterItem.firstChange) {
            const initialValue = this.getValueFromDataArray(false);
            this.hadInitialValue = initialValue !== null;
        }
    }

    dropdownOptionSelected(selectedValue: string) {
        this.previouslySelectedOptionValue = this.selectedOptionValue;
        this.selectedOptionValue = selectedValue;
        if (selectedValue === DATE_CUSTOM) {
            this.isCustomOption = true;
            let leftPositionDateFilter = this.element.nativeElement.getBoundingClientRect().left;
            this.dateTimePicker.nativeElement.firstChild.style.left = leftPositionDateFilter + 'px';

        } else if (selectedValue === DATE_RECENT) {
            // TODO - productAvailabilitiesSidebarFilterOpen dialog
        } else {
            this.createFilterRequestFromPredefinedValue();
            this.initializeDateOptions();
        }
    }

    onCloseDatePicker() {
        this.isCustomOption = false;
    }

    onDateChange(event: DateRangeModel) {
        if (event.startDate && event.endDate) {
            this.fromDate = event.startDate;
            this.toDate = event.endDate;
            this.dateCustomSelected();
        } else if (this.isCustomOption && !event.startDate && !event.endDate) {
            this.fromDate = null;
            this.toDate = null;
            this.selectedOptionValue = null;
            this.createFilterRequestFromPredefinedValue();
            this.initializeDateOptions();
        }
    }

    private dateCustomSelected() {
        this.selectedOptionValue = DATE_CUSTOM;

        this.createFilterRequestFromStartAndEndDate();

        this.initializeDateOptions();
    }

    private getLabelForCustomOption(fromDate, toDate): string {
        const fromDateStr = fromDate.format(DATE_FORMAT);
        const toDateStr = toDate.format(DATE_FORMAT);
        return fromDateStr + ' - ' + toDateStr;
    }

    private createFilterRequestFromStartAndEndDate() {
        const fromDateObj = this.getDateObjectFromMomentDate(this.fromDate);
        const toDateObj = this.getDateObjectFromMomentDate(this.toDate);

        const fromDateJSON = JSON.stringify(fromDateObj);
        const toDateJSON = JSON.stringify(toDateObj);

        this.changed.emit([fromDateJSON, toDateJSON]);
    }

    private createFilterRequestFromPredefinedValue() {
        const data: string[] = this.selectedOptionValue ? [this.selectedOptionValue] : [];
        this.changed.emit(data);
    }

    private getDateObjectFromMomentDate(time) {
        return {
            day: time.date(),
            month: time.month() + 1,
            year: time.year()
        };
    }

    private getDateFromTimeObject(timeObject): moment.Moment {
        const dateObj = moment();
        dateObj.year(timeObject.year);
        dateObj.month(timeObject.month - 1);
        dateObj.date(timeObject.day);

        return dateObj;
    }

    private initializeDateOptions() {
        const optionCustom: DropdownOptionModel = _.cloneDeep(OPTION_CUSTOM);

        if (this.fromDate && this.toDate) {
            optionCustom.label = this.getLabelForCustomOption(this.fromDate, this.toDate);
        }

        this.listOfFilterOptions = [optionCustom,
            OPTION_YESTERDAY, OPTION_TODAY,
            OPTION_LAST_WEEK, OPTION_THIS_WEEK, OPTION_NEXT_WEEK,
            OPTION_NEXT_30_DAYS, OPTION_LAST_MONTH, OPTION_THIS_MONTH,
            OPTION_NEXT_MONTH, OPTION_LAST_YEAR, OPTION_THIS_YEAR, OPTION_NEXT_YEAR];
    }

    private getValueFromDataArray(throwException: boolean): string {
        if (this.filterItem.data) {
            if (this.filterItem.data.length === 1) {
                return this.filterItem.data[0];
            } else if (this.filterItem.data.length === 2) {
                return DATE_CUSTOM;
            } else {
                if (throwException) {
                    throw new Error('Unexpected array size for recreating date filter: ' + this.filterItem.data);
                } else {
                    return null;
                }
            }
        }

        return null;
    }
}
