import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {DropdownOptionModel} from '../form-elements/dropdown/dropdown-option.model';
import {NotificationResponseModel} from '../../../core/modules/rest/notification/response/notification-response.model';



@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit, OnChanges {

    @Input() listOfNotifications: NotificationResponseModel[];
    @Input() selectedNotificationId: number;
    @Input() expressionItemsListEmpty: boolean;
    @Input() areEnoughFieldsSelected: boolean;
    @Input() hasUseNotificationsPermission: boolean;
    @Input() hasCreateNotificationPermission: boolean;
    @Input() hasEditNotificationPermission: boolean;
    @Input() hasRemoveNotificationPermission: boolean;
    @Input() showSpanSelectNotification: boolean;

    @Output() selectedNotificationChangedEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() editNotificationEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() removeNotificationEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() sidebarOpenStatusChanged: EventEmitter<void> = new EventEmitter<void>();

    listOfNotificationsForDropdown: DropdownOptionModel[] = [];
    notificationName: string;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.listOfNotifications &&  this.listOfNotifications) {
            this.listOfNotificationsForDropdown = this.listOfNotifications.map((notification: NotificationResponseModel) =>
                new DropdownOptionModel(notification.description, notification.notificationId.toString())
            );
        }
        this.updateNotificationName();
    }

    onNotificationSelected(value: string) {
        const notificationId = value !== null ? +value : null;
        this.selectedNotificationChangedEvent.emit(notificationId);
    }

    addNotificationClick() {
        this.editNotificationEvent.emit();
    }

    updateNotificationClick() {
        this.editNotificationEvent.emit(this.selectedNotificationId);
    }

    removeNotificationClick() {
        this.removeNotificationEvent.emit(this.selectedNotificationId);
    }

    onOpenCloseSidebarNotification() {
        this.sidebarOpenStatusChanged.emit();
    }

    private updateNotificationName() {
        if (this.selectedNotificationId) {
            this.notificationName = this.listOfNotifications.find(notification => notification.notificationId === this.selectedNotificationId).description;
        } else {
            this.notificationName = null;
        }
    }
}
