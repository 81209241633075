import {Action} from "@ngrx/store";
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export const RESET_STATE = '[Dialog - Edit Product Basic Info :: MAIN] Reset State';
export const SET_PRODUCT = '[Dialog - Edit Product Basic Info :: MAIN] Set Product';
export const SET_CATEGORIES = '[Dialog - Edit Product Basic Info :: MAIN] Set Categories';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetProduct implements Action {
    readonly type = SET_PRODUCT;

    constructor(public product: ProductSimpleResponseModel) {
    }
}

export class SetCategories implements Action {
    readonly type = SET_CATEGORIES;

    constructor(public categories: DropdownOptionModel[]) {
    }
}

export type All = ResetState
    | SetProduct
    | SetCategories;
