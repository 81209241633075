
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {CategoryRestService} from "../../../../../../../../core/modules/rest/category/category-rest.service";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {SubmitProductData} from "../components/add-product-dialog-form/add-product-dialog-form.component";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {ProductRestService} from "../../../../../../../../core/modules/rest/product/product-rest.service";
import {LocationListRestService} from "../../../../../../../../core/modules/rest/location-list/location-list-rest.service";
import {CategoryResponseModel} from "../../../../../../../../core/modules/rest/category/response/category-response.model";
import {LocationListResponseModel} from "../../../../../../../../core/modules/rest/location-list/response/location-list-response.model";
import {DropdownOptionModel} from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

@Injectable()
export class AddProductDialogSandbox extends DialogSandbox {

    categories$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.categories_selector));
    pickupDropoffLocationLists$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.pickupDropoffLocationLists_selector));
    pickupLocationLists$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.pickupLocationLists_selector));
    dropoffLocationLists$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.dropoffLocationLists_selector));

    constructor(appDialogsService: AppDialogsService,
                private productRestService: ProductRestService,
                private categoryRestService: CategoryRestService,
                private locationListRestService: LocationListRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData() {

        observableCombineLatest(this.categoryRestService.getAll(), this.locationListRestService.getAllActive()).pipe(
            take(1))
            .subscribe(([categories, locationLists]: [CategoryResponseModel[], LocationListResponseModel[]]) => {
                this.store.dispatch(new actions.SetCategories(categories.map(c => new DropdownOptionModel(c.description, c.categoryId.toString()))));
                this.store.dispatch(new actions.SetPickupDropoffLocationLists(locationLists.map(ll => new DropdownOptionModel(ll.description, ll.locationListId.toString()))));
                this.store.dispatch(new actions.SetPickupLocationLists(locationLists.map(ll => new DropdownOptionModel(ll.description, ll.locationListId.toString()))));
                this.store.dispatch(new actions.SetDropoffLocationLists(locationLists.map(ll => new DropdownOptionModel(ll.description, ll.locationListId.toString()))));
            });
    }

    createProduct(data: SubmitProductData): Observable<CreateBaseResponseModel> {

        return this.productRestService.create({
            categoryId: data.categoryId,
            description: data.description,
            descriptionRegularTrip: data.descriptionRegularTrip,
            descriptionReturnTrip: data.descriptionReturnTrip,
            isFlexibleProduct: data.isFlexibleProduct,
            isRoundTripProduct: data.isRoundTripProduct,
            tracksQuantityBetweenStops: data.tracksQuantityBetweenStops,
            usesPickupLocations: data.usesPickupLocations,
            pickupLocationListId: data.pickupLocationListId,
            usesDropoffLocations: data.usesDropoffLocations,
            dropoffLocationListId: data.dropoffLocationListId
        });
    }
}
