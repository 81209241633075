import { ShoppingCartResponseModel } from "../../../../../../../core/modules/rest/shopping-cart/response/shopping-cart-response.model";
import { ShoppingCartItemDataModel } from "./shopping-cart-item-data.model";
import { PaymentItemDataModel } from "./payment-item-data.model";
import { OrderCostItemDataModel } from "./order-cost-item-data.model";

export class ShoppingCartDataModel {

    shoppingCartItems: ShoppingCartItemDataModel[];

    shoppingCartResponse: ShoppingCartResponseModel;

    paymentItems: PaymentItemDataModel[];

    orderLevelAdditionalCostItems: OrderCostItemDataModel[];

    orderLevelDiscountItems: OrderCostItemDataModel[];
}
