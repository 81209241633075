const EmailHtmlTemplateTypeEnum = {
    CREATE_ORDER: 'CREATE_ORDER' as 'CREATE_ORDER',
    VOID_ORDER: 'VOID_ORDER' as 'VOID_ORDER',
    TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS' as 'TERMS_AND_CONDITIONS',
    REGISTERED_CUSTOMER: 'REGISTERED_CUSTOMER' as 'REGISTERED_CUSTOMER',
    NOTIFICATION: 'NOTIFICATION' as 'NOTIFICATION'
};
type EmailHtmlTemplateTypeEnum = (typeof EmailHtmlTemplateTypeEnum)[keyof typeof EmailHtmlTemplateTypeEnum];
export {EmailHtmlTemplateTypeEnum};

export function getDescription(emailHtmlTemplateType: EmailHtmlTemplateTypeEnum): string {
    if (emailHtmlTemplateType === EmailHtmlTemplateTypeEnum.CREATE_ORDER) {
        return "Create Order";
    } else if (emailHtmlTemplateType === EmailHtmlTemplateTypeEnum.VOID_ORDER) {
        return "Void Order";
    } else if (emailHtmlTemplateType === EmailHtmlTemplateTypeEnum.TERMS_AND_CONDITIONS) {
        return "Terms and Conditions";
    }
    else if (emailHtmlTemplateType === EmailHtmlTemplateTypeEnum.REGISTERED_CUSTOMER) {
        return "Registered Customer";
    }
    else if (emailHtmlTemplateType === EmailHtmlTemplateTypeEnum.NOTIFICATION) {
        return "Notification";
    }
    else {
        return "";
    }
}
