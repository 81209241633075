<app-abstract-dialog-container>
    <app-edit-pass-template-items-dialog-form
        [isEditMode]="isEditMode"
        [passId]="passId"
        [templateId]="templateId"
        [templateItems]="templateItems$ | async"
        (submit)="onSubmit($event)"
        (cancel)="onCancel()">
    </app-edit-pass-template-items-dialog-form>
</app-abstract-dialog-container>
