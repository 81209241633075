
import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {QuickSellingButtonPassResponseModel} from "../../../../../../../../core/modules/rest/quick-selling-button-pass/response/quick-selling-button-pass-response.model";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {QuickSellingButtonPassRestService} from "../../../../../../../../core/modules/rest/quick-selling-button-pass/quick-selling-button-pass-rest.service";
import {Store} from "@ngrx/store";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {PassSimpleResponseModel} from "../../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {PassRestService} from "../../../../../../../../core/modules/rest/pass/pass-rest.service";
import {LocationListItemDescriptorResponseModel} from "../../../../../../../../core/modules/rest/location-list/response/location-list-item-descriptor-response.model";
import {LocationListRestService} from "../../../../../../../../core/modules/rest/location-list/location-list-rest.service";
import {TiersResponseModel} from "../../../../../../../../core/modules/rest/tier/response/tiers-response.model";
import {TierResponseModel} from "../../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {TierRestService} from "../../../../../../../../core/modules/rest/tier/tier-rest.service";
import {TierWithQuantityModel} from "../model/tier-with-quantity.model";
import {CreateQuickSellingButtonPassRequestModel} from "../../../../../../../../core/modules/rest/quick-selling-button-pass/request/create-quick-selling-button-pass-request.model";
import {CreateQuickSellingButtonPassTierRequestModel} from "../../../../../../../../core/modules/rest/quick-selling-button-pass/request/create-quick-selling-button-pass-tier-request.model";
import {UpdateQuickSellingButtonPassRequestModel} from "../../../../../../../../core/modules/rest/quick-selling-button-pass/request/update-quick-selling-button-pass-request.model";
import {SubmitFormDialogDataModel} from "../model/submit-form-dialog-data.model";

@Injectable()
export class EditQuickSellingButtonPassDialogSandbox extends DialogSandbox {

    quickSellingButtonPass$: Observable<QuickSellingButtonPassResponseModel> = this.store.select(reducer.quick_selling_button_pass_selector);

    tiers$: Observable<TierResponseModel[]> = this.store.select(reducer.tiers_selector);
    tiersLoading$: Observable<boolean> = this.store.select(reducer.tiers_loading_selector);
    passes$: Observable<PassSimpleResponseModel[]> = this.store.select(reducer.passes_selector);
    passesLoading$: Observable<boolean> = this.store.select(reducer.passes_loading_selector);
    pickupLocations$: Observable<LocationListItemDescriptorResponseModel[]> = this.store.select(reducer.pickup_locations_selector);
    pickupLocationsLoading$: Observable<boolean> = this.store.select(reducer.pickup_locations_loading_selector);
    dropoffLocations$: Observable<LocationListItemDescriptorResponseModel[]> = this.store.select(reducer.dropoff_locations_selector);
    dropoffLocationsLoading$: Observable<boolean> = this.store.select(reducer.dropoff_locations_loading_selector);

    errorMessage$: Observable<string> = this.store.select(reducer.error_message_selector);

    constructor(appDialogsService: AppDialogsService,
                private quickSellingButtonPassRestService: QuickSellingButtonPassRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox,
                private passRestService: PassRestService,
                private locationListRestService: LocationListRestService,
                private tierRestService: TierRestService) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(editMode: boolean, quickSellingButtonPassId: number, passId: number, pickupLocationId: number) {

        this.showLoader();

        // Check whether edit mode
        if (editMode) {
            observableForkJoin(
                    this.quickSellingButtonPassRestService.getById(quickSellingButtonPassId),
                    this.tierRestService.getAllActive(),
                    this.passRestService.getAllActive(),
                    this.locationListRestService.getPickupLocationsByPassId(passId),
                    this.locationListRestService.getDropoffLocationsByPassIdAndPickupLocationId(passId, pickupLocationId)
                ).subscribe(results => {
                    const quickSellingButtonPass: QuickSellingButtonPassResponseModel = results[0];
                    const tiersResponse: TiersResponseModel = results[1];
                    const passes: PassSimpleResponseModel[] = results[2];
                    const pickupLocations: LocationListItemDescriptorResponseModel[] = results[3];
                    const dropoffLocations: LocationListItemDescriptorResponseModel[] = results[4];

                    this.store.dispatch(new actions.SetData(quickSellingButtonPass, tiersResponse.tiers, passes, pickupLocations, dropoffLocations));
                    this.hideLoader();
                },
                () => this.hideLoader());
            } else {
                observableForkJoin(
                        this.tierRestService.getAllActive(),
                        this.passRestService.getAllActive())
                    .subscribe(results => {
                        const quickSellingButtonPass: QuickSellingButtonPassResponseModel = null;
                        const tiersResponse: TiersResponseModel = results[0];
                        const passes: PassSimpleResponseModel[] = results[1];
                        const pickupLocations: LocationListItemDescriptorResponseModel[] = [];
                        const dropoffLocations: LocationListItemDescriptorResponseModel[] = [];

                        this.store.dispatch(new actions.SetData(quickSellingButtonPass, tiersResponse.tiers, passes, pickupLocations, dropoffLocations));
                        this.hideLoader();
                    },
                    () => this.hideLoader());
            }
    }

    loadPickupLocationsByPassId(passId: number) {

        // Clear dependant items
        this.store.dispatch(new actions.SetDropoffLocations([]));

        if (passId) {
            this.store.dispatch(new actions.SetPickupLocationsLoading(true));
            this.locationListRestService.getPickupLocationsByPassId(passId)
                .subscribe((pickupLocations: LocationListItemDescriptorResponseModel[]) => {
                    this.store.dispatch(new actions.SetPickupLocations(pickupLocations));
                    this.store.dispatch(new actions.SetPickupLocationsLoading(false));
                },
                () => this.store.dispatch(new actions.SetPickupLocationsLoading(false)));
        } else {
            this.store.dispatch(new actions.SetPickupLocations([]));
        }
    }

    loadDropoffLocationsByPassIdAndPickupLocationId(passId: number, pickupLocationId: number) {

        if (passId && pickupLocationId) {
            this.store.dispatch(new actions.SetDropoffLocationsLoading(true));
            this.locationListRestService.getDropoffLocationsByPassIdAndPickupLocationId(passId, pickupLocationId)
                .subscribe((dropoffLocations: LocationListItemDescriptorResponseModel[]) => {
                    this.store.dispatch(new actions.SetDropoffLocations(dropoffLocations));
                    this.store.dispatch(new actions.SetDropoffLocationsLoading(false));
                },
                () => this.store.dispatch(new actions.SetDropoffLocationsLoading(false)));
        } else {
            this.store.dispatch(new actions.SetDropoffLocations([]));
        }
    }

    createQuickSellingButtonPass(data: SubmitFormDialogDataModel): Observable<any> {

        let quickSellingButtonPassTiers: CreateQuickSellingButtonPassTierRequestModel[] = [];
        data.tiersWithQuantities.map((tierWithQuantity: TierWithQuantityModel) => {
            quickSellingButtonPassTiers.push(new CreateQuickSellingButtonPassTierRequestModel(tierWithQuantity.tierId, tierWithQuantity.tierQuantity));
        });

        const request = new CreateQuickSellingButtonPassRequestModel(
            data.description,
            data.passId,
            data.pickupLocationId,
            data.dropoffLocationId,
            data.useLastName,
            data.lastNameRequired,
            data.useEmail,
            data.emailRequired,
            data.usePhone,
            data.phoneRequired,
            data.useDiscountCode,
            data.color,
            quickSellingButtonPassTiers
        );

        return this.quickSellingButtonPassRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating quick selling button pass', error);
            }));
    }

    updateQuickSellingButtonPass(quickSellingButtonPassId: number, data: SubmitFormDialogDataModel): Observable<any> {

        let quickSellingButtonPassTiers: CreateQuickSellingButtonPassTierRequestModel[] = [];
        data.tiersWithQuantities.map((tierWithQuantity: TierWithQuantityModel) => {
            quickSellingButtonPassTiers.push(new CreateQuickSellingButtonPassTierRequestModel(tierWithQuantity.tierId, tierWithQuantity.tierQuantity));
        });

        const request = new UpdateQuickSellingButtonPassRequestModel(
            quickSellingButtonPassId,
            data.description,
            data.passId,
            data.pickupLocationId,
            data.dropoffLocationId,
            data.useLastName,
            data.lastNameRequired,
            data.useEmail,
            data.emailRequired,
            data.usePhone,
            data.phoneRequired,
            data.useDiscountCode,
            data.color,
            quickSellingButtonPassTiers
        );

        return this.quickSellingButtonPassRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating quick selling button pass', error);
            }));
    }
}
