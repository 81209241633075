import {AmountTypeEnum} from "../../../../../../../../shared/enums/amount-type.enum";
import * as moment from "moment";

export class CommissionFormSubmitDataModel {
    description: string;
    commissionAmount: number;
    commissionAmountType: AmountTypeEnum;
    referralAmount: number;
    referralAmountType: AmountTypeEnum;
    startDate: number;
    endDate: number;
    startTime: number;
    endTime: number;

    constructor(description: string, commissionAmount: number, commissionAmountType: AmountTypeEnum, referralAmount: number, referralAmountType: AmountTypeEnum, startDate: number, endDate: number, startTime: number, endTime: number) {
        this.description = description;
        this.commissionAmount = commissionAmount;
        this.commissionAmountType = commissionAmountType;
        this.referralAmount = referralAmount;
        this.referralAmountType = referralAmountType;
        this.startDate = startDate;
        this.endDate = endDate;
        this.startTime = startTime;
        this.endTime = endTime;
    }
}
