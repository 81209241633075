import {Action} from "@ngrx/store";
import {TreeNode} from "../../../../../../../shared/components/tree/tree.component";
import {CategoryBasicInfoDataModel} from "../../model/category-basic-info-data.model";
import {CategoryEmailHtmlTemplatesDataModel} from "../../model/category-email-html-templates-data.model";
import {CategoryTemplateItemsDataModel} from "../../model/category-template-items-data.model";
import {ProductBasicInfoDataModel} from "../../model/product-basic-info-data.model";
import {ProductAvailabilitiesDataModel} from "../../model/product-availabilities-data.model";
import {ProductPricingDateRangesDataModel} from "../../model/product-pricing-date-ranges-data.model";
import {TreeItemTypeEnum} from "../../model/tree-item-type.enum";
import {CategoryTabItemEnum} from "../../model/category-tab-item.enum";
import {ProductImagesDataModel} from "../../model/product-images-data.model";
import {ProductTabItemEnum} from "../../model/product-tab-item.enum";
import {ProductFlagsDataModel} from "../../model/product-flags-data.model";
import {ProductValidityDataModel} from "../../model/product-validity-data.model";
import {ProductEmailHtmlTemplatesDataModel} from "../../model/product-email-html-templates-data.model";
import {ProductRoutesDataModel} from "../../model/product-routes-data.model";
import {ProductOrTicketBackgroundImageDataModel} from "../../model/product-or-ticket-background-image-data.model";
import {ProductAvailabilitiesSidebarFilterTypeEnum} from "../../model/product-availabilities-sidebar-filter-type.enum";
import {ProductAvailabilitiesSidebarFilterDataModel} from "../../model/product-availabilities-sidebar-filter-data.model";
import {DepartureGroupResponseModel} from "../../../../../../../core/modules/rest/departure-group/response/departure-group-response.model";
import {LocationResponseModel} from "../../../../../../../core/modules/rest/location/response/location-response.model";
import {ProductPricingDateRangesSidebarFilterTypeEnum} from "../../model/product-pricing-date-ranges-sidebar-filter-type.enum";
import {TierResponseModel} from "../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {ProductPricingDateRangesSidebarFilterDataModel} from "../../model/product-pricing-date-ranges-sidebar-filter-data.model";
import {CategoryCostsDataModel} from "../../model/category-costs-data.model";
import {ProductCostsDataModel} from "../../model/product-costs-data.model";
import {SortByModel} from "../../../../../../../shared/model/sort-by.model";

export const RESET_STATE = "[Manage Categories And Products :: MAIN] Reset State";
export const SET_LOADING_CATEGORIES_AND_PRODUCTS = "[Manage Categories And Products :: MAIN] Loading Categories And Products";
export const SET_CATEGORIES_AND_PRODUCTS_TREE = "[Manage Categories And Products :: MAIN] Set Categories And Products";
export const SET_SHOW_INACTIVE_ITEMS = "[Manage Categories And Products :: MAIN] Show Inactive Items";
export const SET_SELECTED_ITEM = "[Manage Categories And Products :: MAIN] Set Selected Item";
export const SET_SELECTED_NODE = "[Manage  Categories And Products :: MAIN] Set Selected Node";
export const SET_LOADING_CATEGORY_FULL = "[Manage Categories And Products :: MAIN] Set Loading Category Full";
export const SET_LOADING_CATEGORY_TEMPLATE_ITEMS = "[Manage Categories And Products :: MAIN] Set Loading Category Template Items";
export const SET_SELECTED_CATEGORY_TAB_ITEM = "[Manage Categories And Products :: MAIN] Set Selected Category Tab Item";
export const SET_CATEGORY_BASIC_INFO = "[Manage Categories And Products :: MAIN] Set Category Basic Info";
export const SET_CATEGORY_EMAIL_HTML_TEMPLATES = "[Manage Categories And Products :: MAIN] Set Category Email HTML Templates";
export const SET_CATEGORY_COSTS = "[Manage Categories And Products :: MAIN] Set Category Costs";
export const SET_CATEGORY_TEMPLATE_ITEMS = "[Manage Categories And Products :: MAIN] Set Category Template Items";
export const SET_LOADING_PRODUCT_FULL = "[Manage Categories And Products :: MAIN] Set Loading Product Full";
export const SET_LOADING_PRODUCT_IMAGES = "[Manage Categories And Products :: MAIN] Set Loading Product Images";
export const SET_LOADING_PRODUCT_AVAILABILITIES = "[Manage Categories And Products :: MAIN] Set Loading Product Availabilities";
export const SET_LOADING_PRODUCT_PRICING_DATE_RANGES = "[Manage Categories And Products :: MAIN] Set Loading Product Pricing Date Ranges";
export const SET_SELECTED_PRODUCT_TAB_ITEM = "[Manage Categories And Products :: MAIN] Set Selected Product Tab Item";
export const SET_PRODUCT_BASIC_INFO = "[Manage Categories And Products :: MAIN] Set Product Basic Info";
export const SET_PRODUCT_IMAGES = "[Manage Categories And Products :: MAIN] Set Product Images";
export const SET_PRODUCT_IMAGE = "[Manage Categories And Products :: MAIN] Set Product Image";
export const SET_TICKET_BACKGROUND_IMAGE = "[Manage Categories And Products :: MAIN] Set Ticket Background Image";
export const SET_PRODUCT_FLAGS = "[Manage Categories And Products :: MAIN] Set Product Flags";
export const SET_PRODUCT_VALIDITY = "[Manage Categories And Products :: MAIN] Set Product Validity";
export const SET_PRODUCT_EMAIL_HTML_TEMPLATES = "[Manage Categories And Products :: MAIN] Set Product Email HTML Templates";
export const SET_PRODUCT_ROUTES = "[Manage Categories And Products :: MAIN] Set Product Routes";
export const SET_PRODUCT_COSTS = "[Manage Categories And Products :: MAIN] Set Product Costs";
export const RESET_PRODUCT_AVAILABILITIES = "[Manage Categories And Products :: MAIN] Reset Product Availabilities And Product Availabilities Sidebar Filter";
export const SET_PRODUCT_AVAILABILITIES = "[Manage Categories And Products :: MAIN] Set Product Availabilities";
export const SET_PRODUCT_AVAILABILITIES_FROM = "[Manage Categories And Products :: MAIN] Set Product Availabilities From";
export const SET_PRODUCT_AVAILABILITIES_SIDEBAR_FILTER_OPEN = "[Manage Categories And Products :: MAIN] Set Product Availabilities Sidebar Filter Open";
export const SET_PRODUCT_AVAILABILITIES_SIDEBAR_FILTER_DEPARTURE_GROUPS = "[Manage Categories And Products :: MAIN] Set Product Availabilities Sidebar Filter Departure Groups";
export const SET_PRODUCT_AVAILABILITIES_SIDEBAR_FILTER_DATA = "[Manage Categories And Products :: MAIN] Set Product Availabilities Sidebar Filter Data";
export const UPDATE_PRODUCT_AVAILABILITIES_CURRENT_SEARCH_DATA_SORT_BY = "[Manage Categories And Products :: MAIN] Update Product Availabilities Current Search Data Sort By";
export const RESET_PRODUCT_PRICING_DATE_RANGES = "[Manage Categories And Products :: MAIN] Reset Product Pricing Date Ranges And Product Pricing Date Ranges Sidebar Filter";
export const SET_PRODUCT_PRICING_DATE_RANGES = "[Manage Categories And Products :: MAIN] Set Product Pricing Date Ranges";
export const SET_PRODUCT_PRICING_DATE_RANGES_FROM = "[Manage Categories And Products :: MAIN] Set Product Pricing Date Ranges From";
export const SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_OPEN = "[Manage Categories And Products :: MAIN] Set Product Pricing Date Ranges Sidebar Filter Open";
export const SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_PICKUP_LOCATIONS = "[Manage Categories And Products :: MAIN] Set Product Pricing Date Ranges Sidebar Filter Pickup Locations";
export const SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_DROPOFF_LOCATIONS = "[Manage Categories And Products :: MAIN] Set Product Pricing Date Ranges Sidebar Filter Dropoff Locations";
export const SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_TIERS = "[Manage Categories And Products :: MAIN] Set Product Pricing Date Ranges Sidebar Filter Tiers";
export const SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_DATA = "[Manage Categories And Products :: MAIN] Set Product Pricing Date Ranges Sidebar Filter Data";
export const UPDATE_PRODUCT_PRICING_DATE_RANGES_CURRENT_SEARCH_DATA_SORT_BY = "[Manage Categories And Products :: MAIN] Update Product Pricing Date Ranges Current Search Data Sort By";

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetLoadingCategoriesAndProducts implements Action {
    readonly type = SET_LOADING_CATEGORIES_AND_PRODUCTS;

    constructor(public loading: boolean) {
    }
}

export class SetCategoriesAndProductsTree implements Action {
    readonly type = SET_CATEGORIES_AND_PRODUCTS_TREE;

    constructor(public tree: TreeNode[]) {
    }
}

export class SetShowInactiveItems implements Action {
    readonly type = SET_SHOW_INACTIVE_ITEMS;

    constructor(public showInactiveItems: boolean) {
    }
}

export class SetSelectedItem implements Action {
    readonly type = SET_SELECTED_ITEM;

    constructor(public item: TreeNode, public itemType: TreeItemTypeEnum, public itemId: number, public itemActive: boolean) {
    }
}

export class SetSelectedNode implements Action {
    readonly type = SET_SELECTED_NODE;

    constructor(public node: TreeNodeMaterial) {
    }
}

export class SetLoadingCategoryFull implements Action {
    readonly type = SET_LOADING_CATEGORY_FULL;

    constructor(public loading: boolean) {
    }
}

export class SetLoadingCategoryTemplateItems implements Action {
    readonly type = SET_LOADING_CATEGORY_TEMPLATE_ITEMS;

    constructor(public loading: boolean) {
    }
}

export class SetSelectedCategoryTabItem implements Action {
    readonly type = SET_SELECTED_CATEGORY_TAB_ITEM;

    constructor(public categoryTabItem: CategoryTabItemEnum) {
    }
}

export class SetCategoryBasicInfo implements Action {
    readonly type = SET_CATEGORY_BASIC_INFO;

    constructor(public categoryBasicInfo: CategoryBasicInfoDataModel) {
    }
}

export class SetCategoryEmailHtmlTemplates implements Action {
    readonly type = SET_CATEGORY_EMAIL_HTML_TEMPLATES;

    constructor(public categoryEmailHtmlTemplates: CategoryEmailHtmlTemplatesDataModel) {
    }
}

export class SetCategoryCosts implements Action {
    readonly type = SET_CATEGORY_COSTS;

    constructor(public categoryCosts: CategoryCostsDataModel) {
    }
}

export class SetCategoryTemplateItems implements Action {
    readonly type = SET_CATEGORY_TEMPLATE_ITEMS;

    constructor(public categoryTemplateItems: CategoryTemplateItemsDataModel) {
    }
}

export class SetLoadingProductFull implements Action {
    readonly type = SET_LOADING_PRODUCT_FULL;

    constructor(public loading: boolean) {
    }
}

export class SetLoadingProductImages implements Action {
    readonly type = SET_LOADING_PRODUCT_IMAGES;

    constructor(public loading: boolean) {
    }
}

export class SetLoadingProductAvailabilities implements Action {
    readonly type = SET_LOADING_PRODUCT_AVAILABILITIES;

    constructor(public loading: boolean) {
    }
}

export class SetLoadingProductPricingDateRanges implements Action {
    readonly type = SET_LOADING_PRODUCT_PRICING_DATE_RANGES;

    constructor(public loading: boolean) {
    }
}

export class SetSelectedProductTabItem implements Action {
    readonly type = SET_SELECTED_PRODUCT_TAB_ITEM;

    constructor(public productTabItem: ProductTabItemEnum) {
    }
}

export class SetProductBasicInfo implements Action {
    readonly type = SET_PRODUCT_BASIC_INFO;

    constructor(public productBasicInfo: ProductBasicInfoDataModel) {
    }
}

export class SetProductImages implements Action {
    readonly type = SET_PRODUCT_IMAGES;

    constructor(public productImages: ProductImagesDataModel) {
    }
}

export class SetProductImage implements Action {
    readonly type = SET_PRODUCT_IMAGE;

    constructor(public productImage: ProductOrTicketBackgroundImageDataModel) {
    }
}

export class SetTicketBackgroundImage implements Action {
    readonly type = SET_TICKET_BACKGROUND_IMAGE;

    constructor(public ticketBackgroundImage: ProductOrTicketBackgroundImageDataModel) {
    }
}

export class SetProductFlags implements Action {
    readonly type = SET_PRODUCT_FLAGS;

    constructor(public productFlags: ProductFlagsDataModel) {
    }
}

export class SetProductValidity implements Action {
    readonly type = SET_PRODUCT_VALIDITY;

    constructor(public productValidity: ProductValidityDataModel) {
    }
}

export class SetProductEmailHtmlTemplates implements Action {
    readonly type = SET_PRODUCT_EMAIL_HTML_TEMPLATES;

    constructor(public productEmailHtmlTemplates: ProductEmailHtmlTemplatesDataModel) {
    }
}

export class SetProductRoutes implements Action {
    readonly type = SET_PRODUCT_ROUTES;

    constructor(public productRoutes: ProductRoutesDataModel) {
    }
}

export class SetProductCosts implements Action {
    readonly type = SET_PRODUCT_COSTS;

    constructor(public productCosts: ProductCostsDataModel) {
    }
}

export class ResetProductAvailabilities implements Action {
    readonly type = RESET_PRODUCT_AVAILABILITIES;

    constructor() {
    }
}

export class SetProductAvailabilities implements Action {
    readonly type = SET_PRODUCT_AVAILABILITIES;

    constructor(public append: boolean, public productAvailabilities: ProductAvailabilitiesDataModel) {
    }
}

export class SetProductAvailabilitiesFrom implements Action {
    readonly type = SET_PRODUCT_AVAILABILITIES_FROM;

    constructor(public productAvailabilitiesFrom: number) {
    }
}

export class SetProductAvailabilitiesSidebarFilterOpen implements Action {
    readonly type = SET_PRODUCT_AVAILABILITIES_SIDEBAR_FILTER_OPEN;

    constructor(public productAvailabilitiesSidebarFilterOpen: boolean, public productAvailabilitiesSidebarFilterCurrentFocus?: ProductAvailabilitiesSidebarFilterTypeEnum) {
    }
}

export class SetProductAvailabilitiesSidebarFilterDepartureGroups implements Action {
    readonly type = SET_PRODUCT_AVAILABILITIES_SIDEBAR_FILTER_DEPARTURE_GROUPS;

    constructor(public productAvailabilitiesSidebarFilterDepartureGroups: DepartureGroupResponseModel[]) {
    }
}

export class SetProductAvailabilitiesSidebarFilterData implements Action {
    readonly type = SET_PRODUCT_AVAILABILITIES_SIDEBAR_FILTER_DATA;

    constructor(public productAvailabilitiesSidebarFilterData: ProductAvailabilitiesSidebarFilterDataModel) {
    }
}

export class UpdateProductAvailabilitiesCurrentSearchDataSortBy implements Action {
    readonly type = UPDATE_PRODUCT_AVAILABILITIES_CURRENT_SEARCH_DATA_SORT_BY;

    constructor(public productAvailabilitiesSortBy: SortByModel) {
    }
}

export class ResetProductPricingDateRanges implements Action {
    readonly type = RESET_PRODUCT_PRICING_DATE_RANGES;

    constructor() {
    }
}

export class SetProductPricingDateRanges implements Action {
    readonly type = SET_PRODUCT_PRICING_DATE_RANGES;

    constructor(public append: boolean, public productPricingDateRanges: ProductPricingDateRangesDataModel) {
    }
}

export class SetProductPricingDateRangesFrom implements Action {
    readonly type = SET_PRODUCT_PRICING_DATE_RANGES_FROM;

    constructor(public productPricingDateRangesFrom: number) {
    }
}

export class SetProductPricingDateRangesSidebarFilterOpen implements Action {
    readonly type = SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_OPEN;

    constructor(public productPricingDateRangesSidebarFilterOpen: boolean, public productPricingDateRangesSidebarFilterCurrentFocus?: ProductPricingDateRangesSidebarFilterTypeEnum) {
    }
}

export class SetProductPricingDateRangesSidebarFilterPickupLocations implements Action {
    readonly type = SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_PICKUP_LOCATIONS;

    constructor(public productPricingDateRangesSidebarFilterPickupLocations: LocationResponseModel[]) {
    }
}

export class SetProductPricingDateRangesSidebarFilterDropoffLocations implements Action {
    readonly type = SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_DROPOFF_LOCATIONS;

    constructor(public productPricingDateRangesSidebarFilterDropoffLocations: LocationResponseModel[]) {
    }
}

export class SetProductPricingDateRangesSidebarFilterTiers implements Action {
    readonly type = SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_TIERS;

    constructor(public productPricingDateRangesSidebarFilterTiers: TierResponseModel[]) {
    }
}

export class SetProductPricingDateRangesSidebarFilterData implements Action {
    readonly type = SET_PRODUCT_PRICING_DATE_RANGES_SIDEBAR_FILTER_DATA;

    constructor(public productPricingDateRangesSidebarFilterData: ProductPricingDateRangesSidebarFilterDataModel) {
    }
}

export class UpdateProductPricingDateRangesCurrentSearchDataSortBy implements Action {
    readonly type = UPDATE_PRODUCT_PRICING_DATE_RANGES_CURRENT_SEARCH_DATA_SORT_BY;

    constructor(public productPricingDateRangesSortBy: SortByModel) {
    }
}

export type All = ResetState
    | SetLoadingCategoriesAndProducts
    | SetCategoriesAndProductsTree
    | SetShowInactiveItems
    | SetSelectedItem
    | SetSelectedNode
    | SetLoadingCategoryFull
    | SetLoadingCategoryTemplateItems
    | SetSelectedCategoryTabItem
    | SetCategoryBasicInfo
    | SetCategoryEmailHtmlTemplates
    | SetCategoryCosts
    | SetCategoryTemplateItems
    | SetLoadingProductFull
    | SetLoadingProductImages
    | SetLoadingProductAvailabilities
    | SetLoadingProductPricingDateRanges
    | SetSelectedProductTabItem
    | SetProductBasicInfo
    | SetProductImages
    | SetProductImage
    | SetTicketBackgroundImage
    | SetProductFlags
    | SetProductValidity
    | SetProductEmailHtmlTemplates
    | SetProductRoutes
    | SetProductCosts
    | ResetProductAvailabilities
    | SetProductAvailabilities
    | SetProductAvailabilitiesFrom
    | SetProductAvailabilitiesSidebarFilterOpen
    | SetProductAvailabilitiesSidebarFilterDepartureGroups
    | SetProductAvailabilitiesSidebarFilterData
    | UpdateProductAvailabilitiesCurrentSearchDataSortBy
    | ResetProductPricingDateRanges
    | SetProductPricingDateRanges
    | SetProductPricingDateRangesFrom
    | SetProductPricingDateRangesSidebarFilterOpen
    | SetProductPricingDateRangesSidebarFilterPickupLocations
    | SetProductPricingDateRangesSidebarFilterDropoffLocations
    | SetProductPricingDateRangesSidebarFilterTiers
    | SetProductPricingDateRangesSidebarFilterData
    | UpdateProductPricingDateRangesCurrentSearchDataSortBy;
