<app-abstract-dialog-container alt-design>
    <div class="email-order-dialog">
        <div class="header_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <h1>Send Email</h1>
                </div>
            </div>
        </div>

        <div class="body_wrapper formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Email Address</label>
                        <input class="main--element input-large" [(ngModel)]="emailAddress">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="footer_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-6 text-right">
                    <app-button
                        [buttonType]='"secondary"'
                        (clickEvent)="onCancelClick()">
                        Cancel
                    </app-button>
                </div>

                <div class="cell medium-6 text-left">
                    <app-button
                        [buttonType]='"primary"'
                        (clickEvent)="onEmailOrderClick()">
                        Send Email
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</app-abstract-dialog-container>
