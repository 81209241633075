import {BaseExpressionRequestModel} from './base-expression-request.model';
import {ExpressionItemTypeEnum} from '../../../../../modules/secured/reports/general/order-reports/model/expression-item-type.enum';

export class GroupExpressionRequestModel extends BaseExpressionRequestModel {
    groupExpression: BaseExpressionRequestModel[];

    constructor(groupExpression: BaseExpressionRequestModel[]) {
        super(ExpressionItemTypeEnum.GROUP);
        this.groupExpression = groupExpression;
    }
}
