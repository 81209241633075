<app-abstract-dialog-container>
    <app-mapping-commission-dialog-form
        [commissionId]="commissionId"
        [commissionDescription]="commissionDescription"
        [loading]="loading$ | async"
        [categories]="categories$ | async"
        [mappedCategories]="mappedCategories$ | async"
        [products]="products$ | async"
        [mappedProducts]="mappedProducts$ | async"
        [passes]="passes$ | async"
        [mappedPasses]="mappedPasses$ | async"
        [tiers]="tiers$ | async"
        [mappedTiers]="mappedTiers$ | async"
        [roles]="roles$ | async"
        [mappedRoles]="mappedRoles$ | async"
        [users]="users$ | async"
        [mappedUsers]="mappedUsers$ | async"
        [locations]="locations$ | async"
        [mappedLocations]="mappedLocations$ | async"
        (submit)="onSubmit($event)"
        (cancel)="onCancel()">
    </app-mapping-commission-dialog-form>
</app-abstract-dialog-container>
