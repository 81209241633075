import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {
    MultiselectDropdownOptionActiveModel,
    OPTION_ALL
} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option-active.model";

export interface SubmitCommissionMappingsData {
    commissionId: number;
    commissionWhatMappings: SubmitCommissionWhatMappingData[];
    commissionWhoMappings: SubmitCommissionWhoMappingData[];
    commissionWhereMappings: SubmitCommissionWhereMappingData[];
}
export interface SubmitCommissionWhatMappingData {
    categoryId: number;
    productId: number;
    passId: number;
    tierId: number;
}
export interface SubmitCommissionWhoMappingData {
    roleId: number;
    userId: number;
}
export interface SubmitCommissionWhereMappingData {
    locationId: number;
}

@Component({
    selector: 'app-mapping-commission-dialog-form',
    templateUrl: './mapping-commission-dialog-form.component.html'
})

export class MappingCommissionDialogFormComponent implements OnChanges {

    @Input() commissionId: number;
    @Input() commissionDescription: string;
    @Input() loading: boolean;
    @Input() categories: MultiselectDropdownOptionActiveModel[];
    @Input() mappedCategories: number[];
    @Input() products: MultiselectDropdownOptionActiveModel[];
    @Input() mappedProducts: number[];
    @Input() passes: MultiselectDropdownOptionActiveModel[];
    @Input() mappedPasses: number[];
    @Input() tiers: MultiselectDropdownOptionActiveModel[];
    @Input() mappedTiers: number[];
    @Input() roles: MultiselectDropdownOptionActiveModel[];
    @Input() mappedRoles: number[];
    @Input() users: MultiselectDropdownOptionActiveModel[];
    @Input() mappedUsers: number[];
    @Input() locations: MultiselectDropdownOptionActiveModel[];
    @Input() mappedLocations: number[];

    @Output() submit: EventEmitter<SubmitCommissionMappingsData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    showInactiveCategories: boolean = false;
    showInactiveProducts: boolean = false;
    showInactivePasses: boolean = false;
    showInactiveTiers: boolean = false;
    showInactiveRoles: boolean = false;
    showInactiveUsers: boolean = false;
    showInactiveLocations: boolean = false;
    categoriesInit: MultiselectDropdownOptionActiveModel[];
    productsInit: MultiselectDropdownOptionActiveModel[];
    passesInit: MultiselectDropdownOptionActiveModel[];
    tiersInit: MultiselectDropdownOptionActiveModel[];
    rolesInit: MultiselectDropdownOptionActiveModel[];
    usersInit: MultiselectDropdownOptionActiveModel[];
    locationsInit: MultiselectDropdownOptionActiveModel[];

    constructor() {

        this.form = new FormGroup({
            categoryIds: new FormControl(),
            productIds: new FormControl(),
            passIds: new FormControl(),
            tierIds: new FormControl(),
            roleIds: new FormControl(),
            userIds: new FormControl(),
            locationIds: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.mappedCategories && changes.mappedProducts && changes.mappedPasses && changes.mappedTiers && changes.mappedRoles && changes.mappedUsers && changes.mappedLocations) {
            this.initializeFormData();
        }
        if (changes.categories) {
            this.categoriesInit = this.categories;
            this.onShowInactiveCategories(this.showInactiveCategories);

        }
        if (changes.products) {
            this.productsInit = this.products;
            this.onShowInactiveProducts(this.showInactiveProducts);
        }
        if (changes.passes) {
            this.passesInit = this.passes;
            this.onShowInactivePasses(this.showInactivePasses);
        }
        if (changes.tiers) {
            this.tiersInit = this.tiers;
            this.onShowInactiveTiers(this.showInactiveTiers);
        }
        if (changes.roles) {
            this.rolesInit = this.roles;
            this.onShowInactiveRoles(this.showInactiveRoles);
        }
        if (changes.users) {
            this.usersInit = this.users;
            this.onShowInactiveUsers(this.showInactiveUsers);
        }
        if (changes.locations) {
            this.locationsInit = this.locations;
            this.onShowInactiveLocations(this.showInactiveLocations);
        }
    }

    onSubmit() {

        // Collect "what" mappings from categories, products passes and tiers
        let commissionWhatMappingsFromCategories: SubmitCommissionWhatMappingData[] = this.form.value.categoryIds.filter(cid => cid !== OPTION_ALL).map(cid => ({
            categoryId: +cid,
            productId: null,
            passId: null,
            tierId: null}));
        let commissionWhatMappingsFromProducts: SubmitCommissionWhatMappingData[] = this.form.value.productIds.filter(pid => pid !== OPTION_ALL).map(pid => ({
            categoryId: null,
            productId: +pid,
            passId: null,
            tierId: null}));
        let commissionWhatMappingsFromPasses: SubmitCommissionWhatMappingData[] = this.form.value.passIds.filter(pid => pid !== OPTION_ALL).map(pid => ({
            categoryId: null,
            productId: null,
            passId: +pid,
            tierId: null}));
        let commissionWhatMappingsFromTiers: SubmitCommissionWhatMappingData[] = this.form.value.tierIds.filter(tid => tid !== OPTION_ALL).map(tid => ({
            categoryId: null,
            productId: null,
            passId: null,
            tierId: +tid}));
        let commissionWhatMappings: SubmitCommissionWhatMappingData[] = [];
        commissionWhatMappings.push(...commissionWhatMappingsFromCategories, ...commissionWhatMappingsFromProducts, ...commissionWhatMappingsFromPasses, ...commissionWhatMappingsFromTiers);

        // Collect "who" mappings from roles and users
        let commissionWhoMappingsFromRoles: SubmitCommissionWhoMappingData[] = this.form.value.roleIds.filter(rid => rid !== OPTION_ALL).map(rid => ({
            roleId: +rid,
            userId: null}));
        let commissionWhoMappingsFromUsers: SubmitCommissionWhoMappingData[] = this.form.value.userIds.filter(uid => uid !== OPTION_ALL).map(uid => ({
            roleId: null,
            userId: +uid}));
        let commissionWhoMappings: SubmitCommissionWhoMappingData[] = [];
        commissionWhoMappings.push(...commissionWhoMappingsFromRoles, ...commissionWhoMappingsFromUsers);

        // Collect "where" mappings from locations
        let commissionWhereMappingsFromLocations: SubmitCommissionWhereMappingData[] = this.form.value.locationIds.filter(lid => lid !== OPTION_ALL).map(lid => ({
            locationId: +lid}));
        let commissionWhereMappings: SubmitCommissionWhereMappingData[] = [];
        commissionWhereMappings.push(...commissionWhereMappingsFromLocations);

        this.submit.emit({
            commissionId: this.commissionId,
            commissionWhatMappings: commissionWhatMappings,
            commissionWhoMappings: commissionWhoMappings,
            commissionWhereMappings: commissionWhereMappings
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    onShowInactiveCategories(value: boolean) {

        this.showInactiveCategories = value;

        if (value) {
            this.categories = this.categoriesInit;
        }
        else {
            this.categories = this.categories.filter((category: MultiselectDropdownOptionActiveModel) => category.active == true);
        }

    }

    onShowInactiveProducts(value: boolean) {

        this.showInactiveProducts = value;

        if (value) {
            this.products = this.productsInit;
        }
        else {
            this.products = this.products.filter((product: MultiselectDropdownOptionActiveModel) => product.active == true);
        }

    }

    onShowInactivePasses(value: boolean) {

        this.showInactivePasses = value;

        if (value) {
            this.passes = this.passesInit;
        }
        else {
            this.passes = this.passes.filter((pass: MultiselectDropdownOptionActiveModel) => pass.active == true);
        }
    }

    onShowInactiveTiers(value: boolean) {

        this.showInactiveTiers = value;

        if (value) {
            this.tiers = this.tiersInit;
        }
        else {
            this.tiers = this.tiers.filter((tier: MultiselectDropdownOptionActiveModel) => tier.active == true);
        }
    }

    onShowInactiveRoles(value: boolean) {

        this.showInactiveRoles = value;

        if (value) {
            this.roles = this.rolesInit;
        }
        else {
            this.roles = this.roles.filter((role: MultiselectDropdownOptionActiveModel) => role.active == true);
        }
    }

    onShowInactiveUsers(value: boolean) {

        this.showInactiveUsers = value;

        if (value) {
            this.users = this.usersInit;
        }
        else {
            this.users = this.users.filter((user: MultiselectDropdownOptionActiveModel) => user.active == true);
        }
    }

    onShowInactiveLocations(value: boolean) {

        this.showInactiveLocations = value;

        if (value) {
            this.locations = this.locationsInit;
        }
        else {
            this.locations = this.locations.filter((location: MultiselectDropdownOptionActiveModel) => location.active == true);
        }
    }

    private initializeFormData(): void {
        this.form.patchValue({
            categoryIds: this.mappedCategories,
            productIds: this.mappedProducts,
            passIds: this.mappedPasses,
            tierIds: this.mappedTiers,
            roleIds: this.mappedRoles,
            userIds: this.mappedUsers,
            locationIds: this.mappedLocations
        });
    }
}
