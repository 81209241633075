import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild  } from '@angular/core';
import {CloseDropdownOnScrollService} from '../../../directives/scroll/close-dropdown-on-scroll.service';
import {Subscription} from 'rxjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { MultiselectDropdownOptionModel, OPTION_ALL } from '../multiselect-dropdown/multiselect-dropdown-option.model';

const SORT_ASC = 'ASC';
const SORT_DESC = 'DESC';

export enum SelectionState {
    INDIVIDUAL = 'INDIVIDUAL',
    PARTIAL_ALL = 'PARTIAL_ALL',
    ALL = 'ALL'
}

@Component({
    selector: 'app-multiselect-dropdown-batch',
    templateUrl: './multiselect-dropdown-batch.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiselectDropdownBatchComponent),
            multi: true,
        }
    ]
})
export class MultiselectDropdownBatchComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {

    @Input() loading = false;
    @Input() autocomplete = true;
    @Input() allOptionEnabled: boolean = false;
    @Input() preSelectedValues: string[] = []; // Used in ngOnChanges
    @Input() placeholderText = '- Select option(s) -';
    @Input() noOptionsText = 'No items to select';
    @Input() openedOnInit = false; // Used in ngOnChanges
    @Input() options: MultiselectDropdownOptionModel[] = []; // Used in ngOnChanges
    @Input() customClasses: string;
    @Input() positionedOptionsList = false; // Used in ngOnChanges
    @Input() disabled = false;
    @Input() sort = null;

    @Input() newCustomers: MultiselectDropdownOptionModel[] = [];
    @Input() totalCount: number;


    @Output() changed: EventEmitter<string[]> = new EventEmitter();
    @Output() scrolled: EventEmitter<void> = new EventEmitter();
    @Output() search: EventEmitter<string> = new EventEmitter<string>();
    @Output() selectedType: EventEmitter<SelectionState> = new EventEmitter<SelectionState>();
    @Output() deselected: EventEmitter<string[]> = new EventEmitter();

    @ViewChild('dropdownContainer') dropdownContainer: ElementRef;
    @ViewChild('dropdownOptionsList') optionsListContainer: ElementRef;

    opened = false;
    selectedOptionValues: string[] = [];
    hoverOptionIndex = 0;
    private closeDropdownSubscription: Subscription;

    searchValue: string = '';
    placeholderToShow: string = this.placeholderText;

    selectedState: SelectionState = SelectionState.INDIVIDUAL;
    previousSelectedState: string = 'INDIVIDUAL';

    deselectedOptions: string[] = [];

    private _options: MultiselectDropdownOptionModel[] = [];
    private _filteredOptions: MultiselectDropdownOptionModel[] = [];
    private registerOnChangeFunc: (values: string[]) => void;

    constructor(private cdr: ChangeDetectorRef, private closeDropdownService: CloseDropdownOnScrollService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.openedOnInit) {
            this.opened = this.openedOnInit;
        }

        if (changes.preSelectedValues) {
            this.evaluateSelectedValues(this.preSelectedValues);
        }

        if (changes.options) {

            // Check if options are null
            this._options = this.options ? [...this.options] : [];

            // Sort options, before adding option "All"
            if (this.sort) {
                if (this.sort === SORT_ASC) {
                    this._options.sort((o1, o2) => (o1.label.toLowerCase() > o2.label.toLowerCase()) ? 1 : -1);
                } else if (this.sort === SORT_DESC) {
                    this._options.sort((o1, o2) => (o1.label.toLowerCase() > o2.label.toLowerCase()) ? -1 : 1);
                }
            }

            // Workaround: Option "All" is sometimes added explicitly, as valid option, so even if not "allOptionsEnabled", it can still be present; put it at the first place
            let allOptionIndex = this._options.findIndex(o => o.label === "All");
            if (allOptionIndex !== -1) {
                let allOption: MultiselectDropdownOptionModel = this._options[allOptionIndex];
                this._options.splice(allOptionIndex, 1);
                this._options.unshift(allOption);
            }

            if (this.allOptionEnabled && this._options.length !== 0 && !this.searchValue) {
                this._options.unshift(new MultiselectDropdownOptionModel("All", OPTION_ALL));
            }

            this._filteredOptions = this._options;
            let preSelectedValuesFiltered = this.preSelectedValues.filter(value => {
                return this._filteredOptions.some(item => item.value === value.toString());
              });

            if (this._filteredOptions.length != preSelectedValuesFiltered.length) {
                preSelectedValuesFiltered = preSelectedValuesFiltered.filter(function(value) {
                    return value !== "OPTION_ALL";
                  });
            }
            this.evaluateSelectedValues(this.selectedOptionValues);
        }

        if (changes.positionedOptionsList) {
            if (this.positionedOptionsList) {
                this.closeDropdownSubscription = this.closeDropdownService.parentContainerScrollOccurred()
                    .subscribe(() => {
                        if (this.opened && this.positionedOptionsList) {
                            this.closeDropdown();
                            this.cdr.detectChanges();
                        }
                    });
            } else {
                if (this.closeDropdownSubscription) {
                    this.closeDropdownSubscription.unsubscribe();
                }
            }
        }
    }

    ngOnDestroy() {
        if (this.closeDropdownSubscription) {
            this.closeDropdownSubscription.unsubscribe();
        }
    }

    hasToBeDisabled(): boolean {
        if (this.loading) {
            return true;
        } else {
            if (this._options) {
                return this._options.length === 0 && this.searchValue.trim().length === 0;
            } else {
                return true;
            }
        }
    }

    getOptions() {
        if (this.autocomplete) {
            return this._filteredOptions;
        } else {
            return this._options;
        }
    }

    getOptionId(index: number, option: MultiselectDropdownOptionModel) {
        return option.value;
    }

    isOptionActive(option: MultiselectDropdownOptionModel): boolean {
        return this.selectedOptionValues.some(value => option.value === value);
    }

    toggleDropdown() {
        this.opened = !this.opened;

        if (this.opened) {
            this.openDropdown();
        } else {
            this.closeDropdown();
        }
    }

    openDropdown() {
        this.searchValue = '';
        this.placeholderToShow = '';
        this.resetAutocompleteInput();
        this.calculateOptionsListPosition();

        const customFocus = document.createEvent('HTMLEvents');
        customFocus.initEvent('focus', true, true);
        this.dropdownContainer.nativeElement.dispatchEvent(customFocus);
    }

    closeDropdown() {
        this.opened = false;
        this.placeholderToShow = this.getPrintValue();
        this.searchValue = '';
        

        const customBlur = document.createEvent('HTMLEvents');
        customBlur.initEvent('blur', true, true);
        this.dropdownContainer.nativeElement.dispatchEvent(customBlur);
    }

    dropdownOptionClick(selectedDropdownOption: MultiselectDropdownOptionModel, event?) {

        event.stopPropagation();

        let value = selectedDropdownOption.value;

        if (value) {
            value = String(value);
        } else {
            value = null;
        }

        const index = this.selectedOptionValues.findIndex(item => item === value);
        let values: string[] = [...this.selectedOptionValues];

        if (value === OPTION_ALL && this.searchValue.trim() !== '') {
            return;
        }

        if (index === -1) {
            values.push(value);
            if(value === OPTION_ALL) {

                this.selectedState = SelectionState.ALL;
            }
        } else if (value === OPTION_ALL) {
            this.selectedState = SelectionState.INDIVIDUAL;
            values = [];
        } else {
            values.splice(index, 1);
            if (this.previousSelectedState == SelectionState.ALL) {
                this.selectedState = SelectionState.PARTIAL_ALL;
            } else if (this.previousSelectedState == SelectionState.PARTIAL_ALL) {
                this.selectedState = SelectionState.PARTIAL_ALL;
            } else {
                this.selectedState = SelectionState.INDIVIDUAL;
            }
        }

        if (this.allOptionEnabled) {

            if (values.length !== 0) {

                if (!values.some(v => v === OPTION_ALL) && values.length === this.options.length) {
                    values.push(OPTION_ALL);
                    this.selectedState = SelectionState.ALL;
                }

                if (value !== OPTION_ALL && values.some(v => v === OPTION_ALL) && values.length - 1 !== this.options.length) {
                    values = values.filter(v => v !== OPTION_ALL);
                }
            }
        }

        this.previousSelectedState = this.selectedState;
        
        const filteredSelectedValues: string[] = this.selectedOptionValues.filter((v: string) => v !== OPTION_ALL);

        if(this.searchValue.trim() !== '') {
            this.selectedState = SelectionState.INDIVIDUAL;
        }
        
        this.evaluateSelectedValues(values);
        // Emit new values - form
        if (this.registerOnChangeFunc) {
            this.registerOnChangeFunc(filteredSelectedValues);
        }
    }

    getPrintValue() {
        if (this.loading) {
            return 'Loading...';
        } else {
            if (this.opened) {
                return '';
            } else {
                const filteredValues = this.selectedOptionValues.filter(value => value !== OPTION_ALL);
    
                if (filteredValues.length === 0) {
                    return this.placeholderText;
                } else if (filteredValues.length === 1) {
                    const selectedOption: MultiselectDropdownOptionModel = this._options.find((option: MultiselectDropdownOptionModel) => option.value === filteredValues[0]);
                    return selectedOption ? selectedOption.label : '';
                } else {
                    if (this.selectedState === SelectionState.ALL && this.totalCount != null) {
                        return 'Multiple: ' + this.totalCount + ' items';
                    } else if (this.selectedState === SelectionState.PARTIAL_ALL && this.totalCount != null) {
                        return 'Multiple: ' + (this.totalCount - this.deselectedOptions.length) + ' items';
                    } else {
                        return 'Multiple: ' + filteredValues.length + ' items';
                    }
                }
            }
        }
    }    

    onAutocompleteInput(e) {
        const searchParam = e.target.value;
        this.filterDropdownOptionsValue(searchParam);
        this.search.emit(searchParam);
    }

    private resetAutocompleteInput() {
        this._filteredOptions = this._options;
    }

    private filterDropdownOptionsValue(param: string) {
        const searchParam = param.trim().toLowerCase();

        this._filteredOptions = this._options.filter((option: MultiselectDropdownOptionModel) => {
            // check if existing option matches search param
            if (option.label.toLowerCase().indexOf(searchParam) > -1) {
                return true;
            } else {
                this.hoverOptionIndex = 0;
                return false;
            }
        }).map((option: MultiselectDropdownOptionModel) => {
            return this.highlightMatchingString(option, searchParam);
        });
    }

    private highlightMatchingString(dropdownOption: MultiselectDropdownOptionModel, searchValue: string): MultiselectDropdownOptionModel {
        const value = dropdownOption.value;

        const searchValueLength: number = searchValue.length;
        const indexOfSearchValue: number = dropdownOption.label.toLowerCase().indexOf(searchValue);
        const foundSearchValue = dropdownOption.label.substr(indexOfSearchValue, searchValueLength);

        const newLabel = dropdownOption.label.replace(foundSearchValue, `<b>${foundSearchValue}</b>`);
        return new MultiselectDropdownOptionModel(newLabel, value);
    }

    private evaluateSelectedValues(values: string[]) {

        if (this.searchValue.trim() !== '') {
            values = values.filter(value => value !== OPTION_ALL);
        }
    
        if (this.selectedState == SelectionState.ALL) {
            this._options.forEach(option => {
                if (!this.selectedOptionValues.includes(option.value)) {
                    this.selectedOptionValues.push(option.value);
                }
            });
            this.deselectedOptions = [];
        
        } else if (this.selectedState == SelectionState.PARTIAL_ALL) {
            const newlyDeselected = this.selectedOptionValues.filter(value => !values.includes(value) && value !== OPTION_ALL);

            this.deselectedOptions = Array.from(new Set([...this.deselectedOptions, ...newlyDeselected]));

            this.selectedOptionValues = values.map(value => String(value));

        } else {
            this.selectedOptionValues = values ? values.map(value => String(value)) : [];
            this.deselectedOptions = [];
        }
        
        this.deselected.emit(this.deselectedOptions);
        this.selectedType.emit(this.selectedState);
        this.changed.emit(this.selectedOptionValues);
    }  

    private calculateOptionsListPosition() {

        if (!this.positionedOptionsList) {
            return;
        }

        const optionListContainerNativeElement = this.optionsListContainer.nativeElement;
        const dropdownContainerNativeElement = this.dropdownContainer.nativeElement;

        optionListContainerNativeElement.style.minWidth = dropdownContainerNativeElement.offsetWidth + "px";
        optionListContainerNativeElement.style.top = dropdownContainerNativeElement.getBoundingClientRect().top + dropdownContainerNativeElement.offsetHeight + "px";
        optionListContainerNativeElement.style.left = dropdownContainerNativeElement.getBoundingClientRect().left + "px";
    }

    public writeValue(values: string[]) {
        if (values) {
            this.preSelectedValues = values;
            this.evaluateSelectedValues(values);
        }

        this.cdr.detectChanges();
    }

    public registerOnChange(fn: (values: string[]) => {}) {
        this.registerOnChangeFunc = fn;
    }

    public registerOnTouched() {
    }

    onDropdownScroll(event: Event): void {
        const target = event.target as HTMLElement;
        const scrollPosition = target.scrollTop + target.clientHeight;
        const scrollHeight = target.scrollHeight;
    
        if (scrollPosition >= scrollHeight - 50) {
            this.scrolled.emit();
        }
    }
}
