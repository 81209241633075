export class CreateKioskConfigurationDropoffLocationProductRequestModel {
    productId: number;
    isReturnTrip: boolean;
    pickupLocationId: number;
    dropoffLocationId: number;
    sortOrder: number;

    constructor(productId: number, isReturnTrip: boolean, pickupLocationId: number, dropoffLocationId: number, sortOrder: number) {
        this.productId = productId;
        this.isReturnTrip = isReturnTrip;
        this.pickupLocationId = pickupLocationId;
        this.dropoffLocationId = dropoffLocationId;
        this.sortOrder = sortOrder;
    }
}
