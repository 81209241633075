import {Component, EventEmitter, Input, Output} from '@angular/core';
import {KioskConfigurationDropoffLocationProductTierModel} from "../../model/kiosk-configuration-dropoff-location-product-tier.model";
import _ from 'lodash';
import {TierUpdatedData} from "../product-tier-dialog-tier-item/product-tier-dialog-item.component";

@Component({
    selector: 'app-product-tier-setup-dialog-form',
    templateUrl: './product-tier-setup-dialog-form.component.html'
})
export class ProductTierSetupDialogFormComponent {

    @Input() productTiers: KioskConfigurationDropoffLocationProductTierModel[];
    @Input() roundTrip: boolean;

    @Output() submitEvent = new EventEmitter<KioskConfigurationDropoffLocationProductTierModel[]>();
    @Output() closeEvent = new EventEmitter<void>();

    errorMessage: string = null;
    disabledSaveButton: boolean = false;

    constructor() {
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        this.errorMessage = null;

        for (let productTier of this.productTiers) {
            if (productTier.minQuantity) {
                if (productTier.defaultQuantity < productTier.minQuantity) {
                    this.errorMessage = "Default quantity must be greater than or equal to min quantity";
                    return;
                }
            }

            if (productTier.maxQuantity) {
                if (productTier.defaultQuantity > productTier.maxQuantity) {
                    this.errorMessage = "Default quantity must be less than or equal to max quantity";
                    return;
                }
            }

            if (productTier.minQuantity > productTier.maxQuantity) {
                this.errorMessage = "Min quantity must be less than or equal to max quantity";
                return;
            }
        }

        // Get only tiers that have default quantity
        this.productTiers = this.productTiers.filter((productTier) => {
            return productTier.defaultQuantity !== null && productTier.defaultQuantity >= 0;
        });

        this.submitEvent.emit(this.productTiers);
    }

    defaultTierQuantityChanged(data: TierUpdatedData) {
        const productTier: KioskConfigurationDropoffLocationProductTierModel = this.getProductTierById(data.tierId);
        productTier.defaultQuantity = Number(data.value);
        this.updateProductTierInArray(productTier);
    }

    minTierQuantityChanged(data: TierUpdatedData) {
        const productTier: KioskConfigurationDropoffLocationProductTierModel = this.getProductTierById(data.tierId);
        productTier.minQuantity = Number(data.value);
        this.updateProductTierInArray(productTier);
    }

    maxTierQuantityChanged(data: TierUpdatedData) {
        const productTier: KioskConfigurationDropoffLocationProductTierModel = this.getProductTierById(data.tierId);
        productTier.maxQuantity = Number(data.value);
        this.updateProductTierInArray(productTier);
    }

    private getProductTierById(tierId: number): KioskConfigurationDropoffLocationProductTierModel {
        return _.find(this.productTiers, (productTier: KioskConfigurationDropoffLocationProductTierModel) => productTier.tierId === tierId);
    }

    private updateProductTierInArray(newProductTier: KioskConfigurationDropoffLocationProductTierModel) {
        const index = _.findIndex(this.productTiers, (productTier: KioskConfigurationDropoffLocationProductTierModel) => productTier.tierId === newProductTier.tierId);

        this.productTiers = [
            ... this.productTiers.slice(0, index),
            newProductTier,
            ... this.productTiers.slice(index + 1)
        ];
    }
}
