import {DiscountResponseModel} from "../../../../../../../../core/modules/rest/cost/response/discount-response.model";
import {CostTypeEnum} from "../../../../../../../../shared/enums/cost-type.enum";
import {AmountTypeEnum} from "../../../../../../../../shared/enums/amount-type.enum";
import {PlatformEnum} from "../../../../../../../../shared/enums/platform.enum";

export class DiscountCostModel implements DiscountResponseModel {
    costId: number;
    costType: CostTypeEnum;
    description: string;
    amount: number;
    amountType: AmountTypeEnum;
    isOrderLevel: boolean;
    glCode: string;
    discountCode: string;
    startDateInt: number;
    startDateFriendly: string;
    startDateMS: number;
    endDateInt: number;
    endDateFriendly: string;
    endDateMS: number;
    allPlatforms: boolean;
    platformDescription: string;
    platforms: PlatformEnum[];
    active: boolean;

    constructor() {
    }
}
