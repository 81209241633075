import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SystemOptionsResponseModel} from "./response/system-options-response.model";
import {environment} from "../../../../../environments/environment";
import {ImageResponseModel} from "../image-response.model";

@Injectable()
export class SystemOptionsRestService {

    constructor(private httpClient: HttpClient) {
    }

    getSystemOptions(): Observable<SystemOptionsResponseModel> {
        return this.httpClient.get<SystemOptionsResponseModel>(environment.server_api_url + '/api/secured/systemOptions/getSystemOptions');
    }

    updateSystemOptions(request: any): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/systemOptions/updateSystemOptions', request, {responseType: 'text'});
    }

    updateCompanyLogo(request: FormData): Observable<ImageResponseModel> {
        return this.httpClient.post<ImageResponseModel>(environment.server_api_url + '/api/secured/systemOptions/updateCompanyLogo', request);
    }

    removeCompanyLogo(): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/systemOptions/removeCompanyLogo', null, {responseType: 'text'});
    }

    updateAndroidLogo(request: FormData): Observable<ImageResponseModel> {
        return this.httpClient.post<ImageResponseModel>(environment.server_api_url + '/api/secured/systemOptions/updateAndroidLogo', request);
    }

    removeAndroidLogo(): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/systemOptions/removeAndroidLogo', null, {responseType: 'text'});
    }
}
