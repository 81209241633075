import { Component, EventEmitter, Output, ViewChild, ElementRef, ChangeDetectorRef, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnChanges {

  @Input() resetSearch: boolean = false;
  @Input() placeholder: string = '';
  @Output() searchTermChanged = new EventEmitter<string>();
  @ViewChild('searchInput') searchInput: ElementRef;

  searchTerm: string = '';
  isSearchOpen: boolean = false;
  isLoading: boolean = false;
  multipleResults: boolean = false;
  noResults: boolean = false;
  searchDisabled: boolean = false;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetSearch && changes.resetSearch.currentValue === true) {
      this.searchTerm = '';
    }
  }

  openSearch(event?: Event): void {
    if (event) {
      event.stopPropagation();
    }
    if (!this.isSearchOpen) {
      this.isSearchOpen = true;
      setTimeout(() => {
        this.searchInput?.nativeElement.focus();
      }, 100);
      this.cdRef.detectChanges();
    }
  }  

  cleanSearchBox(): void {
    this.searchTerm = '';
    this.multipleResults = false;
    this.isSearchOpen = false;
    this.noResults = false;
    this.searchDisabled = false;
  }

  closeSearchBar(): void {
    this.isSearchOpen = false;
    this.multipleResults = false;
    this.noResults = false;
    this.searchDisabled = false;
    this.cdRef.detectChanges();
  }

  onSearchChanged(): void {
    this.noResults = false;
    this.searchTermChanged.emit(this.searchTerm);
  }

  toggleSearch(event?: Event): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.isSearchOpen) {
      this.closeSearchBar();
    } else {
      this.isSearchOpen = true;
      setTimeout(() => {
        this.searchInput?.nativeElement.focus();
      }, 100);
    }
    this.cdRef.detectChanges();
  }
  
}
