import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {HolidayResponseModel} from "../../../../../../../../../core/modules/rest/holiday/response/holiday-response.model";
import {CategoryResponseModel} from "../../../../../../../../../core/modules/rest/category/response/category-response.model";
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {ProductAvailabilityHolidayMappingResponseModel} from "../../../../../../../../../core/modules/rest/product-availability/response/product-availability-holiday-mapping-response.model";

export const REDUCER_NAME = 'dialog_mapping-holiday';

export interface State {
    holiday: HolidayResponseModel;
    categories: CategoryResponseModel[];
    categoriesLoading: boolean;
    products: ProductSimpleResponseModel[];
    productsLoading: boolean;
    productAvailabilityHolidayMappingItems: ProductAvailabilityHolidayMappingResponseModel[];
    productAvailabilityHolidayMappingItemsLoading: boolean;
}

const initialState: State = {
    holiday: {} as HolidayResponseModel,
    categories: [],
    categoriesLoading: false,
    products: [],
    productsLoading: false,
    productAvailabilityHolidayMappingItems: [],
    productAvailabilityHolidayMappingItemsLoading: false
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const holiday_selector = createSelector(state_selector, (state: State) => state.holiday);

export const categories_selector = createSelector(state_selector, (state: State) => state.categories);
export const categories_loading_selector = createSelector(state_selector, (state: State) => state.categoriesLoading);

export const products_selector = createSelector(state_selector, (state: State) => state.products);
export const products_loading_selector = createSelector(state_selector, (state: State) => state.productsLoading);

export const product_availability_hmi_selector = createSelector(state_selector, (state: State) => state.productAvailabilityHolidayMappingItems);
export const product_availability_hmi_loading_selector = createSelector(state_selector, (state: State) => state.productAvailabilityHolidayMappingItemsLoading);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_HOLIDAY_ACTION:  return { ... state, holiday: action.holiday };

        case actions.SET_CATEGORIES_ACTION: return { ... state, categories: action.categories };

        case actions.SET_CATEGORIES_LOADING_ACTION: return { ... state, categoriesLoading: action.loading };

        case actions.SET_PRODUCTS_ACTION: return { ... state, products: action.products };

        case actions.SET_PRODUCTS_LOADING_ACTION: return { ... state, productsLoading: action.loading };

        case actions.SET_PRODUCT_AVAILABILITY_HM_ITEMS_ACTION: return { ... state, productAvailabilityHolidayMappingItems: action.productAvailabilityItemsHolidayMappingItems };

        case actions.SET_PRODUCT_AVAILABILITY_HM_ITEMS_LOADING_ACTION: return { ... state, productAvailabilityHolidayMappingItemsLoading: action.loading };

        default: return state;
    }
}
