<app-abstract-dialog-container [shouldOverrideCloseClicked]="true" (closeClicked)="onCloseClicked()">
    <div class="form_container">
        <!-- Header -->
        <div class="header_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <h1>{{ header }}</h1>
                </div>
            </div>
        </div>

        <!-- Message -->
        <div class="content_wrapper">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12 fs-16">
                    {{ message }}
                </div>
            </div>
        </div>

        <!-- Input -->
        <div class="content_wrapper" style="margin-top: 20px;">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12 fs-16">
                    <div class="datepicker_wrapper single-day-datepicker single-date-mode-datepicker">
                        <div>
                            <app-input-label-group>
                                <app-datetimepicker
                                    class="main--element clear-none date-input-width"
                                    [isSingleDatePicker]="isSingleDatePicker"
                                    [opensPosition]="'right'"
                                    [placeholderText]="placeholderText"
                                    (dateChanged)="valueChanged($event)"
                                >
                                </app-datetimepicker>
                            </app-input-label-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer container -->
        <div class="footer_wrapper flex-container" style="display: flex; justify-content: center;">
            <!-- No button -->
            <app-button class="mr-16px"
                        [buttonType]="'button ghost-button'"
                        (clickEvent)="onNoClicked()">
                {{ noLabel }}
            </app-button>

            <!-- Yes button -->
            <app-button [disabled]="!selected" [buttonType]="buttonType"
                        (clickEvent)="onYesClicked()">
                {{ yesLabel }}
            </app-button>
        </div>
    </div>
</app-abstract-dialog-container>
