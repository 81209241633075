import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {QuickSellingButtonProductResponseModel} from "../../../../../../../../../core/modules/rest/quick-selling-button-product/response/quick-selling-button-product-response.model";
import {TierResponseModel} from "../../../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {CategoryResponseModel} from "../../../../../../../../../core/modules/rest/category/response/category-response.model";
import {LocationListItemDescriptorResponseModel} from "../../../../../../../../../core/modules/rest/location-list/response/location-list-item-descriptor-response.model";
import {StartTimeResponseModel} from '../../../../../../../../../core/modules/rest/product-availability/response/start-time-response.model';

export const REDUCER_NAME = 'dialog_edit-quick-selling-button-products';

export interface State {
    quickSellingButtonProduct: QuickSellingButtonProductResponseModel;
    tiers: TierResponseModel[];
    tiersLoading: boolean;
    categories: CategoryResponseModel[];
    categoriesLoading: boolean;
    product: ProductSimpleResponseModel;
    products: ProductSimpleResponseModel[];
    productsLoading: boolean;
    pickupLocations: LocationListItemDescriptorResponseModel[];
    pickupLocationsLoading: boolean;
    dropoffLocations: LocationListItemDescriptorResponseModel[];
    dropoffLocationsLoading: boolean;
    startTimes: StartTimeResponseModel[];
    startTimesLoading: boolean;
    startTimesReturnTrip: StartTimeResponseModel[];
    startTimesReturnTripLoading: boolean;
    errorMessage: string;
}

const initialState: State = {
    quickSellingButtonProduct: {} as QuickSellingButtonProductResponseModel,
    tiers: [],
    tiersLoading: false,
    categories: [],
    categoriesLoading: false,
    product: {
        usesPickupLocations: true,
        usesDropoffLocations: true,
        isRoundTripProduct: true
    } as ProductSimpleResponseModel,
    products: [],
    productsLoading: false,
    pickupLocations: [],
    pickupLocationsLoading: false,
    dropoffLocations: [],
    dropoffLocationsLoading: false,
    startTimes: [],
    startTimesLoading: false,
    startTimesReturnTrip: [],
    startTimesReturnTripLoading: false,
    errorMessage: ""
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const quick_selling_button_product_selector = createSelector(state_selector, (state: State) => state.quickSellingButtonProduct);

export const tiers_selector = createSelector(state_selector, (state: State) => state.tiers);
export const tiers_loading_selector = createSelector(state_selector, (state: State) => state.tiersLoading);

export const categories_selector = createSelector(state_selector, (state: State) => state.categories);
export const categories_loading_selector = createSelector(state_selector, (state: State) => state.categoriesLoading);

export const products_selector = createSelector(state_selector, (state: State) => state.products);
export const products_loading_selector = createSelector(state_selector, (state: State) => state.productsLoading);

export const pickup_locations_selector = createSelector(state_selector, (state: State) => state.pickupLocations);
export const pickup_locations_loading_selector = createSelector(state_selector, (state: State) => state.pickupLocationsLoading);

export const dropoff_locations_selector = createSelector(state_selector, (state: State) => state.dropoffLocations);
export const dropoff_locations_loading_selector = createSelector(state_selector, (state: State) => state.dropoffLocationsLoading);

export const start_times_selector = createSelector(state_selector, (state: State) => state.startTimes);
export const start_times_loading_selector = createSelector(state_selector, (state: State) => state.startTimesLoading);

export const start_times_return_trip_selector = createSelector(state_selector, (state: State) => state.startTimesReturnTrip);
export const start_times_return_trip_loading_selector = createSelector(state_selector, (state: State) => state.startTimesReturnTripLoading);

export const product_selector = createSelector(state_selector, (state: State) => state.product);

export const error_message_selector = createSelector(state_selector, (state: State) => state.errorMessage);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION:
            return initialState;

        case actions.SET_DATA_ACTION:
            return {
                ...state,
                quickSellingButtonProduct: action.quickSellingButtonProduct,
                tiers: action.tiers,
                categories: action.categories,
                products: action.products,
                pickupLocations: action.pickupLocations,
                dropoffLocations: action.dropoffLocations,
                startTimes: action.startTimes,
                startTimesReturnTrip: action.startTimesReturnTrip
            };

        case actions.SET_PRODUCT_ACTION:
            return {
                ...state,
                product: action.product
            };

        case actions.SET_PRODUCTS_ACTION:
            return {
                ...state,
                products: action.products
            };

        case actions.SET_PRODUCTS_LOADING_ACTION:
            return {
                ...state,
                productsLoading: action.loading
            };

        case actions.SET_PICKUP_LOCATIONS_ACTION:
            return {
                ...state,
                pickupLocations: action.pickupLocations
            };

        case actions.SET_PICKUP_LOCATIONS_LOADING_ACTION:
            return {
                ...state,
                pickupLocationsLoading: action.loading
            };

        case actions.SET_DROPOFF_LOCATIONS_ACTION:
            return {
                ...state,
                dropoffLocations: action.dropoffLocations
            };

        case actions.SET_DROPOFF_LOCATIONS_LOADING_ACTION:
            return {
                ...state,
                dropoffLocationsLoading: action.loading
            };

        case actions.SET_START_TIMES_ACTION:
            return {
                ...state,
                startTimes: action.startTimes
            };

        case actions.SET_START_TIMES_LOADING_ACTION:
            return {
                ...state,
                startTimesLoading: action.loading
            };

        case actions.SET_START_TIMES_RETURN_TRIP_ACTION:
            return {
                ...state,
                startTimesReturnTrip: action.startTimesReturnTrip
            };

        case actions.SET_START_TIMES_RETURN_TRIP_LOADING_ACTION:
            return {
                ...state,
                startTimesReturnTripLoading: action.loading
            };

        default:
            return state;
    }
}
