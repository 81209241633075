<button class="purchase-form-circle-button" (click)="onDecClick()" [disabled]="decDisabled">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 65">
        <path class="shp0" d="M59.36,26h-55.71c-2.56,0 -4.64,2.08 -4.64,4.64v4.64c0,2.56 2.08,4.64 4.64,4.64h55.71c2.56,0 4.64,-2.08 4.64,-4.64v-4.64c0,-2.56 -2.08,-4.64 -4.64,-4.64z"></path>
    </svg>
</button>

<input #valueInput
       type="number"
       [min]="minValue"
       [max]="maxValue"
       [(ngModel)]="value"
       (input)="onInputChanged()"
       class="purchase-form-counts-input"/>

<button class="purchase-form-circle-button" (click)="onIncClick()" [disabled]="incDisabled">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 65" class="">
        <path class="shp0" d="M60.36,25.54h-20.89v-20.89c0,-2.56 -2.08,-4.64 -4.64,-4.64h-4.64c-2.56,0 -4.64,2.08 -4.64,4.64v20.89l-20.89,0c-2.56,0 -4.64,2.08 -4.64,4.64v4.64c0,2.56 2.08,4.64 4.64,4.64h20.89v20.89c0,2.56 2.08,4.64 4.64,4.64h4.64c2.56,0 4.64,-2.08 4.64,-4.64v-20.89h20.89c2.56,0 4.64,-2.08 4.64,-4.64v-4.64c0,-2.56 -2.08,-4.64 -4.64,-4.64z"></path>
    </svg>
</button>
