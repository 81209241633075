import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './shared/components/page-not-found/page-not-found.component';
import {PreloadUserDataGuard} from './shared/guards/preload-user-data.guard';

const routes: Routes = [
    {path: '', redirectTo: 'secured', pathMatch: 'full'},
    {path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)},
    {path: 'secured', loadChildren: () => import('./modules/secured/secured.module').then(m => m.SecuredModule), canActivate: [PreloadUserDataGuard]},
    {path: 'style-guide', loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuideModule)},
    {path: 'page-not-found', component: PageNotFoundComponent},
    {path: '**', redirectTo: 'page-not-found'}
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, useHash: true})
    ],
    exports: [
        RouterModule,
    ],
    declarations: [
        PageNotFoundComponent
    ],
    providers: [
        PreloadUserDataGuard
    ]
})
export class AppModuleRoutes {
}
