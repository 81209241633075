export class UpdateProductBusMappingRequestModel {
    busId: number;
    unassignedProductIds: number[];
    assignedProductIds: number[];

    constructor(busId: number, unassignedProductIds: number[], assignedProductIds: number[]) {
        this.busId = busId;
        this.unassignedProductIds = unassignedProductIds;
        this.assignedProductIds = assignedProductIds;
    }
}
