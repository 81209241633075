import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BidResponseModel} from "../../../../../../../../../core/modules/rest/bid/response/bid-response.model";


@Component({
    selector: 'app-edit-bid-form',
    templateUrl: './edit-bid-form.component.html'
})
export class EditBidFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() bid: BidResponseModel;

    @Output() submitEvent = new EventEmitter<string>();
    @Output() closeEvent  = new EventEmitter<void>();

    form: FormGroup;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.form && this.editMode && changes.bid !== null && Object.keys(changes.bid.currentValue).length !== 0) {
            this.form.patchValue({
                description: changes.bid.currentValue.description
            });
        }
    }

    ngOnInit() {

        let description = '';

        if (this.editMode) {
            description = this.bid.description;
        }

        this.form = new FormGroup({
            description: new FormControl(description, Validators.required)
        });
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {
        const description = this.form.value.description;
        this.submitEvent.emit(description);
    }
}
