import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {CreateBaseResponseModel} from '../create-base-response.model';
import {CreateKioskDeviceInfoRequestModel} from './request/create-kiosk-device-info-request.model';
import {UpdateKioskDeviceInfoRequestModel} from './request/update-kiosk-device-info-request.model';
import {KioskDeviceInfoResponseModel} from "./response/kiosk-device-info-response.model";
import {UpdateKioskDeviceInfoConfigurationRequestModel} from "./request/update-kiosk-device-info-configuration-request.model";

@Injectable()
export class KioskDeviceInfoRestService {

    constructor(private httpClient: HttpClient) {
    }

    getById(kioskDeviceInfoId: number): Observable<KioskDeviceInfoResponseModel> {
        return this.httpClient.get<KioskDeviceInfoResponseModel>(environment.server_api_url + `/api/secured/kioskDeviceInfo/getById/${kioskDeviceInfoId}`);
    }

    getAll(): Observable<KioskDeviceInfoResponseModel[]> {
        return this.httpClient.get<KioskDeviceInfoResponseModel[]>(environment.server_api_url + '/api/secured/kioskDeviceInfo/getAll');
    }

    create(data: CreateKioskDeviceInfoRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/kioskDeviceInfo/create', data);
    }

    update(data: UpdateKioskDeviceInfoRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/kioskDeviceInfo/update', data, {responseType: 'text'});
    }

    updateKioskConfigurationId(data: UpdateKioskDeviceInfoConfigurationRequestModel) {
        return this.httpClient.post(environment.server_api_url + '/api/secured/kioskDeviceInfo/updateKioskConfigurationId', data, {responseType: 'text'});
    }

    activate(kioskId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/kioskDeviceInfo/activate', kioskId, {responseType: 'text'});
    }

    deactivate(kioskId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/kioskDeviceInfo/deactivate', kioskId, {responseType: 'text'});
    }

    getKioskDeviceInfosByKioskConfigurationId(kioskConfigurationId: number): Observable<KioskDeviceInfoResponseModel[]> {
        return this.httpClient.get<KioskDeviceInfoResponseModel[]>(environment.server_api_url + '/api/secured/kioskDeviceInfo/getKioskDeviceInfosByKioskConfigurationId/' + kioskConfigurationId);
    }

    getKioskDeviceInfosWithoutKioskConfiguration(): Observable<KioskDeviceInfoResponseModel[]> {
        return this.httpClient.get<KioskDeviceInfoResponseModel[]>(environment.server_api_url + '/api/secured/kioskDeviceInfo/getKioskDeviceInfosWithoutKioskConfiguration');
    }
}
