import * as actions from './actions';
import {KioskConfigurationResponseModel} from "../../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-response.model";
import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";
import {createFeatureSelector, createSelector} from "@ngrx/store";

export const REDUCER_NAME = "dialog_create/edit-kiosk-configuration";

export interface State {
    kioskConfiguration: KioskConfigurationResponseModel;
    locations: LocationResponseModel[];
}

const initialState: State = {
    kioskConfiguration: {} as KioskConfigurationResponseModel,
    locations: []
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const kioskConfiguration_selector = createSelector(state_selector, (state: State) => state.kioskConfiguration);
export const locations_selector = createSelector(state_selector, (state: State) => state.locations);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_KIOSK_CONFIGURATION: return { ... state, kioskConfiguration: action.kioskConfiguration };

        case actions.SET_LOCATIONS: return { ... state, locations: action.locations };

        default: return state;
    }
}
