import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";
import {IncludedCostModel} from "../../model/included-cost.model";
import {createFeatureSelector, createSelector} from "@ngrx/store";

import * as actions from './actions';

export interface State {
    dialogDataLoaded: boolean;
    editIncludedCost: boolean;
    includedCost: IncludedCostModel;
    allLocations: LocationResponseModel[];
}

const initialState: State = {
    dialogDataLoaded: false,
    editIncludedCost: false,
    includedCost: new IncludedCostModel(),
    allLocations: [],
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.UPDATE_DIALOG_DATA_LOADED_ACTION:
            return handleUpdateDialogDataLoadedAction(state, action);
        case actions.UPDATE_EDIT_INCLUDED_COST_ACTION:
            return handleUpdateEditIncludedCostAction(state, action);
        case actions.RESET_STATE_ACTION:
            return handleResetStateAction(state, action);
        case actions.UPDATE_INCLUDED_COST_ACTION:
            return handleUpdateIncludedCostAction(state, action);
        case actions.UPDATE_ALL_LOCATIONS_ACTION:
            return handleUpdateAllLocationsAction(state, action);
        default:
            return state;
    }
}


// #################################################### SELECTORS ####################################################
export const REDUCER_NAME = 'dialog_edit-included-cost';

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const dialogDataLoaded_selector = createSelector(state_selector, (state: State) => state.dialogDataLoaded);
export const editIncludedCost_selector = createSelector(state_selector, (state: State) => state.editIncludedCost);
export const includedCost_selector = createSelector(state_selector, (state: State) => state.includedCost);
export const allLocations_selector = createSelector(state_selector, (state: State) => state.allLocations);


// #################################################### HANDLERS ####################################################
function handleUpdateDialogDataLoadedAction(state: State, action: actions.UpdateDialogDataLoaded) {
    return {
        ...state,
        dialogDataLoaded: action.value
    };
}

function handleResetStateAction(state: State, action: actions.ResetState) {
    return initialState;
}

function handleUpdateIncludedCostAction(state: State, action: actions.UpdateIncludedCost) {
    return {
        ...state,
        includedCost: action.includedCost
    };
}

function handleUpdateAllLocationsAction(state: State, action: actions.UpdateAllLocations) {
    return {
        ...state,
        allLocations: action.locations
    };
}

function handleUpdateEditIncludedCostAction(state: State, action: actions.UpdateEditIncludedCost) {
    return {
        ...state,
        editIncludedCost: action.value
    };
}
