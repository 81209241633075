import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {ProductTierSetupDialogComponent} from "./container/product-tier-setup-dialog.component";
import {ProductTierSetupDialogSandbox} from "./sandbox/product-tier-setup-dialog.sandbox";
import {StoreModule} from "@ngrx/store";
import {REDUCER_NAME, reducer} from './sandbox/store/reducer';
import { ProductTierSetupDialogFormComponent } from './components/product-tier-setup-dialog-form/product-tier-setup-dialog-form.component';
import {ProductTierDialogItemComponent} from "./components/product-tier-dialog-tier-item/product-tier-dialog-item.component";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        ProductTierSetupDialogSandbox
    ],
    declarations: [
        ProductTierSetupDialogComponent,
        ProductTierSetupDialogFormComponent,
        ProductTierDialogItemComponent
    ],
    exports: [
        ProductTierSetupDialogComponent
    ],
    entryComponents: [
        ProductTierSetupDialogComponent
    ]
})
export class ProductTierSetupDialogModule {

}
