import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {ExpressionOperatorModel} from '../../../../model/expression-operator.model';
import {ExpressionOperatorTypeEnum} from '../../../../model/expression-operator-type.enum';
import {DropdownOptionModel} from '../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model';
import {UpdateExpressionItemValueModel} from '../../../../model/update-expression-item-value.model';

@Component({
    selector: 'app-order-reports-expression-operator',
    templateUrl: './order-reports-expression-operator.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReportsExpressionOperatorComponent implements OnInit {

    @Input() expressionOperatorItem: ExpressionOperatorModel;
    @Input() hasRunOrderReportFullPermission: boolean;
    @Output() operatorValueChanged: EventEmitter<UpdateExpressionItemValueModel> = new EventEmitter<UpdateExpressionItemValueModel>();

    constructor() { }

    ngOnInit() {
    }

    getOptions(): DropdownOptionModel[] {
        return [
            new DropdownOptionModel(ExpressionOperatorTypeEnum.AND, ExpressionOperatorTypeEnum.AND),
            new DropdownOptionModel(ExpressionOperatorTypeEnum.OR, ExpressionOperatorTypeEnum.OR)
        ];
    }

    onChanged(value: string) {
        const operatorType: ExpressionOperatorTypeEnum = <ExpressionOperatorTypeEnum>value;
        const item: UpdateExpressionItemValueModel = new UpdateExpressionItemValueModel(this.expressionOperatorItem.uuid, [operatorType]);
        this.operatorValueChanged.emit(item);
    }
}
