<a class="switch_container" (click)="onClick($event)">
    <div class="switch_content">
        <label for="{{switchIdName}}">
            <input type="checkbox"
                   id="{{switchIdName}}"
                   [checked]="value"
                   (change)="switchCheckedChanged($event)"
                   [disabled]="disabled"/>

            <span class="switch"></span>
            <span class="toggle"></span>
        </label>
    </div>

    <label for="{{switchIdName}}"
           class="switch_label">
        {{switchLabel}}
    </label>
</a>
