import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromRoot from '../../../../../../core/store/reducers';

import * as fromMain from './main/reducer';
import * as fromUi from './ui/reducer';

export const REDUCER_NAME = 'feature_orderReports';

export interface FeatureState {
    ui: fromUi.State;
    main: fromMain.State;
}

export interface State extends fromRoot.AppState {
    REDUCER_NAME: FeatureState;
}

export const reducers: ActionReducerMap<FeatureState> = {
    ui: fromUi.reducer,
    main: fromMain.reducer
};


const tiersState_selector = createFeatureSelector<FeatureState>(REDUCER_NAME);


// ######### MAIN STATE #########
const mainState_selector = createSelector(tiersState_selector, (state: FeatureState) => state.main);

export const mainState_expressionItems_selector = createSelector(mainState_selector, fromMain.expressionItems_selector);
export const mainState_selectedFields_selector = createSelector(mainState_selector, fromMain.selectedFields_selector);
export const mainState_draggedData_selector = createSelector(mainState_selector, fromMain.draggedData_selector);
export const mainState_selectedFavoriteId_selector = createSelector(mainState_selector, fromMain.selectedFavoriteId_selector);
export const mainState_allUserFavorites_selector = createSelector(mainState_selector, fromMain.allUserFavorites_selector);
export const mainState_currentSearchData_selector = createSelector(mainState_selector, fromMain.currentSearchData_selector);
export const mainState_reportItemsSearchText_selector = createSelector(mainState_selector, fromMain.reportItemsSearchText_selector);
export const mainState_showPaymentSummary_selector = createSelector(mainState_selector, fromMain.showPaymentSummary_selector);
export const mainState_showPaymentSummaryDisabled_selecor = createSelector(mainState_selector, fromMain.showPaymentSummaryDisabled_selector);


// ######### UI STATE #########
const uiState_selector = createSelector(tiersState_selector, (state: FeatureState) => state.ui);

export const uiState_availableFieldsLoading_selector = createSelector(uiState_selector, fromUi.availableFieldsLoading_selector);
export const uiState_activeReportGroups_selector = createSelector(uiState_selector, fromUi.activeReportGroups_selector);

