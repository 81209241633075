import { Component, Input, OnInit  } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppRoutesService} from '../../../core/services/app-routes.service';
import {BreadcrumbItemModel} from "./breadcrumb-item.model";


@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html'
})
export class BreadcrumbsComponent implements OnInit {

    @Input() items: BreadcrumbItemModel[];

    constructor(private router: Router, private route: ActivatedRoute, private appRoutesService: AppRoutesService) {
    }

    ngOnInit() {
    }

    goToHomeClick() {
        this.appRoutesService.goToHomePage();
    }
}
