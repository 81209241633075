import { ChangeDetectionStrategy, Component, OnInit  } from '@angular/core';


@Component({
    selector: 'app-order-reports-fields-info-message',
    templateUrl: './order-reports-fields-info-message.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReportsFieldsInfoMessageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
