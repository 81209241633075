const StatusReasonEnum = {
    SERVICE_SITE_SHUTDOWN: 'SERVICE_SITE_SHUTDOWN' as 'SERVICE_SITE_SHUTDOWN',
    CHANGED_THEIR_MIND: 'CHANGED_THEIR_MIND' as 'CHANGED_THEIR_MIND',
    DATA_ENTRY_ERROR: 'DATA_ENTRY_ERROR' as 'DATA_ENTRY_ERROR',
    DIFFERENT_FORM_OF_PAYMENT: 'DIFFERENT_FORM_OF_PAYMENT' as 'DIFFERENT_FORM_OF_PAYMENT',
    DIFFERENT_RATE: 'DIFFERENT_RATE' as 'DIFFERENT_RATE',
    DOUBLE_BOOKED: 'DOUBLE_BOOKED' as 'DOUBLE_BOOKED',
    MISSED_PICKUP: 'MISSED_PICKUP' as 'MISSED_PICKUP',
    OVER_SOLD: 'OVER_SOLD' as 'OVER_SOLD',
    TEST_BOOKING: 'TEST_BOOKING' as 'TEST_BOOKING',
    TRAINING_BOOKING: 'TRAINING_BOOKING' as 'TRAINING_BOOKING',
    UNABLE_TO_TRAVEL: 'UNABLE_TO_TRAVEL' as 'UNABLE_TO_TRAVEL',
    WRONG_DATE_OR_TIME: 'WRONG_DATE_OR_TIME' as 'WRONG_DATE_OR_TIME',
    COURTESY_CANCELLATION: 'COURTESY_CANCELLATION' as 'COURTESY_CANCELLATION',
    TRIP_WAS_CANCELLED: 'TRIP_WAS_CANCELLED' as 'TRIP_WAS_CANCELLED',
    RESERVATION_EXPIRED: 'RESERVATION_EXPIRED' as 'RESERVATION_EXPIRED',
    MANAGER_INSTRUCTED: 'MANAGER_INSTRUCTED' as 'MANAGER_INSTRUCTED',
    CUSTOMER_ERROR: 'CUSTOMER_ERROR' as 'CUSTOMER_ERROR',
    SYSTEM_ERROR: 'SYSTEM_ERROR' as 'SYSTEM_ERROR',
    KIOSK_ERROR: 'KIOSK_ERROR' as 'KIOSK_ERROR'
};
type StatusReasonEnum = (typeof StatusReasonEnum)[keyof typeof StatusReasonEnum];
export {StatusReasonEnum};

export function getDescription(statusReason: StatusReasonEnum): string {

    let statusReasonDescription: string = null;

    switch (statusReason) {
        case StatusReasonEnum.SERVICE_SITE_SHUTDOWN:
            statusReasonDescription = "Service/Site Shutdown";
            break;
        case StatusReasonEnum.CHANGED_THEIR_MIND:
            statusReasonDescription = "Changed Their Mind";
            break;
        case StatusReasonEnum.DATA_ENTRY_ERROR:
            statusReasonDescription = "Data Entry Error";
            break;
        case StatusReasonEnum.DIFFERENT_FORM_OF_PAYMENT:
            statusReasonDescription = "Different Form Of Payment";
            break;
        case StatusReasonEnum.DIFFERENT_RATE:
            statusReasonDescription = "Different Rate";
            break;
        case StatusReasonEnum.DOUBLE_BOOKED:
            statusReasonDescription = "Double Booked";
            break;
        case StatusReasonEnum.MISSED_PICKUP:
            statusReasonDescription = "Missed Pickup";
            break;
        case StatusReasonEnum.OVER_SOLD:
            statusReasonDescription = "Over Sold";
            break;
        case StatusReasonEnum.TEST_BOOKING:
            statusReasonDescription = "Test Booking";
            break;
        case StatusReasonEnum.TRAINING_BOOKING:
            statusReasonDescription = "Training Booking";
            break;
        case StatusReasonEnum.UNABLE_TO_TRAVEL:
            statusReasonDescription = "Unable To Travel";
            break;
        case StatusReasonEnum.WRONG_DATE_OR_TIME:
            statusReasonDescription = "Wrong Date or Time";
            break;
        case StatusReasonEnum.COURTESY_CANCELLATION:
            statusReasonDescription = "Courtesy Cancellation";
            break;
        case StatusReasonEnum.TRIP_WAS_CANCELLED:
            statusReasonDescription = "Trip Was Cancelled";
            break;
        case StatusReasonEnum.RESERVATION_EXPIRED:
            statusReasonDescription = "Reservation Expired";
            break;
        case StatusReasonEnum.MANAGER_INSTRUCTED:
            statusReasonDescription = "Manager Instructed";
            break;
        case StatusReasonEnum.CUSTOMER_ERROR:
            statusReasonDescription = "Customer Error";
            break;
        case StatusReasonEnum.SYSTEM_ERROR:
            statusReasonDescription = "System Error";
            break;
        case StatusReasonEnum.KIOSK_ERROR:
            statusReasonDescription = "Kiosk Error";
            break;
    }

    return statusReasonDescription;
}

export function getAllStatusReasons(): StatusReasonEnum[] {

    let allStatusReasons = [];

    for (let statusReason in StatusReasonEnum) {
        if (StatusReasonEnum.hasOwnProperty(statusReason)) {
            allStatusReasons.push(statusReason);
        }
    }

    return allStatusReasons;
}
