export class CreateHolidayRequestModel {
    description: string;
    year: number;
    month: number;
    day: number;

    constructor(description: string, year: number, month: number, day: number) {
        this.description = description;
        this.year = year;
        this.month = month;
        this.day = day;
    }
}
