import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[appKeypressEscDirective]'
})
export class KeypressEscDirective {

    @Output() escKeypress: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    @HostListener('document:keydown', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.escKeypress.emit();
        }
    }
}
