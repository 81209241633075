import {Action} from "@ngrx/store";
import {PassCostResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-cost-response.model";
import {MultiselectDropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

export const RESET_STATE = '[Dialog - Edit Pass Costs :: MAIN] Reset State';
export const SET_LOADING = '[Dialog - Edit Pass Costs :: MAIN] Loading';
export const SET_PASS_ADDITIONAL_COSTS = '[Dialog - Edit Pass Costs :: MAIN] Set Pass Additional Costs';
export const SET_ADDITIONAL_COSTS = '[Dialog - Edit Pass Costs :: MAIN] Set Additional Costs';
export const SET_PASS_INCLUDED_COSTS = '[Dialog - Edit Pass Costs :: MAIN] Set Pass Included Costs';
export const SET_INCLUDED_COSTS = '[Dialog - Edit Pass Costs :: MAIN] Set Included Costs';
export const SET_PASS_DISCOUNTS = '[Dialog - Edit Pass Costs :: MAIN] Set Pass Discounts';
export const SET_DISCOUNTS = '[Dialog - Edit Pass Costs :: MAIN] Set Discounts';
export const SET_PASS_AUTO_APPLIED_DISCOUNTS = '[Dialog - Edit Pass Costs :: MAIN] Set Pass Auto Applied Discounts';
export const SET_AUTO_APPLIED_DISCOUNTS = '[Dialog - Edit Pass Costs :: MAIN] Set Auto Applied Discounts';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetLoading implements Action {
    readonly type = SET_LOADING;
    constructor(public loading: boolean) {}
}

export class SetPassAdditionalCosts implements Action {
    readonly type = SET_PASS_ADDITIONAL_COSTS;

    constructor(public passAdditionalCosts: PassCostResponseModel[]) {
    }
}

export class SetAdditionalCosts implements Action {
    readonly type = SET_ADDITIONAL_COSTS;

    constructor(public additionalCosts: MultiselectDropdownOptionModel[]) {
    }
}

export class SetPassIncludedCosts implements Action {
    readonly type = SET_PASS_INCLUDED_COSTS;

    constructor(public passIncludedCosts: PassCostResponseModel[]) {
    }
}

export class SetIncludedCosts implements Action {
    readonly type = SET_INCLUDED_COSTS;

    constructor(public includedCosts: MultiselectDropdownOptionModel[]) {
    }
}

export class SetPassDiscounts implements Action {
    readonly type = SET_PASS_DISCOUNTS;

    constructor(public passDiscounts: PassCostResponseModel[]) {
    }
}

export class SetDiscounts implements Action {
    readonly type = SET_DISCOUNTS;

    constructor(public discounts: MultiselectDropdownOptionModel[]) {
    }
}

export class SetPassAutoAppliedDiscounts implements Action {
    readonly type = SET_PASS_AUTO_APPLIED_DISCOUNTS;

    constructor(public passAutoAppliedDiscounts: PassCostResponseModel[]) {
    }
}

export class SetAutoAppliedDiscounts implements Action {
    readonly type = SET_AUTO_APPLIED_DISCOUNTS;

    constructor(public autoAppliedDiscounts: MultiselectDropdownOptionModel[]) {
    }
}

export type All = ResetState
    | SetLoading
    | SetPassAdditionalCosts
    | SetAdditionalCosts
    | SetPassIncludedCosts
    | SetIncludedCosts
    | SetPassDiscounts
    | SetDiscounts
    | SetPassAutoAppliedDiscounts
    | SetAutoAppliedDiscounts;
