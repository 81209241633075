import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {PreviewEmailHtmlTemplateDialogSandbox} from "../sandbox/preview-email-html-template-dialog.sandbox";
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {Observable} from "rxjs";


// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class PreviewEmailHtmlTemplateDialogData extends DialogData {

    emailHtmlTemplateId: number;
    header: string;

    constructor(emailHtmlTemplateId?: number, header?: string) {
        super();
        this.emailHtmlTemplateId = emailHtmlTemplateId;
        this.header = header;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-preview-email-html-template-dialog',
    templateUrl: './preview-email-html-template-dialog.component.html'
})
export class PreviewEmailHtmlTemplateDialogComponent implements OnInit, OnDestroy {

    emailHtmlTemplateId: number;
    header: string;
    emailHtmlTemplate$: Observable<EmailHtmlTemplateResponseModel>;

    constructor(@Inject(DialogData) dialogData: PreviewEmailHtmlTemplateDialogData, private sandbox: PreviewEmailHtmlTemplateDialogSandbox) {
        this.emailHtmlTemplateId = dialogData.emailHtmlTemplateId;
        this.header = dialogData.header;
    }

    ngOnInit() {
        this.sandbox.loadEmailHtmlTemplate(this.emailHtmlTemplateId);
        this.emailHtmlTemplate$ = this.sandbox.emailHtmlTemplate$;
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onDestroy() {
        this.sandbox.destroyDialog();
    }
}
