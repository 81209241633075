<div class="dialog-section">

    <!-- HEADER -->
    <div class="dialog-section-header">
        <h2>General Information</h2>
        <div class="dialog-section-edit-options" (click)="onEditClick()" [ngClass]="{'remove': isEditEnabled(),  'disabled': editMode}">
            <div class="edit-option">
                <i class="fas fa-pencil"></i>
                <span>Edit</span>
            </div>
        </div>
    </div>

    <!-- VIEW MODE -->
    <ng-container *ngIf="!editMode">
        <div class="summary-general-info-wrapper">
            <div class="summary-general-info">
                <div class="summary-data-wrapper">
                    <p>Order Number</p>
                    <p>{{orderSummary.orderExternalId ? orderSummary.orderExternalId : "N/A"}}</p>
                </div>
                <div class="summary-data-wrapper">
                    <p>Create Date</p>
                    <p>{{orderSummary.createDateTimeFriendly ? orderSummary.createDateTimeFriendly  : "N/A"}}</p>
                </div>
                <div class="summary-data-wrapper">
                    <p>Sold by</p>
                    <p>{{orderSummary.soldByDisplayName ? orderSummary.soldByDisplayName : 'N/A'  }}</p>
                </div>
                <div class="summary-data-wrapper">
                    <p>Sold at</p>
                    <p>{{orderSummary.soldAtLocationDescription ? orderSummary.soldAtLocationDescription : 'N/A'}}</p>
                </div>
                <div class="summary-data-wrapper">
                    <p>Sold from</p>
                    <p>{{orderSummary.soldFromDescription ? orderSummary.soldFromDescription : "N/A"}}</p>
                </div>
                <div class="summary-data-wrapper" *ngFor="let customField of customFields">
                    <p>{{customField.templateItemDescription}}</p>
                    <p>{{customField.fieldValue}}</p>
                </div>
            </div>
            <div class="summary-general-info">
                <div class="summary-data-wrapper">
                    <p>Email</p>
                    <p>{{orderSummary.email ? orderSummary.email :  "N/A" }}</p>
                </div>
                <div class="summary-data-wrapper">
                    <p>Phone Number</p>
                    <p>{{orderSummary.phone ? orderSummary.phone : "N/A" }}</p>
                </div>
                <div class="summary-data-wrapper">
                    <p>Name</p>
                    <p>{{orderSummary.lastName ? orderSummary.lastName : "N/A" }}</p>
                </div>
                <div class="summary-data-wrapper">
                    <p>Status</p>
                    <p>{{orderSummary.orderStatusDescription ? orderSummary.orderStatusDescription : 'N/A' }}</p>
                </div>
                <div class="summary-general-info">
                    <div class="summary-data-wrapper" *ngIf="orderSummary.address1">
                        <p>Address 1</p>
                        <p>{{orderSummary.address1 }}</p>
                    </div>
                    <div class="summary-data-wrapper" *ngIf="orderSummary.address2">
                        <p>Address 2</p>
                        <p>{{orderSummary.address2 }}</p>
                    </div>
                    <div class="summary-data-wrapper" *ngIf="orderSummary.city">
                        <p>City</p>
                        <p>{{orderSummary.city }}</p>
                    </div>
                    <div class="summary-data-wrapper" *ngIf="orderSummary.state">
                        <p>State</p>
                        <p>{{orderSummary.state }}</p>
                    </div>
                    <div class="summary-data-wrapper" *ngIf="orderSummary.country">
                        <p>Country</p>
                        <p>{{orderSummary.country }}</p>
                    </div>
                    <div class="summary-data-wrapper" *ngIf="orderSummary.zipCode">
                        <p>Zip Code</p>
                        <p>{{orderSummary.zipCode }}</p>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- EDIT MODE -->
    <ng-container *ngIf="editMode">
        <div class="summary-general-info-wrapper editable">
            <div class="summary-general-info">
                <div class="summary-data-wrapper h-38">
                    <p>Order Number</p>
                    <p>{{orderSummary.orderExternalId ? orderSummary.orderExternalId : "N/A"}}</p>
                </div>
                <div class="summary-data-wrapper h-38">
                    <p>Create Date</p>
                    <p>{{orderSummary.createDateTimeFriendly ? orderSummary.createDateTimeFriendly  : "N/A"}}</p>
                </div>
                <div class="summary-data-wrapper h-38" >
                    <p>Sold by</p>
                    <p *ngIf="soldById == null || indexOfSoldBy == -1 ">{{orderSummary.soldByDisplayName ? orderSummary.soldByDisplayName : 'N/A'  }}</p>
                    <app-dropdown
                        [options]="soldByOptions"
                        [preSelectedValue]="soldByUsers[indexOfSoldBy].userId"
                        (changed)="onSoldBySelected($event)" *ngIf="soldById != null && indexOfSoldBy > -1">
                    </app-dropdown>
                </div>
                <div class="summary-data-wrapper h-38">
                    <p>Sold at</p>
                    <app-dropdown
                        [options]="soldAtLocationOptions"
                        [preSelectedValue]="soldAtLocationId ? soldAtLocationId.toString() : null"
                        (changed)="onSoldAtLocationSelected($event)">
                    </app-dropdown>
                    <!--<p>{{orderSummary.soldAtLocationDescription ? orderSummary.soldAtLocationDescription : 'N/A'}}</p>-->
                </div>
                <div class="summary-data-wrapper h-38">
                    <p>Sold from</p>
                    <p>{{orderSummary.soldFromDescription ? orderSummary.soldFromDescription : "N/A"}}</p>
                </div>
                <div class="summary-data-wrapper" *ngFor="let customField of customFields">
                    <p>{{customField.templateItemDescription}}</p>
                    <input [(ngModel)]="customField.fieldValue" (input)="onDataEdit()">
                </div>
            </div>
            <div class="summary-general-info">
                <div class="summary-data-wrapper h-38">
                    <p>Email</p>
                    <input type="text" [(ngModel)]="email" (input)="onDataEdit()">
                </div>
                <div class="summary-data-wrapper h-38">
                    <p>Phone Number</p>
                    <input type="text" [(ngModel)]="phoneNumber" (input)="onDataEdit()">
                </div>
                <div class="summary-data-wrapper h-38">
                    <p>Passenger Name</p>
                    <input type="text" [(ngModel)]="passengerName" (input)="onDataEdit()">
                </div>
                <div class="summary-data-wrapper h-38">
                    <p>Status</p>
                    <p>{{orderSummary.orderStatusDescription ? orderSummary.orderStatusDescription : 'N/A' }}</p>
                </div>
            </div>

            <div class="summary-general-button-wrapper">
                <button class="button cancel-button" (click)="onCancelClick()">Cancel</button>
                <button class="button primaryButton" (click)="onSaveClick()" [disabled]="!saveEnabled">Save</button>
            </div>
        </div>
    </ng-container>
</div>
