<div class="checkboxLabelGroup">
    <input type="checkbox"
           [class.disabled]="disabled"
           id="{{checkboxId}}"
           [checked]="checked"
           (change)="onCheckboxValueChanged()">
    <label class="ss-checkbox"
           for="{{checkboxId}}"
           [class.disabled]="disabled">
        {{labelName}}
    </label>
</div>
