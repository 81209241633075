import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../../../shared/shared.module";
import { StoreModule } from "@ngrx/store";
import { reducer, REDUCER_NAME } from "./sandbox/store/reducer";
import { EditCloudPaymentDeviceDialogSandbox } from "./sandbox/edit-cloud-payment-device-dialog.sandbox";
import { EditCloudPaymentDeviceDialogComponent } from "./container/edit-cloud-payment-device-dialog.component";
import { EditCloudPaymentDeviceFormComponent } from "./components/edit-cloud-payment-device-form.component";



@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditCloudPaymentDeviceDialogSandbox
    ],
    declarations: [
        EditCloudPaymentDeviceDialogComponent,
        EditCloudPaymentDeviceFormComponent
    ],
    entryComponents: [
        EditCloudPaymentDeviceDialogComponent
    ],
    exports: [
      EditCloudPaymentDeviceDialogComponent
    ]
})
export class EditCloudPaymentDeviceDialogModule {

}
