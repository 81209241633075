import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {PassSimpleResponseModel} from "../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";
import {Observable} from "rxjs";
import {SubmitPassEmailHtmlTemplatesData} from "./components/edit-pass-email-html-templates-dialog-form/edit-pass-email-html-templates-dialog-form.component";
import {EditPassEmailHtmlTemplatesDialogSandbox} from "./sandbox/edit-pass-email-html-templates-dialog.sandbox";
import {DropdownOptionModel} from "../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export class EditPassEmailHtmlTemplatesDialogData extends DialogData {

    passId: number;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, passId: number) {
        super();
        this.passId = passId;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-edit-pass-email-html-templates-dialog',
    templateUrl: './edit-pass-email-html-templates-dialog.component.html'
})
export class EditPassEmailHtmlTemplatesDialogComponent implements OnDestroy {

    passId: number;
    successCallbackFn: Function;

    pass$: Observable<PassSimpleResponseModel>;
    createOrderEmailHtmlTemplates$: Observable<DropdownOptionModel[]>;
    voidOrderEmailHtmlTemplates$: Observable<DropdownOptionModel[]>;
    termsAndConditionsEmailHtmlTemplates$: Observable<DropdownOptionModel[]>;

    constructor(@Inject(DialogData) data: EditPassEmailHtmlTemplatesDialogData, private sandbox: EditPassEmailHtmlTemplatesDialogSandbox) {

        this.passId = data.passId;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.passId);

        this.pass$ = this.sandbox.pass$;
        this.createOrderEmailHtmlTemplates$ = this.sandbox.createOrderEmailHtmlTemplates$;
        this.voidOrderEmailHtmlTemplates$ = this.sandbox.voidOrderEmailHtmlTemplates$;
        this.termsAndConditionsEmailHtmlTemplates$ = this.sandbox.termsAndConditionsEmailHtmlTemplates$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitPassEmailHtmlTemplatesData) {
        this.sandbox.updatePassEmailHtmlTemplates(data).subscribe(() => {
            this.successCallbackFn(data.passId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
