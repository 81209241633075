<app-abstract-dialog-container>
    <app-mapping-holiday-dialog-form *ngIf="holidayLoaded"
        [holiday]="(holiday$ | async)"

        [categories]="categories$ | async"
        [products]="products$ | async"
        [productAvailabilityHolidayMappingItems]="productAvailabilityHolidayMappingItems$ | async"

        [areCategoriesLoading]="categoriesLoading$ | async"
        [areProductsLoading]="productsLoading$ | async"
        [areProductAvailabilityHolidayMappingItemsLoading]=" productAvailabilityHolidayMappingItemsLoading$ | async"

        (categoriesSelectedEvent)="onCategoriesSelectedEvent($event)"
        (productsSelectedEvent)="onProductsSelectedEvent($event)"
        (showInactiveExpiredProductAvailabilitiesEvent)="onShowInactiveExpiredProductAvailabilitiesEvent($event)"

        (submitEvent)="onSubmit($event)"
        (closeEvent)="onClose()">
    </app-mapping-holiday-dialog-form>
</app-abstract-dialog-container>
