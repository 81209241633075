const OrderStatusEnum = {
    PENDING: 'PENDING' as 'PENDING',
    ON_HOLD: 'ON_HOLD' as 'ON_HOLD',
    NEW: 'NEW' as 'NEW',
    EXPIRED: 'EXPIRED' as 'EXPIRED',
    CANCELLED: 'CANCELLED' as 'CANCELLED',
    COMPLETE: 'COMPLETE' as 'COMPLETE',
    VOID: 'VOID' as 'VOID',
    NO_SHOW: 'NO_SHOW' as 'NO_SHOW',
    PARTIALLY_COMPLETE: 'PARTIALLY_COMPLETE' as 'PARTIALLY_COMPLETE'
};
type OrderStatusEnum = (typeof OrderStatusEnum)[keyof typeof OrderStatusEnum];
export {OrderStatusEnum};
