import { Component, Inject, Input, OnInit  } from '@angular/core';
import {DialogData} from "../../../model/dialog.data";
import {EditFavoriteDialogSandbox} from "../sandbox/edit-favorite-dialog.sandbox";
import {Observable} from "rxjs";
import {FavoriteResponseModel} from "../../../../../core/modules/rest/favorite/response/favorite-response.model";
import {FavoriteTypeEnum} from "../../../../enums/favorite-type.enum";
import {CreateBaseResponseModel} from "../../../../../core/modules/rest/create-base-response.model";
import {FavoriteDescriptionAndGroupDescription} from "../components/edit-favorite-dialog-form/edit-favorite-dialog-form.component";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditFavoriteDialogData extends DialogData {

    favoriteId: number;
    favoriteType: FavoriteTypeEnum;
    updateCallbackFn: (favoriteId: number) => void;
    createCallbackFn: (favoriteId: number) => void;

    constructor(updateCallbackFn: (favoriteId: number) => void, createCallbackFn: (favoriteId: number) => void, favoriteId: number, favoriteType: FavoriteTypeEnum) {
        super();
        this.favoriteId = favoriteId;
        this.favoriteType = favoriteType;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-favorite-dialog',
    templateUrl: './edit-favorite-dialog.component.html'
})
export class EditFavoriteDialogComponent implements OnInit {

    favoriteId: number;
    favoriteType: FavoriteTypeEnum;
    updateCallbackFn: (favoriteId: number) => void;
    createCallbackFn: (favoriteId: number) => void;

    favorite$: Observable<FavoriteResponseModel>;
    descriptionAvailable$: Observable<boolean>;

    editMode: boolean = false;

    constructor(@Inject(DialogData) dialogData: EditFavoriteDialogData, private sandbox: EditFavoriteDialogSandbox) {
        this.favoriteId = dialogData.favoriteId;
        this.favoriteType = dialogData.favoriteType;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
        this.favorite$ = this.sandbox.favorite$;
        this.descriptionAvailable$ = this.sandbox.descriptionAvailable$;
    }

    ngOnInit() {

        if (this.favoriteId && !isNaN(this.favoriteId)) {
            this.sandbox.loadFavorite(this.favoriteId);
            this.editMode = true;
        }
    }

    destroyDialog() {
        this.sandbox.resetState();
    }

    onFavoriteDescriptionChanged(value: string) {
        this.sandbox.isFavoriteDescriptionUnique(value);
    }

    onSubmit(favorite: FavoriteDescriptionAndGroupDescription) {
        if (this.editMode) {
            this.sandbox.updateFavorite(this.favoriteId, favorite.favoriteDescription, favorite.groupDescription, this.favoriteType).subscribe(() => {
                this.updateCallbackFn(this.favoriteId);
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createFavorite(favorite.favoriteDescription, favorite.groupDescription, this.favoriteType).subscribe((response: CreateBaseResponseModel) => {
                this.createCallbackFn(response.id);
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
