import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {EnableCategoryDialogSandbox} from "./sandbox/enable-category-dialog.sandbox";
import {Observable} from "rxjs";
import {DropdownOptionModel} from "../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {EnableCategoryProductsEventData} from "./components/enable-category-dialog-form/enable-category-dialog-form.component";

export class EnableCategoryDialogData extends DialogData {
    categoryId: number;
    successCallback: Function;

    constructor(categoryId: number, successCalback: Function) {
        super();
        this.categoryId = categoryId;
        this.successCallback = successCalback;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-enable-category-dialog',
    templateUrl: './enable-category-dialog.component.html'
})
export class EnableCategoryDialogComponent implements OnDestroy {

    categoryId: number;

    successCallback: Function;

    products$: Observable<DropdownOptionModel[]>;

    constructor(@Inject(DialogData) data: EnableCategoryDialogData, private sandbox: EnableCategoryDialogSandbox) {

        this.categoryId = data.categoryId;
        this.successCallback = data.successCallback;

        this.sandbox.load(this.categoryId);

        this.products$ = this.sandbox.products$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }

    onSubmit(data: EnableCategoryProductsEventData) {
        this.sandbox.enableCategory(this.categoryId, data.type, data.productIds).subscribe(() => {
            this.successCallback();
            this.sandbox.destroyDialog();
        });
    }
}
