import {DroppedDataI} from './dropped-data.model';
import {FilterItemModel} from './filter-item.model';

export class DroppedFilterDataModel implements DroppedDataI {
    parentGroupUUID: string;
    leftSiblingUUID: string;
    filterItem: FilterItemModel;

    constructor(parentGroupUUID: string, leftSiblingUUID: string, filterItem: FilterItemModel) {
        this.parentGroupUUID = parentGroupUUID;
        this.leftSiblingUUID = leftSiblingUUID;
        this.filterItem = filterItem;
    }
}
