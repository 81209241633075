import {Action} from '@ngrx/store';
import {MultiselectDropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

export const RESET_STATE = '[Dialog - Mapping Commission :: MAIN] Reset State';
export const SET_LOADING = '[Dialog - Mapping Commission :: MAIN] Loading';
export const SET_CATEGORIES = '[Dialog - Mapping Commission :: MAIN] Set Categories';
export const SET_MAPPED_CATEGORIES = '[Dialog - Mapping Commission :: MAIN] Set Mapped Categories';
export const SET_PRODUCTS = '[Dialog - Mapping Commission :: MAIN] Set Products';
export const SET_MAPPED_PRODUCTS = '[Dialog - Mapping Commission :: MAIN] Set Mapped Products';
export const SET_PASSES = '[Dialog - Mapping Commission :: MAIN] Set Passes';
export const SET_MAPPED_PASSES = '[Dialog - Mapping Commission :: MAIN] Set Mapped Passes';
export const SET_TIERS = '[Dialog - Mapping Commission :: MAIN] Set Tiers';
export const SET_MAPPED_TIERS = '[Dialog - Mapping Commission :: MAIN] Set Mapped Tiers';
export const SET_ROLES = '[Dialog - Mapping Commission :: MAIN] Set Roles';
export const SET_MAPPED_ROLES = '[Dialog - Mapping Commission :: MAIN] Set Mapped Roles';
export const SET_USERS = '[Dialog - Mapping Commission :: MAIN] Set Users';
export const SET_MAPPED_USERS = '[Dialog - Mapping Commission :: MAIN] Set Mapped Users';
export const SET_LOCATIONS = '[Dialog - Mapping Commission :: MAIN] Set Locations';
export const SET_MAPPED_LOCATIONS = '[Dialog - Mapping Commission :: MAIN] Set Mapped Locations';

export class ResetState implements Action {
    readonly type = RESET_STATE;
    constructor() {}
}

export class SetLoading implements Action {
    readonly type = SET_LOADING;
    constructor(public loading: boolean) {}
}

export class SetCategories implements Action {
    readonly type = SET_CATEGORIES;
    constructor(public categories: MultiselectDropdownOptionModel[]) {}
}

export class SetMappedCategories implements Action {
    readonly type = SET_MAPPED_CATEGORIES;
    constructor(public mappedCategories: number[]) {}
}

export class SetProducts implements Action {
    readonly type = SET_PRODUCTS;
    constructor(public products: MultiselectDropdownOptionModel[]) {}
}

export class SetMappedProducts implements Action {
    readonly type = SET_MAPPED_PRODUCTS;
    constructor(public mappedProducts: number[]) {}
}

export class SetPasses implements Action {
    readonly type = SET_PASSES;
    constructor(public passes: MultiselectDropdownOptionModel[]) {}
}

export class SetMappedPasses implements Action {
    readonly type = SET_MAPPED_PASSES;
    constructor(public mappedPasses: number[]) {}
}

export class SetTiers implements Action {
    readonly type = SET_TIERS;
    constructor(public tiers: MultiselectDropdownOptionModel[]) {}
}

export class SetMappedTiers implements Action {
    readonly type = SET_MAPPED_TIERS;
    constructor(public mappedTiers: number[]) {}
}

export class SetRoles implements Action {
    readonly type = SET_ROLES;
    constructor(public roles: MultiselectDropdownOptionModel[]) {}
}

export class SetMappedRoles implements Action {
    readonly type = SET_MAPPED_ROLES;
    constructor(public mappedRoles: number[]) {}
}

export class SetUsers implements Action {
    readonly type = SET_USERS;
    constructor(public users: MultiselectDropdownOptionModel[]) {}
}

export class SetMappedUsers implements Action {
    readonly type = SET_MAPPED_USERS;
    constructor(public mappedUsers: number[]) {}
}

export class SetLocations implements Action {
    readonly type = SET_LOCATIONS;
    constructor(public locations: MultiselectDropdownOptionModel[]) {}
}

export class SetMappedLocations implements Action {
    readonly type = SET_MAPPED_LOCATIONS;
    constructor(public mappedLocations: number[]) {}
}

export type All = ResetState
    | SetLoading
    | SetCategories
    | SetMappedCategories
    | SetProducts
    | SetMappedProducts
    | SetPasses
    | SetMappedPasses
    | SetTiers
    | SetMappedTiers
    | SetRoles
    | SetMappedRoles
    | SetUsers
    | SetMappedUsers
    | SetLocations
    | SetMappedLocations;
