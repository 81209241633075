import { catchError, switchMap, tap } from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import * as fromFeatureMainActions from '../../store/main/actions';

import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { DialogSandbox } from "../../../../../../../shared/dialogs/services/dialog.sandbox";
import { SaleGroupResponseModel } from '../../../../../../../core/modules/rest/sale-group/response/sale-group-response.model';
import { AppDialogsService } from "../../../../../../../core/services/app-dialogs.service";
import { Store } from "@ngrx/store";
import { SaleGroupRestService } from '../../../../../../../core/modules/rest/sale-group/sale-group-rest.service';
import { RootSandbox } from "../../../../../../../core/store/root.sandbox";
import { CreateBaseResponseModel } from "../../../../../../../core/modules/rest/create-base-response.model";
import { HttpErrorModel } from '../../../../../../../core/modules/rest/http-error.model';
import { CreateSaleGroupCustomersRequestModel } from '../../../../../../../core/modules/rest/sale-group/request/create-sale-group-customers-request.model';
import { CreateSaleGroupQSBRequestModel } from '../../../../../../../core/modules/rest/sale-group/request/create-sale-group-qsb-request.model';
import { CreateSaleGroupDomainsRequestModel } from '../../../../../../../core/modules/rest/sale-group/request/create-sale-group-domains-request.model';

@Injectable()
export class AddSaleGroupItemsDialogSandbox extends DialogSandbox {

    saleGroup$: Observable<SaleGroupResponseModel> = this.store.select(reducer.saleGroup_selector);
    saleGroup: SaleGroupResponseModel;

    constructor(appDialogsService: AppDialogsService,
                private saleGroupRestService: SaleGroupRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox,

               ) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadSaleGroup( saleGroupId: number ) {

        this.showLoader();

        this.saleGroupRestService.getById( saleGroupId ).subscribe((saleGroup: SaleGroupResponseModel) => {
            this.store.dispatch(new actions.SetSaleGroup( saleGroup ));
            this.hideLoader();
        }

       );
    }

    addCustomersToSaleGroup(request: CreateSaleGroupCustomersRequestModel ) {
        return this.saleGroupRestService.addCustomersToSaleGroup( request ).pipe(
            switchMap(() => {
                const saleGroupId = request.saleGroupId;
                const loadRequest = { saleGroupId, from: 0, size: 50 };
                return this.saleGroupRestService.getCustomersForSaleGroupInRange(loadRequest);
            }),
            tap((response) => {
                this.store.dispatch(new fromFeatureMainActions.SetCustomers(response));
            }),
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while adding customer to sale group and reloading data", error);
            })
        );
    }

    addDomainsToSaleGroup( request: CreateSaleGroupDomainsRequestModel ): Observable<CreateBaseResponseModel> {
        return this.saleGroupRestService.addDomainsToSaleGroup( request ).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while adding domains to sale group", error);
            }));
    }

    addQSBItemToSaleGroup( request: CreateSaleGroupQSBRequestModel ): Observable<CreateBaseResponseModel>{
        return this.saleGroupRestService.addQSBItemToSaleGroup( request ).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while adding customer to sale group", error);
            }));
    }

}
