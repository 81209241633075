<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>Mapping for holiday: {{ holiday.description }}</h1>
            </div>
        </div>
    </div>


    <!-- Dialog Content :: BEGIN -->
    <div *ngIf="showMappingForHoliday" class="content_wrapper">

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-4">
                    <app-input-label-group>
                        <label class="label">Category</label>
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="areCategoriesLoading"
                            [allOptionEnabled]="true"
                            [preSelectedValues]="selectedCategories"
                            [options]="categoryOptions"
                            (changed)="onSelectedCategoriesChanged($event)">
                        </app-multiselect-dropdown>
                    </app-input-label-group>
                </div>

                <div class="cell medium-4">
                    <app-input-label-group>
                        <label class="label">Product</label>
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="areProductsLoading"
                            [allOptionEnabled]="true"
                            [preSelectedValues]="selectedProducts"
                            [options]="productOptions"
                            (changed)="onSelectedProductsChanged($event)">
                        </app-multiselect-dropdown>
                    </app-input-label-group>
                </div>

                <div class="cell medium-4">
                    <app-input-label-group>
                        <label class="label">Availability</label>
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="areProductAvailabilityHolidayMappingItemsLoading"
                            [allOptionEnabled]="true"
                            [preSelectedValues]="selectedProductAvailabilityHolidayMappingItems"
                            [options]="productAvailabilityHolidayMappingOptions"
                            (changed)="onSelectedProductAvailabilityHolidayMappingItemsChanged($event)">
                        </app-multiselect-dropdown>
                    </app-input-label-group>
                </div>

                <div class="cell medium-4">
                    <app-switch
                        [switchLabel]="'Show Inactive Categories'"
                        [value]="showInactiveCategories"
                        (switchValueChanged)="onShowInactiveCategories($event)">
                    </app-switch>
                </div>

                <div class="cell medium-4">
                    <app-switch
                        [switchLabel]="'Show Inactive Products'"
                        [value]="showInactiveProducts"
                        (switchValueChanged)="onShowInactiveProducts($event)">
                    </app-switch>
                </div>

                <div class="cell medium-4">
                    <app-switch
                        [switchLabel]="'Show Inactive/Expired'"
                        [value]="showInactiveExpiredProductAvailabilities"
                        (switchValueChanged)="onShowInactiveExpiredProductAvailabilities($event)">
                    </app-switch>
                </div>

            </div>
        </div>

    </div><!-- Dialog Content :: END -->

    <div *ngIf="!showMappingForHoliday" class="content_wrapper content_wrapper_mapping_unevaliable">
        Mapping is not available for past dates.
    </div>


    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>


            <div [ngClass]="{'disabled': !showMappingForHoliday}" class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
