
import { of as observableOf, combineLatest, Observable, of, interval, throwError, defer } from 'rxjs';

import { catchError, flatMap, map, mergeMap, switchMap, take } from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import * as appReducer from "../../../../../../../core/store/reducers";

import { Injectable } from "@angular/core";
import { ShoppingCartRequestModel } from "../../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-request.model";
import { ShoppingCartRestService } from "../../../../../../../core/modules/rest/shopping-cart/shopping-cart-rest.service";
import { ShoppingCartResponseModel } from "../../../../../../../core/modules/rest/shopping-cart/response/shopping-cart-response.model";
import { ShoppingCartItemDataModel } from "../../../order-long-form/model/shopping-cart/shopping-cart-item-data.model";
import { ShoppingCartDataModel } from "../../../order-long-form/model/shopping-cart/shopping-cart-data.model";
import { TemplateItemResponseModel } from "../../../../../../../core/modules/rest/template/response/template-item-response.model";
import { ShoppingCartItemTypeEnum } from "../../../order-long-form/model/enum/shopping-cart-item-type.enum";
import { GetTemplateItemsForProductsAndPassesRequest } from "../../../../../../../core/modules/rest/template/request/get-template-items-for-products-and-passes-request";
import { TemplateRestService } from "../../../../../../../core/modules/rest/template/template-rest.service";
import { CreditCardPaymentRestService } from "../../../../../../../core/modules/rest/credit-card-payment/credit-card-payment-rest.service";
import { UserInfoResponseModel } from "../../../../../../../core/modules/rest/user/response/user-info-response.model";
import { CsrfTokenResponseModel } from "../../../../../../../core/modules/rest/credit-card-payment/response/csrf-token-response.model";
import { CreditCardDataModel } from "../../../order-long-form/model/payment-info/credit-card-data.model";
import { AuthorizeTransactionRequestModel } from "../../../../../../../core/modules/rest/credit-card-payment/request/authorize-transaction-request.model";
import { AuthorizeTransactionResponseModel } from "../../../../../../../core/modules/rest/credit-card-payment/response/authorize-transaction-response.model";
import { HttpErrorModel } from "../../../../../../../core/modules/rest/http-error.model";
import { RootSandbox } from "../../../../../../../core/store/root.sandbox";
import { PaymentMethodDataModel } from "../../../order-long-form/model/payment-info/payment-method-data.model";
import { PostBillDataModel } from "../../../order-long-form/model/payment-info/post-bill-data.model";
import { ShoppingCartPaymentRequestModel } from "../../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-payment-request.model";
import * as moment from "moment";
import { ShoppingCartPaymentMethodEnum } from "../../../order-long-form/model/enum/shopping-cart-payment-method.enum";
import { isCreditCardPayment, PaymentMethodEnum } from "../../../../../../../shared/enums/payment-method.enum";
import { ShoppingCartPaymentPostBillDetailRequestModel } from "../../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-payment-post-bill-detail-request.model";
import { PostBillTypeAdditionalActionEnum } from "../../../../../../../shared/enums/post-bill-type-additional-action.enum";
import { DateTimeUtility } from "../../../../../../../shared/utils/date-time-utility";
import { PaymentItemDataModel } from "../../../order-long-form/model/shopping-cart/payment-item-data.model";
import { OrderCostItemDataModel } from "../../../order-long-form/model/shopping-cart/order-cost-item-data.model";
import { OrderInfoDataModel } from "../../../order-long-form/model/order-info/order-info-data.model";
import { PlatformEnum } from "../../../../../../../shared/enums/platform.enum";
import { ShoppingCartCustomFieldRequestModel } from "../../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-custom-field-request.model";
import { ShoppingCartProductRequestModel } from "../../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-product-request.model";
import { ShoppingCartProductResponseModel } from "../../../../../../../core/modules/rest/shopping-cart/response/shopping-cart-product-response.model";
import { ShoppingCartProductTierRequestModel } from "../../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-product-tier-request.model";
import { CostTypeEnum } from "../../../../../../../shared/enums/cost-type.enum";
import { ShoppingCartTicketRequestModel } from "../../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-ticket-request.model";
import { ShoppingCartPassRequestModel } from "../../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-pass-request.model";
import { ShoppingCartPassResponseModel } from "../../../../../../../core/modules/rest/shopping-cart/response/shopping-cart-pass-response.model";
import { ShoppingCartPassTierRequestModel } from "../../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-pass-tier-request.model";
import { ShoppingCartPassCardRequestModel } from "../../../../../../../core/modules/rest/shopping-cart/request/shopping-cart-pass-card-request.model";
import { select, Store } from "@ngrx/store";
import { ShoppingCartOrderCostResponseModel } from "../../../../../../../core/modules/rest/shopping-cart/response/shopping-cart-order-cost-response.model";
import { ShoppingCartValidationDataModel } from "../../../order-long-form/model/shopping-cart-validation-data.model";
import { ShoppingCartRequestHelper } from "../../../order-long-form/sandbox/shopping-cart-request-helper";
import { SystemOptionsResponseModel } from "../../../../../../../core/modules/rest/system-options/response/system-options-response.model";
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutesService } from '../../../../../../../core/services/app-routes.service';
import { CloudPaymentDeviceDataModel } from '../../../order-long-form/model/cloud-payment-device/cloud-payment-device-data.model';
import { CloudPaymentTransactionStatusResponse } from '../../../../../../../core/modules/rest/cloud-payment/response/cloud-payment-transaction-status-response.model';
import { CloudPaymentDeviceRestService } from '../../../../../../../core/modules/rest/cloud-payment/cloud-payment-device-rest.service';
import { CloudPaymentAuthorizeTransactionRequest } from '../../../../../../../core/modules/rest/cloud-payment/request/cloud-payment-authorize-transaction-request.model';
import { CloudPaymentTransactionStatusEnum } from '../../../../../../../shared/enums/cloud-payment-transaction-status.enum';
import { CloudPaymentRequestStatusEnum } from '../../../../../../../shared/enums/cloud-payment-request-status.enum';
import { CloudPaymentAuthorizeTransactionAsyncResponse } from '../../../../../../../core/modules/rest/cloud-payment/response/cloud-payment-transaction-response.model';
import { ErrorCodeEnum } from '../../../../../../../shared/enums/error-code.enum';

@Injectable()
export class ShoppingCartSandbox {

    systemOptions$: Observable<SystemOptionsResponseModel> = this.appState.pipe(select(appReducer.systemOptionsState_systemOptions_selector));

    currentUser$: Observable<UserInfoResponseModel> = this.appState.pipe(select(appReducer.userState_userInfo_selector));

    companyCode$: Observable<string> = this.rootSandbox.getCompanyCode();

    loading$: Observable<boolean> = this.store.pipe(select(reducer.loading_selector));

    shoppingCart$: Observable<ShoppingCartDataModel> = this.store.pipe(select(reducer.shoppingCart_selector));

    shoppingCartItems$: Observable<ShoppingCartItemDataModel[]> = this.store.pipe(select(reducer.selectShoppingCartStateProperties("shoppingCartItems")));

    shoppingCartResponse$: Observable<ShoppingCartResponseModel> = this.store.pipe(select(reducer.selectShoppingCartStateProperties("shoppingCartResponse")));

    paymentItems$: Observable<PaymentItemDataModel[]> = this.store.pipe(select(reducer.selectShoppingCartStateProperties("paymentItems")));

    orderLevelAdditionalCostItems$: Observable<OrderCostItemDataModel[]> = this.store.pipe(select(reducer.selectShoppingCartStateProperties("orderLevelAdditionalCostItems")));

    orderLevelDiscountItems$: Observable<OrderCostItemDataModel[]> = this.store.pipe(select(reducer.selectShoppingCartStateProperties("orderLevelDiscountItems")));

    orderExternalID: string = "";

    appRoutesService: AppRoutesService;

    DEFAULT_ORDER_INFO: OrderInfoDataModel = {
        referrers: [],
        referredById: null,
        passengerName: null,
        email: null,
        phone: null,
        printOrder: true,
        showOrderSummary: true,
        printOneTicketPerPerson: true,
        sendEmailToCustomer: false,
        canEmailOrder: true,
        canPrintOrder: true,
        canViewOrder: true,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zipCode: null,
        country: null
    };

    constructor(private store: Store<reducer.State>,
        private appState: Store<appReducer.AppState>,
        private rootSandbox: RootSandbox,
        private shoppingCartRestService: ShoppingCartRestService,
        private templateRestService: TemplateRestService,
        private creditCardPaymentRestService: CreditCardPaymentRestService,
        private cloudPaymentDeviceRestService: CloudPaymentDeviceRestService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        appRoutesService: AppRoutesService) {
        this.appRoutesService = appRoutesService;
    }

    /**
     * Adds item to cart and makes call to backend
     * @param addItemFn - Function that takes request and applies new item
     */
    addToCart(addItemFn: (request: ShoppingCartRequestModel) => Observable<ShoppingCartRequestModel>): Observable<ShoppingCartResponseModel> {

        this.store.dispatch(new actions.SetLoading(true));

        return this.createShoppingCartRequestFromShoppingCart().pipe(
            take(1),
            flatMap((request: ShoppingCartRequestModel) => addItemFn(request)),
            take(1),
            flatMap((request: ShoppingCartRequestModel) => this.shoppingCartRestService.updateShoppingCart(request, this.orderExternalID)),
            take(1),
            flatMap((response: ShoppingCartResponseModel) => {
                this.store.dispatch(new actions.SetLoading(false));
                return observableOf(this.updateShoppingCartState(response));
            })
        );
    }

    /**
     * Removes item from shopping cart
     * @param itemToBeRemoved - item to be removed
     */
    removeFromCart(itemToBeRemoved: ShoppingCartItemDataModel): Observable<ShoppingCartResponseModel> {

        return this.shoppingCart$.pipe(
            take(1),
            flatMap((shoppingCart: ShoppingCartDataModel) => {

                let removeIndex: number = shoppingCart.shoppingCartItems.findIndex((item: ShoppingCartItemDataModel) => item.isEqualTo(itemToBeRemoved));
                shoppingCart.shoppingCartItems.splice(removeIndex, 1);

                // Get all template items from products and passes in shopping cart to see what custom fields can be removed from order
                return combineLatest([this.getTemplateItemsForShoppingCart(shoppingCart), this.shoppingCart$]);
            }),
            take(1),
            flatMap((val: any[]) => {

                let templateItems: TemplateItemResponseModel[] = val[0];
                let shoppingCart: ShoppingCartDataModel = val[1];

                shoppingCart.shoppingCartResponse.customFields = shoppingCart.shoppingCartResponse.customFields
                    .filter(cf => templateItems.map(ti => ti.templateItemId).indexOf(cf.templateItemId) !== -1);

                return this.createShoppingCartRequestFromShoppingCart();
            }),
            take(1),
            flatMap((request: ShoppingCartRequestModel) => this.shoppingCartRestService.updateShoppingCart(request, this.orderExternalID)),
            take(1),
            flatMap((response: ShoppingCartResponseModel) => observableOf(this.updateShoppingCartState(response)))
        );
    }

    /**
     * Marks shopping cart item as one who's being edited
     * @param shoppingCartItem - item to be edited
     */
    editShoppingCartItem(shoppingCartItem: ShoppingCartItemDataModel): void {
        this.store.dispatch(new actions.ShoppingCartSetShoppingCartItemEditMode(shoppingCartItem));
    }

    /**
     * Updates item in shopping cart by first removing it from current shopping cart and then re-adding it
     * @param updateItemFn - function that takes in request and adds item to request (same as addToCart)
     */
    updateCart(updateItemFn: (request: ShoppingCartRequestModel) => Observable<ShoppingCartRequestModel>): Observable<ShoppingCartResponseModel> {

        return this.shoppingCart$.pipe(
            take(1),
            flatMap((shoppingCart: ShoppingCartDataModel) => {

                let removeIndex: number = shoppingCart.shoppingCartItems.findIndex((item: ShoppingCartItemDataModel) => item.editMode);
                shoppingCart.shoppingCartItems.splice(removeIndex, 1);
                return this.createShoppingCartRequestFromShoppingCart();
            }),
            take(1),
            flatMap((request: ShoppingCartRequestModel) => updateItemFn(request)),
            take(1),
            flatMap((request: ShoppingCartRequestModel) => this.shoppingCartRestService.updateShoppingCart(request, this.orderExternalID)),
            take(1),
            flatMap((response: ShoppingCartResponseModel) => observableOf(this.updateShoppingCartState(response)))
        );
    }

    /**
     * Updates cart state. Method here because of custom fields, this needs to be refactored
     * @param shoppingCart
     */
    updateCartState(shoppingCart: ShoppingCartDataModel): void {
        this.store.dispatch(new actions.ShoppingCartSetShoppingCart(shoppingCart));
    }

    /**
     * Cancel cart updating by setting shopping cart item edit mode to false
     */
    cancelUpdatingCart(): Observable<boolean> {

        return this.shoppingCartItems$.pipe(
            take(1),
            map((shoppingCartItems: ShoppingCartItemDataModel[]) => {
                shoppingCartItems.forEach((item) => item.editMode = false);

                this.store.dispatch(new actions.ShoppingCartSetShoppingCartItems(shoppingCartItems));
                return true;
            })
        );
    }

    populateShoppingCartItems(shoppingCartItems: any) {
        this.store.dispatch(new actions.ShoppingCartSetShoppingCartItems(shoppingCartItems));
    }

    /**
     * Completes order
     * @param addAdditionalInfoFn - fills in any additional info for completing order
     * @param validateDataFn - validates data
     */
    completeOrder(validateDataFn: (shoppingCart: Observable<ShoppingCartDataModel>) => Observable<ShoppingCartValidationDataModel>, addAdditionalInfoFn: (request: ShoppingCartRequestModel) => Observable<ShoppingCartRequestModel>, orderExternalID: string): Observable<ShoppingCartValidationDataModel | ShoppingCartResponseModel> {
        if (orderExternalID != null && orderExternalID != undefined) {
            this.appRoutesService.goToOrderForme();
        }
        let completeOrderFlow$: Observable<ShoppingCartResponseModel> = this.createShoppingCartRequestFromShoppingCart(true).pipe(
            take(1),
            flatMap((request: ShoppingCartRequestModel) => addAdditionalInfoFn(request)),
            take(1),
            flatMap((request: ShoppingCartRequestModel) => this.shoppingCartRestService.updateShoppingCart(request, orderExternalID)),
            take(1),
            flatMap((response: ShoppingCartResponseModel) => observableOf(this.updateShoppingCartState(response)))
        );

        return validateDataFn(this.shoppingCart$).pipe(
            take(1),
            flatMap((validation: ShoppingCartValidationDataModel) => {

                if (validation.isValid()) {
                    return completeOrderFlow$;
                } else {
                    return observableOf(validation);
                }
            }),
        );
    }

    /**
     * Clears content of shopping cart
     */
    clearCart(): Observable<boolean> {
        this.store.dispatch(new actions.ResetState());
        return observableOf(true);
    }

    /**
     *
     * Adds payment to shopping cart
     *
     * We have to flows:
     * [1] CC payment
     * [2] postbill / cash payment
     *
     * Now, we have two observables (one for each flow [1] and [2])
     * Both add proper payment to shopping cart and call backend (update shopping cart)
     * They can return ShoppingCartResponseModel or null depending on success (response if success, null otherwise)
     *
     * @param amount$
     * @param selectedPaymentMethodData$
     * @param creditCardData$
     * @param postBillData$
     *
     * @return null if it has error, shopping cart with filled payment otherwise
     */

    addPaymentItem(amount$: Observable<number>, selectedPaymentMethodData$: Observable<PaymentMethodDataModel>, creditCardData$: Observable<CreditCardDataModel>, postBillData$: Observable<PostBillDataModel>, cloudPaymentData$: Observable<CloudPaymentDeviceDataModel>, refundPayments: boolean): Observable<ShoppingCartResponseModel> {

        // [1] Credit card payment method
        let addPaymentMethodCreditCard$: Observable<ShoppingCartResponseModel> = combineLatest([this.creditCardPaymentRestService.getCsrfToken(), this.systemOptions$, this.currentUser$, this.companyCode$, amount$, creditCardData$]).pipe(
            take(1),
            flatMap(([csrfTokenResponse, systemOptions, currentUser, companyCode, amount, creditCardData]: [CsrfTokenResponseModel, SystemOptionsResponseModel, UserInfoResponseModel, string, number, CreditCardDataModel]) => {

                let error: AuthorizeTransactionResponseModel = {
                    authorizationToken: null,
                    hasError: true,
                    errorCode: null,
                    errorMessage: ""
                };

                if (!csrfTokenResponse || !csrfTokenResponse.token || csrfTokenResponse.token.trim().length === 0) {
                    error.errorMessage = "Cannot add payment, error while getting CSRF token";
                    return of([null, error]);
                }

                let nameOnCardValid: boolean = creditCardData.nameOnCard !== null && creditCardData.nameOnCard.trim().length > 0;

                let cardNumber: string = creditCardData.cardNumber !== null ? creditCardData.cardNumber.replace(/\s/g, "") : null;
                let ccnPopulated: boolean = cardNumber !== null && cardNumber.length > 0 && this.validateCardNumber(cardNumber);

                let ccExpMonthPopulated: boolean = creditCardData.expirationMonth && creditCardData.expirationMonth.trim().length !== 0 && this.validateExpirationMonth(creditCardData.expirationMonth);
                let ccExpYearPopulated: boolean = creditCardData.expirationYear && creditCardData.expirationYear.trim().length !== 0 && this.validateExpirationYear(creditCardData.expirationYear);

                let cardSecurityCode: string = creditCardData.cardSecurityCode !== null ? creditCardData.cardSecurityCode.replace(/\s/g, "") : null;
                let cscCodePopulated: boolean = cardSecurityCode !== null && cardSecurityCode.length > 0 && this.validateCardSecurityCode(cardSecurityCode);

                let ccZipCodePopulated: boolean = creditCardData.zipCode && creditCardData.zipCode.trim().length !== 0;

                if (!nameOnCardValid) {
                    error.errorMessage = "Please fill Credit Card Name";
                    return of([null, error]);
                }

                if (!ccnPopulated) {
                    error.errorMessage = "Please fill valid Credit Card Number";
                    return of([null, error]);
                }

                if (!ccExpMonthPopulated) {
                    error.errorMessage = "Please fill Expiration Month";
                    return of([null, error]);
                }

                if (!ccExpYearPopulated) {
                    error.errorMessage = "Please fill Expiration Year";
                    return of([null, error]);
                }

                if (!cscCodePopulated) {
                    error.errorMessage = "Please fill valid Credit Card Security Code";
                    return of([null, error]);
                }

                if (systemOptions.zipCodeMandatoryForCNPTransactions) {
                    if (!ccZipCodePopulated) {
                        error.errorMessage = "Please fill Zip Code";
                        return of([null, error]);
                    }
                }


                // Authorize transaction
                let authorizeTransactionRequest: AuthorizeTransactionRequestModel = new AuthorizeTransactionRequestModel();
                authorizeTransactionRequest.userId = currentUser.userId;
                authorizeTransactionRequest.amount = amount;
                authorizeTransactionRequest.creditCardHolderFullName = creditCardData.nameOnCard;
                authorizeTransactionRequest.creditCardZipCode = creditCardData.zipCode;
                authorizeTransactionRequest.creditCardNumber = creditCardData.cardNumber.replace(/\s/g, "");
                authorizeTransactionRequest.creditCardSecurityCode = creditCardData.cardSecurityCode.replace(/\s/g, "");
                authorizeTransactionRequest.creditCardExpirationMonth = parseInt(creditCardData.expirationMonth, 10);
                authorizeTransactionRequest.creditCardExpirationYear = parseInt(creditCardData.expirationYear, 10);

                return combineLatest([
                    this.createShoppingCartRequestFromShoppingCart(),
                    this.creditCardPaymentRestService.authorizeTransaction(csrfTokenResponse.token, companyCode, authorizeTransactionRequest)
                ]);
            }),
            flatMap(([shoppingCartRequest, authorizeTransactionResponse]: [ShoppingCartRequestModel, AuthorizeTransactionResponseModel]) => {

                if (!authorizeTransactionResponse || authorizeTransactionResponse.hasError || !shoppingCartRequest) {

                    let error: string = authorizeTransactionResponse && authorizeTransactionResponse.errorMessage.trim().length !== 0 ? authorizeTransactionResponse.errorMessage : "";
                    this.rootSandbox.addErrorNotification(`Cannot add payment, error while authorizing transaction. ${error}`);

                    return observableOf(null);
                }

                shoppingCartRequest.paymentServiceAuthorizationTokensToAdd.push(authorizeTransactionResponse.authorizationToken);

                return this.shoppingCartRestService.updateShoppingCart(shoppingCartRequest, this.orderExternalID);
            })
        ).pipe(catchError((error: HttpErrorModel) => {
            this.rootSandbox.handleHttpError("Cannot add payment", error);
            return observableOf(null);
        }));

        // [2] Cloud payment device method
        let addPaymentMethodCloudPaymentDevice$: Observable<ShoppingCartResponseModel> = combineLatest([amount$, cloudPaymentData$]).pipe(
            take(1),
            mergeMap(([amount, cloudPaymentData]: [number, CloudPaymentDeviceDataModel]) => {

                if (cloudPaymentData.cloudPaymentDeviceId == null) {
                    const errorMessage = "Please select Cloud Payment Device.";
                    this.rootSandbox.addErrorNotification(errorMessage);
                    return throwError(errorMessage);
                }

                let request = new CloudPaymentAuthorizeTransactionRequest(cloudPaymentData.nmiPaymentDeviceId, amount);

                return combineLatest([
                    this.createShoppingCartRequestFromShoppingCart(),
                    this.cloudPaymentDeviceRestService.authorizeTransaction(request)
                ]);
            }),
            mergeMap(([shoppingCartRequest, authorizeTransactionResponse]: [ShoppingCartRequestModel, CloudPaymentAuthorizeTransactionAsyncResponse]) => {

                if (!authorizeTransactionResponse || authorizeTransactionResponse.hasError || !shoppingCartRequest) {

                    let error: string = authorizeTransactionResponse && authorizeTransactionResponse.errorMessage.trim().length !== 0 ? authorizeTransactionResponse.errorMessage : "";
                    this.rootSandbox.addErrorNotification(`Cannot add payment, error creating device request. ${error}`);
                    return observableOf(null);
                }

                return this.cloudPaymentDeviceRestService.getCloudPaymentTransaction(authorizeTransactionResponse.asyncStatusGuid).pipe(
                    switchMap((response) => {
                        if (response.hasError) {
                            this.rootSandbox.addErrorNotification(`Cannot add payment: ${response.errorMessage}`);
                            return observableOf(null);
                        }
                        if (response.asyncStatus === CloudPaymentRequestStatusEnum.INTERACTIONCOMPLETE && response.transactionCondition === CloudPaymentTransactionStatusEnum.PENDING) {
                            shoppingCartRequest.paymentServiceAuthorizationTokensToAdd.push(response?.authorizationToken);
                            return this.shoppingCartRestService.updateShoppingCart(shoppingCartRequest, this.orderExternalID);
                        }
                        if (response.asyncStatus == CloudPaymentRequestStatusEnum.POIDEVICEINUSE) {
                            return throwError(`POI Device in use`);
                        }
                        if (response.asyncStatus == CloudPaymentRequestStatusEnum.INFLIGHT) {
                            return checkCloudPaymentTransaction(authorizeTransactionResponse.asyncStatusGuid, shoppingCartRequest, this);
                        }
                        if (response.asyncStatus !== CloudPaymentRequestStatusEnum.INFLIGHT && response.asyncStatus !== CloudPaymentRequestStatusEnum.POIDEVICEINUSE) {
                            return throwError(`Transaction status: ${response.asyncStatus}`);
                        }
                    })
                );
            })
        ).pipe(catchError((error: HttpErrorModel) => {
            if (error.errorCode != null) {
                this.rootSandbox.handleHttpError("Cannot add payment", error);
            }
            else {
                this.rootSandbox.addErrorNotification(error.toString());
            }
            return observableOf(null);
        }));

        function checkCloudPaymentTransaction(asyncStatusGuid: string, shoppingCartRequest: ShoppingCartRequestModel, that: any): Observable<CloudPaymentTransactionStatusResponse> {
            return defer(() => {
                return that.cloudPaymentDeviceRestService.getCloudPaymentTransaction(asyncStatusGuid).pipe(
                    switchMap((res: CloudPaymentTransactionStatusResponse) => {
                        if (res.asyncStatus === CloudPaymentRequestStatusEnum.INFLIGHT || res.asyncStatus === CloudPaymentRequestStatusEnum.POIDEVICEINUSE) {
                            return checkCloudPaymentTransaction(asyncStatusGuid, shoppingCartRequest, that);
                        }
                        else if (res.asyncStatus === CloudPaymentRequestStatusEnum.INTERACTIONCOMPLETE && res.transactionCondition === CloudPaymentTransactionStatusEnum.PENDING) {
                            shoppingCartRequest.paymentServiceAuthorizationTokensToAdd.push(res?.authorizationToken);
                            return that.shoppingCartRestService.updateShoppingCart(shoppingCartRequest);
                        }
                        else if (res.asyncStatus === CloudPaymentRequestStatusEnum.CANCELLEDATTERMINAL) {
                            return throwError(`Transaction canceled at payment device!`);
                        }
                        else {
                            return throwError(res.errorMessage);
                        }
                    })
                );
            }) as Observable<CloudPaymentTransactionStatusResponse>;
        }

        // [3] Post bill and cash payment method
        let postBillCashPaymentMethod$: Observable<ShoppingCartResponseModel> = combineLatest([this.createShoppingCartRequestFromShoppingCart(), amount$, selectedPaymentMethodData$, postBillData$]).pipe(
            take(1),
            flatMap(([shoppingCartRequest, amount, selectedPaymentMethodData, postBillData]: [ShoppingCartRequestModel, number, PaymentMethodDataModel, PostBillDataModel]) => {

                // Add new post bill payment into shopping cart request
                const shoppingCartPaymentRequest: ShoppingCartPaymentRequestModel = new ShoppingCartPaymentRequestModel();
                shoppingCartPaymentRequest.amount = amount;
                shoppingCartPaymentRequest.paymentDateTime = DateTimeUtility.convertMomentToDateTimeDescriptor(moment());

                if (selectedPaymentMethodData.paymentMethodEnum === ShoppingCartPaymentMethodEnum.POST_BILL) {

                    shoppingCartPaymentRequest.paymentMethod = PaymentMethodEnum.POST_BILL;

                    shoppingCartPaymentRequest.paymentPostBillDetails = [];
                    const shoppingCartPaymentPostBillDetailRequest: ShoppingCartPaymentPostBillDetailRequestModel = new ShoppingCartPaymentPostBillDetailRequestModel(
                        amount,
                        selectedPaymentMethodData.postBillType.postBillTypeId,
                        null,
                        null
                    );

                    // Check if post bill is of type "ENTER CODE MANUALLY"
                    if (selectedPaymentMethodData.postBillType.postBillTypeAdditionalAction === PostBillTypeAdditionalActionEnum.ENTER_CODE_MANUALLY) {
                        shoppingCartPaymentPostBillDetailRequest.postBillCode = postBillData.postBillCode;
                    }

                    shoppingCartPaymentRequest.paymentPostBillDetails.push(shoppingCartPaymentPostBillDetailRequest);

                } else if (selectedPaymentMethodData.paymentMethodEnum === ShoppingCartPaymentMethodEnum.CASH) {
                    shoppingCartPaymentRequest.paymentMethod = PaymentMethodEnum.CASH;

                } else if (selectedPaymentMethodData.paymentMethodEnum === ShoppingCartPaymentMethodEnum.CREDIT_CARD) {
                    shoppingCartPaymentRequest.paymentMethod = PaymentMethodEnum.CUSTOM;

                } else {

                    // Unsupported payment method
                    this.rootSandbox.addErrorNotification("Unsupported payment method");
                    return observableOf(null);
                }

                shoppingCartRequest.payments.push(shoppingCartPaymentRequest);

                return this.shoppingCartRestService.updateShoppingCart(shoppingCartRequest, this.orderExternalID);
            })
        );

        // Depending on selected payment method, process it (subscribe to proper flow)
        return selectedPaymentMethodData$.pipe(
            take(1),
            flatMap((selectedPaymentMethodData: PaymentMethodDataModel | null) => {

            if (!selectedPaymentMethodData) {
                let httpError = new HttpErrorModel(
                    "API_APPLICATION",
                    ErrorCodeEnum.SMARTSTUBS_INTERNAL_SERVER_ERROR,
                    "No Payment options available. Please contact your administrator.",
                    "No Payment options available. Please contact your administrator."
                );
                return throwError(httpError);
            }

            if (selectedPaymentMethodData.paymentMethodEnum === ShoppingCartPaymentMethodEnum.CREDIT_CARD) {
                if (refundPayments) {
                  return postBillCashPaymentMethod$;
                } else {
                  return addPaymentMethodCreditCard$;
                }
              } else if (selectedPaymentMethodData.paymentMethodEnum === ShoppingCartPaymentMethodEnum.CLOUD_PAYMENT_DEVICE) {
                return addPaymentMethodCloudPaymentDevice$;
              } else {
                return postBillCashPaymentMethod$;
              }
            }),
            take(1),
            flatMap((response: ShoppingCartResponseModel | null) => {
              if (!response) {
                return observableOf(null);
              }
              return observableOf(this.updateShoppingCartState(response));
            })
          );
    }

    private validateCardNumber(cardNumber: string): boolean {

        const regexp: RegExp = /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(6(?:011|[5|7][0-9]{2})(([0-9]{12})|([0-9]{15})))|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;
        return regexp.test(cardNumber);
    }

    private validateCardSecurityCode(cardSecurityCode: string): boolean {

        const regexp: RegExp = /^[0-9]{3,4}$/;
        return regexp.test(cardSecurityCode);
    }

    private validateExpirationMonth(expirationMonth: string): boolean {

        const expirationMonthValue: number = parseInt(expirationMonth, 10);
        return !isNaN(expirationMonthValue) && expirationMonthValue >= 1 && expirationMonthValue <= 12;
    }

    private validateExpirationYear(expirationYear: string): boolean {

        const currentYear: number = moment().year();
        const expirationYearValue: number = parseInt(expirationYear, 10);
        return !isNaN(expirationYearValue) && expirationYearValue >= currentYear;
    }

    /**
     * Removes payment from shopping cart
     * @param paymentItem - payment item to be removed
     */
    removePaymentItem(paymentItem: PaymentItemDataModel): Observable<ShoppingCartResponseModel> {

        return this.shoppingCart$.pipe(
            flatMap((shoppingCart: ShoppingCartDataModel) => {

                // If not credit card payment to remove, remove shopping cart payment
                if (!isCreditCardPayment(paymentItem.paymentMethod)) {

                    let removeIndex: number = shoppingCart.paymentItems.findIndex((item: PaymentItemDataModel) => item.isEqualTo(paymentItem));
                    shoppingCart.paymentItems.splice(removeIndex, 1);
                }

                return this.createShoppingCartRequestFromShoppingCart();
            }),
            take(1),
            flatMap((shoppingCartRequest: ShoppingCartRequestModel) => {

                // If credit card payment to remove, add its payment service authorization token into list to remove
                if (isCreditCardPayment(paymentItem.paymentMethod)) {

                    // Remove payment service authorization token for credit card payment to be removed, from shopping cart request payment service authorization tokens to add
                    let removeIndex: number = shoppingCartRequest.paymentServiceAuthorizationTokensToAdd.findIndex((token: string) => token.localeCompare(paymentItem.paymentServiceAuthorizationToken) === 0);
                    shoppingCartRequest.paymentServiceAuthorizationTokensToAdd.splice(removeIndex, 1);

                    // Add payment service authorization token for credit card payment to be removed, into shopping cart request payment service authorization tokens to remove
                    shoppingCartRequest.paymentServiceAuthorizationTokensToRemove.push(paymentItem.paymentServiceAuthorizationToken);
                }

                return this.shoppingCartRestService.updateShoppingCart(shoppingCartRequest, this.orderExternalID);
            }),
            take(1),
            flatMap((shoppingCartResponse: ShoppingCartResponseModel) => observableOf(this.updateShoppingCartState(shoppingCartResponse)))
        ).pipe(catchError((error: HttpErrorModel) => {
            this.rootSandbox.handleHttpError("Error while removing payment", error);
            return observableOf(null);
        }));
    }

    /**
     * Removes payments from shopping cart
     * @param paymentItems - payment items to be removed
     */
    removePaymentItems(paymentItems: PaymentItemDataModel[]): Observable<ShoppingCartResponseModel> {

        return this.shoppingCart$.pipe(
            flatMap((shoppingCart: ShoppingCartDataModel) => {

                for (let paymentItem of paymentItems) {

                    // If not credit card payment to remove, remove shopping cart payment
                    if (!isCreditCardPayment(paymentItem.paymentMethod)) {

                        let removeIndex: number = shoppingCart.paymentItems.findIndex((item: PaymentItemDataModel) => item.isEqualTo(paymentItem));
                        shoppingCart.paymentItems.splice(removeIndex, 1);
                    }
                }

                return this.createShoppingCartRequestFromShoppingCart();
            }),
            take(1),
            flatMap((shoppingCartRequest: ShoppingCartRequestModel) => {

                for (let paymentItem of paymentItems) {

                    // If credit card payment to remove, add its payment service authorization token into list to remove
                    if (isCreditCardPayment(paymentItem.paymentMethod)) {

                        // Remove payment service authorization token for credit card payment to be removed, from shopping cart request payment service authorization tokens to add
                        let removeIndex: number = shoppingCartRequest.paymentServiceAuthorizationTokensToAdd.findIndex((token: string) => token.localeCompare(paymentItem.paymentServiceAuthorizationToken) === 0);
                        shoppingCartRequest.paymentServiceAuthorizationTokensToAdd.splice(removeIndex, 1);

                        // Add payment service authorization token for credit card payment to be removed, into shopping cart request payment service authorization tokens to remove
                        shoppingCartRequest.paymentServiceAuthorizationTokensToRemove.push(paymentItem.paymentServiceAuthorizationToken);
                    }
                }

                return this.shoppingCartRestService.updateShoppingCart(shoppingCartRequest, this.orderExternalID);
            }),
            take(1),
            flatMap((shoppingCartResponse: ShoppingCartResponseModel) => observableOf(this.updateShoppingCartState(shoppingCartResponse)))
        ).pipe(catchError((error: HttpErrorModel) => {
            this.rootSandbox.handleHttpError("Error while removing payment", error);
            return observableOf(null);
        }));
    }

    /**
     * Applies order level discount item to shopping cart.
     * @param addOrderLevelDiscountFn - function that takes in request and adds information about discount to request
     * @param discountCode - order level discount code we are adding
     */
    applyOrderLevelDiscountItem(discountCode: string, addOrderLevelDiscountFn: (discountCode: string, request: ShoppingCartRequestModel) => Observable<ShoppingCartRequestModel>): Observable<ShoppingCartResponseModel> {

        return this.createShoppingCartRequestFromShoppingCart().pipe(
            take(1),
            flatMap((request: ShoppingCartRequestModel) => addOrderLevelDiscountFn(discountCode, request)),
            take(1),
            flatMap((request: ShoppingCartRequestModel) => this.shoppingCartRestService.updateShoppingCart(request, this.orderExternalID)),
            take(1),
            flatMap((response: ShoppingCartResponseModel) => observableOf(this.updateShoppingCartState(response)))
        );
    }

    /**
     * Removes order level discount item
     * @param orderLevelDiscountItem - order level discount item to be removed from shopping cart
     */
    removeOrderLevelDiscountItem(orderLevelDiscountItem: OrderCostItemDataModel): Observable<ShoppingCartResponseModel> {

        return this.shoppingCart$.pipe(
            take(1),
            flatMap((shoppingCart: ShoppingCartDataModel) => {

                let removeIndex: number = shoppingCart.orderLevelDiscountItems.findIndex((item: OrderCostItemDataModel) => item.isEqualTo(orderLevelDiscountItem));
                shoppingCart.orderLevelDiscountItems.splice(removeIndex, 1);

                return this.createShoppingCartRequestFromShoppingCart();
            }),
            take(1),
            flatMap((request: ShoppingCartRequestModel) => this.shoppingCartRestService.updateShoppingCart(request, this.orderExternalID)),
            take(1),
            flatMap((response: ShoppingCartResponseModel) => observableOf(this.updateShoppingCartState(response)))
        );
    }

    // noinspection JSMethodCanBeStatic
    private createShoppingCartRequestFromShoppingCart(shouldProcessOrder: boolean = false, orderInfo$: Observable<OrderInfoDataModel> = of(this.DEFAULT_ORDER_INFO)): Observable<ShoppingCartRequestModel> {

        return combineLatest([this.shoppingCart$, this.currentUser$, orderInfo$]).pipe(
            take(1),
            map((val: any[]) => {

                let shoppingCart: ShoppingCartDataModel = val[0];
                let user: UserInfoResponseModel = val[1];
                let orderInfo: OrderInfoDataModel = val[2];

                let request: ShoppingCartRequestModel = new ShoppingCartRequestModel();

                request.shouldProcessOrder = shouldProcessOrder;
                request.shouldGeneratePdf = false;
                request.shouldCreateFreeSellProducts = true;
                request.shouldGenerateExternalIds = true;
                request.shouldGenerateTicketsAndPassCards = true;
                request.shouldSendProductOwnersEmails = true;
                request.shouldSendCustomerEmail = shouldProcessOrder && orderInfo.sendEmailToCustomer;
                request.shouldGenerateGooglePass = request.shouldSendCustomerEmail;
                request.shouldGenerateApplePass = request.shouldSendCustomerEmail;
                if (shoppingCart.shoppingCartResponse) {
                    request.orderExternalId = shoppingCart.shoppingCartResponse.orderExternalId ? shoppingCart.shoppingCartResponse.orderExternalId : null;
                }
                else {
                    request.orderExternalId = null;
                }
                request.soldById = user.userId;
                request.soldAtLocationId = user.locationId;
                request.referredById = orderInfo.referredById;
                request.soldFrom = PlatformEnum.WEB_APPLICATION;
                request.firstName = null;
                request.lastName = orderInfo.passengerName;
                request.email = orderInfo.email;
                request.phone = null;
                request.address1 = orderInfo.address1;
                request.address2 = orderInfo.address2;
                request.city = orderInfo.city;
                request.state = orderInfo.state;
                request.country = orderInfo.country;
                request.zipCode = orderInfo.zipCode;
                request.isTicketPerPerson = orderInfo.printOneTicketPerPerson;
                request.signature = null;

                request.products = this.getShoppingCartProductRequestsFromShoppingCart(shoppingCart);
                request.passes = this.getShoppingCartPassRequestsFromShoppingCart(shoppingCart);
                request.payments = this.getShoppingCartPaymentRequestsFromShoppingCart(shoppingCart);

                request.paymentServiceAuthorizationTokensToAdd = shoppingCart.paymentItems
                    .filter((paymentItem: PaymentItemDataModel) => isCreditCardPayment(paymentItem.paymentMethod) && paymentItem.paymentServiceAuthorizationToken != null)
                    .map((paymentItem: PaymentItemDataModel) => paymentItem.paymentServiceAuthorizationToken);

                request.paymentServiceAuthorizationTokensToRemove = [];

                request.customFields = shoppingCart.shoppingCartResponse && shoppingCart.shoppingCartResponse.customFields
                    ? shoppingCart.shoppingCartResponse.customFields.map(cf => new ShoppingCartCustomFieldRequestModel(cf.templateItemId, cf.fieldValue, 0))
                    : [];

                request.discountCodes = shoppingCart.orderLevelDiscountItems
                    ? shoppingCart.orderLevelDiscountItems.map((d) => d.discountCode)
                    : [];

                return request;
            })
        );
    }

    private getShoppingCartProductRequestsFromShoppingCart(shoppingCart: ShoppingCartDataModel): ShoppingCartProductRequestModel[] {

        if (!shoppingCart.shoppingCartResponse || !shoppingCart.shoppingCartResponse.products) {
            return [];
        }

        let requests: ShoppingCartProductRequestModel[] = [];

        shoppingCart.shoppingCartItems
            .filter((sci) => sci.type === ShoppingCartItemTypeEnum.PRODUCT)
            .forEach((sci: ShoppingCartItemDataModel) => {

                let firstLeg: ShoppingCartProductResponseModel = sci.parentProduct;
                let secondLeg: ShoppingCartProductResponseModel = sci.childProduct;

                let productId: number = firstLeg.productId;
                let isRoundTrip: boolean = firstLeg.isRoundTrip;
                let isReturnTrip: boolean = firstLeg.isReturnTrip;

                let pickupLocationId1stLeg: number = firstLeg.pickupLocationId;
                let customPickupLocationDescription1stLeg: string = firstLeg.customPickupLocationDescription;
                let customPickupLocationLatitude1stLeg: number = firstLeg.customPickupLocationLatitude;
                let customPickupLocationLongitude1stLeg: number = firstLeg.customPickupLocationLongitude;
                let dropoffLocationId1stLeg: number = firstLeg.dropoffLocationId;
                let customDropoffLocationDescription1stLeg: string = firstLeg.customDropoffLocationDescription;
                let customDropoffLocationLatitude1stLeg: number = firstLeg.customDropoffLocationLatitude;
                let customDropoffLocationLongitude1stLeg: number = firstLeg.customDropoffLocationLongitude;
                let dateInt1stLeg: number = firstLeg.dateInt;
                let productAvailabilityId1stLeg: number = firstLeg.productAvailabilityId;
                let customFields1stLeg: ShoppingCartCustomFieldRequestModel[] = firstLeg && firstLeg.customFields
                    ? firstLeg.customFields.map(cf => new ShoppingCartCustomFieldRequestModel(cf.templateItemId, cf.fieldValue, cf.partyMember)) : [];

                let pickupLocationId2ndLeg: number = null;
                let customPickupLocationDescription2ndLeg: string = null;
                let customPickupLocationLatitude2ndLeg: number = null;
                let customPickupLocationLongitude2ndLeg: number = null;
                let dropoffLocationId2ndLeg: number = null;
                let customDropoffLocationDescription2ndLeg: string = null;
                let customDropoffLocationLatitude2ndLeg: number = null;
                let customDropoffLocationLongitude2ndLeg: number = null;
                let dateInt2ndLeg: number = null;
                let productAvailabilityId2ndLeg: number = null;
                let customFields2ndLeg: ShoppingCartCustomFieldRequestModel[] = null;

                if (secondLeg) {
                    pickupLocationId2ndLeg = secondLeg.pickupLocationId;
                    customPickupLocationDescription2ndLeg = secondLeg.customPickupLocationDescription;
                    customPickupLocationLatitude2ndLeg = secondLeg.customPickupLocationLatitude;
                    customPickupLocationLongitude2ndLeg = secondLeg.customPickupLocationLongitude;
                    dropoffLocationId2ndLeg = secondLeg.dropoffLocationId;
                    customDropoffLocationDescription2ndLeg = secondLeg.customDropoffLocationDescription;
                    customDropoffLocationLatitude2ndLeg = secondLeg.customDropoffLocationLatitude;
                    customDropoffLocationLongitude2ndLeg = secondLeg.customDropoffLocationLongitude;
                    dateInt2ndLeg = secondLeg.dateInt;
                    productAvailabilityId2ndLeg = secondLeg.productAvailabilityId;
                    customFields2ndLeg = secondLeg && secondLeg.customFields
                        ? secondLeg.customFields.map(cf => new ShoppingCartCustomFieldRequestModel(cf.templateItemId, cf.fieldValue, cf.partyMember)) : [];

                }

                let tiers: ShoppingCartProductTierRequestModel[] = firstLeg.productTiers.map((tier) => new ShoppingCartProductTierRequestModel(tier.tierId, tier.quantity, !tier.isCustomPrice, tier.price));
                let discountCodes: string[] = firstLeg.productCosts.filter((c) => c.costType === CostTypeEnum.DISCOUNT).map((d) => d.discountCode);

                let tickets: ShoppingCartTicketRequestModel[] = [];

                let productRequests: ShoppingCartProductRequestModel[] = ShoppingCartRequestHelper.createShoppingCartProductRequest(
                    productId,
                    isRoundTrip,
                    isReturnTrip,
                    pickupLocationId1stLeg,
                    customPickupLocationDescription1stLeg,
                    customPickupLocationLatitude1stLeg,
                    customPickupLocationLongitude1stLeg,
                    dropoffLocationId1stLeg,
                    customDropoffLocationDescription1stLeg,
                    customDropoffLocationLatitude1stLeg,
                    customDropoffLocationLongitude1stLeg,
                    dateInt1stLeg,
                    productAvailabilityId1stLeg,
                    customFields1stLeg,
                    pickupLocationId2ndLeg,
                    customPickupLocationDescription2ndLeg,
                    customPickupLocationLatitude2ndLeg,
                    customPickupLocationLongitude2ndLeg,
                    dropoffLocationId2ndLeg,
                    customDropoffLocationDescription2ndLeg,
                    customDropoffLocationLatitude2ndLeg,
                    customDropoffLocationLongitude2ndLeg,
                    dateInt2ndLeg,
                    productAvailabilityId2ndLeg,
                    customFields2ndLeg,
                    tiers,
                    tickets,
                    discountCodes
                );

                requests = [...requests, ...productRequests];
            });


        return requests;
    }

    private getShoppingCartPassRequestsFromShoppingCart(shoppingCart: ShoppingCartDataModel): ShoppingCartPassRequestModel[] {

        if (!shoppingCart.shoppingCartResponse || !shoppingCart.shoppingCartResponse.passes) {
            return [];
        }

        let requests: ShoppingCartPassRequestModel[] = [];

        shoppingCart.shoppingCartItems
            .filter((sci) => sci.type === ShoppingCartItemTypeEnum.PASS)
            .forEach((sci: ShoppingCartItemDataModel) => {

                let pass: ShoppingCartPassResponseModel = sci.pass;

                let passTiers: ShoppingCartPassTierRequestModel[] = pass.passTiers.map((tier) => new ShoppingCartProductTierRequestModel(tier.tierId, tier.quantity, !tier.isCustomPrice, tier.price));

                let passCards: ShoppingCartPassCardRequestModel[] = [];

                let customFields: ShoppingCartCustomFieldRequestModel[] = pass.customFields.map(cf => new ShoppingCartCustomFieldRequestModel(cf.templateItemId, cf.fieldValue, cf.partyMember));

                let discountCodes: string[] = pass.passCosts
                    .filter((d) => d.costType === CostTypeEnum.DISCOUNT)
                    .map((d) => d.discountCode);

                let request: ShoppingCartPassRequestModel = ShoppingCartRequestHelper.createShoppingCartPassRequest(
                    pass.passId,
                    pass.pickupLocationId,
                    pass.dropoffLocationId,
                    passTiers,
                    passCards,
                    customFields,
                    discountCodes
                );

                requests.push(request);
            });

        return requests;
    }

    // noinspection JSMethodCanBeStatic
    private getShoppingCartPaymentRequestsFromShoppingCart(shoppingCart: ShoppingCartDataModel): ShoppingCartPaymentRequestModel[] {

        let shoppingCartPaymentRequests: ShoppingCartPaymentRequestModel[] = [];
        let shoppingCartPaymentRequest: ShoppingCartPaymentRequestModel;

        for (let shoppingCartPayment of shoppingCart.paymentItems) {

            // Do not create requests for credit card payments; they are processed "externally", via payment service authorization tokens
            if (!isCreditCardPayment(shoppingCartPayment.paymentMethod)) {

                shoppingCartPaymentRequest = new ShoppingCartPaymentRequestModel();
                shoppingCartPaymentRequest.amount = shoppingCartPayment.amount;
                shoppingCartPaymentRequest.paymentDateTime = shoppingCartPayment.paymentDateTime;
                shoppingCartPaymentRequest.paymentMethod = shoppingCartPayment.paymentMethod;

                // Post bill payment
                if (shoppingCartPayment.paymentMethod === PaymentMethodEnum.POST_BILL) {

                    shoppingCartPaymentRequest.paymentPostBillDetails = [];
                    const shoppingCartPaymentPostBillDetailRequest: ShoppingCartPaymentPostBillDetailRequestModel = new ShoppingCartPaymentPostBillDetailRequestModel(
                        shoppingCartPayment.amount,
                        shoppingCartPayment.postBillTypeId,
                        shoppingCartPayment.postBillImage,
                        shoppingCartPayment.postBillCode
                    );
                    shoppingCartPaymentRequest.paymentPostBillDetails.push(shoppingCartPaymentPostBillDetailRequest);
                }

                shoppingCartPaymentRequests.push(shoppingCartPaymentRequest);
            }
            else if (shoppingCartPayment.amount < 0) {

                shoppingCartPaymentRequest = new ShoppingCartPaymentRequestModel();
                shoppingCartPaymentRequest.amount = shoppingCartPayment.amount;
                shoppingCartPaymentRequest.paymentDateTime = shoppingCartPayment.paymentDateTime;
                shoppingCartPaymentRequest.paymentMethod = shoppingCartPayment.paymentMethod;
                shoppingCartPaymentRequest.lastFourOfCard = shoppingCartPayment.lastFourOfCard;

                shoppingCartPaymentRequests.push(shoppingCartPaymentRequest);
            }
        }

        return shoppingCartPaymentRequests;
    }

    // noinspection JSMethodCanBeStatic
    public updateShoppingCartState(shoppingCartResponse: ShoppingCartResponseModel): ShoppingCartResponseModel {

        if (shoppingCartResponse.hasError || shoppingCartResponse.products.some(p => p.hasError) || shoppingCartResponse.passes.some(p => p.hasError)) {

            if (shoppingCartResponse.errorMessage) {
                this.rootSandbox.addErrorNotification("Cannot update shopping cart. Error: " + shoppingCartResponse.errorMessage);
            } else {
                this.rootSandbox.addInfoNotification("Cannot update shopping cart");
            }

            return shoppingCartResponse;
        }

        // Clear notifications if everything is ok
        this.rootSandbox.disposeFirstNotification();

        // ---------------------------------
        // Generate Shopping Cart Items
        // ---------------------------------

        // Skip products where product is a child product
        let shoppingCartProducts: ShoppingCartItemDataModel[] = shoppingCartResponse.products
            .filter((p) => (p.childGuid === null && p.parentGuid !== null) || (p.parentGuid === null && p.childGuid === null))
            .map((product: ShoppingCartProductResponseModel) => {

                let shoppingCartItem: ShoppingCartItemDataModel = new ShoppingCartItemDataModel();

                shoppingCartItem.type = ShoppingCartItemTypeEnum.PRODUCT;
                shoppingCartItem.parentProduct = product;

                if (product.parentGuid && product.isRoundTrip) {
                    shoppingCartItem.childProduct = shoppingCartResponse.products.find((p) => p.childGuid === product.parentGuid);
                }

                shoppingCartItem.editMode = false;

                return shoppingCartItem;
            });

        let shoppingCartPasses: ShoppingCartItemDataModel[] = shoppingCartResponse.passes
            .map((pass: ShoppingCartPassResponseModel) => {

                let shoppingCartItem: ShoppingCartItemDataModel = new ShoppingCartItemDataModel();

                shoppingCartItem.type = ShoppingCartItemTypeEnum.PASS;
                shoppingCartItem.pass = pass;
                shoppingCartItem.editMode = false;

                return shoppingCartItem;
            });

        // ---------------------------------
        // Generate payment items
        // ---------------------------------

        const paymentItems: PaymentItemDataModel[] = [];
        let paymentItem: PaymentItemDataModel;

        if (shoppingCartResponse.payments !== null) {

            for (let shoppingCartPaymentResponse of shoppingCartResponse.payments) {

                paymentItem = new PaymentItemDataModel();
                paymentItem.amount = shoppingCartPaymentResponse.amount;
                paymentItem.paymentDateTime = shoppingCartPaymentResponse.paymentDateTime;
                paymentItem.paymentMethod = shoppingCartPaymentResponse.paymentMethod;
                paymentItem.paymentMethodDescription = shoppingCartPaymentResponse.paymentMethodDescription;

                // Credit card payment
                if (isCreditCardPayment(shoppingCartPaymentResponse.paymentMethod)) {
                    paymentItem.paymentServiceAuthorizationToken = shoppingCartPaymentResponse.paymentServiceAuthorizationToken;
                    paymentItem.lastFourOfCard = shoppingCartPaymentResponse.lastFourOfCard;
                }

                // Post bill payment
                if (shoppingCartPaymentResponse.paymentMethod === PaymentMethodEnum.POST_BILL) {

                    paymentItem.postBillTypeId = shoppingCartPaymentResponse.paymentPostBillDetails[0].postBillTypeId;
                    paymentItem.postBillTypeDescription = shoppingCartPaymentResponse.paymentPostBillDetails[0].postBillTypeDescription;
                    paymentItem.postBillTypeAdditionalAction = shoppingCartPaymentResponse.paymentPostBillDetails[0].postBillTypeAdditionalAction;
                    paymentItem.postBillImage = shoppingCartPaymentResponse.paymentPostBillDetails[0].postBillImage;
                    paymentItem.postBillCode = shoppingCartPaymentResponse.paymentPostBillDetails[0].postBillCode;
                }

                paymentItems.push(paymentItem);
            }
        }

        // ----------------------------------------------
        // Generate order level additional cost items
        // ----------------------------------------------

        const orderLevelAdditionalCostItems: OrderCostItemDataModel[] = [];
        let orderLevelAdditionalCostItem: OrderCostItemDataModel = null;
        if (shoppingCartResponse.orderLevelAdditionalCosts !== null) {
            shoppingCartResponse.orderLevelAdditionalCosts.map((shoppingCartOrderLevelAdditionalCostResponse: ShoppingCartOrderCostResponseModel) => {

                orderLevelAdditionalCostItem = new OrderCostItemDataModel();
                orderLevelAdditionalCostItem.costDescription = shoppingCartOrderLevelAdditionalCostResponse.costDescription;
                orderLevelAdditionalCostItem.amount = shoppingCartOrderLevelAdditionalCostResponse.amount;
                orderLevelAdditionalCostItem.discountCode = shoppingCartOrderLevelAdditionalCostResponse.discountCode;
                orderLevelAdditionalCostItems.push(orderLevelAdditionalCostItem);
            });
        }

        // ----------------------------------------------
        // Generate order level discount items
        // ----------------------------------------------

        const orderLevelDiscountItems: OrderCostItemDataModel[] = [];
        let orderLevelDiscountItem: OrderCostItemDataModel = null;
        if (shoppingCartResponse.orderLevelDiscounts !== null) {
            shoppingCartResponse.orderLevelDiscounts.map((shoppingCartOrderLevelDiscountResponse: ShoppingCartOrderCostResponseModel) => {

                orderLevelDiscountItem = new OrderCostItemDataModel();
                orderLevelDiscountItem.costDescription = shoppingCartOrderLevelDiscountResponse.costDescription;
                orderLevelDiscountItem.amount = shoppingCartOrderLevelDiscountResponse.amount;
                orderLevelDiscountItem.discountCode = shoppingCartOrderLevelDiscountResponse.discountCode;
                orderLevelDiscountItems.push(orderLevelDiscountItem);
            });
        }

        // Dispatch actions
        this.store.dispatch(new actions.ShoppingCartSetShoppingCartResponse(shoppingCartResponse));
        this.store.dispatch(new actions.ShoppingCartSetShoppingCartItems([...shoppingCartProducts, ...shoppingCartPasses]));
        this.store.dispatch(new actions.ShoppingCartSetPaymentItems(paymentItems));
        this.store.dispatch(new actions.ShoppingCartSetOrderLevelAdditionalCostItems(orderLevelAdditionalCostItems));
        this.store.dispatch(new actions.ShoppingCartSetOrderLevelDiscountItems(orderLevelDiscountItems));

        return shoppingCartResponse;
    }

    private getTemplateItemsForShoppingCart(shoppingCart: ShoppingCartDataModel): Observable<TemplateItemResponseModel[]> {

        let products: number[] = [];
        let passes: number[] = [];

        shoppingCart.shoppingCartItems.forEach(sci => {
            if (sci.type === ShoppingCartItemTypeEnum.PRODUCT) {
                products.push(sci.parentProduct.productId);
            } else {
                passes.push(sci.pass.passId);
            }
        });

        return this.templateRestService.getTemplateItemsForProductsAndPasses(new GetTemplateItemsForProductsAndPassesRequest(products, passes));
    }
}
