
import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {Store} from "@ngrx/store";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {LetterGroupResponseModel} from "../../../../../../../../core/modules/rest/letter-group/response/letter-group-response.model";
import {LetterGroupRestService} from "../../../../../../../../core/modules/rest/letter-group/letter-group-rest.service";
import {UpdateLetterGroupRequestModel} from "../../../../../../../../core/modules/rest/letter-group/request/update-letter-group-request.model";
import {CreateLetterGroupRequestModel} from "../../../../../../../../core/modules/rest/letter-group/request/create-letter-group-request.model";


@Injectable()
export class EditLetterGroupDialogSandbox extends DialogSandbox {

    letterGroup$: Observable<LetterGroupResponseModel> = this.store.select(reducer.letter_group_selector);

    constructor(appDialogsService: AppDialogsService,
                private letterGroupRestService: LetterGroupRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadDepartureGroup(letterGroupId: number) {

        this.showLoader();

        this.letterGroupRestService.getById(letterGroupId).subscribe((letterGroup: LetterGroupResponseModel) => {
            this.store.dispatch(new actions.SetLetterGroup(letterGroup));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    createLetterGroup(description: string): Observable<CreateBaseResponseModel> {
        const request = new CreateLetterGroupRequestModel(description);

        return this.letterGroupRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating letter group', error);
            }));
    }

    updateLetterGroup(letterGroupId: number, description: string): Observable<any> {
        const request = new UpdateLetterGroupRequestModel(letterGroupId, description);

        return this.letterGroupRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating letter group', error);
            }));
    }
}
