import { PostBillTypeAdditionalActionEnum } from "../../../../../shared/enums/post-bill-type-additional-action.enum";

export class UpdatePostBillTypeRequestModel {
    postBillTypeId: number;
    description: string;
    details: string;
    useDeposit: boolean;
    depositAmount; number
    postBillTypeAdditionalAction: PostBillTypeAdditionalActionEnum;

    constructor(postBillTypeId: number, description: string, details: string, useDeposit: boolean,depositAmount: number, postBillTypeAdditionalAction: PostBillTypeAdditionalActionEnum) {
        this.postBillTypeId = postBillTypeId;
        this.description = description;
        this.details = details;
        this.postBillTypeAdditionalAction = postBillTypeAdditionalAction;
        this.useDeposit = useDeposit;
        this.depositAmount = depositAmount;
    }
}
