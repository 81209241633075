import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {FormControl, FormGroup} from "@angular/forms";

export interface SubmitProductEmailHtmlTemplatesData {
    productId: number;
    createOrderEmailHtmlTemplateId: number;
    voidOrderEmailHtmlTemplateId: number;
    termsAndConditionsEmailHtmlTemplateId: number;
}


@Component({
    selector: 'app-edit-product-email-html-templates-dialog-form',
    templateUrl: './edit-product-email-html-templates-dialog-form.component.html'
})

export class EditProductEmailHtmlTemplatesDialogFormComponent implements OnChanges {

    @Input() product: ProductSimpleResponseModel;
    @Input() createOrderEmailHtmlTemplates: DropdownOptionModel[];
    @Input() voidOrderEmailHtmlTemplates: DropdownOptionModel[];
    @Input() termsAndConditionsEmailHtmlTemplates: DropdownOptionModel[];

    @Output() submit: EventEmitter<SubmitProductEmailHtmlTemplatesData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    constructor() {

        this.form = new FormGroup({
            createOrderEmailHtmlTemplateId: new FormControl(),
            voidOrderEmailHtmlTemplateId: new FormControl(),
            termsAndConditionsEmailHtmlTemplateId: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.product) {
            this.initializeFormData();
        }
    }

    onSubmit() {

        let createOrderEmailHtmlTemplateId: number = this.form.value.createOrderEmailHtmlTemplateId ? +this.form.value.createOrderEmailHtmlTemplateId : null;
        let voidOrderEmailHtmlTemplateId: number = this.form.value.voidOrderEmailHtmlTemplateId ? +this.form.value.voidOrderEmailHtmlTemplateId : null;
        let termsAndConditionsEmailHtmlTemplateId: number = this.form.value.termsAndConditionsEmailHtmlTemplateId ? +this.form.value.termsAndConditionsEmailHtmlTemplateId : null;

        this.submit.emit({
            productId: this.product.productId,
            createOrderEmailHtmlTemplateId: createOrderEmailHtmlTemplateId,
            voidOrderEmailHtmlTemplateId: voidOrderEmailHtmlTemplateId,
            termsAndConditionsEmailHtmlTemplateId: termsAndConditionsEmailHtmlTemplateId
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {

        this.form.patchValue({
            createOrderEmailHtmlTemplateId: this.product.createOrderEmailHtmlTemplateId ? this.product.createOrderEmailHtmlTemplateId.toString() : null,
            voidOrderEmailHtmlTemplateId: this.product.voidOrderEmailHtmlTemplateId ? this.product.voidOrderEmailHtmlTemplateId.toString() : null,
            termsAndConditionsEmailHtmlTemplateId: this.product.termsAndConditionsEmailHtmlTemplateId ? this.product.termsAndConditionsEmailHtmlTemplateId.toString() : null
        });
    }
}
