import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {FormControl, FormGroup} from "@angular/forms";

export interface SubmitProductRoutesData {
    productId: number;
    tracksQuantityBetweenStops: boolean;
    usesPickupLocations: boolean;
    pickupLocationListId: number;
    usesDropoffLocations: boolean;
    dropoffLocationListId: number;
}


@Component({
    selector: 'app-edit-product-routes-dialog-form',
    templateUrl: './edit-product-routes-dialog-form.component.html'
})

export class EditProductRoutesDialogFormComponent implements OnChanges {

    @Input() product: ProductSimpleResponseModel;
    @Input() pickupDropoffLocationLists: DropdownOptionModel[];
    @Input() pickupLocationLists: DropdownOptionModel[];
    @Input() dropoffLocationLists: DropdownOptionModel[];

    @Output() submit: EventEmitter<SubmitProductRoutesData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        this.form = new FormGroup({
            tracksQuantityBetweenStops: new FormControl(),
            pickupDropoffLocationListId: new FormControl(),
            usesPickupLocations: new FormControl(),
            pickupLocationListId: new FormControl(),
            usesDropoffLocations: new FormControl(),
            dropoffLocationListId: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.product) {
            this.initializeFormData();
        }
    }

    onSubmit() {

        let tracksQuantityBetweenStops: boolean = this.form.value.tracksQuantityBetweenStops;
        let pickupDropoffLocationListId: number = this.form.value.pickupDropoffLocationListId;
        let usesPickupLocations: boolean = this.form.value.usesPickupLocations;
        let pickupLocationListId: number = this.form.value.pickupLocationListId;
        let usesDropoffLocations: boolean = this.form.value.usesDropoffLocations;
        let dropoffLocationListId: number = this.form.value.dropoffLocationListId;

        if (tracksQuantityBetweenStops && !pickupDropoffLocationListId) {
            this.hasError = true;
            this.errorMessage = "Please choose pickup/dropoff location list";
            return;
        }

        if (!tracksQuantityBetweenStops) {
            if (usesPickupLocations && !pickupLocationListId) {
                this.hasError = true;
                this.errorMessage = "Please choose pickup location list";
                return;
            }
            if (usesDropoffLocations && !dropoffLocationListId) {
                this.hasError = true;
                this.errorMessage = "Please choose dropoff location list";
                return;
            }
        }

        // If product tracks quantity between stops, it has to use both pickup and dropoff location lists, and pickup and dropoff location lists have to be the same list
        if ( tracksQuantityBetweenStops ) {
            usesPickupLocations = true;
            pickupLocationListId = pickupDropoffLocationListId;
            usesDropoffLocations = true;
            dropoffLocationListId = pickupDropoffLocationListId;
        }

        this.submit.emit({
            productId: this.product.productId,
            tracksQuantityBetweenStops: tracksQuantityBetweenStops,
            usesPickupLocations: usesPickupLocations,
            pickupLocationListId: pickupLocationListId,
            usesDropoffLocations: usesDropoffLocations,
            dropoffLocationListId: dropoffLocationListId
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {

        this.form.patchValue({
            tracksQuantityBetweenStops: this.product.tracksQuantityBetweenStops,
            pickupDropoffLocationListId: this.product.pickupLocationListId,
            usesPickupLocations: this.product.usesPickupLocations,
            pickupLocationListId: this.product.pickupLocationListId,
            usesDropoffLocations: this.product.usesDropoffLocations,
            dropoffLocationListId: this.product.dropoffLocationListId
        });
    }
}
