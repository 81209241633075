export class ShoppingCartPaymentPostBillDetailRequestModel {
    amount: number;
    postBillTypeId: number;
    postBillImage: string;
    postBillCode: string;

    constructor(amount: number, postBillTypeId: number, postBillImage: string, postBillCode: string) {
        this.amount = amount;
        this.postBillTypeId = postBillTypeId;
        this.postBillImage = postBillImage;
        this.postBillCode = postBillCode;
    }
}