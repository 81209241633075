import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {CostTypeEnum} from "../../../../../../../shared/enums/cost-type.enum";
import {AccordionItemService} from "../../../../../../../shared/components/accordion/accordion-item/accordion-item.service";
import {Subscription} from "rxjs";
import {EMPTY_STATE_ICON_CREATE_NEW} from "../../../../../../../core/services/app-properties.service";
import {AutoAppliedDiscountResponseModel} from "../../../../../../../core/modules/rest/cost/response/auto-applied-discount-response.model";
import {AutoAppliedDiscountPriorityEventDataModel} from "../../model/auto-applied-discount-priority-event-data.model";
import { YesNoDialogComponent, YesNoDialogData } from '../../../../../../../shared/dialogs/common-dialogs/yes-no-dialog/container/yes-no-dialog.component';
import { AppDialogsService } from '../../../../../../../core/services/app-dialogs.service';
import { CostRestService } from '../../../../../../../core/modules/rest/cost/cost-rest.service';

@Component({
    selector: 'app-auto-applied-discounts-container',
    templateUrl: './auto-applied-discounts-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        AccordionItemService
    ],
})
export class AutoAppliedDiscountsContainerComponent implements OnInit, OnChanges, OnDestroy {
    emptyStateIcon = EMPTY_STATE_ICON_CREATE_NEW;

    @Input() activeCostType: CostTypeEnum; // Used in ngOnChanges
    @Input() autoAppliedDiscounts: AutoAppliedDiscountResponseModel[];
    @Input() showInactiveAutoAppliedDiscounts: boolean;

    @Output() activateCostTypeEvent: EventEmitter<CostTypeEnum> = new EventEmitter();
    @Output() addNewAutoAppliedDiscountEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() showInactiveAutoAppliedDiscountsEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() mappingAutoAppliedDiscountEvent: EventEmitter<[number, string]> = new EventEmitter();
    @Output() editAutoAppliedDiscountEvent: EventEmitter<number> = new EventEmitter();
    @Output() editAutoAppliedDiscountActivityEvent: EventEmitter<number> = new EventEmitter();
    @Output() autoAppliedDiscountPriorityEvent: EventEmitter<AutoAppliedDiscountPriorityEventDataModel> = new EventEmitter();

    private thisCostType: CostTypeEnum = CostTypeEnum.AUTO_APPLIED_DISCOUNT;
    private accordionHeaderClickSubscription: Subscription;

    inactiveShown: boolean = false;

    constructor(private accordionItemService: AccordionItemService, private appDialogsService: AppDialogsService,  private costRestService: CostRestService) {
        this.accordionHeaderClickSubscription = this.accordionItemService.listenOnHeaderClick().subscribe(this.activateCostType.bind(this));
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeCostType) {
            this.updateAccordionActivity();
        }
    }

    ngOnDestroy(): void {
        if (this.accordionHeaderClickSubscription) {
            this.accordionHeaderClickSubscription.unsubscribe();
        }
    }

    addNewAutoAppliedDiscountClick() {
        this.addNewAutoAppliedDiscountEvent.emit();
    }

    onShowInactiveAutoAppliedDiscounts(value: boolean) {

        this.inactiveShown = value;

        this.showInactiveAutoAppliedDiscountsEvent.emit(value);
    }

    onMappingAutoAppliedDiscount(costInfo: [number, string]) {
        this.mappingAutoAppliedDiscountEvent.emit(costInfo);
    }

    onEditAutoAppliedDiscount(costId: number) {
        this.editAutoAppliedDiscountEvent.emit(costId);
    }

    onEditAutoAppliedDiscountActivity(autoAppliedDiscount: AutoAppliedDiscountResponseModel) {
        if( autoAppliedDiscount.active ) {
            this.costRestService.getAllReferencesByCostId(autoAppliedDiscount.costId).subscribe((res) => {
                let message: string = '\n'
                if(res.responseListCategory.length > 0) {
                    message += 'This cost is still used in some categories:\n\n'
                    for (let category in res.responseListCategory) {
                        message += '• ' + res.responseListCategory[category].description + "\n"
                    }
                    message += '\n'
                }
                if (res.responseListProduct.length > 0) {
                    message += 'This cost is still used in some products:\n\n'
                    for (let product in res.responseListProduct) {
                        message += '• ' + res.responseListProduct[product].description + "\n"
                    }
                    message += '\n'
                }
                if (res.responseListPass.length > 0) {
                    message += 'This cost is still used in some passes:\n\n'
                    for (let pass in res.responseListPass) {
                        message += '• ' + res.responseListPass[pass].description + "\n"
                    }
                    message += '\n'
                }
                if (message != '\n') {
                    message += 'PLEASE CHANGE ALL COSTS MANUALLY!\n'
                    let data: YesNoDialogData = new YesNoDialogData(
                        "Cost",
                        message,
                        "Ok",
                        "Cancel",
                        "costPrimaryButton",
                        (confirmed: boolean) => {
                        }
                    );
                    this.appDialogsService.renderDialog(YesNoDialogComponent, data)
                }
            });
        }
        this.editAutoAppliedDiscountActivityEvent.emit(autoAppliedDiscount.costId);
    }

    onAutoAppliedDiscountPriorityChanged(data: AutoAppliedDiscountPriorityEventDataModel) {
        this.autoAppliedDiscountPriorityEvent.emit(data);
    }

    private updateAccordionActivity() {
        this.accordionItemService.changeItemActivityStatus(this.activeCostType === this.thisCostType);
    }

    private activateCostType() {
        this.activateCostTypeEvent.emit(this.activeCostType !== this.thisCostType ? this.thisCostType : null);
    }
}
