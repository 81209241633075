import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { DomainResponseModel } from '../../../../../../../../core/modules/rest/domain/response/domain-response.model';
import { DialogData } from "../../../../../../../../shared/dialogs/model/dialog.data";
import { EditDomainDialogSandbox } from '../sandbox/edit-domain-dialog.sandbox';
import { DomainFormSubmitDataModel } from '../model/domain-form-submit-data.model';

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditDomainDialogData extends DialogData {

    domain: DomainResponseModel;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, domain?: DomainResponseModel) {
        super();
        this.domain = domain;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-domain-dialog',
    templateUrl: './edit-domain-dialog.component.html'
})
export class EditDomainDialogComponent implements OnInit, OnDestroy {

    domain$: Observable<DomainResponseModel>;
    domain: DomainResponseModel;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditDomainDialogData, private sandbox: EditDomainDialogSandbox) {
        this.domain = dialogData.domain;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
        if (this.domain) {
            this.editMode = true;
            this.sandbox.loadDomain(this.domain.domainId);
            this.domain$ = this.sandbox.domain$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: DomainFormSubmitDataModel) {
        if (this.editMode) {
            this.sandbox.updateDomain( data ).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createDomain( data.domainName ).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
