import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DialogData } from "../../../model/dialog.data";
import { Sandbox } from "../sandbox/sandbox";


// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class DateDialogData extends DialogData {

    header: string;
    message: string;
    yesLabel: string;
    noLabel: string;
    buttonType: string;
    placeholderText: string;
    isSingleDatePicker: boolean;


    callbackFn: (yesClicked: boolean, selected: string) => void;

    constructor(header: string, message: string, noLabel: string = 'No', yesLabel: string = 'Yes', buttonType: string = 'primary', placeholderText: string, isSingleDatePicker: boolean, callbackFn: (yesClicked: boolean, selected: string) => void) {
        super();
        this.header = header;
        this.message = message;
        this.noLabel = noLabel;
        this.yesLabel = yesLabel;
        this.buttonType = buttonType;
        this.placeholderText = placeholderText;
        this.isSingleDatePicker = isSingleDatePicker;
        this.callbackFn = callbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
// Component that represents it
// -------------------------------------------------------------------------------------------------
@Component({
    selector: 'app-date-dialog',
    templateUrl: './date-dialog.component.html'
})
export class DateDialogComponent {

    header: string;
    message: string;
    noLabel: string;
    yesLabel: string;
    buttonType: string;
    placeholderText: string;
    isSingleDatePicker: boolean;
    selected: any;

    // Function that gets called once yes or no button has been clicked
    callbackFn: (yesClicked: boolean, selected: any) => void;

    constructor(@Inject(DialogData) dialogData: DateDialogData, private sandbox: Sandbox) {

        this.header = dialogData.header;
        this.message = dialogData.message;
        this.noLabel = dialogData.noLabel;
        this.yesLabel = dialogData.yesLabel;
        this.buttonType = dialogData.buttonType;
        this.placeholderText = dialogData.placeholderText;
        this.isSingleDatePicker = dialogData.isSingleDatePicker;
        this.callbackFn = dialogData.callbackFn;
    }

    onNoClicked() {
        this.callbackFn(false, this.selected);
        this.sandbox.destroyDialog();
    }

    onYesClicked() {
        this.callbackFn(true, this.selected);
        this.sandbox.destroyDialog();
    }

    onCloseClicked() {
        this.callbackFn(false, this.selected);
        this.sandbox.destroyDialog();
    }

    valueChanged(date: any) {
        if (date != null && date.startDate === null && date.endDate === null) {
            this.selected = null;
            return;
        }
        this.selected = date;
    }
}
