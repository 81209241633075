import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditKioskDeviceInfoDialogSandbox} from "./sandbox/edit-kiosk-device-info-dialog.sandbox";
import {EditKioskDeviceInfoDialogComponent} from "./container/edit-kiosk-device-info-dialog.component";
import {StoreModule} from "@ngrx/store";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {EditKioskDeviceInfoDialogFormComponent} from './components/edit-kiosk-device-info-dialog-form/edit-kiosk-device-info-dialog-form.component';

@NgModule({

    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditKioskDeviceInfoDialogSandbox
    ],
    declarations: [
        EditKioskDeviceInfoDialogComponent,
        EditKioskDeviceInfoDialogFormComponent
    ],
    exports: [
        EditKioskDeviceInfoDialogComponent
    ],
    entryComponents: [
        EditKioskDeviceInfoDialogComponent
    ]
})
export class EditKioskDeviceInfoDialogModule {
}
