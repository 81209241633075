import { Action } from "@ngrx/store";
import { SaleGroupResponseModel } from "../../../../../../../../../core/modules/rest/sale-group/response/sale-group-response.model";

export const RESET_STATE_ACTION = '[Dialog - Create/Edit Sale Group :: MAIN] Reset State';
export const SET_SALE_GROUP_ACTION = '[Dialog - Create/Edit Sale Group :: MAIN] Set Sale Group';

export class ResetState implements Action {
    readonly  type = RESET_STATE_ACTION;
    constructor() {}
}

export class SetSaleGroup implements Action {
    readonly type = SET_SALE_GROUP_ACTION;
    constructor(public saleGroup: SaleGroupResponseModel) {}
}

export type All = ResetState | SetSaleGroup;
