import {CreateKioskConfigurationDropoffLocationProductTierRequestModel} from "./create-kiosk-configuration-dropoff-location-product-tier-request.model";

export class CreateKioskConfigurationDropoffLocationProductTiersRequestModel {
    kioskConfigurationDropoffLocationId: number;
    roundTrip: boolean;
    productTiers: CreateKioskConfigurationDropoffLocationProductTierRequestModel[];

    constructor(kioskConfigurationDropoffLocationId: number, roundTrip: boolean, productTiers: CreateKioskConfigurationDropoffLocationProductTierRequestModel[]) {
        this.kioskConfigurationDropoffLocationId = kioskConfigurationDropoffLocationId;
        this.roundTrip = roundTrip;
        this.productTiers = productTiers;
    }
}
