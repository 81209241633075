
import {combineLatest as observableCombineLatest, combineLatest, Observable, of} from 'rxjs';
import {OrderSummaryRestService} from "../modules/rest/order-summary/order-summary-rest.service";
import {UserInfoResponseModel} from "../modules/rest/user/response/user-info-response.model";
import {select, Store} from "@ngrx/store";
import * as appReducer from "../store/reducers";
import {flatMap, map, take} from "rxjs/operators";
import * as moment from "moment";
import {Injectable} from "@angular/core";
import {OrderPrintingInfoSummaryResponseModel} from "../modules/rest/order-summary/response/order-printing-info-summary-response.model";
import {SystemOptionsResponseModel} from "../modules/rest/system-options/response/system-options-response.model";
import {HttpClient} from "@angular/common/http";
import {EndOfShiftRestService} from "../modules/rest/end-of-shift/end-of-shift-rest.service";
import {ReportingRestService} from "../modules/rest/reporting/reporting-rest.service";
import {PassCardRestService} from "../modules/rest/pass-card/pass-card-rest.service";
import {OccurrenceRestService} from "../modules/rest/occurrence/occurrence-rest.service";

@Injectable()
export class AppPrintingService {

    currentUser$: Observable<UserInfoResponseModel> = this.appState.pipe(select(appReducer.userState_userInfo_selector));

    systemOptions$: Observable<SystemOptionsResponseModel> = this.appState.pipe(select(appReducer.systemOptionsState_systemOptions_selector));

    constructor(private appState: Store<appReducer.AppState>,
                private orderSummaryRestService: OrderSummaryRestService,
                private endOfShiftRestService: EndOfShiftRestService,
                private passCardRestService: PassCardRestService,
                private occurrenceRestService: OccurrenceRestService,
                private reportingRestService: ReportingRestService,
                private http: HttpClient) {
    }

    /**
     * Method that downloads or prints order depending on set System Options flags.
     * @param orderId Order for downloading / printing
     */
    downloadOrPrintOrder(orderId: number): Observable<OrderPrintingInfoSummaryResponseModel> {

        return observableCombineLatest([
            this.systemOptions$,
            this.orderSummaryRestService.generateUrlForPrintPdfOrder(orderId)
        ]).pipe(
            take(1),
            flatMap(([so, url]: [SystemOptionsResponseModel, string]) => {

                let printOrDownload$: Observable<any> = so.shouldPrintInsteadOfDownloadOnWebApp
                    ? this.http.get(url, {responseType: 'blob'})
                    : of(window.open(url));

                return combineLatest([this.currentUser$, of(so), printOrDownload$]);
            }),
            take(1),
            map(([user, so, blob]: [UserInfoResponseModel, SystemOptionsResponseModel, Blob]) => {

                if (so.shouldPrintInsteadOfDownloadOnWebApp) {
                    AppPrintingService.openPdfDialog(blob);
                }

                return {
                    printedByUserId: user.userId,
                    printedByDisplayName: user.displayName,
                    printingDateTimeFriendly: moment().format("MMM-DD-YYYY hh:mm A")
                };
            })
        );
    }

    /**
     * Method that downloads or prints cashout entry depending on set System Options flags.
     * @param cashCollectedId Cash collected for downloading / printing
     */
    downloadOrPrintCashoutEntry(cashCollectedId: number): Observable<boolean> {

        return observableCombineLatest([
            this.systemOptions$,
            this.endOfShiftRestService.generateUrlForPrintPdfCashoutEntry(cashCollectedId)
        ]).pipe(
            take(1),
            flatMap(([so, url]: [SystemOptionsResponseModel, string]) => {

                let printOrDownload$: Observable<any> = so.shouldPrintInsteadOfDownloadOnWebApp
                    ? this.http.get(url, {responseType: 'blob'})
                    : of(window.open(url));

                return combineLatest([of(so), printOrDownload$]);
            }),
            take(1),
            map(([so, blob]: [SystemOptionsResponseModel, Blob]) => {

                if (so.shouldPrintInsteadOfDownloadOnWebApp) {
                    AppPrintingService.openPdfDialog(blob);
                }

                return true;
            })
        );
    }

    /**
     * Method that downloads or prints pass card depending on set System Options flags.
     * @param passCardId Pass card for downloading / printing
     */
    downloadOrPrintPassCard(passCardId: number, shouldPrintReceipt: boolean): Observable<boolean> {

        return observableCombineLatest([
            this.systemOptions$,
            this.passCardRestService.generateUrlForPrintPdfPassCard({passCardId: passCardId, shouldPrintReceipt: shouldPrintReceipt})
        ]).pipe(
            take(1),
            flatMap(([so, url]: [SystemOptionsResponseModel, string]) => {

                let printOrDownload$: Observable<any> = so.shouldPrintInsteadOfDownloadOnWebApp
                    ? this.http.get(url, {responseType: 'blob'})
                    : of(window.open(url));

                return combineLatest([of(so), printOrDownload$]);
            }),
            take(1),
            map(([so, blob]: [SystemOptionsResponseModel, Blob]) => {

                if (so.shouldPrintInsteadOfDownloadOnWebApp) {
                    AppPrintingService.openPdfDialog(blob);
                }

                return true;
            })
        );
    }

    /**
     * Method that downloads or prints occurrence manifest depending on set System Options flags.
     * @param occurrenceIds Occurrences manifest for downloading / printing
     */
    downloadOrPrintOccurrenceManifest(occurrenceId: number): Observable<boolean> {

        return observableCombineLatest([
            this.systemOptions$,
            this.occurrenceRestService.generateUrlForPrintPdfOccurrenceManifest(occurrenceId)
        ]).pipe(
            take(1),
            flatMap(([so, url]: [SystemOptionsResponseModel, string]) => {

                let printOrDownload$: Observable<any> = so.shouldPrintInsteadOfDownloadOnWebApp
                    ? this.http.get(url, {responseType: 'blob'})
                    : of(window.open(url));

                return combineLatest([of(so), printOrDownload$]);
            }),
            take(1),
            map(([so, blob]: [SystemOptionsResponseModel, Blob]) => {

                if (so.shouldPrintInsteadOfDownloadOnWebApp) {
                    AppPrintingService.openPdfDialog(blob);
                }

                return true;
            })
        );
    }

    /**
     * Calls window.print on current document
     */
    printCurrentWindow() {
        window.print();
    }

    // Creates fake iframe and puts pdf into it, after which it calls print() which opens print dialog with pdf
    public static openPdfDialog(blob: any): void {

        const _blob: Blob = new Blob([blob], {type: 'application/pdf'});
        const blobUrl: string = URL.createObjectURL(_blob);

        const iframe = document.createElement('iframe');
        iframe.addEventListener("load", function() {
            iframe.contentWindow.print();
        });
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);

    }
}
