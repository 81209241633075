import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {Observable} from "rxjs";
import {NotificationResponseModel} from "../../../../../../../../core/modules/rest/notification/response/notification-response.model";
import {NotificationFormSubmitDataModel} from "../model/notification-form-submit-data.model";
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {EditNotificationDialogSandbox} from "../sandbox/edit-notification-dialog.sandbox";
import { NotificationBaseUserResponseModel } from '../../../../../../../../core/modules/rest/notification/response/notification-base-user-response.model';

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditNotificationDialogData extends DialogData {

    notificationId: number;
    notificationBaseUser: NotificationBaseUserResponseModel[];
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, notificationId?: number, notificationBaseUser?: NotificationBaseUserResponseModel[]) {
        super();
        this.notificationId = notificationId;
        this.notificationBaseUser = notificationBaseUser;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
// Component that represents it
// -------------------------------------------------------------------------------------------------

@Component({
  selector: 'app-edit-notification-dialog',
  templateUrl: './edit-notification-dialog.component.html'
})
export class EditNotificationDialogComponent implements OnInit, OnDestroy {

    notificationId: number;
    notification$: Observable<NotificationResponseModel>;
    notificationBaseUser$: Observable<NotificationBaseUserResponseModel[]>;
    notificationBaseUser: NotificationBaseUserResponseModel[];
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditNotificationDialogData, private sandbox: EditNotificationDialogSandbox) {
        this.notificationId = dialogData.notificationId;
        this.notificationBaseUser = dialogData.notificationBaseUser;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
        if (this.notificationId && !isNaN(this.notificationId)) {
            this.editMode = true;
            this.sandbox.loadNotification(this.notificationId);
            this.sandbox.loadNotificationBaseUsers(this.notificationId)
            this.notification$ = this.sandbox.notification$;
            this.notificationBaseUser$ = this.sandbox.notificationBaseUser$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(notificationFormData: NotificationFormSubmitDataModel) {

        if (this.editMode) {
            this.sandbox.updateNotification(this.notificationId, notificationFormData).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createNotification(notificationFormData).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
