import { PostBillTypeAdditionalActionEnum } from "../../../../../../../../shared/enums/post-bill-type-additional-action.enum";

export class PostBillTypeFormSubmitData {

    description: string;
    details: string;
    useDeposit: boolean
    additionalAction: PostBillTypeAdditionalActionEnum;

    constructor(description: string, details: string, useDeposit: boolean, additionalAction: PostBillTypeAdditionalActionEnum) {
        this.description = description;
        this.details = details;
        this.additionalAction = additionalAction;
        this.useDeposit = useDeposit;
    }
}
