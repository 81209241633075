import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {AmountTypeEnum} from "../../../../../../../../shared/enums/amount-type.enum";
import {AdditionalCostResponseModel} from "../../../../../../../../core/modules/rest/cost/response/additional-cost-response.model";
import {SortByModel} from "../../../../../../../../shared/model/sort-by.model";

const SORT_ASC = 'ASC';
const SORT_DESC = 'DESC';

@Component({
    selector: 'app-additional-costs-table',
    templateUrl: './additional-costs-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalCostsTableComponent implements OnInit, OnChanges {

    @Input() additionalCosts: AdditionalCostResponseModel[];

    @Output() mappingAdditionalCostEvent: EventEmitter<[number, string]> = new EventEmitter();
    @Output() editAdditionalCostEvent: EventEmitter<number> = new EventEmitter();
    @Output() editAdditionalCostActivityEvent: EventEmitter<AdditionalCostResponseModel> = new EventEmitter();

    currentSearchDataSortBy: SortByModel;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.additionalCosts) {
            if (this.currentSearchDataSortBy) {

                // Invert order direction, because it will be inverted again in sort method
                this.currentSearchDataSortBy.order = this.currentSearchDataSortBy.order === SORT_ASC ? SORT_DESC : SORT_ASC;
                this.sortByHeaderClick(this.currentSearchDataSortBy.fieldName);
            }
        }
    }

    mappingAdditionalCostClick(additionalCost: AdditionalCostResponseModel) {
        this.mappingAdditionalCostEvent.emit([additionalCost.costId, additionalCost.description]);
    }

    editAdditionalCostClick(additionalCost: AdditionalCostResponseModel) {
        this.editAdditionalCostEvent.emit(additionalCost.costId);
    }

    onAdditionalCostActivityChanged(additionalCost: AdditionalCostResponseModel) {
        this.editAdditionalCostActivityEvent.emit(additionalCost);
    }

    getAmountPrintValue(additionalCost: AdditionalCostResponseModel): string {

        let amountPrintValue: string = "";

        if (additionalCost.amountType === AmountTypeEnum.DOLLARS) {
            amountPrintValue = '$' + additionalCost.amount;
        } else if (additionalCost.amountType === AmountTypeEnum.PERCENT) {
            amountPrintValue = additionalCost.amount + '%';
        }

        return amountPrintValue;
    }

    getDateRangePrintValue(additionalCost: AdditionalCostResponseModel): string {
        if (additionalCost.startDateFriendly && additionalCost.endDateFriendly) {
            return additionalCost.startDateFriendly + ' - ' + additionalCost.endDateFriendly;
        } else if (additionalCost.endDateFriendly && !additionalCost.startDateFriendly) {
            return 'Before ' + additionalCost.endDateFriendly;
        } else if (additionalCost.startDateFriendly && !additionalCost.endDateFriendly) {
            return 'After ' + additionalCost.startDateFriendly;
        } else {
            return 'Any Date';
        }
    }

    getIsOrderLevelPrintValue(additionalCost: AdditionalCostResponseModel): string {
        return (additionalCost.isOrderLevel ? 'Yes' : 'No');
    }

    sortByHeaderClick(header: string) {

        if (!this.currentSearchDataSortBy) {
            this.currentSearchDataSortBy = new SortByModel(header, SORT_ASC);
        } else if (this.currentSearchDataSortBy.fieldName !== header) {
            this.currentSearchDataSortBy = new SortByModel(header, SORT_ASC);
        } else {
            if (this.currentSearchDataSortBy.order === SORT_ASC) {
                this.currentSearchDataSortBy = new SortByModel(header, SORT_DESC);
            } else {
                this.currentSearchDataSortBy = new SortByModel(header, SORT_ASC);
            }
        }

        switch (this.currentSearchDataSortBy.fieldName) {
            case  "Description":
                this.currentSearchDataSortBy.order === SORT_ASC ? this.additionalCosts.sort((a: AdditionalCostResponseModel, b: AdditionalCostResponseModel) => (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : -1) : this.additionalCosts.sort((a: AdditionalCostResponseModel, b: AdditionalCostResponseModel) => (a.description.toLowerCase() > b.description.toLowerCase()) ? -1 : 1);
                break;
            case  "Amount":
                this.currentSearchDataSortBy.order === SORT_ASC ? this.additionalCosts.sort((a: AdditionalCostResponseModel, b: AdditionalCostResponseModel) => (a.amount > b.amount) ? 1 : -1) : this.additionalCosts.sort((a: AdditionalCostResponseModel, b: AdditionalCostResponseModel) => (a.amount > b.amount) ? -1 : 1);
                break;
            case  "Is Order Level":
                this.currentSearchDataSortBy.order === SORT_ASC ? this.additionalCosts.sort((a: AdditionalCostResponseModel, b: AdditionalCostResponseModel) => (this.getIsOrderLevelPrintValue(a) > this.getIsOrderLevelPrintValue(b)) ? 1 : -1) : this.additionalCosts.sort((a: AdditionalCostResponseModel, b: AdditionalCostResponseModel) => (this.getIsOrderLevelPrintValue(a) > this.getIsOrderLevelPrintValue(b)) ? -1 : 1);
                break;
            case  "GL Code":
                this.currentSearchDataSortBy.order === SORT_ASC ? this.additionalCosts.sort((a: AdditionalCostResponseModel, b: AdditionalCostResponseModel) => ((a.glCode || b.glCode) ? !a.glCode ? -1 : !b.glCode ? 1 : a.glCode.toLowerCase() > b.glCode.toLowerCase() ? 1 : -1 : 0)) : this.additionalCosts.sort((a: AdditionalCostResponseModel, b: AdditionalCostResponseModel) => ((a.glCode || b.glCode) ? !a.glCode ? 1 : !b.glCode ? -1 : a.glCode.toLowerCase() > b.glCode.toLowerCase() ? -1 : 1 : 0));
                break;
            case  "Date Range":
                this.currentSearchDataSortBy.order === SORT_ASC ? this.additionalCosts.sort((a: AdditionalCostResponseModel, b: AdditionalCostResponseModel) => {
                    if (a.startDateInt === null && b.startDateInt !== null) {
                        return -1;
                    } else if (a.startDateInt !== null && b.startDateInt === null) {
                        return 1;
                    } else if (a.startDateInt !== null && b.startDateInt !== null && a.startDateInt !== b.startDateInt) {
                        return a.startDateInt < b.startDateInt ? -1 : 1;
                    } else {
                        if (a.endDateInt === null && b.endDateInt !== null) {
                            return -1;
                        } else if (a.endDateInt !== null && b.endDateInt === null) {
                            return 1;
                        } else if (a.endDateInt !== null && b.endDateInt !== null && a.endDateInt !== b.endDateInt) {
                            return a.endDateInt < b.endDateInt ? -1 : 1;
                        } else {
                            return 0;
                        }
                    }
                }) : this.additionalCosts.sort((a: AdditionalCostResponseModel, b: AdditionalCostResponseModel) => {
                    if (a.startDateInt === null && b.startDateInt !== null) {
                        return 1;
                    } else if (a.startDateInt !== null && b.startDateInt === null) {
                        return -1;
                    } else if (a.startDateInt !== null && b.startDateInt !== null && a.startDateInt !== b.startDateInt) {
                        return a.startDateInt < b.startDateInt ? 1 : -1;
                    } else {
                        if (a.endDateInt === null && b.endDateInt !== null) {
                            return 1;
                        } else if (a.endDateInt !== null && b.endDateInt === null) {
                            return -1;
                        } else if (a.endDateInt !== null && b.endDateInt !== null && a.endDateInt !== b.endDateInt) {
                            return a.endDateInt < b.endDateInt ? 1 : -1;
                        } else {
                            return 0;
                        }
                    }
                });
                break;
            case  "Location":
                this.currentSearchDataSortBy.order === SORT_ASC ? this.additionalCosts.sort((a: AdditionalCostResponseModel, b: AdditionalCostResponseModel) => (a.locationDescription > b.locationDescription) ? 1 : -1) : this.additionalCosts.sort((a: AdditionalCostResponseModel, b: AdditionalCostResponseModel) => (a.locationDescription > b.locationDescription) ? -1 : 1);
                break;
            case  "Platform":
                this.currentSearchDataSortBy.order === SORT_ASC ? this.additionalCosts.sort((a: AdditionalCostResponseModel, b: AdditionalCostResponseModel) => (a.platformDescription > b.platformDescription) ? 1 : -1) : this.additionalCosts.sort((a: AdditionalCostResponseModel, b: AdditionalCostResponseModel) => (a.platformDescription > b.platformDescription) ? -1 : 1);
                break;
        }
    }
}
