import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedSandbox } from '../../shared.sandbox';
import { NotificationAlertModel, NotificationAlertTypeEnum } from '../notification-alert.model';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-notification-alert',
    templateUrl: './notification-alert.component.html'
})
export class NotificationAlertComponent implements OnInit, OnDestroy {

    notification: NotificationAlertModel = new NotificationAlertModel(NotificationAlertTypeEnum.INFO, 'Notification Alert Example');
    notificationsSubscription: Subscription;

    iconClass: string = "";

    constructor(private sharedSandbox: SharedSandbox) {
    }

    ngOnInit() {
        this.notificationsSubscription = this.sharedSandbox.getNotifications().subscribe(this.notificationsLoaded.bind(this));
    }

    ngOnDestroy() {
        if (this.notificationsSubscription) {
            this.notificationsSubscription.unsubscribe();
        }
    }

    notificationsLoaded(notifications: NotificationAlertModel[]) {

        if (notifications.length !== 0) {

            this.notification = notifications[0];

            switch (this.notification.type) {
                case NotificationAlertTypeEnum.ERROR:
                    this.iconClass = "far fa-times-circle";
                    break;
                case NotificationAlertTypeEnum.INFO:
                    this.iconClass = "far fa-info-circle";
                    break;
                case NotificationAlertTypeEnum.SUCCESS:
                    this.iconClass = "far fa-check-circle";
                    break;
                default:
                    console.error("Unknown Notification Type!");
            }

            setTimeout(() => {
                this.sharedSandbox.disposeFirstNotification();
            }, 10000);

        } else {
            this.notification = null;
        }
    }

    disposeNotification(e) {

        this.stopPropagation(e);
        this.sharedSandbox.disposeFirstNotification();
    }

    stopPropagation(e) {
        e.stopPropagation();
    }
}
