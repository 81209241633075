import {NgModule} from "@angular/core";
import {CloneProductDialogComponent} from './clone-product-dialog.component';
import {CloneProductDialogSandbox} from "./sandbox/clone-product-dialog.sandbox";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {CloneProductDialogFormComponent} from './components/clone-product-dialog-form/clone-product-dialog-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    declarations: [
        CloneProductDialogComponent,
        CloneProductDialogFormComponent
    ],
    providers: [
        CloneProductDialogSandbox
    ],
    exports: [
        CloneProductDialogComponent
    ],
    entryComponents: [
        CloneProductDialogComponent
    ]
})
export class CloneProductDialogModule {
}
