import * as actions from './actions';
import { DomainResponseModel } from '../../../../../../../core/modules/rest/domain/response/domain-response.model';

export interface State {
    domains: DomainResponseModel[];
    showInactiveDomains: boolean;
}

const initialState: State = {
    domains: [],
    showInactiveDomains: false
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.UPDATE_DOMAINS_ACTION:
            return handleUpdateDomainsAction(state, action);
        case actions.UPDATE_SHOW_INACTIVE_DOMAINS_ACTION:
            return handleUpdateShowInactiveDomainsAction(state, action);
        default:
            return state;
    }
}


// #################################################### SELECTORS ####################################################
export const domains_selector = (state: State) => state.domains;
export const showInactiveDomains_selector = (state: State) => state.showInactiveDomains;


// #################################################### HANDLERS ####################################################
function handleUpdateDomainsAction(state: State, action: actions.UpdateDomainManagment) {
    return {
        ...state,
        domains: action.domains
    };
}

function handleUpdateShowInactiveDomainsAction(state: State, action: actions.UpdateShowInactiveDomain) {
    return {
        ...state,
        showInactiveDomains: action.show
    };
}
