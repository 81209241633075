import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditLocationListDialogComponent} from "./container/edit-location-list-dialog.component";
import {EditLocationListDialogSandbox} from "./sandbox/edit-location-list-dialog.sandbox";
import {StoreModule} from "@ngrx/store";
import {REDUCER_NAME, reducer} from './sandbox/store/reducer';
import {EditLocationListFormComponent} from './components/edit-location-list-form/edit-location-list-form.component';
import {EditLocationListItemFormComponent} from "./components/edit-location-list-form/edit-location-list-item-form/edit-location-list-item-form.component";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditLocationListDialogSandbox
    ],
    exports: [
        EditLocationListDialogComponent
    ],
    declarations: [
        EditLocationListFormComponent,
        EditLocationListItemFormComponent,
        EditLocationListDialogComponent
    ],
    entryComponents: [
        EditLocationListDialogComponent
    ]
})
export class EditLocationListDialogModule {

}
