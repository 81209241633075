import { SelectionState } from "../../../../../../../../shared/components/form-elements/multiselect-dropdown-batch/multiselect-dropdown-batch.component";

export class SaleGroupCustomerFormSubmitDataModel {
    saleGroupName: string;
    saleGroupId: number;
    customerIds: number[];
    mode: SelectionState;

    constructor( saleGroupName: string, customerIds: number[], mode: SelectionState  ) {
        this.saleGroupName = saleGroupName;
        this.customerIds = customerIds;
        this.mode = mode;
    }
}
