import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BusResponseModel} from "../../../../../../../../../core/modules/rest/bus/response/bus-response.model";
import {BusFormSubmitDataModel} from "../../model/bus-form-submit-data.model";


@Component({
  selector: 'app-edit-bus-form',
  templateUrl: './edit-bus-form.component.html'
})
export class EditBusFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() bus: BusResponseModel;

    @Output() submitEvent = new EventEmitter<BusFormSubmitDataModel>();
    @Output() closeEvent  = new EventEmitter<void>();

    form: FormGroup;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.editMode && changes.bus !== null && Object.keys(changes.bus.currentValue).length !== 0) {
            this.form.patchValue({
                number: changes.bus.currentValue.number,
                description: changes.bus.currentValue.description,
                capacity: changes.bus.currentValue.capacity
            });
        }
    }

    ngOnInit() {

        let number: number = null;
        let description: string = '';
        let capacity: number = null;

        if (this.editMode) {
            number = this.bus.number;
            description = this.bus.description;
            capacity = this.bus.capacity;
        }

        this.form = new FormGroup({
            number: new FormControl(number, Validators.required),
            description: new FormControl(description),
            capacity: new FormControl(capacity, Validators.pattern('^[0-9]*$')),
        });
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        const number = this.form.value.number;
        const description = this.form.value.description;
        const capacity = this.form.value.capacity;

        const data: BusFormSubmitDataModel = new BusFormSubmitDataModel(number, description, capacity);

        this.submitEvent.emit(data);
    }
}
