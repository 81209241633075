import {Injectable} from '@angular/core';
import {LoginRequestModel} from './login-request.model';
import {Observable} from 'rxjs';
import {LoginResponseModel} from './login-response.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {LoginStepOneResponseModel} from "./login-step-one-response.model";
import {CreateResetPasswordRequestRequestModel} from "./create-reset-password-request-request.model";
import {ResetPasswordRequestModel} from "./reset-password-request.model";
import {ValidateResetPasswordTokenRequestModel} from "./validate-reset-password-token-request.model";
import {ValidateActivateMfaTokenRequestModel} from "./validate-activate-mfa-token-request.model";
import {ActivateMfaRequestModel} from "./activate-mfa-request.model";
import {LoginStepTwoResponseModel} from "./login-step-two-response.model";
import {GetUseComplexPasswordFlagRequestModel} from "./get-use-complex-password-flag-request.model";

@Injectable()
export class AuthRestService {

    constructor(private httpClient: HttpClient) {
    }

    loginStepOne(data: LoginRequestModel) {
        return this.httpClient.post<LoginStepOneResponseModel>(environment.server_api_url + '/api/public/auth/loginStepOne', data);
    }

    loginStepTwo(data: LoginRequestModel) {
        return this.httpClient.post<LoginStepTwoResponseModel>(environment.server_api_url + '/api/public/auth/loginStepTwo', data);
    }

    login(data: LoginRequestModel): Observable<LoginResponseModel> {
        return this.httpClient.post<LoginResponseModel>(environment.server_api_url + '/api/public/auth/login', data);
    }

    isUserLoggedInUrl() {
        return environment.server_api_url + '/api/secured/auth/isUserLoggedIn';
    }

    isUserLoggedIn(): Observable<boolean> {
        return this.httpClient.get<boolean>(this.isUserLoggedInUrl());
    }

    refreshToken(): Observable<LoginResponseModel> {
        return this.httpClient.get<LoginResponseModel>(environment.server_api_url + '/api/public/auth/refreshToken');
    }

    logout(): Observable<any> {
        return this.httpClient.get<any>(environment.server_api_url + '/api/public/auth/logout');
    }

    createResetPasswordRequest(data: CreateResetPasswordRequestRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/public/auth/createResetPasswordRequest', data, {responseType: 'text'});
    }

    validateResetPasswordToken(data: ValidateResetPasswordTokenRequestModel): Observable<boolean> {
        return this.httpClient.post<boolean>(environment.server_api_url + '/api/public/auth/validateResetPasswordToken', data);
    }

    getUseComplexPasswordFlag(data: GetUseComplexPasswordFlagRequestModel): Observable<boolean> {
        return this.httpClient.post<boolean>(environment.server_api_url + '/api/public/auth/getUseComplexPasswordFlag', data);
    }

    resetPassword(data: ResetPasswordRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/public/auth/resetPassword', data, {responseType: 'text'});
    }

    validateActivateMfaTokenAndGetOtpAuthUrl(data: ValidateActivateMfaTokenRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/public/auth/validateActivateMfaTokenAndGetOtpAuthUrl', data, {responseType: 'text'});
    }

    activateMfa(data: ActivateMfaRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/public/auth/activateMfa', data, {responseType: 'text'});
    }
}
