import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {NotificationResponseModel} from "../../../../../../../../../core/modules/rest/notification/response/notification-response.model";
import { NotificationBaseUserResponseModel } from '../../../../../../../../../core/modules/rest/notification/response/notification-base-user-response.model';

export const REDUCER_NAME = 'dialog_create/edit-notification';

export interface State {
    notification: NotificationResponseModel;
    notificationBaseUser: NotificationBaseUserResponseModel[];
}

const initialState: State = {
    notification: {} as NotificationResponseModel,
    notificationBaseUser: {} as NotificationBaseUserResponseModel[]
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const notification_selector = createSelector(state_selector, (state: State) => state.notification);

export const notification_base_user_selector = createSelector(state_selector, (state: State) => state.notificationBaseUser);
// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_NOTIFICATION_ACTION:  return { ... state, notification: action.notification };

        case actions.SET_NOTIFICATION_BASE_USER_ACTION:  return { ... state, notificationBaseUser: action.notificationBaseUser };

        default: return state;
    }
}
