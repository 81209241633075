import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {AddPassDialogSandbox} from "./sandbox/add-pass-dialog.sandbox";
import {SubmitPassData} from "./components/add-pass-dialog-form/add-pass-dialog-form.component";
import {CreateBaseResponseModel} from "../../../../../../../core/modules/rest/create-base-response.model";

export class AddPassDialogData extends DialogData {

    successCallbackFn: Function;

    constructor(successCallbackFn: Function) {
        super();
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-add-pass-dialog',
    templateUrl: './add-pass-dialog.component.html'
})
export class AddPassDialogComponent implements OnDestroy {

    successCallbackFn: Function;

    constructor(@Inject(DialogData) data: AddPassDialogData, private sandbox: AddPassDialogSandbox) {

        this.successCallbackFn = data.successCallbackFn;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitPassData) {
        this.sandbox.createPass(data).subscribe((response: CreateBaseResponseModel) => {
            this.successCallbackFn(response.id);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
