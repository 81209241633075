import { UUID } from "angular2-uuid";

export class OrderCostItemDataModel {

    readonly guid: string;

    costDescription: string;
    amount: number;
    discountCode: string;

    constructor() {
        this.guid = UUID.UUID();
    }

    isEqualTo(other: OrderCostItemDataModel): boolean {
        return this.guid === other.guid;
    }
}
