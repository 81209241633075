import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {EmailHtmlTemplateResponseModel} from './response/email-html-template-response.model';
import {CreateBaseResponseModel} from "../create-base-response.model";
import {CreateEmailHtmlTemplateRequestModel} from './request/create-email-html-template-request.model';
import {UpdateEmailHtmlTemplateRequestModel} from './request/update-email-html-template-request.model';
import { EmailHtmlTemplateUsedInResponseModel } from './response/email-html-template-used-in-response.model';

@Injectable()
export class EmailHtmlTemplateRestService {

    constructor(private httpClient: HttpClient) {
    }

    getById(emailHtmlTemplateId: number): Observable<EmailHtmlTemplateResponseModel> {

        return this.httpClient.get<EmailHtmlTemplateResponseModel>(environment.server_api_url + `/api/secured/emailHtmlTemplate/getById/${emailHtmlTemplateId}`);
    }

    getAllTermsAndConditionsEmailHtmlTemplates(): Observable<EmailHtmlTemplateResponseModel[]> {
        return this.httpClient.get<EmailHtmlTemplateResponseModel[]>(environment.server_api_url + '/api/secured/emailHtmlTemplate/getAllTermsAndConditionsEmailHtmlTemplates');
    }

    getAllNotificationsEmailHtmlTemplates(): Observable<EmailHtmlTemplateResponseModel[]> {
        return this.httpClient.get<EmailHtmlTemplateResponseModel[]>(environment.server_api_url + '/api/secured/emailHtmlTemplate/getAllNotificationsEmailHtmlTemplates');
    }

    getAll(): Observable<EmailHtmlTemplateResponseModel[]> {
        return this.httpClient.get<EmailHtmlTemplateResponseModel[]>(environment.server_api_url + '/api/secured/emailHtmlTemplate/getAll');
    }

    getAllReferencesByEmailTemplate(emailTemplate: EmailHtmlTemplateResponseModel): Observable<EmailHtmlTemplateUsedInResponseModel> {
        return this.httpClient.post<EmailHtmlTemplateUsedInResponseModel>(environment.server_api_url + '/api/secured/emailHtmlTemplate/getAllReferencesByEmailTemplate', emailTemplate);
    }

    getAllActive(): Observable<EmailHtmlTemplateResponseModel[]> {
        return this.httpClient.get<EmailHtmlTemplateResponseModel[]>(environment.server_api_url + '/api/secured/emailHtmlTemplate/getAllActive');
    }

    create(request: CreateEmailHtmlTemplateRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/emailHtmlTemplate/create', request);
    }

    update(request: UpdateEmailHtmlTemplateRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/emailHtmlTemplate/update', request, {responseType: 'text'});
    }

    activate(emailHtmlTemplateId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/emailHtmlTemplate/activate', emailHtmlTemplateId, {responseType: 'text'});
    }

    deactivate(emailHtmlTemplateId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/emailHtmlTemplate/deactivate', emailHtmlTemplateId, {responseType: 'text'});
    }
}
