import * as actions from './actions';

import {ProductForRouteSetupResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-for-route-setup-response.model";
import {KioskConfigurationDropoffLocationProductModel} from "../../model/kiosk-configuration-dropoff-location-product.model";
import {KioskConfigurationWarningModel} from "../../../../model/kiosk-configuration-warning.model";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {LocationListItemDescriptorModel} from "../../model/location-list-item-descriptor.model";

export const REDUCER_NAME = "dialog_route-setup";

export interface State {
    allProducts: ProductForRouteSetupResponseModel[];
    kioskConfigurationDropoffLocationProducts: KioskConfigurationDropoffLocationProductModel[];
    warnings: KioskConfigurationWarningModel[];
}

const initialState: State = {
    allProducts: [],
    kioskConfigurationDropoffLocationProducts: [],
    warnings: []
};

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const allProducts_selector                               = createSelector(state_selector, (state: State) => state.allProducts);
export const kioskConfigurationDropoffLocationProducts_selector = createSelector(state_selector, (state: State) => state.kioskConfigurationDropoffLocationProducts);
export const warnings_selector                                  = createSelector(state_selector, (state: State) => state.warnings);

export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_ALL_PRODUCTS: return { ... state, allProducts: action.allProducts };

        case actions.SET_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCTS: return { ... state, kioskConfigurationDropoffLocationProducts: action.kioskConfigurationDropoffLocationProducts };

        case actions.SET_WARNINGS: return { ... state, warnings: action.warnings };

        case actions.UPDATE_PRODUCT_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT: return updateProductForKioskConfigurationDropoffLocationProduct(state, action);

        case actions.UPDATE_DIRECTION_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT: return updateDirectionForKioskConfigurationDropoffLocationProduct(state, action);

        case actions.UPDATE_PICKUP_LOCATIONS_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT: return updatePickupLocationsForKioskConfigurationDropoffLocationProduct(state, action);

        case actions.UPDATE_DROPOFF_LOCATIONS_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT: return updateDropoffLocationsForKioskConfigurationDropoffLocationProduct(state, action);

        case actions.UPDATE_SELECTED_DROPOFF_LOCATION_FOR_KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT: return updateSelectedDropoffLocationForKioskConfigurationDropoffLocationProduct(state, action);

        case actions.KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT_ADDED: return kioskConfigurationDropoffLocationProductAdded(state);

        case actions.KIOSK_CONFIGURATION_DROPOFF_LOCATION_PRODUCT_DELETED: return kioskConfigurationDropoffLocationDeleted(state, action);

        default: return state;
    }
}

function updateProductForKioskConfigurationDropoffLocationProduct(state: State, action: actions.UpdateProductForKioskConfigurationDropoffLocationProduct): State {

    let index: number = action.index;
    let product: ProductForRouteSetupResponseModel = action.product;

    let kioskConfigurationDropoffLocationProduct: KioskConfigurationDropoffLocationProductModel = { ... state.kioskConfigurationDropoffLocationProducts[index] };


    if (product === undefined) {

        return {
            ... state,
            kioskConfigurationDropoffLocationProducts: [
                ... state.kioskConfigurationDropoffLocationProducts.slice(0, index),
                new KioskConfigurationDropoffLocationProductModel(
                    null,
                    null,
                    null,
                    null,
                    null,
                    [],
                    []
                ),
                ... state.kioskConfigurationDropoffLocationProducts.slice(index + 1)
            ]
        };
    }

    kioskConfigurationDropoffLocationProduct.productId = product.productId;
    kioskConfigurationDropoffLocationProduct.isRoundTripProduct = product.isRoundTripProduct;
    kioskConfigurationDropoffLocationProduct.isReturnTrip = false;

    kioskConfigurationDropoffLocationProduct.pickupLocationId = null;
    kioskConfigurationDropoffLocationProduct.dropoffLocationId = null;

    kioskConfigurationDropoffLocationProduct.pickupLocations = [];
    kioskConfigurationDropoffLocationProduct.dropoffLocations = [];

    return {
        ... state,
        kioskConfigurationDropoffLocationProducts: [
            ... state.kioskConfigurationDropoffLocationProducts.slice(0, index),
            kioskConfigurationDropoffLocationProduct,
            ... state.kioskConfigurationDropoffLocationProducts.slice(index + 1)
        ]
    };
}

function updateDirectionForKioskConfigurationDropoffLocationProduct(state: State, action: actions.UpdateDirectionForKioskConfigurationDropoffLocationProduct): State {

    let index: number = action.index;
    let isReturnTrip: boolean = action.isReturnTrip;

    let kioskConfigurationDropoffLocationProduct: KioskConfigurationDropoffLocationProductModel = { ... state.kioskConfigurationDropoffLocationProducts[index] };

    kioskConfigurationDropoffLocationProduct.pickupLocationId = null;
    kioskConfigurationDropoffLocationProduct.dropoffLocationId = null;
    kioskConfigurationDropoffLocationProduct.pickupLocations = [];
    kioskConfigurationDropoffLocationProduct.dropoffLocations = [];

    kioskConfigurationDropoffLocationProduct.isReturnTrip = isReturnTrip;

    if (isReturnTrip == null) {
        kioskConfigurationDropoffLocationProduct.loadingPickupLocations = false;
        kioskConfigurationDropoffLocationProduct.loadingDropoffLocations = false;
    }

    return {
        ... state,
        kioskConfigurationDropoffLocationProducts: [
            ... state.kioskConfigurationDropoffLocationProducts.slice(0, index),
            kioskConfigurationDropoffLocationProduct,
            ... state.kioskConfigurationDropoffLocationProducts.slice(index + 1)
        ]
    };
}

function updatePickupLocationsForKioskConfigurationDropoffLocationProduct(state: State, action: actions.UpdatePickupLocationsForKioskConfigurationDropoffLocationProduct): State {

    let index = action.index;
    let locations = action.locations;

    let kioskConfigurationDropoffLocationProduct: KioskConfigurationDropoffLocationProductModel = { ... state.kioskConfigurationDropoffLocationProducts[index] };

    kioskConfigurationDropoffLocationProduct.pickupLocations = locations;
    kioskConfigurationDropoffLocationProduct.dropoffLocationId = null;
    kioskConfigurationDropoffLocationProduct.dropoffLocations = [];

    kioskConfigurationDropoffLocationProduct.loadingPickupLocations = false;
    kioskConfigurationDropoffLocationProduct.loadingDropoffLocations = false;

    return {
        ... state,
        kioskConfigurationDropoffLocationProducts: [
            ... state.kioskConfigurationDropoffLocationProducts.slice(0, index),
            kioskConfigurationDropoffLocationProduct,
            ... state.kioskConfigurationDropoffLocationProducts.slice(index + 1)
        ]
    };
}

function updateDropoffLocationsForKioskConfigurationDropoffLocationProduct(state: State, action: actions.UpdateDropoffLocationsForKioskConfigurationDropoffLocationProduct): State {

    let index: number = action.index;
    let locations: LocationListItemDescriptorModel[] = action.locations;
    let pickupLocationId: number = action.pickupLocationId;

    let kioskConfigurationDropoffLocationProduct: KioskConfigurationDropoffLocationProductModel = { ... state.kioskConfigurationDropoffLocationProducts[index] };

    kioskConfigurationDropoffLocationProduct.pickupLocationId  = pickupLocationId;
    kioskConfigurationDropoffLocationProduct.dropoffLocationId = locations.filter((l: LocationListItemDescriptorModel) => l.locationId === kioskConfigurationDropoffLocationProduct.dropoffLocationId).length === 0 ? null : kioskConfigurationDropoffLocationProduct.dropoffLocationId;
    kioskConfigurationDropoffLocationProduct.dropoffLocations  = locations;

    kioskConfigurationDropoffLocationProduct.loadingPickupLocations = false;
    kioskConfigurationDropoffLocationProduct.loadingDropoffLocations = false;

    return {
        ... state,
        kioskConfigurationDropoffLocationProducts: [
            ... state.kioskConfigurationDropoffLocationProducts.slice(0, index),
            kioskConfigurationDropoffLocationProduct,
            ... state.kioskConfigurationDropoffLocationProducts.slice(index + 1)
        ]
    };
}

function updateSelectedDropoffLocationForKioskConfigurationDropoffLocationProduct(state: State, action: actions.UpdateSelectedDropoffLocationForKioskConfigurationDropoffLocationProduct): State {

    let index = action.index;
    let dropoffLocationId = action.dropoffLocationId;

    let kioskConfigurationDropoffLocationProduct: KioskConfigurationDropoffLocationProductModel = { ... state.kioskConfigurationDropoffLocationProducts[index] };

    kioskConfigurationDropoffLocationProduct.dropoffLocationId = dropoffLocationId;

    return {
        ... state,
        kioskConfigurationDropoffLocationProducts: [
            ... state.kioskConfigurationDropoffLocationProducts.slice(0, index),
            kioskConfigurationDropoffLocationProduct,
            ... state.kioskConfigurationDropoffLocationProducts.slice(index + 1)
        ]
    };
}

function kioskConfigurationDropoffLocationProductAdded(state: State): State {

    return {
        ... state,
        kioskConfigurationDropoffLocationProducts: [
            ... state.kioskConfigurationDropoffLocationProducts,
            new KioskConfigurationDropoffLocationProductModel(
                null,
                null,
                null,
                null,
                null,
                [],
                []
            )
        ]
    };
}

function kioskConfigurationDropoffLocationDeleted(state: State, action: actions.KioskConfigurationDropoffLocationProductDeleted): State {

    let index: number = action.index;

    return {
        ... state,
        kioskConfigurationDropoffLocationProducts: [
            ... state.kioskConfigurationDropoffLocationProducts.slice(0, index),
            ... state.kioskConfigurationDropoffLocationProducts.slice(index + 1)
        ]
    };
}
