
<!-- Default behaviour : display values in the <span> / <paragraph> 
  On edit : pass values to the input and allow changes -->

<div class="dynamicInputIconGroup edit-input"
    [ngClass]='{"edit-input": enableEditing}'>
            
    <div class="dynamicInput_container">
        <app-input-label-group>
            <label>{{labelName}}</label>

            <input  type="text" 
                    name="email"
                    class="main--element no-borders-input"
                    placeholder="{{labelName}}"
                    value= {{fieldValue}}>
        
                <span class="message">
                    {{errorMessage}}
                </span>
        </app-input-label-group>
    </div>



    <!-- Icons visible only if input is active -->
    <div class="dynamicIcon_wrapper">
        <a class="icon_container default-icon"
            (click)='editDynamicInputField()'>
            <i class="fal fa-pen"></i>
        </a>

        
        <div class="input-active">
            <a class="icon_container check-icon">
                <i class="fal fa-check-circle"></i>
            </a>
            <a class="icon_container times-icon">
                <i class="fal fa-times-circle"></i>
            </a>
        </div>
    </div>
</div>
