import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {MappingBusDialogComponent} from "./container/mapping-bus-dialog.component";
import {MappingBusDialogFormComponent} from './components/mapping-bus-dialog-form/mapping-bus-dialog-form.component';
import {MappingBusDialogSandbox} from "./sandbox/mapping-bus-dialog.sandbox";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        MappingBusDialogSandbox
    ],
    declarations: [
        MappingBusDialogComponent,
        MappingBusDialogFormComponent
    ],
    exports: [
        MappingBusDialogComponent
    ],
    entryComponents: [
        MappingBusDialogComponent
    ]
})
export class MappingBusDialogModule {

}
