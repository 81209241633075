import {ReportItemModel} from './report-item.model';

export class ReportGroupModel {
    name: string;
    items: ReportItemModel[];

    constructor(name: string, items: ReportItemModel[]) {
        this.name = name;
        this.items = items;
    }
}
