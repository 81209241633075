export class ProductTierDataModel {
    tierId: number;
    tierDescription: string;
    cost: number;
    costRoundTrip: number;

    constructor(tierId: number, tierDescription: string, cost: number, costRoundTrip: number) {
        this.tierId = tierId;
        this.tierDescription = tierDescription;
        this.cost = cost;
        this.costRoundTrip = costRoundTrip;
    }
}
