
import {of as observableOf, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {CommissionResponseModel} from "../../../../../../../../core/modules/rest/commission/response/commission-response.model";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {Store} from "@ngrx/store";
import {CommissionRestService} from "../../../../../../../../core/modules/rest/commission/commission-rest.service";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {CreateCommissionRequestModel} from "../../../../../../../../core/modules/rest/commission/request/create-commission-request.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {UpdateCommissionRequestModel} from "../../../../../../../../core/modules/rest/commission/request/update-commission-request.model";
import {CommissionFormSubmitDataModel} from "../model/commission-form-submit-data.model";
import {MultiselectDropdownOptionModel} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import {AmountTypeEnum} from "../../../../../../../../shared/enums/amount-type.enum";


@Injectable()
export class EditCommissionDialogSandbox extends DialogSandbox {

    commission$: Observable<CommissionResponseModel> = this.store.select(reducer.commission_selector);

    constructor(appDialogsService: AppDialogsService,
                private commissionRestService: CommissionRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadCommission(commissionId: number) {

        this.showLoader();

        this.commissionRestService.getCommissionById(commissionId).subscribe((commission: CommissionResponseModel) => {
            this.store.dispatch(new actions.SetCommission(commission));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    getAmountTypesForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        const array: MultiselectDropdownOptionModel[] = [
            new MultiselectDropdownOptionModel('Percent', AmountTypeEnum.PERCENT),
            new MultiselectDropdownOptionModel('Dollars', AmountTypeEnum.DOLLARS)
        ];

        return observableOf(array);
    }

    createCommission(data: CommissionFormSubmitDataModel): Observable<CreateBaseResponseModel> {

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const request = new CreateCommissionRequestModel(
            data.description,
            data.commissionAmount,
            data.commissionAmountType,
            data.referralAmount,
            data.referralAmountType,
            data.startDate ? data.startDate : null,
            data.endDate ? data.endDate : null,
            data.startTime,
            data.endTime);

        return this.commissionRestService.create(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while creating commission", error);
            }));
    }

    updateCommission(commissionId: number, data: CommissionFormSubmitDataModel): Observable<any> {

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const request = new UpdateCommissionRequestModel(
            commissionId,
            data.description,
            data.commissionAmount,
            data.commissionAmountType,
            data.referralAmount,
            data.referralAmountType,
            data.startDate ? data.startDate : null,
            data.endDate ? data.endDate : null,
            data.startTime,
            data.endTime);

        return this.commissionRestService.update(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while updating commission", error);
            }));
    }
}
