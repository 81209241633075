import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {CreateDropoffLocationDialogSandbox} from "../sandbox/create-dropoff-location-dialog.sandbox";
import {Observable} from "rxjs";
import {LocationResponseModel} from "../../../../../../../../core/modules/rest/location/response/location-response.model";

export class CreateDropoffLocationDialogData extends DialogData {

    kioskConfigurationId: number;

    constructor(kioskConfigurationId: number) {
        super();
        this.kioskConfigurationId = kioskConfigurationId;
    }
}


@Component({
    selector: 'app-create-dropoff-location-dialog',
    templateUrl: './create-dropoff-location-dialog.component.html'
})
export class CreateDropoffLocationDialogComponent implements OnInit, OnDestroy {

    private kioskConfigurationId: number;
    allLocations$: Observable<LocationResponseModel[]>;

    constructor(@Inject(DialogData) dialogData: CreateDropoffLocationDialogData,
                private sandbox: CreateDropoffLocationDialogSandbox) {

        this.allLocations$ = this.sandbox.allLocations$;
        this.kioskConfigurationId = dialogData.kioskConfigurationId;
    }

    ngOnInit() {
        this.sandbox.loadAllLocations();
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(dropoffLocationId: number) {
        this.sandbox.createKioskConfigurationDropoffLocation(this.kioskConfigurationId, dropoffLocationId).subscribe(() => {
            this.sandbox.destroyDialog();
        });
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
