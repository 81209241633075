import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { CustomerResponseModel } from '../../../../../../../../core/modules/rest/customer/response/customer-response.model';
import { DialogData } from "../../../../../../../../shared/dialogs/model/dialog.data";
import { EditCustomerDialogSandbox } from '../sandbox/edit-customer-dialog.sandbox';
import { CustomerFormSubmitDataModel } from '../model/customer-form-submit-data.model';

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditCustomerDialogData extends DialogData {

    customerId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, customerId?: number) {
        super();
        this.customerId = customerId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-customer-dialog',
    templateUrl: './edit-customer-dialog.component.html'
})
export class EditCustomerDialogComponent implements OnInit, OnDestroy {

    customer$: Observable<CustomerResponseModel>;
    customerId: number;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditCustomerDialogData, private sandbox: EditCustomerDialogSandbox) {
        this.customerId = dialogData.customerId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
        if (this.customerId && !isNaN(this.customerId)) {
            this.editMode = true;
            this.sandbox.loadCustomer(this.customerId);
            this.customer$ = this.sandbox.customer$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: CustomerFormSubmitDataModel) {
        if (this.editMode) {
            this.sandbox.updateCustomer( data.email, data.displayName, data.phoneNumber, data.isCustomerOwner ).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createCustomer( data.email, data.displayName, data.phoneNumber, data.forPlugin, data.isCustomerOwner ).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
