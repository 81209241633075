import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {MultiselectDropdownOptionModel, OPTION_ALL} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import {FormGroup} from "@angular/forms";
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {SubmitFormDialogDataModel} from "../../model/submit-form-dialog-data.model";
import { BusResponseModel } from '../../../../../../../../../core/modules/rest/bus/response/bus-response.model';
import { ProductSelectedModel } from '../../model/product-selected.model';
import { ProductBusMappingResponseModel } from '../../../../../../../../../core/modules/rest/product/response/product-bus-mapping-response.model';


@Component({
    selector: 'app-mapping-bus-dialog-form',
    templateUrl: './mapping-bus-dialog-form.component.html'
})
export class MappingBusDialogFormComponent implements OnInit, OnChanges {


    // --------------------------------------------------------
    // Input information
    // --------------------------------------------------------

    // Information about bus
    @Input() bus: BusResponseModel;

    // Data
    @Input() productBusMappingItems: ProductBusMappingResponseModel[];

    // Loading status for dropdown
    @Input() areProductBusMappingItemsLoading: boolean;

    // --------------------------------------------------------
    // Output event information
    // --------------------------------------------------------

    // Dialog submit/close events
    @Output() submitEvent = new EventEmitter<SubmitFormDialogDataModel>();
    @Output() closeEvent = new EventEmitter<void>();

    // Component helpers
    form: FormGroup;

    // Model for HTML
    productOptions: MultiselectDropdownOptionModel[] = [];

    // Selected values for dropdown
    selectedProductBusMappingItems: number[] = [];
    unselectedProductBusMappingItems: number[] = [];

    showInactiveProducts: boolean = false;

    showMappingForBus: boolean = true;

    productIds: string[] = [];

    constructor() {
    }

    ngOnInit() {
        this.form = new FormGroup({});
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.productBusMappingItems !== undefined && changes.productBusMappingItems.currentValue.length > 0) {
            // Populate dropdown values
            this.productBusMappingItems.sort((a, b) => a.productDescription.localeCompare(b.productDescription));
            this.onShowInactiveProducts(this.showInactiveProducts);
            // Update already selected items
            this.selectedProductBusMappingItems = changes.productBusMappingItems.currentValue
                .filter((product: ProductBusMappingResponseModel) => product.isAssigned)
                .map((product: ProductBusMappingResponseModel) => product.productId);
        }
    }


    onSelectedProductsChanged(values: string[]) {

        // Update selected list
        this.selectedProductBusMappingItems = values.map((value: string) => Number(value));
        // Update unselected list == product availabilities that have been unselected
        this.unselectedProductBusMappingItems = this.productBusMappingItems
            .filter((product: ProductBusMappingResponseModel) => !this.selectedProductBusMappingItems.includes(product.productId) && product.isAssigned)
            .map((product: ProductBusMappingResponseModel) => product.productId);
    }

    onShowInactiveProducts(value: boolean) {
        this.showInactiveProducts = value;
        if (value) {
            this.productOptions = this.productBusMappingItems
            .map((product: ProductBusMappingResponseModel) => new MultiselectDropdownOptionModel(product.productDescription, product.productId.toString()));
        }
        else {
            let activeProducts = this.productBusMappingItems.filter((product: ProductBusMappingResponseModel) => product.productActive == true);

            this.productOptions = activeProducts
            .map((product: ProductBusMappingResponseModel) => new MultiselectDropdownOptionModel(product.productDescription, product.productId.toString()));
        }
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {
        let selectedProductBusMappingItemsIds: number[] = this.selectedProductBusMappingItems
        .filter(t => String(t) !== OPTION_ALL)
        .map(Number);
        let unselectedProductBusMappingItemsIds: number[] = this.unselectedProductBusMappingItems
        .filter(t => String(t) !== OPTION_ALL)
        .map(Number);

        const data = new SubmitFormDialogDataModel(
            selectedProductBusMappingItemsIds,
            unselectedProductBusMappingItemsIds
        );

        this.submitEvent.emit(data);
    }
}
