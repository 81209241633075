import {BaseExpressionRequestModel} from './base-expression-request.model';
import {ExpressionItemTypeEnum} from '../../../../../modules/secured/reports/general/order-reports/model/expression-item-type.enum';

export class FilterExpressionRequestModel extends BaseExpressionRequestModel {
    fieldName: string;
    data: string[];

    constructor(fieldName: string, data: string[]) {
        super(ExpressionItemTypeEnum.FILTER);
        this.fieldName = fieldName;
        this.data = data;
    }
}
