import {DropdownOptionModel} from "../dropdown/dropdown-option.model";

export const OPTION_ALL: string = "OPTION_ALL";

export class MultiselectDropdownOptionActiveModel extends DropdownOptionModel {
    constructor(public label: string,
                public value: string,
                public active: boolean) {

        super(label, value);
    }
}
