<app-accordion-item>
    <div class="accordion--item--header accordionItemHeader">
        <h5>{{groupName}}</h5>
    </div>

    <div class="accordion--item--body accordionItemBody">
        <ul>
            <li *ngFor="let favorite of listOfFavorites"
                [ngClass]="{'selected': selectedFavoriteId === favorite?.favoriteId}"
                (click)="onSelectedFavorite(favorite?.favoriteId)">{{favorite?.description}}</li>
        </ul>
    </div>
</app-accordion-item>

