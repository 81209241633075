import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {CreateDropoffLocationDialogSandbox} from "./sandbox/create-dropoff-location-dialog.sandbox";
import {CreateDropoffLocationDialogComponent} from "./container/create-dropoff-location-dialog.component";
import {StoreModule} from "@ngrx/store";
import {REDUCER_NAME, reducer} from './sandbox/store/reducer';
import {CreateDropoffLocationDialogContentComponent} from './components/create-dropoff-location-dialog-content/create-dropoff-location-dialog-content.component';
import {KioskConfigurationDetailsSandbox} from "../../store/kiosk-configuration-details.sandbox";


@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        KioskConfigurationDetailsSandbox,
        CreateDropoffLocationDialogSandbox
    ],
    declarations: [
        CreateDropoffLocationDialogComponent,
        CreateDropoffLocationDialogContentComponent
    ],
    exports: [
        CreateDropoffLocationDialogComponent
    ],
    entryComponents: [
        CreateDropoffLocationDialogComponent
    ]
})
export class CreateDropoffLocationDialogModule {

}
