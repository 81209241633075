import { Component, OnInit  } from '@angular/core';


@Component({
  selector: 'app-table-no-stripes',
  templateUrl: './table-no-stripes.component.html',
  styleUrls: ['./table-no-stripes.component.scss']
})
export class TableNoStripesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
