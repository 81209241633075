import {DraggedDataI} from './dragged-data.model';
import {FilterItemModel} from './filter-item.model';

export class DraggedFilterDataModel implements DraggedDataI {
    uuid: string;
    filterItem: FilterItemModel;

    constructor(uuid: string, filterItem: FilterItemModel) {
        this.uuid = uuid;
        this.filterItem = filterItem;
    }
}
