const ErrorCodeEnum = {
    FRONT_CONNECTION_LOST_ERROR: 'FRONT_CONNECTION_LOST_ERROR' as 'FRONT_CONNECTION_LOST_ERROR',
    FRONT_UNKNOWN_ERROR: 'FRONT_UNKNOWN_ERROR' as 'FRONT_UNKNOWN_ERROR',

    SMARTSTUBS_INTERNAL_SERVER_ERROR: 'SMARTSTUBS_INTERNAL_SERVER_ERROR' as 'SMARTSTUBS_INTERNAL_SERVER_ERROR',
    SMARTSTUBS_UNEXPECTED_SERVER_ERROR: 'SMARTSTUBS_UNEXPECTED_SERVER_ERROR' as 'SMARTSTUBS_UNEXPECTED_SERVER_ERROR',

    SMARTSTUBS_UNAUTHORIZED: 'SMARTSTUBS_UNAUTHORIZED' as 'SMARTSTUBS_UNAUTHORIZED',
    SMARTSTUBS_INVALID_CREDENTIALS: 'SMARTSTUBS_INVALID_CREDENTIALS' as 'SMARTSTUBS_INVALID_CREDENTIALS',
    SMARTSTUBS_USER_IS_LOCKED: 'SMARTSTUBS_USER_IS_LOCKED' as 'SMARTSTUBS_USER_IS_LOCKED',
    SMARTSTUBS_OTP_NOT_VALID: 'SMARTSTUBS_OTP_NOT_VALID' as 'SMARTSTUBS_OTP_NOT_VALID',
    SMARTSTUBS_LOGIN_LOCATION_NOT_PROVIDED: 'SMARTSTUBS_LOGIN_LOCATION_NOT_PROVIDED' as 'SMARTSTUBS_LOGIN_LOCATION_NOT_PROVIDED',
    SMARTSTUBS_ACCESS_TOKEN_EXPIRED: 'SMARTSTUBS_ACCESS_TOKEN_EXPIRED' as 'SMARTSTUBS_ACCESS_TOKEN_EXPIRED',
    SMARTSTUBS_REFRESH_TOKEN_EXPIRED: 'SMARTSTUBS_REFRESH_TOKEN_EXPIRED' as 'SMARTSTUBS_REFRESH_TOKEN_EXPIRED',

    SMARTSTUBS_INVALID_EMAIL_ADDRESS: 'SMARTSTUBS_INVALID_EMAIL_ADDRESS' as 'SMARTSTUBS_INVALID_EMAIL_ADDRESS',
    SMARTSTUBS_RESET_PASSWORD_ERROR: 'SMARTSTUBS_RESET_PASSWORD_ERROR' as 'SMARTSTUBS_RESET_PASSWORD_ERROR',
    SMARTSTUBS_PASSWORD_COMPROMISED: 'SMARTSTUBS_PASSWORD_COMPROMISED' as 'SMARTSTUBS_PASSWORD_COMPROMISED',
    SMARTSTUBS_ACTIVATE_MFA_ERROR: 'SMARTSTUBS_ACTIVATE_MFA_ERROR' as 'SMARTSTUBS_ACTIVATE_MFA_ERROR',

    SMARTSTUBS_ENTITY_ITEM_NOT_FOUND: 'SMARTSTUBS_ENTITY_ITEM_NOT_FOUND' as 'SMARTSTUBS_ENTITY_ITEM_NOT_FOUND',

    SMARTSTUBS_NON_EXISTING_TIER: 'SMARTSTUBS_NON_EXISTING_TIER' as 'SMARTSTUBS_NON_EXISTING_TIER',
    SMARTSTUBS_NON_EXISTING_KIOSK_DEVICE_INFO: 'SMARTSTUBS_NON_EXISTING_KIOSK_DEVICE_INFO' as 'SMARTSTUBS_NON_EXISTING_KIOSK_DEVICE_INFO',

    SMARTSTUBS_CANT_CHANGE_OCCURRENCE_QUANTITY: 'SMARTSTUBS_CANT_CHANGE_OCCURRENCE_QUANTITY' as "SMARTSTUBS_CANT_CHANGE_OCCURRENCE_QUANTITY"
};
type ErrorCodeEnum = (typeof ErrorCodeEnum)[keyof typeof ErrorCodeEnum];
export {ErrorCodeEnum};
