<!-- Dialog Content :: BEGIN -->
<div class="content_wrapper">
    <div class="formRow">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <app-input-label-group>
                    <label class="label">Description</label>
                    <input class="main--element input-large"
                           type="text"
                           name="Description"
                           [(ngModel)]="autoAppliedDiscount.description"
                           (change)="onInputChanged()">
                </app-input-label-group>
            </div>
        </div>
    </div>

    <div class="formRow">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
                <app-input-label-group>
                    <label class="label">Head Count From</label>
                    <input class="main--element input-large"
                           appNumericInput
                           numericType="number"
                           name="Head Count From"
                           [(ngModel)]="autoAppliedDiscount.fromHeadCount"
                           (change)="onInputChanged()">
                </app-input-label-group>
            </div>

            <div class="cell medium-6">
                <app-input-label-group>
                    <label class="label">Head Count To</label>
                    <input class="main--element input-large"
                           appNumericInput
                           numericType="number"
                           name="Head Count To"
                           [(ngModel)]="autoAppliedDiscount.toHeadCount"
                           (change)="onInputChanged()">
                </app-input-label-group>
            </div>
        </div>
    </div>

    <div class="formRow">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <app-input-label-group>
                    <label class="label">Discount Type</label>
                    <app-dropdown class="main--element"
                                  [preSelectedValue]="autoAppliedDiscount.discountApplyType"
                                  [customClasses]="'dropdown_large'"
                                  [options]="discountApplyTypes"
                                  (changed)="onDiscountApplyTypeChanged($event)">
                    </app-dropdown>
                </app-input-label-group>
            </div>
        </div>
    </div>

    <div class="formRow">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
                <app-input-label-group>
                    <label class="label">Amount</label>
                    <input class="main--element input-large"
                           appNumericInput
                           numericType="decimal"
                           name="Amount"
                           [(ngModel)]="autoAppliedDiscount.amount"
                           (change)="onInputChanged()">
                </app-input-label-group>
            </div>

            <div class="cell medium-6">
                <app-input-label-group>
                    <label class="label">&nbsp;</label>
                    <app-dropdown class="main--element"
                                  [preSelectedValue]="autoAppliedDiscount.amountType"
                                  [customClasses]="'dropdown_large'"
                                  [options]="amountTypes"
                                  (changed)="onAmountTypeChanged($event)">
                    </app-dropdown>
                </app-input-label-group>
            </div>
        </div>
    </div>

    <div class="formRow">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <app-input-label-group>
                    <label class="label">GL Code (optional)</label>
                    <input class="main--element input-large"
                           type="text"
                           name="GL Code"
                           [(ngModel)]="autoAppliedDiscount.glCode"
                           (change)="onInputChanged()">
                </app-input-label-group>
            </div>
        </div>
    </div>

    <div class="flex-container space-between align-items-center mb-16px">
        <div class="small-12 medium-6 large-6 mr-8px pt-8px">
            <div class="cell large-12 pt-4px">
                <app-checkbox
                    [labelName]="'Use Auto Applied Discount For All Locations'"
                    [checked]="autoAppliedDiscount.allLocations"
                    (valueChanged)="onAllLocationChanged($event)">
                </app-checkbox>
            </div>
        </div>

        <div class="small-12 medium-6 large-6 pl-8px">
            <div class="cell large-12">
                <app-input-label-group>
                    <label class="label">Location (optional)</label>

                    <app-multiselect-dropdown
                        class="main--element"
                        [preSelectedValues]="autoAppliedDiscount.locationIds"
                        [options]="allLocations"
                        [disabled]="autoAppliedDiscount.allLocations"
                        (changed)="onSelectedLocationsChanged($event)">
                    </app-multiselect-dropdown>
                </app-input-label-group>
            </div>
        </div>
    </div>
</div><!-- Dialog Content :: END -->

