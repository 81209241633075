import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {KioskDeviceInfoResponseModel} from "../../../../../../../../../core/modules/rest/kiosk-device-info/response/kiosk-device-info-response.model";
import {KioskConfigurationResponseModel} from "../../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-response.model";
import {UserResponseModel} from "../../../../../../../../../core/modules/rest/user/response/user-response.model";
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {EditKioskDeviceInfoDialogFormSubmitDataModel} from "../../model/edit-kiosk-device-info-dialog-form-submit-data.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {UUID} from "angular2-uuid";


@Component({
    selector: 'app-edit-kiosk-device-info-dialog-form',
    templateUrl: './edit-kiosk-device-info-dialog-form.component.html'
})
export class EditKioskDeviceInfoDialogFormComponent implements OnInit, OnChanges {

    @Input() kioskDeviceInfo: KioskDeviceInfoResponseModel;
    @Input() kioskConfigurations: KioskConfigurationResponseModel[];
    @Input() users: UserResponseModel[];
    @Input() termsAndConditionsTemplates: EmailHtmlTemplateResponseModel[];
    @Input() editMode;

    @Output() closeEvent = new EventEmitter<any>();
    @Output() submitEvent = new EventEmitter<EditKioskDeviceInfoDialogFormSubmitDataModel>();

    form: FormGroup;
    errorMessage: string;
    kioskConfigurationOptions: DropdownOptionModel[];
    soldByOptions: DropdownOptionModel[];
    termsAndConditionsTemplateOptions: DropdownOptionModel[];

    constructor() {
    }

    ngOnInit() {

        let description: string;
        let kioskDeviceNumber: string;
        let kioskSupportEmails: string;
        let kioskSupportPhones: string;
        let soldById: number;
        let termsAndConditionsEmailHtmlTemplateId: number;
        let kioskConfigurationId: number;
        let kioskSenderEmail: string;

        if (this.editMode) {
            description = this.kioskDeviceInfo.description;
            kioskDeviceNumber = this.kioskDeviceInfo.kioskDeviceNumber;
            kioskSupportEmails = this.kioskDeviceInfo.kioskSupportEmails;
            kioskSupportPhones = this.kioskDeviceInfo.kioskSupportPhones;
            soldById = this.kioskDeviceInfo.soldById;
            termsAndConditionsEmailHtmlTemplateId = this.kioskDeviceInfo.termsAndConditionsEmailHtmlTemplateId;
            kioskConfigurationId = this.kioskDeviceInfo.kioskConfigurationId;
            kioskSenderEmail = this.kioskDeviceInfo.kioskSenderEmail;
        }

        this.form = new FormGroup({
            description: new FormControl(description, Validators.required),
            kioskDeviceNumber: new FormControl(kioskDeviceNumber, [Validators.required, Validators.minLength(3), Validators.maxLength(3),  Validators.pattern('^(?!000)[0-9]*$')]),
            kioskSupportEmails: new FormControl(kioskSupportEmails, Validators.required),
            kioskSupportPhones: new FormControl(kioskSupportPhones, Validators.required),
            soldById: new FormControl(soldById),
            termsAndConditionsEmailHtmlTemplateId: new FormControl(termsAndConditionsEmailHtmlTemplateId),
            kioskConfigurationId: new FormControl(kioskConfigurationId),
            kioskSenderEmail: new FormControl(kioskSenderEmail,Validators.required),
        });
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.editMode && this.form && changes.kioskDeviceInfo) {

            let kioskDeviceInfo: KioskDeviceInfoResponseModel = this.kioskDeviceInfo;

            this.form.patchValue({
                description: kioskDeviceInfo.description,
                kioskDeviceNumber: kioskDeviceInfo.kioskDeviceNumber,
                kioskSupportEmails: kioskDeviceInfo.kioskSupportEmails,
                kioskSupportPhones: kioskDeviceInfo.kioskSupportPhones,
                soldById: kioskDeviceInfo.soldById,
                termsAndConditionsEmailHtmlTemplateId: kioskDeviceInfo.termsAndConditionsEmailHtmlTemplateId,
                kioskConfigurationId: kioskDeviceInfo.kioskConfigurationId,
                kioskSenderEmail: kioskDeviceInfo.kioskSenderEmail
            });
        }

        if (changes.kioskConfigurations) {
            this.kioskConfigurationOptions = this.kioskConfigurations.map((kioskConfiguration: KioskConfigurationResponseModel) => {
                return new DropdownOptionModel(kioskConfiguration.description, kioskConfiguration.kioskConfigurationId.toString());
            });
        }

        if (changes.users) {
            this.soldByOptions = this.users.map((user: UserResponseModel) => {
                return new DropdownOptionModel(user.displayName, user.userId.toString());
            });
        }

        if (changes.termsAndConditionsTemplates) {
            this.termsAndConditionsTemplateOptions = this.termsAndConditionsTemplates.map((template: EmailHtmlTemplateResponseModel) => {
                return new DropdownOptionModel(template.description, template.emailHtmlTemplateId.toString());
            });
        }
    }

    onClose() {
        this.closeEvent.emit();
    }

    onSubmit() {

        if (!this.form.value.soldById) {
            this.errorMessage = 'Please select Sold By';
            return;
        }

        if (!this.form.value.termsAndConditionsEmailHtmlTemplateId) {
            this.errorMessage = 'Please select Terms and Conditions template';
            return;
        }

        this.errorMessage = null;

        let data: EditKioskDeviceInfoDialogFormSubmitDataModel = new EditKioskDeviceInfoDialogFormSubmitDataModel(
            this.form.value.description,
            this.form.value.kioskDeviceNumber,
            this.editMode ? this.kioskDeviceInfo.kioskDeviceUniqueIdentifier : UUID.UUID(),
            this.form.value.kioskSupportEmails,
            this.form.value.kioskSupportPhones,
            this.form.value.soldById,
            this.form.value.termsAndConditionsEmailHtmlTemplateId,
            this.form.value.kioskConfigurationId,
            this.form.value.kioskSenderEmail
        );

        this.submitEvent.emit(data);
    }
}
