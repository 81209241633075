
import {take, catchError} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {OccurrenceRestService} from "../../../../../../../../core/modules/rest/occurrence/occurrence-rest.service";
import {Observable} from "rxjs";
import {OccurrenceTicketsResponseModel} from "../../../../../../../../core/modules/rest/occurrence/response/occurrence-tickets-response.model";
import {select, Store} from "@ngrx/store";
import * as reducer from "../sandbox/store/reducer";
import * as actions from "../sandbox/store/actions";
import {BarcodeCheckInRestService} from "../../../../../../../../core/modules/rest/barcode-check-in/barcode-check-in-rest.service";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {AppPrintingService} from "../../../../../../../../core/services/app-printing-service";
import {UserInfoResponseModel} from "../../../../../../../../core/modules/rest/user/response/user-info-response.model";
import * as appReducer from "../../../../../../../../core/store/reducers";
import {ErrorCodeEnum} from "../../../../../../../../shared/enums/error-code.enum";

@Injectable()
export class OccurrenceManifestDialogSandbox extends DialogSandbox {

    currentUser$: Observable<UserInfoResponseModel> = this.appStore.select(appReducer.userState_userInfo_selector);

    occurrenceTickets$: Observable<OccurrenceTicketsResponseModel> = this.store.pipe(select(reducer.occurrenceTickets_selector));

    constructor(appDialogsService: AppDialogsService,
                private store: Store<reducer.State>,
                private appStore: Store<appReducer.AppState>,
                private rootSandbox: RootSandbox,
                private occurrenceRestService: OccurrenceRestService,
                private barcodeCheckInRestService: BarcodeCheckInRestService,
                private appPrintingService: AppPrintingService) {
        super(appDialogsService);
    }

    resetState() {
    }

    loadDialogData(occurrenceId: number) {

        this.showLoader();

        this.occurrenceRestService.getTicketsByOccurrenceId(occurrenceId)
            .subscribe((occurrenceTickets: OccurrenceTicketsResponseModel) => {
                this.store.dispatch(new actions.SetOccurrenceTickets(occurrenceTickets));
                this.hideLoader();
            },
            () => this.hideLoader());
    }

    printOccurrenceManifest(occurrenceId: number): Observable<boolean> {
        return this.appPrintingService.downloadOrPrintOccurrenceManifest(occurrenceId);
    }

    emailOccurrenceManifest(occurrenceId: number, emailAddress: string): Observable<any> {
        return this.occurrenceRestService.emailOccurrenceManifest({occurrenceId: occurrenceId, emailAddress: emailAddress}).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while sending email occurrence manifest", error);
            }));
    }

    doCheckIn(occurrenceIdAndTicketId: [number, number]) {

        this.showLoader();

        this.barcodeCheckInRestService.doCheckIn(occurrenceIdAndTicketId[1]).pipe(
            take(1),
            catchError((error: HttpErrorModel) => {
                this.hideLoader();
                this.loadDialogData(occurrenceIdAndTicketId[0]);
                return this.rootSandbox.handleHttpError("Error while updating check-in status for ticket: " + occurrenceIdAndTicketId[1], error);
            }),)
            .subscribe((success: boolean) => {
                this.hideLoader();
                this.loadDialogData(occurrenceIdAndTicketId[0]);
                if (success) {
                    this.rootSandbox.addInfoNotification("Check-in status updated");
                } else {
                    let httpError: HttpErrorModel = new HttpErrorModel(
                        "API_APPLICATION",
                        ErrorCodeEnum.SMARTSTUBS_INTERNAL_SERVER_ERROR,
                        "Error while updating check-in status for ticket: " + occurrenceIdAndTicketId[1],
                        "Error while updating check-in status for ticket: " + occurrenceIdAndTicketId[1]
                    );
                    this.rootSandbox.handleHttpError("SmartStubs server error", httpError);
                }
            },
            () => this.hideLoader());
    }

    showInfoMessage(message: string) {
        this.rootSandbox.addInfoNotification(message);
    }

    doBulkCheckIn(occurrenceIdAndTicketId: [number, number[]]) {

        this.showLoader();

        this.barcodeCheckInRestService.doBulkCheckIn(occurrenceIdAndTicketId[1]).pipe(
            take(1),
            catchError((error: HttpErrorModel) => {
                this.hideLoader();
                this.loadDialogData(occurrenceIdAndTicketId[0]);
                return this.rootSandbox.handleHttpError("Error while updating check-in status for ticket: " + occurrenceIdAndTicketId[1], error);
            }),)
            .subscribe((success: boolean) => {
                this.hideLoader();
                this.loadDialogData(occurrenceIdAndTicketId[0]);
                if (success) {
                    this.rootSandbox.addInfoNotification("Check-in status updated");
                } else {
                    let httpError: HttpErrorModel = new HttpErrorModel(
                        "API_APPLICATION",
                        ErrorCodeEnum.SMARTSTUBS_INTERNAL_SERVER_ERROR,
                        "Error while updating check-in status for ticket: " + occurrenceIdAndTicketId[1],
                        "Error while updating check-in status for ticket: " + occurrenceIdAndTicketId[1]
                    );
                    this.rootSandbox.handleHttpError("SmartStubs server error", httpError);
                }
            },
            () => this.hideLoader());
    }

    undoCheckIn(occurrenceIdAndTicketId: [number, number]) {

        this.showLoader();

        this.barcodeCheckInRestService.undoCheckIn(occurrenceIdAndTicketId[1]).pipe(take(1),
            catchError((error: HttpErrorModel) => {
                this.hideLoader();
                this.loadDialogData(occurrenceIdAndTicketId[0]);
                return this.rootSandbox.handleHttpError("Error while updating undo check-in status for ticket: " + occurrenceIdAndTicketId[1], error);
            }),)
            .subscribe((success: boolean) => {
                this.hideLoader();
                this.loadDialogData(occurrenceIdAndTicketId[0]);
                if (success) {
                    this.rootSandbox.addInfoNotification("Undo check-in status updated");
                } else {
                    let httpError: HttpErrorModel = new HttpErrorModel(
                        "API_APPLICATION",
                        ErrorCodeEnum.SMARTSTUBS_INTERNAL_SERVER_ERROR,
                        "Error while updating undo check-in status for ticket: " + occurrenceIdAndTicketId[1],
                        "Error while updating undo check-in status for ticket: " + occurrenceIdAndTicketId[1]
                    );
                    this.rootSandbox.handleHttpError("SmartStubs server error", httpError);
                }
            },
            () => this.hideLoader());
    }

    doNoShow(occurrenceIdAndTicketId: [number, number]) {

        this.showLoader();

        this.barcodeCheckInRestService.doNoShow(occurrenceIdAndTicketId[1]).pipe(take(1),
            catchError((error: HttpErrorModel) => {
                this.hideLoader();
                this.loadDialogData(occurrenceIdAndTicketId[0]);
                return this.rootSandbox.handleHttpError("Error while updating no-show status for ticket: " + occurrenceIdAndTicketId[1], error);
            }),)
            .subscribe((success: boolean) => {
                this.hideLoader();
                this.loadDialogData(occurrenceIdAndTicketId[0]);
                if (success) {
                    this.rootSandbox.addInfoNotification("No-show status updated");
                } else {
                    let httpError: HttpErrorModel = new HttpErrorModel(
                        "API_APPLICATION",
                        ErrorCodeEnum.SMARTSTUBS_INTERNAL_SERVER_ERROR,
                        "Error while updating no-show status for ticket: " + occurrenceIdAndTicketId[1],
                        "Error while updating no-show status for ticket: " + occurrenceIdAndTicketId[1]
                    );
                    this.rootSandbox.handleHttpError("SmartStubs server error", httpError);
                }
            },
            () => this.hideLoader());
    }

    undoNoShow(occurrenceIdAndTicketId: [number, number]) {

        this.showLoader();

        this.barcodeCheckInRestService.undoNoShow(occurrenceIdAndTicketId[1]).pipe(take(1),
            catchError((error: HttpErrorModel) => {
                this.hideLoader();
                this.loadDialogData(occurrenceIdAndTicketId[0]);
                return this.rootSandbox.handleHttpError("Error while updating undo no-show status for ticket: " + occurrenceIdAndTicketId[1], error);
            }),)
            .subscribe((success: boolean) => {
                this.hideLoader();
                this.loadDialogData(occurrenceIdAndTicketId[0]);
                if (success) {
                    this.rootSandbox.addInfoNotification("Undo no-show status updated");
                } else {
                    let httpError: HttpErrorModel = new HttpErrorModel(
                        "API_APPLICATION",
                        ErrorCodeEnum.SMARTSTUBS_INTERNAL_SERVER_ERROR,
                        "Error while updating undo no-show status for ticket: " + occurrenceIdAndTicketId[1],
                        "Error while updating undo no-show status for ticket: " + occurrenceIdAndTicketId[1]
                    );
                    this.rootSandbox.handleHttpError("SmartStubs server error", httpError);
                }
            },
            () => this.hideLoader());
    }
}
