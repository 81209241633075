export class ShoppingCartProductTierRequestModel {
    tierId: number;
    quantity: number;
    shouldCalculateCost: boolean;
    cost: number;

    constructor(tierId: number, quantity: number, shouldCalculateCost: boolean, cost: number) {
        this.tierId = tierId;
        this.quantity = quantity;
        this.shouldCalculateCost = shouldCalculateCost;
        this.cost = cost;
    }
}