import {CreateUpdateLocationListItemRequestModel} from "./create-update-location-list-item-request.model";

export class UpdateLocationListRequestModel {
    locationListId: number;
    description: string;
    locationListItems: CreateUpdateLocationListItemRequestModel[];

    constructor(locationListId: number, description: string, locationListItems: CreateUpdateLocationListItemRequestModel[]) {
        this.locationListId = locationListId;
        this.description = description;
        this.locationListItems = locationListItems;
    }
}
