import {CreateKioskConfigurationDropoffLocationProductRequestModel} from "./create-kiosk-configuration-dropoff-location-product-request.model";

export class CreateKioskConfigurationDropoffLocationProductsRequestModel {
    kioskConfigurationDropoffLocationId: number;
    dropoffLocationId: number;
    products: CreateKioskConfigurationDropoffLocationProductRequestModel[];

    constructor(kioskConfigurationDropoffLocationId: number, dropoffLocationId: number, products: CreateKioskConfigurationDropoffLocationProductRequestModel[]) {
        this.kioskConfigurationDropoffLocationId = kioskConfigurationDropoffLocationId;
        this.dropoffLocationId = dropoffLocationId;
        this.products = products;
    }
}
