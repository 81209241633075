import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditHolidayDialogSandbox} from "./sandbox/edit-holiday-dialog.sandbox";
import {EditHolidayDialogComponent} from "./container/edit-holiday-dialog.component";
import {StoreModule} from "@ngrx/store";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {EditHolidayFormComponent} from "./components/edit-holiday-form/edit-holiday-form.component";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditHolidayDialogSandbox
    ],
    declarations: [
        EditHolidayDialogComponent,
        EditHolidayFormComponent
    ],
    exports: [
        EditHolidayDialogComponent
    ],
    entryComponents: [
        EditHolidayDialogComponent
    ]
})
export class EditHolidayDialogModule {

}
