import {DraggedDataI} from './dragged-data.model';
import {FieldItemModel} from './field-item.model';

export class DraggedFieldDataModel implements DraggedDataI {
    fieldItem: FieldItemModel;

    constructor(fieldItem: FieldItemModel) {
        this.fieldItem = fieldItem;
    }
}
