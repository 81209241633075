import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {ProductForRouteSetupResponseModel} from "./response/product-for-route-setup-response.model";
import {ProductSimpleResponseModel} from "./response/product-simple-response.model";
import {FreeSellProductResponseModel} from './response/free-sell-product-response.model';
import {ProductResponseModel} from "./response/product-response.model";
import {ImageResponseModel} from "../image-response.model";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {CreateProductRequestModel} from "./request/create-product-request.model";
import {CloneProductRequestModel} from "./request/clone-product-request.model";
import {UpdateProductBasicInfoRequestModel} from "./request/update-product-basic-info-request.model";
import {UpdateProductFlagsRequestModel} from "./request/update-product-flags-request.model";
import {UpdateProductValidityRequestModel} from "./request/update-product-validity-request.model";
import {UpdateProductEmailHtmlTemplatesRequestModel} from "./request/update-product-email-html-templates-request.model";
import {UpdateProductRoutesRequestModel} from "./request/update-product-routes-request.model";
import {ProductCostResponseModel} from "./response/product-cost-response.model";
import {UpdateProductCostsRequestModel} from "./request/update-product-costs-request.model";
import { UpdateProductBusMappingRequestModel } from './request/update-product-bus-mapping-request.model';
import { ProductBusMappingResponseModel } from './response/product-bus-mapping-response.model';

@Injectable()
export class ProductRestService {

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<ProductSimpleResponseModel[]> {
        return this.httpClient.get<ProductSimpleResponseModel[]>(environment.server_api_url + '/api/secured/product/getAll');
    }

    getAllActive(): Observable<ProductSimpleResponseModel[]> {
        return this.httpClient.get<ProductSimpleResponseModel[]>(environment.server_api_url + '/api/secured/product/getAllActive');
    }

    getProductSimpleById(productId: number): Observable<ProductSimpleResponseModel> {
        return this.httpClient.get<ProductSimpleResponseModel>(environment.server_api_url + '/api/secured/product/getProductSimpleById/' + productId);
    }

    getProductById(productId: number): Observable<ProductResponseModel> {
        return this.httpClient.get<ProductResponseModel>(environment.server_api_url + '/api/secured/product/getProductById/' + productId);
    }

    getProductsByCategoryId(categoryId: number): Observable<ProductSimpleResponseModel[]> {
        return this.httpClient.get<ProductSimpleResponseModel[]>(environment.server_api_url + '/api/secured/product/getProductsByCategoryId/' + categoryId);
    }

    getProductsByCategoryIds(categoryIds: number[]): Observable<ProductSimpleResponseModel[]> {
        return this.httpClient.post<ProductSimpleResponseModel[]>(environment.server_api_url + '/api/secured/product/getProductsByCategoryIds', categoryIds);
    }

    getProductsByCategoryIdAndCurrentUser(categoryId: number): Observable<ProductSimpleResponseModel[]> {
        return this.httpClient.get<ProductSimpleResponseModel[]>(environment.server_api_url + '/api/secured/product/getProductsByCategoryIdAndCurrentUser/' + categoryId);
    }

    getProductsByCategoryIdAndCurrentUserSortedByDescription(categoryId: number): Observable<ProductSimpleResponseModel[]> {
        return this.httpClient.get<ProductSimpleResponseModel[]>(environment.server_api_url + '/api/secured/product/getProductsByCategoryIdAndCurrentUserSortedByDescription/' + categoryId);
    }

    getFreeSellProductsByProductId(productId: number): Observable<FreeSellProductResponseModel[]> {
        return this.httpClient.get<FreeSellProductResponseModel[]>(environment.server_api_url + '/api/secured/product/getFreeSellProductsByProductId/' + productId);
    }

    getProductsForKioskConfigurationDropoffLocationRouteSetup(): Observable<ProductForRouteSetupResponseModel[]> {
        return this.httpClient.get<ProductForRouteSetupResponseModel[]>(environment.server_api_url + '/api/secured/product/getProductsForKioskConfigurationDropoffLocationRouteSetup');
    }

    getProductCostsByProductId(productId: number): Observable<ProductCostResponseModel[]> {
        return this.httpClient.get<ProductCostResponseModel[]>(environment.server_api_url + '/api/secured/product/getProductCostsByProductId/' + productId);
    }

    getCategoryAndProductCostsByProductId(productId: number): Observable<ProductCostResponseModel[]> {
        return this.httpClient.get<ProductCostResponseModel[]>(environment.server_api_url + '/api/secured/product/getCategoryAndProductCostsByProductId/' + productId);
    }

    getProductBusMappingByBusId(busId: number): Observable<ProductBusMappingResponseModel[]> {
        return this.httpClient.get<ProductBusMappingResponseModel[]>(environment.server_api_url + '/api/secured/product/getProductBusMappingByBusId/' + busId);
    }

    create(request: CreateProductRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + "/api/secured/product/create", request);
    }

    cloneProduct(request: CloneProductRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + "/api/secured/product/cloneProduct", request);
    }

    updateProductBasicInfo(request: UpdateProductBasicInfoRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/product/updateProductBasicInfo', request, {responseType: 'text'});
    }

    updateProductImage(productId: number, request: FormData): Observable<ImageResponseModel> {
        return this.httpClient.post<ImageResponseModel>(environment.server_api_url + '/api/secured/product/updateProductImage/' + productId, request);
    }

    removeProductImage(productId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/product/removeProductImage/' + productId, null, {responseType: 'text'});
    }

    updateTicketBackgroundImage(productId: number, request: FormData): Observable<ImageResponseModel> {
        return this.httpClient.post<ImageResponseModel>(environment.server_api_url + '/api/secured/product/updateTicketBackgroundImage/' + productId, request);
    }

    removeTicketBackgroundImage(productId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/product/removeTicketBackgroundImage/' + productId, null, {responseType: 'text'});
    }

    updateProductFlags(request: UpdateProductFlagsRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/product/updateProductFlags', request, {responseType: 'text'});
    }

    updateProductValidity(request: UpdateProductValidityRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/product/updateProductValidity', request, {responseType: 'text'});
    }

    updateProductEmailHtmlTemplates(request: UpdateProductEmailHtmlTemplatesRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/product/updateProductEmailHtmlTemplates', request, {responseType: 'text'});
    }

    updateProductRoutes(request: UpdateProductRoutesRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/product/updateProductRoutes', request, {responseType: 'text'});
    }

    updateProductCosts(request: UpdateProductCostsRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/product/updateProductCosts', request, {responseType: 'text'});
    }

    updateProductBusMapping(request: UpdateProductBusMappingRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/product/updateProductBusMapping', request, {responseType: 'text'});
    }

    activate(productId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/product/activate', productId, {responseType: 'text'});
    }

    deactivate(productId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/product/deactivate', productId, {responseType: 'text'});
    }
}
