import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {PassCostResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-cost-response.model";
import {MultiselectDropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

export const REDUCER_NAME = 'dialog_edit-pass-costs';

export interface State {
    loading: boolean;
    passAdditionalCosts: PassCostResponseModel[];
    additionalCosts: MultiselectDropdownOptionModel[];
    passIncludedCosts: PassCostResponseModel[];
    includedCosts: MultiselectDropdownOptionModel[];
    passDiscounts: PassCostResponseModel[];
    discounts: MultiselectDropdownOptionModel[];
    passAutoAppliedDiscounts: PassCostResponseModel[];
    autoAppliedDiscounts: MultiselectDropdownOptionModel[];
}

const INITIAL_STATE: State = {
    loading: false,
    passAdditionalCosts: [],
    additionalCosts: [],
    passIncludedCosts: [],
    includedCosts: [],
    passDiscounts: [],
    discounts: [],
    passAutoAppliedDiscounts: [],
    autoAppliedDiscounts: []
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const loading_selector = createSelector(state_selector, (state: State) => state.loading);
export const passAdditionalCosts_selector = createSelector(state_selector, (state: State) => state.passAdditionalCosts);
export const additionalCosts_selector = createSelector(state_selector, (state: State) => state.additionalCosts);
export const passIncludedCosts_selector = createSelector(state_selector, (state: State) => state.passIncludedCosts);
export const includedCosts_selector = createSelector(state_selector, (state: State) => state.includedCosts);
export const passDiscounts_selector = createSelector(state_selector, (state: State) => state.passDiscounts);
export const discounts_selector = createSelector(state_selector, (state: State) => state.discounts);
export const passAutoAppliedDiscounts_selector = createSelector(state_selector, (state: State) => state.passAutoAppliedDiscounts);
export const autoAppliedDiscounts_selector = createSelector(state_selector, (state: State) => state.autoAppliedDiscounts);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;

        case actions.SET_LOADING:
            return {...state, loading: action.loading};

        case actions.SET_PASS_ADDITIONAL_COSTS:
            return {...state, passAdditionalCosts: action.passAdditionalCosts};

        case actions.SET_ADDITIONAL_COSTS:
            return {...state, additionalCosts: action.additionalCosts};

        case actions.SET_PASS_INCLUDED_COSTS:
            return {...state, passIncludedCosts: action.passIncludedCosts};

        case actions.SET_INCLUDED_COSTS:
            return {...state, includedCosts: action.includedCosts};

        case actions.SET_PASS_DISCOUNTS:
            return {...state, passDiscounts: action.passDiscounts};

        case actions.SET_DISCOUNTS:
            return {...state, discounts: action.discounts};

        case actions.SET_PASS_AUTO_APPLIED_DISCOUNTS:
            return {...state, passAutoAppliedDiscounts: action.passAutoAppliedDiscounts};

        case actions.SET_AUTO_APPLIED_DISCOUNTS:
            return {...state, autoAppliedDiscounts: action.autoAppliedDiscounts};

        default:
            return state;
    }
}
