import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RelatedOrdersGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(): boolean {
    const relatedOrders = sessionStorage.getItem('relatedOrders');
    if (relatedOrders) {
        return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
