import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DialogData} from "../../../model/dialog.data";
import {Sandbox} from "../sandbox/sandbox";

interface Options {
    label: string;
    value: string;
  }


// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class DropdownDialogData extends DialogData {

    header: string;
    message: string;
    yesLabel: string;
    noLabel: string;
    buttonType: string;
    options: Options[];

    callbackFn: (yesClicked: boolean, selected: string) => void;

    constructor(header: string, message: string, noLabel: string = 'No', yesLabel: string = 'Yes', buttonType: string = 'primary', options: Options[] = [{label: 'label', value: 'value'}], callbackFn: (yesClicked: boolean, selected: string) => void) {
        super();
        this.header = header;
        this.message = message;
        this.noLabel = noLabel;
        this.yesLabel = yesLabel;
        this.buttonType = buttonType;
        this.options = options;
        this.callbackFn = callbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
// Component that represents it
// -------------------------------------------------------------------------------------------------
@Component({
    selector: 'app-dropdown-dialog',
    templateUrl: './dropdown-dialog.component.html'
})
export class DropdownDialogComponent {

    header: string;
    message: string;
    noLabel: string;
    yesLabel: string;
    buttonType: string;
    options: Options[];
    selected: string;

    // Function that gets called once yes or no button has been clicked
    callbackFn: (yesClicked: boolean, selected: string) => void;

    constructor(@Inject(DialogData) dialogData: DropdownDialogData, private sandbox: Sandbox) {

        this.header = dialogData.header;
        this.message = dialogData.message;
        this.noLabel = dialogData.noLabel;
        this.yesLabel = dialogData.yesLabel;
        this.buttonType = dialogData.buttonType;
        this.options = dialogData.options;
        this.callbackFn = dialogData.callbackFn;
    }

    onNoClicked() {
        this.callbackFn(false, this.selected);
        this.sandbox.destroyDialog();
    }

    onYesClicked() {
        this.callbackFn(true, this.selected);
        this.sandbox.destroyDialog();
    }

    onCloseClicked() {
        this.callbackFn(false, this.selected);
        this.sandbox.destroyDialog();
    }

    valueChanged(event){
        this.selected = event;
    }
}
