import {AppDialogsService} from "../../../core/services/app-dialogs.service";
import {DialogData} from "../model/dialog.data";

/**
 * Dialog sandbox that gives control of dialog destroying, showing and hiding it
 */
export abstract class DialogSandbox {

    protected constructor(private appDialogsService: AppDialogsService) {
    }

    abstract resetState();

    renderDialog(componentToRender: any, data?: DialogData) {
        this.appDialogsService.renderDialog(componentToRender, data);
    }

    destroyDialog() {
        this.resetState();
        this.appDialogsService.destroyActiveDialog();
    }

    showLoader() {
        this.appDialogsService.showDialogLoader();
    }

    hideLoader() {
        this.appDialogsService.hideDialogLoader();
    }
}
