import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {EditKioskConfigurationDialogSandbox} from "./sandbox/edit-kiosk-configuration-dialog.sandbox";
import {EditKioskConfigurationDialogComponent} from "./container/edit-kiosk-configuration-dialog.component";
import { EditKioskConfigurationDialogContentComponent } from './components/edit-kiosk-configuration-dialog-content/edit-kiosk-configuration-dialog-content.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditKioskConfigurationDialogSandbox
    ],
    declarations: [
        EditKioskConfigurationDialogComponent,
        EditKioskConfigurationDialogContentComponent,
    ],
    exports: [
        EditKioskConfigurationDialogComponent
    ],
    entryComponents: [
        EditKioskConfigurationDialogComponent
    ]
})
export class EditKioskConfigurationDialogModule {

}
