import {Action} from "@ngrx/store";
import {CategoryResponseModel} from "../../../../../../../../../core/modules/rest/category/response/category-response.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export const RESET_STATE = '[Dialog - Edit Category Email HTML Templates :: MAIN] Reset State';
export const SET_CATEGORY = '[Dialog - Edit Category Email HTML Templates :: MAIN] Set Category';
export const SET_CREATE_ORDER_EMAIL_HTML_TEMPLATES = '[Dialog - Edit Category Email HTML Templates :: MAIN] Set Create Order Email HTML Templates';
export const SET_VOID_ORDER_EMAIL_HTML_TEMPLATES = '[Dialog - Edit Category Email HTML Templates :: MAIN] Set Void Order Email HTML Templates';
export const SET_TERMS_AND_CONDITIONS_EMAIL_HTML_TEMPLATES = '[Dialog - Edit Category Email HTML Templates :: MAIN] Set Terms And Conditions Email HTML Templates';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetCategory implements Action {
    readonly type = SET_CATEGORY;

    constructor(public category: CategoryResponseModel) {
    }
}

export class SetCreateOrderEmailHtmlTemplates implements Action {
    readonly type = SET_CREATE_ORDER_EMAIL_HTML_TEMPLATES;

    constructor(public createOrderEmailHtmlTemplates: DropdownOptionModel[]) {
    }
}

export class SetVoidOrderEmailHtmlTemplates implements Action {
    readonly type = SET_VOID_ORDER_EMAIL_HTML_TEMPLATES;

    constructor(public voidOrderEmailHtmlTemplates: DropdownOptionModel[]) {
    }
}

export class SetTermsAndConditionsEmailHtmlTemplates implements Action {
    readonly type = SET_TERMS_AND_CONDITIONS_EMAIL_HTML_TEMPLATES;

    constructor(public termsAndConditionsEmailHtmlTemplates: DropdownOptionModel[]) {
    }
}

export type All = ResetState
    | SetCategory
    | SetCreateOrderEmailHtmlTemplates
    | SetVoidOrderEmailHtmlTemplates
    | SetTermsAndConditionsEmailHtmlTemplates;
