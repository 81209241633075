import { Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {CashCollectedModel} from "../../../../model/cash-collected.model";
import {CashCollectedPaymentMethodModel} from "../../../../model/cash-collected-payment-method.model";
import {PaymentMethodEnum} from "../../../../../../../../../shared/enums/payment-method.enum";


@Component({
  selector: 'app-add-new-entry-dialog-content',
  templateUrl: './add-new-entry-dialog-content.component.html'
})
export class AddNewEntryDialogContentComponent implements OnInit {

    @Input() cashCollectedData: CashCollectedModel;
    @Input() errorMessage: string;

    @Output() cancelEvent = new EventEmitter<void>();
    @Output() submitEvent = new EventEmitter<CashCollectedModel>();

    constructor() {
    }

    ngOnInit() {
    }

    amountChanged(event: any) {
        const amount: number = Number(event.target.value.replace(",", ""));
        const paymentMethod: PaymentMethodEnum = <PaymentMethodEnum> event.target.getAttribute('data-cash-collected-payment-method');
        const postBillTypeId: number = +event.target.getAttribute('data-cash-collected-post-bill-type-id');
        const cashCollectedPaymentMethod: CashCollectedPaymentMethodModel = this.cashCollectedData.cashCollectedPaymentMethods.find(ccpm => ccpm.paymentMethod === paymentMethod && (ccpm.postBillTypeId === null || ccpm.postBillTypeId === postBillTypeId));
        cashCollectedPaymentMethod.usd = amount;
    }

    onPrintAfterSubmitChanged(value: boolean) {
        this.cashCollectedData.shouldPrintAfterSubmit = value;
    }

    onCancel() {
        this.cancelEvent.emit();
    }

    onSubmit() {

        // If some of the payment methods' amount is not provided, populate it with 0
        if (this.cashCollectedData.cashCollectedPaymentMethods) {
            for (let cashCollectedPaymentMethod of this.cashCollectedData.cashCollectedPaymentMethods) {
                if (!cashCollectedPaymentMethod.usd) {
                    cashCollectedPaymentMethod.usd = 0;
                }
            }
        }

        this.submitEvent.emit(this.cashCollectedData);
    }

    getPaymentMethodFriendly(cashCollectedPaymentMethod: CashCollectedPaymentMethodModel): string {
        if (cashCollectedPaymentMethod.paymentMethod === PaymentMethodEnum.POST_BILL) {
            return cashCollectedPaymentMethod.postBillTypeDescription;
        } else {
            return cashCollectedPaymentMethod.paymentMethodDescription;
        }
    }
}
