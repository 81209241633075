<form class="form_container">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ roundTrip ? 'Round Trip' : 'One Way' }} Setup</h1>
            </div>
        </div>
    </div>

    <div class="content_wrapper">

        <!--Content Label :: BEGIN-->
        <div class="grid-x grid-margin-x align-middle">
            <div class="cell medium-6">
                <label class="secondary">Tier</label>
            </div>

            <div class="cell auto">
                <div class="grid-x grid-margin-x">
                    <div class="cell auto ">
                        <label class="text-center secondary"> Default </label>
                    </div>
                    <div class="cell auto">
                        <label class="text-center secondary"> Min </label>
                    </div>
                    <div class="cell auto">
                        <label class="text-center secondary"> Max </label>
                    </div>
                </div>
            </div>
        </div>
        <!--Content Label :: END-->

        <app-dialog-product-tier-item class="formRow"
                                      *ngFor="let productTier of productTiers"
                                      [productTier]="productTier"
                                      (defaultQuantityChangedEvent)="defaultTierQuantityChanged($event)"
                                      (minQuantityChangedEvent)="minTierQuantityChanged($event)"
                                      (maxQuantityChangedEvent)="maxTierQuantityChanged($event)">
        </app-dialog-product-tier-item>
    </div>

    <div class="errorMessage_wrapper"
         *ngIf="errorMessage">
        <label class="error">
            {{ errorMessage }}
        </label>
    </div>


    <div class="footer_wrapper">
        <div class="grid-x grid-padding-x">
            <div class="cell medium-6">
                <app-button
                    [buttonType]="'secondary'"
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>

            <div class="cell medium-6">
                <app-button
                    [buttonType]="'primary'"
                    [disabled]="disabledSaveButton"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div>
</form>
