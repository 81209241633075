import {ProductTierDataModel} from "./product-tier-data.model";
import {PricingDateRangeFullResponseModel} from "../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-full-response.model";
import {ProductTierResponseModel} from "../../../../../../core/modules/rest/pricing-date-range/response/product-tier-response.model";

export class PricingDateRangeDataModel {
    pricingDateRangeId: number;
    active: boolean;
    productId: number;
    priority: number;
    startDateInt: number;
    startDateMS: number;
    startDateFriendly: string;
    endDateInt: number;
    endDateMS: number;
    endDateFriendly: string;
    startTime: number;
    startTimeFriendly: string;
    endTime: number;
    endTimeFriendly: string;
    fromLocationId: number;
    fromLocationDescription: string;
    toLocationId: number;
    toLocationDescription: string;

    productTiers: ProductTierDataModel[];

    constructor(pricingDateRangeFull: PricingDateRangeFullResponseModel) {
        this.pricingDateRangeId = pricingDateRangeFull.pricingDateRangeId;
        this.active = pricingDateRangeFull.active;
        this.productId = pricingDateRangeFull.productId;
        this.priority = pricingDateRangeFull.priority;
        this.startDateInt = pricingDateRangeFull.startDateInt;
        this.startDateMS = pricingDateRangeFull.startDateMS;
        this.startDateFriendly = pricingDateRangeFull.startDateFriendly;
        this.endDateInt = pricingDateRangeFull.endDateInt;
        this.endDateMS = pricingDateRangeFull.endDateMS;
        this.endDateFriendly = pricingDateRangeFull.endDateFriendly;
        this.startTime = pricingDateRangeFull.startTime;
        this.startTimeFriendly = pricingDateRangeFull.startTimeFriendly;
        this.endTime = pricingDateRangeFull.endTime;
        this.endTimeFriendly = pricingDateRangeFull.endTimeFriendly;
        this.fromLocationId = pricingDateRangeFull.fromLocationId;
        this.fromLocationDescription = pricingDateRangeFull.fromLocationDescription;
        this.toLocationId = pricingDateRangeFull.toLocationId;
        this.toLocationDescription = pricingDateRangeFull.toLocationDescription;

        this.productTiers = pricingDateRangeFull.productTiers ?
            pricingDateRangeFull.productTiers.map((pt: ProductTierResponseModel) => new ProductTierDataModel(pt.tierId, pt.tierDescription, pt.cost, pt.costRoundTrip)) :
            [];
    }
}
