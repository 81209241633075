import {NgModule} from "@angular/core";
import {ClonePassPricingDateRangesDialogSandbox} from "./sandbox/clone-pass-pricing-date-ranges-dialog.sandbox";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {ClonePassPricingDateRangesDialogFormComponent} from './components/clone-pass-pricing-date-ranges-dialog-form/clone-pass-pricing-date-ranges-dialog-form.component';
import {ClonePassPricingDateRangesDialogComponent} from "./clone-pass-pricing-date-ranges-dialog.component";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    declarations: [
        ClonePassPricingDateRangesDialogComponent,
        ClonePassPricingDateRangesDialogFormComponent
    ],
    providers: [
        ClonePassPricingDateRangesDialogSandbox
    ],
    exports: [
        ClonePassPricingDateRangesDialogComponent
    ],
    entryComponents: [
        ClonePassPricingDateRangesDialogComponent
    ]
})
export class ClonePassPricingDateRangesDialogModule {
}
