
import { take } from 'rxjs/operators';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DialogData } from "../../../../../../../shared/dialogs/model/dialog.data";
import { OccurrenceManifestDialogSandbox } from "./sandbox/occurrence-manifest-dialog.sandbox";
import { Observable } from "rxjs";
import { OccurrenceTicketsResponseModel } from "../../../../../../../core/modules/rest/occurrence/response/occurrence-tickets-response.model";
import {
    EmailOrderDialogData,
    SendEmailDialogComponent
} from "../../../../../../../shared/dialogs/common-dialogs/send-email-dialog/send-email-dialog.component";
import { AppDialogsService } from "../../../../../../../core/services/app-dialogs.service";
import { RootSandbox } from "../../../../../../../core/store/root.sandbox";
import { UserInfoResponseModel } from "../../../../../../../core/modules/rest/user/response/user-info-response.model";
import { TicketStatusEnum } from "../../../../../../../shared/enums/ticket-status.enum";
import {
    OrderSummaryDialogComponent,
    OrderSummaryDialogData
} from "../../../../../../../shared/dialogs/common-dialogs/order-summary-dialog/container/order-summary-dialog.component";

export class OccurrenceManifestDialogData extends DialogData {

    occurrenceId: number;
    updateCallbackFn: Function;

    constructor(occurrenceId: number, updateCallbackFn: Function) {
        super();
        this.occurrenceId = occurrenceId;
        this.updateCallbackFn = updateCallbackFn;
    }
}


@Component({
    selector: 'app-occurrence-manifest-dialog',
    templateUrl: './occurrence-manifest-dialog.component.html'
})
export class OccurrenceManifestDialogComponent implements OnInit, OnDestroy {

    occurrenceId: number;
    updateCallbackFn: Function;

    currentUser$: Observable<UserInfoResponseModel> = this.sandbox.currentUser$;

    occurrenceTickets$: Observable<OccurrenceTicketsResponseModel> = this.sandbox.occurrenceTickets$;

    constructor(@Inject(DialogData) data: OccurrenceManifestDialogData, public rootSandbox: RootSandbox, private sandbox: OccurrenceManifestDialogSandbox, private appDialogsService: AppDialogsService) {

        this.occurrenceId = data.occurrenceId;
        this.updateCallbackFn = data.updateCallbackFn;
    }

    ngOnInit(): void {
        this.sandbox.loadDialogData(this.occurrenceId);
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onPrintClicked() {
        this.sandbox.printOccurrenceManifest(this.occurrenceId).subscribe();
    }

    onEmailClicked() {

        this.currentUser$.pipe(take(1)).subscribe((currentUser: UserInfoResponseModel) => {
            this.appDialogsService.renderDialog(SendEmailDialogComponent, new EmailOrderDialogData(
                currentUser.email,
                (emailAddress: string) => {
                    this.sandbox.emailOccurrenceManifest(this.occurrenceId, emailAddress).pipe(take(1)).subscribe(() => this.rootSandbox.addInfoNotification(`Email sent to ${emailAddress}`));
                }
            ));
        });
    }

    onCheckInAllClick(occurrenceIdAndTicketIds: [number, number[]]) {
        this.sandbox.doBulkCheckIn(occurrenceIdAndTicketIds);
    }

    onShowInfoMessage(message: string) {
        this.sandbox.showInfoMessage(message);
    }

    onShowOrderSummaryClicked(orderId: number) {
        this.appDialogsService.renderDialog(OrderSummaryDialogComponent, new OrderSummaryDialogData(orderId));
    }

    onDoCheckInClicked(occurrenceIdAndTicketId: [number, number]) {
        this.sandbox.doCheckIn(occurrenceIdAndTicketId);
    }

    onUndoCheckInClicked(occurrenceIdAndTicketId: [number, number]) {
        this.sandbox.undoCheckIn(occurrenceIdAndTicketId);
    }

    onDoNoShowClicked(occurrenceIdAndTicketId: [number, number]) {
        this.sandbox.doNoShow(occurrenceIdAndTicketId);
    }

    onUndoNoShowClicked(occurrenceIdAndTicketId: [number, number]) {
        this.sandbox.undoNoShow(occurrenceIdAndTicketId);
    }

    onCloseClicked() {

        this.occurrenceTickets$.pipe(
            take(1))
            .subscribe((occurrenceTickets: OccurrenceTicketsResponseModel) => {

                // Send new occurrence counts as callback parameter
                let occurrenceCounts: { reserved: number, checkedIn: number, noShow: number } = { reserved: 0, checkedIn: 0, noShow: 0 };

                occurrenceCounts.reserved = occurrenceTickets.occurrenceTickets
                    .filter(ot => ot.lastTicketStatus === null || ot.lastTicketStatus === TicketStatusEnum.CHECKED_OUT || ot.lastTicketStatus === TicketStatusEnum.REJECTED)
                    .map(ot => ot.tierHeadCount)
                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                occurrenceCounts.checkedIn = occurrenceTickets.occurrenceTickets
                    .filter(ot => ot.lastTicketStatus !== null && ot.lastTicketStatus === TicketStatusEnum.CHECKED_IN)
                    .map(ot => ot.tierHeadCount)
                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                occurrenceCounts.noShow = occurrenceTickets.occurrenceTickets
                    .filter(ot => ot.lastTicketStatus !== null && ot.lastTicketStatus === TicketStatusEnum.NO_SHOW)
                    .map(ot => ot.tierHeadCount)
                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                this.updateCallbackFn(occurrenceCounts);
                this.sandbox.destroyDialog();
            });
    }
}
