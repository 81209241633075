import {FavoriteTypeEnum} from "../../../../../shared/enums/favorite-type.enum";

export class CreateFavoriteRequestModel {
    description: string;
    groupDescription: string;
    favoriteType: FavoriteTypeEnum;
    favoriteData: string;

    constructor(description: string, groupDescription: string, favoriteType: FavoriteTypeEnum, favoriteData: string) {
        this.description = description;
        this.groupDescription = groupDescription;
        this.favoriteType = favoriteType;
        this.favoriteData = favoriteData;
    }
}
