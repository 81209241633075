import {Action} from "@ngrx/store";
import {TreeItemTypeEnum} from "../../model/tree-item-type.enum";
import {TreeNode} from "../../../../../../../shared/components/tree/tree.component";
import {PermissionNode} from "../../model/permission-node";
import { PermissionItemNode } from "../../../../../../../shared/components/tree-permission/tree-permission.component";

export const RESET_STATE = "[Manage Customer Owners :: MAIN] Reset State";
export const SET_SELECTED_ITEM = "[Manage Customer Owners :: MAIN] Set Selected Item";
export const SET_SELECTED_NODE = "[Manage Customer Owners :: MAIN] Set Selected Node";
export const SET_CUSTOMER_OWNER = "[Manage Customer Owners :: MAIN] Set Customer Owner";
export const SET_CUSTOMER_OWNERS_TREE = "[Manage Customer Owners :: MAIN] Set Customer Owners";
export const SET_PERMISSION_TREE = "[Manage Customer Owners :: MAIN] Set Permission Tree";
export const SET_PERMISSION_TREE_MATERIAL = "[Manage Customer Owners :: MAIN] Set Permission Tree Material";
export const SET_LOADING_CUSTOMER_OWNERS = "[Manage Customer Owners :: MAIN] Loading Customer Owners";
export const SET_LOADING_PERMISSIONS_TREE = "[Manage Customer Owners :: MAIN] Loading Permissions Tree";
export const SET_CUSTOMER_OWNERS_PERMISSION_TREE = "[Manage Customer Owners:: MAIN] Set Customer Owners Permission Tree";
export const SET_FORM_HAS_CHANGES   = "[Manage Customer Owners :: MAIN] Set Form Has Changes";

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetSelectedItem implements Action {
    readonly type = SET_SELECTED_ITEM;

    constructor(public item: TreeNode) {
    }
}

export class SetSelectedNode implements Action {
    readonly type = SET_SELECTED_NODE;

    constructor(public node: TreeNodeMaterial) {
    }
}

export class SetCustomerOwner implements Action {
    readonly type = SET_CUSTOMER_OWNER;

    constructor(public itemType: TreeItemTypeEnum, public itemId: number, public itemActive: boolean) {
    }
}

export class SetCustomerOwnersTree implements Action {
    readonly type = SET_CUSTOMER_OWNERS_TREE;

    constructor(public tree: TreeNode[]) {
    }
}

export class SetPermissionTree implements Action {
    readonly type = SET_PERMISSION_TREE;
    constructor(public tree: PermissionNode[]) {
    }
}

export class SetPermissionTreeMaterial implements Action {
    readonly type = SET_PERMISSION_TREE_MATERIAL;

    constructor(public treeMaterial: PermissionItemNode[]) {
    }
}

export class SetLoadingCustomerOwners implements Action {
    readonly type = SET_LOADING_CUSTOMER_OWNERS;

    constructor(public loading: boolean) {
    }
}

export class SetLoadingPermissionsTree implements Action {
    readonly type = SET_LOADING_PERMISSIONS_TREE;

    constructor(public loading: boolean) {
    }
}

export class SetCustomerOwnersPermissionTree implements Action {
    readonly type = SET_CUSTOMER_OWNERS_PERMISSION_TREE;

    constructor(public tree: PermissionNode[]) {
    }
}

export class SetFormHasChanges implements Action {
    readonly type = SET_FORM_HAS_CHANGES;

    constructor(public data: boolean) {
    }
}

export type All = ResetState
    | SetSelectedItem
    | SetSelectedNode
    | SetCustomerOwner
    | SetCustomerOwnersTree
    | SetPermissionTree
    | SetPermissionTreeMaterial
    | SetLoadingCustomerOwners
    | SetLoadingPermissionsTree
    | SetCustomerOwnersPermissionTree
    |SetFormHasChanges;
