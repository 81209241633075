
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {CategoryRestService} from "../../../../../../../../core/modules/rest/category/category-rest.service";
import {EmailHtmlTemplateRestService} from "../../../../../../../../core/modules/rest/email-html-template/email-html-template-rest.service";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {CategoryResponseModel} from "../../../../../../../../core/modules/rest/category/response/category-response.model";
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {DropdownOptionModel} from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {SubmitCategoryEmailHtmlTemplatesData} from "../components/edit-category-email-html-templates-dialog-form/edit-category-email-html-templates-dialog-form.component";
import {EmailHtmlTemplateTypeEnum} from "../../../../../../../../shared/enums/email-html-template-type.enum";

@Injectable()
export class EditCategoryEmailHtmlTemplatesDialogSandbox extends DialogSandbox {

    category$: Observable<CategoryResponseModel> = this.store.pipe(select(reducer.category_selector));
    createOrderEmailHtmlTemplates$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.createOrderEmailHtmlTemplates_selector));
    voidOrderEmailHtmlTemplates$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.voidOrderEmailHtmlTemplates_selector));
    termsAndConditionsEmailHtmlTemplates$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.termsAndConditionsEmailHtmlTemplates_selector));

    constructor(appDialogsService: AppDialogsService,
                private categoryRestService: CategoryRestService,
                private emailHtmlTemplateRestService: EmailHtmlTemplateRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(categoryId: number) {

        observableCombineLatest(this.categoryRestService.getCategoryById(categoryId), this.emailHtmlTemplateRestService.getAllActive()).pipe(
            take(1))
            .subscribe(([category, emailHtmlTemplates]: [CategoryResponseModel, EmailHtmlTemplateResponseModel[]]) => {
                this.store.dispatch(new actions.SetCategory(category));
                this.store.dispatch(new actions.SetCreateOrderEmailHtmlTemplates(emailHtmlTemplates.filter(eht => eht.emailHtmlTemplateType === EmailHtmlTemplateTypeEnum.CREATE_ORDER).map(eht => new DropdownOptionModel(eht.description, eht.emailHtmlTemplateId.toString()))));
                this.store.dispatch(new actions.SetVoidOrderEmailHtmlTemplates(emailHtmlTemplates.filter(eht => eht.emailHtmlTemplateType === EmailHtmlTemplateTypeEnum.VOID_ORDER).map(eht => new DropdownOptionModel(eht.description, eht.emailHtmlTemplateId.toString()))));
                this.store.dispatch(new actions.SetTermsAndConditionsEmailHtmlTemplates(emailHtmlTemplates.filter(eht => eht.emailHtmlTemplateType === EmailHtmlTemplateTypeEnum.TERMS_AND_CONDITIONS).map(eht => new DropdownOptionModel(eht.description, eht.emailHtmlTemplateId.toString()))));
            });
    }

    updateCategoryEmailHtmlTemplates(data: SubmitCategoryEmailHtmlTemplatesData): Observable<any> {

        return this.categoryRestService.updateCategoryEmailHtmlTemplates({
            categoryId: data.categoryId,
            createOrderEmailHtmlTemplateId: data.createOrderEmailHtmlTemplateId,
            voidOrderEmailHtmlTemplateId: data.voidOrderEmailHtmlTemplateId,
            termsAndConditionsEmailHtmlTemplateId: data.termsAndConditionsEmailHtmlTemplateId
        });
    }
}
