
import { combineLatest as observableCombineLatest, Observable } from 'rxjs';

import { take, flatMap, catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { DialogSandbox } from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import { AppDialogsService } from "../../../../../../../../core/services/app-dialogs.service";
import { OccurrenceRestService } from "../../../../../../../../core/modules/rest/occurrence/occurrence-rest.service";
import { EmailHtmlTemplateRestService } from "../../../../../../../../core/modules/rest/email-html-template/email-html-template-rest.service";
import { OccurrenceOrdersResponseModel } from "../../../../../../../../core/modules/rest/occurrence/response/occurrence-orders-response.model";
import { EmailHtmlTemplateResponseModel } from "../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import { select, Store } from "@ngrx/store";
import * as reducer from "../sandbox/store/reducer";
import * as actions from "../sandbox/store/actions";
import { HttpErrorModel } from '../../../../../../../../core/modules/rest/http-error.model';
import { RootSandbox } from "../../../../../../../../core/store/root.sandbox";

@Injectable()
export class DeactivateOccurrencesDialogSandbox extends DialogSandbox {

    occurrencesOrders$: Observable<OccurrenceOrdersResponseModel[]> = this.store.pipe(select(reducer.occurrencesOrders_selector));
    emailHtmlTemplates$: Observable<EmailHtmlTemplateResponseModel[]> = this.store.pipe(select(reducer.emailHtmlTemplates_selector));

    constructor(appDialogsService: AppDialogsService, private store: Store<reducer.State>, private occurrenceRestService: OccurrenceRestService, private emailHtmlTemplateRestService: EmailHtmlTemplateRestService, private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
    }

    loadDialogData(occurrenceIds: number[]) {

        this.showLoader();

        observableCombineLatest([
            this.occurrenceRestService.getOrdersByOccurrenceIds({occurrenceIds: occurrenceIds}),
            this.emailHtmlTemplateRestService.getAllActive()
        ]).pipe(take(1)).subscribe(([occurrencesOrders, emailHtmlTemplates]: [OccurrenceOrdersResponseModel[], EmailHtmlTemplateResponseModel[]]) => {
            this.store.dispatch(new actions.SetOccurrencesOrders(occurrencesOrders));
            this.store.dispatch(new actions.SetEmailHtmlTemplates(emailHtmlTemplates));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    deactivateOccurrence(voidAndRefundOrders: boolean, emailHtmlTemplateId: number) {

        this.showLoader();

        return this.occurrencesOrders$.pipe(
            take(1),
            catchError((error: HttpErrorModel) => {
                this.hideLoader();
                return this.rootSandbox.handleHttpError("Error while deactivate occurrence", error);
            }),
            flatMap((occurrenceOrdersList: OccurrenceOrdersResponseModel[]) => {

                return this.occurrenceRestService.deactivateOccurrences({
                    voidOrders: voidAndRefundOrders,
                    occurrenceIds: occurrenceOrdersList.map(ool => ool.occurrenceId),
                    emailHtmlTemplateId: emailHtmlTemplateId
                });
            }));
    }
}
