const SystemPermissionEnum = {
    REVENUE_REPORT: 'REVENUE_REPORT' as 'REVENUE_REPORT',
    NET_SUITE_EXPORT_REPORT: 'NET_SUITE_EXPORT_REPORT' as 'NET_SUITE_EXPORT_REPORT',
    KIOSK_CONFIGURATION: 'KIOSK_CONFIGURATION' as 'KIOSK_CONFIGURATION',
    KIOSK: 'KIOSK' as 'KIOSK',
    CLOUD_PAYMENT: 'CLOUD_PAYMENT' as 'CLOUD_PAYMENT',
    USE_CUSTOMERS: 'USE_CUSTOMERS' as 'USE_CUSTOMERS',
    CUSTOM_BILLING_REPORT: 'CUSTOM_BILLING_REPORT' as 'CUSTOM_BILLING_REPORT',
    LEGACY_TICKET: 'LEGACY_TICKET' as 'LEGACY_TICKET',
    USE_OVERRIDE_REASON: 'USE_OVERRIDE_REASON' as 'USE_OVERRIDE_REASON'
};
type SystemPermissionEnum = (typeof SystemPermissionEnum)[keyof typeof SystemPermissionEnum];
export {SystemPermissionEnum};

export function descriptionFriendly(description: SystemPermissionEnum): string {
    const words = description.toLowerCase().split("_");
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(" ");
}
