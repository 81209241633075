import {FavoriteTypeEnum} from "../../../../../shared/enums/favorite-type.enum";

export class UpdateFavoriteRequestModel {
    favoriteId: number;
    description: string;
    groupDescription: string;
    favoriteType: FavoriteTypeEnum;
    favoriteData: any;

    constructor(favoriteId: number, description: string, groupDescription: string, favoriteType: FavoriteTypeEnum, favoriteData: any) {
        this.favoriteId = favoriteId;
        this.description = description;
        this.groupDescription = groupDescription;
        this.favoriteType = favoriteType;
        this.favoriteData = favoriteData;
    }
}
