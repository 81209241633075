<div class="pass-dialog-content">
    <h1 class="mb-8px">{{getHeader()}}</h1>
    <form [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="grid-x grid-margin-x">
                    <div class="cell medium-12 mb-16px">
                        <app-input-label-group>
                            <label class="label">Name</label>
                            <input class="main--element input-large" placeholder="Enter Custom field name" formControlName="description" [ngClass]="{'disabled': isDescriptionDisabled}"/>
                        </app-input-label-group>
                    </div>
                    <div class="cell medium-12 mb-16px">
                        <app-checkbox
                            [ngClass]="'mb-8px'"
                            [labelName]="'Required'"
                            formControlName="required">
                        </app-checkbox>

                        <app-checkbox
                            [ngClass]="'mb-8px'"
                            [labelName]="'Display on Ticket'"
                            formControlName="displayOnTicket">
                        </app-checkbox>
                    </div>
                    <div class="cell medium-12">
                        <app-radio-button-group
                            class="radio-input-half-width flex-container role-radio-option flex-dir-column"
                            formControlName="fieldScope"
                            [data]="fieldScopes">
                        </app-radio-button-group>
                    </div>
                </div>
            </div>
        </ng-container>

        <p *ngIf="hasError" class="error">{{ errorMessage }}</p>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">{{getSubmitButtonLabel()}}</button>
    </div>
</div>
