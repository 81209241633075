import {NgModule} from "@angular/core";
import {AddProductDialogComponent} from './add-product-dialog.component';
import {AddProductDialogSandbox} from "./sandbox/add-product-dialog.sandbox";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {AddProductDialogFormComponent} from './components/add-product-dialog-form/add-product-dialog-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    declarations: [
        AddProductDialogComponent,
        AddProductDialogFormComponent
    ],
    providers: [
        AddProductDialogSandbox
    ],
    exports: [
        AddProductDialogComponent
    ],
    entryComponents: [
        AddProductDialogComponent
    ]
})
export class AddProductDialogModule {
}
