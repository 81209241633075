export class UpdateLocationRequestModel {
    locationId: number;
    description: string;
    zipCode: string;
    latitude: number;
    longitude: number;

    constructor(locationId: number, description: string, zipCode: string, latitude: number, longitude: number) {
        this.locationId = locationId;
        this.description = description;
        this.zipCode = zipCode;
        this.latitude = latitude;
        this.longitude = longitude;
    }
}
