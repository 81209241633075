import {FieldRequestModel} from './field-request.model';
import {SortByRequestModel} from './sort-by-request.model';
import {GroupByRequestModel} from './group-by-request.model';
import {BaseExpressionRequestModel} from './base-expression-request.model';

export class ReportSearchRequestModel {
    searchExpression: BaseExpressionRequestModel[];
    fieldRequests: FieldRequestModel[];
    sortRequests: SortByRequestModel[];
    groupByRequests: GroupByRequestModel[];
    from: number;
    size: number;
    scrollId: string;
    showPaymentSummary: boolean;

    constructor(searchExpression: BaseExpressionRequestModel[], fieldRequests: FieldRequestModel[], sortRequests: SortByRequestModel[],
                groupByRequests: GroupByRequestModel[], from: number, size: number, scrollId: string, showPaymentSummary: boolean) {
        this.searchExpression = searchExpression;
        this.fieldRequests = fieldRequests;
        this.sortRequests = sortRequests;
        this.groupByRequests = groupByRequests;
        this.from = from;
        this.size = size;
        this.scrollId = scrollId;
        this.showPaymentSummary = showPaymentSummary;
    }
}

