import * as actions from './actions';
import {createFeatureSelector} from "@ngrx/store";

export const REDUCER_NAME = 'dialog_clone-product';

export interface State {
}

const INITIAL_STATE: State = {
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;

        default:
            return state;
    }
}
