import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {CloneProductDialogSandbox} from "./sandbox/clone-product-dialog.sandbox";
import {SubmitCloneProductData} from "./components/clone-product-dialog-form/clone-product-dialog-form.component";
import {CreateBaseResponseModel} from "../../../../../../../core/modules/rest/create-base-response.model";

export class CloneProductDialogData extends DialogData {

    productId: number;
    isRoundTripProduct: boolean;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, productId: number, isRoundTripProduct: boolean) {
        super();
        this.productId = productId;
        this.isRoundTripProduct = isRoundTripProduct;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-clone-product-dialog',
    templateUrl: './clone-product-dialog.component.html'
})
export class CloneProductDialogComponent implements OnDestroy {

    productId: number;
    isRoundTripProduct: boolean;
    successCallbackFn: Function;

    constructor(@Inject(DialogData) data: CloneProductDialogData, private sandbox: CloneProductDialogSandbox) {

        this.productId = data.productId;
        this.isRoundTripProduct = data.isRoundTripProduct;
        this.successCallbackFn = data.successCallbackFn;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitCloneProductData) {
        this.sandbox.cloneProduct(data).subscribe((response: CreateBaseResponseModel) => {
            this.successCallbackFn(response.id);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
