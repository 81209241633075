<div class="category-and-product-dialog-content">
    <h1 class="mb-8px">Edit Product Basic info</h1>
    <form [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="grid-x grid-margin-x">
                    <div class="order-form-item">
                        <label class="order-label">Category</label>
                        <div class="order-input-wrapper">
                            <app-dropdown
                                class="main--element"
                                [customClasses]="'dropdown_large'"
                                [options]="categories"
                                [preSelectedValue]="product.categoryId"
                                formControlName="categoryId">
                            </app-dropdown>
                        </div>
                    </div>
                    <div class="order-form-item">
                        <label class="order-label">Product Name</label>
                        <div class="order-input-wrapper">
                            <input type="text" placeholder="Enter product name" formControlName="description"/>
                        </div>
                    </div>
                    <div class="order-form-item" *ngIf="product.isRoundTripProduct">
                        <label class="order-label">Outbound Trip Name</label>
                        <div class="order-input-wrapper">
                            <input type="text" placeholder="Enter outgoing trip name" formControlName="descriptionRegularTrip"/>
                        </div>
                    </div>
                    <div class="order-form-item" *ngIf="product.isRoundTripProduct">
                        <label class="order-label">Return Trip Name</label>
                        <div class="order-input-wrapper">
                            <input type="text" placeholder="Enter return trip name" formControlName="descriptionReturnTrip"/>
                        </div>
                    </div>
                    <div class="order-form-item">
                        <label class="order-label">Description (HTML) </label>
                        <div class="order-input-wrapper">
                            <textarea rows="5" placeholder="Enter parsed HTML" formControlName="detailsHtml"></textarea>
                        </div>
                    </div>
                    <div class="order-form-item">
                        <label class="order-label">Mobile Receipt Notes</label>
                        <div class="order-input-wrapper">
                            <textarea rows="5" placeholder="Enter mobile receipt notes" formControlName="details"></textarea>
                        </div>
                    </div>
                    <div class="order-form-item">
                        <label class="order-label">Receipt Note</label>
                        <div class="order-input-wrapper">
                            <textarea rows="5" placeholder="Enter Receipt Notes" formControlName="note"></textarea>
                        </div>
                    </div>
                    <div class="order-form-item">
                        <label class="order-label">Email Orders to</label>
                        <div class="order-input-wrapper">
                            <input type="text" placeholder="Enter a valid email address(es)" formControlName="emails">
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <p *ngIf="hasError" class="error">{{ errorMessage }}</p>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Update</button>
    </div>
</div>
