
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {catchError, take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {TreeItemTypeEnum} from "../../../model/tree-item-type.enum";
import {UserRestService} from "../../../../../../../../core/modules/rest/user/user-rest.service";
import {RoleRestService} from "../../../../../../../../core/modules/rest/role/role-rest.service";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {RoleResponseModel} from "../../../../../../../../core/modules/rest/role/response/role-response.model";
import {DropdownOptionModel} from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {RolesResponseModel} from "../../../../../../../../core/modules/rest/role/response/roles-response.model";
import {UserResponseModel} from "../../../../../../../../core/modules/rest/user/response/user-response.model";
import {
    SubmitRoleData,
    SubmitUserData
} from "../components/edit-role-or-user-dialog-form/edit-role-or-user-dialog-form.component";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {DateTimeUtility} from "../../../../../../../../shared/utils/date-time-utility";
import * as moment from "moment";

@Injectable()
export class EditRoleOrUserDialogSandbox extends DialogSandbox {

    user$: Observable<UserResponseModel> = this.store.pipe(select(reducer.user_selector));
    role$: Observable<RoleResponseModel> = this.store.pipe(select(reducer.role_selector));
    roles$: Observable<RoleResponseModel[]> = this.store.pipe(select(reducer.roles_selector));

    constructor(appDialogsService: AppDialogsService,
                private userRestService: UserRestService,
                private roleRestService: RoleRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(id: number, type: TreeItemTypeEnum) {

        if (type === TreeItemTypeEnum.ADD_NEW_ROLE) {
            this.store.dispatch(new actions.SetRole({
                description: "",
                active: false,
                useComplexPassword: false,
                useMfa: false,
                useLoginLocations: false,
                roleId: null
            }));
        } else if (type === TreeItemTypeEnum.ROLE) {
            this.roleRestService.getById(id)
                .subscribe((role: RoleResponseModel) => {
                    this.store.dispatch(new actions.SetRole(role));
                });
        } else if (type === TreeItemTypeEnum.ADD_NEW_USER) {
            this.roleRestService.getAll()
                .subscribe((roles: RolesResponseModel) => {
                    this.store.dispatch(new actions.SetUser({
                        userId: null,
                        roleId: id,
                        locationId: null,
                        active: false,
                        username: "",
                        password: "",
                        displayName: "",
                        email: "",
                        isLocked: false,
                        failedLoginAttemptsCount: 0,
                        passwordLastChange: DateTimeUtility.convertMomentToDateTimeDescriptor(moment()),
                        isMfaActivated: false
                    }));
                    this.store.dispatch(new actions.SetRoles(roles.roles));
                });
        } else if (type === TreeItemTypeEnum.USER) {
            observableCombineLatest(this.userRestService.getById(id), this.roleRestService.getAll()).pipe(
                take(1))
                .subscribe(([user, roles]: [UserResponseModel, RolesResponseModel]) => {
                    this.store.dispatch(new actions.SetUser(user));
                    this.store.dispatch(new actions.SetRoles(roles.roles));
                });
        }
    }

    createRole(data: SubmitRoleData): Observable<CreateBaseResponseModel> {

        return this.roleRestService.create({
            description: data.description,
            useComplexPassword: data.useComplexPassword,
            useMfa: data.useMfa,
            useLoginLocations: data.useLoginLocations
        }).pipe(
        catchError((error: HttpErrorModel) => {
            return this.rootSandbox.handleHttpError('Error while creating role', error);
        }));
    }

    updateRole(data: SubmitRoleData): Observable<any> {
        return this.roleRestService.updateRoleBasicInfo({
            roleId: data.roleId,
            description: data.description,
            useComplexPassword: data.useComplexPassword,
            useMfa: data.useMfa,
            useLoginLocations: data.useLoginLocations
        }).pipe(
        catchError((error: HttpErrorModel) => {
            return this.rootSandbox.handleHttpError('Error while updating role', error);
        }));
    }

    createUser(data: SubmitUserData): Observable<CreateBaseResponseModel> {

        return this.userRestService.create({
            roleId: data.roleId,
            displayName: data.displayName,
            username: data.username,
            password: data.password,
            email: data.email
        }).pipe(
        catchError((error: HttpErrorModel) => {
            return this.rootSandbox.handleHttpError('Error while creating user', error);
        }));
    }

    updateUser(data: SubmitUserData): Observable<any> {

        return this.userRestService.updateUserBasicInfo({
            userId: data.userId,
            roleId: data.roleId,
            displayName: data.displayName,
            username: data.username,
            password: data.password,
            email: data.email,
            isLocked: data.isLocked
        }).pipe(
        catchError((error: HttpErrorModel) => {
            return this.rootSandbox.handleHttpError('Error while updating user', error);
        }));
    }
}
