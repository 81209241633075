import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {CategoryResponseModel} from "../../../../../../../../../core/modules/rest/category/response/category-response.model";
import {FormControl, FormGroup} from "@angular/forms";

export interface SubmitCategoryBasicInfoData {
    categoryId: number;
    description: string;
}

@Component({
    selector: 'app-edit-category-basic-info-dialog-form',
    templateUrl: './edit-category-basic-info-dialog-form.component.html'
})

export class EditCategoryBasicInfoDialogFormComponent implements OnChanges {

    @Input() category: CategoryResponseModel;

    @Output() submit: EventEmitter<SubmitCategoryBasicInfoData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    constructor() {

        this.form = new FormGroup({
            description: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.category) {
            this.initializeFormData();
        }
    }

    onSubmit() {

        let description: string = this.form.value.description;

        if (!description) {
            this.hasError = true;
            this.errorMessage = "Please enter category name";
            return;
        }

        this.submit.emit({
            categoryId: this.category.categoryId,
            description: description
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    private initializeFormData(): void {
        this.form.patchValue({
            description: this.category.description
        });
    }
}
