import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {OrderSummaryResponseModel} from '../../../../../../core/modules/rest/order-summary/response/order-summary-response.model';
import {UserResponseModel} from "../../../../../../core/modules/rest/user/response/user-response.model";
import {DropdownOptionModel} from "../../../../../components/form-elements/dropdown/dropdown-option.model";
import {CustomFieldSummaryResponseModel} from "../../../../../../core/modules/rest/order-summary/response/custom-field-summary-response.model";
import {LocationResponseModel} from "../../../../../../core/modules/rest/location/response/location-response.model";
import { OrderStatusEnum } from '../../../../../enums/order-status.enum';

export interface EditOrderEventDataModel {
    soldById: number;
    soldAtLocationId: number;
    passengerName: string;
    email: string;
    phoneNumber: string;
    customFields: CustomFieldsEventDataModel[];
}

export interface CustomFieldsEventDataModel {
    templateItemId: number;
    partyMember: number;
    fieldValue: string;
}

export interface CustomFieldDataModel {
    templateItemId: number;
    templateItemDescription: string;
    partyMember: number;
    fieldValue: string;
}


@Component({
    selector: 'app-general-info-dialog-summary',
    templateUrl: './general-info-dialog-summary.component.html'
})
export class GeneralInfoDialogSummaryComponent implements OnChanges {

    @Input() soldByUsers: UserResponseModel[];
    @Input() soldAtLocations: LocationResponseModel[];
    @Input() orderSummary: OrderSummaryResponseModel;
    @Input() hasEditPermission: boolean = false;
    @Input() hasCompletedEditPermission: boolean = false;

    @Output() orderDataEdit: EventEmitter<EditOrderEventDataModel> = new EventEmitter();

    editMode: boolean = false;
    saveEnabled: boolean = false;

    email: string = null;
    soldById: number = null;
    soldAtLocationId: number = null;
    phoneNumber: string = null;
    passengerName: string = null;
    customFields: CustomFieldDataModel[] = [];

    soldByOptions: DropdownOptionModel[];
    soldAtLocationOptions: DropdownOptionModel[];
    indexOfSoldBy: number = -1;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.orderSummary && Object.keys(changes.orderSummary.currentValue).length !== 0) {

            this.email = this.orderSummary.email;
            this.soldById = this.orderSummary.soldById;
            this.soldAtLocationId = this.orderSummary.soldAtLocationId;
            this.phoneNumber = this.orderSummary.phone;
            this.passengerName = (this.orderSummary.firstName ? this.orderSummary.firstName : "") + (this.orderSummary.lastName ? (this.orderSummary.firstName ? " " : "") + this.orderSummary.lastName : "");
          
            if(this.soldById != null){
                for (let i = 0; i < this.soldByUsers.length; i++) {
                    let user: UserResponseModel = this.soldByUsers[i];
                    let x: number = user.userId;
                    let y: number = this.soldById;
                    if(x == y){
                        this.indexOfSoldBy = i;
                        break
                    }
                  }
               
            }
            
            this.customFields = this.orderSummary.orderFields.map(of => {
                return {
                    templateItemId: of.templateItemId,
                    templateItemDescription: of.templateItemDescription,
                    fieldValue: of.fieldValue,
                    partyMember: of.partyMember
                };
            });

            if (this.orderSummary.orderStatus !== OrderStatusEnum.COMPLETE && this.hasCompletedEditPermission) {
                this.hasCompletedEditPermission = false;
            }

            if (this.orderSummary.orderStatus === OrderStatusEnum.COMPLETE && this.hasEditPermission) {
                this.hasEditPermission = false;
            }
        }

        if (changes.soldByUsers) {
            this.soldByOptions = this.soldByUsers.map(u => new DropdownOptionModel(u.displayName, u.userId.toString()));
        }

        if (changes.soldAtLocations) {
            this.soldAtLocationOptions = this.soldAtLocations.map(l => new DropdownOptionModel(l.description, l.locationId.toString()));
        }
    }

    isEditEnabled() {
        return !this.hasEditPermission && !this.hasCompletedEditPermission
    }

    onSoldBySelected(id: string) {
        this.soldById = Number(id);
        this.onDataEdit();
    }

    onSoldAtLocationSelected(id: string) {
        this.soldAtLocationId = Number(id);
        this.onDataEdit();
    }

    onEditClick() {
        this.editMode = !this.editMode;
    }

    onSaveClick() {
        this.orderDataEdit.emit({
            soldById: this.soldById ? this.soldById : null,
            soldAtLocationId: this.soldAtLocationId ? this.soldAtLocationId : null,
            passengerName: this.passengerName ? this.passengerName.trim() : null,
            email: this.email ? this.email.trim() : null,
            phoneNumber: this.phoneNumber ? this.phoneNumber.trim() : null,
            customFields: this.customFields
        });

        this.editMode = false;
    }

    onCancelClick() {
        this.editMode = false;
    }

    onDataEdit(): void {
        this.saveEnabled = this.generalInfoHasChanges();
    }

    private generalInfoHasChanges(): boolean {

        if (this.orderSummary) {

            if (this.email !== this.orderSummary.email) {
                return true;
            }

            if (this.soldById !== this.orderSummary.soldById && this.soldById > 0) {
                return true;
            }

            if (this.soldAtLocationId !== this.orderSummary.soldAtLocationId) {
                return true;
            }

            if (this.phoneNumber !== this.orderSummary.phone) {
                return true;
            }

            if (this.passengerName !== this.orderSummary.lastName) {
                return true;
            }

            let customFieldHaveChanges: boolean = false;

            this.customFields.forEach(cf => {

                let orderField: CustomFieldSummaryResponseModel = this.orderSummary.orderFields.find(of => cf.partyMember === of.partyMember && cf.templateItemId === of.templateItemId);

                if (orderField.fieldValue !== cf.fieldValue) {
                    customFieldHaveChanges = true;
                    return;
                }
            });

            return customFieldHaveChanges;
        }

        return false;
    }

}
