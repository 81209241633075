<div class="form_container">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? 'Update' : 'Create' }} favorite</h1>
            </div>
        </div>
    </div>

    <!--Dialog Content :: BEGIN -->
    <div class="content_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12 pt-8px">

                <app-input-label-group>
                    <label class="label">Name</label>
                    <input type="text"
                           placeholder="Name"
                           class="main--element input-large"
                           [(ngModel)]="favoriteDescriptionValue"
                           (keyup)="onFavoriteInputKeyUp()">
                    <span class="message" *ngIf="!descriptionAvailable">
                        Name already in use
                    </span>
                </app-input-label-group>
            </div>

            <div class="cell medium-12 pt-8px" *ngIf="checkIsOrderReportPage()">

                <app-input-label-group>
                    <label class="label">Group name</label>
                    <input type="text"
                           placeholder="Group name"
                           class="main--element input-large"
                           [(ngModel)]="groupDescriptionValue">
                </app-input-label-group>

            </div>
        </div>
    </div><!--Dialog Content :: END-->

    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">

            <div class="cell medium-6">
                <div class="button_wrapper center">
                    <app-button
                        [buttonType]='"secondary"'
                        (clickEvent)="onCancelClick()">
                        Cancel
                    </app-button>
                </div>
            </div>

            <div class="cell medium-6">
                <div class="button_wrapper center">
                    <app-button
                        [buttonType]='"primary"'
                        [disabled]='favoriteDescriptionValue.trim().length === 0 || !descriptionAvailable'
                        (clickEvent)="onSubmitClick()">
                        Save
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</div>
