import {NgModule} from "@angular/core";
import {EditPassCostsDialogSandbox} from "./sandbox/edit-pass-costs-dialog.sandbox";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {EditPassCostsDialogFormComponent} from './components/edit-pass-costs-dialog-form/edit-pass-costs-dialog-form.component';
import {EditPassCostsDialogComponent} from "./edit-pass-costs-dialog.component";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    declarations: [
        EditPassCostsDialogComponent,
        EditPassCostsDialogFormComponent
    ],
    providers: [
        EditPassCostsDialogSandbox
    ],
    exports: [
        EditPassCostsDialogComponent
    ],
    entryComponents: [
        EditPassCostsDialogComponent
    ]
})
export class EditPassCostsDialogModule {
}
