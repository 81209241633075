
import { catchError } from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DialogSandbox } from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import { AppDialogsService } from "../../../../../../../../core/services/app-dialogs.service";
import { RootSandbox } from "../../../../../../../../core/store/root.sandbox";
import { AndroidDeviceInfoRestService } from "../../../../../../../../core/modules/rest/android-device-info/android-device-info-rest.service";
import { Store } from "@ngrx/store";
import { HttpErrorModel } from "../../../../../../../../core/modules/rest/http-error.model";
import { CreateAndroidDeviceInfoRemoteCommandRequestModel } from "../../../../../../../../core/modules/rest/android-device-info/request/create-android-device-info-remote-command-request.model";
import { RemoteCommandEnum } from "../../../../../../../../shared/enums/remote-command.enum";
import { CreateBaseResponseModel } from "../../../../../../../../core/modules/rest/create-base-response.model";
import { MerchantRestService } from '../../../../../../../../core/modules/rest/merchants/merchants-rest.service';
import { ConnectMerchantDialogFormSubmitDataModel } from '../model/connect-merchant-account-dialog-form-submit-data.model';

@Injectable()
export class AddAndroidDeviceInfoRemoteCommandDialogSandbox extends DialogSandbox {

    constructor(appDialogsService: AppDialogsService,
        private store: Store<reducer.State>,
        private rootSandbox: RootSandbox,
        private androidDeviceInfoRestService: AndroidDeviceInfoRestService,
        private merchantRestService: MerchantRestService) {

        super(appDialogsService);
    }


    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    createAndroidDeviceInfoRemoteCommand(androidDeviceInfoId: number, remoteCommand: RemoteCommandEnum, timeoutHours: number): Observable<CreateBaseResponseModel> {

        const data: CreateAndroidDeviceInfoRemoteCommandRequestModel = new CreateAndroidDeviceInfoRemoteCommandRequestModel(androidDeviceInfoId, remoteCommand, timeoutHours);

        return this.androidDeviceInfoRestService.createAndroidDeviceInfoRemoteCommand(data).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating android device remote command', error);
            }));
    }

    loadMerchants() {
        return this.merchantRestService.getAllActive().pipe(catchError((error: HttpErrorModel) => {
            return this.rootSandbox.handleHttpError('Error while getting merchants', error);
        }));
    }

    loadAndroidDevices() {
        return this.androidDeviceInfoRestService.getAll().pipe(catchError((error: HttpErrorModel) => {
            return this.rootSandbox.handleHttpError('Error while getting adroid devices', error);
        }));
    }

    connectAndroidDevicesToMerchantAccount(data: ConnectMerchantDialogFormSubmitDataModel) {
        return this.merchantRestService.addAndroidDevicesToMerchantsAccount(data).pipe(catchError((error: HttpErrorModel) => {
            return this.rootSandbox.handleHttpError('Error while connection merchant account to android devices', error);
        }));
    }
}
