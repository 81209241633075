import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, forwardRef, ChangeDetectorRef  } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';


@Component({
    selector: 'app-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SwitchComponent),
            multi: true,
        }
    ]
})
export class SwitchComponent implements OnInit, ControlValueAccessor {

    @Input() switchLabel = "";
    @Input() value = false;
    @Input() disabled: boolean = false;

    @Output() switchValueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter();

    switchIdName = this.generateSwitchName();

    private registerOnChangeFunc: (value: boolean) => void;

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.switchIdName = this.generateSwitchName();
    }

    switchCheckedChanged(event) {

        // Component re-rendering when store updates value
        // Value updated in store re-creates component
        setTimeout(() => {
            this.value = !this.value;
            this.valueChange.emit(this.value);
            this.switchValueChanged.emit(this.value);

            if (this.registerOnChangeFunc !== undefined) {
                this.registerOnChangeFunc(this.value);
            }
        }, 300);
    }

    onClick(event) {
        event.stopPropagation();
    }

    // ------------------------------------
    // ControlValueAccessor members : BEGIN
    // ------------------------------------

    public writeValue(value: boolean) {

        if (value === null || value === undefined) {
            return;
        }

        this.value = value;

        if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
        }
    }

    public registerOnChange(fn: (value: boolean) => {}) {
        this.registerOnChangeFunc = fn;
    }

    public registerOnTouched() {
    }

    // ------------------------------------
    // ControlValueAccessor members : END
    // ------------------------------------

    private generateSwitchName() {
        return 'switch_' + Math.floor(Math.random() * 1001);
    }
}
