import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {Observable} from "rxjs";
import {CompanyInfoResponseModel} from "../../../../../../../../core/modules/rest/company-info/response/company-info-response.model";
import {CompanyInfoFormSubmitDataModel} from "../model/company-info-form-submit-data.model";
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {EditCompanyInfoDialogSandbox} from "../sandbox/edit-company-info-dialog.sandbox";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditCompanyInfoDialogData extends DialogData {

    companyInfoId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, companyInfoId?: number) {
        super();
        this.companyInfoId = companyInfoId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
// Component that represents it
// -------------------------------------------------------------------------------------------------

@Component({
  selector: 'app-edit-company-info-dialog',
  templateUrl: './edit-company-info-dialog.component.html'
})
export class EditCompanyInfoDialogComponent implements OnInit, OnDestroy {

    companyInfoId: number;
    companyInfo$: Observable<CompanyInfoResponseModel>;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditCompanyInfoDialogData, private sandbox: EditCompanyInfoDialogSandbox) {
        this.companyInfoId = dialogData.companyInfoId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
        if (this.companyInfoId && !isNaN(this.companyInfoId)) {
            this.editMode = true;
            this.sandbox.loadCompanyInfo(this.companyInfoId);
            this.companyInfo$ = this.sandbox.companyInfo$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(companyInfoFormData: CompanyInfoFormSubmitDataModel) {

        if (this.editMode) {
            this.sandbox.updateCompanyInfo(this.companyInfoId, companyInfoFormData).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createCompanyInfo(companyInfoFormData).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
