import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AutoAppliedDiscountCostModel} from "../../model/auto-applied-discount-cost.model";
import {MultiselectDropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";
import {
    USE_AMOUNT_AS_DISCOUNT_PER_PERSON_PRINT_STRING,
    USE_AMOUNT_AS_TOTAL_PER_ORDER_DETAIL_OR_PASS_ORDER_DETAIL_PRINT_STRING,
    USE_AMOUNT_AS_TOTAL_PER_PERSON_PRINT_STRING
} from "../../../../costs.component";

import _ from 'lodash';
import {DiscountApplyTypeEnum} from "../../../../../../../../../shared/enums/discount-apply-type.enum";
import {AmountTypeEnum} from "../../../../../../../../../shared/enums/amount-type.enum";

@Component({
    selector: 'app-edit-auto-applied-discount-dialog-content',
    templateUrl: './edit-auto-applied-discount-dialog-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditAutoAppliedDiscountDialogContentComponent {

    @Input('autoAppliedDiscount')
    set setAutoAppliedDiscount(autoAppliedDiscount: AutoAppliedDiscountCostModel) {
        this.autoAppliedDiscount = _.cloneDeep(autoAppliedDiscount);
    }

    @Input() allLocations: MultiselectDropdownOptionModel[];

    @Output() autoAppliedDiscountChangedEvent: EventEmitter<AutoAppliedDiscountCostModel> = new EventEmitter();

    autoAppliedDiscount: AutoAppliedDiscountCostModel;

    discountApplyTypes: MultiselectDropdownOptionModel[] = [
        new MultiselectDropdownOptionModel(USE_AMOUNT_AS_DISCOUNT_PER_PERSON_PRINT_STRING, DiscountApplyTypeEnum.USE_AMOUNT_AS_DISCOUNT_PER_PERSON),
        new MultiselectDropdownOptionModel(USE_AMOUNT_AS_TOTAL_PER_PERSON_PRINT_STRING, DiscountApplyTypeEnum.USE_AMOUNT_AS_TOTAL_PER_PERSON),
        new MultiselectDropdownOptionModel(USE_AMOUNT_AS_TOTAL_PER_ORDER_DETAIL_OR_PASS_ORDER_DETAIL_PRINT_STRING, DiscountApplyTypeEnum.USE_AMOUNT_AS_TOTAL_PER_ORDER_DETAIL_OR_PASS_ORDER_DETAIL)
    ];

    amountTypes: MultiselectDropdownOptionModel[] = [
        new MultiselectDropdownOptionModel('Percent', AmountTypeEnum.PERCENT),
        new MultiselectDropdownOptionModel('Dollars', AmountTypeEnum.DOLLARS)
    ];

    constructor() {
    }

    onDiscountApplyTypeChanged(type: string) {

        this.autoAppliedDiscount.discountApplyType = <DiscountApplyTypeEnum>type;

        if (this.autoAppliedDiscount.discountApplyType === DiscountApplyTypeEnum.USE_AMOUNT_AS_TOTAL_PER_PERSON || this.autoAppliedDiscount.discountApplyType === DiscountApplyTypeEnum.USE_AMOUNT_AS_TOTAL_PER_ORDER_DETAIL_OR_PASS_ORDER_DETAIL) {

            this.autoAppliedDiscount.amountType = AmountTypeEnum.DOLLARS;

            this.amountTypes = [
                new MultiselectDropdownOptionModel('Dollars', AmountTypeEnum.DOLLARS)
            ];
        } else {

            this.amountTypes = [
                new MultiselectDropdownOptionModel('Percent', AmountTypeEnum.PERCENT),
                new MultiselectDropdownOptionModel('Dollars', AmountTypeEnum.DOLLARS)
            ];
        }

        this.autoAppliedDiscountChangedEvent.emit(this.autoAppliedDiscount);
    }

    onInputChanged() {
        this.autoAppliedDiscountChangedEvent.emit(this.autoAppliedDiscount);
    }

    onAmountTypeChanged(value: AmountTypeEnum) {
        this.autoAppliedDiscount.amountType = value;
        this.autoAppliedDiscountChangedEvent.emit(this.autoAppliedDiscount);
    }

    onAllLocationChanged(value: boolean) {
        this.autoAppliedDiscount.allLocations = value;
        this.autoAppliedDiscountChangedEvent.emit(this.autoAppliedDiscount);
    }

    onSelectedLocationsChanged(values: string[]) {
        this.autoAppliedDiscount.locationIds = values.map((value: string) => +value);
        this.autoAppliedDiscountChangedEvent.emit(this.autoAppliedDiscount);
    }
}
