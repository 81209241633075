import { NgModule } from "@angular/core";
import { AddSaleGroupDomainDialogComponent } from "./container/add-sale-group-domain-dialog.component";
import { StoreModule } from "@ngrx/store";
import { AddSaleGroupItemsDialogSandbox } from "../sandbox/add-sale-group-items-dialog.sandbox";
import { REDUCER_NAME } from "../../store/reducers";
import { reducer } from "../../store/main/reducer";
import { SharedModule } from "../../../../../../../shared/shared.module";
import { AddSaleGroupDomainFormComponent } from "./components/add-sale-group-domain-form.component";



@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        AddSaleGroupItemsDialogSandbox
    ],
    declarations: [
        AddSaleGroupDomainDialogComponent,
        AddSaleGroupDomainFormComponent
    ],
    entryComponents: [
        AddSaleGroupDomainDialogComponent
    ],
    exports: [
        AddSaleGroupDomainDialogComponent
    ]
})
export class AddSaleGroupDomainDialogModule {

}
