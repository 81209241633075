<div class="dialog-section">
    <h2>Voiding Info</h2>

    <div class="placeholderText-emptySection"
        *ngIf="voidingInfos === null || voidingInfos === undefined || voidingInfos.length === 0">
        No Voiding Info to Display
    </div>

    <div class="summary-general-info-wrapper"
        *ngIf="!(voidingInfos === null || voidingInfos === undefined || voidingInfos.length === 0)">
        <table style="width:100%">
            <thead>
              <tr>
                  <th>User</th>
                  <th>Voiding Date/Time</th>
                  <th class="width-1">Void Reason</th>
                  <th>Void explanation</th>
                  <th class="width-1">Is Refunded</th>
              </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let voidingInfo of voidingInfos">
                  <tr>
                      <td>{{voidingInfo.voidedByDisplayName}}</td>
                      <td class="min-width-1">{{voidingInfo.voidingDateTimeFriendly}}</td>
                      <td>{{voidingInfo.voidReason}}</td>
                      <td>{{voidingInfo.voidExplanation}}</td>
                      <td>{{voidingInfo.isRefunded ? 'Yes' : 'No'}}</td>
                  </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
