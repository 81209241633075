import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {SubmitCategoryCostsData} from "./components/edit-category-costs-dialog-form/edit-category-costs-dialog-form.component";
import {EditCategoryCostsDialogSandbox} from "./sandbox/edit-category-costs-dialog.sandbox";
import {ProductCostResponseModel} from "../../../../../../../core/modules/rest/product/response/product-cost-response.model";
import {DropdownOptionModel} from "../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export class EditCategoryCostsDialogData extends DialogData {

    categoryId: number;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, categoryId: number) {
        super();
        this.categoryId = categoryId;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-edit-category-costs-dialog',
    templateUrl: './edit-category-costs-dialog.component.html'
})
export class EditCategoryCostsDialogComponent implements OnDestroy {

    categoryId: number;
    successCallbackFn: Function;

    loading$: Observable<boolean>;
    categoryAdditionalCosts$: Observable<ProductCostResponseModel[]>;
    additionalCosts$: Observable<DropdownOptionModel[]>;
    categoryIncludedCosts$: Observable<ProductCostResponseModel[]>;
    includedCosts$: Observable<DropdownOptionModel[]>;
    categoryDiscounts$: Observable<ProductCostResponseModel[]>;
    discounts$: Observable<DropdownOptionModel[]>;
    categoryAutoAppliedDiscounts$: Observable<ProductCostResponseModel[]>;
    autoAppliedDiscounts$: Observable<DropdownOptionModel[]>;

    constructor(@Inject(DialogData) data: EditCategoryCostsDialogData, private sandbox: EditCategoryCostsDialogSandbox) {

        this.categoryId = data.categoryId;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.categoryId);

        this.loading$ = this.sandbox.loading$;
        this.categoryAdditionalCosts$ = this.sandbox.categoryAdditionalCosts$;
        this.additionalCosts$ = this.sandbox.additionalCosts$;
        this.categoryIncludedCosts$ = this.sandbox.categoryIncludedCosts$;
        this.includedCosts$ = this.sandbox.includedCosts$;
        this.categoryDiscounts$ = this.sandbox.categoryDiscounts$;
        this.discounts$ = this.sandbox.discounts$;
        this.categoryAutoAppliedDiscounts$ = this.sandbox.categoryAutoAppliedDiscounts$;
        this.autoAppliedDiscounts$ = this.sandbox.autoAppliedDiscounts$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitCategoryCostsData) {
        this.sandbox.updateCategoryCosts(data).subscribe(() => {
            this.successCallbackFn(data.categoryId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
