import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {LocationListFullResponseModel} from "../../../../../../../../../core/modules/rest/location-list/response/location-list-full-response.model";
import {LocationListFormSubmitDataModel} from "../../model/location-list-form-submit-data.model";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {LocationListItemFormSubmitDataModel} from "../../model/location-list-item-form-submit-data.model";
import {LocationListItemDescriptorResponseModel} from "../../../../../../../../../core/modules/rest/location-list/response/location-list-item-descriptor-response.model";


@Component({
    selector: 'app-edit-location-list-form',
    templateUrl: './edit-location-list-form.component.html'
})
export class EditLocationListFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() locationList: LocationListFullResponseModel;
    @Input() allLocations: DropdownOptionModel[];

    @Output() submitEvent = new EventEmitter<LocationListFormSubmitDataModel>();
    @Output() closeEvent  = new EventEmitter<void>();

    allLocationsSelectedOption: string = null;
    hasAnyLocationInList: boolean = false;

    form: FormGroup;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.form && this.editMode && changes.locationList !== null && Object.keys(changes.locationList.currentValue).length !== 0) {

            // Patch form array
            let locationListItemsFormArray: FormArray = <FormArray>this.form.get('locationListItems');
            while (locationListItemsFormArray.length > 0) {
                locationListItemsFormArray.removeAt(0);
            }
            this.locationList.locationListItems.map((lli: LocationListItemDescriptorResponseModel) => {
                locationListItemsFormArray.push(new FormGroup({
                    locationId: new FormControl(lli.locationId),
                    locationDescription: new FormControl(lli.locationDescription),
                    offset: new FormControl(lli.offset),
                    isCustomLocation: new FormControl(lli.isCustomLocation),
                    firstItem: new FormControl(false),
                    lastItem: new FormControl(false),
                    locationActive: new FormControl(lli.locationActive)
                }));
            });

            // Set first and last item
            this.setFirstAndLastItem(locationListItemsFormArray);

            // Patch form
            this.form.patchValue({
                description: changes.locationList.currentValue.description
            });

            // Locations that are already added need to be removed from all locations
            this.locationList.locationListItems.forEach((lli) => {
                let index: number = this.allLocations.findIndex((l) => l.value === lli.locationId.toString());
                if (index > -1) {
                    let locations = [...this.allLocations.slice(0, index), ...this.allLocations.slice(index + 1)]
                    this.allLocations = locations;
                }
            });

            if (changes.locationList.currentValue.locationListItems && changes.locationList.currentValue.locationListItems.length > 0) {
                this.hasAnyLocationInList = true;
            } else {
                this.hasAnyLocationInList = false;
            }
        }
    }

    ngOnInit() {

        let description: string = "";

        if (this.editMode) {
            description = this.locationList.description;
        }

        this.form = new FormGroup({
            description: new FormControl(description),
            locationListItems: new FormArray([])
        });
    }

    getLocationListItemsFormControls() {
        return (<FormArray>this.form.get('locationListItems')).controls;
    }

    onLocationSelected(locationIdStr: string) {

        if (!locationIdStr) {
            return;
        }

        let index: number = this.allLocations.findIndex((l) => l.value === locationIdStr);

        // Add new location list item to form array
        let locationListItemsFormArray: FormArray = <FormArray>this.form.get('locationListItems');
        locationListItemsFormArray.push(new FormGroup({
            locationId: new FormControl(parseInt(locationIdStr, 10)),
            locationDescription: new FormControl(this.allLocations[index].label),
            offset: new FormControl(null),
            isCustomLocation: new FormControl(false),
            firstItem: new FormControl(false),
            lastItem: new FormControl(false),
            locationActive: new FormControl(true)
        }));

        // Set first and last item
        this.setFirstAndLastItem(locationListItemsFormArray);

        // Location list item that is being added needs to be removed from all locations
        let locations = [...this.allLocations.slice(0, index), ...this.allLocations.slice(index + 1)]
        this.allLocations = locations

        // Clear selected location in all locations and re-create all locations array
        this.allLocationsSelectedOption = null;
        this.allLocations = Array.from(this.allLocations);

        this.hasAnyLocationInList = true;
    }

    onLocationListItemUp(locationId: number) {

        let locationListItemsFormArray: FormArray = <FormArray>this.form.get('locationListItems');

        let indexSelected: number = locationListItemsFormArray.controls.findIndex((fg) => fg.value.locationId === locationId);
        let indexAffected: number = locationListItemsFormArray.controls.length > 1 && indexSelected > 0 ? indexSelected - 1 : null;

        if (indexAffected === null) {
            return;
        }

        // Switch form groups in form array
        let affectedLocationListItemFormGroup: AbstractControl = locationListItemsFormArray.controls.splice(indexAffected, 1)[0];
        locationListItemsFormArray.controls.splice(indexSelected, 0, affectedLocationListItemFormGroup);

        // Set first and last item
        this.setFirstAndLastItem(locationListItemsFormArray);
    }

    onLocationListItemDown(locationId: number) {

        let locationListItemsFormArray: FormArray = <FormArray>this.form.get('locationListItems');

        let indexSelected: number = locationListItemsFormArray.controls.findIndex((fg) => fg.value.locationId === locationId);
        let indexAffected: number = locationListItemsFormArray.controls.length > 1 && indexSelected < locationListItemsFormArray.controls.length - 1 ? indexSelected + 1 : null;

        if (indexAffected === null) {
            return;
        }

        // Switch form groups in form array
        let affectedLocationListItemFormGroup: AbstractControl = locationListItemsFormArray.controls.splice(indexAffected, 1)[0];
        locationListItemsFormArray.controls.splice(indexSelected, 0, affectedLocationListItemFormGroup);

        // Set first and last item
        this.setFirstAndLastItem(locationListItemsFormArray);
    }

    onLocationListItemRemoved(locationIdAndLocationDescription: [number, string]) {

        let locationListItemsFormArray: FormArray = <FormArray>this.form.get('locationListItems');

        let indexSelected: number = locationListItemsFormArray.controls.findIndex((fg) => fg.value.locationId === locationIdAndLocationDescription[0]);

        // Remove form group
        locationListItemsFormArray.controls.splice(indexSelected, 1);

        // Set first and last item
        this.setFirstAndLastItem(locationListItemsFormArray);

        // Location list item that is being removed needs to be added to all locations
        this.allLocations.push(new DropdownOptionModel(locationIdAndLocationDescription[1], locationIdAndLocationDescription[0].toString()));

        // Clear selected location in all locations and re-create all locations array
        this.allLocationsSelectedOption = null;
        this.allLocations = Array.from(this.allLocations);

        if (locationListItemsFormArray.controls.length === 0) {
            this.hasAnyLocationInList = false;
        }
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        const description: string = this.form.value.description;
        const locationListItems: LocationListItemFormSubmitDataModel[] = [];

        for (let control of (<FormArray>this.form.get('locationListItems')).controls) {

            let locationId: number = +control.value.locationId;
            let offset: number = +control.value.offset;
            let isCustomLocation: boolean = control.value.isCustomLocation;

            locationListItems.push({
                locationId: locationId,
                offset: offset,
                isCustomLocation: isCustomLocation
            });
        }

        this.submitEvent.emit({
            description: description,
            locationListItems: locationListItems
        });
    }

    private setFirstAndLastItem(locationListItemsFormArray: FormArray) {

        if (locationListItemsFormArray.controls.length > 0) {

            for (let i = 0; i < locationListItemsFormArray.controls.length; i++) {
                locationListItemsFormArray.controls[i].get("firstItem").setValue(false);
                locationListItemsFormArray.controls[i].get("lastItem").setValue(false);
            }

            locationListItemsFormArray.controls[0].get("firstItem").setValue(true);
            locationListItemsFormArray.controls[locationListItemsFormArray.controls.length - 1].get("lastItem").setValue(true);
        }
    }
}
