<form [formGroup]="locationListItemForm">
    <div class="formRow form-edit-location-list-item">
        <div class="grid-x align-middle align-center">
            <div class="cell location-grid-items small-1">
                <span (click)="onLocationListItemUp()"
                      [ngClass]="{'disabled': locationListItemForm.value.firstItem}"
                      class="icon_container mr-4px">
                <i class="fal fa-arrow-square-up"></i>
                </span>
                <span (click)="onLocationListItemDown()"
                      [ngClass]="{'disabled': locationListItemForm.value.lastItem}"
                      class="icon_container">
                    <i class="fal fa-arrow-square-down"></i>
                </span>
            </div>
            <div class="cell location-grid-items small-7">
                <label [ngClass]="{'deactiveWarning': !locationListItemForm.value.locationActive}" class="location-grid-items-label" title="{{locationListItemForm.value.locationDescription}}">{{locationListItemForm.value.locationDescription}} <span *ngIf="!locationListItemForm.value.locationActive">(Deactivated)</span></label>
            </div>
            <div class="cell location-grid-items small-2">
                <input [formControlName]="'offset'"
                       type="text"/>
            </div>
            <div class="cell location-grid-items center pl-16px small-1">
                <app-checkbox
                    [formControlName]="'isCustomLocation'"
                    [labelName]="''">
                </app-checkbox>
            </div>
            <div class="cell location-grid-items center small-1">
                <span (click)="onLocationListItemRemoved()"
                      class="closeButton">
                    <i class="fal fa-times"></i>
                </span>
            </div>
        </div>
    </div>
</form>
