import { Component, EventEmitter, Inject, Injectable, Input, Output } from '@angular/core';
import { RolesAndUsersSandbox } from '../../../modules/secured/management/system/roles-and-users/sandbox/roles-and-users.sandbox';
import { CategoriesAndProductsSandbox } from '../../../modules/secured/management/inventory/categories-and-products/sandbox/categories-and-products.sandbox';
import { PassesSandbox } from '../../../modules/secured/management/inventory/passes/sandbox/passes.sandbox';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { AppInjector } from '../../../app.module';
import { TreeTypeEnum } from '../../enums/tree-type.enum';
import { UserInfoResponseModel } from '../../../core/modules/rest/user/response/user-info-response.model';
import { SharedSandbox } from '../../shared.sandbox';
import { environment } from '../../../../environments/environment';
import { CustomerOwnersSandbox } from '../../../modules/secured/management/customers/customer-owners/sandbox/customer-owners.sandbox';

export interface TreeData {
    label: string;
    type: string;
    id: number;
    parentId: number;
}

export class TreeNode<T extends TreeData = TreeData> {

    data: T;
    children: TreeNode<T>[];

    private _open: boolean;
    private _visible: boolean;

    private _active: boolean;
    useLoginLocations: boolean;
;

    private _parent: TreeNode;

    constructor(data: T, children: TreeNode<T>[] = [], active: boolean = true, visible: boolean = true) {

        this.data = data;
        this.children = children;

        this._open = false;
        this._visible = visible;

        // this._selected = false;
        this._active = active;

        children.forEach(n => n._parent = this);
    }

    toggleOpen(open: boolean): void {

        this._visible = true;
        this._open = open;

        this._toggleVisibility(this.children);
    }

    get open(): boolean {
        return this._open;
    }

    set open(open: boolean) {
        this._open = open;
    }

    get visible(): boolean {
        return this._visible;
    }

    get active(): boolean {
        return this._active;
    }

    set active(active: boolean) {
        this._active = active;
    }

    private _toggleVisibility(nodes: TreeNode<T>[]): void {
        nodes.forEach(n => {
            n._visible = this._open;
        });
    }

    private _toggleParentOpen(open: boolean, node: TreeNode) {

        node.toggleOpen(true);

        if (!node._parent) {
            return;
        }

        this._toggleParentOpen(open, node._parent);
    }

}

enum TreeSelectionType {
    SINGLE_SELECT,
    MULTI_SELECT
}

@Component({
    selector: 'app-tree',
    templateUrl: './tree.component.html'
})
export class TreeComponent {

    @Input() selectionType: TreeSelectionType = TreeSelectionType.SINGLE_SELECT;
    @Input() showInactive: boolean = true;
    @Input() tree: TreeNode[];
    @Input() treeType: string;

    @Output() valueChanged: EventEmitter<TreeNode> = new EventEmitter();

    nodes: TreeNode<TreeData>[] = [];
    item: TreeNode;
    selectedNode: TreeNodeMaterial;
    selectedNodes: TreeNode[] = [];
    rolesAndUsersSandbox = AppInjector.get(RolesAndUsersSandbox);
    customerOwnersSandbox = AppInjector.get(CustomerOwnersSandbox);
    categoriesAndProductsSandbox = AppInjector.get(CategoriesAndProductsSandbox);
    passesSandbox = AppInjector.get(PassesSandbox);
    user: UserInfoResponseModel;
    isTreeRendered: boolean = false;

    private _transformer = (node: TreeNodeMaterial, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            id: node.id,
            parentId: node.parentId,
            name: node.name,
            type: node.type,
            active: node.active,
            level: level,
        };
    }

    constructor(private sharedSandbox: SharedSandbox) {
    }

    treeControl = new FlatTreeControl<FlatNodeMaterial>(
        node => node.level, node => node.expandable);


    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: FlatNodeMaterial) => node.expandable;

    onShowInactiveChanged(active: boolean): void {
        this.showInactive = active;
    }

    selectNode(node: TreeNodeMaterial) {
        this.findNode(node);

        let selectedNodes: TreeNode[] = [];
        this.getSelectedNodes(this.nodes, selectedNodes, node);

        if (this.selectionType === TreeSelectionType.SINGLE_SELECT) {
            this.valueChanged.emit(selectedNodes[0]);
        }
    }

    isNodeParent(node: TreeNode): boolean {
        return node.children.length !== 0;
    }

    isNodeVisible(node: TreeNode): boolean {

        if (!node.data.label) {
            return false;
        }

        if (node.active) {
            return node.visible;
        } else {
            return this.showInactive && node.visible;
        }
    }

    isLastChild(index: number, children: TreeNode[]): boolean {
        return index === children.length - 1;
    }

    toggleNodeOpen(node: TreeNode): void {
        node.toggleOpen(!node.open);
    }

    private getSelectedNodes(children: TreeNode[], selected: TreeNode[], node: TreeNodeMaterial) {
        if (node.parentId != null) {
            let filteredNode = children.filter(
                nodeLocal => nodeLocal.data.id === node.parentId);
            let filteredNodeChild = filteredNode[0].children.filter(
                nodeChildLocal => nodeChildLocal.data.id === node.id);
            selected.push(filteredNodeChild[0]);

        }
        else {
            let filteredNode = children.filter(
                nodeLocal => nodeLocal.data.id === node.id);
            selected.push(filteredNode[0]);
        }
    }

    findNode(node) {
        if (node.parentId != null) {
            let filteredNode = this.dataSource.data.filter(
                nodeLocal => nodeLocal.id === node.parentId);
            let filteredNodeChild = filteredNode[0].children.filter(
                nodeChildLocal => nodeChildLocal.id === node.id);
            this.selectedNode = filteredNodeChild[0]
            if (this.treeType == TreeTypeEnum.ROLES_AND_USERS_TREE) {
                this.rolesAndUsersSandbox.loadNode(this.selectedNode);
            }
            if (this.treeType == TreeTypeEnum.CATEGORIES_AND_PRODUCTS_TREE) {
                this.categoriesAndProductsSandbox.loadNode(this.selectedNode);
            }
            if (this.treeType == TreeTypeEnum.PASSES_TREE) {
                this.passesSandbox.loadNode(this.selectedNode);
            }
            if (this.treeType == TreeTypeEnum.CUSTOMER_OWNERS_TREE) {
                this.customerOwnersSandbox.loadNode(this.selectedNode);
            }
        }
        else {
            let filteredNode = this.dataSource.data.filter(
                nodeLocal => nodeLocal.id === node.id);
            this.selectedNode = filteredNode[0]
            if (this.treeType == TreeTypeEnum.ROLES_AND_USERS_TREE) {
                this.rolesAndUsersSandbox.loadNode(this.selectedNode);
            }
            if (this.treeType == TreeTypeEnum.CATEGORIES_AND_PRODUCTS_TREE) {
                this.categoriesAndProductsSandbox.loadNode(this.selectedNode);
            }
            if (this.treeType == TreeTypeEnum.PASSES_TREE) {
                this.passesSandbox.loadNode(this.selectedNode);
            }
            if (this.treeType == TreeTypeEnum.CUSTOMER_OWNERS_TREE) {
                this.customerOwnersSandbox.loadNode(this.selectedNode);
            }
        }
    }
    findElementPosition(data, item, type) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].id == item && data[i].type === type) {
                this.treeControl.expand(this.treeControl.dataNodes[i]);
            }
        }
    }

    ngOnInit() {
         this.sharedSandbox.getUserInfo().subscribe(res => {
             this.user = res
        });

        this.nodes = this.tree
        let parsedNodes = []
        for (let n in this.nodes) {
            let nodeChildren = this.nodes[n].children
            let parsedChildren = []
            for (let c in nodeChildren) {
                let active: boolean;
                !this.nodes[n].active ? active = this.nodes[n].active : active = nodeChildren[c].active;
                const children = {
                    id: nodeChildren[c].data.id,
                    parentId: nodeChildren[c].data.parentId,
                    name: nodeChildren[c].data.label,
                    type: nodeChildren[c].data.type,
                    active: active,
                    children: []
                }
                if (this.treeType === TreeTypeEnum.ROLES_AND_USERS_TREE) {
                    if (environment.adminUserId === this.user.userId)
                        parsedChildren.push(children);
                     else
                        if(environment.adminUserId !== children.id)
                            parsedChildren.push(children);
                }
                else {
                    parsedChildren.push(children);
                }
            }
            const node = {
                id: this.nodes[n].data.id,
                parentId: this.nodes[n].data.parentId,
                name: this.nodes[n].data.label,
                type: this.nodes[n].data.type,
                active: this.nodes[n].active,
                children: parsedChildren
            }
            parsedNodes.push(node);
        }

        this.dataSource.data = parsedNodes;

        if (this.treeType == TreeTypeEnum.ROLES_AND_USERS_TREE) {
            this.rolesAndUsersSandbox.item$.subscribe(item =>
                this.item = item
            );
        }
        if (this.treeType == TreeTypeEnum.CUSTOMER_OWNERS_TREE) {
            this.customerOwnersSandbox.item$.subscribe(item =>
                this.item = item
            );
        }
        if (this.treeType == TreeTypeEnum.CATEGORIES_AND_PRODUCTS_TREE) {
            this.categoriesAndProductsSandbox.item$.subscribe(item =>
                this.item = item
            );
        }
        if (this.treeType == TreeTypeEnum.PASSES_TREE) {
            this.passesSandbox.item$.subscribe(item =>
                this.item = item
            );
        }

        if (this.item.data) {
            const data = this.treeControl.dataNodes
            let filterItem = data.filter(
                item => item.id === this.item.data.id && item.type === this.item.data.type);
            if (filterItem[0].parentId) {
                let filterItemParent = data.filter(
                    item => item.id === filterItem[0].parentId && item.parentId === null);
                this.findElementPosition(data, filterItem[0].parentId, filterItemParent[0].type);
            }
            if (filterItem[0].id) {
                this.findElementPosition(data, filterItem[0].id, filterItem[0].type);
                this.findNode(filterItem[0]);
            }
        }
        this.isTreeRendered = true;
    }
}
