const DayOfWeekEnum = {
    SUNDAY: 'SUNDAY' as 'SUNDAY',
    MONDAY: 'MONDAY' as 'MONDAY',
    TUESDAY: 'TUESDAY' as 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY' as 'WEDNESDAY',
    THURSDAY: 'THURSDAY' as 'THURSDAY',
    FRIDAY: 'FRIDAY' as 'FRIDAY',
    SATURDAY: 'SATURDAY' as 'SATURDAY'
};
type DayOfWeekEnum = (typeof DayOfWeekEnum)[keyof typeof DayOfWeekEnum];
export {DayOfWeekEnum};
