import {DateTimeDescriptor} from "../../../../../../shared/model/date-time-descriptor.model";
import {CashCollectedPaymentMethodModel} from "./cash-collected-payment-method.model";
import {EndOfShiftReportOrderRevenueDescriptorModel} from "./end-of-shift-report-order-revenue-descriptor.model";

export class CashCollectedModel {

    startDateTime: DateTimeDescriptor;
    dateTimeCollected: DateTimeDescriptor;
    locationId: number;
    cashCollectedForUserId: number;
    cashCollectedForUserDisplayName: string;
    cashCollectedByUserId: number;
    reason: string;
    reconciled: boolean;
    shouldPrintAfterSubmit: boolean;
    cashCollectedPaymentMethods: CashCollectedPaymentMethodModel[];
    orderRevenueDescriptors: EndOfShiftReportOrderRevenueDescriptorModel[];
}
