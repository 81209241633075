import {DialogSandbox} from "../../../services/dialog.sandbox";
import {Injectable} from "@angular/core";
import {AppDialogsService} from "../../../../../core/services/app-dialogs.service";
import { Observable } from "rxjs";
import * as fromReducer from "./store/reducer";
import * as fromActions from './store/actions';
import { Store, select } from "@ngrx/store";

@Injectable()
export class ChangePasswordSandbox extends DialogSandbox {

    showLoader$: Observable<boolean> = this.store.pipe(select(fromReducer.showLoader_selector));

    constructor(appDialogsService: AppDialogsService, private store: Store<fromReducer.State>) {
        super(appDialogsService);
    }

    setShowLoader(loader: boolean) {
        this.store.dispatch(new fromActions.SetChangePasswordLoader(loader));
    }

    resetState() {
        this.store.dispatch(new fromActions.SetChangePasswordLoader(false));
    }
}
