<div class="page-wrapper commission-report-page">

    <div class="header_wrapper">
        <app-header [pageBreadcrumbItems]="pageBreadcrumbItems">
        </app-header>
    </div>

    <app-commission-report-content
        [pageBreadcrumbItems]="pageBreadcrumbItems"
        [favorites]="favorites$ | async"
        [loadingFavorites]="loadingFavorites$ | async"
        [selectedFavoriteId]="selectedFavoriteId$ | async"
        [favoriteCreatedUpdated]="favoriteCreatedUpdated$ | async"
        [hasUseFavoritesPermission]="hasUseFavoritesPermission$ | async"
        [hasCreateFavoritePermission]="hasCreateFavoritePermission$ | async"
        [hasEditFavoritePermission]="hasEditFavoritePermission$ | async"
        [hasRemoveFavoritePermission]="hasRemoveFavoritePermission$ | async"
        [roles]="roles$ | async"
        [loadingRoles]="loadingRoles$ | async"
        [usersForSelectedRoles]="usersForSelectedRoles$ | async"
        [loadingUsers]="loadingUsers$ | async"
        [passes]="passes$ | async"
        [loadingPasses]="loadingPasses$ | async"
        [products]="products$ | async"
        [loadingProducts]="loadingProducts$ | async"
        [selectedStartDate]="selectedStartDate$ | async"
        [selectedEndDate]="selectedEndDate$ | async"
        [selectedRoleIds]="selectedRoleIds$ | async"
        [selectedUserIds]="selectedUserIds$ | async"
        [selectedPassIds]="selectedPassIds$ | async"
        [selectedProductIds]="selectedProductIds$ | async"
        [currentSearchData]="currentSearchData$ | async"
        [currentSearchDataSortBy]="currentSearchDataSortBy$ | async"
        (filterChangedEvent)="onFilterChanged($event)"
        (clearSearchDataEvent)="onClearSearchData()"
        (runSearchEvent)="onRunSearch()"
        (generateUrlForExportToXlsCommissionReportEvent)="onGenerateUrlForExportToXlsCommissionReport()"
        (headerSortEvent)="onHeaderSort($event)"
        (selectedFavoriteChangedEvent)="onSelectedFavoriteChanged($event)"
        (editFavoriteEvent)="onEditFavorite($event)"
        (removeFavoriteEvent)="onRemoveFavorite($event)">
    </app-commission-report-content>

</div>
