import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DialogData} from "../../../model/dialog.data";
import {Sandbox} from "../sandbox/sandbox";


// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class OkDialogData extends DialogData {

    header: string;
    message: string;
    okLabel: string;
    buttonType: string;

    callbackFn: () => void;

    constructor(header: string, message: string, okLabel: string = 'Ok', buttonType: string = 'button ghost-button', callbackFn: () => void) {
        super();
        this.header = header;
        this.message = message;
        this.okLabel = okLabel;
        this.buttonType = buttonType;
        this.callbackFn = callbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
// Component that represents it
// -------------------------------------------------------------------------------------------------
@Component({
    selector: 'app-ok-dialog',
    templateUrl: './ok-dialog.component.html'
})
export class OkDialogComponent {

    header: string;
    message: string;
    okLabel: string;
    buttonType: string;

    // Function that gets called once ok button has been clicked
    callbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: OkDialogData, private sandbox: Sandbox) {

        this.header = dialogData.header;
        this.message = dialogData.message;
        this.okLabel = dialogData.okLabel;
        this.buttonType = dialogData.buttonType;
        this.callbackFn = dialogData.callbackFn;
    }

    onCloseClicked() {
        this.callbackFn();
        this.sandbox.destroyDialog();
    }
}
