import {Action} from "@ngrx/store";
import {KioskDeviceInfoResponseModel} from "../../../../../../../../../core/modules/rest/kiosk-device-info/response/kiosk-device-info-response.model";
import {KioskConfigurationResponseModel} from "../../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-response.model";
import {UserResponseModel} from "../../../../../../../../../core/modules/rest/user/response/user-response.model";
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";


export const RESET_STATE_ACTION                       = '[Dialog - Create/Edit Kiosk Device Info :: MAIN] Reset State';
export const SET_KIOSK_DEVICE_INFO                    = '[Dialog - Create/Edit Kiosk Device Info :: MAIN] Set Kiosk Device Info';
export const SET_KIOSK_CONFIGURATIONS                 = '[Dialog - Create/Edit Kiosk Device Info :: MAIN] Set Kiosk Configurations';
export const SET_USERS                                = '[Dialog - Create/Edit Kiosk Device Info :: MAIN] Set Users';
export const SET_TERMS_AND_CONDITIONS_HTML_TEMPLATES  = '[Dialog - Create/Edit Kiosk Device Info :: MAIN] Set T&C Email Templates';

export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class SetKioskDeviceInfo implements Action {
    readonly type = SET_KIOSK_DEVICE_INFO;

    constructor(public kioskDeviceInfo: KioskDeviceInfoResponseModel) {
    }
}

export class SetKioskConfigurations implements Action {
    readonly type = SET_KIOSK_CONFIGURATIONS;

    constructor(public kioskConfigurations: KioskConfigurationResponseModel[]) {
    }
}

export class SetUsers implements Action {
    readonly type = SET_USERS;

    constructor(public users: UserResponseModel[]) {
    }
}

export class SetTermsAndConditionsTemplates implements Action {
    readonly type = SET_TERMS_AND_CONDITIONS_HTML_TEMPLATES;

    constructor(public termsAndConditionsTemplates: EmailHtmlTemplateResponseModel[]) {
    }
}

export type All = ResetState | SetKioskDeviceInfo | SetKioskConfigurations | SetUsers | SetTermsAndConditionsTemplates;
