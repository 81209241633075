import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-commission-report-table-caption',
    templateUrl: './commission-report-table-caption.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommissionReportTableCaptionComponent implements OnInit {

    @Input() displayMessage: string;
    @Output() generateUrlForExportToXlsCommissionReportEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit() {
    }

    exportToExcelClick() {
        this.generateUrlForExportToXlsCommissionReportEvent.emit();
    }
}
