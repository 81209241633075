import { Injectable } from "@angular/core";
import { jwtDecode } from "jwt-decode";
@Injectable()
export class LocalStorageService {

    private JWT_ACCESS_TOKEN = 'JWT_ACCESS_TOKEN';
    private JWT_REFRESH_TOKEN = 'JWT_REFRESH_TOKEN';

    setAccessToken(value) {
        localStorage.setItem(this.JWT_ACCESS_TOKEN, value);
    }

    getAccessToken() {
        return localStorage.getItem(this.JWT_ACCESS_TOKEN);
    }

    setRefreshToken(value) {
        localStorage.setItem(this.JWT_REFRESH_TOKEN, value);
    }

    getRefreshToken() {
        return localStorage.getItem(this.JWT_REFRESH_TOKEN);
    }

    getDecodedToken() {
        const token = this.getAccessToken();
        return token ? jwtDecode(token) : null;
    }

    getUserIdFromToken(): number | null {
        const decodedToken = this.getDecodedToken();
        return decodedToken ? decodedToken['userId'] : null;
    }
}
