import {RouterModule, Routes} from '@angular/router';
import {OrderReportsComponent} from './order-reports.component';
import {NgModule} from '@angular/core';

const routes: Routes = [
    {path: '', component: OrderReportsComponent}
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule,
    ]
})
export class OrderReportsRoutes {
}
