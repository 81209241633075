import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";

@Injectable()
export class BarcodeCheckInRestService {

    constructor(private http: HttpClient) {
    }

    doCheckIn(ticketId: number): Observable<boolean> {
        return this.http.post<boolean>(environment.server_api_url + '/api/secured/barcodeCheckIn/doCheckIn', ticketId);
    }

    doBulkCheckIn(ticketIds: number[]): Observable<boolean> {
        return this.http.post<boolean>(environment.server_api_url + '/api/secured/barcodeCheckIn/doBulkCheckIn', ticketIds);
    }

    undoCheckIn(ticketId: number): Observable<boolean> {
        return this.http.post<boolean>(environment.server_api_url + '/api/secured/barcodeCheckIn/undoCheckIn', ticketId);
    }

    doNoShow(ticketId: number): Observable<boolean> {
        return this.http.post<boolean>(environment.server_api_url + '/api/secured/barcodeCheckIn/doNoShow', ticketId);
    }

    undoNoShow(ticketId: number): Observable<boolean> {
        return this.http.post<boolean>(environment.server_api_url + '/api/secured/barcodeCheckIn/undoNoShow', ticketId);
    }

    doNoShowOrderDetail(orderDetailId: number): Observable<boolean> {
        return this.http.post<boolean>(environment.server_api_url + '/api/secured/barcodeCheckIn/doNoShowOrderDetail', orderDetailId);
    }
}
