import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {
    EmailHtmlTemplateTypeEnum,
    getDescription as EmailHtmlTemplateTypeEnum_getDescription
} from "../../../../../../../../../shared/enums/email-html-template-type.enum";
import {EditHtmlTemplateFormSubmitDataModel} from "../../model/edit-html-template-form-submit-data.model";
import { SystemPermissionsRestService } from '../../../../../../../../../core/modules/rest/system-permissions/system-permissions-rest.service';
import { SystemPermissionsResponseModel } from '../../../../../../../../../core/modules/rest/system-permissions/response/system-permissions-response.model';
import { SystemPermissionEnum } from '../../../../../../../../../shared/enums/system-permission.enum';


@Component({
    selector: 'app-edit-email-html-template-form',
    templateUrl: './edit-email-html-template-form.component.html'
})
export class EditEmailHtmlTemplateFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() emailHtmlTemplate: EmailHtmlTemplateResponseModel;

    @Output() submitEvent = new EventEmitter<EditHtmlTemplateFormSubmitDataModel>();
    @Output() closeEvent = new EventEmitter<void>();

    form: FormGroup;
    emailHtmlTemplateTypeOptions: DropdownOptionModel[] = this.initEmailHtmlTemplateOptions();
    htmlPreview: string = "";

    TERMS_AND_CONDITIONS: EmailHtmlTemplateTypeEnum = EmailHtmlTemplateTypeEnum.TERMS_AND_CONDITIONS;
    NOTIFICATION: EmailHtmlTemplateTypeEnum = EmailHtmlTemplateTypeEnum.NOTIFICATION;

    readonly SENDER_EMAIL_ADDRESS: string = "senderEmailAddress";
    readonly SENDER_EMAIL_NAME: string = "senderEmailName";
    readonly EMAIL_SUBJECT: string = "emailSubject";
    message: string = "*Variables: %ordernumber%, %partyname%, %category%, %expirationdate%, %departuredate%,  %product% and %multipass%";

    constructor(
        private systemPermissionsRestService: SystemPermissionsRestService
    ) {
    }

    ngOnInit() {

        this.systemPermissionsRestService.getSystemPermissions().subscribe((systemPermissions: SystemPermissionsResponseModel[]) => {
            let useCustomers =  systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.USE_CUSTOMERS)?.active ?? false;
            if( useCustomers ){
              this.message = "*Variables: %ordernumber%, %partyname%, %activationLink%, %customerName%, %category%, %expirationdate%, %departuredate%,  %product% and %multipass%";
            }else{
                this.emailHtmlTemplateTypeOptions =  this.emailHtmlTemplateTypeOptions.slice( 0,3 );
            }
        });

        let html: string;
        let senderEmailAddress: string;
        let senderEmailName: string;
        let description: string;
        let emailSubject: string;
        let emailHtmlTemplateType: EmailHtmlTemplateTypeEnum;

        if (this.editMode) {

            html = this.emailHtmlTemplate.html;
            senderEmailAddress = this.emailHtmlTemplate.senderEmailAddress;
            senderEmailName = this.emailHtmlTemplate.senderEmailName;
            description = this.emailHtmlTemplate.description;
            emailSubject = this.emailHtmlTemplate.emailSubject;
            emailHtmlTemplateType = this.emailHtmlTemplate.emailHtmlTemplateType;

            this.htmlPreview = this.emailHtmlTemplate.html;
        }

        this.form = new FormGroup({
            html: new FormControl(html, Validators.required),
            description: new FormControl(description, Validators.required),
            emailHtmlTemplateType: new FormControl(emailHtmlTemplateType, Validators.required)
        });

        // EmailFromAddress, EmailFromName and EmailSubject are part of form only if email html template type is not TERMS_AND_CONDITIONS
        if (this.emailHtmlTemplate && this.emailHtmlTemplate.emailHtmlTemplateType && this.emailHtmlTemplate.emailHtmlTemplateType !== this.TERMS_AND_CONDITIONS) {
            this.form.addControl(this.SENDER_EMAIL_ADDRESS, new FormControl(senderEmailAddress, Validators.required));
            this.form.addControl(this.SENDER_EMAIL_NAME, new FormControl(senderEmailName, Validators.required));
            this.form.addControl(this.EMAIL_SUBJECT, new FormControl(emailSubject, Validators.required));
        }
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.editMode && this.form && changes.emailHtmlTemplate) {

            const emailHtmlTemplate: EmailHtmlTemplateResponseModel = this.emailHtmlTemplate;

            this.form.patchValue({
                html: emailHtmlTemplate.html,
                description: emailHtmlTemplate.description,
                emailHtmlTemplateType: emailHtmlTemplate.emailHtmlTemplateType
            });

            // EmailFromAddress, EmailFromName and EmailSubject are part of form only if email html template type is not TERMS_AND_CONDITIONS
            if (this.emailHtmlTemplate && this.emailHtmlTemplate.emailHtmlTemplateType !== this.TERMS_AND_CONDITIONS) {

                // Check if form control for email from and email subject exist, if not, create them and patch values
                let senderEmailAddressCtrl: AbstractControl = this.form.get(this.SENDER_EMAIL_ADDRESS);
                let senderEmailNameCtrl: AbstractControl = this.form.get(this.SENDER_EMAIL_NAME);
                let emailSubjectCtrl: AbstractControl = this.form.get(this.EMAIL_SUBJECT);

                if (!senderEmailAddressCtrl) {
                    this.form.addControl(this.SENDER_EMAIL_ADDRESS, new FormControl(emailHtmlTemplate.senderEmailAddress, Validators.required));
                } else {
                    this.form.patchValue({
                        senderEmailAddress: emailHtmlTemplate.senderEmailAddress
                    });
                }

                if (!senderEmailNameCtrl) {
                    this.form.addControl(this.SENDER_EMAIL_NAME, new FormControl(emailHtmlTemplate.senderEmailName, Validators.required));
                } else {
                    this.form.patchValue({
                        senderEmailName: emailHtmlTemplate.senderEmailName
                    });
                }

                if (!emailSubjectCtrl) {
                    this.form.addControl(this.EMAIL_SUBJECT, new FormControl(emailHtmlTemplate.emailSubject, Validators.required));
                } else {
                    this.form.patchValue({
                        emailSubject: emailHtmlTemplate.emailSubject
                    });
                }
            }

            // Update html preview
            this.htmlPreview = emailHtmlTemplate.html;
        }
    }

    onSelectedEmailHtmlTemplateTypeChanged(evt: string) {

        let value: EmailHtmlTemplateTypeEnum = <EmailHtmlTemplateTypeEnum>evt;

        // EmailFromAddress, EmailFromName and EmailSubject are part of form only if email html template type is not TERMS_AND_CONDITIONS or Notificaiton
        if (value !== this.TERMS_AND_CONDITIONS) {
            this.form.addControl(this.SENDER_EMAIL_ADDRESS, new FormControl(null, Validators.required));
            this.form.addControl(this.SENDER_EMAIL_NAME, new FormControl(null, Validators.required));
            this.form.addControl(this.EMAIL_SUBJECT, new FormControl(null, Validators.required));
        } else {
            this.form.removeControl(this.SENDER_EMAIL_ADDRESS);
            this.form.removeControl(this.SENDER_EMAIL_NAME);
            this.form.removeControl(this.EMAIL_SUBJECT);
        }
    }

    onSubmitClick() {

        const emailHtmlTemplateType = this.form.value.emailHtmlTemplateType;
        const description = this.form.value.description;
        const senderEmailAddress = emailHtmlTemplateType !== this.TERMS_AND_CONDITIONS ? this.form.value.senderEmailAddress : null;
        const senderEmailName = emailHtmlTemplateType !== this.TERMS_AND_CONDITIONS ? this.form.value.senderEmailName : null;
        const emailSubject = emailHtmlTemplateType !== this.TERMS_AND_CONDITIONS ? this.form.value.emailSubject : null;
        const html = this.form.value.html;

        const data: EditHtmlTemplateFormSubmitDataModel = new EditHtmlTemplateFormSubmitDataModel(
            emailHtmlTemplateType,
            description,
            senderEmailAddress,
            senderEmailName,
            emailSubject,
            html
        );

        this.submitEvent.emit(data);
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    initEmailHtmlTemplateOptions(): DropdownOptionModel[] {

        return Object.values(EmailHtmlTemplateTypeEnum)
            .map((enumValue: EmailHtmlTemplateTypeEnum) => {
                return new DropdownOptionModel(EmailHtmlTemplateTypeEnum_getDescription(enumValue), enumValue);
            });
    }
}
