import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DialogData} from "../../../model/dialog.data";
import {ChangePasswordSandbox} from "../sandbox/sandbox";
import { Observable, Subscription } from 'rxjs';


// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class ChangePasswordDialogData extends DialogData {

    header: string;
    yesLabel: string;
    noLabel: string;
    buttonType: string;

    callbackFn: (yesClicked: boolean, currentPassword: string, newPassword: string, confirmedPassword: string) => void;

    constructor(header: string, noLabel: string = 'No', yesLabel: string = 'Yes', buttonType: string = 'primary', callbackFn: (yesClicked: boolean, currentPassword: string, newPassword: string, confirmedPassword: string) => void) {
        super();
        this.header = header;
        this.noLabel = noLabel;
        this.yesLabel = yesLabel;
        this.buttonType = buttonType;
        this.callbackFn = callbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
// Component that represents it
// -------------------------------------------------------------------------------------------------
@Component({
    selector: 'app-change-password-dialog',
    templateUrl: './change-password-dialog.component.html'
})
export class ChangePasswordDialogComponent {

    header: string;
    noLabel: string;
    yesLabel: string;
    buttonType: string;
    currentPassword: string;
    newPassword: string;
    confirmedPassword: string;

    // Function that gets called once yes or no button has been clicked
    callbackFn: (yesClicked: boolean, currentPassword: string, newPassword: string, confirmedPassword: string) => void;

    constructor(@Inject(DialogData) dialogData: ChangePasswordDialogData, private changePasswordSandbox: ChangePasswordSandbox) {

        this.header = dialogData.header;
        this.noLabel = dialogData.noLabel;
        this.yesLabel = dialogData.yesLabel;
        this.buttonType = dialogData.buttonType;
        this.callbackFn = dialogData.callbackFn;
    }

    showLoader$: Observable<boolean>;

    ngOnInit(): void {
        this.showLoader$ = this.changePasswordSandbox.showLoader$;
    }

    onNoClicked() {
        this.callbackFn(false, this.currentPassword, this.newPassword, this.confirmedPassword);
        this.changePasswordSandbox.resetState();
        this.changePasswordSandbox.destroyDialog();
    }

    onYesClicked() {
        this.callbackFn(true, this.currentPassword, this.newPassword, this.confirmedPassword);
    }

    onCloseClicked() {
        this.callbackFn(false, this.currentPassword, this.newPassword, this.confirmedPassword);
        this.changePasswordSandbox.resetState();
        this.changePasswordSandbox.destroyDialog();
    }

    currentPassswordChanged(event: string) {
        this.currentPassword = event;
    }

    newPasswordChanged(event: string) {
        this.newPassword = event;
    }

    confirmedPasswordChanged(event: string) {
        this.confirmedPassword = event;
    }

    currentPasswordVisible: boolean = false;
    newPasswordVisible: boolean = false;
    confirmedPasswordVisible: boolean = false;

    toggleCurrentPasswordVisibility() {
        this.currentPasswordVisible = !this.currentPasswordVisible;
        const inputEl = document.getElementById('currentPassword_text');
        if (inputEl) {
            inputEl.setAttribute('type', this.currentPasswordVisible ? 'text' : 'password');
        }
    }

    toggleNewPasswordVisibility() {
        this.newPasswordVisible = !this.newPasswordVisible;
        const inputEl = document.getElementById('newPassword_text');
        if (inputEl) {
            inputEl.setAttribute('type', this.newPasswordVisible ? 'text' : 'password');
        }
    }

    toggleConfirmedPasswordVisibility() {
        this.confirmedPasswordVisible = !this.confirmedPasswordVisible;
        const inputEl = document.getElementById('confirmedPassword_text');
        if (inputEl) {
            inputEl.setAttribute('type', this.confirmedPasswordVisible ? 'text' : 'password');
        }
    }
}
