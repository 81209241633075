import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges  } from '@angular/core';
import {ReportGroupModel} from '../../model/report-group.model';
import {AccordionItemService} from '../../../../../../../shared/components/accordion/accordion-item/accordion-item.service';
import {DraggedDataI} from '../../model/dragged-data.model';


@Component({
    selector: 'app-order-reports-sidebar-report-group',
    templateUrl: './order-reports-sidebar-report-group.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        AccordionItemService
    ],
})
export class OrderReportsSidebarReportGroupComponent implements OnInit, OnChanges {

    @Input() reportGroup: ReportGroupModel;
    @Input() reportItemsSearchText: string; // Used in ngOnChanges
    @Input() activeReportGroups: string[]; // Used in ngOnChanges
    @Output() changeReportGroupActivityStatusEvent: EventEmitter<string> = new EventEmitter();
    @Output() dragStartEvent: EventEmitter<DraggedDataI> = new EventEmitter<DraggedDataI>();
    @Output() dragEndEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor(private accordionItemService: AccordionItemService) {
        this.accordionItemService.listenOnHeaderClick().subscribe(() => {
            this.changeReportGroupActivityStatusEvent.emit(this.reportGroup.name);
        });
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.activeReportGroups) {
            const active: boolean = this.activeReportGroups.some((name: string) => name === this.reportGroup.name);
            this.accordionItemService.changeItemActivityStatus(active);
        }

        if (changes.reportItemsSearchText) {
            if (this.reportItemsSearchText.length !== 0) {
                this.accordionItemService.changeItemActivityStatus(true);
            }
        }
    }

    onDragStart(data: DraggedDataI) {
        this.dragStartEvent.emit(data);
    }

    onDragEnd() {
        this.dragEndEvent.emit();
    }
}
