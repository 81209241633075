import {Action} from "@ngrx/store";
import {TemplateItemResponseModel} from "../../../../../../../../../core/modules/rest/template/response/template-item-response.model";

export const RESET_STATE = '[Dialog - Edit Pass Template Items :: MAIN] Reset State';
export const SET_TEMPLATE_ITEMS = '[Dialog - Edit Pass Template Items :: MAIN] Set Template Items';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetTemplateItems implements Action {
    readonly type = SET_TEMPLATE_ITEMS;

    constructor(public templateItems: TemplateItemResponseModel[]) {
    }
}

export type All = ResetState | SetTemplateItems;
