import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditBusDialogComponent} from "./container/edit-bus-dialog.component";
import {StoreModule} from "@ngrx/store";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {EditBusDialogSandbox} from "./sandbox/edit-bus-dialog.sandbox";
import {EditBusFormComponent} from './components/edit-bus-form/edit-bus-form.component';


@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditBusDialogSandbox
    ],
    declarations: [
        EditBusDialogComponent,
        EditBusFormComponent
    ],
    entryComponents: [
        EditBusDialogComponent
    ],
    exports: [
      EditBusDialogComponent
    ]
})
export class EditBusDialogModule {

}
