<div class="dialog-section-header">
    <h3>{{passOrderDetail.passDescription}}</h3>
    <div class="dialog-section-edit-options" [ngClass]="{'remove': isEditEnabled()}">
        <div class="edit-option" (click)="onEditClick()" [ngClass]="{'disabled': editMode || !editEnabled}">
            <i class="fas fa-pencil"></i>
            <span>Edit</span>
        </div>
    </div>
</div>

<!-- VIEW MODE -->
<div class="summary-general-info-wrapper" [ngClass]="{'remove': editMode}">
    <div class="summary-general-info">
        <div class="summary-data-wrapper">
            <p>Pick Up Location</p>
            <p>{{passOrderDetail.startLocationDescription ? passOrderDetail.startLocationDescription : 'N/A'}}</p>
        </div>
        <div class="summary-data-wrapper">
            <p>Drop Off Location</p>
            <p>{{passOrderDetail.stopLocationDescription ? passOrderDetail.stopLocationDescription : 'N/A'}}</p>
        </div>
        <div class="summary-data-wrapper"
             *ngFor="let passOrderDetailTier of passOrderDetail.passOrderDetailTiers; let i = index">
            <ng-container *ngIf="i === 0">
                <p>Tiers</p>
            </ng-container>
            <ng-container *ngIf="i > 0">
                <p></p>
            </ng-container>
            <p>
                <span>{{passOrderDetailTier.quantity}}</span>
                <span>x</span>
                <span>{{passOrderDetailTier.tierDescription}}</span>
            </p>
        </div>
        <div class="summary-data-wrapper" *ngFor="let passOrderDetailField of passOrderDetail.passOrderDetailFields">
            <p>{{passOrderDetailField.templateItemDescription}}</p>
            <p>{{passOrderDetailField.fieldValue}}</p>
        </div>
    </div>

    <div class="summary-general-info">
        <div class="summary-data-wrapper">
            <p>Status</p>
            <p>{{passOrderDetail.orderStatusDescription}}</p>
        </div>
    </div>
</div>

<!-- EDIT MODE -->
<div class="summary-general-info-wrapper editable" [ngClass]="{'remove': !editMode}">
    <div class="summary-general-info">
        <div class="summary-data-wrapper h-38">
            <p>Pick Up Location</p>
            <p>{{passOrderDetail.startLocationDescription ? passOrderDetail.startLocationDescription : 'N/A'}}</p>
        </div>
        <div class="summary-data-wrapper h-38">
            <p>Drop Off Location</p>
            <p>{{passOrderDetail.stopLocationDescription ? passOrderDetail.stopLocationDescription : 'N/A'}}</p>
        </div>
        <div class="summary-data-wrapper h-38"
             *ngFor="let passOrderDetailTier of passOrderDetail.passOrderDetailTiers; let i = index">
            <ng-container *ngIf="i === 0">
                <p>Tiers</p>
            </ng-container>
            <ng-container *ngIf="i > 0">
                <p></p>
            </ng-container>
            <p>
                <span>{{passOrderDetailTier.quantity}}</span>
                <span>x</span>
                <span>{{passOrderDetailTier.tierDescription}}</span>
            </p>
        </div>
        <div class="summary-data-wrapper h-38" *ngFor="let customField of customFields">
            <p>{{customField.templateItemDescription}}</p>
            <input [(ngModel)]="customField.fieldValue" (input)="onDataEdit()">
        </div>
    </div>

    <div class="summary-general-info">
        <div class="summary-data-wrapper h-38">
            <p>Status</p>
            <p>{{passOrderDetail.orderStatusDescription}}</p>
        </div>
    </div>

    <div class="summary-general-button-wrapper">
        <button class="button cancel-button" (click)="onCancelClick()">Cancel</button>
        <button class="button primaryButton" (click)="onSaveClick()" [disabled]="!saveEnabled">Save</button>
    </div>
</div>

<div class="placeholderText-emptySection" *ngIf="!passCardsExist()">
    No Usage Info to Display
</div>

<ng-container *ngIf="passCardsExist()">
    <div class="summary-general-info-wrapper summary-pass-detail-wrapper summary-scroll-wrapper">
        <h4>Usage Info</h4>
        <ng-container *ngFor="let passCard of passCardsDialogData">
            <div class="table-wrapper">
                <i class="fas fa-caret-right table-arrow"
                   *ngIf="!!passCard.ticketCheckIns &&  passCard.ticketCheckIns.length > 0"
                   [ngClass]="{'down':passCard.opened}"
                   (click)="onToggle(passCard)"></i>
                <table (click)="onToggle(passCard)">
                    <thead>
                    <tr>
                        <th>Pass Card</th>
                        <th>Total Used</th>
                        <th>Remaining Quantity</th>
                        <th>Expires On</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="!passCard.invalidDueToReprint">
                        <td>{{passCard.passCardExternalId}}</td>
                        <td>{{passCard.totalUsed}}</td>
                        <td>{{passCard.isUnlimited ? "Unlimited" : passCard.remainingQuantity}}</td>
                        <td>{{passCard.expiration}}</td>
                    </tr>
                    </tbody>
                </table>
                <ng-container *ngIf="!!passCard.ticketCheckIns &&  passCard.ticketCheckIns.length > 0 && passCard.opened || !!passCard.passCardCheckIns &&  passCard.passCardCheckIns.length > 0 && passCard.opened">
                    <div class="children-table-container">
                        <table>
                            <thead>
                            <tr>
                                <th *ngIf="invalidTicketCheckInExist() || invalidPassCardCheckInExist()"></th>
                                <th>Direction</th>
                                <th>Trip Name</th>
                                <th>Occurrence Date/Time</th>
                                <th>Check in Date/Time</th>
                                <th>Check in Location</th>
                                <th>Bus</th>
                                <th>Driver</th>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container *ngFor="let ticketCheckIn of passCard.ticketCheckIns">
                                <tr>
                                    <td class="width-5px" title="Invalid Check-in" *ngIf="invalidTicketCheckinExists && ticketCheckIn.failedScan">
                                        <i class="fa fa-exclamation-triangle"></i>
                                    </td>
                                    <td class="width-5px" *ngIf="invalidTicketCheckinExists && !ticketCheckIn.failedScan"></td>
                                    <td>{{ticketCheckIn.directionDescription}}</td>
                                    <td>{{ticketCheckIn.productAvailabilityDescription}}</td>
                                    <td>{{ticketCheckIn.occurrenceDateTimeFriendly ? ticketCheckIn.occurrenceDateTimeFriendly : 'N/A' }}</td>
                                    <td>{{ticketCheckIn.checkInDateTimeFriendly ? ticketCheckIn.checkInDateTimeFriendly : 'N/A'}}</td>
                                    <td>
                                        <div *ngIf="ticketCheckIn.startLocationId">
                                            {{getLocationDescriptionForTicketCheckIn(ticketCheckIn)}}
                                        </div>
                                        <div
                                            *ngIf="!ticketCheckIn.startLocationId && (!ticketCheckIn.startLocationLatitude || !ticketCheckIn.startLocationLongitude)">
                                            {{getLocationDescriptionForTicketCheckIn(ticketCheckIn)}} (NO GPS DATA)
                                        </div>
                                        <div
                                            *ngIf="!ticketCheckIn.startLocationId && ticketCheckIn.startLocationLatitude && ticketCheckIn.startLocationLongitude">
                                            <a href="https://www.google.com/maps/place/{{ticketCheckIn.startLocationLatitude}},{{ticketCheckIn.startLocationLongitude}}"
                                               target="_blank">
                                                {{getLocationDescriptionForTicketCheckIn(ticketCheckIn)}}
                                                ({{ticketCheckIn.startLocationLatitude}}
                                                ,{{ticketCheckIn.startLocationLongitude}})
                                            </a>
                                        </div>
                                    </td>
                                    <td>{{ticketCheckIn.busNumber ? ticketCheckIn.busNumber : 'N/A'}}</td>
                                    <td>{{ticketCheckIn.driverDisplayName ? ticketCheckIn.driverDisplayName : 'N/A'}}</td>
                                </tr>
                            </ng-container>

                            <ng-container *ngFor="let passCardCheckIn of passCard.passCardCheckIns">
                                <tr>
                                    <td class="width-5px" title="Invalid Check-in" *ngIf="invalidPassCheckinExists && passCardCheckIn.failedScan">
                                        <i class="fa fa-exclamation-triangle"></i>
                                    </td>
                                    <td class="width-5px" *ngIf="invalidPassCheckinExists && !passCardCheckIn.failedScan"></td>
                                    <td>{{passCardCheckIn.directionDescription}}</td>
                                    <td>{{passCardCheckIn.productAvailabilityDescription}}</td>
                                    <td>{{passCardCheckIn.occurrenceDateTimeFriendly ? passCardCheckIn.occurrenceDateTimeFriendly : 'N/A' }}</td>
                                    <td>{{passCardCheckIn.checkInDateTimeFriendly ? passCardCheckIn.checkInDateTimeFriendly : 'N/A'}}</td>
                                    <td>
                                        <div *ngIf="passCardCheckIn.startLocationId">
                                            {{getLocationDescriptionForPassCardCheckIn(passCardCheckIn)}}
                                        </div>
                                        <div
                                            *ngIf="!passCardCheckIn.startLocationId && (!passCardCheckIn.startLocationLatitude || !passCardCheckIn.startLocationLongitude)">
                                            {{getLocationDescriptionForPassCardCheckIn(passCardCheckIn)}} (NO GPS DATA)
                                        </div>
                                        <div
                                            *ngIf="!passCardCheckIn.startLocationId && passCardCheckIn.startLocationLatitude && passCardCheckIn.startLocationLongitude">
                                            <a href="https://www.google.com/maps/place/{{passCardCheckIn.startLocationLatitude}},{{passCardCheckIn.startLocationLongitude}}"
                                               target="_blank">
                                                {{getLocationDescriptionForPassCardCheckIn(passCardCheckIn)}}
                                                ({{passCardCheckIn.startLocationLatitude}}
                                                ,{{passCardCheckIn.startLocationLongitude}})
                                            </a>
                                        </div>
                                    </td>
                                    <td>{{passCardCheckIn.busNumber ? passCardCheckIn.busNumber : 'N/A'}}</td>
                                    <td>{{passCardCheckIn.driverDisplayName ? passCardCheckIn.driverDisplayName : 'N/A'}}</td>
                                </tr>
                            </ng-container>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-container>
