import {CreateBaseResponseModel} from "../create-base-response.model";
import {Observable} from "rxjs";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {HolidayResponseModel} from "./response/holiday-response.model";
import {CreateHolidayRequestModel} from "./request/create-holiday-request.model";
import {UpdateHolidayRequestModel} from "./request/update-holiday-request.model";
import {Injectable} from "@angular/core";

@Injectable()
export class HolidayRestService {

    constructor(private httpClient: HttpClient) {
    }

    getById(holidayId: number): Observable<HolidayResponseModel> {
        return this.httpClient.get<HolidayResponseModel>(environment.server_api_url + '/api/secured/holiday/getById/' + holidayId);
    }

    getAll(): Observable<HolidayResponseModel[]> {
        return this.httpClient.get<HolidayResponseModel[]>(environment.server_api_url + '/api/secured/holiday/getAll');
    }

    create(request: CreateHolidayRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/holiday/create', request);
    }

    update(request: UpdateHolidayRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/holiday/update', request, {responseType: 'text'});
    }

    activate(holidayId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/holiday/activate', holidayId, {responseType: 'text'});
    }

    deactivate(holidayId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/holiday/deactivate', holidayId, {responseType: 'text'});
    }
}
