import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges  } from '@angular/core';
import {AccordionItemService} from "../../../../../../../shared/components/accordion/accordion-item/accordion-item.service";
import {Subscription} from "rxjs";
import {FavoriteResponseModel} from "../../../../../../../core/modules/rest/favorite/response/favorite-response.model";
import {take} from "rxjs/operators";


@Component({
    selector: 'app-order-report-sidbar-favorite-group',
    templateUrl: './order-report-sidebar-favorite-group.component.html',
    providers: [
        AccordionItemService
    ]
})
export class OrderReportSidebarFavoriteGroupComponent implements OnChanges, OnDestroy {

    @Output() selectedFavoriteChangedEvent: EventEmitter<number> = new EventEmitter();

    @Input() listOfFavorites: FavoriteResponseModel[] = [];
    @Input() openAccordion: boolean;
    @Input() groupName: string;
    @Input() selectedFavoriteId: number;

    accordionHeaderClickSubscription: Subscription;

    constructor(private accordionItemService: AccordionItemService) {
        this.accordionHeaderClickSubscription = this.accordionItemService.listenOnHeaderClick().subscribe(this.changeAccordionItemStatus.bind(this));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.openAccordion) {
            this.accordionItemService.changeItemActivityStatus(changes.openAccordion.currentValue);
        }
        // When openAccordion was null and there was an id, only the accordion containing that id was opened
        // if (changes.selectedFavoriteId && changes.openAccordion===null) {
        //     this.openAccordionIfExistInListOfFavorites();
        // }
    }

    ngOnDestroy() {
        if (this.accordionHeaderClickSubscription) {
            this.accordionHeaderClickSubscription.unsubscribe();
        }
    }

    onSelectedFavorite(favoriteId: number) {
        this.selectedFavoriteId = favoriteId;
        this.selectedFavoriteChangedEvent.emit(favoriteId);
    }

    private changeAccordionItemStatus() {
        let activityStatus: boolean;
        this.accordionItemService.listenOnItemActivityStatus().pipe(take(1)).subscribe((status: boolean) => {
            activityStatus = status;
        });
        this.accordionItemService.changeItemActivityStatus(!activityStatus);
    }

    // private openAccordionIfExistInListOfFavorites() {
    //     if (!this.listOfFavorites.length) {
    //         return;
    //     }
    //     let openAccordion: boolean = !!this.listOfFavorites.find(favorite => favorite.favoriteId === this.selectedFavoriteId);
    //     this.accordionItemService.changeItemActivityStatus(openAccordion);
    // }
}
