import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DropdownOptionModel } from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { CategoryResponseModel } from "../../../../../../../../../core/modules/rest/category/response/category-response.model";
import { ProductSimpleResponseModel } from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import { LocationListItemDescriptorResponseModel } from "../../../../../../../../../core/modules/rest/location-list/response/location-list-item-descriptor-response.model";
import { StartTimeResponseModel } from "../../../../../../../../../core/modules/rest/product-availability/response/start-time-response.model";
import { SubmitFormDialogDataModel } from "../../model/submit-form-dialog-data.model";
import { QuickSellingButtonProductResponseModel } from '../../../../../../../../../core/modules/rest/quick-selling-button-product/response/quick-selling-button-product-response.model';
import { QuickSellingButtonProductTierResponseModel } from '../../../../../../../../../core/modules/rest/quick-selling-button-product/response/quick-selling-button-product-tier-response.model';
import { TierResponseModel } from '../../../../../../../../../core/modules/rest/tier/response/tier-response.model';
import { TierWithQuantityModel } from '../../model/tier-with-quantity.model';

@Component({
    selector: 'app-edit-quick-selling-button-product-form',
    templateUrl: './edit-quick-selling-button-product-form.component.html',
    styleUrls: ['./edit-quick-selling-button-product-form.component.scss']
})
export class EditQuickSellingButtonProductFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;

    @Input() quickSellingButtonProduct: QuickSellingButtonProductResponseModel;
    @Input() product: ProductSimpleResponseModel;

    @Input() tiers: TierResponseModel[];
    @Input() categories: CategoryResponseModel[];
    @Input() products: ProductSimpleResponseModel[];
    @Input() pickupLocations: LocationListItemDescriptorResponseModel[];
    @Input() dropoffLocations: LocationListItemDescriptorResponseModel[];
    @Input() startTimes: StartTimeResponseModel[];
    @Input() startTimesReturnTrip: StartTimeResponseModel[];

    @Input() tiersLoading: boolean;
    @Input() categoriesLoading: boolean;
    @Input() productsLoading: boolean;
    @Input() pickupLocationsLoading: boolean;
    @Input() dropoffLocationsLoading: boolean;
    @Input() startTimesLoading: boolean;
    @Input() startTimesReturnTripLoading: boolean;

    @Input() errorMessage: string;

    @Output() selectedCategoryChangedEvent = new EventEmitter<number>();
    @Output() selectedProductChangedEvent = new EventEmitter<number>();
    @Output() selectedPickupLocationChangedEvent = new EventEmitter<number>();
    @Output() selectedDropoffLocationChangedEvent = new EventEmitter<number>();

    @Output() submitEvent = new EventEmitter<SubmitFormDialogDataModel>();
    @Output() closeEvent = new EventEmitter<void>();

    form: FormGroup;

    categoryOptions: DropdownOptionModel[];
    productOptions: DropdownOptionModel[];
    pickupLocationOptions: DropdownOptionModel[];
    dropoffLocationOptions: DropdownOptionModel[];
    startTimeOptions: DropdownOptionModel[];
    startTimeReturnTripOptions: DropdownOptionModel[];

    private selectedCategoryId: number = null;
    private selectedProductId: number = null;
    private selectedPickupLocationId: number = null;
    private selectedDropoffLocationId: number = null;
    private selectedStartTime: number = null;
    private selectedStartTimeReturnTrip: number = null;

    constructor() {
    }

    private readonly ANY_TIME = '-1';

    ngOnInit() {

        this.form = new FormGroup({
            description: new FormControl("", Validators.required),
            color: new FormControl("#000000", Validators.required),
            selectedCategoryId: new FormControl(null, Validators.required),
            selectedProductId: new FormControl(null, Validators.required),
            selectedPickupLocationId: new FormControl(null, Validators.required),
            selectedDropoffLocationId: new FormControl(null, Validators.required),
            selectedStartTime: new FormControl(null, Validators.required),
            selectedStartTimeReturnTrip: new FormControl(null, Validators.required),
            tiersWithQuantities: new FormArray([]),
            useLastName: new FormControl(false),
            lastNameRequired: new FormControl(false),
            useEmail: new FormControl(false),
            emailRequired: new FormControl(false),
            usePhone: new FormControl(false),
            phoneRequired: new FormControl(false),
            useDiscountCode: new FormControl(false),
            useRoundTrip: new FormControl(false)
        });
    }

    ngOnChanges(changes: SimpleChanges) {

        // Check if form created
        if (!this.form) {
            return;
        }

        if (changes.product) {

            this.form.controls['selectedPickupLocationId'].setValidators(this.product && this.product.usesPickupLocations ? Validators.required : null);
            this.form.controls['selectedDropoffLocationId'].setValidators(this.product && this.product.usesDropoffLocations ? Validators.required : null);
            this.form.controls['selectedStartTimeReturnTrip'].setValidators(this.product && this.product.isRoundTripProduct ? Validators.required : null);
        }

        // Check if initial changes or later changes (if dialog data loaded or if some "in-form" load triggered)
        if ((!this.editMode || (this.editMode && changes.quickSellingButtonProduct && changes.products && changes.pickupLocations && changes.dropoffLocations && changes.startTimes && changes.startTimesReturnTrip)) && changes.tiers && changes.categories) {

            if (this.editMode && changes.quickSellingButtonProduct) {
                this.selectedCategoryId = changes.quickSellingButtonProduct.currentValue.categoryId;
                this.selectedProductId = changes.quickSellingButtonProduct.currentValue.productId;
                this.selectedPickupLocationId = changes.quickSellingButtonProduct.currentValue.pickupLocationId;
                this.selectedDropoffLocationId = changes.quickSellingButtonProduct.currentValue.dropoffLocationId;
            } else {
                this.selectedCategoryId = null;
                this.selectedProductId = null;
                this.selectedPickupLocationId = null;
                this.selectedDropoffLocationId = null;
            }

            // Populate categories dropdown
            this.categoryOptions = changes.categories.currentValue
                .map((category: CategoryResponseModel) => new DropdownOptionModel(category.description, category.categoryId.toString()));

            // Populate products dropdown
            this.productOptions = changes.products.currentValue
                .map((product: ProductSimpleResponseModel) => new DropdownOptionModel(product.description, product.productId.toString()));

            // Populate pickup locations dropdown
            this.pickupLocationOptions = changes.pickupLocations.currentValue
                .map((pickupLocation: LocationListItemDescriptorResponseModel) => new DropdownOptionModel(pickupLocation.locationDescription, pickupLocation.locationId.toString()));

            // Populate dropoff locations dropdown
            this.dropoffLocationOptions = changes.dropoffLocations.currentValue
                .map((dropoffLocation: LocationListItemDescriptorResponseModel) => new DropdownOptionModel(dropoffLocation.locationDescription, dropoffLocation.locationId.toString()));

            // Populate start times dropdown
            this.startTimeOptions = changes.startTimes.currentValue
                .map((startTime: StartTimeResponseModel) => new DropdownOptionModel(startTime.startTimeWithOffsetFriendly, startTime.productAvailabilityId ? startTime.productAvailabilityId.toString() : this.ANY_TIME));

            // Populate start times return trip dropdown
            this.startTimeReturnTripOptions = changes.startTimesReturnTrip.currentValue
                .map((startTimeReturnTrip: StartTimeResponseModel) => new DropdownOptionModel(startTimeReturnTrip.startTimeWithOffsetFriendly, startTimeReturnTrip.productAvailabilityId ? startTimeReturnTrip.productAvailabilityId.toString() : this.ANY_TIME));

            // Populate tiers list
            let tiersWithQuantities = [];
            let tierWithQuantity: TierWithQuantityModel;
            let quickSellingButtonProductTier: QuickSellingButtonProductTierResponseModel;

            for (let tier of changes.tiers.currentValue) {

                quickSellingButtonProductTier = null;

                // If edit mode and quick selling button product provided, try to find current tier among quick selling button product tiers
                if (this.editMode && changes.quickSellingButtonProduct) {
                    for (let qsbpTier of changes.quickSellingButtonProduct.currentValue.quickSellingButtonProductTiers) {
                        if (tier.tierId === qsbpTier.tierId) {
                            quickSellingButtonProductTier = qsbpTier;
                            break;
                        }
                    }
                }

                tierWithQuantity = new TierWithQuantityModel(tier.tierId, tier.description, quickSellingButtonProductTier != null ? quickSellingButtonProductTier.tierQuantity : null);
                tiersWithQuantities.push(tierWithQuantity);
            }

            // Patch form - description, color, selected category, selected product, selected pickup location, selected dropoff location, selected start time, selected start time return trip and quick selling button product flags
            if (this.editMode && changes.quickSellingButtonProduct && Object.keys(changes.quickSellingButtonProduct.currentValue).length !== 0) {

                this.form.patchValue({
                    description: this.quickSellingButtonProduct.description,
                    color: this.quickSellingButtonProduct.color,
                    selectedCategoryId: this.quickSellingButtonProduct.categoryId,
                    selectedProductId: this.quickSellingButtonProduct.productId,
                    selectedPickupLocationId: this.quickSellingButtonProduct.pickupLocationId,
                    selectedDropoffLocationId: this.quickSellingButtonProduct.dropoffLocationId,
                    selectedStartTime: !this.quickSellingButtonProduct.productAvailabilityId ? this.ANY_TIME : this.quickSellingButtonProduct.productAvailabilityId.toString(),
                    selectedStartTimeReturnTrip: !this.quickSellingButtonProduct.productAvailabilityReturnTripId ? this.ANY_TIME : this.quickSellingButtonProduct.productAvailabilityReturnTripId.toString(),
                    useLastName: this.quickSellingButtonProduct.useLastName,
                    lastNameRequired: this.quickSellingButtonProduct.lastNameRequired,
                    useEmail: this.quickSellingButtonProduct.useEmail,
                    emailRequired: this.quickSellingButtonProduct.emailRequired,
                    usePhone: this.quickSellingButtonProduct.usePhone,
                    phoneRequired: this.quickSellingButtonProduct.phoneRequired,
                    useDiscountCode: this.quickSellingButtonProduct.useDiscountCode,
                    useRoundTrip: this.quickSellingButtonProduct.useRoundTrip
                });
            }

            const selectedPickupLocationtExists = this.pickupLocationOptions.some(option => option.value === this.form.get('selectedPickupLocationId').value.toString());
            this.form.patchValue({
                selectedPickupLocationId: selectedPickupLocationtExists ? this.form.get('selectedPickupLocationId').value : null
            });
            if (!selectedPickupLocationtExists) {
                this.form.patchValue({
                    selectedDropoffLocationId: null,
                    selectedStartTime: null,
                    selectedStartTimeReturnTrip: null
                });
                this.selectedPickupLocationId = null;
                this.selectedDropoffLocationId = null;
                this.dropoffLocationOptions = [];
                this.startTimeOptions = [];
                this.startTimeReturnTripOptions = [];

            } else {

                const selectedDropoffLocationtExists = this.dropoffLocationOptions.some(option => option.value === this.form.get('selectedDropoffLocationId').value.toString());
                this.form.patchValue({
                    selectedDropoffLocationId: selectedDropoffLocationtExists ? this.form.get('selectedDropoffLocationId').value : null
                });
                if (!selectedDropoffLocationtExists) {
                    this.form.patchValue({
                        selectedStartTimeReturnTrip: null,
                    });
                    this.selectedDropoffLocationId = null;
                    this.startTimeReturnTripOptions = [];
                }

                const selectedStartTimeExists = this.startTimeOptions.some(option => option.value === this.form.get('selectedStartTime').value);
                this.form.patchValue({
                    selectedStartTime: selectedStartTimeExists ? this.form.get('selectedStartTime').value : null
                });

                const selectedStartTimeReturnTripExists = this.startTimeReturnTripOptions.some(option => option.value === this.form.get('selectedStartTimeReturnTrip').value);
                this.form.patchValue({
                    selectedStartTimeReturnTrip: selectedStartTimeReturnTripExists ? this.form.get('selectedStartTimeReturnTrip').value : null
                });
            }



            // Patch form - tiers
            let tiersWithQuantitiesFormArray: FormArray = this.form.get('tiersWithQuantities') as FormArray;
            tiersWithQuantitiesFormArray.reset([]);
            for (let tierWithQuantity of tiersWithQuantities) {
                tiersWithQuantitiesFormArray.push(new FormGroup({
                    tierId: new FormControl(tierWithQuantity.tierId),
                    tierDescription: new FormControl(tierWithQuantity.tierDescription),
                    tierQuantity: new FormControl(tierWithQuantity.tierQuantity)
                }));
            }
        } else {

            if (changes.products) {

                // Populate dropdown values
                this.productOptions = changes.products.currentValue
                    .map((product: ProductSimpleResponseModel) => new DropdownOptionModel(product.description, product.productId.toString()));

                this.selectedProductId = null;
                this.selectedPickupLocationId = null;
                this.selectedDropoffLocationId = null;

                // Patch form - selected product
                this.form.patchValue({
                    selectedProductId: null,
                    selectedPickupLocationId: null,
                    selectedDropoffLocationId: null,
                    selectedStartTime: null,
                    selectedStartTimeReturnTrip: null
                });
            }

            if (changes.pickupLocations) {

                // Populate dropdown values
                this.pickupLocationOptions = changes.pickupLocations.currentValue
                    .map((pickupLocation: LocationListItemDescriptorResponseModel) => new DropdownOptionModel(pickupLocation.locationDescription, pickupLocation.locationId.toString()));

                this.selectedPickupLocationId = null;
                this.selectedDropoffLocationId = null;

                // Patch form - selected pickup location
                this.form.patchValue({
                    selectedPickupLocationId: null,
                    selectedDropoffLocationId: null,
                    selectedStartTime: null,
                    selectedStartTimeReturnTrip: null
                });
            }

            if (changes.dropoffLocations) {

                // Populate dropdown values
                this.dropoffLocationOptions = changes.dropoffLocations.currentValue
                    .map((dropoffLocation: LocationListItemDescriptorResponseModel) => new DropdownOptionModel(dropoffLocation.locationDescription, dropoffLocation.locationId.toString()));

                this.selectedDropoffLocationId = null;

                // Patch form - selected dropoff location
                this.form.patchValue({
                    selectedDropoffLocationId: null,
                    selectedStartTimeReturnTrip: null
                });
            }

            if (changes.startTimes) {

                // Populate start times dropdown
                this.startTimeOptions = changes.startTimes.currentValue
                    .map((startTime: StartTimeResponseModel) => new DropdownOptionModel(startTime.startTimeWithOffsetFriendly, startTime.productAvailabilityId ? startTime.productAvailabilityId.toString() : this.ANY_TIME));

                // Patch form - selected start time
                this.form.patchValue({
                    selectedStartTime: null
                });
            }

            if (changes.startTimesReturnTrip) {

                // Populate start times return trip dropdown
                this.startTimeReturnTripOptions = changes.startTimesReturnTrip.currentValue
                    .map((startTimeReturnTrip: StartTimeResponseModel) => new DropdownOptionModel(startTimeReturnTrip.startTimeWithOffsetFriendly, startTimeReturnTrip.productAvailabilityId ? startTimeReturnTrip.productAvailabilityId.toString() : this.ANY_TIME));

                // Patch form - selected start time return trip
                this.form.patchValue({
                    selectedStartTimeReturnTrip: null
                });
            }
        }
    }

    checkStartTime() {
        if (this.form.value.selectedStartTime == this.ANY_TIME || this.form.value.selectedStartTimeReturnTrip == this.ANY_TIME) {
            return true;
        } else {
            return false;
        }
    }

    onSelectedCategoryChanged(value: string) {
        let newSelectedCategoryId = value !== null && value !== undefined ? +value : null;
        if (this.selectedCategoryId !== newSelectedCategoryId) {
            this.selectedCategoryId = newSelectedCategoryId;
            this.selectedCategoryChangedEvent.emit(this.selectedCategoryId);
        }
    }

    onSelectedProductChanged(value: string) {
        let newSelectedProductId = value !== null && value !== undefined ? +value : null;
        if (this.selectedProductId !== newSelectedProductId) {
            this.selectedProductId = newSelectedProductId;
            this.selectedProductChangedEvent.emit(this.selectedProductId);
        }
    }

    onSelectedPickupLocationChanged(value: string) {
        let newSelectedPickupLocationId = value !== null && value !== undefined ? +value : null;
        if (this.selectedPickupLocationId !== newSelectedPickupLocationId) {
            this.selectedPickupLocationId = newSelectedPickupLocationId;
            this.selectedPickupLocationChangedEvent.emit(this.selectedPickupLocationId);
        }
    }

    onSelectedDropoffLocationChanged(value: string) {
        let newSelectedDropoffLocationId = value !== null && value !== undefined ? +value : null;
        if (this.selectedDropoffLocationId !== newSelectedDropoffLocationId) {
            this.selectedDropoffLocationId = newSelectedDropoffLocationId;
            this.selectedDropoffLocationChangedEvent.emit(this.selectedDropoffLocationId);
        }
    }

    onCancel() {
        this.closeEvent.emit();
    }

    onSubmit() {

        // Collect tiers
        let tiersWithQuantities: TierWithQuantityModel[] = [];

        for (let control of (<FormArray>this.form.get('tiersWithQuantities')).controls) {
            if (control.value.tierQuantity !== null && control.value.tierQuantity !== undefined) {
                tiersWithQuantities.push(<TierWithQuantityModel>control.value);
            }
        }

        let selectedPickupLocationId: number = Number(this.form.get('selectedPickupLocationId').value);
        let selectedDropoffLocationId: number = Number(this.form.get('selectedDropoffLocationId').value);

        let productAvailabilityId: number = Number(this.form.get('selectedStartTime').value);
        let productAvailabilityReturnTripId: number = Number(this.form.get("selectedStartTimeReturnTrip").value);

        if (productAvailabilityId.toString() === this.ANY_TIME) {
            productAvailabilityId = null;
        }

        if (productAvailabilityReturnTripId.toString() === this.ANY_TIME) {
            productAvailabilityReturnTripId = null;
        }

        if (!this.product.usesPickupLocations) {
            selectedPickupLocationId = null;
        }

        if (!this.product.usesDropoffLocations) {
            selectedDropoffLocationId = null;
        }

        if (!this.product.isRoundTripProduct) {
            productAvailabilityReturnTripId = null;
        }

        // Validate form
        if (!this.form.valid) {

            if (!this.form.get("description").valid) {
                this.errorMessage = "Description not provided!";
                return;
            }

            if (!this.form.get("color").valid) {
                this.errorMessage = "Color not provided!";
                return;
            }

            if (!this.form.get("selectedCategoryId").valid) {
                this.errorMessage = "Category not selected!";
                return;
            }

            if (!this.form.get("selectedProductId").valid) {
                this.errorMessage = "Product not selected!";
                return;
            }

            if (this.product.usesPickupLocations && !this.selectedPickupLocationId) {
                this.errorMessage = "Pickup location not selected!";
                return;
            }

            if (this.product.usesDropoffLocations && !this.selectedDropoffLocationId) {
                this.errorMessage = "Dropoff location not selected!";
                return;
            }

            if (!this.form.get("selectedStartTime").valid) {
                this.errorMessage = "Start time not selected!";
                return;
            }

            if (this.product.isRoundTripProduct && !this.form.get("selectedStartTimeReturnTrip").valid) {
                this.errorMessage = "Start time return trip not selected!";
                return;
            }
        }

        if (tiersWithQuantities.length === 0) {
            this.errorMessage = "At least one tier has to have quantity specified!";
            return;
        }

        if (!this.form.get('useLastName').value && this.form.get('lastNameRequired').value) {
            this.errorMessage = "Last name required, but last name not set to be used!";
            return;
        }

        if (!this.form.get('useEmail').value && this.form.get('emailRequired').value) {
            this.errorMessage = "Email required, but email not set to be used!";
            return;
        }

        if (!this.form.get('usePhone').value && this.form.get('phoneRequired').value) {
            this.errorMessage = "Phone required, but phone not set to be used!";
            return;
        }

        const data = new SubmitFormDialogDataModel(
            this.form.get('description').value,
            this.form.get('selectedCategoryId').value,
            this.form.get('selectedProductId').value,
            selectedPickupLocationId,
            selectedDropoffLocationId,
            productAvailabilityId,
            productAvailabilityReturnTripId,
            this.form.get('useLastName').value,
            this.form.get('lastNameRequired').value,
            this.form.get('useEmail').value,
            this.form.get('emailRequired').value,
            this.form.get('usePhone').value,
            this.form.get('phoneRequired').value,
            this.form.get('useDiscountCode').value,
            this.form.get('useRoundTrip').value,
            this.form.get('color').value,
            tiersWithQuantities
        );

        this.submitEvent.emit(data);
    }

    getTiersWithQuantitiesFormControls() {
        return (<FormArray>this.form.get('tiersWithQuantities')).controls;
    }
}
