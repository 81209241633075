import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EditOrderDetailEventDataModel} from "./product-detail-dialog-summary/product-detail-dialog-summary.component";
import {OrderDetailSummaryResponseModel} from "../../../../../../core/modules/rest/order-summary/response/order-detail-summary-response.model";
import {ProductSimpleResponseModel} from "../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {StartTimeResponseModel} from "../../../../../../core/modules/rest/product-availability/response/start-time-response.model";
import {DateChangedDataModel} from "../../model/date-changed-data.model";

@Component({
    selector: 'app-product-details-dialog-summary',
    templateUrl: './product-details-dialog-summary.component.html'
})
export class ProductDetailsDialogSummaryComponent {

    @Input() allowFixedTimeForFlexibleAndMultipleCheckInProducts: boolean = false;
    @Input() startTimesForEditedOrderDetailLoading: boolean;
    @Input() startTimesForEditedOrderDetail: StartTimeResponseModel[];
    @Input() products: ProductSimpleResponseModel[];
    @Input() orderDetails: OrderDetailSummaryResponseModel[];
    @Input() hasEditPermission: boolean = false;
    @Input() hasCompletedEditPermission: boolean = false;

    @Output() orderDetailDataEdit: EventEmitter<EditOrderDetailEventDataModel> = new EventEmitter();
    @Output() noShowClicked: EventEmitter<number> = new EventEmitter();
    @Output() selectedDateChanged: EventEmitter<DateChangedDataModel> = new EventEmitter();

    orderDetailIdEditing: number = null;

    constructor() {
    }

    onSaveClicked(data: EditOrderDetailEventDataModel) {
        this.orderDetailIdEditing = null;
        this.orderDetailDataEdit.emit(data);
    }

    onCancelClick() {
        this.orderDetailIdEditing = null;
    }

    onEditClick(orderDetailId: number) {
        this.orderDetailIdEditing = orderDetailId;
    }

    onNoShowClicked(orderDetailId: number) {
        this.noShowClicked.emit(orderDetailId);
    }

    onSelectedDateChanged(data: DateChangedDataModel) {
        this.selectedDateChanged.emit(data);
    }

    isEditEnabled(orderDetailId: number): boolean {

        if (this.orderDetailIdEditing === null) {
            let orderDetail: OrderDetailSummaryResponseModel = this.orderDetails.find((od) => od.orderDetailId === orderDetailId);
            return orderDetail.orderStatus !== "CANCELLED" && ( orderDetail.occurrenceId !== null || (orderDetail.orderDetailFields !== null && orderDetail.orderDetailFields.length > 0));
        } else {
            return false;
        }
    }
}
