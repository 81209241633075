import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class WebSocketService {
  private url: string;
  private socket: WebSocket | null = null;
  private pingInterval: any = null;
  private reconnectTimer: any = null;
  private messageReceived$: EventEmitter<any> = new EventEmitter<any>();

  constructor(url: string) {
    this.url = url;
  }

  public connect(): void {
    this.socket = new WebSocket(this.url);

    this.socket.onopen = () => {
      // Start sending ping messages
      this.setPingTimer();

      // Set a reconnect timer to reconnect after 2 hours
      this.setReconnectTimer();
    };

    this.socket.onmessage = (message) => {
      if (JSON.parse(message.data) !== 'pong') {
        this.messageReceived$.emit(JSON.parse(message.data));
      }
      // Reset ping interval upon receiving any mfessage
      this.setPingTimer();
    };

    this.socket.onclose = () => {
      // Clear the ping interval and reconnect timer if the connection is closed
      clearInterval(this.pingInterval);
      clearTimeout(this.reconnectTimer);
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  public close(): void {
    if (this.socket) {
      this.socket.close();
      clearInterval(this.pingInterval);
      clearTimeout(this.reconnectTimer);
    }
  }

  private setPingTimer(): void {
    // Clear any existing reconnect timer
    clearInterval(this.pingInterval);

    // Set a timer to send ping messages
    this.pingInterval = setTimeout(() => {
        if (this.socket) {
            const message = { action: 'sendNotification', message: 'ping' };
            this.socket.send(JSON.stringify(message));
        }
        this.connect();
    }, 570000); // Reset interval to send ping every 9.5 minutes 570000
  }

  private setReconnectTimer(): void {
    // Clear any existing reconnect timer
    clearTimeout(this.reconnectTimer);

    // Set a timer to reconnect after 2 hours (adjust as needed)
    this.reconnectTimer = setTimeout(() => {
      if (this.socket) {
        this.close();
        this.connect();
      }
    }, 6900000); // 1:55 hours in milliseconds
  }
}
