<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? 'Edit Tier' : 'Add New Tier' }}</h1>
            </div>
        </div>
    </div>

    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Description</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Name"
                               formControlName="description">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-6">
                    <app-input-label-group>
                        <label class="label">Minimum Age</label>
                        <input class="main--element input-large"
                               type="number"
                               min="0"
                               name="min age"
                               placeholder="Minimum Age"
                               formControlName="minimumAge"
                               appNumericInput
                               numericType="number">
                    </app-input-label-group>
                </div>

                <div class="cell medium-6">
                    <app-input-label-group>
                        <label class="label">Maximum Age</label>
                        <input class="main--element input-large"
                               type="number"
                               min="0"
                               name="max age"
                               placeholder="Maximum Age"
                               formControlName="maximumAge"
                               appNumericInput
                               numericType="number">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <!--<app-checkbox-->
                    <!--[labelName]="'Use Alternate Check In Sound'">-->

                    <!--</app-checkbox>-->
                    <div class="checkboxLabelGroup">
                        <input type="checkbox"
                               id="checkbox-use-alternated-check-in-sound"
                               formControlName="useAlternateCheckInSound"/>
                        <label for="checkbox-use-alternated-check-in-sound">
                            Use Alternate Check In Sound
                        </label>
                    </div>

                    <div class="checkboxLabelGroup">
                        <input type="checkbox"
                               id="checkbox-manual-acceptance-required"
                               formControlName="manualAcceptanceRequired"/>
                        <label for="checkbox-manual-acceptance-required">
                            Manual Acceptance Required
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div><!-- Dialog Content :: END -->

    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>

            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid || !ageRangeValid"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
