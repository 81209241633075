<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? 'Edit Sale Group' : 'Add New Sale Group' }}</h1>
            </div>
        </div>
    </div>


    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Name</label>
                        <input class="main--element input-large"
                               type="text"
                               name="saleGroupName"
                               formControlName="saleGroupName">
                    </app-input-label-group>
                    <div class="cell medium-12 mt-10px">
                        <app-checkbox
                            [labelName]="'Owner Type Group'"
                            [checked]="form.value.useOwnerTypeGroup"
                            [formControlName]="'useOwnerTypeGroup'">
                        </app-checkbox>
                        <app-checkbox
                            [labelName]="'Domain Type Group'"
                            [checked]="form.value.useDomainTypeGroup"
                            [formControlName]="'useDomainTypeGroup'">
                        </app-checkbox>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- Dialog Content :: END -->


    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>


            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div>
    <!-- Footer :: END -->

</form>
