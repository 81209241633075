export enum PermissionTypeEnum {
    DYNAMIC,
    PERMISSION
}

export enum DynamicPermissionTypeEnum {
    PERMISSIONS_CUSTOMER,
    SALE_GROUPS,
    POST_BILL_TYPES
}

export class PermissionNode {

    type: PermissionTypeEnum;
    subtype: DynamicPermissionTypeEnum;
    id: number | string;
    label: string;
    inherited: boolean;

    parent: PermissionNode;
    children: PermissionNode[];

    open: boolean;
    visible: boolean;
    checked: boolean;

    readonly _originalChecked: boolean;
    readonly _originalInherited: boolean;

    constructor(type: PermissionTypeEnum, subtype: DynamicPermissionTypeEnum, id: number | string, label: string, checked: boolean, children: PermissionNode[] = [], inherited: boolean = false) {

        this.type = type;
        this.subtype = subtype;
        this.id = id;
        this.label = label;
        this.inherited = inherited;

        this.checked = checked;
        this.children = children;

        this.open = false;
        this.visible = true;

        this.children.forEach(c => c.parent = this);

        this._originalChecked = checked;
        this._originalInherited = inherited;

        this._recalculateVisibility(this);
    }

    /**
     * Toggles productAvailabilitiesSidebarFilterOpen state of node
     */
    toggleOpen(open: boolean): void {

        this.visible = true;
        this.open = open;

        this.children.forEach(n => {
            n.visible = this.open;
        });
    }

    /**
     * Checks if node has changes (has selection changed)
     * */
    hasChanges(): boolean {
        return this._originalChecked !== this.checked;
    }

    /**
     * Updates node selection if any of child node is selected (only first-level children)
     */
    updateSelection(assigned: boolean): void {

        let checkedNodes: PermissionNode[] = this.children.filter(n => n.checked);

        this.checked = checkedNodes.length !== 0 || assigned;
    }

    private _recalculateVisibility(node: PermissionNode) {
        node.visible = node.parent === undefined;
        node.children.forEach(c => this._recalculateVisibility(c));
    }
}
