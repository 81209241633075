import * as actions from './actions';
import {KioskConfigurationDropoffLocationProductTierModel} from "../../model/kiosk-configuration-dropoff-location-product-tier.model";
import {createFeatureSelector, createSelector} from "@ngrx/store";

export const REDUCER_NAME = "dialog_kiosk-configuration-details-product-tier-setup";

export interface State {
    productTiers: KioskConfigurationDropoffLocationProductTierModel[];
}

const INITIAL_STATE: State = {
    productTiers: []
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const productTiers_selector = createSelector(state_selector, (state: State) => state.productTiers);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return INITIAL_STATE;

        case actions.SET_PRODUCT_TIERS: return { ... state, productTiers: action.productTiers };

        default: return state;
    }
}
