import { Component, OnInit } from '@angular/core';
import { RootSandbox } from '../../../../../core/store/root.sandbox';
import { AppRoutesService } from '../../../../../core/services/app-routes.service';
import { PermissionEnum } from "../../../../../shared/enums/permission.enum";
import { SystemPermissionsRestService } from '../../../../../core/modules/rest/system-permissions/system-permissions-rest.service';
import { SystemPermissionsResponseModel } from '../../../../../core/modules/rest/system-permissions/response/system-permissions-response.model';
import { SystemPermissionEnum } from '../../../../enums/system-permission.enum';


@Component({
    selector: 'app-secondary-nav-reports',
    templateUrl: './secondary-nav-reports.component.html'
})
export class SecondaryNavReportsComponent implements OnInit {

    appRoutesService: AppRoutesService;
    systemPermissions: SystemPermissionsResponseModel[];

    constructor(appRoutesService: AppRoutesService,
        private rootSandbox: RootSandbox,
        private systemPermissionsRestService: SystemPermissionsRestService
    ) {
        this.appRoutesService = appRoutesService;

    }

    userPermissions: string[];

    ngOnInit() {
        this.systemPermissionsRestService.getSystemPermissions().subscribe((res: SystemPermissionsResponseModel[]) => {
            this.systemPermissions = res;
        });
        this.rootSandbox.getUserInfo().subscribe(res => {
            if (res) {
                this.userPermissions = res.permissions;
            }
        });
    }

    userSystemPermissions(item: string) {
        let isPresent: boolean = this.userPermissions.includes(item);
        return isPresent;
    }

    allSystemPermissions() {
        return PermissionEnum;
    }

    customReportPermission() {
        var customReportsUsed = false;

        if(this.systemPermissions != undefined) {
            this.systemPermissions.forEach( permission => {
                if (permission.active &&  (permission.description === "REVENUE_REPORT" || permission.description === "NET_SUITE_EXPORT_REPORT" || permission.description === "CUSTOM_BILLING_REPORT")) {
                    customReportsUsed = true;
                }
            });
        }
        if(!customReportsUsed){

            return false;
        }
        else{
            return this.systemPermissions ? this.customRevenueReportPermission() || this.customNetSuiteExportReportPermission() || this.customCustomBillingReportReportPermission : false;
        }

    }

    customRevenueReportPermission() {
        return this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.REVENUE_REPORT)?.active ?? false;
    }

    customNetSuiteExportReportPermission() {
        return this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.NET_SUITE_EXPORT_REPORT)?.active ?? false;
    }

    customCustomBillingReportReportPermission() {
        return this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.CUSTOM_BILLING_REPORT)?.active ?? false;
    }
}
