import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SystemPermissionsResponseModel} from "./response/system-permissions-response.model";
import {environment} from "../../../../../environments/environment";
import { SystemPermissionsRequestModel } from "./request/system-permissions-request.model";

@Injectable()
export class SystemPermissionsRestService {

    constructor(private httpClient: HttpClient) {
    }

    getSystemPermissions(): Observable<SystemPermissionsResponseModel[]> {
        return this.httpClient.get<SystemPermissionsResponseModel[]>(environment.server_api_url + '/api/secured/systemPermissions/getSystemPermissions');
    }

    updateSystemPermission(systemPermissionsRequest: SystemPermissionsRequestModel): Observable<SystemPermissionsRequestModel> {
        return this.httpClient.post<SystemPermissionsResponseModel>(environment.server_api_url + '/api/secured/systemPermissions/updateSystemPermission', systemPermissionsRequest);
    }

}
