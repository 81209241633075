import {Action} from "@ngrx/store";
import {TreeNode} from "../../../../../../../shared/components/tree/tree.component";
import {PassBasicInfoDataModel} from "../../model/pass-basic-info-data.model";
import {PassPricingDateRangesDataModel} from "../../model/pass-pricing-date-ranges-data.model";
import {TreeItemTypeEnum} from "../../model/tree-item-type.enum";
import {PassProductsDataModel} from "../../model/pass-products-data.model";
import {PassImagesDataModel} from "../../model/pass-images-data.model";
import {PassTabItemEnum} from "../../model/pass-tab-item.enum";
import {PassFlagsDataModel} from "../../model/pass-flags-data.model";
import {PassValidityDataModel} from "../../model/pass-validity-data.model";
import {PassEmailHtmlTemplatesDataModel} from "../../model/pass-email-html-templates-data.model";
import {PassTemplateItemsDataModel} from "../../model/pass-template-items-data.model";
import {PassOrPassCardBackgroundImageDataModel} from "../../model/pass-or-pass-card-background-image-data.model";
import {LocationResponseModel} from "../../../../../../../core/modules/rest/location/response/location-response.model";
import {PassPricingDateRangesSidebarFilterTypeEnum} from "../../model/pass-pricing-date-ranges-sidebar-filter-type.enum";
import {TierResponseModel} from "../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {PassPricingDateRangesSidebarFilterDataModel} from "../../model/pass-pricing-date-ranges-sidebar-filter-data.model";
import {PassCostsDataModel} from "../../model/pass-costs-data.model";
import {SortByModel} from "../../../../../../../shared/model/sort-by.model";

export const RESET_STATE = "[Manage Passes :: MAIN] Reset State";
export const SET_LOADING_PASSES = "[Manage Passes :: MAIN] Loading Passes";
export const SET_PASSES_TREE = "[Manage Passes :: MAIN] Set Passes";
export const SET_SHOW_INACTIVE_ITEMS = "[Manage Passes :: MAIN] Show Inactive Items";
export const SET_SELECTED_ITEM = "[Manage Passes :: MAIN] Set Selected Item";
export const SET_SELECTED_NODE = "[Manage Passes :: MAIN] Set Selected Node";
export const SET_LOADING_PASS_FULL = "[Manage Passes :: MAIN] Set Loading Pass Full";
export const SET_LOADING_PASS_IMAGES = "[Manage Passes :: MAIN] Set Loading Pass Images";
export const SET_LOADING_PASS_TEMPLATE_ITEMS = "[Manage Passes :: MAIN] Set Loading Pass Template Items";
export const SET_LOADING_PASS_PRICING_DATE_RANGES = "[Manage Passes :: MAIN] Set Loading Pass Pricing Date Ranges";
export const SET_SELECTED_PASS_TAB_ITEM = "[Manage Passes :: MAIN] Set Selected Pass Tab Item";
export const SET_PASS_BASIC_INFO = "[Manage Passes :: MAIN] Set Pass Basic Info";
export const SET_PASS_PRODUCTS = "[Manage Passes :: MAIN] Set Pass Products";
export const SET_PASS_IMAGES = "[Manage Passes :: MAIN] Set Pass Images";
export const SET_PASS_IMAGE = "[Manage Passes :: MAIN] Set Pass Image";
export const SET_PASS_CARD_BACKGROUND_IMAGE = "[Manage Passes :: MAIN] Set PassCard Background Image";
export const SET_PASS_FLAGS = "[Manage Passes :: MAIN] Set Pass Flags";
export const SET_PASS_VALIDITY = "[Manage Passes :: MAIN] Set Pass Validity";
export const SET_PASS_EMAIL_HTML_TEMPLATES = "[Manage Passes :: MAIN] Set Pass Email HTML Templates";
export const SET_PASS_COSTS = "[Manage Passes :: MAIN] Set Pass Costs";
export const SET_PASS_TEMPLATE_ITEMS = "[Manage Passes :: MAIN] Set Pass Template Items";
export const RESET_PASS_PRICING_DATE_RANGES = "[Manage Passes :: MAIN] Reset Pass Pricing Date Ranges And Pass Pricing Date Ranges Sidebar Filter";
export const SET_PASS_PRICING_DATE_RANGES = "[Manage Passes :: MAIN] Set Pass Pricing Date Ranges";
export const SET_PASS_PRICING_DATE_RANGES_FROM = "[Manage Passes :: MAIN] Set Pass Pricing Date Ranges From";
export const SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_OPEN = "[Manage Passes :: MAIN] Set Pass Pricing Date Ranges Sidebar Filter Open";
export const SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_PICKUP_LOCATIONS = "[Manage Passes :: MAIN] Set Pass Pricing Date Ranges Sidebar Filter Pickup Locations";
export const SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_DROPOFF_LOCATIONS = "[Manage Passes :: MAIN] Set Pass Pricing Date Ranges Sidebar Filter Dropoff Locations";
export const SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_TIERS = "[Manage Passes :: MAIN] Set Pass Pricing Date Ranges Sidebar Filter Tiers";
export const SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_DATA = "[Manage Passes :: MAIN] Set Pass Pricing Date Ranges Sidebar Filter Data";
export const UPDATE_PASS_PRICING_DATE_RANGES_CURRENT_SEARCH_DATA_SORT_BY = "[Manage Passes :: MAIN] Update Pass Pricing Date Ranges Current Search Data Sort By";

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetLoadingPasses implements Action {
    readonly type = SET_LOADING_PASSES;

    constructor(public loading: boolean) {
    }
}

export class SetPassesTree implements Action {
    readonly type = SET_PASSES_TREE;

    constructor(public tree: TreeNode[]) {
    }
}

export class SetShowInactiveItems implements Action {
    readonly type = SET_SHOW_INACTIVE_ITEMS;

    constructor(public showInactiveItems: boolean) {
    }
}

export class SetSelectedItem implements Action {
    readonly type = SET_SELECTED_ITEM;

    constructor(public item: TreeNode, public itemType: TreeItemTypeEnum, public itemId: number, public itemActive: boolean) {
    }
}

export class SetSelectedNode implements Action {
    readonly type = SET_SELECTED_NODE;

    constructor(public node: TreeNodeMaterial) {
    }
}

export class SetLoadingPassFull implements Action {
    readonly type = SET_LOADING_PASS_FULL;

    constructor(public loading: boolean) {
    }
}

export class SetLoadingPassImages implements Action {
    readonly type = SET_LOADING_PASS_IMAGES;

    constructor(public loading: boolean) {
    }
}

export class SetLoadingPassTemplateItems implements Action {
    readonly type = SET_LOADING_PASS_TEMPLATE_ITEMS;

    constructor(public loading: boolean) {
    }
}

export class SetLoadingPassPricingDateRanges implements Action {
    readonly type = SET_LOADING_PASS_PRICING_DATE_RANGES;

    constructor(public loading: boolean) {
    }
}

export class SetSelectedPassTabItem implements Action {
    readonly type = SET_SELECTED_PASS_TAB_ITEM;

    constructor(public passTabItem: PassTabItemEnum) {
    }
}

export class SetPassBasicInfo implements Action {
    readonly type = SET_PASS_BASIC_INFO;

    constructor(public passBasicInfo: PassBasicInfoDataModel) {
    }
}

export class SetPassProducts implements Action {
    readonly type = SET_PASS_PRODUCTS;

    constructor(public passProducts: PassProductsDataModel) {
    }
}

export class SetPassImages implements Action {
    readonly type = SET_PASS_IMAGES;

    constructor(public passImages: PassImagesDataModel) {
    }
}

export class SetPassImage implements Action {
    readonly type = SET_PASS_IMAGE;

    constructor(public passImage: PassOrPassCardBackgroundImageDataModel) {
    }
}

export class SetPassCardBackgroundImage implements Action {
    readonly type = SET_PASS_CARD_BACKGROUND_IMAGE;

    constructor(public passCardBackgroundImage: PassOrPassCardBackgroundImageDataModel) {
    }
}

export class SetPassFlags implements Action {
    readonly type = SET_PASS_FLAGS;

    constructor(public passFlags: PassFlagsDataModel) {
    }
}

export class SetPassValidity implements Action {
    readonly type = SET_PASS_VALIDITY;

    constructor(public passValidity: PassValidityDataModel) {
    }
}

export class SetPassEmailHtmlTemplates implements Action {
    readonly type = SET_PASS_EMAIL_HTML_TEMPLATES;

    constructor(public passEmailHtmlTemplates: PassEmailHtmlTemplatesDataModel) {
    }
}

export class SetPassCosts implements Action {
    readonly type = SET_PASS_COSTS;

    constructor(public passCosts: PassCostsDataModel) {
    }
}

export class SetPassTemplateItems implements Action {
    readonly type = SET_PASS_TEMPLATE_ITEMS;

    constructor(public passTemplateItems: PassTemplateItemsDataModel) {
    }
}

export class ResetPassPricingDateRanges implements Action {
    readonly type = RESET_PASS_PRICING_DATE_RANGES;

    constructor() {
    }
}

export class SetPassPricingDateRanges implements Action {
    readonly type = SET_PASS_PRICING_DATE_RANGES;

    constructor(public append: boolean, public passPricingDateRanges: PassPricingDateRangesDataModel) {
    }
}

export class SetPassPricingDateRangesFrom implements Action {
    readonly type = SET_PASS_PRICING_DATE_RANGES_FROM;

    constructor(public passPricingDateRangesFrom: number) {
    }
}

export class SetPassPricingDateRangesSidebarFilterOpen implements Action {
    readonly type = SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_OPEN;

    constructor(public passPricingDateRangesSidebarFilterOpen: boolean, public passPricingDateRangesSidebarFilterCurrentFocus?: PassPricingDateRangesSidebarFilterTypeEnum) {
    }
}

export class SetPassPricingDateRangesSidebarFilterPickupLocations implements Action {
    readonly type = SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_PICKUP_LOCATIONS;

    constructor(public passPricingDateRangesSidebarFilterPickupLocations: LocationResponseModel[]) {
    }
}

export class SetPassPricingDateRangesSidebarFilterDropoffLocations implements Action {
    readonly type = SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_DROPOFF_LOCATIONS;

    constructor(public passPricingDateRangesSidebarFilterDropoffLocations: LocationResponseModel[]) {
    }
}

export class SetPassPricingDateRangesSidebarFilterTiers implements Action {
    readonly type = SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_TIERS;

    constructor(public passPricingDateRangesSidebarFilterTiers: TierResponseModel[]) {
    }
}

export class SetPassPricingDateRangesSidebarFilterData implements Action {
    readonly type = SET_PASS_PRICING_DATE_RANGES_SIDEBAR_FILTER_DATA;

    constructor(public passPricingDateRangesSidebarFilterData: PassPricingDateRangesSidebarFilterDataModel) {
    }
}

export class UpdatePassPricingDateRangesCurrentSearchDataSortBy implements Action {
    readonly type = UPDATE_PASS_PRICING_DATE_RANGES_CURRENT_SEARCH_DATA_SORT_BY;

    constructor(public passPricingDateRangesSortBy: SortByModel) {
    }
}

export type All = ResetState
    | SetLoadingPasses
    | SetPassesTree
    | SetShowInactiveItems
    | SetSelectedItem
    | SetSelectedNode
    | SetLoadingPassFull
    | SetLoadingPassImages
    | SetLoadingPassTemplateItems
    | SetLoadingPassPricingDateRanges
    | SetSelectedPassTabItem
    | SetPassBasicInfo
    | SetPassProducts
    | SetPassImages
    | SetPassImage
    | SetPassCardBackgroundImage
    | SetPassFlags
    | SetPassValidity
    | SetPassEmailHtmlTemplates
    | SetPassCosts
    | SetPassTemplateItems
    | ResetPassPricingDateRanges
    | SetPassPricingDateRanges
    | SetPassPricingDateRangesFrom
    | SetPassPricingDateRangesSidebarFilterOpen
    | SetPassPricingDateRangesSidebarFilterPickupLocations
    | SetPassPricingDateRangesSidebarFilterDropoffLocations
    | SetPassPricingDateRangesSidebarFilterTiers
    | SetPassPricingDateRangesSidebarFilterData
    | UpdatePassPricingDateRangesCurrentSearchDataSortBy;
