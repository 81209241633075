
import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {ProductRestService} from "../../../../../../../../core/modules/rest/product/product-rest.service";
import {ProductSimpleResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {DropdownOptionModel} from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {Observable} from "rxjs";
import {EnableCategoryProductsEnum} from "../components/enable-category-dialog-form/enable-category-dialog-form.component";
import {CategoryRestService} from "../../../../../../../../core/modules/rest/category/category-rest.service";
import {ActivateCategoryRequestModel} from "../../../../../../../../core/modules/rest/category/request/activate-category-request.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";

@Injectable()
export class EnableCategoryDialogSandbox extends DialogSandbox {

    products$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.products_selector));

    constructor(appDialogsService: AppDialogsService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox,
                private productRestService: ProductRestService,
                private categoryRestService: CategoryRestService) {
        super(appDialogsService);
    }

    load(categoryId: number) {
        this.productRestService.getProductsByCategoryId(categoryId)
            .subscribe((response: ProductSimpleResponseModel[]) => {
                this.store.dispatch(new actions.SetProducts(response.map(p => new DropdownOptionModel(p.description, p.productId.toString()))));
            });
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    enableCategory(categoryId: number, type: EnableCategoryProductsEnum, productIds: number[]): Observable<any> {
        return this.categoryRestService.activate(new ActivateCategoryRequestModel(categoryId, type === EnableCategoryProductsEnum.ALL_PRODUCTS, type === EnableCategoryProductsEnum.SOME_PRODUCTS ? productIds : [])).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError("Error while activating category", error);
            }));
    }
}
