import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {HolidayResponseModel} from "../../../../../../../../core/modules/rest/holiday/response/holiday-response.model";
import {HolidayFormSubmitDataModel} from "../model/holiday-form-submit-data.model";
import {EditHolidayDialogSandbox} from "../sandbox/edit-holiday-dialog.sandbox";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditHolidayDialogData extends DialogData {

    holidayId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, holidayId?: number) {
        super();
        this.holidayId = holidayId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
  selector: 'app-edit-holiday-dialog',
  templateUrl: './edit-holiday-dialog.component.html'
})
export class EditHolidayDialogComponent implements OnInit, OnDestroy {

    holiday$: Observable<HolidayResponseModel>;
    holidayId: number;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditHolidayDialogData, private sandbox: EditHolidayDialogSandbox) {
        this.holidayId = dialogData.holidayId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {
        if (this.holidayId && !isNaN(this.holidayId)) {
            this.editMode = true;
            this.sandbox.loadHoliday(this.holidayId);
            this.holiday$ = this.sandbox.holiday$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: HolidayFormSubmitDataModel) {
        if (this.editMode) {
            this.sandbox.updateHoliday(this.holidayId, data.description, data.selectedYear, data.selectedMonth, data.selectedDay).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createHoliday(data.description, data.selectedYear, data.selectedMonth, data.selectedDay).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}

