import {NgModule} from "@angular/core";
import {SharedModule} from "../../../shared.module";
import {ChangePasswordDialogComponent} from "./container/change-password-dialog.component";
import {ChangePasswordSandbox} from "./sandbox/sandbox";

@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        ChangePasswordSandbox
    ],
    exports: [
        ChangePasswordDialogComponent
    ],
    declarations: [
        ChangePasswordDialogComponent
    ],
    entryComponents: [
        ChangePasswordDialogComponent
    ]
})
export class ChangePasswordDialogModule {

}
