import {Observable} from 'rxjs';
import {LocationsResponseModel} from './response/locations-response.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {LocationResponseModel} from "./response/location-response.model";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {CreateLocationRequestModel} from "./request/create-location-request.model";
import {UpdateLocationRequestModel} from "./request/update-location-request.model";
import { LocationListResponseModel } from '../location-list/response/location-list-response.model';

@Injectable()
export class LocationRestService {

    constructor(private httpClient: HttpClient) {
    }

    getById(locationId: number): Observable<LocationResponseModel> {
        return this.httpClient.get<LocationResponseModel>(environment.server_api_url + '/api/secured/location/getById/' + locationId);
    }

    getAll(): Observable<LocationsResponseModel> {
        return this.httpClient.get<LocationsResponseModel>(environment.server_api_url + '/api/secured/location/getAll');
    }

    getAllReferencesByLocation(locationId: number): Observable<LocationListResponseModel[]> {
        return this.httpClient.post<LocationListResponseModel[]>(environment.server_api_url + '/api/secured/location/getAllReferencesByLocation', locationId);
    }

    getAllActive(): Observable<LocationsResponseModel> {
        return this.httpClient.get<LocationsResponseModel>(environment.server_api_url + '/api/secured/location/getAllActive');
    }

    create(request: CreateLocationRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/location/create', request);
    }

    update(request: UpdateLocationRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/location/update', request);
    }

    activate(locationId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/location/activate', locationId, {responseType: 'text'});
    }

    deactivate(locationId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/location/deactivate', locationId, {responseType: 'text'});
    }
}
