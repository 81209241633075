export class CreateBusRequestModel {
    number: number;
    description: string;
    capacity: number;

    constructor(number: number, description: string, capacity: number) {
        this.number = number;
        this.description = description;
        this.capacity = capacity;
    }
}
