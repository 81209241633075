import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {Observable} from "rxjs";
import {CommissionResponseModel} from "../../../../../../../../core/modules/rest/commission/response/commission-response.model";
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {EditCommissionDialogSandbox} from "../sandbox/edit-commission-dialog.sandbox";
import {CommissionFormSubmitDataModel} from "../model/commission-form-submit-data.model";
import {MultiselectDropdownOptionModel} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";


// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditCommissionDialogData extends DialogData {

    commissionId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, commissionId?: number) {
        super();
        this.commissionId = commissionId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------
@Component({
    selector: 'app-edit-commission-dialog',
    templateUrl: './edit-commission-dialog.component.html'
})
export class EditCommissionDialogComponent implements OnInit, OnDestroy {

    commission$: Observable<CommissionResponseModel>;
    amountTypes$: Observable<MultiselectDropdownOptionModel[]>;
    commissionId: number;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditCommissionDialogData, private sandbox: EditCommissionDialogSandbox) {
        this.commissionId = dialogData.commissionId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
        this.commission$ = this.sandbox.commission$;
        this.amountTypes$ = this.sandbox.getAmountTypesForMultiselectDropdown();
    }

    ngOnInit() {
        if (this.commissionId && !isNaN(this.commissionId)) {
            this.editMode = true;
            this.sandbox.loadCommission(this.commissionId);
            this.commission$ = this.sandbox.commission$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: CommissionFormSubmitDataModel) {
        if (this.editMode) {
            this.sandbox.updateCommission(this.commissionId, data).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createCommission(data).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}
