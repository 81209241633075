
<app-filter-date
    *ngIf="filterItem.type === 'DATE'"
    [filterItem]="filterItem"
    [hasRunOrderReportFullPermission]="hasRunOrderReportFullPermission"
    (changed)="onChanged($event)">
</app-filter-date>


<app-filter-dropdown-multiselect
    *ngIf="filterItem.type === 'DROPDOWN_MULTISELECT'"
    [filterItem]="filterItem"
    [hasRunOrderReportFullPermission]="hasRunOrderReportFullPermission"
    (changed)="onChanged($event)">
</app-filter-dropdown-multiselect>


<app-filter-input
    *ngIf="filterItem.type === 'INPUT_STRING'"
    [filterItem]="filterItem"
    [hasRunOrderReportFullPermission]="hasRunOrderReportFullPermission"
    (changed)="onChanged($event)">
</app-filter-input>
