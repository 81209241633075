import {Action} from "@ngrx/store";
import {OrderSummaryResponseModel} from "../../../../../../core/modules/rest/order-summary/response/order-summary-response.model";
import {UserResponseModel} from "../../../../../../core/modules/rest/user/response/user-response.model";
import {LocationResponseModel} from "../../../../../../core/modules/rest/location/response/location-response.model";
import {ProductSimpleResponseModel} from "../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {StartTimeResponseModel} from "../../../../../../core/modules/rest/product-availability/response/start-time-response.model";

export const RESET_STATE_ACTION = '[Dialog - Order Summary :: MAIN] Reset state';
export const SET_ORDER_ACTION = '[Dialog - Order Summary :: MAIN] Set Order';
export const SET_USERS_ACTION = '[Dialog - Order Summary :: MAIN] Set Users';
export const SET_LOCATIONS_ACTION = '[Dialog - Order Summary :: MAIN] Set Locations';
export const SET_PRODUCTS_ACTION = '[Dialog - Order Summary :: MAIN] Set Products';
export const SET_START_TIMES_FOR_EDITED_ORDER_DETAIL_LOADING_ACTION = '[Dialog - Order Summary :: MAIN] Set Start Times For Edited Order Detail Loading';
export const SET_START_TIMES_FOR_EDITED_ORDER_DETAIL_ACTION = '[Dialog - Order Summary :: MAIN] Set Start Times For Edited Order Detail';


export class ResetState implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class SetOrder implements Action {
    readonly type = SET_ORDER_ACTION;

    constructor(public orderSummary: OrderSummaryResponseModel) {
    }
}

export class SetUsers implements Action {
    readonly type = SET_USERS_ACTION;

    constructor(public users: UserResponseModel[]) {
    }
}

export class SetLocations implements Action {
    readonly type = SET_LOCATIONS_ACTION;

    constructor(public locations: LocationResponseModel[]) {
    }
}

export class SetProducts implements Action {
    readonly type = SET_PRODUCTS_ACTION;

    constructor(public products: ProductSimpleResponseModel[]) {
    }
}

export class SetStartTimesForEditedOrderDetailLoading implements Action {
    readonly type = SET_START_TIMES_FOR_EDITED_ORDER_DETAIL_LOADING_ACTION;

    constructor(public startTimesForEditedOrderDetailLoading: boolean) {
    }
}

export class SetStartTimesForEditedOrderDetail implements Action {
    readonly type = SET_START_TIMES_FOR_EDITED_ORDER_DETAIL_ACTION;

    constructor(public startTimesForEditedOrderDetail: StartTimeResponseModel[]) {
    }
}

export type All = ResetState | SetOrder | SetUsers | SetLocations | SetProducts | SetStartTimesForEditedOrderDetailLoading | SetStartTimesForEditedOrderDetail;
