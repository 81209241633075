import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditAutoAppliedDiscountDialogComponent} from "./container/edit-auto-applied-discount-dialog.component";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditAutoAppliedDiscountDialogContentComponent} from './components/edit-auto-applied-discount-dialog-content/edit-auto-applied-discount-dialog-content.component';
import {EditAutoAppliedDiscountDialogSandbox} from "./sandbox/edit-auto-applied-discount-dialog.sandbox";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditAutoAppliedDiscountDialogSandbox
    ],
    exports: [
        EditAutoAppliedDiscountDialogComponent
    ],
    declarations: [
        EditAutoAppliedDiscountDialogComponent,
        EditAutoAppliedDiscountDialogContentComponent
    ],
    entryComponents: [
        EditAutoAppliedDiscountDialogComponent
    ]
})
export class EditAutoAppliedDiscountDialogModule {
}
