import { ShoppingCartItemTypeEnum } from "../enum/shopping-cart-item-type.enum";
import { ShoppingCartProductResponseModel } from "../../../../../../../core/modules/rest/shopping-cart/response/shopping-cart-product-response.model";
import { ShoppingCartPassResponseModel } from "../../../../../../../core/modules/rest/shopping-cart/response/shopping-cart-pass-response.model";
import { ShoppingCartItemDataEqualityModel } from "./shopping-cart-item-data-equality.model";

export class ShoppingCartItemDataModel extends ShoppingCartItemDataEqualityModel {

    type: ShoppingCartItemTypeEnum;

    parentProduct: ShoppingCartProductResponseModel;
    childProduct: ShoppingCartProductResponseModel;

    pass: ShoppingCartPassResponseModel;

    editMode: boolean;

    constructor() {
        super();
    }
}
