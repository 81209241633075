<form [formGroup]="productAvailabilityForm">
    <div class="order-form-item">
        <label class="order-label">{{getOriginalDescriptionFriendly(productAvailabilityForm.value.originalDescription)}}</label>
        <div class="order-input-wrapper">
            <input class="main--element input-large"
                   placeholder="Clone Name"
                   [formControlName]="'cloneDescription'"/>
        </div>
    </div>
</form>
