import {SortByRequestModel} from "../../../request/sort-by-request.model";
import {DateTimeDescriptor} from "../../../../../../../shared/model/date-time-descriptor.model";
import {ProductDirectionDescriptorRequestModel} from "./product-direction-descriptor-request.model";

export class DriverManifestReportRequestModel {
    fromDate: DateTimeDescriptor;
    toDate: DateTimeDescriptor;
    productDirectionDescriptors: ProductDirectionDescriptorRequestModel[];
    sortBy: SortByRequestModel;

    constructor(fromDate: DateTimeDescriptor, toDate: DateTimeDescriptor, productDirectionDescriptors: ProductDirectionDescriptorRequestModel[], sortBy: SortByRequestModel) {
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.productDirectionDescriptors = productDirectionDescriptors;
        this.sortBy = sortBy;
    }
}
