import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {KioskConfigurationResponseModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-response.model";
import {EditKioskConfigurationDialogSandbox} from "../sandbox/edit-kiosk-configuration-dialog.sandbox";
import {LocationResponseModel} from "../../../../../../../../core/modules/rest/location/response/location-response.model";
import {EditKioskConfigurationDialogFormSubmitData} from "../model/edit-kiosk-configuration-dialog-form-submit-data.model";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditKioskConfigurationDialogData extends DialogData {

    kioskConfigurationId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, kioskConfigurationId?: number) {
        super();
        this.kioskConfigurationId = kioskConfigurationId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
//                                 Component that represents it                                   //
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-kiosk-configuration-dialog',
    templateUrl: './edit-kiosk-configuration-dialog.component.html'
})
export class EditKioskConfigurationDialogComponent implements OnInit, OnDestroy {

    kioskConfigurationId: number;

    kioskConfiguration$: Observable<KioskConfigurationResponseModel>;
    pickupLocations$: Observable<LocationResponseModel[]>;

    editMode: boolean = false;

    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditKioskConfigurationDialogData,
                private sandbox: EditKioskConfigurationDialogSandbox) {

        this.kioskConfigurationId = dialogData.kioskConfigurationId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit(): void {

        if (this.kioskConfigurationId) {
            this.editMode = true;
        }

        this.sandbox.loadDialogData(this.kioskConfigurationId);

        this.kioskConfiguration$ = this.sandbox.kioskConfiguration$;
        this.pickupLocations$ = this.sandbox.pickupLocations$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: EditKioskConfigurationDialogFormSubmitData) {

        if (this.editMode) {
            this.sandbox.updateKioskConfiguration(this.kioskConfigurationId, data.description, data.pickupLocationId)
                .subscribe(() => {
                    this.updateCallbackFn();
                    this.sandbox.destroyDialog();
                });
        } else {
            this.sandbox.addNewKioskConfiguration(data.description, data.pickupLocationId)
                .subscribe(() => {
                    this.createCallbackFn();
                    this.sandbox.destroyDialog();
                });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }

}
