<div class="pass-dialog-content">
    <h1 class="mb-8px">Edit MultiPass Costs</h1>
    <form class="pl-8px pr-8px" [formGroup]="form">
        <ng-container>
            <div class="body_wrapper formRow">
                <div class="order-form-item">
                    <label class="order-label">Additional Costs</label>
                    <div class="order-input-wrapper">
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="loading"
                            [allOptionEnabled]="true"
                            [options]="additionalCosts"
                            [formControlName]="'additionalCostIds'">
                        </app-multiselect-dropdown>
                    </div>
                </div>
                <!-- <div class="order-form-item">
                    <label class="order-label">Included Costs</label>
                    <div class="order-input-wrapper">
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="loading"
                            [allOptionEnabled]="true"
                            [options]="includedCosts"
                            [formControlName]="'includedCostIds'">
                        </app-multiselect-dropdown>
                    </div>
                </div> -->
                <div class="order-form-item">
                    <label class="order-label">Discounts</label>
                    <div class="order-input-wrapper">
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="loading"
                            [allOptionEnabled]="true"
                            [options]="discounts"
                            [formControlName]="'discountIds'">
                        </app-multiselect-dropdown>
                    </div>
                </div>
                <div class="order-form-item">
                    <label class="order-label">Auto Applied Discounts</label>
                    <div class="order-input-wrapper">
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="loading"
                            [allOptionEnabled]="true"
                            [options]="autoAppliedDiscounts"
                            [formControlName]="'autoAppliedDiscountIds'">
                        </app-multiselect-dropdown>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>
    <div class="dialog-action-buttons-wrapper pt-24px">
        <button class="button ghost-button" (click)="onCancel()">Cancel</button>
        <button class="button primaryButton" (click)="onSubmit()">Update</button>
    </div>
</div>
