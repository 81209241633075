<app-abstract-dialog-container>
    <app-edit-bid-form
        [editMode]="editMode"
        [bid]="bid$ | async"
        (submitEvent)="onSubmit($event)"
        (closeEvent)="onClose()">
    </app-edit-bid-form>
</app-abstract-dialog-container>


