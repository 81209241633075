import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {SubmitPassBasicInfoData} from "./components/edit-pass-basic-info-dialog-form/edit-pass-basic-info-dialog-form.component";
import {EditPassBasicInfoDialogSandbox} from "./sandbox/edit-pass-basic-info-dialog.sandbox";
import {PassSimpleResponseModel} from "../../../../../../../core/modules/rest/pass/response/pass-simple-response.model";

export class EditPassBasicInfoDialogData extends DialogData {

    passId: number;
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, passId: number) {
        super();
        this.passId = passId;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-edit-pass-basic-info-dialog',
    templateUrl: './edit-pass-basic-info-dialog.component.html'
})
export class EditPassBasicInfoDialogComponent implements OnDestroy {

    passId: number;
    successCallbackFn: Function;

    pass$: Observable<PassSimpleResponseModel>;

    constructor(@Inject(DialogData) data: EditPassBasicInfoDialogData, private sandbox: EditPassBasicInfoDialogSandbox) {

        this.passId = data.passId;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.passId);

        this.pass$ = this.sandbox.pass$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitPassBasicInfoData) {
        this.sandbox.updatePassBasicInfo(data).subscribe(() => {
            this.successCallbackFn(data.passId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
