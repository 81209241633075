import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild  } from '@angular/core';


@Component({
    selector: 'app-input-number-step',
    templateUrl: './input-number-step.component.html'
})
export class InputNumberStepComponent implements OnInit {

    @Input() value: number = 0;
    @Input() step: number = 1;

    @Input() maxValue: number = Number.MAX_SAFE_INTEGER.valueOf();
    @Input() minValue: number = 0;

    @ViewChild('valueInput') input: ElementRef;

    @Output() valueChanged: EventEmitter<number> = new EventEmitter();

    incDisabled: boolean = false;

    decDisabled: boolean = false;

    constructor() {
    }

    ngOnInit(): void {

       this.toggleStepButtons();
    }

    onIncClick() {

        let nextValue: number = this.value + this.step;

        if (this.maxValue < nextValue) {
            this.value = this.maxValue;
        } else {
            this.value += this.step;
        }

        this.toggleStepButtons();
        
        this.valueChanged.emit(this.value);
    }

    onDecClick() {

        let nextValue: number = this.value - this.step;

        if (nextValue < this.minValue) {
            this.value = this.minValue;
        } else {
            this.value = nextValue;
        }

        this.toggleStepButtons();

        this.valueChanged.emit(this.value);
    }

    onInputChanged() {

        this.value = Math.trunc(this.value);

        if (this.value >= this.maxValue) {
            this.value = this.maxValue;
        }

        if (this.value <= this.minValue) {
            this.value = this.minValue;
        }

        this.valueChanged.emit(this.value);

        this.input.nativeElement.value = this.value.toString();

        this.toggleStepButtons();
    }

    private toggleStepButtons() {

        this.incDisabled = this.value >= this.maxValue;

        this.decDisabled = this.value <= this.minValue;
    }
}
