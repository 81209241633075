import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { RootSandbox } from '../../../../../../../../core/store/root.sandbox';
import { OverrideReasonFormSubmitDataModel } from '../model/over-ride-reason-form-submit-data.model';
import { OverrideReasonResponseModel } from '../../../../../../../../core/modules/rest/over-ride-reason/response/over-ride-reason-response.model';

@Component({
    selector: 'app-edit-override-reason-form',
    templateUrl: './edit-override-reason-form.component.html'
})
export class EditOverrideReasonFormComponent implements OnInit, OnChanges {

    @Input() editMode: boolean;
    @Input() overrideReason: OverrideReasonResponseModel;

    @Output() submitEvent = new EventEmitter<OverrideReasonFormSubmitDataModel>();
    @Output() closeEvent = new EventEmitter<void>();

    form: FormGroup;

    constructor(private rootSandbox: RootSandbox) {
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (this.editMode && changes.overrideReason !== null && Object.keys(changes.overrideReason.currentValue).length !== 0) {
            this.form.patchValue({
                overrideReason: changes.overrideReason.currentValue.overrideReason,

            });
        }
    }

    ngOnInit() {

        let overrideReason: string = '';

        if (this.editMode) {
            overrideReason = this.overrideReason.overrideReason;

        }

        this.form = new FormGroup({
            overrideReason: new FormControl(overrideReason)
        });
    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        const overrideReason = this.form.value.overrideReason;

        if (overrideReason == null || overrideReason.trim() === '') {
            this.rootSandbox.addErrorNotification("Error while creating override reason: override reason can't be null or empty");
            return;
        }

        const data: OverrideReasonFormSubmitDataModel = new OverrideReasonFormSubmitDataModel(this.overrideReason !== undefined && this.overrideReason ? this.overrideReason.overrideReasonId : null, overrideReason);

        this.submitEvent.emit(data);
    }
}
