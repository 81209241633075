import {Directive, HostListener} from '@angular/core';
import {CloseDropdownOnScrollService} from './close-dropdown-on-scroll.service';

@Directive({
    selector: '[appScrollDirective]',
})

export class ScrollDirective {

    constructor( private scrollService:  CloseDropdownOnScrollService ) { }

    @HostListener('scroll') scrollHandler () {
        this.scrollService.setParentContainerScrollDetect();
    }
}
