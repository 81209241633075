const TicketStatusEnum = {
    SCANNED_ON: 'SCANNED_ON' as 'SCANNED_ON',
    SCANNED_OFF: 'SCANNED_OFF' as 'SCANNED_OFF',
    CHECKED_IN: 'CHECKED_IN' as 'CHECKED_IN',
    CHECKED_OUT: 'CHECKED_OUT' as 'CHECKED_OUT',
    NO_SHOW: 'NO_SHOW' as 'NO_SHOW',
    REJECTED: 'REJECTED' as 'REJECTED'
};
type TicketStatusEnum = (typeof TicketStatusEnum)[keyof typeof TicketStatusEnum];
export {TicketStatusEnum};
