


<app-drop-area
    [active]="visible || (enabled && pointerNear)"
    appMousePointerNear [disabled]="!enabled || (mouseOverDropArea$ | async)"  [distance]="100" (pointerNearEvent)="onPointerNear($event)"
    droppable (onDrop)="onDrop($event)" (onDragOver)="onDragOver()" (onDragLeave)="onDragLeave()">

    <div class="icon_container fieldIcon"></div>
    <p class="dropAreaMessage">Field parameters</p>
</app-drop-area>
