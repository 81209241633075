
import { of as observableOf, combineLatest, Observable } from 'rxjs';

import { catchError, take, filter, flatMap, map } from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { RoleRestService } from "../../../../../../core/modules/rest/role/role-rest.service";
import { UserRestService } from "../../../../../../core/modules/rest/user/user-rest.service";
import { RoleResponseModel } from "../../../../../../core/modules/rest/role/response/role-response.model";
import { UserResponseModel } from "../../../../../../core/modules/rest/user/response/user-response.model";
import { TreeItemTypeEnum } from "../model/tree-item-type.enum";
import { TreeNode } from "../../../../../../shared/components/tree/tree.component";
import { RoleMappingResponseModel } from "../../../../../../core/modules/rest/role/response/role-mapping-response.model";
import { PermissionNode } from "../model/permission-node";
import { UserMappingResponseModel } from "../../../../../../core/modules/rest/user/response/user-mapping-response.model";
import { BasicInformationDataModel } from "../model/basic-information-data.model";
import { RolesResponseModel } from "../../../../../../core/modules/rest/role/response/roles-response.model";
import { DropdownOptionModel } from "../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import { AppDialogsService } from "../../../../../../core/services/app-dialogs.service";
import { RootSandbox } from "../../../../../../core/store/root.sandbox";
import { TreeNodeDataModel } from "../model/tree-node-data.model";
import { UsersResponseModel } from "../../../../../../core/modules/rest/user/response/users-response.model";
import { MappedData, SelectedRoleMappedDataModel, SelectedUserMappedDataModel } from "../model/mapped-data";
import { HttpErrorModel } from "../../../../../../core/modules/rest/http-error.model";
import { PermissionItemNode } from '../../../../../../shared/components/tree-permission/tree-permission.component';

@Injectable()
export class RolesAndUsersSandbox {

    item$: Observable<TreeNode> = this.store.pipe(select(reducer.item_selector));

    node$: Observable<TreeNodeMaterial> = this.store.pipe(select(reducer.node_selector));

    itemId$: Observable<number> = this.store.pipe(select(reducer.item_id_selector));

    itemType$: Observable<TreeItemTypeEnum> = this.store.pipe(select(reducer.item_type_selector));

    itemActive$: Observable<boolean> = this.store.pipe(select(reducer.item_active_selector));

    itemBasicInfo$: Observable<BasicInformationDataModel> = this.store.pipe(select(reducer.item_basic_info_selector));

    showInactiveItems$: Observable<boolean> = this.store.pipe(select(reducer.show_inactive_items_selector));

    rolesAndUsersTree$: Observable<TreeNode[]> = this.store.pipe(select(reducer.roles_and_users_tree_selector));

    permissionTree$: Observable<PermissionNode[]> = this.store.pipe(select(reducer.permission_tree_selector));

    permissionTreeMaterial$: Observable<PermissionItemNode[]> = this.store.pipe(select(reducer.permission_tree_material_selector));

    userRolePermissionTree$: Observable<PermissionNode[]> = this.store.pipe(select(reducer.user_role_permission_tree_selector));

    loadingRolesAndUsers$: Observable<boolean> = this.store.pipe(select(reducer.loading_roles_and_users_selector));

    loadingPermissionsTree$: Observable<boolean> = this.store.pipe(select(reducer.loading_permissions_tree_selector));

    formHasChanges$: Observable<boolean> = this.store.pipe(select(reducer.formHasChanges_selector));

    mapper: MappedData;

    constructor(private store: Store<reducer.State>,
        private roleRestService: RoleRestService,
        private userRestService: UserRestService,
        private appDialogsService: AppDialogsService,
        private rootSandbox: RootSandbox) {


        this.mapper = new MappedData();
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    /**
     * Loads left section - Tree of roles and users
     */
    loadRolesAndUsers() {

        this.store.dispatch(new actions.SetLoadingRolesAndUsers(true));

        combineLatest([this.roleRestService.getAll(), this.userRestService.getAll()]).pipe(
            take(1),
            map(([roles, users]: [RolesResponseModel, UsersResponseModel]) => {
                let nodes: TreeNode[] = this.getRolesAndUsersTree(roles.roles, users.users);
                this.store.dispatch(new actions.SetLoadingRolesAndUsers(false));
                this.store.dispatch(new actions.SetRolesAndUsersTree(nodes));
            })
        ).subscribe();
    }

    setPermissionTreeMaterial(nodes: PermissionItemNode[]) {
        this.store.dispatch(new actions.SetPermissionTreeMaterial(nodes));
    }

    /**
     * Loads right section - Basic information with permission tree info
     */
    loadBasicInfoAndPermissions() {

        this.item$.pipe(
            take(1),
            map((item: TreeNode<TreeNodeDataModel>) => {

                if (item.data.type === TreeItemTypeEnum.ROLE) {
                    this.setRole(item);
                } else {
                    this.setUser(item);
                }
            })
        ).subscribe();
    }

    loadNode(passedNode: TreeNodeMaterial) {
        this.node$.pipe(
            take(1),
            map((node: TreeNodeMaterial) => {
                node = passedNode
                this.store.dispatch(new actions.SetSelectedNode(passedNode));
            })
        ).subscribe();
    }

    roleNodeSelected(itemRole: TreeNode<TreeNodeDataModel>) {
        this.item$.pipe(
            take(1))
            .subscribe((item: TreeNode<TreeNodeDataModel>) => {
                if (!item || !item.data || item.data.type !== itemRole.data.type || item.data.id !== itemRole.data.id) {
                    this.setRole(itemRole);
                }
            });
    }

    userNodeSelected(itemUser: TreeNode<TreeNodeDataModel>) {
        this.item$.pipe(
            take(1))
            .subscribe((item: TreeNode<TreeNodeDataModel>) => {
                if (!item || !item.data || item.data.type !== itemUser.data.type || item.data.id !== itemUser.data.id) {
                    this.setUser(itemUser);
                }
            });
    }

    setRole(item: TreeNode<TreeNodeDataModel>) {

        let roleId: number = item.data.id;

        this.store.dispatch(new actions.SetSelectedItem(item));
        this.store.dispatch(new actions.SetLoadingPermissionsTree(true));

        this.roleRestService.getRoleMapping(roleId).subscribe((role: RoleMappingResponseModel) => {

            let info: BasicInformationDataModel = {
                description: role.description,
                useComplexPassword: role.useComplexPassword,
                useMfa: role.useMfa,
                useLoginLocations: role.useLoginLocations
            };

            this.store.dispatch(new actions.SetBasicInfo(info));
            this.store.dispatch(new actions.SetRoleOrUserForEdit(TreeItemTypeEnum.ROLE, role.roleId, role.active));
            this.store.dispatch(new actions.SetPermissionTree(this.mapper.getPermissionTreeFromRoleMapping(role)));
            this.store.dispatch(new actions.SetLoadingPermissionsTree(false));
        },
        () => this.store.dispatch(new actions.SetLoadingPermissionsTree(false)));
    }

    setUser(item: TreeNode<TreeNodeDataModel>) {

        let userId: number = item.data.id;
        let roleId: number = item.data.parentId;

        this.store.dispatch(new actions.SetSelectedItem(item));
        this.store.dispatch(new actions.SetLoadingPermissionsTree(true));

        combineLatest([this.userRestService.getUserMapping(userId), this.roleRestService.getRoleMapping(roleId), this.roleRestService.getAll()]).pipe(
            take(1),
            map(([user, role, roles]: [UserMappingResponseModel, RoleMappingResponseModel, RolesResponseModel]) => {
                let info: BasicInformationDataModel = {
                    roles: roles.roles.map(r => new DropdownOptionModel(r.description, r.roleId.toString())),
                    roleId: user.roleId,
                    roleDescription: role.description,
                    displayName: user.displayName,
                    username: user.username,
                    password: user.password,
                    email: user.email,
                    isLocked: user.isLocked,
                    failedLoginAttemptsCount: user.failedLoginAttemptsCount,
                    passwordLastChange: user.passwordLastChange,
                    isMfaActivated: user.isMfaActivated,
                    roleUseMfa: role.useMfa
                };
                this.store.dispatch(new actions.SetBasicInfo(info));
                this.store.dispatch(new actions.SetRoleOrUserForEdit(TreeItemTypeEnum.USER, user.userId, user.active));
                this.store.dispatch(new actions.SetPermissionTree(this.mapper.getPermissionTreeFromUserMapping(user)));
                this.store.dispatch(new actions.SetUserRolePermissionTree(this.mapper.getPermissionTreeFromRoleMapping(role)));
                this.store.dispatch(new actions.SetLoadingPermissionsTree(false));
            })
        ).subscribe();
    }

    enableRole(roleId: number) {

        this.store.dispatch(new actions.SetLoadingRolesAndUsers(true));

        combineLatest([this.roleRestService.getAll(), this.userRestService.getAll()]).pipe(
            take(1),
            map(([roles, users]: [RolesResponseModel, UsersResponseModel]) => {

                let nodes: TreeNode<TreeNodeDataModel>[] = this.getRolesAndUsersTree(roles.roles, users.users);

                this.store.dispatch(new actions.SetLoadingRolesAndUsers(false));
                this.store.dispatch(new actions.SetRolesAndUsersTree(nodes));

                let enabledRoleNode: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(roleId, TreeItemTypeEnum.ROLE, nodes);

                // enabledRoleNode.selected = true;
                enabledRoleNode.toggleOpen(true);

                this.setRole(enabledRoleNode);
            })
        ).subscribe();
    }

    disableRole(roleId: number) {

        this.store.dispatch(new actions.SetLoadingRolesAndUsers(true));

        this.roleRestService.deactivate(roleId).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("Role can't be disabled");
                }

                return combineLatest([this.roleRestService.getAll(), this.userRestService.getAll()]);
            }),
            take(1),
            map(([roles, users]: [RolesResponseModel, UsersResponseModel]) => {

                let nodes: TreeNode<TreeNodeDataModel>[] = this.getRolesAndUsersTree(roles.roles, users.users);

                this.store.dispatch(new actions.SetLoadingRolesAndUsers(false));
                this.store.dispatch(new actions.SetRolesAndUsersTree(nodes));
                this.store.dispatch(new actions.SetShowInactiveItems(true));

                let disabledRoleNode: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(roleId, TreeItemTypeEnum.ROLE, nodes);

                // disabledRoleNode.selected = true;
                disabledRoleNode.toggleOpen(true);

                this.setRole(disabledRoleNode);
            })
        ).subscribe();
    }

    createActivateMfaRequest(userId: number): Observable<any> {
        return this.userRestService.createActivateMfaRequest(userId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating request for MFA activation', error);
            }));
    }

    deactivateMfa(userId: number): Observable<any> {
        return this.userRestService.deactivateMfa(userId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while deactivating MFA', error);
            }));
    }

    enableUser(userId: number) {

        this.rolesAndUsersTree$.pipe(
            take(1),
            flatMap((rolesAndUsersTree: TreeNode<TreeNodeDataModel>[]) => {

                let userNode: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(userId, TreeItemTypeEnum.USER, rolesAndUsersTree);
                let roleNode: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(userNode.data.parentId, TreeItemTypeEnum.ROLE, rolesAndUsersTree);

                if (!roleNode.active) {
                    this.rootSandbox.addInfoNotification(`You can't activate user because user's role (${roleNode.data.label}) is inactive! Try activating role first`);
                    return observableOf(null);
                }

                return this.userRestService.activate(userId);
            }),
            take(1),
            filter((response: any) => response !== null),
            flatMap((response: any) => {

                this.store.dispatch(new actions.SetLoadingRolesAndUsers(true));

                return combineLatest([this.roleRestService.getAll(), this.userRestService.getAll()]);
            }),
            take(1),
            map(([roles, users]: [RolesResponseModel, UsersResponseModel]) => {

                let nodes: TreeNode<TreeNodeDataModel>[] = this.getRolesAndUsersTree(roles.roles, users.users);

                this.store.dispatch(new actions.SetLoadingRolesAndUsers(false));
                this.store.dispatch(new actions.SetRolesAndUsersTree(nodes));

                let enabledUserNode: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(userId, TreeItemTypeEnum.USER, nodes);

                // enabledUserNode.setSelected();

                this.setUser(enabledUserNode);
            })
        ).subscribe();
    }

    disableUser(userId: number) {

        this.store.dispatch(new actions.SetLoadingRolesAndUsers(true));

        this.userRestService.deactivate(userId).pipe(
            take(1),
            flatMap((response: any) => {

                if (response === null) {
                    this.rootSandbox.addErrorNotification("User can't be disabled");
                }

                return combineLatest([this.roleRestService.getAll(), this.userRestService.getAll()]);
            }),
            take(1),
            map(([roles, users]: [RolesResponseModel, UsersResponseModel]) => {

                let nodes: TreeNode<TreeNodeDataModel>[] = this.getRolesAndUsersTree(roles.roles, users.users);

                this.store.dispatch(new actions.SetLoadingRolesAndUsers(false));
                this.store.dispatch(new actions.SetRolesAndUsersTree(nodes));
                this.store.dispatch(new actions.SetShowInactiveItems(true));

                let disabledUserNode: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(userId, TreeItemTypeEnum.USER, nodes);

                // disabledUserNode.setSelected();

                this.setUser(disabledUserNode);
            })
        ).subscribe();
    }

    refreshViewAndSelect(id: number, type: TreeItemTypeEnum) {

        this.store.dispatch(new actions.SetLoadingRolesAndUsers(true));

        combineLatest([this.roleRestService.getAll(), this.userRestService.getAll()]).pipe(
            take(1),
            map(([roles, users]: [RolesResponseModel, UsersResponseModel]) => {

                let nodes: TreeNode<TreeNodeDataModel>[] = this.getRolesAndUsersTree(roles.roles, users.users);
                let node: TreeNode<TreeNodeDataModel> = this.findNodeByIdAndType(id, type, nodes);

                this.store.dispatch(new actions.SetLoadingRolesAndUsers(false));
                this.store.dispatch(new actions.SetRolesAndUsersTree(nodes));

                // If inactive node has been selected/edited, set flag ShowInactiveItems to true
                if (!node.active) {
                    this.store.dispatch(new actions.SetShowInactiveItems(true));
                }

                if (type === TreeItemTypeEnum.ROLE) {
                    node.toggleOpen(true);
                    // node.setSelected();
                    this.setRole(node);
                    return;
                }

                if (type === TreeItemTypeEnum.USER) {
                    // node.setSelected();
                    this.setUser(node);
                    return;
                }
            })
        ).subscribe();
    }

    iterateTree(children, treeMaterial) {
        for (let child in children) {
            let filteredNode = treeMaterial.filter(
                nodeLocal => nodeLocal.item === children[child].label && nodeLocal.id === children[child].id);
            if (filteredNode[0]) {
                children[child] = { ...children[child], checked: filteredNode[0].isChecked }
            }
            if (children[child].children && children[child].children.length !== 0) {
                this.iterateTree(children[child].children, treeMaterial)
            }
        }
    }

    prepareTreeMaterial(cloned, treeMaterial = []) {
        this.permissionTreeMaterial$.subscribe((res) => {
            treeMaterial = res;
        });
        for (let node in cloned) {
            let filteredNode = treeMaterial.filter(
                nodeLocal => nodeLocal.item === cloned[node].label);
            if (filteredNode[0]) {
                cloned[node].checked = filteredNode[0].isChecked
                if (cloned[node].children.length !== 0) {
                    this.iterateTree(cloned[node].children, treeMaterial)
                }
            }

        }
        this.store.dispatch(new actions.SetPermissionTree(cloned));
    }

    save() {

        combineLatest([this.item$, this.permissionTree$]).pipe(
            take(1),
            flatMap(([node, tree]: [TreeNode<TreeNodeDataModel>, PermissionNode[]]) => {

                let cloned = this.deepClone(tree);

                if (node.data.type === TreeItemTypeEnum.USER) {
                    this.prepareTreeMaterial(cloned);
                    let mappedData: SelectedUserMappedDataModel = this.mapper.getUserMappedDataFromPermissionTree(cloned);
                    return this.userRestService.updateUserMapping({
                        userId: node.data.id,
                        roleId: node.data.parentId,
                        categories: mappedData.categories,
                        products: mappedData.products,
                        passes: mappedData.passes,
                        cloudPaymentDevices: mappedData.cloudPaymentDevices,
                        quickSellingButtonProducts: mappedData.qsbProducts,
                        quickSellingButtonPasses: mappedData.qsbPasses,
                        postBillTypes: mappedData.postBillTypes,
                        favorites: mappedData.favorites,
                        permissions: mappedData.permissions
                    });
                }

                if (node.data.type === TreeItemTypeEnum.ROLE) {
                    this.prepareTreeMaterial(cloned);
                    let mappedData: SelectedRoleMappedDataModel = this.mapper.getRoleMappedDataFromPermissionTree(cloned);

                    return this.roleRestService.updateRoleMapping({
                        roleId: node.data.id,
                        categoryIds: mappedData.categories,
                        productIds: mappedData.products,
                        passIds: mappedData.passes,
                        cloudPaymentDeviceIds: mappedData.cloudPaymentDevices,
                        quickSellingButtonProductIds: mappedData.qsbProducts,
                        quickSellingButtonPassIds: mappedData.qsbPasses,
                        postBillTypeIds: mappedData.postBillTypes,
                        favoriteIds: mappedData.favorites,
                        permissions: mappedData.permissions
                    });
                }
            })
        ).subscribe(()=>{
            this.rootSandbox.loadUserInfo();
        });

    }

    setFormHasChanges(hasChanges: boolean) {
        this.store.dispatch(new actions.SetFormHasChanges(hasChanges));
    }

    private getRolesAndUsersTree(roles: RoleResponseModel[], users: UserResponseModel[]): TreeNode<TreeNodeDataModel>[] {

        let result: TreeNode<TreeNodeDataModel>[] = [];

        roles.sort((r1, r2) => (r1.description.toLowerCase() > r2.description.toLowerCase()) ? 1 : -1);

        roles.forEach((role: RoleResponseModel) => {

            let roleChildren: TreeNode<TreeNodeDataModel>[] = users
                .filter((u) => u.roleId === role.roleId)
                .sort((u1, u2) => (u1.displayName.toLowerCase() > u2.displayName.toLowerCase()) ? 1 : -1)
                .map((u) => {

                    let data: TreeNodeDataModel = new TreeNodeDataModel(TreeItemTypeEnum.USER, u.userId, u.roleId, u.displayName);

                    return new TreeNode<TreeNodeDataModel>(data, [], u.active, false);
                });

            roleChildren.push(new TreeNode<TreeNodeDataModel>(new TreeNodeDataModel(TreeItemTypeEnum.ADD_NEW_USER, null, role.roleId, "Add New User"), [], true, false));

            result.push(new TreeNode<TreeNodeDataModel>(new TreeNodeDataModel(TreeItemTypeEnum.ROLE, role.roleId, null, role.description), roleChildren, role.active, true));
        });

        result.push(new TreeNode<TreeNodeDataModel>(new TreeNodeDataModel(TreeItemTypeEnum.ADD_NEW_ROLE, null, null, "Add New Role"), [new TreeNode<TreeNodeDataModel>(new TreeNodeDataModel(null, null, null, null), [])], true, true));

        return result;
    }

    private findNodeByIdAndType(id: number, type: TreeItemTypeEnum, nodes: TreeNode<TreeNodeDataModel>[]): TreeNode<TreeNodeDataModel> {

        let result: TreeNode<TreeNodeDataModel> = null;

        for (let i = 0; result === null && i < nodes.length; i++) {
            result = this._findNodeByIdAndType(id, type, nodes[i]);
        }

        return result;
    }

    private _findNodeByIdAndType(id: number, type: TreeItemTypeEnum, node: TreeNode<TreeNodeDataModel>): TreeNode<TreeNodeDataModel> {

        if (node.data.id === id && node.data.type === type) {
            return node;
        } else if (node.children.length !== 0) {

            let result: TreeNode<TreeNodeDataModel> = null;

            for (let i = 0; result === null && i < node.children.length; i++) {
                result = this._findNodeByIdAndType(id, type, node.children[i]);
            }

            return result;
        }

        return null;
    }

    deepClone(obj, hash = new WeakMap()) {
        // Do not try to clone primitives or functions
        if (Object(obj) !== obj || obj instanceof Function) return obj;
        if (hash.has(obj)) return hash.get(obj); // Cyclic reference
        try { // Try to run constructor (without arguments, as we don't know them)
            var result = new obj.constructor();
        } catch (e) { // Constructor failed, create object without running the constructor
            result = Object.create(Object.getPrototypeOf(obj));
        }
        // Optional: support for some standard constructors (extend as desired)
        if (obj instanceof Map)
            Array.from(obj, ([key, val]) => result.set(this.deepClone(key, hash),
                this.deepClone(val, hash)));
        else if (obj instanceof Set)
            Array.from(obj, (key) => result.add(this.deepClone(key, hash)));
        // Register in hash
        hash.set(obj, result);
        // Clone and assign enumerable own properties recursively
        return Object.assign(result, ...Object.keys(obj).map(
            key => ({ [key]: this.deepClone(obj[key], hash) })));
    }
}
