
import {forkJoin as observableForkJoin, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';

import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {select, Store} from "@ngrx/store";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {KioskConfigurationRestService} from "../../../../../../../../core/modules/rest/kiosk-configuration/kiosk-configuration-rest.service";
import {TierResponseModel} from "../../../../../../../../core/modules/rest/tier/response/tier-response.model";
import {KioskConfigurationDropoffLocationPassTierModel} from "../model/kiosk-configuration-dropoff-location-pass-tier.model";
import {KioskConfigurationDropoffLocationPassTierResponseModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-dropoff-location-pass-tier-response.model";
import _ from 'lodash';
import {KioskConfigurationDropoffLocationProductTierModel} from "../../product-tier-setup-dialog/model/kiosk-configuration-dropoff-location-product-tier.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {CreateKioskConfigurationDropoffLocationPassTierRequestModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-dropoff-location-pass-tier-request.model";
import {CreateKioskConfigurationDropoffLocationPassTiersRequestModel} from "../../../../../../../../core/modules/rest/kiosk-configuration/request/create-kiosk-configuration-dropoff-location-pass-tiers-request.model";

@Injectable()
export class PassTierSetupDialogSandbox extends DialogSandbox {

    passTiers$: Observable<KioskConfigurationDropoffLocationPassTierModel[]> = this.store.pipe(select(reducer.passTiers_selector));

    constructor(appDialogsService: AppDialogsService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox,
                private kioskConfigurationRestService: KioskConfigurationRestService) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetStateAction());
    }

    loadDialogData(kioskConfigurationDropoffLocationId: number, passId: number) {

        this.showLoader();

        const getAllTiers_request: Observable<TierResponseModel[]> = this.rootSandbox.getAllTiers();

        const getAllPassTiers_request: Observable<KioskConfigurationDropoffLocationPassTierResponseModel[]> = this.kioskConfigurationRestService.getKioskConfigurationDropoffLocationPassTiersByKioskConfigurationDropoffLocationIdAndPassId(kioskConfigurationDropoffLocationId, passId);

        observableForkJoin(getAllTiers_request, getAllPassTiers_request)
            .subscribe(([allTiersResponse, allPassTiersResponse]: [TierResponseModel[], KioskConfigurationDropoffLocationPassTierResponseModel[]]) => {

                let allTiersResponseActive = allTiersResponse.filter((tiers: TierResponseModel) => tiers.active == true);

                const allTiersParameterized: TierResponseModel[] = allTiersResponseActive;
                const allPassTiersParameterized: KioskConfigurationDropoffLocationPassTierResponseModel[] = allPassTiersResponse;

                let allPassTiers = allTiersParameterized.map((tier: TierResponseModel) => {
                    const passTier: KioskConfigurationDropoffLocationPassTierResponseModel = _.find(allPassTiersParameterized, (passTier: KioskConfigurationDropoffLocationPassTierResponseModel) => passTier.tierId === tier.tierId);

                    if (passTier) {
                        return new KioskConfigurationDropoffLocationPassTierModel(passTier.tierId, passTier.tierDescription, passTier.defaultQuantity, passTier.minQuantity, passTier.maxQuantity);
                    } else {
                        return new KioskConfigurationDropoffLocationPassTierModel(tier.tierId, tier.description, null, null, null);
                    }
                });

                this.store.dispatch(new actions.SetPassTiers(allPassTiers));

                this.hideLoader();
            },
            () => this.hideLoader());
    }

    createKioskConfigurationDropoffLocationProductTier(kioskConfigurationDropoffLocationId: number, passId: number, passTiers: KioskConfigurationDropoffLocationProductTierModel[]): Observable<any> {

        this.showLoader();

        const passTiersRequest: CreateKioskConfigurationDropoffLocationPassTierRequestModel[] = _.map(passTiers, (passTier: KioskConfigurationDropoffLocationPassTierModel) => new CreateKioskConfigurationDropoffLocationPassTierRequestModel(passTier.tierId, passTier.defaultQuantity, passTier.minQuantity, passTier.maxQuantity));

        const data: CreateKioskConfigurationDropoffLocationPassTiersRequestModel = new CreateKioskConfigurationDropoffLocationPassTiersRequestModel(
            kioskConfigurationDropoffLocationId,
            passId,
            passTiersRequest
        );

        return this.kioskConfigurationRestService.createKioskConfigurationDropoffLocationPassTier(data).pipe(
            catchError((error: HttpErrorModel) => {
                this.hideLoader();
                return this.rootSandbox.handleHttpError('Error while creating kiosk configuration dropoff location pass tiers', error);
            }));
    }
}
