import { Component, OnInit } from '@angular/core';
import { RootSandbox } from '../../../../../core/store/root.sandbox';
import { AppRoutesService } from '../../../../../core/services/app-routes.service';
import { PermissionEnum } from "../../../../../shared/enums/permission.enum";
import { UserInfoResponseModel } from '../../../../../core/modules/rest/user/response/user-info-response.model';
import { SharedSandbox } from '../../../../../shared/shared.sandbox';
import { environment } from '../../../../../../environments/environment';
import { SystemPermissionsRestService } from '../../../../../core/modules/rest/system-permissions/system-permissions-rest.service';
import { SystemPermissionsResponseModel } from '../../../../../core/modules/rest/system-permissions/response/system-permissions-response.model';
import { SystemPermissionEnum } from '../../../../enums/system-permission.enum';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';


@Component({
    selector: 'app-secondary-nav-management',
    templateUrl: './secondary-nav-management.component.html'
})
export class SecondaryNavManagementComponent implements OnInit {
    userId: number;

    appRoutesService: AppRoutesService;
    kioskConfiguration: boolean;
    user: UserInfoResponseModel;
    systemPermissions: SystemPermissionsResponseModel[];
    userPermissions: string[];

    constructor(appRoutesService: AppRoutesService,
        private rootSandbox: RootSandbox,
        private sharedSandbox: SharedSandbox,
        private systemPermissionsRestService: SystemPermissionsRestService, private localStorageService: LocalStorageService) {
        this.appRoutesService = appRoutesService;
    }


    ngOnInit() {
        this.userId = this.localStorageService.getUserIdFromToken();
        this.systemPermissionsRestService.getSystemPermissions().subscribe((res: SystemPermissionsResponseModel[]) => {
            this.systemPermissions = res;
        });
        this.rootSandbox.getUserInfo().subscribe(res => {
            if (res) {
                this.userPermissions = res.permissions
            }
        });
    }

    userSystemPermissions(item: string) {
        return this.userPermissions ? this.userPermissions.includes(item) : false;
    }

    checkMultipleUserSystemPermissions(items: string[]) {
        return items.some(item => this.userPermissions ? this.userPermissions.includes(item) : false);
    }

    checkSystemUserSystemPermissions() {
        return this.checkMultipleUserSystemPermissions([PermissionEnum.MANAGE_SYSTEM_OPTIONS,
        PermissionEnum.MANAGE_COMPANY_INFOS, PermissionEnum.MANAGE_EMAIL_HTML_TEMPLATES,
        PermissionEnum.MANAGE_ROLES_AND_USERS, PermissionEnum.MANAGE_FAVORITES, PermissionEnum.MANAGE_NOTIFICATIONS]);
    }

    checkSalesUserSystemPermissions() {
        return this.checkMultipleUserSystemPermissions([PermissionEnum.MANAGE_QSB_PRODUCTS,
        PermissionEnum.MANAGE_QSB_PASSES, PermissionEnum.MANAGE_TIERS,
        PermissionEnum.MANAGE_COSTS, PermissionEnum.MANAGE_POST_BILL_TYPES,
        PermissionEnum.MANAGE_COMMISSIONS]);
    }

    checkInventoryUserSystemPermissions() {
        return this.checkMultipleUserSystemPermissions([PermissionEnum.MANAGE_CATEGORIES_AND_PRODUCTS,
        PermissionEnum.MANAGE_OCCURRENCE, PermissionEnum.MANAGE_PASSES,
        PermissionEnum.MANAGE_PASS_CARDS, PermissionEnum.MANAGE_BUSES,
        PermissionEnum.MANAGE_HOLIDAYS, PermissionEnum.MANAGE_LOCATIONS_AND_ROUTES,
        PermissionEnum.MANAGE_TRIP_GROUPINGS]);
    }

    checkHardwareUserSystemPermissions() {
        return this.userSystemPermissions(PermissionEnum.MANAGE_HARDWARE);
    }

    checkCustomersUserSystemPermissions() {
        return this.userSystemPermissions(PermissionEnum.CUSTOMERS_ADMINISTRATION) && this.useCustomerPermission();
    }

    allSystemPermissions() {
        return PermissionEnum;
    }

    customKioskPermission() {
        return this.systemPermissions ? this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.KIOSK)?.active ?? false : false;
    }

    customCloudPaymentPermission() {
        return this.systemPermissions ? this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.CLOUD_PAYMENT)?.active ?? false: false;
    }

    customKioskConfigurationPermission() {
        return this.systemPermissions ? this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.KIOSK_CONFIGURATION)?.active ?? false : false;
    }

    useCustomerPermission() {
        return this.systemPermissions ? this.systemPermissions.find((sprm: SystemPermissionsResponseModel) => sprm.description === SystemPermissionEnum.USE_CUSTOMERS)?.active ?? false: false;
    }

    adminPanelPermission() {
        this.sharedSandbox.getUserInfo().subscribe(res => {
            this.user = res
        });
        return environment.adminUserId === this.user.userId;
    }

    isUserAdmin(): boolean {
        return this.userId === environment.adminUserId;
    }
}
