// Assets Images
export const SS_LOGO = '../../../assets/images/logo/smartstubs-logo-white.svg';
export const SS_LOGO_BLACK = '../../../assets/images/logo/smartstubs-logo-black.svg';


// ICONS
export const SS_LOGO_ICON = '../../../assets/images/logo/ss-ico.svg';
export const DRAG_N_DROP_DEFAULT_ICON = '../../../assets/images/svg-icons/dnd-icon-default.svg';


// Empty Icons
export const NO_RESULTS_ICON =  '../../../assets/images/svg-icons/no-search-ico.svg';
export const EMPTY_STATE_ICON_CREATE_NEW  = '../../../assets/images/svg-icons/create-new-ico.svg';

export const DRAG_DATA_IMAGE = '../../../assets/images/svg-icons/drag-data-BIG.png';
export const DRAG_FILTER_IMAGE = '../../../assets/images/svg-icons/drag-filter-BIG.png';
export const DRAG_GROUP_IMAGE = '../../../assets/images/svg-icons/drag-group-BIG.png';
export const DRAG_ITEM_IMAGE = '../../../assets/images/svg-icons/drag-item-BIG.png';
