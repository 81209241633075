import * as actions from './actions';
import {CostTypeEnum} from "../../../../../../../shared/enums/cost-type.enum";

export interface State {
    pageDataLoaded: boolean;
    activeCostType: CostTypeEnum;
    showInactiveAdditionalCosts: boolean;
    showInactiveIncludedCosts: boolean;
    showInactiveDiscounts: boolean;
    showInactiveAutoAppliedDiscounts: boolean;
}

const initialState: State = {
    pageDataLoaded: false,
    activeCostType: null,
    showInactiveAdditionalCosts: false,
    showInactiveIncludedCosts: false,
    showInactiveDiscounts: false,
    showInactiveAutoAppliedDiscounts: false
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.PAGE_DATA_LOADED:
            return handlePageDataLoadedAction(state, action);
        case actions.UPDATE_ACTIVE_COST_TYPE_ACTION:
            return handleUpdateActiveCostTypeAction(state, action);
        case actions.UPDATE_SHOW_INACTIVE_ADDITIONAL_COSTS_ACTION:
            return handleShowInactiveAdditionalCostsAction(state, action);
        case actions.UPDATE_SHOW_INACTIVE_INCLUDED_COSTS_ACTION:
            return handleShowInactiveIncludedCostsAction(state, action);
        case actions.UPDATE_SHOW_INACTIVE_DISCOUNTS_ACTION:
            return handleShowInactiveDiscountsAction(state, action);
        case actions.UPDATE_SHOW_INACTIVE_AUTO_APPLIED_DISCOUNTS_ACTION:
            return handleShowInactiveAutoAppliedDiscountsAction(state, action);
        default:
            return state;
    }
}


// #################################################### SELECTORS ####################################################
export const pageDataLoaded_selector = (state: State) => state.pageDataLoaded;
export const activeCostType_selector = (state: State) => state.activeCostType;
export const showInactiveAdditionalCosts_selector = (state: State) => state.showInactiveAdditionalCosts;
export const showInactiveIncludedCosts_selector = (state: State) => state.showInactiveIncludedCosts;
export const showInactiveDiscounts_selector = (state: State) => state.showInactiveDiscounts;
export const showInactiveAutoAppliedDiscounts_selector = (state: State) => state.showInactiveAutoAppliedDiscounts;


// #################################################### HANDLERS ####################################################
function handlePageDataLoadedAction(state: State, action: actions.PageDataLoaded) {
    return {
        ...state,
        pageDataLoaded: action.loaded
    };
}

function handleUpdateActiveCostTypeAction(state: State, action: actions.UpdateActiveCostType) {
    return {
        ...state,
        activeCostType: action.costType
    };
}

function handleShowInactiveAdditionalCostsAction(state: State, action: actions.UpdateShowInactiveAdditionalCosts) {
    return {
        ...state,
        showInactiveAdditionalCosts: action.show
    };
}


function handleShowInactiveIncludedCostsAction(state: State, action: actions.UpdateShowInactiveIncludedCosts) {
    return {
        ...state,
        showInactiveIncludedCosts: action.show
    };
}

function handleShowInactiveDiscountsAction(state: State, action: actions.UpdateShowInactiveDiscounts) {
    return {
        ...state,
        showInactiveDiscounts: action.show
    };
}

function handleShowInactiveAutoAppliedDiscountsAction(state: State, action: actions.UpdateShowInactiveAutoAppliedDiscounts) {
    return {
        ...state,
        showInactiveAutoAppliedDiscounts: action.show
    };
}
