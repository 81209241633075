import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";

export enum EnableRoleUsersEnum {
    ALL_USERS,
    SOME_USERS,
    NONE
}

export interface EnableRoleUsersEventData {
    type: EnableRoleUsersEnum;
    userIds: number[];
}

@Component({
    selector: 'app-enable-role-dialog-form',
    templateUrl: './enable-role-dialog-form.component.html'
})
export class EnableRoleDialogFormComponent {

    @Input() users: DropdownOptionModel[];

    @Output() submit: EventEmitter<EnableRoleUsersEventData> = new EventEmitter();

    @Output() cancel: EventEmitter<void> = new EventEmitter();

    selectedUsers: string[] = [];

    type: EnableRoleUsersEnum = EnableRoleUsersEnum.ALL_USERS;

    readonly ALL_USERS: EnableRoleUsersEnum = EnableRoleUsersEnum.ALL_USERS;
    readonly SOME_USERS: EnableRoleUsersEnum = EnableRoleUsersEnum.SOME_USERS;
    readonly NONE: EnableRoleUsersEnum = EnableRoleUsersEnum.NONE;

    constructor() {
    }

    onUsersSelected(val: string[]) {

        this.type = EnableRoleUsersEnum.SOME_USERS;
        this.selectedUsers = val;
    }

    setType(type: EnableRoleUsersEnum) {

        if (type !== EnableRoleUsersEnum.SOME_USERS) {
            this.selectedUsers = [];
        }

        this.type = type;
    }

    onCancel() {
        this.cancel.emit();
    }

    onSubmit() {

        this.submit.emit({
           type: this.type,
           userIds: this.selectedUsers.map(id => Number(id))
        });
    }
}
