import {LocationResponseModel} from "../../../../../../../../../core/modules/rest/location/response/location-response.model";
import {DiscountCostModel} from "../../model/discount-cost.model";
import {createFeatureSelector, createSelector} from "@ngrx/store";

import * as actions from './actions';

export interface State {
    dialogDataLoaded: boolean;
    editDiscount: boolean;
    discount: DiscountCostModel;
    allLocations: LocationResponseModel[];
}

const initialState: State = {
    dialogDataLoaded: false,
    editDiscount: false,
    discount: new DiscountCostModel(),
    allLocations: [],
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.UPDATE_DIALOG_DATA_LOADED_ACTION:
            return handleUpdateDialogDataLoadedAction(state, action);
        case actions.UPDATE_EDIT_DISCOUNT_ACTION:
            return handleUpdateEditDiscountAction(state, action);
        case actions.RESET_STATE_ACTION:
            return handleResetStateAction(state, action);
        case actions.UPDATE_DISCOUNT_ACTION:
            return handleUpdateDiscountAction(state, action);
        case actions.UPDATE_ALL_LOCATIONS_ACTION:
            return handleUpdateAllLocationsAction(state, action);
        default:
            return state;
    }
}


// #################################################### SELECTORS ####################################################
export const REDUCER_NAME = 'dialog_edit-discount';

const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const dialogDataLoaded_selector = createSelector(state_selector, (state: State) => state.dialogDataLoaded);
export const editDiscount_selector = createSelector(state_selector, (state: State) => state.editDiscount);
export const discount_selector = createSelector(state_selector, (state: State) => state.discount);
export const allLocations_selector = createSelector(state_selector, (state: State) => state.allLocations);


// #################################################### HANDLERS ####################################################
function handleUpdateDialogDataLoadedAction(state: State, action: actions.UpdateDialogDataLoaded) {
    return {
        ...state,
        dialogDataLoaded: action.value
    };
}

function handleResetStateAction(state: State, action: actions.ResetState) {
    return initialState;
}

function handleUpdateDiscountAction(state: State, action: actions.UpdateDiscount) {
    return {
        ...state,
        discount: action.discount
    };
}

function handleUpdateAllLocationsAction(state: State, action: actions.UpdateAllLocations) {
    return {
        ...state,
        allLocations: action.locations
    };
}

function handleUpdateEditDiscountAction(state: State, action: actions.UpdateEditDiscount) {
    return {
        ...state,
        editDiscount: action.value
    };
}
