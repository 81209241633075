import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {BusResponseModel} from "../../../../../../../../../core/modules/rest/bus/response/bus-response.model";
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import { ProductBusMappingResponseModel } from '../../../../../../../../../core/modules/rest/product/response/product-bus-mapping-response.model';

export const REDUCER_NAME = 'dialog_mapping-bus';

export interface State {
    bus: BusResponseModel;
    productBusMappingItems: ProductBusMappingResponseModel[];
    productBusMappingItemsLoading: boolean;
}

const initialState: State = {
    bus: {} as BusResponseModel,
    productBusMappingItems: [],
    productBusMappingItemsLoading: false
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const bus_selector = createSelector(state_selector, (state: State) => state.bus);
export const product_bus_mapping_items_selector = createSelector(state_selector, (state: State) => state.productBusMappingItems);
export const product_bus_mapping_items_loading_selector = createSelector(state_selector, (state: State) => state.productBusMappingItemsLoading);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_BUS_ACTION:  return { ... state, bus: action.bus };

        case actions.SET_PRODUCT_BUS_MAPPING_ITEMS_LOADING_ACTION: return { ... state, productBusMappingItemsLoading: action.loading };

        case actions.SET_PRODUCT_BUS_MAPPING_ITEMS_ACTION: return { ... state, productBusMappingItems: action.productBusMappingItems };

        default: return state;
    }
}
