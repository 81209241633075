<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmitClick()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? 'Edit Customer' : 'Add New Customer' }}</h1>
            </div>
        </div>
    </div>


    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">

        <div class="formRow">
            <div class="grid-x grid-margin-x" *ngIf="!editMode">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Email</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Email"
                               formControlName="email"
                               [maxLength]="maxCharacters">
                    </app-input-label-group>
                </div>
            </div>

            <div class="cell medium-12 mb-16px" *ngIf="editMode">
                <label class="label-color">Email</label>
                <div title="{{customerEmail}}" class="main--element input-large email-input scale-input mt-5px"> {{customerEmail}}</div>
            </div>
        </div>



        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Display Name</label>
                        <input class="main--element input-large"
                               type="text"
                               name="DisplayName"
                               formControlName="displayName"
                               [maxLength]="maxCharacters">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Phone Number</label>
                        <input class="main--element input-large"
                               type="text"
                               name="PhoneNumber"
                               formControlName="phoneNumber"
                               [maxLength]="maxCharacters">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow" *ngIf="!editMode">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    Create account for
                    <app-radio-button-group
                        class="radio-input-half-width flex-container role-radio-option flex-dir-column"
                        style="margin-top: 10px;"
                        [data]="fieldScopes"
                        [selectedValue]="fieldScopes[0].value"
                        (changed)="selectedRadioButtonChanged($event)">
                    </app-radio-button-group>
                </div>
            </div>
        </div>

        <div class="cell medium-12 mt-10px">
            <app-checkbox
                [labelName]="'Customer Owner'"
                [checked]="form.value.isCustomerOwner"
                [formControlName]="'isCustomerOwner'">
            </app-checkbox>
        </div>

    </div>
    <!-- Dialog Content :: END -->


    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancelClick()">
                    Cancel
                </app-button>
            </div>


            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid"
                    (clickEvent)="onSubmitClick()">
                    Save
                </app-button>
            </div>
        </div>
    </div>
    <!-- Footer :: END -->

</form>
