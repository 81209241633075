<div class="table_container"
     appTableScrollDirective>

    <table class="table_striped">
        <thead class="tableHeaderRow">
        <th class="tableHeaderColumn fixedWidthCell_s">
            <p>Mapping</p>
        </th>
        <th class="tableHeaderColumn fixedWidthCell_s">
            <p>Edit</p>
        </th>
        <th class="tableHeaderColumn fixedWidthCell_s">
            <p>Active</p>
        </th>
        <th class="tableHeaderColumn">
            <a class="tableHeaderColumnLink_container"
               [ngClass]="{'tableHeaderSortable': true}"
               (click)="sortByHeaderClick('Description')">

                <div class="tableHeaderColumnLabel">
                    Description

                    <!-- defaultSortIcon, sortAscending or sortDescending -->
                    <div class="sortIcon"

                         [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Description'"
                         [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Description' && currentSearchDataSortBy.order === 'ASC'"
                         [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Description' && currentSearchDataSortBy.order === 'DESC'">
                        <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                        <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                        <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                    </div>
                </div>
            </a>
        </th>
        <th class="tableHeaderColumn">
            <a class="tableHeaderColumnLink_container"
               [ngClass]="{'tableHeaderSortable': true}"
               (click)="sortByHeaderClick('Amount')">

                <div class="tableHeaderColumnLabel">
                    Amount

                    <!-- defaultSortIcon, sortAscending or sortDescending -->
                    <div class="sortIcon"

                         [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Amount'"
                         [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Amount' && currentSearchDataSortBy.order === 'ASC'"
                         [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Amount' && currentSearchDataSortBy.order === 'DESC'">
                        <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                        <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                        <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                    </div>
                </div>
            </a>
        </th>
        <th class="tableHeaderColumn">
            <a class="tableHeaderColumnLink_container"
               [ngClass]="{'tableHeaderSortable': true}"
               (click)="sortByHeaderClick('Is Order Level')">

                <div class="tableHeaderColumnLabel">
                    Is Order Level

                    <!-- defaultSortIcon, sortAscending or sortDescending -->
                    <div class="sortIcon"

                         [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Is Order Level'"
                         [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Is Order Level' && currentSearchDataSortBy.order === 'ASC'"
                         [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Is Order Level' && currentSearchDataSortBy.order === 'DESC'">
                        <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                        <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                        <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                    </div>
                </div>
            </a>
        </th>
        <th class="tableHeaderColumn">
            <a class="tableHeaderColumnLink_container"
               [ngClass]="{'tableHeaderSortable': true}"
               (click)="sortByHeaderClick('GL Code')">

                <div class="tableHeaderColumnLabel">
                    GL Code

                    <!-- defaultSortIcon, sortAscending or sortDescending -->
                    <div class="sortIcon"

                         [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'GL Code'"
                         [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'GL Code' && currentSearchDataSortBy.order === 'ASC'"
                         [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'GL Code' && currentSearchDataSortBy.order === 'DESC'">
                        <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                        <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                        <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                    </div>
                </div>
            </a>
        </th>
        <th class="tableHeaderColumn">
            <a class="tableHeaderColumnLink_container"
               [ngClass]="{'tableHeaderSortable': true}"
               (click)="sortByHeaderClick('Date Range')">

                <div class="tableHeaderColumnLabel">
                    Date Range

                    <!-- defaultSortIcon, sortAscending or sortDescending -->
                    <div class="sortIcon"

                         [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Date Range'"
                         [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Date Range' && currentSearchDataSortBy.order === 'ASC'"
                         [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Date Range' && currentSearchDataSortBy.order === 'DESC'">
                        <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                        <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                        <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                    </div>
                </div>
            </a>
        </th>
        <th class="tableHeaderColumn">
            <a class="tableHeaderColumnLink_container"
               [ngClass]="{'tableHeaderSortable': true}"
               (click)="sortByHeaderClick('Location')">

                <div class="tableHeaderColumnLabel">
                    Location

                    <!-- defaultSortIcon, sortAscending or sortDescending -->
                    <div class="sortIcon"

                         [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Location'"
                         [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Location' && currentSearchDataSortBy.order === 'ASC'"
                         [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Location' && currentSearchDataSortBy.order === 'DESC'">
                        <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                        <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                        <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                    </div>
                </div>
            </a>
        </th>
        <th class="tableHeaderColumn">
            <a class="tableHeaderColumnLink_container"
               [ngClass]="{'tableHeaderSortable': true}"
               (click)="sortByHeaderClick('Platform')">

                <div class="tableHeaderColumnLabel">
                    Platform

                    <!-- defaultSortIcon, sortAscending or sortDescending -->
                    <div class="sortIcon"

                         [class.defaultSortIcon]="!currentSearchDataSortBy || currentSearchDataSortBy.fieldName !== 'Platform'"
                         [class.sortAscending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Platform' && currentSearchDataSortBy.order === 'ASC'"
                         [class.sortDescending]="currentSearchDataSortBy && currentSearchDataSortBy.fieldName === 'Platform' && currentSearchDataSortBy.order === 'DESC'">
                        <span class="sort-default"><i class="fa fa-sort" aria-hidden="true"></i></span>
                        <span class="sort-down"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                        <span class="sort-up"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                    </div>
                </div>
            </a>
        </th>
        </thead>

        <tr *ngFor="let additionalCost of additionalCosts">
            <!-- Mapping :: column-->
            <td class="fixedWidthCell_s">
                <a class="icon_container tableIconContainer"
                   (click)="mappingAdditionalCostClick(additionalCost)">
                    <i class="fal fa-link"></i>
                </a>
            </td>

            <!-- Edit :: column-->
            <td class="fixedWidthCell_s">
                <a class="icon_container tableIconContainer"
                   (click)="editAdditionalCostClick(additionalCost)">
                   <span class="icon_container">
                        <i class="fal fa-edit"></i>
                   </span>
                </a>
            </td>

            <!-- Active :: column -->
            <td class="fixedWidthCell_s">
                <app-switch
                    [value]="additionalCost.active"
                    (switchValueChanged)="onAdditionalCostActivityChanged(additionalCost)">
                </app-switch>
            </td>

            <td>{{ additionalCost.description }}</td>
            <td>{{ getAmountPrintValue(additionalCost) }}</td>
            <td>{{ getIsOrderLevelPrintValue(additionalCost) }}</td>
            <td>{{ additionalCost.glCode }}</td>
            <td>{{ getDateRangePrintValue(additionalCost) }}</td>
            <td>{{ additionalCost.locationDescription }}</td>
            <td>{{ additionalCost.platformDescription }}</td>

        </tr>

    </table>
</div>
