<div class="headerDropdown_container">
    <div class="headerDropdown_title">
        <h3>Notifications</h3>
    </div>

    <div class="headerNotificationDropdown_content">
        <ul class="notificationList_container">
            <li class="notificationListLi" *ngFor="let notification of notificationHistoryResults">
                <a class="notificationListLink" (click)="showNotification(notification)">
                    <div class="notificationListIcon">
                        <i *ngIf="notification.notificationType == 'TEXT'" aria-hidden="true" class="mt-10px fal fa-file-alt"></i>
                        <i *ngIf="notification.notificationType == 'TEMPLATE'" aria-hidden="true" class="mt-10px fal fa-file-code"></i>
                    </div>
                    <div class="notificationListMessage_container">
                        <div class="notificationListMessage_label" [ngClass]="{'notification-displayed': notification.displayed,'notification-not-displayed': !notification.displayed}">
                            {{ notification.subject }}
                        </div>
                        <div class="notificationListMessage_subtitle">
                            <label class="cursorPointer" [ngClass]="{'notification-displayed': notification.displayed,'notification-not-displayed': !notification.displayed}">{{ getDateFriendly(notification.fromDateTime) }}</label>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
    </div>

    <div *ngIf="notificationHistoryResults.length === 0" class="emptyState_wrapper">
        <div class="emptyState_container">
            <img src="{{emptyStateIcon}}" alt="" class="emptyStateImage">
            <h3>No Notifications to Display!</h3>
        </div>
    </div>

    <div class="headerNotificationDropdown_content"></div>
</div>
