<app-loader *ngIf="preloadingModules">
    Loading...
</app-loader>

<ng-container *ngIf="!preloadingModules">

    <app-notification-alert></app-notification-alert>

    <router-outlet></router-outlet>

    <ng-template #dialogContainer></ng-template>

    <ng-container *ngIf="activeDialog$ | async">
        <ng-container
            *ngComponentOutlet="(activeDialog$ | async).component; injector: (activeDialog$ | async).injector">
        </ng-container>
    </ng-container>
</ng-container>

