import * as actions from './actions';

import {AndroidDeviceInfoResponseModel} from "../../../../../../../../../core/modules/rest/android-device-info/response/android-device-info-response.model";
import {createFeatureSelector, createSelector} from "@ngrx/store";

export const REDUCER_NAME = "dialog_add-android-device-info-remote-command";

export interface State {
    androidDeviceInfo: AndroidDeviceInfoResponseModel;
}

export const initialState: State = {
    androidDeviceInfo: {} as AndroidDeviceInfoResponseModel
};


// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const androidDeviceInfo_selector = createSelector(state_selector, (state: State) => state.androidDeviceInfo);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_ANDROID_DEVICE_INFO: return { ... state, androidDeviceInfo: action.androidDeviceInfo };

        default: return state;
    }
}
