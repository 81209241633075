import { EmailHtmlTemplateTypeEnum } from "../../../../../shared/enums/email-html-template-type.enum";

export class UpdateEmailHtmlTemplateRequestModel {
    emailHtmlTemplateId: number;
    emailHtmlTemplateType: EmailHtmlTemplateTypeEnum;
    description: string;
    senderEmailAddress: string;
    senderEmailName: string;
    emailSubject: string;
    html: string;

    constructor(emailHtmlTemplateId: number, emailHtmlTemplateType: EmailHtmlTemplateTypeEnum, description: string, senderEmailAddress: string, senderEmailName: string, emailSubject: string, html: string) {
        this.emailHtmlTemplateId = emailHtmlTemplateId;
        this.emailHtmlTemplateType = emailHtmlTemplateType;
        this.description = description;
        this.senderEmailAddress = senderEmailAddress;
        this.senderEmailName = senderEmailName;
        this.emailSubject = emailSubject;
        this.html = html;
    }
}
