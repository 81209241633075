import {Action} from "@ngrx/store";
import {PassProductResponseModel} from "../../../../../../../../../core/modules/rest/pass/response/pass-product-response.model";
import {MultiselectDropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

export const RESET_STATE = '[Dialog - Edit Pass Products :: MAIN] Reset State';
export const SET_PASS_PRODUCTS = '[Dialog - Edit Pass Products :: MAIN] Set Pass Products';
export const SET_PRODUCTS = '[Dialog - Edit Pass Products :: MAIN] Set Products';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetPassProducts implements Action {
    readonly type = SET_PASS_PRODUCTS;

    constructor(public passProducts: PassProductResponseModel[]) {
    }
}

export class SetProducts implements Action {
    readonly type = SET_PRODUCTS;

    constructor(public products: MultiselectDropdownOptionModel[]) {
    }
}

export type All = ResetState
    | SetPassProducts
    | SetProducts;
