import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild  } from '@angular/core';


@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

    @ViewChild('searchInput') searchInput: ElementRef;
    @Output() searchTextChanged: EventEmitter<string> = new EventEmitter<string>();

    @Input() placeholder: string = 'Search filter or data';


    constructor() {
    }

    ngOnInit() {
    }

    onTextChanged() {
        this.searchTextChanged.emit(this.searchInput.nativeElement.value);
    }

    clearSearch() {
        this.searchInput.nativeElement.value = "";
        this.searchTextChanged.emit(this.searchInput.nativeElement.value);
    }
}
