
import {combineLatest as observableCombineLatest, Observable} from 'rxjs';

import {take} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {ProductRestService} from "../../../../../../../../core/modules/rest/product/product-rest.service";
import {LocationListRestService} from "../../../../../../../../core/modules/rest/location-list/location-list-rest.service";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {ProductSimpleResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {LocationListResponseModel} from "../../../../../../../../core/modules/rest/location-list/response/location-list-response.model";
import {DropdownOptionModel} from "../../../../../../../../shared/components/form-elements/dropdown/dropdown-option.model";
import {SubmitProductRoutesData} from "../components/edit-product-routes-dialog-form/edit-product-routes-dialog-form.component";

@Injectable()
export class EditProductRoutesDialogSandbox extends DialogSandbox {

    product$: Observable<ProductSimpleResponseModel> = this.store.pipe(select(reducer.product_selector));
    pickupDropoffLocationLists$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.pickupDropoffLocationLists_selector));
    pickupLocationLists$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.pickupLocationLists_selector));
    dropoffLocationLists$: Observable<DropdownOptionModel[]> = this.store.pipe(select(reducer.dropoffLocationLists_selector));

    constructor(appDialogsService: AppDialogsService,
                private productRestService: ProductRestService,
                private locationListRestService: LocationListRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(productId: number) {

        observableCombineLatest(this.productRestService.getProductSimpleById(productId), this.locationListRestService.getAllActive()).pipe(
            take(1))
            .subscribe(([product, locationLists]: [ProductSimpleResponseModel, LocationListResponseModel[]]) => {
                this.store.dispatch(new actions.SetProduct(product));
                this.store.dispatch(new actions.SetPickupDropoffLocationLists(locationLists.map(ll => new DropdownOptionModel(ll.description, ll.locationListId.toString()))));
                this.store.dispatch(new actions.SetPickupLocationLists(locationLists.map(ll => new DropdownOptionModel(ll.description, ll.locationListId.toString()))));
                this.store.dispatch(new actions.SetDropoffLocationLists(locationLists.map(ll => new DropdownOptionModel(ll.description, ll.locationListId.toString()))));
            });
    }

    updateProductRoutes(data: SubmitProductRoutesData): Observable<any> {

        return this.productRestService.updateProductRoutes({
            productId: data.productId,
            tracksQuantityBetweenStops: data.tracksQuantityBetweenStops,
            usesPickupLocations: data.usesPickupLocations,
            pickupLocationListId: data.pickupLocationListId,
            usesDropoffLocations: data.usesDropoffLocations,
            dropoffLocationListId: data.dropoffLocationListId
        });
    }
}
