import * as actions from './actions';
import {ActionReducer, createFeatureSelector, createSelector} from "@ngrx/store";

export const REDUCER_NAME = 'dialog_void-order';

export interface State {
}

const initialState: State = {
};

export const state_reducer: ActionReducer<State> = reducer;

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        default: return state;
    }
}
