
import {catchError} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HolidayResponseModel} from "../../../../../../../../core/modules/rest/holiday/response/holiday-response.model";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {HolidayRestService} from "../../../../../../../../core/modules/rest/holiday/holiday-rest.service";
import {Store} from "@ngrx/store";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {CategoryResponseModel} from "../../../../../../../../core/modules/rest/category/response/category-response.model";
import {CategoryRestService} from "../../../../../../../../core/modules/rest/category/category-rest.service";
import {ProductSimpleResponseModel} from "../../../../../../../../core/modules/rest/product/response/product-simple-response.model";
import {ProductAvailabilityHolidayMappingResponseModel} from "../../../../../../../../core/modules/rest/product-availability/response/product-availability-holiday-mapping-response.model";
import {ProductRestService} from "../../../../../../../../core/modules/rest/product/product-rest.service";
import {ProductAvailabilityRestService} from "../../../../../../../../core/modules/rest/product-availability/product-availability-rest.service";
import {GetProductAvailabilityHolidayMappingRequestModel} from "../../../../../../../../core/modules/rest/product-availability/request/get-product-availability-holiday-mapping-request.model";
import {UpdateProductAvailabilityHolidayMappingRequestModel} from "../../../../../../../../core/modules/rest/product-availability/request/update-product-availability-holiday-mapping-request.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";

@Injectable()
export class MappingHolidayDialogSandbox extends DialogSandbox {

    holiday$: Observable<HolidayResponseModel> = this.store.select(reducer.holiday_selector);

    categories$: Observable<CategoryResponseModel[]> = this.store.select(reducer.categories_selector);
    categoriesLoading$: Observable<boolean> = this.store.select(reducer.categories_loading_selector);

    products$: Observable<ProductSimpleResponseModel[]> = this.store.select(reducer.products_selector);
    productsLoading$: Observable<boolean> = this.store.select(reducer.products_loading_selector);

    productAvailabilityHolidayMappingItems$: Observable<ProductAvailabilityHolidayMappingResponseModel[]> = this.store.select(reducer.product_availability_hmi_selector);
    productAvailabilityHolidayMappingItemsLoading$: Observable<boolean> = this.store.select(reducer.product_availability_hmi_loading_selector);

    constructor(appDialogsService: AppDialogsService,
                private holidayRestService: HolidayRestService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox,
                private categoryRestService: CategoryRestService,
                private productRestService: ProductRestService,
                private productAvailabilityRestService: ProductAvailabilityRestService) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadHoliday(holidayId: number) {

        this.showLoader();

        this.holidayRestService.getById(holidayId).subscribe((holiday: HolidayResponseModel) => {
            this.store.dispatch(new actions.SetHoliday(holiday));
            this.hideLoader();
        },
        () => this.hideLoader());
    }

    loadCategories() {
        this.store.dispatch(new actions.SetCategoriesLoading(true));
        this.categoryRestService.getAll()
            .subscribe((categories: CategoryResponseModel[]) => {
                this.store.dispatch(new actions.SetCategories(categories));
                this.store.dispatch(new actions.SetCategoriesLoading(false));
            },
            () => this.store.dispatch(new actions.SetCategoriesLoading(false)));
    }

    loadProductsByCategoryIds(categoryIds: number[]) {
        this.store.dispatch(new actions.SetProductsLoading(true));
        this.productRestService.getProductsByCategoryIds(categoryIds)
            .subscribe((products: ProductSimpleResponseModel[]) => {
                this.store.dispatch(new actions.SetProducts(products));
                this.store.dispatch(new actions.SetProductsLoading(false));
            },
            () => this.store.dispatch(new actions.SetProductsLoading(false)));
    }

    loadProductAvailabilityHolidayMappingByHolidayIdAndProductIds(holidayId: number, productIds: number[], onlyValid: boolean) {

        this.store.dispatch(new actions.SetProductAvailabilityHmItemsLoading(true));

        const request = new GetProductAvailabilityHolidayMappingRequestModel(holidayId, productIds, onlyValid);

        this.productAvailabilityRestService.getProductAvailabilityHolidayMappingByHolidayIdAndProductIds(request)
            .subscribe((items: ProductAvailabilityHolidayMappingResponseModel[]) => {
                this.store.dispatch(new actions.SetProductAvailabilityHmItems(items));
                this.store.dispatch(new actions.SetProductAvailabilityHmItemsLoading(false));
            },
            () => this.store.dispatch(new actions.SetProductAvailabilityHmItemsLoading(false)));
    }

    updateProductAvailabilityHolidayMapping(holidayId: number, productIds: number[], unselectedProductAvailabilityIds: number[], selectedProductAvailabilityIds: number[]): Observable<any> {

        const request = new UpdateProductAvailabilityHolidayMappingRequestModel(
            holidayId,
            productIds,
            unselectedProductAvailabilityIds,
            selectedProductAvailabilityIds
        );

        return this.productAvailabilityRestService.updateProductAvailabilityHolidayMapping(request).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating product availability - holiday mapping', error);
            }));
    }
}
