import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { SaleGroupResponseModel } from '../../../../../../../../core/modules/rest/sale-group/response/sale-group-response.model';
import { SaleGroupDomainFormSubmitDataModel } from '../model/sale-group-domain-form-submit-data.model';
import { SaleGroupRestService } from '../../../../../../../../core/modules/rest/sale-group/sale-group-rest.service';
import { HttpErrorModel } from '../../../../../../../../core/modules/rest/http-error.model';
import { catchError } from 'rxjs/operators';
import { RootSandbox } from '../../../../../../../../core/store/root.sandbox';
import { forkJoin } from 'rxjs';
import { DomainResponseModel } from '../../../../../../../../core/modules/rest/domain/response/domain-response.model';
import { MultiselectDropdownOptionModel } from '../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model';

@Component({
    selector: 'app-add-sale-group-domain-form',
    templateUrl: './add-sale-group-domain-form.component.html'
})
export class AddSaleGroupDomainFormComponent implements OnInit, OnChanges {

    @Input() saleGroup: SaleGroupResponseModel;
    @Input() saleGroupId: number;
    @Input() saleGroupName: number;

    @Output() submitEvent = new EventEmitter<SaleGroupDomainFormSubmitDataModel>();
    @Output() closeEvent = new EventEmitter<void>();

    domains: any;
    form: FormGroup;
    isFormValid: boolean = false;

    domainOptions: MultiselectDropdownOptionModel[] = [];
    areDomainsLoading: boolean;
    domainIds: number[];
    search: string = '';

    constructor(
        private saleGroupRestService: SaleGroupRestService,
        private rootSandbox: RootSandbox,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes != null && changes.domain != null && Object.keys(changes.domain.currentValue).length !== 0) {
            this.isFormValid = true;
            this.form.patchValue({
                domainId: changes.domain.currentValue.domain,
            });
        }
    }

    ngOnInit() {

        let domainId: number = 0;

        this.form = new FormGroup({
            domainId: new FormControl(domainId),
            domain: new FormControl()
        });

        this.areDomainsLoading = true;

        forkJoin([
            this.saleGroupRestService.getAllDomains().pipe(
                catchError((error: HttpErrorModel) => {
                    this.areDomainsLoading = false;
                    return this.rootSandbox.handleHttpError('Error while loading Domains', error);
                })
            ),
            this.saleGroupRestService.getDomainsForSaleGroup(this.saleGroupId).pipe(
                catchError((error: HttpErrorModel) => {
                    return this.rootSandbox.handleHttpError('Error while loading Domains', error);
                })
            ),
        ]).subscribe(([domains, saleGroupDomains]: [DomainResponseModel[], DomainResponseModel[]]) => {
            this.domains = domains.filter((domain) =>
                !saleGroupDomains.some((saleGroupDomain) => saleGroupDomain.domainId === domain.domainId)
            ).sort((a, b) => a.domainName.localeCompare(b.domainName));
            this.domainOptions = this.domains
            .map((domain: DomainResponseModel) => new MultiselectDropdownOptionModel(domain.domainName, domain.domainId.toString()));
            this.areDomainsLoading = false;
        });

        this.form.valueChanges.subscribe(() => {
            this.isFormValid = this.form.valid;
        });

    }

    onCancelClick() {
        this.closeEvent.emit();
    }

    onSubmitClick() {

        const saleGroupName = this.form.value.saleGroupName;

        const data: SaleGroupDomainFormSubmitDataModel = new SaleGroupDomainFormSubmitDataModel(saleGroupName, this.domainIds);

        this.submitEvent.emit(data);
    }

    onSelectedDomainsChanged(values: number[]) {
        values.length > 0 ? this.isFormValid = true : this.isFormValid = false;
        this.domainIds = values;
    }

}
