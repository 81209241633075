import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../../../../../shared/dialogs/model/dialog.data";
import {Observable} from "rxjs";
import {SubmitCloneProductAvailabilitiesData} from "./components/clone-product-availabilities-dialog-form/clone-product-availabilities-dialog-form.component";
import {CloneProductAvailabilitiesDialogSandbox} from "./sandbox/clone-product-availabilities-dialog.sandbox";
import {ProductAvailabilityResponseModel} from "../../../../../../../core/modules/rest/product-availability/response/product-availability-response.model";

export class CloneProductAvailabilitiesDialogData extends DialogData {

    productId: number;
    productAvailabilityIds: number[];
    successCallbackFn: Function;

    constructor(successCallbackFn: Function, productId: number, productAvailabilityIds: number[]) {
        super();
        this.productId = productId;
        this.productAvailabilityIds = productAvailabilityIds;
        this.successCallbackFn = successCallbackFn;
    }
}

// TODO: Add Angular decorator.
@Component({
    selector: 'app-clone-product-availabilities-dialog',
    templateUrl: './clone-product-availabilities-dialog.component.html'
})
export class CloneProductAvailabilitiesDialogComponent implements OnDestroy {

    productId: number;
    productAvailabilityIds: number[];
    successCallbackFn: Function;

    productAvailabilities$: Observable<ProductAvailabilityResponseModel[]>;

    constructor(@Inject(DialogData) data: CloneProductAvailabilitiesDialogData, private sandbox: CloneProductAvailabilitiesDialogSandbox) {

        this.productId = data.productId;
        this.productAvailabilityIds = data.productAvailabilityIds;
        this.successCallbackFn = data.successCallbackFn;

        this.sandbox.loadData(this.productAvailabilityIds);

        this.productAvailabilities$ = this.sandbox.productAvailabilities$;
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onSubmit(data: SubmitCloneProductAvailabilitiesData) {
        this.sandbox.cloneProductAvailabilities(data).subscribe(() => {
            this.successCallbackFn(data.productId);
            this.sandbox.destroyDialog();
        });
    }

    onCancel() {
        this.sandbox.destroyDialog();
    }
}
