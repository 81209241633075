
import {combineLatest as observableCombineLatest, of as observableOf, forkJoin as observableForkJoin, BehaviorSubject, Observable} from 'rxjs';

import {mergeMap, take, map, catchError} from 'rxjs/operators';
import {Injectable} from "@angular/core";

import {Store} from "@ngrx/store";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {CostRestService} from "../../../../../../../../core/modules/rest/cost/cost-rest.service";
import {LocationResponseModel} from "../../../../../../../../core/modules/rest/location/response/location-response.model";
import {MultiselectDropdownOptionModel} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

import * as fromFeature from './store/reducer';
import * as fromFeatureActions from './store/actions';

import {AutoAppliedDiscountCostModel} from "../model/auto-applied-discount-cost.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {CostTypeEnum} from "../../../../../../../../shared/enums/cost-type.enum";
import {AutoAppliedDiscountResponseModel} from "../../../../../../../../core/modules/rest/cost/response/auto-applied-discount-response.model";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {UpdateCostRequestModel} from "../../../../../../../../core/modules/rest/cost/request/update-cost-request.model";
import {CreateCostRequestModel} from "../../../../../../../../core/modules/rest/cost/request/create-cost-request.model";
import * as actions from "../../../../quick-selling-button-products/dialogs/edit-quick-selling-button-product-dialog/sandbox/store/actions";

@Injectable()
export class EditAutoAppliedDiscountDialogSandbox extends DialogSandbox {

    private errorMessage$: BehaviorSubject<string>;

    constructor(appDialogsService: AppDialogsService,
                private store: Store<fromFeature.State>,
                private rootSandbox: RootSandbox,
                private costRestService: CostRestService) {
        super(appDialogsService);
        this.errorMessage$ = new BehaviorSubject<string>(null);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadDialogData(costId: number) {

        this.showLoader();

        const editAutoAppliedDiscount = !!costId;

        // Clear error message
        this.errorMessage$.next('');

        // Dialog data loading
        this.store.dispatch(new fromFeatureActions.UpdateDialogDataLoaded(false));

        // Update edit auto applied discount
        this.store.dispatch(new fromFeatureActions.UpdateEditAutoAppliedDiscount(editAutoAppliedDiscount));

        // Get auto applied discount by id
        const getAutoAppliedDiscountById_Request = this.costRestService.getAutoAppliedDiscountById(costId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading auto applied discount by id', error);
            }));

        // Get all locations
        const getAllLocations_Request = this.rootSandbox.getAllLocations();

        observableForkJoin([getAllLocations_Request, editAutoAppliedDiscount ? getAutoAppliedDiscountById_Request : observableOf(null)])
            .subscribe(results => {

                const allLocations: LocationResponseModel[] = results[0];
                let autoAppliedDiscount: AutoAppliedDiscountResponseModel = results[1];

                this.store.dispatch(new fromFeatureActions.UpdateAllLocations(allLocations));

                if (!autoAppliedDiscount) {
                    autoAppliedDiscount = new AutoAppliedDiscountCostModel();
                }

                this.store.dispatch(new fromFeatureActions.UpdateAutoAppliedDiscount(autoAppliedDiscount));

                // Dialog data loaded
                this.store.dispatch(new fromFeatureActions.UpdateDialogDataLoaded(true));

                this.hideLoader();
            },
            () => this.hideLoader());
    }

    updateAutoAppliedDiscountProperty(autoAppliedDiscount: AutoAppliedDiscountCostModel) {
        this.store.dispatch(new fromFeatureActions.UpdateAutoAppliedDiscount(autoAppliedDiscount));
    }

    getDialogDataLoaded(): Observable<boolean> {
        return this.store.select(fromFeature.dialogDataLoaded_selector);
    }

    getEditAutoAppliedDiscount(): Observable<boolean> {
        return this.store.select(fromFeature.editAutoAppliedDiscount_selector);
    }

    getAllLocationsForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        return this.store.select(fromFeature.allLocations_selector).pipe(
            map((locations: LocationResponseModel[]) => {
                return locations.map((location: LocationResponseModel) => {
                    return new MultiselectDropdownOptionModel(location.description, location.locationId.toString());
                });
            }));
    }

    getAutoAppliedDiscount(): Observable<AutoAppliedDiscountCostModel> {
        return this.store.select(fromFeature.autoAppliedDiscount_selector);
    }

    getErrorMessage(): Observable<string> {
        return this.errorMessage$.asObservable();
    }

    save(): Observable<boolean> {
        return observableCombineLatest(
            this.getEditAutoAppliedDiscount(),
            this.getAutoAppliedDiscount()
        ).pipe(
            take(1),
            mergeMap(([editAutoAppliedDiscount, autoAppliedDiscount]) => {
                const editAutoAppliedDiscountParameterized: boolean = editAutoAppliedDiscount;
                const autoAppliedDiscountParameterized: AutoAppliedDiscountCostModel = autoAppliedDiscount;

                let locationIds: number[] = autoAppliedDiscountParameterized.allLocations ? [] : autoAppliedDiscountParameterized.locationIds;
                locationIds = locationIds ? locationIds : [];

                // Validate data
                if (!autoAppliedDiscountParameterized.description || autoAppliedDiscountParameterized.description.trim().length === 0) {
                    this.errorMessage$.next('Please enter description');
                    return observableOf(false);
                }

                if (!autoAppliedDiscountParameterized.fromHeadCount && autoAppliedDiscountParameterized.fromHeadCount !== 0) {
                    this.errorMessage$.next('Please enter head count from');
                    return observableOf(false);
                }

                if (!autoAppliedDiscountParameterized.toHeadCount && autoAppliedDiscountParameterized.toHeadCount !== 0) {
                    this.errorMessage$.next('Please enter head count to');
                    return observableOf(false);
                }

                if (autoAppliedDiscountParameterized.amount === null) {
                    this.errorMessage$.next('Please enter amount');
                    return observableOf(false);
                }

                if (!autoAppliedDiscountParameterized.amountType) {
                    this.errorMessage$.next('Please select amount type');
                    return observableOf(false);
                }

                if (!autoAppliedDiscountParameterized.discountApplyType) {
                    this.errorMessage$.next('Please select discount apply type');
                    return observableOf(false);
                }

                // Clear error message
                this.errorMessage$.next('');

                if (editAutoAppliedDiscountParameterized) {
                    const data: UpdateCostRequestModel = new UpdateCostRequestModel(
                        autoAppliedDiscountParameterized.costId,
                        autoAppliedDiscountParameterized.costType,
                        autoAppliedDiscountParameterized.description,
                        autoAppliedDiscountParameterized.amount,
                        autoAppliedDiscountParameterized.amountType,
                        false,
                        autoAppliedDiscountParameterized.glCode,
                        null,
                        null,
                        null,
                        autoAppliedDiscountParameterized.fromHeadCount,
                        autoAppliedDiscountParameterized.toHeadCount,
                        autoAppliedDiscountParameterized.discountApplyType,
                        autoAppliedDiscountParameterized.allLocations,
                        locationIds,
                        false,
                        null);

                    return this.costRestService.update(data).pipe(
                        map(() => {
                            return true;
                        }),
                        catchError((error: HttpErrorModel) => {
                            return this.rootSandbox.handleHttpError('Error while updating auto applied discount', error);
                        }),);
                } else {
                    const data: CreateCostRequestModel = new CreateCostRequestModel(
                        CostTypeEnum.AUTO_APPLIED_DISCOUNT,
                        autoAppliedDiscountParameterized.description,
                        autoAppliedDiscountParameterized.amount,
                        autoAppliedDiscountParameterized.amountType,
                        false,
                        autoAppliedDiscountParameterized.glCode,
                        null,
                        null,
                        null,
                        autoAppliedDiscountParameterized.fromHeadCount,
                        autoAppliedDiscountParameterized.toHeadCount,
                        autoAppliedDiscountParameterized.discountApplyType,
                        autoAppliedDiscountParameterized.allLocations,
                        locationIds,
                        false,
                        null);

                    return this.costRestService.create(data).pipe(
                        map(() => {
                            return true;
                        }),
                        catchError((error: HttpErrorModel) => {
                            return this.rootSandbox.handleHttpError('Error while creating auto applied discount', error);
                        }),);
                }
            }),);
    }
}
