import { Action } from '@ngrx/store';
import { DomainResponseModel } from '../../../../../../../core/modules/rest/domain/response/domain-response.model';


export const UPDATE_DOMAINS_ACTION = '[Domain Managment Main] Update domain managment';
export const UPDATE_SHOW_INACTIVE_DOMAINS_ACTION = '[Domain Managment Main] Update show inactive domains';


export class UpdateDomainManagment implements Action {
    readonly type = UPDATE_DOMAINS_ACTION;

    constructor(public domains: DomainResponseModel[]) {
    }
}

export class UpdateShowInactiveDomain implements Action {
    readonly type = UPDATE_SHOW_INACTIVE_DOMAINS_ACTION;

    constructor(public show: boolean) {
    }
}

export type Actions =
    UpdateDomainManagment |
    UpdateShowInactiveDomain;
