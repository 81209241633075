import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges  } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {
    MultiselectDropdownOptionActiveModel,
    OPTION_ALL
} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option-active.model";

export interface SubmitCostMappingsData {
    costId: number;
    categoryIds: number[];
    productIds: number[];
    passIds: number[];
}


@Component({
    selector: 'app-mapping-cost-dialog-form',
    templateUrl: './mapping-cost-dialog-form.component.html'
})

export class MappingCostDialogFormComponent implements OnChanges {

    @Input() costId: number;
    @Input() costDescription: string;
    @Input() loading: boolean;
    @Input() categories: MultiselectDropdownOptionActiveModel[];
    @Input() mappedCategories: number[];
    @Input() products: MultiselectDropdownOptionActiveModel[];
    @Input() mappedProducts: number[];
    @Input() passes: MultiselectDropdownOptionActiveModel[];
    @Input() mappedPasses: number[];

    @Output() submit: EventEmitter<SubmitCostMappingsData> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    form: FormGroup;

    hasError: boolean = false;
    errorMessage: string = "";

    showInactiveCategories: boolean = false;
    showInactiveProducts: boolean = false;
    showInactivePasses: boolean = false;
    categoriesInit: MultiselectDropdownOptionActiveModel[];
    productsInit: MultiselectDropdownOptionActiveModel[];
    passesInit: MultiselectDropdownOptionActiveModel[];


    constructor() {

        this.form = new FormGroup({
            categoryIds: new FormControl(),
            productIds: new FormControl(),
            passIds: new FormControl()
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.mappedCategories && changes.mappedProducts && changes.mappedPasses) {
            this.initializeFormData();
        }
        if (changes.categories) {
            this.categoriesInit = this.categories;
            this.onShowInactiveCategories(this.showInactiveCategories);

        }
        if (changes.products) {
            this.productsInit = this.products;
            this.onShowInactiveProducts(this.showInactiveProducts);
        }
        if (changes.passes) {
            this.passesInit = this.passes;
            this.onShowInactivePasses(this.showInactivePasses);
        }
    }

    onSubmit() {

        let categoryIds: number[] = this.form.value.categoryIds.filter(cid => cid !== OPTION_ALL).map(cid => +cid);
        let productIds: number[] = this.form.value.productIds.filter(pid => pid !== OPTION_ALL).map(pid => +pid);
        let passIds: number[] = this.form.value.passIds.filter(pid => pid !== OPTION_ALL).map(pid => +pid);

        this.submit.emit({
            costId: this.costId,
            categoryIds: categoryIds,
            productIds: productIds,
            passIds: passIds
        });
    }

    onCancel() {
        this.cancel.emit();
    }

    onShowInactiveCategories(value: boolean) {

        this.showInactiveCategories = value;

        if (value) {
            this.categories = this.categoriesInit;
        }
        else {
            this.categories = this.categories.filter((category: MultiselectDropdownOptionActiveModel) => category.active == true);
        }

    }

    onShowInactiveProducts(value: boolean) {

        this.showInactiveProducts = value;

        if (value) {
            this.products = this.productsInit;
        }
        else {
            this.products = this.products.filter((product: MultiselectDropdownOptionActiveModel) => product.active == true);
        }

    }

    onShowInactivePasses(value: boolean) {

        this.showInactivePasses = value;

        if (value) {
            this.passes = this.passesInit;
        }
        else {
            this.passes = this.passes.filter((pass: MultiselectDropdownOptionActiveModel) => pass.active == true);
        }
    }

    private initializeFormData(): void {
        this.form.patchValue({
            categoryIds: this.mappedCategories,
            productIds: this.mappedProducts,
            passIds: this.mappedPasses
        });
    }
}
