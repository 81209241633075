import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ProductCostResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-cost-response.model";
import {MultiselectDropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

export const REDUCER_NAME = 'dialog_edit-category-costs';

export interface State {
    loading: boolean;
    categoryAdditionalCosts: ProductCostResponseModel[];
    additionalCosts: MultiselectDropdownOptionModel[];
    categoryIncludedCosts: ProductCostResponseModel[];
    includedCosts: MultiselectDropdownOptionModel[];
    categoryDiscounts: ProductCostResponseModel[];
    discounts: MultiselectDropdownOptionModel[];
    categoryAutoAppliedDiscounts: ProductCostResponseModel[];
    autoAppliedDiscounts: MultiselectDropdownOptionModel[];
}

const INITIAL_STATE: State = {
    loading: false,
    categoryAdditionalCosts: [],
    additionalCosts: [],
    categoryIncludedCosts: [],
    includedCosts: [],
    categoryDiscounts: [],
    discounts: [],
    categoryAutoAppliedDiscounts: [],
    autoAppliedDiscounts: []
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const loading_selector = createSelector(state_selector, (state: State) => state.loading);
export const categoryAdditionalCosts_selector = createSelector(state_selector, (state: State) => state.categoryAdditionalCosts);
export const additionalCosts_selector = createSelector(state_selector, (state: State) => state.additionalCosts);
export const categoryIncludedCosts_selector = createSelector(state_selector, (state: State) => state.categoryIncludedCosts);
export const includedCosts_selector = createSelector(state_selector, (state: State) => state.includedCosts);
export const categoryDiscounts_selector = createSelector(state_selector, (state: State) => state.categoryDiscounts);
export const discounts_selector = createSelector(state_selector, (state: State) => state.discounts);
export const categoryAutoAppliedDiscounts_selector = createSelector(state_selector, (state: State) => state.categoryAutoAppliedDiscounts);
export const autoAppliedDiscounts_selector = createSelector(state_selector, (state: State) => state.autoAppliedDiscounts);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;

        case actions.SET_LOADING:
            return {...state, loading: action.loading};

        case actions.SET_CATEGORY_ADDITIONAL_COSTS:
            return {...state, categoryAdditionalCosts: action.categoryAdditionalCosts};

        case actions.SET_ADDITIONAL_COSTS:
            return {...state, additionalCosts: action.additionalCosts};

        case actions.SET_CATEGORY_INCLUDED_COSTS:
            return {...state, categoryIncludedCosts: action.categoryIncludedCosts};

        case actions.SET_INCLUDED_COSTS:
            return {...state, includedCosts: action.includedCosts};

        case actions.SET_CATEGORY_DISCOUNTS:
            return {...state, categoryDiscounts: action.categoryDiscounts};

        case actions.SET_DISCOUNTS:
            return {...state, discounts: action.discounts};

        case actions.SET_CATEGORY_AUTO_APPLIED_DISCOUNTS:
            return {...state, categoryAutoAppliedDiscounts: action.categoryAutoAppliedDiscounts};

        case actions.SET_AUTO_APPLIED_DISCOUNTS:
            return {...state, autoAppliedDiscounts: action.autoAppliedDiscounts};

        default:
            return state;
    }
}
