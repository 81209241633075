import {Observable} from 'rxjs';
import {TiersResponseModel} from './response/tiers-response.model';
import {Injectable} from '@angular/core';
import {CreateBaseResponseModel} from '../create-base-response.model';
import {CreateTierRequestModel} from './request/create-tier-request.model';
import {UpdateTierRequestModel} from './request/update-tier-request.model';
import {TierResponseModel} from './response/tier-response.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class TierRestService {

    constructor(private httpClient: HttpClient) {
    }

    getById(tierId: number): Observable<TierResponseModel> {
        return this.httpClient.get<TierResponseModel>(environment.server_api_url + '/api/secured/tier/getById/' + tierId);
    }

    getAll(): Observable<TiersResponseModel> {
        return this.httpClient.get<TiersResponseModel>(environment.server_api_url + '/api/secured/tier/getAll');
    }

    getAllActive(): Observable<TiersResponseModel> {
        return this.httpClient.get<TiersResponseModel>(environment.server_api_url + '/api/secured/tier/getAllActive');
    }

    create(request: CreateTierRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/tier/create', request);
    }

    update(request: UpdateTierRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/tier/update', request, {responseType: 'text'});
    }

    activate(tierId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/tier/activate', tierId, {responseType: 'text'});
    }

    deactivate(tierId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/tier/deactivate', tierId, {responseType: 'text'});
    }
}
