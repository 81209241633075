import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {PricingDateRangeRestService} from "../../../../../../../../core/modules/rest/pricing-date-range/pricing-date-range-rest.service";
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {PricingDateRangeResponseModel} from "../../../../../../../../core/modules/rest/pricing-date-range/response/pricing-date-range-response.model";
import {SubmitCloneProductPricingDateRangesData} from "../components/clone-product-pricing-date-ranges-dialog-form/clone-product-pricing-date-ranges-dialog-form.component";
import moment from "moment";

@Injectable()
export class CloneProductPricingDateRangesDialogSandbox extends DialogSandbox {

    pricingDateRanges$: Observable<PricingDateRangeResponseModel[]> = this.store.pipe(select(reducer.pricingDateRanges_selector));

    constructor(appDialogsService: AppDialogsService,
                private pricingDateRangeRestService: PricingDateRangeRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(pricingDateRangeIds: number[]) {

        this.pricingDateRangeRestService.getPricingDateRangesByPricingDateRangeIds(pricingDateRangeIds)
            .subscribe((pricingDateRanges: PricingDateRangeResponseModel[]) => {
                this.store.dispatch(new actions.SetPricingDateRanges(pricingDateRanges));
            });
    }

    cloneProductPricingDateRanges(data: SubmitCloneProductPricingDateRangesData): Observable<any> {

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return this.pricingDateRangeRestService.clonePricingDateRanges({
            startDateMS: data.startDate ? moment.parseZone(data.startDate, timeZone).valueOf() : null,
            endDateMS: data.endDate ? moment.parseZone(data.endDate, timeZone).valueOf() : null,
            pricingDateRangeIds: data.pricingDateRangeIds
        });
    }
}
