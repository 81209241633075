import {Action} from "@ngrx/store";
import {KioskConfigurationDropoffLocationPassTierModel} from "../../model/kiosk-configuration-dropoff-location-pass-tier.model";

export const RESET_STATE_ACTION = "[Dialog Pass Tier Setup :: MAIN] Reset State";
export const SET_PASS_TIERS     = "[Dialog Pass Tier Setup :: MAIN] Set Pass Tiers";

export class ResetStateAction implements Action {
    readonly type = RESET_STATE_ACTION;

    constructor() {
    }
}

export class SetPassTiers implements Action {
    readonly type = SET_PASS_TIERS;

    constructor(public passTiers: KioskConfigurationDropoffLocationPassTierModel[]) {
    }
}

export type All = ResetStateAction | SetPassTiers;
