import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {NgxPermissionsModule} from 'ngx-permissions';
import {SharedModule} from '../../../../../shared/shared.module';
import {RouterModule, Routes} from '@angular/router';
import {CommissionReportSandbox} from './sandbox/commission-report.sandbox';
import {CommissionReportComponent} from './container/commission-report.component';
import {CommissionReportContentComponent} from './components/commission-report-content/commission-report-content.component';
import {CommissionReportFilterComponent} from './components/commission-report-content/commission-report-filter/commission-report-filter.component';
import {CommissionReportTableWrapperComponent} from './components/commission-report-content/commission-report-table-wrapper/commission-report-table-wrapper.component';
import {CommissionReportTableCaptionComponent} from './components/commission-report-content/commission-report-table-wrapper/commission-report-table-caption/commission-report-table-caption.component';
import {CommissionReportTableElementComponent} from './components/commission-report-content/commission-report-table-wrapper/commission-report-table-element/commission-report-table-element.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import * as fromReducer from './sandbox/store/reducer';

const routes: Routes = [
    {path: '', component: CommissionReportComponent}
];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature(fromReducer.REDUCER_NAME, fromReducer.reducer),
        NgxPermissionsModule.forChild(),
        InfiniteScrollModule
    ],
    declarations: [
        CommissionReportComponent,
        CommissionReportContentComponent,
        CommissionReportFilterComponent,
        CommissionReportTableWrapperComponent,
        CommissionReportTableCaptionComponent,
        CommissionReportTableElementComponent
    ],
    providers: [
        CommissionReportSandbox
    ]
})
export class CommissionReportModule {

}
