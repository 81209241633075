
import {combineLatest as observableCombineLatest, of as observableOf, Observable} from 'rxjs';

import {take, filter, flatMap, map} from 'rxjs/operators';
import {Component, Inject, OnDestroy} from '@angular/core';
import {DialogData} from "../../../model/dialog.data";
import {OrderSummaryResponseModel} from "../../../../../core/modules/rest/order-summary/response/order-summary-response.model";
import {OrderPrintingInfoSummaryResponseModel} from "../../../../../core/modules/rest/order-summary/response/order-printing-info-summary-response.model";
import {OrderSummaryDialogSandbox} from "../sandbox/order-summary-dialog.sandbox";
import {
    VoidOrderDialogComponent,
    VoidOrderDialogData
} from '../../void-order-dialog/container/void-order-dialog.component';
import {AppDialogsService} from '../../../../../core/services/app-dialogs.service';
import {UserInfoResponseModel} from '../../../../../core/modules/rest/user/response/user-info-response.model';
import {PermissionEnum} from '../../../../enums/permission.enum';
import {NgxPermissionsService} from "ngx-permissions";
import {AppPrintingService} from "../../../../../core/services/app-printing-service";
import {PrintOrderDialogComponent, PrintOrderDialogData} from "../../print-order-dialog/print-order-dialog.component";
import {SendEmailDialogComponent, EmailOrderDialogData} from "../../send-email-dialog/send-email-dialog.component";
import {RootSandbox} from "../../../../../core/store/root.sandbox";
import {UserResponseModel} from "../../../../../core/modules/rest/user/response/user-response.model";
import {EditOrderEventDataModel} from "../components/general-info-dialog-summary/general-info-dialog-summary.component";
import {EditOrderDetailEventDataModel} from "../components/product-details-dialog-summary/product-detail-dialog-summary/product-detail-dialog-summary.component";
import {EditPassOrderDetailEventDataModel} from "../components/pass-details-dialog-summary/pass-detail-dialog-summary/pass-detail-dialog-summary.component";
import {LocationResponseModel} from "../../../../../core/modules/rest/location/response/location-response.model";
import {ProductSimpleResponseModel} from "../../../../../core/modules/rest/product/response/product-simple-response.model";
import {YesNoDialogComponent, YesNoDialogData} from "../../yes-no-dialog/container/yes-no-dialog.component";
import {StartTimeResponseModel} from "../../../../../core/modules/rest/product-availability/response/start-time-response.model";
import {DateChangedDataModel} from "../model/date-changed-data.model";
import { PassOrderDetailSummaryResponseModel } from '../../../../../core/modules/rest/order-summary/response/pass-order-detail-summary-response.model';
import { OrderStatusEnum } from '../../../../../shared/enums/order-status.enum';
import { OrderDetailSummaryResponseModel } from '../../../../../core/modules/rest/order-summary/response/order-detail-summary-response.model';
import { RelatedOrderSummaryResponseModel } from '../../../../../core/modules/rest/order-summary/response/related-order-summary-response.model';

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class OrderSummaryDialogData extends DialogData {

    orderId: number;
    additionalPrintingInformation: OrderPrintingInfoSummaryResponseModel;
    newOrder: boolean;

    constructor(orderId: number, additionalPrintingInfo: OrderPrintingInfoSummaryResponseModel = null, newOrder: boolean = false) {
        super();
        this.orderId = orderId;
        this.additionalPrintingInformation = additionalPrintingInfo;
        this.newOrder = newOrder;
    }
}

// -------------------------------------------------------------------------------------------------
// Smart component that represents order summary dialog
// -------------------------------------------------------------------------------------------------
// TODO: Add Angular decorator.
@Component({
    selector: 'app-order-summary-dialog',
    templateUrl: './order-summary-dialog.component.html'
})
export class OrderSummaryDialogComponent implements OnDestroy {

    orderId: number;
    orderSummary$: Observable<OrderSummaryResponseModel>;
    currentUser$: Observable<UserInfoResponseModel>;
    users$: Observable<UserResponseModel[]>;
    locations$: Observable<LocationResponseModel[]>;
    products$: Observable<ProductSimpleResponseModel[]>;
    allowFixedTimeForFlexibleAndMultipleCheckInProducts$: Observable<boolean>;
    startTimesForEditedOrderDetailLoading$: Observable<boolean>;
    startTimesForEditedOrderDetail$: Observable<StartTimeResponseModel[]>;
    passOrderDetails: PassOrderDetailSummaryResponseModel[];
    orderDetails: OrderDetailSummaryResponseModel[];
    legacyOrder: boolean;
    relatedOrders: RelatedOrderSummaryResponseModel[];
    parentOrder: boolean;

    readonly CAN_PRINT_ORDER_ONCE: PermissionEnum = PermissionEnum.PRINT_ORDER_ONCE;
    readonly CAN_PRINT_ORDER_TWICE: PermissionEnum = PermissionEnum.PRINT_ORDER_TWICE;
    readonly CAN_PRINT_ORDER_MULTIPLE_TIMES: PermissionEnum = PermissionEnum.PRINT_ORDER_MULTIPLE_TIMES;
    readonly CAN_EMAIL_ORDER: PermissionEnum = PermissionEnum.EMAIL_ORDER;
    readonly CAN_VOID_ORDER: PermissionEnum = PermissionEnum.VOID_ORDER;
    readonly CAN_VOID_COMPLETED_ORDER: PermissionEnum = PermissionEnum.VOID_COMPLETED_ORDER;
    readonly CAN_VOID_OWN_ORDER_IN_LAST_24_HOURS: PermissionEnum = PermissionEnum.VOID_OWN_ORDER_IN_LAST_24_HOURS;
    readonly CAN_VOID_EXPIRED_ORDER: PermissionEnum = PermissionEnum.VOID_EXPIRED_ORDER;
    readonly CAN_EDIT_ORDER_DETAILS: PermissionEnum = PermissionEnum.EDIT_ORDER_DETAILS;
    readonly CAN_EDIT_COMPLETED_ORDER: PermissionEnum = PermissionEnum.EDIT_COMPLETED_ORDER;
    readonly CAN_PRINT_ORDER_SUMMARY: PermissionEnum = PermissionEnum.PRINT_ORDER_SUMMARY;
    readonly CAN_CLONE_ORDER: PermissionEnum = PermissionEnum.CLONE_ORDER;
    readonly CAN_EDIT_ORDER: PermissionEnum = PermissionEnum.EDIT_ORDER;

    constructor(@Inject(DialogData) private dialogData: OrderSummaryDialogData,
                private appDialogsService: AppDialogsService,
                private appPrintingService: AppPrintingService,
                private sandbox: OrderSummaryDialogSandbox,
                private rootSandbox: RootSandbox,
                private permissionService: NgxPermissionsService) {

        this.orderId = dialogData.orderId;

        this.sandbox.loadOrderSummary(this.orderId, this.dialogData.additionalPrintingInformation, this.dialogData.newOrder);

        this.orderSummary$ = this.sandbox.orderSummary$;
        this.currentUser$ = this.sandbox.currentUser$;
        this.users$ = this.sandbox.users$;
        this.locations$ = this.sandbox.locations$;
        this.products$ = this.sandbox.products$;
        this.allowFixedTimeForFlexibleAndMultipleCheckInProducts$ = this.sandbox.allowFixedTimeForFlexibleAndMultipleCheckInProducts$;
        this.startTimesForEditedOrderDetailLoading$ = this.sandbox.startTimesForEditedOrderDetailLoading$;
        this.startTimesForEditedOrderDetail$ = this.sandbox.startTimesForEditedOrderDetail$;
        this.orderSummary$.subscribe(res => {
            if(res.passOrderDetails){
                this.currentUser$.subscribe(user => {
                    if (this.canViewCancelledDetails(user)) {
                        this.passOrderDetails = res.passOrderDetails;
                    }
                    else {
                        this.passOrderDetails = res.passOrderDetails?res.passOrderDetails.filter(pod => pod.orderStatus != OrderStatusEnum.CANCELLED):[];
                    }
                  });
            }

            if(res.orderDetails){
                this.currentUser$.subscribe(user => {
                    if (this.canViewCancelledDetails(user)) {
                        this.orderDetails = res.orderDetails;
                    }
                    else {
                        this.orderDetails = res.orderDetails?res.orderDetails.filter(od => od.orderStatus != OrderStatusEnum.CANCELLED):[];
                    }
                  });
            }

            this.parentOrder = res.parentOrder;

            if(res.relatedOrders){
                this.relatedOrders = res.relatedOrders
            }

            this.legacyOrder = res.legacyOrder;
        })
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    canViewCancelledDetails(currentUser: UserInfoResponseModel): boolean {
        return currentUser.permissions.includes(PermissionEnum.CAN_VIEW_CANCELLED_DETAILS);
    }

    canViewOrderSummary(currentUser: UserInfoResponseModel): boolean {
        return currentUser.permissions.includes(PermissionEnum.VIEW_ORDER);
    }

    printOrderClick() {

        this.appDialogsService.renderDialog(PrintOrderDialogComponent, new PrintOrderDialogData(
            (regenerateBarcode: boolean) => {

                const regenerateBarcode$ = regenerateBarcode ? this.sandbox.regenerateBarcodes(this.orderId) : observableOf(null);

                regenerateBarcode$.pipe(
                    take(1),
                    flatMap(() => observableCombineLatest([this.orderSummary$, this.appPrintingService.downloadOrPrintOrder(this.orderId)])),
                    filter(([orderSummary, printingInfo]: [OrderSummaryResponseModel, OrderPrintingInfoSummaryResponseModel]) => Object.entries(orderSummary).length !== 0),
                    take(1),
                    map(([orderSummary, printingInfo]: [OrderSummaryResponseModel, OrderPrintingInfoSummaryResponseModel]) => {

                        if (Object.entries(orderSummary).length !== 0) {
                            orderSummary.printingInfos = [...orderSummary.printingInfos, printingInfo];
                            orderSummary.printingCount += 1;
                            this.reloadOrderSummary();
                        }
                    })
                ).subscribe();
            }
        ));
    }

    sendEmailOrderClick() {

        this.orderSummary$.pipe(take(1)).subscribe((orderSummary: OrderSummaryResponseModel) => {
            this.appDialogsService.renderDialog(SendEmailDialogComponent, new EmailOrderDialogData(
                orderSummary.email,
                (emailAddress: string) => {
                    this.sandbox.sendEmailOrderTo(this.orderId, emailAddress).pipe(take(1)).subscribe(() => this.rootSandbox.addInfoNotification(`Email sent to ${emailAddress}`));
                }
            ));
        });
    }

    printSummaryClick() {
       this.appPrintingService.printCurrentWindow();
    }

    reloadOrderSummary() {
        this.sandbox.loadOrderSummary(this.orderId, this.dialogData.additionalPrintingInformation, this.dialogData.newOrder);
    }

    voidOrderClick() {

        this.orderSummary$.pipe(take(1)).subscribe((orderSummary: OrderSummaryResponseModel) => {
            const hasEmail: boolean = orderSummary.email && orderSummary.email.trim().length > 0;
            this.appDialogsService.renderDialog(VoidOrderDialogComponent, new VoidOrderDialogData(this.orderId, orderSummary.orderPayments, hasEmail));
        });
    }

    onSelectedDateChanged(data: DateChangedDataModel) {
        this.sandbox.changeSelectedDateInt(data.product, data.orderDetail, data.dateInt);
    }

    onOrderDataEdit(data: EditOrderEventDataModel) {
        this.sandbox.updateOrderData(this.orderId, data);
    }

    onOrderDetailDataEdit(data: EditOrderDetailEventDataModel) {
        this.sandbox.updateOrderDetailData(this.orderId, data);
    }

    onPassOrderDetailDataEdit(data: EditPassOrderDetailEventDataModel) {
        this.sandbox.updatePassOrderDetailData(this.orderId, data);
    }

    onNoShowClicked(orderDetailId: number) {

        let dialogData = new YesNoDialogData(
            "No Show",
            "Are you sure you want mark order detail as 'No Show'?",
            "Cancel",
            "Confirm",
            "primary",
            (confirmed: boolean) => {
                if (confirmed) {
                    this.sandbox.doNoShow(this.orderId, orderDetailId);
                }
            }
        );

        this.appDialogsService.renderDialog(YesNoDialogComponent, dialogData);
    }
}
