
import { take } from 'rxjs/operators';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DialogData } from "../../../../../../../shared/dialogs/model/dialog.data";
import { DeactivateOccurrencesDialogSandbox } from "./sandbox/deactivate-occurrences-dialog.sandbox";
import { Observable } from "rxjs";
import { OccurrenceOrdersResponseModel } from "../../../../../../../core/modules/rest/occurrence/response/occurrence-orders-response.model";
import { EmailHtmlTemplateResponseModel } from "../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {
    OrderSummaryDialogComponent,
    OrderSummaryDialogData
} from "../../../../../../../shared/dialogs/common-dialogs/order-summary-dialog/container/order-summary-dialog.component";
import { AppDialogsService } from "../../../../../../../core/services/app-dialogs.service";
import { YesNoDialogComponent, YesNoDialogData } from '../../../../../../../shared/dialogs/common-dialogs/yes-no-dialog/container/yes-no-dialog.component';

export class DeactivateOccurrencesDialogData extends DialogData {

    occurrenceIds: number[];
    updateCallbackFn: Function;

    constructor(occurrenceIds: number[], updateCallbackFn: Function) {
        super();
        this.occurrenceIds = occurrenceIds;
        this.updateCallbackFn = updateCallbackFn;
    }
}


@Component({
    selector: 'app-deactivate-occurrences-dialog',
    templateUrl: './deactivate-occurrences-dialog.component.html'
})
export class DeactivateOccurrencesDialogComponent implements OnInit, OnDestroy {

    occurrenceIds: number[];
    updateCallbackFn: Function;

    occurrencesOrders$: Observable<OccurrenceOrdersResponseModel[]> = this.sandbox.occurrencesOrders$;
    emailHtmlTemplates$: Observable<EmailHtmlTemplateResponseModel[]> = this.sandbox.emailHtmlTemplates$;

    constructor(@Inject(DialogData) data: DeactivateOccurrencesDialogData, private sandbox: DeactivateOccurrencesDialogSandbox, private appDialogsService: AppDialogsService) {

        this.occurrenceIds = data.occurrenceIds;
        this.updateCallbackFn = data.updateCallbackFn;
    }

    ngOnInit(): void {
        this.sandbox.loadDialogData(this.occurrenceIds);
    }

    ngOnDestroy(): void {
        this.sandbox.resetState();
    }

    onOrderClicked(orderId: number) {
        this.appDialogsService.renderDialog(OrderSummaryDialogComponent, new OrderSummaryDialogData(orderId));
    }

    onSubmit(data: any) {
        this.sandbox.deactivateOccurrence(data.voidOrders, data.emailHtmlTemplateId).pipe(
            take(1))
            .subscribe(() => {
                this.sandbox.hideLoader();
                this.sandbox.destroyDialog();
                this.updateCallbackFn();
            },
                async () => {
                    this.sandbox.hideLoader()
                    this.sandbox.destroyDialog();
                    await new Promise(f => setTimeout(f, 1000));
                    this.showPopUp();
                    this.updateCallbackFn();
                });
    }

    onClose() {
        this.updateCallbackFn();
        this.sandbox.destroyDialog();
    }

    showPopUp() {
        let data: YesNoDialogData = new YesNoDialogData(
            "Info",
            "Data is beeing processed longer then expected.\n Selected action will be executed in the background, please refresh and check Occurrence page in a few minutes!",
            "Ok",
            "Disable",
            "occurenceDialogButton",
            (confirmed: boolean) => {
                if (confirmed) {
                }
            }
        );
        this.appDialogsService.renderDialog(YesNoDialogComponent, data);
    }
}
