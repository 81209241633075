import { Component, Inject, OnDestroy, OnInit  } from '@angular/core';
import {DialogData} from "../../../../../../../../shared/dialogs/model/dialog.data";
import {EditLocationDialogSandbox} from "../sandbox/edit-location-dialog.sandbox";
import {Observable} from "rxjs";
import {LocationResponseModel} from "../../../../../../../../core/modules/rest/location/response/location-response.model";
import {LocationFormSubmitDataModel} from "../model/location-form-submit-data.model";

// -------------------------------------------------------------------------------------------------
// Data model passed into dialog
// -------------------------------------------------------------------------------------------------
export class EditLocationDialogData extends DialogData {

    locationId: number;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(updateCallbackFn: () => void, createCallbackFn: () => void, locationId?: number) {
        super();
        this.locationId = locationId;
        this.updateCallbackFn = updateCallbackFn;
        this.createCallbackFn = createCallbackFn;
    }
}

// -------------------------------------------------------------------------------------------------
// Component that represents it
// -------------------------------------------------------------------------------------------------

@Component({
    selector: 'app-edit-location-dialog',
    templateUrl: './edit-location-dialog.component.html'
})
export class EditLocationDialogComponent implements OnDestroy, OnInit {

    location$: Observable<LocationResponseModel>;
    locationId: number;
    editMode: boolean = false;
    updateCallbackFn: () => void;
    createCallbackFn: () => void;

    constructor(@Inject(DialogData) dialogData: EditLocationDialogData, private sandbox: EditLocationDialogSandbox) {
        this.locationId = dialogData.locationId;
        this.updateCallbackFn = dialogData.updateCallbackFn;
        this.createCallbackFn = dialogData.createCallbackFn;
    }

    ngOnInit() {

        if (this.locationId && !isNaN(this.locationId)) {
            this.editMode = true;
            this.sandbox.loadLocation(this.locationId);
            this.location$ = this.sandbox.location$;
        }
    }

    ngOnDestroy() {
        this.sandbox.resetState();
    }

    onSubmit(data: LocationFormSubmitDataModel) {

        if (this.editMode) {
            this.sandbox.updateLocation(this.locationId, data).subscribe(() =>  {
                this.updateCallbackFn();
                this.sandbox.destroyDialog();
            });
        } else {
            this.sandbox.createLocation(data).subscribe(() => {
                this.createCallbackFn();
                this.sandbox.destroyDialog();
            });
        }
    }

    onClose() {
        this.sandbox.destroyDialog();
    }
}

