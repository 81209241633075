import * as actions from './actions';

export interface State {
    pageDataLoaded: boolean;
}

const initialState: State = {
    pageDataLoaded: false,
};

export function reducer(state: State = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.PAGE_DATA_LOADED:
            return handlePageDataLoadedAction(state, action);
        default:
            return state;
    }
}


// #################################################### SELECTORS ####################################################
export const pageDataLoaded_selector = (state: State) => state.pageDataLoaded;


// #################################################### HANDLERS ####################################################
function handlePageDataLoadedAction(state: State, action: actions.PageDataLoaded) {
    return {
        ...state,
        pageDataLoaded: action.loaded
    };
}
