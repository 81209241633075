<app-abstract-dialog-container>
    <app-edit-category-costs-dialog-form
        [categoryId]="categoryId"
        [loading]="loading$ | async"
        [categoryAdditionalCosts]="categoryAdditionalCosts$ | async"
        [additionalCosts]="additionalCosts$ | async"
        [categoryIncludedCosts]="categoryIncludedCosts$ | async"
        [includedCosts]="includedCosts$ | async"
        [categoryDiscounts]="categoryDiscounts$ | async"
        [discounts]="discounts$ | async"
        [categoryAutoAppliedDiscounts]="categoryAutoAppliedDiscounts$ | async"
        [autoAppliedDiscounts]="autoAppliedDiscounts$ | async"
        (submit)="onSubmit($event)"
        (cancel)="onCancel()">
    </app-edit-category-costs-dialog-form>
</app-abstract-dialog-container>
