import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-driver-manifest-report-table-caption',
    templateUrl: './driver-manifest-report-table-caption.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DriverManifestReportTableCaptionComponent implements OnInit {

    @Input() displayMessage: string;
    @Output() generateUrlForExportToXlsxDriverManifestReportEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() generateUrlForExportToPdfDriverManifestReportEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() printDriverManifestReportEvent: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit() {
    }

    exportToExcelClick() {
        this.generateUrlForExportToXlsxDriverManifestReportEvent.emit();
    }

    exportToPdfClick() {
        this.generateUrlForExportToPdfDriverManifestReportEvent.emit();
    }

    printClick() {
        this.printDriverManifestReportEvent.emit();
    }
}
