import {BehaviorSubject, Observable} from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class MousePointerOverDropAreaService {

    private previousValue = false;
    private subject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    getMousePointerOverDropArea(): Observable<boolean> {
        return this.subject.asObservable();
    }

    setMousePointerOverDropArea(value: boolean) {
        if (this.previousValue !== value) {
            this.previousValue = value;
            this.subject.next(value);
        }
    }
}
