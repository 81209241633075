import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UsersResponseModel} from './response/users-response.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {UserInfoResponseModel} from './response/user-info-response.model';
import {UserMappingResponseModel} from "./response/user-mapping-response.model";
import {UserResponseModel} from "./response/user-response.model";
import {UpdateUserBasicInfoRequestModel} from "./request/update-user-basic-info-request.model";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {CreateUserRequestModel} from "./request/create-user-request-model";
import {UpdateUserMappingRequestModel} from "./request/update-user-mapping-request.model";
import {UpdateUserFlagsRequestModel} from "./request/update-user-flags-request.model";
import {GetUsersByRoleIdsRequestModel} from "./request/get-users-by-role-ids-request.model";
import {ChangePasswordRequest} from '../auth/change-password-request.model';

@Injectable()
export class UserRestService {

    constructor(private httpClient: HttpClient) {
    }

    getById(userId: number): Observable<UserResponseModel> {
        return this.httpClient.get<UserResponseModel>(environment.server_api_url + `/api/secured/user/getById/${userId}`);
    }

    getAll(): Observable<UsersResponseModel> {
        return this.httpClient.get<UsersResponseModel>(environment.server_api_url + '/api/secured/user/getAll');
    }

    getAllActive(): Observable<UsersResponseModel> {
        return this.httpClient.get<UsersResponseModel>(environment.server_api_url + '/api/secured/user/getAllActive');
    }

    getAllSortedByDisplayName(): Observable<UsersResponseModel> {
        return this.httpClient.get<UsersResponseModel>(environment.server_api_url + '/api/secured/user/getAllSortedByDisplayName');
    }

    getAllActiveSortedByDisplayName(): Observable<UsersResponseModel> {
        return this.httpClient.get<UsersResponseModel>(environment.server_api_url + '/api/secured/user/getAllActiveSortedByDisplayName');
    }

    getAllActiveReferrersSortedByDisplayName(): Observable<UsersResponseModel> {
        return this.httpClient.get<UsersResponseModel>(environment.server_api_url + '/api/secured/user/getAllActiveReferrersSortedByDisplayName');
    }

    getAllUsersByRoleIdSortedByDisplayName(roleId: number) {
        return this.httpClient.get<UsersResponseModel>(environment.server_api_url + '/api/secured/user/getAllUsersByRoleIdSortedByDisplayName/' + roleId);
    }

    getAllUsersByRoleIdsSortedByDisplayName(request: GetUsersByRoleIdsRequestModel): Observable<UsersResponseModel> {
        return this.httpClient.post<UsersResponseModel>(environment.server_api_url + '/api/secured/user/getAllUsersByRoleIdsSortedByDisplayName', request);
    }

    getCurrentlyLoggedInUserInfo(): Observable<UserInfoResponseModel> {
        return this.httpClient.get<UserInfoResponseModel>(environment.server_api_url + '/api/secured/user/getCurrentlyLoggedInUserInfo');
    }

    getUserMapping(userId: number): Observable<UserMappingResponseModel> {
        return this.httpClient.get<UserMappingResponseModel>(environment.server_api_url + '/api/secured/user/getUserMapping/' + userId);
    }

    create(request: CreateUserRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/user/create', request);
    }

    updateUserBasicInfo(request: UpdateUserBasicInfoRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/user/updateBasicInfo', request, {responseType: 'text'});
    }

    updateUserMapping(request: UpdateUserMappingRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/user/updateUserMapping', request, {responseType: 'text'});
    }

    updateUserFlags(request: UpdateUserFlagsRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/user/updateUserFlags', request, {responseType: 'text'});
    }

    createActivateMfaRequest(userId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/user/createActivateMfaRequest', userId, {responseType: 'text'});
    }

    deactivateMfa(userId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/user/deactivateMfa', userId, {responseType: 'text'});
    }

    activate(userId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/user/activate', userId, {responseType: 'text'});
    }

    deactivate(userId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/user/deactivate', userId, {responseType: 'text'});
    }

    changePassword(request: ChangePasswordRequest): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/user/changePassword', request, {responseType: 'text'});
    }
}
