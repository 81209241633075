import {ExpressionItemModel} from './expression-item.model';
import {FilterItemModel} from './filter-item.model';
import {UUID} from 'angular2-uuid';
import {ExpressionItemTypeEnum} from './expression-item-type.enum';

export class ExpressionFilterModel implements ExpressionItemModel {
    readonly itemType: ExpressionItemTypeEnum = ExpressionItemTypeEnum.FILTER;
    readonly uuid: string;

    filter: FilterItemModel;

    constructor(filter: FilterItemModel, uuid?: string) {
        this.filter = filter;
        this.uuid = uuid ? uuid : UUID.UUID();
    }
}
