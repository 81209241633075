import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CreateBaseResponseModel} from '../../../create-base-response.model';
import {CreateAutoReportRequestModel} from './request/create-auto-report-request.model';
import {UpdateAutoReportRequestModel} from './request/update-auto-report-request.model';
import {AutoReportResponseModel} from './response/auto-report-response.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../../environments/environment';

@Injectable()
export class AutoReportRestService {

    constructor(private httpClient: HttpClient) {
    }

    getById(autoReportId: number): Observable<AutoReportResponseModel> {
        return this.httpClient.get<AutoReportResponseModel>(environment.server_api_url + '/api/secured/autoReport/getById/' + autoReportId);
    }

    getAll(): Observable<AutoReportResponseModel[]> {
        return this.httpClient.get<AutoReportResponseModel[]>(environment.server_api_url + '/api/secured/autoReport/getAll');
    }

    create(request: CreateAutoReportRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/autoReport/create', request);
    }

    update(request: UpdateAutoReportRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/autoReport/update', request, {responseType: 'text'});
    }

    activate(autoReportId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/autoReport/activate', autoReportId, {responseType: 'text'});
    }

    deactivate(autoReportId: number): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/autoReport/deactivate', autoReportId, {responseType: 'text'});
    }
}
