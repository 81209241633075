import {Injectable} from "@angular/core";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {TemplateRestService} from "../../../../../../../../core/modules/rest/template/template-rest.service";
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {TemplateItemResponseModel} from "../../../../../../../../core/modules/rest/template/response/template-item-response.model";
import {SubmitCategoryTemplateItemsData} from "../components/edit-category-template-items-dialog-form/edit-category-template-items-dialog-form.component";
import {CustomFieldTypeEnum} from "../../../../../../../../shared/enums/custom-field-type.enum";

@Injectable()
export class EditCategoryTemplateItemsDialogSandbox extends DialogSandbox {

    templateItems$: Observable<TemplateItemResponseModel[]> = this.store.pipe(select(reducer.templateItems_selector));

    constructor(appDialogsService: AppDialogsService,
                private templateRestService: TemplateRestService,
                private store: Store<reducer.State>) {
        super(appDialogsService);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(templateItemIds: number[]) {
        this.templateRestService.getTemplateItemsByTemplateItemIds(templateItemIds)
            .subscribe((templateItems: TemplateItemResponseModel[]) => {
                this.store.dispatch(new actions.SetTemplateItems(templateItems));
            });
    }

    updateCategoryTemplateItems(data: SubmitCategoryTemplateItemsData): Observable<any> {

        // Check if create or edit
        if (data.isEditMode) {

            // Check if single edit or multiple edit
            if (data.templateItemIds.length > 1) {
                return this.templateRestService.updateTemplateItems({
                    templateItemIds: data.templateItemIds,
                    templateId: data.templateId,
                    locationListId: null,
                    fieldType: CustomFieldTypeEnum.TEXT,
                    fieldScope: data.fieldScope,
                    fieldScopeChanged: data.fieldScopeChanged,
                    displayOnTicket: data.displayOnTicket,
                    displayOnTicketChanged: data.displayOnTicketChanged,
                    required: data.required,
                    requiredChanged: data.requiredChanged,
                    defaultValue: null
                });
            } else {
                return this.templateRestService.updateTemplateItem({
                    templateItemId: data.templateItemIds[0],
                    templateId: data.templateId,
                    locationListId: null,
                    description: data.description,
                    fieldType: CustomFieldTypeEnum.TEXT,
                    fieldScope: data.fieldScope,
                    displayOnTicket: data.displayOnTicket,
                    required: data.required,
                    defaultValue: null
                });
            }
        } else {
            return this.templateRestService.createTemplateItem({
                templateId: data.templateId,
                locationListId: null,
                description: data.description,
                fieldType: CustomFieldTypeEnum.TEXT,
                fieldScope: data.fieldScope,
                displayOnTicket: data.displayOnTicket,
                required: data.required,
                defaultValue: null
            });
        }
    }
}
