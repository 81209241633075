import * as actions from './actions';
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CloudPaymentDeviceResponseModel } from '../../../../../../../../../core/modules/rest/cloud-payment/response/cloud-payment-response.model';
import { OverrideReasonResponseModel } from '../../../../../../../../../core/modules/rest/over-ride-reason/response/over-ride-reason-response.model';

export const REDUCER_NAME = 'dialog_create/edit-override-reason';

export interface State {
    overrideReason: OverrideReasonResponseModel;
}

const initialState: State = {
    overrideReason: {} as OverrideReasonResponseModel
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const override_reason_selector = createSelector(state_selector, (state: State) => state.overrideReason);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = initialState, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE_ACTION: return initialState;

        case actions.SET_OVERRIDE_REASON_ACTION: return { ... state, overrideReason: action.overrideReason };

        default: return state;
    }
}
