
import {forkJoin as observableForkJoin, of as observableOf, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import * as reducer from './store/reducer';
import * as actions from './store/actions';
import {Injectable} from "@angular/core";
import {
    KioskDeviceInfoResponseModel
} from "../../../../../../../../core/modules/rest/kiosk-device-info/response/kiosk-device-info-response.model";
import {
    KioskConfigurationResponseModel
} from "../../../../../../../../core/modules/rest/kiosk-configuration/response/kiosk-configuration-response.model";
import {UserResponseModel} from "../../../../../../../../core/modules/rest/user/response/user-response.model";
import {
    EmailHtmlTemplateResponseModel
} from "../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {
    KioskDeviceInfoRestService
} from "../../../../../../../../core/modules/rest/kiosk-device-info/kiosk-device-info-rest.service";
import {
    KioskConfigurationRestService
} from "../../../../../../../../core/modules/rest/kiosk-configuration/kiosk-configuration-rest.service";
import {
    EmailHtmlTemplateRestService
} from "../../../../../../../../core/modules/rest/email-html-template/email-html-template-rest.service";
import {Store} from "@ngrx/store";
import {
    UpdateKioskDeviceInfoRequestModel
} from "../../../../../../../../core/modules/rest/kiosk-device-info/request/update-kiosk-device-info-request.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {CreateBaseResponseModel} from "../../../../../../../../core/modules/rest/create-base-response.model";
import {
    CreateKioskDeviceInfoRequestModel
} from "../../../../../../../../core/modules/rest/kiosk-device-info/request/create-kiosk-device-info-request.model";

@Injectable()
export class EditKioskDeviceInfoDialogSandbox extends DialogSandbox {

    kioskDeviceInfo$: Observable<KioskDeviceInfoResponseModel> = this.store.select(reducer.kioskDeviceInfo_selector);
    kioskConfigurations$: Observable<KioskConfigurationResponseModel[]> = this.store.select(reducer.kioskConfigurations_selector);
    users$: Observable<UserResponseModel[]> = this.store.select(reducer.users_selector);
    termsAndConditionsTemplates$: Observable<EmailHtmlTemplateResponseModel[]> = this.store.select(reducer.termsAndConditionsTemplates_selector);

    constructor(appDialogsService: AppDialogsService,
                private store: Store<reducer.State>,
                private rootSandbox: RootSandbox,
                private kioskDeviceInfoRestService: KioskDeviceInfoRestService,
                private kioskConfigurationRestService: KioskConfigurationRestService,
                private emailHtmlTemplateRestService: EmailHtmlTemplateRestService) {

        super(appDialogsService);
    }


    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadData(kioskDeviceInfoId: number) {

        this.showLoader();

        let getKioskDeviceInfo_r = kioskDeviceInfoId === undefined ? observableOf(null) : this.kioskDeviceInfoRestService.getById(kioskDeviceInfoId);
        let getKioskConfigurations_r = this.kioskConfigurationRestService.getAll();
        let users_r = this.rootSandbox.getAllActiveUsers();
        let termsAndConditionsTemplates_r = this.emailHtmlTemplateRestService.getAllTermsAndConditionsEmailHtmlTemplates();

        observableForkJoin(getKioskDeviceInfo_r, getKioskConfigurations_r, users_r, termsAndConditionsTemplates_r)
            .subscribe(([kioskDeviceInfo, kioskConfigurations, users, termsAndConditionsTemplates]: [KioskDeviceInfoResponseModel, KioskConfigurationResponseModel[], UserResponseModel[], EmailHtmlTemplateResponseModel[]]) => {
                let activeKioskConfigurations = kioskConfigurations.filter((kiosk: KioskConfigurationResponseModel) => kiosk.active == true);
                this.store.dispatch(new actions.SetKioskDeviceInfo(kioskDeviceInfo));
                this.store.dispatch(new actions.SetKioskConfigurations(activeKioskConfigurations));
                this.store.dispatch(new actions.SetUsers(users));
                this.store.dispatch(new actions.SetTermsAndConditionsTemplates(termsAndConditionsTemplates));

                this.hideLoader();
            },
            () => this.hideLoader());
    }

    createKioskDeviceInfo(description: string,
                          kioskDeviceNumber: string,
                          kioskDeviceUniqueIdentifier: string,
                          kioskSupportEmails: string,
                          kioskSupportPhones: string,
                          soldById: number,
                          termsAndConditionsEmailHtmlTemplateId: number,
                          kioskConfigurationId: number,
                          kioskSenderEmail: string): Observable<CreateBaseResponseModel> {

        const data: CreateKioskDeviceInfoRequestModel = new CreateKioskDeviceInfoRequestModel(description, kioskDeviceNumber, kioskDeviceUniqueIdentifier, kioskSupportEmails, kioskSupportPhones, soldById, termsAndConditionsEmailHtmlTemplateId, kioskConfigurationId, kioskSenderEmail);

        return this.kioskDeviceInfoRestService.create(data).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while creating kiosk', error);
            }));
    }

    updateKioskDeviceInfo(kioskDeviceInfoId: number,
                          description: string,
                          kioskDeviceNumber: string,
                          kioskDeviceUniqueIdentifier: string,
                          kioskSupportEmails: string,
                          kioskSupportPhones: string,
                          soldById: number,
                          termsAndConditionsEmailHtmlTemplateId: number,
                          kioskConfigurationId: number,
                          kioskSenderEmail: string): Observable<any> {

        const data: UpdateKioskDeviceInfoRequestModel = new UpdateKioskDeviceInfoRequestModel(kioskDeviceInfoId, description, kioskDeviceNumber, kioskDeviceUniqueIdentifier, kioskSupportEmails, kioskSupportPhones, soldById, termsAndConditionsEmailHtmlTemplateId, kioskConfigurationId, kioskSenderEmail);

        return this.kioskDeviceInfoRestService.update(data).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while updating kiosk', error);
            }));
    }
}
