import {NgModule} from "@angular/core";
import {CloneProductPricingDateRangesDialogSandbox} from "./sandbox/clone-product-pricing-date-ranges-dialog.sandbox";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {StoreModule} from "@ngrx/store";
import {CloneProductPricingDateRangesDialogFormComponent} from './components/clone-product-pricing-date-ranges-dialog-form/clone-product-pricing-date-ranges-dialog-form.component';
import {CloneProductPricingDateRangesDialogComponent} from "./clone-product-pricing-date-ranges-dialog.component";

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    declarations: [
        CloneProductPricingDateRangesDialogComponent,
        CloneProductPricingDateRangesDialogFormComponent
    ],
    providers: [
        CloneProductPricingDateRangesDialogSandbox
    ],
    exports: [
        CloneProductPricingDateRangesDialogComponent
    ],
    entryComponents: [
        CloneProductPricingDateRangesDialogComponent
    ]
})
export class CloneProductPricingDateRangesDialogModule {
}
