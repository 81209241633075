import { NgModule } from "@angular/core";
import { SharedModule } from "../../../../../../../shared/shared.module";
import { EditCustomerDialogComponent} from "./container/edit-customer-dialog.component";
import { StoreModule } from "@ngrx/store";
import { reducer, REDUCER_NAME } from "./sandbox/store/reducer";
import { EditCustomerDialogSandbox } from "./sandbox/edit-customer-dialog.sandbox";
import { EditCustomerFormComponent } from './components/edit-customer-form/edit-customer-form.component';


@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditCustomerDialogSandbox
    ],
    declarations: [
        EditCustomerDialogComponent,
        EditCustomerFormComponent
    ],
    entryComponents: [
        EditCustomerDialogComponent
    ],
    exports: [
      EditCustomerDialogComponent
    ]
})
export class EditCustomerDialogModule {

}
