
import {combineLatest as observableCombineLatest, of as observableOf, forkJoin as observableForkJoin, BehaviorSubject, Observable} from 'rxjs';

import {mergeMap, take, map, catchError} from 'rxjs/operators';
import {Injectable} from "@angular/core";

import {Store} from "@ngrx/store";
import {RootSandbox} from "../../../../../../../../core/store/root.sandbox";
import {CostRestService} from "../../../../../../../../core/modules/rest/cost/cost-rest.service";
import {LocationResponseModel} from "../../../../../../../../core/modules/rest/location/response/location-response.model";
import {MultiselectDropdownOptionModel} from "../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

import * as fromFeature from './store/reducer';
import * as fromFeatureActions from './store/actions';

import {IncludedCostModel} from "../model/included-cost.model";
import {IncludedCostResponseModel} from "../../../../../../../../core/modules/rest/cost/response/included-cost-response.model";
import {HttpErrorModel} from "../../../../../../../../core/modules/rest/http-error.model";
import {AmountTypeEnum} from "../../../../../../../../shared/enums/amount-type.enum";
import {CostTypeEnum} from "../../../../../../../../shared/enums/cost-type.enum";
import {DialogSandbox} from "../../../../../../../../shared/dialogs/services/dialog.sandbox";
import {AppDialogsService} from "../../../../../../../../core/services/app-dialogs.service";
import {UpdateCostRequestModel} from "../../../../../../../../core/modules/rest/cost/request/update-cost-request.model";
import {CreateCostRequestModel} from "../../../../../../../../core/modules/rest/cost/request/create-cost-request.model";
import * as actions from "../../../../quick-selling-button-products/dialogs/edit-quick-selling-button-product-dialog/sandbox/store/actions";

@Injectable()
export class EditIncludedCostDialogSandbox extends DialogSandbox {

    private errorMessage$: BehaviorSubject<string>;

    constructor(appDialogsService: AppDialogsService,
                private store: Store<fromFeature.State>,
                private rootSandbox: RootSandbox,
                private costRestService: CostRestService) {
        super(appDialogsService);
        this.errorMessage$ = new BehaviorSubject<string>(null);
    }

    resetState() {
        this.store.dispatch(new actions.ResetState());
    }

    loadDialogData(costId: number) {

        this.showLoader();

        const editIncludedCost = !!costId;

        // Clear error message
        this.errorMessage$.next('');

        // Dialog data loading
        this.store.dispatch(new fromFeatureActions.UpdateDialogDataLoaded(false));

        // Update edit included cost
        this.store.dispatch(new fromFeatureActions.UpdateEditIncludedCost(editIncludedCost));

        // Get included cost by id
        const getIncludedCostById_Request = this.costRestService.getIncludedCostById(costId).pipe(
            catchError((error: HttpErrorModel) => {
                return this.rootSandbox.handleHttpError('Error while loading included cost by id', error);
            }));

        // Get all locations
        const getAllLocations_Request = this.rootSandbox.getAllLocations();

        observableForkJoin([getAllLocations_Request, editIncludedCost ? getIncludedCostById_Request : observableOf(null)])
            .subscribe(results => {

                const allLocations: LocationResponseModel[] = results[0];
                let includedCost: IncludedCostResponseModel = results[1];

                this.store.dispatch(new fromFeatureActions.UpdateAllLocations(allLocations));

                if (!includedCost) {
                    includedCost = new IncludedCostModel();
                }

                this.store.dispatch(new fromFeatureActions.UpdateIncludedCost(includedCost));

                // Dialog data loaded
                this.store.dispatch(new fromFeatureActions.UpdateDialogDataLoaded(true));

                this.hideLoader();
            },
            () => this.hideLoader());
    }

    updateIncludedCostProperty(includedCost: IncludedCostModel) {
        this.store.dispatch(new fromFeatureActions.UpdateIncludedCost(includedCost));
    }

    getDialogDataLoaded(): Observable<boolean> {
        return this.store.select(fromFeature.dialogDataLoaded_selector);
    }

    getEditIncludedCost(): Observable<boolean> {
        return this.store.select(fromFeature.editIncludedCost_selector);
    }

    getAllLocationsForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        return this.store.select(fromFeature.allLocations_selector).pipe(
            map((locations: LocationResponseModel[]) => {
                return locations.map((location: LocationResponseModel) => {
                    return new MultiselectDropdownOptionModel(location.description, location.locationId.toString());
                });
            }));
    }

    getIncludedCost(): Observable<IncludedCostModel> {
        return this.store.select(fromFeature.includedCost_selector);
    }

    getAmountTypesForMultiselectDropdown(): Observable<MultiselectDropdownOptionModel[]> {
        const array: MultiselectDropdownOptionModel[] = [
            new MultiselectDropdownOptionModel('Percent', AmountTypeEnum.PERCENT),
            new MultiselectDropdownOptionModel('Dollars', AmountTypeEnum.DOLLARS)
        ];

        return observableOf(array);
    }

    getErrorMessage(): Observable<string> {
        return this.errorMessage$.asObservable();
    }

    save(): Observable<boolean> {
        return observableCombineLatest(
            this.getEditIncludedCost(),
            this.getIncludedCost()
        ).pipe(
            take(1),
            mergeMap(([editIncludedCost, includedCost]) => {
                const editIncludedCostParameterized: boolean = editIncludedCost;
                const includedCostParameterized: IncludedCostModel = includedCost;

                // Validate data
                if (!includedCostParameterized.description || includedCostParameterized.description.trim().length === 0) {
                    this.errorMessage$.next('Please enter description');
                    return observableOf(false);
                }

                if (includedCostParameterized.amount === null) {
                    this.errorMessage$.next('Please enter amount');
                    return observableOf(false);
                }

                if (!includedCostParameterized.amountType) {
                    this.errorMessage$.next('Please select amount type');
                    return observableOf(false);
                }

                // Clear error message
                this.errorMessage$.next('');

                if (editIncludedCostParameterized) {
                    const data: UpdateCostRequestModel = new UpdateCostRequestModel(
                        includedCostParameterized.costId,
                        includedCostParameterized.costType,
                        includedCostParameterized.description,
                        includedCostParameterized.amount,
                        includedCostParameterized.amountType,
                        false,
                        includedCostParameterized.glCode,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        false,
                        null,
                        false,
                        null);

                    return this.costRestService.update(data).pipe(
                        map(() => {
                            return true;
                        }),
                        catchError((error: HttpErrorModel) => {
                            return this.rootSandbox.handleHttpError('Error while updating included cost', error);
                        }),);
                } else {
                    const data: CreateCostRequestModel = new CreateCostRequestModel(
                        CostTypeEnum.INCLUDED,
                        includedCostParameterized.description,
                        includedCostParameterized.amount,
                        includedCostParameterized.amountType,
                        false,
                        includedCostParameterized.glCode,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        false,
                        null,
                        false,
                        null);

                    return this.costRestService.create(data).pipe(
                        map(() => {
                            return true;
                        }),
                        catchError((error: HttpErrorModel) => {
                            return this.rootSandbox.handleHttpError('Error while creating included cost', error);
                        }),);
                }
            }),);

    }
}
