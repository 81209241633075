import {NgModule} from "@angular/core";
import {SendEmailDialogComponent} from './send-email-dialog.component';
import {SendEmailDialogSandbox} from "./send-email-dialog.sandbox";
import {SharedModule} from "../../../shared.module";

@NgModule({
    providers: [
        SendEmailDialogSandbox
    ],
    declarations: [
        SendEmailDialogComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        SendEmailDialogComponent
    ],
    entryComponents: [
        SendEmailDialogComponent
    ]
})
export class SendEmailDialogModule {

}
