import { Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {EmailHtmlTemplateResponseModel} from "../../../../../../../../../core/modules/rest/email-html-template/response/email-html-template-response.model";


@Component({
    selector: 'app-preview-email-html-template-form',
    templateUrl: './preview-email-html-template-form.component.html'
})
export class PreviewEmailHtmlTemplateFormComponent implements OnInit {

    @Input() emailHtmlTemplate: EmailHtmlTemplateResponseModel;

    @Input() header: string;

    @Output() closeEvent = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit() {
    }

    onClose() {
        this.closeEvent.emit();
    }
}
