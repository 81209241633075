import {NgModule} from "@angular/core";
import {SharedModule} from "../../../../../../../shared/shared.module";
import {EditAutoReportDialogComponent} from "./container/edit-auto-report-dialog.component";
import {EditAutoReportDialogSandbox} from "./sandbox/edit-auto-report-dialog.sandbox";
import {StoreModule} from "@ngrx/store";
import {reducer, REDUCER_NAME} from "./sandbox/store/reducer";
import {EditAutoReportFormComponent} from './components/edit-auto-report-form/edit-auto-report-form.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature(REDUCER_NAME, reducer)
    ],
    providers: [
        EditAutoReportDialogSandbox
    ],
    declarations: [
        EditAutoReportDialogComponent,
        EditAutoReportFormComponent
    ],
    exports: [
        EditAutoReportDialogComponent
    ],
    entryComponents: [
        EditAutoReportDialogComponent
    ]
})
export class EditAutoReportDialogModule {

}
