import {Action} from "@ngrx/store";

export const RESET_STATE = '[Dialog - Clone Product :: MAIN] Reset State';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export type All = ResetState;
