<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmit()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>{{ editMode ? "Edit Kiosk" : "Add Kiosk" }}</h1>
            </div>
        </div>
    </div>

    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">
        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Description</label>
                        <input class="main--element input-large"
                               type="text"
                               name="Description"
                               placeholder="Description"
                               formControlName="description">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Kiosk Device Number</label>
                        <input class="main--element input-large"
                               maxlength="3"
                               type="text"
                               name="Kiosk Device Number"
                               placeholder="Fill in Device Number"
                               formControlName="kioskDeviceNumber">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Support Emails</label>
                        <input type="text"
                               class="main--element input-large"
                               placeholder="example: kiosk-support@smartstubs.com"
                               formControlName="kioskSupportEmails"
                               name="Kiosk Support Emails">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Support Phones</label>
                        <input type="text"
                               class="main--element input-large"
                               placeholder="example: (888)123-4567"
                               formControlName="kioskSupportPhones"
                               name="Kiosk Support Phones">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Kiosk Sender Email</label>
                        <input type="text"
                               class="main--element input-large"
                               placeholder="example: kiosk-support@smartstubs.com"
                               formControlName="kioskSenderEmail"
                               name="Kiosk Sender Email">
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Sold By</label>
                        <app-dropdown class="main--element"
                                      [customClasses]="'dropdown_large'"
                                      [options]='soldByOptions'
                                      [preSelectedValue]="form.value.soldById"
                                      formControlName="soldById">
                        </app-dropdown>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Terms and Conditions template</label>
                        <app-dropdown class="main--element"
                                      [customClasses]="'dropdown_large'"
                                      [options]="termsAndConditionsTemplateOptions"
                                      [preSelectedValue]="form.value.termsAndConditionsEmailHtmlTemplateId"
                                      formControlName="termsAndConditionsEmailHtmlTemplateId"
                        >
                        </app-dropdown>
                    </app-input-label-group>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-12">
                    <app-input-label-group>
                        <label class="label">Configuration</label>
                        <app-dropdown class="main--element"
                                      [customClasses]="'dropdown_large'"
                                      [options]='kioskConfigurationOptions'
                                      [preSelectedValue]="form.value.kioskConfigurationId"
                                      formControlName="kioskConfigurationId"
                        >
                        </app-dropdown>
                    </app-input-label-group>
                </div>
            </div>
        </div>
    </div><!-- Dialog Content :: END -->

    <div class="errorMessage_wrapper"
         *ngIf="errorMessage">
        <label class="error">
            {{ errorMessage }}
        </label>
    </div>

    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onClose()">
                    Cancel
                </app-button>
            </div>

            <div class="cell medium-6">
                <app-button
                    [buttonType]='"primary"'
                    [disabled]="!form.valid"
                    (clickEvent)="onSubmit()">
                    Save
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
