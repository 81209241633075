<form class="form_container"
      [formGroup]="form"
      (ngSubmit)="onSubmit()">

    <div class="header_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-12">
                <h1>Mapping for commission: {{ commissionDescription }}</h1>
            </div>
        </div>
    </div>

    <!-- Dialog Content :: BEGIN -->
    <div class="content_wrapper">
        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-3">
                    <app-input-label-group>
                        <label class="label">Category</label>
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="loading"
                            [allOptionEnabled]="true"
                            [options]="categories"
                            [sort]="'ASC'"
                            [formControlName]="'categoryIds'">
                        </app-multiselect-dropdown>
                    </app-input-label-group>
                    <app-switch
                        [switchLabel]="'Show Inactive Categories'"
                        [value]="showInactiveCategories"
                        (switchValueChanged)="onShowInactiveCategories($event)">
                     </app-switch>
                </div>

                <div class="cell medium-3">
                    <app-input-label-group>
                        <label class="label">Product</label>
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="loading"
                            [allOptionEnabled]="true"
                            [options]="products"
                            [sort]="'ASC'"
                            [formControlName]="'productIds'">
                        </app-multiselect-dropdown>
                    </app-input-label-group>
                    <app-switch
                        [switchLabel]="'Show Inactive Products'"
                        [value]="showInactiveProducts"
                        (switchValueChanged)="onShowInactiveProducts($event)">
                    </app-switch>
                </div>

                <div class="cell medium-3">
                    <app-input-label-group>
                        <label class="label">MultiPass</label>
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="loading"
                            [allOptionEnabled]="true"
                            [options]="passes"
                            [sort]="'ASC'"
                            [formControlName]="'passIds'">
                        </app-multiselect-dropdown>
                    </app-input-label-group>
                    <app-switch
                        [switchLabel]="'Show Inactive Passes'"
                        [value]="showInactivePasses"
                        (switchValueChanged)="onShowInactivePasses($event)">
                    </app-switch>
                </div>

                <div class="cell medium-3">
                    <app-input-label-group>
                        <label class="label">Tier</label>
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="loading"
                            [allOptionEnabled]="true"
                            [options]="tiers"
                            [sort]="'ASC'"
                            [formControlName]="'tierIds'">
                        </app-multiselect-dropdown>
                    </app-input-label-group>
                    <app-switch
                        [switchLabel]="'Show Inactive Tiers'"
                        [value]="showInactiveTiers"
                        (switchValueChanged)="onShowInactiveTiers($event)">
                    </app-switch>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-3">
                    <app-input-label-group>
                        <label class="label">Role</label>
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="loading"
                            [allOptionEnabled]="true"
                            [options]="roles"
                            [sort]="'ASC'"
                            [formControlName]="'roleIds'">
                        </app-multiselect-dropdown>
                    </app-input-label-group>
                    <app-switch
                        [switchLabel]="'Show Inactive Roles'"
                        [value]="showInactiveRoles"
                        (switchValueChanged)="onShowInactiveRoles($event)">
                    </app-switch>
                </div>

                <div class="cell medium-3">
                    <app-input-label-group>
                        <label class="label">User</label>
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="loading"
                            [allOptionEnabled]="true"
                            [options]="users"
                            [sort]="'ASC'"
                            [formControlName]="'userIds'">
                        </app-multiselect-dropdown>
                    </app-input-label-group>
                    <app-switch
                        [switchLabel]="'Show Inactive Users'"
                        [value]="showInactiveUsers"
                        (switchValueChanged)="onShowInactiveUsers($event)">
                    </app-switch>
                </div>
            </div>
        </div>

        <div class="formRow">
            <div class="grid-x grid-margin-x">
                <div class="cell medium-3">
                    <app-input-label-group>
                        <label class="label">Location</label>
                        <app-multiselect-dropdown
                            class="main--element"
                            [loading]="loading"
                            [allOptionEnabled]="true"
                            [options]="locations"
                            [sort]="'ASC'"
                            [formControlName]="'locationIds'">
                        </app-multiselect-dropdown>
                    </app-input-label-group>
                    <app-switch
                        [switchLabel]="'Show Inactive Locations'"
                        [value]="showInactiveLocations"
                        (switchValueChanged)="onShowInactiveLocations($event)">
                    </app-switch>
                </div>
            </div>
        </div>
    </div><!-- Dialog Content :: END -->

    <!-- Footer :: BEGIN -->
    <div class="footer_wrapper">
        <div class="grid-x grid-margin-x">
            <div class="cell medium-6 text-right">
                <app-button
                    [buttonType]='"secondary"'
                    (clickEvent)="onCancel()">
                    Cancel
                </app-button>
            </div>
            <div class="cell medium-6 text-left">
                <app-button
                    [buttonType]='"primary"'
                    (clickEvent)="onSubmit()">
                    Update
                </app-button>
            </div>
        </div>
    </div><!-- Footer :: END -->
</form>
