import * as actions from './actions';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ProductCostResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-cost-response.model";
import {MultiselectDropdownOptionModel} from "../../../../../../../../../shared/components/form-elements/multiselect-dropdown/multiselect-dropdown-option.model";

export const REDUCER_NAME = 'dialog_edit-product-costs';

export interface State {
    loading: boolean;
    productAdditionalCosts: ProductCostResponseModel[];
    additionalCosts: MultiselectDropdownOptionModel[];
    productIncludedCosts: ProductCostResponseModel[];
    includedCosts: MultiselectDropdownOptionModel[];
    productDiscounts: ProductCostResponseModel[];
    discounts: MultiselectDropdownOptionModel[];
    productAutoAppliedDiscounts: ProductCostResponseModel[];
    autoAppliedDiscounts: MultiselectDropdownOptionModel[];
}

const INITIAL_STATE: State = {
    loading: false,
    productAdditionalCosts: [],
    additionalCosts: [],
    productIncludedCosts: [],
    includedCosts: [],
    productDiscounts: [],
    discounts: [],
    productAutoAppliedDiscounts: [],
    autoAppliedDiscounts: []
};

// ########################################################################################################
// # SELECTORS
// ########################################################################################################
const state_selector = createFeatureSelector<State>(REDUCER_NAME);

export const loading_selector = createSelector(state_selector, (state: State) => state.loading);
export const productAdditionalCosts_selector = createSelector(state_selector, (state: State) => state.productAdditionalCosts);
export const additionalCosts_selector = createSelector(state_selector, (state: State) => state.additionalCosts);
export const productIncludedCosts_selector = createSelector(state_selector, (state: State) => state.productIncludedCosts);
export const includedCosts_selector = createSelector(state_selector, (state: State) => state.includedCosts);
export const productDiscounts_selector = createSelector(state_selector, (state: State) => state.productDiscounts);
export const discounts_selector = createSelector(state_selector, (state: State) => state.discounts);
export const productAutoAppliedDiscounts_selector = createSelector(state_selector, (state: State) => state.productAutoAppliedDiscounts);
export const autoAppliedDiscounts_selector = createSelector(state_selector, (state: State) => state.autoAppliedDiscounts);

// ########################################################################################################
// # REDUCER
// ########################################################################################################
export function reducer(state: State = INITIAL_STATE, action: actions.All): State {
    switch (action.type) {

        case actions.RESET_STATE:
            return INITIAL_STATE;

        case actions.SET_LOADING:
            return {...state, loading: action.loading};

        case actions.SET_PRODUCT_ADDITIONAL_COSTS:
            return {...state, productAdditionalCosts: action.productAdditionalCosts};

        case actions.SET_ADDITIONAL_COSTS:
            return {...state, additionalCosts: action.additionalCosts};

        case actions.SET_PRODUCT_INCLUDED_COSTS:
            return {...state, productIncludedCosts: action.productIncludedCosts};

        case actions.SET_INCLUDED_COSTS:
            return {...state, includedCosts: action.includedCosts};

        case actions.SET_PRODUCT_DISCOUNTS:
            return {...state, productDiscounts: action.productDiscounts};

        case actions.SET_DISCOUNTS:
            return {...state, discounts: action.discounts};

        case actions.SET_PRODUCT_AUTO_APPLIED_DISCOUNTS:
            return {...state, productAutoAppliedDiscounts: action.productAutoAppliedDiscounts};

        case actions.SET_AUTO_APPLIED_DISCOUNTS:
            return {...state, autoAppliedDiscounts: action.autoAppliedDiscounts};

        default:
            return state;
    }
}
