import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {Observable} from "rxjs";
import {StartTimeResponseModel} from "./response/start-time-response.model";
import {GetProductAvailabilityHolidayMappingRequestModel} from "./request/get-product-availability-holiday-mapping-request.model";
import {ProductAvailabilityHolidayMappingResponseModel} from './response/product-availability-holiday-mapping-response.model';
import {ProductAvailabilityResponseModel} from "./response/product-availability-response.model";
import {GetProductAvailabilitiesRequestModel} from "./request/get-product-availabilities-request.model";
import {ProductAvailabilityFullResponseModel} from "./response/product-availability-full-response.model";
import {GetProductAvailabilitiesFilteredRequestModel} from "./request/get-product-availabilities-filtered-request.model";
import {CreateBaseResponseModel} from "../create-base-response.model";
import {CreateProductAvailabilityRequestModel} from "./request/create-product-availability-request.model";
import {UpdateProductAvailabilityRequestModel} from "./request/update-product-availability-request.model";
import {UpdateProductAvailabilitiesRequestModel} from "./request/update-product-availabilities-request.model";
import {UpdateProductAvailabilityHolidayMappingRequestModel} from "./request/update-product-availability-holiday-mapping-request.model";
import {CloneProductAvailabilitiesRequestModel} from "./request/clone-product-availabilities-request.model";
import {ProductAvailabilitiesFullResponseModel} from "./response/product-availabilities-full-response.model";

@Injectable()
export class ProductAvailabilityRestService {

    constructor(private httpClient: HttpClient) {
    }

    getProductAvailabilitiesByProductId(productId: number): Observable<ProductAvailabilityResponseModel[]> {
        return this.httpClient.get<ProductAvailabilityResponseModel[]>(environment.server_api_url + `/api/secured/productAvailability/getProductAvailabilitiesByProductId/${productId}`);
    }

    getProductAvailabilitiesByProductIdsDirectionsAndDateRange(request: GetProductAvailabilitiesRequestModel): Observable<ProductAvailabilityResponseModel[]> {
        return this.httpClient.post<ProductAvailabilityResponseModel[]>(environment.server_api_url + `/api/secured/productAvailability/getProductAvailabilitiesByProductIdsDirectionsAndDateRange`, request);
    }

    getProductAvailabilitiesFiltered(request: GetProductAvailabilitiesFilteredRequestModel): Observable<ProductAvailabilitiesFullResponseModel> {
        return this.httpClient.post<ProductAvailabilitiesFullResponseModel>(environment.server_api_url + `/api/secured/productAvailability/getProductAvailabilitiesFiltered`, request);
    }

    getProductAvailabilitiesByProductAvailabilityIds(productAvailabilityIds: number[]): Observable<ProductAvailabilityResponseModel[]> {
        return this.httpClient.post<ProductAvailabilityResponseModel[]>(environment.server_api_url + '/api/secured/productAvailability/getProductAvailabilitiesByProductAvailabilityIds', productAvailabilityIds);
    }

    getProductAvailabilitiesFullByProductId(productId: number): Observable<ProductAvailabilityFullResponseModel[]> {
        return this.httpClient.get<ProductAvailabilityFullResponseModel[]>(environment.server_api_url + `/api/secured/productAvailability/getProductAvailabilitiesFullByProductId/${productId}`);
    }

    getStartTimesByProductIdAndDirection(productId: number, isReturnTrip: boolean): Observable<StartTimeResponseModel[]> {
        return this.httpClient.get<StartTimeResponseModel[]>(environment.server_api_url + `/api/secured/productAvailability/getStartTimesByProductIdAndDirection/${productId}/${isReturnTrip}`);
    }

    getStartTimesByProductIdPickupLocationIdAndDirection(productId: number, pickupLocationId: number, isReturnTrip: boolean): Observable<StartTimeResponseModel[]> {
        return this.httpClient.get<StartTimeResponseModel[]>(environment.server_api_url + `/api/secured/productAvailability/getStartTimesByProductIdPickupLocationIdAndDirection/${productId}/${pickupLocationId}/${isReturnTrip}`);
    }

    getStartTimesByProductIdDateAndDirection(productId: number, dateInt: number, isReturnTrip: boolean): Observable<StartTimeResponseModel[]> {
        return this.httpClient.get<StartTimeResponseModel[]>(environment.server_api_url + `/api/secured/productAvailability/getStartTimesByProductIdDateAndDirection/${productId}/${dateInt}/${isReturnTrip}`);
    }

    getStartTimesByProductIdPickupLocationIdDateAndDirection(productId: number, pickupLocationId: number, dateInt: number, isReturnTrip: boolean): Observable<StartTimeResponseModel[]> {
        return this.httpClient.get<StartTimeResponseModel[]>(environment.server_api_url + `/api/secured/productAvailability/getStartTimesByProductIdPickupLocationIdDateAndDirection/${productId}/${pickupLocationId}/${dateInt}/${isReturnTrip}`);
    }

    getStartTimesByProductIdPickupLocationIdDropoffLocationIdDateAndDirection(productId: number, pickupLocationId: number, dropoffLocationId: number, dateInt: number, isReturnTrip: boolean): Observable<StartTimeResponseModel[]> {
        return this.httpClient.get<StartTimeResponseModel[]>(environment.server_api_url + `/api/secured/productAvailability/getStartTimesByProductIdPickupLocationIdDropoffLocationIdDateAndDirection/${productId}/${pickupLocationId}/${dropoffLocationId}/${dateInt}/${isReturnTrip}`);
    }

    getProductAvailabilityHolidayMappingByHolidayIdAndProductIds(request: GetProductAvailabilityHolidayMappingRequestModel): Observable<ProductAvailabilityHolidayMappingResponseModel[]> {
        return this.httpClient.post<ProductAvailabilityHolidayMappingResponseModel[]>(environment.server_api_url + '/api/secured/productAvailability/getProductAvailabilityHolidayMappingByHolidayIdAndProductIds', request);
    }

    createProductAvailability(request: CreateProductAvailabilityRequestModel): Observable<CreateBaseResponseModel> {
        return this.httpClient.post<CreateBaseResponseModel>(environment.server_api_url + '/api/secured/productAvailability/create', request);
    }

    cloneProductAvailabilities(request: CloneProductAvailabilitiesRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/productAvailability/cloneProductAvailabilities', request, {responseType: 'text'});
    }

    updateProductAvailability(request: UpdateProductAvailabilityRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/productAvailability/update', request, {responseType: 'text'});
    }

    updateProductAvailabilities(request: UpdateProductAvailabilitiesRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/productAvailability/updateProductAvailabilities', request, {responseType: 'text'});
    }

    updateProductAvailabilityHolidayMapping(request: UpdateProductAvailabilityHolidayMappingRequestModel): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/productAvailability/updateProductAvailabilityHolidayMapping', request, {responseType: 'text'});
    }

    activateProductAvailabilities(productAvailabilityIds: number[]): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/productAvailability/activateProductAvailabilities', productAvailabilityIds, {responseType: 'text'});
    }

    deactivateProductAvailabilities(productAvailabilityIds: number[]): Observable<any> {
        return this.httpClient.post(environment.server_api_url + '/api/secured/productAvailability/deactivateProductAvailabilities', productAvailabilityIds, {responseType: 'text'});
    }
}
