import {AutoAppliedDiscountResponseModel} from "../../../../../../../../core/modules/rest/cost/response/auto-applied-discount-response.model";
import {CostTypeEnum} from "../../../../../../../../shared/enums/cost-type.enum";
import {AmountTypeEnum} from "../../../../../../../../shared/enums/amount-type.enum";
import {DiscountApplyTypeEnum} from "../../../../../../../../shared/enums/discount-apply-type.enum";

export class AutoAppliedDiscountCostModel implements AutoAppliedDiscountResponseModel {
    costId: number;
    costType: CostTypeEnum;
    description: string;
    amount: number;
    amountType: AmountTypeEnum;
    glCode: string;
    fromHeadCount: number;
    toHeadCount: number;
    allLocations: boolean;
    locationDescription: string;
    locationIds: number[];
    active: boolean;
    discountApplyType: DiscountApplyTypeEnum;
    priority: number;

    constructor() {
    }
}
