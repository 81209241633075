export class AuthorizeTransactionRequestModel {

    userId: number;
    amount: number;
    creditCardHolderFullName: string;
    creditCardZipCode: string;
    creditCardNumber: string;
    creditCardSecurityCode: string;
    creditCardExpirationMonth: number;
    creditCardExpirationYear: number;

    constructor() {
    }
}
