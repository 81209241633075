import { Component, Input } from '@angular/core';
import { RelatedOrderSummaryResponseModel } from '../../../../../../../core/modules/rest/order-summary/response/related-order-summary-response.model';

@Component({
  selector: 'app-related-order-info-dialog-summary',
  templateUrl: './related-order-info-dialog-summary.component.html'
})
export class RelatedOrderInfoDialogSummaryComponent {

  @Input() relatedOrder: RelatedOrderSummaryResponseModel;

  constructor() {}

}
