<div class="notification-alert-box"
    *ngIf="notification"
    [ngClass]="notification.type"
    (click)="stopPropagation($event)">

    <div class="notification-alert-box-content">
        <div class="notification-alert-box-icon">
            <i class="{{iconClass}}"></i>
        </div>
        <div class="notification-alert-box-message">
            <h3>{{notification.type}}</h3>
            <p>{{ notification.message }}</p>

            <div class="close-icon " (click)="disposeNotification($event)">
                <i class="fal fa-times"></i>
            </div>
        </div>
    </div>
</div>
