<app-abstract-dialog-container>
    <app-edit-product-availabilities-dialog-form
        [isEditMode]="isEditMode"
        [productId]="productId"
        [productAvailabilities]="productAvailabilities$ | async"
        [departureGroups]="departureGroups$ | async"
        [letterGroups]="letterGroups$ | async"
        [bids]="bids$ | async"
        (submit)="onSubmit($event)"
        (cancel)="onCancel()">
    </app-edit-product-availabilities-dialog-form>
</app-abstract-dialog-container>
