import {Action} from "@ngrx/store";
import {ProductSimpleResponseModel} from "../../../../../../../../../core/modules/rest/product/response/product-simple-response.model";

export const RESET_STATE = '[Dialog - Edit Product Validity :: MAIN] Reset State';
export const SET_PRODUCT = '[Dialog - Edit Product Validity :: MAIN] Set Product';

export class ResetState implements Action {
    readonly type = RESET_STATE;

    constructor() {
    }
}

export class SetProduct implements Action {
    readonly type = SET_PRODUCT;

    constructor(public product: ProductSimpleResponseModel) {
    }
}

export type All = ResetState | SetProduct;
