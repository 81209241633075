<form
    [formGroup]="filterForm">

    <div class="content_wrapper">

        <div class="row-padding">
            <div class="flex-container">
                <div class="display-inline-block">
                    <label for="commission-report-start-date-end-date-datepicker" class="label">Start Date - End Date</label>
                    <div class="datepicker_wrapper single-day-datepicker">
                        <app-datetimepicker
                        id="commission-report-start-date-end-date-datepicker"
                        [isSingleDatePicker]="false"
                        [showDefaultRanges]="true"
                        [showCustomRangeLabel]="true"
                        [placeholderText]="'Start Date - End Date'"
                        [formControlName]="'selectedStartDateEndDate'">
                    </app-datetimepicker>
                </div>
                </div>
                <div class="switch-column">
                    <app-switch 
                        [switchLabel]="'Show Inactive'"
                        [formControlName]="'showInactiveProducts'" >
                    </app-switch>
                </div>
            </div>
        </div>

        <div class="row-padding">
            <div class="display-inline-block">
                <app-input-label-group>
                    <label class="label">Role Sold By</label>
                    <app-multiselect-dropdown
                        class="main--element"
                        [loading]="loadingRoles"
                        [allOptionEnabled]="true"
                        [options]="roleOptions"
                        [sort]="'ASC'"
                        [formControlName]="'selectedRoleIds'">
                    </app-multiselect-dropdown>
                </app-input-label-group>
            </div>

            <div class="display-inline-block">
                <app-input-label-group>
                    <label class="label">Sold By</label>
                    <app-multiselect-dropdown
                        class="main--element"
                        [loading]="loadingUsers"
                        [allOptionEnabled]="true"
                        [options]="userOptions"
                        [formControlName]="'selectedUserIds'">
                    </app-multiselect-dropdown>
                </app-input-label-group>
            </div>
        </div>

        <div class="row-padding">
            <div class="display-inline-block">
                <app-input-label-group>
                    <label class="label">Pass</label>
                    <app-multiselect-dropdown
                        class="main--element"
                        [loading]="loadingPasses"
                        [allOptionEnabled]="true"
                        [options]="passOptions"
                        [sort]="'ASC'"
                        [formControlName]="'selectedPassIds'">
                    </app-multiselect-dropdown>
                </app-input-label-group>
            </div>

            <div class="display-inline-block">
                <app-input-label-group>
                    <label class="label">Product</label>
                    <app-multiselect-dropdown
                        class="main--element"
                        [loading]="loadingProducts"
                        [allOptionEnabled]="true"
                        [options]="productOptions"
                        [sort]="'ASC'"
                        [formControlName]="'selectedProductIds'">
                    </app-multiselect-dropdown>
                </app-input-label-group>
            </div>
        </div>
    </div>

</form>
