import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import {ExpressionItemModel} from '../../../model/expression-item.model';
import {DroppedDataI} from '../../../model/dropped-data.model';
import {UpdateExpressionItemValueModel} from '../../../model/update-expression-item-value.model';
import {DraggedDataI} from '../../../model/dragged-data.model';
import {DraggedFilterDataModel} from '../../../model/dragged-filter-data.model';
import {DraggedGroupDataModel} from '../../../model/dragged-group-data.model';
import {Observable} from 'rxjs';


@Component({
    selector: 'app-order-reports-expression-item',
    templateUrl: './order-reports-expression-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderReportsExpressionItemComponent implements OnInit {

    @Input() itemDepth: number;
    @Input() draggedItemHeight: number;
    @Input() expressionItem: ExpressionItemModel;
    @Input() leftExpressionItem: ExpressionItemModel;
    @Input() rightExpressionItem: ExpressionItemModel;
    @Input() draggedData: DraggedDataI;
    @Input() dropAreasVisible: boolean;
    @Input() parentGroupUUID: string;
    @Input() leftSiblingUUID: string;
    @Input() hasRunOrderReportFullPermission: boolean;
    @Output() droppedDataEvent: EventEmitter<DroppedDataI> = new EventEmitter<DroppedDataI>();
    @Output() deleteExpressionItemEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() dragStartEvent: EventEmitter<DraggedDataI> = new EventEmitter<DraggedDataI>();
    @Output() dragEndEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() expressionItemValueChangedEvent: EventEmitter<UpdateExpressionItemValueModel> = new EventEmitter<UpdateExpressionItemValueModel>();

    constructor() { }

    ngOnInit() {
    }

    onDroppedData(droppedData: DroppedDataI) {
        this.droppedDataEvent.emit(droppedData);
    }

    onDeleteExpressionItem(uuid: string) {
        this.deleteExpressionItemEvent.emit(uuid);
    }

    onDragStart(data: DraggedDataI) {
        this.dragStartEvent.emit(data);
    }

    onDragEnd() {
        this.dragEndEvent.emit();
    }

    onExpressionItemValueChanged(item: UpdateExpressionItemValueModel) {
        this.expressionItemValueChangedEvent.emit(item);
    }

    isLeftExpressionItemCurrentlyInDrag(): boolean {
        if (this.draggedData) {
            if (this.leftExpressionItem) {
                const leftExpressionItemUUID = this.leftExpressionItem.uuid;

                switch (this.draggedData.constructor) {
                    case DraggedFilterDataModel:
                        const draggedFilter: DraggedFilterDataModel = <DraggedFilterDataModel> this.draggedData;
                        return draggedFilter.uuid === leftExpressionItemUUID;
                    case DraggedGroupDataModel:
                        const draggedGroupData: DraggedGroupDataModel = <DraggedGroupDataModel> this.draggedData;
                        return draggedGroupData.uuid === leftExpressionItemUUID;
                }
            }
        }

        return false;
    }

    isRightExpressionItemCurrentlyInDrag(): boolean {
        if (this.draggedData) {
            if (this.rightExpressionItem) {
                const rightExpressionItemUUID = this.rightExpressionItem.uuid;

                switch (this.draggedData.constructor) {
                    case DraggedFilterDataModel:
                        const draggedFilter: DraggedFilterDataModel = <DraggedFilterDataModel> this.draggedData;
                        return draggedFilter.uuid === rightExpressionItemUUID;
                    case DraggedGroupDataModel:
                        const draggedGroupData: DraggedGroupDataModel = <DraggedGroupDataModel> this.draggedData;
                        return draggedGroupData.uuid === rightExpressionItemUUID;
                }
            }
        }

        return false;
    }
}
